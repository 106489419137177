export default {
  keys: [
    {
      title: '소속 부서',
      noorder: true,
      filter: (o) => o.parent.name,
    },
    {
      title: '이름',
      noorder: true,
      filter: (o) => o.user.name,
    },
    {
      title: '직무',
      noorder: true,
      filter: (o) => o.user.duty,
    },
    {
      title: '직급',
      noorder: true,
      filter: (o) => o.user.position,
    },
    {
      title: '이메일(ID)',
      noorder: true,
      filter: (o) => o.user.email,
    },
    {
      title: '휴대폰 번호',
      noorder: true,
      filter: (o) => o.user.phoneNumber,
    },
    {
      title: '내선번호',
      noorder: true,
      filter: (o) => o.user.telNumber,
    },
  ],
};
