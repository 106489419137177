import React from 'react';
import { observer } from 'mobx-react';
import useCtiStore from './hooks/useCtiStore';
import './ctiHistoryFilters.scss';
import { CtiStatusFilterType } from './ctiStatusFilterType';

export default observer(function CtiHistoryFilters() {
  const ctiStore = useCtiStore();

  return (
    <div className="cti-history-filters">
      <select
        value={ctiStore.historyFilter}
        onChange={(e) => ctiStore.setHistoryFilter(e.target.value)}
      >
        <option value={CtiStatusFilterType.all}>전체</option>
        <option value={CtiStatusFilterType.ringing}>링잉</option>
        <option value={CtiStatusFilterType.calling}>통화중</option>
        <option value={CtiStatusFilterType.ended}>통화완료(기록필요)</option>
        <option value={CtiStatusFilterType.missed}>부재중 전화</option>
        <option value={CtiStatusFilterType.handled}>응대완료</option>
        <option value={CtiStatusFilterType.recall}>리콜</option>
      </select>
    </div>
  );
});
