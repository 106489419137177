import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { observer } from 'mobx-react';
import { copy } from 'filters';
import { $qb } from 'scripts/querybuilder';
import { useModal } from 'hooks/useModal';
import { useServices } from 'hooks/useServices';
import modelAddCategory from 'models/add-category';
import DataTableAlpha from 'components/data-table/DataTableAlpha';

const Input = styled.input`
  width: calc(100% - 85px);
  padding: 8px 20px;
  font-size: 14px;
  border: 1px solid #dee2ec;
  border-radius: 4px;
  height: 34px;
  font-size: 14px;
`;

const ModalAddCategory = ({ options, close }) => {
  const modal = useModal();
  const services = useServices();
  const defaultParams = $qb().limit(1000).orderBy('createdAt desc');
  const [params, setParams] = useState(defaultParams);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [list, setList] = useState([]);
  const [obj, setObj] = useState({});

  const categoryCallApi = useCallback(async () => {
    const resp = await services.crm.treatment.categories.items_categories_v2(
      params.build()
    );
    if (!resp) return;

    setData(resp.data);
    setTotal(resp.pagination.total);
    setList(resp.data);
  }, [params]);

  useEffect(() => {
    categoryCallApi();
  }, [categoryCallApi]);

  const onParams = (p) => {
    setParams(p);
    categoryCallApi();
  };

  const onAction = (obj) => {
    let model = obj.key;
    let object = obj.row;
    let event = obj.event;

    if (['name'].indexOf(model.column) !== -1) {
      object[model.column] = event.target.value;
    }

    const btnLabel = event.target.innerText;
    if (btnLabel === '수정') {
      object.$$editing = true;
      return setData(copy(data));
    }

    if (btnLabel === '저장') {
      object.$$editing = false;
      update(object);
      return setData(copy(data));
    }

    if (btnLabel === '취소') {
      object.$$editing = false;
      return setData(copy(data));
    }
  };

  const update = async (object) => {
    if (validator(list, object.name)) {
      try {
        let payload = {
          id: object.id,
          name: object.name,
        };

        await services.crm.treatment.categories.update_v2(payload);
        modal.confirm({
          type: 'SUCCESS',
          msg: '업데이트되었습니다',
        });
      } catch (e) {
        console.log(e.description);
        let errorMessage = e.description;
        modal.confirm({
          type: 'ERROR',
          msg: errorMessage,
        });
      }
    }
  };

  const validator = useCallback((data, name) => {
    if (name === undefined) {
      modal.confirm({
        type: 'ERROR',
        msg: '추가할 카테고리명을 입력하세요.',
      });
      return false;
    }

    //중복 체크 시 공백제거 하여 비교
    const checkName = data.filter((v) => {
      return (v.name || '').replace(' ', '') === (name || '').replace(' ', '');
    });
    if (checkName.length !== 0) {
      modal.confirm({
        type: 'ERROR',
        msg: '중복된 카테고리가 있습니다.',
      });
    }
    return checkName.length === 0;
  }, []);

  const onClickSave = async () => {
    if (validator(data, obj.name)) {
      try {
        const item = {
          name: obj.name,
          visible: true,
        };

        const result = await services.crm.treatment.categories.create_v2(item);
        modal
          .confirm({
            type: 'SUCCESS',
            msg: '생성되었습니다.',
          })
          .then(() => {
            if (options.afterUpdateReturn) {
              close(result.data);
            } else {
              close(1);
            }
          });
      } catch (e) {
        const { code, description } = e;
        console.log(description);
        let errorMessage = description;
        errorMessage = code === 409 && '중복된 카테고리가 있습니다.';
        modal.confirm({
          type: 'ERROR',
          msg: errorMessage,
        });
      }
    }
  };

  return (
    <div className="modal-category modal-input">
      <div className="head flex-row flex-between items-start">
        <div className="title">카테고리 추가</div>
        <i
          onClick={() => {
            if (options.afterUpdateReturn) {
              close({});
            } else {
              close();
            }
          }}
          className="zmdi zmdi-close"
        />
      </div>

      <div className="body">
        <div className="form-control one-line">
          <label className="label-required">카테고리명</label>
          <div className="flex-row">
            <Input
              name="name"
              placeholder="추가할 카테고리명을 입력 후 저장 버튼을 클릭하세요."
              onChange={(e) => setObj({ [e.target.name]: e.target.value })}
            />
            <button
              className="btn btn-basic _small m-l-8"
              onClick={onClickSave}
            >
              저장
            </button>
          </div>
        </div>

        <DataTableAlpha
          model={modelAddCategory}
          data={data}
          total={total}
          params={params}
          onParams={onParams}
          onAction={onAction}
          hideBottom={true}
        />
      </div>
    </div>
  );
};

ModalAddCategory.propTypes = {
  options: PropTypes.object,
  close: PropTypes.func,
};

export default observer(ModalAddCategory);
