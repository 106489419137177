import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { translate } from 'filters';

const TableContextMenu = ({ options, onClick }) => {
  const onClickOverlay = (e) => {
    e.preventDefault();

    if (e.target.classList.contains('table-context-menu')) {
      onClick(-1);
    }
  };

  const action = (item, clickColumn) => {
    onClick({
      item,
      row: options.row,
      clickColumn: clickColumn,
      parentRow: options.parentRow,
    });
  };

  return (
    <div
      className={`table-context-menu ${options.show ? '' : 'display-none'}`}
      onClick={onClickOverlay}
      onContextMenu={onClickOverlay}
    >
      <ul
        style={{
          left: `${options.x}px`,
          top: `${options.y}px`,
        }}
      >
        {(options.items || []).length > 0
          ? options.items.map((item, idx) => (
              <li
                key={idx}
                onClick={() => action(item, options?.clickColumn)}
                className={`flex-row items-center flex-between ${item.customStyle}`}
              >
                <span className="flex-fill m-r-4">{translate(item.title)}</span>
                {item.prevOrderCheck && <i className="zmdi zmdi-check"></i>}
              </li>
            ))
          : null}
      </ul>
    </div>
  );
};

TableContextMenu.propTypes = {
  options: PropTypes.object,
  onClick: PropTypes.func,
};

export default observer(TableContextMenu);
