import React, { useEffect, useState, useCallback } from 'react';
import { observer } from 'mobx-react';
import { translate } from 'filters';
import { useModal } from 'hooks/useModal';
import { useServices } from 'hooks/useServices';
import styled from 'styled-components';
import { RadioInput } from 'components/common/RadioInput';

const RadioInputWrapper = styled(RadioInput)`
  width: 100%;
`;

const UnvisitedConfig = () => {
  const modal = useModal();
  const services = useServices();
  const [checkFlag, setCheckFlag] = useState(false);
  const [timeValue, setTimeValue] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [obj, setObj] = useState('');

  const configCallApi = useCallback(async () => {
    try {
      const resp = await services.crm.clinic.configs({
        groupName: 'calender_unvisited',
      });

      if (resp && resp.data.length < 1) return;
      else {
        if (resp.data[0].codeValue === '-1') {
          setCheckFlag(checkFlag);
          setTimeValue(null);
        } else {
          setCheckFlag(!checkFlag);
          setTimeValue(resp.data[0].codeValue);
        }
        setObj(resp.data[0]);
      }
    } catch (e) {
      console.log(e.description);
    }
  }, [services.crm.clinic]);

  useEffect(() => {
    configCallApi();
  }, []);

  const onToggle = () => {
    setCheckFlag(!checkFlag);
  };

  const onChange = (e) => {
    setTimeValue(e.target.value);
  };

  /*const CallPostApi = async (payload) => {
    try {
      const resp = await services.crm.clinic.configs_multi(payload);
      toast.success('UPDATED');
    } catch (e) {
      toast.error(e.description);
    }
  };*/

  const onClickSave = async () => {
    //1.26.4 임시 팝업 확인
    modal.basic({
      body:
        '해당 설정을 변경하려면 CS팀(cs@unobeauty.kr)으로 연락부탁드립니다.',
      buttons: [{ text: 'CONFIRM', class: 'btn-primary' }],
    });

    //1.26.4 임시 주석처리
    // let data = { ...obj };
    // let payload = {
    //   groupName: 'calender_unvisited',
    //   codeName: 'time',
    //   codeValue: timeValue,
    // };

    // if (data.id) payload.id = data.id;
    // payload.codeValue = checkFlag ? timeValue : '-1';
    // payload.codeValue = payload.codeValue === null ? '10' : payload.codeValue;

    // await CallPostApi({ data: payload });
    // setTimeValue(null);
    // configCallApi();
  };

  return (
    <div className="unvisited-time">
      <div className="route-top">
        <div className="title">미방문 자동전환 설정</div>
        <div className="flex-row">
          <button
            className="btn btn-warning"
            onClick={() => {
              configCallApi();
            }}
          >
            {translate('INIT')}
          </button>
          <button className="btn btn-primary m-l-8" onClick={onClickSave}>
            {translate('SAVE')}
          </button>
        </div>
      </div>
      <div className="data-table">
        <div className="form-control">
          <label>자동 전환</label>
          <div className="radios flex-row">
            <div className="form-control unvisited-padding">
              <label htmlFor="on">사용</label>
              <div className="unvisited-width">
                <RadioInputWrapper
                  id="on"
                  name="auto"
                  checked={checkFlag}
                  onChange={onToggle}
                />
              </div>
            </div>
            <div className="form-control unvisited">
              <label htmlFor="off">미사용</label>
              <div className="unvisited-width">
                <RadioInputWrapper
                  id="off"
                  name="auto"
                  checked={!checkFlag}
                  onChange={onToggle}
                />
              </div>
            </div>
          </div>
        </div>
        {checkFlag && (
          <div className="form-control-time">
            예약시간으로부터
            <select value={timeValue || '10'} onChange={onChange}>
              <option value="10">10분</option>
              <option value="30">30분</option>
              <option value="60">60분</option>
            </select>
            경과 시 미방문으로 자동전환
          </div>
        )}
      </div>
    </div>
  );
};

export default observer(UnvisitedConfig);
