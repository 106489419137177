import * as moment from 'moment';
import { CounselingResult } from 'components/cti/counselingResult';

export default {
  keys: [
    {
      title: 'No',
      column: 'no',
      noorder: true,
      class: 'flex-center no',
    },
    {
      title: '통화일',
      column: 'createdAt',
      noorder: true,
      filter: (o) => {
        if (!o.createdAt) {
          return `<div>-</div>`;
        }
        return `<div style="text-align: center;">
            <div>${moment(o.createdAt).format('YYYY-MM-DD')}</div>
            <div>${moment(o.createdAt).format('HH:mm:ss')}</div>
            </div>`;
      },
    },
    {
      title: '통화결과',
      column: 'counselingResult',
      noorder: true,
      filter: (o) => {
        return `<div>${CounselingResult.toName(o.counselingResult)}</div>`;
      },
    },
    {
      title: '통화메모',
      column: 'memo',
      type: 'text',
      noorder: true,
      thStyle: () => {
        return { width: '200px' };
      },
      customStyle: (o) => {
        let refundCount = false;
        for (let i in o.items) {
          const item = o.items[i];
          if (item.refundCount > 0) {
            refundCount = true;
            break;
          }
        }
        if (refundCount) {
          return { color: 'red' };
        }
      },
    },
    {
      title: '리콜시간',
      column: 'recallAt',
      type: 'date',
      noorder: true,
      filter: (o) => {
        let value = '';
        if (o.recallTime) {
          if (o.recallTime > 0 && o.recallTime < 4) {
            value = `오전 ${o.recallTime + 8}시-${o.recallTime + 9}시`;
          } else if (o.recallTime < 10) {
            value = `오후 ${o.recallTime - 4}시-${o.recallTime - 3}시`;
          } else {
            if (o.recallTime === 10) value = '오전';
            if (o.recallTime === 11) value = '오후';
            if (o.recallTime === 12) value = '상시';
          }
        }
        if (!o.recallAt) {
          return `<div>-</div>`;
        }

        return `<div style="text-align: center;">
        <div>${moment(o.recallAt).format('YYYY-MM-DD')}</div>
        <div>${value}</div>
        </div>`;
      },
    },
    {
      title: '통화자',
      column: 'callerName',
      noorder: true,
    },
    {
      title: '통화시간',
      column: 'callTime',
      noorder: true,
    },
  ],
};
