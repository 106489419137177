import React from 'react';
import { observer } from 'mobx-react';
import AppointmentCellItems from './AppointmentCellItems';
import AppointmentCellName from './AppointmentCellName';
import AppointmentCellWrapper from './AppointmentCellWrapper';

const AppointmentCell = observer(({ appointment, index }) => {
  return (
    <AppointmentCellWrapper appointment={appointment} index={index}>
      <AppointmentCellName appointment={appointment} />
      <AppointmentCellItems appointment={appointment} />
    </AppointmentCellWrapper>
  );
});

export default AppointmentCell;
