import { toThousandCommas } from 'filters';

export default {
  keys: [
    {
      title: 'ACTION',
      noorder: true,
      filter: (o) => `
        ${
          !o.isDisabled
            ? `<button class="btn btn-sm btn-primary m-r-8">수정</button>`
            : ''
        }
        <button class="btn btn-sm m-r-8 ${
          o.isDisabled ? 'btn-gray' : 'btn-black'
        }">${o.isDisabled ? '사용' : '미사용'}</button>
        <button class="btn btn-sm m-r-8 ${
          o.isDisabled ? 'btn-gray' : 'btn-danger'
        }" data-type="delete" >삭제</button>`,
    },
    {
      title: '매체',
      noorder: true,
      filter: (o) => o.media?.tag,
    },
    {
      title: '이벤트명',
      column: 'name',
      noorder: true,
    },
    {
      title: '이벤트 유형',
      column: 'eventType',
      noorder: true,
    },
    {
      title: '이벤트 가격',
      noorder: true,
      filter: (o) => (o.price !== null ? toThousandCommas(o.price) : ''),
    },
  ],
};
