import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import ImageEditor from 'components/image-editor/ImageEditor';
import { useHistory, useParams } from 'react-router-dom';
import { objectToFormData } from 'object-to-formdata';
import { useServices } from 'hooks/useServices';
import { useToast } from 'hooks/useToast';
import { useAuth } from 'store/auth';
import Resizer from 'react-image-file-resizer';

function blobToDataURL(blob, callback) {
  var a = new FileReader();
  if (blob) {
    a.onload = function (e) {
      callback(e.target.result);
    };
    a.readAsDataURL(blob);
  }
}

function EditImage() {
  const history = useHistory();
  const services = useServices();
  const toast = useToast();
  const auth = useAuth();
  const { id } = useParams();
  const [penchart, setPenchart] = useState({});
  const [url, setUrl] = useState(null);

  useEffect(() => {
    loadImage();
  }, []);

  const loadImage = () => {
    services.crm.penchart.get_v2(id).then((res) => {
      if (!res) return;
      setPenchart(res.data);
    });
    services.crm.penchart.getImg_v2(id, 2048).then((res) => {
      if (!res) return;
      blobToDataURL(res, (dataurl) => {
        setUrl(dataurl);
      });
    });
  };

  const dataURLtoFile = (dataurl, fileName) => {
    if (!dataurl) return;
    let arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], fileName, { type: mime });
  };

  // - customerId : 고객ID
  // - name : 파일 / 폴더명
  // - type : 파일, 폴더 구분(file, folder)
  // - parentId : 부모ID (부모 폴더 ID, 빈값을 넘기면 최상위 폴더)
  // - imageFile : 파일
  // - memo : 메모
  // - id : 파일 업데이트시 업데이트 할 파일 ID (생성시에는 사용하지 않음)
  async function onSave(dataUri) {
    let filename = penchart.name;
    if (filename === undefined) return;
    if (penchart.name.split('.').length === 1) {
      filename += '.jpg';
    }

    try {
      let file = dataURLtoFile(dataUri, 'thumbnail_' + filename);
      const thumbnail = await thumbnailFile(file);

      const params = {
        customerId: penchart.customer?.id,
        name: penchart.name,
        type: 'file',
        imageFile: dataURLtoFile(dataUri, filename),
        thumbnailFile: dataURLtoFile(thumbnail, 'thumbnail_' + filename),
        parentId: penchart.parent?.id,
        id: id,
      };
      await services.crm.penchart.updateFile_v2(objectToFormData(params));
      toast.success('저장되었습니다.');
    } catch (error) {
      toast.error(error.description || error.message);
    }

    if (window.onSaveCallback) {
      setTimeout(function () {
        //팝업 로딩 후 0.5초 뒤에 콜백 호출 (복제지연시간을 고려한 호출)
        window.onSaveCallback(id);
      }, 500);
    }
  }

  const thumbnailFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        512,
        10000,
        'JPEG',
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        'base64'
      );
    });

  function onDownloadFile(dataUri) {
    let link = document.createElement('a');
    link.download = `${penchart.name.split('.')[0]}.png`;
    link.href = dataUri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  if (!url) return null;

  return (
    <ImageEditor url={url} onSave={onSave} onDownloadFile={onDownloadFile} />
  );
}

export default observer(EditImage);
