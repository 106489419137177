import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import CustomerChart from 'components/pages/crm/customer-chart/CustomerChart';
import { useEvent } from 'hooks/useEvent';

const ModalCustomerChart = ({ options, close }) => {
  const eventBus = useEvent();
  useEffect(() => {
    return () => {
      if (options.calledPage === 'home') {
        eventBus.emit('homeLoadAppointments');
      } else if (options.calledPage === 'boards') {
        eventBus.emit(
          'boardsLoadAppointments',
          options.openFlag?.appointmentId
        );
      }
    };
  });
  return (
    <>
      <CustomerChart
        customerId={(options.customer || {}).id}
        appointment={options.appointment}
        consulting={options.consulting}
        treatment={options.treatment}
        payment={options.payment}
        surgery={options.surgery}
        operationNurseChart={options.operationNurseChart}
        nurseChart={options.nurseChart}
        skinManageChart={options.skinManageChart}
        smsNotification={options.smsNotification}
        selectTab={options.selectTab}
        openFlag={options.openFlag}
        closeFlag={options.closeFlag}
        closeModal={close}
        editInfo={options.editInfo}
        calledPage={options.calledPage}
        consultingRequest={options.consultingRequest}
      />
      {/* <div className="buttons">
          <div className="flex-row">
            <button onClick={() => { close(undefined) }} className="btn btn-primary">
              {translate("CLOSE")}
            </button>
          </div>
        </div> */}
    </>
  );
};

ModalCustomerChart.propTypes = {
  options: PropTypes.object,
  close: PropTypes.func,
};

export default observer(ModalCustomerChart);
