export default {
  keys: [
    {
      title: '제품명',
      column: 'name',
    },
    {
      title: '구매 개수',
      column: 'quantity',
      filter: (o) => {
        return `${o.quantity}`;
      },
    },
    {
      title: 'REFUND_QUANTITY',
      column: 'refundCount',
      type: 'input',
    },
  ],
};
