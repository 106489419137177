export const $qb = () => ({
  queryParams: {},

  limit: function (limit) {
    this.queryParams.limit = limit;
    return this;
  },

  page: function (page) {
    this.queryParams.page = page;
    return this;
  },

  offset: function (offset) {
    this.queryParams.offset = offset;
    return this;
  },

  customParam: function (name, value) {
    const splitted = (name || '').split('__');
    this.queryParams[
      splitted.length === 1 ? name : splitted[splitted.length - 1]
    ] = value;
    return this;
  },

  orderBy: function (orderBy) {
    this.queryParams.orderBy = orderBy;
    return this;
  },

  build: function () {
    return JSON.parse(JSON.stringify(this.queryParams));
  },
});
