import React, { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import HideHidden from 'components/app/HideHidden';
import DataTableAlpha from 'components/data-table/DataTableAlpha';
import ModalMessageCallerNumber from 'components/modals/codes/message-codes/ModalMessageCallerNumber';

import helpers from 'hooks/helpers';
import models from 'models';
import { translate } from 'filters';
import { $qb } from 'scripts/querybuilder';
import { useModal } from 'hooks/useModal';
import { useServices } from 'hooks/useServices';
import { useToast } from 'hooks/useToast';

const MessageCallerNumbers = () => {
  const modal = useModal();
  const services = useServices();
  const toast = useToast();
  const defaultParams = $qb().limit().orderBy();
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(null);
  const [params, setParams] = useState(defaultParams);
  const [hideHidden, setHideHidden] = useState(false);
  const [loading, setLoading] = useState(true);
  const onParams = (p) => {
    setParams(p);
  };

  const callApi = useCallback(async () => {
    try {
      setLoading(true);
      const resp = await services.crm.notification.callerNumbers.all();
      setData(resp.data);
      setTotal(resp.total);
      setLoading(false);
    } catch (error) {
      toast.error(error.description);
      setLoading(false);
    }
  }, [services.crm.notification.callerNumbers, toast]);

  useEffect(() => {
    callApi();
  }, [callApi]);

  const onAction = (obj) => {
    const { row, event } = obj; // , rowIdx
    if (event.target.dataset.type === 'visible') {
      const toggleObj = {
        visible: !row['visible'],
        id: row['id'],
      };
      const endpoint = services.crm.notification.callerNumbers;
      return helpers.toggleVisibilityById({
        obj: toggleObj,
        endpoint,
        callback: callApi,
      });
    }
  };

  const onOpenModalCallerNumber = useCallback(() => {
    modal.custom({
      component: ModalMessageCallerNumber,
    });
  }, [modal]);

  return (
    <div className="message-caller-numbers">
      <div className="route-top">
        <div className="title">{translate('CALLER_NUMBERS')}</div>
        <div className="flex-row items-center">
          <HideHidden
            toggleHandler={setHideHidden}
            text="미사용항목 보지않기"
          />
          <button
            onClick={() => {
              onOpenModalCallerNumber({});
            }}
            className="flex-wrap btn btn-sm m-r-8 btn-add"
          >
            {translate('ADD')}
          </button>
        </div>
      </div>
      <DataTableAlpha
        className="header-style-classic"
        model={models.crm.messageCallerNumber}
        data={hideHidden ? data.filter((row) => row.visible) : data}
        total={total}
        params={params}
        onParams={onParams}
        onAction={onAction}
        loading={loading}
        hideBottom={true}
        hideHidden={hideHidden}
        hasDeactivate
      />
    </div>
  );
};

export default observer(MessageCallerNumbers);
