import { makeObservable, observable, toJS } from 'mobx';
import services from 'services';

const toCapital = (str) => str.charAt(0).toUpperCase() + str.slice(1);

// Adding definition here will automatically add co-related getter, action and the observable prop.
// If you want to make custom definition, set customDefinition: true and define yourself.
// (EX: loadTreatmentItemCategories, loadDepartmentCategories)
const models = [
  {
    prop: 'departmentCategories',
    endpoint: services.crm.crud.departmentCategory.all,
    customDefinition: true,
  },
  { prop: 'departments' },
  // { prop: 'treatmentItemCategories', endpoint: services.crm.crud.treatmentItemCategory.all, customDefinition: true },
  { prop: 'treatmentItemCategories', customDefinition: true },
  { prop: 'treatmentItems' },
  // { prop: 'absenceSchedules', endpoint: services.crm.crud.absenceSchedule.all },
  {
    prop: 'boilerplateMessages',
    endpoint: services.crm.crud.boilerplateMessage.all,
  },
  {
    prop: 'smsAutoNotifications',
    endpoint: services.crm.crud.smsAutoNotification.all,
  },
  { prop: 'callerNumbers', endpoint: services.crm.crud.callerNumber.all },
  // { prop: 'counselors', endpoint: services.crm.consulting.counselor.all },
  { prop: 'counselors' },

  //ConsultingCodes에서 사용했음 > 상담조회 컬럼에서 사용했음
  //result > message-target-search-history 에서 storedata 사용
  { prop: 'results', endpoint: services.crm.consulting.result.all },
  { prop: 'doctors' },
  { prop: 'facialists' },
  // { prop: 'doctors', endpoint: services.crm.crud.doctor.all },

  //CustomerCodes / ModalCustomerFinder / datatable search field 에서 사용
  {
    prop: 'acquisitionChannels',
    endpoint: services.crm.customer.acquisitionChannel.all,
  },
  //CustomerCodes / datatable search field 에서 사용했음
  // { prop: 'jobs', endpoint: services.crm.customer.job.all },
  //levels, regions > message-target-search-history 에서 storedata 사용
  { prop: 'levels', endpoint: services.crm.customer.level.all },
  // { prop: 'complaints', endpoint: services.crm.customer.complaint.all },
  { prop: 'regions', endpoint: services.crm.customer.region.all },

  // CusotmerPayments / PaymentCodes에서 사용했음
  // { prop: 'discountReasons', endpoint: services.crm.payment.discountReason.all },

  // ModalPaymentBill / PaymentCodes에서 사용했음
  // { prop: 'refundReasons', endpoint: services.crm.payment.refundReason.all },

  //Products에서 사용했음
  // { prop: 'products', endpoint: services.crm.payment.product.all },

  // { prop: 'roles', endpoint: services.crm.user.role.all },
  // { prop: 'authorityGroups', endpoint: services.crm.user.authorityGroup.all },
  { prop: 'alimtalk', endpoint: services.crm.crud.alimtalk.all }, // prop 단수
  // { prop: 'surgeries', endpoint: services.crm.crud.surgery.all },
  {
    prop: 'treatmentCategories',
    endpoint: services.crm.treatment.categories.items_categories_v2,
  },
];

const decoratedProps = {};
models.forEach((model) => (decoratedProps[model.prop] = observable));
class Data {
  constructor() {
    models.forEach((model) => {
      this[model.prop] = undefined;
      if (model.customDefinition) return;

      this.actions[`load${toCapital(model.prop)}`] = () => {
        if (!model.endpoint) return;
        let params = { limit: 10000, visible: true };
        return model.endpoint(params).then((resp) => {
          if (!resp) return;

          this[model.prop] = resp.data;
          return Promise.resolve(resp);
        });
      };
    });

    this.getters = () => {
      const getters = {};
      models.forEach((model) => (getters[model.prop] = toJS(this[model.prop])));
      return getters;
    };

    makeObservable(this, decoratedProps);
  }

  actions = {
    loadTreatmentItemCategories: async () => {
      try {
        let params = { limit: 10000, visible: true };
        const resp = await services.crm.treatment.categories.items_categories_v2(
          params
        );
        if (!resp) return;

        this.treatmentItems = [];
        this.treatmentItemCategories = resp.data.filter((category) => {
          if (category.items.length > 0) {
            category.items.forEach((item) => {
              item.category = { id: category.id, name: category.name };
              this.treatmentItems.push(item);
            });
            return category;
          }
        });
        return Promise.resolve(resp);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    loadDepartmentCategories: async () => {
      try {
        let params = { limit: 300 };
        const resp = await services.crm.crud.departmentCategory.all(params);
        if (!resp) return;

        this.departments = [];
        this.departmentCategories = resp.data.map((category) => {
          category.departments.forEach((item) => {
            item.category = { id: category.id, name: category.name };
            this.departments.push(item);
          });
          return category;
        });
        return Promise.resolve(resp);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    loadUserSearchCounselors: async () => {
      try {
        let params = { duty: '상담사', userStatus: 'active', limit: 300 };
        const resp = await services.crm.user.duty(params);
        if (!resp) return;
        this.counselors = resp.data.map((conselors) => {
          const { id, name, email, updatedAt, createdAt } = conselors;
          return { id, name, email, updatedAt, createdAt };
        });
        return Promise.resolve(resp);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    loadUserSearchDoctors: async () => {
      try {
        let params = { duty: '의사', userStatus: 'active', limit: 300 };
        const resp = await services.crm.user.duty(params);
        if (!resp) return;
        this.doctors = resp.data.map((doctors) => {
          const { id, name, email, updatedAt, createdAt } = doctors;
          return { id, name, email, updatedAt, createdAt };
        });
        return Promise.resolve(resp);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    loadUserSearchFacialLists: async () => {
      try {
        let params = { duty: '피부관리사', userStatus: 'active', limit: 300 };
        const resp = await services.crm.user.duty(params);
        if (!resp) return;
        this.facialists = resp.data.map((facialists) => {
          const { id, name, email, updatedAt, createdAt } = facialists;
          return { id, name, email, updatedAt, createdAt };
        });
        return Promise.resolve(resp);
      } catch (e) {
        return Promise.reject(e);
      }
    },
  };

  loadOptions = () =>
    Object.keys(this.actions).forEach((key) => this.actions[key]());
}

export default new Data();
