//category 객체가 존재하지 않으면(undefined) 이미 수납된 데이터
//category 객체가 존재하면, 수납되지 않고 추가한 시수술
import { currency } from 'filters';

export default {
  keys: [
    {
      type: 'checkbox',
      column: '',
    },
    {
      title: '카테고리',
      column: 'categoryName',
      filter: (o) => {
        if (o.category === undefined) {
          return `<div style="position: relative; width: 125px; text-align: center;">
                <div class="flex-wrap"
                    style="max-width: 125px; white-space: break-spaces; display: inline-block;">${
                      o.categoryName || ''
                    }</div></div>`;
        } else {
          return `<div style="position: relative; width: 125px; text-align: center;">
                <div class="flex-wrap"
                    style="max-width: 125px; white-space: break-spaces; display: inline-block;">${
                      o.category.name || ''
                    }</div></div>`;
        }
      },
    },
    {
      title: '시/수술명',
      column: 'name',
      filter: (o) => {
        return `<div style="position: relative; width: 240px; text-align: center;">
            <div class="flex-wrap"
                style="max-width: 240px; white-space: break-spaces; display: inline-block;">${
                  o['name'] || ''
                }${
          o['FREE_SERVICE_$$checked'] || o['isFree']
            ? '<div class="flex-wrap ico-service"></div>'
            : ''
        }</div>
            </div>`;
      },
    },
    {
      title: '시/수술 횟수',
      column: 'treatmentCount',
      filter: (o) => {
        return `${o.treatmentCount}회`;
      },
    },
    {
      title: '진행한 회차',
      column: 'useCount',
      filter: (o) => {
        if (o.category === undefined) {
          return `${o.useCount}회`;
        } else {
          //새로 추가한 시수술이기때문에 useCount가 없음
          return '0회';
        }
      },
    },
    {
      title: '남은 회차',
      column: 'remainingCount',
      filter: (o) => {
        if (o.category === undefined) {
          return `${o.remainingCount}회`;
        } else {
          //새로 추가한 시수술이기때문에 진행회차=남은회차
          return `${o.treatmentCount}회`;
        }
      },
    },
    {
      title: 'NOT_VAT_INCLUDE',
      column: 'price',
      filter: (o) => {
        if (o.vatIncluded) {
          return `₩${currency(o.price)}`;
        } else {
          return `₩${currency(o.priceVatInclude)}`;
        }
      },
    },
    {
      title: 'PRICE_VAT_INCLUDE',
      column: 'priceVatInclude',
      filter: (o) => {
        return `₩${currency(o.priceVatInclude)}`;
      },
    },
    {
      column: '',
      filter: (o) => {
        if (o.isSelected === undefined) {
          return `<div class="action-delete"><i class="zmdi zmdi-minus" style="font-size: 12px;" ></i></div>`;
        }
      },
    },
  ],
};
