import { observable, computed, makeObservable } from 'mobx';
import moment from 'moment';

export class Appointment {
  constructor(appointment) {
    makeObservable(this, {
      counselorId: observable,
      counselorName: observable,
      customerBirthday: observable,
      customerId: observable,
      customerMemo: observable,
      customerName: observable,
      customerPhoneNumber: observable,
      consultingDoneAt: observable,
      consultingDuringAt: observable,
      consultingWaitAt: observable,
      doneAt: observable,
      leaveAt: observable,
      paymentDoneAt: observable,
      paymentDuringAt: observable,
      paymentWaitAt: observable,
      surgeryDoneAt: observable,
      surgeryDuringAt: observable,
      surgeryWaitAt: observable,
      treatmentDoneAt: observable,
      treatmentDuringAt: observable,
      treatmentWaitAt: observable,

      customerSex: observable,
      customerStatus: observable,
      departmentCategoryId: observable,
      departmentCategoryName: observable,
      departmentId: observable,
      departmentName: observable,
      doctorId: observable,
      doctorName: observable,
      endHour: observable,
      estimatedServiceMinutes: observable,
      facialistId: observable,
      facialistName: observable,
      favorCategories: observable,
      favorCategoryId: observable,
      favorCategoryName: observable,
      firstVisit: observable,
      id: observable,
      isNewCustomer: observable,
      items: observable,
      memo: observable,
      paymentTreatmentItemCategoryName: observable,
      paymentTreatmentItemId: observable,
      paymentTreatmentItemName: observable,
      paymentTreatmentItems: observable,
      scheduledAt: observable,
      startHour: observable,
      status: observable,
      treatmentItemCategoryId: observable,
      treatmentItemCategoryName: observable,
      treatmentItemId: observable,
      treatmentItemName: observable,
      type: observable,
      visited: observable,
      deletedAt: observable,

      minSpan: computed,
      date: computed,
      colorStatus: computed,
    });

    Object.assign(this, appointment);
  }
  counselorId = undefined;
  counselorName = undefined;
  customerBirthday = undefined;
  customerId = undefined;
  customerMemo = undefined;
  customerName = undefined;
  customerPhoneNumber = undefined;
  consultingDoneAt = undefined;
  consultingDuringAt = undefined;
  consultingWaitAt = undefined;
  doneAt = undefined;
  leaveAt = undefined;
  paymentDoneAt = undefined;
  paymentDuringAt = undefined;
  paymentWaitAt = undefined;
  surgeryDoneAt = undefined;
  surgeryDuringAt = undefined;
  surgeryWaitAt = undefined;
  treatmentDoneAt = undefined;
  treatmentDuringAt = undefined;
  treatmentWaitAt = undefined;

  customerSex = undefined;
  customerStatus = undefined;
  departmentCategoryId = undefined;
  departmentCategoryName = undefined;
  departmentId = undefined;
  departmentName = undefined;
  doctorId = undefined;
  doctorName = undefined;
  endHour = undefined;
  estimatedServiceMinutes = undefined;
  facialistId = undefined;
  facialistName = undefined;
  favorCategories = undefined;
  favorCategoryId = undefined;
  favorCategoryName = undefined;
  firstVisit = undefined;
  id = undefined;
  isNewCustomer = undefined;
  items = undefined;
  memo = undefined;
  paymentTreatmentItemCategoryName = undefined;
  paymentTreatmentItemId = undefined;
  paymentTreatmentItemName = undefined;
  paymentTreatmentItems = undefined;
  scheduledAt = undefined;
  startHour = undefined;
  status = undefined;
  treatmentItemCategoryId = undefined;
  treatmentItemCategoryName = undefined;
  treatmentItemId = undefined;
  treatmentItemName = undefined;
  type = undefined;
  visited = undefined;
  deletedAt = undefined;

  get minSpan() {
    const [startHours, startMins] = this.startHour.split(':');
    const [endHours, endMins] = this.endHour.split(':');

    return (
      Number(endHours) * 60 +
      Number(endMins) -
      (Number(startHours) * 60 + Number(startMins))
    );
  }

  get date() {
    return moment(this.scheduledAt).format('YYYY-MM-DD');
  }

  get colorStatus() {
    let scheduleAppointmentStatus = this.status;
    if (
      this.status === 'consulting_done' ||
      this.status === 'treatment_done' ||
      this.status === 'surgery_done' ||
      this.status === 'payment_done' ||
      this.status === 'consulting_request_done'
    ) {
      scheduleAppointmentStatus = 'complete';
    } else if (this.status === 'done') {
      scheduleAppointmentStatus = 'leave';
    }

    return scheduleAppointmentStatus;
  }
}
