import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import {
  AppointmentBoardSize,
  //AppointmentBoardState,
} from 'store/appointmentBoardState';
import { AppointmentBoardStateContext } from './AppointmentBoards';

const SizeSelectPanel = observer(() => {
  /**
   * @type {AppointmentBoardState}
   */
  const state = useContext(AppointmentBoardStateContext);

  const sizes = [
    { label: '80%', value: AppointmentBoardSize.small },
    { label: '100%', value: AppointmentBoardSize.normal },
    { label: '120%', value: AppointmentBoardSize.big },
    { label: '140%', value: AppointmentBoardSize.large },
    { label: '160%', value: AppointmentBoardSize.xlarge },
    { label: '180%', value: AppointmentBoardSize.xxlarge },
  ];

  return (
    <div className="date-functions size-select-panel">
      <span>캘린더 비율</span>
      <select
        value={state.cellSize}
        onChange={(e) => state.setSize(e.target.value)}
      >
        {sizes.map((s, i) => (
          <option key={i} value={s.value}>
            {s.label}
          </option>
        ))}
      </select>
    </div>
  );
});

export default SizeSelectPanel;
