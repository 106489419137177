import React, { useEffect, useState, useCallback } from 'react';
import { observer } from 'mobx-react';
import DataTableAlpha from 'components/data-table/DataTableAlpha';
import { $case, pluralize, translate } from 'filters';
import ListFilter from 'components/pages/crm/ListFilter';
import moment from 'moment';
import PropTypes from 'prop-types';
import services from 'services';
import { $qb } from 'scripts/querybuilder';
import { useEvent } from 'hooks/useEvent';
import { useModal } from 'hooks/useModal';
import { useToast } from 'hooks/useToast';
import { AxiosErrorCodes } from 'axiosErrorCodes';
import { useAuth } from 'store/auth';
import { useClinicCheck } from 'store/clinicCheck';

const List = ({
  api,
  renderFlag,
  model,
  modelName,
  onAction,
  // dom,
  orderBy,
  mergeColumn,
  onAllCheckAction,
  // onClickSendSms,
}) => {
  const auth = useAuth();
  const clinicCheck = useClinicCheck();
  const eventBus = useEvent();
  const modal = useModal();
  const toast = useToast();
  const defaultParams = $qb()
    .limit(20)
    .orderBy(orderBy || 'id desc');
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [params, setParams] = useState(
    modelName === 'customer'
      ? defaultParams
      : modelName === 'operations'
      ? defaultParams
          .customParam(
            'scheduledStartAt',
            moment(new Date().setDate(new Date().getDate() - 30)).format(
              'YYYY-MM-DD'
            )
          )
          .customParam(
            'scheduledEndAt',
            moment(new Date()).format('YYYY-MM-DD')
          )
      : defaultParams
          .customParam(
            'startAt',
            moment(new Date().setDate(new Date().getDate() - 30)).format(
              'YYYY-MM-DD'
            )
          )
          .customParam('endAt', moment(new Date()).format('YYYY-MM-DD'))
  );
  const [downloadBtnDisabledFlag, setDownloadBtnDisabledFlag] = useState(false);

  const callApi = async () => {
    try {
      if (
        clinicCheck.isClinicStantopCounselor &&
        (modelName === 'customer' || modelName === 'appointment')
      ) {
        params.customParam('counselorId', auth.me.id);
      }

      const resp = await api(params.build());
      if (!resp) return;
      // 페이지네이션시에도 체크박스가 유지되도록
      // (resp.data).forEach((data) => {
      //   if ((checkItems || []).find((v) => v.id === data.id) !== undefined) {
      //     data.$$checked = true;
      //   } else {
      //     data.$$checked = false;
      //   }
      //   return data;
      // });
      setData(resp.data);
      setTotal(resp.total || resp.pagination.total);
    } catch (e) {
      console.log(e.description);
    }
  };

  const excelDownload = (resp) => {
    if (resp !== undefined) {
      //리스폰스 받고 로딩팝업 닫은 후 내려받기 버튼 활성화
      modal.close({
        type: 'LOADING',
      });
      setDownloadBtnDisabledFlag(false);

      const url = window.URL.createObjectURL(new Blob([resp]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `${translate($case.toConst(pluralize(modelName)))}.xlsx`
      );
      document.body.appendChild(link);
      link.click();
    }
  };

  const onParams = (p) => {
    setParams(p);
    callApi();
  };

  useEffect(() => {
    eventBus.on('callApi', callApi);
    callApi();

    return () => eventBus.off('callApi');
  }, [eventBus, renderFlag]);

  const onClickExcelDownload = useCallback(async () => {
    try {
      setDownloadBtnDisabledFlag(true);
      modal.loading({
        type: 'LOADING',
        model: modelName,
        msg:
          '다운로드중입니다. 데이터 양에 따라 \n 수 초 ~ 수 분이 걸릴 수 있습니다.',
      });

      let resp;
      if (modelName === 'appointment') {
        resp = await services.crm.crud.appointment.excel_download_v2(
          params.build()
        );
        // 5000 건 이하인 경우
        if (resp.type.indexOf('sheet') !== -1) {
          excelDownload(resp);
        } else {
          const reader = new FileReader();
          reader.addEventListener('loadend', async (e) => {
            let res = undefined;
            res = await services.crm.crud[modelName].excel_check_download_v2({
              fileKey: JSON.parse(e.target.result).fileKey,
            });

            let startInterval = setInterval(async () => {
              res = await services.crm.crud[modelName].excel_check_download_v2({
                fileKey: JSON.parse(e.target.result).fileKey,
              });
              if (res.type.indexOf('sheet') !== -1) {
                excelDownload(res);
                clearInterval(startInterval);
              }
            }, 20000);
          });
          reader.readAsText(resp);
        }
      } else if (
        modelName === 'customer' ||
        modelName === 'consulting' ||
        modelName === 'surgery' ||
        modelName === 'treatment'
      ) {
        resp = await services.crm[modelName].excel_download_v2(params.build());

        // 5000 건 이하인 경우
        if (resp.type.indexOf('sheet') !== -1) {
          excelDownload(resp);
        } else {
          const reader = new FileReader();
          reader.addEventListener('loadend', async (e) => {
            let res;
            res = await services.crm[modelName].excel_check_download_v2({
              fileKey: JSON.parse(e.target.result).fileKey,
            });

            let startInterval = setInterval(async () => {
              res = await services.crm[modelName].excel_check_download_v2({
                fileKey: JSON.parse(e.target.result).fileKey,
              });
              if (res.type.indexOf('sheet') !== -1) {
                excelDownload(res);
                clearInterval(startInterval);
              }
            }, 20000);
          });
          reader.readAsText(resp);
        }
      } else {
        resp = await services.crm[modelName].excel_download(params.build());
        excelDownload(resp);
      }
    } catch (e) {
      modal.close({
        type: 'LOADING',
      });
      setDownloadBtnDisabledFlag(false);

      let critical = true;
      if (e.code === AxiosErrorCodes.aborted) {
        toast.error('다운로드가 취소되었습니다.');
        critical = false;
      } else {
        toast.error(
          '다운로드에 실패하였습니다.\n다시 시도하거나, cs@unobeauty.kr로 문의해주세요.'
        );
      }

      if (critical) {
        console.log('throw');
        throw e;
      }
    }
  }, [modelName, params]);

  return (
    <div className={`${pluralize(modelName)} list inquiry`}>
      <div className="page-navi">
        <span className="title">{translate('SEARCH')}</span>
        <span className="title">
          {translate($case.toConst(pluralize(modelName)))} ({total || 0})
        </span>
      </div>
      <div className="route-top">
        <ListFilter
          model={model}
          modelName={modelName}
          params={params}
          setParams={setParams}
          onParams={onParams}
          defaultParams={defaultParams}
        />
        <div className="display-table">
          <div className="flex-row">
            {modelName !== 'operations' &&
            modelName !== 'nurses' &&
            modelName !== 'skins' ? (
              <button
                className="btn btn-basic _small m-r-8 btn-excel"
                disabled={downloadBtnDisabledFlag}
                onClick={onClickExcelDownload}
              >
                Excel 내려받기
              </button>
            ) : null}
            {/* <button
              className="btn btn-basic _small"
              onClick={onClickSendSms}>문자 전송 </button> */}
          </div>
        </div>
      </div>

      <DataTableAlpha
        model={model}
        data={data}
        total={total}
        params={params}
        onParams={onParams}
        onAction={onAction}
        mergeColumn={mergeColumn}
        onAllCheckAction={onAllCheckAction}
        hasLine
        useExcelDownload={true}
        onClickExcelDownload={onClickExcelDownload}
      />
    </div>
  );
};

List.propTypes = {
  api: PropTypes.func,
  renderFlag: PropTypes.bool,
  model: PropTypes.object,
  modelName: PropTypes.string,
  onAction: PropTypes.func,
  // dom,
  orderBy: PropTypes.string,
  mergeColumn: PropTypes.array,
  onAllCheckAction: PropTypes.func,
  // onClickSendSms: PropTypes.func,
  checkItems: PropTypes.array,
};

export default observer(List);
