export default {
  keys: [
    {
      title: 'STATUS',
      noorder: true,
      filter: () => `
            <div class="text-center">
                <button class="btn btn-sm btn-primary">수정</button>
                <button class="btn btn-sm btn-danger">삭제</button>
            </div>`,
    },
    {
      title: '진료일',
      column: 'scheduledAt',
      type: 'date',
    },
    {
      title: '진료내용',
      column: 'memo',
      noorder: true,
      type: 'memo',
      thStyle: () => {
        return { width: '240px' };
      },
    },
    {
      title: '의사',
      column: 'doctor__name',
      noorder: true,
    },
    {
      title: '카테고리',
      noorder: true,
      type: 'list_mini',
      filter: (o) =>
        `${o.items
          .map((item) => `<p>${(item.category || {}).name || ''}</p>`)
          .join('\n')}</div>`,
    },
    {
      title: '시/수술명',
      noorder: true,
      type: 'list',
      filter: (o) =>
        `<div>${o.items
          .map((item) => `<p class="text-center">${item.name}</p>`)
          .join('\n')}</div>`,
    },
    // {
    //     title: 'UPDATE_AT',
    //     column: 'updatedAt',
    //     filter: o => {
    //         return `<div>
    //             <div>${moment(o.updatedAt).format('YYYY-MM-DD')}</div>
    //             <div>${moment(o.updatedAt).format('HH:mm:ss')}</div>
    //         </div>`
    //     },
    // }
  ],
};
