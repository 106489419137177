import moment from 'moment';
import { formatCallerNumber, translate } from 'filters';
// const serverTimezoneDateFormat = "YYYY-MM-DDTHH:mm:ssZ"

export default {
  keys: [
    {
      title: '설정',
      noorder: true,
      filter: (o) => {
        //전송일의 10분전까지만 취소 가능
        const today = new Date();
        const timeValue = new Date(moment(o.scheduledAt));
        return `<button class="btn btn-sm btn-white-red m-r-4"
      ${
        Math.floor((today.getTime() - timeValue.getTime()) / 1000 / 60) >= -10
          ? 'disabled'
          : null
      }
      data-type="delete" >${translate(
        'MESSAGE_RESULT_STATUS_CANCELED'
      )}</button>`;
      },
    },
    {
      title: 'TITLE_CREATED_AT',
      column: 'createdAt',
      filter: (o) => {
        // if( !moment(o.createdAt, serverTimezoneDateFormat, true).isValid()){
        //   return o.createdAt
        // }
        return `<div>
          <div>${moment(o.createdAt).format('YYYY-MM-DD')}</div>
          <div style="text-align:center;">${moment(o.createdAt).format(
            'HH:mm:ss'
          )}</div>
        </div>`;
      },
    },
    {
      title: 'MESSAGE_SCHEDULED_AT',
      column: 'scheduledAt',
      filter: (o) => {
        // if( !moment(o.scheduledAt, serverTimezoneDateFormat, true).isValid()){
        //   return o.scheduledAt
        // }
        return `<div>
      <div>${moment(o.scheduledAt).format('YYYY-MM-DD')}</div>
      <div style="text-align:center;">${moment(o.scheduledAt).format(
        'HH:mm:ss'
      )}</div>
    </div>`;
      },
    },
    {
      title: '고객명',
      noorder: true,
      filter: (o) => {
        if (o.totalCount > 1) {
          return `<div class="contents">
                    <div style="line-height:2.4;"><u>${o.name}</u></div>
                 </div>`;
        } else {
          return `<div class="contents">
                    ${o.name}</div>
                 </div>`;
        }
      },
    },
    {
      title: '휴대폰번호',
      noorder: true,
      filter: (o) => formatCallerNumber(o.phoneNumber),
    },
    {
      title: 'TITLE_AND_CONTENT',
      noorder: true,
      type: 'text',
      class: 'title-and-content',
      filter: (o) => {
        if (o.title === '' || !o.title) {
          return `<div class="contents">
                    <div style="line-height:3.2; overflow: hidden;
                    text-overflow: ellipsis"><u >${o.contents || ''}</u></div>
                 </div>`;
        }
        return `<div class="title">
                  <div><u>${o.title || ''}</u></div>
               </div>
               <div class="contents">
                    <div style=" overflow: hidden;
                    text-overflow: ellipsis"><u >${o.contents || ''}</u></div>
                 </div>`;
      },
    },
    {
      title: 'IMAGE',
      noorder: true,
      type: 'image',
      class: 'image-preview',
      filter: (o) => {
        if (!o.imageUrl) return;
        return o.imageUrl;
      },
      // },
      // {
      //   title: 'NUMBER_OF_REQUESTS',
      //   column: 'totalCount',
      // },
      // {
      //   title: 'MESSAGE_EXPECTED_AMOUNT',
      //   column: 'expectedAmount',
      //   filter: (o) => {
      //     const expectedAmount = o.expectedAmount ? o.expectedAmount : 0;
      //     return `${currencySymbol()} ${currency(expectedAmount)}`;
      //   },
    },
  ],
};
