import './image-editor.scss';
import React, { createContext, useRef, useContext, useEffect } from 'react';
import { Stage, Layer, Line, Image } from 'react-konva';
import useImage from 'use-image';
import ImageEditorToolbar from './ImageEditorToolbar';
import AttachedImage from './AttachedImage';
import { stateStore, uiStore } from './imageEditorStore';
import { observer } from 'mobx-react';
import TextItem from './TextItem';
import EditTextField from './EditTextField';
import { onPatch } from 'mobx-state-tree';
import ImageEditorOverlayToolbar from './ImageEditorOverlayToolbar';
import PropTypes from 'prop-types';

export const ImageEditorContext = createContext({});

function ImageEditor({ url, onSave, onDownloadFile }) {
  const { state, ui } = useContext(ImageEditorContext);

  const editTextRef = useRef();

  useEffect(() => {
    onPatch(state, (patch, reverse) => {
      if (!state.isIgnoringPatch()) {
        state.addPatch(patch, reverse);
      }
    });
  }, [state]);

  const frameWidth = window.innerWidth - 240;
  const frameHeight = window.innerHeight - 120;

  const objectId = useRef({ id: 0 });
  const stageRef = useRef();

  const [baseImage] = useImage(url, 'Anonymous');

  useEffect(() => {
    if (baseImage) {
      const zoomX = Math.max(1, baseImage.width / frameWidth);
      const zoomY = Math.max(1, baseImage.height / frameHeight);
      const zoomRatio = Math.max(zoomX, zoomY);

      ui.setZoom(1 / zoomRatio);
      ui.setStageSize(
        baseImage.width / zoomRatio,
        baseImage.height / zoomRatio
      );
      ui.setPixelRatio(zoomRatio);
    }
  }, [baseImage, frameHeight, frameWidth, ui]);

  function onExport() {
    ui.setZoom(1 / ui.pixelRatio);
    ui.setStagePosition(0, 0);
    ui.setSelected(0);

    saveText();

    setTimeout(() => {
      const stage = stageRef.current.getStage();
      const uri = stage.toDataURL({
        mimeType: 'image/jpeg',
        pixelRatio: ui.pixelRatio, // or other value you need
      });

      onSave(uri);
    });
  }

  function onDownload() {
    ui.setZoom(1 / ui.pixelRatio);
    ui.setStagePosition(0, 0);

    saveText();

    setTimeout(() => {
      const stage = stageRef.current.getStage();
      const uri = stage.toDataURL({
        mimeType: 'image/jpeg',
        pixelRatio: ui.pixelRatio, // or other value you need
      });

      onDownloadFile(uri);
    });
  }

  function genId() {
    objectId.current.id++;
    return objectId.current.id;
  }

  function getInnerText(el) {
    var sel,
      range,
      innerText = '';
    if (
      typeof document.selection != 'undefined' &&
      typeof document.body.createTextRange != 'undefined'
    ) {
      range = document.body.createTextRange();
      range.moveToElementText(el);
      innerText = range.text;
    } else if (
      typeof window.getSelection != 'undefined' &&
      typeof document.createRange != 'undefined'
    ) {
      sel = window.getSelection();
      sel.selectAllChildren(el);
      innerText = '' + sel;
      sel.removeAllRanges();
    }
    return innerText;
  }

  function saveText() {
    if (!ui.editText) return;

    const found = state.texts.find((t) => t.id === ui.editText.id);

    if (found) {
      found.changeText(ui.editTextValue, getInnerText(editTextRef.current));
      found.update({
        fontSize: ui.editText.props.fontSize,
        fill: ui.editText.props.fill,
      });
      found.updateSize(editTextRef.current.getBoundingClientRect().width);
    } else {
      if (ui.editTextValue.trim().length > 0) {
        state.addText({
          id: genId(),
          htmlText: ui.editTextValue,
          text: getInnerText(editTextRef.current),
          width: editTextRef.current.getBoundingClientRect().width,
          x: ui.editText.props.x,
          y: ui.editText.props.y,
          fontSize: ui.editText.props.fontSize,
          fill: ui.editText.props.fill,
        });
      }
    }

    ui.setEditText(null);
    ui.setEditTextValue('');
  }

  function onChangeTool(tool) {
    ui.selectTool(tool);

    if (ui.editText) {
      saveText();
    }
  }

  const handleClick = (e) => {
    const stage = e.target.getStage();
    const pos = getMousePos(e);
    const screenPos = stage.getPointerPosition();

    if (ui.editText) {
      saveText();
      ui.selectTool('select');
      return;
    }

    if (ui.tool === 'text') {
      ui.setEditText({
        autoResize: true,
        props: {
          width: 200,
          height: 60,
          x: pos.x,
          y: pos.y,
          fontSize: ui.textOption.size,
          fill: ui.textOption.color,
        },
        style: {
          top: screenPos.y,
          left: screenPos.x,
          fontSize: ui.textOption.size,
          color: ui.textOption.color,
        },
      });
    }

    if (ui.tool === 'select') {
      ui.setSelected(0);
    }
  };

  const handleMouseDown = (e) => {
    const stage = e.target.getStage();
    const pos = getMousePos(e);

    if (['pen', 'marker', 'eraser'].includes(ui.tool)) {
      ui.setDraw(true);
      let color;
      let size;
      switch (ui.tool) {
        case 'pen':
          color = ui.penOption.color;
          size = ui.penOption.size;
          break;
        case 'marker':
          color = ui.markerOption.color;
          size = ui.markerOption.size;
          break;
        case 'eraser':
          size = ui.eraserOption.size;
          color = '#fff';
          break;
        default:
          throw new Error('invalid case');
      }

      state.addLine({
        type: ui.tool,
        points: [pos.x, pos.y],
        stroke: color,
        strokeWidth: size,
      });
    }

    if (ui.tool === 'hand') {
      ui.setMove(true);
      ui.setMovingAnchor(stage.getPointerPosition());
    }
  };

  const handleMouseMove = (e) => {
    const stage = e.target.getStage();
    // no drawing - skipping
    if (ui.isDrawing && ['pen', 'marker', 'eraser'].includes(ui.tool)) {
      const point = getMousePos(e);
      let lastLine = state.lines[state.lines.length - 1];

      lastLine.append([Number(point.x), Number(point.y)]);
    }

    if (ui.isMoving && ui.tool === 'hand') {
      const maxX = (ui.zoom * ui.pixelRatio - 1) * ui.stageSize.x;
      const maxY = (ui.zoom * ui.pixelRatio - 1) * ui.stageSize.y;

      const pos = stage.getPointerPosition();
      const diff = {
        x: pos.x - ui.movingAnchor.x,
        y: pos.y - ui.movingAnchor.y,
      };

      ui.setMovingAnchor(pos);
      ui.setStagePosition(
        Math.max(-maxX, Math.min(0, ui.stagePosition.x + diff.x)),
        Math.max(-maxY, Math.min(0, ui.stagePosition.y + diff.y))
      );
    }
  };

  const handleMouseUp = () => {
    ui.setDraw(false);
    ui.setMove(false);
  };

  const scaleBy = 1.02;

  function getMousePos(e) {
    const stage = e.target.getStage();
    return ui.toOverlayPos(stage.getPointerPosition());
  }

  function onWheel(e) {
    ui.setDraw(false);
    ui.setMove(false);

    e.evt.preventDefault();
    if (ui.tool === 'select' || ui.tool === 'hand') {
      const stage = e.target.getStage();

      ui.zoomTo(
        stage,
        e.evt.deltaY < 0 ? scaleBy : 1 / scaleBy,
        stage.getPointerPosition()
      );
    }

    if (ui.tool === 'pen') {
      ui.setPenOption({
        ...ui.penOption,
        size: Math.max(1, ui.penOption.size + Math.sign(e.evt.deltaY) * 1),
      });
    }

    if (ui.tool === 'marker') {
      ui.setMarkerOption({
        ...ui.markerOption,
        size: Math.max(1, ui.markerOption.size + Math.sign(e.evt.deltaY) * 1),
      });
    }

    if (ui.tool === 'eraser') {
      ui.setEraserOption({
        ...ui.eraserOption,
        size: Math.max(1, ui.eraserOption.size + Math.sign(e.evt.deltaY) * 1),
      });
    }
  }

  function onFileUpload(url) {
    state.addImage({
      id: genId(),
      url: url,
      x: 0,
      y: 0,
    });
  }

  function onKeyDown(e) {
    if (e.keyCode === 46) {
      if (ui.selected !== 0) {
        state.removeText(ui.selected);
        state.removeImage(ui.selected);
        ui.setSelected(0);
      }
    }
  }

  return (
    <div className="image-editor">
      <div className="image-editor-canvas">
        <div
          className="image-editor-stage-wrapper"
          tabIndex={1}
          onKeyDown={onKeyDown}
        >
          <Stage
            ref={stageRef}
            width={ui.stageSize.x}
            height={ui.stageSize.y}
            onClick={handleClick}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            onMouseLeave={handleMouseUp}
            // scale={{ x: ui.zoom, y: ui.zoom }}
            onWheel={onWheel}
            // x={ui.stagePosition.x}
            // y={ui.stagePosition.y}
          >
            <ImageEditorContext.Provider
              value={{
                state,
                ui,
                stage: stageRef,
              }}
            >
              <Layer
                scale={{ x: ui.zoom, y: ui.zoom }}
                x={ui.stagePosition.x}
                y={ui.stagePosition.y}
              >
                <Image image={baseImage} />
              </Layer>
              <Layer
                scale={{ x: ui.zoom, y: ui.zoom }}
                x={ui.overlayPosition.x}
                y={ui.overlayPosition.y}
              >
                {state.images.map((image) => (
                  <AttachedImage key={image.id} image={image} />
                ))}
              </Layer>
              <Layer
                scale={{ x: ui.overlayZoom, y: ui.overlayZoom }}
                x={ui.overlayPosition.x}
                y={ui.overlayPosition.y}
              >
                {state.texts.map((t) => (
                  <TextItem key={t.id} text={t} />
                ))}
              </Layer>
              <Layer
                scale={{ x: ui.overlayZoom, y: ui.overlayZoom }}
                x={ui.overlayPosition.x}
                y={ui.overlayPosition.y}
              >
                {state.lines.map((line, i) => {
                  return (
                    <Line
                      key={i}
                      points={line.points}
                      stroke={line.type === 'eraser' ? '#fff' : line.stroke}
                      opacity={line.type === 'marker' ? 0.5 : 1}
                      strokeWidth={line.strokeWidth}
                      tension={0.5}
                      lineCap="round"
                      globalCompositeOperation={
                        line.type === 'eraser'
                          ? 'destination-out'
                          : 'source-over'
                      }
                    />
                  );
                })}
              </Layer>
            </ImageEditorContext.Provider>
          </Stage>
          {ui.editText && (
            <EditTextField innerRef={editTextRef} onSave={saveText} />
          )}
          <div className="image-editor-overlay-toolbar">
            <ImageEditorOverlayToolbar stageRef={stageRef} />
          </div>
        </div>
      </div>
      <div className="image-editor-toolbar">
        <ImageEditorToolbar
          onFileUpload={onFileUpload}
          onExport={onExport}
          onDownload={onDownload}
          onChangeTool={onChangeTool}
        />
      </div>
    </div>
  );
}

ImageEditor.propTypes = {
  url: PropTypes.string,
  onSave: PropTypes.func,
  onDownloadFile: PropTypes.func,
};

const Observed = observer(ImageEditor);

export default function WrappedApp({ ...props }) {
  return (
    <ImageEditorContext.Provider
      value={{
        state: stateStore.create({}),
        ui: uiStore.create({}),
        editText: false,
      }}
    >
      <Observed {...props} />
    </ImageEditorContext.Provider>
  );
}
