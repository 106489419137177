import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import { FiMinus, FiPlus } from 'react-icons/fi';
import { ImageEditorContext } from './ImageEditor';
import PropTypes from 'prop-types';

function OverlayItem({ children, onClick }) {
  return (
    <div className="image-editor-overlay-item" onClick={onClick}>
      {children}
    </div>
  );
}

OverlayItem.propTypes = {
  children: PropTypes.object,
  onClick: PropTypes.func,
};

export default observer(function ImageEditorOverlayToolbar({ stageRef }) {
  // eslint-disable-next-line no-unused-vars
  const { state, ui } = useContext(ImageEditorContext);

  function zoom(scale) {
    const stage = stageRef.current.getStage();
    ui.zoomTo(stage, scale, ui.getScreenCenter());
  }

  return (
    <div className="image-editor-overlay-toolbar">
      <OverlayItem onClick={() => zoom(1.4)}>
        <FiPlus />
      </OverlayItem>
      <OverlayItem onClick={() => zoom(1 / 1.4)}>
        <FiMinus />
      </OverlayItem>
    </div>
  );
});
