import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { objectToFormData } from 'object-to-formdata';
import PenChartsDrive from 'components/pages/crm/PenChartsDrive';
import PropTypes from 'prop-types';
import { translate } from 'filters';
import { useModal } from 'hooks/useModal';
import { useServices } from 'hooks/useServices';
import { useToast } from 'hooks/useToast';

const ModalPenChartSample = ({ options, close }) => {
  const modal = useModal();
  const services = useServices();
  const toast = useToast();
  const [selectArr, setSelectArr] = useState([]);

  const onClickCopy = (openType) => {
    const onConfirm = async (id) => {
      let object = {
        customerId: options.customer.id,
        targetId: id,
      };

      if (options.currentFolder !== null) {
        object.parentId = options.currentFolder;
      }

      try {
        await services.crm.penchart.copy_v2(objectToFormData(object));
        toast.success('업로드 하였습니다.');
        options.setNeedUpdate({ type: 'COPY' });
      } catch (error) {
        toast.error(error.description);
        close();
        return;
      }
    };

    if (openType !== 'chart_sample') {
      modal
        .basic({
          body: `선택한 ${selectArr.length}개의 항목을 업로드 하시겠습니까?`,
          buttons: [
            {
              text: 'CANCEL',
              class: 'btn-default',
            },
            {
              text: 'CONFIRM',
              class: 'btn-primary',
            },
          ],
        })
        .then((idx) => {
          // idx === 1 >> 업로드 확인버튼 클릭
          if (idx) {
            selectArr.map((v) => {
              onConfirm(v);
            });
            close();
          }
        });
    } else {
      selectArr.map((v) => {
        onConfirm(v);
      });
      close();
    }
  };

  return (
    <div className="modal-pen-chart-sample">
      <div className="head flex-row flex-between items-start">
        <div className="title">펜차트 샘플함</div>
        <i onClick={() => close()} className="zmdi zmdi-close" />
      </div>

      {/* can read and select */}
      <div
        className="pen-chart-contents"
        style={{
          marginTop: '30px',
          height: '645px',
          overflow: 'scroll',
        }}
      >
        <PenChartsDrive
          customer={{ id: 'default' }}
          shareSelectArr={setSelectArr}
          readOnly
          openType={'chart_sample'}
          setDoubleClick={() => {
            onClickCopy('chart_sample');
          }}
        />
      </div>
      <div className="buttons">
        <div className="flex-row">
          <button
            onClick={() => close()}
            className="btn btn-default _small"
            style={{ display: 'block' }}
          >
            {translate('CANCEL')}
          </button>
          <button
            onClick={onClickCopy}
            className="btn btn-primary _small"
            style={{ display: 'block' }}
            disabled={selectArr.length === 0 ? true : false}
          >
            업로드
          </button>
        </div>
      </div>
    </div>
  );
};

ModalPenChartSample.propTypes = {
  options: PropTypes.object,
  close: PropTypes.func,
};

export default observer(ModalPenChartSample);
