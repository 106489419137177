import React, { useCallback, useState, useEffect, useRef } from 'react';
import { observer } from 'mobx-react';
import { ContextMenu, MenuItem, ContextMenuTrigger } from 'react-contextmenu';
import printJS from 'print-js';

import ModalPenChartsUpdate from 'components/modals/ModalPenChartsUpdate';
import ModalPenChartsCreate from 'components/modals/ModalPenChartsCreate';
import ModalPenChartsDelete from 'components/modals/ModalPenChartsDelete';
import ModalPenChartSample from 'components/modals/ModalPenChartSample';
import BeforeAndAfterViewer from 'components/pages/crm/BeforeAndAfterViewer';
import { Tooltip } from 'antd';

import 'assets/styles/components/penchart.scss';
import PenChartHoverCard from './PenChartHoverCard';
import PropTypes from 'prop-types';
import penchart from 'store/penchart';
import { useModal } from 'hooks/useModal';
import { useServices } from 'hooks/useServices';
import { useToast } from 'hooks/useToast';
import styled from 'styled-components';
import searchIcon from 'assets/images/icon/ic-search.png';

const Input = styled.input`
  width: 280px !important;
  height: 46px !important;
  background: #fff !important;
  padding: 8px 15px 8px 50px !important;
  margin-right: 10px !important;
  border-color: #dee2ec;
  background-color: #fff;
  background-image: url(${searchIcon}) !important;
  background-size: 19px !important;
  background-repeat: no-repeat !important;
  background-position: left 15px center !important;
  border: solid 1px #efeff4 !important;
`;

// * customer
// 샘플함은 {id: default}
// 고객별은 customer data 넘기면 됨

// * isSample일때, (펜차트 샘플함 /crm/chart-sample)
// * readOnly일때, (고객 조회 > 팹차트 > 차트 샘플함 팝업)
// 오른쪽 클릭 이벤트 블락

const PenChartsDrive = ({
  customer,
  shareSelectArr,
  isSample,
  readOnly,
  openType,
  setDoubleClick,
  scrollY,
  height,
}) => {
  const modal = useModal();
  const services = useServices();
  const toast = useToast();
  const contextRef = useRef(null);
  const [needUpdate, setNeedUpdate] = useState(false);
  const [currentFolder, setCurrentFolder] = useState(null);
  const [historyFolder, setHistoryFolder] = useState([]);
  const [fileArr, setFileArr] = useState([]);
  const [selectArr, setSelectArr] = useState([]);
  const [printLoading, setPrintLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  // const [loadingImgIndex, setLoadingImgIndex] = useState(0);
  const [imgArr, setImgArr] = useState([]);

  const [searchText, setSearchText] = useState('');
  const [searchSort, setSearchSort] = useState('createdAt desc');

  const [selectBeforeArr, setSelectBeforeArr] = useState([]);
  const [selectAfterArr, setSelectAfterArr] = useState([]);

  const [apiCallFlag, setApiCallFlag] = useState(false);
  const [pagination, setPagination] = useState({});

  useEffect(() => {
    const pageApiCall = () => {
      if (
        height > 0 &&
        height - scrollY < 500 &&
        !apiCallFlag &&
        pagination.has_next
      ) {
        setApiCallFlag(true);
        pagingApiCall();
      }
    };
    pageApiCall();
  }, [scrollY, height]);

  const pagingApiCall = useCallback(async () => {
    if (!customer.id) return;
    const searchCriteria = { orderBy: searchSort };

    let tishPagenation;

    setLoading(true);
    try {
      let resp;
      if (customer.id === 'default') {
        // 샘플함
        await services.crm.penchart
          .all_v2({
            type: 'file',
            parentId: currentFolder,
            limit: 200,
            page: pagination.current_page + 1,
            ...searchCriteria,
          })
          .then((e) => {
            resp = e;
            setPagination(e.pagination);
            tishPagenation = e.pagination;
          });
      } else {
        await services.crm.penchart
          .all_v2({
            customerId: customer.id,
            type: 'file',
            parentId: currentFolder,
            limit: 200,
            page: pagination.current_page + 1,
            ...searchCriteria,
          })
          .then((e) => {
            resp = e;
            setPagination(e.pagination);
            tishPagenation = e.pagination;
          });
      }

      let blobImg = await resp.data.map(async (v) => {
        if (!v.url) return;
        let blobData = await services.crm.penchart.getImgThumb_v2(v.id);
        var imageUrl = (window.URL || window.webkitURL).createObjectURL(
          blobData
        );
        return { url: imageUrl, id: v.id };
      });

      Promise.allSettled(blobImg).then((results) => {
        let copyImgArr = [];
        setFileArr(fileArr.concat(resp.data));
        results.forEach((result, i) => {
          if (result.status === 'fulfilled') {
            if (!result.value) return;
            copyImgArr.push(result.value);
            setImgArr([...imgArr, ...copyImgArr]);
          }

          if (result.status === 'rejected') {
            copyImgArr.push({ url: 'error', id: resp.data[i].id });
          }
        });

        if (tishPagenation.has_next) {
          setApiCallFlag(false);
        }
        setLoading(false);
      });
    } catch (e) {
      setLoading(false);
    }

    // 초기화
    //initState();
  }, [pagination, imgArr]);

  useEffect(() => {
    if (printLoading === true) {
      modal.loading({
        type: 'LOADING',
        msg: '프린트 준비중입니다.',
      });
    } else {
      modal.close({
        type: 'LOADING',
      });
    }
  }, [printLoading]);

  const updateThumbnail = useCallback(
    async (id) => {
      let blobData = await services.crm.penchart.getImgThumb_v2(id);
      var imageUrl = (window.URL || window.webkitURL).createObjectURL(blobData);
      const imageData = { url: imageUrl, id: id };

      setImgArr(imgArr.map((img) => (img.id === id ? imageData : img)));

      //이미지 변경 후 업데이트 시간, 업데이트한 유저 정보 가져오기
      let updateInfo = await services.crm.penchart.get_v2(id);
      let updateFileArr = fileArr.map((f) => {
        if (f.id === updateInfo.data.id) {
          return {
            ...f,
            ...updateInfo.data,
          };
        } else {
          return f;
        }
      });
      setFileArr([...updateFileArr]);
    },
    [imgArr]
  );

  const onImageChanged = useCallback(
    (id) => {
      updateThumbnail(Number(id));
    },
    [updateThumbnail]
  );

  const img_new_tab = useCallback(async () => {
    if (!fileArr[selectArr]) return;
    let options = fileArr[selectArr[0]];
    var win = window.open(`/image/${options.id}`);
    win.onSaveCallback = onImageChanged;
    win.sessionStorage.setItem(
      'authToken',
      sessionStorage.getItem('authToken')
    );
  }, [selectArr]);

  const download = async () => {
    const file = fileArr[selectArr[0]];

    let blobData, imageUrl;
    blobData = await services.crm.penchart.getImg_v2(file.id, 0);
    imageUrl = (window.URL || window.webkitURL).createObjectURL(blobData);

    function onDownloadFile(dataUri) {
      let link = document.createElement('a');
      link.download = `${file.name.split('.')[0]}.png`;
      link.href = dataUri;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }

    onDownloadFile(imageUrl);
  };

  const printImg = async () => {
    setPrintLoading(true);
    let blobImgArr = await selectArr.map(async (v) => {
      if (fileArr[v].type === 'file') {
        let blobData, imageUrl;
        blobData = await services.crm.penchart.getImg_v2(fileArr[v].id, 0);
        imageUrl = (window.URL || window.webkitURL).createObjectURL(blobData);
        return imageUrl;
      }
    });

    await Promise.allSettled(blobImgArr).then((results) => {
      let copyPrintImgArr = [];
      results.forEach((result) => {
        if (result.status === 'fulfilled') {
          if (!result.value) return;
          copyPrintImgArr.push(result.value);
        }
      });

      if (copyPrintImgArr.length > 0) {
        printJS({
          printable: copyPrintImgArr.filter((v) => v),
          type: 'image',
        });
      } else {
        toast.error('프린트 할 수 있는 사진이 없습니다. 재시도 해주세요.');
      }
      setPrintLoading(false);
    });
  };

  const openDepositModal = (type) => {
    let component = null;
    switch (type) {
      case 'CREATE_FOLDER':
        component = ModalPenChartsCreate;
        break;
      case 'CREATE_FILE':
        component = ModalPenChartsCreate;
        break;
      case 'EDIT_NAME':
        component = ModalPenChartsUpdate;
        break;
      case 'DELETE':
        component = ModalPenChartsDelete;
        break;
      case 'IMAGE':
        img_new_tab();
        return;

      case 'GET_SAMPLE':
        component = ModalPenChartSample;
        break;
      default:
        break;
    }

    modal
      .custom({
        component: component,
        options: {
          setNeedUpdate: setNeedUpdate,
          customer: customer,
          currentFolder: currentFolder,
          fileArr: fileArr,
          selectArr: selectArr,
          target: fileArr[selectArr[0]],
          type: type,
        },
      })
      .then(() => {
        try {
          // if (!payload) return
        } catch (error) {
          toast.error(error.description);
        }
      });
  };

  // get folders & files
  const init = useCallback(
    async (searchCriteria) => {
      if (!customer.id) return;
      if (!searchCriteria) searchCriteria = { orderBy: searchSort };

      setLoading(true);
      try {
        let folderResp;
        let resp;
        if (customer.id === 'default') {
          // 샘플함
          resp = await services.crm.penchart.all_v2({
            type: 'file',
            parentId: currentFolder,
            limit: 200,
            page: 1,
            ...searchCriteria,
          });
          folderResp = await services.crm.penchart.all_v2({
            type: 'folder',
            parentId: currentFolder,
            limit: 200,
            page: 1,
            ...searchCriteria,
          });
        } else {
          resp = await services.crm.penchart.all_v2({
            customerId: customer.id,
            type: 'file',
            parentId: currentFolder,
            limit: 200,
            page: 1,
            ...searchCriteria,
          });

          folderResp = await services.crm.penchart.all_v2({
            customerId: customer.id,
            type: 'folder',
            parentId: currentFolder,
            limit: 200,
            page: 1,
            ...searchCriteria,
          });
        }

        if (folderResp.data) {
          setFileArr(folderResp.data);
        }

        let blobImg = await resp.data.map(async (v) => {
          if (!v.url) return;
          let blobData = await services.crm.penchart.getImgThumb_v2(v.id);
          var imageUrl = (window.URL || window.webkitURL).createObjectURL(
            blobData
          );
          return { url: imageUrl, id: v.id };
        });

        Promise.allSettled(blobImg).then((results) => {
          let copyImgArr = [];

          setFileArr(resp.data.concat(folderResp.data));

          results.forEach((result, i) => {
            if (result.status === 'fulfilled') {
              if (!result.value) return;
              copyImgArr.push(result.value);
              setImgArr([...copyImgArr]);
            }
            if (result.status === 'rejected') {
              copyImgArr.push({ url: 'error', id: resp.data[i].id });
            }
          });
          setPagination(resp.pagination);
          setLoading(false);
        });
      } catch (e) {
        setLoading(false);
      }

      // 초기화
      initState();
    },
    [currentFolder]
  );

  const initState = () => {
    setSelectArr([]);
    setNeedUpdate(false);
  };

  const toggleSelectBeforeAndAfter = (e, type) => {
    if (!type) return;

    if (type === 'after') {
      selectBeforeFile(e, 'remove');
      selectAfterFile(e);
    }

    if (type === 'before') {
      selectAfterFile(e, 'remove');
      selectBeforeFile(e);
    }
  };

  const selectBeforeFile = useCallback(
    (e, action) => {
      let targetIndex = e.target.closest('li').dataset.index;
      let fileInfo = fileArr[targetIndex];
      let overlapIndex = selectBeforeArr.findIndex((v) => v.id == fileInfo.id);

      if (overlapIndex > -1 || action == 'remove') {
        if (overlapIndex === -1) return;
        let copyArr = [...selectBeforeArr];
        copyArr.splice(overlapIndex, 1);
        setSelectBeforeArr(copyArr);
      } else {
        setSelectBeforeArr([
          ...selectBeforeArr,
          { id: fileInfo.id, name: fileInfo.name, type: fileInfo.type },
        ]);
      }
    },
    [selectBeforeArr, fileArr]
  );

  const selectAfterFile = useCallback(
    (e, action) => {
      let targetIndex = e.target.closest('li').dataset.index;
      let fileInfo = fileArr[targetIndex];
      let overlapIndex = selectAfterArr.findIndex((v) => v.id == fileInfo.id);

      if (overlapIndex > -1 || action == 'remove') {
        if (overlapIndex === -1) return;
        let copyArr = [...selectAfterArr];
        copyArr.splice(overlapIndex, 1);
        setSelectAfterArr(copyArr);
      } else {
        setSelectAfterArr([
          ...selectAfterArr,
          { id: fileInfo.id, name: fileInfo.name, type: fileInfo.type },
        ]);
      }
    },
    [selectAfterArr, fileArr]
  );

  // .react-contextmenu-wrapper click, onContextMenu event listener
  const checkTarget = (e) => {
    if (
      e.target.parentElement.parentElement.tagName === 'LI' ||
      e.target.parentElement.tagName === 'LI' ||
      e.target.tagName === 'LI'
    ) {
      let targetIndex =
        e.target.dataset.index ||
        e.target.parentElement.dataset.index ||
        e.target.parentElement.parentElement.dataset.index;
      if (e.type === 'click') {
        if (e.target.className == 'btn-edit') {
          selectArr.includes(targetIndex)
            ? setSelectArr([...selectArr])
            : setSelectArr([targetIndex]);
        } else if (e.shiftKey) {
          if (selectArr.includes(targetIndex)) {
            setSelectArr(selectArr.filter((x) => x !== targetIndex));
          } else {
            setSelectArr([...selectArr, targetIndex]);
          }
        } else {
          if (openType === 'chart_sample') {
            if (e.shiftKey) {
              if (selectArr.includes(targetIndex)) {
                setSelectArr(selectArr.filter((x) => x !== targetIndex));
              } else {
                setSelectArr([...selectArr, targetIndex]);
              }
            } else {
              selectArr.includes(targetIndex)
                ? setSelectArr([...selectArr])
                : setSelectArr([targetIndex]);
            }
          } else {
            if (e.target.className === 'btn-create-file') {
              setSelectArr([]);
            } else {
              setSelectArr([targetIndex]);
            }
          }
        }
      }
      if (e.type === 'contextmenu') {
        if (!selectArr.includes(targetIndex)) setSelectArr([targetIndex]);
      }
    } else {
      setSelectArr([]);
    }
  };

  const addHistoryFolder = (e) => {
    let targetIndex =
      e.target.dataset.index || e.target.parentElement.dataset.index;
    if (!fileArr || !targetIndex) return;
    setCurrentFolder(fileArr[targetIndex].id);
    setHistoryFolder([
      ...historyFolder,
      { id: fileArr[targetIndex].id, name: fileArr[targetIndex].name },
    ]);
  };

  const removeHistoryFolder = (v) => {
    setCurrentFolder(v.id);
    let index = historyFolder.findIndex((x) => x.id === v.id) + 1;
    setHistoryFolder([...historyFolder.slice(0, index)]);
  };

  const moveFolder = (targetFolderID) => {
    const onConfirm = async (fileArrObj) => {
      let object = {
        name: fileArrObj.name,
        type: fileArrObj.type,
      };
      if (targetFolderID) object.parentId = targetFolderID;

      try {
        await services.crm.penchart.update_v2(fileArrObj.id, object);
        let copyFileArr = [...fileArr];
        selectArr.map((v) => (copyFileArr[v] = null));
        setFileArr(copyFileArr);
      } catch (error) {
        return;
      }
    };

    if (targetFolderID != currentFolder) {
      selectArr.map((v) => {
        if (fileArr[v] && fileArr[v].id != targetFolderID)
          onConfirm(fileArr[v]);
      });
    }
    setSelectArr([]);
  };

  useEffect(() => {
    init();
  }, [init, currentFolder]);

  useEffect(() => {
    switch (needUpdate.type) {
      case 'CREATE':
      case 'COPY':
        init();
        break;

      case 'EDIT':
        if (fileArr && fileArr[selectArr[0]]) {
          setFileArr([
            ...fileArr,
            (fileArr[selectArr[0]].name = needUpdate.data),
          ]);
        }
        break;

      case 'DELETE':
        setFileArr([
          ...fileArr.filter(function (v, i) {
            return selectArr.indexOf(String(i)) == -1;
          }),
        ]);
        break;

      default:
        break;
    }
    initState();
  }, [needUpdate]);

  useEffect(() => {
    readOnly && shareSelectArr(selectArr.map((v) => fileArr[v].id));
  }, [selectArr]);

  useEffect(() => {
    setSelectArr([]);
  }, [historyFolder]);

  const onMouseMove = useCallback(
    (e, pen) => {
      // if (!setHoverCardOptions) return;
      if (e.target.closest('.thumb')) {
        penchart.setHoverCardOptions({
          show: true,
        });
      } else {
        penchart.setHoverCardOptions({
          show: false,
        });
      }

      penchart.setHoveringPenChart(pen);
      penchart.setShowHoverCard(true);
      penchart.setHoverCardPosition({
        x: e.clientX,
        y: e.clientY + 10,
      });
    },
    [penchart.penChart]
  );

  const onMouseEnter = useCallback((pen) => {
    penchart.setHoveringPenChart(pen);
    penchart.setPenChart(pen);
  }, []);

  const onMouseLeave = () => {
    penchart.setShowHoverCard(false);
    penchart.setHoverCardOptions({
      show: false,
    });
  };

  useEffect(() => {
    if (loading) {
      modal.loading({
        color: '#0060FF',
        message: '이미지를 불러오고있습니다.',
        barType: 'spin',
        type: 'LOADING',
      });
    } else {
      modal.close({ type: 'LOADING' });
    }
  }, [loading]);

  useEffect(() => {
    if (loading) {
      modal.loading({
        type: 'LOADING',
        msg: '이미지를 불러오는중입니다.',
      });
    } else {
      modal.close({
        type: 'LOADING',
      });
    }
  }, [loading]);

  return (
    <div>
      <ContextMenuTrigger id="ContextMenu" holdToDisplay={-1} ref={contextRef}>
        <div
          className="wrap-resource penchart"
          onClick={(e) => {
            checkTarget(e);
          }}
          onContextMenu={(e) => {
            checkTarget(e);
          }}
        >
          <div className="data-input">
            <div
              className="title"
              onDrop={(e) => {
                moveFolder(e.target.dataset.id);
              }}
              onDragOver={(e) => {
                e.preventDefault();
              }}
            >
              <span
                data-id={null}
                onClick={() => {
                  setCurrentFolder(null);
                  setHistoryFolder([]);
                }}
              >
                홈
              </span>
              {historyFolder.map((v, i) => {
                return (
                  <span
                    key={i}
                    data-id={v.id}
                    onClick={() => {
                      setCurrentFolder(v.id);
                      removeHistoryFolder(v);
                    }}
                  >
                    {v.name}
                  </span>
                );
              })}
            </div>
          </div>

          <div className="search-bar">
            <Input
              placeholder="폴더명, 파일명을 검색하세요."
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              onKeyDown={(e) =>
                e.keyCode === 13 &&
                init({ name: e.target.value, orderBy: searchSort })
              }
            />
            <select
              value={searchSort}
              onChange={(e) => {
                setSearchSort(e.target.value);
                init({ name: searchText, orderBy: e.target.value });
              }}
            >
              <option value="id desc">등록일 최신순</option>
              <option value="id asc">등록일 오래된순</option>
              <option value="name desc">이름 오름차순</option>
              <option value="name asc">이름 내림차순</option>
              <option value="updatedAt desc">수정일 최신순</option>
              <option value="updatedAt asc">수정일 오래된순</option>
            </select>
          </div>

          <div className="wrap-folder">
            <div className="sub-title">폴더</div>
            {!readOnly && (
              <div className="wrap-btns">
                {customer.id !== 'default' && (
                  <>
                    <BeforeAndAfterViewer
                      customer={customer}
                      beforeFileArr={selectBeforeArr}
                      afterFileArr={selectAfterArr}
                    />
                    <button
                      className="btn-create-folder _small m-r-10"
                      onClick={() => openDepositModal('GET_SAMPLE')}
                    >
                      차트샘플 불러오기
                    </button>
                  </>
                )}
                <button
                  className="btn-create-folder _small"
                  onClick={() => openDepositModal('CREATE_FOLDER')}
                >
                  폴더추가
                </button>
              </div>
            )}
            <ul className="folder">
              {fileArr &&
                fileArr.map((v, i) => {
                  if (!v) return;
                  if (v.parent && v.parent.id !== currentFolder) return;
                  if (v.type === 'folder')
                    return (
                      <li
                        className={
                          selectArr.includes(i) || selectArr.includes(String(i))
                            ? 'select'
                            : ''
                        }
                        key={i}
                        data-index={i}
                        onDrop={() => moveFolder(v.id)}
                        onDragStart={(e) => {
                          setSelectArr([e.target.dataset.index]);
                        }}
                        onDragLeave={(e) => {
                          if (e.target.tagName == 'LI') e.target.className = '';
                        }}
                        onDragOver={(e) => {
                          e.preventDefault();
                          if (e.target.tagName == 'LI')
                            e.target.className = 'select';
                        }}
                        draggable="true"
                        onClick={() => {
                          setSelectArr([i]);
                        }}
                        onDoubleClick={(e) => {
                          checkTarget(e);
                          addHistoryFolder(e);
                        }}
                      >
                        {v.name.length > 20 ? (
                          <Tooltip
                            placement="topLeft"
                            arrowPointAtCenter
                            title={v.name}
                          >
                            <p>{v.name}</p>
                          </Tooltip>
                        ) : (
                          <p>{v.name}</p>
                        )}
                        {!readOnly && (
                          <button
                            className="btn-edit"
                            onClick={(e) => {
                              checkTarget(e);
                              contextRef.current.handleContextClick(e);
                            }}
                          />
                        )}
                        {customer.id !== 'default' && (
                          <div className="wrap-checkboxs">
                            <span className="wrap-checkbox before">
                              <div
                                className={`checkbox blue ${
                                  selectBeforeArr.findIndex(
                                    (bv) => bv.id == v.id
                                  ) > -1
                                    ? 'checked'
                                    : ''
                                }`}
                                onClick={(e) =>
                                  toggleSelectBeforeAndAfter(e, 'before')
                                }
                              >
                                <i className="zmdi zmdi-check" />
                              </div>
                              전
                            </span>
                            <span className="wrap-checkbox after">
                              <div
                                className={`checkbox blue ${
                                  selectAfterArr.findIndex(
                                    (bv) => bv.id == v.id
                                  ) > -1
                                    ? 'checked'
                                    : ''
                                }`}
                                onClick={(e) =>
                                  toggleSelectBeforeAndAfter(e, 'after')
                                }
                              >
                                <i className="zmdi zmdi-check" />
                              </div>
                              후
                            </span>
                          </div>
                        )}
                      </li>
                    );
                })}
            </ul>
          </div>
          <div className="wrap-file">
            <div className="sub-title">파일</div>
            <ul className="file">
              <PenChartHoverCard />
              {!readOnly && (
                <li
                  className="btn-create-file"
                  onClick={() => {
                    openDepositModal('CREATE_FILE');
                  }}
                >
                  <span>사진 업로드</span>
                </li>
              )}
              {fileArr &&
                fileArr.map((v, i) => {
                  if (!v) return;
                  if (v.parent && v.parent.id !== currentFolder) return;
                  if (v.type === 'file')
                    return (
                      <li
                        className={
                          selectArr.includes(i) || selectArr.includes(String(i))
                            ? 'select'
                            : ''
                        }
                        key={i}
                        data-index={i}
                        onDoubleClick={(e) => {
                          if (openType === 'chart_sample') {
                            setDoubleClick();
                          } else {
                            if (e.target.className.includes('error')) {
                              toast.error(
                                '이미지를 불러오는데 실패하였습니다.'
                              );
                              return;
                            }
                            !e.target.className.includes('check') &&
                              openDepositModal('IMAGE');
                          }
                        }}
                        onDragStart={(e) => {
                          selectArr.length === 0 && e.target.click();
                        }}
                        onDragEnd={(e) => {
                          e.preventDefault();
                        }}
                        draggable="true"
                      >
                        {/* {console.log(imgArr.filter(x => x.id === v.id).map(v => v.url))[0] == "error"} */}
                        <div
                          className={`thumb ${
                            !loading &&
                            imgArr
                              .filter((x) => x.id === v.id)
                              .map((v) => v.url)[0] == 'error'
                              ? 'error'
                              : ''
                          }`}
                          data-id={v.id}
                          style={{
                            background: `url(${imgArr
                              .filter((x) => x.id === v.id)
                              .map((v) => v.url)})`,
                          }}
                          draggable="false"
                          onMouseMove={(e) => {
                            onMouseMove(e, v);
                          }}
                          onMouseEnter={() => {
                            onMouseEnter(v);
                          }}
                          onMouseLeave={onMouseLeave}
                        />
                        <div className="file-name">
                          <p>{v.name}</p>
                          {!readOnly && (
                            <button
                              className="btn-edit"
                              onClick={(e) => {
                                checkTarget(e);
                                contextRef.current.handleContextClick(e);
                              }}
                            />
                          )}
                        </div>
                        {customer.id !== 'default' && (
                          <div className="wrap-checkboxs">
                            <span className="wrap-checkbox before">
                              <div
                                className={`checkbox blue ${
                                  selectBeforeArr.findIndex(
                                    (bv) => bv.id == v.id
                                  ) > -1
                                    ? 'checked'
                                    : ''
                                }`}
                                onClick={(e) =>
                                  toggleSelectBeforeAndAfter(e, 'before')
                                }
                              >
                                <i className="zmdi zmdi-check" />
                              </div>
                              전
                            </span>
                            <span className="wrap-checkbox after">
                              <div
                                className={`checkbox blue ${
                                  selectAfterArr.findIndex(
                                    (bv) => bv.id == v.id
                                  ) > -1
                                    ? 'checked'
                                    : ''
                                }`}
                                onClick={(e) =>
                                  toggleSelectBeforeAndAfter(e, 'after')
                                }
                              >
                                <i className="zmdi zmdi-check" />
                              </div>
                              후
                            </span>
                          </div>
                        )}
                      </li>
                    );
                })}
            </ul>
          </div>
        </div>
      </ContextMenuTrigger>

      {!readOnly && (
        <ContextMenu id="ContextMenu">
          {selectArr.length === 0 && (
            <>
              {!isSample && (
                <MenuItem
                  onClick={() => {
                    openDepositModal('GET_SAMPLE');
                  }}
                >
                  차트샘플 불러오기
                </MenuItem>
              )}
              <MenuItem
                onClick={() => {
                  openDepositModal('CREATE_FOLDER');
                }}
              >
                폴더 생성
              </MenuItem>
              <MenuItem
                onClick={() => {
                  openDepositModal('CREATE_FILE');
                }}
              >
                사진 업로드
              </MenuItem>
            </>
          )}

          {selectArr.length > 0 && (
            <>
              {selectArr.length === 1 && (
                <>
                  <MenuItem
                    onClick={() => {
                      openDepositModal('EDIT_NAME');
                    }}
                  >
                    이름 변경
                  </MenuItem>
                </>
              )}
              {selectArr.length === 1 &&
                selectArr[0] != null &&
                fileArr &&
                fileArr[selectArr[0]] &&
                fileArr[selectArr[0]].type === 'file' && (
                  <MenuItem
                    onClick={() => {
                      download();
                    }}
                  >
                    다운로드
                  </MenuItem>
                )}
              {/* 폴더 제외, 1개 이상의 파일을 선택했을때 */}
              {selectArr
                .filter((v) => v != null)
                .map(
                  (v) => fileArr && fileArr[v] && fileArr[v]?.type === 'file'
                )
                .filter((v) => v == true).length > 0 && (
                <MenuItem
                  onClick={() => {
                    printImg();
                  }}
                >
                  인쇄하기
                </MenuItem>
              )}
              <MenuItem
                onClick={() => {
                  openDepositModal('DELETE');
                }}
              >
                삭제
              </MenuItem>
            </>
          )}
        </ContextMenu>
      )}
    </div>
  );
};

PenChartsDrive.propTypes = {
  customer: PropTypes.shape({
    // oneOfType: 여러 종류중 하나의 종류가 될 수 있는 객체
    //차트샘플함에서는 id = 'default' type string,
    //펜차트탭에서는 id type number
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
  shareSelectArr: PropTypes.func,
  isSample: PropTypes.bool,
  readOnly: PropTypes.bool,
  openType: PropTypes.string,
  setDoubleClick: PropTypes.func,
  scrollY: PropTypes.number,
  height: PropTypes.number,
};

export default observer(PenChartsDrive);
