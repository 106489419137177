import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { observer } from 'mobx-react';
import {
  currencySymbol,
  currency,
  emojisContainsCheck,
  translate,
} from 'filters';
import ScreenMessageSend from 'components/app/MessageScreen/ScreenMessageSend';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import ModalMessagePriceTable from 'components/modals/ModalMessagePriceTable';
import DataTableAlpha from 'components/data-table/DataTableAlpha';
import ModalMessageSendConfirm from 'components/modals/ModalMessageSendConfirm';
import models from 'models';
import { $qb } from 'scripts/querybuilder';
import { useAuth } from 'store/auth';
import { useModal } from 'hooks/useModal';
import { useServices } from 'hooks/useServices';
import { useToast } from 'hooks/useToast';

const ModalMessageSendToBoards = ({ options, close }) => {
  const modal = useModal();
  const services = useServices();
  const toast = useToast();
  const auth = useAuth();
  const [loadingBtnDisabledFlag, setLoadingBtnDisabledFlag] = useState(false);
  const defaultParams = $qb().limit(20).page(1);
  const [params, setParams] = useState(defaultParams);

  const initMessageObj = {
    images: [],
    imageFiles: [],
    title: '',
    contents: '',
    smsScheduleType: 'immediate',
    scheduleTime: '08:00',
    smsType: 'normal',
    smsAppointmentCheck: true,
  };

  const [messageObj, setMessageObj] = useState({
    ...initMessageObj,
    ...(options.mapMessage && options.mapMessage),
  });
  const [targetDataObj] = useState({
    smsSearchHistory: {
      id: null,
    },
    excludePhoneNumbers: [],
  });
  const [balance, setBalance] = useState({
    amount: null,
    freeAmount: null,
    totalAmount: null,
    limit: null,
  });
  const [callerNumberList, setCallerNumberList] = useState();
  //targetCustomers > 대상리스트
  const [targetCustomers, setTargetCustomers] = useState([]);
  const [totalTargetCustomers, setTotalTargetCustomers] = useState(0);
  //checkTargetCustoemrs > 선택인원
  const [checkTargetCustomers, setCheckTargetCustomers] = useState([]);

  const callApiCallerNumberList = useCallback(async () => {
    try {
      const resp = await services.crm.notification.callerNumbers.all();
      if (resp && resp.data) {
        setCallerNumberList(resp.data);
        return;
      } else {
        setCallerNumberList([]);
      }
    } catch (e) {
      setCallerNumberList([]);
    }
  }, [services.crm.notification.callerNumbers]);

  const callBalanceInfo = useCallback(async () => {
    try {
      const resp = await services.crm.notification.balance.all();
      const respData = resp.data;
      const { amount, freeAmount } = respData;
      const totalAmount = amount + freeAmount;

      setBalance((balance) => ({
        ...balance,
        ...respData,
        totalAmount,
      }));
    } catch (e) {
      toast.error('문자충전금 조회에 실패하였습니다.');
    }
  }, [services.crm.notification.balance, toast]);

  const callApiTargetCustomers = useCallback(async () => {
    try {
      let enabledCheck =
        (messageObj || {}).smsType === 'normal'
          ? 'smsEnabled'
          : 'eventSmsEnabled';

      const { id, customerId } = options.appointment || {};
      const resp = await services.crm.customer.detail(customerId);
      if (!resp) return;

      let data = [resp.data].map((v) => {
        //초기 진입 시, smsType에 따라 문자 수신 동의 고객이 모두 체크되어 있어야 한다.
        if (v[enabledCheck] === true) {
          v.checked = true;
          v.appointmentId = id;
        }
        v.smsType = (messageObj || {}).smsType;

        //모든 페이지의 공통화를 위해 customer 객체를 생성해줌(table model 사용)
        v.customer = {
          smsEnabled: v.smsEnabled,
          smsType: (messageObj || {}).smsType,
          eventSmsEnabled: v.eventSmsEnabled,
          name: v.name,
          phoneNumber: v.phoneNumber,
        };
        return v;
      });

      setTargetCustomers([...data]);
      setCheckTargetCustomers([
        ...data.filter((v) => v[enabledCheck] === true),
      ]);
      setTotalTargetCustomers(data.length);
    } catch (e) {
      console.log(e.description);
    }
    //문자분류 일반/광고 선택시에만 대상리스트 재세팅
  }, [messageObj.smsType, services.crm.customer, options.appointment]);

  useEffect(() => {
    callBalanceInfo();
    return () => {
      setBalance({
        amount: null,
        freeAmount: null,
        totalAmount: null,
        limit: null,
      });
    };
  }, [callBalanceInfo]);

  useEffect(() => {
    callApiCallerNumberList();
    return () => {
      setCallerNumberList([]);
    };
  }, [callApiCallerNumberList]);

  useEffect(() => {
    callApiTargetCustomers();
    return () => {
      setTargetCustomers([]);
    };
  }, [callApiTargetCustomers]);

  const onAction = (obj) => {
    const { row, event } = obj; // , rowIdx
    if (event.target.dataset.type === 'check') {
      const { checked } = row;
      if (!checked) {
        //체크 선택
        checkTargetCustomers.push(row);
      }

      const list = checkTargetCustomers
        .map((v) => {
          if (v.id === row.id) {
            v.checked = !checked;
          }
          return v;
        })
        .filter((v) => v.checked === true);

      setCheckTargetCustomers([...list]);
    }
  };

  const onParams = (p) => {
    setParams(p);
    callApiTargetCustomers();
  };

  const openModalMessagePriceTable = useCallback(() => {
    modal.custom({
      component: ModalMessagePriceTable,
    });
  }, [modal]);

  const validate = useCallback(
    (dataObj) => {
      // 전송대상
      if (((dataObj || {}).appointmentIds || []).length === 0) {
        toast.error('ERROR_NO_TARGET_SELECTED');
        return false;
      }

      const {
        callerNumber,
        smsScheduleType,
        // scheduledAt,
        scheduleDate,
        scheduleTime,
        contents,
        smsType,
        title,
      } = dataObj.notification;

      // 발신번호 미선택
      if (!callerNumber || !callerNumber.id) {
        toast.error('ERROR_SELECT_CALLING_NUMBER');
        return false;
      }

      // 예약선택인경우. 날짜 미선택
      if (smsScheduleType === 'scheduled' && !scheduleDate) {
        toast.error('ERROR_SELECT_SCHEDULE_DATE');
        return false;
      }

      // 선택한 시간/날짜가 이전인 경우
      const scheduleAt = moment(
        `${scheduleDate} ${scheduleTime}`,
        'YYYY-MM-DD HH:mm'
      );
      if (smsScheduleType === 'scheduled' && scheduleAt.isBefore()) {
        toast.error('ERROR_INVALID_SCHEDULED_TIME');
        return false;
      }

      // 문자내용 없음
      if (!contents || contents.length === 0) {
        toast.error('ERROR_EMPTY_MESSAGE_CONTENTS');
        return false;
      }

      const meInfo = auth.me ? auth.me : {};
      const clinicInfo = meInfo.clinic ? meInfo.clinic : {};
      const { rejectionPhoneNumber } = clinicInfo ? clinicInfo : {};
      if (smsType === 'event' && !rejectionPhoneNumber) {
        toast.error('080 수신거부번호가 없어 광고문자를 전송할 수 없습니다');
        return false;
      }

      if (
        emojisContainsCheck(title) === true ||
        emojisContainsCheck(contents) === true
      ) {
        //문자 전송시 제목, 내용에 특수 이모티콘(이모지) 포함되어있는지 체크
        toast.error('문자 제목 또는 내용에 특수 이모티콘을 제거해주세요');
        return false;
      }

      // dataObj
      return true;
    },
    [toast, auth]
  );

  const onOpenModalConfirm = useCallback(() => {
    const { scheduleDate, scheduleTime } = messageObj;
    const notification = {
      ...messageObj,
      ...(messageObj.smsScheduleType === 'scheduled' && {
        scheduledAt: moment(
          `${scheduleDate} ${scheduleTime}`,
          'YYYY-MM-DDTHH:mm:ss'
        ).format('YYYY-MM-DDTHH:mm:ss'),
      }),
    };

    const dataObj = {
      notification,
      checkedCount: checkTargetCustomers.length,
      appointmentIds: checkTargetCustomers.map((v) => v.appointmentId),
      customerIds: options.customerIds,
    };

    if (validate(dataObj)) {
      setLoadingBtnDisabledFlag(true);

      let notification = dataObj.notification;
      //api 호출시 보내는 request에 불필요한 값 제거
      //smsAppointmentCheck: ui에서 구분하기 위한 값
      // eslint-disable-next-line no-unused-vars
      let { smsAppointmentCheck: deletedKey, ...otherKeys } = notification;
      let payload = { ...dataObj, notification: { ...otherKeys } };

      modal
        .custom({
          component: ModalMessageSendConfirm,
          options: {
            dataObj: payload,
            balance,
            endpoint: 'sendNotification/task',
          },
        })
        .then((submitted) => {
          if (submitted === true) {
            close();
          } else {
            setLoadingBtnDisabledFlag(false);
          }
        });
    }
  }, [messageObj, validate, checkTargetCustomers, targetDataObj]);

  return (
    <div className="modal-message-send message-send">
      <div className="message-wrap">
        <div className="head flex-row flex-between items-start">
          <div className="title">문자 전송</div>
          <i onClick={() => close()} className="zmdi zmdi-close" />
        </div>
        <div className="flex-row">
          <div className="point-status">
            <div className="point-head">
              <div className="head-left">
                <span style={{ fontSize: '1.1em', marginRight: '.5em' }}>
                  {translate('MESSAGE_POINT_AMOUNT')}
                </span>
                <span>
                  {balance.totalAmount !== null &&
                    `${currencySymbol()}${currency(balance.totalAmount)}`}
                  {balance.totalAmount === null && (
                    <FontAwesomeIcon icon={faCircleNotch} spin />
                  )}
                </span>
              </div>
              <div className="head-right">
                {/* <button className="btn btn-basic _small m-r-8">{translate("MESSAGE_POINT_RECHARGE")}</button> */}
                <button
                  className="btn btn-basic _small"
                  onClick={() => openModalMessagePriceTable()}
                >
                  {translate('MESSAGE_POINT_PRICE')}
                </button>
              </div>
            </div>
            <ScreenMessageSend
              style={{ display: 'flex' }}
              obj={messageObj}
              setObj={setMessageObj}
              callerNumberList={callerNumberList}
            />
            <div className="btnwrap">
              <button
                className="btn btn-basic _small _full"
                style={{ width: '100%' }}
                disabled={loadingBtnDisabledFlag}
                onClick={() => onOpenModalConfirm()}
              >
                {translate('MESSAGE_SEND')}
              </button>
            </div>
          </div>
          <div className="target-container card m-t-20">
            <div className="set-cnt">
              <span>
                {`${translate('SEARCH_TARGET_RESULTS')} `}
                <b>{`${
                  totalTargetCustomers === 1
                    ? translate('ONE_PERSON')
                    : translate('N_PERSONS').replace(
                        '%s',
                        totalTargetCustomers ? totalTargetCustomers : 0
                      )
                }`}</b>
              </span>
              <span className="m-l-8">
                {`${translate('SELECTED_TARGETS')} `}
                <b>{`${
                  checkTargetCustomers.length === 1
                    ? translate('ONE_PERSON')
                    : translate('N_PERSONS').replace(
                        '%s',
                        checkTargetCustomers ? checkTargetCustomers.length : 0
                      )
                }`}</b>
              </span>
            </div>
            <DataTableAlpha
              className="header-style-classic"
              model={models.crm.messageCustomerTarget}
              data={targetCustomers}
              total={totalTargetCustomers}
              params={params}
              onParams={onParams}
              onAction={onAction}
              hideBottom={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

ModalMessageSendToBoards.propTypes = {
  options: PropTypes.object,
  close: PropTypes.func,
};

export default observer(ModalMessageSendToBoards);
