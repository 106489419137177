import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import {
  addDays,
  getDay,
  format,
  isSameDay,
  lastDayOfMonth,
  startOfMonth,
  differenceInDays,
} from 'date-fns';
import { ko } from 'date-fns/locale';
import { useServices } from 'hooks/useServices';

const UnoDatePicker = ({ onChanged, startDate, endDate }) => {
  const services = useServices();
  const [selectedDates, setSelectedDates] = useState([new Date()]);
  const [currentStartDayOfMonth, setCurrentStartDayOfMonth] = useState();
  const [selectedMonthDays, setSelectedMonthDays] = useState([]);
  const [holidays, setHolidays] = useState([]);
  const selectedStyle = {
    fontWeight: 'bold',
    background: `#484760`,
    borderLeft: `1px solid #484760`,
    borderTop: `1px solid #484760`,
    borderRight: `1px solid #484760`,
    color: `#ffffff`,
  };

  const getStyles = (day) => {
    return selectedDates.some((v) => isSameDay(day, v)) ? selectedStyle : null;
  };

  const onDateClick = (day) => {
    setSelectedDates([day]);
    if (onChanged) {
      onChanged(day);
    }
  };

  const holidaysApi = useCallback(async (startDay, endDay) => {
    if (
      !currentStartDayOfMonth ||
      !isSameDay(currentStartDayOfMonth, startDay)
    ) {
      setCurrentStartDayOfMonth(startDay);
      try {
        const resp = await services.crm.holiday.list({
          locdateStartAt: format(startDay, 'yyyy-MM-dd', { locale: ko }),
          locdateEndAt: format(endDay, 'yyyy-MM-dd', { locale: ko }),
          orderBy: 'locdate asc',
        });
        if (resp) setHolidays(resp.data);
      } catch (e) {
        console.log(e.description);
      }
    }
  });

  useEffect(() => {
    if (startDate) {
      let selectedDays = [];
      selectedDays.push(startDate);
      const diffDays = differenceInDays(
        endDate ? endDate : startDate,
        startDate
      );
      for (let i = 0; i < diffDays; i++) {
        selectedDays.push(addDays(startDate, i + 1));
      }
      setSelectedDates(selectedDays);
      const startDayOfmonth = startOfMonth(startDate);
      const endDayOfMonth = Number(
        format(lastDayOfMonth(startDayOfmonth), 'd')
      );
      let monthDays = [];
      for (let j = 0; j < endDayOfMonth; j++) {
        monthDays.push(addDays(startDayOfmonth, j));
      }
      holidaysApi(startDayOfmonth, lastDayOfMonth(startDayOfmonth));
      setSelectedMonthDays(monthDays);
    }
  }, [startDate]);

  return (
    <div className="calendar-picker">
      <div
        id={'calendar-picker'}
        className="calendar-picker-dateListScrollable"
      >
        {selectedMonthDays.map((day, i, arr) => (
          <div
            className={`calendar-picker-date-day calendar-picker-date-day-${getDay(
              day
            )} ${
              holidays.some(
                (v) => v.locdate === format(day, 'yyyy-MM-dd', { locale: ko })
              )
                ? 'calendar-picker-date-day-holiday '
                : ''
            }${i === 0 ? 'calendar-picker-date-day-first' : ''}${
              i === arr.length - 1 ? 'calendar-picker-date-day-last' : ''
            }`}
            style={getStyles(day)}
            key={day}
            onClick={() => onDateClick(day)}
          >
            <div className="calendar-picker-date-dayItem">
              <div className="calendar-picker-date-dayItem calendar-picker-date-dayItem-date">
                {format(day, 'd')}
              </div>
              <div className="calendar-picker-date-dayItem calendar-picker-date-dayItem-day">
                {format(day, 'E', { locale: ko })}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

UnoDatePicker.propTypes = {
  onChanged: PropTypes.func,
  startDate: PropTypes.object,
  endDate: PropTypes.object,
};

export default observer(UnoDatePicker);
