import React, { useState, useCallback } from 'react';
import { observer } from 'mobx-react';
import SelectAutoNotificationCommon from './SelectAutoNotificationCommon';
import PropTypes from 'prop-types';
import MultiSelect from 'react-multi-select-component';
import { toJS } from 'mobx';
import { useMessages } from 'hooks/useMessages';
import { useToast } from 'hooks/useToast';
import styled from 'styled-components';

const Input = styled.input`
  width: 65px;
  height: 31px;
  min-height: 10px;
  padding: 16px 20px;
  border-radius: 4px;
  background-color: #f3f8ff;
  color: #000;
  font-size: 14px;
  border: 1px solid #dee2ec;
  font-family: var(--mobile-screen-font);
  line-height: 1.4em;
`;

const ViewAutoSurgeryPeriodBottom = ({ obj, setObj }) => {
  const toast = useToast();
  const messages = useMessages();
  const [initUiObj] = useState({
    scheduleTimeHour:
      obj.smsScheduleType === 'scheduled'
        ? obj.scheduleTime.split(':')[0]
        : '08',
    scheduleTimeMinute:
      obj.smsScheduleType === 'scheduled'
        ? obj.scheduleTime.split(':')[1]
        : '00',
    disableScheduleTime: false,
    scheduleDaysBefore: false,
  });

  const [uiObj, setUiObj] = useState(initUiObj);

  const onChangeDays = useCallback((e) => {
    const { name, value } = e.target;
    messages.setSelectScheduleDaysDelta(Number(value));
    setUiObj((uiObj) => ({
      ...uiObj,
      [name]: value,
    }));
    setObj((obj) => ({
      ...obj,
      scheduleDaysDelta: Number(value),
    }));
    return;
  }, []);

  const onChangeReserveCount = useCallback((e) => {
    const { name, value } = e.target;
    messages.setSelectReserveCount(Number(value));
    setUiObj((uiObj) => ({
      ...uiObj,
      [name]: value,
    }));
    setObj((obj) => ({
      ...obj,
      reserveCount: Number(value),
    }));
    return;
  }, []);

  const onChangeHandler = useCallback(
    (e) => {
      const { name, value } = e.target;
      const scheduleTimeItemNames = ['scheduleTimeHour', 'scheduleTimeMinute'];

      //전송상황: name = smsSituation
      //value = 고객등록완료: register / 예약완료 : reserved / 예약취소 : cancel
      //미방문 : unvisited / 퇴원 : leave
      if (scheduleTimeItemNames.indexOf(name) !== -1) {
        const timeObj = Object.assign(
          ...scheduleTimeItemNames.map((name) => ({ [name]: uiObj[name] })),
          { [name]: value }
        );
        const scheduleTime = `${timeObj.scheduleTimeHour}:${timeObj.scheduleTimeMinute}`;

        setUiObj((uiObj) => ({
          ...uiObj,
          ...timeObj,
        }));

        setObj((obj) => ({
          ...obj,
          scheduleTime: scheduleTime,
        }));
        return;
      }

      setObj((obj) => ({
        ...obj,
        [name]: value,
      }));
    },
    [setObj, uiObj, obj]
  );

  const onChangeSelectCategory = (categories) => {
    if (categories.length === 0) {
      // 선택한 시/수술 카테고리값 없음 = 시/수술명도 초기화
      messages.setAllSurgeryItems([]);
      messages.setSelectSurgeryItems([]);
      messages.setSelectSurgeryCategories([]);
      setObj({
        ...obj,
        treatmentItemId: '',
      });
      return;
    }

    let selectCategories = categories;
    if (obj.id && categories.length > 1) {
      // obj id가 존재하는경우, 개별선택해야함
      selectCategories = [toJS(categories[1])];
      messages.setSelectSurgeryItems([]);
      setObj({
        ...obj,
        treatmentItemId: [],
      });
    }
    // 선택한 시/수술 카테고리
    messages.setSelectSurgeryCategories(toJS(selectCategories));

    // 선택한 시/수술 카테고리의 시/수술명 리스트
    let treatmentItems = selectCategories.flatMap((v) => {
      const { data } = v;
      const { items } = data;
      return items
        .filter((item) => item.visible)
        .map((item) => {
          return { label: item.name, value: item.id };
        });
    });
    messages.setAllSurgeryItems(treatmentItems);
  };

  const onChangeSelectItem = (items) => {
    let selectItems = items;
    if (obj.id && items.length > 1) {
      // obj id가 존재하는경우, 개별선택해야함
      selectItems = [toJS(items[1])];
    }

    messages.setSelectSurgeryItems(toJS(selectItems));
    const list = selectItems.map((v) => v.value);

    setObj({
      ...obj,
      treatmentItemId: list,
    });
  };

  return (
    <div className={`app-view-bottom app-view-surgery-bottom`}>
      <div className="sms-situation">
        <div className="label" style={{ lineHeight: '1.5em' }}>
          <span className="label-required" style={{ wordBreak: 'keep-all' }}>
            시/수술 카테고리
          </span>
        </div>
        <div className="input-select select-surgery-items">
          <MultiSelect
            className={`multi-select surgery-categories ${
              messages.allSurgeryCategories.length >= 5
                ? messages.allSurgeryCategories.length >= 6
                  ? 'reverse-drop'
                  : 'reverse-sub-drop'
                : ''
            }`}
            options={messages.allSurgeryCategories || []}
            value={messages.selectSurgeryCategories || []}
            onChange={(selected) => {
              onChangeSelectCategory(toJS(selected));
            }}
            hasSelectAll={!obj.id}
            labelledBy={'Select'}
            disableSearch
            selectAllLabel={'전체선택'}
            overrideStrings={{
              selectSomeItems: '시/수술 카테고리를 선택하세요.',
              allItemsAreSelected: '전체선택',
            }}
          />
        </div>
      </div>
      <div className="sms-situation">
        <div className="label">
          <span className="label-required">시/수술명</span>
        </div>
        <div className="input-select select-surgery-items">
          <MultiSelect
            className={`multi-select surgery-items ${
              messages.allSurgeryItems.length >= 4
                ? messages.allSurgeryItems.length >= 6
                  ? 'reverse-drop'
                  : 'reverse-sub-drop'
                : ''
            }`}
            options={messages.allSurgeryItems || []}
            value={messages.selectSurgeryItems || []}
            onChange={(selected) => {
              onChangeSelectItem(toJS(selected));
            }}
            hasSelectAll={!obj.id}
            labelledBy={'Select'}
            disableSearch
            selectAllLabel={'전체선택'}
            overrideStrings={{
              selectSomeItems: '시/수술 카테고리를 우선 선택하세요.',
              allItemsAreSelected: !obj.id
                ? '전체선택'
                : messages.selectSurgeryItems.length > 0 &&
                  messages.selectSurgeryItems[0]?.label,
            }}
          />
        </div>
      </div>
      <div className="schedule-type">
        <div className="label">
          <span className="label-required">발송주기</span>
        </div>
        <div className="schedule-info">
          <div className="input-select auto-send-notification">
            <div className="schedule-info-select schedule-info-days-delta">
              <Input
                placeholder="0"
                value={messages.selectScheduleDaysDelta}
                onChange={(e) => {
                  if (isNaN(Number(e.target.value))) {
                    toast.error('숫자만 입력하세요');
                  } else {
                    onChangeDays(e);
                  }
                }}
              />
            </div>
            <div>
              <select
                className="m-l-4 m-r-2"
                style={{
                  width: '65px',
                }}
                value={messages.selectScheduleDaysCycle}
                onChange={(e) => {
                  const { name, value } = e.target;
                  messages.setSelectScheduleDaysCycle(value);
                  setUiObj((uiObj) => ({
                    ...uiObj,
                    [name]: value,
                  }));
                }}
              >
                <option value="day">일</option>
                <option value="week">주</option>
                <option value="month">개월</option>
              </select>
            </div>
            <div>
              <span>마</span>
            </div>
            <div>
              <span className="m-r-4">다</span>
            </div>
            <div className="schedule-info-select schedule-info-time-hour">
              <SelectAutoNotificationCommon
                disabled={uiObj.disableScheduleTime}
                name="scheduleTimeHour"
                onChange={onChangeHandler}
                value={uiObj.scheduleTimeHour}
              />
            </div>
            <div>
              <span>:</span>
            </div>
            <div className="schedule-info-select schedule-info-time-minute">
              <SelectAutoNotificationCommon
                disabled={uiObj.disableScheduleTime}
                name="scheduleTimeMinute"
                onChange={onChangeHandler}
                value={uiObj.scheduleTimeMinute}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="schedule-type reserve-count">
        <div className="label">
          <span className="label-required">발송횟수</span>
        </div>
        <div className="schedule-info">
          <div className="input-select auto-send-notification">
            <div className="schedule-info-select schedule-info-days-delta">
              <Input
                name="reserveCount"
                placeholder="1"
                value={messages.selectReserveCount || 1}
                onChange={(e) => {
                  if (isNaN(Number(e.target.value))) {
                    toast.error('숫자만 입력하세요');
                  } else if (Number(e.target.value) === 0) {
                    toast.error('디폴트 값은 1일입니다.');
                  } else if (Number(e.target.value) > 100) {
                    toast.error('최대 입력 가능한 횟수는 100회 입니다.');
                  } else {
                    onChangeReserveCount(e);
                  }
                }}
              />
            </div>
            <div>
              <span className="m-l-4">회</span>
            </div>
          </div>
        </div>
      </div>
      <div className="sms-notice">
        <div className="label">
          * 마지막 시수술일 기준으로 문자를 자동 전송합니다.
        </div>
        <div className="label">
          * 예약내역이 확인되는 경우, 자동 전송되지 않습니다.
        </div>
        <div className="label">* 최대 입력가능한 횟수는 100회입니다.</div>
      </div>
    </div>
  );
};

ViewAutoSurgeryPeriodBottom.propTypes = {
  obj: PropTypes.object,
  setObj: PropTypes.func,
};

export default React.memo(observer(ViewAutoSurgeryPeriodBottom));
