import moment from 'moment';
import { toThousandCommas } from 'filters';

export default {
  keys: [
    {
      title: '일자',
      column: 'createdAt',
      filter: (o) => moment(o.createdAt).format('YYYY-MM-DD'),
    },
    {
      title: '상태',
      noorder: true,
      filter: (o) => {
        switch (o.status) {
          case 'deposit':
            return '입금';
          case 'paid':
            return '사용';
          case 'deduction':
            return '차강';
          case 'refund':
            return '환불';
          case 'canceled_deposit':
            return '입금 취소';
          case 'expired_deposit':
            return '기한만료 취소';
          default:
            return;
        }
      },
    },
    {
      title: '금액',
      noorder: true,
      filter: (o) =>
        `${
          o.status === 'paid' || o.stauts === 'dsposit' ? '-' : '+'
        } ₩${toThousandCommas(Math.abs(o.amount) + Math.abs(o.freeAmount))}`,
    },
    {
      title: '잔여 충전금',
      noorder: true,
      filter: (o) =>
        `₩${toThousandCommas(o.balanceAmount + o.balanceFreeAmount)}`,
    },
  ],
};
