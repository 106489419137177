import React, { useState, useEffect, useCallback, useRef } from 'react';
import 'assets/styles/components/mobilescreen.scss';
import MessageDetailViewMain from './MessageDetailViewMain';
import PropTypes from 'prop-types';

const MessageDetail = ({
  className = '',
  obj,
  setObj,
  titleComponent,
  searchHistoryInit,
  setLoadingBtnDisabledFlag,
}) => {
  const contentsRef = useRef();
  /*const appendContentsBytes = 28; // 광고문자일 경우
  const appendBabitalkLinkedContentsByte = 18; // 링크추가> 바비톡 병원 홈페이지 링크*/

  const [initUiObj] = useState({
    // type : SMS, MMS, LMS,
    type: null,
    useImage: obj.images.length > 0 ? true : false,
    useTitle: null,
    titleBytes: 0,
    contentsBytes: 0,
    previewImages: obj.images.map((imageObj) => ({
      isUploaded: true,
      url: imageObj.url,
      id: imageObj.id,
    })),
    // previewImages : [{
    // isUploaded : false,
    // url : ""
    // }]
  });

  const [uiObj, setUiObj] = useState(initUiObj);

  const resetImages = useCallback(() => {
    setObj((obj) => ({
      ...obj,
      images: [],
      imageFiles: [],
    }));

    setUiObj((uiObj) => ({
      ...uiObj,
      useImage: false,
      previewImages: [],
    }));
  }, [setObj]);

  const countByteByString = useCallback((str) => {
    let count = 0;
    const strLength = str ? str.length : 0;
    for (let i = strLength; i--; ) {
      if (str.charCodeAt(i) > 127) {
        count += 2;
      } else if (str.charAt(i) !== '\r') {
        count += 1;
      }
    }
    return count;
  }, []);

  useEffect(() => {
    const titleBytes = countByteByString(obj.title);
    let contentsBytes = countByteByString(obj.contents);

    // contentsBytes += obj.smsType === 'event' ? appendContentsBytes : 0;
    // contentsBytes += obj.isBabitalkUri ? appendBabitalkLinkedContentsByte : 0;

    const hasImage = obj.images.length > 0 || obj.imageFiles.length > 0;

    let type = 'SMS';
    if (contentsBytes > 90) type = 'LMS';
    if (hasImage) type = 'MMS';

    setUiObj((uiObj) => ({
      ...uiObj,
      type: type,
      contentsBytes: contentsBytes,
      useTitle: type === 'SMS' ? false : true,
      titleBytes: type === 'SMS' ? 0 : titleBytes,
      useImage: type === 'MMS' ? true : false,
    }));
  }, [
    obj.title,
    obj.contents,
    obj.images,
    obj.imageFiles,
    countByteByString,
    obj.smsType,
    obj.isBabitalkUri,
  ]);

  useEffect(() => {
    if (uiObj.type === 'SMS') {
      setObj((obj) => ({
        ...obj,
        title: '',
      }));
    }

    setObj((obj) => ({
      ...obj,
      messageType: uiObj.type,
    }));
  }, [setObj, uiObj.type]);

  useEffect(() => {
    if (searchHistoryInit === true) {
      //이미지 첨부 초기화
      resetImages();
    }
  }, [searchHistoryInit, resetImages]);

  const [testUiObj] = useState({
    fontSize: '1.0em',
    // theme : "kakaotalk"
    theme: 'default',
  });

  return (
    <div className={`mobile-screen ${className}`} data-theme={testUiObj.theme}>
      {titleComponent && <div className="nav-bar">{titleComponent}</div>}

      <div className="app-view-header"></div>
      <MessageDetailViewMain
        uiObj={uiObj}
        obj={obj}
        contentsRef={contentsRef}
        resetImages={resetImages}
        setLoadingBtnDisabledFlag={setLoadingBtnDisabledFlag}
        hidePlaceholder
      />
    </div>
  );
};

MessageDetail.propTypes = {
  className: PropTypes.string,
  theme: PropTypes.string,
  obj: PropTypes.object,
  setObj: PropTypes.func,
  titleComponent: PropTypes.object,
  searchHistoryInit: PropTypes.bool,
  setLoadingBtnDisabledFlag: PropTypes.bool,
};

export default MessageDetail;
