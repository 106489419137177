export default {
  keys: [
    {
      title: 'SELECT',
      type: 'checkbox',
    },
    {
      title: 'SCHEDULED_AT',
      column: 'scheduledAt',
      type: 'date',
    },
    {
      title: 'COUNSELORS',
      column: 'counselor__name',
    },
    {
      title: 'DOCTORS',
      column: 'doctor__name',
    },
    {
      title: 'TREATMENT_ITEM_CATEGORIES',
      filter: (o) => `<div>
            ${o.items
              .map(
                (item) => `
                <div>${(item.category || {}).name || ''}</div>
()            `
              )
              .join('\n')}
            </div>`,
    },
    {
      title: 'TREATMENT_ITEMS',
      filter: (o) => `<div>
            ${o.items
              .map(
                (item) => `
                <div>${item.name}</div>
            `
              )
              .join('\n')}
            </div>`,
    },
    {
      title: 'PRICE',
      filter: (o) => `<div>
            ${o.items
              .map(
                (item) => `
                <div>${item.price}</div>
            `
              )
              .join('\n')}
            </div>`,
    },
    {
      title: 'VAT_INCLUDED',
      filter: (o) => `<div>
            ${o.items
              .map(
                (item) => `
                <div>${item.vatIncluded}</div>
            `
              )
              .join('\n')}
            </div>`,
    },
    {
      title: 'REIMBURSEMENT',
      filter: (o) => `<div>
            ${o.items
              .map(
                (item) => `
                <div>${item.reimbursement}</div>
            `
              )
              .join('\n')}
            </div>`,
    },
    {
      title: 'PERIOD_COUNT',
      filter: (o) => `<div>
            ${o.items
              .map(
                (item) => `
                <div>${item.periodCount || 0}</div>
            `
              )
              .join('\n')}
            </div>`,
    },
    {
      title: 'MEMO',
      column: 'memo',
      type: 'text',
    },
  ],
};
