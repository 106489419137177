import React, { useContext, useEffect } from 'react';
import { observer } from 'mobx-react';
import { ImageEditorContext } from './ImageEditor';
import classNames from 'classnames';
import ContentEditable from 'react-contenteditable';

export default observer(function ({ innerRef, onSave }) {
  // eslint-disable-next-line no-unused-vars
  const { state, ui } = useContext(ImageEditorContext);

  useEffect(() => {
    if (!ui.editText.id) return;

    innerRef.current.style.width = ui.editText.props.width + 'px';
  }, [ui.editText.id]);

  useEffect(() => {
    innerRef.current.focus();
  }, []);

  function onKeyDown(e) {
    if (e.keyCode === 27) {
      onSave();
    }
  }

  function onChange(e) {
    ui.setEditTextValue(e.target.value);
  }

  function onPaste(e) {
    e.preventDefault();
    const text = (e.originalEvent || e).clipboardData.getData('text/plain');
    document.execCommand('insertText', false, text);
  }

  return (
    <ContentEditable
      onPaste={onPaste}
      innerRef={innerRef}
      className={classNames('image-editor-edit-text-field')}
      style={{
        ...ui.editText.style,
        transform: `scale(${ui.overlayZoom})`,
      }}
      html={ui.editTextValue}
      onChange={onChange}
      onKeyDown={onKeyDown}
    />
  );
});
