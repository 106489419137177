import React, { createContext, useContext, useMemo } from 'react';
import * as axios from 'scripts/axios';
import * as $http from 'axios';
import { makeAutoObservable } from 'mobx';
import services from 'services';
import data from 'store/data';
import { isTokenExpired } from 'utils/token';
import Cookies from 'universal-cookie';

function generateCacheBuster() {
  return Math.floor(Math.random() * 1000000);
}

class Auth {
  logoutHandlers = [];
  loaded = false;
  token = null;
  me = null;
  isNewCustomerOption;

  constructor() {
    axios.init(this);
    makeAutoObservable(this);
    this.initialize(window.sessionStorage.getItem('authToken'));
  }

  initialize = async (authToken) => {
    try {
      if (!authToken) return;
      if (!isTokenExpired(authToken)) {
        this.signOut();
        return;
      }

      axios.setAuthToken(authToken);
      await this.loadMe();
      await this.loadIsNewCustomerOption();
      this.token = authToken;
      await data.loadOptions();
    } catch (e) {
      this.signOut();
    } finally {
      this.loaded = true;
    }
  };

  loadMe = async () => {
    const resp = await services.crm.user.me();
    if (resp) {
      const { data } = resp;
      this.me = data;
      return data;
    } else {
      throw new Error('loadMe error');
    }
  };

  updateMe = (obj) => {
    this.me = { ...this.me, ...obj };
  };

  signIn = async (payload, v2) => {
    try {
      const response = await $http({
        method: 'post',
        url: '/users/sign_in',
        baseURL: v2
          ? process.env.REACT_APP_V2_API_URL
          : process.env.REACT_APP_API_URL,
        data: payload,
      });

      if (v2) {
        const cookies = new Cookies();

        cookies.set('access-token', response.accessToken, { path: '/' });
        cookies.set('refresh-token', response.refreshToken, { path: '/' });
        window.location.replace(`/crm/home?no-cache=${generateCacheBuster()}`);
        return 'v2';
      } else {
        await this.initialize(response.accessToken);
        return 'v1';
      }
    } catch (e) {
      if (v2) {
        window.location.replace(`/crm/home?no-cache=${generateCacheBuster()}`);
        return 'v2';
      }
      const { response } = e;
      if (response.status === 404 && response.headers['upgrade-api'] === 'V2') {
        const cookies = new Cookies();

        cookies.set('origin', 'v2', { path: '/', maxAge: 3600 * 24 * 365 });
        return await this.signIn(payload, true);
      } else {
        return Promise.reject(response);
      }
    }
  };

  signOut = () => {
    for (const func of this.logoutHandlers) {
      func();
    }
    this.token = null;
    this.me = null;
    this.logoutHandlers = [];
    window.sessionStorage.removeItem('authToken');
    delete $http.defaults.headers.common.Authorization;
  };

  addLogoutListener = (func) => {
    this.logoutHandlers.push(func);
  };

  removeLogoutListener = (func) => {
    const index = this.logoutHandlers.findIndex(func);
    if (index === -1) return;
    this.logoutHandlers.splice(index, 1);
  };

  loadIsNewCustomerOption = async () => {
    const resp = await services.crm.customer.convertTime();
    if (resp) this.isNewCustomerOption = resp.data.convertAt;
  };
}
const AuthContext = createContext();

// hooks
export const useAuth = () => {
  return useContext(AuthContext);
};

// provider
export const AuthProvider = (props) => {
  const store = useMemo(() => new Auth(), []);
  return <AuthContext.Provider value={store} {...props} />;
};
