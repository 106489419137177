import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { useModal } from 'hooks/useModal';

const Modal = ({ modal }) => {
  const modalStore = useModal();

  useEffect(() => {
    document.addEventListener('keydown', onKeydown);

    return () => {
      document.removeEventListener('keydown', onKeydown);
    };
  });

  const onKeydown = (e) => {
    //Modal이 이중 이상으로 열린경우, 가장 마지막에 열린 모달의 프롭스를 꺼내도록 함
    let data =
      modalStore.list.length > 1
        ? modalStore.list[modalStore.list.length - 1]
        : modal;

    if (e.key === 'Escape') {
      e.stopPropagation();
      e.preventDefault();

      if (data.options?.canClose === false) {
        return;
      }

      onClose();
    }
  };

  const onClose = (event) => {
    // event can be '0', so don't do nill check for event like: !event
    if (modal.resolve) modal.resolve(event);
    modalStore.pop();
  };

  const onClickOverlay = (event) => {
    event.stopPropagation();

    if (modal.options?.canClose === false) {
      return;
    }

    if (modal.options !== undefined) {
      if (modal.options.type === 'CREATE_FILE') return; // 펜차트 이미지 업로드
      if (modal.options.type == 'SUCCESS' || modal.options.type == 'ERROR')
        return; // API 호출 후 응답 확인창
    }
    if (event.target.classList.contains('modal-overlay')) {
      modalStore.pop();
      return;
    }
  };

  const modalWithProp = () => {
    if (!modal.component) return;

    let ModalComponent = modal.component;
    return <ModalComponent options={modal.options} close={(e) => onClose(e)} />;
  };

  return (
    <div className={`modal modal-overlay`} onMouseDown={onClickOverlay}>
      <div className="modal-container">{modalWithProp()}</div>
    </div>
  );
};

Modal.propTypes = {
  modal: PropTypes.object,
};

export default observer(Modal);
