import React, { useEffect, useState, useCallback } from 'react';
import { observer } from 'mobx-react';
import hooks from 'hooks';
import ModalPaymentDetail from 'components/modals/ModalPaymentDetail';
import DataTableAlpha from 'components/data-table/DataTableAlpha';
import { $case, pluralize, translate } from 'filters';
/*import ModalMessageSendToPage from 'components/modals/ModalMessageSendToPage';*/
import ListFilter from 'components/pages/crm/ListFilter';
import moment from 'moment';
import models from 'models';
import { $qb } from 'scripts/querybuilder';
import { useEvent } from 'hooks/useEvent';
import { useModal } from 'hooks/useModal';
import { useServices } from 'hooks/useServices';
import { useToast } from 'hooks/useToast';
import { AxiosErrorCodes } from 'axiosErrorCodes';
import styled, { css } from 'styled-components';

const DiscountReasonInput = styled.input`
  width: 100%;
  height: 34px;
  padding: 8px 20px;
  border-color: #d7e3f1;
  font-size: 14px;
  min-height: 10px;
  border-radius: 4px;
  background-color: #f3f8ff;
  color: #000;
  border: 1px solid #dee2ec;

  ${({ disabled }) =>
    disabled &&
    css`
      background-color: #efeff4 !important;
      color: #ceced7 !important;
    `}
`;

const Payments = () => {
  const eventBus = useEvent();
  const modal = useModal();
  const services = useServices();
  const toast = useToast();
  const [totalSearchList, setTotalSearchList] = useState({});
  const defaultParams = $qb()
    .limit(20)
    .orderBy('firstPaidAt desc')
    .customParam(
      'startAt',
      moment(new Date().setDate(new Date().getDate() - 30)).format('YYYY-MM-DD')
    )
    .customParam('endAt', moment(new Date()).format('YYYY-MM-DD'));
  const [data, setData] = useState(null);
  const [total, setTotal] = useState(null);
  const [params, setParams] = useState(defaultParams);
  const [checkItems, setCheckItems] = useState([]);
  const [downloadBtnDisabledFlag, setDownloadBtnDisabledFlag] = useState(false);
  const [paymentModel, setPaymentModels] = useState(models.crm.payment);

  const callApi = async () => {
    try {
      const requestParams = params.build();

      //status 가 미수인 경우
      if (requestParams && requestParams.status) {
        if (requestParams.status === 'unpaid' && requestParams.refundStatus)
          delete requestParams.refundStatus;

        if (requestParams.status === 'paid')
          requestParams.refundStatus = 'none';
      }

      // refundStatus 가 none 이 아닐때
      if (
        requestParams &&
        requestParams.status &&
        requestParams.status.includes('refund')
      ) {
        requestParams.refundStatus = requestParams.status;
        requestParams.status = 'paid';
      }

      const resp = await services.crm.payment.all(requestParams);

      if (!resp) return;
      resp.data.map((v) => {
        v['paymentTotalItems'] = [
          ...v.paymentTreatmentItems,
          ...v.paymentProducts,
        ];
        // 페이지네이션시에도 체크박스가 유지되도록
        if ((checkItems || []).find((f) => f.id === v.id) !== undefined) {
          v.$$checked = true;
        } else {
          v.$$checked = false;
        }
        return v;
      });

      setData(resp.data);
      setTotal(resp.total);
    } catch (e) {
      console.log(e.description);
    }
  };

  const codeCallApi = useCallback(async () => {
    const totalResp = {};
    const treatmentParams = { limit: 10000, visible: true };
    const treatmentResp = await services.crm.treatment.categories.items_categories_v2(
      treatmentParams
    );
    if (!treatmentResp) return;
    totalResp.surgery_category = treatmentResp.data;

    const treatmentItemResp = await services.crm.treatment.items.all_v2(
      treatmentParams
    );
    if (!treatmentItemResp) return;
    totalResp.surgery_item_product_name = treatmentItemResp.data;
    totalResp.surgery_item_name = treatmentItemResp.data;

    const productResp = await services.crm.payment.product.all(treatmentParams);
    if (!productResp) return;
    totalResp.product_name = productResp.data;

    let counselorsParams = { duty: '상담사', userStatus: 'active', limit: 300 };
    const counselorsResp = await services.crm.user.duty(counselorsParams);
    if (!counselorsResp) return;
    totalResp._counselors = counselorsResp.data;

    setTotalSearchList(totalResp);
  }, [
    services.crm.treatment,
    services.crm.treatment.categories,
    services.crm.payment,
    services.crm.user,
  ]);

  const onParams = (p) => {
    setParams(p);
    codeCallApi();
    callApi();
  };

  useEffect(() => {
    eventBus.on('callApi', callApi);
    callApi();
    codeCallApi();

    return () => eventBus.off('callApi');
  }, [eventBus]);

  const openModalDiscountReason = (row) => {
    const discountReason =
      row['@@paymentTotalItems'].discountReason === null
        ? ''
        : row['@@paymentTotalItems'].discountReason;
    modal.basic({
      title: '할인사유',
      markup: (
        <DiscountReasonInput disabled={true} value={discountReason.content} />
      ),
      buttons: [{ text: 'CONFIRM', class: 'btn-primary' }],
    });
  };

  const openModalPayoutsDetail = (row) => {
    modal.custom({
      component: ModalPaymentDetail,
      options: { row },
    });
  };

  const onDeletePayment = useCallback(
    async (id) => {
      try {
        await services.crm.payment.delete(id);
        modal
          .confirm({
            type: 'SUCCESS',
            msg: '삭제되었습니다.',
          })
          .then(() => {
            callApi();
          });
      } catch (e) {
        console.log(e.description);
      }
    },
    [services.crm.payment, toast]
  );

  const onClickDeletePayment = (payment) => {
    modal
      .basic({
        body: '정말로 삭제하시겠습니까?',
        buttons: [
          {
            text: 'CANCEL',
            class: 'btn-default',
          },
          {
            text: 'CONFIRM',
            class: 'btn-primary',
          },
        ],
      })
      .then((idx) => {
        if (idx === 1) {
          onDeletePayment(payment.id);
        }
      });
  };

  /*const onClickSendSms = () => {
    if (checkItems.length) {
      modal.custom({
        component: ModalMessageSendToPage,
        options: {
          page: 'payment',
          data: checkItems,
        },
      });
    } else {
      toast.error('문자전송할 수납을 선택하세요.');
    }

    return;
  };*/

  const onAllCheckAction = (all, allChecked) => {
    if (!allChecked) {
      //전체 선택인 경우 allChecked:false
      //merged인 경우 걸러내기 .filter( v=> v['@@merged'] === false)
      const list = (all || [])
        .filter((v) => v['@@merged'] === false)
        .filter((v) => {
          return (
            v.$$checked === true &&
            checkItems.find((f) => f.id === v.id) === undefined
          );
        });
      setCheckItems([...checkItems, ...list]);
    } else {
      //전체 해제인 경우
      // eslint-disable-next-line array-callback-return
      const list = (checkItems || []).filter((v) => {
        if (all.find((f) => f.id === v.id) === undefined) {
          return v;
        }
      });
      setCheckItems([...list]);
    }
  };

  const onAction = (obj) => {
    let payment = obj.row;
    let model = obj.key;
    const event = obj.event;
    const classList = event.target.classList;

    if (model.type === 'multiCheck') {
      let data = [...checkItems];
      if (payment.$$checked) {
        if ((data || []).find((v) => v.id === payment.id) === undefined) {
          data.push(payment);
        }
        setCheckItems([...data]);
      } else {
        let list = data.filter((v) => {
          return v.id !== payment.id;
        });

        setCheckItems([...list]);
      }
      return;
    }

    if (classList.contains('btn-unpaid')) {
      if (payment.unpaidAmount === 0) {
        toast.success('미수액이 없습니다.');
      } else {
        const detail = {
          payment: { componentFlag: 'unpaidPayments', row: payment },
        };
        hooks.openCustomerChart({
          customer: payment.customer,
          payment,
          openFlag: detail,
        });
      }
      return;
    }

    if (classList.contains('btn-refunds')) {
      const detail = { payment: { componentFlag: 'refunds', row: payment } };
      hooks.openCustomerChart({
        customer: payment.customer,
        payment,
        openFlag: detail,
      });
      return;
    }

    if (classList.contains('c-red')) {
      onClickDeletePayment(payment);
      return;
    }

    if (['고객명', 'CUSTOMER', 'STATUS'].indexOf(model.title) !== -1) {
      hooks.openCustomerChart({ customer: payment.customer, payment });
    }

    if (classList.contains('btn-discount-reason')) {
      //할인 사유 팝업
      openModalDiscountReason(payment);
      return;
    }

    if (classList.contains('btn-payout-amount')) {
      //수납이력 상세보기 팝업
      openModalPayoutsDetail(payment);
      return;
    }
  };

  const excelDownload = (resp) => {
    if (resp !== undefined) {
      //리스폰스 받고 로딩팝업 닫은 후 내려받기 버튼 활성화
      modal.close({
        type: 'LOADING',
      });
      setDownloadBtnDisabledFlag(false);

      const url = window.URL.createObjectURL(new Blob([resp]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `${translate($case.toConst(pluralize('payment')))}.xlsx`
      );
      document.body.appendChild(link);
      link.click();
    }
  };

  const settingModels = useCallback(() => {
    const dataCallList = [
      'surgery_category',
      'surgery_item_product_name',
      'surgery_item_name',
      'product_name',
      '_counselors',
    ];
    const model = { ...paymentModel };
    let found;
    dataCallList.forEach((v) => {
      found = (model.keys || []).find(
        (o) => o.title === $case.toConst(pluralize(v))
      );
      if (!found) return;
      found.selectOptions = (totalSearchList[v] || []).map((o) => {
        o.$$label = o['name'];
        o.$$value = o.id;
        return o;
      });
    });
    setPaymentModels(model);
  }, [totalSearchList]);

  useEffect(() => {
    settingModels();
  }, [settingModels]);

  const onClickExcelDownload = useCallback(async () => {
    try {
      setDownloadBtnDisabledFlag(true);
      modal.loading({
        type: 'LOADING',
        msg:
          '다운로드중입니다. 데이터 양에 따라 \n 수 초 ~ 수 분이 걸릴 수 있습니다.',
      });

      let resp = undefined;
      resp = await services.crm.payment.excel_download_v2(params.build());

      // 5000 건 이하인 경우
      if (resp.type.indexOf('sheet') !== -1) {
        excelDownload(resp);
      } else {
        const reader = new FileReader();
        reader.addEventListener('loadend', async (e) => {
          let res;
          res = await services.crm.payment.excel_check_download_v2({
            fileKey: JSON.parse(e.target.result).fileKey,
          });

          let startInterval = setInterval(async () => {
            res = await services.crm.payment.excel_check_download_v2({
              fileKey: JSON.parse(e.target.result).fileKey,
            });
            if (res.type.indexOf('sheet') !== -1) {
              excelDownload(res);
              clearInterval(startInterval);
            }
          }, 20000);
        });
        reader.readAsText(resp);
      }
    } catch (e) {
      modal.close({
        type: 'LOADING',
      });
      setDownloadBtnDisabledFlag(false);

      let critical = true;
      if (e.code === AxiosErrorCodes.aborted) {
        toast.error('다운로드가 취소되었습니다.');
        critical = false;
      } else {
        toast.error(
          '데이터가 많아 실패했습니다. 우노케어 CRM CS팀에 문의해주세요.'
        );
      }

      if (critical) {
        throw e;
      }
    }
  }, [modal, services, toast, params]);

  return (
    <div className={`${pluralize('payment')} list inquiry`}>
      <div className="page-navi">
        <span className="title">{translate('SEARCH')}</span>
        <span className="title">
          {translate($case.toConst(pluralize('payment')))} ({total || 0})
        </span>
      </div>
      <div className="route-top">
        <ListFilter
          model={paymentModel}
          modelName={'payment'}
          dateSetButton={true}
          params={params}
          setParams={setParams}
          onParams={onParams}
          defaultParams={defaultParams}
        />
        <div className="display-table m-t-16">
          <div className="flex-row">
            <button
              className="btn btn-basic _small m-r-8 btn-excel"
              disabled={downloadBtnDisabledFlag}
              onClick={onClickExcelDownload}
            >
              Excel 내려받기
            </button>
            {/* <button
              className="btn btn-basic _small"
              onClick={onClickSendSms}>문자 전송 </button> */}
          </div>
        </div>
      </div>

      <DataTableAlpha
        model={paymentModel}
        data={data}
        total={total}
        params={params}
        onParams={onParams}
        onAction={onAction}
        hasLine
        mergeColumn={[
          {
            categoryName: null,
            name: null,
            quantity: null,
            price: null,
            priceVatInclude: null,
            discountAmount: null,
          },
        ]}
        onAllCheckAction={onAllCheckAction}
      />
    </div>
  );
};

export default observer(Payments);
