import * as $http from 'axios';

const treatment = {
  create: (treatment) => $http.post('treatments', treatment),
  create_v2: (treatment) => $http.post('treatments/v2', treatment),
  all: (params) => $http.get('treatments', { params }),
  all_v2: (params) => $http.get('treatments/v2', { params }),
  detail: (treatmentId) => $http.get(`treatments/${treatmentId}`),
  update: (treatment) => $http.put(`treatments/${treatment.id}`, treatment),
  update_v2: (treatment) =>
    $http.put(`treatments/v2/${treatment.id}`, treatment),
  delete: (treatmentId) => $http.delete(`treatments/${treatmentId}`),
  delete_v2: (treatmentId) => $http.delete(`treatments/v2/${treatmentId}`),
  excel_download_v2: (params) =>
    $http.get('/treatments/v2/excel', { params, responseType: 'blob' }),
  excel_check_download_v2: (params) =>
    $http.get('/treatments/v2/excel/check/download', {
      params,
      responseType: 'blob',
    }),
};

treatment.items = {
  create: (model) => $http.post(`treatments/items`, model),
  all: (params) => $http.get(`treatments/items`, { params }),
  all_v2: (params) => $http.get(`treatments/v2/items`, { params }),
  visible: (model) => $http.put(`treatments/items/visible/${model.id}`, model),
  visible_v2: (model) =>
    $http.put(`treatments/v2/items/visible/${model.id}`, model),
  delete: (modelId) => $http.delete(`treatments/items/${modelId}`),
  delete_v2: (modelId) => $http.delete(`treatments/v2/items/${modelId}`),
};

treatment.categories = {
  create: (model) => $http.post(`treatments/categories`, model),
  create_v2: (model) => $http.post(`treatments/v2/items/categories`, model),
  all: (params) => $http.get(`treatments/categories`, { params }),
  items_categories: (params) =>
    $http.get('treatments/items/categories', { params }),
  items_categories_v2: (params) =>
    $http.get('treatments/v2/items/categories', { params }),
  detail: (modelId) => $http.get(`treatments/categories/${modelId}`),
  update: (model) => $http.put(`treatments/categories/${model.id}`, model),
  update_v2: (model) =>
    $http.put(`treatments/v2/items/categories/${model.id}`, model),
  visible: (model) =>
    $http.put(`treatments/categories/visible/${model.id}`, model),
  visible_v2: (model) =>
    $http.put(`treatments/v2/items/categories/visible/${model.id}`, model),
  multi_update: (payload) => $http.put(`treatments/categories/multi`, payload),
  multi_update_v2: (payload) =>
    $http.put(`treatments/v2/items/categories/multi`, payload),
  delete: (modelId) => $http.delete(`treatments/categories/${modelId}`),
  delete_v2: (modelId) =>
    $http.delete(`treatments/v2/items/categories/${modelId}`),
};

export default treatment;
