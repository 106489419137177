import React from 'react';
import useCtiStore from './hooks/useCtiStore';
import './ctiStats.scss';

export default function CtiStats() {
  const ctiStore = useCtiStore();

  function onClickMissed() {
    ctiStore.setShowHistory(true);
    ctiStore.setHistoryFilter('missed');
  }

  function onClickRecall() {
    ctiStore.setShowHistory(true);
    ctiStore.setHistoryFilter('recall');
  }

  return (
    <div className="cti-stats">
      <div className="cti-stat-item" onClick={onClickMissed}>
        <div className="cti-stat-label missed"></div>
        <div className="cti-stat-count">{ctiStore.missedCount}</div>
      </div>
      <div className="cti-stat-item" onClick={onClickRecall}>
        <div className="cti-stat-label recall"></div>
        <div className="cti-stat-count">{ctiStore.recallCount}</div>
      </div>
    </div>
  );
}
