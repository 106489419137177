import React from 'react';
import MessageApp from './Layouts/MessageApp';
import ModalMessageBoilerplateSelector from 'components/modals/codes/message-codes/ModalMessageBoilerplateSelect';
import PropTypes from 'prop-types';
import { translate } from 'filters';
import { observer } from 'mobx-react';
import styled from 'styled-components';

const Input = styled.input`
  min-height: 10px;
  padding: 16px 20px;
  border-radius: 4px;
  background-color: #f3f8ff;
  border: 1px solid #dee2ec;
  width: 100%;
  height: 34px;

  font-size: 0.9em;
  padding-left: 0.5em;
  border-color: #f1f3f5;

  font-family: var(--mobile-screen-font);
  line-height: 1.4em;

  &:focus {
    box-shadow: 1px 1px 4px 0 rgba(181, 176, 176, 0.56);
  }
`;

const ScreenBoilerplate = ({ onChange, obj, setObj }) => {
  return (
    <div className={`message-screen-boilerplate`}>
      <MessageApp
        onChange={onChange}
        obj={obj}
        setObj={setObj}
        modalBoilerplateSelector={ModalMessageBoilerplateSelector}
        hideBabitalkLink
        titleComponent={<InputBoilerplateType obj={obj} onChange={onChange} />}
      />
    </div>
  );
};

const InputBoilerplateType = observer(({ obj, onChange }) => {
  return (
    <>
      <div className="nav-bar-title">
        <div className="title-label ">
          <span className="label-required">
            {translate('LABEL_BOILERPLATE_CATEGORY')}
          </span>
        </div>
        <div className="title-input">
          <Input
            name={'category'}
            value={obj.category}
            onChange={onChange}
            placeholder={translate('PLACEHOLDER_BOILERPLATE_CATEGORY')}
          />
        </div>
      </div>
    </>
  );
});

ScreenBoilerplate.propTypes = {
  onChange: PropTypes.func,
  obj: PropTypes.object,
  setObj: PropTypes.func,
};

export default ScreenBoilerplate;
