import React from 'react';
import { numArray } from 'filters';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import arrow from 'assets/images/icon/icon_pagination_arrow.png';
import disabledArrow from 'assets/images/icon/icon_pagination_arrow_disable.png';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  margin-left: 180px;
  max-width: 80%;
`;

const PageButton = styled.button`
  :not(:last-child) {
    margin-right: 20px;
  }
  ${({ current }) =>
    current
      ? css`
          background-color: transparent;
          font-weight: 600;
          color: #273142;
        `
      : css`
          color: #a4b9d2;
          font-size: 12px;
          line-height: 1.4;
          cursor: pointer;
        `}
`;

const MoveButton = styled.button`
  width: 26px;
  height: 26px;
  border-radius: 4px;
  border: solid 1px #dee2ec;
  background-image: url(${arrow});
  background-repeat: no-repeat;
  background-size: 8px;
  background-position: center center;
  background-color: #fff;

  ${({ disable }) =>
    disable &&
    css`
      cursor: initial;
      background-image: url(${disabledArrow});
    `}

  ${({ prev }) =>
    prev &&
    css`
      margin-right: 20px;
      transform: rotate(180deg);
    `}
`;

const Pagination = ({ total, page, displayCount, onChange }) => {
  const currentPart = Math.floor((page - 1) / displayCount);
  const pageStart = 1 + currentPart * displayCount;
  const pageEnd = (currentPart + 1) * displayCount;
  const currentPageArr = numArray(total)
    .map((v) => v + 1)
    .filter((v) => pageStart <= v && v <= pageEnd);

  const prevPages = () => {
    if (currentPart === 0) return;
    onChange(Math.floor((currentPart - 1) * displayCount) + 1);
  };

  const nextPages = () => {
    if (currentPart >= Math.floor((total - 1) / displayCount)) return;
    onChange(Math.floor((currentPart + 1) * displayCount) + 1);
  };
  return (
    <Wrapper>
      <MoveButton
        prev
        disable={currentPart === 0 ? true : false}
        onClick={() => prevPages()}
      />
      {currentPageArr &&
        currentPageArr.map((p) => (
          <PageButton
            onClick={() => onChange(p)}
            key={p}
            current={page === p ? true : false}
          >
            {p}
          </PageButton>
        ))}
      <MoveButton
        disable={
          currentPart >= Math.floor((total - 1) / displayCount) ? true : false
        }
        onClick={() => nextPages()}
      />
    </Wrapper>
  );
};

Pagination.defaultProps = {
  displayCount: 5,
};

Pagination.propTypes = {
  displayCount: PropTypes.number,
  total: PropTypes.number,
  page: PropTypes.number,
  onChange: PropTypes.func,
};

export default Pagination;
