import React, { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import hooks from 'hooks';
import { asAge, translate } from 'filters';
import CustomerAppointments from './CustomerAppointments';
import CustomerConsultings from './CustomerConsultings';
import CustomerTreatments from './CustomerTreatments';
import CustomerSurgeries from './CustomerSurgeries';
import CustomerPayments from './CustomerPayments';
import CustomerPenCharts from './CustomerPenCharts';
import ModalAddChartInfo from 'components/modals/ModalAddChartInfo';
import ModalAllMemo from 'components/modals/ModalAllMemo';
import QuillText from 'components/quill/QuillText';
import CustomerOperationNurseCharts from './CustomerOperationNurseCharts';
import CustomerNurseCharts from './CustomerNurseCharts';
import CustomerSkinManage from './CustomerSkinManage';
import CustomerCallHistory from './CustomerCallHistory';
import PropTypes from 'prop-types';
import { useAuth } from 'store/auth';
import { useEvent } from 'hooks/useEvent';
import { useModal } from 'hooks/useModal';
import { useServices } from 'hooks/useServices';
import { useToast } from 'hooks/useToast';
import { useUI } from 'hooks/useUI';

//스크롤 리스너 등록
const useScroll = (element) => {
  const [state, setState] = useState({
    scrollY: 0,
    height: 0,
  });

  useEffect(() => {
    if (!element) return;

    const onScroll = () => {
      setState({
        scrollY: element.scrollTop,
        height: element.scrollHeight - element.clientHeight,
      });
    };

    element.addEventListener('scroll', onScroll);
    return () => element.removeEventListener('scroll', onScroll);
  }, [element]);

  return state;
};

const CustomerChart = ({
  customerId,
  appointment,
  // consultingRequests,
  consulting,
  treatment,
  surgery,
  payment,
  smsNotification,
  penChart,
  operationNurseChart,
  nurseChart,
  skinManageChart,
  openFlag,
  selectTab,
  closeModal,
  editInfo,
  calledPage,
  consultingRequest,
}) => {
  const auth = useAuth();
  const eventBus = useEvent();
  const modal = useModal();
  const services = useServices();
  const toast = useToast();
  const ui = useUI();

  const forceUpdate = hooks.useForceUpdate();
  const [isAllMemoShow, setIsAllMemoShow] = useState(false);
  const [isMenuFold, setIsMenuFold] = useState(
    ui.isMenuFold && typeof ui.isMenuFold.chartInfo === 'boolean'
      ? ui.isMenuFold.chartInfo
      : false
  );
  const [customer, setCustomer] = useState({});
  const [tabItems, setTabItems] = useState(
    [
      // { key: 'DEPOSIT'},
      // { key: 'INFO' },
      // { key: 'CONSULTATION_INQUIRY' },
      { key: 'APPOINTMENT' },
      { key: 'CONSULTING' },
      { key: 'TREATMENT' },
      { key: 'SURGERY' },
      { key: 'OPERATIONNURSECHART' },
      { key: 'NURSECHART' },
      { key: 'SKINMANAGECHART' },
      { key: 'PAYMENT' },
      // { key: 'SMS' },
      { key: 'PENCHART' },
      { key: 'CALLHISTORYCHART' },
    ]
      .filter((tab) => {
        if (!auth.me.callManagement && tab.key === 'CALLHISTORYCHART')
          return false;

        return true;
      })
      .map((tab) => ({
        key: tab.key,
        title: tab.key,
        count: 0,
        selected: false,
        disabled: tab.key !== 'INFO' && !(customer || {}).id,
      }))
  );

  const callApi = useCallback(async () => {
    try {
      if (!customerId) return;
      const resp = await services.crm.customer.detail(customerId);
      if (!resp) return;

      tabItems.forEach((tab) => {
        tab.disabled = tab.key !== 'INFO' && !(resp.data || {}).id;
      });
      let data = (resp || {}).data;
      // 전화번호 '-' 처리
      data.phoneNumber = (data.phoneNumber || '')
        .replace(/[^0-9]/g, '')
        .replace(
          /(^02|^0505|^1[0-9]{3}|^0[0-9]{2})([0-9]+)?([0-9]{4})/,
          '$1-$2-$3'
        )
        .replace('--', '-');
      // 주민등록번호 가공
      if (data.ssnHead) {
        if (data.ssnHead.length === 7) {
          data.ssnTemp =
            data.ssnHead.slice(0, 6) + '-' + data.ssnHead.slice(-1) + '******';
        } else {
          data.ssnTemp = data.ssnHead + '-*******';
        }
      }

      // 나이 계산
      if (data.birthday && data.birthday.length === 10) {
        let age = asAge(data.birthday);
        data.age = age;
      }

      setCustomer({ ...data });
    } catch (e) {
      toast.error(e.description);
    }
  }, [services.crm.customer, toast, customerId, tabItems]);

  const chartCountApi = useCallback(async () => {
    if (!customerId) return;
    const resp = await services.crm.chartCount.count({ customerId });
    if (!resp) return;
    if (!resp.data) return;
    setTabItems(
      tabItems.map((v) => {
        if (v.key === 'APPOINTMENT') v.count = resp.data.appointmentCount;
        if (v.key === 'CONSULTING') v.count = resp.data.consultingCount;
        if (v.key === 'TREATMENT') v.count = resp.data.treatmentCount;
        if (v.key === 'SURGERY') v.count = resp.data.surgeryCount;
        if (v.key === 'OPERATIONNURSECHART')
          v.count = resp.data.nurseOperationCount;
        if (v.key === 'NURSECHART') v.count = resp.data.nurseCareCount;
        if (v.key === 'SKINMANAGECHART') v.count = resp.data.nurseSkinCount;
        if (v.key === 'PAYMENT') v.count = resp.data.paymentCount;
        if (v.key === 'PENCHART') v.count = resp.data.fileCount;
        return v;
      })
    );
  }, [services.crm.chartCount, customerId, tabItems]);

  const onClickTab = useCallback(
    (selectedTab) => {
      if (!customerId) return;

      let found = tabItems.find((tab) => tab.key === selectedTab);
      if (!found || found.disabled) {
        toast.error('ERROR_SELECT_CUSTOMER_FIRST');
        return;
      }

      tabItems.forEach((tab) => (tab.selected = tab.key === selectedTab));
      forceUpdate();
    },
    [forceUpdate, tabItems, toast, customerId]
  );

  const Tabs = ({ tabs, onClickTab }) => (
    <div className="tabwrap">
      <ul>
        {tabs.map((tab, idx) => (
          <li
            key={idx}
            onClick={() => (onClickTab ? onClickTab(tab.key) : null)}
            className={`tab ${tab.selected ? 'selected' : ''} ${
              tab.disabled ? 'disabled' : ''
            }`}
          >
            <a>{`${translate(tab.title)} ${
              tab.key === 'PENCHART' || tab.key === 'CALLHISTORYCHART'
                ? ``
                : `(${tab.count})`
            }`}</a>
          </li>
        ))}
      </ul>
    </div>
  );

  Tabs.propTypes = {
    tabs: PropTypes.array,
    onClickTab: PropTypes.func,
  };

  const authorityGroupChangeTab = () => {
    //권한에 따른 탭 메뉴 보여주기
    const changeTab = tabItems.filter((v) => {
      //병원 요청에 의해 통합차트 메뉴 권한 기능 주석처리
      // // if(v.key !== 'DEPOSIT') {
      //   if(v.key === 'INFO') {
      //     return auth.me.authorityGroup.customer === 'write'
      //   // } else if(v.key === 'CONSULTATION_INQUIRY') {
      //   //   return auth.me.authorityGroup.consultationInquiry === 'write'
      //   } else {
      //     return auth.me.authorityGroup[v.key.toLowerCase()] === 'write'
      //   }
      // // }
      return v;
    });

    setTabItems(changeTab);
  };

  const init = useCallback(async () => {
    try {
      authorityGroupChangeTab();
      await callApi();
      await chartCountApi();
      if (selectTab !== undefined) return onClickTab(selectTab);
      if (appointment) return onClickTab('APPOINTMENT');
      // if (consultingRequests) return onClickTab('CONSULTATIONINQUIRY')
      if (consulting) return onClickTab('CONSULTING');
      if (treatment) return onClickTab('TREATMENT');
      if (surgery) return onClickTab('SURGERY');
      if (payment) return onClickTab('PAYMENT');
      // if (smsNotification) return onClickTab('SMS')
      if (penChart) return onClickTab('PENCHART');
      if (operationNurseChart) return onClickTab('OPERATIONNURSECHART');
      if (nurseChart) return onClickTab('NURSECHART');
      if (skinManageChart) return onClickTab('SKINMANAGECHART');
      onClickTab('APPOINTMENT');
    } catch (e) {
      toast.error(e.description);
    }
  }, [
    toast,
    callApi,
    chartCountApi,
    onClickTab,
    appointment,
    consulting,
    treatment,
    surgery,
    payment,
    smsNotification,
    penChart,
    operationNurseChart,
    nurseChart,
    skinManageChart,
  ]);

  useEffect(() => {
    eventBus.on('customerCallApi', callApi);
    eventBus.on('chartCountCallApi', chartCountApi);
    init();

    return () => {
      eventBus.off('customerCallApi');
      eventBus.off('chartCountCallApi');
    };
  }, [eventBus]);

  const onClickEdit = () => {
    modal.custom({
      component: ModalAddChartInfo,
      options: { customer, editInfo: true },
    });
  };

  const onClickFold = (isMenuFold) => {
    ui.setUiState('isMenuFold', { chartInfo: isMenuFold });
    setIsMenuFold(isMenuFold);
  };

  const [scrollElement, setScrollElement] = useState(null);
  const { scrollY, height } = useScroll(scrollElement);
  // 차트함
  if (!editInfo && customerId)
    return (
      <div className="modal-custom-chart">
        <div
          className={`chart-info hidden-scroll ${isMenuFold ? 'short' : ''}`}
        >
          <button
            className={`btn btn-fold ${isMenuFold ? 'full' : ''}`}
            onClick={() => onClickFold(!isMenuFold)}
          />
          <div className="cust">
            <p className="fig">
              <img alt="" />
            </p>
            <div className="desc">
              <div style={{ width: '80%', float: 'left' }}>
                <p className="name">
                  {(customer || {}).name}
                  {!isMenuFold &&
                    `(${
                      (customer || {}).sex === 'male'
                        ? '남성'
                        : (customer || {}).sex === 'female'
                        ? '여성'
                        : '-'
                    } `}
                  {!isMenuFold && `/${(customer && customer.age) || '-'}/`}
                  {!isMenuFold &&
                    `${
                      customer && customer.birthday
                        ? customer.birthday.slice(2, 4) +
                          customer.birthday.slice(5, 7) +
                          customer.birthday.slice(-2)
                        : '-'
                    })`}
                  {/* {obj.birthday && <span>(만 29세)</span>} */}
                </p>
                <p className="numb">({(customer || {}).chartNo})</p>
              </div>
              {!isMenuFold && (
                <div style={{ width: '20%', float: 'right' }}>
                  <button
                    className="btn btn-sm btn-basic _ssmall m-l-8"
                    onClick={onClickEdit}
                  >
                    수정
                  </button>
                </div>
              )}
              {/* <div className="btnwrap">
              <a href="#" className="btn btn-basic _small m-r-8">문자보내기</a>
              <a href="#" className="btn btn-normal _small">전화</a>
            </div> */}
            </div>
          </div>
          {/* <div className="cust-info">
          <dl className="line">
            <dt>예수금</dt>
            <dd className="cost c-blue t-under">200,000원</dd>
            <dd className="btnwrap">
              <a href="#" className="btn btn-normal _ssmall">차감</a>
              <a href="#" className="btn btn-basic _ssmall">충전</a>
            </dd>
          </dl>
          <dl className="line">
            <dt>총 수납액</dt>
            <dd className="cost">3,600,000원</dd>
            <dd className="btnwrap"></dd>
          </dl>
          <dl className="line">
            <dt>미수액</dt>
            <dd className="cost c-blue t-under">0원</dd>
            <dd className="btnwrap">
              <a href="#" className="btn btn-normal _ssmall">수납</a>
            </dd>
          </dl>
        </div> */}
          <div className="cust-detail">
            <div className="data-table">
              <table>
                <tbody>
                  <tr>
                    <th>생년월일</th>
                    <td className="left">{(customer || {}).birthday || '-'}</td>
                    <th>주민등록번호</th>
                    <td>{(customer || {}).ssnTemp || '-'}</td>
                  </tr>
                  <tr>
                    <th>내/외국인</th>
                    <td className="left">
                      {(customer || {}).type === 'domestic'
                        ? '내국인'
                        : (customer || {}).type === 'foreigner'
                        ? '외국인'
                        : '-'}
                    </td>
                    <th>키</th>
                    <td>
                      {customer && customer.height
                        ? customer.height + 'cm'
                        : '-'}
                    </td>
                  </tr>
                  <tr>
                    <th>혈액형</th>
                    <td className="left">
                      {customer &&
                      customer.bloodType &&
                      customer.bloodType !== '-1'
                        ? customer.bloodType + '형'
                        : '-'}
                    </td>
                    <th>몸무게</th>
                    <td>
                      {customer && customer.weight
                        ? customer.weight + 'kg'
                        : '-'}
                    </td>
                  </tr>
                  <tr>
                    <th>문자수신</th>
                    <td className="left">
                      {(customer || {}).smsEnabled ? '동의' : '거부'}
                    </td>
                    <th>마케팅수신</th>
                    <td>
                      {(customer || {}).eventSmsEnabled ? '동의' : '거부'}
                    </td>
                  </tr>
                  <tr>
                    <th>전화번호</th>
                    <td colSpan="3">{(customer || {}).phoneNumber || '-'}</td>
                  </tr>
                  <tr>
                    <th>관심항목</th>
                    <td colSpan="3">
                      {(customer || {}).favorites &&
                      customer.favorites.length > 0
                        ? (customer || {}).favorites
                            .map((v) => v.name)
                            .join(', ')
                        : '-'}
                    </td>
                  </tr>
                  <tr>
                    <th>담당상담사</th>
                    <td className="left">
                      {customer && customer.counselor
                        ? customer.counselor.name
                        : '-'}
                    </td>
                    <th>담당의사</th>
                    <td>
                      {customer && customer.doctor ? customer.doctor.name : '-'}
                    </td>
                  </tr>
                  <tr>
                    <th>내원경로</th>
                    <td className="left">
                      {((customer || {}).acquisitionChannel &&
                        (customer || {}).acquisitionChannel.name) ||
                        '-'}
                    </td>
                    <th>소개자</th>
                    <td>
                      <div style={{ wordBreak: 'break-all' }}>
                        {(customer || {}).recommenderName
                          ? (customer || {}).recommenderChartNo
                            ? `${(customer || {}).recommenderName} (${
                                (customer || {}).recommenderChartNo
                              })`
                            : (customer || {}).recommenderName
                          : '-'}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>고객등급</th>
                    <td className="left">
                      {((customer || {}).level &&
                        (customer || {}).level.name) ||
                        '-'}
                    </td>
                    <th>직업</th>
                    <td colSpan="3">
                      {((customer || {}).job && (customer || {}).job.name) ||
                        '-'}
                    </td>
                  </tr>
                  <tr>
                    <th>불만사항</th>
                    <td colSpan="3">
                      {((customer || {}).complaint &&
                        (customer || {}).complaint.content) ||
                        '-'}
                    </td>
                  </tr>
                  <tr>
                    <th>국가/지역</th>
                    <td colSpan="3">
                      {((customer || {}).region &&
                        (customer || {}).region.name) ||
                        '-'}
                    </td>
                  </tr>
                  <tr>
                    <th>주소</th>
                    <td colSpan="3">{(customer || {}).address || '-'}</td>
                  </tr>
                  <tr>
                    <th>메모</th>
                    <td colSpan="3">
                      <QuillText value={(customer || {}).memo || '-'} />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div
          id="scrollListener"
          ref={(node) => setScrollElement(node)}
          className={`chart-conts ${(
            (tabItems.find((tab) => tab.selected) || {}).key || ''
          ).toLowerCase()} ${isMenuFold ? 'short' : ''} `}
        >
          {!isAllMemoShow && (
            <button
              className="btn-all-memo"
              onClick={() => setIsAllMemoShow(true)}
            >
              <p>모든메모보기</p>
            </button>
          )}
          {isAllMemoShow && (
            <ModalAllMemo
              customerId={customerId}
              close={() => setIsAllMemoShow(false)}
            />
          )}
          <div className="head flex-row">
            <Tabs tabs={tabItems} onClickTab={(tab) => onClickTab(tab)} />
            <i onClick={() => closeModal()} className="zmdi zmdi-close" />
          </div>
          <>
            {(tabItems.find((tab) => tab.selected) || {}).key ===
            'APPOINTMENT' ? (
              <CustomerAppointments
                customer={customer}
                appointment={appointment}
                openFlag={openFlag}
                closeFlag={false}
                calledPage={calledPage}
                closeModal={closeModal}
                consultingRequest={consultingRequest}
              />
            ) : null}
            {(tabItems.find((tab) => tab.selected) || {}).key ===
            'CONSULTING' ? (
              <CustomerConsultings
                customer={customer}
                appointment={appointment}
                consulting={consulting}
                openFlag={openFlag}
                closeFlag={false}
              />
            ) : null}
            {(tabItems.find((tab) => tab.selected) || {}).key ===
            'TREATMENT' ? (
              <CustomerTreatments
                customer={customer}
                appointment={appointment}
                treatment={treatment}
                openFlag={openFlag}
                closeFlag={false}
              />
            ) : null}
            {(tabItems.find((tab) => tab.selected) || {}).key === 'SURGERY' ? (
              <CustomerSurgeries
                customer={customer}
                appointment={appointment}
                surgery={surgery}
                openFlag={openFlag}
                closeFlag={false}
              />
            ) : null}
            {(tabItems.find((tab) => tab.selected) || {}).key === 'PAYMENT' ? (
              <CustomerPayments
                customer={customer}
                appointment={appointment}
                payment={payment}
                openFlag={openFlag}
                closeFlag={false}
              />
            ) : null}
            {(tabItems.find((tab) => tab.selected) || {}).key === 'PENCHART' ? (
              <CustomerPenCharts
                customer={customer}
                penChart={penChart}
                scrollY={scrollY}
                height={height}
              />
            ) : null}
            {(tabItems.find((tab) => tab.selected) || {}).key ===
            'OPERATIONNURSECHART' ? (
              <CustomerOperationNurseCharts
                customer={customer}
                operationNurseChart={operationNurseChart}
              />
            ) : null}
            {(tabItems.find((tab) => tab.selected) || {}).key ===
            'NURSECHART' ? (
              <CustomerNurseCharts
                customer={customer}
                nurseChart={nurseChart}
              />
            ) : null}
            {(tabItems.find((tab) => tab.selected) || {}).key ===
            'SKINMANAGECHART' ? (
              <CustomerSkinManage
                customer={customer}
                skinManageChart={skinManageChart}
              />
            ) : null}
            {(tabItems.find((tab) => tab.selected) || {}).key ===
            'CALLHISTORYCHART' ? (
              <CustomerCallHistory customer={customer} />
            ) : null}
          </>
        </div>
      </div>
    );
};

CustomerChart.propTypes = {
  customerId: PropTypes.number,
  appointment: PropTypes.object,
  consulting: PropTypes.object,
  treatment: PropTypes.object,
  surgery: PropTypes.object,
  payment: PropTypes.object,
  smsNotification: PropTypes.object,
  penChart: PropTypes.object,
  operationNurseChart: PropTypes.object,
  nurseChart: PropTypes.object,
  skinManageChart: PropTypes.object,
  openFlag: PropTypes.object,
  closeFlag: PropTypes.bool,
  selectTab: PropTypes.string,
  closeModal: PropTypes.func,
  editInfo: PropTypes.bool,
  calledPage: PropTypes.string,
};

export default observer(CustomerChart);
