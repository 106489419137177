import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const Wrapper = styled.div`
  display: flex;
`;

const LimitButton = styled.button`
  margin-left: 8px;
  padding: 10px;
  border-radius: 4px;
  border: solid 1px #dee2ec;
  background-color: #ffffff;
  color: #273142;
  font-size: 12px;
  line-height: 1;

  ${({ active }) =>
    active &&
    css`
      border: solid 1px #0060ff;
      color: #0060ff;
      font-weight: 700;
    `}
`;

export const LimitSetter = ({ limit, onLimit }) => {
  return (
    <Wrapper>
      {[10, 20, 50, 100].map((value) => (
        <LimitButton
          onClick={() => onLimit(value)}
          key={value}
          active={value === limit || false}
        >
          {value}
        </LimitButton>
      ))}
    </Wrapper>
  );
};

LimitSetter.propTypes = {
  limit: PropTypes.number,
  onLimit: PropTypes.func,
};
