import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

const OrganizationsDetail = ({ data }) => {
  let allDepartmentList = [];
  const [allDepartmentStr, setAllDepartmentStr] = useState('');

  const createStringToAllDepartment = (d) => {
    const { parent } = d;
    if (parent !== null) {
      const { name } = parent;
      allDepartmentList.push(name);
      createStringToAllDepartment(parent);
    } else {
      setAllDepartmentStr(allDepartmentList.reverse().join(' > '));
    }
  };

  useEffect(() => {
    allDepartmentList = [];
    if (Object.keys(data).length) {
      createStringToAllDepartment(data);
    }
  }, [data]);

  return (
    data.user,
    (
      <div className="organizations-detail organ-section flex-row">
        <div className={`profile flex`}>
          {data.user && data.user.profileImage && (
            <img className="img-cover" alt="" src={data.user.profileImage} />
          )}
        </div>
        <div className="profile-data">
          <table>
            <colgroup>
              <col width="20%" />
              <col width="30%" />
              <col width="20%" />
              <col width="30%" />
            </colgroup>
            <tbody>
              <tr>
                <th>이름</th>
                <td>{data.user !== undefined && (data.user.name || '')}</td>
                <th>생년월일</th>
                <td>{data.user !== undefined && (data.user.birthday || '')}</td>
              </tr>
              <tr>
                <th>직무</th>
                <td>{data.user !== undefined && (data.user.duty || '')}</td>
                <th>직급</th>
                <td>{data.user !== undefined && (data.user.position || '')}</td>
              </tr>
              <tr>
                <th>전체부서</th>
                <td colSpan="3">
                  {Object.keys(data).length ? allDepartmentStr : ''}
                </td>
              </tr>
              <tr>
                <th>휴대폰 번호</th>
                <td>
                  {data.user !== undefined && (data.user.phoneNumber || '')}
                </td>
                <th>내선번호</th>
                <td>
                  {data.user !== undefined && (data.user.telNumber || '')}
                </td>
              </tr>
              <tr>
                <th>이메일(ID)</th>
                <td>{data.user !== undefined && (data.user.email || '')}</td>
                <th>입사일</th>
                <td>
                  {data.user !== undefined && (data.user.employedAt || '')}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    )
  );
};

OrganizationsDetail.propTypes = {
  data: PropTypes.object,
};

export default observer(OrganizationsDetail);
