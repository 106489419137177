import moment from 'moment';
import { currency, currencySymbol } from 'filters';

export default {
  keys: [
    {
      title: '일자',
      columnGroup: false,
      key: 'scheduledAt',
      column: 'scheduledAt',
      // TODO: 추후 정렬 추가 작업시 orderKey, order 주석 해제
      // orderKey: 'scheduledAt',
      // order: 'desc',
      filter: (o) => {
        if (o !== undefined) {
          if (o.scheduledAt === 'total') {
            return 'total';
          } else {
            return `<div><span>${moment(o.scheduledAt).format(
              'YYYY-MM-DD'
            )}</span></div>`;
          }
        }
      },
    },
    {
      title: '성공',
      columnGroup: false,
      key: 'totalSuccessCount',
      column: 'totalSuccessCount',
    },
    {
      title: '실패',
      columnGroup: false,
      key: 'totalFailCount',
      column: 'totalFailCount',
    },
    {
      title: '소진액',
      columnGroup: false,
      key: 'totalAmount',
      column: 'totalAmount',
      filter: (o) => {
        return `${currencySymbol()} ${currency((o || {}).totalAmount || 0)}`;
      },
    },
    {
      title: '바비톡웹링크',
      columnGroup: true,
      columnItems: [
        { title: '링크클릭', key: 'clickCount' },
        { title: '이벤트신청', key: 'consultingRequestCount' },
      ],
    },
  ],
};
