import axios from 'axios';

const init = (auth) => {
  const authToken = window.sessionStorage.getItem('authToken');
  if (authToken)
    axios.defaults.headers.common.Authorization = `Bearer ${authToken}`;
  axios.interceptors.response.use(
    (res) => {
      if (!res) {
        console.error('server might not be responding');
        auth.signOut();
        return;
      }

      // We almost always just need res.data, not the wrapped object
      return res.data;
    },
    (err) => {
      if (
        !err.response ||
        (err.response.status === 404 && err.config.url === '/users/sign_in')
      ) {
        throw err;
      }

      if (err.response.status === 401) {
        auth.signOut();
        return;
      }

      throw err.response.data;
    }
  );

  axios.defaults.baseURL = process.env.REACT_APP_API_URL;
};

const setAuthToken = (authToken) => {
  axios.defaults.headers.common.Authorization = `Bearer ${authToken}`;
  axios.defaults.headers.common['Client-Version'] =
    process.env.REACT_APP_RELEASE_VERSION;
  window.sessionStorage.setItem('authToken', authToken);
};

export { init, setAuthToken };
