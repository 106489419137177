import * as moment from 'moment';
import { translate } from 'filters';

export default {
  keys: [
    {
      //     type: 'multiCheck',
      //     column: '',
      //     checkUse: o => 'false',
      //   },{
      title: 'STATUS',
      noorder: true,
      filter: () => {
        return `<div class="text-center">
              <span class="text-underline c-red">삭제</span>
            </div>`;
      },
    },
    {
      title: 'START_AT',
      column: 'startAt',
      hideColumn: true,
      selectType: 'date',
      customParamFieldName: 'startAt',
      filter: (o) => {
        return `<div>
            <div>${moment(o.scheduledAt).format('YYYY-MM-DD')} ${
          o.startHour
        }</div>
            </div>`;
      },
    },
    {
      title: 'END_AT',
      column: 'endAt',
      hideColumn: true,
      selectType: 'date',
      customParamFieldName: 'endAt',
      filter: (o) => {
        return `<div>
            <div>${moment(o.scheduledAt).format('YYYY-MM-DD')} ${
          o.endHour
        }</div>
            </div>`;
      },
    },
    {
      title: 'SCHEDULED_AT',
      column: 'scheduledAt',
      nosearch: true,
      filter: (o) => {
        return `<div>
            <div>${moment(o.scheduledAt).format('YYYY-MM-DD')} </div>
            <div>${o.startHour} - ${o.endHour}</div>
            </div>`;
      },
    },
    {
      title: '고객명',
      column: 'customer__name',
      noorder: true,
      customParamFieldName: 'name',
      filter: (o) =>
        `<span class="text-underline c-primary">${o.customer.name}</span>`,
    },
    {
      title: 'CHART_NO',
      column: 'customer__chartNo',
      noorder: true,
      customParamFieldName: 'chartNo',
    },
    {
      title: '예약상태',
      column: 'status',
      noorder: true,
      customParamFieldName: 'status',
      selectOptions: [
        'scheduled',
        'canceled',
        'unvisited',
        //'waiting',
        //'treating',
        'consulting_waiting',
        'consulting_during',
        'treatment_waiting',
        'treatment_during',
        //'consulting',
        //'consulted',
        'surgery_waiting',
        'surgery_during',
        'payment_waiting',
        'leave',
        'complete',
        //'absence',
      ].map((key) => ({
        $$label: `APPOINTMENT_STATUS_${key.toUpperCase()}`,
        $$value: key,
      })),
      filter: (o) => {
        if (
          o.status === 'treatment_done' ||
          o.status === 'surgery_done' ||
          o.status === 'consulting_done' ||
          o.status === 'payment_done'
        ) {
          return '완료';
        } else {
          return translate(
            `APPOINTMENT_STATUS_${(o.status || '').toUpperCase()}`
          );
        }
      },
    },
    {
      title: '예약종류',
      column: 'type',
      noorder: true,
      selectOptions: [
        {
          $$label: '상담예약',
          $$value: 'consulting',
        },
        {
          $$label: '진료예약',
          $$value: 'treatment',
        },
        {
          $$label: '시수술예약',
          $$value: 'surgery',
        },
      ],
      customParamFieldName: 'type',
      filter: (o) => {
        const type = o.type;
        switch (type) {
          case 'consulting':
            return '상담예약';
          case 'treatment':
            return '진료예약';
          case 'surgery':
            return '시수술예약';
          default:
            break;
        }
      },
    },
    {
      title: 'DEPARTMENT_CATEGORIES',
      column: 'department__category__name',
      noorder: true,
      type: 'list_mini',
      customParamFieldName: 'departmentCategoryId',
      filter: (o) =>
        `<p>${
          o.department && o.department.category
            ? o.department.category.name
            : ''
        }</p>`,
    },
    {
      title: 'DEPARTMENTS',
      column: 'department__name',
      noorder: true,
      customParamFieldName: 'departmentId',
    },
    {
      title: '_COUNSELORS',
      column: 'counselor__name',
      noorder: true,
      customParamFieldName: 'counselorId',
    },
    {
      title: '_DOCTORS',
      column: 'doctor__name',
      noorder: true,
      customParamFieldName: 'doctorId',
    },
    {
      title: 'ASSISTS',
      column: 'facialist__name',
      noorder: true,
      customParamFieldName: 'facialistId',
    },
    {
      title: 'ACQUISITION_CHANNELS',
      column: 'acquisitionChannel__name',
      noorder: true,
      customParamFieldName: 'acquisitionChannelId',
    },
    {
      title: 'SURGERY_CATEGORIES',
      column: 'surgery_item_category__name',
      noorder: true,
      type: 'list_mini',
      customParamFieldName: 'treatmentCategoryId',
      filter: (o) =>
        `${
          o.type === 'surgery'
            ? `${o.paymentTreatmentItems
                .map((item) => `<p>${item.categoryName || ''}</p>`)
                .join('\n')}`
            : `${o.items
                .map((item) => `<p>${(item.category || {}).name || ''}</p>`)
                .join('\n')}`
        }`,
    },
    {
      title: 'SURGERY_ITEM_NAMES',
      column: 'surgery_item_name__name',
      noorder: true,
      type: 'list',
      customParamFieldName: 'treatmentItemId',
      filter: (o) =>
        `${
          o.type === 'surgery'
            ? `${o.paymentTreatmentItems
                .map((item) => `<p>${item.name || ''}</p>`)
                .join('\n')}`
            : `${o.items.map((item) => `<p>${item.name || ''}</p>`).join('\n')}`
        }`,
    },
    {
      title: '예약메모',
      column: 'memo',
      noorder: true,
      type: 'text',
      nosearch: true,
    },
    {
      title: '예약완료 자동문자 전송설정',
      noorder: true,
      titleStyle: () => {
        return { whiteSpace: 'break-spaces' };
      },
      filter: (o) => {
        if (o.smsSent) {
          return `<span class="sms-sent text-underline">설정</span>`;
        } else {
          return `<span class="sms-not-sent">미설정</span>`;
        }
      },
      thStyle: () => {
        return { width: '110px' };
      },
    },
    {
      title: '작성자',
      column: 'createdName',
      noorder: true,
      nosearch: true,
    },
    {
      title: '작성일',
      column: 'createdAt',
      nosearch: true,
      filter: (o) => {
        return `<div>
            <div>${moment(o.createdAt).format('YYYY-MM-DD')} </div>
            <div>${moment(o.createdAt).format('HH:mm')}</div>
            </div>`;
      },
    },
    // {
    //     title: 'UPDATE_AT',
    //     column: 'updatedAt',
    //     filter: o => {
    //         return `<div>
    //             <div>${moment(o.updatedAt).format('YYYY-MM-DD')}</div>
    //             <div>${moment(o.updatedAt).format('HH:mm:ss')}</div>
    //         </div>`
    //     },
    //     nosearch: true,
    // }
  ],
};
