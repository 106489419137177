export default {
  keys: [
    {
      title: '',
      filter: () => `<button class="btn btn-sm btn-primary">선택</button>`,
    },
    {
      title: '약품명',
      column: 'drugName',
    },
    {
      title: '투여구분',
      column: 'drugType',
      filter: (o) => `
        ${
          o.drugType === 'internal_medicine'
            ? '내복'
            : o.drugType === 'external_use'
            ? '외용'
            : o.drugType === 'injection'
            ? '주사'
            : '기타'
        }  
      `,
    },
    {
      title: '규격',
      column: 'dosage',
    },
    {
      title: '단위',
      column: 'unit',
    },
    {
      title: '급여기준',
      column: 'paidType',
      filter: (o) => {
        let paidType = '';
        switch (o.paidType) {
          case 'paid':
            paidType = '급여';
            break;
          case 'paid_welfare':
            paidType = '보훈급여';
            break;
          case 'paid_stop':
            paidType = '급여정지';
            break;
          case 'deleted':
            paidType = '삭제';
            break;
          case 'impossible':
            paidType = '산정불가';
            break;
          case 'unpaid':
            paidType = '비급여';
            break;
          default:
            break;
        }
        return paidType;
      },
    },
  ],
};
