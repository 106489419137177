import React, { useState, useEffect, useCallback } from 'react';
import { observer } from 'mobx-react';
import Checkbox from 'components/app/Checkbox';
import DataTableAlpha from 'components/data-table/DataTableAlpha';
import ModalAddConsultingRequestsStatusCategories from 'components/modals/ModalAddConsultingRequestsStatusCategories';
import models from 'models';
import { translate } from 'filters';
import { $qb } from 'scripts/querybuilder';
import { useModal } from 'hooks/useModal';
import { useServices } from 'hooks/useServices';
import { useToast } from 'hooks/useToast';

const ConsultingRequestsCategories = () => {
  const modal = useModal();
  const services = useServices();
  const toast = useToast();
  const defaultParams = $qb().limit(10);
  const [params, setParams] = useState(defaultParams);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [hideHidden, setHideHidden] = useState(false);

  const callApi = useCallback(async () => {
    const resp = await services.crm.consultingRequest.requests_categories.all(
      params.build()
    );
    if (!resp) return;

    setData(resp.data);
    setTotal(resp.total);
  }, [services.crm.consultingRequest.requests_categories, params]);

  useEffect(() => {
    callApi();
  }, [callApi]);

  const onParams = (p) => {
    setParams(p);
    callApi();
  };

  const onAction = (obj) => {
    let model = obj.key;
    let object = obj.row;
    let event = obj.event;

    const btnLabel = event.target.innerText;
    if (btnLabel === '수정') {
      modal
        .custom({
          component: ModalAddConsultingRequestsStatusCategories,
          options: {
            props: object,
            categories: data,
          },
        })
        .then(() => {
          callApi();
        });
    }

    if (
      model['title'] === 'ACTION' &&
      event.target.dataset.type === 'visible'
    ) {
      const toggleObj = {
        id: object['id'],
        visible: !object['visible'],
      };
      update(object['id'], toggleObj);
    }

    //응대 처리결과 사용/미사용
    if (
      model['title'] === '응대 처리결과' &&
      event.target.dataset.type === 'visible'
    ) {
      //복사 생성이기에 consultingRequestStatus 자체를 복사해야함
      let payloadConsultingRequestStatus = object.consultingRequestStatus.map(
        (v) => {
          if (v.id === object['@@consultingRequestStatus']['id']) {
            v.visible = !object['@@consultingRequestStatus']['visible'];
          }

          const {
            // eslint-disable-next-line no-unused-vars
            'exploded@@order': deletedKey1,
            // eslint-disable-next-line no-unused-vars
            'key@@child': deletedKey2,
            ...otherKeys
          } = v;
          return otherKeys;
        }
      );

      const toggleObj = {
        id: object['id'],
        consultingRequestStatus: [...payloadConsultingRequestStatus],
      };
      update(object['id'], toggleObj);
    }

    if (
      model['title'] === '응대 처리결과' &&
      event.target.dataset.type === 'delete'
    ) {
      modal
        .basic({
          body: `정말로 삭제하시겠습니까?`,
          buttons: [
            {
              text: 'CANCEL',
              class: 'btn-default',
            },
            {
              text: 'CONFIRM',
              class: 'btn-primary',
            },
          ],
        })
        .then((idx) => {
          idx === 1 && onClickDeleteResult(object['@@consultingRequestStatus']);
        });
    }
  };

  const onClickDeleteResult = useCallback(
    async (object) => {
      //카테고리 하위의 응대처리결과 삭제
      try {
        await services.crm.consultingRequest.requests.status_delete(object.id);
        modal
          .confirm({
            type: 'SUCCESS',
            msg: '삭제되었습니다.',
          })
          .then(() => {
            callApi();
          });
      } catch (e) {
        console.log(e.description);
        modal
          .confirm({
            type: 'ERROR',
            msg: e.description,
          })
          .then(() => {
            callApi();
          });
      }
    },
    [services.crm.consultingRequest.requests, toast, callApi]
  );

  const update = async (id, object) => {
    try {
      await services.crm.consultingRequest.requests_categories.update(
        id,
        object
      );
      modal
        .confirm({
          type: 'SUCCESS',
          msg: '업데이트되었습니다.',
        })
        .then(() => {
          callApi();
        });
    } catch (e) {
      const { name, description } = e;
      console.log(description);
      let message =
        name === 'Conflict' ? translate('ERROR_CONFLICT_CODE') : description;

      modal
        .confirm({
          type: 'ERROR',
          msg: message,
        })
        .then(() => {
          callApi();
        });
    }
  };

  const onClickCreateCategories = async () => {
    modal
      .custom({
        component: ModalAddConsultingRequestsStatusCategories,
        options: {
          categories: data,
        },
      })
      .then(() => {
        callApi();
      });
  };

  return (
    <div className="consulting-status-category m-r-16">
      <div className="status-category-header">
        <span className="title">상담문의 응대 처리결과</span>
        <div className="flex-row items-center m-t-8">
          <Checkbox
            className="flex-wrap m-r-8"
            checked={hideHidden}
            toggleHandler={() => setHideHidden(!hideHidden)}
          />
          <span className="flex-wrap m-r-12">미사용항목 보지 않기</span>
          <button
            className="flex-wrap btn btn-sm btn-add"
            onClick={onClickCreateCategories}
          >
            추가
          </button>
        </div>
      </div>
      <div className="status-category-body">
        <DataTableAlpha
          model={models.crm.consultingRequestsCategories}
          data={hideHidden ? data.filter((object) => object.visible) : data}
          total={total}
          params={params}
          onParams={onParams}
          onAction={onAction}
          hideLimitSetter={true}
          hasLine
          hasDeactivate
        />
      </div>
    </div>
  );
};

export default observer(ConsultingRequestsCategories);
