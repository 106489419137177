import React, { useRef } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { formatTime } from './dateUtil';
import './ctiHistoryItem.scss';
import hooks from 'hooks';
import CtiGuideTip from './CtiGuideTip';
import useCtiStore from './hooks/useCtiStore';
import { CtiTooltip } from './CtiTooltip';
import ModalMessageSendToBoards from 'components/modals/ModalMessageSendToBoards';
import { useModal } from 'hooks/useModal';
import { useServices } from 'hooks/useServices';
import { useToast } from 'hooks/useToast';
import { useAuth } from 'store/auth';
import classNames from 'classnames';
import { CtiStatusIconType } from './ctiStatusIconType';

const CtiHistoryItem = observer(({ history }) => {
  const auth = useAuth();
  const modal = useModal();
  const services = useServices();
  const toast = useToast();
  const ctiStore = useCtiStore();
  const itemElement = useRef();

  function onClickDashboard() {
    hooks.openCustomerChart({
      customer: {
        id: history.customerData.id,
      },
    });
  }

  function onClickToggleMenu(e) {
    e.stopPropagation();
    if (ctiStore.historyItemContextMenu?.id === history.id) {
      ctiStore.setHistoryItemContextMenu(null);
    } else {
      ctiStore.setHistoryItemContextMenu({
        id: history.id,
        top: null,
        left: null,
      });
    }
  }

  function onContextMenu(e) {
    e.preventDefault();
    const rect = itemElement.current.getBoundingClientRect();
    ctiStore.setHistoryItemContextMenu({
      id: history.id,
      top: e.clientY - rect.y,
      left: Math.min(196 - 101, e.clientX - rect.x),
    });
  }

  function onClickEnd() {
    if (!ctiStore.validateCurrentCall()) {
      toast.error('통화 응대 필수 값을 입력해주세요.');
      return;
    }
    ctiStore.endCall(history);
    toast.success('통화 응대가 저장 및 종료되었습니다.');
  }

  function onClickStart() {
    if (ctiStore.currentCall) {
      toast.error('ERROR_CTI_CLOSE_FIRST');
      return;
    }
    ctiStore.startCall(history);
  }

  function onClickItem() {
    ctiStore.setHistoryItemContextMenu(null);
  }

  async function onClickEdit() {
    if (ctiStore.currentCall) {
      toast.error('ERROR_CTI_CLOSE_FIRST');
      return;
    }

    if (history.customerData && history.customerData.name.includes('[삭제]')) {
      toast.error('삭제된 고객입니다.');
      return;
    }

    const res = await services.crm.call.memo(history.id);
    ctiStore.setCurrentCall(history.id, res);
  }

  function onClickSms() {
    if (history.customerData && history.customerData.name.includes('[삭제]')) {
      toast.error('삭제된 고객입니다.');
      return;
    }

    modal.custom({
      component: ModalMessageSendToBoards,
      options: {
        appointment: {
          customerId: history.customerData.id,
        },
        customerIds: [history.customerData.id],
      },
    });
  }

  const callBoilerplateMap = async () => {
    try {
      const resp = await services.crm.notification.boilerplateMap.all();
      if (!resp) return;

      let map = resp.data[0];
      //약도전송에 저장된 id 제거하고 메시지 전송하기
      let { id: deletedKey, ...message } = map;
      modal.custom({
        component: ModalMessageSendToBoards,
        options: {
          appointment: {
            customerId: history.customerData.id,
          },
          customerIds: [history.customerData.id],
          mapMessage: {
            ...message,
          },
        },
      });
    } catch (e) {
      console.log(e.description);
    }
  };

  async function onClickMap() {
    if (history.customerData && history.customerData.name.includes('[삭제]')) {
      toast.error('삭제된 고객입니다.');
      return;
    }

    try {
      const resp = await services.crm.notification.smsConfig.all();
      if (!resp) return;

      if (resp.data.map === undefined || !resp.data.map) {
        modal.basic({
          body: '문자코드 설정에서 약도이미지를 설정하세요.',
          buttons: [{ text: 'CONFIRM', class: 'btn-primary' }],
        });
        return;
      } else {
        callBoilerplateMap();
      }
    } catch (error) {
      toast.error(error.description);
    }
  }

  const menuOpened = ctiStore.historyItemContextMenu?.id === history.id;

  function getTooltipText() {
    switch (history.iconType) {
      case CtiStatusIconType.ringing:
        return '링잉';
      case CtiStatusIconType.calling:
        return '통화중';
      case CtiStatusIconType.ended:
        return '통화완료(기록필요)';
      case CtiStatusIconType.missed:
        return '부재중 전화';
      case CtiStatusIconType.recall:
        return '리콜';
      case CtiStatusIconType.handled:
        return '응대완료';
    }
  }

  return (
    <div
      className="cti-history-item"
      onContextMenu={onContextMenu}
      onClick={onClickItem}
      ref={itemElement}
    >
      <div className="cti-item-header">
        <span>
          {history.isCalling && history.callerId === auth.me.id
            ? '현재 상담 중 고객'
            : ''}
        </span>
        {formatTime(history.createdAt)}
      </div>
      <div className="cti-item-content">
        <CtiTooltip title={getTooltipText()} placement="left">
          <span
            className={classNames('cti-status-icon', history.iconType)}
          ></span>
        </CtiTooltip>
        <div className="cti-description">
          <div className="cti-description-secondary">
            상담사: {history.customerData?.counselorName ?? '미지정'}
            {history.customerData && (
              <button
                className="cti-dashboard-button"
                onClick={onClickDashboard}
              ></button>
            )}
          </div>
          <div className="cti-description-primary">
            {history.customerData ? (
              <>
                <span className="name">{`${history.customerData.name} ${history.phoneNumber}`}</span>
              </>
            ) : (
              `${history.phoneNumber}`
            )}
          </div>
        </div>
        {history.isCalling && history.callerId === auth.me.id && (
          <CtiGuideTip
            id="end-button"
            text="상담종료 버튼입니다."
            offset={-48}
            show={true}
            direction="bottom"
          >
            <button
              className="cti-call-button calling"
              onClick={onClickEnd}
            ></button>
          </CtiGuideTip>
        )}
        {history.isRinging && (
          <CtiGuideTip
            id="incoming-button"
            text="상담시작 버튼입니다."
            offset={-48}
            show={true}
            direction="bottom"
          >
            <button
              className="cti-call-button incoming"
              onClick={onClickStart}
            ></button>
          </CtiGuideTip>
        )}
        {history.isCalling && history.callerId !== auth.me.id && (
          <button className="cti-call-button connected"></button>
        )}
        {!history.isRinging && !history.isCalling && (
          <button
            className="cti-call-button inactive"
            onClick={onClickToggleMenu}
          ></button>
        )}
      </div>
      {menuOpened && (
        <div
          className="cti-history-item-context-menu"
          style={{
            top: ctiStore.historyItemContextMenu.top ?? 32,
            left: ctiStore.historyItemContextMenu.left ?? 80,
          }}
        >
          <button
            className="context-menu-item"
            onClick={onClickEdit}
            disabled={history.isRinging || history.isCalling}
          >
            통화 입력 / 수정
          </button>
          <button
            className="context-menu-item"
            onClick={onClickSms}
            disabled={Boolean(!history.customerData)}
          >
            문자 전송
          </button>
          <button
            className="context-menu-item"
            onClick={onClickMap}
            disabled={Boolean(!history.customerData)}
          >
            약도 전송
          </button>
        </div>
      )}
    </div>
  );
});

CtiHistoryItem.propTypes = {
  history: PropTypes.object,
};

export default CtiHistoryItem;
