import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { formatDate, translate } from 'filters';
import TableContextMenu from 'components/data-table/TableContextMenu';
import PropTypes from 'prop-types';

const SubDataTable = ({
  parentRow,
  subRow,
  subModel,
  onSubContextAction,
  onSubAction,
}) => {
  const [subContextMenuOptions, setSubContextMenuOptions] = useState({});

  const onSubContextMenu = (e, row, contextItems) => {
    e.preventDefault();
    setSubContextMenuOptions({
      show: true,
      x: e.clientX,
      y: e.clientY,
      row: row,
      parentRow,
      items: contextItems,
    });
  };

  const onClickSubContextMenuItem = async ({ item, row, parentRow }) => {
    setSubContextMenuOptions({});
    if (item === -1) {
      return;
    }

    onSubContextAction({ item, row, parentRow });
  };

  const onThDoAction = (e, key) => {
    onSubAction(key);
  };

  const doAction = (event, row, key, rowIdx, eventType) => {
    const classList = event.target.classList;

    if (key.type === 'textarea' || key.type === 'input') return;

    // context를 사용하고, 클릭한 이벤트 타입에 맞게 걸려있으며,
    // not-context 를 클래스명에 포함하지 않는 경우에만 컨텍스트 동작
    if (
      key.useContext &&
      key.contextTrigger === eventType &&
      !classList.contains('not-context')
    ) {
      onSubContextMenu(event, row, key.contextItems);
      return;
    }
  };

  const getValue = (row, key) => {
    if (!row || !key.column) return;

    let cols = key.column.split('__');
    if (cols.length === 1) {
      if ((key.type || '').includes('timestamp')) {
        return formatDate(
          row[cols[0]],
          `YYYY-MM-DD ${key.type.includes('day') ? '(ddd)' : 'HH:mm:ss'}`
        );
      }
      if ((key.type || '').includes('date')) {
        return formatDate(row[cols[0]], 'YYYY-MM-DD');
      }
      return row[cols[0]];
    }

    let newKey = JSON.parse(JSON.stringify(key));
    newKey.column = cols.slice(1).join('__');
    return getValue(row[cols[0]], newKey);
  };

  return (
    <>
      <TableContextMenu
        options={subContextMenuOptions}
        onClick={onClickSubContextMenuItem}
      />
      <table className={`sub-data-table`}>
        <thead>
          <tr>
            {(subModel.keys || [])
              .filter((o) => !o.hideColumn)
              .map((key, idx) => {
                let thDivStyle = { display: 'inline-block' };
                if (key.thStyle !== undefined) {
                  thDivStyle = { ...thDivStyle, ...key.thStyle() };
                }
                return (
                  <th
                    onClick={(e) => onThDoAction(e, key)}
                    key={idx}
                    className={`${key.class ? key.class : ''} ${
                      key.type === 'sub-line' ? 'sub-line not-padding' : ''
                    }`}
                  >
                    {key.type === 'sub-line' ? (
                      <div className="sub-line-wrapper">
                        <div className="line-box"></div>
                      </div>
                    ) : (
                      <div
                        className={`flex-row items-center`}
                        style={thDivStyle}
                      >
                        <div>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: translate(key.title),
                            }}
                          ></span>
                        </div>
                        {key.order ? (
                          <i
                            className={`zmdi flex-wrap m-l-8 zmdi-chevron-${
                              key.order === 'asc' ? 'up' : 'down'
                            }`}
                          />
                        ) : null}
                      </div>
                    )}
                  </th>
                );
              })}
          </tr>
        </thead>
        <tbody>
          {(subRow || []).length > 0
            ? subRow.map((row, rowIdx) => (
                <tr
                  className={``}
                  onClick={() => (row.selected = !row.selected)}
                  key={rowIdx}
                >
                  {subModel.keys
                    .filter((o) => !o.hideColumn)
                    .map((key, idx) => (
                      <td
                        onClick={(e) => doAction(e, row, key, rowIdx, 'click')}
                        onDoubleClick={(e) =>
                          doAction(e, row, key, rowIdx, 'doubleClick')
                        }
                        key={idx}
                        className={`${
                          key.type === 'sub-line' && 'sub-line not-padding'
                        } ${key.class ? key.class : ''} `}
                        style={
                          key.customStyle !== undefined
                            ? key.customStyle(row)
                            : key.style
                        }
                      >
                        {key.type === 'sub-line' ? (
                          <div
                            className="sub-line-wrapper"
                            style={{
                              ...key.tdDivStyle(),
                            }}
                          >
                            <div className="line-box"></div>
                            <div className="radius-box"></div>
                          </div>
                        ) : (
                          <div
                            className="flex-row"
                            style={key.tdDivStyle && { ...key.tdDivStyle() }}
                          >
                            <div
                              className={`item-content ${
                                key.type !== 'text' ? 'center' : ''
                              } ${
                                key.type === 'text' &&
                                (getValue(row, key) ||
                                  (key.filter && key.filter(row)))
                                  ? 'pre-line'
                                  : ''
                              }
                                          ${
                                            (
                                              key.filter
                                                ? key.filter(row) === true
                                                : getValue(row, key) === true
                                            )
                                              ? 'c-blue'
                                              : ''
                                          }
                                          ${
                                            (
                                              key.filter
                                                ? key.filter(row) === false
                                                : getValue(row, key) === false
                                            )
                                              ? 'c-danger'
                                              : ''
                                          }
            `}
                              dangerouslySetInnerHTML={{
                                __html: key.filter
                                  ? key.filter(row)
                                  : getValue(row, key),
                              }}
                              style={key.align && { justifyContent: key.align }}
                            />
                          </div>
                        )}
                      </td>
                    ))}
                </tr>
              ))
            : null}
        </tbody>
      </table>
    </>
  );
};

SubDataTable.propTypes = {
  parentRow: PropTypes.object,
  subRow: PropTypes.array,
  subModel: PropTypes.object,
  onSubContextAction: PropTypes.func,
  onSubAction: PropTypes.func,
};

export default observer(SubDataTable);
