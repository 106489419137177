import { currency, currencySymbol } from 'filters';

export default {
  keys: [
    {
      title: '순위',
      key: 'no',
      column: 'no',
      columnGroup: false,
      filter: (o) => {
        if (o !== undefined) {
          return `<div><span>${o.no && o.no !== -1 ? o.no : ''}</span></div>`;
        }
      },
    },
    {
      title: '국가/지역',
      key: 'regionName',
      column: 'regionName',
      columnGroup: false,
      filter: (o) => {
        return `${(o || {}).regionName || 0}(${currency(
          (o || {}).customerCount || 0
        )})`;
      },
    },
    {
      title: '청구금액',
      key: 'salesFigures',
      column: 'salesFigures',
      columnGroup: false,
      orderKey: 'salesFigures',
      order: 'desc',
      filter: (o) => {
        return `${currencySymbol()} ${currency((o || {}).salesFigures || 0)}`;
      },
    },
    {
      title: '할인적용',
      key: 'requestAmount',
      column: 'requestAmount',
      columnGroup: false,
      orderKey: 'requestAmount',
      order: 'desc',
      filter: (o) => {
        return `${currencySymbol()} ${currency((o || {}).requestAmount || 0)}`;
      },
    },
    {
      title: '할인금액',
      key: 'discountAmount',
      column: 'discountAmount',
      columnGroup: false,
      orderKey: 'discountAmount',
      order: 'desc',
      filter: (o) => {
        return `${currencySymbol()} ${currency((o || {}).discountAmount || 0)}`;
      },
    },
    {
      title: '방문횟수',
      key: 'visitCount',
      column: 'visitCount',
      columnGroup: false,
      orderKey: 'visitCount',
      order: 'desc',
      filter: (o) => {
        if (o !== undefined) {
          return `<div><span>${o.visitCount || 0}</span></div>`;
        }
      },
    },
    {
      title: '비율',
      key: 'percentage',
      column: 'percentage',
      columnGroup: false,
      order: 'desc',
      filter: (o) => {
        if (o !== undefined) {
          return `<div><span>${o.percentage || '100'}%</span></div>`;
        }
      },
    },
  ],
};
