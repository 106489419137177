import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { observer } from 'mobx-react';
import { translate } from 'filters';
import { objectToFormData } from 'object-to-formdata';
import { useServices } from 'hooks/useServices';
import { useToast } from 'hooks/useToast';
import styled from 'styled-components';
import { FileInput } from 'components/common/FileInput';
import eventForm from 'assets/xlsx/events_form.xlsx';
import requestsForm from 'assets/xlsx/requests_form.xlsx';

const InputWrapper = styled.div`
  margin-right: 8px;
  width: 100%;
`;

const Input = styled.input`
  background-color: #fff;
  min-height: 10px;
  border-radius: 4px;
  color: #000;
  font-size: 14px;
  border: 1px solid #dee2ec;
  padding: 4px;
  width: 100%;
  height: 34px;
`;

const ModalEventBulkRegistration = ({ options, close }) => {
  const services = useServices();
  const toast = useToast();
  const [obj, setObj] = useState({});
  const [excelFileName, setExcelFileName] = useState('');
  const excelFileRef = useRef();
  const [loadingBtnDisabledFlag, setLoadingBtnDisabledFlag] = useState(true);

  const onSelectFile = (e) => {
    const file = e.target.files[0];
    const { name } = e.target.files[0];

    const fileType = name.split('.').pop();
    const confirmType = ['xlsx'];
    if (confirmType.indexOf(fileType) === -1) {
      toast.error('xlsx 파일만 업로드 가능합니다.');
      return;
    }

    setObj({ excelFile: file });
    setExcelFileName(name);
    setLoadingBtnDisabledFlag(false);
  };

  const onClickDeleteFile = () => {
    setObj({});
    setExcelFileName('');
    setLoadingBtnDisabledFlag(true);
  };

  const onClickSave = () => {
    setLoadingBtnDisabledFlag(true);
    if (!loadingBtnDisabledFlag) {
      toast.success('등록중입니다.');
      createCallApi().then((response) => {
        if (response !== undefined) {
          const date = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
          const title = options.title === 'event' ? 'event' : 'requests';
          const url = window.URL.createObjectURL(new Blob([response]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${date}_${title}_result.xlsx`);
          document.body.appendChild(link);
          link.click();
        }
      });
    }
  };

  const createCallApi = async () => {
    try {
      if (excelFileName !== '') {
        const endpoint = options.title === 'event' ? 'event' : 'requests';
        const resp = await services.crm.consultingRequest[
          endpoint
        ].excel_create(objectToFormData(obj));
        toast.success('대량등록 성공');
        close();
        return resp;
      }
    } catch (e) {
      toast.error('대량등록 실패');
    }
  };

  return (
    <div className="modal-event-registration">
      <div className="head flex-row flex-between items-start">
        <div className="title">
          {options.title === 'event' ? '이벤트 대량등록' : '상담문의 대량등록'}
        </div>
        <i onClick={() => close()} className="zmdi zmdi-close" />
      </div>
      <div className="body">
        <div className="danger-confrim m-b-16">* 대량등록 방법</div>
        <div className="m-b-16">
          <span className="m-r-8">1. 양식을 다운로드 하세요.</span>
        </div>
        <div>
          <span>2. 다운로드 받은 엑셀에 정보를 입력하세요.</span>
        </div>
        <div className="m-b-16">
          <span className="help">
            (양식과 다르게 입력 시, 조회/통계 데이터에 오류가 생길 수 있습니다.)
          </span>
        </div>
        <div className="m-b-16">
          <span>3. 입력한 엑셀 파일을 첨부하고 등록버튼을 클릭하세요.</span>
        </div>
        <div className="m-b-40">
          <button>
            <a href={options.title === 'event' ? eventForm : requestsForm}>
              대량등록 양식 다운로드
            </a>
          </button>
        </div>
        <div className="m-b-8">파일등록*</div>
        <div className="flex-row">
          <InputWrapper>
            <Input defaultValue={excelFileName} />
          </InputWrapper>
          <button
            className="btn btn-white m-r-8"
            onClick={() => excelFileRef.current.click()}
          >
            {'파일첨부'}
          </button>
          <FileInput ref={excelFileRef} onChange={onSelectFile} />
          <button className="btn btn-del" onClick={onClickDeleteFile}>
            {'제거'}
          </button>
        </div>
      </div>
      <div className="buttons">
        <div className="flex-row">
          <button onClick={() => close(0)} className="btn btn-default">
            {translate('CANCEL')}
          </button>
          <button
            onClick={() => onClickSave()}
            className={`btn btn-primary ${excelFileName === '' && 'disabled'} ${
              loadingBtnDisabledFlag && 'disabled'
            }`}
            disabled={loadingBtnDisabledFlag ? 'disabled' : ''}
          >
            등록
          </button>
        </div>
      </div>
    </div>
  );
};

ModalEventBulkRegistration.propTypes = {
  options: PropTypes.object,
  close: PropTypes.func,
};

export default observer(ModalEventBulkRegistration);
