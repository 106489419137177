import { currency, currencySymbol } from 'filters';

export default {
  keys: [
    {
      title: '카테고리명',
      key: 'categoryName',
      column: 'categoryName',
      columnGroup: false,
    },
    {
      title: '상담인원수',
      key: 'consultedPeopleCount',
      column: 'consultedPeopleCount',
      columnGroup: false,
      orderKey: 'consultedPeopleCount',
      order: 'desc',
      filter: (o) => {
        if (o !== undefined) {
          return `<div><span>${o.consultedPeopleCount}</span></div>`;
        }
      },
    },
    {
      title: '상담건수',
      key: 'consultedCount',
      column: 'consultedCount',
      columnGroup: false,
      orderKey: 'consultedCount',
      order: 'desc',
      filter: (o) => {
        if (o !== undefined) {
          return `<div><span>${o.consultedCount}</span></div>`;
        }
      },
    },
    {
      title: '진료인원수',
      key: 'treatmentPeopleCount',
      column: 'treatmentPeopleCount',
      columnGroup: false,
      orderKey: 'treatmentPeopleCount',
      order: 'desc',
      filter: (o) => {
        if (o !== undefined) {
          return `<div><span>${o.treatmentPeopleCount}</span></div>`;
        }
      },
    },
    {
      title: '진료건수',
      key: 'treatmentCount',
      column: 'treatmentCount',
      columnGroup: false,
      orderKey: 'treatmentCount',
      order: 'desc',
      filter: (o) => {
        if (o !== undefined) {
          return `<div><span>${o.treatmentCount}</span></div>`;
        }
      },
    },
    {
      title: '시수술인원수',
      key: 'surgeryPeopleCount',
      column: 'surgeryPeopleCount',
      columnGroup: false,
      orderKey: 'surgeryPeopleCount',
      order: 'desc',
      filter: (o) => {
        if (o !== undefined) {
          return `<div><span>${o.surgeryPeopleCount}</span></div>`;
        }
      },
    },
    {
      title: '시수술건수',
      key: 'surgeryCount',
      column: 'surgeryCount',
      columnGroup: false,
      orderKey: 'surgeryCount',
      order: 'desc',
      filter: (o) => {
        if (o !== undefined) {
          return `<div><span>${o.surgeryCount}</span></div>`;
        }
      },
    },
    {
      title: '수납건수',
      key: 'paymentCount',
      column: 'paymentCount',
      columnGroup: false,
      orderKey: 'paymentCount',
      order: 'desc',
      filter: (o) => {
        if (o !== undefined) {
          return `<div><span>${o.paymentCount}</span></div>`;
        }
      },
    },
    {
      title: '매출액',
      key: 'salesFigures',
      column: 'salesFigures',
      columnGroup: false,
      orderKey: 'salesFigures',
      order: 'desc',
      filter: (o) => {
        return `${currencySymbol()} ${currency(o?.salesFigures ?? 0)}`;
      },
    },
  ],
};
