import { translate } from 'filters';

const CATEGORY = Object.freeze({
  CUSTOMER: 'customer',
  APPOINTMENT: 'appointment',
  CONSULTING: 'consulting',
  TREATMENT: 'treatment',
  PAYMENT: 'payment',
});

const INPUT_TYPE = Object.freeze({
  INPUT: 'input',
  SELECT: 'select',
  // DATE: "date",
  DATERANGE: 'date-range',
  //DEPTH_SELECT : 관심항목, 예약항목, 진료항목 등
  //시/수술 카테고리 - 시/수술명 2depth 검색조건 셀렉트
  DEPTH_SELECT: 'depth-select',
});

// 22개의 항목 = (26개항목) - (폐기된 4개항목) + 고객조건 신환/구환 추가
const keys = [
  {
    // 고객조건 신환/구환 추가
    item: 'isNewCustomer',
    category: CATEGORY.CUSTOMER,
    inputType: INPUT_TYPE.SELECT,
    multi: false,
    useStoreData: false,
    options: [
      {
        value: true,
        label: 'FIRST_VISIT',
      },
      {
        value: false,
        label: 'RE_VISIT',
      },
    ],
    filter: (label) => translate(label),
  },
  {
    item: 'lastVisitAt',
    category: CATEGORY.CUSTOMER,
    inputType: INPUT_TYPE.DATERANGE,
  },
  {
    // 1. 고객조건 - 고객명
    item: 'name',
    category: CATEGORY.CUSTOMER,
    inputType: INPUT_TYPE.INPUT,
    multi: true,
    useStoreData: false,
  },
  {
    // 2. 고객조건 - 생년월일
    item: 'birthday',
    category: CATEGORY.CUSTOMER,
    inputType: INPUT_TYPE.DATERANGE,
    multi: true,
    useStoreData: false,
  },
  {
    // 3. 고객조건 - 성별
    item: 'sex',
    category: CATEGORY.CUSTOMER,
    inputType: INPUT_TYPE.SELECT,
    multi: true,
    useStoreData: false,
    options: [
      {
        value: 'male',
        label: 'male',
      },
      {
        value: 'female',
        label: 'female',
      },
    ],
    filter: (label) => translate(label.toUpperCase()),
  },
  {
    // 4. 고객조건 - 휴대폰번호
    item: 'phoneNumber',
    category: CATEGORY.CUSTOMER,
    inputType: INPUT_TYPE.INPUT,
    multi: true,
    useStoreData: false,
  },
  {
    // 5. 고객조건 - 담당 상담자
    item: 'counselor',
    category: CATEGORY.CUSTOMER,
    inputType: INPUT_TYPE.SELECT,
    multi: true,
    useStoreData: true,
    store: {
      key: 'counselors',
      value: 'id',
      label: 'name',
    },
  },
  {
    // 6. 고객조건 - 담당 의자
    item: 'doctor',
    category: CATEGORY.CUSTOMER,
    inputType: INPUT_TYPE.SELECT,
    multi: true,
    useStoreData: true,
    store: {
      key: 'doctors',
      value: 'id',
      label: 'name',
    },
  },
  {
    // 7. 고객조건 - 소개자
    item: 'recommender',
    category: CATEGORY.CUSTOMER,
    inputType: INPUT_TYPE.INPUT,
    multi: true,
    useStoreData: false,
  },
  {
    // 8. 고객조건 - 고객등급
    item: 'levelIds',
    category: CATEGORY.CUSTOMER,
    inputType: INPUT_TYPE.SELECT,
    multi: true,
    useStoreData: true,
    store: {
      key: 'levels',
      value: 'id',
      label: 'name',
    },
  },
  {
    // 9. 고객조건 - 내원경로
    item: 'acquisitionChannel',
    category: CATEGORY.CUSTOMER,
    inputType: INPUT_TYPE.SELECT,
    multi: true,
    useStoreData: true,
    store: {
      key: 'acquisitionChannels',
      value: 'id',
      label: 'name',
    },
  },
  {
    // todo: 10. 고객조건 - 국가/지역
    item: 'region',
    category: CATEGORY.CUSTOMER,
    inputType: INPUT_TYPE.SELECT,
    multi: true,
    useStoreData: true,
    store: {
      key: 'regions',
      value: 'id',
      label: 'name',
    },
  },
  {
    // 11. 고객조건 - 관심항목
    item: 'favoriteIds', // 시수술 카테고리 기준
    category: CATEGORY.CUSTOMER,
    inputType: INPUT_TYPE.SELECT,
    multi: true,
    useStoreData: true,
    store: {
      key: 'treatmentCategories',
      value: 'id',
      label: 'name',
    },
  },
  {
    // 13. 예약조건 - 초/재진 > 신환/구환
    item: 'isNewCustomer',
    category: CATEGORY.APPOINTMENT,
    inputType: INPUT_TYPE.SELECT,
    multi: false,
    useStoreData: false,
    options: [
      {
        value: true,
        label: 'FIRST_VISIT',
      },
      {
        value: false,
        label: 'RE_VISIT',
      },
    ],
    filter: (label) => translate(label),
  },
  {
    // 14. 예약조건 - 예약일자
    item: 'scheduledAt',
    category: CATEGORY.APPOINTMENT,
    inputType: INPUT_TYPE.DATERANGE,
    multi: true,
    useStoreData: false,
  },
  {
    // 15. 예약조건 - 예약항목
    item: 'itemIds', // 시수술명 기준
    // item: "categoryIds", // 시수술 카테고리 기준
    category: CATEGORY.APPOINTMENT,
    inputType: INPUT_TYPE.DEPTH_SELECT,
    multi: true,
    useStoreData: true,
    store: {
      key: 'treatmentCategories',
      value: 'id',
      label: 'name',
    },
  },
  {
    // 16. 예약조건 - 방문여부
    item: 'visited',
    category: CATEGORY.APPOINTMENT,
    inputType: INPUT_TYPE.SELECT,
    multi: false,
    useStoreData: false,
    options: [
      {
        value: true,
        label: 'VISIT_TRUE',
      },
      {
        value: false,
        label: 'VISIT_FALSE',
      },
    ],
    filter: (label) => translate(label),
  },
  {
    // 18. 상담조건 - 상담자
    item: 'counselor',
    category: CATEGORY.CONSULTING,
    inputType: INPUT_TYPE.SELECT,
    multi: true,
    useStoreData: true,
    store: {
      key: 'counselors',
      value: 'id',
      label: 'name',
    },
  },
  {
    // 19. 상담조건 - 상담항목
    item: 'itemIds', // 시수술명 기준
    // item: "categoryIds", // 시수술 카테고리 기준
    category: CATEGORY.CONSULTING,
    inputType: INPUT_TYPE.DEPTH_SELECT,
    multi: true,
    useStoreData: true,
    store: {
      key: 'treatmentCategories',
      value: 'id',
      label: 'name',
    },
  },
  {
    // 20. 상담조건 - 상담결과
    item: 'resultIds',
    category: CATEGORY.CONSULTING,
    inputType: INPUT_TYPE.SELECT,
    multi: true,
    useStoreData: true,
    store: {
      key: 'results',
      value: 'id',
      label: 'name',
    },
  },
  {
    // 22. 진료조건 - 의사
    item: 'doctor',
    category: CATEGORY.TREATMENT,
    inputType: INPUT_TYPE.SELECT,
    multi: true,
    useStoreData: true,
    store: {
      key: 'doctors',
      value: 'id',
      label: 'name',
    },
  },
  {
    // 23. 진료조건 - 진료항목
    item: 'itemIds', // 시수술명 기준
    // item: "categoryIds", // 시수술 카테고리 기준
    category: CATEGORY.TREATMENT,
    inputType: INPUT_TYPE.DEPTH_SELECT,
    multi: true,
    useStoreData: true,
    store: {
      key: 'treatmentCategories',
      value: 'id',
      label: 'name',
    },
  },
  {
    // 25. 수납조건 - 수납항목
    item: 'itemIds', // 시수술명 기준
    // item: "categoryIds", // 시수술 카테고리 기준
    category: CATEGORY.PAYMENT,
    inputType: INPUT_TYPE.DEPTH_SELECT,
    multi: true,
    useStoreData: true,
    store: {
      key: 'treatmentCategories',
      value: 'id',
      label: 'name',
    },
  },
  {
    // 26. 수납조건 - 수납여부
    item: 'status',
    category: CATEGORY.PAYMENT,
    inputType: INPUT_TYPE.SELECT,
    multi: false,
    useStoreData: false,
    options: [
      {
        value: 'paid',
        label: 'PAID',
      },
      {
        value: 'unpaid',
        label: 'UNPAID',
      },
    ],
    filter: (label) => translate(label),
  },
];

export default {
  categories: Object.values(CATEGORY),
  keys: keys.map((key) => {
    return {
      ...key,
      uiKeyName: `${key.category}.${key.item}`,
    };
  }),
};
