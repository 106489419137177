import * as $http from 'axios';
import { $case, pluralize } from 'filters';

const consulting = {
  create: (consulting) => $http.post('consultings', consulting),
  create_v2: (consulting) => $http.post('consultings/v2', consulting),
  all: (params) => $http.get('/consultings', { params }),
  all_v2: (params) => $http.get('/consultings/v2', { params }),
  detail: (consultingId) => $http.get(`consultings/${consultingId}`),
  update: (consulting) => $http.put(`consultings/${consulting.id}`, consulting),
  update_v2: (consulting) =>
    $http.put(`consultings/v2/${consulting.id}`, consulting),
  delete: (consultingId) => $http.delete(`consultings/${consultingId}`),
  delete_v2: (consultingId) => $http.delete(`consultings/v2/${consultingId}`),
  excel_download_v2: (params) =>
    $http.get('/consultings/v2/excel', { params, responseType: 'blob' }),
  excel_check_download_v2: (params) =>
    $http.get('/consultings/v2/excel/check/download', {
      params,
      responseType: 'blob',
    }),
};

const models = ['counselor', 'result'];

const foo = (str) => $case.toSnake(pluralize(str));

models.forEach((modelName) => {
  consulting[modelName] = {
    create: (model) => $http.post(`consultings/${foo(modelName)}`, model),
    create_v2: (model) => $http.post(`consultings/v2/${foo(modelName)}`, model),
    all: (params) => $http.get(`consultings/${foo(modelName)}`, { params }),
    all_v2: (params) =>
      $http.get(`consultings/v2/${foo(modelName)}`, { params }),
    detail: (modelId) => $http.get(`consultings/${foo(modelName)}/${modelId}`),
    update: (model) =>
      $http.put(`consultings/${foo(modelName)}/${model.id}`, model),
    update_v2: (model) =>
      $http.put(`consultings/v2/${foo(modelName)}/${model.id}`, model),
    delete: (modelId) =>
      $http.delete(`consultings/${foo(modelName)}/${modelId}`),
    delete_v2: (modelId) =>
      $http.delete(`consultings/v2/${foo(modelName)}/${modelId}`),
  };
});

export default consulting;
