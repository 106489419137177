import { observable, action, computed, makeObservable } from 'mobx';
import { Call } from './call';
import { CallStatus } from './callStatus';
import { CounselingResult } from './counselingResult';
import { CtiStatusFilterType } from './ctiStatusFilterType';
import services from 'services';

export class CtiStore {
  constructor(auth) {
    this.auth = auth;
    const localSettings = localStorage.getItem('cti_settings');
    this.settings = localSettings
      ? JSON.parse(localSettings)
      : {
          useRing: true,
          autohide: true,
          autohideDuration: 3,
          showGrade: true,
          showCounselor: true,
          showDoctor: true,
          showCaller: false,
          showResult: false,
          historyLocation: 'right',
          callPopupLocation: 'bottomleft',
        };

    try {
      this.guidesCount = JSON.parse(localStorage.getItem('cti_guides')) ?? {};
    } catch {
      this.guidesCount = {};
    }

    makeObservable(this, {
      showStatusBar: observable,
      showHistory: observable,
      phoneNumber: observable,
      myStatus: observable,
      historyOpacity: observable,
      historyFilter: observable,
      historyItems: observable,
      doNotDisturb: observable,
      today: observable,
      guidesCount: observable,
      guidesDisplayed: observable,
      historyMaxHour: observable,
      historyItemContextMenu: observable,
      currentCall: observable,
      ringingsCollapsed: observable,
      settings: observable,
      updateTimestamp: observable,

      missedCount: computed,
      recallCount: computed,
      visibleItems: computed,
      ringings: computed,

      setCurrentCall: action,
      updateCurrentCall: action,
      validateCurrentCall: action,
      updateHistories: action,
      setHistoryItemContextMenu: action,
      setHistoryMaxHour: action,
      setShowStatusBar: action,
      setShowHistory: action,
      setMyStatus: action,
      setHistoryOpacity: action,
      setHistoryFilter: action,
      addNewCall: action,
      setDoNotDisturb: action,
      clearRingings: action,
      setRingingsCollapsed: action,
      setSettings: action,
      startCall: action,
      endCall: action,
      saveCall: action,
      incrementGuide: action,
      setGuideDisplayed: action,
    });
  }

  showStatusBar = false;
  showHistory = false;
  phoneNumber = '010-5047-4340';
  myStatus = 'test1';
  historyOpacity = 1;
  historyFilter = 'all';
  doNotDisturb = localStorage.getItem('cti_do_not_disturb') === 'true';
  today = new Date();
  historyMaxHour = 24;
  historyItemContextMenu = null;
  ringingsCollapsed = false;

  guidesCount = {};
  guidesDisplayed = {};

  historyItems = [];

  currentCall = null;

  hideTimers = [];

  updateTimestamp = null;

  setCurrentCall(id, data) {
    if (id == null) {
      this.currentCall = null;
      return;
    }

    const call = this.historyItems.find((item) => item.id === id);
    this.currentCall = {
      call: call,
      caller: data?.callMemoData?.callerId ?? this.auth.me.id,
      recallDate: data?.callMemoData?.recallAt
        ? data.callMemoData.recallAt.split(' ')[0]
        : '',
      recallTime: data?.callMemoData?.recallTime ?? 0,
      recall: Boolean(data?.callMemoData?.recallAt ?? false),
      result: data?.callMemoData?.counselingResult ?? CounselingResult.none,
      memo: data?.callMemoData?.memo ?? '',
      sex: 'female',
      sms: false,
      promotion: false,
      name: '',
      inflowSource: 0,
      referral: null,
      referralName: '',
      modified: call.isCalling,
      callerError: '',
      recallError: '',
      resultError: '',
      nameError: '',
    };
  }

  validateCurrentCall() {
    let passed = true;
    if (this.currentCall.recall) {
      if (!this.currentCall.recallDate) {
        this.currentCall.recallError = '* 리콜 날짜를 입력해주세요';
        passed = false;
      } else {
        this.currentCall.recallError = '';
      }
    }

    if (!this.currentCall.caller) {
      this.currentCall.callerError = '* 통화자를 입력해주세요';
      passed = false;
    } else {
      this.currentCall.callerError = '';
    }

    if (!this.currentCall.call.customerData && !this.currentCall.name) {
      this.currentCall.nameError = '* 이름을 입력해주세요';
      passed = false;
    } else {
      this.currentCall.nameError = '';
    }

    if (this.currentCall.result === CounselingResult.none) {
      this.currentCall.resultError = '* 상담결과를 선택해주세요';
      passed = false;
    } else {
      this.currentCall.resultError = '';
    }

    return passed;
  }

  updateCurrentCall(update) {
    Object.assign(this.currentCall, update);
  }

  setHistoryItemContextMenu(data) {
    this.historyItemContextMenu = data;
  }

  setShowStatusBar(show) {
    this.showStatusBar = show;
  }

  setShowHistory(show) {
    if (show) {
      this.showStatusBar = true;
    }
    this.showHistory = show;
  }

  setMyStatus(status) {
    this.myStatus = status;
  }

  setHistoryOpacity(value) {
    this.historyOpacity = value;
  }

  setHistoryFilter(value) {
    this.historyFilter = value;
  }

  addNewCall(newCall) {
    this.historyItems.push(newCall);

    if (this.settings.autohide) {
      this.setHideTimer(newCall);
    }
  }

  setHideTimer(call) {
    const timer = setTimeout(() => {
      this.hideTimers = this.hideTimers.filter((t) => t !== timer);
      const ringing = this.historyItems.find((r) => r.id === call.id);
      if (ringing && ringing.ringing !== false) {
        this.hideCall(ringing);
        if (!this.doNotDisturb) {
          this.setShowHistory(true);
        }
      }
    }, this.settings.autohideDuration * 1000);
    this.hideTimers.push(timer);
  }

  hideCall(call) {
    call.ringing = false;
  }

  clearRingings() {
    this.ringings.slice().forEach((h) => (h.ringing = false));
  }

  setSettings(settings) {
    this.settings = settings;
    localStorage.setItem('cti_settings', JSON.stringify(settings));

    if (this.settings.autohide) {
      if (this.hideTimers.length === 0) {
        this.ringings.forEach((r) => {
          this.setHideTimer(r);
        });
      }
    } else {
      if (this.hideTimers.length > 0) {
        this.hideTimers.forEach((t) => clearTimeout(t));
        this.hideTimers = [];
      }
    }
  }

  setRingingsCollapsed(collapsed) {
    this.ringingsCollapsed = collapsed;
  }

  setDoNotDisturb(value) {
    this.doNotDisturb = value;
    localStorage.setItem('cti_do_not_disturb', value.toString());
  }

  setHistoryMaxHour(hours) {
    hours = Math.floor(hours);
    if (hours < 0) {
      hours = 0;
    }
    if (hours > 24) {
      hours = 24;
    }

    this.historyMaxHour = hours;
  }

  async startCall(call) {
    const res = await services.crm.call.update({
      callId: call.id,
      callStatus: CallStatus.calling,
    });
    this.updateHistories(res.data);
    this.setCurrentCall(call.id);
    this.showHistory = false;
  }

  async endCall() {
    await this.saveCurrentCall(CallStatus.ended);

    this.setCurrentCall(null);
  }

  async saveCall() {
    await this.saveCurrentCall(CallStatus.ended);
    this.setCurrentCall(null);
  }

  async saveCurrentCall(status) {
    if (this.currentCall.call.customerData) {
      const res = await services.crm.call.update({
        callId: this.currentCall.call.id,
        callStatus: status,
        callMemoData: {
          callerId: this.currentCall.caller,
          counselingResult: this.currentCall.result,
          recallAt: this.currentCall.recallDate
            ? `${this.currentCall.recallDate} 00:00:00`
            : undefined,
          recallTime: this.currentCall.recallTime,
          memo: this.currentCall.memo,
        },
      });
      this.updateHistories(res.data);
    } else {
      const res = await services.crm.call.join({
        callId: this.currentCall.call.id,
        name: this.currentCall.name,
        gender: this.currentCall.sex,
        phoneNumber: this.currentCall.call.phoneNumber,
        acquisitionChannelId: this.currentCall.inflowSource || undefined,
        recommenderId: this.currentCall.referral ?? undefined,
        recommenderName: this.currentCall.referralName || undefined,
        smsEnabled: this.currentCall.sms ? 1 : 0,
        eventSmsEnabled: this.currentCall.promotion ? 1 : 0,
        callMemoData: {
          callerId: this.currentCall.caller,
          counselingResult: this.currentCall.result,
          recallAt: this.currentCall.recallDate
            ? `${this.currentCall.recallDate} 00:00:00`
            : undefined,
          recallTime: this.currentCall.recallTime,
          memo: this.currentCall.memo,
        },
      });

      this.updateHistories(res.data);

      // Load history to update previous histories of same phone number.
      this.loadHistory();
    }
  }

  async cancelCall() {
    if (this.currentCall.call.isCalling) {
      await services.crm.call.cancel({
        callId: this.currentCall.call.id,
      });

      this.loadHistory();
    }
    this.setCurrentCall(null);
  }

  async loadHistory() {
    const params = {};
    if (this.updateTimestamp) {
      params['updatedStartAt'] = this.updateTimestamp;
    }

    const res = await services.crm.call.history(params);
    if (res.data.length === 0) return;

    this.updateTimestamp = res.lastUpdatedAt;
    this.updateHistories(res.data);
  }

  updateHistories(data) {
    const histories = data.map((d) => ({
      ...d,
      createdAt: new Date(d.createdAt),
    }));

    for (const history of histories) {
      const current = this.historyItems.find((h) => h.id === history.id);
      if (current) {
        if (
          current.isCalling &&
          current.callerId === this.auth.me.id &&
          history.callStatus !== CallStatus.calling
        ) {
          this.setCurrentCall(null);
        }

        Object.assign(current, history);
      } else {
        const call = new Call(history);
        this.addNewCall(call);
        if (call.isCalling && call.callerId === this.auth.me.id) {
          this.setCurrentCall(call.id);
        }
      }
    }
  }

  get ringings() {
    return this.historyItems
      .filter((h) => h.isRinging && h.ringing !== false && this.filterByDate(h))
      .sort((a, b) => {
        return a.createdAt - b.createdAt;
      });
  }

  get missedCount() {
    return this.historyItems.filter((h) => h.isMissed && this.filterByDate(h))
      .length;
  }

  get recallCount() {
    return this.historyItems.filter(
      (h) =>
        [
          CounselingResult.recall_need,
          CounselingResult.recall_first_absence,
          CounselingResult.recall_second_absence,
        ].includes(h.counselingResult) && this.filterByDate(h)
    ).length;
  }

  get callingItems() {
    return this.historyItems.filter((h) => {
      return h.isCalling && h.callerId === this.auth.me.id;
    });
  }

  filterByType(history) {
    if (this.historyFilter === CtiStatusFilterType.all) return true;

    if (this.historyFilter === CtiStatusFilterType.ringing)
      return history.isRinging;

    if (this.historyFilter === CtiStatusFilterType.calling)
      return history.isCalling;

    if (this.historyFilter === CtiStatusFilterType.ended)
      return history.isEnded;

    if (this.historyFilter === CtiStatusFilterType.missed)
      return history.isMissed;

    if (this.historyFilter === CtiStatusFilterType.recall) {
      return history.isRecall;
    }

    if (this.historyFilter === CtiStatusFilterType.handled) {
      return history.isHandled;
    }

    return false;
  }

  filterByDate(call) {
    return (
      (Number(this.today) - Number(call.createdAt)) / (1000 * 60 * 60) <
      this.historyMaxHour
    );
  }

  clear() {
    if (this.currentCall) {
      const blob = new Blob(
        [
          JSON.stringify({
            callId: this.currentCall.call.id,
            access_token: `${window.sessionStorage.getItem('authToken')}`,
          }),
        ],
        {
          type: 'application/json',
        }
      ); // the blob

      navigator.sendBeacon(
        `${process.env.REACT_APP_API_URL}/centrex/call_cancel`,
        blob
      );
    }
  }

  get visibleItems() {
    return this.historyItems
      .filter((h) => {
        return this.filterByType(h) && this.filterByDate(h);
      })
      .sort((a, b) => {
        if (a.callStatus === b.callStatus) {
          return b.createdAt - a.createdAt;
        } else {
          if (a.isRinging && !a.isCalling) return -1;
          if (b.isRinging && !b.isCalling) return -1;
          if (a.isCalling) return -1;
          if (b.isCalling) return -1;
        }

        return b.createdAt - a.createdAt;
      });
  }

  canDisplayGuide(id) {
    return (
      (!this.guidesCount[id] || this.guidesCount[id] < 3) &&
      !this.guidesDisplayed[id]
    );
  }

  incrementGuide(id) {
    if (id in this.guidesCount) {
      this.guidesCount[id] += 1;
    } else {
      this.guidesCount[id] = 1;
    }

    localStorage.setItem('cti_guides', JSON.stringify(this.guidesCount));
  }

  setGuideDisplayed(id) {
    this.guidesDisplayed[id] = true;
  }
}
