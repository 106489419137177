export default {
  keys: [
    {
      title: 'ACTION',
      noorder: true,
      filter: (o) => `
            ${
              o.visible
                ? `<button class="btn btn-sm btn-primary m-r-8">${
                    o.$$editing ? '저장' : '수정'
                  }</button>`
                : ''
            }
            ${
              o.$$editing
                ? `<button class="btn btn-sm btn-danger m-r-8">취소</button>`
                : ''
            }
            `,
    },
    {
      title: 'name',
      column: 'name',
      noorder: true,
      type: 'editable',
    },
  ],
};
