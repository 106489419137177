import React, { useState } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import useCustomerChart from 'hooks/useCustomerChart';
import { useToast } from 'hooks/useToast';
import styled from 'styled-components';
import { RadioInput } from 'components/common/RadioInput';

const RadioInputWrapper = styled(RadioInput)`
  margin-right: 8px;
`;

const ModalAppointmentUpdateCompleteAutoSmsAlert = ({ options, close }) => {
  const toast = useToast();
  const [smsCheck, setSmsCheck] = useState(true);
  //자동문자 선택
  const [smsAutoNotifications, setSmsAutoNotifications] = useState(
    options.notifications
  );

  return (
    <div
      className={`modal-complete-update-auto-sms-alert`}
      style={{ width: '800px' }}
    >
      <div className="title">
        {options.msgType === 'default' &&
          '고객에게 예약완료 자동문자를 전송하시겠습니까?'}
        {(options.msgType === 'paste_cut' ||
          options.msgType === 'paste_copy') &&
          '붙여넣기 한 예약으로 고객에게 예약완료 자동문자를 전송하시겠습니까?'}
      </div>

      <label>
        <RadioInputWrapper
          name="smsCheck"
          checked={smsCheck === true}
          onChange={() => {
            setSmsCheck(true);
          }}
        />
        <span className="flex-wrap m-r-16">
          {options.msgType === 'paste_cut'
            ? '수정된 예약으로 문자내용 변경'
            : '전송'}
        </span>
      </label>
      <label>
        <RadioInputWrapper
          name="smsCheck"
          checked={smsCheck === false}
          onChange={() => {
            setSmsCheck(false);
          }}
        />
        <span className="flex-wrap m-r-16">
          {options.msgType === 'paste_cut' ? '기존 문자내용 유지' : '미전송'}
        </span>
      </label>
      {/* 전송 체크시에만 아래 문구 노출 */}
      {smsCheck === true && (
        <div className="m-t-8 flex-row">
          <span style={{ maxWidth: '125px' }}>자동문자 전송설정내용 : </span>
          <span style={{ float: 'right' }}>
            {useCustomerChart.SmsAutoNotificationsList(
              options.notifications,
              setSmsAutoNotifications,
              !smsCheck,
              options.scheduledAt
            )}
          </span>
        </div>
      )}
      <div className="buttons">
        <button
          className="btn btn-primary"
          onClick={() => {
            if (
              smsCheck &&
              smsAutoNotifications.every((e) => e['$$checked'] === false)
            ) {
              toast.error('최소 1개 이상의 전송할 예약 문자를 선택해 주세요.');
              return false;
            } else {
              close({
                sendAutoSmsCheck: smsCheck,
                sendAutoSmsIds: smsAutoNotifications
                  .filter((f) => f['$$checked'])
                  .map((v) => {
                    return v.id;
                  }),
              });
            }
          }}
        >
          확인
        </button>
      </div>
    </div>
  );
};

ModalAppointmentUpdateCompleteAutoSmsAlert.propTypes = {
  options: PropTypes.shape({
    model: PropTypes.string,
    type: PropTypes.string,
    data: PropTypes.array,
    notifications: PropTypes.array,
    msgType: PropTypes.string,
    scheduledAt: PropTypes.string,
  }),
  close: PropTypes.func,
};

export default observer(ModalAppointmentUpdateCompleteAutoSmsAlert);
