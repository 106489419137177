import React, { useEffect, useState, useCallback } from 'react';
import { observer } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinusCircle } from '@fortawesome/free-solid-svg-icons';
import TextareaAutosize from 'react-textarea-autosize';
import PropTypes from 'prop-types';
import { translate } from 'filters';
import { useAuth } from 'store/auth';
import styled from 'styled-components';
import { useToast } from 'hooks/useToast';

const Input = styled.input`
  overflow-y: hidden;
  width: 100%;
  background-color: transparent;
  border-width: 0;
  font-size: 0.9em;
  resize: none;
  height: 34px;
  line-height: 1.4em;
`;

const MessageAppMainView = ({
  className,
  uiObj,
  obj,
  setObj,
  onChangeHandler,
  contentsRef,
  resetImages,
  setLoadingBtnDisabledFlag,
}) => {
  const toast = useToast();
  const auth = useAuth();
  const unusableVariable = [
    '#{예약일}',
    '#{예약시간}',
    '#{대부서명}',
    '#{세부부서명}',
  ];
  const [LINK_CONSTANT] = useState('#바비톡병원홈페이지링크');
  // eslint-disable-next-line no-unused-vars
  const [rejectionPhoneNumber, setRejectionPhoneNumber] = useState('');
  const [rejectionString, setRejectionString] = useState('');
  const [unusableVariableList, setUnusableVariableList] = useState([]);

  useEffect(() => {
    // 실제 받게되는 문자내용은 다국어 UI요소가 아님.
    const nullCaseString = '번호가 없습니다';
    const meInfo = auth.me ? auth.me : {};
    const clinicInfo = meInfo.clinic ? auth.me.clinic : {};
    const rejection_phone_number = clinicInfo.rejectionPhoneNumber
      ? clinicInfo.rejectionPhoneNumber
      : '';
    if (!rejection_phone_number) {
      setRejectionPhoneNumber(rejection_phone_number);
      setRejectionString(nullCaseString);
    } else {
      setRejectionPhoneNumber(rejection_phone_number);
      setRejectionString(rejection_phone_number);
    }
  }, [auth.me]);

  useEffect(() => {
    setLoadingBtnDisabledFlag && checkUnusableVariable(obj.contents);
  }, [obj.contents]);

  const checkUnusableVariable = (text) => {
    let arr = [];
    unusableVariable.map((v) => {
      if (text.includes(v)) arr.push(v);
    });
    setUnusableVariableList(arr);
    setLoadingBtnDisabledFlag(arr.length > 0);
  };

  const countByteByString = useCallback((str) => {
    let count = 0;
    const strLength = str ? str.length : 0;
    for (let i = strLength; i--; ) {
      if (str.charCodeAt(i) > 127) {
        count += 2;
      } else if (str.charAt(i) !== '\r') {
        count += 1;
      }
    }
    return count;
  }, []);

  const substringByteByString = useCallback((str, max) => {
    let count = 0;

    for (let i = 0; i < max; i++) {
      let byte = 0;
      if (str.charCodeAt(i) > 127) {
        byte = 2;
      } else if (str.charAt(i) !== '\r') {
        byte = 1;
      }
      if (count + byte > max) {
        return str.substr(0, i);
      } else {
        count += byte;
      }
    }
    return str;
  }, []);

  return (
    <div className={`app-view-main ${className}`}>
      <div className="message-bubble">
        <div className="message-bubble-clinic-profile">
          {/* <FontAwesomeIcon icon={faUser} mask={faCircle} transform="shrink-7" size="2x"/> */}
          {/* <div className="message-bubble-datetime">HH:mm</div>. 예약문자의 경우가 있어 뺌 */}
        </div>
        <div className="message-bubble-content">
          {unusableVariableList.length > 0 && (
            <p className="error-msg c-red">
              사용할 수 없는 변수가 있습니다 : <br />
              <b>{String(unusableVariableList)}</b>
              <br />
              해당 변수를 사용하려면 예약캘린더 > 마우스 우클릭하여
              문자전송해주세요.
            </p>
          )}
          {uiObj.previewImages &&
            uiObj.previewImages.length === 1 &&
            uiObj.previewImages.map((imgObj, idx) => (
              <div className="message-bubble-image" key={idx}>
                <div
                  className={'remove-image'}
                  onClick={(e) => {
                    resetImages(e);
                  }}
                >
                  <FontAwesomeIcon icon={faMinusCircle} />
                </div>
                <img src={imgObj.url} alt="" />
              </div>
            ))}
          <div className="message-bubble-text">
            {uiObj.useTitle && (
              <div className="message-title ">
                <div className="title-input">
                  <Input
                    name="title"
                    onChange={onChangeHandler}
                    value={obj.title}
                    placeholder={translate('PLACEHOLDER_SMS_TITLE')}
                  />
                </div>
                <div className="title-status">
                  <span className="title-bytes">{uiObj.titleBytes}</span>
                  <span className="title-max-bytes">/40&nbsp;byte</span>
                </div>
              </div>
            )}

            <div className="message-contents">
              <div className="content-input">
                {obj.smsType === 'event' && (
                  <span className="event-prefix">(광고)</span>
                )}
                <TextareaAutosize
                  inputRef={contentsRef}
                  className="message-contents  message-textarea "
                  name={'contents'}
                  value={obj.contents}
                  placeholder={translate('PLACEHOLDER_SMS_CONTENTS')}
                  onPaste={(e) => {
                    const text = (e.originalEvent || e).clipboardData.getData(
                      'text/plain'
                    );
                    if (
                      countByteByString(e.target.value) +
                        countByteByString(text) >
                      2000
                    ) {
                      e.preventDefault();
                      const {
                        selectionStart,
                        selectionEnd,
                      } = contentsRef.current;
                      const maxByte = 2000 - countByteByString(e.target.value);
                      const valueLength = contentsRef.current.value.length
                        ? contentsRef.current.value.length
                        : 0;
                      const beforeCursorValue = contentsRef.current.value.substring(
                        0,
                        selectionStart
                      );
                      const afterCursorValue = contentsRef.current.value.substring(
                        selectionEnd,
                        valueLength
                      );

                      const substrText = substringByteByString(text, maxByte);

                      const joinedValue = `${beforeCursorValue}${substrText}${afterCursorValue}`;
                      contentsRef.current.value = joinedValue;
                      setObj((obj) => ({
                        ...obj,
                        contents: joinedValue,
                      }));

                      contentsRef.current.selectionStart =
                        selectionStart + substrText.length;
                      contentsRef.current.selectionEnd =
                        selectionStart + substrText.length;
                      contentsRef.current.focus();
                      toast.error('최대 입력수를 초과하였습니다');
                    }
                  }}
                  onChange={(e) => {
                    onChangeHandler(e);
                  }}
                />
                {/* #{바비톡 병원 홈페이지 링크} 변수처리 결과가 들어갈 영역 */}
                {obj.smsType === 'event' && obj.isBabitalkUri && (
                  <span className="event-suffix">{LINK_CONSTANT}</span>
                )}
                {obj.smsType === 'event' && (
                  <span className="event-suffix">
                    무료거부 {rejectionString}
                  </span>
                )}
              </div>
              <div className="content-status">
                <span className="message-type">{uiObj.type}</span>
                <span className="content-bytes">{uiObj.contentsBytes}</span>
                <span className="content-max-bytes">
                  &nbsp;/&nbsp;{`${uiObj.type === 'SMS' ? 90 : 2000}`}&nbsp;byte
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

MessageAppMainView.propTypes = {
  className: PropTypes.string,
  uiObj: PropTypes.object,
  obj: PropTypes.object,
  setObj: PropTypes.func,
  onChangeHandler: PropTypes.func,
  contentsRef: PropTypes.object,
  resetImages: PropTypes.func,
  setLoadingBtnDisabledFlag: PropTypes.func,
};

export default React.memo(observer(MessageAppMainView));
