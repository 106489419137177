import React, { createContext, useContext, useMemo } from 'react';
import { makeAutoObservable, runInAction } from 'mobx';
import services from 'services';
import moment from 'moment';

class Holiday {
  loadedMonths = [];
  data = [];

  constructor() {
    makeAutoObservable(this);
  }

  loadHolidayApi = async (startDay, endDay) => {
    const calenderStartDay = moment(startDay)
      .startOf('month')
      .startOf('isoweek')
      .format('YYYY-MM-DD');
    const calendarEndDay = moment(endDay)
      .endOf('month')
      .endOf('isoweek')
      .format('YYYY-MM-DD');

    const resp = await services.crm.holiday.list({
      locdateStartAt: calenderStartDay,
      locdateEndAt: calendarEndDay,
      orderBy: 'locdate asc',
    });
    return resp.data;
  };

  setHolidayData = async (startDate) => {
    const startMonth = moment(startDate).format('YYYY-MM');

    if (this.loadedMonths.indexOf(startMonth) === -1) {
      const list = await this.loadHolidayApi(startDate, startDate);
      runInAction(() => {
        this.loadedMonths = this.loadedMonths.concat(startMonth);
        this.data = this.data.concat(
          list.filter((v) => !this.data.find((f) => f.locdate === v.locdate))
        );
      });
    }
  };
}

const HolidayContext = createContext();

export const useHoliday = () => {
  return useContext(HolidayContext);
};

export const HolidayProvider = (props) => {
  const store = useMemo(() => new Holiday(), []);
  return <HolidayContext.Provider value={store} {...props} />;
};
