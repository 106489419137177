import React, { useState, useEffect, useCallback } from 'react';
import { observer } from 'mobx-react';
import Select from 'react-select';
import Creatable from 'react-select/creatable';
import InputDatePickerRange from 'components/common/InputDatePickerRange';
import { translate } from 'filters';
import { useToast } from 'hooks/useToast';
import { useData } from 'hooks/useData';

import PropTypes from 'prop-types';
import models from 'models';
import { useClinicCheck } from 'store/clinicCheck';

const ConditionForm = ({ uiKeyName, query, onChange }) => {
  const toast = useToast();
  const storeData = useData();

  const clinicCheck = useClinicCheck();

  const [model, setModel] = useState({
    title: null,
    column: null,
    type: null,
    multi: null,
    use: null,
    store: {
      key: null,
      value: null,
      label: null,
      filter: () => null,
    },
    options: [null],
  });
  const [options, setOptions] = useState([]);
  //예약항목 등 선택한 시/수술카테고리 (ex) 예약항목 선택 시, 전체 상위 카테고리 목록을 label, value로 반환)
  const [categories, setCategories] = useState([]);
  //선택한 카테고리 목록 (셀렉트박스에 선택된 상위 카테고리 목록을 label, value로 반환)
  const [selectCategory, setSelectCategory] = useState([]);

  const loadModel = useCallback(() => {
    setOptions([]);
    if (!uiKeyName) {
      return;
    }
    const model = models.crm.messageTargetCondition.keys.find(
      (model) => model.uiKeyName === uiKeyName
    );
    if (!model) {
      const errStr = `모델에 명시되지 않은 uiKeyName값이 사용되었습니다. : ${uiKeyName})`;
      // throw Error(errStr)
      console.debug(errStr);
    }

    if (model === undefined) {
      //구버전 검색항목에 대한 예외처리
      toast.error('검색할 수 없는 조건입니다. 검색항목을 다시 선택하세요.');
      return;
    }

    if (!model.inputType) {
      const er1 = uiKeyName;
      const filename = '/models/message-target-condition';
      const errStr = `잘못 정의된 모델입니다. \n참고: ${filename}'s keys:{ column: ${er1} }에 해당하는 모델을 확인하세요`;
      // throw Error(errStr)
      console.debug(errStr);
    }

    const { inputType, useStoreData, store, filter } = model;

    if (inputType === 'select' && useStoreData) {
      const { key, value, label } = store;
      const _options = storeData.getters()[key].map((row) => {
        return {
          label: filter ? filter(row[label]) : row[label],
          value: row[value],
        };
      });
      setOptions(_options);
    }
    if (inputType === 'select' && !useStoreData) {
      const _options = model.options
        ? model.options.map((obj) => ({
            label: filter ? filter(obj.label) : obj.label,
            value: obj.value,
          }))
        : [];
      setOptions(_options);
    }
    setModel(model);

    if (inputType === 'depth-select' && useStoreData) {
      const { key, value, label } = store;
      const _options = storeData.getters()[key].map((row) => {
        return {
          label: filter ? filter(row[label]) : row[label],
          value: row[value],
        };
      });
      setCategories(_options);

      //스토어 데이터에서, query(하위 카테고리 아이디)가 포함된 상위 카테고리 리스트를 생성
      const _categories = storeData.getters()[key].filter((row) => {
        return (
          row.items.filter((r) => {
            return (
              (query || []).find((q) => {
                return q === r.id;
              }) !== undefined
            );
          }).length !== 0
        );
      });
      setSelectCategory([
        ..._categories.map((v) => {
          return { label: v.name, value: v.id };
        }),
      ]);

      //선택된 상위 카테고리에서 items 배열을 뽑아 하위 카테고리 전체 목록을 lable, value set으로 만듦
      let options_ = [];
      _categories.forEach((v) => (options_ = [...options_, ...v.items]));
      setOptions([
        ...options_.map((v) => {
          return { label: v.name, value: v.id };
        }),
      ]);
    }
  }, [models.crm.messageTargetCondition.keys, uiKeyName, storeData]);

  useEffect(() => {
    loadModel();
  }, [loadModel, uiKeyName]);

  const INPUT_TYPE = Object.freeze({
    INPUT: 'input',
    SELECT: 'select',
    // DATE: "date",
    DATERANGE: 'date-range',
    //DEPTH_SELECT : 관심항목, 예약항목, 진료항목 등
    //시/수술 카테고리 - 시/수술명 2depth 검색조건 셀렉트
    DEPTH_SELECT: 'depth-select',
  });

  return (
    <>
      {!uiKeyName && (
        <div className="null">
          <span>{translate('PLACEHOLDER_SELECT_CONDITION')}</span>
        </div>
      )}
      {model.inputType === INPUT_TYPE.DATERANGE && (
        <InputDatePickerRange
          value={query}
          name={{ query: ['start', 'end'] }}
          onChange={onChange}
        />
      )}

      {model.inputType === INPUT_TYPE.INPUT && (
        <Creatable
          isMulti
          className="select"
          classNamePrefix="uno-select"
          // classNamePrefix="input-condition-query"
          closeMenuOnSelect={false}
          value={(query ? query : []).map((val) => {
            return {
              value: val,
              label: val,
            };
          })}
          onChange={(e) => {
            // const beforeValue = query ? query : []
            const selectedValue = (e ? e : []).map((o) => o.value);
            // const nextValue = [ ...new Set([...beforeValue, ...(selectedValue) ])]
            onChange({
              target: {
                name: 'query',
                value: selectedValue,
              },
            });
          }}
          placeholder={translate('INPUT_SEARCH_CONDITION')}
          formatCreateLabel={(value) => `Add to.. ${value}`}
          noOptionsMessage={() => translate('ENTER_SEARCH_VALUE')}
          noOptionsText={translate('ENTER_SEARCH_VALUE')}
          styles={{
            control: SelectControlComponent,
          }}
        />
      )}
      {model.inputType === INPUT_TYPE.SELECT && (
        <Select
          isDisabled={
            clinicCheck.isClinicStantopCounselor &&
            model.category === 'customer' &&
            model.item === 'counselor'
          }
          isMulti
          className="select"
          classNamePrefix="uno-select"
          options={options}
          closeMenuOnSelect={false}
          value={(query && options ? options : []).filter((opt) => {
            return query.indexOf(opt.value) > -1;
          })}
          onChange={(e) => {
            // const beforeValue = query ? query : []
            const selectedValue = (e ? e : []).map((o) => o.value);
            // const nextValue = [ ...new Set([...beforeValue, ...(selectedValue) ])]
            onChange({
              target: {
                name: 'query',
                value: selectedValue,
              },
            });
          }}
          placeholder={translate('SELECT_A_SEARCH_VALUE')}
          noOptionsMessage={() => translate('EMPTY_A_SEARCH_VALUE')}
          noOptionsText={translate('EMPTY_A_SEARCH_VALUE')}
          isSearchable={false}
          styles={{
            control: SelectControlComponent,
          }}
        />
      )}

      {model.inputType === INPUT_TYPE.DEPTH_SELECT && (
        <div className="uno-depth-select">
          <Select
            isMulti
            className="select"
            classNamePrefix="uno-select"
            options={categories}
            closeMenuOnSelect={false}
            value={selectCategory}
            onChange={(e) => {
              setSelectCategory([...(e || [])]);

              //하위 검색조건에 시/수술명 set
              const { store } = model;
              const { key } = store;

              const _options = storeData
                .getters()
                [key].filter((v) => (e || []).find((f) => f.value === v.id))
                .map((row) => {
                  return row.items.map((v) => {
                    return { label: v.name, value: v.id };
                  });
                });

              let options_ = [];
              _options.forEach((v) => (options_ = [...options_, ...v]));
              setOptions([...options_]);

              //하위 시수술명 옵션 변경
              //ex) 상위 시수술 삭제했을 때, 하위 시수술명도 삭제
              let selectedValue = (query && options ? options : [])
                .filter((opt) => {
                  return query.indexOf(opt.value) > -1;
                })
                .filter((v) => {
                  return (
                    options_.find((opt) => {
                      return opt.value === v.value;
                    }) !== undefined
                  );
                });

              onChange({
                target: {
                  name: 'query',
                  value: selectedValue.map((v) => v.value),
                },
              });
            }}
            placeholder={translate('SELECT_A_SEARCH_VALUE')}
            noOptionsMessage={() => translate('EMPTY_A_SEARCH_VALUE')}
            noOptionsText={translate('EMPTY_A_SEARCH_VALUE')}
            isSearchable={false}
            styles={{
              control: SelectControlComponent,
            }}
          />
          <Select
            isMulti
            className="select"
            classNamePrefix="uno-select"
            options={options}
            closeMenuOnSelect={false}
            value={(query && options ? options : []).filter((opt) => {
              return query.indexOf(opt.value) > -1;
            })}
            onChange={(e) => {
              // const beforeValue = query ? query : []
              const selectedValue = (e ? e : []).map((o) => o.value);
              // const nextValue = [ ...new Set([...beforeValue, ...(selectedValue) ])]
              onChange({
                target: {
                  name: 'query',
                  value: selectedValue,
                },
              });
            }}
            placeholder={translate('SELECT_A_SEARCH_VALUE')}
            noOptionsMessage={() => translate('EMPTY_A_SEARCH_VALUE')}
            noOptionsText={translate('EMPTY_A_SEARCH_VALUE')}
            isSearchable={false}
            styles={{
              control: SelectControlComponent,
            }}
          />
        </div>
      )}
    </>
  );
};

ConditionForm.propTypes = {
  uiKeyName: PropTypes.string,
  // oneOfType: 여러 종류중 하나의 종류가 될 수 있는 객체
  // object인경우 : 검색항목 최종방문일,생일,예약일자 처럼 데이터피커 2개가 노출되는 경우
  // array인 경우 : 그외
  query: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  onChange: PropTypes.func,
};

export default observer(ConditionForm);

const SelectControlComponent = (props) => ({
  ...props,
  cursor: 'pointer',
  border: 0,
  boxShadow: 'none',
  minHeight: '1em',
  fontSize: '.9em',
});
