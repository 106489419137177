import React from 'react';
import useCtiStore from './hooks/useCtiStore';
import './ctiRecallDatePicker.scss';
import { observer } from 'mobx-react';
import moment from 'moment';
import DatePicker from 'components/common/DatePicker';

export default observer(function CtiRecallDatePicker({
  open,
  onClose,
  date,
  onChange,
}) {
  const ctiStore = useCtiStore();

  if (!open) return null;

  function onSelect(d) {
    onChange(moment(d).format('YYYY-MM-DD'));
    onClose();
  }

  return (
    <div
      className={`cti-recall-date-picker ${ctiStore.settings.callPopupLocation}`}
    >
      <DatePicker
        inline
        calendarClassName="folding-cnt"
        className="text-center flex-wrap"
        value={date}
        onSelect={onSelect}
        onCalendarClose={onClose}
      />
    </div>
  );
});
