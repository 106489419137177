import { makeAutoObservable } from 'mobx';

const getDeprecatedConfig = (key) => {
  return JSON.parse(window.localStorage.getItem('uiState'))?.[key];
};

const getLocalStorage = (key) => {
  return JSON.parse(window.localStorage.getItem(key));
};

class UI {
  isNavMenuFold =
    getLocalStorage('isNavMenuFold') ?? getDeprecatedConfig('isNavMenuFold');
  isCardFold =
    getLocalStorage('isCardFold') ?? getDeprecatedConfig('isCardFold');
  isMenuFold =
    getLocalStorage('isMenuFold') ?? getDeprecatedConfig('isMenuFold');
  showLeftNav =
    getLocalStorage('showLeftNav') ?? getDeprecatedConfig('showLeftNav');
  showNavigation =
    typeof this.showLeftNav === 'boolean'
      ? this.showLeftNav
      : window.screen.width >= 720;
  prescriptionPrintBtnDisabledFlag = false;

  constructor() {
    makeAutoObservable(this);
  }

  setUiState = (key, value) => {
    const update = { ...this[key], ...value };
    window.localStorage.setItem(key, JSON.stringify(update));
    this[key] = update;
  };

  setShowNavigation = (show) => {
    window.localStorage.setItem('showLeftNav', show);
    this.showNavigation = show;
  };

  setPrescriptionPrintBtnDisabledFlag = (disabled) => {
    this.prescriptionPrintBtnDisabledFlag = disabled;
  };
}

export default new UI();
