import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { translate } from 'filters';
import ConditionContainer from './ConditionContainer';
import TargetTable from './TargetTable';

const TargetContainer = ({
  searchHistoryInit,
  setSearchHistoryInit,
  targetDataObj,
  setTargetDataObj,
  checkedCount,
  setCheckedCount,
  smsType,
}) => {
  useEffect(() => {
    //smsType 이 변경될때 검색된 결과를 대상리스트에 적용 버튼 동작
    onAppliyResults();
  }, [smsType]);

  const STATUS_TYPE = {
    IDLE: 'idle',
    LOAD: 'load',
    SEARCH: 'search',
    SET: 'set',
    APPLY: 'apply',
  };
  const [status, setStatus] = useState(STATUS_TYPE.INIT);
  const [searchDataObj, setSearchDataObj] = useState({
    targetCount: 0,
    smsSearchHistory: {
      id: null,
    },
  });
  const onAppliyResults = () => {
    const { smsSearchHistory } = searchDataObj;
    setTargetDataObj({
      smsSearchHistory,
      excludePhoneNumbers: [],
    });
    setStatus(STATUS_TYPE.APPLY);
  };

  return (
    <div className="target-container">
      <div className="group-wrap">
        <ConditionContainer
          searchHistoryInit={searchHistoryInit}
          setSearchHistoryInit={setSearchHistoryInit}
          dataObj={searchDataObj}
          setDataObj={setSearchDataObj}
          status={status}
          setStatus={setStatus}
        />
        <div className="set-target">
          <button
            className="btn btn-basic _small"
            onClick={() => onAppliyResults()}
          >
            {translate('APPLY_SEARCH_RESULTS')}
          </button>
        </div>
        <div className="card m-t-20">
          <TargetTable
            searchHistoryInit={searchHistoryInit}
            status={status}
            setStatus={setStatus}
            smsSearchHistory={targetDataObj.smsSearchHistory}
            dataObj={targetDataObj}
            setDataObj={setTargetDataObj}
            checkedCount={checkedCount}
            setCheckedCount={setCheckedCount}
            smsType={smsType}
          />
        </div>
      </div>
    </div>
  );
};

TargetContainer.propTypes = {
  searchHistoryInit: PropTypes.bool,
  setSearchHistoryInit: PropTypes.func,
  targetDataObj: PropTypes.shape({
    smsSearchHistory: PropTypes.shape({
      id: PropTypes.number,
    }),
    excludePhoneNumbers: PropTypes.array,
  }),
  setTargetDataObj: PropTypes.func,
  checkedCount: PropTypes.number,
  setCheckedCount: PropTypes.func,
  smsType: PropTypes.string,
};

export default observer(TargetContainer);
