import React from 'react';
import './ctiHistoryMenu.scss';
import { observer } from 'mobx-react';
import useCtiStore from './hooks/useCtiStore';
import classNames from 'classnames';
// import { CallStatus } from './callStatus';

export default observer(function CtiHistoryMenu() {
  const ctiStore = useCtiStore();

  function showHistory() {
    ctiStore.setShowHistory(true);
    ctiStore.setHistoryFilter('all');
  }

  return (
    <div className="cti-history-menu">
      <button
        className={classNames('cti-history-open-icon', {
          calling: ctiStore.currentCall != null,
          badge: ctiStore.historyItems.filter((h) => h.isRinging).length > 0,
        })}
        onClick={showHistory}
      ></button>
    </div>
  );
});
