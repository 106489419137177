import moment from 'moment';
import { currency } from 'filters';
import { mustParse } from 'utils/json';

export default {
  keys: [
    {
      title: '상담문의일',
      column: 'requestAt',
      filter: (o) => moment(o.requestAt).format('YYYY-MM-DD HH:mm:ss'),
      thStyle: () => {
        return { width: '116px' };
      },
      tdDivStyle: () => {
        return {
          textAlign: 'center',
          width: '116px',
          textOverflow: 'ellipsis',
          whiteSpace: 'normal',
        };
      },
    },
    {
      title: '배정된 담당자',
      column: 'assignUserName',
      filter: (o) => (o.assignUser !== null ? o.assignUser.name : ''),
    },
    {
      title: '처리결과',
      column: 'status',
      filter: (o) => (o.status === null ? '미응대' : o.status.name),
    },
    {
      title: '매체',
      column: 'mediaName',
      filter: (o) => o.media?.tag,
      thStyle: () => {
        return { width: '110px' };
      },
      tdDivStyle: () => {
        return {
          textAlign: 'center',
          width: '110px',
          textOverflow: 'ellipsis',
          whiteSpace: 'normal',
        };
      },
    },
    {
      title: '이벤트명',
      column: 'eventName',
      tdDivStyle: () => {
        return {
          textAlign: 'center',
          width: '240px',
          textOverflow: 'ellipsis',
          whiteSpace: 'normal',
        };
      },
    },
    {
      title: '이벤트 유형',
      column: 'eventType',
    },
    {
      title: '이벤트 가격',
      column: 'price',
      filter: (o) => {
        return o.price === null ? '' : `₩${currency(o.price)}`;
      },
    },
    {
      title: '방문희망일',
      column: 'visitAt',
      filter: (o) =>
        o.visitAt !== null ? moment(o.visitAt).format('YYYY-MM-DD') : '',
      thStyle: () => {
        return { width: '116px' };
      },
      tdDivStyle: () => {
        return {
          textAlign: 'center',
          width: '116px',
          textOverflow: 'ellipsis',
          whiteSpace: 'normal',
        };
      },
    },
    {
      title: '관심항목',
      column: 'interestItem',
      thStyle: () => {
        return { width: '180px' };
      },
      tdDivStyle: () => {
        return {
          textAlign: 'center',
          width: '180px',
          textOverflow: 'ellipsis',
          whiteSpace: 'normal',
        };
      },
    },
    {
      title: '고객문의',
      column: 'memo',
      thStyle: () => {
        return { width: '180px' };
      },
      tdDivStyle: () => {
        return {
          width: '180px',
          textOverflow: 'ellipsis',
          whiteSpace: 'normal',
        };
      },
    },
    {
      title: '메모',
      filter: (o) => {
        let arr = mustParse(
          o.consultingRequestResult.length ? o.consultingRequestResult : ''
        );
        return `<div class="flex-row">
                <div class="memo-list">
                ${
                  (arr || []).length > 0
                    ? arr
                        .map(
                          (memoItem, idx) =>
                            `<div class="memo-item index-${idx}">${memoItem.memo}</div>`
                        )
                        .join('')
                    : ''
                }
                </div>
            </div>`;
      },
      thStyle: () => {
        return { width: '240px' };
      },
      tdDivStyle: () => {
        return {
          width: '240px',
          textOverflow: 'ellipsis',
          whiteSpace: 'normal',
        };
      },
    },
  ],
};
