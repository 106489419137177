import React, { useEffect, useState, useRef } from 'react';
import { observer } from 'mobx-react';
import DataTableAlpha from 'components/data-table/DataTableAlpha';
import PropTypes from 'prop-types';
import modelMedicine from 'models/medicine';
import services from 'services';
import { $qb } from 'scripts/querybuilder';
import styled from 'styled-components';

const Input = styled.input`
  min-height: 10px;
  padding: 16px 20px;
  border-radius: 4px;
  background-color: #f3f8ff;
  color: #000;
  font-size: 14px;
  border: 1px solid #dee2ec;
  width: 100%;
  height: 34px;
  margin-right: 8px;
`;

const ModalSearchMedicine = ({ close, options }) => {
  const inputRef = useRef(null);

  const defaultParams = $qb().limit(10);
  const [data, setData] = useState(null);
  const [total, setTotal] = useState(null);
  const [params, setParams] = useState(defaultParams);
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  const getMedicineList = async () => {
    let requestParams = {
      type: options.type,
      keyword: searchValue,
      ...params.build(),
    };
    const resp = await services.crm.prescription.drug_list.all_v2(
      requestParams
    );
    if (resp) {
      setData(resp.data.prescriptionDrugList);
      setTotal(resp.meta.pagination.total);
    }
  };

  const onParams = (p) => {
    setParams(p);
    getMedicineList();
  };

  const onAction = (obj) => {
    const select = obj.row;
    const event = obj.event;

    if (event.target.innerText === '선택') {
      options.onSelect(options.type, select);
      close();
      return;
    }
  };

  const onClickSearch = () => {
    getMedicineList();
    onParams(params);
  };

  return (
    <div className="modal-serach-medicine">
      <div className="head flex-row flex-between items-start m-b-16">
        <div className="title">
          {options.type === 'medicine' && '처방약 선택'}
          {options.type === 'injection' && '주사제 선택'}
        </div>
        <i onClick={() => close()} className="zmdi zmdi-close" />
      </div>
      <div className="search-bar">
        <Input
          placeholder="약품명을 입력하세요"
          onChange={(e) => setSearchValue(e.target.value)}
          defaultValue={searchValue || ''}
          onKeyPress={(e) => e.key === 'Enter' && onClickSearch()}
          ref={inputRef}
        />
        <button
          className="btn btn-basic _small"
          onClick={() => onClickSearch()}
        >
          검색
        </button>
      </div>
      <DataTableAlpha
        model={modelMedicine}
        data={data}
        total={total}
        params={params}
        onParams={onParams}
        onAction={onAction}
      />
    </div>
  );
};

ModalSearchMedicine.propTypes = {
  close: PropTypes.func,
  options: PropTypes.object,
};

export default observer(ModalSearchMedicine);
