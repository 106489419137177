import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import DataTableAlpha from 'components/data-table/DataTableAlpha';
import OrganizationsDetail from 'components/pages/crm/organizations/OrganizationsDetail';
import ModalOrganizationsAuthoritySettings from 'components/modals/ModalOrganizationsAuthoritySettings';
import ModalTemporaryChangePassword from 'components/modals/ModalTemporaryChangePassword';
import models from 'models';
import { $qb } from 'scripts/querybuilder';
import { useEvent } from 'hooks/useEvent';
import { useModal } from 'hooks/useModal';
import { useServices } from 'hooks/useServices';
import styled from 'styled-components';
import searchIcon from 'assets/images/icon/ic-search.png';
import { useAuth } from 'store/auth';

const Input = styled.input`
  font-size: 12px;
  width: 306px;
  padding: 8px 15px 8px 50px;
  border: 1px solid #dee2ec;
  border-radius: 4px;
  height: 46px;
  background-color: #fff;
  background-image: url(${searchIcon});
  background-size: 19px;
  background-repeat: no-repeat;
  background-position: left 15px center;
`;

const OrganizationsStaff = () => {
  const history = useHistory();
  const eventBus = useEvent();
  const modal = useModal();
  const services = useServices();
  const defaultParams = $qb().limit(10);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [detailData, setDetailData] = useState({});
  const [params, setParams] = useState(defaultParams);
  const [searchValue, setSearchValue] = useState('');
  const [searchStatusValue, setSearchStatusValue] = useState('');
  const OrganizationType = { DEPARTMENT: 'department', STAFF: 'staff' };
  const auth = useAuth();

  const callApi = useCallback(async () => {
    const resp = await services.crm.organizations.organ.all(params.build());
    if (!resp) return;

    resp.data.forEach((data) => {
      data.$$checked = data.user.isConsultingRequestUser;
    });

    setData(resp.data);
    setTotal(resp.total);
  }, [services.crm.organizations.organ, params]);

  useEffect(() => {
    eventBus.on('callApi', callApi);

    params.queryParams = {
      ...params.queryParams,
      type: OrganizationType.STAFF,
      userStatus: 'active,deactivated',
    };
    callApi();

    return () => eventBus.off('callApi');
  }, [eventBus, callApi]);

  const onClickCreate = () => {
    history.push('/crm/organizations-users');
  };

  const onParams = (p) => {
    setParams(p);
    callApi();
  };

  const onActionSettings = (user) => {
    modal.custom({
      component: ModalOrganizationsAuthoritySettings,
      options: {
        user,
      },
    });
  };

  const onActionEditIsConsultingRequestUser = async (payload) => {
    try {
      await services.crm.user.update(payload);
      modal
        .confirm({
          type: 'SUCCESS',
          msg: '업데이트되었습니다.',
        })
        .then(() => {
          callApi();
          auth.loadMe();
        });
    } catch (e) {
      modal.confirm({
        type: 'ERROR',
        msg: e.description,
      });
    }
  };

  const onActionEditUser = (user) => {
    history.push(`/crm/organizations-users/${user.userId}`);
  };

  const onActionEditStatus = async (user) => {
    const { id, status } = user.user;
    let statusStr = status === 'active' ? 'deactivated' : 'active';

    try {
      const payload = { email: user.user.email, id, status: statusStr };
      await services.crm.user.update(payload);
      modal
        .confirm({
          type: 'SUCCESS',
          msg: '업데이트되었습니다.',
        })
        .then(() => {
          callApi();
        });
    } catch (e) {
      modal.confirm({
        type: 'ERROR',
        msg: e.description,
      });
    }
  };

  const deleteUserCallApi = async (user) => {
    const id = user.userId;
    try {
      await services.crm.user.delete(id);
      modal
        .confirm({
          type: 'SUCCESS',
          msg: '삭제되었습니다.',
        })
        .then(() => {
          callApi();
        });
    } catch (e) {
      modal.confirm({
        type: 'ERROR',
        msg: e.description,
      });
    }
  };

  const onActionDeleteUser = (user) => {
    modal
      .basic({
        body: `정말로 삭제하시겠습니까?`,
        buttons: [
          {
            text: 'CANCEL',
            class: 'btn-default',
          },
          {
            text: 'CONFIRM',
            class: 'btn-primary',
          },
        ],
      })
      .then((selectedIdx) => {
        if (selectedIdx === 1) {
          deleteUserCallApi(user);
        }
      });
  };

  const onActionChangePassword = (user) => {
    modal.custom({
      component: ModalTemporaryChangePassword,
      options: { userId: user.userId },
    });
  };

  const onAction = (obj) => {
    const event = obj.event;
    const user = obj.row;
    const classList = event.target.classList;

    if (classList.contains('checkbox') || classList.contains('zmdi-check')) {
      let isConsultingRequestUser =
        user.user.isConsultingRequestUser === null ||
        !user.user.isConsultingRequestUser
          ? true
          : false;
      let editUser = {
        isConsultingRequestUser,
        email: user.user.email,
        id: user.user.id,
      };
      onActionEditIsConsultingRequestUser(editUser);
    }

    if (classList.contains('btn')) {
      if (classList.contains('btn-edit')) {
        onActionEditUser(user);
        return;
      }
      if (classList.contains('btn-status')) {
        onActionEditStatus(user);
        return;
      }
      if (classList.contains('btn-delete')) {
        onActionDeleteUser(user);
        return;
      }
      if (classList.contains('btn-settings')) {
        if (!classList.contains('btn-gray')) {
          onActionSettings(user);
          return;
        }
      }
      if (classList.contains('btn-change-pw')) {
        onActionChangePassword(user);
        return;
      }
    } else {
      setDetailData(user);
    }
  };

  const onChangeSearch = (e) => {
    const { value } = e.target;
    let statusStr = value === '' ? 'active,deactivated' : value;
    setSearchStatusValue(statusStr);
  };

  const onClickSearch = () => {
    params.queryParams = {
      limit: params.queryParams.limit,
      userName: searchValue.userName,
      type: OrganizationType.STAFF,
      userStatus: searchStatusValue,
    };
    onParams(params);
  };

  return (
    <div className="list organizations organizations-staff">
      <div className="page-navi">
        <span className="title">병원관리</span>
        <span className="title">직원 관리</span>
      </div>
      <div className="staff-list">
        <div className="search-bar">
          <div className="search-item title">
            <span>직원 목록</span>
          </div>
          <div className="search-item">
            <Input
              name="userName"
              placeholder="이름"
              onChange={(e) =>
                setSearchValue({ [e.target.name]: e.target.value })
              }
            />
          </div>
          <div className="search-item">
            <select name="userStatus" onChange={onChangeSearch}>
              <option value="">사용여부</option>
              <option value="active">사용</option>
              <option value="deactivated">미사용</option>
              <option value="deleted">삭제</option>
            </select>
          </div>
          <div className="search-item">
            <button className="btn-sm" onClick={onClickSearch}>
              {'검색'}
            </button>
          </div>
          <div className="search-item">
            <div className="right">
              <button className="btn-sm" onClick={onClickCreate}>
                {'신규 등록'}
              </button>
            </div>
          </div>
        </div>
        <DataTableAlpha
          model={models.crm.organizationsStaff}
          data={data}
          total={total}
          params={params}
          onParams={onParams}
          onAction={onAction}
          hideLimitSetter={true}
          hasDeactivate
        />
        <OrganizationsDetail data={detailData} />
      </div>
    </div>
  );
};

export default observer(OrganizationsStaff);
