import { currency, currencySymbol } from 'filters';
import moment from 'moment';

export default {
  keys: [
    {
      title: '번호',
      key: 'no',
      column: 'no',
      columnGroup: false,
    },
    {
      title: '일자',
      key: 'paidAt',
      column: 'paidAt',
      orderKey: 'paidAt',
      order: 'desc',
      columnGroup: false,
      filter: (o) => {
        if (o !== undefined) {
          return `<div><span>${moment(o.paidAt).format(
            'YYYY-MM-DD'
          )}</span></div>`;
        }
      },
    },
    {
      title: '고객명',
      key: 'customerId',
      column: 'name',
      columnGroup: false,
      filter: (o) => {
        return `<div><span class="text-underline c-primary cursor-pointer">${
          (o || {}).name
        }</span></div>`;
      },
    },
    {
      title: '매출액',
      key: 'totalAmount',
      column: 'totalAmount',
      columnGroup: false,
      filter: (o) => {
        return `${currencySymbol()} ${currency((o || {}).totalAmount || 0)}`;
      },
    },
    {
      title: '수납액',
      columnGroup: true,
      columnItems: [
        { title: 'Total', key: 'sumAmount', filter: { type: 'amount' } },
        {
          title: '신용카드',
          key: 'creditCardAmount',
          filter: { type: 'amount' },
        },
        {
          title: '체크카드',
          key: 'debitCardAmount',
          filter: { type: 'amount' },
        },
        {
          title: '계좌이체',
          key: 'bankTransferAmount',
          filter: { type: 'amount' },
        },
        { title: '현금', key: 'cashAmount', filter: { type: 'amount' } },
      ],
    },
    {
      title: '환불액',
      columnGroup: true,
      columnItems: [
        { title: 'Total', key: 'refundAmount', filter: { type: 'amount' } },
        {
          title: '신용카드',
          key: 'refundCreditCardAmount',
          filter: { type: 'amount' },
        },
        {
          title: '체크카드',
          key: 'refundDebitCardAmount',
          filter: { type: 'amount' },
        },
        {
          title: '계좌이체',
          key: 'refundBankTransferAmount',
          filter: { type: 'amount' },
        },
        { title: '현금', key: 'refundCashAmount', filter: { type: 'amount' } },
      ],
    },
    {
      title: '실매출액',
      key: 'actualAmount',
      column: 'actualAmount',
      columnGroup: false,
      filter: (o) => {
        // 실매출액 : '수납 total 금액' - '환불액' = actualAmount
        return `${currencySymbol()} ${currency((o || {}).actualAmount)}`;
      },
    },
    {
      title: '현금영수증 발급액',
      key: 'cashReceiptAmount',
      column: 'cashReceiptAmount',
      columnGroup: false,
      filter: (o) => {
        return `${currencySymbol()} ${currency(
          (o || {}).cashReceiptAmount || 0
        )}`;
      },
    },
  ],
};
