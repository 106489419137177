import React, { useCallback, useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { currency, translate } from 'filters';
import DataTableAlpha from 'components/data-table/DataTableAlpha';
import ModalAddPaymentSurgeries from 'components/modals/ModalAddPaymentSurgeries';
import ModalAddPaymentProducts from 'components/modals/ModalAddPaymentProducts';
import ChangePrice from 'components/pages/crm/customer-chart/payments/ChangePrice';
import ChangeVatNotPrice from 'components/pages/crm/customer-chart/payments/ChangeVatNotPrice';
import ChangeDiscountAmount from 'components/pages/crm/customer-chart/payments/ChangeDiscountAmount';
import QuillTextField from 'components/quill/QuillTextField';
import moment from 'moment';
import PropTypes from 'prop-types';
import models from 'models';
import useCustomerChart from 'hooks/useCustomerChart';
import { useEvent } from 'hooks/useEvent';
import { useModal } from 'hooks/useModal';
import { useServices } from 'hooks/useServices';
import { useToast } from 'hooks/useToast';
import { RadioInput } from 'components/common/RadioInput';

const ModalPaymentsUpdate = ({ options, close }) => {
  const eventBus = useEvent();
  const modal = useModal();
  const services = useServices();
  const toast = useToast();
  const [loadingBtnDisabledFlag, setLoadingBtnDisabledFlag] = useState(false);
  const [obj, setObj] = useState({});
  const [paidAt, setPaidAt] = useState(new Date());
  const [counselors, setCounselors] = useState([]);
  const [radioValue, setRadioValue] = useState(false);
  const [counselorFlag, setCounselorFlag] = useState(false);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState({});

  const userCallApi = useCallback(async () => {
    let params = { userStatus: 'active' };
    const resp = await services.crm.user.duty(params);
    if (!resp) return;

    setUsers(resp.data);
  }, [services.crm.user]);

  useEffect(() => {
    userCallApi();
  }, [userCallApi]);

  const counselorCallApi = useCallback(async () => {
    let params = { duty: '상담사', userStatus: 'active', limit: 300 };
    const resp = await services.crm.user.duty(params);
    if (!resp) return;

    setCounselors(resp.data);
  }, [services.crm.user]);

  useEffect(() => {
    counselorCallApi();
  }, [counselorCallApi]);

  const dataSettings = () => {
    const { row } = options;
    const { paymentProducts, paymentTreatmentItems } = options.row;
    //고객의 담당상담사가 있는 경우
    if (options.customer && options.customer.counselor) {
      // 상담건의 상담사가 있는 경우
      if (row && row.counselor && row.counselor.id) {
        // 고객의 담당상담사와 상담건의 상담사가 다른경우
        if (row.counselor.id !== options.customer.counselor.id) {
          setCounselorFlag(true);
        } else {
          setCounselorFlag(false);
        }
      } else {
        setCounselorFlag(true);
      }
    }
    //고객의 담당상담사가 없는 경우
    else {
      // 상담건의 상담사가 있는 경우
      if (row && row.counselor && row.counselor.id) {
        setCounselorFlag(true);
      } else {
        setCounselorFlag(false);
      }
    }
    setObj({
      ...options.row,
      surgeriesData: paymentTreatmentItems.map((v, i) => {
        v.checkId = i;
        return v;
      }),
      productsData: paymentProducts.map((v, i) => {
        v.checkId = i;
        return v;
      }),
    });
    setPaidAt(moment(options.row.firstPaidAt));
    setSelectedUser({
      id: options.row.createdId,
      name: options.row.createdName,
    });
  };

  useEffect(() => {
    dataSettings();
  }, []);

  const openModalAddPaymentSurgeries = () => {
    modal
      .custom({
        component: ModalAddPaymentSurgeries,
      })
      .then((items) => {
        if (items !== undefined) {
          let addAllData = [...items];
          let allRequestAmount = obj.requestAmount;
          let allTotalAmount = obj.totalAmount;
          let allDiscountAmount = obj.discountAmount;

          addAllData.forEach((v) => {
            v.treatmentItemId = v.id;
            v.priceVatInclude = parseInt(v['PRICE_VAT_INCLUDE_$$input']);
            v.treatmentCount = parseInt(v['SURGERY_NUMOF_$$input']);
            v.remainingCount = parseInt(v['SURGERY_NUMOF_$$input']);
            v.isFree = v['FREE_SERVICE_$$checked'];

            let total = v.priceVatInclude;
            let discount =
              v.discountAmount === undefined ? 0 : v.discountAmount;
            allRequestAmount += total - discount;
            allTotalAmount += total;
            allDiscountAmount += discount;
          });

          const allData = [
            ...obj.surgeriesData,
            ...addAllData.map((v) => {
              //복사생성이기에 id값을 삭제하고 넘겨야 함
              // eslint-disable-next-line no-unused-vars
              const { id: deletedKey, ...otherKeys } = v;
              return otherKeys;
            }),
          ].map((v, i) => {
            v.checkId = i;
            return v;
          });
          setObj({
            ...obj,
            requestAmount: allRequestAmount,
            totalAmount: allTotalAmount,
            unpaidAmount: allRequestAmount - obj.paidAmount,
            discountAmount: allDiscountAmount,
            surgeriesData: allData,
          });
        }
      });
  };

  const openModalAddPaymentProducts = () => {
    modal
      .custom({
        component: ModalAddPaymentProducts,
      })
      .then((items) => {
        if (items !== undefined) {
          let addAllData = [...items].filter(
            (v) => parseInt(v['QUANTITY_$$input']) !== 0
          );
          let allRequestAmount = obj.requestAmount;
          let allTotalAmount = obj.totalAmount;
          let allDiscountAmount = obj.discountAmount;

          addAllData.forEach((v) => {
            v.productId = v.id;
            v.priceVatInclude =
              v['PRICE_VAT_INCLUDE_$$input'] * parseInt(v['QUANTITY_$$input']);
            v.price = Math.round(
              (v['PRICE_VAT_INCLUDE_$$input'] *
                parseInt(v['QUANTITY_$$input'])) /
                1.1
            );
            v.quantity = parseInt(v['QUANTITY_$$input']);
            v.requestAmount =
              v['PRICE_VAT_INCLUDE_$$input'] * parseInt(v['QUANTITY_$$input']);
            v.quantity = parseInt(v['QUANTITY_$$input']);

            let total = v.priceVatInclude;
            let discount =
              v.discountAmount === undefined ? 0 : v.discountAmount;
            allRequestAmount += total - discount;
            allTotalAmount += total;
            allDiscountAmount += discount;
          });

          const allData = [
            ...obj.productsData,
            ...addAllData.map((v) => {
              //복사생성이기에 id값을 삭제하고 넘겨야 함
              // eslint-disable-next-line no-unused-vars
              const { id: deletedKey, ...otherKeys } = v;
              return otherKeys;
            }),
          ].map((v, i) => {
            v.checkId = i;
            return v;
          });
          setObj({
            ...obj,
            requestAmount: allRequestAmount,
            totalAmount: allTotalAmount,
            unpaidAmount: allRequestAmount - obj.paidAmount,
            discountAmount: allDiscountAmount,
            productsData: allData,
          });
        }
      });
  };

  const onActionSurgeries = (object) => {
    const event = object.event;
    const payments = object.row;
    const classList = event.target.classList;
    const model = object.key;

    if (model.title === 'PRICE_VAT_INCLUDE') {
      //VAT포함가격 변경 팝업
      openModalChangePrice(payments, 'surgeries');
      return;
    }

    if (model.title === 'NOT_VAT_INCLUDE') {
      //VAT포함가격 변경 팝업
      openModalChangeVatNotPrice(payments, 'surgeries');
      return;
    }

    if (model.title === '할인') {
      //할인 변경 팝업
      openModalChangeDiscount(payments, 'surgeries');
      return;
    }

    if (classList.contains('zmdi-minus')) {
      //삭제
      onClickDeleteSurgeries(payments);
    }
  };

  const onAction = (object) => {
    const event = object.event;
    const payments = object.row;
    const classList = event.target.classList;
    const model = object.key;

    if (model.title === 'PRICE_VAT_INCLUDE') {
      //VAT포함가격 변경 팝업
      openModalChangePrice(payments, 'products');
      return;
    }

    if (model.title === 'NOT_VAT_INCLUDE') {
      //VAT포함가격 변경 팝업
      openModalChangeVatNotPrice(payments, 'products');
      return;
    }

    if (model.title === '할인') {
      //할인 변경 팝업
      openModalChangeDiscount(payments, 'products');
      return;
    }

    if (classList.contains('zmdi-minus')) {
      //삭제
      onClickDeleteProducts(payments);
    }
  };

  const openModalChangePrice = (payments, type) => {
    const data = type === 'surgeries' ? obj.surgeriesData : obj.productsData;
    let changeFlag = false;
    let changePayment = payments;
    const setPayments = (p) => {
      changePayment = p;
      changeFlag = true;
    };

    modal
      .slot({
        title: translate('PRICE_VAT_INCLUDE'),
        preparedDom: () => (
          <ChangePrice payments={payments} setPayments={setPayments} />
        ),
        showCloseButton: true,
        buttons: [
          { text: 'CANCEL', class: 'btn-primary' },
          { text: '확인', class: 'btn-primary' },
        ],
      })
      .then((idx) => {
        if (idx === 1 && changeFlag) {
          let allDataRequestAmount = 0;
          let allDataTotalAmount = 0;
          const list = data.map((v) => {
            if (v.checkId === changePayment.checkId) {
              v.price = changePayment.vatIncluded
                ? Math.round(changePayment.priceVatInclude / 1.1)
                : changePayment.priceVatInclude;
              v.priceVatInclude = changePayment.priceVatInclude;
              const discountAmt = changePayment.discountAmount || 0;
              v.requestAmount = Math.round(
                changePayment.priceVatInclude - discountAmt
              );
              v.totalAmount = changePayment.priceVatInclude;
            }

            allDataRequestAmount +=
              v.requestAmount === undefined
                ? v.priceVatInclude - v.discountAmount
                : v.requestAmount;
            allDataTotalAmount +=
              v.priceVatInclude === undefined ? 0 : v.priceVatInclude;
            return v;
          });

          let swapData =
            type === 'surgeries' ? obj.productsData : obj.surgeriesData;
          swapData.forEach((v) => {
            allDataRequestAmount +=
              v.requestAmount === undefined
                ? v.priceVatInclude - v.discountAmount
                : v.requestAmount;
            allDataTotalAmount +=
              v.priceVatInclude === undefined ? 0 : v.priceVatInclude;
          });

          let o = {
            ...obj,
            totalAmount: allDataTotalAmount,
            requestAmount: allDataRequestAmount,
            unpaidAmount: allDataRequestAmount - obj.payoutAmount,
          };

          if (type === 'surgeries') {
            setObj({
              ...o,
              surgeriesData: list,
            });
          } else {
            setObj({
              ...o,
              productsData: list,
            });
          }
        }
      });
  };

  const openModalChangeVatNotPrice = (payments, type) => {
    const data = type === 'surgeries' ? obj.surgeriesData : obj.productsData;
    let changePayment = payments;
    let changeFlag = false;
    const setPayments = (p) => {
      changePayment = p;
      changeFlag = true;
    };

    modal
      .slot({
        title: translate('NOT_VAT_INCLUDE'),
        preparedDom: () => (
          <ChangeVatNotPrice payments={payments} setPayments={setPayments} />
        ),
        showCloseButton: true,
        buttons: [
          { text: 'CANCEL', class: 'btn-primary' },
          { text: '확인', class: 'btn-primary' },
        ],
      })
      .then((idx) => {
        if (idx === 1 && changeFlag) {
          let allDataRequestAmount = 0;
          let allDataTotalAmount = 0;
          const list = data.map((v) => {
            if (v.checkId === changePayment.checkId) {
              v.price = changePayment.price;
              if (changePayment.vatIncluded) {
                v.priceVatInclude = Math.round(v.price * 1.1);
              } else {
                if (changePayment.changeFlag) {
                  v.priceVatInclude = v.price;
                }
              }
              const discountAmt = changePayment.discountAmount || 0;
              v.requestAmount = Math.round(v.priceVatInclude - discountAmt);
              v.totalAmount = v.priceVatInclude;
            }

            allDataRequestAmount +=
              v.requestAmount === undefined
                ? v.priceVatInclude - v.discountAmount
                : v.requestAmount;
            allDataTotalAmount +=
              v.priceVatInclude === undefined ? 0 : v.priceVatInclude;
            return v;
          });

          let swapData =
            type === 'surgeries' ? obj.productsData : obj.surgeriesData;
          swapData.forEach((v) => {
            allDataRequestAmount +=
              v.requestAmount === undefined
                ? v.priceVatInclude - v.discountAmount
                : v.requestAmount;
            allDataTotalAmount +=
              v.priceVatInclude === undefined ? 0 : v.priceVatInclude;
          });

          let o = {
            ...obj,
            totalAmount: allDataTotalAmount,
            requestAmount: allDataRequestAmount,
            unpaidAmount: allDataRequestAmount - obj.payoutAmount,
          };

          if (type === 'surgeries') {
            setObj({
              ...o,
              surgeriesData: list,
            });
          } else {
            setObj({
              ...o,
              productsData: list,
            });
          }
        }
      });
  };

  const openModalChangeDiscount = (payments, type) => {
    const data = type === 'surgeries' ? obj.surgeriesData : obj.productsData;
    let changePayment;
    let changeFlag = false;
    const setPayments = (p) => {
      changePayment = p;
      changeFlag = true;
    };

    const onSaveDiscount = (changePayment) => {
      let allDataRequestAmount = 0;
      let allDataDiscountAmount = 0;
      const list = data.map((v) => {
        if (v.checkId === changePayment.checkId) {
          let total = v.priceVatInclude;
          v.discountAmount = changePayment.discountAmount;
          v.requestAmount = total - changePayment.discountAmount;
          v.discountReason = changePayment.discountReason;
        }

        allDataDiscountAmount +=
          v.discountAmount === undefined ? 0 : v.discountAmount;
        allDataRequestAmount +=
          v.requestAmount === undefined
            ? v.priceVatInclude - v.discountAmount
            : v.requestAmount;
        return v;
      });

      let swapData =
        type === 'surgeries' ? obj.productsData : obj.surgeriesData;
      swapData.forEach((v) => {
        allDataDiscountAmount +=
          v.discountAmount === undefined ? 0 : v.discountAmount;
        allDataRequestAmount +=
          v.requestAmount === undefined
            ? v.priceVatInclude - v.discountAmount
            : v.requestAmount;
      });

      let o = {
        ...obj,
        discountAmount: allDataDiscountAmount,
        requestAmount: allDataRequestAmount,
        unpaidAmount: allDataRequestAmount - obj.payoutAmount,
      };

      if (type === 'surgeries') {
        setObj({
          ...o,
          surgeriesData: list,
        });
      } else {
        setObj({
          ...o,
          productsData: list,
        });
      }
    };

    modal
      .slot({
        title: '할인',
        preparedDom: () => (
          <ChangeDiscountAmount payments={payments} setPayments={setPayments} />
        ),
        showCloseButton: true,
        buttons: [
          { text: 'CANCEL', class: 'btn-gray' },
          { text: '확인', class: 'btn-primary' },
        ],
      })
      .then((idx) => {
        if (idx === 1 && changeFlag) {
          if (changePayment !== undefined) {
            onSaveDiscount(changePayment);
          }
        }
      });
  };

  const onClickDeleteSurgeries = (payments) => {
    let deleteItems = obj.surgeriesData.filter((f) => {
      return f.checkId !== payments.checkId;
    });

    let allRequestAmount = 0;
    let allTotalAmount = 0;
    let allDiscountAmount = 0;

    deleteItems.forEach((v) => {
      if (v.checkId !== payments.checkId) {
        let total = v.priceVatInclude;
        let discount = v.discountAmount === undefined ? 0 : v.discountAmount;
        allRequestAmount += total - discount;
        allTotalAmount += total;
        allDiscountAmount += discount;
      }
    });

    obj.productsData.forEach((v) => {
      let total = v.priceVatInclude;
      let discount = v.discountAmount === undefined ? 0 : v.discountAmount;
      allRequestAmount += total - discount;
      allTotalAmount += total;
      allDiscountAmount += discount;
    });

    // 완납 상태일때 delete시 미수액을 0으로 잡도록
    let unpaidAmount = payments.priceVatInclude;
    if (obj.status === 'paid') {
      unpaidAmount = 0;
    }

    setObj({
      ...obj,
      requestAmount: allRequestAmount,
      totalAmount: allTotalAmount,
      unpaidAmount: obj.unpaidAmount - unpaidAmount,
      discountAmount: allDiscountAmount,
      surgeriesData: deleteItems,
    });
  };

  const onClickDeleteProducts = (payments) => {
    let deleteItems = obj.productsData.filter((f) => {
      return f.checkId !== payments.checkId;
    });

    let allRequestAmount = 0;
    let allTotalAmount = 0;
    let allDiscountAmount = 0;

    deleteItems.forEach((v) => {
      if (v.checkId !== payments.checkId) {
        let total = v.priceVatInclude;
        let discount = v.discountAmount === undefined ? 0 : v.discountAmount;
        allRequestAmount += total - discount;
        allTotalAmount += total;
        allDiscountAmount += discount;
      }
    });

    obj.surgeriesData.forEach((v) => {
      let total = v.priceVatInclude;
      let discount = v.discountAmount === undefined ? 0 : v.discountAmount;
      allRequestAmount += total - discount;
      allTotalAmount += total;
      allDiscountAmount += discount;
    });

    // 완납 상태일때 delete시 미수액을 0으로 잡도록
    let unpaidAmount = payments.priceVatInclude;
    if (obj.status === 'paid') {
      unpaidAmount = 0;
    }

    setObj({
      ...obj,
      requestAmount: allRequestAmount,
      totalAmount: allTotalAmount,
      unpaidAmount: obj.unpaidAmount - unpaidAmount,
      discountAmount: allDiscountAmount,
      productsData: deleteItems,
    });
  };

  const setPaymentPayload = () => {
    const status = obj.unpaidAmount === 0 ? 'paid' : 'unpaid';

    let paymentTreatmentItems = obj.surgeriesData;
    let paymentProducts = obj.productsData;

    let payload = {
      id: obj.id,
      customer: obj.customer,
      customerId: ((obj || {}).customer || {}).id,
      counselorId: ((obj || {}).counselor || {}).id,
      paymentProducts: paymentProducts,
      paymentTreatmentItems: paymentTreatmentItems,
      totalAmount: obj.totalAmount,
      requestAmount: obj.requestAmount,
      payoutAmount: obj.payoutAmount,
      unpaidAmount: obj.unpaidAmount,
      paidAmount: obj.paidAmount,
      discountAmount: obj.discountAmount,
      createdId: selectedUser.id,
      createdName: selectedUser.name,
      memo: obj.memo,
      payouts: [...obj.payouts],
      status,
      refunds: [...obj.refunds],
      discountReason: null,
      reimbursementAmount: 0,
      nonReimbursementAmount: obj.totalAmount,
      refundAmount: obj.refundAmount,
      firstPaidAt: moment(paidAt).format('YYYY-MM-DDTHH:mm:ss'),
      saveCustomer: radioValue,
    };
    return payload;
  };

  const onClickSave = () => {
    const payload = setPaymentPayload();
    setLoadingBtnDisabledFlag(true);
    onClickSavePaymentUpdate(payload);
  };

  const onClickSavePaymentUpdate = useCallback(
    async (payload) => {
      try {
        const resp = await services.crm.payment.update(payload);
        if (!resp) return;

        modal
          .confirm({
            type: 'SUCCESS',
            msg: '업데이트되었습니다.',
          })
          .then(() => {
            setLoadingBtnDisabledFlag(false);
            eventBus.emit('customerCallApi');
            close(1);
          });
      } catch (e) {
        console.log(e.description);
        modal
          .confirm({
            type: 'ERROR',
            msg: e.description,
          })
          .then(() => {
            setLoadingBtnDisabledFlag(false);
          });
      }
    },
    [services.crm.payment, toast, close]
  );

  const onChangeScheduledAt = (scheduledAt) => {
    scheduledAt === null
      ? setPaidAt(moment().format())
      : setPaidAt(moment(scheduledAt).format());
  };

  return (
    <div className="modal-payments-update modal-payments-unpaid">
      <div className="head flex-row flex-between items-start">
        <div className="title">수정</div>
        <i onClick={() => close()} className="zmdi zmdi-close" />
      </div>

      <div className="data-input m-t-16">
        <div className="title">
          수납 입력
          <div className="right flex-row">
            <button
              className="btn btn-basic _small m-r-8"
              style={{ display: 'block' }}
              onClick={openModalAddPaymentSurgeries}
            >
              시/수술 추가
            </button>
            <button
              className="btn btn-basic _small"
              style={{ display: 'block' }}
              onClick={openModalAddPaymentProducts}
            >
              제품 추가
            </button>
          </div>
        </div>
      </div>

      <div className="form-wide m-b-32">
        <div className="form-control form-full">
          <DataTableAlpha
            model={models.crm.paymentUpdateSurgeries}
            data={obj.surgeriesData}
            hideBottom={true}
            hideAllCheck={true}
            onAction={onActionSurgeries}
          />
        </div>
      </div>
      {(obj.productsData || []).length > 0 && (
        <div className="form-wide p-b-32">
          <div className="form-control form-full">
            <DataTableAlpha
              model={models.crm.paymentInputProducts}
              data={obj.productsData}
              hideBottom={true}
              hideAllCheck={true}
              onAction={onAction}
            />
          </div>
        </div>
      )}

      <div className="data-input">
        <div className="total-price m-b-20">
          <div className="wrap-price">
            {/* 최종수납액 = 수납액 - 환불액(실제돌려받은금액) */}
            <p className="label">청구액</p>
            <p className="price">₩{currency(obj.requestAmount || 0)}</p>
            <p className="etc">
              <span>원가</span>
              <b>₩{currency(obj.totalAmount || 0)}</b>
            </p>
            <p className="etc">
              <span>할인</span>
              <b>(-) ₩{currency(obj.discountAmount || 0)}</b>
            </p>
          </div>
          <div className="wrap-price">
            <p className="label">수납액</p>
            <p className="price">₩{currency(obj.paidAmount || 0)}</p>
          </div>
          <div className="wrap-price">
            <p className="label">미수액</p>
            <p className="price">₩{currency(obj.unpaidAmount || 0)}</p>
          </div>
        </div>
      </div>

      <div className="body">
        <div className="flex-row">
          <div className="form-control flex-wrap">
            <label className="label-required">수납생성일</label>
            <div style={{ width: '180px' }}>
              {useCustomerChart.getScheduledAtDatePicker({
                objScheduledAt: paidAt || new Date(),
                onChangeScheduledAt,
              })}
            </div>
          </div>
          <div className="form-control flex-wrap" style={{ width: '180px' }}>
            <label>상담사</label>
            <select
              value={counselors.findIndex(
                (existing) => existing.id === (obj['counselor'] || {}).id
              )}
              onChange={(e) => {
                if (options.customer && options.customer.counselor) {
                  if (
                    counselors[e.target.value] &&
                    options.customer.counselor.id ===
                      counselors[e.target.value].id
                  ) {
                    setCounselorFlag(false);
                  } else {
                    setCounselorFlag(true);
                  }
                }
                // 고객의 담당상담사가 없는 경우
                else {
                  if (e.target.value === 'null') {
                    setCounselorFlag(false);
                  } else {
                    setCounselorFlag(true);
                  }
                }
                obj['counselor'] = counselors[e.target.value];
                setObj({ ...obj });
              }}
            >
              <option disabled key={-1} value={-1}>
                -
              </option>
              {(counselors || []).map((o, idx) => (
                <option key={idx} value={idx}>
                  {' '}
                  {o.name}{' '}
                </option>
              ))}
            </select>
          </div>
          <div className="form-control flex-wrap w-180">
            <label className="required">담당자</label>
            <select
              value={users.findIndex(
                (existing) => existing.id === (selectedUser || {}).id
              )}
              onChange={(e) => {
                setSelectedUser(users[e.target.value]);
              }}
            >
              <option disabled key={-1} value={-1}>
                -
              </option>
              {(users || []).map((o, idx) => (
                <option key={idx} value={idx}>
                  {o.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        {counselorFlag ? (
          <div className="flex-row" style={{ fontSize: '12px' }}>
            <div className="form-wide m-b-20">
              <span className="m-r-20">
                {counselorFlag ? ' [상담사] ' : null}
                {'를 해당 고객의 담당으로 지정하시겠습니까?'}
              </span>
              <span className="m-r-20">
                <RadioInput
                  name="saveCustomer"
                  checked={radioValue}
                  onChange={() => setRadioValue(true)}
                />{' '}
                예
              </span>
              <span className="m-r-20">
                <RadioInput
                  name="saveCustomer"
                  checked={!radioValue}
                  onChange={() => setRadioValue(false)}
                />{' '}
                아니오
              </span>
            </div>
          </div>
        ) : null}
        <div className="form-wide">
          <div className="form-control">
            <label>{translate('MEMO')}</label>
            <QuillTextField
              value={options.row.memo || ''}
              setValue={(v) => setObj({ ...obj, memo: v })}
              placeholder="메모를 입력해주세요."
            />
          </div>
        </div>
      </div>

      <div className="buttons">
        <div className="flex-row">
          <button onClick={close} className="btn btn-default">
            {translate('CANCEL')}
          </button>
          <button
            onClick={onClickSave}
            disabled={`${loadingBtnDisabledFlag ? 'disabled' : ''}`}
            className="btn btn-primary"
          >
            수정완료
          </button>
        </div>
      </div>
    </div>
  );
};

ModalPaymentsUpdate.propTypes = {
  options: PropTypes.object,
  close: PropTypes.func,
};

export default observer(ModalPaymentsUpdate);
