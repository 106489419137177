import React, { useCallback, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import DataTableAlpha from 'components/data-table/DataTableAlpha';
import QuillTextField from 'components/quill/QuillTextField2';
import { toHexColorHtml } from 'components/quill/quillUtil';
import Select from 'react-select';
import moment from 'moment';
import PropTypes from 'prop-types';
import ModalSurgery from 'components/modals/ModalSurgery';
import useCustomerChart from 'hooks/useCustomerChart';
import modelTreatmentTab from 'models/treatment-tab';
import SelectNextProcess from './SelectNextProcess';
import { copy, translate } from 'filters';
import { $qb } from 'scripts/querybuilder';
import { useAuth } from 'store/auth';
import { useEvent } from 'hooks/useEvent';
import { useModal } from 'hooks/useModal';
import { useServices } from 'hooks/useServices';
import { useToast } from 'hooks/useToast';
import { RadioInput } from 'components/common/RadioInput';

const CustomerTreatments = ({ customer, openFlag, closeFlag }) => {
  const history = useHistory();
  const auth = useAuth();
  const eventBus = useEvent();
  const modal = useModal();
  const services = useServices();
  const toast = useToast();

  const tabName = 'treatment';
  const [loadingBtnDisabledFlag, setLoadingBtnDisabledFlag] = useState(false);
  const defaultParams = $qb()
    .limit(10)
    .customParam('customerId', customer.id)
    .orderBy('scheduledAt desc');
  const [params, setParams] = useState(defaultParams);
  const [treatments, setTreatments] = useState([]);
  const [obj, setObj] = useState({});
  const [total, setTotal] = useState(0);
  const [nextProcess, setNextProcess] = useState(undefined); //다음진행 상태

  const [appointmentData, setAppointmentData] = useState({}); //예약데이터. 저장되어있는 의사, 피부관리사값을 불러와 다음진행 선택 팝업에서 불러오기위함
  const [treatmentSelectDoctors, setTreatmentSelectDoctors] = useState({}); //다음진행에서 선택한 의사
  const [treatmentSelectFacialists, setTreatmentSelectFacialists] = useState(
    {}
  ); //다음진행에서 선택한 피부관리사
  const [treatmentSelectCounselors, setTreatmentSelectCounselors] = useState(
    {}
  ); //다음진행에서 선택한 상담사
  const [addCategoriesObj, setAddCategoriesObj] = useState([{}]); // 시/수술 카테고리 배열

  // const [recallDate, setRecallDate] = useState()
  // const [recallTime, setRecallTime] = useState()
  const [processAppointmentId, setProcessAppointmentId] = useState(
    (openFlag || {}).appointmentId || null
  );
  //componentFlag : treatments(신규진료등록)
  const [componentFlag, setComponentFlag] = useState(null);
  const [memoBoilerplateList, setMemoBoilerplateList] = useState([]);

  //진료 저장에 필요한 코드성 데이터
  const [treatmentItemCategories, setTreatmentItemCategories] = useState([]);
  const [doctors, setDoctors] = useState([]);
  //다음진행 팝업에서 사용할 상담사/피부관리사 리스트
  const [facialist, setFaicalist] = useState([]);
  const [counselors, setCounselors] = useState([]);
  const [radioValue, setRadioValue] = useState(false);
  const [doctorFlag, setDoctorFlag] = useState(false);

  const selectCustomStyles = {
    container: () => ({
      marginRight: '10px',
      width: '200px',
      outlineWidth: '0px',
    }),
    control: (base) => ({
      ...base,
      padding: '8px 0px 8px 10px',
      boxShadow: '0px',
      minHeight: '34px',
      height: '34px',
    }),
    menu: (base) => ({
      ...base,
      zIndex: 2,
    }),
    valueContainer: (base) => ({
      ...base,
      height: '18px',
      padding: '0px',
    }),
    input: (base) => ({
      ...base,
      margin: '0px',
      border: '0px',
      padding: '0px !important',
      height: '18px',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    indicatorsContainer: (base) => ({
      ...base,
      height: '18px',
      width: '18px',
    }),
    singleValue: (base) => ({
      ...base,
      margin: '0px',
    }),
    placeholder: (base) => ({
      ...base,
      color: '-internal-light-dark(black, white)',
      margin: '0px 0px 0px 0px',
      whiteSpace: 'nowrap',
    }),
  };

  const facialistCallApi = useCallback(async () => {
    let params = { duty: '피부관리사', userStatus: 'active', limit: 300 };
    const resp = await services.crm.user.duty(params);
    if (!resp) return;

    setFaicalist(resp.data);
  }, [services.crm.user]);

  useEffect(() => {
    facialistCallApi();
  }, [facialistCallApi]);

  const counselorCallApi = useCallback(async () => {
    let params = { duty: '상담사', userStatus: 'active', limit: 300 };
    const resp = await services.crm.user.duty(params);
    if (!resp) return;

    setCounselors(resp.data);
  }, [services.crm.user]);

  useEffect(() => {
    counselorCallApi();
  }, [counselorCallApi]);

  const treatmentItemCategoriesCallApi = useCallback(async () => {
    try {
      let params = { limit: 10000, visible: true };
      const resp = await services.crm.treatment.categories.items_categories_v2(
        params
      );
      if (!resp) return;
      setTreatmentItemCategories(resp.data);
    } catch (e) {
      console.log(e.description);
    }
  }, [services.crm.treatment.categories]);

  useEffect(() => {
    treatmentItemCategoriesCallApi();
  }, [treatmentItemCategoriesCallApi]);

  const doctorCallApi = useCallback(async () => {
    let params = { duty: '의사', userStatus: 'active', limit: 300 };
    const resp = await services.crm.user.duty(params);
    if (!resp) return;

    setDoctors(resp.data);
  }, [services.crm.user]);

  useEffect(() => {
    doctorCallApi();
  }, [doctorCallApi]);

  const loadTreatments = useCallback(async () => {
    try {
      if (customer.id) {
        const resp = await services.crm.treatment.all_v2(params.build());
        if (!resp) return;

        setTreatments(resp.data);
        setTotal(resp.pagination.total);
      }
    } catch (e) {
      console.log(e.description);
    }
  }, [services.crm.treatment, customer.id, params]);

  const init = useCallback(async () => {
    loadTreatments();
  }, [loadTreatments]);

  const onDeleteTreatment = useCallback(
    async (id) => {
      try {
        await services.crm.treatment.delete_v2(id);
        modal
          .confirm({
            type: 'SUCCESS',
            msg: '삭제되었습니다.',
          })
          .then(() => {
            eventBus.emit('chartCountCallApi');
            init();
          });
      } catch (e) {
        console.log(e.description);
        modal.confirm({
          type: 'ERROR',
          msg: e.description,
        });
      }
    },
    [services.crm.treatment, toast, init]
  );

  const onClickDeleteTreatment = (treatment) => {
    modal
      .basic({
        body: '정말로 삭제하시겠습니까?',
        buttons: [
          {
            text: 'CANCEL',
            class: 'btn-default',
          },
          {
            text: 'CONFIRM',
            class: 'btn-primary',
          },
        ],
      })
      .then((idx) => {
        if (idx === 1) {
          onDeleteTreatment(treatment.id);
        }
      });
  };

  const infoFlagSetting = (name, row) => {
    //고객의 담당상담사가 있는 경우
    if (customer && customer[name]) {
      // 상담건의 상담사가 있는 경우
      if (row && row[name] && row[name].id) {
        // 고객의 담당상담사와 상담건의 상담사가 다른경우
        if (row[name].id !== customer[name].id) {
          if (name === 'doctor') setDoctorFlag(true);
        } else {
          if (name === 'doctor') setDoctorFlag(false);
        }
      } else {
        if (name === 'doctor') setDoctorFlag(true);
      }
    }
    //고객의 담당상담사가 없는 경우
    else {
      // 상담건의 상담사가 있는 경우
      if (row && row[name] && row[name].id) {
        if (name === 'doctor') setDoctorFlag(true);
      } else {
        if (name === 'doctor') setDoctorFlag(false);
      }
    }
  };

  const onAction = (obj) => {
    const event = obj.event;
    const row = obj.row;
    const classList = event.target.classList;
    const model = obj.key;

    if (
      (obj.eventType === 'doubleClick' && model?.title !== 'STATUS') ||
      classList.contains('btn-primary')
    ) {
      //수정
      //메모 상용구 api 호출
      memoBoilerplateCallApi();

      setObj(row);
      setNextProcess();
      setComponentFlag('treatments');
      infoFlagSetting('doctor', row);
      if (row.items !== undefined) {
        setAddCategoriesObj([...row.items]);
      }

      //고객의 가장 최근 예약건을 가져오기 위해 호출
      recentAppointmentDataCallApi();
    }

    if (classList.contains('btn-danger')) {
      onClickDeleteTreatment(row);
      return;
    }
  };

  const onParams = (p) => {
    setParams(p);
    loadTreatments();
  };

  const appointmentUpdate = async (appointmentUpdate) => {
    try {
      let payload = {
        id: appointmentData.id,
        scheduledAt: appointmentData.scheduledAt,
        facialist: appointmentData.facialist,
        counselor: appointmentData.counselor,
        customerId: ((appointmentData || {}).customer || {}).id,
        ...appointmentUpdate,
      };
      await services.crm.crud.appointment.update(payload);
    } catch (e) {
      console.log(e.description);
    }
  };

  // const needRecallUpdate = async(payload) => {
  //   try {
  //     await services.crm.consultingRequest.recalls.create(payload)
  //   } catch (e) {
  //     console.log(e.description)
  //   }
  // }

  const beforeSave = () => {
    let nextProcessStatus = nextProcess;

    let appointmentUpdatePayload;
    //nextProcess 값에 따라 다음진행 영역 셀렉트값 선택하여 onClickSave로 넘겨
    //상담 완료 하고 완료되면 예약데이터 업데이트
    if (nextProcess === 'consulting_waiting') {
      appointmentUpdatePayload = {
        counselor: treatmentSelectCounselors,
      };
    }
    if (nextProcess === 'surgery_waiting') {
      appointmentUpdatePayload = {
        doctor: treatmentSelectDoctors,
        facialist: treatmentSelectFacialists,
      };
    }

    onClickSave(nextProcessStatus, appointmentUpdatePayload);
  };

  const onClickSave = async (nextProcess, appointmentUpdatePayload) => {
    setLoadingBtnDisabledFlag(true);
    let endpoint = !obj.id ? 'create' : 'update';
    let changeProcess =
      nextProcess === 'payment_during' ? 'payment_waiting' : nextProcess;
    let items = addCategoriesObj.filter(
      (v) => v !== undefined && Object.keys(v).length !== 0
    );
    let categories =
      items.length > 0 ? items : endpoint === 'update' ? (items = []) : items;
    const integratedTreatmentItemCategory = [...categories];
    let payload = {
      ...obj,
      items: integratedTreatmentItemCategory,
      saveCustomer: radioValue,
    };
    if (obj.memo) payload.memo = toHexColorHtml(obj.memo);

    if (endpoint === 'create') {
      payload.appointmentStatus = changeProcess;

      if (processAppointmentId !== null) {
        payload.appointmentId = processAppointmentId;
      }
    }

    //필요한 request payload
    //appointmentId, appointmentStatus, customerId, doctor: { id },
    //items[{ id, category: { id } }], memo, saveCustomer, scheduledAt

    // customer 를 삭제하고 id만 전달하도록함
    if (payload.customer !== null) {
      payload.customerId = payload.customer.id;
      delete payload.customer;
    }

    // doctor 객체 내에서 id 값만 전달
    if (payload.doctor) {
      payload.doctor = {
        id: payload.doctor.id,
      };
    }

    //items 배열 객체 형식에 맞춰 전달
    if (payload.items.length > 0) {
      let changeItems = payload.items.map((v) => {
        const { category, id } = v;
        return {
          category: { id: category?.id },
          id,
        };
      });
      payload.items = changeItems;
    }

    try {
      let endpoint_v2 = endpoint === 'create' ? 'create_v2' : 'update_v2';
      const resp = await services.crm.treatment[endpoint_v2](payload);
      if (!resp) return;

      let message = translate(`${endpoint.toUpperCase()}D`);
      modal
        .confirm({
          type: 'SUCCESS',
          msg: message,
        })
        .then(async () => {
          eventBus.emit('chartCountCallApi');
          //탭이 닫히지 않을 때 내역 조회 필요
          loadTreatments();

          if (resp.data.id) {
            // && !obj.id
            // 진료 저장 후, 예약 데이터 업데이트 (의사/피부관리사 등의 변경)
            if (endpoint === 'create') {
              if (
                (nextProcess === 'consulting_waiting' &&
                  appointmentUpdatePayload?.counselor !== null) ||
                (nextProcess === 'surgery_waiting' &&
                  (appointmentUpdatePayload?.doctor !== null ||
                    appointmentUpdatePayload?.facialist !== null))
              ) {
                //예약이 수정되는 경우 : 시수술,추가상담
                let appointmentPayload = {
                  customerId: (customer || {}).id,
                  ...appointmentUpdatePayload,
                };
                await appointmentUpdate(appointmentPayload, nextProcess);
              }
            }

            // if(nextProcess === 'need_recall') {
            //     //리콜필요 테이블에 추가
            //     const recallsPayload = {
            //       userId: auth.me.id,
            //       customerId: customer.id,
            //       visitAt: moment(obj.scheduledAt).format('YYYY-MM-DDTHH:mm:ss'),
            //       recallAt: recallTime !== undefined ? moment(recallDate).hours(recallTime.split(":")[0])
            //       .minutes(recallTime.split(":")[1]).format('YYYY-MM-DDTHH:mm:ss') : recallDate
            //     }
            //     await needRecallUpdate(recallsPayload)
            //   }
          }
          stateInit();
          eventBus.emit('customerCallApi');

          if (history.location.pathname === '/crm/appointment-boards') {
            eventBus.emit('boardsLoadAppointments');
          } else if (history.location.pathname === '/crm/home') {
            eventBus.emit('homeLoadAppointments');
          }

          if (closeFlag) {
            modal.pop();
            return;
          }
        });
    } catch (e) {
      console.log(e.description);
      modal
        .confirm({
          type: 'ERROR',
          msg: e.description,
        })
        .then(() => {
          setLoadingBtnDisabledFlag(false);
        });
    }
  };

  const onChangeValue = (column, value) => {
    setObj((obj) => {
      return { ...obj, [column]: value };
    });
  };

  // const makePeriodicItemAssociations = useCallback(() => {
  //   if (!obj || !obj.items) return

  //   obj.periodicItems = []
  //   obj.items
  //     .forEach(treatmentItem =>
  //       (treatmentItem.periodicItems || []).forEach(periodicItem => {
  //         let periodicItemAssociation = {
  //           periodicItem,
  //         }
  //         obj.periodicItems.push(periodicItemAssociation)
  //       })
  //     )
  // })

  const initTreatment = (treatmentId) => {
    let item = [{}];
    let doctor = customer && customer.doctor ? customer.doctor : null;
    if (treatmentId && (treatments || []).length > 0) {
      setObj(
        copy(treatments.find((treatment) => treatment.id === treatmentId))
      );
    } else {
      setObj({ customer, items: item, doctor, scheduledAt: moment().format() });
    }
    setComponentFlag('treatments');

    if (openFlag === undefined) {
      setAddCategoriesObj(item);
    } else {
      if (openFlag.treatment === null) {
        setAddCategoriesObj(item);
      }
    }
  };

  const addTreatmentItem = () => {
    if (treatmentItemCategories[0] === undefined) {
      toast.error('수납코드 설정에서 시/수술을 추가해주세요.');
      return;
    }

    let list = [...addCategoriesObj];
    list.push({});
    setAddCategoriesObj([...list]);
  };

  // useEffect(() => {
  //   makePeriodicItemAssociations()
  // }, [obj, makePeriodicItemAssociations])

  const closetToTodayAppointmentCallApi = async () => {
    //오늘 예약건을 조회하여 setProcessAppointmentId
    //예약건 복수 개중 가장 오늘과 가까운 가장 최근 예약건을 찾기 위해 orderBy=scheduledAt+asc
    //ex) customerId=290startAt=2020-07-29&orderBy=scheduledAt+asc
    if (!customer.id) return;

    const notLeaveItemId = await useCustomerChart.useGetAppointmentClosetToTodayItem(
      customer.id
    );
    if (notLeaveItemId !== null) {
      setProcessAppointmentId(notLeaveItemId);
    }
  };

  useEffect(() => {
    //탭 진입 시, openFlag 값이 있으면 setObj setState
    if (openFlag !== undefined) {
      if (openFlag.treatment === null) {
        onClickCreateTreatment();
      } else if (openFlag.treatment !== undefined) {
        setObj(openFlag.treatment);
      }
    } else {
      //openFlag === undefined > 헤더 등으로 고객통합차트 진입
      //데이터 생성 시, 예약 status 변경을 "오늘 중" 예약건의 status를 변경하도록 수정.
      closetToTodayAppointmentCallApi();
    }

    return () => {
      setObj({});
      setComponentFlag(null);
    };
  }, [openFlag]);

  useEffect(() => {
    init();
  }, [init]);

  const stateInit = useCallback(async () => {
    setComponentFlag(null);
    setObj({});
    setLoadingBtnDisabledFlag(false);
    setAppointmentData({});
    setTreatmentSelectDoctors({});
    setTreatmentSelectFacialists({});
    setTreatmentSelectCounselors({});
    setNextProcess();
    setRadioValue(false);
    setDoctorFlag(false);
  }, []);

  const actionInit = useCallback(async () => {
    init();
    stateInit();
  }, [init, stateInit]);

  const onClickCreateTreatment = async () => {
    //메모 상용구 api 호출
    memoBoilerplateCallApi();

    initTreatment();
    //고객의 가장 최근 예약건을 가져오기 위해 호출
    recentAppointmentDataCallApi();
  };

  //해당 고객의 가장 최근 예약건을 조회하여, 다음진행 팝업의 doctor, facialist, counselor를 세팅
  const recentAppointmentDataCallApi = async () => {
    try {
      if (!customer.id) return;

      const appointment = await useCustomerChart.useGetRecentAppointmentItem(
        customer.id
      );
      if (!appointment.id) return;

      setAppointmentData(appointment);
      if (appointment.doctor !== null) {
        setTreatmentSelectDoctors(appointment.doctor);
      }
      if (appointment.facialist !== null) {
        setTreatmentSelectFacialists(appointment.facialist);
      }
      if (appointment.counselor !== null) {
        setTreatmentSelectCounselors(appointment.counselor);
      }
    } catch (e) {
      console.log(e.description);
    }
  };

  const onCallbackProcessChangeToWating = (selectObj, nextProcess) => {
    if (!selectObj) return;
    setTreatmentSelectCounselors(selectObj.counselor);
    setTreatmentSelectDoctors(selectObj.doctor);
    setTreatmentSelectFacialists(selectObj.facialist);
    setToggleNextProcessBtn(nextProcess);
  };

  const processChangeToConsultingWaiting = () => {
    //상담 대기할 상담사 선택 팝업
    if (nextProcess !== 'consulting_waiting') {
      useCustomerChart.useProcessChangeToWaiting({
        nextProcessStatus: 'consulting_waiting',
        counselors,
        treatmentSelectCounselors,
        callback: onCallbackProcessChangeToWating,
      });
    } else {
      setToggleNextProcessBtn('consulting_waiting');
    }
  };

  const processChangeToSurgeryWaiting = () => {
    //시수술 대기할 의사/피부관리사 선택 팝업
    if (nextProcess !== 'surgery_waiting') {
      useCustomerChart.useProcessChangeToWaiting({
        nextProcessStatus: 'surgery_waiting',
        doctors,
        facialist,
        treatmentSelectDoctors,
        treatmentSelectFacialists,
        callback: onCallbackProcessChangeToWating,
      });
    } else {
      setToggleNextProcessBtn('surgery_waiting');
    }
  };

  const setToggleNextProcessBtn = (status) => {
    if (nextProcess === status) {
      setNextProcess(undefined);
    } else {
      setNextProcess(status);
    }
  };

  const memoBoilerplateCallApi = useCallback(async () => {
    try {
      const memoItems = await useCustomerChart.useGetMemoBoilerplate(tabName);
      setMemoBoilerplateList(memoItems);
    } catch (e) {
      console.log(e.description);
    }
  }, []);

  const onChangeScheduledAt = (scheduledAt) => {
    let date =
      scheduledAt === null ? moment().format() : moment(scheduledAt).format();
    setObj((obj) => {
      return { ...obj, scheduledAt: date };
    });
  };

  const onClickOpenPaymentCodePopup = () => {
    // afterUpdateReturn: true > 팝업에서 수납코드 저장 후 저장된 내용 반환 위한 플래그
    modal
      .custom({
        component: ModalSurgery,
        options: { surgery: undefined, afterUpdateReturn: true },
      })
      .then((result) => {
        if (typeof result === 'object') {
          treatmentItemCategoriesCallApi();
          const setItems = useCustomerChart.useSettingSurgeryItems(
            result,
            addCategoriesObj
          );
          setAddCategoriesObj(setItems);
        }
      });
  };

  return (
    <div className="customer-treatments chart-page">
      <div className="data-input">
        <div className="title">
          {translate('FORM_TREATMENT')}{' '}
          {(obj || {}).id ? <span>&#40;ID: {obj.id}&#41;</span> : null}
          <div className="right">
            {componentFlag === null ? (
              <button
                className="btn btn-basic _small"
                onClick={onClickCreateTreatment}
              >
                신규진료등록
              </button>
            ) : (
              <></>
            )}
          </div>
        </div>
        {obj && componentFlag === 'treatments' ? (
          <div className="card">
            <div className="form-double">
              <div className="form-control">
                <label className="label-required">
                  {translate('TREATMENT_SCHEDULED_AT')}
                </label>
                {useCustomerChart.getScheduledAtDatePicker({
                  objScheduledAt: obj.scheduledAt || new Date(),
                  onChangeScheduledAt,
                })}
              </div>

              {/* {hooks.DOMOption({ optionKey: 'doctor', obj, onChangeValue })} */}
              <div className="form-control">
                <label>의사</label>
                <select
                  value={doctors.findIndex(
                    (existing) => existing.id === (obj['doctor'] || {}).id
                  )}
                  onChange={(e) => {
                    // 고객의 담당의사가 있는 경우
                    if (customer && customer.doctor) {
                      if (
                        doctors[e.target.value] &&
                        customer.doctor.id === doctors[e.target.value].id
                      ) {
                        setDoctorFlag(false);
                      } else {
                        setDoctorFlag(true);
                      }
                    }
                    // 고객의 담당의사가 없는 경우
                    else {
                      if (e.target.value === 'null') {
                        setDoctorFlag(false);
                      } else {
                        setDoctorFlag(true);
                      }
                    }
                    obj['doctor'] = doctors[e.target.value];
                    setObj({ ...obj });
                  }}
                >
                  <option value={-1} hidden disabled>
                    의사를 선택하세요.
                  </option>
                  <option value="null">-</option>
                  {(doctors || []).map((o, idx) => (
                    <option key={idx} value={idx}>
                      {' '}
                      {o.name}{' '}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            {doctorFlag ? (
              <div className="form-wide m-b-20">
                <span className="m-r-20">
                  {doctorFlag ? ' [의사] ' : null}
                  {'를 해당 고객의 담당으로 지정하시겠습니까?'}
                </span>
                <span className="m-r-20">
                  <RadioInput
                    name="saveCustomer"
                    checked={radioValue}
                    onChange={() => setRadioValue(true)}
                  />{' '}
                  예
                </span>
                <span className="m-r-20">
                  <RadioInput
                    name="saveCustomer"
                    checked={!radioValue}
                    onChange={() => setRadioValue(false)}
                  />{' '}
                  아니오
                </span>
              </div>
            ) : null}

            <div className="form-wide m-b-20">
              <>
                <div className="form-wide m-b-20">
                  {addCategoriesObj.map((list, idx) => {
                    return (treatmentItemCategories || []).length > 0 ? (
                      <div className="form-double-side" key={idx}>
                        <div className="form-control">
                          {idx === 0 && <label>시/수술 카테고리</label>}
                          <Select
                            className="form-react-select"
                            styles={selectCustomStyles}
                            placeholder="시/수술 카테고리를 선택하세요."
                            value={
                              (addCategoriesObj[idx] || {}).category
                                ? {
                                    value: (
                                      treatmentItemCategories || []
                                    ).findIndex(
                                      (category) =>
                                        category.id ===
                                        (
                                          (addCategoriesObj[idx] || {})
                                            .category || {}
                                        ).id
                                    ),
                                    label: (
                                      (addCategoriesObj[idx] || {}).category ||
                                      {}
                                    ).name,
                                  }
                                : null
                            }
                            onChange={(e) => {
                              if (e.value !== -1) {
                                const items = (
                                  treatmentItemCategories[e.value] || {}
                                ).items;
                                addCategoriesObj[idx] = (items || [])[0];
                                setAddCategoriesObj([...addCategoriesObj]);
                              } else {
                                addCategoriesObj[idx] = [];
                                setAddCategoriesObj([...addCategoriesObj]);
                              }
                            }}
                            options={[{ value: null, label: '없음' }].concat(
                              (treatmentItemCategories || [])
                                .map((category, idx) => ({
                                  value: idx,
                                  label: category.name,
                                  isDisabled: !category.visible,
                                }))
                                .filter((v) => !v.isDisabled)
                            )}
                          />
                        </div>
                        <div className="form-control">
                          {idx === 0 && <label>시/수술명</label>}
                          {(treatmentItemCategories || []).length > 0 ? (
                            <Select
                              className="form-react-select"
                              styles={selectCustomStyles}
                              placeholder="시/수술명을 선택하세요."
                              value={
                                (addCategoriesObj[idx] || {}).category
                                  ? {
                                      value: (
                                        (
                                          (
                                            treatmentItemCategories || []
                                          ).find((category) =>
                                            category.items.some(
                                              (item) =>
                                                item.id ===
                                                (addCategoriesObj[idx] || {}).id
                                            )
                                          ) || {}
                                        ).items || []
                                      ).findIndex(
                                        (item) =>
                                          item.id ===
                                          (addCategoriesObj[idx] || {}).id
                                      ),
                                      label: (addCategoriesObj[idx] || {}).name,
                                    }
                                  : null
                              }
                              onChange={(e) => {
                                if (e.value === null) {
                                  addCategoriesObj[idx] = {};
                                  setAddCategoriesObj([...addCategoriesObj]);
                                  return false;
                                }
                                addCategoriesObj[idx] = (
                                  treatmentItemCategories || []
                                ).find((category) =>
                                  category.items.some(
                                    (item) =>
                                      item.id ===
                                      (addCategoriesObj[idx] || {}).id
                                  )
                                ).items[e.value];
                                setAddCategoriesObj([...addCategoriesObj]);
                              }}
                              options={[{ value: null, label: '없음' }].concat(
                                (
                                  (
                                    (treatmentItemCategories || []).find(
                                      (category) =>
                                        category.id ===
                                        (
                                          (addCategoriesObj[idx] || {})
                                            .category || {}
                                        ).id
                                    ) || {}
                                  ).items || []
                                )
                                  .map((item, idx) => ({
                                    value: idx,
                                    label: item.name,
                                    isDisabled: !item.visible,
                                  }))
                                  .filter((v) => !v.isDisabled)
                              )}
                            />
                          ) : null}
                        </div>

                        <button
                          className="btn btn-danger _small"
                          onClick={() => {
                            modal
                              .basic({
                                body:
                                  '삭제하시겠습니까? 최종 저장 전에는 반영되지 않습니다.',
                                buttons: [
                                  { text: 'CANCEL', class: 'btn-default' },
                                  { text: 'CONFIRM', class: 'btn-danger' },
                                ],
                              })
                              .then((selectedBtnIdx) => {
                                if (selectedBtnIdx === 1) {
                                  addCategoriesObj.splice(idx, 1);
                                  setAddCategoriesObj([...addCategoriesObj]);
                                }
                              });
                          }}
                        >
                          {translate('DELETE')}
                        </button>
                      </div>
                    ) : null;
                  })}
                </div>
                <div className="btnwrap m-t-8">
                  <button
                    className="btn btn-basic middle _small _full"
                    onClick={() => addTreatmentItem()}
                  >
                    입력란 추가
                  </button>
                  {auth.me.authorityGroup.paymentCode !== 'disabled' && (
                    <button
                      className="btn btn-primary _small m-l-8"
                      onClick={() => onClickOpenPaymentCodePopup()}
                    >
                      시/수술코드 추가
                    </button>
                  )}
                </div>
              </>
            </div>

            <div className="form-wide">
              <div className="form-control">
                <label>진료내용</label>
                {memoBoilerplateList.length > 0 && (
                  <div className="wrap-btn-boilerplate-memo">
                    {memoBoilerplateList.slice(0, 5).map((v, i) => (
                      <button
                        className="btn btn-sm btn-white _ssmall"
                        key={i}
                        onClick={() =>
                          onChangeValue(
                            'memo',
                            (obj.memo == '<p><br></p>' ? '' : obj.memo || '') +
                              v.contents
                          )
                        }
                      >
                        {v.title.slice(0, 5) + (v.title.length > 5 ? '…' : '')}
                      </button>
                    ))}
                  </div>
                )}
                {obj && (
                  <QuillTextField
                    tabName={tabName}
                    id={obj.id}
                    value={obj.memo || ''}
                    setValue={(v) => onChangeValue('memo', v)}
                    setMemoBoilerplateList={setMemoBoilerplateList}
                    placeholder="진료내용을 입력하세요."
                  />
                )}
              </div>
            </div>

            {!obj.id ? (
              <>
                <div className="form-control m-t-20">
                  <label>다음 진행</label>
                  <div className="flex-row wrap-next-step-btns">
                    <button
                      className={`btn flex-wrap _small m-r-8 ${
                        nextProcess === 'surgery_waiting'
                          ? 'btn-basic'
                          : 'btn-normal'
                      }`}
                      onClick={processChangeToSurgeryWaiting}
                    >
                      시/수술대기
                    </button>
                    <button
                      className={`btn flex-wrap _small m-r-8 ${
                        nextProcess === 'consulting_waiting'
                          ? 'btn-basic'
                          : 'btn-normal'
                      }`}
                      onClick={processChangeToConsultingWaiting}
                    >
                      추가상담대기
                    </button>
                    <button
                      className={`btn flex-wrap _small m-r-8 ${
                        nextProcess === 'payment_waiting'
                          ? 'btn-basic'
                          : 'btn-normal'
                      }`}
                      onClick={() => {
                        setToggleNextProcessBtn('payment_waiting');
                      }}
                    >
                      수납대기
                    </button>
                    <button
                      className={`btn flex-wrap _small m-r-8 ${
                        nextProcess === 'payment_during'
                          ? 'btn-basic'
                          : 'btn-normal'
                      }`}
                      onClick={() => {
                        setToggleNextProcessBtn('payment_during');
                      }}
                    >
                      수납하기
                    </button>
                    <button
                      className={`btn flex-wrap _small m-r-8 ${
                        nextProcess === 'leave' ? 'btn-basic' : 'btn-normal'
                      }`}
                      onClick={() => {
                        setToggleNextProcessBtn('leave');
                      }}
                    >
                      퇴원
                    </button>
                    <button
                      className={`btn flex-wrap _small m-r-8 ${
                        nextProcess === 'treatment_done'
                          ? 'btn-basic'
                          : 'btn-normal'
                      }`}
                      onClick={() => {
                        setToggleNextProcessBtn('treatment_done');
                      }}
                    >
                      완료
                    </button>
                    {/* <button className={`btn flex-wrap _small m-r-8 ${nextProcess === 'need_recall' ? 'btn-basic' : 'btn-normal'}`}
                    onClick={processChangeToNeedRecall}>리콜필요</button> */}
                  </div>
                </div>
                <SelectNextProcess
                  nextProcess={nextProcess}
                  counselors={counselors}
                  treatmentSelectCounselors={treatmentSelectCounselors}
                  setTreatmentSelectCounselors={setTreatmentSelectCounselors}
                  doctors={doctors}
                  treatmentSelectDoctors={treatmentSelectDoctors}
                  setTreatmentSelectDoctors={setTreatmentSelectDoctors}
                  facialist={facialist}
                  treatmentSelectFacialists={treatmentSelectFacialists}
                  setTreatmentSelectFacialists={setTreatmentSelectFacialists}
                />

                {/* <NextProcessSelector /> */}
              </>
            ) : null}

            <div className="flex-row wrap-bottom-btns">
              <button
                className="btn btn-cancel _small m-r-8"
                onClick={actionInit}
              >
                {'입력취소'}
              </button>
              <button
                className="btn btn-basic _small"
                disabled={`${loadingBtnDisabledFlag ? 'disabled' : ''}`}
                onClick={beforeSave}
              >
                진료완료
              </button>
            </div>
          </div>
        ) : null}
      </div>
      <div className="wrap-data-table data-table">
        <div className="title">{translate('LIST_TREATMENT')}</div>
        <div className="card">
          <DataTableAlpha
            model={modelTreatmentTab}
            data={treatments}
            total={total}
            params={params}
            onParams={onParams}
            onAction={onAction}
            bottomPositionInner
            hasLine
          />
        </div>
      </div>
    </div>
  );
};

CustomerTreatments.propTypes = {
  customer: PropTypes.object,
  openFlag: PropTypes.object,
  closeFlag: PropTypes.bool,
};

export default observer(CustomerTreatments);
