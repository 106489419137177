import React, { useState, useEffect, useCallback } from 'react';
import { observer } from 'mobx-react';
import List from './List';
import hooks from 'hooks';
import { $case, pluralize } from 'filters';
import ModalMessageSendToPage from 'components/modals/ModalMessageSendToPage';
import modelSkins from 'models/skin';
import { useModal } from 'hooks/useModal';
import { useServices } from 'hooks/useServices';
import { useToast } from 'hooks/useToast';

const Skins = () => {
  const modal = useModal();
  const services = useServices();
  const toast = useToast();

  const [totalSearchList, setTotalSearchList] = useState({});
  const [renderFlag, setRenderFlag] = useState(true);
  const [checkItems, setCheckItems] = useState([]);

  const callApi = useCallback(async () => {
    let totalResp = {};
    let facialistParams = {
      duty: '피부관리사',
      userStatus: 'active',
      limit: 300,
    };
    const facialistResp = await services.crm.user.duty(facialistParams);
    if (!facialistResp) return;
    totalResp.facialists = facialistResp.data;

    let doctorsParams = { duty: '의사', userStatus: 'active', limit: 300 };
    const doctorsResp = await services.crm.user.duty(doctorsParams);
    if (!doctorsResp) return;
    totalResp._doctors = doctorsResp.data;

    let counselorsParams = { duty: '상담사', userStatus: 'active', limit: 300 };
    const counselorsResp = await services.crm.user.duty(counselorsParams);
    if (!counselorsResp) return;
    totalResp._counselors = counselorsResp.data;

    let treatmentParams = { limit: 10000, visible: true };
    const treatmentResp = await services.crm.treatment.categories.items_categories_v2(
      treatmentParams
    );
    if (!treatmentResp) return;
    totalResp.surgery_category = treatmentResp.data;

    const treatmentItemResp = await services.crm.treatment.items.all_v2(
      treatmentParams
    );
    if (!treatmentItemResp) return;
    totalResp.surgery_item_name = treatmentItemResp.data;

    setTotalSearchList(totalResp);
    setRenderFlag((renderFlag) => !renderFlag);
  }, []);

  useEffect(() => {
    callApi();
  }, [callApi]);

  const onDeleteSkin = useCallback(async (id) => {
    try {
      await services.crm.nurse['skins'].delete_v2(id);
      modal
        .confirm({
          type: 'SUCCESS',
          msg: '삭제되었습니다.',
        })
        .then(() => {
          setRenderFlag((renderFlag) => !renderFlag);
        });
    } catch (e) {
      modal.confirm({
        type: 'ERROR',
        msg: e.description,
      });
    }
  }, []);

  const onClickDeleteSkin = (skin) => {
    modal
      .basic({
        body: '삭제하시겠습니까?',
        buttons: [
          {
            text: 'CANCEL',
            class: 'btn-default',
          },
          {
            text: 'CONFIRM',
            class: 'btn-primary',
          },
        ],
      })
      .then((idx) => {
        if (idx === 1) {
          onDeleteSkin(skin.id);
        }
      });
  };

  const onClickSendSms = () => {
    if (checkItems.length) {
      modal.custom({
        component: ModalMessageSendToPage,
        options: {
          page: 'skin',
          data: checkItems,
        },
      });
    } else {
      toast.error('문자전송할 피부관리를 선택하세요.');
    }

    return;
  };

  const onAllCheckAction = (all, allChecked) => {
    if (!allChecked) {
      //전체 선택인 경우 allChecked:false
      //merged인 경우 걸러내기 .filter( v=> v['@@merged'] === false)
      const list = (all || [])
        .filter((v) => v['@@merged'] === false)
        .filter((v) => {
          return (
            v.$$checked === true &&
            checkItems.find((f) => f.id === v.id) === undefined
          );
        });
      setCheckItems([...checkItems, ...list]);
    } else {
      //전체 해제인 경우
      // eslint-disable-next-line array-callback-return
      const list = (checkItems || []).filter((v) => {
        if (all.find((f) => f.id === v.id) === undefined) {
          return v;
        }
      });
      setCheckItems([...list]);
    }
  };

  const onAction = (obj) => {
    let skinManageChart = obj.row;
    let model = obj.key;

    if (model.type === 'multiCheck') {
      let data = [...checkItems];
      if (skinManageChart.$$checked) {
        if (
          (data || []).find((v) => v.id === skinManageChart.id) === undefined
        ) {
          data.push(skinManageChart);
        }
        setCheckItems([...data]);
      } else {
        let list = data.filter((v) => {
          return v.id !== skinManageChart.id;
        });

        setCheckItems([...list]);
      }
      return;
    }

    if (['고객명', 'CUSTOMER'].indexOf(model.title) !== -1) {
      hooks.openCustomerChart({
        customer: skinManageChart.customer,
        skinManageChart,
      });
    }

    if (['STATUS'].indexOf(model.title) !== -1) {
      onClickDeleteSkin(skinManageChart);
    }
  };

  const settingModels = useCallback(() => {
    const dataCallList = [
      'facialists',
      'surgery_category',
      'surgery_item_name',
      '_doctors',
      '_counselors',
    ];
    const model = modelSkins;
    let found;
    dataCallList.forEach((v) => {
      found = (model.keys || []).find(
        (o) => o.title === $case.toConst(pluralize(v))
      );
      if (!found) return;
      found.selectOptions = (totalSearchList[v] || []).map((o) => {
        o.$$label = o['name'];
        o.$$value = o.id;
        return o;
      });
    });
  }, [totalSearchList]);

  useEffect(() => {
    settingModels();
  }, [settingModels]);

  return (
    <List
      renderFlag={renderFlag}
      api={services.crm.nurse['skins'].list_v2}
      model={modelSkins}
      modelName={'skins'}
      orderBy={'scheduledAt desc'}
      onAction={onAction}
      mergeColumn={[
        {
          categoryName: null,
          name: null,
          treatmentCount: null,
          useCount: null,
          remainingCount: null,
        },
      ]}
      onAllCheckAction={onAllCheckAction}
      onClickSendSms={onClickSendSms}
      checkItems={checkItems}
    />
  );
};

export default observer(Skins);
