import React, { useEffect, useCallback } from 'react';
import { observer } from 'mobx-react';
import { format } from 'date-fns';
import { ko } from 'date-fns/locale';
import { useHoliday } from 'store/holiday';
import ReactDatePicker from 'react-datepicker';
import PropTypes from 'prop-types';

const DatePicker = ({
  className,
  placeholderText,
  dateFormat = 'yyyy년 MM월 d일',
  value,
  todayButton,
  onChange,
  ...props
}) => {
  const holiday = useHoliday();

  const datePickerKoLocale = {
    ...ko,
    options: {
      weekStartsOn: 0,
      /* Monday */
      firstWeekContainsDate: 1,
    },
  };

  const getHoliday = async (date) => {
    if (String(new Date(date).getFullYear()).length < 5) {
      await holiday.setHolidayData(date ?? new Date());
    }
  };

  useEffect(() => {
    getHoliday(value);
  }, [value]);

  const getDayName = (date) => {
    return date.toLocaleDateString('ko-KR', { weekday: 'long' }).substr(0, 1);
  };

  const getDayClassName = useCallback(
    (date) => {
      return holiday.data.some(
        (v) => v.locdate === format(date, 'yyyy-MM-dd', { locale: ko })
      )
        ? 'holiday'
        : getDayName(date) === '토'
        ? 'saturday'
        : getDayName(date) === '일'
        ? 'sunday'
        : undefined;
    },
    [holiday.data]
  );

  const onChangeDate = (v, e) => {
    let date = v;
    const year = String(new Date(v).getFullYear());
    if (String(new Date(v).getFullYear()).length > 4) {
      date = new Date(new Date(v).setFullYear(year.substring(0, 4)));
    }
    onChange(date, e);
  };

  return (
    <ReactDatePicker
      locale={datePickerKoLocale}
      showMonthDropdown
      showYearDropdown
      dropdownMode="select"
      className={className}
      placeholderText={placeholderText}
      dateFormat={dateFormat}
      selected={value}
      todayButton={todayButton}
      onChange={onChangeDate}
      onYearChange={getHoliday}
      onMonthChange={getHoliday}
      dayClassName={getDayClassName}
      {...props}
    />
  );
};

export default observer(DatePicker);

DatePicker.propTypes = {
  className: PropTypes.string,
  placeholderText: PropTypes.string,
  dateFormat: PropTypes.string,
  value: PropTypes.object,
  todayButton: PropTypes.string,
  onChange: PropTypes.func,
};
