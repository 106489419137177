import { currency } from 'filters';

export default {
  keys: [
    {
      title: 'NAME',
      column: 'product__name',
    },
    {
      title: 'QUANTITY',
      column: 'qty',
    },
    {
      title: 'PRICE',
      column: 'product__price',
      filter: (o) => `₩${currency(o.product.price)}`,
    },
    {
      title: 'VAT_INCLUDED',
      column: 'product__vatIncluded',
      filter: (o) => (o.product.vatIncluded ? '포함' : '미포함'),
    },
    {
      title: 'REIMBURSEMENT',
      column: 'product__reimbursement',
      filter: (o) => (o.product.reimbursement ? '급여' : '비급여'),
    },
  ],
};
