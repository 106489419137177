import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import useCtiStore from './hooks/useCtiStore';
import './ctiHistoryItems.scss';
import moment from 'moment';
import { getDayName } from './dateUtil';
import CtiHistoryItem from './CtiHistoryItem';
import { useAuth } from 'store/auth';

const HistoryItems = ({ callingItems, visibleItems }) => {
  if (callingItems.length === 0 && visibleItems.length === 0) {
    return <div className="cti-history-empty">최근 내역이 없습니다.</div>;
  }

  return (
    <>
      {callingItems.map((c) => (
        <CtiHistoryItem key={c.id} history={c} />
      ))}

      {callingItems.length > 0 && <div className="cti-history-divider"></div>}
      <div className="list-body">
        {visibleItems.map((history) => (
          <CtiHistoryItem key={history.id} history={history} />
        ))}
      </div>
    </>
  );
};

HistoryItems.propTypes = {
  callingItems: PropTypes.array,
  visibleItems: PropTypes.array,
};

const CtiHistoryItems = observer(function CtiHistoryItems() {
  const auth = useAuth();
  const ctiStore = useCtiStore();

  const today = new Date();

  function dateLabelDesc(dateStr) {
    const date = new Date(dateStr);
    return `${date.getMonth() + 1}.${date.getDate()}.${getDayName(date)}`;
  }

  function dateLabel(date) {
    var start = moment(date);
    var now = moment();

    return `${getDateDiffName(now.diff(start, 'days'))}`;
  }

  function getDateDiffName(diff) {
    switch (diff) {
      case 0:
        return '오늘';
      case 1:
        return '어제';
      default:
        return `${diff}일 전`;
    }
  }

  const visibleItems = ctiStore.visibleItems.filter(
    (h) => !(h.isCalling && h.callerId === auth.me.id)
  );

  return (
    <div className="cti-history-items">
      <div className="list-header">
        <div className="cti-date-label">
          <div className="cti-date-name">{dateLabel(today)}</div>
          <div className="cti-date-desc">{dateLabelDesc(today)}</div>
        </div>
        <div className="hour-option-box">
          <input
            className="hour-input"
            type="number"
            value={ctiStore.historyMaxHour.toString()}
            onChange={(e) => {
              ctiStore.setHistoryMaxHour(Number(e.target.value));
            }}
          />
          <label className="input-label">시간</label>
        </div>
      </div>
      <HistoryItems
        callingItems={ctiStore.callingItems}
        visibleItems={visibleItems}
      />
    </div>
  );
});

export default CtiHistoryItems;
