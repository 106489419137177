import { currency, currencySymbol } from 'filters';

export default {
  keys: [
    {
      title: '의사명',
      key: 'doctorName',
      column: 'doctorName',
      columnGroup: false,
    },
    {
      title: '시수술건수',
      key: 'surgeryCount',
      column: 'surgeryCount',
      columnGroup: false,
      // TODO: 추후 정렬 추가 작업시 orderKey, order 주석 해제
      // orderKey: 'surgeryCount',
      // order: 'desc',
    },
    {
      title: '시수술인원수',
      key: 'surgeryPeopleCount',
      column: 'surgeryPeopleCount',
      columnGroup: false,
      // TODO: 추후 정렬 추가 작업시 orderKey, order 주석 해제
      // orderKey: 'surgeryPeopleCount',
      // order: 'desc',
    },
    {
      title: '시수술 매출액',
      key: 'salesFigures',
      column: 'salesFigures',
      columnGroup: false,
      // TODO: 추후 정렬 추가 작업시 orderKey, order 주석 해제
      // orderKey: 'salesFigures',
      // order: 'desc',
      filter: (o) => {
        return `${currencySymbol()} ${currency((o || {}).salesFigures || 0)}`;
      },
    },
  ],
};
