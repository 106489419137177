import { observable, action, flow, makeObservable } from 'mobx';
import services from 'services';

class Messages {
  constructor() {
    makeObservable(this, {
      allSurgeryCategories: observable,
      selectSurgeryCategories: observable,
      allSurgeryItems: observable,
      selectSurgeryItems: observable,

      selectScheduleDaysDelta: observable,
      selectReserveCount: observable,
      selectScheduleDaysCycle: observable,

      setAllSurgeryCategories: action,
      setSelectSurgeryCategories: action,
      setAllSurgeryItems: action,
      setSelectSurgeryItems: action,

      setSelectScheduleDaysDelta: action,
      setSelectReserveCount: action,
      setSelectScheduleDaysCycle: action,

      departments: observable,
      setDepartments: action,

      smsAutoNotificationsCallApi: action,
      filteredSmsAutoNotifications: action,
      loadDepartmentSmsAutoNotifications: action,
    });
  }

  //api 조회한 시/수술 카테고리 리스트 (전체내역)
  allSurgeryCategories = [];
  //선택한 시/수술 카테고리 리스트 (선택)
  selectSurgeryCategories = [];

  //선택한 시/수술카테고리의 모든 시/수술명 리스트 (전체내역)
  allSurgeryItems = [];
  //선택한 시/수술명 리스트 (선택)
  selectSurgeryItems = [];

  //선택한 item의 디폴트 설정주기
  selectScheduleDaysDelta = 0;
  //선택한 item의 디폴트 설정주기(일 day, 주week, 개월month)
  selectScheduleDaysCycle = 'day';
  //선택한 item의 디폴트 발송횟수
  selectReserveCount = 1;

  //전송부서 리스트
  departments = [];

  setAllSurgeryCategories = (categories) => {
    this.allSurgeryCategories = categories;
  };

  setSelectSurgeryCategories = (categories) => {
    this.selectSurgeryCategories = categories;
  };

  setAllSurgeryItems = (items) => {
    this.allSurgeryItems = items;
  };

  setSelectSurgeryItems = (items) => {
    this.selectSurgeryItems = items;
  };

  setSelectScheduleDaysDelta = (num) => {
    this.selectScheduleDaysDelta = num;
  };

  setDepartments = (list) => {
    this.departments = list;
  };

  smsAutoNotificationsCallApi = flow(function* () {
    return yield services.crm.notification.smsAutoNotifications.all({
      limit: 1000,
      smsSituation: 'reserved',
      visible: true,
    });
  });

  filteredSmsAutoNotifications = (resp, departmentId) => {
    //자동문자가 '예약완료'로 설정된 모든 문자 리스트
    let allNotificationsItems = resp.filter(
      (v) => v.visible === true && v.smsSituation === 'reserved'
    );

    //자동문자 부서 선택이 전체인 자동 문자 리스트
    let departmentIdisNullNotificationsItems = allNotificationsItems.filter(
      (v) => {
        return v.departmentId === null;
      }
    );

    //자동문자 부서 선택이 (전체가 아닌) 부서로 걸려있는 자동 문자 리스트
    let departmentIdisNotNullNotificationsItems = allNotificationsItems.filter(
      (v) => {
        return departmentId === v.departmentId;
      }
    );

    let notifications = [];
    if (
      departmentIdisNotNullNotificationsItems.every(
        (item) => item.departmentExtendAll === true
      )
    ) {
      //문자코드 부서설정에서 전체문자도포함된 경우
      notifications = [
        ...departmentIdisNotNullNotificationsItems,
        ...departmentIdisNullNotificationsItems,
      ];
    } else {
      notifications = [...departmentIdisNotNullNotificationsItems];
    }

    return notifications.map((v) => {
      return {
        ...v,
        $$checked: true,
      };
    });
  };

  loadDepartmentSmsAutoNotifications = flow(function* (departmentId) {
    const resp = yield this.smsAutoNotificationsCallApi();

    return this.filteredSmsAutoNotifications(resp.data, departmentId);
  });

  setSelectReserveCount = (num) => {
    this.selectReserveCount = num;
  };

  setSelectScheduleDaysCycle = (val) => {
    this.selectScheduleDaysCycle = val;
  };
}

export default new Messages();
