import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import hooks from 'hooks';
import * as $http from 'axios';
import { translate } from 'filters';
import { useModal } from 'hooks/useModal';
import { useToast } from 'hooks/useToast';
import { useAuth } from 'store/auth';
import TimeSetter from 'components/common/TimeSetter';

const AppointmentCodes = () => {
  const auth = useAuth();
  const modal = useModal();
  const toast = useToast();
  const [obj, setObj] = useState(null);
  const [lunchState, setLunchState] = useState(null);

  const durationSetter = (task) => (
    <div className="form-control">
      <label>
        {translate(`${task.toUpperCase()}_TIME`)}
        {task === 'lunch' && (
          <button
            className={`header-toggle-btn ${lunchState ? '' : 'selected'}`}
            onClick={onToggle}
          >
            <label className="header-toggle-btn-label">
              {lunchState ? 'on' : 'off'}
            </label>
          </button>
        )}
      </label>
      <div className="flex-row items-center">
        <div className="flex-wrap">
          <TimeSetter
            fieldName={`${task}Start`}
            obj={obj}
            setObj={setObj}
            unit={obj.appointmentTimeUnit}
            lunchState={lunchState}
          />
        </div>
        <div className="flex-wrap">~</div>
        <div className="flex-wrap">
          <TimeSetter
            fieldName={`${task}End`}
            obj={obj}
            setObj={setObj}
            unit={obj.appointmentTimeUnit}
            lunchState={lunchState}
          />
        </div>
      </div>
    </div>
  );

  const onToggle = () => {
    lunchState === null ? setLunchState(true) : setLunchState(!lunchState);
    setObj({ ...obj, lunchEnabled: !lunchState });
  };

  const onClickSave = () => {
    const onConfirm = async () => {
      try {
        await $http.put('appointments/time', {
          workStart: obj.workStart,
          workEnd: obj.workEnd,
          lunchStart: obj.lunchStart,
          lunchEnd: obj.lunchEnd,
          appointmentTimeUnit: obj.appointmentTimeUnit,
          lunchEnabled: lunchState,
        });
        modal
          .confirm({
            type: 'SUCCESS',
            msg: '업데이트되었습니다.',
          })
          .then(() => {
            auth.loadMe();
          });
      } catch (e) {
        console.log(e.description);
        toast.error(e.description);
      }
    };

    modal
      .basic({
        body: translate('SAVE_APPOINTMENT_TIME_CONFIRM'),
        buttons: [
          {
            text: 'CANCEL',
            class: 'btn-default',
          },
          {
            text: 'CONFIRM',
            class: 'btn-primary',
          },
        ],
      })
      .then((idx) => {
        if (idx === 1) onConfirm();
      });
  };

  useEffect(() => {
    if (!auth.me) return;

    setObj(toJS(auth.me.clinic));
    if (auth.me.clinic.lunchEnabled === null) {
      setLunchState(false);
    } else {
      setLunchState(auth.me.clinic.lunchEnabled);
    }
  }, [auth.me]);

  return (
    <>
      {obj && (
        <div className="appointment-time">
          <div className="route-top">
            <div className="title">{translate('APPOINTMENT_TIME')}</div>
            <div className="flex-row">
              <button
                className="btn btn-warning"
                onClick={() => {
                  setObj(toJS(auth.me.clinic));
                  if (auth.me.clinic.lunchEnabled === null) {
                    setLunchState(false);
                  } else {
                    setLunchState(auth.me.clinic.lunchEnabled);
                  }
                }}
              >
                {translate('INIT')}
              </button>
              <button
                disabled={[
                  'workStart',
                  'workEnd',
                  'lunchStart',
                  'lunchEnd',
                  'appointmentTimeUnit',
                  'lunchEnabled',
                ].every((key) => obj[key] === auth.me.clinic[key])}
                className="btn btn-primary m-l-8"
                onClick={() => onClickSave()}
              >
                {translate('SAVE')}
              </button>
            </div>
          </div>
          <div className="data-table">
            {durationSetter('work')}
            <div className="form-control">
              <label>{translate('APPOINTMENT_TIME_UNIT')}</label>
              {hooks.selector({
                options: [10, 30, 60],
                unit: '분',
                value: obj.appointmentTimeUnit,
                onChange: (e) => {
                  obj.appointmentTimeUnit = parseInt(e.target.value);
                  setObj({ ...obj });
                },
              })}
            </div>
            {durationSetter('lunch')}
          </div>
        </div>
      )}
    </>
  );
};

AppointmentCodes.propTypes = {};

export default observer(AppointmentCodes);
