import React, { useCallback, useState } from 'react';
import { observer } from 'mobx-react';
import { components } from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort } from '@fortawesome/free-solid-svg-icons';
import SelectBox from 'components/common/SelectBox';
import PropTypes from 'prop-types';
import { translate } from 'filters';
import { useMessages } from 'hooks/useMessages';

const SelectAutoNotificationCommon = ({
  name,
  value,
  onChange,
  options,
  placeholder = ' ',
  disabled,
}) => {
  const messages = useMessages();
  // [초진예약완료], [재진예약완료], [생일]  삭제됨.

  //situations = 고객완료, 예약완료, 예약취소, 미방문, 퇴원
  const situations = ['register', 'reserved', 'cancel', 'unvisited', 'leave'];

  const [autoNotificationOptions] = useState({
    // scheduleDaysDelta : 1일~7일
    scheduleDaysDelta: [...Array(8).keys()].slice(1).map((num) => {
      if (num === 1) return { value: num, label: `${num}${translate('DAY')}` };
      return { value: num, label: `${num}${translate('DAYS')}` };
    }),
    //
    scheduleDaysBefore: [
      { value: 'true', label: translate('BEFORE') },
      { value: 'false', label: translate('AFTER') },
    ],
    scheduleDaysAfter: [{ value: 'false', label: translate('AFTER') }],
    // scheduleTimeHour : 08~21시
    scheduleTimeHour: [...Array(22).keys()].slice(8).map((num) => {
      const parsedValue = String(num).padStart(2, '0');
      return { value: parsedValue, label: parsedValue };
    }),
    // scheduleTimeMinute : 00, 15, 30, 45
    scheduleTimeMinute: [...Array(4).keys()].map((num) => {
      const parsedValue = String(num * 15).padStart(2, '0');
      return { value: parsedValue, label: parsedValue };
    }),
    smsSituation: situations.map((s) => ({
      value: s,
      label: translate(`SITUATION_${s.toUpperCase()}`),
    })),
    departmentId: messages.departments.map((v) => {
      return {
        value: v.id,
        label: v.name,
        departmentExtendAll: v.departmentExtendAll,
      };
    }),
  });

  const DropdownIndicator = useCallback(
    ({ ...props }) => (
      <components.DropdownIndicator {...props} style={{ textAlign: 'right' }}>
        <FontAwesomeIcon icon={faSort} />
      </components.DropdownIndicator>
    ),
    []
  );

  return (
    <SelectBox
      name={name}
      value={value}
      onChange={onChange}
      disabled={disabled}
      menuPlacement={'top'}
      options={options ? options : autoNotificationOptions[name]}
      placeholder={placeholder}
      components={{
        DropdownIndicator,
        IndicatorSeparator: () => null,
        ...(name !== 'smsSituation' && {
          ClearIndicator: () => null,
        }),
      }}
    />
  );
};

SelectAutoNotificationCommon.propTypes = {
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  options: PropTypes.object,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
};

export default React.memo(observer(SelectAutoNotificationCommon));
