import { formatCallerNumber, translate } from 'filters';

export default {
  keys: [
    {
      title: '',
      filter: (o) => {
        return `<div >
              ${
                o['visible']
                  ? `<button class="btn btn-sm btn-black"  data-type="visible" >${translate(
                      'TOGGLE_DEACTIVATE'
                    )}</button>`
                  : ''
              }
              ${
                !o['visible']
                  ? `<button class="btn btn-sm btn-gray" data-type="visible" >${translate(
                      'TOGGLE_ACTIVATE'
                    )}</button>`
                  : ''
              }
           </div>`;
      },
      class: 'action',
    },
    {
      title: 'CALLER_NUMBER',
      column: 'number',
      filter: (o) => {
        return formatCallerNumber(o['number']);
      },
    },
    {
      title: 'MEMO',
      column: 'comment',
      filter: (o) => {
        return o['comment'] ? o['comment'] : '-';
      },
    },
  ],
};
