import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import modelPrescriptionBundleList from 'models/prescription-bundle-list';
import DataTableAlpha from 'components/data-table/DataTableAlpha';
import PropTypes from 'prop-types';
import services from 'services';
import { $qb } from 'scripts/querybuilder';
import { useModal } from 'hooks/useModal';

const PresciptionBundleList = ({
  setUpdateBundle,
  updateBundle,
  setBundleId,
}) => {
  const modal = useModal();
  const defaultParams = $qb().limit(20);
  const [params, setParams] = useState(defaultParams);
  const [bundleList, setBundleList] = useState([]);
  const [total, setTotal] = useState(null);

  useEffect(() => {
    updateBundle && getPrescriptionBundleList();
  }, [updateBundle]);

  useEffect(() => {
    getPrescriptionBundleList();
  }, []);

  const onParams = (p) => {
    setParams(p);
    getPrescriptionBundleList();
  };

  const getPrescriptionBundleList = async () => {
    try {
      const resp = await services.crm.prescription.prescription_collection_list.all_v2(
        params.build()
      );
      setBundleList(resp.data.medicineCollectionList);
      setTotal(resp.meta.pagination.total);
    } catch (e) {
      console.log(e.description);
    }
    setUpdateBundle(false);
  };

  const deletePrescriptionBundel = async (id) => {
    let params = { collectionId: id };

    try {
      await services.crm.prescription.prescription_collection.delete(params);
      modal
        .confirm({
          type: 'SUCCESS',
          msg: '삭제하였습니다.',
        })
        .then(() => {
          getPrescriptionBundleList();
        });
    } catch (e) {
      modal.confirm({
        type: 'ERROR',
        msg: e.description,
      });
    }
  };

  const onActionDeleteBundle = (id) => {
    modal
      .basic({
        body: `정말로 삭제하시겠습니까?`,
        buttons: [
          {
            text: 'CANCEL',
            class: 'btn-default',
          },
          {
            text: 'CONFIRM',
            class: 'btn-primary',
          },
        ],
      })
      .then((selectedIdx) => {
        if (selectedIdx === 1) {
          deletePrescriptionBundel(id);
        }
      });
  };

  const onAction = (obj) => {
    let model = obj.key;
    let object = obj.row;
    let event = obj.event;
    let id = object.collectionId;

    if (['name'].indexOf(model.column) !== -1) {
      object[model.column] = event.target.value;
    }

    const btnLabel = event.target.innerText;
    if (btnLabel === '선택') {
      setBundleId(id);
    }

    if (btnLabel === '삭제') {
      onActionDeleteBundle(id);
    }
  };

  return (
    <div className="wrap-prescription-bundle-list">
      <div className="route-top">
        <span className="title">묶음 처방 리스트</span>
      </div>
      <DataTableAlpha
        model={modelPrescriptionBundleList}
        data={bundleList}
        total={total}
        params={params}
        onParams={onParams}
        onAction={onAction}
      />
    </div>
  );
};

PresciptionBundleList.propTypes = {
  setUpdateBundle: PropTypes.func,
  updateBundle: PropTypes.bool,
  setBundleId: PropTypes.func,
};

export default observer(PresciptionBundleList);
