import './quill.css';
import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { toHexColorHtml } from './quillUtil';
import PropTypes from 'prop-types';

export default function QuillTextField({
  innerRef,
  value,
  setValue,
  ...props
}) {
  function onChange(v) {
    setValue(toHexColorHtml(v));
  }
  return (
    <ReactQuill
      ref={innerRef}
      {...props}
      theme="snow"
      modules={{
        toolbar: [
          'bold',
          'italic',
          'underline',
          'strike',
          { color: [] },
          { background: [] },
        ],
      }}
      formats={['background', 'bold', 'color', 'italic', 'strike', 'underline']}
      defaultValue={value}
      onChange={onChange}
      preserveWhitespace
    />
  );
}

QuillTextField.propTypes = {
  innerRef: PropTypes.object,
  value: PropTypes.string,
  setValue: PropTypes.func,
};
