import React, { useState, useEffect, useCallback } from 'react';
import { observer } from 'mobx-react';
import HeaderRowSpanDataTable from 'components/data-table/HeaderRowSpanDataTable';
import hooks from 'hooks';
import PropTypes from 'prop-types';
import moment from 'moment';
import modelMonthlySettlementsDetail from 'models/monthly-settlements-detail';
import { $qb } from 'scripts/querybuilder';
import { useServices } from 'hooks/useServices';
import { useToast } from 'hooks/useToast';

const ModalMonthlySettlementsDetail = ({ options, close }) => {
  const services = useServices();
  const toast = useToast();
  const defaultParams = $qb()
    .limit(10)
    .orderBy('paidAt desc')
    .customParam('paidStartAt', moment(options.startAt).format('YYYY-MM-DD'))
    .customParam('paidEndAt', moment(options.endAt).format('YYYY-MM-DD'));
  const [params, setParams] = useState(defaultParams);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);

  const monthlyCallApi = useCallback(async () => {
    const resp = await services.crm.settlements.settlements.detail_v2(
      params.build()
    );
    if (!resp) return;

    // 전체 레코드 수 - ( (현재 페이지 번호 - 1) * 한 페이지당 보여지는 레코드 수 + 현재 게시물 출력 순서 )
    //total ( (page-1) * limit + i)
    let page = params.queryParams.page || 1;
    let limit = params.queryParams.limit;

    resp.data.forEach((v, i) => {
      v.no = resp.pagination.total - ((page - 1) * limit + i);
      v.id = i;
      v.sumAmount =
        v.creditCardAmount +
        v.debitCardAmount +
        v.cashAmount +
        v.bankTransferAmount;
    });

    setData(resp.data);
    setTotal(resp.pagination.total);
  }, [params]);

  useEffect(() => {
    monthlyCallApi();
  }, [monthlyCallApi]);

  const onParams = (p) => {
    setParams(p);
    monthlyCallApi();
  };

  const onAction = (obj) => {
    const { row, key } = obj; // , rowIdx, event
    if (key.title === '고객명') {
      openCustomerPaymentChart(row.customerId);
    }
  };

  const customerDetailCallApi = async (customerId) => {
    try {
      const resp = await services.crm.customer.detail(customerId);
      if (!resp) return;

      return resp.data;
    } catch (e) {
      console.log(e.description);
      if (e.dscription === 'CustomerNotFound') {
        toast.error('삭제된 고객입니다.');
      }
    }
  };

  const openCustomerPaymentChart = useCallback(async (customerId) => {
    const customer = await customerDetailCallApi(customerId);
    hooks.openCustomerChart({ customer, selectTab: 'PAYMENT' });
  }, []);

  const onClickHeaderRow = (row) => {
    let orderBy = `${row.column} ${row.order}`;
    params.orderBy(orderBy);
    monthlyCallApi();
  };

  return (
    <div className={`modal-monthly-settlements-detail`}>
      <div className="head flex-row flex-between items-start">
        <div className="title">수납 상세 내역</div>
        <i onClick={() => close()} className="zmdi zmdi-close" />
      </div>
      <div className="body settlements-container">
        <HeaderRowSpanDataTable
          rowKey="id"
          model={modelMonthlySettlementsDetail}
          data={data}
          total={total}
          bordered={true}
          params={params}
          onParams={onParams}
          onAction={onAction}
          onClickHeaderRow={onClickHeaderRow}
        />
      </div>
    </div>
  );
};

ModalMonthlySettlementsDetail.propTypes = {
  options: PropTypes.object,
  close: PropTypes.func,
};

export default observer(ModalMonthlySettlementsDetail);
