export default {
  keys: [
    {
      title: 'ACTION',
      noorder: true,
      filter: (o) => `
            ${
              o.visible && o.defaultCode !== 1
                ? `<button class="btn btn-sm btn-primary m-r-8">수정</button>`
                : ''
            }
            ${
              o.defaultCode !== 1
                ? `<button class="btn btn-sm m-r-8 ${
                    o.visible ? 'btn-black' : 'btn-gray'
                  }">${o.visible ? '미사용' : '사용'}</button>`
                : `<button class="btn btn-sm m-r-8 btn-gray" disabled>미사용</button>`
            }
            ${
              o.defaultCode !== 1
                ? `<button class="btn btn-sm m-r-8 btn-danger" data-type="delete" >삭제</button>`
                : `<button class="btn btn-sm m-r-8 btn-gray" disabled>삭제</button>`
            }`,
    },
    {
      title: '바이탈 항목',
      column: 'name',
      noorder: true,
      type: 'arrow',
    },
    {
      title: '단위',
      column: 'unit',
      noorder: true,
      type: 'editable',
    },
  ],
};
