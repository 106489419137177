import { currency } from 'filters';

export default {
  keys: [
    {
      title: '제품명',
      column: 'name',
    },
    {
      title: '개수',
      column: 'quantity',
      filter: (o) => {
        return o.quantity === undefined ? '-' : o.quantity;
      },
    },
    {
      title: 'NOT_VAT_INCLUDE',
      column: 'price',
      filter: (o) => {
        if (o.vatIncluded) {
          return `<span class="text-underline">₩${currency(o.price)}</span>`;
        } else {
          return `<span class="text-underline">₩${currency(
            o.priceVatInclude
          )}</span>`;
        }
      },
    },
    {
      title: 'PRICE_VAT_INCLUDE',
      column: 'priceVatInclude',
      filter: (o) =>
        `<span class="text-underline">₩${currency(
          o.priceVatInclude || 0
        )}</span>`,
    },
    {
      title: '할인',
      column: 'discountAmount',
      filter: (o) =>
        `<span class="text-underline">₩${currency(
          o.discountAmount || 0
        )}</span>`,
    },
    {
      title: '청구액',
      column: 'requestAmount',
      filter: (o) => {
        if (o.requestAmount === undefined) {
          return `₩${currency(o.priceVatInclude - o.discountAmount)}`;
        } else {
          return `₩${currency(o.requestAmount)}`;
        }
      },
    },
    {
      title: '',
      filter: () => {
        return `<div class="action-delete"><i class="zmdi zmdi-minus" style="font-size: 12px;" ></i></div>`;
      },
    },
  ],
};
