import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { translate } from 'filters';
import { $qb } from 'scripts/querybuilder';
import { useEvent } from 'hooks/useEvent';
import { useModal } from 'hooks/useModal';
import { useServices } from 'hooks/useServices';
import { useToast } from 'hooks/useToast';
import styled from 'styled-components';

const Input = styled.input`
  width: calc(100% - 84px);
  background-color: #fff;
  font-size: 14px;
  height: 34px;
  padding: 8px 20px;
  border: 1px solid #dee2ec;
  border-radius: 4px;
`;

const ModalVital = ({ options, close }) => {
  const eventBus = useEvent();
  const modal = useModal();
  const services = useServices();
  const toast = useToast();
  const defaultParams = $qb().limit(100).orderBy('order asc');
  const [vitalList, setVitalList] = useState([
    options.vital || { name: '', unit: '', order: '', visible: '' },
  ]);
  const [data, setData] = useState([]);

  const callApi = useCallback(async () => {
    const resp = await services.crm.vital[options.type].all_v2(
      defaultParams.build()
    );
    if (!resp) return;
    setData(resp.data);
  }, [services.crm.vital]);

  useEffect(() => {
    callApi();
  }, [callApi]);

  const onClickSave = useCallback(async () => {
    let obj = [...vitalList];
    let endpoint = !options.vital ? 'create' : 'update';
    let payload = {};

    if (endpoint === 'create') {
      payload.data = [
        ...obj.map((item, i) => {
          item.order = data && data.length + i + 1;
          item.visible = true;
          return item;
        }),
      ];
    } else {
      payload.data = {
        id: obj[0].id,
        name: obj[0].name,
        unit: obj[0].unit,
        order: obj[0].order,
        visible: obj[0].visible,
      };

      if (payload.data.name === '') {
        toast.error('항목명을 입력해주세요');
        return false;
      }
      if (payload.data.unit === '') {
        toast.error('단위을 입력해주세요');
        return false;
      }
    }

    for (let i = 0; i < payload.data.length; i++) {
      if (payload.data[i].name === '') {
        toast.error('항목명을 입력해주세요');
        return false;
      }
      if (payload.data[i].unit === '') {
        toast.error('단위을 입력해주세요');
        return false;
      }
    }

    try {
      await services.crm.vital[options.type].update_v2(payload);
      let message = translate(`${endpoint.toUpperCase()}D`);
      modal
        .confirm({
          type: 'SUCCESS',
          msg: message,
        })
        .then(() => {
          eventBus.emit('callApi');
          close();
        });
    } catch (e) {
      console.log(e.description);
      let errorMessage = e.description;
      modal.confirm({
        type: 'ERROR',
        msg: errorMessage,
      });
    }
  }, [eventBus, modal, services, toast, vitalList, close, options]);

  const addVital = () => {
    setVitalList([...vitalList, { name: '', unit: '' }]);
  };

  return (
    <div className="modal-nursecode">
      <div className="head flex-row flex-between items-start">
        <div className="title">바이탈 항목 추가</div>
        <i onClick={() => close()} className="zmdi zmdi-close" />
      </div>
      <div className="body">
        {vitalList.map((item, i) => {
          return (
            <div key={i}>
              <div className="flex-row">
                <div className="form-control">
                  <label>바이탈 항목명</label>
                  <div className="flex-row m-b-8">
                    <Input
                      placeholder="바이탈 항목명을 기입하세요."
                      value={item.name || ''}
                      onChange={(e) => {
                        vitalList[i].name = e.target.value;
                        setVitalList([...vitalList]);
                      }}
                    />
                  </div>
                </div>
                <div className="form-control">
                  <label>단위</label>
                  <div className="flex-row m-b-8">
                    <Input
                      placeholder="측정단위를 기입하세요."
                      value={item.unit || ''}
                      onChange={(e) => {
                        vitalList[i].unit = e.target.value;
                        setVitalList([...vitalList]);
                      }}
                    />
                  </div>
                </div>
              </div>
              {options.product === undefined && i !== 0 ? (
                <div className="form-control2">
                  <button
                    className="btn btn-danger"
                    onClick={() => {
                      (vitalList || []).splice(i, 1);
                      setVitalList([...vitalList]);
                    }}
                  >
                    빼기
                  </button>
                </div>
              ) : null}
            </div>
          );
        })}

        {options.vital === undefined ? (
          <button
            className="btn btn-primary btn-block"
            onClick={() => addVital()}
          >
            입력란 추가
          </button>
        ) : null}
      </div>
      <div className="buttons">
        <div className="flex-row">
          <button onClick={() => close(0)} className="btn btn-default">
            {translate('CANCEL')}
          </button>
          <button onClick={() => onClickSave()} className="btn btn-primary">
            {translate('SAVE')}
          </button>
        </div>
      </div>
    </div>
  );
};

ModalVital.propTypes = {
  options: PropTypes.object,
  close: PropTypes.func,
};

export default observer(ModalVital);
