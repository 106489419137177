import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import { observer } from 'mobx-react';
import { currency } from 'filters';
import { Bar } from 'react-chartjs-2';
import PropTypes from 'prop-types';

const SalesStatistics = ({ apiData }) => {
  const [thisMonth, setThisMonth] = useState();
  const [thisMonthData, setThisMonthData] = useState({
    total: 0,
    isNewCustoemer: 0,
    existsCustomer: 0,
  });
  const [chartData, setChartData] = useState({});
  const options = {
    scales: {
      x: { stacked: true },
      y: {
        stacked: true,
        ticks: {
          beginAtZero: true,
          callback: function (value) {
            return `₩${currency(value)}`;
          },
        },
      },
    },
    plugins: {
      datalabels: {
        formatter: function (value) {
          return value === 0 ? '' : `₩${currency(value)}`;
        },
      },
    },
  };

  const settingThisMonthData = useCallback(async (month) => {
    let existsCustomerAmount =
      month.existsCustomerSalesFigures - month.existsCustomerRefundAmount;
    let newCustomerAmount =
      month.newCustomerSalesFigures - month.newCustomerRefundAmount;
    let total = existsCustomerAmount + newCustomerAmount;

    setThisMonthData({ total, newCustomerAmount, existsCustomerAmount });
  }, []);

  const settingSalesChartData = useCallback(async (monthlyData) => {
    let labels = [];
    let existsCustomerAmount = [];
    let newCustomerAmount = [];
    let totalAmount = [];

    monthlyData.forEach((v) => {
      // 이번달 데이터는 제외
      if (moment(v.paidAt).format('MM') === moment(new Date()).format('MM'))
        return;

      let e = v.existsCustomerSalesFigures - v.existsCustomerRefundAmount;
      let n = v.newCustomerSalesFigures - v.newCustomerRefundAmount;

      labels.push(moment(v.paidAt).format('YYYY-MM'));
      existsCustomerAmount.push(e);
      newCustomerAmount.push(n);
      totalAmount.push(e + n);
    });

    setChartData({
      labels,
      datasets: [
        {
          label: '신환매출',
          data: newCustomerAmount,
          backgroundColor: '#D6E9C6',
          barThickness: 50,
        },
        {
          label: '구환매출',
          data: existsCustomerAmount,
          backgroundColor: '#FAEBCC',
          barThickness: 50,
        },
        {
          //토탈값 노출
          label: '',
          data: totalAmount,
          type: 'line',
          datalabels: {
            align: 'end',
            anchor: 'end',
            font: { weight: 'bold' },
          },
          backgroundColor: 'transparent',
          borderColor: 'transparent',
        },
      ],
    });
  }, []);

  const settingsChart = useCallback(async () => {
    const thisMonth = moment(new Date()).format('M');
    setThisMonth(thisMonth);

    if (apiData) {
      settingThisMonthData(
        apiData.data.find((v) => moment(v.paidAt).format('M') == thisMonth)
      );
      settingSalesChartData(apiData.data);
    }
  }, [apiData, settingThisMonthData, settingSalesChartData]);

  useEffect(() => {
    settingsChart();
  }, [settingsChart]);

  return (
    <>
      <div className="subtitle">매출</div>
      <div className="title-area">
        {thisMonth}월 총 매출액 {currency(thisMonthData.total || 0)}원(신환{' '}
        {currency(thisMonthData.newCustomerAmount || 0)}원, 구환{' '}
        {currency(thisMonthData.existsCustomerAmount || 0)}원)
      </div>
      <div className="chart-area">
        <Bar data={chartData} options={options} />
      </div>
    </>
  );
};

SalesStatistics.propTypes = {
  apiData: PropTypes.object,
};

export default observer(SalesStatistics);
