import { useCallback, useEffect, useState } from 'react';

export function useElementSize() {
  const [element, setElement] = useState();
  const [rect, setRect] = useState();

  useEffect(() => {
    if (!element) return;

    const resizeObserver = new ResizeObserver((entries) => {
      const entry = entries[0];
      setRect(entry.target.getBoundingClientRect());
    });

    resizeObserver.observe(element);

    return () => {
      resizeObserver.unobserve(element);
    };
  }, [element]);

  const ref = useCallback((node) => {
    setElement(node);
  });

  return [rect, ref];
}
