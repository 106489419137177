import React, { useState, useEffect, useCallback } from 'react';
import { observer } from 'mobx-react';
import DataTableAlpha from 'components/data-table/DataTableAlpha';
import PropTypes from 'prop-types';
import modelResponseHistories from 'models/response-histories';
import { useServices } from 'hooks/useServices';

const ConsultingRequestResponsiveHistoryDataArea = ({ parentData }) => {
  const services = useServices();
  const [data, setData] = useState(null);

  const callApi = useCallback(async () => {
    const param = { consultingRequestId: parentData.id };
    const resp = await services.crm.consultingRequest.result.all(param);
    if (!resp) return;
    resp.data.forEach((v) => {
      v.statusName = '미입력';
      if (v.status) {
        v.statusName = v.status.category?.name
          ? v.status.category.name + ' > ' + v.status?.name
          : v.status?.name;
      }
    });
    setData(resp.data);
  }, [services.crm.consultingRequest.result, parentData.id]);

  useEffect(() => {
    callApi();
  }, [callApi]);

  return (
    <div className="responsive-history-data m-t-16">
      <span>응대기록</span>
      <div className="scroll-table">
        <DataTableAlpha
          model={modelResponseHistories}
          data={data}
          hideBottom={true}
        />
      </div>
    </div>
  );
};

ConsultingRequestResponsiveHistoryDataArea.propTypes = {
  parentData: PropTypes.object,
};

export default observer(ConsultingRequestResponsiveHistoryDataArea);
