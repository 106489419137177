import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { banks } from 'hooks/constants';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { useToast } from 'hooks/useToast';

const Input = styled.input`
  width: 100%;
  height: 34px;
  padding: 8px 20px;
  border-color: #d7e3f1;
  font-size: 12px;
  padding: 9px 15px;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #dee2ec;

  ${({ disabled }) =>
    disabled &&
    css`
      background-color: #efeff4 !important;
      color: #ceced7;
    `}
`;

const ModalSmsPointRefundRequest = ({ options, close }) => {
  const toast = useToast();
  const [data, setData] = useState(options.data || {});

  const onChangeData = (e) => {
    const { name, value } = e.target;
    const refundData = {
      ...data,
      [name]: value,
    };

    setData(refundData);
    options.onChange(refundData);
  };

  const onClickRefund = () => {
    if (
      data?.refundBankCd == -1 ||
      !data?.refundBankCd ||
      !data?.refundAcctNm ||
      !data?.refundAcctNo
    ) {
      toast.error('환불 신청을 위한 모든 정보를 입력해주세요.');
      return;
    }
    close(true);
  };

  return (
    <div className="point-refund">
      <div className="head flex-row flex-between items-start m-b-16">
        <div className="title">환불 신청하기</div>
        <i onClick={() => close()} className="zmdi zmdi-close" />
      </div>
      <div className="title m-b-8">
        <span className="m-r-16">환불 금액</span>
        <span>{data.amount}원</span>
      </div>
      <div>
        <div className="title m-b-8">결제정보 확인</div>
        <div className="form-control required">
          <label>상호(사업명)</label>
          <Input disabled value={data.clinicName} />
        </div>
        <div className="form-control required">
          <label>사업자등록번호</label>
          <Input disabled value={data.employerNo} />
        </div>
        <div className="form-control required">
          <label>대표자</label>
          <Input disabled value={data.ownerName} />
        </div>

        <div className="title m-b-8">환불 받을 계좌 정보 입력</div>
        <div className="form-control required">
          <label>은행명</label>
          <div>
            <select
              className="block"
              name="refundBankCd"
              onChange={onChangeData}
              defaultValue={data.refundBankCd}
            >
              <option key={-1} value={-1}>
                -
              </option>
              {banks.map((bank, idx) => (
                <option key={idx} value={bank.value}>
                  {bank.key}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="form-control required">
          <label>계좌번호</label>
          <Input
            name="refundAcctNo"
            defaultValue={data.refundAcctNo}
            onChange={onChangeData}
          />
        </div>
        <div className="form-control required">
          <label>예금주</label>
          <Input
            name="refundAcctNm"
            defaultValue={data.refundAcctNm}
            onChange={onChangeData}
          />
        </div>
      </div>
      <div className="buttons">
        <div className="flex-row wrap-bottom-btns">
          <button className="btn btn-normal _small m-r-8" onClick={close}>
            취소
          </button>
          <button
            className="btn btn-basic _small"
            onClick={() => onClickRefund()}
          >
            환불 신청하기
          </button>
        </div>
      </div>
    </div>
  );
};

ModalSmsPointRefundRequest.propTypes = {
  options: PropTypes.object,
  close: PropTypes.func,
};

export default observer(ModalSmsPointRefundRequest);
