import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import Checkbox from 'components/app/Checkbox';
import { $case, translate } from 'filters';
import { useModal } from 'hooks/useModal';
import { useServices } from 'hooks/useServices';
import { useToast } from 'hooks/useToast';
import PropTypes from 'prop-types';
import { RadioInput } from 'components/common/RadioInput';
import styled from 'styled-components';

const RadioInputWrapper = styled(RadioInput)`
  margin-right: 10px;
`;

const OrganizationsAuthoritySettings = ({
  user,
  setAuthorityObj,
  setIsFavorite,
  setDataAccessAuthorityObj,
  departmentId,
  initStyle,
}) => {
  const modal = useModal();
  const services = useServices();
  const toast = useToast();
  const noMenuRow = ['현황판', '예약 캘린더']; //확장 로우가 필요 없는 경우
  // const notReadRow = ['smsPoint', 'smsHistory'] //보기만 권한이 필요 없는 경우
  // const dataAccessRange = ['현황판', '예약 캘린더', '차트 조회', '통계'] //데이터 접근범위 셀렉트 박스가 필요한 경우
  //const dataAccessRange = ['차트 조회']; //데이터 접근범위 셀렉트 박스가 필요한 경우 //'통계'
  const [hideHidden, setHideHidden] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [higherDepartmentList, setHigherDepartmentList] = useState([]);
  const [dataAccessAuthority, setDataAccessAuthority] = useState([
    {
      tableName: 'appointments',
      authorityName: 'appointment_board',
      type: 'staff',
    },
    {
      tableName: 'appointments',
      authorityName: 'appointment_calendar',
      type: 'staff',
    },
    { tableName: 'customers', authorityName: 'customer', type: 'staff' },
    { tableName: 'appointments', authorityName: 'appointment', type: 'staff' },
    { tableName: 'consultings', authorityName: 'consulting', type: 'staff' },
    { tableName: 'treatments', authorityName: 'treatment', type: 'staff' },
    { tableName: 'payments', authorityName: 'payment', type: 'staff' },
    { tableName: 'surgery', authorityName: 'surgery', type: 'staff' },
    {
      tableName: 'operationNurseChart',
      authorityName: 'nurseOperation',
      type: 'staff',
    },
    { tableName: 'nurseChart', authorityName: 'nurseCare', type: 'staff' },
    { tableName: 'skinManageChart', authorityName: 'nurseSkin', type: 'staff' },
    // { tableName: 'prescriptions', authorityName: 'prescription', type: 'staff' },
    { tableName: 'files', authorityName: 'penchart', type: 'staff' },
  ]);
  let parentList = [{ key: '본인', value: -1 }];
  /*
  expandedList = {
    key: '메뉴명', expanded: '확장탭', value: '확장플래그', items: '하위항목'
  }
  expanded: true > 확장이 가능한 메뉴.
  expanded: false  > 확장 불가능한 메뉴. 확장탭 없이 items(하위항목)을 그린다.
  */
  const [expandedList, setExpandedList] = useState([]);

  const defaultUserAuthority = (expandedState) => {
    return [
      {
        key: '현황판',
        expanded: false,
        value: true,
        items: [
          { key: 'appointmentBoard', value: { disabled: true, write: false } },
        ],
      },
      {
        key: '예약 캘린더',
        expanded: false,
        value: true,
        items: [
          {
            key: 'appointmentCalendar',
            value: { disabled: true, write: false },
          },
        ],
      },
      {
        key: '문자 관리',
        expanded: true,
        value: expandedState,
        items: [
          { key: 'sms', value: { disabled: true, write: false } },
          { key: 'smsPoint', value: { disabled: true, write: false } },
          { key: 'smsHistory', value: { disabled: true, write: false } },
          { key: 'smsPointHistory', value: { disabled: true, write: false } },
          { key: 'smsCode', value: { disabled: true, write: false } },
          { key: 'alimtalkCode', value: { disabled: true, write: false } },
        ],
      },
      {
        key: '상담문의관리',
        expanded: true,
        value: expandedState,
        items: [
          {
            key: 'consultationInquiry',
            value: { disabled: true, write: false },
          },
          {
            key: 'consultationInquiryAssign',
            value: { disabled: true, write: false },
          },
          { key: 'mediaConnect', value: { disabled: true, write: false } },
        ],
      },
      {
        key: '차트 조회',
        expanded: true,
        value: expandedState,
        items: [
          { key: 'customer', value: { disabled: true, write: false } },
          { key: 'appointment', value: { disabled: true, write: false } },
          { key: 'consulting', value: { disabled: true, write: false } },
          { key: 'treatment', value: { disabled: true, write: false } },
          { key: 'payment', value: { disabled: true, write: false } },
          // { key: 'prescription', value: { disabled: false, write: true } },
          { key: 'penchart', value: { disabled: true, write: false } },
          { key: 'surgery', value: { disabled: true, write: false } },
          { key: 'nurseOperation', value: { disabled: true, write: false } },
          { key: 'nurseCare', value: { disabled: true, write: false } },
          { key: 'nurseSkin', value: { disabled: true, write: false } },
        ],
      },
      {
        key: '결산',
        expanded: true,
        value: expandedState,
        items: [
          { key: 'settlement', value: { disabled: true, write: false } },
          { key: 'settlementMonthly', value: { disabled: true, write: false } },
        ],
      },
      {
        key: '통계',
        expanded: true,
        value: expandedState,
        items: [
          { key: 'summaryStatistics', value: { disabled: true, write: false } },
          { key: 'salesStatistics', value: { disabled: true, write: false } },
          {
            key: 'counselorStatistics',
            value: { disabled: true, write: false },
          },
          { key: 'doctorStatistics', value: { disabled: true, write: false } },
          {
            key: 'customerStatistics',
            value: { disabled: true, write: false },
          },
          {
            key: 'treatmentItemStatistics',
            value: { disabled: true, write: false },
          },
          // { key: 'customerByRegionStatistics', value: { disabled: true, write: false } },
        ],
      },
      {
        key: '처방전 발급/관리',
        expanded: true,
        value: expandedState,
        items: [
          { key: 'prescription', value: { disabled: true, write: false } },
          {
            key: 'prescriptionStatistics',
            value: { disabled: true, write: false },
          },
        ],
      },
      {
        key: '환경설정',
        expanded: true,
        value: expandedState,
        items: [
          { key: 'customerCode', value: { disabled: true, write: false } },
          { key: 'appointmentCode', value: { disabled: true, write: false } },
          { key: 'consultingCode', value: { disabled: true, write: false } },
          { key: 'paymentCode', value: { disabled: true, write: false } },
          { key: 'nurseCode', value: { disabled: true, write: false } },
        ],
      },
      {
        key: '병원 관리',
        expanded: true,
        value: expandedState,
        items: [
          { key: 'organization', value: { disabled: true, write: false } },
          { key: 'user', value: { disabled: true, write: false } },
          { key: 'clinicInfo', value: { disabled: true, write: false } },
          { key: 'penchartSample', value: { disabled: true, write: false } },
        ],
      },
      // {
      // key: '사용자 로그', expanded: true, value: expandedState, items: [
      // { key: '2개이상', value: {} },
      // ]
      // }
    ];
  };

  const editUserAuthority = (user) => {
    const { authorityGroup } = user.user;
    let defaultSet = [
      { key: '현황판', items: ['appointmentBoard'] },
      { key: '예약 캘린더', items: ['appointmentCalendar'] },
      {
        key: '문자 관리',
        items: ['sms', 'smsPoint', 'smsHistory', 'smsPointHistory', 'smsCode'],
      },
      {
        key: '상담문의관리',
        items: [
          'consultationInquiry',
          'consultationInquiryAssign',
          'mediaConnect',
        ],
      },
      {
        key: '차트 조회',
        items: [
          'customer',
          'appointment',
          'consulting',
          'treatment',
          'payment',
          'penchart',
          'surgery',
          'nurseOperation',
          'nurseCare',
          'nurseSkin',
        ],
      }, //prescription
      { key: '결산', items: ['settlement', 'settlementMonthly'] },
      {
        key: '통계',
        items: [
          'summaryStatistics',
          'salesStatistics',
          'counselorStatistics',
          'doctorStatistics',
          'customerStatistics',
          'treatmentItemStatistics',
        ],
      },
      {
        key: '처방전 발급/관리',
        items: ['prescription', 'prescriptionStatistics'],
      },
      {
        key: '환경설정',
        items: [
          'customerCode',
          'appointmentCode',
          'consultingCode',
          'paymentCode',
          'nurseCode',
        ],
      },
      {
        key: '병원 관리',
        items: ['organization', 'user', 'clinicInfo', 'penchartSample'],
      },
      // { key: '사용자 로그', items: [] },
    ];

    let list = [];

    if (authorityGroup === null) {
      list = defaultUserAuthority(false);
    } else {
      // eslint-disable-next-line array-callback-return
      defaultSet.map((v) => {
        let valueCheck = v.items.some((item) => {
          return authorityGroup[item] === 'write';
        });
        if (noMenuRow.indexOf(v.key) > -1) {
          valueCheck = true;
        }
        let obj = {
          key: v.key,
          expanded: noMenuRow.indexOf(v.key) === -1,
          value: valueCheck,
        };
        if (v.items !== 0) {
          let itemList = v.items.map((iKey) => {
            let defaultValue = { disabled: false, write: false };
            // if(notReadRow.indexOf(iKey) === -1) { defaultValue.read = false }
            defaultValue = { ...defaultValue, [authorityGroup[iKey]]: true };
            return { key: iKey, value: defaultValue };
          });
          obj.items = itemList;
        }
        list.push(obj);
      });
    }

    return list;
  };

  const createListToHigherDepartment = (d) => {
    const { id, name } = d;
    const { parent } = d;
    let keyName = parent === null ? '병원 전체' : name;
    parentList.push({ key: keyName, value: id });
    if (parent !== null) {
      createListToHigherDepartment(parent);
    } else {
      setHigherDepartmentList(parentList);
    }
  };

  const higherDepartmentCallApi = async (departmentId) => {
    //상위부서조회하여 셀렉트박스 리스트 만들기
    const resp = await services.crm.organizations.organ.get(departmentId);

    if (!resp) return;
    createListToHigherDepartment(resp.data);
  };

  const dataAccessAuthoritycallApi = async () => {
    let params = { userId: user.userId };
    const resp = await services.crm.user.data_access_authorities.all(params);
    if (!resp) return;

    if (resp.data.length) {
      setDataAccessAuthority(resp.data);
      setDataAccessAuthorityObj(resp.data);
    }
  };

  useEffect(() => {
    if (departmentId === '-1') {
      setHigherDepartmentList([]);
    } else if (departmentId !== undefined) {
      higherDepartmentCallApi(departmentId);
    }

    if (Object.keys(user).length) {
      dataAccessAuthoritycallApi();
    } else {
      setDataAccessAuthority(dataAccessAuthority);
    }

    let list = Object.keys(user).length
      ? editUserAuthority(user)
      : defaultUserAuthority(false);
    setExpandedList(list);
    setAuthorityObj(list);
  }, [user, departmentId]);

  const notChangeOwnerCheck = () => {
    if (user.user !== undefined) {
      if (user.user.type === 'owner') {
        modal
          .confirm({
            type: 'ERROR',
            msg: 'owner 권한은 수정 불가합니다.',
          })
          .then(() => {
            return true;
          });
      }
    }
    return false;
  };

  const onChangeMenuRow = (e) => {
    const returnValue = notChangeOwnerCheck();
    if (returnValue) return;

    const { name } = e.target;
    let changeList = expandedList.map((v) => {
      if (v.key === name) {
        let items = v.items.map((item) => {
          if (!v.value) {
            //사용이면 모든 항목 write-true/disabled-false로 변경
            item.value['write'] = true;
            item.value['disabled'] = false;
          } else {
            //사용불가이면 모든 항목 write-false/disabled-true로 변경
            item.value['write'] = false;
            item.value['disabled'] = true;
          }
          return item;
        });
        return { ...v, value: !v.value, items: items };
      } else {
        return v;
      }
    });
    setExpandedList(changeList);
  };

  const onChangeRadio = (e) => {
    const returnValue = notChangeOwnerCheck();
    if (returnValue) return;

    const { name, value } = e.target;
    const [parentKey, childKey] = value.split('||');
    let changeList = expandedList.map((v) => {
      if (v.key === parentKey) {
        v.items.map((item) => {
          if (item.key === name) {
            //초기화
            // eslint-disable-next-line array-callback-return
            Object.keys(item.value).map((key) => {
              item.value = { ...item.value, [key]: false };
            });
            //선택한 값으로 업데이트
            item.value = { ...item.value, [childKey]: true };
          }
          return item;
        });
      }
      return v;
    });
    setExpandedList(changeList);
    setAuthorityObj(changeList);
  };

  const onConfirm = async (value) => {
    const isFavorite = {
      isFavorite: !hideHidden,
      name: value,
    };
    setIsFavorite(isFavorite);
    setHideHidden(!hideHidden);
  };

  const onChangeFavorite = () => {
    if (!hideHidden) {
      modal
        .input({
          title: '권한명칭',
          inputs: [
            {
              placeholder: '입력하세요',
            },
          ],
        })
        .then((inputs) => {
          if (!inputs || inputs.length === 0) return;

          if (!inputs[0].text) {
            toast.error('ERROR_EMPTY_VALUE');
            return;
          }

          onConfirm(inputs[0].text);
        });
    } else {
      onConfirm('');
    }
  };

  const closeOptions = (e) => {
    let target = e.target.parentElement.parentElement.parentElement;
    target.className = target.className.includes('close') ? '' : 'close';
  };

  // const onChangeDataAccessAuthority = (e) => {
  //   const returnValue = notChangeOwnerCheck()
  //   if(returnValue) return

  //   const { name, value } = e.target
  //   let selectAuthority = dataAccessAuthority.filter(item => {
  //     return item.authorityName === $case.toSnake(name, '_')
  //   })[0]

  //   let obj = {}
  //   if(value === '-1') {
  //     //본인
  //     obj.type = 'staff'
  //     obj.organizationId = null
  //   } else {
  //     obj.type = 'department'
  //     obj.organizationId = Number(value)
  //   }
  //   obj.tableName = selectAuthority.tableName
  //   obj.authorityName = selectAuthority.authorityName

  //   const resultObj = dataAccessAuthority.map( item => {
  //     //authorityName 예약(appointment)값이 변경되면
  //     //현황판(appointment_board), 예약 캘린더(appointment_calendar)도 같은 값으로 변경 되어야 한다.
  //     if(selectAuthority.authorityName === 'appointment') {
  //       if(item.authorityName === 'appointment' || item.authorityName === 'appointment_board' || item.authorityName === 'appointment_calendar') {
  //         return { ...item, ...obj, authorityName: item.authorityName }
  //       } else {
  //         return item
  //       }
  //     } else {
  //       return item.authorityName === obj.authorityName ? { ...item, ...obj } : item
  //     }
  //   })

  //   setDataAccessAuthority(resultObj)
  //   setDataAccessAuthorityObj(resultObj)
  // }

  // const dataAccessSelectBox = (item) => {
  //   let departmentKey = dataAccessAuthority.filter( v => {
  //     if(v.authorityName === $case.toSnake(item, '_')) {
  //       return v.organizationId
  //     } else {
  //       return
  //     }
  //   })

  //   if(departmentKey.length) {
  //     return departmentKey[0].organizationId
  //   } else {
  //     return ''
  //   }
  // }

  // 현황판/예약캘린더 (1)
  // 나머지 (2)
  const ExpandedList = (n) => {
    let expandedListCopy;
    if (n == 0) expandedListCopy = [...expandedList].slice(0, 2);
    else if (n) expandedListCopy = [expandedList[n]];
    // if (expandedListCopy && expandedList) return
    return (expandedListCopy || expandedList).map((v, i) => {
      return (
        <React.Fragment key={i}>
          {v.expanded && (
            <tr className="menu-row">
              <th>{v.key || ''}</th>
              <td colSpan="2">
                <span className="m-r-20">
                  <RadioInputWrapper
                    name={v.key}
                    checked={!v.value}
                    onChange={onChangeMenuRow}
                  />
                  사용불가
                </span>
                <span className="m-r-20">
                  <RadioInputWrapper
                    name={v.key}
                    checked={v.value}
                    onChange={onChangeMenuRow}
                  />
                  사용
                </span>
                <button
                  className="btn-close-option"
                  onClick={(e) => closeOptions(e)}
                />
              </td>
            </tr>
          )}
          {!v.items.length && (
            <tr>
              <th></th>
              <td span="2">준비중입니다.</td>
            </tr>
          )}
          {v.value &&
            v.items.map((item) => (
              <tr
                key={item.key}
                className={`${
                  noMenuRow.indexOf(
                    translate($case.toSnake(item.key, '_').toUpperCase())
                  ) > -1 && 'menu-row'
                }`}
              >
                <th>{translate($case.toSnake(item.key, '_').toUpperCase())}</th>
                {
                  //추후 read 권한 생길 예정
                  // dataAccessRange.indexOf(v.key) === -1 ?
                  //   <td colSpan="2">
                  //     {item.value.disabled !== undefined &&
                  //       <span className="m-r-8"><input type="radio" name={item.key} value={`${v.key}||disabled`}
                  //       checked={item.value.disabled} onChange={onChangeRadio}/>사용불가</span>}
                  //     {/* {item.value.read !== undefined ?
                  //       <span className="m-r-8"><input type="radio" name={item.key} value={`${v.key}||read`}
                  //       checked={item.value.read} onChange={onChangeRadio}/>보기만</span>
                  //       : <span className="m-r-8">-</span>}  */}
                  //     {item.value.write !== undefined &&
                  //     <span className="m-r-8"><input type="radio" name={item.key} value={`${v.key}||write`}
                  //     checked={item.value.write} onChange={onChangeRadio}/>사용</span>}
                  //   </td>
                  // :
                  <td>
                    {item.value.disabled !== undefined && (
                      <span className="m-r-20">
                        <RadioInputWrapper
                          name={item.key}
                          value={`${v.key}||disabled`}
                          checked={item.value.disabled}
                          onChange={onChangeRadio}
                        />
                        사용불가
                      </span>
                    )}
                    {/* {item.value.read !== undefined ? 
                  <span className="m-r-8"><input type="radio" name={item.key} value={`${v.key}||read`}
                  checked={item.value.read} onChange={onChangeRadio}/>보기만</span>
                  : <span className="m-r-8">-</span>}  */}
                    {item.value.write !== undefined && (
                      <span className="m-r-20">
                        <RadioInputWrapper
                          name={item.key}
                          value={`${v.key}||write`}
                          checked={item.value.write}
                          onChange={onChangeRadio}
                        />
                        사용
                      </span>
                    )}
                  </td>
                }
                {/* { dataAccessRange.indexOf(v.key) > -1 &&
              (
                <td>
                  <select
                    name={item.key}
                    onChange={onChangeDataAccessAuthority}
                    value={ dataAccessSelectBox(item.key) }
                  >
                    {
                    higherDepartmentList.map( v => {
                      return <option key={v.value} value={v.value}>{v.key}</option>
                    })}
                  </select>
                </td>
              )} */}
              </tr>
            ))}
        </React.Fragment>
      );
    });
  };

  return (
    <>
      {initStyle ? (
        <>
          <div className="modal-authority-settings m-b-16 table-round">
            <table>
              <colgroup>
                <col width="30%"></col>
                <col width="70%"></col>
                {/* <col width="40%"></col> */}
              </colgroup>
              <thead>
                <tr>
                  <th>메뉴</th>
                  <th>기능 사용범위</th>
                  {/* <th>데이터 접근범위</th> */}
                </tr>
              </thead>
              <tbody>{ExpandedList(0)}</tbody>
            </table>
          </div>

          {expandedList.map((v, i) => {
            if (i < 2) return null;
            return (
              <div
                className="modal-authority-settings m-b-16 table-round"
                key={i}
              >
                <table>
                  <colgroup>
                    <col width="30%"></col>
                    <col width="70%"></col>
                  </colgroup>
                  <tbody>{ExpandedList(i)}</tbody>
                </table>
              </div>
            );
          })}

          <div className="flex-row items-center btn">
            <div onClick={() => onChangeFavorite()}>
              <Checkbox className="flex-wrap m-r-8" checked={hideHidden} />
              <span>자주 쓰는 권한으로 저장</span>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="table-round">
            <table>
              <colgroup>
                <col width="30%"></col>
                <col width="70%"></col>
              </colgroup>
              <thead>
                <tr>
                  <th>메뉴</th>
                  <th>기능 사용범위</th>
                  {/* <th>데이터 접근범위</th> */}
                </tr>
              </thead>
              <tbody>{ExpandedList(0)}</tbody>
            </table>
          </div>

          {expandedList.map((v, i) => {
            if (i < 2) return null;
            return (
              <div className="table-round" key={i}>
                <table>
                  <colgroup>
                    <col width="30%"></col>
                    <col width="70%"></col>
                  </colgroup>
                  <tbody>{ExpandedList(i)}</tbody>
                </table>
              </div>
            );
          })}

          <div
            className="flex-row items-center"
            onClick={() => onChangeFavorite()}
          >
            <Checkbox className="flex-wrap m-r-8" checked={hideHidden} />
            <span>자주 쓰는 권한으로 저장</span>
          </div>
        </>
      )}
    </>
  );
};

OrganizationsAuthoritySettings.propTypes = {
  user: PropTypes.object,
  setAuthorityObj: PropTypes.func,
  setIsFavorite: PropTypes.func,
  setDataAccessAuthorityObj: PropTypes.func,
  departmentId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  initStyle: PropTypes.bool,
};

export default observer(OrganizationsAuthoritySettings);
