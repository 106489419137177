import React, { useCallback, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import DataTableAlpha from 'components/data-table/DataTableAlpha';
import ModalAddPaymentSurgeries from 'components/modals/ModalAddPaymentSurgeries';
import QuillTextField from 'components/quill/QuillTextField2';
import { toHexColorHtml } from 'components/quill/quillUtil';
import PropTypes from 'prop-types';
import moment from 'moment';
import modelSkinTab from 'models/skin-tab';
import modelSkinSurgeriesUpdate from 'models/skin-surgery-update';
import modelAppointmentSurgeryWaiting from 'models/appointment-surgery-waiting';
import modelAppointmentSurgeryMinified from 'models/appointment-surgery-minified';
import useCustomerChart from 'hooks/useCustomerChart';
import { copy, translate } from 'filters';
import { $qb } from 'scripts/querybuilder';
import { useEvent } from 'hooks/useEvent';
import { useModal } from 'hooks/useModal';
import { useServices } from 'hooks/useServices';
import { useToast } from 'hooks/useToast';

const CustomerSkinManage = ({ customer, closeFlag }) => {
  const history = useHistory();
  const eventBus = useEvent();
  const modal = useModal();
  const services = useServices();
  const toast = useToast();

  const tabName = 'skinManage';
  const [loadingBtnDisabledFlag, setLoadingBtnDisabledFlag] = useState(false);
  const defaultParams = $qb()
    .limit(10)
    .customParam('customerId', customer.id)
    .orderBy('scheduledAt desc');
  const [params, setParams] = useState(defaultParams);
  const [total, setTotal] = useState(0);
  const [skins, setSkins] = useState([]);
  const [surgeryItems, setSurgeryItems] = useState([]); //수납된, 1회이상 남은 시수술(피부관리)내역
  const [obj, setObj] = useState({});
  const [appointmentSurgeries, setAppointmentSurgeries] = useState([]); //예약된 시수술(피부관리)내역
  const [items, setItems] = useState([]); //진행할 피부관리 체크내역
  //componentFlag : skin
  const [componentFlag, setComponentFlag] = useState(null);
  const [memoBoilerplateList, setMemoBoilerplateList] = useState([]);

  const [facialist, setFaicalist] = useState([]);
  const [counselors, setCounselors] = useState([]);
  const [doctors, setDoctors] = useState([]);

  const loadSkins = useCallback(async () => {
    //피부관리차트 get api 호출
    try {
      if (customer.id) {
        const resp = await services.crm.nurse['skins'].all_v2(
          defaultParams.build()
        );
        if (!resp) return;
        //let changeResp = resp.data;
        //setChartList(changeResp)
        setSkins(resp.data);
        setTotal(resp.pagination.total);
      }
    } catch (e) {
      console.log(e.description);
    }
  }, [customer.id]);

  const facialistCallApi = useCallback(async () => {
    let params = { duty: '피부관리사', userStatus: 'active', limit: 300 };
    const resp = await services.crm.user.duty(params);
    if (!resp) return;

    setFaicalist(resp.data);
  }, []);

  useEffect(() => {
    facialistCallApi();
  }, [facialistCallApi]);

  const counselorCallApi = useCallback(async () => {
    let params = { duty: '상담사', userStatus: 'active', limit: 300 };
    const resp = await services.crm.user.duty(params);
    if (!resp) return;

    setCounselors(resp.data);
  }, []);

  useEffect(() => {
    counselorCallApi();
  }, [counselorCallApi]);

  const doctorCallApi = useCallback(async () => {
    let params = { duty: '의사', userStatus: 'active', limit: 300 };
    const resp = await services.crm.user.duty(params);
    if (!resp) return;

    setDoctors(resp.data);
  }, []);

  useEffect(() => {
    doctorCallApi();
  }, [doctorCallApi]);

  const stateInit = useCallback(async () => {
    setComponentFlag(null);
    setObj({});
    setLoadingBtnDisabledFlag(false);
    setItems([]);
  }, []);

  const appointmentListCallApi = useCallback(async () => {
    try {
      if (!customer.id) return;

      const resp = await useCustomerChart.useGetRemainingPaidTreatmentItems(
        customer.id
      );
      if (!resp) return;

      setAppointmentSurgeries(resp);
    } catch (e) {
      console.log(e.description);
    }
  }, [customer.id]);

  const remainingCountCallApi = useCallback(async () => {
    try {
      //수납된(미수포함) / 남은 회차가 1회 이상 남은  시/수술 내역 노출
      //남은 회차가 0회인(환불포함) 시/수술은 노출 x

      if (!customer.id) return;

      const resp = await useCustomerChart.useGetRemainingPaidTreatmentItems(
        customer.id
      );
      if (!resp) return;

      return resp;
    } catch (e) {
      console.log(e.description);
    }
  }, [customer.id]);

  const paymentCallApi = useCallback(async () => {
    try {
      const resp = await remainingCountCallApi();
      const payment = resp.map((v, i) => {
        // v.$$checked = v.isSelected
        v.checkId = i;
        return v;
      });
      setItems([]);
      setSurgeryItems([...payment]);
    } catch (e) {
      console.log(e.description);
    }
  }, [remainingCountCallApi]);

  const init = useCallback(async () => {
    loadSkins();
  }, [loadSkins]);

  const onDeleteSkin = useCallback(
    async (id) => {
      try {
        await services.crm.nurse['skins'].delete_v2(id);
        modal
          .confirm({
            type: 'SUCCESS',
            msg: '삭제되었습니다.',
          })
          .then(() => {
            eventBus.emit('chartCountCallApi');
            init();
            appointmentListCallApi();
          });
      } catch (e) {
        console.log(e.description);
        modal.confirm({
          type: 'ERROR',
          msg: e.description,
        });
      }
    },
    [init, appointmentListCallApi]
  );

  const onClickDeleteSkin = (skin) => {
    modal
      .basic({
        body: '정말로 삭제하시겠습니까?',
        buttons: [
          {
            text: 'CANCEL',
            class: 'btn-default',
          },
          {
            text: 'CONFIRM',
            class: 'btn-primary',
          },
        ],
      })
      .then((idx) => {
        if (idx === 1) {
          onDeleteSkin(skin.id);
        }
      });
  };

  const onAction = (obj) => {
    const event = obj.event;
    const row = obj.row;
    const classList = event && event.target.classList;
    const model = obj.key;

    if (
      (obj.eventType === 'doubleClick' && model?.title !== 'STATUS') ||
      (classList && classList.contains('btn-primary'))
    ) {
      //수정
      setObj(copy(obj.row));
      setComponentFlag('skin');
      paymentCallApi();
    }

    if (classList && classList.contains('btn-danger')) {
      //삭제. 삭제는 병원 owner(대표)만 삭제할 수 있다
      onClickDeleteSkin(row);
      return;
    }
  };

  const onSelectSkinAction = (object) => {
    const event = object.event;
    const surgeries = object.row;
    const classList = event.target.classList;

    if (classList.contains('checkbox') || classList.contains('zmdi-check')) {
      if (surgeries.$$checked) {
        setItems([...items, surgeries]);
      } else {
        let list = items.filter((v) => {
          return v.id !== surgeries.id;
        });
        setItems([...list]);
      }
    }

    if (classList.contains('zmdi-minus')) {
      let deleteItems = surgeryItems.filter((f) => {
        return f.checkId !== surgeries.checkId;
      });
      setSurgeryItems([...deleteItems]);
      setItems([...deleteItems].filter((v) => v.$$checked));
    }
  };

  const onParams = (p) => {
    setParams(p);
    loadSkins();
  };

  const beforeSave = () => {
    if (!obj.id) {
      if (!items.length) {
        toast.error('진행할 피부관리가 없습니다.');
        return;
      }
    }
    onClickSave(items);
  };

  const onClickSave = useCallback(
    async (items) => {
      setLoadingBtnDisabledFlag(true);

      let endpoint = !obj.id ? 'create' : 'update';
      let payload = { ...obj };

      //customer 객체를 삭제하고 id만 전달
      if (obj && obj.customer && obj.customer.id) {
        payload.customerId = (customer || {}).id;
      }

      //facialist, doctor, counselor 객체 내에서 id값만 전달
      if (obj && obj.facialist && obj.facialist.id) {
        payload.facialist = {
          id: payload.facialist.id,
        };
      }

      if (obj && obj.doctor && obj.doctor.id) {
        payload.doctor = {
          id: payload.doctor.id,
        };
      }

      if (obj && obj.counselor && obj.counselor.id) {
        payload.counselor = {
          id: payload.counselor.id,
        };
      }

      if (obj.memo) payload.memo = toHexColorHtml(obj.memo);
      if (endpoint === 'create') {
        payload.scheduledAt = obj.scheduledAt; //moment().format('YYYY-MM-DDTHH:mm:ss')
        const changeSurgeryTreatmentItems = items.map((v) => {
          if (v.status !== 'unpaid') {
            //수납된 시수술이 id를 paymentTreatmentItemId로 변경하여 보냄
            v.paymentTreatmentItemId = v.id;
          }
          //복사생성이기에 id값을 삭제하고 넘겨야 함
          // eslint-disable-next-line no-unused-vars
          const { id: deletedKey, $$checked: deletedKey2, ...otherKeys } = v;
          return otherKeys;
        });
        payload.items = [...changeSurgeryTreatmentItems];
      } else {
        //수정시에는 items 수정 불가
        const {
          // eslint-disable-next-line no-unused-vars
          items: deletedKey,
          ...ohterKeys
        } = payload;
        payload = { ...ohterKeys };
      }

      if (payload.items && payload.items.length > 0) {
        let changeItems = payload.items.map((v) => {
          const {
            categoryName,
            discountAmount,
            isFree,
            name,
            nextDay,
            nextMonth,
            paymentTreatmentItemId,
            price,
            priceVatInclude,
            refundCount,
            reimbursement,
            remainingCount,
            treatmentCount,
            treatmentItemId,
            useCount,
            vatIncluded,
            status,
          } = v;

          return {
            categoryName,
            discountAmount,
            isFree,
            name,
            nextDay,
            nextMonth,
            paymentTreatmentItemId,
            price,
            priceVatInclude,
            refundCount,
            reimbursement,
            remainingCount,
            treatmentCount,
            treatmentItemId,
            useCount,
            vatIncluded,
            status,
          };
        });

        payload.items = changeItems;
      }

      try {
        const resp =
          endpoint === 'create'
            ? await services.crm.nurse['skins'].create_v2(payload)
            : await services.crm.nurse['skins'].update_v2(payload);
        if (!resp) return;

        let message = translate(`${endpoint.toUpperCase()}D`);
        modal
          .confirm({
            type: 'SUCCESS',
            msg: message,
          })
          .then(() => {
            loadSkins();
            eventBus.emit('chartCountCallApi');
            if (resp.data.id) {
              appointmentListCallApi();
              if (history.location.pathname === '/crm/appointment-boards') {
                eventBus.emit('boardsLoadAppointments');
              } else if (history.location.pathname === '/crm/home') {
                eventBus.emit('homeLoadAppointments');
              }
            }
            stateInit();
            if (closeFlag) {
              modal.pop();
              return;
            }
            setLoadingBtnDisabledFlag(false);
          });
      } catch (e) {
        console.log(e.description);
        modal
          .confirm({
            type: 'ERROR',
            msg: e.description,
          })
          .then(() => {
            setLoadingBtnDisabledFlag(false);
          });
      }
    },
    [
      history,
      eventBus,
      modal,
      obj,
      loadSkins,
      remainingCountCallApi,
      appointmentListCallApi,
      stateInit,
    ]
  );

  const onChangeValue = (column, value) => {
    setObj((obj) => {
      return { ...obj, [column]: value };
    });
  };

  const initSkin = (skinId) => {
    if (skinId && (skins || []).length > 0) {
      setObj(copy(skins.find((skin) => skin.id === skinId)));
    } else {
      setObj({ customer, scheduledAt: moment().format() });
    }
    setComponentFlag('skin');
  };

  useEffect(() => {
    return () => {
      setObj({});
      setComponentFlag(null);
    };
  }, []);

  useEffect(() => {
    init();
  }, [init]);

  useEffect(() => {
    appointmentListCallApi();
  }, [appointmentListCallApi]);

  const actionInit = useCallback(async () => {
    init();
    appointmentListCallApi();
    stateInit();
  }, [init, appointmentListCallApi, stateInit]);

  const onClickCreateSkin = async () => {
    paymentCallApi();
    initSkin();
  };

  const onClickCreateSkins = () => {
    modal
      .custom({
        component: ModalAddPaymentSurgeries,
      })
      .then((addItems) => {
        if (addItems === undefined || !addItems.length) {
          return;
        }
        let item = [
          ...surgeryItems,
          ...addItems.map((v) => {
            return {
              ...v,
              status: 'unpaid', //추가한 시수술인지 구분하기 위함
            };
          }),
        ];
        let surgeries = item.map((v, i) => {
          v.checkId = i;
          return v;
        });

        setSurgeryItems([...surgeries]);
        setItems([...surgeries.filter((v) => v.$$checked)]);
      });
  };

  const memoBoilerplateCallApi = useCallback(async () => {
    try {
      const memoItems = await useCustomerChart.useGetMemoBoilerplate(tabName);
      setMemoBoilerplateList(memoItems);
    } catch (e) {
      console.log(e.description);
    }
  }, [services.crm.boilerplate.memo]);

  useEffect(() => {
    memoBoilerplateCallApi();
  }, [memoBoilerplateCallApi]);

  const onChangeScheduledAt = (scheduledAt) => {
    let date =
      scheduledAt === null ? moment().format() : moment(scheduledAt).format();
    setObj((obj) => {
      return { ...obj, scheduledAt: date };
    });
  };

  return (
    <div className="customer-treatments chart-page">
      {componentFlag === null ? (
        <>
          <div className="data-input m-b-20">
            {appointmentSurgeries.length ? (
              <>
                <div className="title">
                  대기중인 피부관리가 있습니다.
                  <div className="right">
                    <button
                      className="btn btn-basic _small"
                      onClick={onClickCreateSkin}
                    >
                      피부관리 하기
                    </button>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="title">
                  대기중인 피부관리가 없습니다.
                  <div className="right">
                    <button
                      className="btn btn-basic _small"
                      onClick={onClickCreateSkin}
                    >
                      피부관리 추가
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
          {componentFlag === null && appointmentSurgeries.length ? (
            <div>
              <DataTableAlpha
                model={modelAppointmentSurgeryWaiting}
                data={appointmentSurgeries}
                hideBottom={true}
                params={params}
              />
            </div>
          ) : null}
        </>
      ) : null}
      {obj && componentFlag === 'skin' ? (
        <>
          <div className="data-input">
            <div className="title">
              피부관리 입력
              <div className="right">
                {/* <button className="btn btn-basic _small" onClick={onClickCreateSurgery}>시/수술 입력</button> */}
              </div>
            </div>
            <div className="card">
              <div className="form-double">
                <div className="form-control">
                  <label className="label-required">피부관리일</label>
                  {useCustomerChart.getScheduledAtDatePicker({
                    objScheduledAt: obj.scheduledAt || new Date(),
                    onChangeScheduledAt,
                  })}
                </div>
              </div>
              <div className="form-double">
                <div className="form-control">
                  <label>피부관리사</label>
                  <select
                    value={facialist.findIndex(
                      (existing) => existing.id === (obj['facialist'] || {}).id
                    )}
                    onChange={(e) => {
                      if (e && e.target) {
                        obj['facialist'] = facialist[e.target.value];
                        setObj({ ...obj });
                      }
                    }}
                  >
                    <option value={-1} hidden disabled>
                      피부관리사를 선택하세요.
                    </option>
                    <option value="null">-</option>
                    {(facialist || []).map((o, idx) => (
                      <option key={idx} value={idx}>
                        {' '}
                        {o.name}{' '}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-control">
                  <label>의사</label>
                  <select
                    value={doctors.findIndex(
                      (existing) => existing.id === (obj['doctor'] || {}).id
                    )}
                    onChange={(e) => {
                      if (e && e.target) {
                        obj['doctor'] = doctors[e.target.value];
                        setObj({ ...obj });
                      }
                    }}
                  >
                    <option value={-1} hidden disabled>
                      의사를 선택하세요.
                    </option>
                    <option value="null">-</option>
                    {(doctors || []).map((o, idx) => (
                      <option key={idx} value={idx}>
                        {' '}
                        {o.name}{' '}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-control">
                  <label>상담사</label>
                  <select
                    value={counselors.findIndex(
                      (existing) => existing.id === (obj['counselor'] || {}).id
                    )}
                    onChange={(e) => {
                      if (e && e.target) {
                        obj['counselor'] = counselors[e.target.value];
                        setObj({ ...obj });
                      }
                    }}
                  >
                    <option value={-1} hidden disabled>
                      상담사를 선택하세요.
                    </option>
                    <option value="null">-</option>
                    {(counselors || []).map((o, idx) => (
                      <option key={idx} value={idx}>
                        {' '}
                        {o.name}{' '}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              {!obj.id ? ( //피부관리 생성시 진행할 피부관리 보여주기
                <div
                  className="form-control form-full"
                  style={{ width: '1100px' }}
                >
                  <div className="flex-row select-surgery">
                    <label className="label-required">
                      진행할 피부관리를 선택하세요
                    </label>
                    {!obj.id ? (
                      <button
                        className="btn btn-basic _small"
                        onClick={onClickCreateSkins}
                      >
                        피부관리추가
                      </button>
                    ) : null}
                  </div>
                  <DataTableAlpha
                    model={modelAppointmentSurgeryMinified}
                    data={surgeryItems}
                    hideBottom={true}
                    hideAllCheck={true}
                    onAction={onSelectSkinAction}
                  />
                </div>
              ) : null}

              {obj.id ? ( //피부관리 수정시 저장되어있는 피부관리 보여주기
                <div
                  className="form-control form-full"
                  style={{ width: '1100px' }}
                >
                  <label className="label-required">진행할 피부관리</label>
                  <DataTableAlpha
                    model={modelSkinSurgeriesUpdate}
                    data={obj.items.map((v) => {
                      v.$$checked = true;
                      return v;
                    })}
                    hideBottom={true}
                    hideAllCheck={true}
                  />
                </div>
              ) : null}

              <div className="form-wide">
                <div className="form-control">
                  <label>메모</label>
                  {memoBoilerplateList.length > 0 && (
                    <div className="wrap-btn-boilerplate-memo">
                      {memoBoilerplateList.slice(0, 5).map((v, i) => (
                        <button
                          className="btn btn-sm btn-white _ssmall"
                          key={i}
                          onClick={() =>
                            onChangeValue(
                              'memo',
                              (obj.memo === '<p><br></p>'
                                ? ''
                                : obj.memo || '') + v.contents
                            )
                          }
                        >
                          {v.title.slice(0, 5) +
                            (v.title.length > 5 ? '…' : '')}
                        </button>
                      ))}
                    </div>
                  )}
                  {obj && (
                    <QuillTextField
                      tabName={tabName}
                      key={obj.id}
                      value={obj.memo || ''}
                      setValue={(v) => onChangeValue('memo', v)}
                      setMemoBoilerplateList={setMemoBoilerplateList}
                      placeholder="메모를 입력하세요."
                    />
                  )}
                </div>
              </div>

              <div className="flex-row wrap-bottom-btns">
                <button
                  className="btn btn-cancel _small m-r-8"
                  onClick={actionInit}
                >
                  {'입력취소'}
                </button>
                <button
                  className="btn btn-basic _small"
                  disabled={`${loadingBtnDisabledFlag ? 'disabled' : ''}`}
                  onClick={beforeSave}
                >
                  입력완료
                </button>
              </div>
            </div>
          </div>
        </>
      ) : null}

      <div className="wrap-data-table data-table">
        <div className="title">피부관리 내역</div>
        <div className="card">
          <DataTableAlpha
            model={modelSkinTab}
            total={total}
            data={skins}
            params={params}
            onParams={onParams}
            onAction={onAction}
            bottomPositionInner
            hasLine
          />
        </div>
      </div>
    </div>
  );
};

CustomerSkinManage.propTypes = {
  appointment: PropTypes.object,
  skinManageChart: PropTypes.object,
  customer: PropTypes.object,
  closeFlag: PropTypes.bool,
};

export default observer(CustomerSkinManage);
