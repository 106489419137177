export default {
  keys: [
    {
      title: 'CUSTOMER',
      column: 'name',
      noorder: true,
    },
    {
      title: 'CHART_NO',
      column: 'chartNo',
      noorder: true,
    },
    {
      title: 'BIRTHDAY',
      column: 'birthday',
      type: 'date',
    },
    {
      title: 'SEX',
      noorder: true,
      filter: (o) => {
        if (o.sex === 'female') {
          return '여성';
        } else if (o.sex === 'male') {
          return '남성';
        }
      },
    },
    {
      title: 'PHONE_NUMBER',
      noorder: true,
      filter: (o) => {
        if (o.phoneNumber) {
          let phoneNumber = (o.phoneNumber || '')
            .replace(/[^0-9]/g, '')
            .replace(
              /(^02|^0505|^1[0-9]{3}|^0[0-9]{2})([0-9]+)?([0-9]{4})/,
              '$1-$2-$3'
            )
            .replace('--', '-');
          return phoneNumber;
        }
      },
    },
    {
      title: 'SMS_ENABLED',
      noorder: true,
      filter: (o) => {
        if (o.smsEnabled) {
          return '동의';
        } else if (!o.smsEnabled) {
          return '거부';
        }
      },
    },
    {
      title: 'ACTION',
      noorder: true,
      filter: () => `<button class="btn btn-primary btn-sm">선택</button>`,
    },
  ],
};
