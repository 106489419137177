import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { pluralize, $case, translate } from 'filters';

const SelectNextTreatment = ({
  doctors,
  appointmentDoctor,
  setTreatmentSelectDoctors,
}) => {
  const [selectDoctor, setSelectDoctor] = useState(appointmentDoctor || {});
  const onChangeSelectDoctor = (e) => {
    setSelectDoctor(doctors[e.target.value]);
    setTreatmentSelectDoctors(doctors[e.target.value]);
  };

  return (
    <div className="form-control m-t-16">
      <label>의사</label>
      <select
        className="m-l-8"
        value={(doctors || []).findIndex(
          (option) => option.id === (selectDoctor || {}).id
        )}
        onChange={onChangeSelectDoctor}
      >
        <option value={undefined}>
          {translate($case.toConst(pluralize('doctors')))}를 선택하세요.
        </option>
        {(doctors || []).map((item, idx) => (
          <option key={idx} value={idx}>
            {item.name || item.content}
          </option>
        ))}
      </select>
    </div>
  );
};

SelectNextTreatment.propTypes = {
  doctors: PropTypes.array,
  appointmentDoctor: PropTypes.object,
  setTreatmentSelectDoctors: PropTypes.func,
};

export default observer(SelectNextTreatment);
