import moment from 'moment';

export default {
  keys: [
    {
      title: '응대일',
      column: 'createdAt',
      filter: (o) => moment(o.createdAt).format('YYYY-MM-DD HH:mm:ss'),
      customStyle: () => {
        return { width: '116px' };
      },
    },
    {
      title: '처리결과',
      column: 'statusName',
      filter: (o) => o.statusName,
      customStyle: () => {
        return { width: '140px' };
      },
    },
    {
      title: 'MEMO',
      column: 'memo',
      type: 'text',
    },
  ],
};
