import React, { useState, useEffect, useCallback } from 'react';
import { observer } from 'mobx-react';
import moment from 'moment';
import Checkbox from 'components/app/Checkbox';
import { useModal } from 'hooks/useModal';
import { useServices } from 'hooks/useServices';
import { useToast } from 'hooks/useToast';
import styled from 'styled-components';
import { RadioInput } from 'components/common/RadioInput';

const RadioInputWrapper = styled(RadioInput)`
  flex: 0 0 auto;
  margin-right: 8px;
`;

const ConsultingAutoAssignSettings = () => {
  const modal = useModal();
  const services = useServices();
  const toast = useToast();
  const [showDeactivated, setShowDeactivated] = useState(false);
  const [autoAssign, setAutoAssign] = useState([]);
  const [data, setData] = useState();
  const [assignEndHour, setAssignEndHour] = useState('19');
  const [assignEndMinute, setAssignEndMinute] = useState('00');
  const [showAssignEndAt, setShowAssignEndAt] = useState(true);
  const [assignRadio, setAssignRadio] = useState(true);

  const scheduleTimeHour = [...Array(22).keys()].slice(8).map((num) => {
    const parsedValue = String(num).padStart(2, '0');
    return { value: parsedValue, label: parsedValue };
  });
  const scheduleTimeMinute = [...Array(4).keys()].map((num) => {
    const parsedValue = String(num * 15).padStart(2, '0');
    return { value: parsedValue, label: parsedValue };
  });

  const configCallApi = useCallback(async () => {
    const resp = await services.crm.clinic.configs();
    if (!resp) return;

    if (resp.data.length) {
      resp.data = resp.data.filter((v) => {
        return v.groupName === 'consulting_request_auto_assign';
      });
      setData(resp.data[0]);
      setShowDeactivated(resp.data[0]?.codeValue === 'True');
    }
  }, [services.crm.clinic]);

  const autoAssignCallApi = useCallback(async () => {
    const resp = await services.crm.consultingRequest.auto_assign.all();
    if (!resp) return;

    if (resp.data.length) {
      setAutoAssign(resp.data);
      setAssignRadio(resp.data[0].isAssignAlways);
      //assignEndHour, assignEndMinute setting
      setAssignEndHour(new Date(resp.data[0].assignedEndAt).getHours());
      setAssignEndMinute(
        (new Date(resp.data[0].assignedEndAt).getMinutes() < 10 ? '0' : '') +
          new Date(resp.data[0].assignedEndAt).getMinutes()
      );
    }
  }, [services.crm.consultingRequest.auto_assign]);

  useEffect(() => {
    configCallApi();
  }, [configCallApi]);

  useEffect(() => {
    autoAssignCallApi();
  }, [autoAssignCallApi]);

  const onChangeCodeValue = useCallback(
    async (codeValue) => {
      try {
        let payload = {
          groupName: 'consulting_request_auto_assign',
          codeName: 'consulting_request_auto_assign',
          codeValue: codeValue,
        };

        if (data !== undefined) {
          payload.id = data.id;
        }

        await services.crm.clinic.configs_create(payload);
        modal
          .confirm({
            type: 'SUCCESS',
            msg: '생성되었습니다.',
          })
          .then(() => {
            configCallApi();
          });
      } catch (e) {
        console.log(e.description);
        modal.confirm({
          type: 'ERROR',
          msg: e.description,
        });
      }
    },
    [data, services.crm.clinic, toast, configCallApi]
  );

  const onChangeAutoAssign = () => {
    let codeValue;
    if (showDeactivated) {
      codeValue = 'False';
    } else {
      codeValue = 'True';
    }
    onChangeCodeValue(codeValue);
  };

  const onSaveAutoAssign = useCallback(
    async (payload) => {
      try {
        await services.crm.consultingRequest.auto_assign.create(payload);
        modal
          .confirm({
            type: 'SUCCESS',
            msg: '생성되었습니다.',
          })
          .then(() => {
            autoAssignCallApi();
          });
      } catch (e) {
        console.log(e.description);
        modal.confirm({
          type: 'ERROR',
          msg: e.description,
        });
      }
    },
    [toast, services.crm.consultingRequest.auto_assign, autoAssignCallApi]
  );

  const onChangeRadio = (e) => {
    if (e.target.value === 'false') {
      onChangeIsAssignAlways();
    } else {
      onSaveAssignEndAt();
    }
    setAssignRadio(!assignRadio);
  };

  const onChangeIsAssignAlways = useCallback(async () => {
    let payload;
    if (autoAssign.length > 0) {
      payload = { ...autoAssign[0] };
    }
    payload = {
      ...payload,
      isAssignAlways: true,
    };

    onSaveAutoAssign(payload);
  }, [autoAssign, onSaveAutoAssign]);

  const onSaveAssignEndAt = useCallback(async () => {
    let payload;
    if (autoAssign.length > 0) {
      payload = { ...autoAssign[0] };
    }
    payload = {
      ...payload,
      isAssignAlways: false,
      assignedEndAt: moment().format(
        `YYYY-MM-DDT${assignEndHour}:${assignEndMinute}:00`
      ),
    };

    onSaveAutoAssign(payload);
  }, [autoAssign, assignEndHour, assignEndMinute, onSaveAutoAssign]);

  const onChangeAssiginEndAt = (e) => {
    if (e.target.value !== 'true') {
      onSaveAssignEndAt();
    }
    setShowAssignEndAt(!showAssignEndAt);
  };

  return (
    <div className="auto-assign">
      <div className="evnet-header">
        <span className="title">자동 배정 설정</span>
      </div>
      <div className="event-body">
        <div className="auto-assign-settings">
          <div className="data-table">
            <div className="flex-row items-center">
              <span className="flex-wrap m-r-8">자동 배정 사용</span>
              <Checkbox
                className="flex-wrap m-r-8"
                checked={showDeactivated}
                toggleHandler={onChangeAutoAssign}
              />
            </div>
            <div className="flex-row items-center m-t-16">
              <div className="flex-wrap m-r-8">자동 배정 타입</div>
            </div>
            <div className="flex-row items-center">
              <span className="flex-wrap m-r-16 title-label">
                24시간 자동배정
              </span>
              <RadioInputWrapper
                name="assign"
                disabled={!showDeactivated}
                value={assignRadio}
                checked={assignRadio === true}
                onChange={onChangeRadio}
              />
            </div>
            <div className="flex-row items-center">
              <span className="flex-wrap m-r-16 title-label">
                특정시간 이후 중단
              </span>
              <RadioInputWrapper
                name="assign"
                disabled={!showDeactivated}
                value={assignRadio}
                checked={assignRadio === false}
                onChange={onChangeRadio}
              />
              <span className="flex-wrap m-r-8">금일</span>
              <div className="auto-assigin-end-at flex-wrap m-r-8">
                <select
                  disabled={!showDeactivated || showAssignEndAt}
                  onChange={(e) => setAssignEndHour(e.target.value)}
                  value={assignEndHour}
                >
                  {scheduleTimeHour.map((v, i) => (
                    <option key={i}>{v.value}</option>
                  ))}
                </select>
                &nbsp;&nbsp;:&nbsp;&nbsp;
                <select
                  disabled={!showDeactivated || showAssignEndAt}
                  onChange={(e) => setAssignEndMinute(e.target.value)}
                  value={assignEndMinute}
                >
                  {scheduleTimeMinute.map((v, i) => (
                    <option key={i}>{v.value}</option>
                  ))}
                </select>
              </div>
              <span className="flex-wrap m-r-16">까지</span>
              <button
                className={`btn ${
                  showDeactivated && !assignRadio ? 'btn-primary' : 'btn-gray'
                } flex-wrap`}
                disabled={!showDeactivated || assignRadio}
                onClick={onChangeAssiginEndAt}
                value={showAssignEndAt}
              >
                {showAssignEndAt ? '수정' : '저장'}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(ConsultingAutoAssignSettings);
