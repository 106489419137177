import { currency } from 'filters';
import moment from 'moment';

export default {
  options: {
    hasMergeCell: true,
    groupTitles: [
      '',
      'STATUS',
      'FIRST_PAID_AT',
      '고객명',
      'CHART_NO',
      '담당자',
      '_COUNSELORS',
      '청구액(VAT포함)',
      '수납액',
      '미수액',
      '환불액',
      '현금영수증',
      '메모',
    ],
    explodeColumn: 'paymentTotalItems',
  },
  keys: [
    {
      //     type: 'multiCheck',
      //     title: '',
      //     column: '',
      //     checkUse: o => 'false',
      //   },{
      title: 'STATUS',
      noorder: true,
      filter: (o) => {
        // status: paid(완납) / unpaid(미수)
        // refundStats : null(기존 데이터) / none(환불 없음) / part_refund(부분 환불) / full_refund(전체 환불)
        let status = '완납';
        if (o.status === 'unpaid') {
          status = '미수';
        } else {
          //paid인 경우 refundStatus로 구분
          if (o.refundStatus === null || o.refundStatus === 'none') {
            status = o.status === 'paid' ? '완납' : '미수';
          }
          if (o.refundStatus === 'part_refund') {
            status = '부분 환불';
          }
          if (o.refundStatus === 'full_refund') {
            status = '전체 환불';
          }
        }
        return `<div class="text-center">
                <div>${status}</div>
                <div class="text-center">
                <span class="text-underline c-red">삭제</span>
            </div>
            </div>`;
      },
      nosearch: true,
    },
    {
      title: 'FIRST_PAID_AT',
      column: 'firstPaidAt',
      nosearch: true,
      filter: (o) => moment(o.firstPaidAt).format('YYYY-MM-DD'),
    },
    {
      title: 'START_AT',
      column: 'startAt',
      hideColumn: true,
      selectType: 'date',
      customParamFieldName: 'startAt',
      filter: (o) => {
        return `<div>
                      <div>${moment(o.firstPaidAt).format('YYYY-MM-DD')} ${
          o.startHour
        }</div>
                  </div>`;
      },
    },
    {
      title: 'END_AT',
      column: 'endAt',
      hideColumn: true,
      selectType: 'date',
      customParamFieldName: 'endAt',
      filter: (o) => {
        return `<div>
                      <div>${moment(o.firstPaidAt).format('YYYY-MM-DD')} ${
          o.endHour
        }</div>
                  </div>`;
      },
    },
    {
      title: 'STATUS',
      selectOptions: ['paid', 'unpaid', 'part_refund', 'full_refund'].map(
        (key) => ({
          $$label: `PAYMENT_STATUS_${key.toUpperCase()}`,
          $$value: key,
        })
      ),
      hideColumn: true,
      customParamFieldName: 'status',
    },
    {
      title: '고객명',
      column: 'customer__name',
      noorder: true,
      customParamFieldName: 'name',
      filter: (o) =>
        `<span class="text-underline c-primary">${o.customer.name}</span>`,
    },
    {
      title: 'CHART_NO',
      noorder: true,
      nosearch: true,
      filter: (o) => `${o.customer.chartNo}`,
    },
    {
      title: '_COUNSELORS',
      column: 'counselor__name',
      noorder: true,
      customParamFieldName: 'counselorId',
    },
    {
      title: '담당자',
      column: 'createdName',
      noorder: true,
      customParamFieldName: 'createdName',
    },
    {
      title: 'SURGERY_CATEGORIES',
      column: '@@paymentTotalItems__categoryName',
      noorder: true,
      customParamFieldName: 'treatmentItemCategoryId',
      type: 'list_mini',
      filter: (o) => {
        return `<div style="position: relative; width: 125px; text-align: center;">
            <div class="flex-wrap"
                style="max-width: 125px; white-space: break-spaces; display: inline-block;">${
                  o['@@paymentTotalItems']['categoryName'] || ''
                }</div></div>`;
      },
    },
    {
      title: 'SURGERY_ITEM_NAMES',
      column: '@@paymentTotalItems__name',
      noorder: true,
      customParamFieldName: 'treatmentItemId',
      type: 'list',
      hideColumn: true,
      filter: (o) => {
        return `<div style="position: relative;">
                <p>${o['@@paymentTotalItems']['name'] || ''}${
          o['@@paymentTotalItems']['isFree']
            ? '<span class="flex-wrap ico-service"></span>'
            : ''
        }</span></p>
            </div>`;
      },
    },
    {
      title: 'SURGERY_ITEM_PRODUCT_NAMES',
      column: '@@paymentTotalItems__name',
      noorder: true,
      customParamFieldName: 'treatmentItemId',
      type: 'list',
      nosearch: true,
      filter: (o) => {
        return `<div style="position: relative; width: 240px; text-align: center;">
            <div class="flex-wrap"
                style="max-width: 240px; white-space: break-spaces; display: inline-block;">${
                  o['@@paymentTotalItems']['name'] || ''
                }${
          o['@@paymentTotalItems']['isFree']
            ? '<div class="flex-wrap ico-service"></div>'
            : ''
        }</div>
            </div>`;
      },
    },
    {
      title: 'PRODUCT_NAMES',
      column: '@@paymentTotalItems__name',
      customParamFieldName: 'productId',
      type: 'list',
      hideColumn: true,
    },
    {
      title: '회차(개수)',
      column: '@@paymentTotalItmes__quantity',
      noorder: true,
      nosearch: true,
      filter: (o) => {
        if (o['@@paymentTotalItems']['quantity'] === undefined) {
          //시/수술 회차
          return o['@@paymentTotalItems']['treatmentCount'];
        } else {
          //개수
          return o['@@paymentTotalItems']['quantity'];
        }
      },
    },
    {
      title: 'VAT포함가격',
      column: '@@paymentTotalItems__priceVatInclude',
      noorder: true,
      nosearch: true,
      filter: (o) => {
        return `₩${currency(o['@@paymentTotalItems']['priceVatInclude'] || 0)}`;
      },
    },
    {
      title: '할인',
      column: '@@paymentTotalItems__discountAmount',
      noorder: true,
      nosearch: true,
      filter: (o) => {
        return `${
          o['@@paymentTotalItems']['discountAmount'] > 0
            ? `<button class="btn btn-sm btn-primary btn-discount-reason">할인사유</button>`
            : ''
        }
            ₩${currency(o['@@paymentTotalItems']['discountAmount'] || 0)}`;
      },
    },
    {
      title: '청구액(VAT포함)',
      noorder: true,
      nosearch: true,
      filter: (o) => `₩${currency(o.requestAmount || 0)}`,
    },
    {
      title: '수납액',
      noorder: true,
      nosearch: true,
      filter: (o) => {
        return `<div>
            <div><button class="btn btn-sm btn-primary btn-payout-amount">상세보기</button></div>
            <div>₩${currency(o.paidAmount || 0)}</div>
            </div>`;
      },
    },
    {
      title: '미수액',
      noorder: true,
      nosearch: true,
      filter: (o) => `₩${currency(o.unpaidAmount || 0)}`,
    },
    {
      title: '환불액',
      noorder: true,
      nosearch: true,
      filter: (o) => `₩${currency(o.refundAmount || 0)}`,
    },
    {
      title: '현금영수증',
      noorder: true,
      nosearch: true,
      filter: (o) => {
        //payouts 배열 하위에 cashReceipt , 한건이라도 있으면 발행으로 표시
        return o.payouts.filter((v) => {
          return v.cashReceipt;
        }).length
          ? '발행'
          : '미발행';
      },
    },
    {
      title: '메모',
      column: 'memo',
      noorder: true,
      type: 'text',
      nosearch: true,
    },
  ],
};
