import * as moment from 'moment';
import { translate } from 'filters';

export default {
  keys: [
    {
      title: '전송일',
      column: 'scheduledAt',
      filter: (o) => {
        return `<div>
          <div>${moment(o.scheduledAt).format('YYYY-MM-DD HH:mm:ss')}</div>
        </div>`;
      },
    },
    {
      title: '상태',
      column: 'status',
      filter: (o) => {
        if (o.status === 'success') return '<div>전송성공</div>';
        if (o.status === 'waiting')
          return `
        <div>
          <div class="center">전송대기</div>
          <button class="btn btn-danger btn-sm" data-type="canceled">전송취소</button>
        </div>
        `;
        if (o.status === 'fail') return '<div>전송실패</div>';
        if (o.status === 'canceled') return '<div>전송취소</div>';
        if (o.status === 'sending') return '<div>전송중</div>';
      },
    },
    {
      title: '제목/내용',
      filter: (o) => {
        if (o.title === '' || !o.title) {
          return `<div class="contents">
                   <div class="label">${translate('CONTENTS')}</div>
                   <div class="text">${o.contents || ''}</div>
                 </div>`;
        }
        return `<div class="title">
                   <div class="label">${translate('TITLE')}</div>
                   <div class="text">${o.title || ''}</div>
               </div>
               <div class="contents">
                   <div class="label">${translate('CONTENTS')}</div>
                   <div class="text">${o.contents || ''}</div>
               </div>`;
      },
      type: 'text',
      class: 'title-and-content column-width-px-400',
    },
    {
      title: 'IMAGE',
      filter: (o) => {
        return o.imageUrl;
      },
      type: 'image',
      class: 'image-preview column-width-px-200',
    },
    {
      title: 'FAIL_REASON',
      column: 'failReason',
      filter: (o) => {
        return o.failReason ? o.failReason : '-';
      },
    },
  ],
};
