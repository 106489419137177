import modal from 'store/modal';
import { toHexColorHtml } from 'components/quill/quillUtil';
import ModalBoilerplateMemo from 'components/modals/codes/memo-codes/ModalMemoBoilerplateSelect';

export default {
  openModalBoilerplate: (
    category,
    memo,
    onChangeValue,
    setMemoBoilerplateList
  ) => {
    modal
      .custom({
        component: ModalBoilerplateMemo,
        options: {
          category: category,
          setMemoBoilerplateList: setMemoBoilerplateList,
        },
      })
      .then((payload) => {
        if (payload) {
          payload.memo &&
            onChangeValue &&
            onChangeValue(
              toHexColorHtml((memo == '<p><br></p>' ? '' : memo) + payload.memo)
            );
          payload.memoBoilerplateList &&
            setMemoBoilerplateList &&
            setMemoBoilerplateList(payload.memoBoilerplateList);
        }
      });
  },
};
