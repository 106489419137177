import React, { useCallback, useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import DataTableAlpha from 'components/data-table/DataTableAlpha';
import PropTypes from 'prop-types';
import modelCallHistoryTab from 'models/call-history-tab';
import { $qb } from 'scripts/querybuilder';
import { useServices } from 'hooks/useServices';

const CustomerCallHistory = ({ customer }) => {
  const services = useServices();
  const defaultParams = $qb().limit(10);
  const [params, setParams] = useState(defaultParams);
  const [total, setTotal] = useState(0);
  const [data, setData] = useState([]);

  const loadCallHistory = useCallback(async () => {
    try {
      const obj = { ...params.build() };
      if (customer.id) {
        const resp = await services.crm.call.customer_call_history(
          customer.id,
          { page: obj.page, perPage: obj.limit }
        );
        if (!resp) return;
        const changeData = resp.data.map((v, i) => {
          let page = params.queryParams.page || 1;
          let limit = params.queryParams.limit;
          v.no = resp.pagination.total - ((page - 1) * limit + i);
          v.callTime = '-';
          //통화시간 계산
          if (v.callStartAt && v.callEndAt) {
            let s = new Date(v.callEndAt) - new Date(v.callStartAt);
            let ms = s % 1000;
            s = (s - ms) / 1000;
            let secs = s % 60;
            s = (s - secs) / 60;
            let mins = s % 60;
            let hrs = (s - mins) / 60;
            hrs = hrs < 10 ? `0${hrs}` : hrs.toString();
            mins = mins < 10 ? `0${mins}` : mins.toString();
            secs = secs < 10 ? `0${secs}` : secs.toString();
            v.callTime = hrs + ':' + mins + ':' + secs;
          }
          return v;
        });
        setData(changeData);
        setTotal(resp.pagination.total);
      }
    } catch (e) {
      console.log(e.description);
    }
  }, [customer.id]);

  const onParams = (p) => {
    setParams(p);
    init();
  };

  const init = useCallback(async () => {
    loadCallHistory();
  }, [loadCallHistory]);

  useEffect(() => {
    init();
  }, [init]);

  return (
    <div className="customer-treatments chart-page">
      <div className="wrap-data-table data-table">
        <div className="title">통화내역</div>
        <div className="card">
          <DataTableAlpha
            model={modelCallHistoryTab}
            total={total}
            data={data}
            params={params}
            onParams={onParams}
            bottomPositionInner
            hasLine
          />
        </div>
      </div>
    </div>
  );
};

CustomerCallHistory.propTypes = {
  customer: PropTypes.object,
};

export default observer(CustomerCallHistory);
