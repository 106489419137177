import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'filters';
import { observer } from 'mobx-react';
import styled from 'styled-components';

const Input = styled.input`
  width: 100%;
  height: 34px;
  padding: 8px 20px;
  border-color: #d7e3f1;
  font-size: 12px;
  min-height: 10px;
  border-radius: 4px;
  background-color: #f3f8ff;
  border: 1px solid #dee2ec;
`;

const ModalEventCreate = ({ options, close }) => {
  const [payload, setPayload] = useState({
    name: options.obj.name,
    eventType: options.obj.eventType,
    price: options.obj.price,
    mediaId:
      options.obj.mediaId === undefined
        ? options.mediaList[0].id
        : options.obj.mediaId,
  });

  return (
    <div className="modal-status-categories items-group">
      <div className="head flex-row flex-between items-start">
        <div className="title">이벤트</div>
        <i onClick={() => close()} className="zmdi zmdi-close" />
      </div>

      <div className="form-control m-t-16 required">
        <label>매체</label>
        <select
          name="mediaId"
          defaultValue={options.obj.mediaId}
          onChange={(e) => {
            setPayload({ ...payload, [e.target.name]: Number(e.target.value) });
          }}
        >
          {options.mediaList.map((v) => {
            return (
              <option key={v.id} value={v.id}>
                {v.name}
              </option>
            );
          })}
        </select>
      </div>
      <div className="form-control required">
        <label>이벤트명</label>
        <Input
          name="name"
          defaultValue={options.obj.name}
          onChange={(e) => {
            setPayload({ ...payload, [e.target.name]: e.target.value });
          }}
          placeholder="이벤트명 입력"
        />
      </div>
      <div className="form-control required">
        <label>이벤트 유형</label>
        <Input
          name="eventType"
          defaultValue={options.obj.eventType}
          onChange={(e) => {
            setPayload({ ...payload, [e.target.name]: e.target.value });
          }}
          placeholder="이벤트 유형 입력"
        />
      </div>
      <div className="form-control required">
        <label>이벤트 가격</label>
        <Input
          name="price"
          defaultValue={options.obj.price}
          onChange={(e) => {
            setPayload({ ...payload, [e.target.name]: e.target.value });
          }}
          placeholder="이벤트 가격 입력"
        />
      </div>
      <div className="buttons">
        <div className="flex-row">
          <button
            onClick={() => {
              close(undefined);
            }}
            className="btn btn-default"
          >
            {translate('CANCEL')}
          </button>
          <button
            onClick={() => {
              close(payload);
            }}
            className="btn btn-primary"
          >
            {translate('SAVE')}
          </button>
        </div>
      </div>
    </div>
  );
};

ModalEventCreate.propTypes = {
  options: PropTypes.object,
  close: PropTypes.func,
};

export default observer(ModalEventCreate);
