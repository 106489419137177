import React, { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import HideHidden from 'components/app/HideHidden';
import ModalVital from 'components/modals/ModalVital';
import DataTableAlpha from 'components/data-table/DataTableAlpha';
import helpers from 'hooks/helpers';
import modelNurseVital from 'models/nurse-vital';
import PropTypes from 'prop-types';
import { translate } from 'filters';
import { $qb } from 'scripts/querybuilder';
import { useEvent } from 'hooks/useEvent';
import { useModal } from 'hooks/useModal';
import { useServices } from 'hooks/useServices';
import { useToast } from 'hooks/useToast';

const NurseVital = ({ type }) => {
  const eventBus = useEvent();
  const modal = useModal();
  const services = useServices();
  const toast = useToast();
  const defaultParams = $qb().limit(100).orderBy('order asc');
  const [hideHidden, setHideHidden] = useState(false);
  const [data, setData] = useState([]);

  const callApi = useCallback(async () => {
    const resp = await services.crm.vital[type].all_v2(defaultParams.build());
    if (!resp) return;
    setData(resp.data);
  }, [services.crm.vital]);

  useEffect(() => {
    callApi();
  }, [callApi]);

  const onOpenModalVital = useCallback(
    (vital) => {
      modal
        .custom({
          component: ModalVital,
          options: { type, vital },
        })
        .then((submitted) => {
          if (submitted) {
            callApi();
          }
        });
    },
    [modal]
  );

  const onClickDelete = useCallback(
    async (payload) => {
      try {
        await services.crm.vital[type].delete_v2(payload.id);
        modal.confirm({
          type: 'SUCCESS',
          msg: '삭제하였습니다.',
        });
      } catch (e) {
        console.log(e.description);
        let errorMessage = e.description;
        modal.confirm({
          type: 'ERROR',
          msg: errorMessage,
        });
      }
      callApi();
    },
    [modal, services.crm.vital, callApi]
  );

  const onSaveItem = useCallback(
    async (payload) => {
      try {
        let params = {};
        params.data = [
          ...payload.map((v) => {
            return {
              id: v.id,
              name: v.name,
              unit: v.unit,
              order: v.order,
              visible: v.visible,
            };
          }),
        ];
        await services.crm.vital[type].update_v2(params);
        let message = translate(`${'update'.toUpperCase()}D`);
        modal
          .confirm({
            type: 'SUCCESS',
            msg: message,
          })
          .then(() => {
            callApi();
          });
      } catch (e) {
        console.log(e.description);
        let errorMessage = e.description;
        modal.confirm({
          type: 'ERROR',
          msg: errorMessage,
        });
      }
    },
    [modal, services, callApi]
  );

  const onAction = (obj) => {
    const { row, event } = obj;

    let vital = row;
    let model = obj.key;

    const btnLabel = event.target.innerText;
    if (btnLabel === '수정') {
      return onOpenModalVital(vital);
    }

    if (['미사용', '사용'].indexOf(btnLabel) !== -1) {
      const toggleObj = {
        visible: !row['visible'],
        id: row['id'],
      };
      const endpoint = services.crm.vital[type];
      return helpers.toggleVisibilityById({
        obj: toggleObj,
        endpoint,
        type,
        callback: callApi,
      });
    }

    if (model['title'] === 'ACTION' && event.target.dataset.type === 'delete') {
      modal
        .basic({
          body: `정말로 삭제하시겠습니까?`,
          buttons: [
            {
              text: 'CANCEL',
              class: 'btn-default',
            },
            {
              text: 'CONFIRM',
              class: 'btn-primary',
            },
          ],
        })
        .then((idx) => {
          idx === 1 && onClickDelete(vital);
        });
    }

    if (event.target.classList.contains('zmdi-long-arrow-up')) {
      changeArrowUpDown('up', row);
    } else if (event.target.classList.contains('zmdi-long-arrow-down')) {
      changeArrowUpDown('down', row);
    }
  };

  const changeOrderItem = useCallback(
    async (change, id, vital) => {
      let swapUpIdx = 0;
      let swapUpData = vital.filter((item, i) => {
        if (item.id === id) {
          swapUpIdx = i;
        }
        return item.id === id;
      })[0];

      let swapDownIdx = 0;
      if (change === 'up') {
        swapDownIdx = swapUpIdx - 1;
        if (
          swapDownIdx === -1 ||
          vital.filter((v) => v.visible).findIndex((v) => v.id === id) === 0
        ) {
          toast.error('가장 상위 순서입니다.');
          return;
        }
      } else if (change === 'down') {
        swapDownIdx = swapUpIdx + 1;
        if (
          vital.length - vital.filter((v) => !v.visible).length === 1 ||
          swapDownIdx === vital.length
        ) {
          toast.error('가장 하위 순서입니다.');
          return;
        }
      }

      const getSwapData = (swapDownIdx) => {
        let idx = change === 'up' ? swapDownIdx - 1 : swapDownIdx + 1;
        if (swapDownIdx === 0) {
          toast.error('가장 상위 순서입니다.');
          return;
        }
        if (swapDownIdx === vital.length - 1) {
          toast.error('가장 하위 순서입니다.');
          return;
        }

        if (vital[idx].visible) {
          return { idx, data: vital[idx] };
        } else {
          return getSwapData(idx);
        }
      };

      let swapDownData = vital[swapDownIdx];
      let item;
      if (!vital[swapDownIdx].visible) {
        item = getSwapData(swapDownIdx);
        if (item !== undefined) {
          swapDownData = item.data;
          swapDownIdx = item.idx;
        }
      } else {
        item = { idx: swapDownIdx, data: vital[swapDownIdx] };
      }

      return { item, swapUpData, swapDownData, swapUpIdx, swapDownIdx };
    },
    [toast]
  );

  const changeItem = useCallback(
    async (change, row) => {
      let vital = [...data];
      const { id } = row;
      //현재 인덱스를 가진 row와
      //바로 위 인덱스를 가진 row를 찾아
      //순서를 바꾸고
      //바꾼 순서대로 order를 다시 붙여준다

      //비활성화 된 항목을 제외하고 순서가 변경됨
      const changeOrder = await changeOrderItem(change, id, vital);
      if (changeOrder !== undefined) {
        vital[changeOrder.swapDownIdx] = changeOrder.swapUpData;
        vital[changeOrder.swapUpIdx] = changeOrder.swapDownData;
        let payload = vital.map((v, i) => {
          v['order'] = i + 1;
          return v;
        });
        onSaveItem(payload);
      }
    },
    [data, changeOrderItem, onSaveItem]
  );

  const changeArrowUpDown = useCallback(
    async (change, row) => {
      changeItem(change, row);
    },
    [changeItem]
  );

  useEffect(() => {
    eventBus.on('callApi', callApi);
    callApi();

    return () => eventBus.off('callApi');
  }, [eventBus, callApi]);

  return (
    <>
      <div className="right-box">
        <div className="route-top">
          <div className="title">
            {type === 'operations' ? '수술간호' : '간호'} 바이탈 항목
          </div>
          <div className="flex-row items-center">
            <HideHidden
              toggleHandler={setHideHidden}
              text="미사용항목 보지않기"
            />
            <button
              onClick={() => {
                onOpenModalVital();
              }}
              className="btn btn-primary btn-sm flex-wrap btn-add"
            >
              {translate('ADD')}
            </button>
          </div>
        </div>
        <DataTableAlpha
          model={modelNurseVital}
          data={hideHidden ? data.filter((row) => row.visible) : data}
          onAction={onAction}
          hasLine
          hasDeactivate
          bottomPositionInner
          hideBottom={true}
          hideHidden={hideHidden}
        />
      </div>
    </>
  );
};

NurseVital.propTypes = {
  type: PropTypes.string,
};

export default observer(NurseVital);
