import React, { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
//import { AppointmentBoardState } from 'store/appointmentBoardState';
import { AppointmentBoardStateContext } from './AppointmentBoards';

const TimeIndicatorLine = observer(({ pivotEl, timelineEl }) => {
  /**
   * @type {AppointmentBoardState }
   */
  const state = useContext(AppointmentBoardStateContext);
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    if (!pivotEl || !timelineEl) return;

    const ratio = state.minutesOffset / state.workMinutesSpan;
    const pivotSize =
      state.mode === 'vertical' ? pivotEl.offsetHeight : pivotEl.offsetWidth;
    const timelineSize =
      state.mode === 'vertical'
        ? timelineEl.offsetHeight
        : timelineEl.offsetWidth;
    const offsetTemp = pivotSize + ratio * (timelineSize - pivotSize);
    setOffset(offsetTemp ? offsetTemp : 0);
  }, [
    pivotEl,
    timelineEl,
    state.minutesOffset,
    state.workMinutesSpan,
    state.mode,
    state.cellSize,
  ]);

  if (!state.showTimeline) return null;

  return (
    <>
      <div
        className="time-indicator-line"
        style={{
          top: state.mode === 'vertical' ? offset : undefined,
          left: state.mode === 'horizontal' ? offset : undefined,
        }}
      />
    </>
  );
});

export default TimeIndicatorLine;
