import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import Checkbox from 'components/app/Checkbox';
import moment from 'moment';
import PropTypes from 'prop-types';
import { translate } from 'filters';
import MultiSelect from 'react-multi-select-component';
import styled from 'styled-components';
import { useAuth } from 'store/auth';
import { useClinicCheck } from 'store/clinicCheck';
import DatePicker from 'components/common/DatePicker';

const Input = styled.input`
  font-size: 11px;
  width: 140px;
  vertical-align: top;
  line-height: 1;
  padding: 0 10px;
  border: 1px solid #dee2ec;
  border-radius: 4px;
  height: 28px;

  &::placeholder {
    width: 98px !important;
    font-size: 11px !important;
    background-image: none;
  }
`;

const FilterDate = ({ modelName, keyArr, searchValue, setSearchValue }) => {
  // keyArr 중 시작일, 종료일 컬럼만 필터링
  let arr = keyArr.filter(
    (v) =>
      !v.nosearch &&
      (v.type === 'date' || v.selectType === 'date') &&
      (v.column.toLowerCase().includes('start') ||
        v.column.toLowerCase().includes('end'))
  );

  const placeholderText = (obj) =>
    obj.column.toLowerCase().includes('start') ? '시작날짜' : '종료날짜';
  return (
    <div>
      <DatePicker
        className="text-center"
        placeholderText={
          modelName === 'customer'
            ? placeholderText(arr[0])
            : translate(`${modelName.toUpperCase()}_SCHEDULED_AT`)
        }
        todayButton="오늘"
        dateFormat="yyyy-MM-dd"
        value={(searchValue || {})[arr[0].customParamFieldName] || null}
        onChange={(date) => {
          setSearchValue({
            ...searchValue,
            [arr[0].customParamFieldName]: date,
          });
        }}
        autocomplete="off"
      />
      {arr.length > 1 && (
        <>
          <span className="range-separator">-</span>
          <DatePicker
            className="text-center"
            placeholderText={
              modelName === 'customer'
                ? placeholderText(arr[1])
                : translate(`${modelName.toUpperCase()}_SCHEDULED_AT`)
            }
            todayButton="오늘"
            dateFormat="yyyy-MM-dd"
            value={(searchValue || {})[arr[1].customParamFieldName] || null}
            onChange={(date) => {
              setSearchValue({
                ...searchValue,
                [arr[1].customParamFieldName]: date,
              });
            }}
            autocomplete="off"
          />
        </>
      )}
    </div>
  );
};

const ListFilter = ({
  model,
  modelName,
  params,
  setParams,
  onParams,
  defaultParams,
  dateSetButton,
}) => {
  const [activeFilterArr, setActiveFilterArr] = useState({});
  const [categoryTarget] = useState(
    modelName === 'payment' ? 'treatmentItemCategoryId' : 'treatmentCategoryId'
  );
  const [searchValue, setSearchValue] = useState(
    modelName !== 'customer'
      ? {
          startAt: new Date().setDate(new Date().getDate() - 30),
          endAt: new Date(),
        }
      : {}
  );
  const [selectedDoctor, setSelectedDoctor] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const auth = useAuth();
  const clinicCheck = useClinicCheck();

  useEffect(() => {
    // 현재 활성화되어있는 검색필터
    // endAt은 startAt과 한세트로 제외
    setActiveFilterArr(
      model.keys.filter(
        (v) =>
          (v.column || v.selectOptions) &&
          !v.nosearch &&
          !(v.selectType === 'date' && v.column.toLowerCase().includes('end'))
      )
    );
  }, [model]);

  const deletedSearchValue = (key) => {
    const {
      // eslint-disable-next-line no-unused-vars
      [key.customParamFieldName]: deletedKey,
      ...otherKeys
    } = searchValue;
    setSearchValue({ ...otherKeys });
  };

  const onChangeSearchInput = (e, key) => {
    if (e.target.value === '') {
      deletedSearchValue(key);
    } else {
      setSearchValue({
        ...searchValue,
        [key.customParamFieldName]: e.target.value,
      });
    }
  };

  const onClickSelectDate = (v) => {
    const now = new Date();
    if (v === 'month') {
      onChangeDate(
        new Date(now.getFullYear(), now.getMonth(), 1),
        new Date(now.getFullYear(), now.getMonth() + 1, 0)
      );
    } else if (v === 'week') {
      const nowDayOfWeek = now.getDay();
      const nowDay = now.getDate();
      const nowMonth = now.getMonth();
      let nowYear = now.getYear();
      nowYear += nowYear < 2000 ? 1900 : 0;
      const weekStartDate = new Date(nowYear, nowMonth, nowDay - nowDayOfWeek);
      const weekEndDate = new Date(
        nowYear,
        nowMonth,
        nowDay + (6 - nowDayOfWeek)
      );
      onChangeDate(weekStartDate, weekEndDate);
    }
  };

  const onChangeDate = (startDate, endDate) => {
    setSearchValue({ ...searchValue, startAt: startDate, endAt: endDate });
  };

  const onClickSearch = () => {
    let searchParam = { ...searchValue };

    if (searchParam.startAt === null) {
      //Invalid date
      // eslint-disable-next-line no-unused-vars
      const { startAt: deletedKey, ...otherKeys } = searchParam;
      searchParam = otherKeys;
    }

    if (searchParam.endAt === null) {
      //Invalid date
      // eslint-disable-next-line no-unused-vars
      const { endAt: deletedKey, ...otherKeys } = searchParam;
      searchParam = otherKeys;
    }

    if (searchParam.startAt !== undefined) {
      searchParam.startAt = moment(searchValue.startAt).format('YYYY-MM-DD');
    }
    if (searchParam.endAt !== undefined) {
      searchParam.endAt = moment(searchValue.endAt).format('YYYY-MM-DD');
    }

    if (searchParam.lastVisitStartAt !== undefined) {
      searchParam.lastVisitStartAt = moment(
        searchValue.lastVisitStartAt
      ).format('YYYY-MM-DD');
    }
    if (searchParam.lastVisitEndAt !== undefined) {
      searchParam.lastVisitEndAt = moment(searchValue.lastVisitEndAt).format(
        'YYYY-MM-DD'
      );
    }

    if (
      searchParam.phoneNumber !== undefined &&
      searchParam.phoneNumber.length === 4
    ) {
      searchParam.phoneNumberLast = searchParam.phoneNumber;
      delete searchParam.phoneNumber;
    }

    if (searchParam.status !== undefined && searchParam.status === 'complete') {
      searchParam.status =
        'payment_done,treatment_done,consulting_done,surgery_done';
    }
    if (modelName === 'operations') {
      searchParam['scheduledStartAt'] = searchParam.startAt;
      searchParam['scheduledEndAt'] = searchParam.endAt;
      delete searchParam.startAt;
      delete searchParam.endAt;
    }

    if (selectedDoctor && selectedDoctor.length > 0) {
      let arr = selectedDoctor.map((v) => {
        return v.value;
      });
      searchParam.doctorId = arr.join(',');
      // 전체선택시 request 파라미터 제거
      if (
        model.keys.find((v) => v.title === '_DOCTORS').selectOptions.length ===
        selectedDoctor.length
      ) {
        delete searchParam.doctorId;
      }
    }

    if (selectedCategory && selectedCategory.length > 0) {
      let arr = selectedCategory.map((v) => {
        return v.value;
      });
      searchParam.treatmentCategoryId = arr.join(',');
      // 전체선택시 request 파라미터 제거
      if (
        model.keys.find((v) => v.title === 'SURGERY_CATEGORIES').selectOptions
          .length === selectedCategory.length
      ) {
        delete searchParam.treatmentCategoryId;
      }
    }

    if (selectedItems && selectedItems.length > 0) {
      let arr = selectedItems.map((v) => {
        return v.value;
      });
      searchParam.treatmentItemId = arr.join(',');
      // 전체선택시 request 파라미터 제거
      if (
        model.keys.find((v) => v.title === 'SURGERY_ITEM_NAMES').selectOptions
          .length === selectedItems.length
      ) {
        delete searchParam.treatmentItemId;
      }
    }

    params.queryParams = {
      limit: params.queryParams.limit,
      orderBy: params.queryParams.orderBy,
      ...searchParam,
    };

    params.queryParams = {
      limit: params.queryParams.limit,
      orderBy: params.queryParams.orderBy,
      offset: 0,
      page: 1,
      ...searchParam,
    };
    onParams(params);
  };

  return (
    <>
      {dateSetButton && (
        <div className="m-b-8">
          <button
            className="search-item btn btn-normal _small m-r-8"
            onClick={() => onClickSelectDate('week')}
          >
            이번 주
          </button>
          <button
            className="search-item btn btn-normal _small"
            onClick={() => onClickSelectDate('month')}
          >
            이번 달
          </button>
        </div>
      )}
      <div className="search-filter search-filter-table data-table flex-fill m-b-12">
        {model.keys.map((key, idx) =>
          (key.column || key.selectOptions) && !key.nosearch ? (
            key.selectOptions ? (
              <React.Fragment key={idx}>
                <div>{translate(key.title)}</div>
                <div>
                  {key.selectType === 'checkbox' && (
                    <React.Fragment>
                      <Checkbox
                        className="checkbox-blue"
                        checked={
                          searchValue[key.customParamFieldName] === undefined
                        }
                        clickHandler={() => deletedSearchValue(key)}
                      />
                      <span className="checkbox-label m-l-6 m-r-10">전체</span>
                      {key.selectOptions.map((o, i) => (
                        <React.Fragment key={i}>
                          <Checkbox
                            className="checkbox-blue"
                            checked={
                              o.$$value ===
                              searchValue[key.customParamFieldName]
                            }
                            clickHandler={() =>
                              setSearchValue({
                                ...searchValue,
                                [key.customParamFieldName]: o.$$value,
                              })
                            }
                          />
                          <span className="checkbox-label m-l-6 m-r-10">
                            {translate(o.$$label)}
                          </span>
                        </React.Fragment>
                      ))}
                    </React.Fragment>
                  )}
                  {key.selectType === 'multiSelect' && (
                    <MultiSelect
                      className="multi-select"
                      options={
                        key.customParamFieldName === 'treatmentItemId'
                          ? key.selectOptions
                              .map((v) => {
                                return {
                                  label: v.$$label,
                                  value: v.$$value,
                                  categoryId: v.categoryId,
                                };
                              })
                              .filter((value) =>
                                selectedCategory
                                  .map((e) => {
                                    return e.value;
                                  })
                                  .includes(value.categoryId)
                              )
                          : key.selectOptions.map((v) => {
                              return { label: v.$$label, value: v.$$value };
                            })
                      }
                      value={
                        key.customParamFieldName === 'treatmentCategoryId'
                          ? selectedCategory
                          : key.customParamFieldName === 'treatmentItemId'
                          ? selectedItems
                          : key.customParamFieldName === 'doctorId'
                          ? selectedDoctor
                          : null
                      }
                      onChange={
                        key.customParamFieldName === 'treatmentCategoryId'
                          ? setSelectedCategory
                          : key.customParamFieldName === 'treatmentItemId'
                          ? setSelectedItems
                          : key.customParamFieldName === 'doctorId'
                          ? setSelectedDoctor
                          : null
                      }
                      labelledBy={'Select'}
                      disableSearch
                      selectAllLabel={'전체선택'}
                      overrideStrings={{
                        selectSomeItems:
                          key.customParamFieldName === 'treatmentCategoryId'
                            ? '시/수술 카테고리'
                            : key.customParamFieldName === 'treatmentItemId'
                            ? '시/수술명'
                            : key.customParamFieldName === 'doctorId'
                            ? '의사명'
                            : 'null',
                        allItemsAreSelected: '전체선택',
                      }}
                    />
                  )}
                  {!key.selectType && (
                    <select
                      key={idx}
                      disabled={
                        clinicCheck.isClinicStantopCounselor &&
                        (modelName === 'customer' ||
                          modelName === 'appointment') &&
                        key.title.includes('COUNSELORS')
                          ? true
                          : false
                      }
                      className={
                        !searchValue[key.customParamFieldName] ? 'null' : ''
                      }
                      value={
                        clinicCheck.isClinicStantopCounselor &&
                        (modelName === 'customer' ||
                          modelName === 'appointment') &&
                        key.title.includes('COUNSELORS')
                          ? auth.me.id
                          : searchValue[key.customParamFieldName]
                          ? searchValue[key.customParamFieldName]
                          : -1
                      }
                      onChange={(e) => {
                        if (
                          key.customParamFieldName === 'treatmentCategoryId'
                        ) {
                          delete searchValue.treatmentItemId;
                          setSearchValue({ ...searchValue });
                        }
                        parseInt(e.target.value) === -1
                          ? deletedSearchValue(key)
                          : setSearchValue({
                              ...searchValue,
                              [key.customParamFieldName]: e.target.value,
                            });
                      }}
                    >
                      {/* 시/수술은 시/수술 카테고리 선택하면 보여짐 */}
                      <option key={-1} value={-1}>
                        {translate(key.title)}
                      </option>
                      {key.title === 'SURGERY_ITEM_NAMES'
                        ? searchValue[categoryTarget]
                          ? key.selectOptions
                              .filter((v) => {
                                return (
                                  v.category_id ==
                                    searchValue[categoryTarget] ||
                                  v.categoryId == searchValue[categoryTarget]
                                );
                              })
                              .map((o, idx) => {
                                return (
                                  <option key={idx} value={o.$$value}>
                                    {translate(o.$$label)}
                                  </option>
                                );
                              })
                          : null
                        : key.selectOptions.map((o, idx) => (
                            <option key={idx} value={o.$$value}>
                              {translate(o.$$label)}
                            </option>
                          ))}
                    </select>
                  )}
                </div>
              </React.Fragment>
            ) : key.selectType === 'date' ? (
              key.column.toLowerCase().includes('start') && (
                <React.Fragment key={idx}>
                  <div>
                    {modelName === 'customer'
                      ? '최종방문일'
                      : translate(`${modelName.toUpperCase()}_SCHEDULED_AT`)}
                  </div>
                  <FilterDate
                    modelName={modelName}
                    keyArr={model.keys}
                    searchValue={searchValue}
                    setSearchValue={setSearchValue}
                  />
                </React.Fragment>
              )
            ) : (
              <React.Fragment key={idx}>
                <div>{translate(key.title)}</div>
                <div>
                  <Input
                    placeholder={translate(key.title)}
                    key={idx}
                    value={searchValue[key.customParamFieldName] || ''}
                    onChange={(e) => onChangeSearchInput(e, key)}
                    onKeyPress={(e) =>
                      e.key === 'Enter' ? onClickSearch() : null
                    }
                  />
                </div>
              </React.Fragment>
            )
          ) : null
        )}

        <div
          className="search-btns"
          style={{ gridColumn: `span ${8 - (activeFilterArr.length % 4) * 2}` }}
        >
          <button
            className="btn btn-cancel _small"
            onClick={() => {
              setSearchValue({
                startAt: new Date().setDate(new Date().getDate() - 30),
                endAt: new Date(),
              });
              setParams(
                defaultParams
                  .customParam(
                    'startAt',
                    moment(
                      new Date().setDate(new Date().getDate() - 30)
                    ).format('YYYY-MM-DD')
                  )
                  .customParam('endAt', moment(new Date()).format('YYYY-MM-DD'))
              );
            }}
          >
            초기화
          </button>
          <button className="btn btn-basic _small" onClick={onClickSearch}>
            조회
          </button>
        </div>
      </div>
    </>
  );
};

FilterDate.propTypes = {
  modelName: PropTypes.string,
  keyArr: PropTypes.array,
  searchValue: PropTypes.object,
  setSearchValue: PropTypes.func,
};

ListFilter.propTypes = {
  model: PropTypes.object,
  modelName: PropTypes.string,
  params: PropTypes.object,
  setParams: PropTypes.func,
  onParams: PropTypes.func,
  defaultParams: PropTypes.object,
  dateSetButton: PropTypes.bool,
};

export default observer(ListFilter);
