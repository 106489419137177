export default {
  keys: [
    {
      title: '카테고리',
      column: 'categoryName',
      filter: (o) => {
        if (o.category === undefined) {
          return `<div style="position: relative; width: 125px; text-align: center;">
                <div class="flex-wrap"
                    style="max-width: 125px; white-space: break-spaces; display: inline-block;">${
                      o.categoryName || ''
                    }</div></div>`;
        } else {
          return `<div style="position: relative; width: 125px; text-align: center;">
                <div class="flex-wrap"
                    style="max-width: 125px; white-space: break-spaces; display: inline-block;">${
                      o.category.name || ''
                    }</div></div>`;
        }
      },
    },
    {
      title: '시/수술명',
      column: 'name',
      filter: (o) => {
        return `<div style="position: relative; width: 240px; text-align: center;">
            <div class="flex-wrap"
                style="max-width: 240px; white-space: break-spaces; display: inline-block;">${
                  o['name'] || ''
                }${
          o['isFree'] ? '<div class="flex-wrap ico-service"></div>' : ''
        }</div>
            </div>`;
      },
    },
    {
      title: '총 시/수술 횟수',
      column: 'useCount',
      filter: (o) => `${o.treatmentCount}회`,
    },
    {
      title: '진행한 회차',
      column: 'useCount',
      filter: (o) => `${o.useCount}회`,
    },
    {
      title: '남은 회차',
      column: '',
      filter: (o) => `${o.remainingCount}회`,
    },
  ],
};
