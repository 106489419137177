export const CentrexCallStatus = {
  0: 'ringing',
  1: 'calling',
  2: 'ended',
  3: 'missed',
  ringing: 0,
  calling: 1,
  ended: 2,
  missed: 3,
};
