import { useEffect } from 'react';
import { useRemoteConfig } from 'reactfire';
import 'firebase/remote-config';

export function useAutoRefresh(deps = []) {
  const remoteConfig = useRemoteConfig();

  useEffect(() => {
    remoteConfig.settings = {
      minimumFetchIntervalMillis: 3600000, //1시간
    };
  }, []);

  async function diffVersionAndReload() {
    try {
      const web_version = window.localStorage.getItem('web_min_version');
      //새 버전 값을 받아옴
      await remoteConfig.fetchAndActivate();
      // web_latest_version : 최신 버전의 정보
      // web_min_version : 강제 업데이트를 진행하는 기준
      // latest version 미만, min version 이상인 경우 강제 업데이트 대상이 아니다.
      // 현재 로컬 스토리지의 버전 값이 latest version 보다 작거나 같고,
      // min version 보다 작은 경우 페이지 새로고침 및 강제 업데이트 버전을 로컬스토리지에 저장한다.
      const latest = remoteConfig
        .getValue(process.env.REACT_APP_FIREBASE_LATEST_VERSION_KEY)
        .asString();
      const min = remoteConfig
        .getValue(process.env.REACT_APP_FIREBASE_MIN_VERSION_KEY)
        .asString();

      if (web_version === null || latest < web_version || min > web_version) {
        console.log(
          `App version updated. min: ${min} latest: ${latest} current: ${web_version} Reloading...`
        );
        window.location.reload();
        window.localStorage.setItem('web_min_version', min);
      }
    } catch (e) {
      console.error(e);
    }
  }

  useEffect(() => {
    diffVersionAndReload();
  }, deps);
}
