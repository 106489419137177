import * as moment from 'moment';

export default {
  keys: [
    {
      title: 'STATUS',
      noorder: true,
      filter: () => `<div class="text-center">
            <button class="btn btn-sm btn-primary">수정</button>
            <button class="btn btn-sm btn-danger">삭제</button>
        </div>`,
    },
    {
      title: '일자',
      column: 'scheduledAt',
      filter: (o) => {
        return `<div>
                <div>${moment(o.scheduledAt).format('YYYY-MM-DD')}</div>
            </div>`;
      },
    },
    {
      title: '의사',
      column: 'doctor__name',
      noorder: true,
    },
    {
      title: '간호사',
      column: 'nurse__name',
      noorder: true,
    },
    {
      title: '시/수술 카테고리',
      noorder: true,
      filter: (o) => `<div>
        ${o.items
          .map(
            (item) => `
        <div>${item.categoryName || ''}</div> 
        `
          )
          .join('\n')}
        </div>`,
    },
    {
      title: '시/수술명',
      noorder: true,
      filter: (o) => `<div>
        ${o.items
          .map(
            (item) => `
        <div>${item.name || ''}</div> 
        `
          )
          .join('\n')}
        </div>`,
    },
    {
      title: '메모',
      column: 'memo',
      noorder: true,
      type: 'memo',
      nosearch: true,
    },
  ],
};
