import React from 'react';
import MessageApp from './Layouts/MessageApp';
import ModalMessageBoilerplateSelector from 'components/modals/codes/message-codes/ModalMessageBoilerplateSelect';
import SelectCallerNumber from './Layouts/SelectCallerNumber';
import ViewAutoNotificationBottom from './Layouts/ViewAutoNotificationBottom';
import ViewAutoSurgeryPeriodBottom from './Layouts/ViewAutoSurgeryPeriodBottom';
import PropTypes from 'prop-types';

const ScreenAutoNotification = ({
  onChange,
  obj,
  setObj,
  callerNumberList,
  autoMessageType,
}) => {
  return (
    <div className={`message-screen-auto-notification`}>
      <MessageApp
        onChange={onChange}
        obj={obj}
        setObj={setObj}
        modalBoilerplateSelector={ModalMessageBoilerplateSelector}
        hideBabitalkLink
        titleComponent={
          <SelectCallerNumber
            obj={obj}
            setObj={setObj}
            callerNumberList={callerNumberList}
          />
        }
        bottomComponent={
          autoMessageType === 'surgery-period' ? (
            <ViewAutoSurgeryPeriodBottom obj={obj} setObj={setObj} />
          ) : (
            <ViewAutoNotificationBottom obj={obj} setObj={setObj} />
          )
        }
      />
    </div>
  );
};

ScreenAutoNotification.propTypes = {
  onChange: PropTypes.func,
  obj: PropTypes.object,
  setObj: PropTypes.func,
  callerNumberList: PropTypes.array,
  autoMessageType: PropTypes.string,
};

export default ScreenAutoNotification;
