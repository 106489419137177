import { currency, translate } from 'filters';

const c = (val) => {
  return `₩${currency(val)}`;
};

export default {
  keys: [
    {
      title: 'DATE',
      column: 'updatedAt',
      type: 'date',
    },
    {
      title: 'DEPOSIT_TYPE',
      column: 'reason',
      filter: (obj) => {
        const keyname =
          obj.reason && obj.reason !== ''
            ? `DEPOSIT_DETAILS_${obj.reason.toUpperCase()}`
            : '';
        return translate(keyname);
      },
    },
    {
      title: 'DEPOSIT_CASH',
      column: 'depositAmount',
      filter: (obj) => {
        const plus = c(obj.depositAmount) > 0 ? '+' : '';
        return `${plus} ${c(obj.depositAmount)}`;
      },
    },
    {
      title: 'POINT',
      column: 'pointAmount',
      filter: (obj) => {
        const plus = c(obj.pointAmount) > 0 ? '+' : '';
        return `${plus} ${c(obj.pointAmount)}`;
      },
    },
    {
      title: 'MANAGER',
      column: 'user__name',
    },
    {
      title: 'MEMO',
      column: 'memo',
    },
  ],
};
