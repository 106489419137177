export default {
  keys: [
    {
      title: 'ACTION',
      noorder: true,
      filter: () =>
        `<button class="btn btn-sm btn-primary m-r-8 btn-select">선택</button>
            <button class="btn btn-sm btn-danger btn-delete">삭제</button>`,
    },
    {
      title: '권한 명칭',
      noorder: true,
      filter: (o) => o.user.authorityGroup.name || '',
    },
  ],
};
