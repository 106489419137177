import React from 'react';
import { observer } from 'mobx-react';
import models from 'models';
import SmsTableList from 'hooks/SmsTableList';
import { useServices } from 'hooks/useServices';

const SmsSendLists = () => {
  const services = useServices();
  return (
    <div className="list">
      <SmsTableList
        modelName="마케팅 문자 발송 내역"
        api={services.crm.balance.sms_notifications.all}
        model={models.crm.marketingSms}
        queryParam={[{ sms_type: 'event' }]}
      />
      <SmsTableList
        modelName="일반 문자 발송 내역"
        api={services.crm.balance.sms_notifications.all}
        model={models.crm.normalSms}
        queryParam={[{ sms_type: 'normal' }]}
      />
    </div>
  );
};

export default observer(SmsSendLists);
