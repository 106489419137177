import React, { useState } from 'react';
import { observer } from 'mobx-react';
import MessageDetail from 'components/app/MessageScreen/Layouts/MessageDetail';
import PropTypes from 'prop-types';

const ModalMessageDetail = ({ options, close }) => {
  const initObj = Object.freeze({
    images: [],
    imageFiles: [],
    // id: null,
    category: '',
    title: '',
    contents: '',
    // visible: true,
    // createdId: null,
    // organizationId: null,
  });

  const [obj, setObj] = useState({
    ...initObj,
    ...(options.boilerplateMessage && options.boilerplateMessage),
  });

  return (
    <div className="modal-message-screen boilerplate ">
      <div className="head flex-row flex-between items-start">
        <div className="title">문자 내용</div>
        <i onClick={() => close()} className="zmdi zmdi-close" />
      </div>
      <div className="body">
        <div className="row">
          <div className={`message-detail`}>
            <MessageDetail obj={obj} setObj={setObj} />
          </div>
        </div>
      </div>
    </div>
  );
};

ModalMessageDetail.propTypes = {
  options: PropTypes.object,
  close: PropTypes.func,
};

export default observer(ModalMessageDetail);
