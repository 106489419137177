import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { translate } from 'filters';

const ModalChangeCounselors = ({ options, close }) => {
  const onClickChange = (e, v) => {
    close(v);
    return;
  };

  return (
    <div className="modal-change-counselors">
      <div className="head flex-row flex-between items-start">
        <div className="title">담당자 변경하기</div>
        <i onClick={() => close()} className="zmdi zmdi-close" />
      </div>
      <div className="body">
        <div className="m-t-8">
          <table className="data-table">
            <colgroup>
              <col width="200px" />
              <col width="280px" />
            </colgroup>
            <thead>
              <tr>
                <th>설정</th>
                <th>이름</th>
              </tr>
            </thead>
            <tbody>
              {options.consultingManager.map((v, i) => (
                <tr key={i}>
                  <td>
                    <button
                      className="btn btn-sm btn-blue"
                      onClick={(e) => onClickChange(e, v)}
                    >
                      변경하기
                    </button>
                  </td>
                  <td>{v.user.name}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="buttons">
          <div className="flex-row">
            <button onClick={() => close()} className="btn btn-default">
              {translate('CANCEL')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

ModalChangeCounselors.propTypes = {
  options: PropTypes.object,
  close: PropTypes.func,
};

export default observer(ModalChangeCounselors);
