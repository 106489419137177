import React, { useCallback, useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import DataTableAlpha from 'components/data-table/DataTableAlpha';
import QuillTextField from 'components/quill/QuillTextField2';
import { toJS } from 'mobx';
import moment from 'moment';
import PropTypes from 'prop-types';
import ModalSurgery from 'components/modals/ModalSurgery';
import useCustomerChart from 'hooks/useCustomerChart';
import modelOperationNurseTab from 'models/operation-nurse-tab';
import { copy, translate } from 'filters';
import { $qb } from 'scripts/querybuilder';
import { useAuth } from 'store/auth';
import { useEvent } from 'hooks/useEvent';
import { useModal } from 'hooks/useModal';
import { useServices } from 'hooks/useServices';
import { useToast } from 'hooks/useToast';
import styled from 'styled-components';
import DatePicker from 'components/common/DatePicker';

const Input = styled.input`
  background-color: #fff;
  min-height: 10px;
  border-radius: 4px;
  border: 1px solid #dee2ec;
  color: #000;
  font-size: 14px;
  height: 34px;
`;

const VitalAmount = styled(Input)`
  text-align: center;
  padding: 10px;
  width: 60px;
  height: 30px;
  margin-right: 10px;
`;

const AmountInput = styled(Input)`
  width: 90px;
  margin-right: 10px;
  padding: 16px 20px;
`;

const MemoInput = styled(Input)`
  width: 500px;
  margin-right: 10px;
  font-size: 12px;
  padding: 16px 20px;
`;

const CustomerNurseCharts = ({ customer, closeFlag }) => {
  const auth = useAuth();
  const eventBus = useEvent();
  const modal = useModal();
  const services = useServices();
  const toast = useToast();

  //간호차트 data api
  const tabName = 'nurse';
  const defaultParams = $qb()
    .limit(10)
    .customParam('customerId', customer.id)
    .orderBy('scheduledAt desc');
  const [params, setParams] = useState(defaultParams);
  const [loadingBtnDisabledFlag, setLoadingBtnDisabledFlag] = useState(false);
  const [memoBoilerplateList, setMemoBoilerplateList] = useState([]);
  const [chartList, setChartList] = useState([]);
  const [total, setTotal] = useState(0);
  const [obj, setObj] = useState({});
  const [componentFlag, setComponentFlag] = useState(null);
  const [doctors, setDoctors] = useState([]);
  const [nurses, setNurses] = useState([]);
  const [treatmentItemCategories, setTreatmentItemCategories] = useState([]);
  const [itemsList, setItemsList] = useState([{}]); //관심 시/수술 카테고리 배열
  const [vitals, setVitals] = useState([]);
  const [injectItems, setInjectItems] = useState([]);
  const [itemList, setItemList] = useState([
    { name: '', unit: '', amount: '' },
  ]); // 투약 항목 배열
  const [memoList, setMemoList] = useState([]); // 메모 배열

  const amountItems = [
    'amount',
    'amount1',
    'amount2',
    'amount3',
    'amount4',
    'amount5',
    'amount6',
    'amount7',
    'amount8',
    'amount9',
  ];
  const nurseChartCallApi = useCallback(async () => {
    //간호차트 get api 호출
    try {
      let resp = await services.crm.nurse.cares.all_v2(defaultParams.build());
      if (!resp) return;
      setChartList(resp.data);
      setTotal(resp.pagination.total);
    } catch (e) {
      console.log(e.description);
    }
  }, []);

  useEffect(() => {
    nurseChartCallApi();
  }, [nurseChartCallApi]);

  const onParams = (p) => {
    setParams(p);
    nurseChartCallApi();
  };

  const doctorCallApi = useCallback(async () => {
    let params = { duty: '의사', userStatus: 'active', limit: 300 };
    const resp = await services.crm.user.duty(params);
    if (!resp) return;

    setDoctors(resp.data);
  }, []);

  useEffect(() => {
    doctorCallApi();
  }, [doctorCallApi]);

  const memoBoilerplateCallApi = useCallback(async () => {
    try {
      const memoItems = await useCustomerChart.useGetMemoBoilerplate(tabName);
      setMemoBoilerplateList(memoItems);
    } catch (e) {
      console.log(e.description);
    }
  }, []);

  useEffect(() => {
    memoBoilerplateCallApi();
  }, [memoBoilerplateCallApi]);

  const onChangeValue = (column, value) => {
    setObj((obj) => {
      return { ...obj, [column]: value };
    });
  };

  const onChangeDate = (idx, value) => {
    memoList[idx].scheduledAt = value;
    setMemoList([...memoList]);
    setObj({ ...obj, memos: memoList });
  };

  const nurseCallApi = useCallback(async () => {
    let params = {
      duty: '간호사,간호조무사',
      userStatus: 'active',
      limit: 300,
    };
    const resp = await services.crm.user.duty(params);
    if (!resp) return;

    setNurses(resp.data);
  }, []);

  useEffect(() => {
    nurseCallApi();
  }, [nurseCallApi]);

  const vitalCallApi = useCallback(async () => {
    let params = $qb().limit(100).orderBy('order asc');
    params.customParam('visible', '1');
    const resp = await services.crm.vital.cares.all_v2(params.build());
    if (!resp) return;
    resp.data.map((v) => {
      v.careVitalItemId = v.id;
      return v;
    });
    setVitals(resp.data);
    let obj = [];
    obj['vitalItems'] = resp.data;
    setObj(obj);
  }, []);

  useEffect(() => {
    vitalCallApi();
  }, [vitalCallApi]);

  const injectItemCallApi = useCallback(async () => {
    let params = $qb().limit(100).orderBy('order asc');
    params.customParam('visible', '1');
    const resp = await services.crm.injectitem.cares.all_v2(params.build());
    if (!resp) return;
    resp.data.map((v) => {
      v.careInjectItemId = v.id;
      return v;
    });
    setInjectItems(resp.data);
  }, []);

  useEffect(() => {
    injectItemCallApi();
  }, [injectItemCallApi]);

  const treatmentItemCategoriesCallApi = useCallback(async () => {
    try {
      let params = { limit: 10000, visible: true };
      const resp = await services.crm.treatment.categories.items_categories_v2(
        params
      );
      if (!resp) return;
      setTreatmentItemCategories(resp.data);
    } catch (e) {
      console.log(e.description);
    }
  }, []);

  useEffect(() => {
    treatmentItemCategoriesCallApi();
  }, [treatmentItemCategoriesCallApi]);

  const onAction = (obj) => {
    const event = obj.event;
    const row = toJS(obj.row);
    const classList = event.target.classList;
    const model = obj.key;

    if (
      (obj.eventType === 'doubleClick' && model?.title !== 'STATUS') ||
      classList.contains('btn-primary')
    ) {
      //수정

      let vitalTemp = vitals.map((v) => {
        let result = {};
        result = obj.row.vitalItems.find(
          (value) => value.careVitalItemId === v.careVitalItemId
        );
        v.amount = result ? result.amount : '';
        v.amount1 = result ? result.amount1 : '';
        v.amount2 = result ? result.amount2 : '';
        v.amount3 = result ? result.amount3 : '';
        v.amount4 = result ? result.amount4 : '';
        v.amount5 = result ? result.amount5 : '';
        v.amount6 = result ? result.amount6 : '';
        v.amount7 = result ? result.amount7 : '';
        v.amount8 = result ? result.amount8 : '';
        v.amount9 = result ? result.amount9 : '';

        return v;
      });

      //obj.row.vitalItems = vitalTemp
      setObj(copy(row));
      setVitals(vitalTemp);
      setMemoList(copy(row.memos));
      setItemList(copy(row.injectItems));
      setItemsList(copy(row).items || [{}]);

      setComponentFlag('nurseChart');
      //window.scrollTo(0, 0);
      return;
    }

    if (classList.contains('btn-danger')) {
      onOpenDelete(row);
      return;
    }
  };

  const onClickCreateNurseChart = () => {
    let doctor = customer && customer.doctor ? customer.doctor : null;
    setObj({ ...obj, doctor, scheduledAt: moment().format() });
    setComponentFlag('nurseChart');
  };

  const actionInit = useCallback(() => {
    setComponentFlag(null);
    setObj({});
    setItemsList([{}]);
    setItemList([{ name: '', unit: '', amount: '' }]);
    setMemoList([]);
    vitalCallApi();
  }, [vitalCallApi]);

  const onDelete = useCallback(
    async (id) => {
      try {
        await services.crm.nurse.cares.delete_v2(id);

        modal
          .confirm({
            type: 'SUCCESS',
            msg: '삭제되었습니다.',
          })
          .then(() => {
            nurseChartCallApi();
            eventBus.emit('chartCountCallApi');
            actionInit();
          });
      } catch (e) {
        console.log(e.description);
        modal.confirm({
          type: 'ERROR',
          msg: e.description,
        });
      }
    },
    [actionInit, nurseChartCallApi]
  );

  const onOpenDelete = useCallback(
    async (chart) => {
      modal
        .basic({
          body: '정말로 삭제하시겠습니까?',
          buttons: [
            {
              text: 'CANCEL',
              class: 'btn-default',
            },
            {
              text: 'CONFIRM',
              class: 'btn-primary',
            },
          ],
        })
        .then((idx) => {
          if (idx === 1) {
            onDelete(chart.id);
          }
        });
    },
    [onDelete]
  );

  // const onChange = (e) => {
  //   if (!/^[0-9]*$/.test(e.target.value)) {
  //     return;
  //   }
  //   obj[e.target.name] = e.target.value;
  //   setObj({ ...obj });
  // };

  const addInjectItem = () => {
    let arr = { name: '', unit: '', amount: '' };
    setItemList([...itemList, arr]);
  };

  const addTreatmentItem = () => {
    if (treatmentItemCategories[0] === undefined) {
      toast.error('수납코드 설정에서 시/수술을 추가해주세요.');
      return;
    }
    let list = [...itemsList];
    list.push({});
    setItemsList([...list]);
  };

  const addMemo = () => {
    let arr = {
      memo: '',
      scheduledAt: moment(new Date()).format(),
      nurseCareId: chartList.id,
    };
    setMemoList([...memoList, arr]);
  };

  const validator = () => {
    // if(obj.startHour === undefined || obj.startMinute === null) {
    //   toast.error('수술시작시간을 선택하세요.')
    //   setLoadingBtnDisabledFlag(false)
    //   // setSaveAppointment(false)
    //   return false
    // }
    return true;
  };

  const onClickSave = useCallback(async () => {
    setLoadingBtnDisabledFlag(true);

    if (validator()) {
      let endpoint = !obj.id ? 'create' : 'update';

      //필요한 request payload
      //customerId, nurse: { id }, doctor: { id },
      // items [{
      //   id, categoryId, categoryName, name, treatmentItemId,
      // }],
      // injectItems [{
      //   id, amount, careInjectItemId, name, unit
      // }],
      // vitalItems [{
      //   id, careVitalItemId, name, unit, order,
      //   amount, amount1, ... amount9
      // }]
      //memos [{ id, memo, scheduledAt }],
      //memo, scheduledAt,

      let payload = { ...obj };
      payload.customerId = (customer || {}).id;

      //doctor, nurse 객체 내에서 id값만 전달
      if (payload.doctor) {
        payload.doctor = {
          id: payload.doctor.id,
        };
      }

      if (payload.nurse) {
        payload.nurse = {
          id: payload.nurse.id,
        };
      }

      //memos 배열 객체 형식에 맞춰 전달
      if (payload.memos && payload.memos.length > 0) {
        let changeMemos = payload.memos.map((v) => {
          const { id, memo, scheduledAt } = v;

          let returnObj = { memo, scheduledAt };
          if (endpoint === 'update') {
            returnObj.id = id;
          }
          return returnObj;
        });

        payload.memos = changeMemos;
      }

      //items 배열 객체 형식에 맞춰 전달
      if (payload.items && payload.items.length > 0) {
        let changeItems = payload.items.map((v) => {
          const { id, categoryId, categoryName, name, treatmentItemId } = v;

          let returnObj = { categoryId, categoryName, name, treatmentItemId };
          if (endpoint === 'update') {
            returnObj.id = id;
          }
          return returnObj;
        });

        payload.items = changeItems;
      }

      //injectItems 배열 객체 형식에 맞춰 전달
      if (payload.injectItems && payload.injectItems.length > 0) {
        let changeInjectItems = payload.injectItems.map((v) => {
          const { id, amount, careInjectItemId, name, unit } = v;

          let returnObj = { amount, careInjectItemId, name, unit };
          if (endpoint === 'update') {
            returnObj.id = id;
          }
          return returnObj;
        });

        payload.injectItems = changeInjectItems;
      }

      //vitalItems 배열 객체 형식에 맞춰 전달
      if (payload.vitalItems && payload.vitalItems.length > 0) {
        let changeVitalItems = payload.vitalItems.map((v) => {
          const {
            id,
            amount,
            amount1,
            amount2,
            amount3,
            amount4,
            amount5,
            amount6,
            amount7,
            amount8,
            amount9,
            careVitalItemId,
            name,
            unit,
          } = v;

          let returnObj = {
            amount,
            amount1,
            amount2,
            amount3,
            amount4,
            amount5,
            amount6,
            amount7,
            amount8,
            amount9,
            careVitalItemId,
            name,
            unit,
          };
          if (endpoint === 'update') {
            returnObj.id = id;
          }
          return returnObj;
        });

        payload.vitalItems = changeVitalItems;
      }

      try {
        const resp =
          endpoint === 'create'
            ? await services.crm.nurse.cares.create_v2(payload)
            : await services.crm.nurse.cares.update_v2(payload);

        if (!resp) return;

        let message = translate(`${endpoint.toUpperCase()}D`);
        modal
          .confirm({
            type: 'SUCCESS',
            msg: message,
          })
          .then(() => {
            treatmentItemCategoriesCallApi();
            nurseChartCallApi();
            eventBus.emit('chartCountCallApi');

            if (closeFlag) {
              modal.pop();
              return;
            }
            setLoadingBtnDisabledFlag(false);
            actionInit();
          });
      } catch (e) {
        console.log(e.description);
        modal
          .confirm({
            type: 'ERROR',
            msg: e.description,
          })
          .then(() => {
            setLoadingBtnDisabledFlag(false);
          });
      }
    }
  }, [obj, closeFlag, actionInit, nurseChartCallApi]);

  const onClickOpenPaymentCodePopup = () => {
    // afterUpdateReturn: true > 팝업에서 수납코드 저장 후 저장된 내용 반환 위한 플래그
    modal
      .custom({
        component: ModalSurgery,
        options: { surgery: undefined, afterUpdateReturn: true },
      })
      .then((result) => {
        if (typeof result === 'object') {
          treatmentItemCategoriesCallApi();
          let setItems = useCustomerChart.useSettingSurgeryItems(
            result,
            itemsList.map((v) => {
              return {
                ...v,
                category: { id: v.categoryId, name: v.categoryName },
              };
            })
          );

          if (!setItems) return;

          let items = setItems.map((v) => {
            return {
              ...v,
              categoryId: v.category.id,
              categoryName: v.category.name,
              treatmentItemId:
                v?.treatmentItemId === undefined ? v.id : v.treatmentItemId,
            };
          });
          setItemsList(items);
          obj['items'] = items;
          setObj({ ...obj });
        }
      });
  };

  const onChangeScheduledAt = (scheduledAt) => {
    let date =
      scheduledAt === null ? moment().format() : moment(scheduledAt).format();
    setObj((obj) => {
      return { ...obj, scheduledAt: date };
    });
  };

  return (
    <div className="nurse-charts chart-page">
      <div className="data-input">
        <div className="title">
          간호입력
          <div className="right">
            {componentFlag === null ? (
              <>
                <button
                  className="btn btn-basic _small"
                  onClick={onClickCreateNurseChart}
                >
                  신규 간호 입력
                </button>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
        {componentFlag === 'nurseChart' ? (
          <div className="card">
            <div className="form-double">
              <div className="form-control">
                <label>일자</label>
                {useCustomerChart.getScheduledAtDatePicker({
                  objScheduledAt: obj.scheduledAt || new Date(),
                  onChangeScheduledAt,
                })}
              </div>
            </div>

            <div className="form-double">
              <div className="form-control">
                <label>의사</label>
                <select
                  value={doctors.findIndex(
                    (existing) =>
                      existing.id === ((obj && obj['doctor']) || {}).id
                  )}
                  onChange={(e) => {
                    obj['doctor'] = doctors[e.target.value];
                    setObj({ ...obj });
                  }}
                >
                  <option value={-1}>의사를 선택하세요.</option>

                  {(doctors || []).map((o, idx) => (
                    <option key={idx} value={idx}>
                      {' '}
                      {o.name}{' '}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-control">
                <label>간호사</label>
                <select
                  value={nurses.findIndex(
                    (existing) =>
                      existing.id === ((obj && obj['nurse']) || {}).id
                  )}
                  onChange={(e) => {
                    obj['nurse'] = nurses[e.target.value];
                    setObj({ ...obj });
                  }}
                >
                  <option value={-1}>간호사를 선택하세요.</option>
                  {(nurses || []).map((o, idx) => (
                    <option key={idx} value={idx}>
                      {' '}
                      {o.name}{' '}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <>
              <div className="form-wide m-b-20">
                {itemsList.map((list, idx) => {
                  return (treatmentItemCategories || []).length > 0 ? (
                    <div className="form-double-side" key={idx}>
                      <div className="form-control">
                        {idx === 0 && <label>시/수술 카테고리</label>}
                        <select
                          value={(treatmentItemCategories || []).findIndex(
                            (category) =>
                              category.id === (itemsList[idx] || []).categoryId
                          )}
                          onChange={(e) => {
                            if (
                              e.target.value !== -1 &&
                              e.target.value !== 'null'
                            ) {
                              const items = (
                                treatmentItemCategories[e.target.value] || {}
                              ).items;
                              itemsList[idx] = (items || [])[0];
                              if (!e.target.value) {
                                itemsList[idx]['categoryName'] =
                                  itemsList[idx]['category'].name;
                                itemsList[idx]['categoryId'] =
                                  itemsList[idx]['category'].id;
                              }

                              itemsList[idx]['categoryName'] =
                                itemsList[idx]['category'].name;
                              itemsList[idx]['categoryId'] =
                                itemsList[idx]['category'].id;
                              itemsList[idx]['treatmentItemId'] =
                                itemsList[idx]['id'];
                              setItemsList([...itemsList]);
                              obj['items'] = itemsList;
                              setObj({ ...obj });
                            } else {
                              itemsList[idx] = {};
                              setItemsList([...itemsList]);
                              obj['items'] = itemsList;
                              setObj({ ...obj });
                            }
                          }}
                        >
                          <option value={-1} hidden disabled>
                            시/수술 카테고리를 선택하세요.
                          </option>
                          <option value="null">없음</option>
                          {(treatmentItemCategories || []).map(
                            (category, idx) => (
                              <option
                                key={idx}
                                value={idx}
                                className={
                                  !category.visible ? 'display-none' : ''
                                }
                              >
                                {category.name}
                              </option>
                            )
                          )}
                        </select>
                      </div>
                      <div className="form-control">
                        {idx === 0 && <label>시/수술명</label>}
                        {(treatmentItemCategories || []).length > 0 ? (
                          <select
                            value={(
                              (
                                (
                                  treatmentItemCategories || []
                                ).find((category) =>
                                  category.items.some(
                                    (item) =>
                                      item.id ===
                                      (itemsList[idx] || {}).treatmentItemId
                                  )
                                ) || {}
                              ).items || []
                            ).findIndex(
                              (item) =>
                                item.id ===
                                (itemsList[idx] || {}).treatmentItemId
                            )}
                            onChange={(e) => {
                              if (
                                e.target.value === -1 ||
                                e.target.value === 'null'
                              ) {
                                itemsList[idx] = {};
                                setItemsList([...itemsList]);
                                obj['items'] = itemsList;
                                setObj({ ...obj });
                                return false;
                              }
                              itemsList[idx] = (
                                treatmentItemCategories || []
                              ).find((category) =>
                                category.items.some(
                                  (item) =>
                                    item.id ===
                                    (itemsList[idx] || {}).treatmentItemId
                                )
                              ).items[e.target.value];
                              itemsList[idx]['categoryName'] =
                                itemsList[idx]['category'].name;
                              itemsList[idx]['categoryId'] =
                                itemsList[idx]['category'].id;
                              itemsList[idx]['treatmentItemId'] =
                                itemsList[idx]['id'];
                              setItemsList([...itemsList]);
                              obj['items'] = itemsList;
                              setObj({ ...obj });
                            }}
                          >
                            <option value={-1} hidden disabled>
                              시/수술명을 선택하세요.
                            </option>
                            <option value="null">없음</option>
                            {(
                              (
                                (treatmentItemCategories || []).find(
                                  (category) =>
                                    category.id ===
                                    (itemsList[idx] || {}).categoryId
                                ) || {}
                              ).items || []
                            ).map((item, idx) => (
                              <option
                                key={idx}
                                value={idx}
                                className={!item.visible ? 'display-none' : ''}
                              >
                                {item.name}
                              </option>
                            ))}
                          </select>
                        ) : null}
                      </div>

                      <button
                        className="btn btn-danger _small"
                        onClick={() => {
                          modal
                            .basic({
                              body:
                                '삭제하시겠습니까? 최종 저장 전에는 반영되지 않습니다.',
                              buttons: [
                                { text: 'CANCEL', class: 'btn-default' },
                                { text: 'CONFIRM', class: 'btn-danger' },
                              ],
                            })
                            .then((selectedBtnIdx) => {
                              if (selectedBtnIdx === 1) {
                                itemsList.splice(idx, 1);
                                setItemsList([...itemsList]);
                                obj['items'] = itemsList;
                                setObj({ ...obj });
                              }
                            });
                        }}
                      >
                        {translate('DELETE')}
                      </button>
                    </div>
                  ) : null;
                })}
              </div>
              <div className="btnwrap m-t-8">
                <button
                  className="btn btn-basic middle _small _full"
                  onClick={() => addTreatmentItem()}
                >
                  입력란 추가
                </button>
                {auth.me.authorityGroup.paymentCode !== 'disabled' && (
                  <button
                    className="btn btn-primary _small m-l-8"
                    onClick={() => onClickOpenPaymentCodePopup()}
                  >
                    시/수술코드 추가
                  </button>
                )}
              </div>
            </>

            <div className="form-double">
              <div>
                <label>바이탈</label>
                <table>
                  <tbody>
                    {vitals.map((v, i) => {
                      return (
                        <tr key={i}>
                          <th>
                            {v.name}
                            <br></br>({v.unit})
                          </th>
                          {amountItems.map((amount, idx) => {
                            return (
                              <td key={idx}>
                                <VitalAmount
                                  type="text"
                                  onChange={(e) => {
                                    vitals[i][amount] = e.target.value;
                                    setVitals([...vitals]);
                                    obj['vitalItems'] = vitals;
                                    setObj({ ...obj });
                                  }}
                                  value={v[amount] || ''}
                                />
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>

            {itemList.map((item, i) => {
              return (
                <div key={i} className="form-double-side">
                  <div className="form-control">
                    {i === 0 ? <label>투약약품명</label> : null}
                    <select
                      value={injectItems.findIndex(
                        (index) =>
                          index.careInjectItemId ===
                          ((itemList && itemList[i]) || {}).careInjectItemId
                      )}
                      onChange={(e) => {
                        itemList[i] = {
                          name: injectItems[e.target.value].name,
                          unit: injectItems[e.target.value].unit,
                          careInjectItemId:
                            injectItems[e.target.value].careInjectItemId,
                          amount: '',
                        };
                        setItemList([...itemList]);
                        obj['injectItems'] = itemList;
                        setObj({ ...obj });
                      }}
                    >
                      <option value={-1} hidden disabled>
                        선택
                      </option>
                      <option value="null" hidden disabled>
                        선택
                      </option>
                      {(injectItems || []).map((o, idx) => (
                        <option key={idx} value={idx}>
                          {' '}
                          {o.name}{' '}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="form-control2">
                    {i === 0 ? <label>투약량</label> : null}
                    <AmountInput
                      key={i}
                      type="text"
                      onChange={(e) => {
                        itemList[i]['amount'] = e.target.value;
                        setItemList([...itemList]);
                        obj['injectItems'] = itemList;
                        setObj({ ...obj });
                      }}
                      value={itemList[i]['amount'] ? itemList[i]['amount'] : ''}
                    />
                    {itemList && itemList[i] ? itemList[i].unit : null}
                  </div>
                  <div className="form-control">
                    <button
                      className="btn btn-danger _small"
                      onClick={() => {
                        (itemList || []).splice(i, 1);
                        setItemList([...itemList]);
                        obj['injectItems'] = itemList;
                        setObj({ ...obj });
                      }}
                    >
                      삭제
                    </button>
                  </div>
                </div>
              );
            })}

            <div className="form-double">
              <div className="form-control">
                <button
                  className="btn btn-basic _small"
                  onClick={() => addInjectItem()}
                >
                  입력란 추가
                </button>
              </div>
            </div>

            <div className="form-wide">
              <div className="form-control">
                <label>메모</label>
                {memoBoilerplateList.length > 0 && (
                  <div className="wrap-btn-boilerplate-memo">
                    {memoBoilerplateList.slice(0, 5).map((v, i) => (
                      <button
                        className="btn btn-sm btn-white _ssmall"
                        key={i}
                        onClick={() =>
                          onChangeValue(
                            'memo',
                            (obj.memo === '<p><br></p>' ? '' : obj.memo || '') +
                              v.contents
                          )
                        }
                      >
                        {v.title.slice(0, 5) + (v.title.length > 5 ? '…' : '')}
                      </button>
                    ))}
                  </div>
                )}
                {obj && (
                  <QuillTextField
                    tabName={tabName}
                    key={obj.id}
                    value={obj.memo || ''}
                    setValue={(v) => onChangeValue('memo', v)}
                    setMemoBoilerplateList={setMemoBoilerplateList}
                    placeholder="메모를 입력하세요."
                  />
                )}
              </div>
            </div>

            {memoList &&
              memoList.map((list, idx) => {
                return (
                  <div className="form-double-bottom" key={idx}>
                    <div className="form-control">
                      {idx === 0 ? <label>추가 메모</label> : null}
                      <DatePicker
                        dateFormat="yyyy/MM/dd"
                        key={idx}
                        value={
                          memoList && memoList[idx].scheduledAt
                            ? new Date(memoList[idx].scheduledAt)
                            : onChangeDate(0, moment().format())
                        }
                        onChange={(scheduledAt) => {
                          scheduledAt === null
                            ? onChangeDate(idx, moment().format())
                            : onChangeDate(idx, moment(scheduledAt).format());
                        }}
                        popperModifiers={{
                          preventOverflow: {
                            enabled: true,
                          },
                        }}
                      />
                    </div>
                    <div className="form-control2">
                      <MemoInput
                        key={idx}
                        type="text"
                        onChange={(e) => {
                          memoList[idx].memo = e.target.value;
                          setMemoList([...memoList]);
                          obj['memos'] = memoList;
                          setObj({ ...obj });
                        }}
                        value={
                          memoList[idx]['memo'] ? memoList[idx]['memo'] : ''
                        }
                      />
                    </div>
                    <div className="form-control2">
                      <button
                        className="btn btn-danger _small"
                        onClick={() => {
                          modal
                            .basic({
                              body:
                                '삭제하시겠습니까? 최종 저장 전에는 반영되지 않습니다.',
                              buttons: [
                                { text: 'CANCEL', class: 'btn-default' },
                                { text: 'CONFIRM', class: 'btn-danger' },
                              ],
                            })
                            .then((selectedBtnIdx) => {
                              if (selectedBtnIdx === 1) {
                                (memoList || []).splice(idx, 1);
                                setMemoList([...memoList]);
                                setObj((obj) => {
                                  return {
                                    ...obj,
                                    memos: [...memoList],
                                  };
                                });
                              }
                            });
                        }}
                      >
                        삭제
                      </button>
                    </div>
                  </div>
                );
              })}
            <div className="form-double">
              <div className="form-control">
                <button
                  className="btn btn-basic _small"
                  onClick={() => addMemo()}
                >
                  메모 추가
                </button>
              </div>
            </div>

            <div className="flex-row wrap-bottom-btns">
              <button
                className="btn btn-cancel _small m-r-8"
                onClick={actionInit}
              >
                입력취소
              </button>
              <button
                className="btn btn-basic _small"
                onClick={() => onClickSave()}
                disabled={loadingBtnDisabledFlag}
              >
                {obj && obj.id ? '수정완료' : '입력완료'}
              </button>
            </div>
          </div>
        ) : null}
      </div>
      <div className="wrap-data-table data-table">
        <div className="card">
          <div className="title">간호 내역</div>
          <DataTableAlpha
            model={modelOperationNurseTab}
            data={chartList}
            params={params}
            total={total}
            onParams={onParams}
            onAction={onAction}
            bottomPositionInner
            hasLine
          />
        </div>
      </div>
    </div>
  );
};

CustomerNurseCharts.propTypes = {
  customer: PropTypes.object,
  closeFlag: PropTypes.bool,
};

export default observer(CustomerNurseCharts);
