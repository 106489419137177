import React from 'react';
import { observer } from 'mobx-react';
import { useUI } from 'hooks/useUI';
import HeaderBar from 'components/app/HeaderBar';
import LeftNavigation from 'components/app/LeftNavigation';
import PropTypes from 'prop-types';
import Cti from 'components/cti/Cti';

export const DefaultLayout = observer(({ children }) => {
  const ui = useUI();

  return (
    <div>
      <div
        className="overlay left-navigation-overlay"
        onClick={() => ui.setShowNavigation(false)}
      />
      <HeaderBar />
      <div className="flex-row app-body ui-body">
        <LeftNavigation />
        <div
          className={`flex-fill route ${
            ui.showNavigation ? 'left-nav-opened' : ''
          }`}
        >
          {children}
        </div>
      </div>
      <Cti />
    </div>
  );
});

DefaultLayout.propTypes = {
  children: PropTypes.node,
};
