import React, { useCallback, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import {
  currency,
  removeCurrency,
  payoutCards,
  payoutBankTransfers,
  translate,
} from 'filters';
import Checkbox from 'components/app/Checkbox';
import DataTableAlpha from 'components/data-table/DataTableAlpha';
import ChangePrice from 'components/pages/crm/customer-chart/payments/ChangePrice';
import ChangeVatNotPrice from 'components/pages/crm/customer-chart/payments/ChangeVatNotPrice';
import ChangeTreatmentCount from 'components/pages/crm/customer-chart/payments/ChangeTreatmentCount';
import ChangeDiscountAmount from 'components/pages/crm/customer-chart/payments/ChangeDiscountAmount';
import ModalAddPaymentSurgeries from 'components/modals/ModalAddPaymentSurgeries';
import ModalAddPaymentProducts from 'components/modals/ModalAddPaymentProducts';
import QuillTextField from 'components/quill/QuillTextField2';
import { toHexColorHtml } from 'components/quill/quillUtil';

import ModalPaymentsRefund from 'components/pages/crm/customer-chart/ModalPaymentsRefund';
import ModalPaymentsUnpaid from 'components/pages/crm/customer-chart/ModalPaymentsUnpaid';
import ModalPaymentsUpdate from 'components/pages/crm/customer-chart/ModalPaymentsUpdate';
import ModalPaymentsDetail from 'components/pages/crm/customer-chart/ModalPaymentsDetail';
import moment from 'moment';
import PropTypes from 'prop-types';
import modelPaymentTab from 'models/payment-tab';
import useCustomerChart from 'hooks/useCustomerChart';
import SelectNextProcess from './SelectNextProcess';
import models from 'models';
import { $qb } from 'scripts/querybuilder';
import { useAuth } from 'store/auth';
import { useEvent } from 'hooks/useEvent';
import { useModal } from 'hooks/useModal';
import { useServices } from 'hooks/useServices';
import { useToast } from 'hooks/useToast';
import styled, { css } from 'styled-components';
import { RadioInput } from 'components/common/RadioInput';

const Input = styled.input`
  width: 100%;
  max-width: 200px;
  height: 34px;
  padding: 8px 20px;
  border-color: #d7e3f1;
  font-size: 12px;
  min-height: 10px;
  border-radius: 4px;
  background-color: #f3f8ff;
  color: #000;
  border: 1px solid #dee2ec;
`;

const DiscountReasonInput = styled(Input)`
  max-width: 100%;
  font-size: 14px;

  ${({ disabled }) =>
    disabled &&
    css`
      background-color: #efeff4 !important;
      color: #ceced7 !important;
    `}
`;

const CustomerPayment = ({ customer, openFlag, closeFlag }) => {
  const history = useHistory();
  const auth = useAuth();
  const eventBus = useEvent();
  const modal = useModal();
  const services = useServices();
  const toast = useToast();

  const tabName = 'payments';
  // 고객차트 > 수납 > 상세수납내역 버튼
  const [paymentDetailMode, setPaymentDetailMode] = useState(
    JSON.parse(window.localStorage.getItem('paymentDetailMode')) || false
  );
  const [loadingBtnDisabledFlag, setLoadingBtnDisabledFlag] = useState(false);
  //componentFlag : payments(수납하기)
  const [componentFlag, setComponentFlag] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [selectRow, setSelectRow] = useState();
  const defaultParams = $qb()
    .limit(10)
    .orderBy('firstPaidAt desc')
    .customParam('customerId', customer.id);
  const [params, setParams] = useState(defaultParams);
  const [surgeriesData, setSurgeriesData] = useState([]);
  const [productsData, setProductsData] = useState([]);
  const [payments, setPayments] = useState([]);
  const [total, setTotal] = useState(0);
  const [nextProcess, setNextProcess] = useState(undefined);
  const [appointmentData, setAppointmentData] = useState({});
  const [treatmentSelectCounselors, setTreatmentSelectCounselors] = useState(
    {}
  );
  const [treatmentSelectDoctors, setTreatmentSelectDoctors] = useState({});
  const [treatmentSelectFacialists, setTreatmentSelectFacialists] = useState(
    {}
  );
  const [cashReceipt, setCashReceipt] = useState(false);
  const [payouts, setPayouts] = useState([]);
  const [memo, setMemo] = useState('');
  const [processAppointmentId, setProcessAppointmentId] = useState(
    (openFlag || {}).appointmentId || null
  );
  const [paidAt, setPaidAt] = useState(new Date());
  const [subTablePaidAtOrder, setSubTablePaidAtOrder] = useState('desc');
  const [memoBoilerplateList, setMemoBoilerplateList] = useState([]);
  const [receiptCounselors, setReceiptCounselors] = useState({});

  // const [nonReimbursementAmount, setNonReimbursementAmount] = useState(0) //비급여금액
  // const [reimbursementAmount,] = useState(0) //급여금액
  //현재는 모두 비급여 진료. 급여금액은 0으로 보내주긴 해야함
  const [totalAmount, setTotalAmount] = useState(0); // 할인 전 금액
  const [requestAmount, setRequestAmount] = useState(0); //청구금액
  const [discountAmount, setDiscountAmount] = useState(0); //할인금액
  const [payoutAmount, setPayoutAmount] = useState(0); //납부금액
  const [unpaidAmount, setUnpaidAmount] = useState(0); //미납금액

  const [payoutCreditItems, setPayoutCreditItems] = useState([
    {
      type: 'select',
      paymentMethod: 'credit_card',
      companyName: '',
      amount: 0,
    },
  ]);
  const [payoutDebitItems, setPayoutDebitItems] = useState([
    { type: 'select', paymentMethod: 'debit_card', companyName: '', amount: 0 },
  ]);
  const [payoutBankTransferItems, setPayoutBankTransferItems] = useState([
    {
      type: 'select',
      paymentMethod: 'bank_transfer',
      companyName: '',
      amount: 0,
    },
  ]);
  const [payoutCash, setPayoutCash] = useState(0);

  //각 결제수단별 수납 총 금액액
  const [creditCardsTotalAmount, setCreditCardsTotalAmount] = useState(0);
  const [debitCardsTotalAmount, setDebitCardsTotalAmount] = useState(0);
  const [bankTransferTotalAmount, setBankTransferTotalAmount] = useState(0);
  const [cashTotalAmount, setCashTotalAmount] = useState(0);

  //다음진행 팝업에서 사용할 상담사/피부관리사/의사 리스트
  const [counselors, setCounselors] = useState([]);
  const [facialist, setFaicalist] = useState([]);
  const [doctors, setDoctors] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState({});

  const [radioValue, setRadioValue] = useState(false);
  const [counselorFlag, setCounselorFlag] = useState(false);

  const [summary, setSummary] = useState({});

  const togglePaymentDetailMode = (bool) => {
    window.localStorage.setItem('paymentDetailMode', bool);
    setPaymentDetailMode(
      JSON.parse(window.localStorage.getItem('paymentDetailMode')) || false
    );
  };

  const summaryCallApi = async () => {
    let params = { customerId: customer.id };
    const resp = await services.crm.payment.summary(params);
    if (!resp) return;

    setSummary(resp.data);
  };

  useEffect(() => {
    summaryCallApi();
  }, []);

  const userCallApi = useCallback(async () => {
    let params = { userStatus: 'active', limit: 100 };
    const resp = await services.crm.user.duty(params);
    if (!resp) return;
    setUsers(resp.data);
  }, [services.crm.user]);

  useEffect(() => {
    userCallApi();
  }, [userCallApi]);

  const facialistCallApi = useCallback(async () => {
    let params = { duty: '피부관리사', userStatus: 'active' };
    const resp = await services.crm.user.duty(params);
    if (!resp) return;

    setFaicalist(resp.data);
  }, []);

  useEffect(() => {
    facialistCallApi();
  }, [facialistCallApi]);

  const doctorCallApi = useCallback(async () => {
    let params = { duty: '의사', userStatus: 'active' };
    const resp = await services.crm.user.duty(params);
    if (!resp) return;

    setDoctors(resp.data);
  }, []);

  useEffect(() => {
    doctorCallApi();
  }, [doctorCallApi]);

  const counselorCallApi = useCallback(async () => {
    let params = { duty: '상담사', userStatus: 'active' };
    const resp = await services.crm.user.duty(params);
    if (!resp) return;

    setCounselors(resp.data);
  }, []);

  useEffect(() => {
    counselorCallApi();
  }, [counselorCallApi]);

  const loadPayments = useCallback(async () => {
    try {
      if (customer.id) {
        // 수납내역 조회
        const resp = await services.crm.payment.all(params.build());
        if (!resp) return;
        const changeData = resp.data.map((v, i) => {
          let page = params.queryParams.page || 1;
          let limit = params.queryParams.limit;
          v.no = resp.total - ((page - 1) * limit + i);

          // 시수술 vat미포함 가격 가공
          v.paymentTreatmentItems.forEach((v) => {
            if (!v.vatIncluded) {
              v['price'] = v.priceVatInclude;
            }
          });
          // 제품 vat미포함 가격 가공
          v.paymentProducts.forEach((v) => {
            if (!v.vatIncluded) {
              v['price'] = v.priceVatInclude;
            }
          });

          v['paymentTotalItems'] = [
            ...v.paymentTreatmentItems,
            ...v.paymentProducts,
          ];
          const payouts = v.payouts.map((v) => {
            v.status = 'payouts';
            return v;
          });
          const refunds = v.refunds.map((v) => {
            v.status = 'refunds';
            return v;
          });
          v['subRow'] = [...payouts, ...refunds].sort((a, b) => {
            if (subTablePaidAtOrder === 'desc') {
              return new Date(b.paidAt) - new Date(a.paidAt) > 0 ? 1 : -1;
            } else {
              return new Date(b.paidAt) - new Date(a.paidAt) > 0 ? -1 : 1;
            }
          });
          v.subTableToggle = paymentDetailMode;
          return v;
        });
        setPayments([...changeData]);
        setTotal(resp.total);
      }
    } catch (e) {
      console.log(e.description);
    }
  }, [params, subTablePaidAtOrder]);

  const init = useCallback(async () => {
    loadPayments();
    summaryCallApi();
  }, [loadPayments]);

  const openModalDiscountReason = (row) => {
    const discountReason =
      row['@@paymentTotalItems'].discountReason === null
        ? ''
        : row['@@paymentTotalItems'].discountReason;
    modal.basic({
      title: '할인사유',
      markup: (
        <DiscountReasonInput disabled={true} value={discountReason.content} />
      ),
      buttons: [{ text: 'CONFIRM', class: 'btn-primary' }],
    });
  };

  const onTableAction = (obj) => {
    let payment = obj.row;
    const event = obj.event;
    const classList = event.target.classList;

    if (classList.contains('btn-discount-reason')) {
      //할인 사유 팝업
      openModalDiscountReason(payment);
      return;
    }

    if (
      classList.contains('ico-arrow-down') ||
      classList.contains('ico-arrow-up')
    ) {
      //서브테이블 여닫기
      let _payment = payments.map((v) => {
        if (v.id === payment.id) {
          v.subTableToggle = !payment.subTableToggle;
        }
        return v;
      });
      setPayments([..._payment]);
      return;
    }
  };
  const onSubAction = (key) => {
    if (key.title === '수납일') {
      let orderBy = subTablePaidAtOrder === 'desc' ? 'asc' : 'desc';
      setSubTablePaidAtOrder(orderBy);
    }
  };

  const onParams = (p) => {
    setParams(p);
    loadPayments();
  };

  const actionInit = useCallback(async () => {
    loadPayments();
    summaryCallApi();
    setPaidAt(new Date());
    setAppointmentData({});
    setSurgeriesData([]);
    setProductsData([]);
    setComponentFlag(null);
    setPayoutCreditItems([
      {
        type: 'select',
        paymentMethod: 'credit_card',
        companyName: '',
        amount: 0,
      },
    ]);
    setPayoutDebitItems([
      {
        type: 'select',
        paymentMethod: 'debit_card',
        companyName: '',
        amount: 0,
      },
    ]);
    setPayoutBankTransferItems([
      {
        type: 'select',
        paymentMethod: 'bank_transfer',
        companyName: '',
        amount: 0,
      },
    ]);
    setPayoutCash(0);
    setCreditCardsTotalAmount(0);
    setDebitCardsTotalAmount(0);
    setBankTransferTotalAmount(0);
    setCashTotalAmount(0);
    setTotalAmount(0);
    setRequestAmount(0);
    setDiscountAmount(0);
    setPayoutAmount(0);
    setUnpaidAmount(0);
    setMemo('');
    setCashReceipt(false);
    setPayouts([]);
    setComponentFlag(null);
    setNextProcess();
    setTreatmentSelectDoctors({});
    setTreatmentSelectFacialists({});
    setTreatmentSelectCounselors({});
    setReceiptCounselors(customer.counselor);
    setRadioValue(false);
    setCounselorFlag(false);
  }, [loadPayments, customer]);

  const beforeSave = () => {
    if (componentFlag === 'payments') {
      if (surgeriesData.length === 0 && productsData.length === 0) {
        toast.error('수납해야할 시/수술 내역을 선택하세요.');
        return;
      }

      let appointmentUpdatePayload;
      //nextProcess 값에 따라 다음진행 영역 셀렉트값 선택하여 onClickSave로 넘겨
      //상담 완료 하고 완료되면 예약데이터 업데이트
      if (nextProcess === 'consulting_waiting') {
        appointmentUpdatePayload = {
          counselor: treatmentSelectCounselors,
        };
      }
      if (nextProcess === 'treatment_waiting') {
        appointmentUpdatePayload = {
          doctor: treatmentSelectDoctors,
        };
      }
      if (nextProcess === 'surgery_waiting') {
        appointmentUpdatePayload = {
          doctor: treatmentSelectDoctors,
          facialist: treatmentSelectFacialists,
        };
      }

      onClickSavePayments(appointmentUpdatePayload);
    }
  };

  const appointmentUpdate = async (appointmentUpdatePayload) => {
    try {
      let payload = {
        id: appointmentData.id,
        scheduledAt: appointmentData.scheduledAt,
        doctor: appointmentData.doctor,
        facialist: appointmentData.facialist,
        counselor: appointmentData.counselor,
        customerId: ((appointmentUpdatePayload || {}).customer || {}).id,
        ...appointmentUpdatePayload,
      };
      await services.crm.crud.appointment.update(payload);
    } catch (e) {
      console.log(e.description);
    }
  };

  const savePayments = useCallback(
    async (nextProcess, payload, appointmentUpdatePayload) => {
      let nextProcessStatus = nextProcess;

      //수납 저장 후 nextProcess consulting_waiting, treatment_waiting, surgery_waiting의 경우
      //예약 데이터에 update
      payload.customerId = (customer || {}).id;
      if (processAppointmentId !== null) {
        payload.appointmentId = processAppointmentId;
      }
      payload.appointmentStatus = nextProcessStatus;

      try {
        const resp = await services.crm.payment.create(payload);
        if (!resp) return;

        let message = translate(`${'create'.toUpperCase()}D`);
        modal
          .confirm({
            type: 'SUCCESS',
            msg: message,
          })
          .then(async () => {
            eventBus.emit('chartCountCallApi');
            setComponentFlag(null);

            if (resp.data.id) {
              // 수납 저장 후, 예약 데이터 업데이트 (의사/피부관리사 등의 변경)
              if (
                nextProcess === 'consulting_waiting' ||
                nextProcess === 'treatment_waiting' ||
                nextProcess === 'surgery_waiting' ||
                nextProcess === 'leave'
              ) {
                //예약이 수정되는 경우 : 추가상담대기,진료대기,시수술대기
                let appointmentPayload = {
                  customerId: (customer || {}).id,
                  ...appointmentUpdatePayload,
                };
                await appointmentUpdate(appointmentPayload);
              }
              setLoadingBtnDisabledFlag(false);
            }
            actionInit();
            eventBus.emit('customerCallApi');

            if (history.location.pathname === '/crm/appointment-boards') {
              eventBus.emit('boardsLoadAppointments');
            } else if (history.location.pathname === '/crm/home') {
              eventBus.emit('homeLoadAppointments');
            }

            if (closeFlag) {
              modal.pop();
              return;
            }
          });
      } catch (e) {
        console.log(e.description);
        modal
          .confirm({
            type: 'ERROR',
            msg: e.description,
          })
          .then(() => {
            setLoadingBtnDisabledFlag(false);
          });
      }
    },
    [nextProcess]
  );

  const setPaymentPayload = useCallback(() => {
    let paymentTreatmentItems = [...surgeriesData].map((v) => {
      if (v['FREE_SERVICE_$$checked']) {
        v.isFree = true;
      }
      return v;
    });
    let paymentProducts = [...productsData];

    const status = unpaidAmount === 0 ? 'paid' : 'unpaid';
    const creditItems = payoutCreditItems
      .filter((v) => v.amount !== 0)
      .map((v) => {
        v.paidAt = moment(paidAt).format('YYYY-MM-DDTHH:mm:ss');
        v.createdName = auth.me.name;
        // eslint-disable-next-line no-unused-vars
        const { type: deletedKey, ...otherKeys } = v;
        return otherKeys;
      });
    const debitItems = payoutDebitItems
      .filter((v) => v.amount !== 0)
      .map((v) => {
        v.paidAt = moment(paidAt).format('YYYY-MM-DDTHH:mm:ss');
        v.createdName = auth.me.name;
        // eslint-disable-next-line no-unused-vars
        const { type: deletedKey, ...otherKeys } = v;
        return otherKeys;
      });
    const bankTransferItems = payoutBankTransferItems
      .filter((v) => v.amount !== 0)
      .map((v) => {
        v.paidAt = moment(paidAt).format('YYYY-MM-DDTHH:mm:ss');
        v.createdName = auth.me.name;
        v.cashReceipt = cashReceipt;
        // eslint-disable-next-line no-unused-vars
        const { type: deletedKey, ...otherKeys } = v;
        return otherKeys;
      });

    let setPayoutItems = [
      ...payouts.map((v) => {
        //cashItems
        if (v.paymentMethod === 'cash') {
          v.cashReceipt = cashReceipt;
          v.paidAt = moment(paidAt).format('YYYY-MM-DDTHH:mm:ss');
        }
        v.createdName = auth.me.name;
        return v;
      }),
      ...creditItems,
      ...debitItems,
      ...bankTransferItems,
    ];

    let payload = {
      customer,
      customerId: customer.id,
      totalAmount,
      discountAmount,
      requestAmount,
      payoutAmount,
      unpaidAmount,
      paidAmount: payoutAmount,
      createdId: selectedUser.id,
      createdName: selectedUser.name,
      memo: memo ? toHexColorHtml(memo) : memo,
      payouts: setPayoutItems,
      status,
      refunds: [],
      refundAmount: 0,
      discountReason: null,
      reimbursementAmount: 0,
      nonReimbursementAmount: totalAmount,
      firstPaidAt: moment(paidAt).format('YYYY-MM-DDTHH:mm:ss'),
      counselorId: (receiptCounselors || {}).id,
      saveCustomer: radioValue,
    };
    //0709. 현재는 모두 비급여진료로, 급여금액: 0, 비급여금액은 totalAmount값으로 보낸다.
    //추후 급여/비급여 구분이 생기면, item별로 다르기에 totalAmount값이 아닌 아이템별로 구분하여 더한 값을 보내야한다.

    payload.paymentProducts = paymentProducts.map((v) => {
      //api 호출 시, quantity 개수대로 계산하지 않음. 1개의 제품가격에 대해 리퀘스트를 보내야 함
      //수납내역에서도 1개의 가격으로 나오므로 * quantity 하여 보여줌 (/models/payment-quest)
      v.productId = v.id;
      //시수술/제품에 discountAmount가 없어서 임의로 집어넣어줘야함
      v.discountAmount = v.discountAmount === undefined ? 0 : v.discountAmount;
      //복사생성이기에 id값을 삭제하고 넘겨야 함
      const {
        // eslint-disable-next-line no-unused-vars
        id: deletedKey,
        // eslint-disable-next-line no-unused-vars
        checkId: deletedKey2,
        // eslint-disable-next-line no-unused-vars
        PRICE_VAT_INCLUDE_$$input: deletedKey3,
        // eslint-disable-next-line no-unused-vars
        QUANTITY_$$input: deletedKey4,
        // eslint-disable-next-line no-unused-vars
        FREE_SERVICE_$$checked: deletedKey5,
        ...otherKeys
      } = v;
      return otherKeys;
    });

    payload.paymentTreatmentItems = paymentTreatmentItems.map((v) => {
      v.treatmentItemId = v.id;
      //시수술/제품에 discountAmount가 없어서 임의로 집어넣어줘야함
      v.discountAmount = v.discountAmount === undefined ? 0 : v.discountAmount;
      v.categoryName = v.category.name;
      //복사생성이기에 id값을 삭제하고 넘겨야 함
      const {
        // eslint-disable-next-line no-unused-vars
        id: deletedKey,
        // eslint-disable-next-line no-unused-vars
        checkId: deletedKey2,
        // eslint-disable-next-line no-unused-vars
        PRICE_VAT_INCLUDE_$$input: deletedKey3,
        // eslint-disable-next-line no-unused-vars
        SURGERY_NUMOF_$$input: deletedKey4,
        ...otherKeys
      } = v;
      return otherKeys;
    });
    return payload;
  }, [
    customer,
    discountAmount,
    memo,
    payoutAmount,
    payoutCreditItems,
    payoutDebitItems,
    payoutBankTransferItems,
    payouts,
    requestAmount,
    totalAmount,
    unpaidAmount,
    cashReceipt,
    paidAt,
    surgeriesData,
    productsData,
    receiptCounselors,
    radioValue,
    selectedUser,
  ]);

  const onClickSavePayments = useCallback(
    async (appointmentUpdatePayload) => {
      setLoadingBtnDisabledFlag(true);
      let payload = setPaymentPayload();
      savePayments(nextProcess, payload, appointmentUpdatePayload);
    },
    [loadPayments, nextProcess, payouts, setPaymentPayload]
  );

  useEffect(() => {
    init();
  }, [init]);

  const closetToTodayAppointmentCallApi = async () => {
    //오늘 예약건을 조회하여 setProcessAppointmentId
    //예약건 복수 개중 가장 오늘과 가까운 가장 최근 예약건을 찾기 위해 orderBy=scheduledAt+asc
    //ex) customerId=290startAt=2020-07-29&orderBy=scheduledAt+asc
    if (!customer.id) return;

    const notLeaveItemId = await useCustomerChart.useGetAppointmentClosetToTodayItem(
      customer.id
    );
    if (notLeaveItemId !== null) {
      setProcessAppointmentId(notLeaveItemId);
    }
  };

  useEffect(() => {
    let counselor = customer && customer.counselor ? customer.counselor : null;
    //탭 진입 시, openFlag 값이 있으면 setObj setState
    if (openFlag !== undefined) {
      if (openFlag.payment === null) {
        onClickCreatePayment(null);
      } else if (openFlag.payment !== undefined) {
        if (openFlag.payment.row !== undefined) {
          setSelectRow(openFlag.payment.row);
        }
        if (openFlag.payment.componentFlag !== undefined) {
          setComponentFlag(openFlag.payment.componentFlag);
        }
      }
    } else {
      //openFlag === undefined > 헤더 등으로 고객통합차트 진입
      //데이터 생성 시, 예약 status 변경을 "오늘 중" 예약건의 status를 변경하도록 수정.
      closetToTodayAppointmentCallApi();
      setReceiptCounselors(counselor);
    }

    return () => {
      setComponentFlag(null);
    };
  }, [openFlag, customer]);

  const onClickCreatePayment = async () => {
    //메모 상용구 api 호출
    memoBoilerplateCallApi();

    //고객의 가장 최근 예약건을 가져오기 위해 호출
    recentAppointmentDataCallApi();

    setComponentFlag('payments');
    setSelectedUser({ id: auth.me.id, name: auth.me.name });
  };

  //해당 고객의 가장 최근 예약건을 조회하여, 다음진행 팝업의 doctor, facialist, counselor를 세팅
  const recentAppointmentDataCallApi = async () => {
    try {
      if (!customer.id) return;

      const appointment = await useCustomerChart.useGetRecentAppointmentItem(
        customer.id
      );
      if (!appointment.id) return;

      setAppointmentData(appointment);
      if (appointment.doctor !== null) {
        setTreatmentSelectDoctors(appointment.doctor);
      }
      if (appointment.facialist !== null) {
        setTreatmentSelectFacialists(appointment.facialist);
      }
      if (appointment.counselor !== null) {
        setTreatmentSelectCounselors(appointment.counselor);
      }
    } catch (e) {
      console.log(e.description);
    }
  };

  const openModalChangeTreatmentCount = (payments) => {
    let changePayment = payments;
    const setPayments = (p) => {
      changePayment = p;
    };

    modal
      .slot({
        title: translate('SURGERY_NUMOF'),
        preparedDom: () => (
          <ChangeTreatmentCount payments={payments} setPayments={setPayments} />
        ),
        showCloseButton: true,
        buttons: [
          { text: 'CANCEL', class: 'btn-gray' },
          { text: '확인', class: 'btn-primary' },
        ],
      })
      .then((idx) => {
        if (idx === 1) {
          const list = surgeriesData.map((v) => {
            if (v.checkId === changePayment.checkId) {
              v.treatmentCount = changePayment.treatmentCount;
            }
            return v;
          });
          setSurgeriesData([...list]);
        }
      });
  };

  const openModalChangePrice = (payments, type) => {
    const data = type === 'surgeries' ? surgeriesData : productsData;
    let changePayment = payments;
    let changeFlag = false;
    const setPayments = (p) => {
      changePayment = p;
      changeFlag = true;
    };

    modal
      .slot({
        title: translate('PRICE_VAT_INCLUDE'),
        preparedDom: () => (
          <ChangePrice payments={payments} setPayments={setPayments} />
        ),
        showCloseButton: true,
        buttons: [
          { text: 'CANCEL', class: 'btn-primary' },
          { text: '확인', class: 'btn-primary' },
        ],
      })
      .then((idx) => {
        if (idx === 1 && changeFlag) {
          if (payoutAmount > changePayment.priceVatInclude) {
            toast.error('수납액 초과');
            return;
          }
          let allDataRequestAmount = 0;
          let allDataTotalAmount = 0;
          const list = data.map((v) => {
            if (v.checkId === changePayment.checkId) {
              v.price = changePayment.vatIncluded
                ? Math.round(changePayment.priceVatInclude / 1.1)
                : changePayment.priceVatInclude;
              v.priceVatInclude = changePayment.priceVatInclude;
              const discountAmt = changePayment.discountAmount || 0;
              v.requestAmount = Math.round(
                changePayment.priceVatInclude - discountAmt
              );
              v.totalAmount = changePayment.priceVatInclude;
            }

            allDataRequestAmount +=
              v.requestAmount === undefined
                ? v.priceVatInclude - v.discountAmount
                : v.requestAmount;
            allDataTotalAmount +=
              v.priceVatInclude === undefined ? 0 : v.priceVatInclude;
            return v;
          });

          let swapData = type === 'surgeries' ? productsData : surgeriesData;
          swapData.forEach((v) => {
            allDataRequestAmount +=
              v.requestAmount === undefined
                ? v.priceVatInclude - v.discountAmount
                : v.requestAmount;
            allDataTotalAmount +=
              v.priceVatInclude === undefined ? 0 : v.priceVatInclude;
          });

          setTotalAmount(allDataTotalAmount);
          setRequestAmount(allDataRequestAmount);
          setUnpaidAmount(allDataRequestAmount - payoutAmount);

          if (type === 'surgeries') {
            setSurgeriesData([...list]);
          } else {
            setProductsData([...list]);
          }
        }
      });
  };

  const openModalChangeVatNotPrice = (payments, type) => {
    const data = type === 'surgeries' ? surgeriesData : productsData;
    let changePayment = payments;
    let changeFlag = false;
    const setPayments = (p) => {
      changePayment = p;
      changeFlag = true;
    };

    modal
      .slot({
        title: translate('NOT_VAT_INCLUDE'),
        preparedDom: () => (
          <ChangeVatNotPrice payments={payments} setPayments={setPayments} />
        ),
        showCloseButton: true,
        buttons: [
          { text: 'CANCEL', class: 'btn-primary' },
          { text: '확인', class: 'btn-primary' },
        ],
      })
      .then((idx) => {
        if (idx === 1 && changeFlag) {
          if (payoutAmount > changePayment.price) {
            toast.error('수납액 초과');
            return;
          }
          let allDataRequestAmount = 0;
          let allDataTotalAmount = 0;
          const list = data.map((v) => {
            if (v.checkId === changePayment.checkId) {
              v.price = changePayment.price;
              if (changePayment.vatIncluded) {
                v.priceVatInclude = Math.round(v.price * 1.1);
              } else {
                if (changePayment.changeFlag) {
                  v.priceVatInclude = v.price;
                }
              }
              const discountAmt = changePayment.discountAmount || 0;
              v.requestAmount = Math.round(v.priceVatInclude - discountAmt);
              v.totalAmount = v.priceVatInclude;
            }

            allDataRequestAmount +=
              v.requestAmount === undefined
                ? v.priceVatInclude - v.discountAmount
                : v.requestAmount;
            allDataTotalAmount +=
              v.priceVatInclude === undefined ? 0 : v.priceVatInclude;
            return v;
          });

          let swapData = type === 'surgeries' ? productsData : surgeriesData;
          swapData.forEach((v) => {
            allDataRequestAmount +=
              v.requestAmount === undefined
                ? v.priceVatInclude - v.discountAmount
                : v.requestAmount;
            allDataTotalAmount +=
              v.priceVatInclude === undefined ? 0 : v.priceVatInclude;
          });

          setTotalAmount(allDataTotalAmount);
          setRequestAmount(allDataRequestAmount);
          setUnpaidAmount(allDataRequestAmount - payoutAmount);

          if (type === 'surgeries') {
            setSurgeriesData([...list]);
          } else {
            setProductsData([...list]);
          }
        }
      });
  };

  const openModalChangeDiscount = (payments, type) => {
    const data = type === 'surgeries' ? surgeriesData : productsData;
    let changePayment = payments;
    let changeFlag = false;
    const setPayments = (p) => {
      changePayment = p;
      changeFlag = true;
    };

    modal
      .slot({
        title: '할인',
        preparedDom: () => (
          <ChangeDiscountAmount payments={payments} setPayments={setPayments} />
        ),
        showCloseButton: true,
        buttons: [
          { text: 'CANCEL', class: 'btn-gray' },
          { text: '확인', class: 'btn-primary' },
        ],
      })
      .then((idx) => {
        if (idx === 1 && changeFlag) {
          let allDataRequestAmount = 0;
          let allDataDiscountAmount = 0;
          const list = data.map((v) => {
            if (v.checkId === changePayment.checkId) {
              let total = v.priceVatInclude;
              v.discountAmount = changePayment.discountAmount;
              v.requestAmount = total - changePayment.discountAmount;
              v.discountReason = changePayment.discountReason;
            }

            allDataDiscountAmount +=
              v.discountAmount === undefined ? 0 : v.discountAmount;
            allDataRequestAmount +=
              v.requestAmount === undefined
                ? v.priceVatInclude - v.discountAmount
                : v.requestAmount;
            return v;
          });

          let swapData = type === 'surgeries' ? productsData : surgeriesData;
          swapData.forEach((v) => {
            allDataDiscountAmount +=
              v.discountAmount === undefined ? 0 : v.discountAmount;
            allDataRequestAmount +=
              v.requestAmount === undefined
                ? v.priceVatInclude - v.discountAmount
                : v.requestAmount;
          });

          setDiscountAmount(allDataDiscountAmount);
          setRequestAmount(allDataRequestAmount);
          setUnpaidAmount(allDataRequestAmount - payoutAmount);

          if (type === 'surgeries') {
            setSurgeriesData([...list]);
          } else {
            setProductsData([...list]);
          }
        }
      });
  };

  const onClickDeleteSurgeries = (payments) => {
    let deleteItems = surgeriesData.filter((f) => {
      return f.checkId !== payments.checkId;
    });
    setSurgeriesData([...deleteItems]);

    let allRequestAmount = 0;
    let allTotalAmount = 0;
    let allDiscountAmount = 0;

    deleteItems.forEach((v) => {
      if (v.checkId !== payments.checkId) {
        let total = v.priceVatInclude;
        let discount = v.discountAmount === undefined ? 0 : v.discountAmount;
        allRequestAmount += total - discount;
        allTotalAmount += total;
        allDiscountAmount += discount;
      }
    });

    productsData.forEach((v) => {
      let total = v.priceVatInclude;
      let discount = v.discountAmount === undefined ? 0 : v.discountAmount;
      allRequestAmount += total - discount;
      allTotalAmount += total;
      allDiscountAmount += discount;
    });

    setRequestAmount(allRequestAmount);
    setTotalAmount(allTotalAmount);
    setUnpaidAmount(allRequestAmount);
    setDiscountAmount(allDiscountAmount);
  };

  const onActionSurgeries = (object) => {
    const event = object.event;
    const payments = object.row;
    const classList = event.target.classList;
    const model = object.key;

    if (model.title === 'SURGERY_NUMOF') {
      //시수술 횟수 변경 팝업
      openModalChangeTreatmentCount(payments);
      return;
    }

    if (model.title === 'PRICE_VAT_INCLUDE') {
      //VAT포함가격 변경 팝업
      openModalChangePrice(payments, 'surgeries');
      return;
    }

    if (model.title === 'NOT_VAT_INCLUDE') {
      //VAT포함가격 변경 팝업
      openModalChangeVatNotPrice(payments, 'surgeries');
      return;
    }

    if (model.title === '할인') {
      //할인 변경 팝업
      openModalChangeDiscount(payments, 'surgeries');
      return;
    }

    if (classList.contains('zmdi-minus')) {
      //삭제
      onClickDeleteSurgeries(payments);
    }
  };

  const onClickDeleteProducts = (payments) => {
    let deleteItems = productsData.filter((f) => {
      return f.checkId !== payments.checkId;
    });
    setProductsData([...deleteItems]);

    let allRequestAmount = 0;
    let allTotalAmount = 0;
    let allDiscountAmount = 0;

    deleteItems.forEach((v) => {
      if (v.checkId !== payments.checkId) {
        let total = v.priceVatInclude;
        let discount = v.discountAmount === undefined ? 0 : v.discountAmount;
        allRequestAmount += total - discount;
        allTotalAmount += total;
        allDiscountAmount += discount;
      }
    });

    surgeriesData.forEach((v) => {
      let total = v.priceVatInclude;
      let discount = v.discountAmount === undefined ? 0 : v.discountAmount;
      allRequestAmount += total - discount;
      allTotalAmount += total;
      allDiscountAmount += discount;
    });

    setRequestAmount(allRequestAmount);
    setTotalAmount(allTotalAmount);
    setUnpaidAmount(allRequestAmount);
    setDiscountAmount(allDiscountAmount);
  };

  const onAction = (object) => {
    const event = object.event;
    const payments = object.row;
    const classList = event.target.classList;
    const model = object.key;

    if (model.title === 'PRICE_VAT_INCLUDE') {
      //VAT포함가격 변경 팝업
      openModalChangePrice(payments, 'products');
      return;
    }

    if (model.title === 'NOT_VAT_INCLUDE') {
      //VAT포함가격 변경 팝업
      openModalChangeVatNotPrice(payments, 'products');
      return;
    }

    if (model.title === '할인') {
      //할인 변경 팝업
      openModalChangeDiscount(payments, 'products');
      return;
    }

    if (classList.contains('zmdi-minus')) {
      //삭제
      onClickDeleteProducts(payments);
    }
  };

  const openModalAddPaymentSurgeries = () => {
    modal
      .custom({
        component: ModalAddPaymentSurgeries,
      })
      .then((items) => {
        if (items !== undefined) {
          let allRequestAmount = requestAmount;
          let allTotalAmount = totalAmount;
          let allDiscountAmount = discountAmount;

          let addAllData = items.map((v) => {
            v.requestAmount = parseInt(v['PRICE_VAT_INCLUDE_$$input']);
            v.totalAmount = parseInt(v['PRICE_VAT_INCLUDE_$$input']);

            let total = v.priceVatInclude;
            let discount =
              v.discountAmount === undefined ? 0 : v.discountAmount;
            allRequestAmount += total - discount;
            allTotalAmount += total;
            allDiscountAmount += discount;

            // eslint-disable-next-line no-unused-vars
            const { $$checked: deletedKey, ...otherKeys } = v;
            return otherKeys;
          });

          const allData = [...surgeriesData, ...addAllData].map((v, i) => {
            v.checkId = i;
            return v;
          });
          setRequestAmount(allRequestAmount);
          setTotalAmount(allTotalAmount);
          setUnpaidAmount(allRequestAmount);
          setDiscountAmount(allDiscountAmount);
          setSurgeriesData([...allData]);
        }
      });
  };

  const openModalAddPaymentProducts = () => {
    modal
      .custom({
        component: ModalAddPaymentProducts,
      })
      .then((items) => {
        if (items !== undefined) {
          let addAllData = [...items].filter(
            (v) => parseInt(v['QUANTITY_$$input']) !== 0
          );
          let allRequestAmount = requestAmount;
          let allTotalAmount = totalAmount;
          let allDiscountAmount = discountAmount;

          addAllData.forEach((v) => {
            v.priceVatInclude =
              v['PRICE_VAT_INCLUDE_$$input'] * parseInt(v['QUANTITY_$$input']);
            v.price = Math.round(
              (v['PRICE_VAT_INCLUDE_$$input'] *
                parseInt(v['QUANTITY_$$input'])) /
                1.1
            );
            v.quantity = parseInt(v['QUANTITY_$$input']);
            v.requestAmount =
              v['PRICE_VAT_INCLUDE_$$input'] * parseInt(v['QUANTITY_$$input']);

            let total = v.priceVatInclude;
            let discount =
              v.discountAmount === undefined ? 0 : v.discountAmount;
            allRequestAmount += total - discount;
            allTotalAmount += total;
            allDiscountAmount += discount;
          });

          const allData = [...productsData, ...addAllData].map((v, i) => {
            v.checkId = i;
            return v;
          });
          setRequestAmount(allRequestAmount);
          setTotalAmount(allTotalAmount);
          setUnpaidAmount(allRequestAmount);
          setDiscountAmount(allDiscountAmount);
          setProductsData([...allData]);
        }
      });
  };

  const onDeletePayment = useCallback(
    async (id) => {
      try {
        await services.crm.payment.delete(id);
        modal
          .confirm({
            type: 'SUCCESS',
            msg: '삭제되었습니다.',
          })
          .then(() => {
            eventBus.emit('chartCountCallApi');
            init();
          });
      } catch (e) {
        console.log(e.description);
        modal.confirm({
          type: 'ERROR',
          msg: e.description,
        });
      }
    },
    [init]
  );

  const onClickDeletePayment = (payment, clickColumn) => {
    const itemName = payment?.['@@paymentTotalItems'].name || '';
    const paymentCount = payment?.['paymentTotalItems'].length - 1 || 0;

    // 컨텍스트 기획
    // 수납생성일을 클릭하여 삭제 > 삭제 팝업 문구 '정말로 삭제하시겠습니까?'
    // 특정 카테고리를 더블클릭하여, 컨텍스트메뉴를 노출하고 삭제 >
    // 클릭으로 진입한 시수술명 외 몇건의 수납건을 함께 삭제하게 되는지
    // 'itemName 외 n건의 수납을 삭제하시겠습니까?'
    // 아이템이 1건일 경우 > '정말로 삭제하시겠습니까?'
    let msg =
      clickColumn === 'firstPaidAt' ||
      payment?.['paymentTotalItems'].length === 1
        ? '정말로 삭제하시겠습니까?'
        : `${itemName} \n 외 ${paymentCount}건의 수납을 삭제하시겠습니까?`;

    modal
      .basic({
        body: msg,
        buttons: [
          {
            text: 'CANCEL',
            class: 'btn-default',
          },
          {
            text: 'CONFIRM',
            class: 'btn-primary',
          },
        ],
      })
      .then((idx) => {
        if (idx === 1) {
          onDeletePayment(payment.id);
        }
      });
  };

  const onClickDeleteDetail = (parentRow, row) => {
    let payload = {
      ...parentRow,
    };

    if (row.status === 'payouts') {
      // > 해당 amount만큼 수납액-amount / 미수액+amount
      payload.paidAmount = parentRow.paidAmount - row.amount;
      payload.payoutAmount = parentRow.payoutAmount - row.amount;
      payload.unpaidAmount = parentRow.unpaidAmount + row.amount;
      payload.payouts = parentRow.payouts.filter((v) => v.id !== row.id);
      payload.unpaidAmount =
        parentRow.requestAmount - payload.paidAmount - payload.refundAmount;
    } else if (row.status === 'refunds') {
      // > 해당 amount만큼 수납액+amount / 환불액-amount
      payload.paidAmount = parentRow.paidAmount + row.amount;
      payload.refundAmount = parentRow.refundAmount - row.amount;
      payload.refunds = parentRow.refunds.filter((v) => v.id !== row.id);
      payload.unpaidAmount =
        parentRow.requestAmount - payload.paidAmount - payload.refundAmount;
    }

    const {
      // eslint-disable-next-line no-unused-vars
      subRow: deletedKey,
      // eslint-disable-next-line no-unused-vars
      '@@merged': deletedKey2,
      // eslint-disable-next-line no-unused-vars
      '@@paymentTotalItems': deletedKey3,
      // eslint-disable-next-line no-unused-vars
      '@@rowspan': deletedKey4,
      ...others
    } = payload;

    onDeleteDetail(others);
  };

  const onDeleteDetail = useCallback(
    async (payload) => {
      try {
        await services.crm.payment.update(payload);
        modal
          .confirm({
            type: 'SUCCESS',
            msg: '삭제되었습니다.',
          })
          .then(() => {
            init();
          });
      } catch (e) {
        console.log(e.description);
        modal.confirm({
          type: 'ERROR',
          msg: e.description,
        });
      }
    },
    [init]
  );

  const onSubContextAction = (obj) => {
    const { item, row, parentRow } = obj;
    if (item === undefined) {
      return;
    }

    if (item.title === '수정') {
      if (row.status === 'payouts') {
        modal
          .custom({
            component: ModalPaymentsDetail,
            options: { row, parentRow },
          })
          .then(() => {
            init();
          });
      } else if (row.status === 'refunds') {
        modal
          .custom({
            component: ModalPaymentsRefund,
            options: { row: parentRow, subRow: row },
          })
          .then(() => {
            init();
          });
      }
      return;
    }

    if (item.title === '삭제') {
      onClickDeleteDetail(parentRow, row);
      return;
    }
  };

  const onContextAction = (obj) => {
    const { item, row, clickColumn } = obj;
    if (item === undefined) {
      return;
    }

    if (item.title === '수정') {
      modal
        .custom({
          component: ModalPaymentsUpdate,
          options: { row, customer },
        })
        .then((flag) => {
          if (flag === 1) {
            init();
          }
        });
      return;
    }

    if (item.title === '삭제') {
      onClickDeletePayment(row, clickColumn);
      return;
    }

    if (item.title === '미수수납') {
      modal
        .custom({
          component: ModalPaymentsUnpaid,
          options: { row },
        })
        .then((flag) => {
          if (flag === 1) {
            init();
          }
        });
      return;
    }

    if (item.title === '환불') {
      if (row.unpaidAmount > 0) {
        modal.basic({
          body: '먼저 미수수납처리 후, 환불처리하세요.',
          buttons: [{ text: 'CONFIRM', class: 'btn-primary' }],
        });
        return;
      }

      modal
        .custom({
          component: ModalPaymentsRefund,
          options: { row, subRow: undefined },
        })
        .then((flag) => {
          if (flag === 1) {
            init();
          }
        });
      return;
    }
  };

  const deletePayoutCreditItem = (idx) => {
    let list = payoutCreditItems;
    const deleteItem = payoutCreditItems[idx];
    //리스트 삭제
    list.splice(idx, 1);
    setPayoutCreditItems([...list]);

    //삭제된 아이템의 금액만큼 수납액에서 마이너스
    setPayoutAmount(payoutAmount - deleteItem.amount);
    setUnpaidAmount(unpaidAmount + deleteItem.amount);
    setCreditCardsTotalAmount(creditCardsTotalAmount - deleteItem.amount);
  };

  const deletePayoutDebitItem = (idx) => {
    let list = payoutDebitItems;
    const deleteItem = payoutDebitItems[idx];
    //리스트 삭제
    list.splice(idx, 1);
    setPayoutDebitItems([...list]);

    //삭제된 아이템의 금액만큼 수납액에서 마이너스
    setPayoutAmount(payoutAmount - deleteItem.amount);
    setUnpaidAmount(unpaidAmount + deleteItem.amount);
    setDebitCardsTotalAmount(debitCardsTotalAmount - deleteItem.amount);
  };

  const deletePayoutBankTransferItem = (idx) => {
    let list = payoutBankTransferItems;
    const deleteItem = payoutBankTransferItems[idx];
    //리스트 삭제
    list.splice(idx, 1);
    setPayoutBankTransferItems([...list]);

    //삭제된 아이템의 금액만큼 수납액에서 마이너스
    setPayoutAmount(payoutAmount - deleteItem.amount);
    setUnpaidAmount(unpaidAmount + deleteItem.amount);
    setBankTransferTotalAmount(bankTransferTotalAmount - deleteItem.amount);
  };

  const onCallbackProcessChangeToWating = (selectObj, nextProcess) => {
    if (!selectObj) return;
    setTreatmentSelectDoctors(selectObj.doctor);
    setTreatmentSelectFacialists(selectObj.facialist);
    setToggleNextProcessBtn(nextProcess);
  };

  const processChangeToConsultingWaiting = () => {
    //상담 대기할 상담사 선택 팝업
    if (nextProcess !== 'consulting_waiting') {
      useCustomerChart.useProcessChangeToWaiting({
        nextProcessStatus: 'consulting_waiting',
        counselors,
        treatmentSelectCounselors,
        callback: onCallbackProcessChangeToWating,
      });
    } else {
      setToggleNextProcessBtn('consulting_waiting');
    }
  };

  const processChangeToTreatmentWaiting = () => {
    //진료 대기할 의사 선택 팝업
    if (nextProcess !== 'treatment_waiting') {
      useCustomerChart.useProcessChangeToWaiting({
        nextProcessStatus: 'treatment_waiting',
        doctors,
        facialist,
        treatmentSelectDoctors,
        treatmentSelectFacialists,
        callback: onCallbackProcessChangeToWating,
      });
    } else {
      setToggleNextProcessBtn('treatment_waiting');
    }
  };

  const processChangeToSurgeryWaiting = () => {
    //시수술 대기할 의사/피부관리사 선택 팝업
    if (nextProcess !== 'surgery_waiting') {
      useCustomerChart.useProcessChangeToWaiting({
        nextProcessStatus: 'surgery_waiting',
        doctors,
        facialist,
        treatmentSelectDoctors,
        treatmentSelectFacialists,
        callback: onCallbackProcessChangeToWating,
      });
    } else {
      setToggleNextProcessBtn('surgery_waiting');
    }
  };

  const setToggleNextProcessBtn = (status) => {
    if (nextProcess === status) {
      setNextProcess(undefined);
    } else {
      setNextProcess(status);
    }
  };

  const memoBoilerplateCallApi = useCallback(async () => {
    try {
      const memoItems = await useCustomerChart.useGetMemoBoilerplate(tabName);
      setMemoBoilerplateList(memoItems);
    } catch (e) {
      console.log(e.description);
    }
  }, []);

  const onClickToggleBtn = () => {
    let toggle = paymentDetailMode;
    let _payment = payments.map((v) => {
      v.subTableToggle = !toggle;
      return v;
    });
    setPayments([..._payment]);
    togglePaymentDetailMode(!toggle);
  };

  const onChangeScheduledAt = (scheduledAt) => {
    scheduledAt === null
      ? setPaidAt(moment().format())
      : setPaidAt(moment(scheduledAt).format());
  };

  return (
    <div className="customer-payments chart-page">
      <div className="data-input">
        <div className="title">
          수납 입력
          <div className="right flex-row">
            {componentFlag === null ? (
              <>
                <button
                  className="btn btn-basic _small"
                  onClick={() => {
                    onClickCreatePayment();
                  }}
                >
                  신규수납입력
                </button>
              </>
            ) : (
              <>
                <button
                  className="btn btn-basic _small m-r-8"
                  style={{ display: 'block' }}
                  onClick={openModalAddPaymentSurgeries}
                >
                  시/수술 추가
                </button>
                <button
                  className="btn btn-basic _small m-r-8"
                  style={{ display: 'block' }}
                  onClick={openModalAddPaymentProducts}
                >
                  제품 추가
                </button>
              </>
            )}
          </div>
        </div>

        {componentFlag === 'payments' ? (
          <div className="card">
            <div className="form-wide m-b-32">
              <div className="form-control form-full">
                <DataTableAlpha
                  model={models.crm.paymentInputSurgeries}
                  data={surgeriesData}
                  hideBottom={true}
                  hideAllCheck={true}
                  onAction={onActionSurgeries}
                />
              </div>
            </div>
            {productsData.length > 0 && (
              <div className="form-wide p-b-32">
                <div className="form-control form-full">
                  <DataTableAlpha
                    model={models.crm.paymentInputProducts}
                    data={productsData}
                    hideBottom={true}
                    hideAllCheck={true}
                    onAction={onAction}
                  />
                </div>
              </div>
            )}
            <div className="total-price m-b-20">
              <div className="wrap-price">
                <p className="label">청구액(VAT 포함)</p>
                <p className="price">₩{currency(requestAmount)}</p>
                <p className="etc">
                  <span>원가</span>
                  <b>₩{currency(totalAmount)}</b>
                </p>
                <p className="etc">
                  <span>할인</span>
                  <b>
                    (-) ₩{currency(discountAmount)} (
                    {Math.ceil((discountAmount / totalAmount) * 100) || 0}%)
                  </b>
                </p>
              </div>
              <div className="wrap-price">
                <p className="label">수납액</p>
                <p className="price">₩{currency(payoutAmount)}</p>
              </div>
              <div className="wrap-price">
                <p className="label">미수액</p>
                <p className="price">₩{currency(unpaidAmount)}</p>
              </div>
            </div>

            <div className="body">
              <div className="form-double-side">
                <div className="form-control">
                  <label className="label-required">수납일</label>
                  {useCustomerChart.getScheduledAtDatePicker({
                    objScheduledAt: paidAt || new Date(),
                    onChangeScheduledAt,
                  })}
                </div>
                <div className="form-control">
                  <label>상담사</label>
                  <select
                    value={counselors.findIndex(
                      (existing) => existing.id === (receiptCounselors || {}).id
                    )}
                    onChange={(e) => {
                      // 고객의 담당상담사가 있는 경우
                      if (customer && customer.counselor) {
                        if (
                          counselors[e.target.value] &&
                          customer.counselor.id ===
                            counselors[e.target.value].id
                        ) {
                          setCounselorFlag(false);
                        } else {
                          setCounselorFlag(true);
                        }
                      }
                      // 고객의 담당상담사가 없는 경우
                      else {
                        if (e.target.value === 'null') {
                          setCounselorFlag(false);
                        } else {
                          setCounselorFlag(true);
                        }
                      }
                      setReceiptCounselors(counselors[e.target.value]);
                    }}
                  >
                    <option disabled key={-1} value={-1}>
                      -
                    </option>
                    {(counselors || []).map((o, idx) => (
                      <option key={idx} value={idx}>
                        {' '}
                        {o.name}{' '}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="form-control flex-wrap w-180">
                  <label className="required">담당자</label>
                  <select
                    value={users.findIndex(
                      (existing) => existing.id === (selectedUser || {}).id
                    )}
                    onChange={(e) => {
                      setSelectedUser(users[e.target.value]);
                    }}
                  >
                    <option disabled key={-1} value={-1}>
                      -
                    </option>
                    {(users || []).map((o, idx) => (
                      <option key={idx} value={idx}>
                        {o.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              {counselorFlag ? (
                <div className="form-wide m-b-20">
                  <span className="m-r-20">
                    {counselorFlag ? ' [상담사] ' : null}
                    {'를 해당 고객의 담당으로 지정하시겠습니까?'}
                  </span>
                  <span className="m-r-20">
                    <RadioInput
                      name="saveCustomer"
                      checked={radioValue}
                      onChange={() => setRadioValue(true)}
                    />{' '}
                    예
                  </span>
                  <span className="m-r-20">
                    <RadioInput
                      name="saveCustomer"
                      checked={!radioValue}
                      onChange={() => setRadioValue(false)}
                    />{' '}
                    아니오
                  </span>
                </div>
              ) : null}
            </div>
            <div className="flex-row">
              <div className="flex-wrap m-b-20 card-payouts m-r-12">
                {payoutCreditItems.map((v, i) => {
                  return (
                    <div className="form-double-side" key={i}>
                      <div className="form-control">
                        {i === 0 && <label>신용카드</label>}
                        {v.type === 'select' ? (
                          <select
                            value={v.companyName}
                            onChange={(e) => {
                              if (e.target.value === '직접입력') {
                                let list = payoutCreditItems.filter(
                                  (v, idx) => {
                                    if (i === idx) {
                                      v.type = 'input';
                                    }
                                    return v;
                                  }
                                );
                                setPayoutCreditItems([...list]);
                              } else {
                                let list = payoutCreditItems.filter(
                                  (v, idx) => {
                                    if (i === idx) {
                                      v.companyName = e.target.value;
                                    }
                                    return v;
                                  }
                                );
                                setPayoutCreditItems([...list]);
                              }
                            }}
                          >
                            <option value={''} hidden disabled>
                              카드사 선택
                            </option>
                            {payoutCards.map((bank, i) => (
                              <option key={i} value={bank}>
                                {bank}
                              </option>
                            ))}
                          </select>
                        ) : (
                          <Input
                            type="text"
                            placeholder="직접입력"
                            defaultValue={v.companyName}
                            onBlur={(e) => {
                              let list = payoutCreditItems.filter((v, idx) => {
                                if (i === idx) {
                                  v.companyName = e.target.value;
                                }
                                return v;
                              });
                              setPayoutCreditItems([...list]);
                            }}
                          />
                        )}
                      </div>
                      <div className="form-control">
                        {i === 0 && <label>수납액</label>}
                        <Input
                          value={currency(v.amount)}
                          onBlur={(e) => {
                            let value = removeCurrency(e.target.value);
                            if (!isNaN(Number(value))) {
                              let cardTotal = 0;
                              payoutCreditItems.forEach((v, idx) => {
                                if (i === idx) {
                                  cardTotal += Number(value);
                                } else {
                                  cardTotal += v.amount;
                                }
                              });
                              const totalAmount =
                                cardTotal +
                                debitCardsTotalAmount +
                                bankTransferTotalAmount +
                                cashTotalAmount;
                              const excessAmount = Math.abs(
                                requestAmount - totalAmount
                              );
                              const maxAmount = Number(value) - excessAmount;
                              if (totalAmount > requestAmount) {
                                toast.error('청구금액 초과');
                                let cardsTotalAmount = 0;
                                const list = payoutCreditItems.filter(
                                  (v, idx) => {
                                    if (i === idx) {
                                      v.amount = maxAmount;
                                    }
                                    cardsTotalAmount += v.amount;
                                    return v;
                                  }
                                );
                                setPayoutCreditItems([...list]);
                                setCreditCardsTotalAmount(cardsTotalAmount);
                                setPayoutAmount(requestAmount);
                                setUnpaidAmount(0);
                              } else {
                                setCreditCardsTotalAmount(cardTotal);
                                setPayoutAmount(totalAmount);
                                setUnpaidAmount(requestAmount - totalAmount);
                              }
                            }
                          }}
                          onChange={(e) => {
                            let value = removeCurrency(e.target.value);
                            if (!isNaN(Number(value))) {
                              let list = payoutCreditItems.filter((v, idx) => {
                                if (i === idx) {
                                  v.amount = Number(value);
                                }
                                return v;
                              });
                              setPayoutCreditItems([...list]);
                            }
                          }}
                        />
                      </div>
                      {i === 0 && (
                        <button
                          className="btn btn-normal-gray _small"
                          onClick={() => {
                            setPayoutCreditItems([
                              ...payoutCreditItems,
                              {
                                type: 'select',
                                paymentMethod: 'credit_card',
                                companyName: '',
                                amount: 0,
                              },
                            ]);
                          }}
                        >
                          추가
                        </button>
                      )}
                      {i > 0 && (
                        <button
                          className="btn btn-danger _small"
                          onClick={() => {
                            deletePayoutCreditItem(i);
                          }}
                        >
                          삭제
                        </button>
                      )}
                    </div>
                  );
                })}
              </div>

              <div className="flex-wrap m-b-20 card-payouts">
                {payoutDebitItems.map((v, i) => {
                  return (
                    <div key={i} className="form-double-side">
                      <div className="form-control">
                        {i === 0 && <label>체크카드</label>}
                        {v.type === 'select' ? (
                          <select
                            value={v.companyName}
                            onChange={(e) => {
                              if (e.target.value === '직접입력') {
                                let list = payoutDebitItems.filter((v, idx) => {
                                  if (i === idx) {
                                    v.type = 'input';
                                  }
                                  return v;
                                });
                                setPayoutDebitItems([...list]);
                              } else {
                                let list = payoutDebitItems.filter((v, idx) => {
                                  if (i === idx) {
                                    v.companyName = e.target.value;
                                  }
                                  return v;
                                });
                                setPayoutDebitItems([...list]);
                              }
                            }}
                          >
                            <option value={''} hidden disabled>
                              카드사 선택
                            </option>
                            {payoutCards.map((bank, i) => (
                              <option key={i} value={bank}>
                                {bank}
                              </option>
                            ))}
                          </select>
                        ) : (
                          <Input
                            type="text"
                            placeholder="직접입력"
                            defaultValue={v.companyName}
                            onBlur={(e) => {
                              let list = payoutDebitItems.filter((v, idx) => {
                                if (i === idx) {
                                  v.companyName = e.target.value;
                                }
                                return v;
                              });
                              setPayoutDebitItems([...list]);
                            }}
                          />
                        )}
                      </div>
                      <div className="form-control">
                        {i === 0 && <label>수납액</label>}
                        <Input
                          value={currency(v.amount)}
                          onBlur={(e) => {
                            let value = removeCurrency(e.target.value);
                            if (!isNaN(Number(value))) {
                              let cardTotal = 0;
                              payoutDebitItems.forEach((v, idx) => {
                                if (i === idx) {
                                  cardTotal += Number(value);
                                } else {
                                  cardTotal += v.amount;
                                }
                              });
                              const totalAmount =
                                cardTotal +
                                creditCardsTotalAmount +
                                bankTransferTotalAmount +
                                cashTotalAmount;
                              const excessAmount = Math.abs(
                                requestAmount - totalAmount
                              );
                              const maxAmount = Number(value) - excessAmount;
                              if (totalAmount > requestAmount) {
                                toast.error('청구금액 초과');
                                let cardsTotalAmount = 0;
                                const list = payoutDebitItems.filter(
                                  (v, idx) => {
                                    if (i === idx) {
                                      v.amount = maxAmount;
                                    }
                                    cardsTotalAmount += v.amount;
                                    return v;
                                  }
                                );
                                setPayoutDebitItems([...list]);
                                setDebitCardsTotalAmount(cardsTotalAmount);
                                setPayoutAmount(requestAmount);
                                setUnpaidAmount(0);
                              } else {
                                setDebitCardsTotalAmount(cardTotal);
                                setPayoutAmount(totalAmount);
                                setUnpaidAmount(requestAmount - totalAmount);
                              }
                            }
                          }}
                          onChange={(e) => {
                            let value = removeCurrency(e.target.value);
                            if (!isNaN(Number(value))) {
                              let list = payoutDebitItems.filter((v, idx) => {
                                if (i === idx) {
                                  v.amount = Number(value);
                                }
                                return v;
                              });
                              setPayoutDebitItems([...list]);
                            }
                          }}
                        />
                      </div>
                      {i === 0 && (
                        <button
                          className="btn btn-normal-gray _small"
                          onClick={() => {
                            setPayoutDebitItems([
                              ...payoutDebitItems,
                              {
                                type: 'select',
                                paymentMethod: 'debit_card',
                                companyName: '',
                                amount: 0,
                              },
                            ]);
                          }}
                        >
                          추가
                        </button>
                      )}
                      {i > 0 && (
                        <button
                          className="btn btn-danger _small"
                          onClick={() => {
                            deletePayoutDebitItem(i);
                          }}
                        >
                          삭제
                        </button>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>

            <div className="card-payouts m-b-20">
              {payoutBankTransferItems.map((v, i) => {
                return (
                  <div key={i} className="form-double-side">
                    <div className="form-control">
                      {i === 0 && <label>계좌이체</label>}
                      {v.type === 'select' ? (
                        <select
                          value={v.companyName}
                          onChange={(e) => {
                            if (e.target.value === '직접입력') {
                              let list = payoutBankTransferItems.filter(
                                (v, idx) => {
                                  if (i === idx) {
                                    v.type = 'input';
                                  }
                                  return v;
                                }
                              );
                              setPayoutBankTransferItems([...list]);
                            } else {
                              let list = payoutBankTransferItems.filter(
                                (v, idx) => {
                                  if (i === idx) {
                                    v.companyName = e.target.value;
                                  }
                                  return v;
                                }
                              );
                              setPayoutBankTransferItems([...list]);
                            }
                          }}
                        >
                          <option value={''} hidden disabled>
                            은행 선택
                          </option>
                          {payoutBankTransfers.map((bank, i) => (
                            <option key={i} value={bank}>
                              {bank}
                            </option>
                          ))}
                        </select>
                      ) : (
                        <Input
                          type="text"
                          placeholder="직접입력"
                          defaultValue={v.companyName}
                          onBlur={(e) => {
                            let list = payoutBankTransferItems.filter(
                              (v, idx) => {
                                if (i === idx) {
                                  v.companyName = e.target.value;
                                }
                                return v;
                              }
                            );
                            setPayoutBankTransferItems([...list]);
                          }}
                        />
                      )}
                    </div>
                    <div className="form-control">
                      {i === 0 && <label>수납액</label>}
                      <Input
                        value={currency(v.amount)}
                        onBlur={(e) => {
                          let value = removeCurrency(e.target.value);
                          if (!isNaN(Number(value))) {
                            let bankTotal = 0;
                            payoutBankTransferItems.forEach((v, idx) => {
                              if (i === idx) {
                                bankTotal += Number(value);
                              } else {
                                bankTotal += v.amount;
                              }
                            });
                            const totalAmount =
                              bankTotal +
                              creditCardsTotalAmount +
                              debitCardsTotalAmount +
                              cashTotalAmount;
                            const excessAmount = Math.abs(
                              requestAmount - totalAmount
                            );
                            const maxAmount = Number(value) - excessAmount;
                            if (totalAmount > requestAmount) {
                              toast.error('청구금액 초과');
                              let cardsTotalAmount = 0;

                              const list = payoutBankTransferItems.filter(
                                (v, idx) => {
                                  if (i === idx) {
                                    v.amount = maxAmount;
                                  }
                                  cardsTotalAmount += v.amount;
                                  return v;
                                }
                              );
                              setPayoutBankTransferItems([...list]);
                              setBankTransferTotalAmount(cardsTotalAmount);
                              setPayoutAmount(requestAmount);
                              setUnpaidAmount(0);
                            } else {
                              setBankTransferTotalAmount(bankTotal);
                              setPayoutAmount(totalAmount);
                              setUnpaidAmount(requestAmount - totalAmount);
                            }
                          }
                        }}
                        onChange={(e) => {
                          let value = removeCurrency(e.target.value);
                          if (!isNaN(Number(value))) {
                            let list = payoutBankTransferItems.filter(
                              (v, idx) => {
                                if (i === idx) {
                                  v.amount = Number(value);
                                }
                                return v;
                              }
                            );
                            setPayoutBankTransferItems([...list]);
                          }
                        }}
                      />
                    </div>
                    {i === 0 && (
                      <button
                        className="btn btn-normal-gray _small"
                        onClick={() => {
                          setPayoutBankTransferItems([
                            ...payoutBankTransferItems,
                            {
                              type: 'select',
                              paymentMethod: 'bank_transfer',
                              companyName: '',
                              amount: 0,
                            },
                          ]);
                        }}
                      >
                        추가
                      </button>
                    )}
                    {i > 0 && (
                      <button
                        className="btn btn-danger _small"
                        onClick={() => {
                          deletePayoutBankTransferItem(i);
                        }}
                      >
                        삭제
                      </button>
                    )}
                  </div>
                );
              })}
            </div>

            <div className="form-control">
              <div className="flex-row items-center">
                <div className="flex-wrap payment-input">
                  <label>현금</label>
                </div>
              </div>
              <div className="flex-row items-center">
                <div className="flex-wrap payment-input">
                  <Input
                    value={currency(payoutCash)}
                    onChange={(e) => {
                      let value = removeCurrency(e.target.value);
                      if (!isNaN(Number(value))) {
                        setPayoutCash(Number(value));
                      }
                    }}
                    onBlur={(e) => {
                      if (e.target.value !== '0') {
                        let value = removeCurrency(e.target.value);
                        if (!isNaN(Number(value))) {
                          let cashTotal = Number(value);
                          const totalAmount =
                            cashTotal +
                            creditCardsTotalAmount +
                            debitCardsTotalAmount +
                            bankTransferTotalAmount;
                          const excessAmount = Math.abs(
                            requestAmount - totalAmount
                          );
                          const maxAmount = Number(value) - excessAmount;
                          if (totalAmount > requestAmount) {
                            toast.error('청구금액 초과');
                            setPayoutCash(maxAmount);
                            setPayoutAmount(requestAmount);
                            setCashTotalAmount(requestAmount);
                            setUnpaidAmount(0);
                          } else {
                            setCashTotalAmount(cashTotal);
                            setPayoutAmount(totalAmount);
                            setUnpaidAmount(requestAmount - totalAmount);
                          }

                          //payouts
                          let cashItem = payouts.filter((v) => {
                            return v.paymentMethod === 'cash';
                          });
                          if (cashItem.length) {
                            let payoutsItems = payouts.map((v) => {
                              if (v.paymentMethod === 'cash') {
                                v.amount =
                                  totalAmount > requestAmount
                                    ? maxAmount
                                    : Number(value);
                              }
                              return v;
                            });
                            setPayouts([...payoutsItems]);
                          } else {
                            let payout = {
                              paymentMethod: 'cash',
                              amount:
                                totalAmount > requestAmount
                                  ? maxAmount
                                  : Number(value),
                              paidAt: moment(paidAt).format(
                                'YYYY-MM-DDTHH:mm:ss'
                              ),
                            };
                            setPayouts([...payouts, payout]);
                          }
                        }
                      }
                    }}
                  />
                </div>
                <div className="flex-wrap payment-input m-l-8">
                  {' '}
                  <label>현금영수증</label>
                </div>
                <div className="flex-wrap payment-input">
                  <Checkbox
                    className="flex-wrap m-l-8"
                    checked={cashReceipt}
                    toggleHandler={() => {
                      setCashReceipt(!cashReceipt);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="form-wide">
              <div className="form-control">
                <label>{translate('MEMO')}</label>
                {memoBoilerplateList.length > 0 && (
                  <div className="wrap-btn-boilerplate-memo">
                    {memoBoilerplateList.slice(0, 5).map((v, i) => (
                      <button
                        className="btn btn-sm btn-white _ssmall"
                        key={i}
                        onClick={() =>
                          setMemo(
                            (memo === '<p><br></p>' ? '' : memo || '') +
                              v.contents
                          )
                        }
                      >
                        {v.title.slice(0, 5) + (v.title.length > 5 ? '…' : '')}
                      </button>
                    ))}
                  </div>
                )}
                <QuillTextField
                  tabName={tabName}
                  value={memo || ''}
                  setValue={(v) => setMemo(v)}
                  setMemoBoilerplateList={setMemoBoilerplateList}
                  placeholder="메모를 입력해주세요."
                />
              </div>
            </div>

            <div className="form-control" style={{ marginTop: '30px' }}>
              <label>다음 진행</label>
              <div className="flex-row">
                <button
                  className={`btn flex-wrap _small m-r-8 ${
                    nextProcess === 'consulting_waiting'
                      ? 'btn-basic'
                      : 'btn-normal'
                  }`}
                  onClick={processChangeToConsultingWaiting}
                >
                  추가상담대기
                </button>
                <button
                  className={`btn flex-wrap _small m-r-8 ${
                    nextProcess === 'treatment_waiting'
                      ? 'btn-basic'
                      : 'btn-normal'
                  }`}
                  onClick={processChangeToTreatmentWaiting}
                >
                  진료대기
                </button>
                <button
                  className={`btn flex-wrap _small m-r-8 ${
                    nextProcess === 'surgery_waiting'
                      ? 'btn-basic'
                      : 'btn-normal'
                  }`}
                  onClick={processChangeToSurgeryWaiting}
                >
                  시/수술대기
                </button>
                <button
                  className={`btn flex-wrap _small m-r-8 ${
                    nextProcess === 'leave' ? 'btn-basic' : 'btn-normal'
                  }`}
                  onClick={() => {
                    setToggleNextProcessBtn('leave');
                  }}
                >
                  퇴원
                </button>
                <button
                  className={`btn flex-wrap _small m-r-8 ${
                    nextProcess === 'payment_done' ? 'btn-basic' : 'btn-normal'
                  }`}
                  onClick={() => {
                    setToggleNextProcessBtn('payment_done');
                  }}
                >
                  완료
                </button>
              </div>
            </div>
            <SelectNextProcess
              nextProcess={nextProcess}
              counselors={counselors}
              treatmentSelectCounselors={treatmentSelectCounselors}
              setTreatmentSelectCounselors={setTreatmentSelectCounselors}
              doctors={doctors}
              treatmentSelectDoctors={treatmentSelectDoctors}
              setTreatmentSelectDoctors={setTreatmentSelectDoctors}
              facialist={facialist}
              treatmentSelectFacialists={treatmentSelectFacialists}
              setTreatmentSelectFacialists={setTreatmentSelectFacialists}
            />

            <div className="flex-row wrap-bottom-btns">
              <button
                className="btn btn-cancel _small m-r-8"
                onClick={actionInit}
              >
                {'입력취소'}
              </button>
              <button
                className="btn btn-basic _small"
                disabled={`${loadingBtnDisabledFlag ? 'disabled' : ''}`}
                onClick={beforeSave}
              >
                수납완료
              </button>
            </div>
          </div>
        ) : null}
      </div>

      <div className="summary-table">
        <div className="title m-b-16">수납 요약</div>
        <div className="data-table">
          <table>
            <thead>
              <tr>
                <th>청구액</th>
                <th>수납액</th>
                <th>할인액</th>
                <th>환불액</th>
                <th>미수액</th>
                <th>최근 수납생성일</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{currency(summary.requestAmount || 0)}</td>
                <td>{currency(summary.paidAmount || 0)}</td>
                <td>{currency(summary.discountAmount || 0)}</td>
                <td>{currency(summary.refundAmount || 0)}</td>
                <td>{currency(summary.unpaidAmount || 0)}</td>
                <td>
                  {summary.lastPaidAt === null ||
                  summary.lastPaidAt === undefined
                    ? ''
                    : moment(summary.lastPaidAt || new Date()).format(
                        'YYYY.MM.DD'
                      )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div className="wrap-data-table data-table">
        <div className="flex-row">
          <div className="flex-wrap title m-r-8">상세수납내역</div>
          <div className="flex-wrap toggle">
            <button
              className={`
              header-toggle-btn 
              ${paymentDetailMode ? '' : 'selected'}`}
              onClick={onClickToggleBtn}
            >
              <label className="header-toggle-btn-label">
                {paymentDetailMode ? '열기' : '닫기'}
              </label>
            </button>
          </div>
        </div>
        <DataTableAlpha
          className="payment-sticky"
          model={modelPaymentTab}
          data={payments}
          total={total}
          params={params}
          onParams={onParams}
          bottomPositionInner
          hasLine
          mergeColumn={[
            {
              categoryName: null,
              name: null,
              quantity: null,
              price: null,
              priceVatInclude: null,
              discountAmount: null,
            },
          ]}
          expandable={true}
          subModel={models.crm.paymentSubDetail}
          onContextAction={onContextAction}
          onSubContextAction={onSubContextAction}
          onSubAction={onSubAction}
          onAction={onTableAction}
        />
      </div>
    </div>
  );
};

CustomerPayment.propTypes = {
  appointment: PropTypes.object,
  payment: PropTypes.object,
  nurseChart: PropTypes.object,
  customer: PropTypes.object,
  openFlag: PropTypes.object,
  closeFlag: PropTypes.bool,
};

export default observer(CustomerPayment);
