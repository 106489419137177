import React, { useEffect, useState, useCallback } from 'react';
import SelectBox from 'components/common/SelectBox';
import { observer } from 'mobx-react';
import Select from 'react-select';
import ConditionForm from './ConditionForm';
import PropTypes from 'prop-types';
import models from 'models';
import { translate } from 'filters';
import { useTranslation } from 'hooks/useTranslation';
import { useAuth } from 'store/auth';
import { useClinicCheck } from 'store/clinicCheck';

const ConditionRow = ({
  uiKeyName,
  query,
  include = true,
  conditionId,
  onCreateGroup,
  onRemoveGroup,
  onCreate,
  onChange,
  onRemove,
  rowIdx,
  groupIdx,
  groupId,
  selectedConditions,
}) => {
  const clinicCheck = useClinicCheck();
  const auth = useAuth();
  const translation = useTranslation();
  const [selectOptions, setSelectOptions] = useState([
    {
      value: true,
      label: translate('INCLUDE'),
    },
    {
      value: false,
      label: translate('EXCLUDE'),
    },
  ]);
  const [typeOptions, setTypeOptions] = useState([]);
  useEffect(() => {
    const model = models.crm.messageTargetCondition;
    const options = conditionOptions({
      model,
      selectedUiKeyNames: selectedConditions,
      translation: translation,
    });
    setTypeOptions(options);
  }, [
    models.crm.messageTargetCondition,
    selectedConditions,
    translation,
    translation.locale,
  ]);

  const [category, item] = uiKeyName ? uiKeyName.split('.') : [null, null];
  const initUiObj = {
    uiKeyName: uiKeyName ? uiKeyName : null,
    category: category,
    item: item,
    query: query ? query : null,
    include: include,
    conditionId: conditionId,
  };
  const [uiObj, setUiObj] = useState(initUiObj);
  const onChangeHandler = useCallback(
    (e) => {
      const { name, value } = e.target;
      const __uiKeyName = 'uiKeyName';
      if (name === __uiKeyName) {
        const [category, item] = value.split('.');

        //문자전송대상 검색 시 , 아래의 조건에선 "제외" 선택 못하도록 수정
        //고객조건의 최종방문일, 생일 / 예약조건의 예약일자
        if (
          (category === 'customer' &&
            (item === 'lastVisitAt' || item === 'birthday')) ||
          (category === 'appointment' && item === 'scheduledAt')
        ) {
          setSelectOptions([
            {
              value: true,
              label: translate('INCLUDE'),
            },
          ]);
        } else {
          setSelectOptions([
            {
              value: true,
              label: translate('INCLUDE'),
            },
            {
              value: false,
              label: translate('EXCLUDE'),
            },
          ]);
        }

        let _query = null;
        if (
          clinicCheck.isClinicStantopCounselor &&
          category === 'customer' &&
          item === 'counselor'
        ) {
          _query = [auth.me.id];
        }
        setUiObj((uiObj) => ({
          ...uiObj,
          [name]: value,
          category: category,
          item: item,
          query: _query,
          include: true,
        }));
      } else {
        setUiObj((uiObj) => ({
          ...uiObj,
          [name]: value,
        }));
      }
    },
    [translation]
  );

  useEffect(() => {
    onChange({
      ...uiObj,
      uiKeyName: uiObj.uiKeyName,
      include: uiObj.include,
      query: uiObj.query,
    });
  }, [onChange, uiObj]);

  return (
    <div className="condition-row">
      <div className="condition-label">
        <span>
          {translate('SEARCH_CONDITION')}
          {rowIdx + 1}
        </span>
      </div>
      <div className="condition-category">
        <Select
          className="select"
          classNamePrefix="uno-select"
          options={typeOptions}
          value={[...typeOptions.flatMap((group) => group.options)].filter(
            (option) => option.value === uiKeyName
          )}
          onChange={(e) => {
            const payload = {
              target: {
                name: 'uiKeyName',
                value: e.value,
              },
            };
            onChangeHandler(payload);
          }}
          placeholder={translate('PLACEHOLDER_SELECT_CONDITION_TYPE')}
          isSearchable={false}
          formatGroupLabel={formatGroupLabel}
          styles={{
            control: SelectControlStyle,
          }}
          components={{
            IndicatorSeparator: null,
          }}
        />
      </div>
      <div className="condition-form">
        <ConditionForm
          uiKeyName={uiObj.uiKeyName}
          conditionId={uiObj.conditionId}
          onChange={onChangeHandler}
          query={uiObj.query}
        />
      </div>
      <div className="condition-include">
        <SelectBox
          className="select-condition-include select"
          name="include"
          value={uiObj.include}
          disabled={uiObj.uiKeyName === null}
          options={selectOptions}
          onChange={onChangeHandler}
          components={{ IndicatorSeparator: null }}
          styles={{ control: SelectControlStyle }}
        />
      </div>
      <div className="condition-remove-row">
        {rowIdx === 0 && groupIdx === 0 && (
          <>
            <button
              className="btn btn-normal"
              onClick={() => {
                onCreate();
              }}
            >
              +그리고
            </button>
            <button
              className="btn btn-normal"
              onClick={() => {
                onCreateGroup();
              }}
            >
              +또는
            </button>
          </>
        )}

        {/* 그리고 */}
        {rowIdx !== 0 && groupIdx === 0 && (
          <button
            className="btn-remove"
            onClick={() => {
              onRemove({ conditionId });
            }}
          >
            삭제
          </button>
        )}

        {/* 또는 */}
        {rowIdx === 0 && groupIdx > 0 && (
          <>
            <button
              className="btn btn-normal"
              onClick={() => {
                onCreate();
              }}
            >
              +그리고
            </button>
            <button
              className="btn-remove"
              onClick={() => {
                onRemoveGroup({ groupId });
              }}
            >
              삭제
            </button>
          </>
        )}

        {/* 또는 */}
        {rowIdx !== 0 && groupIdx > 0 && (
          <button
            className="btn-remove"
            onClick={() => {
              onRemove({ conditionId });
            }}
          >
            삭제
          </button>
        )}
      </div>
    </div>
  );
};

const formatGroupLabel = (data) => (
  <div style={{ fontWeight: 'bold', display: 'flex' }}>
    <span>{data.label}</span>
  </div>
);

const SelectControlStyle = (props) => ({
  ...props,
  cursor: 'pointer',
  border: 0,
  boxShadow: 'none',
  minHeight: '1em',
  fontSize: '.9em',
});

const conditionOptions = ({ model, selectedUiKeyNames }) => {
  const _categoried = {};
  model.keys.forEach((key) => {
    const { category, item } = key;
    const isDisabled = selectedUiKeyNames.indexOf(`${category}.${item}`) > -1;
    if (!Object.prototype.hasOwnProperty.call(_categoried, category)) {
      _categoried[category] = {
        label: translate(`LABEL_CONDITION_${category}`.toUpperCase()),
        options: [],
      };
    }
    _categoried[category].options.push({
      label: translate(`LABEL_CONDITION_${category}_${item}`.toUpperCase()),
      value: `${category}.${item}`,
      isDisabled: isDisabled,
    });
  });
  return Object.values(_categoried);
};

ConditionRow.propTypes = {
  translation: PropTypes.object,
  rowIdx: PropTypes.number,
  onCreateGroup: PropTypes.func,
  onRemoveGroup: PropTypes.func,
  onCreate: PropTypes.func,
  onChange: PropTypes.func,
  onRemove: PropTypes.func,
  selectedConditions: PropTypes.array,
  include: PropTypes.bool,
  uiKeyName: PropTypes.string,
  // oneOfType: 여러 종류중 하나의 종류가 될 수 있는 객체
  // object인경우 : 검색항목 최종방문일,생일,예약일자 처럼 데이터피커 2개가 노출되는 경우
  // array인 경우 : 그외
  query: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  conditionId: PropTypes.number,
  groupIdx: PropTypes.number,
  groupId: PropTypes.number,
};

export default observer(ConditionRow);
