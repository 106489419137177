import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { currency, currencySymbol, removeCurrency, translate } from 'filters';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { RadioInput } from 'components/common/RadioInput';

const Input = styled.input`
  font-size: 14px;
  height: auto;
  background-color: #fff;
  width: 100%;
  padding: 8px 20px;
  border-radius: 4px;
  border: 1px solid #e9e9ed;
`;

const RadioInputWrapper = styled(RadioInput)`
  margin-right: 8px;
`;

const translateAndReplace = (str, num) => translate(str).replace(/%s/, num);
const SurgeryItem = ({ item, uId, itemKey, onChangeItem, onRemoveItem }) => {
  const [obj, setObj] = useState(item);

  useEffect(() => {
    onChangeItem(obj);
  }, [obj, onChangeItem]);

  const onChangeHandler = (e) => {
    let adjustObj = {};

    const { name, value } = e.target;
    if (name === 'treatmentCount' && parseInt(value) === 1) {
      adjustObj['nextDay'] = 0;
      adjustObj['nextMonth'] = 0;
    } else if (name === 'treatmentCount') {
      adjustObj['nextDay'] = 1;
      adjustObj['nextMonth'] = 0;
    } else if (name === 'nextDay') {
      adjustObj['nextMonth'] = 0;
    } else if (name === 'nextMonth') {
      adjustObj['nextDay'] = 0;
    }

    setObj({
      ...obj,
      ...adjustObj,
      [name]: value,
    });
  };

  return (
    <div className="item-group">
      <div>
        <div className="flex-row m-b-20">
          <div className="form-control m-r-8" style={{ width: '150%' }}>
            <label className="label-required">시/수술명</label>
            <Input
              name="name"
              value={obj['name']}
              onChange={onChangeHandler}
              placeholder="시/수술명을 입력하세요."
            />
          </div>

          <div className="form-control m-r-8">
            <label className="label-required">
              {translate('SURGERY_NUMOF_LABEL')}
            </label>
            <SelectNumOfSurgery
              className="item-select"
              onChange={onChangeHandler}
              name={'treatmentCount'}
              value={obj['treatmentCount']}
            />
          </div>

          <div className="form-control">
            <label className="label-required">적정시술주기</label>
            <SelectNextVisitDate
              className="item-select"
              name={'nextPeriod'}
              valueObj={{
                nextDay: obj['nextDay'],
                nextMonth: obj['nextMonth'],
              }}
              onChange={onChangeHandler}
            />
          </div>
        </div>
        <div className="flex-row m-b-20">
          <div className="form-control m-r-8">
            <label className="label-required">VAT 포함가격</label>
            <Input
              type="text"
              value={
                `${currencySymbol()} ${currency(
                  Math.round(parseInt(obj.priceVatInclude))
                )}` || ''
              }
              onChange={(e) => {
                obj.priceVatInclude = parseInt(
                  removeCurrency(e.target.value.replace('₩', ''))
                );
                obj.price = obj.vatIncluded
                  ? Math.round(obj.priceVatInclude / 1.1)
                  : obj.priceVatInclude;
                setObj({ ...obj });
              }}
            />
          </div>
          <div className="form-control">
            <label>VAT 미포함가격</label>
            <Input
              type="text"
              value={
                `${currencySymbol()} ${currency(
                  Math.round(parseInt(obj.price))
                )}` || ''
              }
              onChange={(e) => {
                obj.price = parseInt(
                  removeCurrency(e.target.value.replace('₩', ''))
                );
                obj.priceVatInclude = obj.vatIncluded
                  ? Math.round(obj.price * 1.1)
                  : obj.price;
                setObj({ ...obj });
              }}
            />
          </div>
        </div>
        <div className="form-control">
          <label className="label-required m-b-16">과세여부</label>
          <span>
            <RadioInputWrapper
              name={`vatIncluded-${uId}`}
              checked={obj['vatIncluded'] ? 'checked' : ''}
              value={true}
              onChange={() => {
                obj['price'] = Math.round(obj['priceVatInclude'] / 1.1) || '0';
                setObj({
                  ...obj,
                  vatIncluded: true,
                });
              }}
            />
            <span className="m-r-20">과세</span>
          </span>
          <span>
            <RadioInputWrapper
              name={`vatIncluded-${uId}`}
              checked={!obj['vatIncluded'] ? 'checked' : ''}
              value={false}
              onChange={() => {
                obj['price'] = Math.round(obj['priceVatInclude']) || '0';
                setObj({
                  ...obj,
                  vatIncluded: false,
                });
              }}
            />
            <span>비과세</span>
          </span>
        </div>
        {obj['itemRef'] !== 0 &&
        (!Object.prototype.hasOwnProperty.call(obj, 'id') ||
          obj['id'] === null) ? (
          <div className="wrap-del-btn">
            <button
              className="btn btn-danger _small"
              onClick={() => onRemoveItem(obj['itemRef'], itemKey)}
            >
              {translate('REMOVE_ITEM')}
            </button>
          </div>
        ) : null}
      </div>
    </div>
  );
};

const SelectNextVisitDate = observer(
  ({ className, valueObj, onChange, name }) => {
    const getNextDateObjToString = (obj) => {
      const nextDay = obj['nextDay'];
      const nextMonth = obj['nextMonth'];

      if (nextDay && nextDay !== 0) return `d__${nextDay}`;
      if (nextMonth && nextMonth !== 0) return `m__${nextMonth}`;
      return 'n_0';
    };

    const [value, setValue] = useState(getNextDateObjToString(valueObj));

    const days = 7;
    const months = 12;

    const dayList = [...Array(days).keys()]
      .map((i) => i + 1)
      .filter((i) => {
        return i !== 1 && !(i > 7 && i < 15);
      });
    const monthList = [...Array(months).keys()]
      .map((i) => i + 1)
      .filter((i) => i !== 1);

    useEffect(() => {
      const str = getNextDateObjToString(valueObj);
      setValue(str);
    }, [valueObj]);

    const onChangeHandler = (e) => {
      const type = e.target.value.split('__')[0];
      const value = type !== 'n' ? e.target.value.split('__')[1] : 0;
      const name = type === 'd' ? 'nextDay' : 'nextMonth';

      onChange({
        target: {
          name: name,
          value: parseInt(value),
        },
      });
    };

    return (
      <select
        name={name}
        value={value}
        onChange={onChangeHandler}
        // disabled={disabled ? disabled : null}
        className={`w-100 ${className}`}
      >
        {/* {disabled && <option value={"d__0"}>없음</option>} */}
        <option value={'d__0'}>없음</option>
        <option value={`d__1`}>
          {translateAndReplace('SURGERY_AFTER_DAY', 1)}
        </option>
        {dayList.map((i) => {
          return (
            <option value={`d__${i}`} key={i}>
              {translateAndReplace('SURGERY_AFTER_DAYS', i)}
            </option>
          );
        })}
        <option value={`d__15`}>
          {translateAndReplace('SURGERY_AFTER_DAYS', 15)}
        </option>
        <option value={`m__1`}>
          {translateAndReplace('SURGERY_AFTER_MONTH', 1)}
        </option>
        {monthList.map((i) => {
          return (
            <option value={`m__${i}`} key={i}>
              {translateAndReplace('SURGERY_AFTER_MONTHS', i)}
            </option>
          );
        })}
      </select>
    );
  }
);

const SelectNumOfSurgery = observer(({ name, value, onChange, className }) => {
  const maxValue = 30;
  const list = [...Array(maxValue).keys()]
    .map((i) => i + 1)
    .filter((i) => i !== 1);

  return (
    <select onChange={onChange} value={value} name={name} className={className}>
      <option key={1} value={1}>
        {translate('NUM_ONCE')}
      </option>
      {list.map((num) => (
        <option key={num} value={parseInt(num)}>
          {translateAndReplace('NUM_TIMES', num)}
        </option>
      ))}
    </select>
  );
});

SurgeryItem.propTypes = {
  item: PropTypes.object,
  uId: PropTypes.number,
  itemKey: PropTypes.number,
  onChangeItem: PropTypes.func,
  onRemoveItem: PropTypes.func,
};

export default observer(SurgeryItem);
