import React from 'react';
import modal from 'store/modal';
import moment from 'moment';
import SelectNextSurgery from 'components/pages/crm/customer-chart/SelectNextSurgery';
import SelectNextTreatment from 'components/pages/crm/customer-chart/SelectNextTreatment';
import SelectNextConsulting from 'components/pages/crm/customer-chart/SelectNextConsulting';
import Checkbox from 'components/app/Checkbox';
import services from 'services';
import styled, { css } from 'styled-components';
import DatePicker from 'components/common/DatePicker';

const SmsAutoCheckRow = styled.div`
  display: flex;
  flex-flow: row nowrap;
  ${({ color }) =>
    color === 'red' &&
    css`
      color: red;
    `}
  .checkbox {
    flex: 0 0 auto;
    width: 16px;
    height: 16px;
    border-radius: 3px;
    border: solid 1px #d7e3f1;
    box-shadow: none;
  }
`;

const useSettingSurgeryItems = (arr, itemsList) => {
  //통합차트 탭에서 시/수술코드 추가하여 새로운 수납코드를 생성했을때,
  //해당 값을 기존 선택되어있던 시/수술카테고리 배열과 합쳐서 새로운 배열로 반환

  let returnArr = [];
  if (arr.length > 0) {
    arr.forEach((obj) => {
      let category = obj.category || {};
      let items = obj.items || [];

      //id를 가진 items를 반환
      let itemsSet = (category.items || [])
        .filter((v) => {
          return items.findIndex((f) => f.name === v.name) > -1;
        })
        .map((v) => {
          return {
            ...v,
            category: { id: category.id, name: category.name },
          };
        });
      returnArr = [...returnArr, ...itemsSet];
    });

    return (
      [...itemsList, ...returnArr].filter(
        (v) => v !== undefined && v.id !== undefined
      ) || [{}]
    );
  } else {
    return itemsList;
  }
};

const useGetAppointmentClosetToTodayItem = async (customerId) => {
  //오늘 예약건을 조회하여 setProcessAppointmentId
  //예약건 복수 개중 가장 오늘과 가까운 가장 최근 예약건을 찾기 위해 orderBy=scheduledAt+asc
  //ex) customerId=290&startAt=2020-07-29&orderBy=scheduledAt+asc

  try {
    let param = {
      customerId: customerId,
      startAt: moment().format('YYYY-MM-DD'),
      orderBy: 'scheduledAt asc',
    };
    const resp = await services.crm.crud.appointment.all(param);
    if (!resp) return;

    let id = null;
    if (resp.data.length) {
      id = resp.data[0].id;
    }

    return id;
  } catch (e) {
    console.log(e.description);
  }
};

const useGetRecentAppointmentItem = async (customerId) => {
  //해당 고객의 가장 최근 예약건을 조회하여, 다음진행의 의사,상담사,피부관리사에 매칭시키기 위함

  try {
    let param = {
      customerId: customerId,
      limit: 1,
      orderBy: 'scheduledAt desc',
    };
    const resp = await services.crm.crud.appointment.all(param);
    if (!resp) return;

    let item = {};
    if (resp && resp.data && resp.data.length === 1) {
      item = { ...resp.data[0] };
    }

    return item;
  } catch (e) {
    console.log(e.description);
  }
};

const useGetMemoBoilerplate = async (tabName) => {
  //통합차트에서 사용하는 메모 상용구 조회

  try {
    let resultData = [];
    let params = { limit: 5, orderBy: 'order asc', category: tabName };
    const resp = await services.crm.boilerplate.memo.all(params);
    if (!resp) return;

    resultData = [...resp.data];
    return resultData;
  } catch (e) {
    console.log(e.description);
  }
};

const getScheduledAtDatePicker = ({ objScheduledAt, onChangeScheduledAt }) => {
  return (
    <DatePicker
      dateFormat="yyyy/MM/dd"
      value={new Date(objScheduledAt)}
      onChange={(scheduledAt) => onChangeScheduledAt(scheduledAt)}
    />
  );
};

const useProcessChangeToWaiting = ({
  nextProcessStatus,
  doctors,
  facialist,
  counselors,
  treatmentSelectDoctors,
  treatmentSelectFacialists,
  treatmentSelectCounselors,
  callback,
}) => {
  let selectDoctors = treatmentSelectDoctors;
  const selectDoctor = (doctors) => {
    selectDoctors = doctors;
  };

  let selectFacialists = treatmentSelectFacialists;
  const selectFacilist = (facialists) => {
    selectFacialists = facialists;
  };

  let selectCounselors = treatmentSelectCounselors;
  const selectCounselor = (counselors) => {
    selectCounselors = counselors;
  };

  if (nextProcessStatus === 'surgery_waiting') {
    //시수술 대기할 의사/피부관리사 선택 팝업
    modal
      .slot({
        title: '시/수술대기할 의사 또는 피부관리사를 지정하세요.',
        preparedDom: () => (
          <SelectNextSurgery
            doctors={doctors}
            facialist={facialist}
            appointmentDoctor={treatmentSelectDoctors}
            appointmentFacialist={treatmentSelectFacialists}
            setTreatmentSelectDoctors={selectDoctor}
            setTreatmentSelectFacialists={selectFacilist}
          />
        ),
        showCloseButton: true,
        buttons: [
          { text: 'CLOSE', class: 'btn-default' },
          { text: 'CONFIRM', class: 'btn-primary' },
        ],
      })
      .then((idx) => {
        if (idx === 1) {
          const selectObj = {
            doctor: selectDoctors,
            facialist: selectFacialists,
          };
          callback(selectObj, nextProcessStatus);
        } else {
          callback({}, undefined);
        }
      });
  }

  if (nextProcessStatus === 'treatment_waiting') {
    //진료 대기할 의사 선택 팝업
    modal
      .slot({
        title: '진료대기할 의사를 지정하세요.',
        preparedDom: () => (
          <SelectNextTreatment
            doctors={doctors}
            appointmentDoctor={treatmentSelectDoctors}
            setTreatmentSelectDoctors={selectDoctor}
          />
        ),
        showCloseButton: true,
        buttons: [
          { text: 'CLOSE', class: 'btn-default' },
          { text: 'CONFIRM', class: 'btn-primary' },
        ],
      })
      .then((idx) => {
        if (idx === 1) {
          const selectObj = {
            doctor: selectDoctors,
          };
          callback(selectObj, nextProcessStatus);
        } else {
          callback({}, undefined);
        }
      });
  }

  if (nextProcessStatus === 'consulting_waiting') {
    //추가상담대기할 의사 선택 팝업
    modal
      .slot({
        title: '추가상담대기할 상담사를 지정하세요.',
        preparedDom: () => (
          <SelectNextConsulting
            counselors={counselors}
            appointmentCounselor={treatmentSelectCounselors}
            setTreatmentSelectCounselors={selectCounselor}
          />
        ),
        showCloseButton: true,
        buttons: [
          { text: 'CLOSE', class: 'btn-default' },
          { text: 'CONFIRM', class: 'btn-primary' },
        ],
      })
      .then((idx) => {
        if (idx === 1) {
          const selectObj = {
            counselor: selectCounselors,
          };
          callback(selectObj, nextProcessStatus);
        } else {
          callback({}, undefined);
        }
      });
  }
};

const useGetRemainingPaidTreatmentItems = async (customerId) => {
  try {
    //수납된(미수포함) / 남은 회차가 1회 이상 남은  시/수술 내역 노출
    //남은 회차가 0회인(환불포함) 시/수술은 노출 x

    const param = { customerId, remainingCountStart: 1 };
    const resp = await services.crm.payment.paid_treatment_items(param);
    if (!resp) return;

    return resp.data;
  } catch (e) {
    console.log(e.description);
  }
};

const SmsAutoNotificationsList = (
  smsAutoNotifications,
  setSmsAutoNotifications,
  checkedDisabled,
  scheduledAt
) => {
  let list = smsAutoNotifications.map((v, i) => {
    // scheduleDaysBefore: true 전 / false 후
    // scheduleDaysDelta : number
    // smsScheduleType : immediate 즉시전송 / scheduled 예약문자

    let colorClass = '';
    let msgTitle = v?.title;
    let scheduleDaysBefore = '';
    if (v.scheduleDaysBefore) {
      //전
      if (v.smsScheduleType === 'immediate') {
        scheduleDaysBefore = '예약완료 후';
      } else {
        scheduleDaysBefore = `예약일 ${v.scheduleDaysDelta}일 전`;
      }
    } else {
      //후
      scheduleDaysBefore = `예약일 ${v.scheduleDaysDelta}일 후`;
    }

    let smsScheduleType =
      v.smsScheduleType === 'immediate' ? '즉시전송' : `${v.scheduleTime} 전송`;

    // 예약 문자 전송 시점이 과거이면 빨간색으로 표시
    if (!checkedDisabled) {
      let smsSendDate;
      if (v.scheduleDaysBefore) {
        smsSendDate = moment(
          new Date(scheduledAt).setDate(
            new Date(scheduledAt).getDate() - v.scheduleDaysDelta
          )
        ).format(`YYYY-MM-DDT${v.scheduleTime}`);
      } else {
        smsSendDate = moment(
          new Date(scheduledAt).setDate(
            new Date(scheduledAt).getDate() + v.scheduleDaysDelta
          )
        ).format(`YYYY-MM-DDT${v.scheduleTime}`);
      }

      if (moment().format('YYYY-MM-DDTHH:mm') > smsSendDate) {
        colorClass = 'red';
      }
    }

    return (
      <SmsAutoCheckRow color={colorClass} key={i}>
        <Checkbox
          key={i}
          disabled={checkedDisabled}
          className="m-r-4 checkbox-blue"
          checked={v.$$checked}
          toggleHandler={() => {
            let row = v;
            row.$$checked = !row.$$checked;

            setSmsAutoNotifications(
              smsAutoNotifications.map((item) => {
                if (item.id === row.id) {
                  item = row;
                }
                return item;
              })
            );
          }}
        />
        <div className="flex-wrap m-r-16">
          {`${scheduleDaysBefore} ${smsScheduleType}`}
        </div>
        <div
          className="flex-wrap"
          style={{
            width: '400px',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
          }}
        >{`${msgTitle}`}</div>
      </SmsAutoCheckRow>
    );
  });
  return <>{list}</>;
};

export default {
  useSettingSurgeryItems,
  useGetAppointmentClosetToTodayItem,
  useGetRecentAppointmentItem,
  useGetMemoBoilerplate,
  useProcessChangeToWaiting,
  useGetRemainingPaidTreatmentItems,
  getScheduledAtDatePicker,
  SmsAutoNotificationsList,
};
