import React, { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import DataTableAlpha from 'components/data-table/DataTableAlpha';
import { translate } from 'filters';
import { targetConditionConverter } from 'filters/messageFilters';
import modelMessageTargetSearchHistory from 'models/message-target-search-history';
import { $qb } from 'scripts/querybuilder';
import { useModal } from 'hooks/useModal';
import { useServices } from 'hooks/useServices';
import { useToast } from 'hooks/useToast';
import PropTypes from 'prop-types';

const ModalMessageTargetSearchHistory = ({ close }) => {
  const modal = useModal();
  const services = useServices();
  const toast = useToast();
  const defaultParams = $qb().limit(20).orderBy();
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(null);
  const [params, setParams] = useState(defaultParams);
  const [hideHidden] = useState(false);
  const [loading, setLoading] = useState(true);
  const onParams = (p) => {
    setParams(p);
    callApi();
  };

  const callApi = useCallback(async () => {
    try {
      setLoading(true);
      const resp = await services.crm.notification.smsSearch.all(
        params.build()
      );
      setData(resp.data);
      setTotal(resp.total);
      setLoading(false);
    } catch (error) {
      toast.error(error.description);
      setLoading(false);
    }
  }, [services.crm.notification.smsSearch, toast, params]);

  useEffect(() => {
    callApi();
  }, [callApi]);

  const onEditMemo = async (row) => {
    const { memo } = row;
    const onConfirm = async (value) => {
      try {
        const payload = {
          ...row,
          memo: value,
        };
        await services.crm.notification.smsSearch.update(payload);
        modal
          .confirm({
            type: 'SUCCESS',
            msg: '저장되었습니다.',
          })
          .then(() => {
            callApi();
          });
      } catch (e) {
        toast.error(e.description);
        console.log(e.description);
      }
    };
    modal
      .input({
        title: `${translate('MEMO')}`,
        inputs: [
          {
            text: memo,
            placeholder: `${translate('PLACEHOLDER_MEMO')}`,
          },
        ],
      })
      .then((inputs) => {
        if (!inputs || inputs.length === 0 || !inputs[0].text) {
          return;
        }
        onConfirm(inputs[0].text);
      });
  };

  const onAction = async (obj) => {
    const { row, event } = obj;

    if (event.target.dataset.type === 'select') {
      const { smsSearchCondition, directSearchCondition } = row.condition || {};
      const groups = (smsSearchCondition || []).map((condition) =>
        targetConditionConverter.serverToUi(condition)
      );

      const _manualNumbers = (directSearchCondition || {}).phoneNumber
        ? directSearchCondition.phoneNumber
        : {};
      const manualNumbers = _manualNumbers.query ? _manualNumbers.query : [];

      close({ groups: groups, manualNumbers: manualNumbers });
    }

    if (event.target.dataset.type === 'delete') {
      try {
        const { id } = row;
        await services.crm.notification.smsSearch.delete(id);
        modal
          .confirm({
            type: 'SUCCESS',
            msg: '삭제되었습니다.',
          })
          .then(() => {
            callApi();
          });
      } catch (e) {
        toast.error(e.description);
        console.log(e.description);
      }
      return;
    }

    if (event.target.dataset.type === 'memo') {
      onEditMemo(row);
    }
  };

  return (
    <div className="modal-message-target-search-history-selector">
      <div className="head flex-row flex-between items-start">
        <div className="title">{translate('RECENT_SEARCH_HISTORY')}</div>
        <i onClick={() => close()} className="zmdi zmdi-close" />
      </div>
      <div className="body">
        <DataTableAlpha
          className="header-style-classic"
          model={modelMessageTargetSearchHistory}
          data={hideHidden ? data.filter((row) => row.visible) : data}
          total={total}
          params={params}
          onParams={onParams}
          onAction={onAction}
          loading={loading}
          hideBottom={false}
          hideHidden={hideHidden}
        />
      </div>
      {/* <div className="buttons">
        <div className="flex-row">
          <button onClick={close} className="btn btn-default">
            {translate("CLOSE")}
          </button>
        </div>
      </div> */}
    </div>
  );
};

ModalMessageTargetSearchHistory.propTypes = {
  close: PropTypes.func,
};

export default observer(ModalMessageTargetSearchHistory);
