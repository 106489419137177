import React, { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import ModalMemoBoilerplateEdit from 'components/modals/codes/memo-codes/ModalMemoBoilerplateEdit';
import DataTableAlpha from 'components/data-table/DataTableAlpha';
import { toJS } from 'mobx';
import PropTypes from 'prop-types';
import models from 'models';
import { translate } from 'filters';
import { $qb } from 'scripts/querybuilder';
import { useModal } from 'hooks/useModal';
import { useServices } from 'hooks/useServices';
import { useToast } from 'hooks/useToast';

const ModalMemoBoilerplateSelect = ({ options, close }) => {
  const modal = useModal();
  const services = useServices();
  const toast = useToast();
  const endpoint = services.crm.boilerplate.memo;
  const defaultParams = $qb().limit(1000).orderBy('order asc');
  const [category] = useState(toJS(options.category));
  const [isDataLoad, setIsDataLoad] = useState(false);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(null);
  const [params, setParams] = useState(defaultParams);
  const onParams = (p) => {
    setParams(p);
  };

  useEffect(() => {
    isDataLoad && options.setMemoBoilerplateList(data);
  }, [data, isDataLoad]);

  const callApi = useCallback(async () => {
    try {
      const resp = await services.crm.boilerplate.memo.all({
        ...params.build(),
        category: category,
      });
      setData(resp.data);
      setTotal(resp.total);
      setIsDataLoad(true);
    } catch (error) {
      toast.error(error.description);
    }
  }, [services.crm.boilerplate.memo, toast, options]);

  useEffect(() => {
    callApi();
  }, [callApi]);

  const onSaveOrder = useCallback(
    async (payload) => {
      try {
        let resp = await services.crm.boilerplate.memo.multi_update({
          data: payload,
        });
        modal
          .confirm({
            type: 'SUCCESS',
            msg: '업데이트되었습니다.',
          })
          .then(() => {
            setData(resp.data);
          });
      } catch (e) {
        toast.error(e.description);
      }
    },
    [modal, services, toast]
  );

  const changeArrowUpDown = useCallback(
    async (change, row) => {
      let payload;
      let list = [...data];
      let i = list.findIndex((v) => v.id == row.id);
      //현재 인덱스를 가진 row와
      //바로 위 인덱스를 가진 row를 찾아
      //순서를 바꾸고
      //바꾼 순서대로 order를 다시 붙여준다

      if (change == 'up') {
        if (i == 0) {
          toast.error('가장 상위 순서입니다.');
          return;
        }
        list[i] = data[i - 1];
        list[i - 1] = data[i];
        payload = list.map((v, i) => {
          return { ...v, order: i };
        });
      }

      if (change == 'down') {
        if (i == list.length - 1) {
          toast.error('가장 히위 순서입니다.');
          return;
        }
        list[i] = data[i + 1];
        list[i + 1] = data[i];
        payload = list.map((v, i) => {
          return { ...v, order: i };
        });
      }

      onSaveOrder(payload);
    },
    [data, onSaveOrder]
  );

  const onAction = (obj) => {
    const { row, event } = obj; // , rowIdx

    if (event.target.dataset.type === 'select') {
      close({ memo: obj.row.contents, memoBoilerplateList: data });
    }

    if (event.target.dataset.type === 'edit') {
      openModalEdit('edit', row);
    }

    if (event.target.dataset.type === 'delete') {
      openModalDelete(row.id);
    }

    if (event.target.classList.contains('zmdi-long-arrow-up')) {
      changeArrowUpDown('up', row);
    } else if (event.target.classList.contains('zmdi-long-arrow-down')) {
      changeArrowUpDown('down', row);
    }
  };

  const openModalEdit = (action, row) => {
    modal
      .custom({
        component: ModalMemoBoilerplateEdit,
        options: {
          category: category,
          data: row,
          total: total,
          action: action,
        },
      })
      .then((payload) => {
        if (payload && payload.update) {
          if (payload.action == 'edit') {
            modal
              .confirm({
                type: 'SUCCESS',
                msg: '업데이트되었습니다.',
              })
              .then(() => {
                let i = data.findIndex((v) => v.id == payload.update.id);
                const copyData = [...data];
                copyData[i] = payload.update;
                setData(copyData);
              });
          }
          if (payload.action == 'add') {
            modal
              .confirm({
                type: 'SUCCESS',
                msg: '생성되었습니다.',
              })
              .then(() => {
                setData([...data, payload.update]);
              });
          }
        }
      });
  };

  const openModalDelete = (id) => {
    const onConfirm = async () => {
      try {
        await endpoint.delete(id);
        modal
          .confirm({
            type: 'SUCCESS',
            msg: '삭제되었습니다.',
          })
          .then(() => {
            callApi();
          });
      } catch (e) {
        console.log(e.description);
        modal.confirm({
          type: 'ERROR',
          msg: e.description,
        });
      }
    };

    modal
      .basic({
        body: translate('DELETE_CONFIRM'),
        buttons: [
          {
            text: 'CANCEL',
            class: 'btn-default',
          },
          {
            text: 'CONFIRM',
            class: 'btn-danger',
          },
        ],
      })
      .then((idx) => {
        if (idx === 1) {
          onConfirm();
        }
      });
  };

  return (
    <div className="modal-memo-boilerplate-selector renew">
      <div className="head flex-row flex-between items-start">
        <div className="title">{translate('SELECT_BOILERPLATE_MESSAGE')}</div>
        <i
          onClick={() => close({ memoBoilerplateList: data })}
          className="zmdi zmdi-close"
        />
      </div>
      <div className="body">
        <div className="flex-row float-right m-b-8">
          <button
            className="btn btn-primary btn-sm flex-wrap btn-add"
            onClick={() => openModalEdit('add')}
          >
            추가
          </button>
        </div>

        <DataTableAlpha
          model={models.crm.memoBoilerplateSelector}
          data={data}
          total={total}
          params={params}
          onParams={onParams}
          onAction={onAction}
          hideBottom={true}
        />
      </div>
    </div>
  );
};

ModalMemoBoilerplateSelect.propTypes = {
  options: PropTypes.object,
  close: PropTypes.func,
};

export default observer(ModalMemoBoilerplateSelect);
