import React, { useState, useEffect, useCallback } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { translate } from 'filters';
import { useEvent } from 'hooks/useEvent';
import { useModal } from 'hooks/useModal';
import { useServices } from 'hooks/useServices';
import { useToast } from 'hooks/useToast';
import styled, { css } from 'styled-components';
import DatePicker from 'components/common/DatePicker';

const Input = styled.input`
  width: 100%;
  padding: 9px 15px 8px;
  text-align: left;
  min-height: 10px;
  border-radius: 4px;
  background-color: #f3f8ff;
  color: #000;
  border: 1px solid #dee2ec;
  height: 34px;
  font-size: 14px;

  ${({ disabled }) =>
    disabled &&
    css`
      background-color: #efeff4 !important;
      color: #ceced7;
    `}
`;

const ModalCreateConsultingRequests = ({ options, close }) => {
  const eventBus = useEvent();
  const modal = useModal();
  const services = useServices();
  const toast = useToast();

  const [loadingBtnDisabledFlag, setLoadingBtnDisabledFlag] = useState(false);
  const [mediaData, setMediaData] = useState([]);
  const [eventData, setEventData] = useState([]);
  const [visitAt, setVisitAt] = useState(new Date());
  const [requestAt, setRequestAt] = useState(new Date());
  const [payload, setPayload] = useState({
    status: null,
    assignUserId: options.userId,
    visitAt: moment(new Date()).format('YYYY-MM-DDTHH:mm:ss'),
    requestAt: moment(new Date()).format('YYYY-MM-DDTHH:mm:ss'),
  });

  const mediaCallApi = useCallback(async () => {
    const params = { isDisabled: false };
    const resp = await services.crm.consultingRequest.media.all(params);
    if (!resp) return;

    setMediaData(resp.data);
  }, [services.crm.consultingRequest.media]);

  const eventCallApi = async (mediaId) => {
    if (mediaId !== '-') {
      const params = { isDisabled: false, mediaId };
      const resp = await services.crm.consultingRequest.event.all(params);
      if (!resp) return;

      setEventData(resp.data);
      setPayload({
        ...payload,
        mediaId: Number(mediaId),
      });
    } else {
      setPayload({
        ...payload,
        mediaId: null,
        eventId: null,
        price: null,
        eventType: null,
      });
      setEventData([]);
    }
  };

  useEffect(() => {
    mediaCallApi();
  }, [mediaCallApi]);

  const onClickCreate = async () => {
    const validator = (payload) => {
      let returnFlag = true;
      if (!payload.requestAt || !payload.userName || !payload.phoneNumber) {
        toast.error('*(별표)표시는 필수로 입력하세요.');
        returnFlag = false;
      }

      return returnFlag;
    };

    setLoadingBtnDisabledFlag(true);
    if (!payload?.assignUserId) {
      delete payload.assignUserId;
    }
    if (validator(payload)) {
      try {
        let changePayload = payload;
        if (payload.assignUserId === 0) {
          // eslint-disable-next-line no-unused-vars
          const { assignUserId: deletedKey, ...otherKeys } = payload;
          changePayload = otherKeys;
        }
        await services.crm.consultingRequest.requests.create(changePayload);

        modal
          .confirm({
            type: 'SUCCESS',
            msg: '생성되었습니다.',
          })
          .then(() => {
            eventBus.emit('callApi');
            setLoadingBtnDisabledFlag(false);
            close();
          });
      } catch (e) {
        console.log(e.description);
        modal
          .confirm({
            type: 'ERROR',
            msg: e.description,
          })
          .then(() => {
            setLoadingBtnDisabledFlag(false);
          });
      }
    }
  };

  const onChangeRequestAt = (date) => {
    setRequestAt(date);
    setPayload({
      ...payload,
      requestAt: moment(date).format('YYYY-MM-DDTHH:mm:ss'),
    });
  };

  const onChangeVisitAt = (date) => {
    setVisitAt(date);
    setPayload({
      ...payload,
      visitAt: moment(date).format('YYYY-MM-DDTHH:mm:ss'),
    });
  };

  const onChangeMediaId = (e) => {
    const { value } = e.target;
    //선택한 매체에 따라 해당 매체에 연동된 이벤트 검색
    eventCallApi(value);
  };

  const onChangeEventId = (e) => {
    if (e.target.value === '-') {
      setPayload({ ...payload, eventType: null, price: null, eventId: null });
    } else {
      const eventId = Number(e.target.value);

      const selectData = eventData.filter((v) => {
        return v.id === eventId;
      });
      const eventPayload = {
        eventId,
        eventType: selectData[0].eventType || '',
        price: selectData[0].price || '',
      };
      setPayload({ ...payload, ...eventPayload });
    }
  };

  return (
    <div className="modal-consulting-requests">
      <div className="head flex-row flex-between items-start">
        <div className="title">상담문의 개별등록</div>
        <i onClick={() => close()} className="zmdi zmdi-close" />
      </div>
      <div className="body">
        <table>
          <colgroup>
            <col width="20%" />
            <col width="80%" />
          </colgroup>
          <tbody>
            <tr>
              <th>
                <div className="form-control">
                  <label>담당자 배정</label>
                </div>
              </th>
              <td>
                <select
                  name="assignUserId"
                  value={payload.assignUserId}
                  onChange={(e) => {
                    setPayload({
                      ...payload,
                      [e.target.name]: Number(e.target.value),
                    });
                  }}
                >
                  <option value={0}>-</option>
                  {options.consultingManager.map((v, i) => (
                    <option key={i} value={v.user.id}>
                      {v.user.name}
                    </option>
                  ))}
                </select>
              </td>
            </tr>
            <tr>
              <th>
                <div className="form-control required">
                  <label>상담문의일</label>
                </div>
              </th>
              <td>
                <DatePicker
                  className="text-center"
                  todayButton="오늘"
                  value={requestAt}
                  onChange={onChangeRequestAt}
                  popperPlacement="bottom"
                  popperModifiers={{
                    flip: {
                      behavior: ['bottom'],
                    },
                    preventOverflow: {
                      enabled: false,
                    },
                    hide: {
                      enabled: false,
                    },
                  }}
                />
              </td>
            </tr>
            <tr>
              <th>
                <div className="form-control required">
                  <label>고객명</label>
                </div>
              </th>
              <td>
                <Input
                  name="userName"
                  onChange={(e) => {
                    setPayload({ ...payload, [e.target.name]: e.target.value });
                  }}
                />
              </td>
            </tr>
            <tr>
              <th>
                <div className="form-control required">
                  <label>연락처</label>
                </div>
              </th>
              <td>
                <Input
                  name="phoneNumber"
                  onChange={(e) => {
                    setPayload({ ...payload, [e.target.name]: e.target.value });
                  }}
                />
              </td>
            </tr>
            <tr>
              <th>
                <div className="form-control">
                  <label>매체</label>
                </div>
              </th>
              <td>
                <select name="mediaId" onChange={onChangeMediaId}>
                  <option value={null}>-</option>
                  {mediaData.map((v, i) => {
                    return (
                      <option key={i} value={v.id}>
                        {v.tag}
                      </option>
                    );
                  })}
                </select>
              </td>
            </tr>
            <tr>
              <th>
                <div className="form-control">
                  <label>이벤트명</label>
                </div>
              </th>
              <td>
                <select name="eventId" onChange={onChangeEventId}>
                  <option value={null}>-</option>
                  {eventData.map((v, i) => {
                    return (
                      <option key={i} value={v.id}>
                        {v.name}
                      </option>
                    );
                  })}
                </select>
              </td>
            </tr>
            <tr>
              <th>이벤트 유형</th>
              <td>
                <Input
                  name="eventType"
                  disabled
                  value={payload.eventType === null ? '' : payload.eventType}
                />
              </td>
            </tr>
            <tr>
              <th>이벤트 가격</th>
              <td>
                <Input
                  name="price"
                  disabled
                  value={payload.price === null ? '' : payload.price}
                />
              </td>
            </tr>
            <tr>
              <th>방문희망일</th>
              <td>
                <DatePicker
                  className="text-center"
                  todayButton="오늘"
                  value={visitAt}
                  onChange={onChangeVisitAt}
                />
              </td>
            </tr>
            <tr>
              <th>관심항목</th>
              <td>
                <Input
                  name="interestItem"
                  onChange={(e) => {
                    setPayload({ ...payload, [e.target.name]: e.target.value });
                  }}
                />
              </td>
            </tr>
            <tr>
              <th>고객문의</th>
              <td colSpan="3">
                <Input
                  name="memo"
                  onChange={(e) => {
                    setPayload({ ...payload, [e.target.name]: e.target.value });
                  }}
                />
              </td>
            </tr>
          </tbody>
        </table>
        <div className="form-control">
          <span className="comment">*(별표)표시는 필수로 입력하세요.</span>
        </div>
      </div>
      <div className="buttons">
        <div className="flex-row">
          <button onClick={() => close()} className="btn btn-default">
            {translate('CANCEL')}
          </button>
          <button
            onClick={onClickCreate}
            disabled={`${loadingBtnDisabledFlag ? 'disabled' : ''}`}
            className="btn btn-primary"
          >
            등록
          </button>
        </div>
      </div>
    </div>
  );
};

ModalCreateConsultingRequests.propTypes = {
  options: PropTypes.object,
  close: PropTypes.func,
};

export default observer(ModalCreateConsultingRequests);
