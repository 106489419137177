import React, { useState, useEffect, useCallback } from 'react';
import { observer } from 'mobx-react';
import moment from 'moment';
import DataTableAlpha from 'components/data-table/DataTableAlpha';
import Checkbox from 'components/app/Checkbox';
import hooks from 'hooks';
import ModalConsultingRequests from 'components/modals/ModalConsultingRequests';
import ModalCustomerFinder from 'components/modals/ModalCustomerFinder';
import ModalCreateConsultingRequests from 'components/modals/ModalCreateConsultingRequests';

import models from 'models';
import { $qb } from 'scripts/querybuilder';
import { useEvent } from 'hooks/useEvent';
import { useModal } from 'hooks/useModal';
import { useServices } from 'hooks/useServices';
import { useToast } from 'hooks/useToast';
// import ModalConsultingRequestsNeedRecall from 'components/modals/ModalConsultingRequestsNeedRecall'
import styled from 'styled-components';
import { useAuth } from 'store/auth';
import { useClinicCheck } from 'store/clinicCheck';
import { useHoliday } from 'store/holiday';
import DatePicker from 'components/common/DatePicker';

const Input = styled.input`
  min-height: 10px;
  border-radius: 4px;
  background-color: #f3f8ff;
  font-size: 14px;
  padding: 8px 10px 9px 20px;
  display: inline-block;
  width: 140px;
  height: 36px;
  line-height: 1;
  border: 1px solid #dee2ec;
`;

const Div = styled.div`
  align-items: center;
`;

const ConsultingRequests = () => {
  const eventBus = useEvent();
  const modal = useModal();
  const services = useServices();
  const toast = useToast();
  const auth = useAuth();
  const clinicCheck = useClinicCheck();
  const holiday = useHoliday();

  const defaultParams = $qb().limit(20).orderBy('requestAt desc');
  const [params, setParams] = useState(
    defaultParams
      .customParam(
        'requestStartAt',
        moment(new Date().setDate(new Date().getDate() - 30)).format(
          'YYYY-MM-DD'
        )
      )
      .customParam('requestEndAt', moment(new Date()).format('YYYY-MM-DD'))
  );
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [consultingManager, setConsultingManager] = useState([]);
  const [countStatus, setCountStatus] = useState({
    noneStatusCnt: 0,
    recallCnt: 0,
  });
  const [searchValue, setSearchValue] = useState({
    requestStartAt: moment(
      new Date().setDate(new Date().getDate() - 30)
    ).format('YYYY-MM-DD'),
    requestEndAt: moment(new Date()).format('YYYY-MM-DD'),
  });
  const [startAt, setStartAt] = useState(
    new Date().setDate(new Date().getDate() - 30)
  );
  const [endAt, setEndAt] = useState(new Date());
  const [hideStatusUnResponsive, setHideStatusUnResponsive] = useState(false);
  const [hideStatusReCall, setHideStatusReCall] = useState(false);
  const [statusBox, setStatusBox] = useState(false);
  const [statusList, setStatusList] = useState([{ name: '미응대' }]);
  const [autoRegisterCustomer, setAutoRegisterCustomer] = useState({});

  const callApi = useCallback(async () => {
    if (clinicCheck.isClinicStantopCounselor) {
      params.customParam('assignUserId', auth.me.id);
    }
    const resp = await services.crm.consultingRequest.requests.all(
      params.build()
    );
    if (!resp) return;

    resp.data.forEach((v) => {
      let currentTime = new Date();
      let recallTime = new Date(
        moment(v.lastRecallAt).format('YYYY-MM-DD HH:mm:ss')
      );
      let result = recallTime - currentTime;
      v.greenFont = result <= 1800000 && result >= 0; //리콜일시 30분 전
      v.redFont = result < 0; //리콜일시가 지났을 때
      const { consultingRequestResult } = v;
      v.consultingRequestResult = consultingRequestResult.sort((a, b) => {
        return a.createdAt > b.createAt ? 1 : -1;
      });

      v.statusName = '미응대';
      if (v.status) {
        v.statusName = (v.status.category?.name ?? '') + ' > ' + v.status.name;
      } else {
        if (v.consultingRequestResult.length > 0) {
          v.statusName = '미입력';
        }
      }
      if (v.lastRecallAt) {
        v.lastRecallAt = v.lastRecallAt.replace('T', ' ').slice(0, -3);
      }

      return v;
    });

    setData(resp.data);
    setTotal(resp.total);
  }, [services, params]);

  const countCallApi = useCallback(async () => {
    const resp = await services.crm.consultingRequest.requests.today_count();
    if (!resp) return;

    //금일 미응대건, 금일 리콜 건 카운트
    setCountStatus({ ...resp.data });
  }, [services.crm.consultingRequest.requests]);

  useEffect(() => {
    eventBus.on('countCallApi', countCallApi);
    countCallApi();

    return () => {
      eventBus.on('countCallApi', countCallApi);
    };
  }, [countCallApi, eventBus]);

  const consultingManagerCallApi = useCallback(async () => {
    const params = { type: 'staff', userStatus: 'active' };
    const resp = await services.crm.organizations.organ.all(params);
    if (!resp) return;

    const managerList = resp.data.filter((v) => {
      return v.user.isConsultingRequestUser;
    });

    setConsultingManager(managerList);
  }, [services.crm.organizations.organ]);

  const statusCallApi = useCallback(async () => {
    const param = { visible: true };
    const resp = await services.crm.consultingRequest.requests.status(param);
    if (!resp) return;

    setStatusList((statusList) => [...statusList, ...resp.data]);
  }, [services.crm.consultingRequest.requests]);

  const customerInfoCallApi = async (customerId) => {
    try {
      const resp = await services.crm.customer.detail(customerId);
      if (!resp) return;
      const customer = resp.data;
      hooks.openCustomerChart({ customer });
    } catch (e) {
      console.log(e.description);
      if (e.description === 'CustomerNotFound') {
        toast.error('삭제된 고객입니다.');
      }
    }
  };

  useEffect(() => {
    eventBus.on('callApi', callApi);

    //담당자 리스트
    consultingManagerCallApi();
    //처리결과 리스트
    statusCallApi();
    countCallApi();
    callApi();

    return () => eventBus.off('callApi');
  }, [consultingManagerCallApi, statusCallApi, callApi, eventBus]);

  const registerCustomerCallApi = async () => {
    const resp = await services.crm.clinic.configs({
      groupName: 'consulting_request_register_customer',
    });
    if (!resp) return;
    setAutoRegisterCustomer(resp?.data[0]);
  };

  useEffect(() => {
    registerCustomerCallApi();
  }, []);

  const onParams = (p) => {
    setParams(p);
    callApi();
  };

  const onAction = (obj) => {
    const { key, event, row } = obj;
    const consulting = row;
    const model = key;
    const classList = event.target.classList;

    if (classList.contains('btn-respon')) {
      openModalConsultingRequests(consulting);
      return;
    }

    if (['연락처'].indexOf(model.title) !== -1) {
      if (consulting.customerId !== null) {
        customerInfoCallApi(consulting.customerId);
      } else {
        modal
          .custom({
            component: ModalCustomerFinder,
            options: {
              customerData: {
                userName: row.userName,
                phoneNumber: row.phoneNumber,
              },
            },
          })
          .then((customer) => {
            //통합차트 오픈
            if (!customer) return;
            hooks.openCustomerChart({
              customer,
            });
          });
      }
      return;
    }
  };

  const openModalConsultingRequests = (consulting) => {
    //응대하기 팝업
    modal
      .custom({
        component: ModalConsultingRequests,
        options: {
          canClose: false,
          consulting,
          type: 'CONSULTING_REQUESTS',
        },
      })
      .then(() => {
        callApi();
      });
  };

  const onClickSelectDate = (v) => {
    const now = new Date();
    if (v === 'month') {
      onChangeDate(
        new Date(now.getFullYear(), now.getMonth(), 1),
        new Date(now.getFullYear(), now.getMonth() + 1, 0)
      );
    } else if (v === 'week') {
      const nowDayOfWeek = now.getDay();
      const nowDay = now.getDate();
      const nowMonth = now.getMonth();
      let nowYear = now.getYear();
      nowYear += nowYear < 2000 ? 1900 : 0;
      const weekStartDate = new Date(nowYear, nowMonth, nowDay - nowDayOfWeek);
      const weekEndDate = new Date(
        nowYear,
        nowMonth,
        nowDay + (6 - nowDayOfWeek)
      );
      onChangeDate(weekStartDate, weekEndDate);
    }
  };
  const onClickCreateRequest = () => {
    const userId =
      consultingManager.filter((v) => v.user.id === auth.me.id).length > 0
        ? auth.me.id
        : '';
    modal
      .custom({
        component: ModalCreateConsultingRequests,
        options: {
          consultingManager,
          userId,
        },
      })
      .then(() => {
        consultingManagerCallApi();
        callApi();
      });
  };

  const onChangeDate = (startDate, endDate) => {
    setStartAt(startDate);
    setEndAt(endDate);
    setSearchValue({
      ...searchValue,
      requestStartAt: moment(startDate).format('YYYY-MM-DD'),
      requestEndAt: moment(endDate).format('YYYY-MM-DD'),
    });
  };

  const onChangeStartAt = (date) => {
    const requestStartAt = moment(date).format('YYYY-MM-DD');
    setStartAt(date);
    setSearchValue({ ...searchValue, requestStartAt });
  };

  const onChangeEndAt = (date) => {
    const requestEndAt = moment(date).format('YYYY-MM-DD');
    setEndAt(date);
    setSearchValue({ ...searchValue, requestEndAt });
  };

  const onClickSearch = () => {
    let searchParam = searchValue;
    if (searchParam.requestStartAt === 'Invalid date') {
      // eslint-disable-next-line unused-imports/no-unused-vars
      const { requestStartAt: deletedKey, ...otherKeys } = searchParam;
      searchParam = otherKeys;
    }

    if (searchParam.requestEndAt === 'Invalid date') {
      // eslint-disable-next-line unused-imports/no-unused-vars
      const { requestEndAt: deletedKey, ...otherKeys } = searchParam;
      searchParam = otherKeys;
    }

    params.queryParams = {
      limit: params.queryParams.limit,
      orderBy: params.queryParams.orderBy,
      ...searchParam,
    };
    onParams(params);
    setSearchValue(searchParam);
  };

  const objectWithoutKey = (object, key) => {
    // eslint-disable-next-line unused-imports/no-unused-vars
    const { [key]: deletedKey, ...otherKeys } = object;
    return otherKeys;
  };

  const onChangeStatus = (e) => {
    const { name, value } = e.target;

    //status = 미응대 = null
    //검색 조회 필드에서는 status=''
    if (value === '직접입력') {
      setStatusBox(true);
    } else if (value === '') {
      setSearchValue(objectWithoutKey(searchValue, 'status'));
    } else if (value === '미응대') {
      setSearchValue({ ...searchValue, [name]: '' });
    } else {
      setSearchValue({ ...searchValue, [name]: value });
    }
  };

  const onClickCheckBoxStatusUnResponsive = () => {
    let searchParam = searchValue;
    let param = hideStatusUnResponsive
      ? { ...searchValue }
      : { ...searchValue, status: '' };

    if (hideStatusUnResponsive && param?.status === '') delete param.status;
    if (hideStatusUnResponsive && searchParam?.status === '')
      delete searchParam.status;
    params.queryParams = {
      limit: params.queryParams.limit,
      orderBy: params.queryParams.orderBy,
      ...param,
    };

    onParams(params);
    setSearchValue({ ...searchValue, ...param });
    setHideStatusUnResponsive(!hideStatusUnResponsive);
  };

  const onClickCheckBoxStatusReCall = () => {
    let searchParam = searchValue;
    let param = hideStatusReCall
      ? { ...searchValue }
      : { ...searchValue, statusCategory: '리콜 필요' };
    if (hideStatusReCall && param?.statusCategory) delete param.statusCategory;
    if (hideStatusReCall && searchParam?.statusCategory)
      delete searchParam.statusCategory;
    params.queryParams = {
      limit: params.queryParams.limit,
      orderBy: params.queryParams.orderBy,
      ...param,
    };
    delete searchParam.statusCategory;
    onParams(params);
    setSearchValue({ ...searchValue, ...param });
    setHideStatusReCall(!hideStatusReCall);
  };

  // const onClickNeedRecall = () => {
  //   //리콜필요건 팝업
  //   modal.custom({
  //     component: ModalConsultingRequestsNeedRecall,
  //   })
  // }

  const onClickToggleBtn = async () => {
    try {
      let resp;
      let codeValue =
        autoRegisterCustomer?.codeValue === 'True' ? 'False' : 'True';

      if (autoRegisterCustomer === undefined) {
        resp = await services.crm.clinic.configs_create({
          groupName: 'consulting_request_register_customer',
          codeName: 'consulting_request_register_customer',
          codeValue: codeValue,
        });
      } else {
        resp = await services.crm.clinic.configs_update(
          {
            groupName: 'consulting_request_register_customer',
            codeName: 'consulting_request_register_customer',
            codeValue: codeValue,
          },
          autoRegisterCustomer.id
        );
      }

      if (!resp) return;
      modal
        .confirm({
          type: 'SUCCESS',
          msg: '업데이트되었습니다',
        })
        .then(() => {
          registerCustomerCallApi();
        });
    } catch (e) {
      console.log(e.description);
    }
  };

  return (
    <div className="list counselors">
      <div className="page-navi">
        <span className="title">상담문의 관리</span>
        <span className="title">상담문의</span>
      </div>
      <div className="route-top">
        <div className="search-bar">
          <div className="search-btns">
            <button
              className="btn btn-normal _small"
              onClick={() => onClickSelectDate('week')}
            >
              이번 주
            </button>
          </div>
          <div className="search-btns">
            <button
              className="btn btn-normal _small"
              onClick={() => onClickSelectDate('month')}
            >
              이번 달
            </button>
          </div>
          <div className="search-item">
            <DatePicker
              placeholderText="날짜선택"
              value={startAt}
              todayButton="오늘"
              onChange={onChangeStartAt}
            />
          </div>
          <div className="search-item">-</div>
          <div className="search-item">
            <DatePicker
              placeholderText="날짜선택"
              value={endAt}
              todayButton="오늘"
              onChange={onChangeEndAt}
            />
          </div>
          <div className="search-item">
            <select
              className="select-form"
              name="assignUserId"
              disabled={
                clinicCheck.isClinicStantopCounselor &&
                auth.me.isConsultingRequestUser
              }
              value={
                clinicCheck.isClinicStantopCounselor
                  ? auth.me.id
                  : searchValue.assignUserId
              }
              onChange={(e) => {
                setSearchValue({
                  ...searchValue,
                  [e.target.name]: Number(e.target.value),
                });
              }}
            >
              <option value="null">배정된 담당자</option>
              {consultingManager.map((v, i) => (
                <option key={i} value={v.user.id}>
                  {v.user.name}
                </option>
              ))}
            </select>
          </div>
          <div className="search-item">
            {statusBox ? (
              <Input
                name="status"
                onChange={(e) =>
                  setSearchValue({
                    ...searchValue,
                    [e.target.name]: e.target.value,
                  })
                }
              />
            ) : (
              <select
                name="status"
                className="select-form"
                defaultValue=""
                onChange={onChangeStatus}
              >
                <option value={''}>처리결과</option>
                {statusList.map((v, i) => (
                  <option key={i} value={v.name}>
                    {v.name}
                  </option>
                ))}
              </select>
            )}
          </div>
          <div className="row">
            <div className="search-item-normal">
              <Input
                placeholder="고객명"
                name="userName"
                onChange={(e) =>
                  setSearchValue({
                    ...searchValue,
                    [e.target.name]: e.target.value,
                  })
                }
                onKeyPress={(e) => (e.key === 'Enter' ? onClickSearch() : null)}
              />
            </div>
            <div className="search-item-normal">
              <Input
                placeholder="연락처"
                name="phoneNumber"
                onChange={(e) =>
                  setSearchValue({
                    ...searchValue,
                    [e.target.name]: e.target.value,
                  })
                }
                onKeyPress={(e) => (e.key === 'Enter' ? onClickSearch() : null)}
              />
            </div>
            <div className="search-btns">
              <button className="btn btn-basic _small" onClick={onClickSearch}>
                조회
              </button>
            </div>
          </div>
        </div>
        <div className="title">
          <span className="subtitle m-r-16">
            금일 미응대 건{' '}
            <span className="cnt">{countStatus.noneStatusCnt}</span>
          </span>
          <span className="subtitle m-r-16">
            금일 리콜필요 건{' '}
            <span className="cnt">{countStatus.recallCnt}</span>
          </span>
        </div>
      </div>

      <Div className="flex-row flex-between">
        <div className="flex-wrap flex-row m-t-32 m-b-16">
          <span
            className="flex-row flex-wrap"
            onClick={() => onClickCheckBoxStatusUnResponsive()}
          >
            <Checkbox
              className="flex-wrap m-r-8"
              checked={hideStatusUnResponsive}
              toggleHandler={onClickCheckBoxStatusUnResponsive}
            />
            <span className="flex-wrap m-r-16">미응대</span>
          </span>
          <span
            className="flex-row flex-wrap"
            onClick={() => onClickCheckBoxStatusReCall()}
          >
            <Checkbox
              className="flex-wrap m-r-8"
              checked={hideStatusReCall}
              toggleHandler={onClickCheckBoxStatusReCall}
            />
            <span className="flex-wrap m-r-16">리콜필요</span>
          </span>
        </div>
        <Div className="flex-wrap flex-row m-t-32 m-b-16">
          <button
            className="btn btn-basic _small m-r-8"
            onClick={onClickCreateRequest}
          >
            상담문의 개별등록
          </button>
          <span className="flex-wrap m-t-2">신환 자동등록 &nbsp;</span>
          <div className="flex-wrap consultig-request-toggle toggle">
            <button
              className={`
                header-toggle-btn 
                ${autoRegisterCustomer?.codeValue === 'True' ? '' : 'selected'}
                `}
              onClick={onClickToggleBtn}
            >
              <label className="header-toggle-btn-label">
                {autoRegisterCustomer?.codeValue === 'True' ? 'on' : 'off'}
              </label>
            </button>
          </div>
        </Div>
      </Div>
      <DataTableAlpha
        model={models.crm.consultingRequests}
        data={data}
        total={total}
        params={params}
        onParams={onParams}
        onAction={onAction}
      />
    </div>
  );
};

export default observer(ConsultingRequests);
