import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'filters';
import { observer } from 'mobx-react';
import { useModal } from 'hooks/useModal';
import { useServices } from 'hooks/useServices';
import { useToast } from 'hooks/useToast';
import styled from 'styled-components';

const Input = styled.input`
  padding: 16px 20px;
  border-radius: 4px;
  background-color: #f3f8ff;
  font-size: 14px;
  border: 1px solid #dee2ec;
  width: auto;
  height: 34px;
`;

const ModalMediaConnects = ({ close }) => {
  const modal = useModal();
  const services = useServices();
  const toast = useToast();
  const [loadingBtnDisabledFlag, setLoadingBtnDisabledFlag] = useState(false);
  const [obj, setObj] = useState({ mediaName: '바비톡' });
  const [connectFail, setConnectFail] = useState(false);

  const MediaConnectExists = () => {
    return '이미 연동한 계정입니다.';
  };

  const MediaConnectSuccess = () => {
    return `연동성공하였습니다.`;
  };

  const MediaConnectFail = (failCount) => {
    return `연동 실패하였습니다. (${failCount}/5)
    5회 이상 실패 시, 연동이 차단됩니다.`;
  };

  const onClickBabitalkConnect = async () => {
    setLoadingBtnDisabledFlag(true);
    let bodyText = '';
    if (
      obj.userId !== undefined &&
      obj.userId !== '' &&
      obj.password !== undefined &&
      obj.password !== ''
    ) {
      try {
        if (!connectFail) {
          const resp = await services.crm.consultingRequest.connect.request_create(
            obj
          );
          if (!resp) return;

          const { failCount } = resp.data;
          //failCount > 0 && 계정 불일치 시 실패
          // bodyText = failCount > 0 ? MediaConnectFail(failCount) : MediaConnectSuccess()
          if (failCount > 0) {
            modal
              .basic({
                body: MediaConnectFail(failCount),
                buttons: [
                  {
                    text: 'CONFIRM',
                    class: 'btn-primary',
                  },
                ],
              })
              .then((selectedIdx) => {
                setLoadingBtnDisabledFlag(false);
                if (selectedIdx === 0) {
                  failCount === 5 && setConnectFail(true);
                }
              });
          } else {
            modal
              .confirm({
                type: 'SUCCESS',
                msg: MediaConnectSuccess(),
              })
              .then(() => {
                close();
              });
          }
        }
      } catch (e) {
        setLoadingBtnDisabledFlag(false);
        //계정 ID/PW 불일치 하여 연동 차단
        if (e.description === 'MediaConnectRequestIdLocked') {
          setConnectFail(true);
          return;
        }

        //이미 연동한 계정일 시
        if (e.description === 'MediaConnectExists') {
          bodyText = MediaConnectExists();
        }

        if (bodyText !== '') {
          modal.basic({
            body: bodyText,
            buttons: [
              {
                text: 'CONFIRM',
                class: 'btn-primary',
              },
            ],
          });
        } else {
          toast.error(e.description);
        }
      }
    }
  };

  const onChangeBabitalk = (e) => {
    setConnectFail(false);

    const { name, value } = e.target;
    let payload = { ...obj, [name]: value };
    setObj(payload);
  };

  return (
    <div className="modal-status-categories items-group">
      <div className="head flex-row flex-between items-start">
        <div className="title">상담문의 자동연동</div>
        <i onClick={() => close()} className="zmdi zmdi-close" />
      </div>
      <div className="tabs m-t-32">
        <div className="tab selected ">바비톡</div>
      </div>
      <div className="tab-contents">
        <div className="contents">
          <div className="flex-row flex-between m-b-8">
            <span className="m-r-8">바비톡 관리자 계정 ID</span>
            <Input name="userId" onChange={onChangeBabitalk} />
          </div>
          <div className="flex-row flex-between m-b-8">
            <span className="m-r-8">비밀번호</span>
            <Input
              type="password"
              name="password"
              onChange={onChangeBabitalk}
            />
          </div>
          <div className="flex-row flex-between">
            <div className={`fail-text ${!connectFail && 'disabled'}`}>
              <span>
                5회 이상 실패하여 연동기능이 차단되었습니다.
                <br />
                문의 : cs@unobeauty.kr
              </span>
            </div>
            {/* <div className="right m-b-8">
              <button className={`btn btn-sm ${!connectFail && 'disabled'}`} onClick={onClickBabitalkConnect} >연동하기</button>
            </div> */}
          </div>
        </div>
      </div>
      <div className="buttons">
        <div className="flex-row">
          <button onClick={close} className="btn btn-default">
            {translate('CANCEL')}
          </button>
          <button
            onClick={onClickBabitalkConnect}
            className="btn btn-primary"
            disabled={connectFail || loadingBtnDisabledFlag}
          >
            연동하기
          </button>
        </div>
      </div>
    </div>
  );
};

ModalMediaConnects.propTypes = {
  close: PropTypes.func,
};

export default observer(ModalMediaConnects);
