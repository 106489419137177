import { formatCallerNumber, translate } from 'filters';

export default {
  keys: [
    {
      title: 'SMS_SENT',
      noorder: true,
      filter: (obj) => {
        const { smsEnabled, checked, smsType, eventSmsEnabled } = obj;
        let enabled = smsType === 'normal' ? smsEnabled : eventSmsEnabled;
        return `<input type="checkbox" ${checked ? 'checked' : ''} ${
          enabled === false ? 'disabled' : ''
        }
            
            data-type="check"
            style="width:20px;height:20px;cursor:pointer; ${
              enabled === false ? 'cursor:not-allowed;' : ''
            } "
            />
          `;
      },
    },
    {
      title: 'LABEL_CONDITION_CUSTOMER_NAME',
      noorder: true,
      filter: (obj) => {
        const { name } = obj;
        if (name) {
          return `${name}`;
        }
        return `-`;
      },
    },
    {
      title: 'LABEL_CONDITION_CUSTOMER_PHONENUMBER',
      noorder: true,
      filter: (o) => {
        return formatCallerNumber(o.phoneNumber);
      },
    },
    {
      title: '일반 문자수신',
      noorder: true,
      filter: (obj) => {
        const { smsEnabled } = obj;
        if (false === smsEnabled) {
          return `${translate('DISAGREE')}`;
        }
        if (true === smsEnabled) {
          return `${translate('AGREE')}`;
        }
        return `-`;
      },
    },
    {
      title: 'EVENT_SMS_ENABLED',
      noorder: true,
      filter: (obj) => {
        const { eventSmsEnabled } = obj;
        if (false === eventSmsEnabled) {
          return `${translate('DISAGREE')}`;
        }
        if (true === eventSmsEnabled) {
          return `${translate('AGREE')}`;
        }
        return `-`;
      },
    },
  ],
};
