import { formatCallerNumber } from 'filters';

export default {
  keys: [
    {
      title: 'No',
      column: 'no',
      class: 'flex-center no',
      noorder: true,
    },
    {
      title: 'LABEL_CONDITION_CUSTOMER_NAME',
      column: 'customerName',
      noorder: true,
      filter: (o) => {
        return `<div style="width : 100px; text-align : center; padding-top: 1px;">${
          o.customerName || ''
        }</div>`;
      },
    },
    {
      title: 'LABEL_CONDITION_CUSTOMER_PHONENUMBER',
      column: 'phoneNumber',
      noorder: true,
      filter: (o) => formatCallerNumber(o.phoneNumber),
    },
    {
      title: 'TITLE_AND_CONTENT',
      filter: (o) => {
        if (o.title === '' || !o.title) {
          return `<div class="contents">
          <div style="line-height:3.2; overflow: hidden;
          text-overflow: ellipsis"><u >${o.contents || ''}</u></div>
          </div>`;
        }
        return `<div class="title">
        <div><u>${o.title || ''}</u></div>
        </div>
        <div class="contents">
        <div style=" overflow: hidden;
        text-overflow: ellipsis"><u >${o.contents || ''}</u></div>
        </div>`;
      },
      type: 'text',
      class: 'title-and-content ',
      noorder: true,
    },
    {
      title: '종류',
      column: 'smsNotificationChannel',
      noorder: true,
      filter: (o) => {
        return o.smsNotificationChannel.toUpperCase();
      },
    },
  ],
};
