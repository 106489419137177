import React, { useState, useEffect, useRef } from 'react';
import { observer } from 'mobx-react';
import { asAge, translate } from 'filters';
import QuillText from 'components/quill/QuillText';
import PropTypes from 'prop-types';
import moment from 'moment';

const HomeHoverCard = ({
  appointment,
  setHoverCardOptions,
  setDetailAppointment,
  options,
  setReloadHoverCard,
}) => {
  const hoverCardRef = useRef();
  const [hoverOptions, setHoverOptions] = useState({
    left: options.x,
    top: options.y,
  });

  useEffect(() => {
    //처음 클릭 시 hovercard undefined인 경우 리로드
    if (hoverCardRef.current === undefined && options.show) {
      setReloadHoverCard(true);
    }
  }, [options]);

  useEffect(() => {
    if (options.x !== undefined || options.y !== undefined) {
      let left = hoverCardRef?.current
        ? Math.min(
            window.innerWidth - hoverCardRef.current.clientWidth,
            options.x
          )
        : options.x;

      let top = hoverCardRef?.current
        ? Math.min(
            window.innerHeight - hoverCardRef.current.clientHeight,
            options.y
          )
        : options.y;

      setHoverOptions({
        left,
        top,
        show: true,
      });
    }
  }, [options, hoverCardRef]);

  if (!options.show || !appointment || !hoverOptions.show) return;

  const onClickOverlay = (e) => {
    e.preventDefault();
    if (e.target.classList.contains('home-hover-card')) {
      setHoverCardOptions({
        show: false,
        x: hoverOptions.left,
        y: hoverOptions.top,
      });
      setHoverOptions({
        show: false,
      });
      setDetailAppointment({});
    }
  };

  const Ul = () => {
    const {
      isNewCustomer,
      customerName,
      customerBirthday,
      customerSex,
      customerMemo,
      type,
      scheduledAt,
      startHour,
      endHour,
      counselorName,
      doctorName,
      facialistName,
      items,
      paymentTreatmentItems,
      memo,
      customerChartNo,
      status,
      departmentName,
      departmentCategoryName,
      customerPhoneNumber,
      lastSurgeryItemList,
      lastSurgeryDate,
      // counselorNameOfMemo, //상담사
      // consultingMemo, //상담메모
      // consultingMemoAt, //상담메모 작성일
    } = appointment;
    const appointmentType =
      type === 'consulting'
        ? '상담예약'
        : type === 'treatment'
        ? '진료예약'
        : type === 'surgery'
        ? '시/수술예약'
        : '';
    const categoryItems =
      type === 'surgery'
        ? (paymentTreatmentItems || []).map((v) =>
            (((v || {}).category || {}).name || []).concat(
              ' - ',
              (v || {}).name
            )
          )
        : (items || []).map((v) =>
            (((v || {}).category || {}).name || []).concat(
              ' - ',
              (v || {}).name
            )
          );
    const scheduledDate = `${moment(scheduledAt).format(
      'MM월 DD일'
    )} ${startHour} - ${endHour}`;
    const phoneNumber = (customerPhoneNumber || '')
      .replace(/[^0-9]/g, '')
      .replace(
        /(^02|^0505|^1[0-9]{3}|^0[0-9]{2})([0-9]+)?([0-9]{4})/,
        '$1-$2-$3'
      )
      .replace('--', '-');
    // const phoneNumber_last_4 =
    //   phoneNumber !== null && phoneNumber !== ''
    //     ? phoneNumber.substr(phoneNumber.length - 4, 4)
    //     : '-';
    const info =
      customerBirthday !== null
        ? `(${translate((customerSex || '').toUpperCase() || '-')}/${
            asAge(customerBirthday) || '-'
          }세/${moment(customerBirthday).format('YYMMDD')})`
        : `(${translate((customerSex || '').toUpperCase() || '-')})`;

    // 경과일 계산
    // 시간까지 상세하게 계산하려면 YYYY-MM-DD format 제외 후 게산
    const dateDiff =
      lastSurgeryDate === null
        ? 0
        : Math.ceil(
            Math.abs(
              new Date(moment().format('YYYY-MM-DD')).getTime() -
                new Date(moment(lastSurgeryDate).format('YYYY-MM-DD')).getTime()
            ) /
              (1000 * 3600 * 24)
          ) || 0;

    return (
      <ul>
        <li className="title">
          <span>{`${
            isNewCustomer ? '[신환]' : '[구환]'
          } ${customerName} ${info}`}</span>
          <span></span>
        </li>
        <li>
          <span className="label">생년월일</span>
          {`${customerBirthday || '-'}`}
        </li>
        <li>
          <span className="label">차트번호</span>
          {`${customerChartNo || '-'}`}
        </li>
        <li>
          <span className="label">연락처</span>
          {`${phoneNumber || '-'}`}
        </li>
        <hr />
        {lastSurgeryItemList &&
          lastSurgeryItemList.length > 0 &&
          lastSurgeryItemList.map((v, i) => {
            return (
              <React.Fragment key={i}>
                <li>
                  {i === 0 ? (
                    <span className="label">최종 시/수술</span>
                  ) : (
                    <span className="label"></span>
                  )}
                  <span className="contents">{v?.name || '-'}</span>
                </li>
                <li>
                  <span className="label"></span>총 {v?.treatmentCount || 0}
                  회, {v?.useCount || 0}회 진행, {v?.remainingCount || 0}회 남음
                </li>
              </React.Fragment>
            );
          })}
        {lastSurgeryDate ? (
          <li>
            <span className="label">최종 시/수술일</span>
            {`${moment(lastSurgeryDate).format(
              'YYYY-MM-DD'
            )} (${dateDiff}일 경과)`}
          </li>
        ) : null}
        {lastSurgeryDate ? <hr /> : null}
        <li>
          <span className="label">예약종류</span>
          {`${appointmentType}`}
        </li>
        <li>
          <span className="label">예약부서</span>
          {`${departmentCategoryName} - ${departmentName}`}
        </li>
        <li>
          <span className="label">예약일</span>
          {`${scheduledDate}`}
        </li>
        <li>
          <span className="label">예약상태</span>
          {`${translate(`APPOINTMENT_STATUS_${status.toUpperCase()}`)}`}
        </li>
        <li>
          <span className="label">상담사</span>
          {`${counselorName || '-'}`}
        </li>
        <li>
          <span className="label">의사</span>
          {`${doctorName || '-'}`}
        </li>
        <li>
          <span className="label">어시스트</span>
          {`${facialistName || '-'}`}
        </li>
        <li>
          <span className="label">시/수술</span>
        </li>
        <li>
          {categoryItems.map((v, i) => (
            <div key={i}>{v}</div>
          ))}
        </li>
        <hr />
        <li>
          <span className="label"> 예약 메모</span>
          <QuillText value={memo ?? '-'} />
        </li>
        <hr />
        <li>
          <span className="label">고객 메모</span>
          <QuillText value={customerMemo ?? '-'} />
        </li>
        {/* <hr/>
    <li>
      <span className="label bold">최근 상담내역</span>
    </li>
    <li>
      <span className="label">작성일자</span>
      {`${moment(consultingMemoAt).format('YYYY-MM-DD') === 'Invalid date' ? "-" : moment(consultingMemoAt).format('YYYY-MM-DD')}`}
    </li>
    <li>
      <span className="label">상담사</span>
      {`${counselorNameOfMemo || "-"}`}
    </li>
    <li>
      <span className="label">상담 내용</span>
      <QuillText value={consultingMemo ?? "-"} />
    </li> */}
      </ul>
    );
  };

  return (
    <div
      className={`home-hover-card
      ${hoverOptions.show ? '' : 'display-none'}
    `}
      onClick={onClickOverlay}
      onContextMenu={onClickOverlay}
    >
      <div
        ref={hoverCardRef}
        className="appointment-hover-card"
        style={{
          left: `${hoverOptions.left}px`,
          top: `${hoverOptions.top}px`,
        }}
      >
        {Object.keys(appointment).length ? <Ul /> : null}
      </div>
    </div>
  );
};

HomeHoverCard.propTypes = {
  appointment: PropTypes.object,
  setHoverCardOptions: PropTypes.func,
  setDetailAppointment: PropTypes.func,
  options: PropTypes.object,
  setReloadHoverCard: PropTypes.func,
};

export default observer(HomeHoverCard);
