import React, { useState, useEffect, useCallback } from 'react';
import { observer } from 'mobx-react';
import DataTableAlpha from 'components/data-table/DataTableAlpha';
import OrganizationsDetail from 'components/pages/crm/organizations/OrganizationsDetail';
import PropTypes from 'prop-types';
import models from 'models';
import { $qb } from 'scripts/querybuilder';
import { useServices } from 'hooks/useServices';
import styled from 'styled-components';
import searchIcon from 'assets/images/icon/ic-search.png';

const Input = styled.input`
  font-size: 12px;
  width: 306px;
  padding: 8px 15px 8px 50px;
  border: 1px solid #dee2ec;
  border-radius: 4px;
  height: 46px;
  background-color: #fff;
  background-image: url(${searchIcon});
  background-size: 19px;
  background-repeat: no-repeat;
  background-position: left 15px center;
`;

const OrganizationsMemberList = ({ parentId }) => {
  const services = useServices();
  const defaultParams = $qb().customParam('userStatus', 'active,deactivated');
  const [params, setParams] = useState(defaultParams);
  const [data, setData] = useState([]);
  const [searchValue, setSearchValue] = useState({});
  const [detailData, setDetailData] = useState({});
  const OrganizationType = { DEPARTMENT: 'department', STAFF: 'staff' };

  const callApi = useCallback(async () => {
    const resp = await services.crm.organizations.organ.all(params.build());
    if (!resp) return;
    setData(
      resp.data.filter((v) => {
        return v.type === OrganizationType.STAFF;
      })
    );
  }, [services.crm.organizations.organ, params, OrganizationType.STAFF]);

  const onParams = (p) => {
    setParams(p);
    callApi();
  };

  useEffect(() => {
    if (parentId) {
      params.queryParams = { parentId, userStatus: 'active,deactivated' };
      setSearchValue({});
      onParams(params);
    }
  }, [callApi, parentId, params]);

  const onChangeSearch = (e) => {
    const { name, value } = e.target;
    const searchParam = { [name]: value };
    setSearchValue(searchParam);
  };

  const onClickSearch = () => {
    params.queryParams = {
      userName: searchValue.userName,
      type: OrganizationType.STAFF,
    };
    onParams(params);
  };

  const onAction = (obj) => {
    const user = obj.row;
    setDetailData(user);
  };

  return (
    <>
      <div className="page-navi">
        <span className="title">병원관리</span>
        <span className="title">조직 관리</span>
      </div>
      <div className="organizations-list organ-section">
        <div className="search-bar">
          <div className="search-item title">구성원 목록</div>
          <div className="search-item">
            <Input
              placeholder="이름"
              name="userName"
              onChange={onChangeSearch}
              value={searchValue.userName || ''}
            />
          </div>
          <div className="search-item">
            <button className="btn-sm" onClick={onClickSearch}>
              검색
            </button>
          </div>
        </div>
        <div className="data-area">
          <DataTableAlpha
            model={models.crm.organizationsList}
            data={data}
            params={params}
            onAction={onAction}
            hideBottom={true}
          />
        </div>
      </div>
      <OrganizationsDetail data={detailData} />
    </>
  );
};

OrganizationsMemberList.propTypes = {
  parentId: PropTypes.string,
};

export default observer(OrganizationsMemberList);
