import React, { useContext, useEffect } from 'react';
import { observer } from 'mobx-react';
import CtiStatusBar from './CtiStatusBar';
import useCtiStore from './hooks/useCtiStore';
import CtiRingings from './CtiRingings';
import CtiCallPopup from './CtiCallPopup';
import './common.scss';
import CtiHistory from './CtiHistory';
import { SetCtiStoreContext } from './hoc/withCtiStore';
import { CtiStore } from './ctiStore';
import { useAuth } from 'store/auth';

function UseCti(Comp) {
  return function WrappedComp(props) {
    const { set } = useContext(SetCtiStoreContext);
    const ctiStore = useCtiStore();
    const auth = useAuth();

    useEffect(() => {
      set(new CtiStore(auth));

      return () => {
        set(null);
      };
    }, []);

    useEffect(() => {
      const pageHideHandler = () => {
        if (ctiStore) {
          ctiStore.clear();
        }
      };

      auth.addLogoutListener(pageHideHandler);
      window.addEventListener('pagehide', pageHideHandler, { capture: true });

      return () => {
        auth.removeLogoutListener(pageHideHandler);
        window.removeEventListener('pagehide', pageHideHandler);
      };
    }, [ctiStore]);

    if (!ctiStore) return null;
    if (!auth.me || !auth.me.callManagement) {
      return null;
    }

    return <Comp {...props} />;
  };
}

const Cti = UseCti(
  observer(function Cti() {
    const ctiStore = useCtiStore();

    useEffect(() => {
      const interval = window.setInterval(() => {
        ctiStore.loadHistory();
      }, 2000);

      ctiStore.loadHistory();

      return () => {
        clearInterval(interval);
      };
    }, []);

    return (
      <>
        <CtiStatusBar />
        <CtiHistory />
        {ctiStore.currentCall && <CtiCallPopup />}
        {ctiStore.settings.useRing && !ctiStore.currentCall && <CtiRingings />}
      </>
    );
  })
);

export default Cti;
