import React, { useState, useEffect, useCallback, useRef } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { $qb } from 'scripts/querybuilder';
import { useServices } from 'hooks/useServices';
import { useToast } from 'hooks/useToast';
import styled from 'styled-components';
import { FileInput } from 'components/common/FileInput';
import DatePicker from 'components/common/DatePicker';

const Input = styled.input`
  border: 1px solid #dee2ec;
  height: 34px;
  border-radius: 4px;
  background-color: #f3f8ff;
  font-size: 14px;
  width: 80%;
  padding: 9px 20px;
`;

const OrganizationsUserInputForm = ({ pageStatus, setUserObj, defaultObj }) => {
  const services = useServices();
  const toast = useToast();

  const profileImage = useRef();
  const sealImageRef = useRef();
  const defaultParams = $qb().limit(10).orderBy('id desc');
  const [params] = useState(defaultParams);
  const [allDepartmentStr, setAllDepartmentStr] = useState('');
  const [employedAt, setEmployedAt] = useState(new Date());
  const [birthday, setBirthday] = useState(new Date());
  const [departmentList, setDepartmentList] = useState([]);
  const [obj, setObj] = useState({});
  const [dutyFlag, setDutyFlag] = useState([false, false, false]);
  const [positionFlag, setPositionFlag] = useState(false);
  const dutyList = [
    '의사',
    '상담사',
    '간호사',
    '간호조무사',
    '피부관리사',
    '데스크',
    '경영/지원',
    '마케팅/홍보',
    '총무',
    '사무',
    '직접입력',
  ];
  const addSelectDutyList = [
    '추가 선택',
    '의사',
    '상담사',
    '간호사',
    '간호조무사',
    '피부관리사',
    '데스크',
    '경영/지원',
    '마케팅/홍보',
    '총무',
    '사무',
    '직접입력',
  ];
  const positionList = [
    '병원장',
    '대표원장',
    '부원장',
    '페이닥터',
    '국장',
    '수간호사',
    '실장',
    '과장',
    '팀장',
    '매니저',
    '시니어',
    '주니어',
    '사원',
    '직접입력',
  ];
  const OrganizationType = { DEPARTMENT: 'department', STAFF: 'staff' };
  let allDepartmentList = [];
  const [image, setImage] = useState(null);
  const [sealImage, setSealImage] = useState(null);

  const callApi = useCallback(async () => {
    const resp = await services.crm.organizations.organ.all(params.build());
    if (!resp) return;

    defaultSetting(resp.data);
    setDepartmentList(resp.data);
  }, [params, defaultObj]);

  const defaultSetting = (data) => {
    let dObj;
    let dEmployedAt = new Date();
    let dBirthday = new Date();

    if (defaultObj === undefined || Object.keys(defaultObj).length === 0) {
      dObj = {
        employedAt: moment(dEmployedAt).format('YYYY-MM-DD'),
        birthday: moment(dBirthday).format('YYYY-MM-DD'),
        duty: [dutyList[0], '추가 선택', '추가 선택'],
        position: positionList[0],
        department: `${data[0].id},${data[0].name}`,
      };
      allDepartmentList.push(data[0].name);
      createStringToAllDepartment(data[0]);
    } else {
      const {
        id,
        email,
        name,
        duty,
        licenseType,
        position,
        phoneNumber,
        telNumber,
        birthday,
        employedAt,
        profileImage,
        sealImage,
      } = defaultObj.user;
      dEmployedAt = employedAt === null ? new Date() : new Date(employedAt);
      dBirthday = birthday === null ? new Date() : new Date(birthday);
      let dDepartment = defaultObj.parentId + ',' + defaultObj.name;
      let dutys =
        duty !== null
          ? duty.split(',')
          : [dutyList[0], '추가 선택', '추가 선택'];
      dObj = {
        id,
        email,
        name,
        employedAt: moment(dEmployedAt).format('YYYY-MM-DD'),
        birthday: moment(dBirthday).format('YYYY-MM-DD'),
        duty: dutys,
        licenseType,
        position: position === null ? positionList[0] : position,
        phoneNumber,
        telNumber,
        department: dDepartment,
        profileImage,
        sealImage,
      };
      createStringToAllDepartment(defaultObj);
    }
    setObj(dObj);
    setUserObj(dObj);
    setEmployedAt(dEmployedAt);
    setBirthday(dBirthday);
  };

  useEffect(() => {
    params.queryParams = { type: OrganizationType.DEPARTMENT };

    // defaultSetting()
    callApi();
  }, [callApi]);

  const onChangeInput = (e) => {
    const { name, value } = e.target;
    if (name.indexOf('duty') > -1) {
      const [, idx] = name.split('-');
      let dutyObj = obj.duty;
      dutyObj[idx] = value;
      settingObj('duty', dutyObj);
    } else {
      settingObj(name, value);
    }
  };

  const onChangeEmployed = (date) => {
    setEmployedAt(date);
    settingObj('employedAt', moment(date).format('YYYY-MM-DD'));
  };

  const onChangeBirthDay = (date) => {
    setBirthday(date);
    settingObj('birthday', moment(date).format('YYYY-MM-DD'));
  };

  const createStringToAllDepartment = (d) => {
    const { parent } = d;
    if (parent !== null) {
      const { name } = parent;
      allDepartmentList.push(name);
      createStringToAllDepartment(parent);
    } else {
      setAllDepartmentStr(allDepartmentList.reverse().join(' > '));
    }
  };

  const settingObj = (name, value) => {
    const userObj = { ...obj, [name]: value };
    setObj(userObj);
    setUserObj(userObj);
  };

  const onChangeDepartment = (e) => {
    const { name, value } = e.target;
    const [departmentValue, departmentName] = value.split(',');
    if (departmentValue !== '-1') {
      allDepartmentList.push(departmentName);
      const user = departmentList.filter((v) => {
        return v.id === Number(departmentValue);
      });
      createStringToAllDepartment(user[0]);
    } else {
      setAllDepartmentStr('');
    }
    settingObj(name, value);
  };

  const onChangeSelect = (e) => {
    const { name, value } = e.target;
    if (value === '직접입력') {
      if (name.indexOf('duty') > -1) {
        const [, idx] = name.split('-');
        let flag = dutyFlag;
        flag[idx] = true;
        setDutyFlag([...flag]);
      } else {
        setPositionFlag(true);
      }
    } else {
      if (name.indexOf('duty') > -1) {
        const [, idx] = name.split('-');
        let dutyObj = obj.duty;
        dutyObj[idx] = value;
        settingObj('duty', dutyObj);
      } else {
        settingObj(name, value);
      }
    }
  };

  const onChangeSealImage = (e) => {
    let target = e.target;
    if (target.files[0] === undefined) {
      return;
    }

    const { size, name } = e.target.files[0];
    const fileType = name.split('.').pop();

    const width = 100;
    const height = 100;
    const confirmType = ['jpg', 'JPG', 'png', 'PNG'];
    // 10Mb
    const confirmSize = 10;
    if (size / 1024 / 1024 > confirmSize) {
      toast.error('10MB 이하로만 첨부가능합니다.');
      return;
    }

    if (confirmType.indexOf(fileType) === -1) {
      toast.error('jpg 또는 PNG 파일만 첨부가능합니다.');
      return;
    }

    const reader = new FileReader();
    reader.onload = function (event) {
      const img = new Image();
      img.src = event.target.result;

      img.onload = function () {
        const w = this.width;
        const h = this.height;

        if (width < w || height < h) {
          toast.error('가로 100px * 세로 100px 이하로만 첨부가능합니다.');
          return;
        }

        setSealImage(event.target.result.toString());
        settingObj(target.name, target.files[0]);
      };
    };
    reader.readAsDataURL(e.target.files[0]);
  };

  const onChangeProfileImage = (e) => {
    let target = e.target;
    if (target.files[0] === undefined) {
      return;
    }

    const { size, name } = e.target.files[0];
    const fileType = name.split('.').pop();

    const width = 100;
    const height = 140;
    const confirmType = ['jpg', 'JPG', 'png', 'PNG'];
    // 10Mb
    const confirmSize = 10;
    if (size / 1024 / 1024 > confirmSize) {
      toast.error('10MB 이하로만 첨부가능합니다.');
      return;
    }

    if (confirmType.indexOf(fileType) === -1) {
      toast.error('jpg 또는 PNG 파일만 첨부가능합니다.');
      return;
    }

    const reader = new FileReader();
    reader.onload = function (event) {
      const img = new Image();
      img.src = event.target.result;

      img.onload = function () {
        const w = this.width;
        const h = this.height;

        if (width < w || height < h) {
          toast.error('가로 100 px * 세로 140px 이하로만 첨부가능합니다.');
          return;
        } else {
          setImage(event.target.result.toString());
          settingObj(target.name, target.files[0]);
        }
      };
    };
    reader.readAsDataURL(target.files[0]);
  };

  return (
    <div className="organ-section flex-row organizations-input">
      <div className="profile">
        <div
          className={`pic ${!image && !obj.profileImage && 'none'}`}
          style={{ backgroundImage: `url(${image || obj.profileImage})` }}
        >
          {/* { obj.profileImage === undefined || obj.profileImage === null ?
          // <img className="img-cover m-a" alt="" src={ blank } />
          <img className="img-cover m-a s2" alt="" src={ image === null ? blank : image } />
          :
          <img className="img-cover m-a" alt="" src={ obj.profileImage } />
          } */}
        </div>
        <button
          className="btn btn-primary flex-wrap"
          onClick={() => profileImage.current.click()}
        >
          {'사진첨부'}
        </button>
        <p>* 사진은 10MB 미만의 JPG, PNG 파일만 업로드 가능</p>
        <p>* 가로 100px * 세로 140px</p>
        <FileInput
          name="imageFile"
          ref={profileImage}
          onChange={onChangeProfileImage}
        />
      </div>
      <div className="table-round">
        <table>
          <tbody>
            <tr>
              <th>
                <label>이메일(ID)</label>
              </th>
              <td>
                <Input
                  maxLength="50"
                  name="email"
                  defaultValue={obj.email}
                  onChange={onChangeInput}
                />
              </td>
            </tr>
            {pageStatus && (
              <tr>
                <th>
                  <label>임시 비밀번호</label>
                </th>
                <td>
                  <Input name="password" onChange={onChangeInput} />
                </td>
              </tr>
            )}
            <tr>
              <th>
                <label>이름</label>
              </th>
              <td>
                <Input
                  name="name"
                  defaultValue={obj.name}
                  onChange={onChangeInput}
                />
              </td>
            </tr>
            <tr>
              <th>
                <label>직무</label>
              </th>
              <td>
                {dutyFlag[0] ? (
                  <Input name="duty-0" onChange={onChangeInput} />
                ) : (
                  <select
                    className="duty"
                    name="duty-0"
                    onChange={onChangeSelect}
                    value={obj.duty !== undefined ? obj.duty[0] : ''}
                  >
                    {obj.duty !== undefined &&
                      dutyList.indexOf(obj.duty[0]) === -1 && (
                        <option>{obj.duty[0]}</option>
                      )}
                    {dutyList.map((v) => {
                      return (
                        <option key={v} value={v}>
                          {v}
                        </option>
                      );
                    })}
                  </select>
                )}
                {dutyFlag[1] ? (
                  <Input name="duty-1" onChange={onChangeInput} />
                ) : (
                  <select
                    className="duty"
                    name="duty-1"
                    onChange={onChangeSelect}
                    value={obj.duty !== undefined && obj.duty[1]}
                  >
                    {obj.duty !== undefined &&
                      addSelectDutyList.indexOf(obj.duty[1]) === -1 &&
                      obj.duty.length >= 2 && <option>{obj.duty[1]}</option>}
                    {addSelectDutyList.map((v) => {
                      return (
                        <option key={v} value={v}>
                          {v}
                        </option>
                      );
                    })}
                  </select>
                )}
                {dutyFlag[2] ? (
                  <Input name="duty-2" onChange={onChangeInput} />
                ) : (
                  <select
                    className="duty"
                    name="duty-2"
                    onChange={onChangeSelect}
                    value={obj.duty !== undefined && obj.duty[2]}
                  >
                    {obj.duty !== undefined &&
                      addSelectDutyList.indexOf(obj.duty[2]) === -1 &&
                      obj.duty.length >= 3 && <option>{obj.duty[2]}</option>}
                    {addSelectDutyList.map((v) => {
                      return (
                        <option key={v} value={v}>
                          {v}
                        </option>
                      );
                    })}
                  </select>
                )}
              </td>
            </tr>
            {obj.duty !== undefined && obj.duty.some((v) => v === '의사') && (
              <>
                <tr>
                  <th>의사면허번호</th>
                  <td>
                    <Input
                      maxLength="6"
                      name="licenseType"
                      defaultValue={obj.licenseType}
                      onChange={onChangeInput}
                    />
                  </td>
                </tr>
                <tr>
                  <th>직인</th>
                  <td>
                    <div className="flex-row seal-contents">
                      {!(!sealImage && !obj.sealImage) && (
                        <div
                          className={`
                          seal-preview
                          flex-wrap
                          m-r-8
                          ${!sealImage && !obj.sealImage && 'none'}
                        `}
                          style={{
                            backgroundImage: `url(${
                              sealImage || obj.sealImage
                            })`,
                          }}
                        />
                      )}
                      <button
                        className="btn btn-basic _small flex-wrap"
                        onClick={() => sealImageRef.current.click()}
                      >
                        {'파일첨부'}
                      </button>
                      <FileInput
                        name="sealFile"
                        ref={sealImageRef}
                        onChange={onChangeSealImage}
                      />
                    </div>
                  </td>
                </tr>
              </>
            )}
            <tr>
              <th>
                <label>직급</label>
              </th>
              <td>
                {positionFlag ? (
                  <Input name="position" onChange={onChangeInput} />
                ) : (
                  <select
                    name="position"
                    onChange={onChangeSelect}
                    value={obj.position || ''}
                  >
                    {positionList.indexOf(obj.position) === -1 && (
                      <option>{obj.position}</option>
                    )}
                    {positionList.map((v) => {
                      return (
                        <option key={v} value={v}>
                          {v}
                        </option>
                      );
                    })}
                  </select>
                )}
              </td>
            </tr>
            <tr>
              <th>휴대폰 번호</th>
              <td>
                <Input
                  maxLength="50"
                  name="phoneNumber"
                  defaultValue={obj.phoneNumber}
                  onChange={onChangeInput}
                />
              </td>
            </tr>
            <tr>
              <th>내선번호</th>
              <td>
                <Input
                  maxLength="50"
                  name="telNumber"
                  defaultValue={obj.telNumber}
                  onChange={onChangeInput}
                />
              </td>
            </tr>
            <tr>
              <th>생년월일</th>
              <td>
                <DatePicker
                  className="text-center"
                  todayButton="오늘"
                  value={birthday}
                  onChange={onChangeBirthDay}
                />
              </td>
            </tr>
            <tr>
              <th>입사일</th>
              <td>
                <DatePicker
                  className="text-center"
                  todayButton="오늘"
                  value={employedAt}
                  onChange={onChangeEmployed}
                />
              </td>
            </tr>
            <tr>
              <th>
                <label>소속 부서</label>
              </th>
              <td>
                <select
                  name="department"
                  onChange={onChangeDepartment}
                  value={
                    obj.department !== undefined
                      ? obj.department.split(',') || ''
                      : ''
                  }
                >
                  {/* <option key={-1} value={[-1, '']}>{'-'}</option> */}
                  {departmentList.map((v, i) => {
                    const { id, name } = v;
                    return (
                      <option value={[id, name]} key={i}>
                        {name}
                      </option>
                    );
                  })}
                </select>
              </td>
            </tr>
            <tr>
              <th>전체부서</th>
              <td>
                <Input onChange={() => ''} value={allDepartmentStr} />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

OrganizationsUserInputForm.propTypes = {
  pageStatus: PropTypes.bool,
  setUserObj: PropTypes.func,
  defaultObj: PropTypes.object,
};

export default observer(OrganizationsUserInputForm);
