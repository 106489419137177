import React, { useState, useEffect, useCallback } from 'react';
import { observer } from 'mobx-react';
import Checkbox from 'components/app/Checkbox';
import DataTableAlpha from 'components/data-table/DataTableAlpha';
import { copy, translate } from 'filters';
import models from 'models';
import { $qb } from 'scripts/querybuilder';
import { useModal } from 'hooks/useModal';
import { useServices } from 'hooks/useServices';
import { useToast } from 'hooks/useToast';

const MediaConnects = () => {
  const modal = useModal();
  const services = useServices();
  const toast = useToast();
  const defaultParams = $qb().limit(10);
  const [params, setParams] = useState(defaultParams);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [showDeactivated, setShowDeactivated] = useState(false);

  const callApi = useCallback(async () => {
    const resp = await services.crm.consultingRequest.media.all(params.build());
    if (!resp) return;

    setData(resp.data);
    setTotal(resp.total);
  }, [services.crm.consultingRequest.media, params]);

  useEffect(() => {
    callApi();
  }, [callApi]);

  const onParams = (p) => {
    setParams(p);
    callApi();
  };

  const onChangeType = () => {
    if (showDeactivated) {
      params.queryParams = {
        limit: params.queryParams.limit,
      };
    } else {
      params.queryParams = {
        limit: params.queryParams.limit,
        isDisabled: showDeactivated,
      };
    }
    onParams(params);
    setShowDeactivated(!showDeactivated);
  };

  const onAction = (obj) => {
    let model = obj.key;
    let object = obj.row;
    let event = obj.event;

    if (['tag'].indexOf(model.column) !== -1) {
      object[model.column] = event.target.value;
    }

    const btnLabel = event.target.innerText;
    if (btnLabel === '수정') {
      object.$$editing = true;
      return setData(copy(data));
    }

    if (btnLabel === '저장') {
      object.$$editing = false;
      update(object);
      return setData(copy(data));
    }

    if (['사용', '미사용'].indexOf(btnLabel) !== -1) {
      return toggleVisibility(object);
    }

    if (model['title'] === 'ACTION' && event.target.dataset.type === 'delete') {
      modal
        .basic({
          body: `정말로 삭제하시겠습니까?`,
          buttons: [
            {
              text: 'CANCEL',
              class: 'btn-default',
            },
            {
              text: 'CONFIRM',
              class: 'btn-primary',
            },
          ],
        })
        .then((idx) => {
          idx === 1 && onClickDelete(object);
        });
    }
  };

  const onClickDelete = useCallback(
    async (object) => {
      try {
        await services.crm.consultingRequest.media.delete(object.id);
        modal
          .confirm({
            type: 'SUCCESS',
            msg: '삭제되었습니다.',
          })
          .then(() => {
            callApi();
          });
      } catch (e) {
        console.log(e.description);
        modal
          .confirm({
            type: 'ERROR',
            msg: e.description,
          })
          .then(() => {
            callApi();
          });
      }
    },
    [services.crm.consultingRequest.media, toast, callApi]
  );

  const update = async (object) => {
    try {
      let payload = { ...object, name: object.tag };
      await services.crm.consultingRequest.media.update(payload);
      modal
        .confirm({
          type: 'SUCCESS',
          msg: '업데이트되었습니다.',
        })
        .then(() => {
          callApi();
        });
    } catch (e) {
      const { name, description } = e;
      console.log(description);
      let message =
        name === 'Conflict' ? translate('ERROR_CONFLICT_CODE') : description;
      modal
        .confirm({
          type: 'ERROR',
          msg: message,
        })
        .then(() => {
          callApi();
        });
    }
  };

  const toggleVisibility = (object) => {
    const { id, name, isDisabled } = object;
    let obj = {
      id,
      name,
      isDisabled: Number(!isDisabled),
    };
    update(obj);
  };

  const onClickCreateMedia = async () => {
    const onConfirm = async (value) => {
      const payload = { name: value };
      try {
        await services.crm.consultingRequest.media.create(payload);
        modal
          .confirm({
            type: 'SUCCESS',
            msg: '생성되었습니다.',
          })
          .then(() => {
            callApi();
          });
      } catch (e) {
        modal
          .confirm({
            type: 'ERROR',
            msg: e.description,
          })
          .then(() => {
            callApi();
          });
      }
    };

    modal
      .input({
        title: '매체',
        inputs: [
          {
            placeholder: '매체',
          },
        ],
      })
      .then((inputs) => {
        if (!inputs || inputs.length === 0) return;

        if (!inputs[0].text) {
          toast.error('ERROR_EMPTY_VALUE');
          return;
        }

        onConfirm(inputs[0].text);
      });
  };

  return (
    <div className="consulting-media m-r-16">
      <div className="media-header">
        <span className="title">매체</span>
        <div className="flex-row items-center">
          <Checkbox
            className="flex-wrap m-r-8"
            checked={showDeactivated}
            toggleHandler={onChangeType}
          />
          <span className="m-r-12">미사용항목 보지 않기</span>
          <button
            className="flex-wrap btn btn-sm btn-add"
            onClick={onClickCreateMedia}
          >
            추가
          </button>
        </div>
      </div>
      <div className="media-body">
        <DataTableAlpha
          model={models.crm.mediaConnects}
          data={data}
          total={total}
          params={params}
          onParams={onParams}
          onAction={onAction}
          hideLimitSetter={true}
          hasDeactivate
          bottomPositionInner
        />
      </div>
    </div>
  );
};

export default observer(MediaConnects);
