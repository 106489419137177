import * as moment from 'moment';
import { translate } from 'filters';

export default {
  keys: [
    {
      title: '전송일',
      noorder: true,
      filter: (o) => {
        return `${moment(o.scheduledAt).format('YYYY-MM-DD HH:mm:ss')}`;
      },
    },
    {
      title: '상태',
      noorder: true,
      filter: (o) => {
        if (o.status === 'success') return '전송성공';
        if (o.status === 'waiting') return '전송대기';
        if (o.status === 'fail') return '전송실패';
        if (o.status === 'canceled') return '전송취소';
        if (o.status === 'sending') return '전송중';
      },
    },
    {
      title: '제목/내용',
      noorder: true,
      type: 'text',
      class: 'title-and-content column-width-px-400',
      filter: (o) => {
        if (o.title === '' || !o.title) {
          return `<div class="contents">
                   <div class="label">${translate('CONTENTS')}</div>
                   <div class="text">${o.contents || ''}</div>
                 </div>`;
        }
        return `<div class="title">
                   <div class="label">${translate('TITLE')}</div>
                   <div class="text">${o.title || ''}</div>
               </div>
               <div class="contents">
                   <div class="label">${translate('CONTENTS')}</div>
                   <div class="text">${o.contents || ''}</div>
               </div>`;
      },
    },
    {
      title: 'IMAGE',
      noorder: true,
      type: 'image',
      class: 'image-preview column-width-px-200',
      filter: (o) => {
        return o.imageUrl;
      },
    },
  ],
};
