import { observer } from 'mobx-react';
import React, { useContext } from 'react';
import { Transformer, Text } from 'react-konva';
import { ImageEditorContext } from './ImageEditor';

const fontFamily = 'NotoSans';

export default observer(function TextItem({ text }) {
  // eslint-disable-next-line no-unused-vars
  const { stage, ui } = useContext(ImageEditorContext);
  const shapeRef = React.useRef();
  const trRef = React.useRef();

  const isSelected =
    ui.tool === 'select' && ui.selected !== 0 && text.id === ui.selected;

  React.useEffect(() => {
    if (isSelected) {
      // we need to attach transformer manually
      trRef.current.nodes([shapeRef.current]);
      trRef.current.getLayer().batchDraw();
    }
  }, [isSelected]);

  function onChange(update) {
    text.update(update);
  }

  function onTransform() {
    const node = shapeRef.current;

    const div = window.document.createElement('div');
    div.textContent = text.text;
    div.style.fontSize = node.fontSize() + 'px';
    div.style.width = `${node.width() * node.scaleX()}px`;
    div.style.overflow = 'hidden';
    div.style.position = 'absolute';
    div.style.top = '0px';
    div.style.left = '0px';
    div.style.color = 'white';
    div.style.whiteSpace = 'pre-wrap';
    div.style.wordBreak = 'break-all';
    div.style.lineHeight = '1';
    div.style.fontFamily = fontFamily;
    div.style.fontWeight = 'normal';

    window.document.body.append(div);

    node.setAttrs({
      width: node.width() * node.scaleX(),
      height: div.scrollHeight,
      scaleX: 1,
    });

    div.remove();
  }

  function onTransformEnd() {
    const node = shapeRef.current;

    // we will reset it back
    node.scaleX(1);
    node.scaleY(1);
    onChange({
      x: node.x(),
      y: node.y(),
      // set minimal value
      width: Math.max(5, node.width()),
    });
  }

  function onDoubleClick() {
    if (ui.tool === 'select') {
      ui.selectTool('text');
    }

    if (ui.tool !== 'text') return;
    const textNode = shapeRef.current;
    // create textarea over canvas with absolute position
    // first we need to find position for textarea
    // how to find it?

    // at first lets find position of text node relative to the stage:
    var textPosition = textNode.absolutePosition();

    // so position of textarea will be the sum of positions above:
    var areaPosition = {
      x: textPosition.x,
      y: textPosition.y,
    };

    ui.setEditTextValue(text.escapedText);
    ui.setEditText({
      id: text.id,
      props: {
        width: textNode.width(),
        height: textNode.height(),
        fontSize: textNode.fontSize(),
        fill: textNode.fill(),
      },
      style: {
        top: areaPosition.y + 'px',
        left: areaPosition.x + 'px',
        // width: textNode.width() - textNode.padding() * 2 + "px",
        // height: textNode.height() - textNode.padding() * 2 + 5 + "px",
        fontSize: textNode.fontSize() + 'px',
        color: textNode.fill(),
      },
    });
    ui.setSelected(0);
  }

  function onSelect(e) {
    e.cancelBubble = true;
    ui.setSelected(text.id);
  }

  return (
    <React.Fragment>
      {!(ui.editText && ui.editText.id === text.id) && (
        <Text
          onClick={onSelect}
          ref={shapeRef}
          {...text}
          text={text.text}
          draggable={ui.tool === 'select'}
          onDragEnd={(e) => {
            onChange({
              x: e.target.x(),
              y: e.target.y(),
            });
          }}
          fontFamily={fontFamily}
          lineHeight={1}
          wrap="char"
          verticalAlign="top"
          onTransform={onTransform}
          onTransformEnd={onTransformEnd}
          onDblClick={onDoubleClick}
        />
      )}
      {isSelected && (
        <Transformer
          ref={trRef}
          rotateEnabled={false}
          enabledAnchors={['middle-left', 'middle-right']}
          boundBoxFunc={(oldBox, newBox) => {
            // limit resize
            if (newBox.width < 5 || newBox.height < 5) {
              return oldBox;
            }
            return newBox;
          }}
        />
      )}
    </React.Fragment>
  );
});
