import { observer } from 'mobx-react';
import React from 'react';

const AppointmentCellItems = observer(({ appointment }) => {
  const items =
    (appointment.type !== 'surgery'
      ? appointment.items
      : appointment.paymentTreatmentItems) ?? [];

  return (
    <>
      {items.map((item, i) => (
        <React.Fragment key={i}>
          <div className="category-item">{item.category?.name}</div>
          <div className="category-item">{item.name}</div>
        </React.Fragment>
      ))}
    </>
  );
});

export default AppointmentCellItems;
