import { currency } from 'filters';
import moment from 'moment';

/*
서브테이블 좌측 sticky 스타일
1. class: 'sub-sticky', 속성 추가
*/

const paymentMethod = {
  credit_card: '신용',
  debit_card: '체크',
  cash: '현금',
  bank_transfer: '계좌',
  account: '',
  account_point: '',
};

const contextItems = [{ title: '수정' }, { title: '삭제' }];

export default {
  options: {
    subColspan: [2, 11, 3], //sub-table colspan
  },
  keys: [
    {
      title: '',
      type: 'sub-line',
      // class: 'title',
      thStyle: () => {
        return { width: '76px' };
      },
      tdDivStyle: () => {
        return { width: '76px' };
      },
      customStyle: () => {
        return { width: '76px' };
      },
    },
    {
      title: '수납일',
      column: 'paidAt',
      tdDivStyle: () => {
        return { width: '90px' };
      },
      customStyle: () => {
        return { width: '90px' };
      },
      filter: (o) =>
        `<div class="text-underline">${moment(o.paidAt).format(
          'YYYY.MM.DD'
        )}</div>`,
      useContext: true,
      contextTrigger: 'click',
      contextItems,
    },
    {
      title: '담당자',
      column: 'createdName',
      tdDivStyle: () => {
        return { width: '44px' };
      },
      customStyle: () => {
        return { width: '44px' };
      },
      useContext: true,
      contextTrigger: 'doubleClick',
      contextItems,
    },
    {
      title: '결제',
      column: 'paymentMethod',
      tdDivStyle: () => {
        return { width: '43px' };
      },
      customStyle: () => {
        return { width: '43px' };
      },
      filter: (o) => {
        return paymentMethod[o.paymentMethod];
      },
      useContext: true,
      contextTrigger: 'doubleClick',
      contextItems,
    },
    {
      title: '카드사/은행명',
      column: 'companyName',
      tdDivStyle: () => {
        return { width: '194px' };
      },
      customStyle: () => {
        return { width: '194px' };
      },
      useContext: true,
      contextTrigger: 'doubleClick',
      contextItems,
    },
    {
      title: '수납/환불액',
      column: 'amount',
      tdDivStyle: () => {
        return { width: '90px' };
      },
      customStyle: (o) => {
        return o.status === 'refunds'
          ? { color: '#f7685b' }
          : { color: '#0060ff' };
      },
      filter: (o) => {
        return o.status === 'refunds'
          ? `- ${currency(Number(o.amount))}`
          : `+ ${currency(Number(o.amount))}`;
      },
      useContext: true,
      contextTrigger: 'doubleClick',
      contextItems,
    },
    {
      title: '현금영수증',
      column: 'cashReceipt',
      filter: (o) => {
        let cashReceipt = '-';
        if (o.paymentMethod === 'cash' || o.paymentMethod === 'bank_transfer') {
          cashReceipt = o.cashReceipt ? '발행' : '미발행';
        }

        return cashReceipt;
      },
      useContext: true,
      contextTrigger: 'doubleClick',
      contextItems,
    },
  ],
};
