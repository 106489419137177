import React, { useState } from 'react';
import { observer } from 'mobx-react';
import OrganizationsTreeArea from 'components/pages/crm/organizations/OrganizationsTreeArea';
import OrganizationsMemberList from 'components/pages/crm/organizations/OrganizationsMemberList';

const Organizations = () => {
  const [treeSelectId, setTreeSelectId] = useState('');
  const [treeSelectFlag, setTreeSelectFlag] = useState(false);

  return (
    <div className="list organizations">
      <div className="card">
        <div className="flex-row">
          <div className="organizations-tree flex-wrap">
            <div className="tree-title">조직도</div>
            <OrganizationsTreeArea
              setParentId={setTreeSelectId}
              setTreeSelectFlag={setTreeSelectFlag}
            />
          </div>
          <div className="organizations-view">
            <OrganizationsMemberList parentId={treeSelectId} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(Organizations);
