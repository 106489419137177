import React, { useContext, useRef, useEffect } from 'react';
import { Transformer, Image } from 'react-konva';
import useImage from 'use-image';
import { observer } from 'mobx-react';
import { ImageEditorContext } from './ImageEditor';

export default observer(function AttachedImage({ image }) {
  const { ui } = useContext(ImageEditorContext);
  const [img] = useImage(image.url);
  const shapeRef = useRef();
  const trRef = useRef();

  const isSelected =
    ui.tool === 'select' && ui.selected !== 0 && image.id === ui.selected;

  useEffect(() => {
    if (isSelected) {
      // we need to attach transformer manually
      trRef.current.nodes([shapeRef.current]);
      trRef.current.getLayer().batchDraw();
    }
  }, [isSelected]);

  function onSelect(e) {
    e.cancelBubble = true;
    ui.setSelected(image.id);
  }

  return (
    <>
      <Image
        ref={shapeRef}
        image={img}
        id={image.id}
        x={image.x}
        y={image.y}
        draggable={ui.tool === 'select'}
        onClick={onSelect}
      />
      {isSelected && (
        <Transformer
          ref={trRef}
          rotateEnabled={false}
          boundBoxFunc={(oldBox, newBox) => {
            // limit resize
            if (newBox.width < 5 || newBox.height < 5) {
              return oldBox;
            }
            return newBox;
          }}
        />
      )}
    </>
  );
});
