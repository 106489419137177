import moment from 'moment';
import { formatCallerNumber, translate } from 'filters';

export default {
  keys: [
    {
      title: '번호',
      column: 'no',
      noorder: true,
      class: 'flex-center no',
    },
    {
      title: 'MESSAGE_SCHEDULED_AT',
      column: 'scheduledAt',
      filter: (o) => {
        // if( !moment(o.updatedAt, serverTimezoneDateFormat, true).isValid()){
        //   return o.updatedAt
        // }
        return `<div>
      <div>${moment(o.scheduledAt).format('YYYY-MM-DD')}</div>
      <div style="text-align:center;">${moment(o.scheduledAt).format(
        'HH:mm:ss'
      )}</div>
    </div>`;
      },
    },
    {
      title: '전송상태',
      noorder: true,
      filter: (o) => {
        //success, waiting, fail
        //발송성공, 발송대기중, 발송 실패)
        return o.status
          ? translate(`MESSAGE_RESULT_STATUS_${o.status.toUpperCase()}`)
          : '-';
      },
    },
    {
      title: 'LABEL_CONDITION_CUSTOMER_NAME',
      column: 'customerName',
      noorder: true,
    },
    {
      title: 'LABEL_CONDITION_CUSTOMER_PHONENUMBER',
      noorder: true,
      filter: (o) => formatCallerNumber(o.phoneNumber),
    },
    {
      title: 'CALLER_NUMBER',
      noorder: true,
      filter: (o) => {
        if (o.callerNumber && o.callerNumber.number) {
          return formatCallerNumber(o.callerNumber.number);
        }
        return '-';
      },
    },
    {
      title: 'TITLE_AND_CONTENT',
      noorder: true,
      type: 'text',
      class: 'title-and-content ',
      filter: (o) => {
        if (o.mini_contents.length > 22) {
          o.mini_contents = o.mini_contents.substring(0, 21) + '...';
        }
        if (o.title === '' || !o.title) {
          return `<div class="contents">
                    <div style="line-height:3.2; overflow: hidden;
                    text-overflow: ellipsis"><u >${o.contents || ''}</u></div>
                 </div>`;
        }
        return `<div class="title">
                  <div><u>${o.title || ''}</u></div>
               </div>
               <div class="contents">
                    <div style=" overflow: hidden;
                    text-overflow: ellipsis"><u >${o.contents || ''}</u></div>
                 </div>`;
      },
    },
    {
      title: 'IMAGE',
      noorder: true,
      type: 'image',
      class: 'image-preview ',
      filter: (o) => {
        if (!o.imageUrl) return;
        return o.imageUrl;
      },
    },
    {
      title: '종류',
      noorder: true,
      filter: (o) => {
        return o.smsNotificationChannel.toUpperCase();
      },
    },
    {
      title: 'FAIL_REASON',
      noorder: true,
      filter: (o) => {
        if (o.failReason === 'invalidVariabled') o.failReason = '치환불가변수';
        if (o.failReason === 'InvalidPhoneNumber')
          o.failReason = '유효하지않은번호';
        if (o.failReason === 'UnknownException') o.failReason = '기타';
        return o.failReason ? o.failReason : '-';
      },
    },
    {
      title: 'NUMBER_OF_CLICKED_BABITALK',
      noorder: true,
      filter: (o) => {
        return o.isBabitalkClick ? 'Y' : 'N';
      },
    },
    {
      title: 'NUMBER_OF_CONVERSIONS',
      noorder: true,
      filter: (o) => {
        return o.isConsultingRequest ? 'Y' : 'N';
      },
    },
  ],
};
