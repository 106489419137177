export default {
  keys: [
    {
      title: 'ACTION',
      noorder: true,
      filter: (o) => `
        ${
          o.connectionType === 'manual' && !o.isDisabled
            ? `<button class="btn btn-sm btn-primary m-r-8">${
                o.$$editing ? '저장' : '수정'
              }</button>`
            : ''
        }
        ${
          o.connectionType === 'manual'
            ? `<button class="btn btn-sm m-r-8 ${
                o.isDisabled ? 'btn-gray' : 'btn-black'
              }">${o.isDisabled ? '사용' : '미사용'}</button>`
            : ''
        }
        <button class="btn btn-sm m-r-8 ${
          o.isDisabled ? 'btn-gray' : 'btn-danger'
        }" data-type="delete" >삭제</button>`,
    },
    {
      title: '매체',
      column: 'tag',
      noorder: true,
      type: 'editable',
    },
  ],
};
