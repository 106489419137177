import React from 'react';
import { observer } from 'mobx-react';
import { translate } from 'filters';
import MediaConnects from 'components/pages/crm/consulting-request/MediaConnects';
import ConsultingEvents from 'components/pages/crm/consulting-request/ConsultingEvents';
import ConsultingRequestsCategories from 'components/pages/crm/consulting-request/ConsultingRequestsCategories';
import ConsultingRequestConnect from 'components/pages/crm/consulting-request/ConsultingRequestConnect';
import ConsultingAutoAssignSettings from 'components/pages/crm/consulting-request/ConsultingAutoAssignSettings';

const ConsultingRequestConnects = () => {
  return (
    <div className={`connects list`}>
      <div className="page-navi">
        <span className="title">상담문의 관리</span>
        <span className="title">{translate('MEDIA_CONNECT')}</span>
      </div>
      <div className="cust-box">
        <ConsultingAutoAssignSettings />
      </div>
      <div className="cust-box">
        <ConsultingRequestConnect />
      </div>
      <div className="cust-box">
        <ConsultingRequestsCategories />
      </div>
      <div className="cust-box">
        <MediaConnects />
      </div>
      <div className="cust-box">
        <ConsultingEvents />
      </div>
    </div>
  );
};

export default observer(ConsultingRequestConnects);
