import React, { useCallback, useContext } from 'react';
import { observer } from 'mobx-react';
import { AppointmentBoardStateContext } from './AppointmentBoards';
import AppointmentCellsList from './AppointmentCellsList';
import ModalCustomerFinder from 'components/modals/ModalCustomerFinder';
import hooks from 'hooks';
import moment from 'moment';
import { useModal } from 'hooks/useModal';

const contextItems = {
  copied_items: [{ title: '붙여넣기' }],
};

const CalendarCell = observer(({ department, date, startTime, index }) => {
  const modal = useModal();
  /**
   * @type {AppointmentBoardState }
   */
  const state = useContext(AppointmentBoardStateContext);

  const getScheduleDatetime = useCallback(() => {
    const datetime = new Date(date);
    const [hour, min] = startTime.split(':');
    datetime.setHours(Number(hour), Number(min));
    return moment(datetime).format('YYYY-MM-DDTHH:mm:ss');
  }, [date, startTime]);

  const absenceDepartment = state.departmentAbsence.some(
    (v) =>
      (v.department || {}).id === department.id &&
      moment(v.scheduledAt).format('YYYY-MM-DD') === date
  )
    ? true
    : false;

  const widthSize =
    state.timedAppointments[department.id]?.[date]?.timedAppointments[startTime]
      ?.length ?? 0;

  const onDragOver = useCallback((e) => {
    e.preventDefault();
    e.dataTransfer.dropEffect = 'move';
    state.setTargetSchedule({
      startHour: startTime,
      department: department,
    });
  }, []);

  const onContextMenu = useCallback(
    (e) => {
      if (!state.copied) return;

      e.preventDefault();
      state.setContextMenuOptions({
        show: true,
        x: e.clientX,
        y: e.clientY,
        items: contextItems['copied_items'],
      });
      state.setCopySchedule({
        startHour: startTime,
        department: department,
        scheduledAt: getScheduleDatetime(),
      });
      state.setShowHoverCard(false);
      state.setAppointment(null);
    },
    [department, startTime, getScheduleDatetime]
  );

  const onDoubleClicked = () => {
    if (absenceDepartment) {
      modal
        .basic({
          body: '휴진설정한 부서입니다. 그래도 예약을 생성하시겠습니까?',
          buttons: [
            { text: '취소', class: 'btn-default' },
            { text: '확인', class: 'btn-primary' },
          ],
        })
        .then((idx) => {
          if (idx === 1) {
            showSearchCustomer();
          }
        });
    } else showSearchCustomer();
  };

  const showSearchCustomer = useCallback(() => {
    modal
      .custom({
        component: ModalCustomerFinder,
        options: {
          customerData: {},
        },
      })
      .then((customer) => {
        if (!customer) return;

        hooks.openCustomerChart({
          customer,
          selectTab: 'APPOINTMENT',
          openFlag: {
            appointment: {
              customer,
              department: department,
              scheduledAt: getScheduleDatetime(),
              startHour: startTime,
            },
          },
          calledPage: 'boards',
          // appointment: {
          //   customer,
          //   department: schedule.department,
          //   scheduledAt: schedule.scheduledAt,
          //   startHour: schedule.startHour,
          // },
        });
      });
  }, [department, startTime, getScheduleDatetime]);

  const lunchClass =
    state.clinic.lunchEnabled &&
    state.clinic.lunchStart <= startTime &&
    startTime < state.clinic.lunchEnd
      ? 'lunch'
      : '';

  return (
    <div
      className={`calendar-cell wrap-scheduled wrap-draggable size-${widthSize} ${lunchClass}`}
      onContextMenu={(e) => onContextMenu(e)}
      onDoubleClick={() => {
        onDoubleClicked();
      }}
      onDragOver={onDragOver}
      style={{
        backgroundColor: state.getDepartmentColor(department.id, date),
        borderRight:
          state.mode === 'vertical'
            ? state.calendarUnit === '3-day'
              ? state.threeDaysArr.some((v) => v === index + 1) &&
                '3px solid #d7e3f1'
              : state.calendarUnit === 'week'
              ? state.weekArr.some((v) => v === index + 1) &&
                '3px solid #d7e3f1'
              : state.calendarUnit === 'day'
              ? state.dateCategoryDepartments.length === index + 1 &&
                '3px solid #d7e3f1'
              : ''
            : undefined,
        borderBottom:
          state.mode === 'horizontal'
            ? state.calendarUnit === '3-day'
              ? state.threeDaysArr.some((v) => v === index + 1) &&
                '3px solid #d7e3f1'
              : state.calendarUnit === 'week'
              ? state.weekArr.some((v) => v === index + 1) &&
                '3px solid #d7e3f1'
              : state.calendarUnit === 'day'
              ? state.dateCategoryDepartments.length === index + 1 &&
                '3px solid #d7e3f1'
              : ''
            : undefined,
      }}
    >
      <AppointmentCellsList
        department={department}
        date={date}
        left={startTime}
      />
    </div>
  );
});

export default CalendarCell;
