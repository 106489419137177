import React, { useCallback, useState, useEffect, useRef } from 'react';
import { observer } from 'mobx-react';
import { $case, pluralize, translate } from 'filters';
import Phone from 'components/app/Phone';
import hooks from 'hooks';
import ModalCustomerFinder from 'components/modals/ModalCustomerFinder';
import QuillTextField from 'components/quill/QuillTextField';
import Select from 'react-select';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useAuth } from 'store/auth';
import { useEvent } from 'hooks/useEvent';
import { useModal } from 'hooks/useModal';
import { useServices } from 'hooks/useServices';
import { useToast } from 'hooks/useToast';
import styled, { css } from 'styled-components';
import DatePicker from 'components/common/DatePicker';

const Input = styled.input`
  width: 100%;
  height: 34px;
  padding: 8px 20px;
  border-color: #d7e3f1;
  font-size: 12px;
  min-height: 10px;
  border-radius: 4px;
  background-color: #f3f8ff;
  border: 1px solid #dee2ec;

  ${({ type }) => {
    switch (type) {
      case 'small':
        return css`
          width: 50px;
          margin-left: 5px;
        `;
      case 'medium':
        return css`
          width: 170px;
          margin-right: 5px;
        `;
    }
  }}

  ${({ disabled }) =>
    disabled &&
    css`
      background-color: #efeff4 !important;
      color: #ceced7;
    `}
`;

const Separate = styled.div`
  margin: 0 5px;
`;

const RecommenderInputWrapper = styled.div`
  margin-right: 8px;
`;

const RecommenderInput = styled(Input)`
  background: #f3f8ff !important;
`;

const CustomerAddChartInfo = ({ customer }) => {
  const auth = useAuth();
  const eventBus = useEvent();
  const modal = useModal();
  const services = useServices();
  const toast = useToast();
  const [counselors, setCounselors] = useState([]);
  const [doctors, setDoctors] = useState([]);
  const [job, setJob] = useState([]);
  const [acquisitionChannel, setAcquisitionChannel] = useState([]);
  const [level, setLevel] = useState([]);
  const [complaint, setComplaint] = useState([]);
  const [region, setRegion] = useState([]);
  const [treatmentItemCategories, setTreatmentItemCategories] = useState([]);
  const [loadingBtnDisabledFlag, setLoadingBtnDisabledFlag] = useState(false);
  const [beforePhoneNumber, setBeforePhoneNumber] = useState({
    phoneNumber: '',
  });
  const [chartInputFlag, setChartInputFlag] = useState(true);
  const inputRef = useRef();
  const ssnRef = useRef();
  const calendar = useRef(null);

  const [ssnBirthdayFirstInputCheck, setSsnBirthdayFirstInputCheck] = useState(
    false
  );

  const [obj, setObj] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [radioItemsType] = useState([
    { value: 'domestic', label: 'DOMESTIC' },
    { value: 'foreigner', label: 'FOREIGN' },
  ]);
  const [radioItemsSex] = useState([
    { value: 'male', label: 'MALE' },
    { value: 'female', label: 'FEMALE' },
  ]);
  const [radioItemsSmsEnabled] = useState([
    { value: true, label: 'AGREE' },
    { value: false, label: 'DISAGREE' },
  ]);
  const [radioItemsEventSmsEnabled] = useState([
    { value: true, label: 'AGREE' },
    { value: false, label: 'DISAGREE' },
  ]);

  const disabled = useCallback(
    () =>
      hooks.disabledField({
        obj,
        requiredFields: ['type', 'sex', 'smsEnabled', 'eventSmsEnabled'],
      }),
    [obj]
  );

  const configCallApi = useCallback(async () => {
    try {
      const resp = await services.crm.clinic.configs({
        groupName: 'customer',
      });

      if (resp && resp.data.length < 1) return;
      else {
        if (!customer || !customer.id)
          setObj({
            ...obj,
            type: 'domestic',
            smsEnabled: true,
            eventSmsEnabled: true,
            sex: resp.data[0].codeValue,
          });
      }
    } catch (e) {
      console.log(e.description);
    }
  }, [services.crm.clinic]);

  useEffect(() => {
    configCallApi();
  }, []);

  const treatmentItemCategoriesCallApi = useCallback(async () => {
    try {
      let params = { limit: 10000, visible: true };
      const resp = await services.crm.treatment.categories.items_categories_v2(
        params
      );
      if (!resp) return;
      setTreatmentItemCategories(resp.data);
    } catch (e) {
      console.log(e.description);
    }
  }, [services.crm.treatment.categories]);

  useEffect(() => {
    treatmentItemCategoriesCallApi();
  }, [treatmentItemCategoriesCallApi]);

  const acquisitionChannelsCallApi = useCallback(async () => {
    let params = { limit: 300, visible: true };
    const resp = await services.crm.customer.acquisitionChannel.all(params);
    if (!resp) return;

    setAcquisitionChannel(resp.data);
  }, [services.crm.customer.acquisitionChannel]);

  useEffect(() => {
    acquisitionChannelsCallApi();
  }, [acquisitionChannelsCallApi]);

  const jobsCallApi = useCallback(async () => {
    let params = { limit: 300, visible: true };
    const resp = await services.crm.customer.job.all(params);
    if (!resp) return;

    setJob(resp.data);
  }, [services.crm.customer.job]);

  useEffect(() => {
    jobsCallApi();
  }, [jobsCallApi]);

  const levelsCallApi = useCallback(async () => {
    let params = { limit: 300, visible: true };
    const resp = await services.crm.customer.level.all(params);
    if (!resp) return;

    setLevel(resp.data);
  }, [services.crm.customer.level]);

  useEffect(() => {
    levelsCallApi();
  }, [levelsCallApi]);

  const complaintsCallApi = useCallback(async () => {
    let params = { limit: 300, visible: true };
    const resp = await services.crm.customer.complaint.all(params);
    if (!resp) return;

    setComplaint(resp.data);
  }, [services.crm.customer.complaint]);

  useEffect(() => {
    complaintsCallApi();
  }, [complaintsCallApi]);

  const regionCallApi = useCallback(async () => {
    let params = { limit: 300, visible: true };
    const resp = await services.crm.customer.region.all(params);
    if (!resp) return;

    setRegion(resp.data);
  }, [services.crm.customer.region]);

  useEffect(() => {
    regionCallApi();
  }, [regionCallApi]);

  const counselorCallApi = useCallback(async () => {
    let params = { duty: '상담사', userStatus: 'active', limit: 300 };
    const resp = await services.crm.user.duty(params);
    if (!resp) return;

    setCounselors(resp.data);
  }, [services.crm.user]);

  useEffect(() => {
    counselorCallApi();
  }, [counselorCallApi]);

  const doctorCallApi = useCallback(async () => {
    let params = { duty: '의사', userStatus: 'active', limit: 300 };
    const resp = await services.crm.user.duty(params);
    if (!resp) return;

    setDoctors(resp.data);
  }, [services.crm.user]);

  useEffect(() => {
    doctorCallApi();
  }, [doctorCallApi]);

  const onClickSave = useCallback(async () => {
    const disabledField = disabled();
    if (disabledField) {
      setLoadingBtnDisabledFlag(false);
      toast.error(disabledField);
      return;
    }

    let payload = { ...obj };
    //전화번호 '-' 제거
    if (payload.phoneNumber) {
      payload.phoneNumber = payload.phoneNumber.replace(/-/gi, '');
    }
    //주민등록번호 가공
    payload.ssnHead = (payload.ssnHead1 || '') + (payload.ssnHead2 || '');
    !payload.ssnHead && delete payload.ssnHead;
    delete payload.ssnHead1;
    delete payload.ssnHead2;

    //키, 몸무게 빈 값 일때 null 처리
    payload.weight = payload.weight === '' ? null : payload.weight;
    payload.height = payload.height === '' ? null : payload.height;

    let endpoint = !obj.id ? 'create' : 'update';
    setLoadingBtnDisabledFlag(true);
    try {
      if (endpoint === 'create') {
        const customer = await services.crm.customer.create(payload);
        await modal.confirm({
          type: 'SUCCESS',
          msg: translate(`${endpoint.toUpperCase()}D`),
        });
        modal.pop();
        if (JSON.parse(window.localStorage.getItem('isCustomerChartOpend'))) {
          hooks.openCustomerChart({ customer: customer.data });
        }
      } else if (endpoint === 'update') {
        // 01.21
        // 백엔드 레거시 문제로 일단 front request에서 id값만 빼서 db 반영이 안되게 처리
        let updatedId = payload.id;
        delete payload.id;
        await services.crm.customer.update_id(updatedId, payload);
        await modal.confirm({
          type: 'SUCCESS',
          msg: translate(`${endpoint.toUpperCase()}D`),
        });
        eventBus.emit('customerCallApi');
        modal.pop();
      }
    } catch (e) {
      const { description } = e;
      console.log(description);
      setLoadingBtnDisabledFlag(false);
      if (e.code === 400 && e.description === 'Duplicated chart number') {
        modal
          .basic({
            body: '동일한 차트번호가 존재합니다. 차트번호를 수정해 주세요.',
            buttons: [
              {
                text: 'CONFIRM',
                class: 'btn-primary',
              },
            ],
          })
          .then(() => {
            setLoadingBtnDisabledFlag(false);
          });
      } else {
        modal
          .confirm({
            type: 'ERROR',
            msg: description,
          })
          .then(() => {
            setLoadingBtnDisabledFlag(false);
          });
      }
    }
  }, [eventBus, modal, services, toast, obj, disabled]);

  const onClickAddFavorite = () => {
    const categories = treatmentItemCategories || [];
    if (categories.length === 0) {
      toast.error('수납코드 설정에서 시/수술을 추가해주세요.');
      return;
    }
    obj.favorites = (obj.favorites || []).concat(categories[0]);
    setObj({ ...obj });
  };

  const onCustomerCheck = async () => {
    try {
      if (obj.phoneNumber) {
        let modalMsg = '';
        let phoneNumber = obj.phoneNumber.replace(/-/gi, '');

        const resp_phone = await services.crm.customer.all({ phoneNumber });

        const phoneCheckList = resp_phone.data.filter(
          (v) => v.phoneNumber === phoneNumber
        );

        const nameCheckList = resp_phone.data.filter(
          (v) => v.name === obj.name
        );

        //팝업 메세지
        if (phoneCheckList.length > 0) {
          modalMsg = '이미 등록되어있는 연락처입니다. 그래도 등록하시겠습니까?';
        }

        if (nameCheckList.length > 0) {
          modalMsg = '이미 등록되있는 고객입니다. 그래도 등록하시겠습니까?';
        }

        //수정화면 , 기존 번호일 경우
        if (
          obj.id &&
          beforePhoneNumber.phoneNumber.replace(/-/gi, '') === phoneNumber
        )
          modalMsg = '';

        if (modalMsg) {
          modal
            .basic({
              body: modalMsg,
              buttons: [
                {
                  text: 'CANCEL',
                  class: 'btn-default',
                },
                {
                  text: 'CONFIRM',
                  class: 'btn-primary',
                },
              ],
            })
            .then((selectedIdx) => {
              if (selectedIdx) onClickSave();
            });
          setLoadingBtnDisabledFlag(false);
        } else {
          onClickSave();
        }
      } else {
        onClickSave();
      }
    } catch (e) {
      console.log(e.description);
      setLoadingBtnDisabledFlag(false);
    }
  };

  const openModalCustomerFinder = () => {
    modal
      .custom({
        component: ModalCustomerFinder,
        options: { customerData: {} },
      })
      .then((customer) => {
        if (!customer) return;
        obj.recommenderId = customer.id;
        obj.recommender = customer;
        obj.recommenderName = customer.name;
        setObj({ ...obj });
      });
  };

  const onChangeValue = useCallback(
    (column, value) => {
      //숫자만 입력되는 항목
      if (
        (column === 'ssnHead1' ||
          column === 'ssnHead2' ||
          column === 'height' ||
          column === 'weight') &&
        !/^[0-9]*$/.test(value)
      ) {
        toast.error('ERROR_NUMBERS_ONLY');
        return;
      }

      let inputObj = {
        ...obj,
        [column]: value,
      };

      if (column === 'birthday' && !ssnBirthdayFirstInputCheck) {
        let changeValue =
          value === null ? null : moment(value).format('YYYYMMDD');

        if (changeValue === null || changeValue === '') return;
        let ssnValue = changeValue.substr(2, 8);
        inputObj.ssnHead1 = ssnValue;

        //6자리가 모두 입력되었을 때, 최초 상호연동, 그 이후 연동 안함
        setSsnBirthdayFirstInputCheck(true);
      }

      let date = '20' + value;
      let birthday = new Date(moment(date).format('YYYY-MM-DD'));
      if (
        column === 'ssnHead1' &&
        value.length === 6 &&
        !isNaN(birthday) &&
        !ssnBirthdayFirstInputCheck
      ) {
        //주민등록번호 인풋박스 포커스 이동
        ssnRef.current.focus();

        //6자리가 모두 입력되었을 때, 생년월일에 매칭
        let date = '20' + value;
        let birthday = new Date(moment(date).format('YYYY-MM-DD'));
        if (birthday > new Date()) {
          birthday.setFullYear(birthday.getFullYear() - 100);
        }

        let changeValue =
          birthday === null ? null : moment(birthday).format('YYYY-MM-DD');
        inputObj.birthday = changeValue;

        //6자리가 모두 입력되었을 때, 최초 상호연동, 그 이후 연동 안함
        setSsnBirthdayFirstInputCheck(true);
      }

      setObj(inputObj);
    },
    [obj]
  );

  useEffect(() => {
    let _obj = {};
    if (customer !== null && customer.id) {
      _obj = { ...customer };

      // 주민등록번호가 있는 경우
      if (_obj.ssnHead) {
        //7자리인 경우 slice
        if (_obj.ssnHead.length === 7) {
          _obj.ssnHead1 = _obj.ssnHead.slice(0, 6);
          _obj.ssnHead2 = _obj.ssnHead.slice(-1);
        } else {
          _obj.ssnHead1 = _obj.ssnHead;
        }
      }

      setBeforePhoneNumber({
        phoneNumber: _obj.phoneNumber,
      });

      //수정팝업에서는 주민등록번호-생년월일 상호연동하지 않음.
      setSsnBirthdayFirstInputCheck(true);
    } else {
      _obj = {
        type: 'domestic',
        sex: obj && obj.sex ? obj.sex : 'female',
        smsEnabled: true,
        eventSmsEnabled: true,
      };
    }

    if ((customer || {}).id === undefined) {
      //신규고객등록시 접속한 계정의 id가 상담사,의사 목록에 존재하면, 자동입력되도록
      if (counselors.length > 0) {
        if (
          counselors.findIndex((existing) => existing.id === auth.me.id) > -1
        ) {
          _obj['counselor'] =
            counselors[
              counselors.findIndex((existing) => existing.id === auth.me.id)
            ];
        }
      }

      if (doctors.length > 0) {
        if (doctors.findIndex((existing) => existing.id === auth.me.id)) {
          _obj['doctor'] =
            doctors[
              doctors.findIndex((existing) => existing.id === auth.me.id)
            ];
        }
      }
    }

    setObj({ ..._obj });
    setLoaded(true);
  }, [auth, customer, doctors, counselors]);

  useEffect(() => {
    if (loaded) {
      inputRef.current.focus();
    }
  }, [loaded]);

  const onChangePicker = (birthday, e) => {
    if (e.target.value !== undefined && !/^[0-9]*$/.test(e.target.value)) {
      toast.error('ERROR_NUMBERS_ONLY');
      let changeObj = { ...obj };
      delete changeObj.birthday;

      setObj(changeObj);
      calendar.current.setOpen(false);
      return;
    }

    if (
      birthday === null ||
      e.target.value === undefined ||
      e.target.value.length === 6
    ) {
      //데이트 피커 날짜를 선택한 경우 혹은 입력값이 6자리인 경우
      if (birthday > new Date()) {
        birthday.setFullYear(birthday.getFullYear() - 100);
      }
      let changeValue =
        birthday === null ? null : moment(birthday).format('YYYY-MM-DD');
      onChangeValue('birthday', changeValue);
    }
  };

  if (!obj) return;

  return (
    <div className="add-customer-info">
      <div className="data-input">
        <div className="title">
          정보입력
          <div className="right">
            <button
              className="btn btn-cancel _small m-r-8"
              onClick={() => {
                setObj({ ...customer });
                if (!customer.id) {
                  setSsnBirthdayFirstInputCheck(false);
                }
              }}
            >
              {translate('INIT')}
            </button>
            <button
              className={`btn _small ${
                disabled() ? 'btn-cancel' : 'btn-basic'
              }`}
              disabled={loadingBtnDisabledFlag}
              onClick={() => {
                setLoadingBtnDisabledFlag(true);
                onCustomerCheck();
              }}
            >
              등록
            </button>
          </div>
        </div>
        <div className="flex-row">
          <div
            className="card"
            style={{
              display: 'inline-block',
              width: '230px',
              marginRight: '20px',
            }}
          >
            <div className="form-control">
              <label className="label-required">{translate('TYPE')}</label>
              <div className="wrap-radio">
                {hooks.radios({
                  radios: radioItemsType,
                  obj,
                  setObj,
                  name: 'type',
                  tabIndex: 1,
                })}
              </div>
            </div>

            <div className="form-control">
              <label>고객명</label>
              <Input
                tabIndex="5"
                value={obj.name || ''}
                onChange={(e) => onChangeValue('name', e.target.value)}
                placeholder="이름을 입력하세요."
                ref={inputRef}
              />
            </div>

            <div className="form-control">
              <label className="label-required">{translate('SEX')}</label>
              <div className="wrap-radio">
                {hooks.radios({
                  radios: radioItemsSex,
                  obj,
                  setObj,
                  name: 'sex',
                  tabIndex: 8,
                })}
              </div>
            </div>
          </div>

          <div
            className="card"
            style={{
              display: 'inline-block',
              width: '230px',
              marginRight: '20px',
            }}
          >
            <div className="form-control">
              <label>{translate('CHART_NO')}</label>
              <div className="flex-row">
                <Input
                  tabIndex="2"
                  disabled={obj.id ? false : chartInputFlag}
                  value={obj.chartNo ?? ''}
                  onChange={(e) => onChangeValue('chartNo', e.target.value)}
                  placeholder={'등록완료 시 자동발급'}
                />
                <button
                  style={{ maxWidth: '50px' }}
                  className={`m-l-4 btn _sssmall btn-normal`}
                  onClick={() => {
                    setChartInputFlag(!chartInputFlag);
                  }}
                >
                  {chartInputFlag ? '직접입력' : '취소'}
                </button>
              </div>
            </div>
            <div className="form-control">
              <label>{translate('PHONE_NUMBER')}</label>
              <Phone
                tabIndex={6}
                phone={obj.phoneNumber || ''}
                onChange={(phoneNumber) =>
                  onChangeValue('phoneNumber', phoneNumber)
                }
                placeholder="전화번호를 입력하세요."
              />
            </div>

            <div className="form-control">
              <label className="label-required">
                {translate('SMS_ENABLED')}
              </label>
              <div className="wrap-radio">
                {hooks.radios({
                  radios: radioItemsSmsEnabled,
                  obj,
                  setObj,
                  name: 'smsEnabled',
                  tabIndex: 9,
                })}
              </div>
            </div>
          </div>
          <div
            className="card"
            style={{ display: 'inline-block', width: '230px' }}
          >
            <div className="form-control">
              <label>주민등록번호</label>
              <div className="flex-row">
                <Input
                  maxLength="6"
                  tabIndex="3"
                  value={(obj || {}).ssnHead1 || ''}
                  onChange={(e) => onChangeValue('ssnHead1', e.target.value)}
                />{' '}
                <Separate>_</Separate>
                <Input
                  type="small"
                  maxLength="1"
                  tabIndex="4"
                  value={(obj || {}).ssnHead2 || ''}
                  onChange={(e) => onChangeValue('ssnHead2', e.target.value)}
                  ref={ssnRef}
                />
              </div>
            </div>
            <div className="form-control">
              <label>{translate('BIRTHDAY')}</label>
              <div>
                <DatePicker
                  ref={calendar}
                  tabIndex="7"
                  value={obj.birthday ? new Date(obj.birthday) : null}
                  onChange={onChangePicker}
                  dateFormat="yyMMdd"
                  placeholderText="ex ) 910826 "
                  popperModifiers
                />
              </div>
            </div>

            <div className="form-control">
              <label className="label-required">
                {translate('EVENT_SMS_ENABLED')}
              </label>
              <div className="wrap-radio">
                {hooks.radios({
                  radios: radioItemsEventSmsEnabled,
                  obj,
                  setObj,
                  name: 'eventSmsEnabled',
                  tabIndex: 10,
                })}
              </div>
            </div>
          </div>
        </div>

        <div className="form-control">
          <label>{translate('MEMO')}</label>
          <QuillTextField
            tabIndex={11}
            value={obj.memo ?? ''}
            setValue={(v) => onChangeValue('memo', v)}
            placeholder="메모를 입력하세요."
          />
        </div>
      </div>
      <div className="data-input">
        <div className="card">
          <div className="form-wide">
            <label>{translate('FAVORITES')}</label>
            {(obj.favorites || []).length > 0
              ? obj.favorites.map((favorite, fIdx) => (
                  <div className="form-control full flex-fill" key={fIdx}>
                    <Select
                      className="form-react-select"
                      styles={{
                        container: () => ({
                          marginRight: '15px',
                          width: '560px',
                          outlineWidth: '0px',
                          display: 'inline-block',
                          verticalAlign: 'middle',
                        }),
                        control: (base) => ({
                          ...base,
                          padding: '8px 0px 8px 20px',
                          boxShadow: '0px',
                          minHeight: '34px',
                          height: '34px',
                        }),
                        menu: (base) => ({
                          ...base,
                          zIndex: 2,
                        }),
                        valueContainer: (base) => ({
                          ...base,
                          height: '18px',
                          padding: '0px',
                        }),
                        input: (base) => ({
                          ...base,
                          margin: '0px',
                          border: '0px',
                          padding: '0px !important',
                          height: '18px',
                        }),
                        indicatorSeparator: () => ({
                          display: 'none',
                        }),
                        indicatorsContainer: (base) => ({
                          ...base,
                          marginRight: '3px',
                          height: '18px',
                          width: '18px',
                        }),
                        singleValue: (base) => ({
                          ...base,
                          margin: '0px',
                        }),
                        placeholder: (base) => ({
                          ...base,
                          color: '-internal-light-dark(black, white)',
                          margin: '0px 0px 0px 0px',
                          whiteSpace: 'nowrap',
                        }),
                      }}
                      value={{
                        value: treatmentItemCategories.findIndex(
                          (treatmentItemCategory) =>
                            treatmentItemCategory.id === favorite.id
                        ),
                        label: (obj.favorites[fIdx] || {}).name,
                      }}
                      onChange={(e) => {
                        obj.favorites[fIdx] = treatmentItemCategories[e.value];
                        setObj({ ...obj });
                      }}
                      options={(treatmentItemCategories || [])
                        .map((treatmentItemCategory, idx) => ({
                          value: idx,
                          label: treatmentItemCategory.name,
                          isDisabled: !treatmentItemCategory.visible,
                        }))
                        .filter((v) => !v.isDisabled)}
                    />
                    <button
                      className="btn btn-danger _small"
                      onClick={() => {
                        modal
                          .basic({
                            body:
                              '삭제하시겠습니까? 최종 저장 전에는 반영되지 않습니다.',
                            buttons: [
                              { text: 'CANCEL', class: 'btn-default' },
                              { text: 'CONFIRM', class: 'btn-danger' },
                            ],
                          })
                          .then((selectedBtnIdx) => {
                            if (selectedBtnIdx === 1) {
                              obj.favorites.splice(fIdx, 1);
                              setObj({ ...obj });
                            }
                          });
                      }}
                    >
                      {translate('DELETE')}
                    </button>
                  </div>
                ))
              : null}
            <div className="btnwrap">
              <button
                className="btn btn-basic _small _long"
                onClick={() => onClickAddFavorite()}
              >
                {translate('ADD_FAVORITE')}
              </button>
            </div>
          </div>
        </div>
        {(obj.items || []).map((treatmentItem, idx) =>
          (treatmentItemCategories || []).length > 0 ? (
            <div className="flex-row items-center" key={idx}>
              <div className="form-control flex-wrap m-r-24">
                <label>관심 시/수술 카테고리</label>
                <select
                  tabIndex="12"
                  value={(treatmentItemCategories || []).findIndex(
                    (category) => category.id === (treatmentItem || {}).id
                  )}
                  onChange={(e) => {
                    const items = treatmentItemCategories[e.target.value];
                    obj.items[idx] = items || [];
                    setObj({ ...obj });
                  }}
                >
                  <option value={-1} hidden disabled>
                    시/수술 카테고리를 선택하세요.
                  </option>
                  {(treatmentItemCategories || []).map((category, idx) => (
                    <option
                      key={idx}
                      value={idx}
                      className={!category.visible ? 'display-none' : ''}
                    >
                      {category.name}
                    </option>
                  ))}
                </select>
              </div>
              <button
                className="btn btn-danger flex-wrap"
                onClick={() => {
                  modal
                    .basic({
                      body:
                        '삭제하시겠습니까? 최종 저장 전에는 반영되지 않습니다.',
                      buttons: [
                        { text: 'CANCEL', class: 'btn-default' },
                        { text: 'CONFIRM', class: 'btn-danger' },
                      ],
                    })
                    .then((selectedBtnIdx) => {
                      if (selectedBtnIdx === 1) {
                        obj.items.splice(idx, 1);
                        setObj({ ...obj });
                      }
                    });
                }}
              >
                {translate('DELETE')}
              </button>
            </div>
          ) : null
        )}

        <div className="flex-row">
          <div
            className="card"
            style={{
              display: 'inline-block',
              width: '230px',
              marginRight: '20px',
            }}
          >
            <div className="form-control">
              <label>키</label>
              <div className="flex-row">
                <Input
                  type="medium"
                  maxLength="3"
                  tabIndex="13"
                  value={obj.height || ''}
                  onChange={(e) => onChangeValue('height', e.target.value)}
                  placeholder="키를 입력하세요."
                />
                <div style={{ paddingTop: '10px' }}>cm</div>
              </div>
            </div>
            <div className="form-control">
              <label>담당상담사</label>
              <select
                tabIndex="16"
                value={counselors.findIndex(
                  (existing) => existing.id === (obj['counselor'] || {}).id
                )}
                onChange={(e) => {
                  obj['counselor'] = counselors[e.target.value];
                  setObj({ ...obj });
                }}
              >
                <option disabled key={-1} value={-1}>
                  상담사
                </option>
                {(counselors || []).map((o, idx) => (
                  <option key={idx} value={idx}>
                    {' '}
                    {o.name}{' '}
                  </option>
                ))}
              </select>
            </div>

            <div className="form-control">
              <label>{translate('RECOMMENDER')}</label>
              <div className="flex-row">
                <RecommenderInputWrapper>
                  <RecommenderInput
                    disabled
                    value={
                      obj && obj.recommenderName ? obj.recommenderName : '-'
                    }
                    placeholder="소개자를 입력하세요."
                  />
                </RecommenderInputWrapper>
                <button
                  tabIndex="19"
                  className="btn btn-primary flex-wrap"
                  onClick={() => openModalCustomerFinder()}
                >
                  {translate('SEARCH')}
                </button>
              </div>
            </div>

            <div className="form-control">
              <label>{translate($case.toConst(pluralize('complaint')))}</label>
              <select
                tabIndex="22"
                value={(complaint || []).findIndex(
                  (option) => option.id === (obj['complaint'] || {}).id
                )}
                onChange={(e) => {
                  obj['complaint'] = complaint[e.target.value];
                  setObj({ ...obj });
                }}
              >
                <option disabled key={-1} value={-1}>
                  {translate($case.toConst(pluralize('complaint')))}
                </option>
                {(complaint || []).map((item, idx) => (
                  <option key={idx} value={idx}>
                    {item.name || item.content}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div
            className="card"
            style={{
              display: 'inline-block',
              width: '230px',
              marginRight: '20px',
            }}
          >
            <div className="form-control">
              <label>혈액형</label>
              <select
                tabIndex="14"
                value={obj && obj['bloodType'] ? obj['bloodType'] : 'null'}
                onChange={(e) => {
                  obj['bloodType'] = e.target.value;
                  setObj({ ...obj });
                }}
              >
                <option value={-1}>혈액형</option>
                <option value="A">A</option>
                <option value="B">B</option>
                <option value="O">O</option>
                <option value="AB">AB</option>
                <option value="Rh-A">Rh-A</option>
                <option value="Rh-B">Rh-B</option>
                <option value="Rh-O">Rh-O</option>
                <option value="Rh-AB">Rh-AB</option>
              </select>
            </div>
            <div className="form-control">
              <label>담당의사</label>
              <select
                tabIndex="17"
                value={doctors.findIndex(
                  (existing) => existing.id === (obj['doctor'] || {}).id
                )}
                onChange={(e) => {
                  obj['doctor'] = doctors[e.target.value];
                  setObj({ ...obj });
                }}
              >
                <option key={-1} disabled value={-1}>
                  의사
                </option>
                {(doctors || []).map((o, idx) => (
                  <option key={idx} value={idx}>
                    {' '}
                    {o.name}{' '}
                  </option>
                ))}
              </select>
            </div>

            <div className="form-control">
              <label>{translate($case.toConst(pluralize('level')))}</label>
              <select
                tabIndex="20"
                value={(level || []).findIndex(
                  (option) => option.id === (obj['level'] || {}).id
                )}
                onChange={(e) => {
                  obj['level'] = level[e.target.value];
                  setObj({ ...obj });
                }}
              >
                <option disabled key={-1} value={-1}>
                  {translate($case.toConst(pluralize('level')))}
                </option>
                {(level || []).map((item, idx) => (
                  <option key={idx} value={idx}>
                    {item.name || item.content}
                  </option>
                ))}
              </select>
            </div>

            <div className="form-control">
              <label>{translate($case.toConst(pluralize('region')))}</label>
              <select
                tabIndex="23"
                value={(region || []).findIndex(
                  (option) => option.id === (obj['region'] || {}).id
                )}
                onChange={(e) => {
                  obj['region'] = region[e.target.value];
                  setObj({ ...obj });
                }}
              >
                <option disabled key={-1} value={-1}>
                  {translate($case.toConst(pluralize('region')))}
                </option>
                {(region || []).map((item, idx) => (
                  <option key={idx} value={idx}>
                    {item.name || item.content}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div
            className="card"
            style={{ display: 'inline-block', width: '230px' }}
          >
            <div className="form-control">
              <label>몸무게</label>
              <div className="flex-row">
                <Input
                  type="medium"
                  maxLength="3"
                  tabIndex="15"
                  value={obj.weight || ''}
                  onChange={(e) => onChangeValue('weight', e.target.value)}
                  placeholder="몸무게를 입력하세요."
                />
                <div style={{ paddingTop: '10px' }}>kg</div>
              </div>
            </div>

            <div className="form-control">
              <label>
                {translate($case.toConst(pluralize('acquisitionChannel')))}
              </label>
              <select
                tabIndex="18"
                value={(acquisitionChannel || []).findIndex(
                  (option) => option.id === (obj['acquisitionChannel'] || {}).id
                )}
                onChange={(e) => {
                  obj['acquisitionChannel'] =
                    acquisitionChannel[e.target.value];
                  setObj({ ...obj });
                }}
              >
                <option disabled key={-1} value={-1}>
                  {translate($case.toConst(pluralize('acquisitionChannel')))}
                </option>
                {(acquisitionChannel || []).map((item, idx) => (
                  <option key={idx} value={idx}>
                    {item.name || item.content}
                  </option>
                ))}
              </select>
            </div>

            <div className="form-control">
              <label>{translate($case.toConst(pluralize('job')))}</label>
              <select
                tabIndex="21"
                value={(job || []).findIndex(
                  (option) => option.id === (obj['job'] || {}).id
                )}
                onChange={(e) => {
                  obj['job'] = job[e.target.value];
                  setObj({ ...obj });
                }}
              >
                <option disabled key={-1} value={-1}>
                  {translate($case.toConst(pluralize('job')))}
                </option>
                {(job || []).map((item, idx) => (
                  <option key={idx} value={idx}>
                    {item.name || item.content}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </div>
      <div className="data-input">
        <div className="card">
          <div className="address-form">
            <label>{translate('ADDRESS')}</label>
            <Input
              tabIndex="24"
              value={obj.address || ''}
              onChange={(e) => onChangeValue('address', e.target.value)}
              placeholder="주소를 입력하세요."
            />
          </div>
        </div>
      </div>
    </div>
  );
};

CustomerAddChartInfo.propTypes = {
  customer: PropTypes.object,
};

export default observer(CustomerAddChartInfo);
