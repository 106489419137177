import React, {
  useEffect,
  useCallback,
  useState,
  useContext,
  useRef,
} from 'react';
import DatePicker from 'components/common/DatePicker';
import moment from 'moment';
import { observer } from 'mobx-react';
import { AppointmentBoardStateContext } from './AppointmentBoards';
import { ChromePicker } from 'react-color';
import { Tooltip, Button } from 'antd';
import { translate } from 'filters';
import { useModal } from 'hooks/useModal';
import { useServices } from 'hooks/useServices';
import { useToast } from 'hooks/useToast';

const AppointmentBoardLeftPanel = () => {
  const modal = useModal();
  const services = useServices();
  const toast = useToast();
  /**
   * @type {AppointmentBoardState}
   */
  const state = useContext(AppointmentBoardStateContext);

  const [pickerParent, setPickerParent] = useState(null);
  const [pickerColor, setPickerColor] = useState(null);
  const [displayColorPicker, setDisplayColorPicker] = useState(false);

  const panelRef = useRef(null);

  const tooltipText = (
    <span>
      특정부서를 휴진설정할 수 있습니다. 예약현황판의 세부부서명에 마우스
      우클릭해보세요.
    </span>
  );

  useEffect(() => {
    // update color
    if (pickerColor == null) return;
    let index = state.stateArr.findIndex(
      (obj) => obj.codeName === pickerParent.name
    );
    state.stateArr[index].codeValueTemp = pickerColor;
  }, [pickerColor]);

  const onDisplayColorPicker = (e) => {
    if (
      e.target.closest('.wrap-color-picker') ||
      typeof e.target.className !== 'string'
    )
      return;
    if (!e.target.className.includes('box status')) {
      onResetColor();
    }
  };

  const onResetColor = useCallback(() => {
    if (!pickerParent) return;
    let index = state.stateArr.findIndex(
      (obj) => obj.codeName == pickerParent.name
    );
    state.stateArr[index].codeValueTemp = null;
    setDisplayColorPicker(false);
  }, [pickerParent]);

  const configCallApi = useCallback(async () => {
    const resp = await services.crm.clinic.configs({
      groupName: 'calender_color',
    });
    resp &&
      resp.data.map((v) => {
        let index = state.stateArr.findIndex(
          (obj) => obj.codeName == v.codeName
        );
        if (state.stateArr[index] && v.codeValue) {
          state.stateArr[index].codeValue = v.codeValue;
          state.stateArr[index].id = v.id;
        }
      });
    if (!resp) return;
  }, [services.crm.clinic]);

  const configPostApi = useCallback(
    async (obj) => {
      try {
        let params = {
          groupName: 'calender_color',
          id: obj.id,
          codeName: obj.codeName,
          codeValue: obj.codeValueTemp || obj.codeValue,
          order: 1,
        };
        const resp = await services.crm.clinic.configs_create(params);
        if (!resp) return;
        modal
          .confirm({
            type: 'SUCCESS',
            msg: '업데이트되었습니다.',
          })
          .then(() => {
            let index = state.stateArr.findIndex(
              (obj) => obj.codeName == resp.data.codeName
            );
            state.stateArr[index].id = resp.data.id;
            state.stateArr[index].codeValue = resp.data.codeValue;
            state.stateArr[index].codeValueTemp = null;
          });
      } catch (e) {
        toast.error(e.description);
      } finally {
        setDisplayColorPicker(false);
      }
    },
    [services.crm.clinic, toast, configCallApi]
  );

  const onSaveColor = useCallback(() => {
    let index = state.stateArr.findIndex(
      (obj) => obj.codeName === pickerParent.name
    );
    configPostApi(state.stateArr[index]);
  }, [pickerParent]);

  return (
    <div
      className={`left-panel flex-wrap ${state.isMenuFold ? 'short' : ''}`}
      ref={panelRef}
      onClick={(e) => onDisplayColorPicker(e)}
    >
      <div>
        <button
          className={`calendar-fold ${state.isMenuFold ? 'full' : ''}`}
          onClick={() => state.setIsMenuFold(!state.isMenuFold)}
        >
          {state.isMenuFold ? '열기' : '접기'}
        </button>
      </div>
      <div className="left-panel-content-wrapper">
        <div className="filters form-control">
          <label>캘린더</label>
          <div className="folding-cnt">
            <DatePicker
              inline
              calendarClassName="folding-cnt"
              className="text-center flex-wrap"
              todayButton="오늘"
              value={state.startAt}
              startDate={state.startAt}
              endDate={state.calendarUnit !== 'month' ? state.endAt : null}
              onChange={(day) => {
                state.setCalendarUnit('day');
                state.setPeriod(moment(day).toDate(), moment(day).toDate());
              }}
            />
          </div>
        </div>
        <div className="filters form-control">
          <label>{translate('FILTERS')}</label>
          <div className="filter-wrap folding-cnt">
            <div className="filter">
              <select
                value={(state.departmentCategories || []).findIndex(
                  (category) =>
                    category.id === (state.filters.departmentCategory || {}).id
                )}
                onChange={(e) => {
                  state.setDeparmentCategoryFilter(
                    (state.departmentCategories || [])[e.target.value]
                  );
                }}
              >
                <option key={-1} value={-1} disabled={!true}>
                  {'전체 부서' || '-'}
                </option>
                {(state.departmentCategories || []).map((category, idx) => (
                  <option
                    key={idx}
                    value={idx}
                    className={!category.visible ? 'display-none' : ''}
                  >
                    {category.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="filter">
              <select
                value={(state.counselors || []).findIndex(
                  (existing) =>
                    existing.id === (state.filters.counselor || {}).id
                )}
                onChange={(e) => {
                  state.setCounselorFilter(
                    (state.counselors || [])[e.target.value]
                  );
                }}
              >
                <option value={-1}>
                  전체 {translate('counselor'.toUpperCase())}
                </option>
                {(state.counselors || []).map((o, idx) => (
                  <option key={idx} value={idx}>
                    {o.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="filter">
              <select
                value={(state.doctors || []).findIndex(
                  (existing) => existing.id === (state.filters.doctor || {}).id
                )}
                onChange={(e) => {
                  state.setDoctorFilter((state.doctors || [])[e.target.value]);
                }}
              >
                <option value={-1}>
                  전체 {translate('doctor'.toUpperCase())}
                </option>
                {(state.doctors || []).map((o, idx) => (
                  <option key={idx} value={idx}>
                    {o.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div className="form-control">
          <label className="title">{translate('STATUS')}</label>
        </div>

        <div className="regions folding-cnt">
          {state.stateArr.map((v, i) => (
            // 'absence', 'lunch',
            <div className="region" key={i}>
              <div className="flex-row">
                <div
                  className={`box status ${v.codeName}`}
                  style={{ backgroundColor: v.codeValueTemp || v.codeValue }}
                  onClick={(e) => {
                    onResetColor();
                    setPickerParent({ name: v.codeName });
                    setPickerColor(
                      window
                        .getComputedStyle(e.target, null)
                        .getPropertyValue('background-color')
                    );
                    setDisplayColorPicker(true);
                  }}
                />
                <div className={`appointment-status-${v.codeName}`}>
                  {translate(`APPOINTMENT_STATUS_${v.codeName.toUpperCase()}`)}
                </div>
                {v.codeName === 'absence' ? (
                  <Tooltip placement="top" title={tooltipText}>
                    <Button>?</Button>
                  </Tooltip>
                ) : undefined}
              </div>

              {pickerParent &&
                v.codeName == pickerParent.name &&
                displayColorPicker && (
                  <div className="wrap-color-picker">
                    <ChromePicker
                      disableAlpha={true}
                      color={pickerColor}
                      onChangeComplete={(color) => setPickerColor(color.hex)}
                    />
                    <div className="wrap_btn">
                      <button
                        className="btn btn-normal m-r-8"
                        onClick={() => onResetColor()}
                      >
                        취소
                      </button>
                      <button
                        className="btn btn-primary"
                        onClick={() => onSaveColor()}
                      >
                        저장
                      </button>
                    </div>
                  </div>
                )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default observer(AppointmentBoardLeftPanel);
