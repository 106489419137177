import React, { useState, useEffect, useCallback } from 'react';
import { observer } from 'mobx-react';
import Checkbox from 'components/app/Checkbox';
import DataTableAlpha from 'components/data-table/DataTableAlpha';
import ModalBulkRegistration from 'components/modals/ModalBulkRegistration';
import ModalEventCreate from 'components/modals/ModalEventCreate';
import models from 'models';
import { translate } from 'filters';
import { $qb } from 'scripts/querybuilder';
import { useModal } from 'hooks/useModal';
import { useServices } from 'hooks/useServices';
import { useToast } from 'hooks/useToast';

const ConsultingEvents = () => {
  const modal = useModal();
  const services = useServices();
  const toast = useToast();
  const defaultParams = $qb().limit(10);
  const [params, setParams] = useState(defaultParams);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [showDeactivated, setShowDeactivated] = useState(false);

  const callApi = useCallback(async () => {
    const resp = await services.crm.consultingRequest.event.all(params.build());
    if (!resp) return;

    setData(resp.data);
    setTotal(resp.total);
  }, [services.crm.consultingRequest, params]);

  useEffect(() => {
    callApi();
  }, [callApi]);

  const onParams = (p) => {
    setParams(p);
    callApi();
  };

  const onChangeType = () => {
    if (showDeactivated) {
      params.queryParams = {
        limit: params.queryParams.limit,
      };
    } else {
      params.queryParams = {
        limit: params.queryParams.limit,
        isDisabled: showDeactivated,
      };
    }
    onParams(params);
    setShowDeactivated(!showDeactivated);
  };

  const onAction = (obj) => {
    let model = obj.key;
    let object = obj.row;
    let event = obj.event;

    if (['name'].indexOf(model.column) !== -1) {
      object[model.column] = event.target.value;
    }

    const btnLabel = event.target.innerText;
    if (btnLabel === '수정') {
      onClickCreateEvent(object, 'update');
    }

    if (['사용', '미사용'].indexOf(btnLabel) !== -1) {
      return toggleVisibility(object);
    }

    if (model['title'] === 'ACTION' && event.target.dataset.type === 'delete') {
      modal
        .basic({
          body: `정말로 삭제하시겠습니까?`,
          buttons: [
            {
              text: 'CANCEL',
              class: 'btn-default',
            },
            {
              text: 'CONFIRM',
              class: 'btn-primary',
            },
          ],
        })
        .then((idx) => {
          idx === 1 && onClickDelete(object);
        });
    }
  };

  const onClickDelete = useCallback(
    async (object) => {
      try {
        await services.crm.consultingRequest.event.delete(object.id);
        modal
          .confirm({
            type: 'SUCCESS',
            msg: '삭제되었습니다.',
          })
          .then(() => {
            callApi();
          });
      } catch (e) {
        console.log(e.description);
        modal.confirm({
          type: 'ERROR',
          msg: e.description,
        });
      }
    },
    [services.crm.consultingRequest.event, toast, callApi]
  );

  const toggleVisibility = (object) => {
    const { id, name, isDisabled } = object;
    let obj = {
      id,
      name,
      isDisabled: Number(!isDisabled),
    };
    update(obj);
  };

  const update = async (object) => {
    try {
      await services.crm.consultingRequest.event.update(object);
      modal
        .confirm({
          type: 'SUCCESS',
          msg: '업데이트되었습니다.',
        })
        .then(() => {
          callApi();
        });
    } catch (e) {
      const { name, description } = e;
      console.log(description);
      let message = description;
      name === 'Conflict' && translate('ERROR_CONFLICT_CODE');

      modal
        .confirm({
          type: 'ERROR',
          msg: message,
        })
        .then(() => {
          callApi();
        });
      // if (e.name === 'Conflict') {
      //   toast.error('ERROR_CONFLICT_CODE')
      // } else {
      //   toast.error(e.description)
      // }
    }
  };

  const onClickCreateEvent = async (obj, endpoint) => {
    let params = { isDisabled: false };
    const resp = await services.crm.consultingRequest.media.all(params);
    if (!resp) return;

    openModalEventCreate(obj, endpoint, resp.data);
  };

  const openModalEventCreate = (obj, endpoint, mediaList) => {
    const onConfirm = async (value) => {
      try {
        await services.crm.consultingRequest.event.create(value);
        modal
          .confirm({
            type: 'SUCCESS',
            msg: '생성되었습니다.',
          })
          .then(() => {
            callApi();
          });
      } catch (e) {
        const { code, description } = e;
        let message = description;
        message = code === 409 && '이미 등록된 이벤트입니다.';
        modal
          .confirm({
            type: 'ERROR',
            msg: message,
          })
          .then(() => {
            callApi();
          });
        console.log(e.description);
      }
    };

    modal
      .custom({
        component: ModalEventCreate,
        options: {
          obj,
          endpoint,
          mediaList,
        },
      })
      .then((payload) => {
        //x버튼, 취소버튼시에는 payload = undefined
        if (payload !== undefined) {
          const validator = (payload) => {
            let returnFlag = true;
            if (
              !payload.mediaId ||
              !payload.name ||
              !payload.eventType ||
              !payload.price
            ) {
              toast.error('*(별표)표시는 필수로 입력하세요.');
              returnFlag = false;
            }
            return returnFlag;
          };

          if (validator(payload)) {
            if (endpoint === 'update') {
              update({ ...payload, id: obj.id });
            } else {
              onConfirm(payload);
            }
          }
        }
      });
  };

  const onClickBulkRegistration = () => {
    modal
      .custom({
        component: ModalBulkRegistration,
        options: {
          title: 'event',
        },
      })
      .then(() => {
        setTimeout(function () {
          //팝업 로딩 후 0.5초 뒤에 api 호출 (복제지연시간을 고려한 호출)
          callApi();
        }, 500);
      });
  };

  return (
    <div className="consulting-event">
      <div className="evnet-header">
        <span className="title">이벤트</span>
        <div className="flex-row items-center">
          <Checkbox
            className="flex-wrap m-r-8"
            checked={showDeactivated}
            toggleHandler={onChangeType}
          />
          <span className="m-r-12">미사용항목 안보기</span>
          <button
            className="flex-wrap btn btn-sm m-r-8 btn-add"
            onClick={onClickCreateEvent}
          >
            추가
          </button>
          <button
            className="flex-wrap btn btn-sm"
            onClick={onClickBulkRegistration}
          >
            대량등록
          </button>
        </div>
      </div>
      <div className="event-body">
        <DataTableAlpha
          model={models.crm.consultingEvent}
          data={data}
          total={total}
          params={params}
          onParams={onParams}
          onAction={onAction}
          hideLimitSetter={true}
          hasDeactivate
          bottomPositionInner
        />
      </div>
    </div>
  );
};

export default observer(ConsultingEvents);
