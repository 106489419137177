import React, { useState, useEffect, useCallback } from 'react';
import { observer } from 'mobx-react';
import InputCurrency from 'components/common/InputCurrency';
import PropTypes from 'prop-types';

const ChangeVatNotPrice = ({ payments, setPayments }) => {
  // eslint-disable-next-line no-unused-vars
  const [price, setPrice] = useState();

  const priceSetting = useCallback(() => {
    setPrice(payments.price);
  }, [payments]);

  useEffect(() => {
    priceSetting();
  }, [priceSetting]);

  const onChangePrice = (e) => {
    setPayments({
      ...payments,
      price: Number(e.target.value),
      changeFlag: 'true',
    });
    setPrice(Number(e.target.value));
  };

  return (
    <React.Fragment>
      <div className="form-control m-t-16">
        <InputCurrency
          className={'item-currency m-l-8'}
          name={'price'}
          initValue={
            payments.vatIncluded
              ? payments.price || 0
              : payments.priceVatInclude || 0
          }
          allowNegative={false}
          onValueChange={onChangePrice}
          countryCode={'kr'}
          disabled={false}
        />
      </div>
    </React.Fragment>
  );
};

ChangeVatNotPrice.propTypes = {
  payments: PropTypes.object,
  setPayments: PropTypes.func,
};

export default observer(ChangeVatNotPrice);
