export default {
  options: {
    hasMergeCell: true,
    groupTitles: ['ACTION', 'DEPARTMENT_CATEGORY'],
    explodeColumn: 'departments',
  },
  keys: [
    {
      title: 'ACTION',
      noorder: true,
      filter: (o) => `
            ${
              o.visible
                ? `<button class="btn btn-sm btn-primary m-r-8" data-type="edit">수정</button>`
                : ''
            }
            <button class="btn btn-sm m-r-8 ${
              o.visible ? 'btn-black' : 'btn-gray'
            }" data-type="visible">${o.visible ? '미사용' : '사용'}</button>
            <button class="btn btn-sm m-r-8 ${
              o.visible ? 'btn-danger' : 'btn-gray'
            }" data-type="delete" >삭제</button>`,
    },
    {
      title: 'DEPARTMENT_CATEGORY',
      column: 'name',
      noorder: true,
      type: 'arrow',
    },
    {
      title: 'DEPARTMENT',
      column: '@@departments__name',
      noorder: true,
      type: 'arrow',
      filter: (o) => `<div>
            <div class="flex-row items-center flex-between">
                <div>${o['@@departments']['name']}</div>
                <button data-type="visible" class="btn ${
                  o['@@departments']['visible'] ? 'btn-black' : 'btn-gray'
                } btn-sm flex-wrap m-r-8 index-${
        o['@@departments']['exploded@@order']
      }">${o['@@departments']['visible'] ? '미사용' : '사용'}</button>
                <button class="btn btn-sm m-r-8 ${
                  o['@@departments']['visible'] ? 'btn-danger' : 'btn-gray'
                }" data-type="delete" >삭제</button>
            </div>
        </div>`,
    },
  ],
};
