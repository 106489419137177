import { useState } from 'react';
import * as $http from 'axios';
import { useModal } from 'hooks/useModal';
import { useToast } from 'hooks/useToast';

export const useDownloadExcel = ({ api, fileName, params }) => {
  const modal = useModal();
  const toast = useToast();
  const [loading, setLoading] = useState(false);

  const downloadFile = async (location) => {
    setLoading(false);
    modal.close({
      type: 'LOADING',
    });

    const res = await $http.get(location, {
      params: { name: `${fileName}.xlsx` },
    });
    console.log('res', res);
    const link = document.createElement('a');
    link.href = res.data.url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const download = async () => {
    setLoading(true);
    modal.loading({
      type: 'LOADING',
      msg:
        '다운로드중입니다. 데이터 양에 따라 \n 수 초 ~ 수 분이 걸릴 수 있습니다.',
    });

    const resp = await $http.post(`/excels/${api}`, params);

    if (resp?.data) {
      const taskId = resp.data.id;
      let startInterval = setInterval(async () => {
        const res = await $http.get(`/tasks/${taskId}`);
        switch (res.data.status) {
          case 'SUCCESS':
            clearInterval(startInterval);
            downloadFile(res.data.location);
            break;
          case 'FAILURE':
            clearInterval(startInterval);
            onFailExcelDownload();
            break;
        }
      }, 2000);
    } else {
      onFailExcelDownload();
    }
  };

  const onFailExcelDownload = () => {
    setLoading(false);
    modal.close({
      type: 'LOADING',
    });
    toast.error(
      '다운로드에 실패하였습니다.\n다시 시도하거나, cs@unobeauty.kr로 문의해주세요.'
    );
  };

  return { download, downloading: loading };
};
