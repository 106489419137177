import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'filters';
import { observer } from 'mobx-react';
import hooks from 'hooks';
import TabConsultingRequest from 'components/pages/crm/consulting-request/TabConsultingRequest';
import TabRequestList from 'components/pages/crm/consulting-request/TabRequestList';
import { useToast } from 'hooks/useToast';

const ModalConsultingRequests = ({ options, close }) => {
  const toast = useToast();
  const forceUpdate = hooks.useForceUpdate();
  const [tabItems] = useState(
    [{ key: '상담문의' }, { key: '처리내역' }].map((tab) => ({
      key: tab.key,
      title: tab.key,
      selected: false,
    }))
  );

  useEffect(() => {
    onClickTab('상담문의');
  }, []);

  const onClickTab = useCallback(
    (selectedTab) => {
      let found = tabItems.find((tab) => tab.key === selectedTab);
      if (!found || found.disabled) {
        toast.error('ERROR_SELECT_CUSTOMER_FIRST');
        return;
      }

      tabItems.forEach((tab) => (tab.selected = tab.key === selectedTab));
      forceUpdate();
    },
    [forceUpdate, tabItems, toast]
  );

  return (
    <div className="modal-consulting">
      <div className="head flex-row flex-between items-start">
        <div className="title">응대하기</div>
        <i onClick={() => close()} className="zmdi zmdi-close" />
      </div>
      <div className="consulting-request-connect">
        <div className="tabs flex-row m-t-8">
          {tabItems.map((tab, idx) => (
            <div
              key={idx}
              onClick={() => (onClickTab ? onClickTab(tab.key) : null)}
              className={`tab ${tab.selected ? 'selected' : ''} ${
                tab.disabled ? 'disabled' : ''
              }`}
            >
              {translate(tab.title)}
            </div>
          ))}
        </div>
        <div className="tab-contents">
          {/* 클릭한 탭의 state에 따라 해당 컴포넌트 출력 */}
          {(tabItems.find((tab) => tab.selected) || {}).key === '상담문의' ? (
            <TabConsultingRequest
              data={options.consulting}
              close={() => {
                close(0);
              }}
            />
          ) : null}
          {(tabItems.find((tab) => tab.selected) || {}).key === '처리내역' ? (
            <TabRequestList
              data={options.consulting}
              close={() => {
                close(0);
              }}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

ModalConsultingRequests.propTypes = {
  options: PropTypes.object,
  close: PropTypes.func,
};

export default observer(ModalConsultingRequests);
