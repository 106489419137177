import moment from 'moment';

export default {
  keys: [
    {
      title: 'STATUS',
      noorder: true,
      filter: (o) => {
        let refundCount = false;
        for (let i in o.surgeryTreatmentItems) {
          const item = o.surgeryTreatmentItems[i];
          if (item.refundCount > 0) {
            refundCount = true;
            break;
          }
        }
        return `<div class="text-center">
                ${
                  !refundCount
                    ? `<button class="btn btn-sm btn-primary">수정</button>`
                    : ''
                }
                <button class="btn btn-sm btn-danger">삭제</button>
            </div>`;
      },
    },
    {
      title: '시/수술일',
      column: 'scheduledAt',
      filter: (o) => {
        return `<div class="text-center">
                <span>${moment(o.scheduledAt).format('YYYY-MM-DD')}</span>
            </div>`;
      },
      customStyle: (o) => {
        let refundCount = false;
        for (let i in o.surgeryTreatmentItems) {
          const item = o.surgeryTreatmentItems[i];
          if (item.refundCount > 0) {
            refundCount = true;
            break;
          }
        }
        if (refundCount) {
          return { color: 'red' };
        }
      },
    },
    {
      title: '시/수술내용',
      column: 'memo',
      noorder: true,
      type: 'memo',
      thStyle: () => {
        return { width: '240px' };
      },
      customStyle: (o) => {
        let refundCount = false;
        for (let i in o.surgeryTreatmentItems) {
          const item = o.surgeryTreatmentItems[i];
          if (item.refundCount > 0) {
            refundCount = true;
            break;
          }
        }
        if (refundCount) {
          return { color: 'red' };
        }
      },
    },
    {
      title: '의사',
      column: 'doctor__name',
      noorder: true,
      customStyle: (o) => {
        let refundCount = false;
        for (let i in o.surgeryTreatmentItems) {
          const item = o.surgeryTreatmentItems[i];
          if (item.refundCount > 0) {
            refundCount = true;
            break;
          }
        }
        if (refundCount) {
          return { color: 'red' };
        }
      },
    },
    {
      title: '어시스트',
      column: 'facialist__name',
      noorder: true,
      customStyle: (o) => {
        let refundCount = false;
        for (let i in o.surgeryTreatmentItems) {
          const item = o.surgeryTreatmentItems[i];
          if (item.refundCount > 0) {
            refundCount = true;
            break;
          }
        }
        if (refundCount) {
          return { color: 'red' };
        }
      },
    },
    {
      title: '카테고리',
      noorder: true,
      type: 'list_mini',
      filter: (o) =>
        `<div class="text-center">${o.surgeryTreatmentItems
          .map(
            (item) => `<div style="position: relative;">
            <div>${item.categoryName || ''}</div>
        </div>`
          )
          .join('\n')}</div>`,
      customStyle: (o) => {
        let refundCount = false;
        for (let i in o.surgeryTreatmentItems) {
          const item = o.surgeryTreatmentItems[i];
          if (item.refundCount > 0) {
            refundCount = true;
            break;
          }
        }
        if (refundCount) {
          return { color: 'red' };
        }
      },
    },
    {
      title: '시/수술명',
      noorder: true,
      type: 'list',
      filter: (o) => `<div>
        ${o.surgeryTreatmentItems
          .map((item) => {
            return `<div class="text-center" style="position: relative; width: 240px; text-align: center;">
            <div class="flex-wrap"
                style="max-width: 240px; white-space: break-spaces; display: inline-block;">${
                  item.name || ''
                }${
              item.isFree ? '<div class="flex-wrap ico-service"></div>' : ''
            }</div>
            </div>`;
          })
          .join('\n')}
        </div>`,
      customStyle: (o) => {
        let refundCount = false;
        for (let i in o.surgeryTreatmentItems) {
          const item = o.surgeryTreatmentItems[i];
          if (item.refundCount > 0) {
            refundCount = true;
            break;
          }
        }
        if (refundCount) {
          return { color: 'red' };
        }
      },
    },
    {
      title: '총 시/수술 횟수',
      column: 'surgeryTreatmentItems__treatmentCount',
      noorder: true,
      filter: (o) => `<div>
        ${o.surgeryTreatmentItems
          .map(
            (item) => `
            <div class="text-center">${item.treatmentCount}회</div>
        `
          )
          .join('\n')}
        </div>`,
      customStyle: (o) => {
        let refundCount = false;
        for (let i in o.surgeryTreatmentItems) {
          const item = o.surgeryTreatmentItems[i];
          if (item.refundCount > 0) {
            refundCount = true;
            break;
          }
        }
        if (refundCount) {
          return { color: 'red' };
        }
      },
    },
    {
      title: '진행한 회차',
      column: 'surgeryTreatmentItems__useCount',
      noorder: true,
      filter: (o) => `<div>
        ${o.surgeryTreatmentItems
          .map(
            (item) => `
            <div class="text-center">${item.useCount}회</div>
        `
          )
          .join('\n')}
        </div>`,
      customStyle: (o) => {
        let refundCount = false;
        for (let i in o.surgeryTreatmentItems) {
          const item = o.surgeryTreatmentItems[i];
          if (item.refundCount > 0) {
            refundCount = true;
            break;
          }
        }
        if (refundCount) {
          return { color: 'red' };
        }
      },
    },
    {
      title: '남은 회차',
      column: 'remainingCount',
      noorder: true,
      filter: (o) => `<div>
        ${o.surgeryTreatmentItems
          .map(
            (item) => `
            <div class="text-center">${item.remainingCount}회</div>
            ${item.refundCount > 0 ? `(잔여 ${item.refundCount}회 환불)` : ''}
        `
          )
          .join('\n')}
        </div>`,
      customStyle: (o) => {
        let refundCount = false;
        for (let i in o.surgeryTreatmentItems) {
          const item = o.surgeryTreatmentItems[i];
          if (item.refundCount > 0) {
            refundCount = true;
            break;
          }
        }
        if (refundCount) {
          return { color: 'red' };
        }
      },
    },
    // {
    //     title: 'UPDATE_AT',
    //     column: 'updatedAt',
    //     filter: o => {
    //         return `<div>
    //             <div>${moment(o.updatedAt).format('YYYY-MM-DD')}</div>
    //             <div>${moment(o.updatedAt).format('HH:mm:ss')}</div>
    //         </div>`
    //     },
    // }
  ],
};
