// routeName Should be captalized word, not alluppercased.
// ex:) Customers (O), CUSTOMERS (X)
const menuItems = {
  crm: [
    { title: 'HOME', routeName: 'Home', authority: 'appointmentBoard' },
    {
      title: 'APPOINTMENT_BOARDS',
      routeName: 'AppointmentBoards',
      authority: 'appointmentCalendar',
    },
    {
      title: '상담문의 관리',
      items: [
        {
          title: 'CONSULTATION_INQUIRY',
          routeName: 'ConsultingRequests',
          authority: 'consultationInquiry',
        },
        {
          title: 'CONSULTATION_INQUIRY_ASSIGN',
          routeName: 'ConsultingRequestsCounselors',
          authority: 'consultationInquiryAssign',
        },
        {
          title: 'MEDIA_CONNECT',
          routeName: 'ConsultingRequestsConnects',
          authority: 'mediaConnect',
        },
      ],
      authority: 'menu',
    },
    {
      title: 'MANAGE_SMS',
      items: [
        { title: 'SMS_SEND', routeName: 'MessageSend', authority: 'sms' },
        {
          title: 'SMS_NOTIFICATIONS',
          routeName: 'MessageHistories',
          authority: 'smsHistory',
        },
        { title: 'SMS_POINTS', routeName: 'SmsPoint', authority: 'smsPoint' },
        {
          title: '충전내역 조회',
          routeName: 'SmsUseLists',
          authority: 'smsPointHistory',
        },
        {
          title: 'SMS_NOTIFICATION_CODES',
          routeName: 'SmsNotificationCodes',
          authority: 'smsCode',
        },
      ],
      authority: 'menu',
    },
    // { title: 'CONSULTING_REQUEST', routeName: 'consultingRequest' },
    {
      title: 'SEARCH',
      items: [
        { title: 'CUSTOMERS', routeName: 'Customers', authority: 'customer' },
        {
          title: 'APPOINTMENTS',
          routeName: 'Appointments',
          authority: 'appointment',
        },
        {
          title: 'CONSULTINGS',
          routeName: 'Consultings',
          authority: 'consulting',
        },
        {
          title: 'TREATMENTS',
          routeName: 'Treatments',
          authority: 'treatment',
        },
        { title: 'PAYMENTS', routeName: 'Payments', authority: 'payment' },
        { title: 'SURGERIES', routeName: 'Surgeries', authority: 'surgery' },
        {
          title: 'OPERATIONS',
          routeName: 'Operations',
          authority: 'nurseOperation',
        },
        { title: 'NURSES', routeName: 'Nurses', authority: 'nurseCare' },
        { title: 'SKINS', routeName: 'Skins', authority: 'nurseSkin' },
      ],
      authority: 'menu',
    },
    {
      title: 'SETTLEMENTS',
      items: [
        {
          title: '일일 결산',
          routeName: 'DailySettlements',
          authority: 'settlement',
        },
        {
          title: '월간 결산',
          routeName: 'MonthlySettlements',
          authority: 'settlementMonthly',
        },
      ],
      authority: 'menu',
    },
    {
      title: 'STATISTICS',
      items: [
        {
          title: '주요 통계 요약',
          routeName: 'Statistics',
          authority: 'summaryStatistics',
        },
        {
          title: '매출 통계',
          routeName: 'SalesStatistics',
          authority: 'salesStatistics',
        },
        {
          title: '상담사별 통계',
          routeName: 'CounselorsStatistics',
          authority: 'counselorStatistics',
        },
        {
          title: '의사별 통계',
          routeName: 'DoctorsStatistics',
          authority: 'doctorStatistics',
        },
        {
          title: '환자 통계',
          routeName: 'CustomersStatistics',
          authority: 'customerStatistics',
        },
        {
          title: '시수술 통계',
          routeName: 'SurgeryStatistics',
          authority: 'treatmentItemStatistics',
        },
        // {
        //   title: '국가/지역별 통계',
        //   routeName: 'RegionsStatistics',
        //   authority: 'customerByRegionStatistics',
        // },
      ],
      authority: 'menu',
    },
    {
      title: 'PRESCRIPTIONS',
      items: [
        {
          title: '처방전 발급',
          routeName: 'PrescriptionIssue',
          authority: 'prescription',
        },
        {
          title: '처방전 조회',
          routeName: 'PrescriptionList',
          authority: 'prescription',
        },
        {
          title: '처방전 통계',
          routeName: 'PrescriptionStatistics',
          authority: 'prescription',
        },
      ],
      authority: 'menu',
    },
    {
      title: 'SETTINGS',
      items: [
        {
          title: 'CUSTOMER_CODES',
          routeName: 'CustomerCodes',
          authority: 'customerCode',
        },
        {
          title: 'APPOINTMENT_CODES',
          routeName: 'AppointmentCodes',
          authority: 'appointmentCode',
        },
        {
          title: 'CONSULTING_CODES',
          routeName: 'ConsultingCodes',
          authority: 'consultingCode',
        },
        {
          title: 'PAYMENT_CODES',
          routeName: 'PaymentCodes',
          authority: 'paymentCode',
        },
        {
          title: 'NURSE_CODES',
          routeName: 'NurseCodes',
          authority: 'nurseCode',
        },
      ],
      authority: 'menu',
    },
    {
      title: 'MANAGE_CLINICS',
      items: [
        {
          title: '조직 관리',
          routeName: 'Organizations',
          authority: 'organization',
        },
        {
          title: '직원 관리',
          routeName: 'OrganizationsStaff',
          authority: 'user',
        },
        {
          title: 'CLINIC_INFO',
          routeName: 'ClinicInfo',
          authority: 'clinicInfo',
        },
        {
          title: 'CHART_SAMPLE',
          routeName: 'ChartSample',
          authority: 'penchartSample',
        },
      ],
      authority: 'menu',
    },
  ],
};

Object.keys(menuItems).forEach((key) =>
  menuItems[key].forEach((menu) => (menu.expanded = menu.items ? true : false))
);
export default menuItems;
