import React, { useCallback, useContext } from 'react';
import moment from 'moment';
//import { AppointmentBoardState } from 'store/appointmentBoardState';
import { AppointmentBoardStateContext } from './AppointmentBoards';
import { observer } from 'mobx-react';
import DatePicker from 'components/common/DatePicker';

const NavigateDatePanel = observer(() => {
  /**
   * @type {AppointmentBoardState}
   */
  const state = useContext(AppointmentBoardStateContext);

  const onClickArrow = useCallback((num) => {
    let s = moment(state.startAt).add(num, `${state.calendarUnit}s`).toDate();
    let e = moment(state.endAt).add(num, `${state.calendarUnit}s`).toDate();

    if (state.calendarUnit === '3-day') {
      s = moment(state.startAt).add(num, `days`).toDate();
      e = moment(state.endAt).add(num, `days`).toDate();
    }

    state.setPeriod(s, e);
    state.loadAppointments();
  }, []);

  return (
    <>
      <button className="btn btn-left" onClick={() => onClickArrow(-1)}>
        <span className="a11y">이전</span>
      </button>
      <div className="wrap_date_picker">
        <DatePicker
          className="text-center"
          todayButton="오늘"
          value={state.startAt}
          onChange={(day) => {
            let startAt = moment(day).startOf(state.calendarUnit);
            let endAt = moment(day).endOf(state.calendarUnit);

            if (state.calendarUnit === 'week') {
              startAt.add(1, 'days');
              endAt.add(1, 'days');
            }
            if (state.calendarUnit === '3-day') {
              endAt.add(2, 'days');
            }

            startAt = startAt.toDate();
            endAt = endAt.toDate();
            state.setPeriod(startAt, endAt);
            state.loadAppointments();
          }}
        />
        {state.calendarUnit !== 'day' && '-' ? (
          <div className="range-separator">-</div>
        ) : null}

        <DatePicker
          disabled
          className={`text-center ${
            state.calendarUnit === 'day' ? 'display-none' : ''
          }`}
          todayButton="오늘"
          value={state.endAt}
          onChange={(end) => state.setEndAt(end)}
        />
      </div>
      <button className="btn btn-right m-l-8" onClick={() => onClickArrow(1)}>
        <span className="a11y">이전</span>
      </button>
    </>
  );
});

export default NavigateDatePanel;
