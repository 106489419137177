import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const AlertWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  font-size: 13px;
`;

const Title = styled.div`
  font-size: 15px;
  font-weight: 700;
`;

const Contents = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  font-size: 13px;
`;

const SubTitle = styled.div`
  font-weight: 700;
  font-size: 13px;
`;

const TextBox = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  font-size: 13px;
`;

const ButtonWrapper = styled.div`
  button {
    color: black;
    border: solid 1px black;
    font-size: 11px;
    padding: 5px;
  }
`;

const MailText = styled.span`
  color: #008ddc;
  text-decoration: underline;
`;

const ModalMessageCaller = ({ close }) => {
  const onClick = () => {
    window.open(
      'https://carecrm.notion.site/e4bee3c8a4ab413fb34358e4b7bfb049',
      '_blank'
    );
  };

  return (
    <div>
      <AlertWrapper>
        <Title>발신번호 추가</Title>
        <Contents>
          <p>
            문자메시지 발송을 위해서는 발신번호 등록을 해야하며 관련 서류를
            제출해야 합니다.
          </p>
          <p>
            {`<거짓으로 표시된 전화번호로 인한 이용자의 피해 예방 등에 관한 고시>에 따라`}
            <br />
            발신번호의 명의가 이용자의 명의와 일치하는 경우에만 발신번호로 등록
            가능합니다.
          </p>
          <p>
            휴대폰 번호 발신번호 등록은 사업자 명의로 개통된 번호만 등록
            가능하며 <br />
            개인 휴대폰은 등록할 수 없습니다.
          </p>
        </Contents>
        <div>
          <SubTitle>제출 서류</SubTitle>
          <TextBox>
            <span>통신서비스 증명원 및 사업자등록증 제출</span>
            <ButtonWrapper>
              <button onClick={() => onClick()}>
                통신서비스 증명원 발급 방법 안내
              </button>
            </ButtonWrapper>
          </TextBox>
        </div>
        <div>
          <SubTitle>제출 방법</SubTitle>
          <TextBox>
            <span>
              발급 받은 서류를 이메일{' '}
              <MailText>
                <a href="mailto:cs@carecrm.co.kr">cs@carecrm.co.kr</a>
              </MailText>
              로 제출
            </span>
          </TextBox>
        </div>
      </AlertWrapper>

      <div className="buttons">
        <div className="flex-row">
          <button
            onClick={() => {
              close();
            }}
            className="btn btn-primary"
          >
            확인
          </button>
        </div>
      </div>
    </div>
  );
};

ModalMessageCaller.propTypes = {
  close: PropTypes.func,
};

export default observer(ModalMessageCaller);
