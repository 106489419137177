import { translate } from 'filters';

export default {
  keys: [
    {
      title: 'ACTION',
      filter: () => {
        return `<button class="btn btn-sm btn-black m-r-4" data-type="select" >${translate(
          'SELECT'
        )}</button>
        <button class="btn btn-sm btn-success m-r-4" data-type="edit" >${translate(
          'EDIT'
        )}</button>
        <button class="btn btn-sm btn-danger" data-type="delete" >${translate(
          'DELETE'
        )}</button>`;
      },
      customStyle: () => {
        return { width: '200px', textAlign: 'center' };
      },
    },
    {
      title: 'TITLE',
      column: 'title',
      type: 'text',
      noorder: true,
      customStyle: () => {
        return { textAlign: 'center', width: '240px', padding: '0 10px' };
      },
    },
    {
      title: 'CONTENTS',
      column: 'memo',
      type: 'arrow_text',
      noorder: true,
      filter: (o) => o.contents,
      customStyle: () => {
        return {
          textAlign: 'left',
          whiteSpace: 'normal',
          wordBreak: 'break-all',
          paddingLeft: '10px',
        };
      },
    },
  ],
};
