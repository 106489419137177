import * as moment from 'moment';
import { translate } from 'filters';

export default {
  keys: [
    {
      title: 'STATUS',
      noorder: true,
      filter: () => `<div class="text-center">
        <button class="btn btn-sm btn-primary">수정</button>
        <button class="btn btn-sm btn-danger">삭제</button>
      </div>`,
    },
    {
      title: 'SCHEDULED_AT',
      column: 'scheduledAt',
      filter: (o) => {
        let start = o.startHour;
        let end = o.endHour;
        return `<div>
          <div style = "width: 80%; float: left;">
          <div>${moment(o.scheduledAt).format('YYYY-MM-DD')}</div>
          <div>${start}-${end}</div>          
          </div>
          <div style = "width: 20%;float: right;"><span class="flex-wrap ico-arrow-right" style= "top : 5px;">
          </span></div>
        </div>`;
      },
      thStyle: () => {
        return { width: '100px' };
      },
    },
    {
      title: 'MEMO',
      column: 'memo',
      type: 'memo',
      noorder: true,
      thStyle: () => {
        return { width: '240px' };
      },
    },
    {
      title: '예약완료 자동문자 전송설정',
      noorder: true,
      titleStyle: () => {
        return { whiteSpace: 'break-spaces' };
      },
      filter: (o) => {
        if (o.smsSent) {
          return `<span class="sms-sent text-underline">설정</span>`;
        } else {
          return `<span class="sms-not-sent">미설정</span>`;
        }
      },
      thStyle: () => {
        return { width: '100px' };
      },
    },
    {
      title: '예약상태',
      noorder: true,
      selectOptions: [
        'scheduled',
        'waiting',
        'treating',
        'treatment_during',
        'done',
        'canceled',
        'consulting',
        'consulting_during',
        'consulted',
        'unvisited',
        'consulting_waiting',
        'treatment_waiting',
        'payment_waiting',
        'surgery_waiting',
        'surgery_during',
        'absence',
      ].map((key) => ({
        $$label: `APPOINTMENT_STATUS_${key.toUpperCase()}`,
        $$value: key,
      })),
      filter: (o) =>
        `${
          o.status === 'canceled'
            ? `<p class="c-red">${translate(
                `APPOINTMENT_STATUS_${(o.status || '').toUpperCase()}`
              )}</p>`
            : `<p>${translate(
                `APPOINTMENT_STATUS_${(o.status || '').toUpperCase()}`
              )}</p>`
        }`,
    },
    {
      title: '예약종류',
      noorder: true,
      filter: (o) => {
        let typeStr;
        if (o.type === 'consulting') typeStr = '상담예약';
        if (o.type === 'treatment') typeStr = '진료예약';
        if (o.type === 'surgery') typeStr = '시수술예약';
        return typeStr;
      },
    },
    {
      title: 'DEPARTMENT_CATEGORIES',
      column: 'department__category__name',
      noorder: true,
      type: 'list_mini',
    },
    {
      title: 'DEPARTMENTS',
      column: 'department__name',
      noorder: true,
    },
    {
      title: '상담사',
      column: 'counselor__name',
      noorder: true,
    },
    {
      title: '의사',
      column: 'doctor__name',
      noorder: true,
    },
    {
      title: 'ASSISTS',
      column: 'facialist__name',
      noorder: true,
    },
    {
      title: 'ACQUISITION_CHANNELS',
      column: 'acquisitionChannel__name',
      noorder: true,
    },
    {
      title: '시/수술 카테고리',
      type: 'list_mini',
      noorder: true,
      filter: (o) =>
        `${
          o.type === 'surgery'
            ? `${o.paymentTreatmentItems
                .map((item) => `<p>${item.categoryName || ''}</p>`)
                .join('\n')}`
            : `${o.items
                .map((item) => `<p>${(item.category || {}).name || ''}</p>`)
                .join('\n')}`
        }`,
    },
    {
      title: '시/수술명',
      type: 'list',
      noorder: true,
      filter: (o) =>
        `${
          o.type === 'surgery'
            ? `${o.paymentTreatmentItems
                .map((item) => `<p>${item.name || ''}</p>`)
                .join('\n')}`
            : `${o.items.map((item) => `<p>${item.name || ''}</p>`).join('\n')}`
        }`,
    },
    {
      title: '작성자',
      column: 'createdName',
      noorder: true,
    },
    {
      title: '작성일',
      column: 'createdAt',
      filter: (o) => {
        return `<div>
            <div>${moment(o.createdAt).format('YYYY-MM-DD')} </div>
            <div>${moment(o.createdAt).format('HH:mm')}</div>
            </div>`;
      },
    },
    // {
    //     title: 'UPDATE_AT',
    //     column: 'updatedAt',
    //     filter: o => {
    //         return `<div>
    //             <div>${moment(o.updatedAt).format('YYYY-MM-DD')}</div>
    //             <div>${moment(o.updatedAt).format('HH:mm:ss')}</div>
    //         </div>`
    //     },
    // },
  ],
};
