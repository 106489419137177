import React from 'react';
import PropTypes from 'prop-types';

const SelectNextProcess = ({
  nextProcess,
  counselors,
  doctors,
  facialist,
  treatmentSelectCounselors,
  treatmentSelectDoctors,
  treatmentSelectFacialists,
  setTreatmentSelectCounselors,
  setTreatmentSelectDoctors,
  setTreatmentSelectFacialists,
}) => {
  if (nextProcess === 'consulting_waiting') {
    return (
      <div>
        <div>
          <span>추가상담대기 지정된 상담사</span>
        </div>
        <div>
          <select
            value={(counselors || []).findIndex(
              (option) => option.id === treatmentSelectCounselors?.id
            )}
            onChange={(e) =>
              setTreatmentSelectCounselors(counselors[e.target.value])
            }
          >
            <option value={undefined}>-</option>
            {(counselors || []).map((item, idx) => (
              <option key={idx} value={idx}>
                {item.name || item.content}
              </option>
            ))}
          </select>
        </div>
      </div>
    );
  }
  if (nextProcess === 'treatment_waiting') {
    return (
      <div>
        <div>
          <span>진료대기 지정된 의사</span>
        </div>
        <div>
          <select
            value={(doctors || []).findIndex(
              (option) => option.id === treatmentSelectDoctors?.id
            )}
            onChange={(e) => setTreatmentSelectDoctors(doctors[e.target.value])}
          >
            <option value={undefined}>-</option>
            {(doctors || []).map((item, idx) => (
              <option key={idx} value={idx}>
                {item.name || item.content}
              </option>
            ))}
          </select>
        </div>
      </div>
    );
  }
  if (nextProcess === 'surgery_waiting') {
    return (
      <div className="flex-row">
        <div>
          <div>
            <span>시/수술대기 지정된 의사</span>
          </div>
          <div>
            <select
              value={(doctors || []).findIndex(
                (option) => option.id === treatmentSelectDoctors?.id
              )}
              onChange={(e) => {
                console.log(doctors[e.target.value]);
                setTreatmentSelectDoctors(doctors[e.target.value]);
              }}
            >
              <option value={undefined}>-</option>
              {(doctors || []).map((item, idx) => (
                <option key={idx} value={idx}>
                  {item.name || item.content}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div>
          <div>
            <span>시/수술대기 지정된 피부관리사</span>
          </div>
          <div>
            <select
              value={(facialist || []).findIndex(
                (option) => option.id === treatmentSelectFacialists?.id
              )}
              onChange={(e) =>
                setTreatmentSelectFacialists(facialist[e.target.value])
              }
            >
              <option value={undefined}>-</option>
              {(facialist || []).map((item, idx) => (
                <option key={idx} value={idx}>
                  {item.name || item.content}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    );
  }
  return <></>;
};

SelectNextProcess.propTypes = {
  nextProcess: PropTypes.string,
  counselors: PropTypes.array,
  doctors: PropTypes.array,
  facialist: PropTypes.array,
  treatmentSelectCounselors: PropTypes.object,
  treatmentSelectDoctors: PropTypes.object,
  treatmentSelectFacialists: PropTypes.object,
  setTreatmentSelectCounselors: PropTypes.func,
  setTreatmentSelectDoctors: PropTypes.func,
  setTreatmentSelectFacialists: PropTypes.func,
};

export default SelectNextProcess;
