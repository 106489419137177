import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';

const HideHidden = ({
  toggleHandler,
  checked = false,
  text = '',
  className = '',
  disabled = false,
}) => {
  const [hideHidden, setHideHidden] = useState(checked);

  useEffect(() => {
    toggleHandler(hideHidden);
  }, [disabled, hideHidden, toggleHandler]);

  return (
    <div
      className={`flex-row items-center ${className} `}
      onClick={() => {
        setHideHidden(disabled ? hideHidden : !hideHidden);
      }}
      style={{ cursor: 'pointer' }}
    >
      <div className="checkbox flex-wrap m-r-8">
        {hideHidden ? <i className="zmdi zmdi-check" /> : null}
      </div>
      <span className="m-r-12">{text}</span>
    </div>
  );
};

HideHidden.propTypes = {
  toggleHandler: PropTypes.func,
  checked: PropTypes.bool,
  text: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

export default HideHidden;
