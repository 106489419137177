import { useEffect } from 'react';
const Privacy = () => {
  useEffect(() => {
    window.location.href = 'https://www.carecrm.co.kr/privacy';
  }, []);

  return null;
};

export default Privacy;
