import React from 'react';
import { observer } from 'mobx-react';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import { translate } from 'filters';

const SelectBox = ({
  name,
  options,
  value,
  onChange,
  placeholder,
  disabled = false,
  isSearchable = false,
  loadingMessage,
  ref,
  ...props
}) => {
  const styles = {
    control: (props) => ({
      ...props,
      cursor: 'pointer',
      border: 0,
      boxShadow: 'none',
    }),
  };

  return (
    <Select
      styles={styles}
      options={options}
      value={(options ? options : []).filter((option) => {
        if (typeof option === 'object') {
          return option.value === value;
        }
        return option;
      })}
      ref={ref}
      isSearchable={isSearchable}
      isDisabled={disabled}
      classNamePrefix="uno-select"
      noOptionsMessage={() => {
        if (!options) {
          return loadingMessage ? (
            loadingMessage
          ) : (
            <div>
              <span>{`${translate('SELECT_DATA_LOADING')}...  `}</span>
              <FontAwesomeIcon icon={faCircleNotch} spin />
            </div>
          );
        }

        return props.noOptionsMessage
          ? props.noOptionsMessage
          : translate('NO_SEARCH_RESULT');
      }}
      placeholder={placeholder ? placeholder : ''}
      onChange={(obj) => {
        if (onChange) {
          let payload = {
            target: {
              ...obj,
              name: name,
              // value: (obj && obj.value) ? obj.value : null
              value: obj.value,
            },
          };
          if (name === 'departmentId' && obj.value === -1) {
            //전송부서, 부서 선택 해제시 value = null 설정으로 플레이스 홀더 노출
            payload = {
              target: {
                ...obj,
                name: name,
                value: null,
              },
            };
          }
          onChange(payload);
        }

        // return obj
      }}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
        },
      })}
      {...props}
      // value={value}
      // className="uno-select"
      // isClearable={true}
      // components={animatedComponents}
      // defaultInputValue=""
      // defaultMenuIsOpen={true}
      // defaultValue={"ㅁㅁㅁㅁ"}
      // inputValue=""
      // menuIsOpen={true}
      // value={ "test" }
      noOptionsText={translate('NO_SEARCH_RESULT')}
      // maxMenuHeight={"15em"}
      // placeholder={ placeholder ? placeholder : "선택하세요" }
    />
  );
};

SelectBox.propTypes = {
  name: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  options: PropTypes.array,
  value: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.number,
  ]),
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  isSearchable: PropTypes.bool,
  loadingMessage: PropTypes.string,
  ref: PropTypes.object,
  noOptionsMessage: PropTypes.object,
};

export default React.memo(observer(SelectBox));
