import { types, applyPatch } from 'mobx-state-tree';

const entityMap = {
  '&': '&amp;',
  '<': '&lt;',
  '>': '&gt;',
  '"': '&quot;',
  "'": '&#39;',
  '/': '&#x2F;',
  '`': '&#x60;',
  '=': '&#x3D;',
};

function escapeHtml(string) {
  return String(string).replace(/[&<>"'`=/]/g, function (s) {
    return entityMap[s];
  });
}

const Line = types
  .model({
    type: types.string,
    points: types.frozen(types.array(types.number)),
    stroke: types.string,
    strokeWidth: types.number,
  })
  .actions((self) => ({
    append(point) {
      self.points = [...self.points, ...point];
    },
  }));

const Image = types.model({
  id: types.number,
  url: types.string,
  x: types.number,
  y: types.number,
});

const Text = types
  .model({
    id: types.number,
    htmlText: types.string,
    text: types.string,
    width: types.number,
    x: types.number,
    y: types.number,
    fontSize: types.number,
    fill: types.string,
  })
  .views((self) => ({
    get escapedText() {
      return escapeHtml(self.text);
    },
  }))
  .actions((self) => ({
    changeText(htmlText, text) {
      self.htmlText = text;
      self.text = text;
    },
    update(text) {
      Object.assign(self, text);
    },
    updateSize(width) {
      self.width = width;
    },
  }));

export const stateStore = types
  .model({
    lines: types.optional(types.array(Line), []),
    images: types.optional(types.array(Image), []),
    texts: types.optional(types.array(Text), []),
  })
  .actions((self) => {
    const patches = [];
    let patchIndex = 0;
    let ignorePatch = false;

    function checkContinuousOperation(pathA, pathB) {
      if (pathA === pathB) return true;

      const pathASplitted = pathA.split('/');
      const [aBase, aOp] = [
        pathASplitted.slice(0, pathASplitted.length - 2).join('/'),
        pathASplitted[pathASplitted.length - 1],
      ];

      const pathBSplitted = pathB.split('/');
      const [bBase, bOp] = [
        pathBSplitted.slice(0, pathBSplitted.length - 2).join('/'),
        pathBSplitted[pathBSplitted.length - 1],
      ];

      const opGroup = ['x', 'y', 'width', 'height'];

      if (aBase === bBase && opGroup.includes(aOp) && opGroup.includes(bOp)) {
        return true;
      }

      if (pathA === `${pathB}/points` || pathB === `${pathA}/points`) {
        return true;
      }

      return false;
    }

    return {
      addLine(line) {
        self.lines.push(Line.create(line));
      },
      addImage(image) {
        self.images.push(Image.create(image));
      },
      removeImage(id) {
        self.images = self.images.filter((i) => i.id !== id);
      },
      addText(text) {
        self.texts.push(Text.create(text));
      },
      removeText(id) {
        self.texts = self.texts.filter((i) => i.id !== id);
      },
      addPatch(patch, reverse) {
        patches.splice(patchIndex, patches.length - patchIndex, {
          patch,
          reverse,
        });
        patchIndex = patches.length;
      },
      undo() {
        ignorePatch = true;
        const lastPatch = patches[patchIndex - 1].reverse;
        applyPatch(self, lastPatch);
        const path = lastPatch.path;
        patchIndex--;
        for (let i = patchIndex - 1; i >= 0; i--) {
          if (checkContinuousOperation(patches[i].reverse.path, path)) {
            applyPatch(self, patches[i].reverse);
            patchIndex = i;
          } else {
            break;
          }
        }
        ignorePatch = false;
      },
      redo() {
        ignorePatch = true;
        const lastPatch = patches[patchIndex].patch;
        patchIndex++;
        const path = lastPatch.path;
        for (let i = patchIndex - 1; i < patches.length; i++) {
          if (checkContinuousOperation(patches[i].patch.path, path)) {
            applyPatch(self, patches[i].patch);
            patchIndex = i + 1;
          } else {
            break;
          }
        }
        ignorePatch = false;
      },
      canUndo() {
        return patchIndex !== 0;
      },
      canRedo() {
        return patchIndex !== patches.length;
      },
      isIgnoringPatch() {
        return ignorePatch;
      },
    };
  });

export const uiStore = types
  .model({
    penOption: types.optional(
      types.model({
        size: types.optional(types.number, 8),
        color: types.optional(types.string, '#000'),
      }),
      {}
    ),
    markerOption: types.optional(
      types.model({
        size: types.optional(types.number, 24),
        color: types.optional(types.string, '#f00'),
      }),
      {}
    ),
    eraserOption: types.optional(
      types.model({
        size: types.optional(types.number, 24),
      }),
      {}
    ),
    textOption: types.optional(
      types.model({
        size: types.optional(
          types.number,
          Number(window.localStorage.getItem('image-editor-font-size') ?? 15)
        ),
        color: types.optional(types.string, '#000'),
      }),
      {}
    ),
    tool: types.optional(types.string, 'select'),
    isDrawing: types.optional(types.boolean, false),
    isMoving: types.optional(types.boolean, false),
    movingAnchor: types.optional(types.frozen(), null),
    selected: types.optional(types.number, 0),
    editText: types.optional(types.frozen(), null),
    editTextValue: types.optional(types.string, ''),
    zoom: types.optional(types.number, 1),
    stageSize: types.optional(
      types.model({
        x: types.number,
        y: types.number,
      }),
      { x: 800, y: 600 }
    ),
    stagePosition: types.optional(
      types.model({
        x: types.number,
        y: types.number,
      }),
      { x: 0, y: 0 }
    ),
    pixelRatio: types.optional(types.number, 1),
  })
  .views((self) => ({
    get overlayZoom() {
      return self.zoom * self.pixelRatio;
    },
    get overlayPosition() {
      return {
        x: self.stagePosition.x,
        y: self.stagePosition.y,
      };
    },
  }))
  .actions((self) => ({
    setPenOption(option) {
      self.penOption = option;
    },
    setMarkerOption(option) {
      self.markerOption = option;
    },
    setEraserOption(option) {
      self.eraserOption = option;
    },
    setTextOption(option) {
      self.textOption = option;
      window.localStorage.setItem(
        'image-editor-font-size',
        option.size.toString()
      );
    },
    selectTool(tool) {
      self.tool = tool;
      self.selected = 0;
    },
    setDraw(drawing) {
      self.isDrawing = drawing;
    },
    setMove(moving) {
      self.isMoving = moving;
    },
    setSelected(id) {
      self.selected = id;
    },
    setEditText(editText) {
      self.editText = editText;
    },
    setEditTextValue(v) {
      self.editTextValue = v;
    },
    setZoom(z) {
      self.zoom = z;
    },
    setStageSize(width, height) {
      self.stageSize = { x: width, y: height };
    },
    setStagePosition(x, y) {
      self.stagePosition = { x, y };
    },
    setMovingAnchor(anchor) {
      self.movingAnchor = anchor;
    },
    setPixelRatio(ratio) {
      self.pixelRatio = ratio;
    },
    getScreenCenter() {
      return {
        x: self.stageSize.x / 2,
        y: self.stageSize.y / 2,
      };
    },
    toImagePos(screenPos) {
      return {
        x: (screenPos.x - self.stagePosition.x) / self.zoom,
        y: (screenPos.y - self.stagePosition.y) / self.zoom,
      };
    },
    toOverlayPos(screenPos) {
      return {
        x: (screenPos.x - self.overlayPosition.x) / self.overlayZoom,
        y: (screenPos.y - self.overlayPosition.y) / self.overlayZoom,
      };
    },

    zoomTo(stage, scale, screenPos) {
      var oldScale = self.zoom;

      var newScale = Math.max(1 / self.pixelRatio, oldScale * scale);

      const maxX = (newScale * self.pixelRatio - 1) * self.stageSize.x;
      const maxY = (newScale * self.pixelRatio - 1) * self.stageSize.y;

      const imagePos = self.toImagePos(screenPos);

      self.setZoom(newScale);
      self.setStagePosition(
        Math.max(-maxX, Math.min(0, screenPos.x - imagePos.x * newScale)),
        Math.max(-maxY, Math.min(0, screenPos.y - imagePos.y * newScale))
      );
    },
  }));
