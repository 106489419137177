import React, { useState } from 'react';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';

const InputCurrency = ({
  name,
  className,
  disabled = false,
  initValue,
  allowNegative = true,
  onValueChange,
  countryCode,
  options,
}) => {
  const [value] = useState(initValue);
  const currency_options = {
    ...currency_meta[countryCode],
    ...options,
  };

  return (
    <NumberFormat
      className={className}
      name={name}
      disabled={disabled}
      defaultValue={value}
      allowNegative={allowNegative}
      onValueChange={(payload) => {
        payload.target = {
          value: payload.floatValue,
          name: name,
        };

        if (onValueChange) {
          return onValueChange(payload);
        }
      }}
      thousandSeparator={currency_options.thousandSeparator}
      decimalSeparator={currency_options.decimalSeparator}
      prefix={currency_options.prefix}
      allowLeadingZeros={currency_options.allowLeadingZeros}
      decimalScale={currency_options.decimalScale}
      fixedDecimalScale={currency_options.fixedDecimalScale}
      allowEmptyFormatting={currency_options.allowEmptyFormatting}
    />
  );
};

// todo:
// 이하 설정값들은, 추후 별도로 작성되는 국가/지역 코드에서 참조될 예정입니다
const currency_meta = {
  kr: {
    thousandSeparator: ',',
    decimalSeparator: '.',
    prefix: '₩',
    allowLeadingZeros: false,
    decimalScale: 0,
    fixedDecimalScale: true,
    allowEmptyFormatting: true,
  },
  us: {
    thousandSeparator: ',',
    decimalSeparator: '.',
    prefix: '$',
    allowLeadingZeros: true,
    decimalScale: 2,
    fixedDecimalScale: true,
    allowEmptyFormatting: false,
  },
};

InputCurrency.propTypes = {
  name: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  initValue: PropTypes.number,
  allowNegative: PropTypes.bool,
  onValueChange: PropTypes.func,
  countryCode: PropTypes.string,
  options: PropTypes.object,
};

export default InputCurrency;
