import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { currency, removeCurrency, translate } from 'filters';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import { useServices } from 'hooks/useServices';
import styled, { css } from 'styled-components';

const Input = styled.input`
  width: 100%;
  height: 34px;
  padding: 8px 20px;
  font-size: 12px;
  min-height: 10px;
  border-radius: 4px;
  border: 1px solid #dee2ec;

  ${({ disabled }) =>
    disabled &&
    css`
      background-color: #efeff4 !important;
      color: #ceced7 !important;
    `}
`;

const ChangeDiscountAmount = ({ payments, setPayments }) => {
  const services = useServices();
  const [discountReasonItems, setDiscountReasonItems] = useState([]);
  const [discountReason, setDiscountReason] = useState(null);
  const [discountAmount, setDiscountAmount] = useState(0);
  const [percentage, setPercentage] = useState(0);

  const discountReasonCallApi = useCallback(async () => {
    try {
      const resp = await services.crm.payment.discountReason.all();
      if (!resp) return;

      setDiscountReasonItems(resp.data);
    } catch (e) {
      console.log(e.description);
    }
  }, [services.crm.payment.discountReason]);

  useEffect(() => {
    discountReasonCallApi();
  }, [discountReasonCallApi]);

  useEffect(() => {
    const discount = (payments || {}).discountAmount || 0;
    const perc =
      Math.ceil(
        ((payments || {}).discountAmount / (payments || {}).priceVatInclude) *
          100
      ) || 0;

    let changePayment = {
      ...payments,
      percentage: parseInt(perc),
      discountAmount: Number(Math.round(discount)),
      requestAmount: payments.priceVatInclude - Number(Math.round(discount)),
      priceVatInclude: payments.priceVatInclude,
    };

    if (payments.discountReason !== null) {
      setDiscountReason(payments.discountReason);
      changePayment.discountReason = toJS(payments.discountReason);
    }

    setDiscountAmount(discount);
    setPercentage(perc);
  }, []);

  const onChangeDiscountPercentage = (e) => {
    let value = e.target.value;
    if (value > 100) {
      value = 100;
    }

    let dot = value / 100;
    let discount = payments.priceVatInclude * dot;

    setPercentage(parseInt(Math.ceil(value)));
    setDiscountAmount(Math.round(discount));
    setPayments({
      ...payments,
      percentage: parseInt(Math.ceil(value)),
      discountAmount: Number(Math.round(discount)),
      requestAmount: payments.priceVatInclude - Number(Math.round(discount)),
      priceVatInclude: payments.priceVatInclude,
    });
  };

  const onChangeDiscountAmount = (e) => {
    //discountAmount = priceVatInclude * (x/100)
    //discountAMount/priceVatInclude = x / 100
    //discountAmount/priceVatInclude*100 = x

    let value = parseInt(removeCurrency(e.target.value.replace('₩', '')));
    let x = (value / payments.priceVatInclude) * 100;

    if (x > 100) {
      value = payments.priceVatInclude;
      x = 100;
    }

    setPercentage(Math.ceil(x));
    setDiscountAmount(value);
    setPayments({
      ...payments,
      percentage: Math.ceil(x),
      discountAmount: Number(Math.round(value)),
      requestAmount: payments.priceVatInclude - Number(Math.round(value)),
      priceVatInclude: payments.priceVatInclude,
    });
  };

  return (
    <React.Fragment>
      <div className="form-control m-t-16">
        <label>{translate('PRICE_VAT_INCLUDE')}</label>
        <Input
          disabled
          defaultValue={`₩${currency(payments.priceVatInclude)}`}
        />
      </div>

      <div className="flex-row">
        <div className="form-control m-t-16 m-r-16" style={{ width: '50px' }}>
          <label>할인율 (%)</label>
          <Input onChange={onChangeDiscountPercentage} value={percentage} />
        </div>
        <div className="form-control m-t-16 m-r-16" style={{ width: '100px' }}>
          <label>할인 금액</label>
          <Input
            onChange={onChangeDiscountAmount}
            value={`₩${currency(discountAmount)}`}
          />
        </div>
      </div>

      <div className="form-control m-t-16">
        <label>{translate('DISCOUNT_REASON')}</label>
        <div>
          <select
            style={{ width: '100%' }}
            value={
              discountReason
                ? (discountReasonItems || []).findIndex(
                    (reason) => reason.id === discountReason.id
                  )
                : -1
            }
            onChange={(e) => {
              if (e.target.value === '') {
                setDiscountReason(null);
                setPayments({
                  ...payments,
                  discountAmount,
                  discountReason: null,
                });
              } else {
                setDiscountReason((discountReasonItems || [])[e.target.value]);
                setPayments({
                  ...payments,
                  discountAmount,
                  discountReason: (discountReasonItems || [])[e.target.value],
                });
              }
            }}
          >
            <option key={-1} value={''}>
              -
            </option>
            {(discountReasonItems || []).map((reason, idx) => (
              <option
                key={idx}
                value={idx}
                className={!reason.visible ? 'display-none' : ''}
              >
                {reason.content}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="form-control m-t-16">
        <label>최종 가격</label>
        <Input
          disabled
          value={`₩${currency(payments.priceVatInclude - discountAmount)}`}
        />
      </div>
    </React.Fragment>
  );
};

ChangeDiscountAmount.propTypes = {
  payments: PropTypes.object,
  setPayments: PropTypes.func,
};

export default observer(ChangeDiscountAmount);
