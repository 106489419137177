import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react';
import { $case, translate } from 'filters';
import PropTypes from 'prop-types';
import { useAuth } from 'store/auth';
import { useUI } from 'hooks/useUI';
import update from 'immutability-helper';

const classNameArr = (title) => {
  switch (title) {
    case 'HOME':
      return 'ic-home';
    case 'APPOINTMENT_BOARDS':
      return 'ic-calender';
    case 'MANAGE_SMS':
      return 'ic-msg';
    case 'CONSULTING_REQUEST':
      return 'ic-consult';
    case 'SEARCH':
      return 'ic-inq';
    case 'STATISTICS':
      return 'ic-stat';
    case 'SETTLEMENTS':
      return 'ic-stat';
    case 'PRESCRIPTIONS':
      return 'ic-prescription';
    case 'SETTINGS':
      return 'ic-setting';
    case 'MANAGE_CLINICS':
      return 'ic-hospital';
    default:
      return 'ic-consult'; // 상담문의 title이 정해지면 추후 수정 필요
  }
};

const Menu = ({ depth, menu }) => {
  const location = useLocation();
  const history = useHistory();
  const auth = useAuth();
  const ui = useUI();

  const [collapsed, setCollapsed] = useState(
    Object.assign({}, ui.isNavMenuFold)
  );

  const onClickMenu = (menu, event) => {
    event.stopPropagation();

    if (menu.items) {
      const updatedConfig = update(collapsed, {
        [menu.title]: { $set: !collapsed[menu.title] },
      });

      setCollapsed(updatedConfig);
      ui.setUiState('isNavMenuFold', updatedConfig);
      return;
    }

    if (menu.routeName) {
      history.push(
        `/${auth.me.type === 'admin' ? 'admin' : 'crm'}/${$case.toSnake(
          menu.routeName,
          '-'
        )}`
      );
    }

    if (menu.link) {
      window.open(menu.link, '_blank');
    }
  };

  const parentSelected = (menu) => {
    if (!menu || !menu.items) return '';

    const frags = location.pathname.split('/');
    if (
      menu.items.filter(
        (v) => $case.toSnake(v.routeName, '-') == frags[frags.length - 1]
      ).length !== 0
    )
      return 'selected';
    else return '';
  };

  const selected = (item) => {
    if (!item.routeName) return '';

    const frags = location.pathname.split('/');
    return frags[frags.length - 1] === $case.toSnake(item.routeName, '-')
      ? 'selected'
      : '';
  };

  return (
    <div className={`menu depth-${depth}`}>
      {menu.items &&
        menu.items
          .filter(
            (item) =>
              item.authority === 'admin' ||
              item.authority === 'menu' ||
              auth.me.authorityGroup[item.authority] === 'write'
          )
          .map((item) => (
            <div
              key={item.title}
              className={`menu-item ${selected(item)}`}
              onClick={(e) => onClickMenu(item, e)}
            >
              <div
                className={`title flex-row ${
                  depth === 0 ? parentSelected(item) : ''
                }`}
              >
                <span className="text flex-fill">
                  {depth === 0 && (
                    <i className={`ico ${classNameArr(item.title)}`}></i>
                  )}
                  {translate(item.title)}
                </span>
                {}

                {item.items && (
                  <i
                    className={`zmdi c-primary flex-wrap ${
                      !collapsed[item.title]
                        ? 'zmdi-chevron-up'
                        : 'zmdi-chevron-down'
                    }`}
                  />
                )}
              </div>
              {!collapsed[item.title] && <Menu depth={depth + 1} menu={item} />}
            </div>
          ))}
    </div>
  );
};

Menu.propTypes = {
  depth: PropTypes.number,
  menu: PropTypes.object,
};

export default observer(Menu);
