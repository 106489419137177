import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { Table } from 'antd';
import LimitSetter from './LimitSetter';
import Pagination from './Pagination';
import { currency, currencySymbol } from 'filters';
import PropTypes from 'prop-types';
import moment from 'moment';

/*
정렬 사용
model 에서 정의 필요
    column: 'paidAt',
    orderKey: 'paidAt',
    order: 'desc',

*/

const { Column, ColumnGroup } = Table;
const HeaderRowSpanDataTable = ({
  data,
  total,
  model,
  params,
  bordered,
  hideBottom,
  hideLimitSetter,
  onAction,
  onParams,
  rowKey,
  onClickHeaderRow,
}) => {
  const [currentIndex, setCurrentIndex] = useState(
    params && params.queryParams && params.queryParams.page
      ? params.queryParams.page - 1
      : 0
  );
  const [limit, setLimit] = useState(params ? params.queryParams.limit : 20);
  const [rows, setRows] = useState([]);

  useEffect(() => {
    setRows(data);
  }, [data]);

  const doAction = (e, data, key) => {
    const rowIdx = rows.findIndex((v) => v[key.key] === data);
    const row = rows[rowIdx];
    const keyObj = { title: key.title, column: key.column };

    if (onAction) onAction({ row, event: e, rowIdx, key: keyObj });
  };

  const onPage = (idx) => {
    if (!params) return;

    if (idx >= 0) {
      setCurrentIndex(idx);
    }
    params.customParam('page', idx + 1);
    model.currentIndex = idx;
    onParams && onParams(params);
  };

  const onLimit = (l) => {
    if (!params) return;

    resetOffset();
    setLimit(l);
    params.limit(l);
    model.limit = l;
    onPage();
  };

  const resetOffset = () => {
    if (!params) return;

    setCurrentIndex(0);
    params.offset(0);
  };

  const percentage = (target) => {
    let targetNumber = target[0];
    let totalNumber = target[1];
    targetNumber = targetNumber ? targetNumber : 0;
    totalNumber = totalNumber ? totalNumber : 0;
    const fixedNum = 0;
    const percent =
      targetNumber !== 0
        ? ((100 * targetNumber) / totalNumber).toFixed(fixedNum)
        : 0;
    return `${targetNumber.toLocaleString()} (${percent}%)`;
  };

  const order = (key) => {
    if (!key.column || key.nojoin) return;

    model.keys.forEach((k) => {
      if (k.column !== key.column) {
        k.order = undefined;
        return;
      }
      k.order = k.order === 'desc' ? 'asc' : 'desc';
    });

    onClickHeaderRow && onClickHeaderRow(key);
  };

  return (
    <>
      <Table
        bordered
        locale={{ emptyText: '조회결과가 없습니다' }}
        dataSource={data}
        rowKey={rowKey}
        pagination={{
          pageSize: '100',
        }}
      >
        {model.keys.map((item, i) => {
          return item.columnGroup ? (
            <ColumnGroup key={i} title={item.title}>
              {item.columnItems.map((column, idx) => {
                return (
                  <Column
                    title={column.title}
                    dataIndex={column.key}
                    key={idx}
                    render={(tags, row) => {
                      if (column.filter !== undefined) {
                        if (column.filter.type === 'percentage') {
                          let params = column.filter.params;
                          let parameters = params.map((v) => {
                            return row[v];
                          });
                          return percentage(parameters);
                        } else if (column.filter.type === 'amount') {
                          return `${currencySymbol()} ${currency(tags)}`;
                        } else {
                          return column.filter(row);
                        }
                      } else {
                        return tags;
                      }
                    }}
                  />
                );
              })}
            </ColumnGroup>
          ) : item.dateFormat ? (
            <Column
              title={item.title}
              dataIndex={item.key}
              key={i}
              render={(tags) => moment(tags).format(item.dateFormat)}
            />
          ) : item.orderKey !== undefined ? (
            //정렬 아이콘을 추가하기 위함
            <Column
              title={
                <div
                  className="flex-row items-center"
                  onClick={() => {
                    order(item);
                  }}
                >
                  <div>
                    <span>{item.title}</span>
                  </div>
                  <i
                    className={`zmdi flex-wrap m-l-8 zmdi-chevron-${
                      item.order === 'asc' ? 'up' : 'down'
                    }`}
                  ></i>
                </div>
              }
              dataIndex={item.key}
              key={i}
              render={(tags, row) => {
                return (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: item.filter ? item.filter(row) : tags,
                    }}
                    onClick={(e) => {
                      doAction(e, tags, item);
                    }}
                  />
                );
              }}
            />
          ) : item.filter ? (
            <Column
              title={item.title}
              dataIndex={item.key}
              key={i}
              render={(tags, row) => {
                return (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: item.filter(row),
                    }}
                    onClick={(e) => {
                      doAction(e, tags, item);
                    }}
                  />
                );
              }}
            />
          ) : (
            <Column title={item.title} dataIndex={item.key} key={i} />
          );
        })}
      </Table>
      {!hideBottom && (
        <div className="flex-row flex-between wrap-pagination">
          <Pagination
            total={total}
            limit={limit}
            currentIndex={currentIndex}
            onPage={onPage}
          />
          {!hideLimitSetter ? (
            <LimitSetter limit={limit} onLimit={onLimit} />
          ) : null}
        </div>
      )}
    </>
  );
};

HeaderRowSpanDataTable.propTypes = {
  data: PropTypes.array,
  total: PropTypes.number,
  model: PropTypes.object,
  params: PropTypes.object,
  bordered: PropTypes.bool,
  hideBottom: PropTypes.bool,
  hideLimitSetter: PropTypes.bool,
  onAction: PropTypes.func,
  onParams: PropTypes.func,
  rowKey: PropTypes.string,
  onClickHeaderRow: PropTypes.func,
};

export default observer(HeaderRowSpanDataTable);
