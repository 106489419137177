import { currency, currencySymbol } from 'filters';

export default {
  keys: [
    {
      title: '월',
      key: 'paidAt',
      column: 'paidAt',
      dateFormat: 'YYYY-MM',
      columnGroup: false,
    },
    {
      title: '월간 매출액',
      key: 'totalAmount',
      column: 'totalAmount',
      columnGroup: false,
      filter: (o) => {
        return `${currencySymbol()} ${currency((o || {}).totalAmount || 0)}`;
      },
    },
    {
      title: '월간 수납액',
      columnGroup: true,
      columnItems: [
        { title: '합계', key: 'paidAmount', filter: { type: 'amount' } },
        { title: '카드', key: 'cardAmount', filter: { type: 'amount' } },
        {
          title: '계좌이체',
          key: 'bankTransferAmount',
          filter: { type: 'amount' },
        },
        { title: '현금', key: 'cashAmount', filter: { type: 'amount' } },
      ],
    },
    {
      title: '월간 미수액',
      key: 'unpaidAmount',
      column: 'unpaidAmount',
      columnGroup: false,
      filter: (o) => {
        return `${currencySymbol()} ${currency((o || {}).unpaidAmount || 0)}`;
      },
    },
    {
      title: '월간 환불액',
      key: 'refundAmount',
      column: 'refundAmount',
      columnGroup: false,
      filter: (o) => {
        return `${currencySymbol()} ${currency((o || {}).refundAmount || 0)}`;
      },
    },
  ],
};
