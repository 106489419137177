import acquisitionChannel from './acquisition-channel';
import allMemo from './all-memo';
import appointment from './appointment';
import appointmentTab from './appointment-tab';
import appointmentConsultingRequests from './appointment-consulting-requests';
import appointmentSurgery from './appointment-surgery';
import appointmentSurgeryMinified from './appointment-surgery-minified';
import consulting from './consulting';
import consultingTab from './consulting-tab';
import consultingRequests from './consulting-requests';
import consultingCounselors from './consulting-counselors';
import consultingAutoAssign from './consulting-auto-assign';
import responseConsultings from './response-consultings';
import responseRequests from './response-requests';
import responseHistories from './response-histories';
import consultingRequestsCategories from './consulting-requests-categories';
import consultingRequestsMediaConnects from './consulting-requests-media-connects';
import consultingRequestsNeedRecall from './consulting-requests-need-recall';
import mediaConnects from './media-connects';
import consultingEvent from './consulting-event';
import counselor from './counselor';
import customer from './customer';
import complaint from './complaint';
import departmentCategory from './department-category';
import discountReason from './discount-reason';
import job from './job';
import level from './level';
import region from './region';
import doctor from './doctor';
import paymentProduct from './payment-product'; // ASSOC
import paymentTreatment from './payment-treatment'; // ASSOC
import payment from './payment';
import paymentTreatmentItems from './payment-treatment-items';
import paymentProductItems from './payment-product-items';
import paymentTab from './payment-tab';
import paymentSubDetail from './payment-sub-detail';
import paymentDetail from './payment-detail';
import paymentRefundsSurgeries from './payment-refunds-surgeries';
import paymentRefundsProducts from './payment-refunds-products';
import paymentInputSurgeries from './payment-input-surgeries';
import paymentUpdateSurgeries from './payment-update-surgeries';
import paymentInputProducts from './payment-input-products';
import toBeStoredSurgeries from './to-be-stored-surgeries';
import toBeStoredSurgeriesMinified from './to-be-stored-surgeries-minified';
import payout from './payout';
import product from './product';
import result from './result';
import role from './role';
import refund from './refund';
import refundReason from './refund-reason';
import treatment from './treatment';
import treatmentTab from './treatment-tab';
import surgeries from './surgeries';
import user from './user';
import minified from './minified';
import deposit from './deposit';
import surgery from './surgery';
import surgeriesTab from './surgery-tab';
import surgeriesCode from './surgery-code';
import surgeriesUpdate from './surgery-update';
import skinSurgeriesUpdate from './skin-surgery-update';
import appointmentSurgeryWaiting from './appointment-surgery-waiting';
import addCategory from './add-category';
import memoBoilerplateSelector from './memo-boilerplate-selector';
import messageCallerNumber from './message-caller-number';
import messageBoilerplate from './message-boilerplate';
import messageBoilerplateSelector from './message-boilerplate-selector';
import messageAutoNotification from './message-auto-notification';
import messageTarget from './message-target';
import messageCustomerTarget from './message-customer-target';
import messagePageTarget from './message-page-target';
import messageTargetCondition from './message-target-condition';
import messageTargetSearchHistory from './message-target-search-history';
import messagePrice from './message-price';
import messageHistoryEventResult from './message-history-event-result';
import messageHistoryEventWaiting from './message-history-event-waiting';
import messageHistoryEventDetail from './message-history-event-detail';
import messageHistorySms from './message-history-sms';
import messageHistorySmsDetail from './message-history-sms-detail';
import messageAppointmentSmsDetail from './message-appointment-sms-detail';
import messageMap from './message-map';

import medicine from './medicine';
import prescriptionBundleList from './prescription-bundle-list';
import prescriptionList from './prescription-list';

import marketingSms from './marketing-sms';
import normalSms from './normal-sms';
import balanceRequest from './balance-request';
import balanceHistories from './balance-histories';
import organizationsList from './organizations-list';
import organizationsStaff from './organizations-staff';
import frequentlyAuthority from './frequently-authority';
import salesStatisticsDaily from './sales-statistics-daily';
import salesStatisticsWeekly from './sales-statistics-weekly';
import salesStatisticsMonthly from './sales-statistics-monthly';

import counselorStatistics from './counselor-statistics';
import doctorStatistics from './doctor-statistics';
import customerStatistics from './customer-statistics';
import rankStatistics from './rank-statistics';
import surgeryCategoryStatistics from './surgery-category-statistics';
import surgeryItemStatistics from './surgery-item-statistics';
import regionStatistics from './region-statistics';

import dailySettlements from './daily-settlements';
import dailySettlementsDetail from './daily-settlements-detail';
import monthlySettlements from './monthly-settlements';
import monthlySettlementsDetail from './monthly-settlements-detail';

import nursevital from './nurse-vital';
import injectItems from './nurse-inject-items';
import opnurseChart from './operation-nurse-tab';
import skin from './skin-tab';

import operations from './operations';
import nurses from './nurses';
import skins from './skin';

import appointmentAutoSms from './appointment-auto-sms';
import appointmentUpdateAutoSms from './appointment-update-auto-sms';

export default {
  crm: {
    acquisitionChannel,
    allMemo,
    appointment,
    appointmentTab,
    appointmentConsultingRequests,
    appointmentSurgery,
    appointmentSurgeryMinified,
    consulting,
    consultingTab,
    consultingRequests,
    consultingCounselors,
    consultingAutoAssign,
    responseConsultings,
    responseRequests,
    responseHistories,
    consultingRequestsCategories,
    consultingRequestsMediaConnects,
    consultingRequestsNeedRecall,
    mediaConnects,
    consultingEvent,
    counselor,
    customer,
    complaint,
    departmentCategory,
    discountReason,
    job,
    level,
    region,
    doctor,
    paymentProduct,
    paymentTreatment,
    payment,
    paymentTab,
    paymentSubDetail,
    paymentDetail,
    paymentTreatmentItems,
    paymentProductItems,
    paymentRefundsSurgeries,
    paymentRefundsProducts,
    paymentInputSurgeries,
    paymentUpdateSurgeries,
    paymentInputProducts,
    toBeStoredSurgeries,
    toBeStoredSurgeriesMinified,
    payout,
    product,
    result,
    role,
    refund,
    refundReason,
    treatment,
    treatmentTab,
    surgeries,
    surgeriesTab,
    surgeriesCode,
    surgeriesUpdate,
    skinSurgeriesUpdate,
    appointmentSurgeryWaiting,
    user,
    deposit,
    surgery,
    addCategory,

    memoBoilerplateSelector,

    messagePrice,
    messageHistoryEventResult,
    messageHistoryEventWaiting,
    messageHistoryEventDetail,
    messageHistorySms,
    messageHistorySmsDetail,
    messageMap,

    messageCallerNumber,
    messageBoilerplate,
    messageBoilerplateSelector,
    messageAutoNotification,
    messageTarget,
    messageCustomerTarget,
    messagePageTarget,
    messageTargetCondition,
    messageTargetSearchHistory,
    messageAppointmentSmsDetail,

    medicine,
    prescriptionBundleList,
    prescriptionList,

    marketingSms,
    normalSms,
    balanceRequest,
    balanceHistories,
    organizationsList,
    organizationsStaff,
    frequentlyAuthority,
    salesStatisticsDaily,
    salesStatisticsWeekly,
    salesStatisticsMonthly,

    counselorStatistics,
    doctorStatistics,
    customerStatistics,
    rankStatistics,
    surgeryCategoryStatistics,
    surgeryItemStatistics,
    regionStatistics,

    dailySettlements,
    dailySettlementsDetail,
    monthlySettlements,
    monthlySettlementsDetail,

    nursevital,
    injectItems,
    opnurseChart,
    skin,

    operations,
    nurses,
    skins,

    appointmentAutoSms,
    appointmentUpdateAutoSms,
  },
  minified,
};
