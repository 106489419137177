import React, { useCallback, useContext } from 'react';
import { observer } from 'mobx-react';
import { translate } from 'filters';
import { AppointmentBoardStateContext } from './AppointmentBoards';

const CountPanel = observer(() => {
  /**
   * @type {AppointmentBoardState}
   */
  const state = useContext(AppointmentBoardStateContext);

  const onClickShowCancel = useCallback(() => {
    state.setShowCanceled(!state.showCanceled);
  }, []);

  const counts =
    state.calendarUnit === 'month' ? state.monthlyCount : state.count;

  return (
    <>
      <div className="stats">
        <span className="ti">
          <span>{translate('APPOINTMENT')}</span> {counts.total}
        </span>
        <span className="ti">
          <span>{translate('FIRST_VISIT')}</span> {counts.first}
        </span>
        <span className="ti">
          <span>{translate('RE_VISIT')}</span> {counts.second}
        </span>
        <span className="ti canceled" onClick={onClickShowCancel}>
          <span>{translate('CANCEL')}</span> {counts.canceled}
        </span>
      </div>
    </>
  );
});

export default CountPanel;
