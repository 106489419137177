import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import models from 'models';
import { observer } from 'mobx-react';
import { translate } from 'filters';
import { useModal } from 'hooks/useModal';
import { useServices } from 'hooks/useServices';
import { useToast } from 'hooks/useToast';
import DataTableAlpha from 'components/data-table/DataTableAlpha';

const ModalAutoAssign = ({ close }) => {
  const modal = useModal();
  const services = useServices();
  const toast = useToast();
  const [counselors, setCounselors] = useState([]);
  const [autoAssignResp, setAutoAssignResp] = useState([]);
  const [selectRow, setSelectRow] = useState([]);
  const [assignEndHour, setAssignEndHour] = useState('19');
  const [assignEndMinute, setAssignEndMinute] = useState('00');

  const scheduleTimeHour = [...Array(22).keys()].slice(8).map((num) => {
    const parsedValue = String(num).padStart(2, '0');
    return { value: parsedValue, label: parsedValue };
  });
  const scheduleTimeMinute = [...Array(4).keys()].map((num) => {
    const parsedValue = String(num * 15).padStart(2, '0');
    return { value: parsedValue, label: parsedValue };
  });

  const counselorCallApi = useCallback(async () => {
    let autoAssignUsers = await services.crm.consultingRequest.auto_assign.users();
    if (!autoAssignUsers) return;

    let selected = [];
    let users = autoAssignUsers.data.map((user) => {
      if (user.consultingRequestAutoAssignId !== null) {
        user.$$checked = true;
        selected.push(user);
      }
      return user;
    });
    let autoAssign = await services.crm.consultingRequest.auto_assign.all();
    if (!autoAssign) return;

    setAutoAssignResp(autoAssign.data);
    setCounselors([...users]);
    setSelectRow([...selected]);
    if (autoAssign.data.length > 0) {
      //assignEndHour, assignEndMinute setting
      setAssignEndHour(new Date(autoAssign.data[0].assignedEndAt).getHours());
      setAssignEndMinute(
        (new Date(autoAssign.data[0].assignedEndAt).getMinutes() < 10
          ? '0'
          : '') + new Date(autoAssign.data[0].assignedEndAt).getMinutes()
      );
    }
  }, [services.crm.consultingRequest.auto_assign]);

  useEffect(() => {
    counselorCallApi();
  }, [counselorCallApi]);

  const onAllCheckAction = (data) => {
    const list = data.filter((v) => {
      return v;
    });
    setSelectRow(list);
  };

  const onAction = (obj) => {
    const { event, row } = obj;
    const consulting = row;
    const classList = event.target.classList;

    if (classList.contains('zmdi-check')) {
      let list = selectRow.filter((v) => {
        return consulting.id !== v.id;
      });
      setSelectRow(list);
    }
    if (classList.contains('checkbox')) {
      let list = selectRow;
      list.push(consulting);
      setSelectRow(list);
    }

    if (event.target.classList.contains('zmdi-long-arrow-up')) {
      changeArrowUpDown('up', consulting);
    } else if (event.target.classList.contains('zmdi-long-arrow-down')) {
      changeArrowUpDown('down', consulting);
    }
  };

  const changeArrowUpDown = (arrow, consulting) => {
    let findIdx = counselors.findIndex((item) =>
      item.userId === undefined
        ? item.id === consulting.id
        : item.userId === consulting.userId
    );

    if (arrow === 'up') {
      if (findIdx === 0) {
        toast.error('가장 상위 순서입니다');
      } else {
        let swap = counselors[findIdx];
        counselors[findIdx] = counselors[findIdx - 1];
        counselors[findIdx - 1] = swap;
        setCounselors([...counselors]);
      }
    } else if (arrow === 'down') {
      if (findIdx === counselors.length - 1) {
        toast.error('가장 하위 순서입니다');
      } else {
        let swap = counselors[findIdx];
        counselors[findIdx] = counselors[findIdx + 1];
        counselors[findIdx + 1] = swap;
        setCounselors([...counselors]);
      }
    }
  };

  const onClickAutoAssign = useCallback(async () => {
    try {
      let users = counselors
        .map((v, i) => {
          let result = { userName: v.userName, userId: v.userId, order: i + 1 };
          if (v.id !== null) {
            result['id'] = v.id;
          }
          if (v.$$checked) {
            result['$$checked'] = true;
          }
          return result;
        })
        .filter((v) => v.$$checked);

      console.log('assignEndHour', assignEndHour);
      console.log('assignEndMinute', assignEndMinute);
      let payload = {
        assignedEndAt: moment().format(
          `YYYY-MM-DDT${assignEndHour}:${assignEndMinute}:00`
        ),
        users,
      };

      if (autoAssignResp.length > 0) {
        payload['id'] = autoAssignResp[0].id;
      }

      await services.crm.consultingRequest.auto_assign.create(payload);
      modal
        .confirm({
          type: 'SUCCESS',
          msg: '생성되었습니다.',
        })
        .then(() => {
          close();
        });
    } catch (e) {
      toast.error(e.description);
      console.log(e.description);
    }
  }, [
    modal,
    services,
    toast,
    autoAssignResp,
    close,
    assignEndHour,
    assignEndMinute,
    counselors,
  ]);

  const onChangeScheduleTimeHour = (e) => {
    setAssignEndHour(e.target.value);
  };

  const onChangeScheduleTimeMinute = (e) => {
    setAssignEndMinute(e.target.value);
  };

  return (
    <div className="modal-auto-assign">
      <div className="head flex-row flex-between items-start">
        <div className="title">자동 배정하기</div>
        <i onClick={() => close()} className="zmdi zmdi-close" />
      </div>
      <div className="auto-assigin m-t-8">
        <div className="flex-row items-center m-t-16">
          <span className="flex-wrap m-r-8">금일</span>
          <div className="auto-assigin-end-at flex-wrap m-r-8">
            <select onChange={onChangeScheduleTimeHour} value={assignEndHour}>
              {scheduleTimeHour.map((v, i) => (
                <option key={i}>{v.value}</option>
              ))}
            </select>
            &nbsp;&nbsp;:&nbsp;&nbsp;
            <select
              onChange={onChangeScheduleTimeMinute}
              value={assignEndMinute}
            >
              {scheduleTimeMinute.map((v, i) => (
                <option key={i}>{v.value}</option>
              ))}
            </select>
          </div>
          <span className="flex-wrap m-r-16">까지</span>
        </div>
        <div className="m-t-8 m-b-8">
          배정을 진행할 담당자들을 선택해주세요.
        </div>
        <DataTableAlpha
          model={models.crm.consultingAutoAssign}
          data={counselors}
          onAction={onAction}
          hideBottom={true}
          onAllCheckAction={onAllCheckAction}
        />
      </div>
      <div className="buttons">
        <div className="flex-row">
          <button className="btn btn-default" onClick={() => close()}>
            {translate('CANCEL')}
          </button>
          <button className="btn btn-primary" onClick={onClickAutoAssign}>
            등록
          </button>
        </div>
      </div>
    </div>
  );
};

ModalAutoAssign.propTypes = {
  close: PropTypes.func,
};

export default observer(ModalAutoAssign);
