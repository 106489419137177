import React, { useState, useEffect, useCallback } from 'react';
import { observer } from 'mobx-react';
import { countByteByString, emojisContainsCheck, translate } from 'filters';
import ScreenAutoNotification from 'components/app/MessageScreen/ScreenAutoNotification';
import PropTypes from 'prop-types';
import { useModal } from 'hooks/useModal';
import { useServices } from 'hooks/useServices';
import { useToast } from 'hooks/useToast';

const ModalMessageAutoNotification = ({ options, close }) => {
  const modal = useModal();
  const services = useServices();
  const toast = useToast();
  const [loadingBtnDisabledFlag, setLoadingBtnDisabledFlag] = useState(false);
  const [initObj] = useState({
    images: [],
    imageFiles: [],
    // id: null,
    title: '',
    contents: '',
    // visible: true,
    // createdId: null,
    // organizationId: null,
    // callerNumber: { },
    smsSituation: null,
    smsScheduleType: 'immediate',
    scheduleDaysDelta: 1,
    scheduleDaysBefore: true,
    scheduleTime: '08:00',
    departmentExtendAll: false,
  });

  const [obj, setObj] = useState({
    ...initObj,
    ...(options.autoNotification && options.autoNotification),
  });

  const [callerNumberList, setCallerNumberList] = useState();

  const validator = useCallback(() => {
    if (!obj.callerNumber || !obj.callerNumber.id) {
      const errorString = translate('ERROR_MISSING_FIELD').replace(
        /%s/,
        translate('PLACEHOLDER_CALLER_NUMBER')
      );
      toast.error(errorString);
      return false;
    }

    if (obj['contents'] === '') {
      const errorString = translate('ERROR_EMPTY_MESSAGE_CONTENTS');
      toast.error(errorString);
      return false;
    }

    if (!obj.smsSituation || obj['smsSituation'] === '') {
      const errorString = translate('PLACEHOLDER_SMS_SITUATION');
      toast.error(errorString);
      return false;
    }

    if (countByteByString(obj['title']) > 40) {
      const errorString = translate('ERROR_EXCEEDED_MESSAGE_TITLE');
      toast.error(errorString);
      return false;
    }

    if (countByteByString(obj['contents']) > 2000) {
      const errorString = translate('ERROR_EXCEEDED_MESSAGE_CONTENTS');
      toast.error(errorString);
      return false;
    }

    if (
      emojisContainsCheck((obj || {}).title) === true ||
      emojisContainsCheck((obj || {}).contents) === true
    ) {
      //문자 자동전송 추가 시 제목, 내용에 특수 이모티콘(이모지) 포함되어있는지 체크
      toast.error('문자 제목 또는 내용에 특수 이모티콘을 제거해주세요');
      return false;
    }

    return true;
  }, [obj]);

  const onClickSave = useCallback(async () => {
    if (validator()) {
      setLoadingBtnDisabledFlag(true);

      let payload = obj;
      if (obj.treatmentItemId === null && obj.treatmentItemName === null) {
        delete payload.treatmentItemId;
        delete payload.treatmentItemName;
      }

      if (obj.departmentId === null || obj.smsSituation === 'register') {
        //21.03.25 백엔드상 departmentId 가 int형이라, 부서 해제 시 null 값을 보내면 오류 발생하여
        //null 인 경우 임의로 -1로 변경하여 리퀘스트를 보냄
        payload.departmentId = -1;
      }

      if (obj.departmentExtendAll === null || obj.departmentId === -1) {
        // 1.기존 저장된 값이 null 인 경우, 해당 항목을 삭제하고 보냄
        // 2.부서 선택이 전체인 경우 해당 항목 삭제하고 보냄, 부서선택시 전체문자 발송 여부이기 때문
        // > 백엔드에서 departmentExtendAll : false로 저장
        delete payload.departmentExtendAll;
      }

      if (obj.reserveCount === null) {
        //시수술 주기별 문자발송에서 사용하는 항목
        delete payload.reserveCount;
      }

      try {
        if (obj.id && obj.id !== null) {
          const resp = await services.crm.notification.smsAutoNotifications.update(
            payload
          );
          if (resp) {
            modal
              .confirm({
                type: 'SUCCESS',
                msg: '업데이트되었습니다.',
              })
              .then(() => {
                close(1);
                setLoadingBtnDisabledFlag(false);
              });
          }
        } else {
          if (payload.smsSituation === 'register') {
            //등록 시 고객등록완료인 경우 departmentId 삭제
            delete payload.departmentId;
          }
          const resp = await services.crm.notification.smsAutoNotifications.create(
            payload
          );
          if (resp) {
            modal
              .confirm({
                type: 'SUCCESS',
                msg: '생성되었습니다.',
              })
              .then(() => {
                close(1);
                setLoadingBtnDisabledFlag(false);
              });
          }
        }
      } catch (e) {
        setLoadingBtnDisabledFlag(false);
        if (e.code === 400 && e.description === 'InvalidSmsSituation') {
          toast.error(
            '고객등록완료 상황시엔 예약일, 예약시간 변수를 사용할 수 없습니다.'
          );
          return;
        }
        if (e.description === 'invalidString') {
          toast.error('문자 제목 또는 내용에 특수 이모티콘을 제거해주세요');
          return;
        }
        if (e.description === 'DepartmentNotFound') {
          toast.error('삭제 혹은 미사용처리된 부서입니다.');
          return;
        }
        toast.error(e.description);
        console.log('catch: e', e);
      }
    }
  }, [close, obj, validator]);

  const getCallerNumberList = useCallback(async () => {
    try {
      const resp = await services.crm.notification.callerNumbers.all();
      if (resp && resp.data) {
        setCallerNumberList(resp.data);
        return;
      }
      setCallerNumberList([]);
    } catch (e) {
      setCallerNumberList([]);
    }
  }, []);

  const onChangeHandler = useCallback(
    (e) => {
      setObj({
        ...obj,
        [e.target.name]: e.target.value,
      });
    },
    [obj]
  );

  useEffect(() => {
    getCallerNumberList();
  }, [getCallerNumberList]);

  return (
    <div className="modal-message-screen auto-notification ">
      <div className="head flex-row flex-between items-start">
        <div className="title">{translate('EDIT_SMS_AUTO_NOTIFICATION')}</div>
        <i onClick={() => close()} className="zmdi zmdi-close" />
      </div>
      <div className="body">
        <div className="row">
          <ScreenAutoNotification
            obj={obj}
            setObj={setObj}
            onChange={onChangeHandler}
            callerNumberList={callerNumberList}
          />
        </div>
      </div>

      <div className="buttons">
        <div className="flex-row">
          <button onClick={close} className="btn btn-default">
            {translate('CANCEL')}
          </button>
          <button
            disabled={loadingBtnDisabledFlag}
            onClick={(e) => {
              onClickSave(e);
            }}
            className="btn btn-primary"
          >
            {translate('SAVE')}
          </button>
        </div>
      </div>
    </div>
  );
};

ModalMessageAutoNotification.propTypes = {
  options: PropTypes.object,
  close: PropTypes.func,
};

export default observer(ModalMessageAutoNotification);
