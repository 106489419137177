import React, { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { copy, pluralize, $case, translate } from 'filters';
import Checkbox from 'components/app/Checkbox';
import DataTableAlpha from 'components/data-table/DataTableAlpha';
import helpers from 'hooks/helpers';
import PropTypes from 'prop-types';
import models from 'models';
import { $qb } from 'scripts/querybuilder';
import { useEvent } from 'hooks/useEvent';
import { useModal } from 'hooks/useModal';
import { useToast } from 'hooks/useToast';

const CommonForm = ({
  api,
  model,
  modelName,
  overrideOnAction,
  overrideCreate,
}) => {
  const eventBus = useEvent();
  const modal = useModal();
  const toast = useToast();
  const defaultParams = $qb().limit(20).orderBy('id desc');
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [params, setParams] = useState(defaultParams);
  const [hideHidden, setHideHidden] = useState(false);

  //v2 적용된 공통코드 props modelName과 동일하게 배열에 추가
  // consultings/result
  const v2Api = ['result'];

  const callApi = useCallback(async () => {
    let params = { limit: 300 };
    const resp =
      v2Api.indexOf(modelName) > -1
        ? await api.all_v2(params)
        : await api.all(params);

    if (!resp) {
      console.error(`loader not defined for the model "${modelName}"`);
      return;
    }

    setData(resp.data);
    setTotal(resp.total);
  }, [api, modelName]);

  const update = async (object) => {
    try {
      v2Api.indexOf(modelName) > -1
        ? await api.update_v2(object)
        : await api.update(object);

      modal
        .confirm({
          type: 'SUCCESS',
          msg: '업데이트되었습니다.',
        })
        .then(() => {
          callApi();
        });
    } catch (e) {
      console.log(e.description);
      let errorMessage = e.description;
      if (e.name === 'Conflict') {
        errorMessage = translate('ERROR_CONFLICT_CODE');
      }
      modal
        .confirm({
          type: 'ERROR',
          msg: errorMessage,
        })
        .then(() => {});
    }
  };

  const onParams = (p) => {
    setParams(p);
    callApi();
  };

  const onAction = (obj) => {
    if (overrideOnAction) {
      overrideOnAction(obj);
      return;
    }

    let model = obj.key;
    let object = obj.row;
    let event = obj.event;

    if (['name', 'content', 'number'].indexOf(model.column) !== -1) {
      object[model.column] = event.target.value;
    }

    const btnLabel = event.target.innerText;
    if (btnLabel === '수정') {
      object.$$editing = true;
      return setData(copy(data));
    }

    if (btnLabel === '저장') {
      object.$$editing = false;
      update(object);
      return setData(copy(data));
    }

    if (['사용', '미사용'].indexOf(btnLabel) !== -1) {
      return helpers.toggleVisibility({
        obj: object,
        endpoint: api,
        callback: callApi,
        modelName,
      });
    }

    if (model['title'] === 'ACTION' && event.target.dataset.type === 'delete') {
      modal
        .basic({
          body: `정말로 삭제하시겠습니까?`,
          buttons: [
            {
              text: 'CANCEL',
              class: 'btn-default',
            },
            {
              text: 'CONFIRM',
              class: 'btn-primary',
            },
          ],
        })
        .then((idx) => {
          idx === 1 && onClickDelete(object);
        });
    }
  };

  const onClickDelete = useCallback(
    async (payload) => {
      try {
        v2Api.indexOf(modelName) > -1
          ? await api.delete_v2(payload.id)
          : await api.delete(payload.id);

        modal
          .confirm({
            type: 'SUCCESS',
            msg: '삭제하였습니다.',
          })
          .then(() => {
            callApi();
          });
      } catch (e) {
        console.log(e.description);
        let errorMessage = e.description;
        modal
          .confirm({
            type: 'ERROR',
            msg: errorMessage,
          })
          .then(() => {});
      }
    },
    [api, callApi]
  );

  useEffect(() => {
    eventBus.on('callApi', callApi);
    callApi();

    return () => eventBus.off('callApi');
  }, [callApi]);

  const create = async () => {
    if (overrideCreate) {
      overrideCreate();
      return;
    }

    const onConfirm = async (value) => {
      const payload = {};
      payload[models.crm[modelName].keys[1].column] = value;

      try {
        v2Api.indexOf(modelName) > -1
          ? await api.create_v2(payload)
          : await api.create(payload);

        modal
          .confirm({
            type: 'SUCCESS',
            msg: '생성되었습니다.',
          })
          .then(() => {
            callApi();
          });
      } catch (e) {
        console.log(e.description);
        let errorMessage = e.description;
        modal
          .confirm({
            type: 'ERROR',
            msg: errorMessage,
          })
          .then(() => {});
      }
    };

    const key = translate($case.toConst(pluralize(modelName)));
    modal
      .input({
        title: `${translate('ADD')} (${key})`,
        inputs: [
          {
            placeholder: key,
          },
        ],
      })
      .then((inputs) => {
        if (!inputs || inputs.length === 0) return;

        if (!inputs[0].text) {
          toast.error('ERROR_EMPTY_VALUE');
          return;
        }

        onConfirm(inputs[0].text);
      });
  };

  return (
    <div className={`${$case.toSnake(pluralize(modelName), '-')} cust-box`}>
      <div className="route-top">
        <div className="title">
          {translate($case.toConst(pluralize(modelName)))}
        </div>
        <div className={`flex-row items-center`}>
          <div className="flex-row items-center m-r-12">
            <Checkbox
              className="flex-wrap m-r-8"
              checked={hideHidden}
              toggleHandler={() => setHideHidden(!hideHidden)}
            />
            <span>미사용항목 보지않기</span>
          </div>
          <button
            onClick={() => create()}
            className="btn btn-primary btn-sm flex-wrap btn-add"
          >
            {translate('ADD')}
          </button>
        </div>
      </div>
      <DataTableAlpha
        model={model}
        data={hideHidden ? data.filter((object) => object.visible) : data}
        total={total}
        params={params}
        onParams={onParams}
        onAction={onAction}
        hideBottom={true}
        hasDeactivate
      />
    </div>
  );
};

CommonForm.propTypes = {
  api: PropTypes.object,
  model: PropTypes.object,
  modelName: PropTypes.string,
  overrideOnAction: PropTypes.func,
  overrideCreate: PropTypes.func,
};

export default observer(CommonForm);
