import React, { useEffect, useCallback, useState, useRef } from 'react';
import { observer } from 'mobx-react';
import DataTableAlpha from 'components/data-table/DataTableAlpha';
import PropTypes from 'prop-types';
import modelAllMemo from 'models/all-memo';
import MultiSelect from 'react-multi-select-component';
import { $qb } from 'scripts/querybuilder';
import { useServices } from 'hooks/useServices';
import { useToast } from 'hooks/useToast';

const ModalAllMemo = ({ customerId, close }) => {
  const services = useServices();
  const toast = useToast();
  const defaultParams = $qb().limit(50).orderBy('scheduledAt desc');
  const memoViewer = useRef(null);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [params, setParams] = useState(defaultParams);

  const [isDrag, setIsDrag] = useState(false);
  const [relPosition, setRelPosition] = useState({ x: null, y: null });
  const [position, setPosition] = useState({ x: null, y: null });

  // 카테고리 선택
  const [types, setTypes] = useState([]);
  const [typeSelectedItems, setTypeSelectedItems] = useState([]);
  // 작성자 선택 (직원관리의 모든 유저)
  const [creators, setCreators] = useState([]);
  const [creatorSelectedItems, setCreatorSelectedItems] = useState([]);

  // MultiSelect 포커스 아웃 동작 실행을 위한 플래그
  const [dropDownClearFlag, setDropDownClearFlag] = useState(true);

  const creatorCallApi = useCallback(async () => {
    let params = { userStatus: 'active', limit: 300 };
    const resp = await services.crm.user.duty(params);
    if (!resp) return;
    const creatorList = resp.data.map((v) => {
      return { label: v.name, value: v.id };
    });
    setCreators(creatorList);
  }, []);

  useEffect(() => {
    creatorCallApi();
  }, [creatorCallApi]);

  useEffect(() => {
    const typeList = [
      { label: '예약', value: 'appointment' },
      { label: '상담', value: 'consulting' },
      { label: '상담문의', value: 'consult_request' },
      { label: '수납', value: 'payment' },
      { label: '시수술', value: 'surgery' },
      { label: '진료', value: 'treatment' },
      { label: '수술간호', value: 'nurse_operation' },
      { label: '간호', value: 'nurse_care' },
      { label: '피부관리', value: 'nurse_skin' },
    ];

    setTypes(typeList);
  }, []);

  const callApi = useCallback(async () => {
    try {
      if (!customerId) return;
      const allMemoParams = { ...params.build(), customerId: customerId };

      if (typeSelectedItems.length > 0) {
        allMemoParams.types = typeSelectedItems.map((v) => v.value).join(',');
      }

      if (creatorSelectedItems.length > 0) {
        allMemoParams.createdIds = creatorSelectedItems
          .map((v) => v.value)
          .join(',');
      }

      const resp = await services.crm.customer.all_memo_v2(allMemoParams);
      setData(resp.data);
      setTotal(resp.total);
    } catch (e) {
      toast.error(e.description);
    }
  }, [params, customerId, typeSelectedItems, creatorSelectedItems]);

  useEffect(() => {
    callApi();
  }, []);

  const onParams = (p) => {
    setParams(p);
    callApi();
  };

  const onChangeSelect = (type, selected) => {
    if (type === 'types') {
      setTypeSelectedItems(selected);
    } else if (type === 'creators') {
      setCreatorSelectedItems(selected);
    }
    setDropDownClearFlag(false);
  };

  return (
    <div
      className="all-memo-viewer"
      ref={memoViewer}
      style={{
        top:
          position.y ||
          document.querySelector('.modal-container').offsetTop - 30,
        right: position.x
          ? 'auto'
          : document.querySelector('.modal-container').offsetLeft + 200,
        left: position.x,
        height: document.querySelector('.modal-container').clientHeight,
      }}
      onMouseDown={(e) => {
        if (!dropDownClearFlag) {
          // e.preventDefault(); 실행에 의해 태그 고유의 동작이 막히게 된다.
          // 따라서 MultiSelect 포커스 아웃시 닫혀야 하는데, 닫히지 않게 된다.
          // MultiSelect onChange 시 플래그를 두어 포커스 아웃시 닫히게 한다.
          setDropDownClearFlag(true);
          return;
        }

        if (e.target.tagName === 'BUTTON') {
          //버튼 선택 혹은 멀티 셀렉트박스 선택 시 드래그 이동되지 않도록 리턴시킴
          return;
        }

        let rect = memoViewer.current.getBoundingClientRect();
        setRelPosition({
          x: e.nativeEvent.pageX - rect.left,
          y: e.nativeEvent.pageY - rect.top,
        });
        setIsDrag(true);
        e.stopPropagation();
        e.preventDefault();
      }}
      onMouseUp={(e) => {
        setIsDrag(false);
        e.stopPropagation();
        e.preventDefault();
      }}
      onMouseMove={(e) => {
        if (!isDrag) return;
        isDrag &&
          setPosition({
            x: e.nativeEvent.pageX - relPosition.x,
            y: e.nativeEvent.pageY - relPosition.y,
          });
        e.stopPropagation();
        e.preventDefault();
      }}
    >
      <div>
        <div className="head flex-row">
          <div>
            <div className="title">모든메모 모아보기</div>
            <i onClick={() => close()} className="zmdi zmdi-close" />
          </div>
          <div className="search-form">
            <MultiSelect
              className="multi-select m-r-16"
              options={types}
              value={typeSelectedItems}
              onChange={(selected) => {
                onChangeSelect('types', selected);
              }}
              labelledBy={'Select'}
              disableSearch
              selectAllLabel={'전체선택'}
              overrideStrings={{
                selectSomeItems: '카테고리 선택',
                allItemsAreSelected: '전체선택',
              }}
            />
            <MultiSelect
              className="multi-select"
              options={creators}
              value={creatorSelectedItems}
              onChange={(selected) => {
                onChangeSelect('creators', selected);
              }}
              labelledBy={'Select'}
              disableSearch
              selectAllLabel={'전체선택'}
              overrideStrings={{
                selectSomeItems: '작성자',
                allItemsAreSelected: '전체선택',
              }}
            />
            <button className="btn btn-basic _small" onClick={() => callApi()}>
              조회
            </button>
          </div>
        </div>

        <div className="body hidden-scroll">
          <DataTableAlpha
            model={modelAllMemo}
            total={total}
            data={data}
            params={{ ...params }}
            onParams={onParams}
            hideLimitSetter
          />
        </div>
      </div>
    </div>
  );
};

ModalAllMemo.propTypes = {
  customerId: PropTypes.number,
  close: PropTypes.func,
};

export default observer(ModalAllMemo);
