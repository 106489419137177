import React, { useContext } from 'react';
//import { AppointmentBoardState } from 'store/appointmentBoardState';
import { AppointmentBoardStateContext } from './AppointmentBoards';
import { toDayName } from 'filters';
import * as moment from 'moment';
import { observer } from 'mobx-react';
import { useHoliday } from 'store/holiday';

const DateRow = observer(() => {
  /**
   * @type {AppointmentBoardState }
   */
  const state = useContext(AppointmentBoardStateContext);
  const holiday = useHoliday();

  return (
    <div className="calendar-dates-row">
      {state.datesBetween.map((date) => (
        <div
          key={date}
          style={{
            width:
              state.mode === 'vertical'
                ? state.getDateViewSize(date)
                : undefined,
            height:
              state.mode === 'horizontal'
                ? state.getDateViewSize(date)
                : undefined,
            borderRight: state.mode === 'vertical' && '3px solid #d7e3f1',
            borderBottom: state.mode === 'horizontal' && '3px solid #d7e3f1',
          }}
          className={`day-cell day-${moment(date).day()}${
            holiday.data.some(
              (v) => v.locdate === moment(date).format('YYYY-MM-DD')
            )
              ? ' holiday'
              : ''
          }`}
        >
          <div className="day-name">{toDayName(date)}</div>
          <div
            className={`time ${
              date === moment().format('YYYY-MM-DD') ? 'today' : ''
            }`}
          >
            {moment(date).format('DD')}
          </div>
        </div>
      ))}
    </div>
  );
});

export default DateRow;
