import React, { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import DataTableAlpha from 'components/data-table/DataTableAlpha';
import ModalMessageDetail from 'components/modals/codes/message-codes/ModalMessageDetail';
import PropTypes from 'prop-types';
import models from 'models';
import { $qb } from 'scripts/querybuilder';
import { useModal } from 'hooks/useModal';
import { useServices } from 'hooks/useServices';
import { useToast } from 'hooks/useToast';

const ModalMessageSendAppointmentsDetail = ({ options, close }) => {
  const modal = useModal();
  const services = useServices();
  const toast = useToast();
  const defaultParams = $qb().limit(10);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(null);
  const [params, setParams] = useState(defaultParams);
  const [loading, setLoading] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [dataObj, setDataObj] = useState({
    customerName: null,
    phoneNumber: null,
    status: null,
  });

  const callApi = useCallback(async () => {
    try {
      setLoading(true);
      let requestParams = {
        ...params.build(),
        ...dataObj,
        ...options,
      };

      const resp = await services.crm.notification.smsNotifications.ad.detail(
        requestParams
      );
      if (!resp) return;
      const changeData = resp.data.map((v, i) => {
        let page = params.queryParams.page || 1;
        let limit = params.queryParams.limit;
        v.no = resp.total - ((page - 1) * limit + i);
        v.mini_contents = v.contents;
        return v;
      });
      setData(changeData);
      setTotal(resp.total);
      setLoading(false);
    } catch (error) {
      toast.error(error.description);
      setData([]);
      setTotal(0);
      setLoading(false);
    }
  }, [
    services.crm.notification.smsNotifications.ad,
    toast,
    dataObj,
    params,
    options,
  ]);

  useEffect(() => {
    callApi();
  }, [callApi, options]);

  const onParams = (p) => {
    setParams(p);
    callApi();
  };

  const openModalBoilerplate = useCallback(
    ({ row }) => {
      modal.custom({
        component: ModalMessageDetail,
        options: { boilerplateMessage: row },
      });
    },
    [modal, callApi]
  );

  const onAction = (obj) => {
    const { key } = obj; // , rowIdx

    if (key.title === 'TITLE_AND_CONTENT') {
      openModalBoilerplate(obj);
    }
  };

  /*const onChangeHandler = (e) => {
    const { name, value } = e.target;

    if (!value || value === null || value === '') {
      setDataObj((dataObj) => ({
        ...dataObj,
        [name]: null,
      }));
    } else {
      setDataObj((dataObj) => ({
        ...dataObj,
        [name]: value,
      }));
    }
  };*/

  return (
    <div className="modal-message-appointment-details">
      <div className="head flex-row flex-between items-start">
        <div className="title">상세리스트</div>
        <i onClick={() => close()} className="zmdi zmdi-close" />
      </div>
      <div className="body">
        <DataTableAlpha
          className="header-style-classic"
          model={models.crm.messageAppointmentSmsDetail}
          data={data}
          total={total}
          params={params}
          onParams={onParams}
          loading={loading}
          onAction={onAction}
        />
      </div>
      <div className="buttons">
        <div className="flex-row">
          <button onClick={() => close()} className="btn btn-primary">
            확인
          </button>
        </div>
      </div>
    </div>
  );
};

ModalMessageSendAppointmentsDetail.propTypes = {
  options: PropTypes.object,
  close: PropTypes.func,
};

export default observer(ModalMessageSendAppointmentsDetail);
