import moment from 'moment';

export default {
  keys: [
    {
      title: 'ACTION',
      filter: () =>
        `<button class="btn btn-sm btn-primary btn-connects m-r-8">연동해제</button>`,
    },
    {
      title: '연동일',
      noorder: true,
      filter: (o) =>
        o.createdAt !== null ? moment(o.createdAt).format('YYYY-MM-DD') : '',
    },
    {
      title: '연동된 매체',
      noorder: true,
      filter: (o) => o.media?.tag,
    },
    {
      title: '연동 ID',
      noorder: true,
      column: 'clinicAccountId',
    },
  ],
};
