import * as $http from 'axios';

const injectitem = {};

const models = ['operations', 'cares'];

models.forEach((modelName) => {
  injectitem[modelName] = {
    all: (params) => $http.get(`nurse/${modelName}/injectitems`, { params }),
    delete: (model) => $http.delete(`nurse/${modelName}/injectitems/${model}`),
    update: (model) => $http.put(`nurse/${modelName}/injectitems/multi`, model),
    visible: (model) =>
      $http.put(`nurse/${modelName}/injectitems/${model.id}`, model),
    all_v2: (params) =>
      $http.get(`nurse/v2/${modelName}/injectitems`, { params }),
    delete_v2: (model) =>
      $http.delete(`nurse/v2/${modelName}/injectitems/${model}`),
    update_v2: (model) =>
      $http.put(`nurse/v2/${modelName}/injectitems/multi`, model),
    visible_v2: (model) =>
      $http.put(`nurse/v2/${modelName}/injectitems/${model.id}`, model),
  };
});

export default injectitem;
