import React from 'react';
import { observer } from 'mobx-react';
import CommonForm from '../CommonForm';
import modelConsultingResult from 'models/result';
import { useServices } from 'hooks/useServices';

const ConsultingCodes = () => {
  const services = useServices();
  return (
    <div className="consulting-codes">
      <div className="page-navi">
        <span className="title">환경설정</span>
        <span className="title">상담코드 설정</span>
      </div>
      <CommonForm
        api={services.crm.consulting.result}
        model={modelConsultingResult}
        modelName="result"
      />
    </div>
  );
};

export default observer(ConsultingCodes);
