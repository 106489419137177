import React, { useState } from 'react';
import { observer } from 'mobx-react';
import MonthlySettlementsList from './MonthlySettlementsList';
import DatePicker from 'components/common/DatePicker';

const MonthlySettlements = () => {
  const [startAt, setStartAt] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1)
  );
  const [endAt, setEndAt] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
  );
  const [searchValue, setSearchValue] = useState({
    startAt: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    endAt: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
  });

  const onChangeStartAt = (date) => {
    setSearchValue({
      ...searchValue,
      startAt: new Date(date.getFullYear(), date.getMonth(), 1),
    });
  };

  const onChangeEndAt = (date) => {
    setSearchValue({
      ...searchValue,
      endAt: new Date(date.getFullYear(), date.getMonth() + 1, 0),
    });
  };

  const onChangeMonthlyAt = (date) => {
    let startDate = new Date(date.getFullYear(), date.getMonth(), 1);
    let endDate = new Date(date.getFullYear(), date.getMonth(), 1);
    setSearchValue({
      startAt: startDate,
      endAt: endDate,
    });
    setStartAt(startDate);
    setEndAt(endDate);
  };

  const onClickSearch = () => {
    setStartAt(searchValue.startAt);
    setEndAt(searchValue.endAt);
  };

  return (
    <div className={`settlements list`}>
      <div className="page-navi">
        <span className="title">결산</span>
        <span className="title">월간 결산</span>
      </div>
      <div className="search-inputs">
        <div className="search-item">
          <button
            className={`btn btn-normal _small`}
            onClick={() => onChangeMonthlyAt(new Date())}
          >
            이번 달
          </button>
        </div>
        <div className="search-item">
          <button
            className={`btn btn-normal _small`}
            onClick={() =>
              onChangeMonthlyAt(
                new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1)
              )
            }
          >
            지난 달
          </button>
        </div>
        <div className="search-item">
          <DatePicker
            className="text-center"
            placeholderText="날짜선택"
            todayButton="오늘"
            dateFormat="MM/yyyy"
            value={searchValue.startAt}
            onChange={onChangeStartAt}
            showMonthYearPicker
          />
        </div>
        <div className="search-item">-</div>
        <div className="search-item">
          <DatePicker
            className="text-center"
            placeholderText="날짜선택"
            todayButton="오늘"
            dateFormat="MM/yyyy"
            value={searchValue.endAt}
            onChange={onChangeEndAt}
            showMonthYearPicker
          />
        </div>
        <div className="search-item">
          <button
            className="btn btn-primary _small"
            onClick={() => onClickSearch()}
          >
            조회
          </button>
        </div>
      </div>
      <MonthlySettlementsList startAt={startAt} endAt={endAt} />
    </div>
  );
};

export default observer(MonthlySettlements);
