/**
 *
 * @param {Date} d
 */
export function getDayName(d) {
  const names = ['일', '월', '화', '수', '목', '금', '토'];

  const day = d.getDay();

  return names[day];
}

/**
 *
 * @param {Date} d
 */
export function formatTime(d) {
  const hours = d.getHours();

  if (hours >= 12) {
    return `오후 ${appendZero(hours - 12)}:${appendZero(d.getMinutes())}`;
  }

  return `오전 ${appendZero(hours)}:${appendZero(d.getMinutes())}`;
}

export function appendZero(n) {
  if (n < 10) {
    return `0${n}`;
  }

  return `${n}`;
}
