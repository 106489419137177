import React, { useContext, useEffect, useRef } from 'react';
import { observer } from 'mobx-react';
import moment from 'moment';
// eslint-disable-next-line unused-imports/no-unused-imports
import { AppointmentBoardState } from 'store/appointmentBoardState';
import { AppointmentBoardStateContext } from './AppointmentBoards';
import Leftmost from './Leftmost';
import DepartmentsList from './DepartmentsList';
import DateRow from './DateRow';
import CategoryRow from './CategoryRow';
import DepartmentRow from './DepartmentRow';
import { useElementSize } from 'hooks/useElementSize';

const WeeklyBoard = observer(() => {
  /**
   * @type {AppointmentBoardState }
   */
  const state = useContext(AppointmentBoardStateContext);

  const wrapperRef = useRef();
  const [rect, calendarRef] = useElementSize();

  useEffect(() => {
    if (!rect) return;
    state.recalcDepartmentSizeAll();
  }, [rect, state.departmentListRef]);

  useEffect(() => {
    state.weeklyBoardRef = wrapperRef;
    //상위 스크롤 이동
    if (state.scrollState) {
      let scheduleDate = moment(
        state.locationState.appointment.scheduledAt
      ).format('YYYY-MM-DD');
      //부서 인덱스
      let departmentIndex = state.dateCategoryDepartments.findIndex(
        (x) =>
          x.department.name ===
            state.locationState.appointment.department.name &&
          x.date === scheduleDate
      );
      //예약시간 인덱스
      let appointmentTimeIndex = state.calendarTimes.findIndex(
        (x) => x === state.locationState.appointment.startHour
      );

      if (state.mode === 'horizontal') {
        wrapperRef.current.scrollTo(
          appointmentTimeIndex * 35,
          departmentIndex * 50
        );
      } else {
        wrapperRef.current.scrollTo(
          departmentIndex * 50,
          appointmentTimeIndex * 35
        );
      }
      state.scrollState = false;
    }
  }, [state.locationState]);

  function onScroll(e) {
    if (state.departmentListRef) {
      state.departmentListRef.current.scrollTo(
        state.mode === 'vertical' ? e.target.scrollLeft : e.target.scrollTop
      );
    }
  }

  return (
    <>
      <div className="calendar-wrapper" ref={wrapperRef} onScroll={onScroll}>
        <div ref={calendarRef} className="calendar-padded">
          <Leftmost />
          <div className="calendar-board-header-panel">
            <DateRow />
            <CategoryRow />
            <DepartmentRow />
            <DepartmentsList />
          </div>
        </div>
      </div>
    </>
  );
});

export default WeeklyBoard;
