import React from 'react';
import moment from 'moment';
import 'moment/locale/ko';
import { observer } from 'mobx-react';
import {
  convertCodeTranslationToLanguage,
  convertCodeTranslationToLanguageAndLocale,
} from 'filters';
import { CommonPicker } from './InputDatePicker';
import PropTypes from 'prop-types';
import { useTranslation } from 'hooks/useTranslation';

const InputDatePickerRange = ({
  name,
  onChange,
  value,
  dateFormat,
  serverDateFormat = 'YYYY-MM-DD',
  placeholder,
  ...props
}) => {
  const translation = useTranslation();
  const translationCode = translation.locale || false;
  const langCode = convertCodeTranslationToLanguage[translationCode];
  moment.locale(convertCodeTranslationToLanguageAndLocale[translationCode]);

  const [keyObj, ,] = [...Object.keys(name)];
  const [nameStartDate, nameEndDate] = [...name[keyObj]];
  const dateValidator = (value, name, format) =>
    value &&
    Object.prototype.hasOwnProperty.call(value, name) &&
    moment(value[name], format).isValid();

  const startDate = dateValidator(value, nameStartDate, serverDateFormat)
    ? moment(value[nameStartDate], serverDateFormat).toDate()
    : null;
  const endDate = dateValidator(value, nameEndDate, serverDateFormat)
    ? moment(value[nameEndDate], serverDateFormat).toDate()
    : null;

  const onChangeHandler = (e) => {
    const dtName = e.target.name;
    const _dtValue = e.target.value;
    const dtValue = moment(_dtValue).format(serverDateFormat);

    const payload = {
      target: {
        name: keyObj,
        value: {
          ...value,
          [dtName]: dtValue,
        },
      },
    };
    onChange(payload);
  };

  return (
    <div className="uno-datepicker">
      <CommonPicker
        name={nameStartDate}
        maxDate={endDate}
        startDate={startDate}
        value={startDate}
        endDate={endDate}
        langCode={langCode}
        dateFormat={dateFormat}
        translationCode={translationCode}
        onChange={onChangeHandler}
        placeholder={placeholder}
        {...props}
      />
      <div className="range-separator">-</div>
      <CommonPicker
        name={nameEndDate}
        minDate={startDate}
        startDate={startDate}
        value={endDate}
        endDate={endDate}
        langCode={langCode}
        dateFormat={dateFormat}
        translationCode={translationCode}
        onChange={onChangeHandler}
        placeholder={placeholder}
        {...props}
      />
    </div>
  );
};

InputDatePickerRange.propTypes = {
  name: PropTypes.object,
  onChange: PropTypes.func,
  value: PropTypes.object,
  translation: PropTypes.object,
  dateFormat: PropTypes.object,
  serverDateFormat: PropTypes.object,
  placeholder: PropTypes.string,
};

export default observer(InputDatePickerRange);
