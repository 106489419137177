import React, { useContext, useEffect } from 'react';
import { observer } from 'mobx-react';
import { AppointmentBoardStateContext } from './AppointmentBoards';

const AppointmentCellName = observer(({ appointment }) => {
  const state = useContext(AppointmentBoardStateContext);
  let style = {};
  let width = { width: '49px' };
  let startTime = new Date(`2020-01-01 ${appointment.startHour}`);
  let endTime = new Date(`2020-01-01 ${appointment.endHour}`);

  if (state.mode === 'vertical') {
    style = { ...width };
  } else {
    if (state.clinic.appointmentTimeUnit === 10) {
      if (endTime - startTime <= '600000') {
        style = { ...width };
      }
    }
    if (state.clinic.appointmentTimeUnit === 30) {
      if (endTime - startTime <= '1800000') {
        style = { ...width };
      }
    }
    if (state.clinic.appointmentTimeUnit === 60) {
      if (endTime - startTime <= '3600000') {
        style = { ...width };
      }
    }
  }

  useEffect(() => {
    //location state 값이 있는 경우
    let appointment_div = document.getElementById(state.locationState.id);
    if (state.locationState && state.locationState.id === appointment.id) {
      //해당 dom 배경색 스타일 지정
      if (appointment_div) {
        appointment_div.style.backgroundColor = 'Yellow';
      }
    }
  }, []);

  return (
    <div className="customer-name" id={appointment.id} style={style}>
      {appointment.isNewCustomer ? (
        <div className="flex-wrap ico-new"></div>
      ) : (
        ''
      )}
      {appointment.customerName}
    </div>
  );
});

export default AppointmentCellName;
