import * as $http from 'axios';

const memo = {
  all: (params) => $http.get(`/boilerplate/memos`, { params }),
  create: (payload) => $http.post(`/boilerplate/memos`, payload),
  multi_update: (payload) => $http.put(`/boilerplate/memos/multi`, payload),
  update: (id, payload) => $http.put(`/boilerplate/memos/${id}`, payload),
  delete: (id) => $http.delete(`/boilerplate/memos/${id}`),
};

export default { memo };
