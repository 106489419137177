import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import OrganizationsUserInputForm from 'components/pages/crm/organizations/OrganizationsUserInputForm';
import OrganizationsAuthoritySettings from 'components/pages/crm/organizations/OrganizationsAuthoritySettings';
import ModalFrequentlyAuthority from 'components/modals/ModalFrequentlyAuthority';
import { objectToFormData } from 'object-to-formdata';
import PropTypes from 'prop-types';
import { $qb } from 'scripts/querybuilder';
import { useAuth } from 'store/auth';
import { useEvent } from 'hooks/useEvent';
import { useModal } from 'hooks/useModal';
import { useServices } from 'hooks/useServices';
import { useToast } from 'hooks/useToast';

const OrganizationsCreateUser = ({ match }) => {
  const history = useHistory();
  const eventBus = useEvent();
  const auth = useAuth();
  const modal = useModal();
  const services = useServices();
  const toast = useToast();
  const [userObj, setUserObj] = useState({});
  const [authorityObj, setAuthorityObj] = useState({});
  const [isFavorite, setIsFavorite] = useState({});
  const [defaultUserObj, setDefaultUserObj] = useState({});
  const [defaultUserAuthorityObj, setDefaultUserAuthorityObj] = useState({});
  const [dataAccessAuthorityList, setDataAccessAuthorityList] = useState([]);
  const [params] = useState($qb());
  const [selectDepartmentId, setSelectDepartmentId] = useState();
  const [loadingBtnDisabledFlag, setLoadingBtnDisabledFlag] = useState(false);

  //0708. 계정 생성시 접근권한 병원 전체로 세팅이 필요하여 디폴트값 생성
  const [organizationId, setOrganizationId] = useState();

  const callApi = useCallback(async () => {
    //parmas = /organizations?userId=로 검색
    const resp = await services.crm.organizations.organ.all(params.build());
    if (!resp) return;
    setDefaultUserObj(resp?.data[0]);
    setDefaultUserAuthorityObj(resp?.data[0]);
    if (!resp) return;
  }, [services.crm.organizations.organ, params]);

  const getOrganizationCallApi = useCallback(async () => {
    try {
      const resp = await services.crm.organizations.organ.all();
      if (!resp) return;
      //최상단 부서
      setOrganizationId(resp?.data[0].id);
    } catch (e) {
      console.log(e.description);
    }
  }, [services.crm.organizations.organ]);

  useEffect(() => {
    //0709 부서아이디 찾기
    //접속한 계정의 userId를 파라미터로 organizationId를 찾는다
    getOrganizationCallApi();
  }, [getOrganizationCallApi]);

  useEffect(() => {
    if (Object.keys(match.params).length) {
      params.queryParams = { userId: match.params.id };
      eventBus.on('callApi', callApi);
      callApi();
      return () => eventBus.off('callApi');
    }
    if (!match) return;
  }, [eventBus, params]);

  const onClickFrequentlyUsedAuthority = () => {
    if (defaultUserAuthorityObj.user !== undefined) {
      if (defaultUserAuthorityObj.user.type === 'owner') {
        toast.success('owner 권한은 수정 불가합니다.');
        return true;
      }
    }

    modal
      .custom({
        component: ModalFrequentlyAuthority,
        options: {},
      })
      .then((user) => {
        if (!user) return;

        let obj = {
          userId: user.userId,
          user: { authorityGroup: user.user.authorityGroup },
        };
        setDefaultUserAuthorityObj(obj);
        setSelectDepartmentId(user.parentId);
      });
  };

  const dataSetting = () => {
    const {
      id,
      email,
      password,
      name,
      duty,
      licenseType,
      position,
      phoneNumber,
      telNumber,
      birthday,
      employedAt,
      imageFile,
      sealFile,
    } = userObj;
    let user = {
      email,
      password,
      name,
      duty: duty.filter((v) => v !== '추가 선택').join(','),
      licenseType,
      position,
      phoneNumber,
      telNumber,
      birthday,
      employedAt,
      imageFile,
      sealFile,
    };
    if (id !== undefined) {
      user.id = id;
    }
    let obj = {};
    authorityObj.map((v) => {
      v.items.length > 0 &&
        v.items.map((item) => {
          let key;
          Object.keys(item.value).map((keys) => {
            if (item.value[keys]) {
              key = keys;
            }
          });
          obj[item.key] = key;
        });
    });

    const authorityGroup = {
      ...isFavorite,
      ...obj,
    };
    return { ...user, authorityGroup: authorityGroup };
  };

  const onSaveOrganizations = async (endpoint, id, departmentInfo) => {
    try {
      const [parentId, name] = departmentInfo.split(',');
      let payload = {
        parentId,
        type: 'staff',
        name,
      };
      if (endpoint === 'create') {
        payload.userId = id;
        const resp = await services.crm.organizations.organ.create(payload);
        if (!resp) return;
      } else if (endpoint === 'update') {
        payload.id = id;
        const resp = await services.crm.organizations.organ.update(payload);
        if (!resp) return;
      }
    } catch (e) {
      toast.error(e.description);
    }
  };

  const onSaveUserAuthorityGroup = async (id, payload) => {
    try {
      let obj = {
        id,
        email: payload.email,
        authorityGroup: payload.authorityGroup,
      };
      const resp = await services.crm.user.update(obj);
      if (!resp) return;
    } catch (e) {
      toast.error(e.description);
    }
  };

  const onSaveDataAccessAuthority = async () => {
    try {
      // const payload = dataAccessAuthorityList.map(v => {
      //   return { ...v, userId: id }
      // })

      //0709 임시 병원전체로 세팅하여 보내기
      const dataAccessAuthorityDefault = [
        {
          tableName: 'appointments',
          authorityName: 'appointment_board',
          type: 'department',
          organizationId,
        },
        {
          tableName: 'appointments',
          authorityName: 'appointment_calendar',
          type: 'department',
          organizationId,
        },
        {
          tableName: 'customers',
          authorityName: 'customer',
          type: 'department',
          organizationId,
        },
        {
          tableName: 'appointments',
          authorityName: 'appointment',
          type: 'department',
          organizationId,
        },
        {
          tableName: 'consultings',
          authorityName: 'consulting',
          type: 'department',
          organizationId,
        },
        {
          tableName: 'treatments',
          authorityName: 'treatment',
          type: 'department',
          organizationId,
        },
        {
          tableName: 'payments',
          authorityName: 'payment',
          type: 'department',
          organizationId,
        },
        {
          tableName: 'files',
          authorityName: 'penchart',
          type: 'department',
          organizationId,
        },
      ];

      const resp = await services.crm.user.data_access_authorities.create({
        data: dataAccessAuthorityDefault,
      });
      // const resp = await services.crm.user.data_access_authorities.create({data: payload})
      if (!resp) return;
    } catch (e) {
      toast.error(e.description);
    }
  };

  const onSaveUser = async () => {
    try {
      //user 생성 / 변경 -> 조직도에 userId를 추가 / 조직도에서 변경
      //-> multipart 변환으로 인해 authorityGroup이 추가되지 않음
      //-> authorityGroup을 업데이트
      //-> data access authorty 권한 업데이트  : 4번의 호출이 이루어짐(api 호출 순서 조직도->권한그룹 )

      const payload = dataSetting();
      if (match.params.id === undefined) {
        const resp = await services.crm.user.create(objectToFormData(payload));
        if (!resp) {
          return;
        } else {
          await onSaveOrganizations('create', resp.data.id, userObj.department);
          await onSaveUserAuthorityGroup(resp.data.id, payload);
          await onSaveDataAccessAuthority(
            resp.data.id,
            dataAccessAuthorityList
          );
          modal
            .confirm({
              type: 'SUCCESS',
              msg: '생성되었습니다.',
            })
            .then(() => {
              history.push('/crm/organizations-staff');
            });
        }
      } else {
        const resp = await services.crm.user.update_multipart(payload);
        if (!resp) {
          return;
        } else {
          await onSaveOrganizations(
            'update',
            defaultUserObj.id,
            userObj.department
          );
          await onSaveUserAuthorityGroup(resp.data.id, payload);
          await onSaveDataAccessAuthority(
            resp.data.id,
            dataAccessAuthorityList
          );
          modal
            .confirm({
              type: 'SUCCESS',
              msg: '업데이트되었습니다.',
            })
            .then(() => {
              history.push('/crm/organizations-staff');
            });
        }
      }
    } catch (e) {
      console.log(e.description);
      let message = e.description;
      if (e.description === 'EmailAlreadyExists') {
        message = '이미 등록된 아이디입니다. 아이디를 변경하세요.';
      }
      if (e.description.includes('Shorter than minimum length 8.')) {
        message = '비밀번호는 8자리 이상 입력하세요.';
      }
      if (
        e.description.includes('"email": ["Missing data for required field."]')
      ) {
        message = '이메일(ID)을 입력하세요.';
      }
      if (e.description.includes('Not a valid email address')) {
        message = '올바른 이메일(ID) 형식으로 입력하세요.';
      }

      modal.confirm({
        type: 'ERROR',
        msg: message,
      });
    } finally {
      setLoadingBtnDisabledFlag(false);
      auth.loadMe();
      //loadMe 호출 이유 : 본인 정보 수정 시 헤더에서 적용확인을 위해
    }
  };

  const validator = ({ id, email, password }) => {
    if (email === undefined || email === '') {
      return '이메일(ID)을 입력하세요.';
    }

    if (
      (!id && (password === undefined || password === '')) ||
      (password && password.length < 8)
    ) {
      return '비밀번호는 8자리 이상 입력하세요.';
    }

    return '';
  };

  const onClickSave = () => {
    let msg = validator(userObj);

    if (msg) {
      modal.confirm({
        type: 'ERROR',
        msg,
      });
      return false;
    }
    setLoadingBtnDisabledFlag(true);
    onSaveUser();
  };

  const onChangeUserObj = (obj) => {
    if (userObj.department !== obj.department) {
      setSelectDepartmentId(obj.department.split(',')[0]);
    }
    setUserObj(obj);
  };

  const onChangeDataAccessAuthorityObj = (obj) => {
    setDataAccessAuthorityList(obj);
  };

  const onChangeAuthorityObj = (obj) => {
    setAuthorityObj(obj);
  };

  const onChangeFavoriteCheck = (obj) => {
    setIsFavorite(obj);
  };

  return (
    <div className="list organizations organizations-users">
      <div className="page-navi">
        <span className="title">병원관리</span>
        <span className="title">직원 관리</span>
        <span className="title">신규등록</span>
      </div>
      <div className="user-contents">
        <div className="card m-b-16">
          <div className="card-title">
            <span>정보 입력</span>
          </div>
          <OrganizationsUserInputForm
            pageStatus={match.params.id === undefined}
            setUserObj={onChangeUserObj}
            defaultObj={defaultUserObj}
          />
        </div>
        <div className="card auth-card">
          <div className="card-title">
            <span className="m-r-8">권한 설정</span>
            <p>
              <button onClick={onClickFrequentlyUsedAuthority}>
                자주쓰는 권한 불러오기
              </button>
            </p>
          </div>
          <OrganizationsAuthoritySettings
            setAuthorityObj={onChangeAuthorityObj}
            setIsFavorite={onChangeFavoriteCheck}
            setDataAccessAuthorityObj={onChangeDataAccessAuthorityObj}
            user={defaultUserAuthorityObj}
            departmentId={selectDepartmentId}
          />
        </div>
      </div>
      <div className="flex-row btnwrap">
        <button
          className="btn btn-cancel"
          onClick={() => {
            history.push('/crm/organizations-staff');
          }}
        >
          입력취소
        </button>
        <button
          disabled={loadingBtnDisabledFlag}
          className="btn btn-save"
          onClick={onClickSave}
        >
          저장
        </button>
      </div>
    </div>
  );
};

OrganizationsCreateUser.propTypes = {
  match: PropTypes.object,
};

export default observer(OrganizationsCreateUser);
