import React, { useContext, useRef } from 'react';
import { GoPencil } from 'react-icons/go';
import { FaMarker, FaEraser } from 'react-icons/fa';
import { BiText } from 'react-icons/bi';
import { IoMdHand, IoIosUndo, IoIosRedo } from 'react-icons/io';
import { AiFillPicture } from 'react-icons/ai';
import { observer } from 'mobx-react';
import { ImageEditorContext } from './ImageEditor';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FileInput } from 'components/common/FileInput';

const Input = styled.input`
  background-color: #000;
  color: white;
  border: 1px solid grey;
  height: 32px;
  border-radius: 4px;
  padding: 4px 8px;
  box-sizing: border-box;
  flex-grow: 1;
  width: 40px;
`;

function SizeOption({ size, onChange }) {
  return (
    <div className="image-editor-hor-option">
      <span className="image-editor-option-label">Size</span>
      <Input type="number" value={size} onChange={onChange} />
    </div>
  );
}

SizeOption.propTypes = {
  size: PropTypes.number,
  onChange: PropTypes.func,
};

function ColorOption({ color, onChange }) {
  const colors = [
    '#000',
    '#f00',
    '#ff0',
    '#0f0',
    '#0ff',
    '#00f',
    '#f0f',
    '#fff',
  ];
  return (
    <>
      <div className="image-editor-hor-option">
        {colors.map((c) => (
          <div
            key={c}
            className={`image-editor-color-palette ${
              color === c ? 'selected' : ''
            }`}
            style={{ backgroundColor: c }}
            onClick={() => onChange(c)}
          />
        ))}
      </div>
    </>
  );
}

ColorOption.propTypes = {
  color: PropTypes.string,
  onChange: PropTypes.func,
};

function SelectableItem({ children, selected, onClick, disabled }) {
  return (
    <div
      className={`image-editor-toolbar-item ${selected ? 'selected' : ''} ${
        disabled ? 'disabled' : ''
      }`}
      onClick={(e) => (!disabled ? onClick(e) : null)}
    >
      {children}
    </div>
  );
}

SelectableItem.propTypes = {
  children: PropTypes.node,
  selected: PropTypes.bool,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

function ToolOption({ children }) {
  return <div className={`image-editor-tool-option`}>{children}</div>;
}

ToolOption.propTypes = {
  children: PropTypes.node,
};

export default observer(function ImageEditorToolbar({
  onFileUpload,
  onExport,
  onDownload,
  onChangeTool,
}) {
  const { state, ui } = useContext(ImageEditorContext);
  const inputRef = useRef();

  function setPen() {
    onChangeTool('pen');
  }

  function setMarker() {
    onChangeTool('marker');
  }

  function setEraser() {
    onChangeTool('eraser');
  }

  function setSelect() {
    onChangeTool('select');
  }

  function setHand() {
    onChangeTool('hand');
  }

  function setText() {
    onChangeTool('text');
  }

  function onClickImport() {
    ui.setSelected(0);
    inputRef.current.click();
  }

  function onClickDownload() {
    ui.setSelected(0);
    onDownload();
  }

  function handleFileChange(e) {
    if (e.target.files[0] !== undefined) {
      ui.setSelected(0);
      onFileUpload(URL.createObjectURL(e.target.files[0]));
    }
  }

  return (
    <>
      <SelectableItem selected={ui.tool === 'hand'} onClick={setHand}>
        <IoMdHand />
      </SelectableItem>
      <SelectableItem selected={ui.tool === 'select'} onClick={setSelect}>
        <svg
          width="17"
          height="20"
          viewBox="0 0 17 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 0L16.5591 7.9638L9.35951 10.5882L14.7592 17.8281L11.6094 20L6.65965 12.4887L1.79991 18.371L0 0Z"
            fill="currentColor"
          />
        </svg>
      </SelectableItem>
      <SelectableItem selected={ui.tool === 'pen'} onClick={setPen}>
        <GoPencil />
      </SelectableItem>
      {ui.tool === 'pen' && (
        <ToolOption>
          <SizeOption
            size={ui.penOption.size}
            onChange={(e) =>
              ui.setPenOption({
                ...ui.penOption,
                size: Number(e.target.value),
              })
            }
          />
          <ColorOption
            color={ui.penOption.color}
            onChange={(c) => ui.setPenOption({ ...ui.penOption, color: c })}
          />
        </ToolOption>
      )}
      <SelectableItem selected={ui.tool === 'marker'} onClick={setMarker}>
        <FaMarker />
      </SelectableItem>
      {ui.tool === 'marker' && (
        <ToolOption>
          <SizeOption
            size={ui.markerOption.size}
            onChange={(e) =>
              ui.setMarkerOption({
                ...ui.markerOption,
                size: Number(e.target.value),
              })
            }
          />
          <ColorOption
            color={ui.markerOption.color}
            onChange={(c) =>
              ui.setMarkerOption({ ...ui.markerOption, color: c })
            }
          />
        </ToolOption>
      )}
      <SelectableItem selected={ui.tool === 'eraser'} onClick={setEraser}>
        <FaEraser />
      </SelectableItem>
      {ui.tool === 'eraser' && (
        <ToolOption>
          <SizeOption
            size={ui.eraserOption.size}
            onChange={(e) =>
              ui.setEraserOption({
                ...ui.eraserOption,
                size: Number(e.target.value),
              })
            }
          />
        </ToolOption>
      )}
      <SelectableItem selected={ui.tool === 'text'} onClick={setText}>
        <BiText />
      </SelectableItem>
      {ui.tool === 'text' && (
        <ToolOption>
          <SizeOption
            size={ui.editText ? ui.editText.props.fontSize : ui.textOption.size}
            onChange={(e) => {
              if (ui.editText) {
                ui.setEditText({
                  ...ui.editText,
                  props: {
                    ...ui.editText.props,
                    fontSize: Number(e.target.value),
                  },
                  style: {
                    ...ui.editText.style,
                    fontSize: Number(e.target.value),
                  },
                });
              } else {
                ui.setTextOption({
                  ...ui.textOption,
                  size: Number(e.target.value),
                });
              }
            }}
          />
          <ColorOption
            color={ui.editText ? ui.editText.props.fill : ui.textOption.color}
            onChange={(c) => {
              if (ui.editText) {
                ui.setEditText({
                  ...ui.editText,
                  props: {
                    ...ui.editText.props,
                    fill: c,
                  },
                  style: {
                    ...ui.editText.style,
                    color: c,
                  },
                });
              } else {
                ui.setTextOption({ ...ui.textOption, color: c });
              }
            }}
          />
        </ToolOption>
      )}
      <SelectableItem onClick={onClickImport}>
        <AiFillPicture />
      </SelectableItem>
      <FileInput accept="image/*" ref={inputRef} onChange={handleFileChange} />
      <SelectableItem disabled={!state.canUndo()} onClick={() => state.undo()}>
        <IoIosUndo />
      </SelectableItem>
      <SelectableItem disabled={!state.canRedo()} onClick={() => state.redo()}>
        <IoIosRedo />
      </SelectableItem>
      <SelectableItem onClick={onExport}>저장</SelectableItem>
      <SelectableItem onClick={onClickDownload}>다운로드</SelectableItem>
    </>
  );
});
