import moment from 'moment';
import { currency } from 'filters';

export default {
  keys: [
    {
      title: '상담문의일',
      column: 'requestAt',
      filter: (o) => moment(o.requestAt).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      title: '매체',
      column: 'mediaName',
      filter: (o) => o.media?.tag,
    },
    {
      title: '이벤트명',
      column: 'eventName',
    },
    {
      title: '이벤트 유형',
      column: 'eventType',
    },
    {
      title: '이벤트 가격',
      column: 'price',
      filter: (o) => {
        return o.price === null ? '' : `₩${currency(o.price)}`;
      },
    },
    {
      title: '방문희망일',
      column: 'visitAt',
      filter: (o) =>
        o.visitAt !== null ? moment(o.visitAt).format('YYYY-MM-DD') : '',
    },
    {
      title: '관심항목',
      column: 'interestItem',
    },
    {
      title: '고객문의',
      column: 'memo',
    },
    {
      title: '메모',
      column: '',
    },
  ],
};
