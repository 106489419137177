export default {
  keys: [
    {
      title: 'STATUS',
      column: 'status',
      filter: (o) => `<div class="text-center">
            <div>${o.status}</div>
            <div class="flex-row">
                <button class="btn btn-sm btn-primary">수정</button>
                ${
                  o.authorityGroup.defaultCode !== 1
                    ? '<button class="btn btn-sm btn-danger m-l-8">삭제</button>'
                    : ''
                }
            </div>
        </div>`,
    },
    {
      title: 'AUTHORITY_GROUPS',
      column: 'authorityGroup__name',
    },
    {
      title: 'EMAIL',
      column: 'email',
    },
    {
      title: 'NAME',
      column: 'name',
    },
    {
      title: 'PHONE_NUMBER',
      column: 'phoneNumber',
    },
    {
      title: 'ROLE',
      column: 'role__name',
    },
  ],
};
