import React, { useCallback, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import DataTableAlpha from 'components/data-table/DataTableAlpha';
import ModalAddPaymentSurgeries from 'components/modals/ModalAddPaymentSurgeries';
import QuillTextField from 'components/quill/QuillTextField2';
import { toHexColorHtml } from 'components/quill/quillUtil';
import moment from 'moment';
import PropTypes from 'prop-types';
import modelSurgeryTab from 'models/surgery-tab';
import useCustomerChart from 'hooks/useCustomerChart';
import SelectNextProcess from './SelectNextProcess';
import models from 'models';
import { copy, translate } from 'filters';
import { $qb } from 'scripts/querybuilder';
import { useEvent } from 'hooks/useEvent';
import { useModal } from 'hooks/useModal';
import { useServices } from 'hooks/useServices';
import { useToast } from 'hooks/useToast';
import { RadioInput } from 'components/common/RadioInput';

const CustomerSurgeries = ({ customer, openFlag, closeFlag }) => {
  const history = useHistory();
  const eventBus = useEvent();
  const modal = useModal();
  const services = useServices();
  const toast = useToast();

  const tabName = 'surgeries';
  const [loadingBtnDisabledFlag, setLoadingBtnDisabledFlag] = useState(false);
  const defaultParams = $qb()
    .limit(10)
    .customParam('customerId', customer.id)
    .orderBy('scheduledAt desc');
  const [params, setParams] = useState(defaultParams);
  const [total, setTotal] = useState(0);
  const [surgeries, setSurgeries] = useState(null);
  const [surgeryItems, setSurgeryItems] = useState([]); //수납된, 1회이상 남은 시수술내역
  const [obj, setObj] = useState({});
  const [nextProcess, setNextProcess] = useState(undefined);

  const [treatmentSelectCounselors, setTreatmentSelectCounselors] = useState(
    {}
  );
  const [appointmentSurgeries, setAppointmentSurgeries] = useState([]); //예약된 시수술내역
  const [appointmentData, setAppointmentData] = useState({});
  const [surgeryTreatmentItems, setSurgeryTreatmentItems] = useState([]); //진행할 시수술 체크내역

  const [processAppointmentId, setProcessAppointmentId] = useState(
    (openFlag || {}).appointmentId || null
  );
  //componentFlag : surgery
  const [componentFlag, setComponentFlag] = useState(null);
  const [memoBoilerplateList, setMemoBoilerplateList] = useState([]);

  const [facialist, setFaicalist] = useState([]);
  const [doctors, setDoctors] = useState([]);

  //다음진행 팝업에서 사용할 상담사 리스트
  const [counselors, setCounselors] = useState([]);

  const [radioValue, setRadioValue] = useState(false);
  const [doctorFlag, setDoctorFlag] = useState(false);

  const counselorCallApi = useCallback(async () => {
    let params = { duty: '상담사', userStatus: 'active', limit: 300 };
    const resp = await services.crm.user.duty(params);
    if (!resp) return;

    setCounselors(resp.data);
  }, [services.crm.user]);

  useEffect(() => {
    counselorCallApi();
  }, [counselorCallApi]);

  const facialistCallApi = useCallback(async () => {
    let params = {
      duty: '피부관리사,간호사,간호조무사',
      userStatus: 'active',
      limit: 300,
    };
    const resp = await services.crm.user.duty(params);
    if (!resp) return;

    setFaicalist(resp.data);
  }, [services.crm.user]);

  useEffect(() => {
    facialistCallApi();
  }, [facialistCallApi]);

  const doctorCallApi = useCallback(async () => {
    let params = { duty: '의사', userStatus: 'active', limit: 300 };
    const resp = await services.crm.user.duty(params);
    if (!resp) return;

    setDoctors(resp.data);
  }, [services.crm.user]);

  useEffect(() => {
    doctorCallApi();
  }, [doctorCallApi]);

  const stateInit = useCallback(async () => {
    setComponentFlag(null);
    setObj({});
    setLoadingBtnDisabledFlag(false);
    setAppointmentData({});
    setTreatmentSelectCounselors({});
    setNextProcess();
    setSurgeryTreatmentItems([]);
    setRadioValue(false);
    setDoctorFlag(false);
  }, []);

  const loadSurgeries = useCallback(async () => {
    try {
      if (customer.id) {
        const resp = await services.crm.surgery.all(params.build());
        if (!resp) return;

        setSurgeries(resp.data);
        setTotal(resp.total);
      }
    } catch (e) {
      console.log(e.description);
    }
  }, [services.crm.surgery, customer.id, params]);

  const appointmentListCallApi = useCallback(async () => {
    try {
      if (!customer.id) return;

      const resp = await useCustomerChart.useGetRemainingPaidTreatmentItems(
        customer.id
      );
      if (!resp) return;

      setAppointmentSurgeries(resp);
    } catch (e) {
      console.log(e.description);
    }
  }, [services.crm.payment, customer.id]);

  const remainingCountCallApi = useCallback(async () => {
    try {
      //수납된(미수포함) / 남은 회차가 1회 이상 남은  시/수술 내역 노출
      //남은 회차가 0회인(환불포함) 시/수술은 노출 x

      if (!customer.id) return;

      const resp = await useCustomerChart.useGetRemainingPaidTreatmentItems(
        customer.id
      );
      if (!resp) return;

      return resp;
    } catch (e) {
      console.log(e.description);
    }
  }, [services.crm.payment, customer.id]);

  const paymentCallApi = useCallback(async () => {
    try {
      const resp = await remainingCountCallApi();
      if (!resp) return;
      const payment = resp.map((v, i) => {
        //v.$$checked = v.isSelected;
        v.checkId = i;
        return v;
      });
      setSurgeryTreatmentItems([]);
      setSurgeryItems([...payment]);
    } catch (e) {
      console.log(e.description);
    }
  }, [remainingCountCallApi]);

  const init = useCallback(async () => {
    loadSurgeries();
  }, [loadSurgeries]);

  const onDeleteSurgery = useCallback(
    async (id) => {
      try {
        await services.crm.surgery.delete(id);
        modal
          .confirm({
            type: 'SUCCESS',
            msg: '삭제되었습니다.',
          })
          .then(() => {
            eventBus.emit('chartCountCallApi');
            init();
            appointmentListCallApi();
          });
      } catch (e) {
        console.log(e.description);
        modal.confirm({
          type: 'ERROR',
          msg: e.description,
        });
      }
    },
    [services.crm.surgery, toast, init, appointmentListCallApi]
  );

  const onClickDeleteSurgery = (surgery) => {
    modal
      .basic({
        body: '정말로 삭제하시겠습니까?',
        buttons: [
          {
            text: 'CANCEL',
            class: 'btn-default',
          },
          {
            text: 'CONFIRM',
            class: 'btn-primary',
          },
        ],
      })
      .then((idx) => {
        if (idx === 1) {
          onDeleteSurgery(surgery.id);
        }
      });
  };

  const infoFlagSetting = (name, row) => {
    //고객의 담당상담사가 있는 경우
    if (customer && customer[name]) {
      // 상담건의 상담사가 있는 경우
      if (row && row[name] && row[name].id) {
        // 고객의 담당상담사와 상담건의 상담사가 다른경우
        if (row[name].id !== customer[name].id) {
          if (name === 'doctor') setDoctorFlag(true);
        } else {
          if (name === 'doctor') setDoctorFlag(false);
        }
      } else {
        if (name === 'doctor') setDoctorFlag(true);
      }
    }
    //고객의 담당상담사가 없는 경우
    else {
      // 상담건의 상담사가 있는 경우
      if (row && row[name] && row[name].id) {
        if (name === 'doctor') setDoctorFlag(true);
      } else {
        if (name === 'doctor') setDoctorFlag(false);
      }
    }
  };
  const onAction = (obj) => {
    const event = obj.event;
    const row = obj.row;
    const classList = event.target.classList;
    const model = obj.key;

    // 시/수술 내역에서,환불내역이 존재하면 수정 버튼도 노출되지 않으므로 더블클릭으로도 수정 화면 진입 불가
    let refundCount = false;
    for (let i in row?.surgeryTreatmentItems) {
      const item = row?.surgeryTreatmentItems[i];
      if (item.refundCount > 0) {
        refundCount = true;
        break;
      }
    }

    if (
      (obj.eventType === 'doubleClick' &&
        model?.title !== 'STATUS' &&
        !refundCount) ||
      classList.contains('btn-primary')
    ) {
      //수정
      //메모 상용구 api 호출
      memoBoilerplateCallApi();

      setObj(copy(obj.row));
      setComponentFlag('surgery');
      infoFlagSetting('doctor', row);
      paymentCallApi();
      setNextProcess();
    }

    if (classList.contains('btn-danger')) {
      //삭제. 삭제는 병원 owner(대표)만 삭제할 수 있다
      onClickDeleteSurgery(row);
      return;
    }
  };

  const onSelectSurgeryAction = (object) => {
    const event = object.event;
    const surgeries = object.row;
    const classList = event.target.classList;

    if (classList.contains('checkbox') || classList.contains('zmdi-check')) {
      if (surgeries.$$checked) {
        setSurgeryTreatmentItems([...surgeryTreatmentItems, surgeries]);
      } else {
        let list = surgeryTreatmentItems.filter((v) => {
          return v.id !== surgeries.id;
        });
        setSurgeryTreatmentItems([...list]);
      }
    }

    if (classList.contains('zmdi-minus')) {
      let deleteItems = surgeryItems.filter((f) => {
        return f.checkId !== surgeries.checkId;
      });
      setSurgeryItems([...deleteItems]);
      setSurgeryTreatmentItems([...deleteItems].filter((v) => v.$$checked));
    }
  };

  const onParams = (p) => {
    setParams(p);
    loadSurgeries();
  };

  const appointmentUpdate = useCallback(
    async (payload) => {
      try {
        await services.crm.crud.appointment.update(payload);
      } catch (e) {
        console.log(e.description);
      }
    },
    [services.crm.crud.appointment]
  );

  const beforeSave = () => {
    let nextProcessStatus = nextProcess;
    if (!obj.id) {
      if (!surgeryTreatmentItems.length) {
        toast.error('진행할 시/수술이 없습니다.');
        return;
      }
    }

    let appointmentUpdatePayload;
    //nextProcess 값에 따라 다음진행 영역 셀렉트값 선택하여 onClickSave로 넘겨
    //상담 완료 하고 완료되면 예약데이터 업데이트
    if (nextProcess === 'consulting_waiting') {
      appointmentUpdatePayload = {
        counselor: treatmentSelectCounselors,
      };
    }

    onClickSave(
      nextProcessStatus,
      appointmentUpdatePayload,
      surgeryTreatmentItems
    );
  };

  const onClickSave = useCallback(
    async (nextProcess, appointmentUpdatePayload, surgeryTreatmentItems) => {
      setLoadingBtnDisabledFlag(true);

      let endpoint = !obj.id ? 'create' : 'update';
      let payload = { ...obj, saveCustomer: radioValue };
      payload.customerId = (customer || {}).id;

      if (endpoint === 'create') {
        payload.scheduledAt = obj.scheduledAt; //moment().format('YYYY-MM-DDTHH:mm:ss')
        const changeSurgeryTreatmentItems = surgeryTreatmentItems.map((v) => {
          if (v.status !== 'unpaid') {
            //수납된 시수술이 id를 paymentTreatmentItemId로 변경하여 보냄
            v.paymentTreatmentItemId = v.id;
          }
          //복사생성이기에 id값을 삭제하고 넘겨야 함
          // eslint-disable-next-line no-unused-vars
          const { id: deletedKey, $$checked: deletedKey2, ...otherKeys } = v;
          return otherKeys;
        });
        payload.surgeryTreatmentItems = [...changeSurgeryTreatmentItems];
      }

      let changeProcess =
        nextProcess === 'payment_during' ? 'payment_waiting' : nextProcess;
      if (endpoint === 'create') {
        //appointmentId, appointmentStatus 넘겨줄것
        payload.appointmentStatus = changeProcess;
        if (obj.memo) payload.memo = toHexColorHtml(obj.memo);

        if (processAppointmentId !== null) {
          payload.appointmentId = processAppointmentId;
        }
      } else {
        //수정시에는 surgeryTreatmentItems 수정 불가
        // eslint-disable-next-line no-unused-vars
        const { surgeryTreatmentItems: deletedKey, ...ohterKeys } = payload;
        payload = { ...ohterKeys, saveCustomer: radioValue };
      }

      try {
        const resp = await services.crm.surgery[endpoint](payload);
        if (!resp) return;
        let message = translate(`${endpoint.toUpperCase()}D`);
        modal
          .confirm({
            type: 'SUCCESS',
            msg: message,
          })
          .then(async () => {
            eventBus.emit('chartCountCallApi');
            //탭이 닫히지 않을 때 내역 조회 필요
            loadSurgeries();

            if (resp.data.id) {
              //시수술 저장 후, 예약 데이터 업데이트 (상담사 변경)
              //다음진행 상태가 추가상담대기인 경우에는 상담사 명'만' 같이 업데이트(appointmentUpdatePayload)

              if (endpoint === 'create') {
                if (appointmentData !== null) {
                  if (changeProcess === 'consulting_waiting') {
                    //예약이 수정되는 경우 : 상담
                    let updatePayload = {
                      id: appointmentData.id,
                      doctor: appointmentData.doctor,
                      facialist: appointmentData.facialist,
                      counselor: appointmentData.counselor,
                      scheduledAt: appointmentData.scheduledAt,
                      customerId: ((appointmentData || {}).customer || {}).id,
                      ...appointmentUpdatePayload,
                    };
                    await appointmentUpdate(updatePayload);
                  }
                }
              }
              appointmentListCallApi();
              if (history.location.pathname === '/crm/appointment-boards') {
                eventBus.emit('boardsLoadAppointments');
              } else if (history.location.pathname === '/crm/home') {
                eventBus.emit('homeLoadAppointments');
              }
            }
            stateInit();
            eventBus.emit('customerCallApi');
            if (closeFlag) {
              modal.pop();
              return;
            }
            setLoadingBtnDisabledFlag(false);
          });
      } catch (e) {
        console.log(e.description);
        modal
          .confirm({
            type: 'ERROR',
            msg: e.description,
          })
          .then(() => {
            setLoadingBtnDisabledFlag(false);
          });
      }
    },
    [
      history,
      eventBus,
      modal,
      obj,
      loadSurgeries,
      processAppointmentId,
      remainingCountCallApi,
      appointmentData,
      appointmentUpdate,
      appointmentListCallApi,
      stateInit,
      radioValue,
    ]
  );

  const onChangeValue = (column, value) => {
    setObj((obj) => {
      return { ...obj, [column]: value };
    });
  };

  const initSurgery = (surgeryId) => {
    let doctor = customer && customer.doctor ? customer.doctor : null;
    if (surgeryId && (surgeries || []).length > 0) {
      setObj(copy(surgeries.find((surgery) => surgery.id === surgeryId)));
    } else {
      setObj({ customer, doctor, scheduledAt: moment().format() });
    }
    setComponentFlag('surgery');
  };

  const closetToTodayAppointmentCallApi = async () => {
    //오늘 예약건을 조회하여 setProcessAppointmentId
    //예약건 복수 개중 가장 오늘과 가까운 가장 최근 예약건을 찾기 위해 orderBy=scheduledAt+asc
    //ex) customerId=290startAt=2020-07-29&orderBy=scheduledAt+asc
    if (!customer.id) return;

    const notLeaveItemId = await useCustomerChart.useGetAppointmentClosetToTodayItem(
      customer.id
    );
    if (notLeaveItemId !== null) {
      setProcessAppointmentId(notLeaveItemId);
    }
  };

  useEffect(() => {
    //탭 진입 시, openFlag 값이 있으면 setObj setState
    if (openFlag !== undefined) {
      if (openFlag.surgery === null) {
        onClickCreateSurgery();
      } else if (openFlag.surgery !== undefined) {
        setObj(openFlag.surgery);
      }
    } else {
      //openFlag === undefined > 헤더 등으로 고객통합차트 진입
      //데이터 생성 시, 예약 status 변경을 "오늘 중" 예약건의 status를 변경하도록 수정.
      closetToTodayAppointmentCallApi();
    }

    return () => {
      setObj({});
      setComponentFlag(null);
    };
  }, [openFlag]);

  useEffect(() => {
    init();
  }, [init]);

  useEffect(() => {
    appointmentListCallApi();
  }, [appointmentListCallApi]);

  const actionInit = useCallback(async () => {
    init();
    appointmentListCallApi();
    stateInit();
  }, [init, appointmentListCallApi, stateInit]);

  const onClickCreateSurgery = async () => {
    paymentCallApi();

    //메모 상용구 api 호출
    memoBoilerplateCallApi();

    initSurgery();

    //고객의 가장 최근 예약건을 가져오기 위해 호출
    recentAppointmentDataCallApi();
  };

  const onClickCreateSurgeries = () => {
    modal
      .custom({
        component: ModalAddPaymentSurgeries,
      })
      .then((addItems) => {
        if (addItems === undefined || !addItems.length) {
          return;
        }
        let items = [
          ...surgeryItems,
          ...addItems.map((v) => {
            return {
              ...v,
              status: 'unpaid', //추가한 시수술인지 구분하기 위함
            };
          }),
        ];
        let surgeries = items.map((v, i) => {
          v.checkId = i;
          return v;
        });

        setSurgeryItems([...surgeries]);
        setSurgeryTreatmentItems([...surgeries.filter((v) => v.$$checked)]);
      });
  };

  //해당 고객의 가장 최근 예약건을 조회하여, 다음진행 팝업의 counselor를 세팅
  const recentAppointmentDataCallApi = async () => {
    try {
      if (!customer.id) return;

      const appointment = await useCustomerChart.useGetRecentAppointmentItem(
        customer.id
      );
      if (!appointment.id) return;

      setAppointmentData(appointment);
      if (appointment.counselor !== null) {
        setTreatmentSelectCounselors(appointment.counselor);
      }
    } catch (e) {
      console.log(e.description);
    }
  };

  const onCallbackProcessChangeToWating = (selectObj, nextProcess) => {
    if (!selectObj) return;
    setTreatmentSelectCounselors(selectObj.counselor);
    setToggleNextProcessBtn(nextProcess);
  };

  const processChangeToConsultingWaiting = () => {
    //상담 대기할 상담사 선택 팝업
    if (nextProcess !== 'consulting_waiting') {
      useCustomerChart.useProcessChangeToWaiting({
        nextProcessStatus: 'consulting_waiting',
        counselors,
        treatmentSelectCounselors,
        callback: onCallbackProcessChangeToWating,
      });
    } else {
      setToggleNextProcessBtn('consulting_waiting');
    }
  };

  const setToggleNextProcessBtn = (status) => {
    if (nextProcess === status) {
      setNextProcess(undefined);
    } else {
      setNextProcess(status);
    }
  };

  const memoBoilerplateCallApi = useCallback(async () => {
    try {
      const memoItems = await useCustomerChart.useGetMemoBoilerplate(tabName);
      setMemoBoilerplateList(memoItems);
    } catch (e) {
      console.log(e.description);
    }
  }, [services.crm.boilerplate.memo]);

  const onSelected = (date) => {
    setObj({ ...obj, scheduledAt: date });
  };

  const onChangeScheduledAt = (scheduledAt) => {
    let date =
      scheduledAt === null ? moment().format() : moment(scheduledAt).format();
    setObj((obj) => {
      return { ...obj, scheduledAt: date };
    });
  };

  return (
    <div className="customer-treatments chart-page">
      {componentFlag === null ? (
        <>
          <div className="data-input m-b-20">
            {appointmentSurgeries.length ? (
              <>
                <div className="title">
                  대기중인 시/수술이 있습니다.
                  <div className="right">
                    <button
                      className="btn btn-basic _small"
                      onClick={onClickCreateSurgery}
                    >
                      시/수술 하기
                    </button>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="title">
                  대기중인 시/수술이 없습니다.
                  <div className="right">
                    <button
                      className="btn btn-basic _small"
                      onClick={onClickCreateSurgery}
                    >
                      시/수술 추가
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
          {componentFlag === null && appointmentSurgeries.length ? (
            <div>
              <DataTableAlpha
                model={models.crm.appointmentSurgeryWaiting}
                data={appointmentSurgeries}
                hideBottom={true}
                params={params}
              />
            </div>
          ) : null}
        </>
      ) : null}
      {obj && componentFlag === 'surgery' ? (
        <>
          <div className="data-input">
            <div className="title">
              {translate('FORM_SURGERY')}{' '}
              {(obj || {}).id ? <span>&#40;ID: {obj.id}&#41;</span> : null}
              <div className="right">
                {/* <button className="btn btn-basic _small" onClick={onClickCreateSurgery}>시/수술 입력</button> */}
              </div>
            </div>
            <div className="card">
              {/* <div className="form-double">
            <div className="form-control">
              <label className="label-required">{translate('SURGERIES_SCHEDULED_AT')}</label>
              <DatePicker
                value={obj.scheduledAt ? new Date(obj.scheduledAt) : setObj({...obj, scheduledAt: moment().format() })}
                onChange={scheduledAt => scheduledAt === null ? onChangeValue('scheduledAt', moment().format()) : onChangeValue('scheduledAt', moment(scheduledAt).format())}
                dateFormat="yyyy/MM/dd"
                locale={hooks.datePickerKoLocale}
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
              />
            </div>
          </div> */}
              <div className="form-double">
                <div className="form-control">
                  <label className="label-required">
                    {translate('SURGERIES_SCHEDULED_AT')}
                  </label>
                  {useCustomerChart.getScheduledAtDatePicker({
                    objScheduledAt: obj.scheduledAt || new Date(),
                    onSelected,
                    onChangeScheduledAt,
                  })}
                </div>
              </div>
              <div className="form-double">
                <div className="form-control">
                  <label>의사</label>
                  <select
                    value={doctors.findIndex(
                      (existing) => existing.id === (obj['doctor'] || {}).id
                    )}
                    onChange={(e) => {
                      // 고객의 담당의사가 있는 경우
                      if (customer && customer.doctor) {
                        if (
                          doctors[e.target.value] &&
                          customer.doctor.id === doctors[e.target.value].id
                        ) {
                          setDoctorFlag(false);
                        } else {
                          setDoctorFlag(true);
                        }
                      }
                      // 고객의 담당의사가 없는 경우
                      else {
                        if (e.target.value === 'null') {
                          setDoctorFlag(false);
                        } else {
                          setDoctorFlag(true);
                        }
                      }
                      obj['doctor'] = doctors[e.target.value];
                      setObj({ ...obj });
                    }}
                  >
                    <option value={-1} hidden disabled>
                      의사를 선택하세요.
                    </option>
                    <option value="null">-</option>
                    {(doctors || []).map((o, idx) => (
                      <option key={idx} value={idx}>
                        {' '}
                        {o.name}{' '}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-control">
                  <label>어시스트</label>
                  <select
                    value={facialist.findIndex(
                      (existing) => existing.id === (obj['facialist'] || {}).id
                    )}
                    onChange={(e) => {
                      obj['facialist'] = facialist[e.target.value];
                      setObj({ ...obj });
                    }}
                  >
                    <option value={-1} hidden disabled>
                      어시스트를 선택하세요.
                    </option>
                    <option value="null">-</option>
                    {(facialist || []).map((o, idx) => (
                      <option key={idx} value={idx}>
                        {' '}
                        {o.name}{' '}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              {doctorFlag ? (
                <div className="form-wide m-b-20">
                  <span className="m-r-20">
                    {doctorFlag ? ' [의사] ' : null}
                    {'를 해당 고객의 담당으로 지정하시겠습니까?'}
                  </span>
                  <span className="m-r-20">
                    <RadioInput
                      name="saveCustomer"
                      checked={radioValue}
                      onChange={() => setRadioValue(true)}
                    />{' '}
                    예
                  </span>
                  <span className="m-r-20">
                    <RadioInput
                      name="saveCustomer"
                      checked={!radioValue}
                      onChange={() => setRadioValue(false)}
                    />{' '}
                    아니오
                  </span>
                </div>
              ) : null}

              {!obj.id ? ( //시수술 생성시 진행할 시/수술 보여주기
                <div
                  className="form-control form-full"
                  style={{ width: '1100px' }}
                >
                  <div className="flex-row select-surgery">
                    <label className="label-required">진행할 시/수술</label>
                    {!obj.id ? (
                      <button
                        className="btn btn-basic _small"
                        onClick={onClickCreateSurgeries}
                      >
                        시/수술추가
                      </button>
                    ) : null}
                  </div>
                  <DataTableAlpha
                    model={models.crm.appointmentSurgeryMinified}
                    data={surgeryItems}
                    hideBottom={true}
                    hideAllCheck={true}
                    onAction={onSelectSurgeryAction}
                  />
                </div>
              ) : null}

              {obj.id ? ( //시수술 수정시 저장되어있는 시/수술 보여주기
                <div
                  className="form-control form-full"
                  style={{ width: '1100px' }}
                >
                  <label className="label-required">진행할 시/수술</label>
                  <DataTableAlpha
                    model={models.crm.surgeriesUpdate}
                    data={obj.surgeryTreatmentItems.map((v) => {
                      v.$$checked = true;
                      return v;
                    })}
                    hideBottom={true}
                    hideAllCheck={true}
                  />
                </div>
              ) : null}

              <div className="form-wide">
                <div className="form-control">
                  <label>시/수술내용</label>
                  {memoBoilerplateList.length > 0 && (
                    <div className="wrap-btn-boilerplate-memo">
                      {memoBoilerplateList.slice(0, 5).map((v, i) => (
                        <button
                          className="btn btn-sm btn-white _ssmall"
                          key={i}
                          onClick={() =>
                            onChangeValue(
                              'memo',
                              (obj.memo == '<p><br></p>'
                                ? ''
                                : obj.memo || '') + v.contents
                            )
                          }
                        >
                          {v.title.slice(0, 5) +
                            (v.title.length > 5 ? '…' : '')}
                        </button>
                      ))}
                    </div>
                  )}
                  {obj && (
                    <QuillTextField
                      tabName={tabName}
                      key={obj.id}
                      value={obj.memo || ''}
                      setValue={(v) => onChangeValue('memo', v)}
                      setMemoBoilerplateList={setMemoBoilerplateList}
                      placeholder="시/수술내용을 입력하세요."
                    />
                  )}
                </div>
              </div>

              {!obj.id ? (
                <>
                  <div className="form-control m-t-20">
                    <label>다음 진행</label>
                    <div className="flex-row wrap-next-step-btns">
                      <button
                        className={`btn flex-wrap _small m-r-8 ${
                          nextProcess === 'consulting_waiting'
                            ? 'btn-basic'
                            : 'btn-normal'
                        }`}
                        onClick={processChangeToConsultingWaiting}
                      >
                        추가상담대기
                      </button>
                      <button
                        className={`btn flex-wrap _small m-r-8 ${
                          nextProcess === 'payment_waiting'
                            ? 'btn-basic'
                            : 'btn-normal'
                        }`}
                        onClick={() => {
                          setToggleNextProcessBtn('payment_waiting');
                        }}
                      >
                        수납대기
                      </button>
                      <button
                        className={`btn flex-wrap _small m-r-8 ${
                          nextProcess === 'payment_during'
                            ? 'btn-basic'
                            : 'btn-normal'
                        }`}
                        onClick={() => {
                          setToggleNextProcessBtn('payment_during');
                        }}
                      >
                        수납하기
                      </button>
                      <button
                        className={`btn flex-wrap _small m-r-8 ${
                          nextProcess === 'leave' ? 'btn-basic' : 'btn-normal'
                        }`}
                        onClick={() => {
                          setToggleNextProcessBtn('leave');
                        }}
                      >
                        퇴원
                      </button>
                      <button
                        className={`btn flex-wrap _small m-r-8 ${
                          nextProcess === 'surgery_done'
                            ? 'btn-basic'
                            : 'btn-normal'
                        }`}
                        onClick={() => {
                          setToggleNextProcessBtn('surgery_done');
                        }}
                      >
                        완료
                      </button>
                    </div>
                  </div>
                  <SelectNextProcess
                    nextProcess={nextProcess}
                    counselors={counselors}
                    treatmentSelectCounselors={treatmentSelectCounselors}
                    setTreatmentSelectCounselors={setTreatmentSelectCounselors}
                  />
                </>
              ) : null}

              <div className="flex-row wrap-bottom-btns">
                <button
                  className="btn btn-cancel _small m-r-8"
                  onClick={actionInit}
                >
                  {'입력취소'}
                </button>
                <button
                  className="btn btn-basic _small"
                  disabled={`${loadingBtnDisabledFlag ? 'disabled' : ''}`}
                  onClick={beforeSave}
                >
                  시/수술완료
                </button>
              </div>
            </div>
          </div>
        </>
      ) : null}

      <div className="wrap-data-table data-table">
        <div className="title">{translate('LIST_SURGERY')}</div>
        <div className="card">
          <DataTableAlpha
            model={modelSurgeryTab}
            total={total}
            data={surgeries}
            params={params}
            onParams={onParams}
            onAction={onAction}
            bottomPositionInner
            hasLine
          />
        </div>
      </div>
    </div>
  );
};

CustomerSurgeries.propTypes = {
  appointment: PropTypes.object,
  surgery: PropTypes.object,
  customer: PropTypes.object,
  openFlag: PropTypes.object,
  closeFlag: PropTypes.bool,
};

export default observer(CustomerSurgeries);
