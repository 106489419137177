import React, { useState, useCallback } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { translate } from 'filters';
import Checkbox from 'components/app/Checkbox';
import { RadioInput } from 'components/common/RadioInput';
import SelectAutoNotificationCommon from './SelectAutoNotificationCommon';

const ViewAutoNotificationBottom = ({ obj, setObj }) => {
  const [initUiObj] = useState({
    scheduleTimeHour:
      obj.smsScheduleType === 'scheduled'
        ? obj.scheduleTime.split(':')[0]
        : '08',
    scheduleTimeMinute:
      obj.smsScheduleType === 'scheduled'
        ? obj.scheduleTime.split(':')[1]
        : '00',
    disableScheduleTime: obj.smsScheduleType === 'scheduled' ? false : true,
    scheduleDaysBefore: String(obj.scheduleDaysBefore).toLowerCase(),
  });

  const [uiObj, setUiObj] = useState(initUiObj);

  const onChangeHandler = useCallback(
    (e) => {
      const { name, value } = e.target;
      const scheduleTimeItemNames = ['scheduleTimeHour', 'scheduleTimeMinute'];

      //전송상황: name = smsSituation
      //value = 고객등록완료: register / 예약완료 : reserved / 예약취소 : cancel
      //미방문 : unvisited / 퇴원 : leave
      if (scheduleTimeItemNames.indexOf(name) !== -1) {
        const timeObj = Object.assign(
          ...scheduleTimeItemNames.map((name) => ({ [name]: uiObj[name] })),
          { [name]: value }
        );
        const scheduleTime = `${timeObj.scheduleTimeHour}:${timeObj.scheduleTimeMinute}`;

        setUiObj((uiObj) => ({
          ...uiObj,
          ...timeObj,
        }));

        setObj((obj) => ({
          ...obj,
          scheduleTime: scheduleTime,
        }));
        return;
      }

      if (name === 'scheduleDaysBefore') {
        setUiObj((uiObj) => ({
          ...uiObj,
          [name]: value,
        }));
        setObj((obj) => ({
          ...obj,
          scheduleDaysBefore: value === 'true',
        }));
        return;
      }

      if (name === 'smsScheduleType') {
        setUiObj((uiObj) => ({
          ...uiObj,
          disableScheduleTime: obj.smsScheduleType === 'scheduled',
        }));
      }

      if (name === 'smsSituation') {
        if (
          value === 'register' ||
          value === 'cancel' ||
          value === 'unvisited' ||
          value === 'leave'
        ) {
          //고객등록완료(register), 예약취소(cancel), 미방문(unvisited), 퇴원(leave)
          // 시에는 전송타입 예약 버튼 비활성화 및 예약 전송시간 초기화
          setUiObj({
            initUiObj,
            disableScheduleTime: true,
          });

          let changeObj = {
            ...obj,
            [name]: value,
            scheduleDaysDelta: 1,
            scheduleDaysBefore: true,
            scheduleTime: '08:00',
            smsScheduleType: 'immediate',
          };

          if (value === 'register') {
            delete changeObj.departmentId;
            changeObj = {
              ...changeObj,
              departmentExtendAll: false,
            };
          }

          setObj({ ...changeObj });
        }
      }

      if (name === 'departmentId') {
        let departmentExtendAll = e.target.departmentExtendAll;
        if (value === null) {
          departmentExtendAll = false;
        }
        setObj((obj) => ({
          ...obj,
          departmentId: value,
          departmentExtendAll: departmentExtendAll,
        }));
      }

      setObj((obj) => ({
        ...obj,
        [name]: value,
      }));
    },
    [setObj, uiObj, initUiObj, obj]
  );

  const onChangeCheck = () => {
    setObj((obj) => ({
      ...obj,
      departmentExtendAll: !obj.departmentExtendAll,
    }));
  };

  return (
    <div className={`app-view-bottom  `}>
      <div className="sms-situation">
        <div className="label">
          <span className="label-required">
            {translate('LABEL_SMS_SITUATION')}
          </span>
        </div>
        <div className="input-select">
          <SelectAutoNotificationCommon
            placeholder={translate('PLACEHOLDER_SMS_SITUATION')}
            name="smsSituation"
            value={obj.smsSituation}
            onChange={onChangeHandler}
          />
        </div>
      </div>
      <div className="schedule-type">
        <div className="label">
          <span className="label-required">
            {translate('LABEL_SCHEDULE_TYPE')}
          </span>
        </div>
        <div className="input-select">
          <label>
            <RadioInput
              name="smsScheduleType"
              value="immediate"
              onChange={onChangeHandler}
              checked={obj.smsScheduleType === 'immediate'}
            />
            <span>{translate('LABEL_SCHEDULE_TYPE_IMMEDIATE')}</span>
          </label>
          <label>
            <RadioInput
              name="smsScheduleType"
              value="scheduled"
              onChange={onChangeHandler}
              checked={obj.smsScheduleType === 'scheduled'}
              disabled={
                obj.smsSituation === 'register' ||
                obj.smsSituation === 'cancel' ||
                obj.smsSituation === 'unvisited' ||
                obj.smsSituation === 'leave'
              }
            />
            <span>{translate('LABEL_SCHEDULE_TYPE_SCHEDULED')}</span>
          </label>
        </div>
      </div>
      <div className="schedule-info">
        <div className="label">
          <span className=""></span>
        </div>
        <div className="input-select auto-send-notification">
          <div className="schedule-info-select schedule-info-days-delta">
            <SelectAutoNotificationCommon
              disabled={uiObj.disableScheduleTime}
              name="scheduleDaysDelta"
              onChange={onChangeHandler}
              value={obj.scheduleDaysDelta}
            />
          </div>
          <div className="schedule-info-select schedule-info-days-before">
            <SelectAutoNotificationCommon
              disabled={uiObj.disableScheduleTime}
              name="scheduleDaysBefore"
              onChange={onChangeHandler}
              value={uiObj.scheduleDaysBefore}
            />
          </div>
          <div className="schedule-info-select schedule-info-time-hour">
            <SelectAutoNotificationCommon
              disabled={uiObj.disableScheduleTime}
              name="scheduleTimeHour"
              onChange={onChangeHandler}
              value={uiObj.scheduleTimeHour}
            />
          </div>
          <div>
            <span>:</span>
          </div>
          <div className="schedule-info-select schedule-info-time-minute">
            <SelectAutoNotificationCommon
              disabled={uiObj.disableScheduleTime}
              name="scheduleTimeMinute"
              onChange={onChangeHandler}
              value={uiObj.scheduleTimeMinute}
            />
          </div>
        </div>
      </div>
      {(obj.smsSituation === 'reserved' ||
        obj.smsSituation === 'cancel' ||
        obj.smsSituation === 'unvisited' ||
        obj.smsSituation === 'leave') && (
        <React.Fragment>
          <div className="schedule-info">
            <div className="label">
              <span className="">전송부서</span>
            </div>
            <div className="input-select">
              <SelectAutoNotificationCommon
                placeholder={'전체'}
                name="departmentId"
                value={obj.departmentId}
                onChange={onChangeHandler}
              />
            </div>
          </div>
          {obj.departmentId && (
            <div className="schedule-info">
              <div className="label">
                <span className=""></span>
              </div>
              <div className="input-select">
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '230px',
                  }}
                >
                  <Checkbox
                    className="checkbox-blue"
                    checked={obj.departmentExtendAll}
                    clickHandler={() => onChangeCheck()}
                  />
                  <span className="checkbox-label m-l-8">
                    해당 부서는 전체로 설정된 문자도 보내기
                  </span>
                </div>
              </div>
            </div>
          )}
        </React.Fragment>
      )}
    </div>
  );
};

ViewAutoNotificationBottom.propTypes = {
  obj: PropTypes.object,
  setObj: PropTypes.func,
};

export default React.memo(observer(ViewAutoNotificationBottom));
