import React, { useState } from 'react';
import { pluralize, $case, translate } from 'filters';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';

const SelectNextConsulting = ({
  counselors,
  appointmentCounselor,
  setTreatmentSelectCounselors,
}) => {
  const [selectCounselor, setSelectCounselor] = useState(
    appointmentCounselor || {}
  );
  const onChangeSelectDoctor = (e) => {
    setSelectCounselor(counselors[e.target.value]);
    setTreatmentSelectCounselors(counselors[e.target.value]);
  };

  return (
    <div className="form-control m-t-16">
      <label>상담사</label>
      <select
        className="m-l-8"
        value={(counselors || []).findIndex(
          (option) => option.id === (selectCounselor || {}).id
        )}
        onChange={onChangeSelectDoctor}
      >
        <option value={undefined}>
          {translate($case.toConst(pluralize('counselors')))}를 선택하세요.
        </option>
        {(counselors || []).map((item, idx) => (
          <option key={idx} value={idx}>
            {item.name || item.content}
          </option>
        ))}
      </select>
    </div>
  );
};

SelectNextConsulting.propTypes = {
  counselors: PropTypes.array,
  appointmentCounselor: PropTypes.object,
  setTreatmentSelectCounselors: PropTypes.func,
};

export default observer(SelectNextConsulting);
