import React, { useState, useCallback, useEffect, useRef } from 'react';
import { observer } from 'mobx-react';
import hooks from 'hooks';
import QuillTextField from 'components/quill/QuillTextField';
import QuillText from 'components/quill/QuillText';
import moment from 'moment';
import PropTypes from 'prop-types';
import { translate } from 'filters';
import { $qb } from 'scripts/querybuilder';
import { useAuth } from 'store/auth';
import { useModal } from 'hooks/useModal';
import { useServices } from 'hooks/useServices';
import { useToast } from 'hooks/useToast';

const Notices = ({ startAt }) => {
  const auth = useAuth();
  const modal = useModal();
  const services = useServices();
  const toast = useToast();
  const forceUpdate = hooks.useForceUpdate();
  const [notices, setNotices] = useState(null);
  const [memo, setMemo] = useState('');

  const quillRef = useRef();

  const loadNotices = useCallback(async () => {
    const query = $qb().customParam(
      'viewAt',
      moment(startAt).format('YYYY-MM-DD')
    );

    const resp = await services.crm.crud.notice.all(query.build());
    if (!resp) return;

    setNotices(resp.data);
  }, [services, startAt]);

  const update = async (notice) => {
    try {
      await services.crm.crud.notice.update(notice);

      modal
        .confirm({
          type: 'SUCCESS',
          msg: '업데이트되었습니다.',
        })
        .then(() => {
          loadNotices();
        });
    } catch (e) {
      console.log(e.description);

      modal
        .confirm({
          type: 'ERROR',
          msg: e.description,
        })
        .then(() => {
          loadNotices();
        });
    }
  };

  const onClickEditOrSave = (notice) => {
    notice.$$editing = !notice.$$editing;
    forceUpdate();

    if (!notice.$$editing) update(notice);
  };

  const onClickDelete = (notice) => {
    const onConfirm = async () => {
      try {
        await services.crm.crud.notice.delete(notice.id);

        modal
          .confirm({
            type: 'SUCCESS',
            msg: '삭제되었습니다.',
          })
          .then(() => {
            loadNotices();
          });
      } catch (e) {
        console.log(e.description);
        modal.confirm({
          type: 'ERROR',
          msg: e.description,
        });
      }
    };

    modal
      .basic({
        body: translate('DELETE_CONFIRM'),
        buttons: [
          {
            text: 'CANCEL',
            class: 'btn-default',
          },
          {
            text: 'DELETE',
            class: 'btn-danger',
          },
        ],
      })
      .then((idx) => {
        if (idx === 1) onConfirm();
      });
  };

  const onClickPin = (notice) => {
    notice.pinnedAt = notice.pinnedAt ? null : moment().format();
    update(notice);
  };

  const post = async (notice) => {
    if (!notice.memo) return toast.error('내용을 입력하세요');

    let payload = {
      ...notice,
      viewAt: moment(startAt).format('YYYY-MM-DDTHH:mm:ss'),
    };
    try {
      await services.crm.crud.notice.create(payload);

      modal
        .confirm({
          type: 'SUCCESS',
          msg: '생성되었습니다.',
        })
        .then(() => {
          setMemo('');
          quillRef.current.getEditor().setContents([]);
          loadNotices();
        });
    } catch (e) {
      console.log(e.description);

      modal.confirm({
        type: 'ERROR',
        msg: e.description,
      });
    }
  };

  const onKeyPressOnTextarea = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      post({ memo });
    }
  };

  const editNotice = (e, notice, action) => {
    e.target.parentElement.style.display = 'none';
    switch (action) {
      case 'edit':
        onClickEditOrSave(notice);
        break;
      case 'delete':
        onClickDelete(notice);
        break;
      case 'pin':
        onClickPin(notice);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    loadNotices();
  }, [loadNotices]);

  return (
    <div className="notices">
      <label className="title">{translate('MEMO')}</label>
      <div className="notice-items">
        {(notices || []).length > 0 ? (
          notices.map((notice, idx) => (
            <div key={idx} className="notice">
              <div className="top flex-row">
                <div className="user-name">{notice.user.name}</div>
                {notice.user.id === (auth.me || {}).id && (
                  <div className="buttons">
                    <span
                      className="btn-more"
                      onClick={(e) =>
                        (e.target.nextElementSibling.style.display = 'block')
                      }
                    >
                      <span className="a11y">메뉴</span>
                    </span>
                    <ul className="more" style={{ display: 'none' }}>
                      <li onClick={(e) => editNotice(e, notice, 'edit')}>
                        {translate(notice.$$editing ? 'SAVE' : 'EDIT')}
                      </li>
                      <li onClick={() => onClickDelete(notice)}>
                        {translate('DELETE')}
                      </li>
                      <li onClick={() => onClickPin(notice)}>
                        {translate('PIN')}
                      </li>
                    </ul>
                  </div>
                )}
              </div>
              {!notice.$$editing ? (
                <QuillText value={notice.memo || ''} />
              ) : (
                <QuillTextField
                  key={notice.id}
                  value={notice.memo}
                  setValue={(v) => {
                    notice.memo = v;
                  }}
                />
              )}
            </div>
          ))
        ) : (
          <div className="notices-empty">공지사항이 없습니다</div>
        )}
      </div>
      <QuillTextField
        innerRef={quillRef}
        className="quill-min-height"
        onKeyPress={onKeyPressOnTextarea}
        placeholder="공유할 내용을 입력 후 엔터를 누르세요. (줄바꿈은 Shift + Enter)"
        value={memo}
        setValue={(v) => {
          setMemo(v);
        }}
      />
      <button
        className="btn btn-wrt _full m-t-8"
        onClick={() => post({ memo })}
        disabled={!memo}
      >
        등록
      </button>
    </div>
  );
};

Notices.propTypes = {
  startAt: PropTypes.object,
};

export default observer(Notices);
