import React, { useState, useEffect, useCallback } from 'react';
import { observer } from 'mobx-react';
import HeaderRowSpanDataTable from 'components/data-table/HeaderRowSpanDataTable';
import hooks from 'hooks';
import PropTypes from 'prop-types';
import moment from 'moment';
import modelDailySettlementsDetail from 'models/daily-settlements-detail';
import { $qb } from 'scripts/querybuilder';
import { useServices } from 'hooks/useServices';
import { useToast } from 'hooks/useToast';

const DailySettlementsDetailList = ({ startAt, endAt }) => {
  const services = useServices();
  const toast = useToast();
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const defaultParams = $qb()
    .limit(20)
    .orderBy('paidAt desc')
    .customParam('paidStartAt', moment(startAt).format('YYYY-MM-DD'))
    .customParam('paidEndAt', moment(endAt).format('YYYY-MM-DD'));

  const [params, setParams] = useState(defaultParams);
  const [searchValue, setSearchValue] = useState({
    paidStartAt: moment(startAt).format('YYYY-MM-DD'),
    paidEndAt: moment(endAt).format('YYYY-MM-DD'),
  });

  const onChangeDateAt = () => {
    const paidStartAt = moment(startAt).format('YYYY-MM-DD');
    const paidEndAt = moment(endAt).format('YYYY-MM-DD');
    let searchParam = { ...searchValue, paidStartAt, paidEndAt };
    if (searchParam.paidStartAt === 'Invalid date') {
      // eslint-disable-next-line no-unused-vars
      const { paidStartAt: deletedKey, ...otherKeys } = searchParam;
      searchParam = otherKeys;
    }

    if (searchParam.paidEndAt === 'Invalid date') {
      // eslint-disable-next-line no-unused-vars
      const { paidEndAt: deletedKey, ...otherKeys } = searchParam;
      searchParam = otherKeys;
    }

    params.queryParams = {
      limit: params.queryParams.limit,
      orderBy: params.queryParams.orderBy,
      ...searchParam,
    };

    setSearchValue(searchParam);
    setParams(params);
    dailyCallApi();
  };

  const dailyCallApi = useCallback(async () => {
    const resp = await services.crm.settlements.settlements.detail_v2(
      params.build()
    );
    if (!resp) return;

    // 전체 레코드 수 - ( (현재 페이지 번호 - 1) * 한 페이지당 보여지는 레코드 수 + 현재 게시물 출력 순서 )
    //total ( (page-1) * limit + i)
    let page = params.queryParams.page || 1;
    let limit = params.queryParams.limit;

    let _data = resp.data.map((v, i) => {
      v.no = resp.pagination.total - ((page - 1) * limit + i);
      v.id = i;
      v.sumAmount =
        v.creditCardAmount +
        v.debitCardAmount +
        v.cashAmount +
        v.bankTransferAmount;
      return v;
    });

    setData([..._data]);
    setTotal(resp.pagination.total);
  }, [params]);

  useEffect(() => {
    dailyCallApi();
  }, [dailyCallApi]);

  useEffect(() => {
    onChangeDateAt();
  }, [startAt, endAt]);

  const onParams = (p) => {
    setParams(p);
    dailyCallApi();
  };

  const onAction = (obj) => {
    const { row, key } = obj; // , rowIdx, event
    if (key.title === '고객명') {
      openCustomerPaymentChart(row.customerId);
    }
  };

  const customerDetailCallApi = async (customerId) => {
    try {
      const resp = await services.crm.customer.detail(customerId);
      if (!resp) return;

      return resp.data;
    } catch (e) {
      console.log(e.description);
      if (e.dscription === 'CustomerNotFound') {
        toast.error('삭제된 고객입니다.');
      }
    }
  };

  const openCustomerPaymentChart = useCallback(async (customerId) => {
    const customer = await customerDetailCallApi(customerId);
    hooks.openCustomerChart({ customer, selectTab: 'PAYMENT' });
  }, []);

  const onClickHeaderRow = (row) => {
    let orderBy = `${row.column} ${row.order}`;
    params.orderBy(orderBy);
    dailyCallApi();
  };

  return (
    <div className="settlements-container m-t-16">
      <div className="title">상세 내역</div>
      <HeaderRowSpanDataTable
        rowKey="id"
        model={modelDailySettlementsDetail}
        data={data}
        total={total}
        bordered={true}
        params={params}
        onParams={onParams}
        onAction={onAction}
        onClickHeaderRow={onClickHeaderRow}
      />
    </div>
  );
};

DailySettlementsDetailList.propTypes = {
  startAt: PropTypes.object,
  endAt: PropTypes.object,
};

export default observer(DailySettlementsDetailList);
