import * as $http from 'axios';

// https://docs.google.com/spreadsheets/d/1JYCtl4NKTEDloZRmE_HRrjmWp6XzLTE3_SPr7sp_-nE/edit#gid=1339444833

const call = {
  history: (params) => $http.get('/centrex/call_history', { params }),
  memo: (callId) =>
    $http.get('/centrex/call_memo_data', {
      params: {
        callId,
      },
    }),
  update: (params) => $http.put('/centrex/update_call_status', params),
  cancel: (params) => $http.post('/centrex/call_cancel', params),
  join: (params) => $http.post('/centrex/new_customer', params),
  customer_call_history: (id, params) =>
    $http.get(`/centrex/customer_call_history/${id}`, { params }),
};

export default call;
