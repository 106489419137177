import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import CalendarCell from './CalendarCell';
//import { AppointmentBoardState } from 'store/appointmentBoardState';
import { AppointmentBoardStateContext } from './AppointmentBoards';

const CalendarCells = observer(({ department, date, index }) => {
  /**
   * @type {AppointmentBoardState }
   */
  const state = useContext(AppointmentBoardStateContext);

  return (
    <>
      {state.calendarTimes.map((time) => (
        <CalendarCell
          key={time}
          department={department}
          date={date}
          startTime={time}
          index={index}
        />
      ))}
    </>
  );
});

export default CalendarCells;
