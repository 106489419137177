import { makeAutoObservable } from 'mobx';

export class Event {
  events = {};

  constructor() {
    makeAutoObservable(this);
  }

  emit(eventName, args) {
    if (this.events[eventName])
      this.events[eventName].forEach((foo) => foo(args));
  }

  on(eventName, callback) {
    this.events[eventName] = (this.events[eventName] || []).concat([callback]);
  }

  off(eventName) {
    delete this.events[eventName];
  }
}

export default new Event();
