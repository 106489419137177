import React, { useState, useEffect, useCallback } from 'react';
import { observer } from 'mobx-react';
import HeaderRowSpanDataTable from 'components/data-table/HeaderRowSpanDataTable';
import moment from 'moment';
import models from 'models';
import { $qb } from 'scripts/querybuilder';
import { useServices } from 'hooks/useServices';
import DatePicker from 'components/common/DatePicker';

const SalesStatistics = () => {
  const services = useServices();

  const defaultParams = $qb()
    .limit(20)
    .orderBy('paidAt desc')
    .customParam(
      'searchStartAt',
      moment(new Date().setDate(new Date().getDate() - 31)).format('YYYY-MM-DD')
    )
    .customParam(
      'searchEndAt',
      moment(new Date().setDate(new Date().getDate() - 1)).format('YYYY-MM-DD')
    );
  const [params, setParams] = useState(defaultParams);
  const [searchValue, setSearchValue] = useState({
    searchStartAt: moment(new Date().setDate(new Date().getDate() - 31)).format(
      'YYYY-MM-DD'
    ),
    searchEndAt: moment(new Date().setDate(new Date().getDate() - 1)).format(
      'YYYY-MM-DD'
    ),
  });
  const [startAt, setStartAt] = useState(
    new Date().setDate(new Date().getDate() - 31)
  );
  const [endAt, setEndAt] = useState(
    new Date().setDate(new Date().getDate() - 1)
  );
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [calendarUnit, setCalendarUnit] = useState('daily');
  const [model, setModel] = useState(models.crm.salesStatisticsDaily);

  const dailyCallApi = useCallback(async () => {
    const resp = await services.crm.statistics.statistics.daily_v2(
      params.build()
    );
    if (!resp) return;

    resp.data.map((v) => {
      v.cardAmount = v.creditCardAmount + v.debitCardAmount;
      v.totalAmount = v.salesFigures - v.refundAmount;
      return v;
    });

    setData(resp.data);
    setTotal(resp.pagination.total);
    setModel(models.crm.salesStatisticsDaily);
  }, [services.crm.statistics, params, models.crm.salesStatisticsDaily]);

  useEffect(() => {
    dailyCallApi();
  }, [dailyCallApi]);

  const weeklyCallApi = useCallback(async () => {
    params.queryParams = {
      ...params.queryParams,
      orderBy: 'weekYear desc',
    };
    const resp = await services.crm.statistics.statistics.weekly_v2(
      params.build()
    );
    if (!resp) return;

    resp.data.map((v) => {
      v.cardAmount = v.creditCardAmount + v.debitCardAmount;
      v.totalAmount = v.salesFigures - v.refundAmount;
      return v;
    });

    setData(resp.data);
    setTotal(resp.pagination.total);
    setModel(models.crm.salesStatisticsWeekly);
  }, [services.crm.statistics, params, models.crm.salesStatisticsWeekly]);

  const monthlyCallApi = useCallback(async () => {
    params.queryParams = {
      ...params.queryParams,
      searchStartAt: moment(params.queryParams.searchStartAt).format(
        'YYYY-MM-DD'
      ),
      searchEndAt: moment(params.queryParams.searchEndAt).format('YYYY-MM-DD'),
    };
    const resp = await services.crm.statistics.statistics.monthly_v2(
      params.build()
    );
    if (!resp) return;

    resp.data.map((v) => {
      v.cardAmount = v.creditCardAmount + v.debitCardAmount;
      v.totalAmount = v.salesFigures - v.refundAmount;
      return v;
    });

    setData(resp.data);
    setTotal(resp.pagination.total);
    setModel(models.crm.salesStatisticsMonthly);
  }, [services, params]);

  const onParams = (p) => {
    setParams(p);
    callApi(calendarUnit);
  };

  const onClickHeaderRow = (row) => {
    let orderBy = `${row.column} ${row.order}`;
    let searchParam = searchValue;
    params.orderBy(orderBy);
    params.queryParams = {
      limit: params.queryParams.limit,
      orderBy: params.queryParams.orderBy,
      ...searchParam,
    };
    setParams(params);
    callApi(calendarUnit);
  };

  const callApi = (type) => {
    if (type === 'monthly') {
      monthlyCallApi();
    } else if (type === 'weekly') {
      weeklyCallApi();
    } else if (type === 'daily') {
      dailyCallApi();
    }
  };

  const onChangeStartAt = (date) => {
    const searchStartAt = moment(date).format('YYYY-MM-DD');
    setStartAt(date);
    setSearchValue({ ...searchValue, searchStartAt });
  };

  const onChangeEndAt = (date) => {
    const searchEndAt = moment(date).format('YYYY-MM-DD');
    setEndAt(date);
    setSearchValue({ ...searchValue, searchEndAt });
  };

  const onClickSearch = (type) => {
    let searchParam = searchValue;
    if (searchParam.searchStartAt === 'Invalid date') {
      // eslint-disable-next-line no-unused-vars
      const { searchStartAt: deletedKey, ...otherKeys } = searchParam;
      searchParam = otherKeys;
    }

    if (searchParam.searchEndAt === 'Invalid date') {
      // eslint-disable-next-line no-unused-vars
      const { searchEndAt: deletedKey, ...otherKeys } = searchParam;
      searchParam = otherKeys;
    }

    params.queryParams = {
      limit: params.queryParams.limit,
      orderBy: params.queryParams.orderBy,
      ...searchParam,
    };

    setSearchValue(searchParam);
    let unit = typeof type === 'string' ? type : calendarUnit;
    setCalendarUnit(unit);
    setParams(params);
    callApi(unit);
  };

  return (
    <div className={`statistics list inquiry`}>
      <div className="page-navi">
        <span className="title">통계</span>
        <span className="title">매출 통계</span>
      </div>
      <div className="route-top">
        <div className="search-bar">
          <div className="search-btns">
            <button
              className={`btn ${
                calendarUnit === 'daily' ? 'btn-basic m-r-8' : 'btn-normal'
              } _small`}
              onClick={() => onClickSearch('daily')}
            >
              일간
            </button>
          </div>
          <div className="search-btns">
            <button
              className={`btn ${
                calendarUnit === 'weekly' ? 'btn-basic m-r-8' : 'btn-normal'
              } _small`}
              onClick={() => onClickSearch('weekly')}
            >
              주간
            </button>
          </div>
          <div className="search-btns">
            <button
              className={`btn ${
                calendarUnit === 'monthly' ? 'btn-basic m-r-8' : 'btn-normal'
              } _small`}
              onClick={() => onClickSearch('monthly')}
            >
              월간
            </button>
          </div>
          <div className="search-item">
            <DatePicker
              className="text-center"
              placeholderText="날짜선택"
              todayButton="오늘"
              maxDate={new Date().setDate(new Date().getDate() - 1)}
              value={startAt}
              onChange={onChangeStartAt}
            />
          </div>
          <div className="search-item">-</div>
          <div className="search-item">
            <DatePicker
              className="text-center"
              placeholderText="날짜선택"
              todayButton="오늘"
              maxDate={new Date().setDate(new Date().getDate() - 1)}
              value={endAt}
              onChange={onChangeEndAt}
            />
          </div>
          <div className="search-btns">
            <button
              className="btn btn-basic _small search"
              onClick={onClickSearch}
            >
              조회
            </button>
          </div>
        </div>
      </div>
      <div className="sales-statistics-container">
        <HeaderRowSpanDataTable
          rowKey="id"
          model={model}
          data={data}
          total={total}
          params={params}
          onParams={onParams}
          bordered={true}
          onClickHeaderRow={onClickHeaderRow}
        />
      </div>
    </div>
  );
};

export default observer(SalesStatistics);
