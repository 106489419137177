import React from 'react';
import Toast from 'components/app/Toast';
import { observer } from 'mobx-react';
import { BrowserRouter } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import { registerLocale } from 'react-datepicker';
import { ko } from 'date-fns/esm/locale';
import { iconConfig as setIconConfig } from 'assets/configure/icon';
import { hot } from 'react-hot-loader/root';
import { FirebaseAppProvider } from 'reactfire';
import { firebaseConfig } from 'firebaseConfig';
import { RootRouter } from 'route/RootRouter';
import { AuthProvider } from 'store/auth';
import { ClinicCheckProvider } from 'store/clinicCheck';
import { HolidayProvider } from 'store/holiday';
import { useModal } from 'hooks/useModal';
import Modal from 'components/app/Modal';
import withCtiStore from 'components/cti/hoc/withCtiStore';

registerLocale('ko', ko);
setIconConfig();

const App = () => {
  const modal = useModal();
  return (
    <FirebaseAppProvider firebaseConfig={firebaseConfig}>
      <AuthProvider>
        <ClinicCheckProvider>
          <BrowserRouter>
            <HolidayProvider>
              <RootRouter />
              {(modal.list || []).length > 0
                ? modal.list.map((v, idx) => <Modal key={idx} modal={v} />)
                : null}
              <Toast />
            </HolidayProvider>
          </BrowserRouter>
        </ClinicCheckProvider>
      </AuthProvider>
    </FirebaseAppProvider>
  );
};

export default process.env.NODE_ENV === 'development'
  ? hot(withCtiStore(observer(App)))
  : withCtiStore(observer(App));
