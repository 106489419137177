import { currency } from 'filters';

export default {
  keys: [
    {
      title: 'ACTION',
      type: 'checkbox',
    },
    {
      title: '카테고리',
      column: 'category__name',
    },
    {
      title: '시/수술명(제품명)',
      column: 'name',
    },
    {
      title: '개수',
      column: 'quantity',
      filter: (o) => {
        return o.quantity === undefined ? '-' : o.quantity;
      },
    },
    {
      title: '과세여부',
      column: 'vatIncluded',
      filter: (o) => (o.vatIncluded ? '과세' : '비과세'),
    },
    {
      title: '가격',
      column: 'price',
      filter: (o) => {
        return `<button class="btn btn-primary btn-sm btn-price">가격변경</button>₩${currency(
          o.price
        )}`;
      },
    },
    {
      title: 'VAT포함가격',
      column: 'priceVatInclude',
      filter: (o) => {
        return `₩${currency(o.priceVatInclude)}`;
      },
    },
    {
      title: '할인',
      column: 'discountAmount',
      filter: (o) => {
        return `<button class="btn btn-primary btn-sm btn-discount">할인입력</button>₩${currency(
          o.discountAmount || 0
        )}`;
      },
    },
    {
      title: '청구액(VAT포함)',
      column: 'requestAmount',
      filter: (o) => {
        if (o.requestAmount === undefined) {
          return `₩${currency(o.priceVatInclude)}`;
        } else {
          return `₩${currency(o.requestAmount)}`;
        }
      },
    },
  ],
};
