import React, { useState, useEffect, useCallback } from 'react';
import { observer } from 'mobx-react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import moment from 'moment';
import { $qb } from 'scripts/querybuilder';
import { useEvent } from 'hooks/useEvent';
import { useModal } from './useModal';
import DataTableAlpha from 'components/data-table/DataTableAlpha';
import DatePicker from 'components/common/DatePicker';

const Input = styled.input`
  width: 100%;
  height: 34px;
  padding: 8px 20px !important;
  border-color: #d7e3f1;
  font-size: 12px;
  min-height: 10px;
  border-radius: 4px;
  border: 1px solid #dee2ec;

  ${({ disabled }) =>
    disabled &&
    css`
      background-color: #efeff4 !important;
      color: #ceced7 !important;
    `}
`;

const SmsTableList = ({
  api,
  model,
  modelName,
  queryParam,
  showSmsSelect = true,
  param,
}) => {
  const eventBus = useEvent();
  const modal = useModal();

  // const [showSelectPeriod, setShowSelectPeriod] = useState(false)
  const [requestStartAt, setRequestStartAt] = useState(
    new Date().setDate(new Date().getDate() - 7)
  );
  const [requestEndAt, setRequestEndAt] = useState(new Date());
  const defaultParams = $qb()
    .limit(10)
    .orderBy('id desc')
    .customParam('requestStartAt', moment(requestStartAt).format('YYYY-MM-DD'))
    .customParam('requestEndAt', moment(requestEndAt).format('YYYY-MM-DD'));
  const [data, setData] = useState(null);
  const [total, setTotal] = useState(null);
  const [params, setParams] = useState(defaultParams);
  const [searchValue, setSearchValue] = useState({
    requestStartAt: moment(new Date().setDate(new Date().getDate() - 7)).format(
      'YYYY-MM-DD'
    ),
    requestEndAt: moment(new Date()).format('YYYY-MM-DD'),
  });

  const callApi = useCallback(async () => {
    if (param !== undefined) {
      const key = Object.keys(param)[0];
      const value = param[Object.keys(param)[0]];
      params.customParam(key, value);
    }
    const resp = await api(params.build());
    if (!resp) return;

    setData(resp.data);
    setTotal(resp.total);
  }, [params, api, param]);

  const onParams = (p) => {
    setParams(p);
    callApi();
  };

  const onAction = (obj) => {
    const row = obj.row;
    const event = obj.event;
    const classList = event.target.classList;

    if (classList.contains('btn-taxbill')) {
      openModalTaxBillConfirm(row);
      return;
    }
  };

  const openModalTaxBillConfirm = (data) => {
    modal.slot({
      title: '세금계산서',
      preparedDom: () => (
        <>
          <div className="body">
            <div className="form-control">
              <label>세금계산서 발행일</label>
              <Input
                value={
                  data.taxBillPublishedAt !== null
                    ? moment(data.taxBillPublishedAt).format('YYYY-MM-DD')
                    : ''
                }
                disabled={true}
              />
            </div>
            <div className="form-control">
              <label>상호(사업명)</label>
              <Input value={data.clinicName} disabled={true} />
            </div>
            <div className="form-control">
              <label>사업자등록번호</label>
              <Input value={data.employerNo} disabled={true} />
            </div>
            <div className="form-control">
              <label>대표자</label>
              <Input value={data.ownerName} disabled={true} />
            </div>
            <div className="form-control">
              <label>세금계산서 발행 이메일</label>
              <Input defaultValue={data.contactEmail || ''} disabled={true} />
            </div>
            <div className="form-control">
              <label>담당자 이름</label>
              <Input defaultValue={data.contactManager || ''} disabled={true} />
            </div>
            <div className="form-control">
              <label>담당자 전화번호</label>
              <Input defaultValue={data.contactTel || ''} disabled={true} />
            </div>
          </div>
        </>
      ),
      showCloseButton: false,
      buttons: [{ text: '확인', class: 'btn-primary' }],
    });
  };

  useEffect(() => {
    eventBus.on('callApi', callApi);
    if (queryParam !== undefined) {
      for (let i in queryParam) {
        params.queryParams = { ...params.queryParams, ...queryParam[i] };
      }
      setParams(params);
    }

    callApi();

    return () => eventBus.off('callApi');
  }, [queryParam, params, callApi]);

  const onClickSelectDate = (v) => {
    const now = new Date();
    if (v === 'month') {
      onChangeDate(
        new Date(now.getFullYear(), now.getMonth(), 1),
        new Date(now.getFullYear(), now.getMonth() + 1, 0)
      );
    } else if (v === 'week') {
      const nowDayOfWeek = now.getDay();
      const nowDay = now.getDate();
      const nowMonth = now.getMonth();
      let nowYear = now.getYear();
      nowYear += nowYear < 2000 ? 1900 : 0;
      const weekStartDate = new Date(nowYear, nowMonth, nowDay - nowDayOfWeek);
      const weekEndDate = new Date(
        nowYear,
        nowMonth,
        nowDay + (6 - nowDayOfWeek)
      );
      onChangeDate(weekStartDate, weekEndDate);
    }
  };

  const onChangeDate = (startDate, endDate) => {
    setRequestStartAt(startDate);
    setRequestEndAt(endDate);
    setSearchValue({
      ...searchValue,
      requestStartAt: moment(startDate).format('YYYY-MM-DD'),
      requestEndAt: moment(endDate).format('YYYY-MM-DD'),
    });
  };

  const onChangeStartAt = (date) => {
    const requestStartAt = moment(date).format('YYYY-MM-DD');
    setRequestStartAt(date);
    setSearchValue({ ...searchValue, requestStartAt });
  };

  const onChangeEndAt = (date) => {
    const requestEndAt = moment(date).format('YYYY-MM-DD');
    setRequestEndAt(date);
    setSearchValue({ ...searchValue, requestEndAt });
  };

  const onClickSearch = () => {
    let searchParam = searchValue;
    if (searchParam.requestStartAt === 'Invalid date') {
      // eslint-disable-next-line unused-imports/no-unused-vars
      const { requestStartAt: deletedKey, ...otherKeys } = searchParam;
      searchParam = otherKeys;
    }

    if (searchParam.requestEndAt === 'Invalid date') {
      // eslint-disable-next-line unused-imports/no-unused-vars
      const { requestEndAt: deletedKey, ...otherKeys } = searchParam;
      searchParam = otherKeys;
    }

    params.queryParams = {
      limit: params.queryParams.limit,
      orderBy: params.queryParams.orderBy,
      ...searchParam,
    };
    onParams(params);
    setSearchValue(searchParam);
  };

  return (
    <div className="sms-list">
      <div className="route-top">
        <span className="title">{modelName}</span>
      </div>
      <div className="search-inputs">
        <div className="flex-row items-center wrap-btns">
          <button
            className="btn btn-normal _small"
            onClick={() => onClickSelectDate('week')}
          >
            {'이번 주'}
          </button>
          <button
            className="btn btn-basic _small"
            onClick={() => onClickSelectDate('month')}
          >
            {'이번 달'}
          </button>
          {/* <button
            className="btn btn-primary _small btn-chakol m-r-8"
            onClick={()=> setShowSelectPeriod(!showSelectPeriod)}
          >
            { '기간 선택' }
          </button> */}
          <div className="uno-datepicker">
            <div className="search-item">
              <DatePicker
                className="text-center"
                todayButton="오늘"
                value={requestStartAt}
                onChange={onChangeStartAt}
              />
            </div>
            <div className="range-separator">-</div>
            <div className="search-item">
              <DatePicker
                className="text-center"
                todayButton="오늘"
                value={requestEndAt}
                onChange={onChangeEndAt}
              />
            </div>
          </div>
          <button className="btn btn-primary _small" onClick={onClickSearch}>
            조회
          </button>
          {showSmsSelect && (
            <select>
              <option>문자종류</option>
            </select>
          )}
        </div>
        {/* <div className="right">
          <button
            className="btn btn-normal _small"
          >
            { '엑셀 다운로드' }
          </button>
        </div> */}
      </div>
      <DataTableAlpha
        model={model}
        data={data}
        total={total}
        params={params}
        onParams={onParams}
        onAction={onAction}
        paginationOuter
      />
    </div>
  );
};

SmsTableList.propTypes = {
  api: PropTypes.func,
  model: PropTypes.object,
  modelName: PropTypes.string,
  queryParam: PropTypes.array,
  showSmsSelect: PropTypes.bool,
  param: PropTypes.object,
};

export default observer(SmsTableList);
