import React, {
  useEffect,
  useCallback,
  useContext,
  createContext,
  useRef,
} from 'react';
import { observer } from 'mobx-react';
import { reaction, toJS } from 'mobx';
import moment from 'moment';
import ConditionalBoard from './ConditionalBoard';
import AppointmentBoardLeftPanel from './AppointmentBoardLeftPanel';
import AppointmentBoardHeader from './AppointmentBoardHeader';
import { AppointmentBoardState } from 'store/appointmentBoardState';
import { useLocation } from 'react-router-dom';
import { useAuth } from 'store/auth';
import { useEvent } from 'hooks/useEvent';
import { useServices } from 'hooks/useServices';

export const AppointmentBoardStateContext = createContext(null);

const AppointmentBoards = () => {
  const auth = useAuth();
  const eventBus = useEvent();
  const services = useServices();
  /**
   * @type {AppointmentBoardState}
   */
  const state = useContext(AppointmentBoardStateContext);
  const location = useLocation();

  useEffect(() => {
    //고객차트에서 페이지 이동이 발생하고 ,예약판이 다 그려진 이후
    if (location.state && state.departmentDrawingFlag) {
      //scroll ref값이 있을 때 자식 스크롤 이동
      if (state.departmentListRef && state.departmentListRef.current) {
        state.calendarUnit = 'day';
        //부서 인덱스
        let scheduleDate = moment(
          location.state.appointment.scheduledAt
        ).format('YYYY-MM-DD');
        //캘린더 별 분기
        if (state && state.calendarUnit === 'day') {
          let departmentIndex = state.dateCategoryDepartments.findIndex(
            (x) =>
              x.department.name === location.state.appointment.department.name
          );
          state.departmentListRef.current.scrollToItem(departmentIndex);
        } else {
          let departmentIndex = state.dateCategoryDepartments.findIndex(
            (x) =>
              x.department.name ===
                location.state.appointment.department.name &&
              x.date === scheduleDate
          );
          state.departmentListRef.current.scrollToItem(departmentIndex);
        }
        state.scrollState = true;
      }
      //해당 날짜의 예약판으로 이동시키기위한 store 시간 setting
      let startAt = moment(location.state.date).startOf(state.calendarUnit);
      let endAt = moment(location.state.date).endOf(state.calendarUnit);

      if (state.calendarUnit === 'week') {
        startAt.add(1, 'days');
        endAt.add(1, 'days');
      }
      if (state.calendarUnit === '3-day') {
        endAt.add(2, 'days');
      }

      startAt = startAt.toDate();
      endAt = endAt.toDate();
      state.setPeriod(startAt, endAt);
      state.setLocationState(location.state);
    }
  }, [location.state, state.departmentDrawingFlag]);

  const configCallApi = useCallback(async () => {
    const resp = await services.crm.clinic.configs({
      groupName: 'calender_color',
    });
    resp &&
      resp.data.map((v) => {
        let index = state.stateArr.findIndex(
          (obj) => obj.codeName == v.codeName
        );
        if (state.stateArr[index] && v.codeValue) {
          state.stateArr[index].codeValue = v.codeValue;
          state.stateArr[index].id = v.id;
        }
      });
    if (!resp) return;
  }, [services.crm.clinic]);

  useEffect(() => {
    configCallApi();
  }, [configCallApi]);

  const departmentCallApi = useCallback(async () => {
    let params = { limit: 300 };
    const resp = await services.crm.crud.departmentCategory.all(params);
    if (!resp) return;

    state.setDepartmentCategories(resp.data);
  }, [services.crm.crud.departmentCategory]);

  useEffect(() => {
    departmentCallApi();
  }, [departmentCallApi]);

  const absenceCallApi = useCallback(async () => {
    const resp = await services.crm.crud.absenceSchedule.all();
    if (!resp) return;
    state.setDepartmentAbsence(resp.data);
  }, [services.crm.crud.absenceSchedule]);

  useEffect(() => {
    absenceCallApi();
  }, [absenceCallApi]);

  const counselorCallApi = useCallback(async () => {
    let params = { duty: '상담사', userStatus: 'active', limit: 300 };
    const resp = await services.crm.user.duty(params);
    if (!resp) return;

    state.setCounselors(resp.data);
  }, [services.crm.user]);

  useEffect(() => {
    counselorCallApi();
  }, [counselorCallApi]);

  const doctorCallApi = useCallback(async () => {
    let params = { duty: '의사', userStatus: 'active', limit: 300 };
    const resp = await services.crm.user.duty(params);
    if (!resp) return;

    state.setDoctors(resp.data);
  }, [services.crm.user]);

  useEffect(() => {
    doctorCallApi();
  }, [doctorCallApi]);

  const smsAutoNotificationCallApi = useCallback(async () => {
    const resp = await services.crm.notification.smsAutoNotifications.all();
    if (!resp) return;

    state.setSmsAutoNotifications(
      resp.data.filter(
        (v) => v.visible === true && v.smsSituation === 'reserved'
      )
    );
  }, [services.crm.notification.smsAutoNotifications]);

  useEffect(() => {
    smsAutoNotificationCallApi();
  }, [smsAutoNotificationCallApi]);

  useEffect(() => {
    eventBus.on('boardsLoadAppointments', (appointmentId) => {
      if (!appointmentId) {
        state.loadAppointments();
      } else {
        state.loadSingleAppointment(appointmentId);
      }
    });

    return () => {
      eventBus.off('boardsLoadAppointments');
    };
  }, [eventBus]);

  useEffect(() => {
    const init = async () => {
      if (!auth.me) return;

      state.setClinic(toJS(auth.me.clinic));
    };
    init();
  }, [auth.me]);

  useEffect(() => {
    let interval;

    function clear() {
      if (interval) {
        clearInterval(interval);
        interval = null;
      }
    }

    const disposer = reaction(
      () => state.datesBetween,
      (dates) => {
        state.appointments = [];
        clear();
        const unit = state.calendarUnit;
        state.loadAppointments().then(() => {
          if (state.datesBetween !== dates) return;
          if (unit === 'month') return;
          interval = setInterval(() => {
            state.loadAppointments();
          }, 5000);
        });
      },
      {
        fireImmediately: true,
      }
    );

    return () => {
      disposer();
      clear();
    };
  }, []);

  return (
    <div className="appointment-boards route flex-row">
      {state.tempFilterStatusOld ? <AppointmentBoardLeftPanel /> : null}
      <div className="right-panel">
        <AppointmentBoardHeader />
        {state.clinic && <ConditionalBoard />}
      </div>
    </div>
  );
};

const Injected = observer(AppointmentBoards);

const WithProvider = () => {
  const auth = useAuth();
  const state = useRef(new AppointmentBoardState(auth));

  return (
    <AppointmentBoardStateContext.Provider value={state.current}>
      <Injected />
    </AppointmentBoardStateContext.Provider>
  );
};

export default WithProvider;
