import React, {
  useEffect,
  useCallback,
  useState,
  useContext,
  useRef,
} from 'react';
import { observer } from 'mobx-react';
import { AppointmentBoardStateContext } from './AppointmentBoards';
import { ChromePicker } from 'react-color';
import moment from 'moment';
import UnoDatePicker from './UnoDatePicker/UnoDatePicker';
import NavigateDatePanel from './NavigateDatePanel';
import SelectPeriodPanel from './SelectPeriodPanel';
import ModeTogglePanel from './ModeTogglePanel';
import CountPanel from './CountPanel';
import SizeSelectPanel from './SizeSelectPanel';
import { translate } from 'filters';
import { useServices } from 'hooks/useServices';
import { useToast } from 'hooks/useToast';

const AppointmentBoardHeader = () => {
  const services = useServices();
  const toast = useToast();
  /**
   * @type {AppointmentBoardState}
   */
  const state = useContext(AppointmentBoardStateContext);

  const [pickerParent, setPickerParent] = useState(null);
  const [pickerColor, setPickerColor] = useState(null);
  const [displayColorPicker, setDisplayColorPicker] = useState(false);

  const panelRef = useRef(null);

  useEffect(() => {
    // update color
    if (pickerColor == null) return;
    let index = state.stateArr.findIndex(
      (obj) => obj.codeName == pickerParent.name
    );
    state.stateArr[index].codeValueTemp = pickerColor;
  }, [pickerColor]);

  const setFilterStatus = useCallback(async (filterStatus) => {
    if (filterStatus !== 'absence') state.setFilterStatus(filterStatus);
  });
  const onDisplayColorPicker = (e) => {
    if (
      e.target.closest('.wrap-color-picker') ||
      typeof e.target.className !== 'string'
    )
      return;
    if (!e.target.className.includes('region-box')) {
      onResetColor();
    }
  };

  const onResetColor = useCallback(() => {
    if (!pickerParent) return;
    let index = state.stateArr.findIndex(
      (obj) => obj.codeName == pickerParent.name
    );
    state.stateArr[index].codeValueTemp = null;
    setDisplayColorPicker(false);
  }, [pickerParent]);

  const configCallApi = useCallback(async () => {
    const resp = await services.crm.clinic.configs({
      groupName: 'calender_color',
    });
    resp &&
      resp.data.map((v) => {
        let index = state.stateArr.findIndex(
          (obj) => obj.codeName == v.codeName
        );
        if (state.stateArr[index] && v.codeValue) {
          state.stateArr[index].codeValue = v.codeValue;
          state.stateArr[index].id = v.id;
        }
      });
    if (!resp) return;
  }, [services.crm.clinic]);

  const configPostApi = useCallback(
    async (obj) => {
      try {
        let params = {
          groupName: 'calender_color',
          id: obj.id,
          codeName: obj.codeName,
          codeValue: obj.codeValueTemp || obj.codeValue,
          order: 1,
        };
        const resp = await services.crm.clinic.configs_create(params);
        let index = state.stateArr.findIndex(
          (obj) => obj.codeName == resp.data.codeName
        );
        state.stateArr[index].id = resp.data.id;
        state.stateArr[index].codeValue = resp.data.codeValue;
        state.stateArr[index].codeValueTemp = null;
      } catch (e) {
        toast.error(e.description);
      } finally {
        setDisplayColorPicker(false);
      }
    },
    [services.crm.clinic, toast, configCallApi]
  );

  const onSaveColor = useCallback(() => {
    let index = state.stateArr.findIndex(
      (obj) => obj.codeName === pickerParent.name
    );
    configPostApi(state.stateArr[index]);
  }, [pickerParent]);

  const onClickToday = useCallback(() => {
    if (state.calendarUnit === 'day') {
      state.setPeriod(
        moment().startOf(state.calendarUnit).toDate(),
        moment().toDate()
      );
    } else if (state.calendarUnit === 'week') {
      state.setPeriod(
        moment().startOf(state.calendarUnit).add('d', 1).toDate(),
        moment().endOf(state.calendarUnit).add('d', 1).toDate()
      );
    } else if (state.calendarUnit === '3-day') {
      state.setPeriod(
        moment().startOf('day').toDate(),
        moment().endOf('day').add('d', 2).toDate()
      );
    } else {
      state.setPeriod(
        moment().startOf(state.calendarUnit).toDate(),
        moment().endOf(state.calendarUnit).toDate()
      );
    }
  }, []);

  const onClickRefresh = useCallback(() => {
    state.loadAppointments();
  }, []);

  return (
    <>
      <div
        className="header"
        ref={panelRef}
        onClick={(e) => onDisplayColorPicker(e)}
      >
        <div className="flex-row flex-between flex-fill">
          <div className="flex-row items-center">
            <div className="date-functions flex-row items-center">
              <NavigateDatePanel />
              <button onClick={onClickToday}>{translate('TODAY')}</button>
              <SelectPeriodPanel />
              <button onClick={onClickRefresh}>새로고침</button>
              {/* <button
                  className={state.tempFilterStatusOld? "" : "selected"}
                  onClick={() => state.toggleFilterStatusOld()}
                >
                  {state.tempFilterStatusOld? "구버전" : "신버전"}
                </button> */}
              <ModeTogglePanel />
            </div>
            <div className="flex-row route-top calendar-header-count-panel">
              <CountPanel />
            </div>
          </div>
          <SizeSelectPanel />
          {state.tempFilterStatusOld ? null : (
            <div className="flex-row filter-wrap folding-cnt">
              <div className="filter">
                <select
                  value={(state.departmentCategories || []).findIndex(
                    (category) =>
                      category.id ===
                      (state.filters.departmentCategory || {}).id
                  )}
                  onChange={(e) => {
                    state.setDeparmentCategoryFilter(
                      (state.departmentCategories || [])[e.target.value]
                    );
                  }}
                >
                  <option key={-1} value={-1} disabled={!true}>
                    {'전체 부서' || '-'}
                  </option>
                  {(state.departmentCategories || []).map((category, idx) => (
                    <option
                      key={idx}
                      value={idx}
                      className={!category.visible ? 'display-none' : ''}
                    >
                      {category.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="filter">
                <select
                  value={(state.counselors || []).findIndex(
                    (existing) =>
                      existing.id === (state.filters.counselor || {}).id
                  )}
                  onChange={(e) => {
                    state.setCounselorFilter(
                      (state.counselors || [])[e.target.value]
                    );
                  }}
                >
                  <option value={-1}>
                    전체 {translate('counselor'.toUpperCase())}
                  </option>
                  {(state.counselors || []).map((o, idx) => (
                    <option key={idx} value={idx}>
                      {o.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="filter">
                <select
                  value={(state.doctors || []).findIndex(
                    (existing) =>
                      existing.id === (state.filters.doctor || {}).id
                  )}
                  onChange={(e) => {
                    state.setDoctorFilter(
                      (state.doctors || [])[e.target.value]
                    );
                  }}
                >
                  <option value={-1}>
                    전체 {translate('doctor'.toUpperCase())}
                  </option>
                  {(state.doctors || []).map((o, idx) => (
                    <option key={idx} value={idx}>
                      {o.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          )}
        </div>
        {state.tempFilterStatusOld ? null : (
          <div className="flex-row filter-wrap filter-status">
            <label>{translate('STATUS')}</label>

            <div className="flex-row">
              {state.stateArr.map((v, i) => (
                <div className="region-item" key={i}>
                  <button
                    className={`region-box ${v.codeName}`}
                    style={{
                      borderColor: v.codeValueTemp || v.codeValue,
                      backgroundColor: v.codeValueTemp
                        ? v.codeValueTemp + '33'
                        : v.codeValue
                        ? v.codeValue + '33'
                        : null,
                    }}
                    onContextMenu={(e) => {
                      e.preventDefault();
                      setPickerParent({
                        name: v.codeName,
                        position: {
                          x: e.target.offsetLeft,
                          y: e.target.offsetTop - panelRef.current.scrollTop,
                        },
                      });
                      setPickerColor(
                        window
                          .getComputedStyle(e.target, null)
                          .getPropertyValue('background-color')
                      );
                      setDisplayColorPicker(true);
                    }}
                    onClick={() => {
                      setFilterStatus(v.codeName);
                    }}
                  >
                    {translate(
                      `APPOINTMENT_STATUS_${v.codeName.toUpperCase()}`
                    )}
                    {`${
                      state.filterStatus.some((x) => x === v.codeName)
                        ? ' v'
                        : ''
                    }`}
                  </button>
                </div>
              ))}
            </div>
            {displayColorPicker && (
              <div
                className="wrap-color-picker"
                style={{
                  top: pickerParent.position.y + 20,
                  left: pickerParent.position.x,
                }}
              >
                <ChromePicker
                  disableAlpha={true}
                  color={pickerColor}
                  onChangeComplete={(color) => setPickerColor(color.hex)}
                />
                <div className="wrap_btn">
                  <button
                    className="btn btn-normal m-r-8"
                    onClick={() => onResetColor()}
                  >
                    취소
                  </button>
                  <button
                    className="btn btn-primary"
                    onClick={() => onSaveColor()}
                  >
                    저장
                  </button>
                </div>
              </div>
            )}
          </div>
        )}
        {/* Calendar on top */}
        {state.tempFilterStatusOld ? null : (
          <div className="flex-row">
            <UnoDatePicker
              startDate={state.startAt}
              endDate={state.calendarUnit !== 'month' ? state.endAt : null}
              onChanged={(day) => {
                state.setCalendarUnit('day');
                state.setPeriod(moment(day).toDate(), moment(day).toDate());
                state.loadAppointments();
              }}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default observer(AppointmentBoardHeader);
