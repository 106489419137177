import React, { useState, useRef } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import services from 'services';
import { translate } from 'filters';
import { useToast } from 'hooks/useToast';
import { FileInput } from 'components/common/FileInput';

const ModalPrescriptionSealCreate = ({ options, close }) => {
  const toast = useToast();
  const sealImageRef = useRef();
  const [seal, setSeal] = useState(null);
  const [sealFile, setSealFile] = useState(null);
  const [loadingBtnDisabledFlag, setLoadingBtnDisabledFlag] = useState(false);

  const onChangeSealImage = (e) => {
    let files = e.target.files[0];
    if (files === undefined) return;

    const { size, name } = e.target.files[0];
    const fileType = name.split('.').pop();
    const reader = new FileReader();

    const width = 100;
    const height = 100;
    const confirmType = ['jpg', 'JPG', 'png', 'PNG'];
    // 10Mb
    const confirmSize = 10;
    if (size / 1024 / 1024 > confirmSize) {
      toast.error('10MB 이하로만 첨부가능합니다.');
      return;
    }

    if (confirmType.indexOf(fileType) === -1) {
      toast.error('jpg 또는 PNG 파일만 첨부가능합니다.');
      return;
    }

    reader.onload = function (event) {
      const img = new Image();
      img.src = event.target.result;

      img.onload = function () {
        const w = this.width;
        const h = this.height;

        if (width < w || height < h) {
          toast.error('가로 100px * 세로 100px 이하로만 첨부가능합니다.');
          return;
        }

        setSeal(event.target.result.toString());
        setSealFile(files);
      };
    };
    reader.readAsDataURL(e.target.files[0]);
  };

  const onClickSave = async () => {
    setLoadingBtnDisabledFlag(true);
    if (sealFile !== null) {
      try {
        const resp = await services.crm.user.detail(options.doctor.id);
        if (!resp) return;

        let payload = {
          id: options.doctor.id,
          email: resp.data.email,
          sealFile,
        };
        await services.crm.user.update_multipart(payload);
        setTimeout(function () {
          //팝업 로딩 후 0.5초 뒤에 api 호출 (복제지연시간을 고려한 호출)
          setLoadingBtnDisabledFlag(false);
          close();
        }, 500);
      } catch (e) {
        console.log(e.description);
        toast.error(e.description);
        setLoadingBtnDisabledFlag(false);
      }
    }
  };

  return (
    <div className={`modal-create-seal`}>
      <div className="head flex-row flex-between items-start">
        <div className="title">
          <span>직인 등록</span>
        </div>
      </div>
      <div className="body">
        <div className="seal-contents flex-row m-t-32 m-b-16">
          {seal && (
            <div
              className={`
              seal-preview
              flex-wrap
              m-r-8
              ${!seal && 'none'}`}
              style={{
                backgroundImage: `url(${seal})`,
              }}
            />
          )}
          <button
            className="btn btn-basic _small flex-wrap"
            onClick={() => sealImageRef.current.click()}
          >
            {'파일첨부'}
          </button>
          <FileInput
            accept="image/jpg;image/png"
            name="sealFile"
            ref={sealImageRef}
            onChange={onChangeSealImage}
          />
        </div>
        <p>* 사진은 10MB 미만의 JPG, PNG 파일만 업로드 가능</p>
        <p>* 가로 100px * 세로 100px</p>
      </div>

      <div className="buttons">
        <div className="flex-row">
          <button
            onClick={() => {
              close(undefined);
            }}
            className="btn btn-default"
          >
            {translate('CANCEL')}
          </button>
          <button
            disabled={loadingBtnDisabledFlag}
            onClick={onClickSave}
            className="btn btn-primary"
          >
            등록
          </button>
        </div>
      </div>
    </div>
  );
};

ModalPrescriptionSealCreate.propTypes = {
  options: PropTypes.object,
  close: PropTypes.func,
};

export default observer(ModalPrescriptionSealCreate);
