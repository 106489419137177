import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import { AppointmentBoardStateContext } from './AppointmentBoards';
import TimeIndicatorText from './TimeIndicatorText';
import { useAuth } from 'store/auth';

// 시간표 가장 좌측 (시간대 세로로 쭉 나열하는 부분)
const Leftmost = observer(
  ({ pivotEl, timelineEl, setPivotEl, setTimelineEl, showIndicator }) => {
    const auth = useAuth();
    /**
     * @type {AppointmentBoardState }
     */
    const state = useContext(AppointmentBoardStateContext);
    const unit = auth.me.clinic.appointmentTimeUnit;
    const cellSizeClass = `size-${Math.ceil(
      state.clinic.appointmentTimeUnit / unit
    )}`;

    return (
      <div ref={setTimelineEl} className="wrap-table-leftmost">
        <div className="table leftmost flex-wrap">
          <div ref={setPivotEl} className="pivot-cell" />
          {state.calendarTimes.map((time, idxTime) => (
            <div key={idxTime} className={`time-cell ${cellSizeClass}`}>
              <span className="time-cell-text">{time}</span>
            </div>
          ))}
        </div>
        {showIndicator && (
          <TimeIndicatorText pivotEl={pivotEl} timelineEl={timelineEl} />
        )}
      </div>
    );
  }
);

export default Leftmost;
