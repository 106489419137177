import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import styled from 'styled-components';

const Input = styled.input`
  width: 100%;
  height: 34px;
  padding: 8px 20px;
  font-size: 12px;
  min-height: 10px;
  border-radius: 4px;
  border: 1px solid #dee2ec;
`;

const ChangeTreatmentCount = ({ payments, setPayments }) => {
  const [treatmentCount, setTreatmentCount] = useState();

  const countSetting = useCallback(() => {
    setTreatmentCount(payments.treatmentCount);
  }, [payments]);

  useEffect(() => {
    countSetting();
  }, [countSetting]);

  const onChangeTreatmentCount = (e) => {
    setPayments({ ...payments, treatmentCount: Number(e.target.value) });
    setTreatmentCount(Number(e.target.value));
  };

  return (
    <React.Fragment>
      <div className="form-control m-t-16">
        <Input
          value={treatmentCount}
          onChange={(e) => {
            onChangeTreatmentCount(e);
          }}
        />
      </div>
    </React.Fragment>
  );
};

ChangeTreatmentCount.propTypes = {
  payments: PropTypes.object,
  setPayments: PropTypes.func,
};

export default observer(ChangeTreatmentCount);
