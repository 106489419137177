import React, { useState, useEffect, useCallback } from 'react';
import { observer } from 'mobx-react';
import Tree from 'rc-tree';
import 'rc-tree/assets/index.css';
import { Menu, Dropdown } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { translate } from 'filters';
import { $qb } from 'scripts/querybuilder';
import { useEvent } from 'hooks/useEvent';
import { useModal } from 'hooks/useModal';
import { useServices } from 'hooks/useServices';
import { useToast } from 'hooks/useToast';

const OrganizationType = { DEPARTMENT: 'department', STAFF: 'staff' };

const OverlayMenu = ({ firstSeq, lastSeq, data, depth }) => {
  const eventBus = useEvent();
  const modal = useModal();
  const services = useServices();
  const toast = useToast();

  const callApiSuccessMsg = (endpoint) => {
    switch (endpoint) {
      case 'delete':
        return '삭제되었습니다.';
      case 'create':
        return '생성되었습니다.';
      case 'update':
        return '업데이트되었습니다.';
      default:
        return endpoint;
    }
  };

  const getListCallApi = async (param) => {
    try {
      const resp = await services.crm.organizations.organ.all(param);
      if (!resp) return;

      return resp.data;
    } catch (e) {
      toast.error(e.description);
    }
  };

  const changeSeqCallApi = async (param) => {
    try {
      await services.crm.organizations.organ.update(param);
    } catch (e) {
      modal.confirm({
        type: 'ERROR',
        msg: e.description,
      });
    }
  };

  const organCrudCallApi = async (param, endpoint) => {
    try {
      await services.crm.organizations.organ[endpoint](param);
      let message = translate(callApiSuccessMsg(endpoint));
      modal
        .confirm({
          type: 'SUCCESS',
          msg: message,
        })
        .then(() => {
          eventBus.emit('treeCallApi');
        });
    } catch (e) {
      modal.confirm({
        type: 'ERROR',
        msg: e.description,
      });
    }
  };

  const onClickCreateDepartment = (parentId) => {
    modal
      .input({
        title: '하위부서 추가',
        inputs: [{}],
      })
      .then((inputs) => {
        if (!inputs || inputs.length === 0) return;

        if (!inputs[0].text) {
          toast.error('ERROR_EMPTY_VALUE');
          return;
        }

        let obj = {
          name: inputs[0].text,
          type: 'department',
          isLeader: 0,
          isDelete: 0,
          parentId,
        };
        organCrudCallApi(obj, 'create');
      });
  };

  const onClickChangeDepartmentName = (data) => {
    console.log(data);
    modal
      .input({
        title: '이름 변경',
        inputs: [
          {
            placeholder: data.name,
          },
        ],
      })
      .then((inputs) => {
        if (!inputs || inputs.length === 0) return;

        if (!inputs[0].text) {
          toast.error('ERROR_EMPTY_VALUE');
          return;
        }

        const { id } = data;
        let obj = { id, name: inputs[0].text };
        organCrudCallApi(obj, 'update');
      });
  };

  const onClickChangeSeq = async (status, data) => {
    const { depth, parentId, seq } = data;
    let param = { type: OrganizationType.DEPARTMENT, depth, parentId };
    const resp = await getListCallApi(param);
    let swapData;
    let updatePayloadList = [];
    if (status === 'up') {
      swapData = resp.filter((item) => {
        return item.seq === seq - 1;
      });
      data.seq = seq - 1;
      swapData[0].seq = swapData[0].seq + 1;
    } else if (status === 'down') {
      swapData = resp.filter((item) => {
        return item.seq === seq + 1;
      });
      data.seq = seq + 1;
      swapData[0].seq = swapData[0].seq - 1;
    }

    updatePayloadList.push(data);
    updatePayloadList.push(swapData[0]);

    for (let i in updatePayloadList) {
      try {
        await changeSeqCallApi(updatePayloadList[i]);
        if (Number(i) === updatePayloadList.length - 1) {
          modal
            .confirm({
              type: 'SUCCESS',
              msg: '업데이트되었습니다.',
            })
            .then(() => {
              eventBus.emit('treeCallApi');
            });
        }
      } catch (e) {
        modal.confirm({
          type: 'ERROR',
          msg: e.description,
        });
      }
    }
  };

  const onClickDeleteDepartment = (id) => {
    modal
      .basic({
        body: `<div class="danger-confrim">해당 부서를 정말로 삭제하시겠습니까?</div>
      <div class="danger-confrim">삭제 시, 연관된 하위부서가 모두 삭제되며 복구할 수 없습니다.</div>
      <div>※삭제된 부서에 소속된 구성원은 ‘무소속’이 되며 소속을 다시 지정해줘야 합니다.</div>`,
        buttons: [
          {
            text: 'CANCEL',
            class: 'btn-default',
          },
          {
            text: 'CONFIRM',
            class: 'btn-primary',
          },
        ],
      })
      .then((selectedIdx) => {
        if (selectedIdx === 1) {
          organCrudCallApi(id, 'delete');
        }
      });
  };

  return (
    <Menu>
      {depth !== 5 && (
        <Menu.Item onClick={() => onClickCreateDepartment(data.id)}>
          하위부서 추가
        </Menu.Item>
      )}
      {depth !== 1 && (
        <Menu.Item onClick={() => onClickChangeDepartmentName(data)}>
          이름변경
        </Menu.Item>
      )}
      {depth !== 1 && !firstSeq && (
        <Menu.Item onClick={() => onClickChangeSeq('up', data)}>
          순서 위로 이동
        </Menu.Item>
      )}
      {depth !== 1 && !lastSeq && (
        <Menu.Item onClick={() => onClickChangeSeq('down', data)}>
          순서 아래로 이동
        </Menu.Item>
      )}
      {depth !== 1 && (
        <Menu.Item onClick={() => onClickDeleteDepartment(data.id)}>
          삭제
        </Menu.Item>
      )}
    </Menu>
  );
};

OverlayMenu.propTypes = {
  firstSeq: PropTypes.bool,
  lastSeq: PropTypes.bool,
  data: PropTypes.object,
  depth: PropTypes.number,
};

const OrganizationsTreeArea = ({ setParentId, setTreeSelectFlag }) => {
  const eventBus = useEvent();
  const services = useServices();
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [treeData, setTreeData] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const defaultParams = $qb().limit(1000).orderBy('parent_id asc,seq asc');
  const [params] = useState(defaultParams);

  const callApi = useCallback(async () => {
    setParentId();
    setSelectedKeys([]);
    const resp = await services.crm.organizations.list.all(params.build());
    if (!resp) return;

    let data = [];
    let expandKey = [];
    resp.data.forEach((v, i) => {
      const { id, name, parentId, depth } = v;
      const prevData = resp.data[i - 1];
      const nextData = resp.data[i + 1];
      let lastSeq = false;
      let firstSeq = false;

      if (nextData === undefined) {
        lastSeq = true;
      } else if (depth !== 1) {
        if (parentId !== nextData.parentId) {
          lastSeq = true;
        }
      }

      if (prevData === undefined) {
        firstSeq = true;
      } else if (depth !== 1) {
        if (parentId !== prevData.parentId) {
          firstSeq = true;
        }
      }

      let d = {
        title: name,
        key: id,
        children: [],
        icon: (
          <SetDropDown
            firstSeq={firstSeq}
            lastSeq={lastSeq}
            data={v}
            depth={depth}
          />
        ),
      };
      expandKey.push(String(id));
      if (parentId === null) {
        data.push(d);
      } else {
        //하위 항목들 재귀함수 호출
        createTreeData(firstSeq, lastSeq, data, v);
      }
    });

    setTreeData(data);
    setExpandedKeys(expandKey);
  }, [services.crm.organizations.list]);

  useEffect(() => {
    eventBus.on('treeCallApi', callApi);

    params.queryParams = {
      ...params.queryParams,
      type: OrganizationType.DEPARTMENT,
    };
    callApi();

    return () => eventBus.off('treeCallApi');
  }, [eventBus, callApi]);

  const createTreeData = (firstSeq, lastSeq, data, v) => {
    const { id, name, parentId, depth } = v;
    let d = {
      title: name,
      key: id,
      children: [],
      icon: (
        <SetDropDown
          firstSeq={firstSeq}
          lastSeq={lastSeq}
          data={v}
          depth={depth}
        />
      ),
    };

    let parentData;
    for (let i in data) {
      if (parentId === data[i].key) {
        //두 아이디가 일치하면 트리데이터에 푸쉬 후 리턴시켜야 한다.
        parentData = data[i];
        data[i].children.push(d);
        return;
      }
    }

    if (parentData === undefined) {
      for (let i in data) {
        createTreeData(firstSeq, lastSeq, data[i].children, v);
      }
    }
  };

  const SetDropDown = ({ firstSeq, lastSeq, data, depth }) => {
    return (
      <Dropdown
        overlay={OverlayMenu({ firstSeq, lastSeq, data, depth })}
        trigger={['click']}
      >
        <a
          href="#"
          className="more ant-dropdown-link"
          onClick={(e) => e.preventDefault()}
        >
          <DownOutlined />
        </a>
      </Dropdown>
    );
  };

  //id, name, parentId, depth
  //depth, parentId, seq

  SetDropDown.propTypes = {
    firstSeq: PropTypes.bool,
    lastSeq: PropTypes.bool,
    data: PropTypes.object,
    depth: PropTypes.number,
  };

  const onExpand = (expanded) => {
    setExpandedKeys(expanded);
  };

  const onClickDepartment = (selectedKeys, e) => {
    const { selectedNodes } = e;
    let selectId;
    let treeFlag = false;
    if (selectedNodes.length) {
      selectId = selectedNodes[0].key;
      treeFlag = true;
    }
    setTreeSelectFlag(treeFlag);
    setParentId(selectId);
    setSelectedKeys(selectedKeys);
  };

  return (
    <div className="draggable-demo">
      <div className="draggable-container">
        <Tree
          expandedKeys={expandedKeys}
          onExpand={onExpand}
          onSelect={onClickDepartment}
          treeData={treeData}
          selectedKeys={selectedKeys}
          // switcherIcon={switcherIcon}
        />
      </div>
    </div>
  );
};

OrganizationsTreeArea.propTypes = {
  setParentId: PropTypes.func,
  setTreeSelectFlag: PropTypes.func,
};

export default observer(OrganizationsTreeArea);
