import { observer } from 'mobx-react';
import React, { useContext } from 'react';
//import { AppointmentBoardState } from 'store/appointmentBoardState';
import { AppointmentBoardStateContext } from './AppointmentBoards';

const CategoryRow = observer(() => {
  /**
   * @type {AppointmentBoardState }
   */
  const state = useContext(AppointmentBoardStateContext);

  return (
    <div className="calendar-categories-row">
      {state.datesBetween.map((date) => (
        <React.Fragment key={date}>
          {state.filteredDepartmentCategories.map((c, i) => (
            <div
              key={c.id}
              style={{
                width:
                  state.mode === 'vertical'
                    ? state.getCategoryViewSize(c, date)
                    : undefined,
                height:
                  state.mode === 'horizontal'
                    ? state.getCategoryViewSize(c, date)
                    : undefined,
                borderRight:
                  state.mode === 'vertical' &&
                  state.filteredDepartmentCategories.length == i + 1 &&
                  '3px solid #d7e3f1',
                borderBottom:
                  state.mode === 'horizontal' &&
                  state.filteredDepartmentCategories.length == i + 1 &&
                  '3px solid #d7e3f1',
              }}
              className="department-category-name"
            >
              {c.name}
            </div>
          ))}
        </React.Fragment>
      ))}
    </div>
  );
});

export default CategoryRow;
