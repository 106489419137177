import { translate } from 'filters';

export default {
  keys: [
    {
      type: 'checkbox',
      noorder: true,
    },
    {
      title: 'FREE_SERVICE',
      noorder: true,
      type: 'subcheckbox',
    },
    {
      title: '카테고리',
      column: 'category__name',
      noorder: true,
      filter: (o) => {
        if (o.category === undefined) {
          return `<div style="width: 125px; 
              white-space: break-spaces;
              text-align: center;">${o.categoryName || ''}</div>`;
        } else {
          return `<div style="width: 125px; 
              white-space: break-spaces;
              text-align: center;">${o.category.name || ''}</div>`;
        }
      },
    },
    {
      title: '시/수술명',
      column: 'name',
      noorder: true,
      filter: (o) => {
        return `<div style="width: 180px; 
              white-space: break-spaces;
              text-align: center;">${o.name || ''}</div>`;
      },
    },
    {
      title: '과세여부',
      column: 'vatIncluded',
      noorder: true,
      type: 'toggle',
      filter: (o) => {
        if (o['vatIncluded'] !== null) {
          return o['vatIncluded']
            ? translate('VAT_TRUE')
            : translate('VAT_FALSE');
        }

        return '-';
      },
    },
    {
      title: 'NOT_VAT_INCLUDE',
      column: 'price',
      noorder: true,
      type: 'input_currency',
    },
    {
      title: 'PRICE_VAT_INCLUDE',
      column: 'priceVatInclude',
      noorder: true,
      type: 'input_currency',
    },
    {
      title: 'SURGERY_NUMOF',
      column: 'treatmentCount',
      noorder: true,
      type: 'input',
      inputUnit: '회',
    },
  ],
};
