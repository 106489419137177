import React, { useEffect, useState, useCallback, useRef } from 'react';
import { observer } from 'mobx-react';
import DataTableAlpha from 'components/data-table/DataTableAlpha';
import { pluralize, translate } from 'filters';
import PropTypes from 'prop-types';
import moment from 'moment';
import models from 'models';
import { $qb } from 'scripts/querybuilder';
import { useModal } from 'hooks/useModal';
import { useServices } from 'hooks/useServices';
import { useToast } from 'hooks/useToast';
import { useData } from 'hooks/useData';
import styled from 'styled-components';
import { RadioInput } from 'components/common/RadioInput';
import DatePicker from 'components/common/DatePicker';

const Input = styled.input`
  padding: 8px 20px;
  background: #f3f8ff;
  width: 140px;
  transition: border-color 0.2s;
  border-color: #dee2ec;
  border-radius: 4px;
  font-size: 14px;
  border: 1px solid #dee2ec;
  height: 34px;

  &:focus {
    border-color: #0060ff;
  }
`;

const PhoneNumberInput = styled(Input)`
  width: 180px;
`;

const AddFormInput = styled.input`
  width: 100%;
  height: 34px;
  padding: 8px 20px;
  border-color: #d7e3f1;
  font-size: 12px;
  border-radius: 4px;
  background-color: #f3f8ff;
  border: 1px solid #dee2ec;
`;

const ModalCustomerFinder = ({ options, close }) => {
  const modal = useModal();
  const services = useServices();
  const toast = useToast();
  const storeData = useData();

  const defaultParams = $qb().limit(10).orderBy('id desc');
  const [data, setData] = useState(null);
  const [dataTemp, setDataTemp] = useState(null);
  const [total, setTotal] = useState(null);
  const [params, setParams] = useState(defaultParams);
  const [showCreateCustomerArea, setShowCreateCustomerArea] = useState(false);
  const [searchValue, setSearchValue] = useState({
    name: options.customerData.userName,
    phoneNumber: options.customerData.phoneNumber,
  });
  const [loadingBtnDisabledFlag, setLoadingBtnDisabledFlag] = useState(false);
  const [sexValue, setSexValue] = useState('');
  const inputRef = useRef();

  const configCallApi = useCallback(async () => {
    try {
      const resp = await services.crm.clinic.configs({
        groupName: 'customer',
      });

      if (resp && resp.data.length < 1) return;
      else {
        //setObj({...obj, sex : resp.data[0].codeValue})
        setSexValue(resp.data[0].codeValue);
      }
    } catch (e) {
      console.log(e.description);
    }
  }, [services.crm.clinic]);

  useEffect(() => {
    configCallApi();
  }, []);

  const customerFindCallApi = useCallback(async () => {
    if (options.customerData.userName !== undefined) {
      params.customParam('name', options.customerData.userName);
    }
    if (options.customerData.phoneNumber !== undefined) {
      params.customParam('phoneNumber', options.customerData.phoneNumber);
    }

    if (options.customerData.searchQuery !== undefined) {
      if (
        options.customerData.searchQuery.replace(/-/gi, '').length === 11 ||
        (!isNaN(options.customerData.searchQuery) &&
          options.customerData.searchQuery.length === 4)
      ) {
        options.customerData.searchQuery.length === 4
          ? params.customParam(
              'phoneNumberLast',
              options.customerData.searchQuery
            )
          : params.customParam(
              'phoneNumber',
              options.customerData.searchQuery.replace(/-/gi, '')
            );

        setSearchValue({
          phoneNumber: options.customerData.searchQuery.replace(/-/gi, ''),
        });
        setDataTemp({
          phoneNumber: options.customerData.searchQuery.replace(/-/gi, ''),
        });
      } else {
        params.customParam('name', options.customerData.searchQuery);

        setSearchValue({
          name: options.customerData.searchQuery,
        });
        setDataTemp({
          name: options.customerData.searchQuery,
        });
      }
      delete options.customerData.searchQuery;
    }
    if (
      params.queryParams.name ||
      params.queryParams.phoneNumber ||
      params.queryParams.phoneNumberLast
    ) {
      let resp = await services.crm.customer.all(params.build());
      if (!resp) return;

      setData(resp.data);
      setTotal(resp.total);
    }

    //document.getElementsByName('name')[0].focus();
  }, [services.crm.customer, params, options]);

  const callApi = useCallback(async () => {
    //초기 진입 이후에는 params로 조회
    let resp = await services.crm.customer.all(params.build());
    if (!resp) return;

    setData(resp.data);
    setTotal(resp.total);
  }, [services.crm.customer, params]);

  useEffect(() => {
    inputRef.current.focus();
    //초기 진입시에는 options를 조회 params로 사용
    customerFindCallApi();
  }, []);

  const onParams = (p) => {
    setParams(p);
    callApi();
  };

  const onAction = (obj) => {
    const customer = obj.row;
    const event = obj.event;

    if (event.target.innerText === '선택') {
      close(customer);
      return;
    }
  };

  const onClickShowArea = () => {
    setLoadingBtnDisabledFlag(false);
    setShowCreateCustomerArea(!showCreateCustomerArea);
  };

  const onClickCreateCustomer = async (customerObj) => {
    //create 후 해당 팝업 닫고
    //통합차트 예약 탭 노출
    try {
      //smsEnabled 는 default true로 저장 (8월3주차 스토리카드)
      let payload = {
        ...customerObj,
        smsEnabled: true,
        eventSmsEnabled: customerObj.eventSmsEnabled === 'true',
      };
      if (payload.phoneNumber) {
        payload.phoneNumber = payload.phoneNumber.replace(/-/gi, '');
      }

      if (payload.birthday) {
        //생년월일을 입력하면 주민등록번호 값도 저장되도록
        //주민번호 인풋은 없음
        let changeValue =
          payload.birthday === null
            ? null
            : moment(payload.birthday).format('YYYYMMDD');

        if (changeValue === null || changeValue === '') return;

        let ssnValue = changeValue.substr(2, 8);
        payload.ssnHead = ssnValue;
      }

      const resp = await services.crm.customer.create(payload);
      modal
        .confirm({
          type: 'SUCCESS',
          msg: '고객이 등록되었습니다.',
        })
        .then(() => {
          close(resp.data);
        });
    } catch (e) {
      console.log(e.description);
      modal
        .confirm({
          type: 'ERROR',
          msg: e.description,
        })
        .then(() => {
          setLoadingBtnDisabledFlag(false);
        });
    }
  };

  const onClickSearch = () => {
    // 전화번호 하이푼 제거 후 검색
    let searchParams = { ...searchValue };
    if (searchParams && searchParams.phoneNumber) {
      searchParams.phoneNumber = searchValue.phoneNumber.replace(/-/gi, '');
    }
    if (
      searchValue.phoneNumber !== undefined &&
      searchValue.phoneNumber.length === 4
    ) {
      params.queryParams = {
        orderBy: params.queryParams.orderBy,
        limit: params.queryParams.limit,
        name: searchValue.name,
        chartNo: searchValue.chartNo,
        birthday: searchValue.birthday,
        phoneNumberLast: searchParams.phoneNumber,
      };
    } else {
      params.queryParams = {
        orderBy: params.queryParams.orderBy,
        limit: params.queryParams.limit,
        ...searchParams,
      };
    }
    onParams(params);
  };

  const onChangeInput = (e) => {
    let obj = {};
    if (e.target.value === '') {
      Object.keys(searchValue).forEach((v) => {
        if (e.target.name !== v) {
          obj[v] = searchValue[v];
        }
      });
    } else {
      obj = { ...searchValue, [e.target.name]: e.target.value };
    }
    setSearchValue(obj);
    setDataTemp(obj);
  };

  const CreateCustomArea = ({ onClickCreate }) => {
    let dataObj = { ...dataTemp };
    if (dataTemp && dataTemp.birthday) {
      if (dataTemp.birthday.length === 8) {
        let formatBirthday = '';
        formatBirthday = dataTemp.birthday.replace(
          /(\d{4})(\d{2})(\d{2})/,
          '$1-$2-$3'
        );
        dataObj.birthday = formatBirthday;
      }
      if (dataTemp.birthday.length === 6) {
        let formatBirthday = '19';
        formatBirthday += dataTemp.birthday.replace(
          /(\d{2})(\d{2})(\d{2})/,
          '$1-$2-$3'
        );
        dataObj.birthday = formatBirthday;
      }
      //datetime validation
      let IsValidDate = Date.parse(dataObj.birthday);
      if (isNaN(IsValidDate)) {
        delete dataObj.birthday;
      }
    }

    const inputRef2 = useRef();
    const [obj, setObj] = useState({
      sex: sexValue || 'female',
      eventSmsEnabled: 'true',
      ...dataObj,
    });

    useEffect(() => {
      inputRef2.current.focus();
    }, []);

    const onChangeAcquisitionChannels = (e) => {
      const { name, value } = e.target;
      setObj({
        ...obj,
        [name]: storeData.getters()[pluralize('acquisitionChannels')][value],
      });
    };

    const validator = (payload) => {
      let returnFlag = true;
      if (!payload.sex || !payload.eventSmsEnabled) {
        toast.error('*(별표)표시는 필수로 입력하세요.');
        returnFlag = false;
      }

      return returnFlag;
    };

    const onClick = async () => {
      setDataTemp({ ...obj });
      if (validator(obj)) {
        setLoadingBtnDisabledFlag(true);

        if (obj.phoneNumber) {
          let modalMsg = '';
          let phoneNumber = obj.phoneNumber.replace(/-/gi, '');

          const resp_phone = await services.crm.customer.all({ phoneNumber });

          const phoneCheckList = resp_phone.data.filter(
            (v) => v.phoneNumber === phoneNumber
          );

          const nameCheckList = resp_phone.data.filter(
            (v) => v.name === obj.name
          );

          //팝업 메세지
          if (phoneCheckList.length > 0) {
            modalMsg =
              '이미 등록되어있는 연락처입니다. 그래도 등록하시겠습니까?';
          }

          if (nameCheckList.length > 0) {
            modalMsg = '이미 등록되있는 고객입니다. 그래도 등록하시겠습니까?';
          }

          if (modalMsg) {
            modal
              .basic({
                body: modalMsg,
                buttons: [
                  {
                    text: 'CANCEL',
                    class: 'btn-default',
                  },
                  {
                    text: 'CONFIRM',
                    class: 'btn-primary',
                  },
                ],
              })
              .then((selectedIdx) => {
                if (selectedIdx) onClickCreate(obj);
                setLoadingBtnDisabledFlag(false);
              });
          } else {
            onClickCreate(obj);
          }
        } else {
          onClickCreate(obj);
        }
      }
    };

    const onChangeValue = (column, value) => {
      obj[column] = value;
      setObj({ ...obj });
    };

    const onChangePicker = (birthday) => {
      if (birthday > new Date()) {
        birthday.setFullYear(birthday.getFullYear() - 100);
      }
      let changeValue =
        birthday === null ? null : moment(birthday).format('YYYY-MM-DD');
      onChangeValue('birthday', changeValue);
    };

    return (
      <div className="wrap-new-customer">
        <div className="data-input">
          <div className="title">
            고객등록
            <div className="right">
              <button
                className="btn btn-cancel _small m-r-8"
                onClick={onClickShowArea}
              >
                입력취소
              </button>
              <button
                className="btn btn-basic _small"
                disabled={loadingBtnDisabledFlag}
                onClick={onClick}
              >
                저장
              </button>
            </div>
          </div>
        </div>

        <div className="form-double">
          <div className="form-control">
            <label>고객명</label>
            <AddFormInput
              name="name"
              onChange={(e) =>
                setObj({ ...obj, [e.target.name]: e.target.value })
              }
              defaultValue={obj.name}
              placeholder="고객명을 입력하세요."
              ref={inputRef2}
            />
          </div>

          <div className="form-control">
            <label>연락처</label>
            <AddFormInput
              name="phoneNumber"
              onChange={(e) => {
                if (!/^[0-9-]*$/.test(e.target.value)) {
                  toast.error('ERROR_NUMBERS_ONLY');
                  return;
                }
                if (e.target.value.length > 13) {
                  toast.error('ERROR_PHONE_TOO_LONG');
                  return;
                }

                let numberTemp = (e.target.value || '')
                  .replace(/[^0-9]/g, '')
                  .replace(
                    /(^02|^0505|^1[0-9]{3}|^0[0-9]{2})([0-9]+)?([0-9]{4})/,
                    '$1-$2-$3'
                  )
                  .replace('--', '-');

                setObj({ ...obj, [e.target.name]: numberTemp });
              }}
              value={obj.phoneNumber || ''}
              placeholder="연락처를 입력하세요."
            />
          </div>
          <div className="form-control">
            <label>{translate('BIRTHDAY')}</label>
            <div>
              <DatePicker
                value={obj.birthday ? new Date(obj.birthday) : null}
                onChange={onChangePicker}
                dateFormat="yyMMdd"
                placeholderText="ex ) 910826 "
              />
            </div>
          </div>
        </div>

        <div className="form-double">
          <div className="form-control">
            <label>내원경로</label>
            <select
              name="acquisitionChannel"
              onChange={onChangeAcquisitionChannels}
              value={(
                storeData.getters()[pluralize('acquisitionChannels')] || []
              ).findIndex(
                (option) => option.id === (obj['acquisitionChannel'] || {}).id
              )}
            >
              <option>-</option>
              {storeData.acquisitionChannels.map((v, i) => {
                return (
                  <option key={i} value={i}>
                    {v.name}
                  </option>
                );
              })}
            </select>
          </div>

          <div className="form-control">
            <label className="label-required">성별</label>
            <div>
              <span className="m-r-24">
                <RadioInput
                  name="sex"
                  value="male"
                  checked={obj.sex === 'male'}
                  onChange={(e) =>
                    setObj({ ...obj, [e.target.name]: e.target.value })
                  }
                />{' '}
                남자
              </span>
              <span>
                <RadioInput
                  name="sex"
                  value="female"
                  checked={obj.sex === 'female'}
                  onChange={(e) =>
                    setObj({ ...obj, [e.target.name]: e.target.value })
                  }
                />{' '}
                여자
              </span>
            </div>
          </div>

          <div className="form-control">
            <label className="label-required">마케팅 수신</label>
            <div>
              <span className="m-r-24">
                <RadioInput
                  name="eventSmsEnabled"
                  value="true"
                  checked={obj.eventSmsEnabled === 'true'}
                  onChange={(e) => setObj({ ...obj, [e.target.name]: 'true' })}
                />{' '}
                동의
              </span>
              <span>
                <RadioInput
                  name="eventSmsEnabled"
                  value="false"
                  checked={obj.eventSmsEnabled === 'false'}
                  onChange={(e) => setObj({ ...obj, [e.target.name]: 'false' })}
                />{' '}
                거부
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  CreateCustomArea.propTypes = {
    onClickCreate: PropTypes.func,
  };

  return (
    <div className="modal-customer-finder">
      <div className="head flex-row flex-between items-start m-b-16">
        <div className="title">{translate('CUSTOMER_FINDER')}</div>
        <i onClick={() => close()} className="zmdi zmdi-close" />
      </div>
      <div className="search-bar m-t-8">
        <div className="search-item m-r-8">
          <Input
            placeholder="고객명"
            name="name"
            onChange={onChangeInput}
            value={searchValue.name || ''}
            onKeyPress={(e) => (e.key === 'Enter' ? onClickSearch() : null)}
            ref={inputRef}
          />
        </div>
        <div className="search-item m-r-8">
          <Input
            placeholder="차트번호"
            name="chartNo"
            onChange={onChangeInput}
            value={searchValue.chartNo || ''}
            onKeyPress={(e) => (e.key === 'Enter' ? onClickSearch() : null)}
          />
        </div>
        <div className="search-item m-r-8">
          <Input
            placeholder="생년월일"
            name="birthday"
            onChange={onChangeInput}
            onKeyPress={(e) => (e.key === 'Enter' ? onClickSearch() : null)}
          />
        </div>
        <div className="search-item m-r-8">
          <PhoneNumberInput
            placeholder="연락처"
            name="phoneNumber"
            onChange={onChangeInput}
            value={searchValue.phoneNumber || ''}
            onKeyPress={(e) => (e.key === 'Enter' ? onClickSearch() : null)}
          />
        </div>
        <div className="search-btns">
          <button className="btn btn-basic _small" onClick={onClickSearch}>
            조회
          </button>
        </div>
      </div>
      <DataTableAlpha
        model={models.minified.customerVer2}
        data={data}
        total={total}
        params={params}
        onParams={onParams}
        onAction={onAction}
      />
      {showCreateCustomerArea ? (
        <CreateCustomArea
          data={searchValue}
          onClickCreate={onClickCreateCustomer}
        />
      ) : (
        <div className="wrap-new-customer">
          <div className="data-input">
            <div className="title">
              고객등록
              <div className="right">
                <span className="m-r-12">찾으시는 고객이 없나요?</span>
                <button
                  className="flex-wrap btn btn-basic _small"
                  onClick={onClickShowArea}
                >
                  {translate('CREATE_CUSTOMER')}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

ModalCustomerFinder.propTypes = {
  options: PropTypes.object,
  close: PropTypes.func,
};

export default observer(ModalCustomerFinder);
