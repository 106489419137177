import React from 'react';
import { observer } from 'mobx-react';
import MessageCallerNumbers from './MessageCallerNumbers';
// import MessageAlimtalk from './MessageAlimtalk';
import MessageBoilerplate from './MessageBoilerplates';
import MessageAutoNotifications from './MessageAutoNotifications';
import MessageMap from './MessageMap';
import MessageAutoSurgeryPeriod from './MessageAutoSurgeryPeriod';

const SmsNotificationCodes = () => {
  return (
    <div className="message-codes">
      <div className="page-navi">
        <span className="title">문자관리</span>
        <span className="title">문자코드 설정</span>
      </div>
      <MessageCallerNumbers />
      <MessageBoilerplate />
      <MessageAutoNotifications />
      <MessageAutoSurgeryPeriod />
      <MessageMap />
      {/* <MessageAlimtalk/> */}
    </div>
  );
};

export default observer(SmsNotificationCodes);
