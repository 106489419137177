import React, { useState } from 'react';
import { observer } from 'mobx-react';
import DailySettlementsList from './DailySettlementsList';
import DailySettlementsDetailList from './DailySettlementsDetailList';
import DatePicker from 'components/common/DatePicker';

const DailySettlements = () => {
  const [startAt, setStartAt] = useState(new Date());
  const [endAt, setEndAt] = useState(new Date());
  const [searchValue, setSearchValue] = useState({
    startAt: new Date(),
    endAt: new Date(),
  });

  const onChangeStartAt = (date) => {
    setSearchValue({
      ...searchValue,
      startAt: date,
    });
  };

  const onChangeEndAt = (date) => {
    setSearchValue({
      ...searchValue,
      endAt: date,
    });
  };

  const onClickSelectDate = (type) => {
    let startDate, endDate;

    if (type === 'today') {
      startDate = new Date();
      endDate = new Date();
    } else if (type === 'week') {
      const now = new Date();
      const nowDayOfWeek = now.getDay();
      const nowDay = now.getDate();
      const nowMonth = now.getMonth();
      let nowYear = now.getYear();
      nowYear += nowYear < 2000 ? 1900 : 0;
      startDate = new Date(nowYear, nowMonth, nowDay - nowDayOfWeek);
      endDate = new Date(nowYear, nowMonth, nowDay + (6 - nowDayOfWeek));
    } else if (type === 'yesterday') {
      startDate = new Date(new Date().setDate(new Date().getDate() - 1));
      endDate = new Date(new Date().setDate(new Date().getDate() - 1));
    } else if (type === 'lastweek') {
      const now = new Date(new Date().setDate(new Date().getDate() - 7));
      const nowDayOfWeek = now.getDay();
      const nowDay = now.getDate();
      const nowMonth = now.getMonth();
      let nowYear = now.getYear();
      nowYear += nowYear < 2000 ? 1900 : 0;
      startDate = new Date(nowYear, nowMonth, nowDay - nowDayOfWeek);
      endDate = new Date(nowYear, nowMonth, nowDay + (6 - nowDayOfWeek));
    }

    setSearchValue({
      startAt: startDate,
      endAt: endDate,
    });
    setStartAt(startDate);
    setEndAt(endDate);
  };

  const onClickSearch = () => {
    setStartAt(searchValue.startAt);
    setEndAt(searchValue.endAt);
  };

  return (
    <div className={`settlements list`}>
      <div className="page-navi">
        <span className="title">결산</span>
        <span className="title">일일 결산</span>
      </div>
      <div className="search-inputs">
        <div className="search-item">
          <button
            className="btn btn-normal _small"
            onClick={() => {
              onClickSelectDate('today');
            }}
          >
            오늘
          </button>
        </div>
        <div className="search-item">
          <button
            className="btn btn-normal _small"
            onClick={() => onClickSelectDate('week')}
          >
            이번 주
          </button>
        </div>
        <div className="search-item">
          <button
            className="btn btn-normal _small"
            onClick={() => onClickSelectDate('yesterday')}
          >
            어제
          </button>
        </div>
        <div className="search-item">
          <button
            className="btn btn-normal _small"
            onClick={() => onClickSelectDate('lastweek')}
          >
            지난 주
          </button>
        </div>
        <div className="search-item">
          <DatePicker
            className="text-center"
            placeholderText="날짜선택"
            todayButton="오늘"
            value={searchValue.startAt}
            onChange={onChangeStartAt}
          />
        </div>
        <div className="search-item">-</div>
        <div className="search-item">
          <DatePicker
            className="text-center"
            placeholderText="날짜선택"
            todayButton="오늘"
            value={searchValue.endAt}
            onChange={onChangeEndAt}
          />
        </div>
        <div className="search-item">
          <button
            className="btn btn-primary _small"
            onClick={() => onClickSearch()}
          >
            조회
          </button>
        </div>
      </div>
      <DailySettlementsList startAt={startAt} endAt={endAt} />
      <DailySettlementsDetailList startAt={startAt} endAt={endAt} />
    </div>
  );
};

export default observer(DailySettlements);
