import { useRef } from 'react';
import { fillTimeframe, numArray, toDateString } from 'filters';
import {
  observable,
  action,
  computed,
  flow,
  toJS,
  reaction,
  makeObservable,
} from 'mobx';
import moment from 'moment';
import modal from './modal';
import ui from './ui';
import { Appointment } from './appointment';
import { $qb } from 'scripts/querybuilder';
import services from 'services';

export class AppointmentBoardSize {
  static big = 'big';
  static normal = 'normal';
  static small = 'small';
  static large = 'large';
  static xlarge = 'xlarge';
  static xxlarge = 'xxlarge';
}
export class AppointmentBoardState {
  constructor(auth) {
    this.auth = auth;
    makeObservable(this, {
      startAt: observable,
      endAt: observable,
      calendarUnit: observable,
      locationState: observable,
      showCanceled: observable,
      appointments: observable,
      filters: observable,
      clinic: observable,
      copied: observable,
      departmentCategories: observable,
      departmentAbsence: observable,
      counselors: observable,
      doctors: observable,
      smsAutoNotifications: observable,
      stateArr: observable,
      mode: observable,
      isMenuFold: observable,
      appointment: observable,
      contextMenuOptions: observable,
      departmentContextMenuOptions: observable,
      dragging: observable,
      targetSchedule: observable,
      showHoverCard: observable,
      dayExpanded: observable,
      cutClipBoard: observable,
      tempFilterStatusOld: observable,
      departmentSize: observable,
      calendarRect: observable,
      hours: observable,
      minutes: observable,
      filterStatus: observable,
      showTimeline: observable,
      departmentColor: observable,
      cellSize: observable,
      _monthDailyCount: observable,
      _monthDailyAppointments: observable,
      departmentDrawingFlag: observable,

      filteredAppointments: computed,
      filteredDepartmentCategories: computed,
      workTimes: computed,
      calendarTimes: computed,
      datesBetween: computed,
      calendarDays: computed,
      startDate: computed,
      endDate: computed,
      cellCrossAxisSize: computed,
      cellAxisSize: computed,
      cellAxisSizePadding: computed,
      stateColors: computed,
      minutesOffset: computed,
      workMinutesSpan: computed,
      count: computed,
      dailyAppointments: computed,
      dailyAppointmentsCount: computed,
      timedAppointments: computed,
      departmentContextAbsence: computed,
      monthlyCount: computed,
      monthDailyCount: computed,
      monthDailyAppointments: computed,
      threeDaysArr: computed,
      weekArr: computed,

      setSize: action,
      setCounselors: action,
      setDoctors: action,
      setSmsAutoNotifications: action,
      setDepartmentCategories: action,
      setIsMenuFold: action,
      setCopied: action,
      setDeparmentCategoryFilter: action,
      setCounselorFilter: action,
      setDoctorFilter: action,
      setPeriod: action,
      setEndAt: action,
      setCalendarUnit: action,
      setLocationState: action,
      setShowCanceled: action,
      setClinic: action,
      loadAppointments: action,
      setTempDepartmentColor: action,
      setTime: action,
      setCalendarRect: action,
      setDepartmentDrawingFlag: action,
      resetDayExpanded: action,
      setDayExpanded: action,
      setShowHoverCard: action,
    });

    reaction(
      () => {
        return [
          this.filters.departmentCategory,
          this.filters.counselor,
          this.filters.doctor,
          this.startAt,
          this.endAt,
          this.filterStatus,
        ];
      },
      () => {
        this.updateTimestamp = null;
      }
    );
  }

  startAt = new Date();
  endAt = new Date();
  calendarUnit = 'day';
  locationState = {};
  showCanceled = true;

  updateTimestamp = null;
  /** @type Appointment[] */
  appointments = [];

  filters = {
    departmentCategory: null,
    counselor: null,
    doctor: null,
  };
  filterStatus = [];

  clinic = null;
  copied = null;
  cutClipBoard = null;
  copySchedule = null;

  departmentCategories = [];
  departmentAbsence = [];
  counselors = [];
  doctors = [];
  smsAutoNotifications = [];
  departmentColor = window.localStorage.getItem('departmentColor')
    ? JSON.parse(window.localStorage.getItem('departmentColor'))
    : {};

  stateArr = [
    { codeName: 'scheduled', codeValue: null },
    { codeName: 'unvisited', codeValue: null },
    { codeName: 'consulting_waiting', codeValue: null },
    { codeName: 'consulting_during', codeValue: null },
    { codeName: 'treatment_waiting', codeValue: null },
    { codeName: 'treatment_during', codeValue: null },
    { codeName: 'surgery_waiting', codeValue: null },
    { codeName: 'surgery_during', codeValue: null },
    { codeName: 'payment_waiting', codeValue: null },
    { codeName: 'complete', codeValue: null },
    { codeName: 'canceled', codeValue: null },
    { codeName: 'leave', codeValue: null },
    { codeName: 'absence', codeValue: '#d8d8d8' },
  ];

  mode = window.localStorage.getItem('calendarMode') ?? 'vertical';

  isMenuFold =
    ui.isMenuFold && typeof ui.isMenuFold.appointment === 'boolean'
      ? ui.isMenuFold.appointment
      : false;

  appointment = null;
  hoveringAppointment = null;

  contextMenuOptions = {
    show: false,
    x: 0,
    y: 0,
    items: [],
  };

  departmentContextMenuOptions = {
    show: false,
    x: 0,
    y: 0,
    items: [],
    date: null,
    department: null,
  };

  showHoverCard = false;
  hoverCardPosition = { x: 0, y: 0 };

  dragging = false;
  targetSchedule = null;

  hoverCardRef = useRef();

  dailyBoardRef = null;

  weeklyBoardRef = null;

  scrollState = false;

  departmentDrawingFlag = false;

  dayExpanded = {};

  tempFilterStatusOld = true;

  departmentSize = {};

  departmentListRef = null;
  calendarRect = null;

  currentHours = new Date().getHours();
  currentMinutes = new Date().getMinutes();

  _monthDailyCount = {};

  _monthDailyAppointments = {};

  hours = undefined;
  minutes = undefined;

  /** @returns {Appointment[]} */
  get filteredAppointments() {
    return this.appointments.filter(
      (a) =>
        (this.filters.departmentCategory
          ? this.filters.departmentCategory.id === a.departmentCategoryId
          : true) &&
        (this.filters.doctor ? this.filters.doctor.id === a.doctorId : true) &&
        (this.filters.counselor
          ? this.filters.counselor.id === a.counselorId
          : true) &&
        (!this.showCanceled ? a.status !== 'canceled' : true) &&
        (this.filterStatus.length > 0
          ? this.filterStatus.includes(a.status)
          : true) &&
        a.deletedAt == null &&
        this.datesBetween.includes(a.date)
    );
  }

  /** @returns {{first: number, second: number, canceled: number, total: number}} */
  get count() {
    const first = this.filteredAppointments.filter(
      (a) => a.isNewCustomer && a.status !== 'canceled'
    ).length;
    const second = this.filteredAppointments.filter(
      (a) => !a.isNewCustomer && a.status !== 'canceled'
    ).length;
    const canceled = this.filteredAppointments.filter(
      (a) => a.status === 'canceled'
    ).length;
    const total = first + second + canceled;

    return {
      first,
      second,
      canceled,
      total,
    };
  }

  get monthDailyAppointments() {
    return this.datesBetween.reduce((p, c) => {
      p[c] = this._monthDailyAppointments[c] ?? [];
      return p;
    }, {});
  }

  get monthDailyCount() {
    return this.datesBetween.reduce((p, c) => {
      p[c] = this._monthDailyCount[c] ?? {
        first: 0,
        second: 0,
        canceled: 0,
        total: 0,
      };
      return p;
    }, {});
  }

  get monthlyCount() {
    return this.datesBetween.reduce(
      (p, c) => {
        return {
          first: p.first + this.monthDailyCount[c].first,
          second: p.second + this.monthDailyCount[c].second,
          canceled: p.canceled + this.monthDailyCount[c].canceled,
          total: p.total + this.monthDailyCount[c].total,
        };
      },
      {
        first: 0,
        second: 0,
        canceled: 0,
        total: 0,
      }
    );
  }

  showTimeline =
    window.localStorage.getItem('showTimeline') != null
      ? window.localStorage.getItem('showTimeline') === 'true'
      : true;

  cellSize =
    window.localStorage.getItem('calendarSize') ?? AppointmentBoardSize.normal;

  get stateColors() {
    const colors = {};
    for (const state of this.stateArr) {
      colors[state.codeName] = state;
    }
    return colors;
  }

  timeToMins(time) {
    const [hour, mins] = time.split(':');

    return 60 * Number(hour) + Number(mins);
  }

  get workMinutesSpan() {
    return (
      this.timeToMins(this.clinic.workEnd) -
      this.timeToMins(this.clinic.workStart) +
      this.clinic.appointmentTimeUnit
    );
  }

  get minutesOffset() {
    return (
      this.hours * 60 + this.minutes - this.timeToMins(this.clinic.workStart)
    );
  }

  get cellCrossAxisSize() {
    if (this.mode === 'vertical') {
      switch (this.cellSize) {
        case AppointmentBoardSize.small:
          return 33;
        case AppointmentBoardSize.normal:
          return 41;
        case AppointmentBoardSize.big:
          return 49;
        case AppointmentBoardSize.large:
          return 57;
        case AppointmentBoardSize.xlarge:
          return 65;
        case AppointmentBoardSize.xxlarge:
          return 73;
        default:
          throw new Error('unreachable code');
      }
    }

    if (this.mode === 'horizontal') {
      switch (this.cellSize) {
        case AppointmentBoardSize.small:
          return 41;
        case AppointmentBoardSize.normal:
          return 51;
        case AppointmentBoardSize.big:
          return 61;
        case AppointmentBoardSize.large:
          return 71;
        case AppointmentBoardSize.xlarge:
          return 81;
        case AppointmentBoardSize.xxlarge:
          return 91;

        default:
          throw new Error('unreachable code');
      }
    }

    throw new Error('unreachable code');
  }

  get cellAxisSize() {
    if (this.mode === 'vertical') {
      switch (this.cellSize) {
        case AppointmentBoardSize.small:
          return 41;
        case AppointmentBoardSize.normal:
          return 51;
        case AppointmentBoardSize.big:
          return 61;
        case AppointmentBoardSize.large:
          return 71;
        case AppointmentBoardSize.xlarge:
          return 81;
        case AppointmentBoardSize.xxlarge:
          return 91;

        default:
          throw new Error('unreachable code');
      }
    }

    if (this.mode === 'horizontal') {
      switch (this.cellSize) {
        case AppointmentBoardSize.small:
          return 34;
        case AppointmentBoardSize.normal:
          return 42;
        case AppointmentBoardSize.big:
          return 50;
        case AppointmentBoardSize.large:
          return 58;
        case AppointmentBoardSize.xlarge:
          return 66;
        case AppointmentBoardSize.xxlarge:
          return 74;

        default:
          throw new Error('unreachable code');
      }
    }

    throw new Error('unreachable code');
  }

  get cellAxisSizePadding() {
    return this.mode === 'vertical' ? 9 : 9;
  }

  get startDate() {
    return moment(this.startAt).format('YYYY-MM-DD');
  }

  get endDate() {
    return moment(this.endAt).format('YYYY-MM-DD');
  }

  get dateCategoryDepartments() {
    return this.datesBetween.reduce((p, c) => {
      return p.concat(
        this.filteredDepartmentCategories
          .reduce((pc, cc) => {
            return pc.concat(
              cc.departments
                .filter((d) => d.visible)
                .map((d) => ({
                  department: d,
                  category: cc,
                }))
            );
          }, [])
          .map((cd) => ({
            date: c,
            department: cd.department,
            category: cd.category,
          }))
      );
    }, []);
  }

  get threeDaysArr() {
    const arr = [];

    for (let i = 1; i <= 3; i++) {
      arr.push((this.dateCategoryDepartments.length / 3) * i);
    }
    return arr;
  }

  get weekArr() {
    const arr = [];

    for (let i = 1; i <= 7; i++) {
      arr.push((this.dateCategoryDepartments.length / 7) * i);
    }
    return arr;
  }

  get calendarDays() {
    const startDay = moment(this.startAt).format('d');
    const endDay = moment(this.endAt).format('d');
    const calendarStartAt = moment(this.startAt).add(-startDay, 'days');
    const calendarEndAt = moment(this.endAt).add(6 - endDay, 'days');

    // pivot: 이 커스텀 캘린더의 가장 첫 칸에 들어갈 날짜입니다.
    // 월요일을 첫 열로 하고 2020-07을 기준으로 보자면, 2020-06-29가 되겠네요!
    // 달력구현이 쉬운일은 아니라 좀 더 알아보기 쉽게 만들지 못해 죄송합니다 ㅜ.ㅜ
    return numArray(calendarEndAt.diff(calendarStartAt, 'days')).map((day) => {
      return moment(calendarStartAt).add(day, 'days').format('YYYY-MM-DD');
    });
  }

  /** @returns {Object.<string, Appointment[]>} */
  get dailyAppointments() {
    const datesMap = {};
    for (const date of this.datesBetween) {
      datesMap[date] = [];
    }

    this.filteredAppointments.forEach((a) => {
      datesMap[a.date].push(a);
    });

    for (const date of this.datesBetween) {
      datesMap[date].sort((a, b) => {
        return new Date(b.scheduledAt) - new Date(a.scheduledAt) > 0 ? -1 : 1;
      });
    }

    return datesMap;
  }

  /** @returns {Object.<string, Object.<string, Object.<string, Appointment[]>>>} */
  get timedAppointments() {
    const result = {};
    this.datesBetween.forEach((date) => {
      const dateFiltered = this.dailyAppointments[date];
      this.calendarTimes.forEach((time, i) => {
        const timeFiltered = dateFiltered.filter(
          (appointment) =>
            //ex) 예약이 10:10분, 그러나 unit: 30분일때를 위해 리턴 조건 변경
            //기존에는 같은 시간일때만 그렸음 appointment.startHour === time
            appointment.startHour >= time &&
            appointment.startHour < this.calendarTimes[i + 1]
        );

        this.filteredDepartmentCategories.forEach((category) => {
          category.departments.forEach((department) => {
            if (!department.visible) return;

            const depFiltered = timeFiltered
              .filter((app) => app.departmentId === department.id)
              .sort((a, b) => {
                return a.endHour > b.endHour ? -1 : 1;
              });
            //findAppointmentToEndHour: 현재 타임에 존재하는 예약건, (전타임에서 이어지는 것도 찾는다)
            this.findAppointmentsIn(
              result,
              depFiltered,
              department,
              date,
              i > 0 ? this.calendarTimes[i - 1] : null,
              time
            );
          });
        });
      });
    });
    return result;
  }

  /** @returns {Object.<string, {first: number, second: number, canceled: number, total: number}>} */
  get dailyAppointmentsCount() {
    const datesMap = {};
    for (const date of this.datesBetween) {
      const appointments = this.dailyAppointments[date];
      datesMap[date] = {
        first: appointments.filter(
          (a) => a.isNewCustomer && a.status !== 'canceled'
        ).length,
        second: appointments.filter(
          (a) => !a.isNewCustomer && a.status !== 'canceled'
        ).length,
        canceled: appointments.filter((a) => a.status === 'canceled').length,
        total: appointments.length,
      };
    }

    return datesMap;
  }

  get datesBetween() {
    const diffDays = moment(this.endAt).diff(moment(this.startAt), 'days');

    const days = [];
    for (let i = 0; i < diffDays + 1; i++) {
      const d = new Date(this.startAt);
      d.setDate(this.startAt.getDate() + i);
      days.push(moment(d).format('YYYY-MM-DD'));
    }

    return days;
  }

  get filteredDepartmentCategories() {
    return this.departmentCategories
      .filter((c) => c.visible)
      .filter((category) => {
        if (!this.filters.departmentCategory) return true;
        return category.id === this.filters.departmentCategory.id;
      });
  }

  get workTimes() {
    const start = moment(
      moment().format('YYYY-MM-DD ') + this.clinic.workStart
    );
    const end = moment(moment().format('YYYY-MM-DD ') + this.clinic.workEnd);

    let current = start;
    let arr = [];
    while (current.isSameOrBefore(end)) {
      arr.push(current.format('HH:mm'));
      current.add(this.auth.me.clinic.appointmentTimeUnit, 'minutes');
    }

    return arr;
  }

  // 달력 단위(일, 주, 월)에 따라 쿼리된 전체 일정(appointments)을 단위대로 나눔
  get calendarTimes() {
    let leftmost = [];
    this.workTimes.forEach((time) => {
      let endsWith = time.split(':')[1];
      [5, 10, 15, 30, 60].forEach((unit) => {
        if (
          this.clinic.appointmentTimeUnit === unit &&
          fillTimeframe(this.clinic.appointmentTimeUnit).indexOf(endsWith) !==
            -1
        ) {
          leftmost.push(time);
        }
      });
    });

    return leftmost;
  }

  get departmentContextAbsence() {
    if (this.departmentContextMenuOptions.department) {
      return this.departmentAbsence.filter(
        (v) =>
          (v.department || {}).id ===
            this.departmentContextMenuOptions.department.id &&
          moment(v.scheduledAt).format('YYYY-MM-DD') ===
            moment(this.departmentContextMenuOptions.date).format('YYYY-MM-DD')
      );
    } else return [];
  }

  getDailyAppointments(date) {
    if (!this.dailyAppointments[date]) {
      this.dailyAppointments[date] = [];
    }

    return this.dailyAppointments[date];
  }

  getDepartmentViewSize(department, date) {
    const count = Math.max(
      1,
      this.timedAppointments[department.id]?.[date]?.maxCount ?? 1
    );

    return this.cellAxisSize * count + this.cellAxisSizePadding;
  }

  getDepartmentAbsence(departmentId, scheduledAt) {
    return this.departmentAbsence.some(
      (v) =>
        (v.department || {}).id === departmentId &&
        moment(v.scheduledAt).format('YYYY-MM-DD') ===
          moment(scheduledAt).format('YYYY-MM-DD')
    )
      ? true
      : false;
  }

  getDepartmentColor(departmentId, date) {
    // Check Absence
    // console.log({...this.departmentColor})
    // console.log(departmentId)
    if (
      this.departmentAbsence.some(
        (v) =>
          (v.department || {}).id === departmentId &&
          moment(v.scheduledAt).format('YYYY-MM-DD') === date
      )
    ) {
      return this.stateColors['absence']
        ? this.stateColors['absence'].codeValueTemp ||
            this.stateColors['absence'].codeValue
        : undefined;
    }
    // Check Local DepartmentColor
    else if (departmentId in this.departmentColor) {
      return (
        this.departmentColor[departmentId].codeValueTemp ||
        this.departmentColor[departmentId].codeValue
      );
    } else {
      return undefined;
    }
  }

  getDepartmentOriginalColor(departmentId) {
    if (departmentId in this.departmentColor) {
      return (
        this.departmentColor[departmentId].codeValueTemp ||
        this.departmentColor[departmentId].codeValue ||
        '#FFFFFF'
      );
    } else {
      return '#FFFFFF';
    }
  }

  setTime(hours, minutes) {
    this.hours = hours;
    this.minutes = minutes;
  }

  resetDayExpanded() {
    this.dayExpanded = {};
  }

  setDayExpanded(day, expanded) {
    this.dayExpanded[day] = expanded;
  }

  toggleMode() {
    if (this.mode === 'horizontal') {
      this.mode = 'vertical';
    } else {
      this.mode = 'horizontal';
    }
    window.localStorage.setItem('calendarMode', this.mode);
  }

  toggleTimeline() {
    this.showTimeline = !this.showTimeline;
    window.localStorage.setItem('showTimeline', this.showTimeline.toString());
  }

  setSize(size) {
    this.cellSize = size;
    window.localStorage.setItem('calendarSize', size);
  }

  setDragging(dragging) {
    this.dragging = dragging;
  }

  setCopySchedule(schedule) {
    this.copySchedule = schedule;
  }

  setTargetSchedule(schedule) {
    this.targetSchedule = schedule;
  }

  setContextMenuOptions(option) {
    this.contextMenuOptions = option;
  }

  setDepartmentContextMenuOptions(option) {
    this.departmentContextMenuOptions = option;
  }

  setShowHoverCard(show) {
    this.showHoverCard = show;
  }

  setDepartmentAbsence(absenceData) {
    this.departmentAbsence = absenceData;
  }

  setHoverCardPosition(pos) {
    if (this.hoverCardRef?.current) {
      this.hoverCardRef.current.style.left = `${Math.min(
        window.innerWidth - this.hoverCardRef.current.clientWidth,
        pos.x
      )}px`;
      this.hoverCardRef.current.style.top = `${Math.min(
        window.innerHeight - this.hoverCardRef.current.clientHeight,
        pos.y
      )}px`;
    } else {
      this.hoverCardPosition = pos;
    }
  }

  setHoverCardOptions(option) {
    this.hoverCardOptions = option;
  }

  setAppointment(appointment) {
    this.appointment = appointment;
  }

  setHoveringAppointment(appointment) {
    this.hoveringAppointment = appointment;
  }

  setCounselors(counselors) {
    this.counselors = counselors;
  }

  setDoctors(doctors) {
    this.doctors = doctors;
  }

  setSmsAutoNotifications(smsAutoNotifications) {
    this.smsAutoNotifications = smsAutoNotifications;
  }

  setDepartmentCategories(categories) {
    this.departmentCategories = categories;
  }

  setIsMenuFold(isMenuFold) {
    ui.setUiState('isMenuFold', { appointment: isMenuFold });
    this.isMenuFold = isMenuFold;
  }

  setCopied(copied) {
    this.copied = copied ? toJS(copied) : null;
  }

  setCutClipBoard(cutClipBoard) {
    this.cutClipBoard = cutClipBoard ? toJS(cutClipBoard) : null;
  }

  setDeparmentCategoryFilter(departmentCategory) {
    this.filters.departmentCategory = departmentCategory;
  }

  setCounselorFilter(counselor) {
    this.filters.counselor = counselor;
  }

  setDoctorFilter(doctor) {
    this.filters.doctor = doctor;
  }

  setFilterStatus(filterStatus) {
    if (this.filterStatus.some((fs) => fs === filterStatus)) {
      // this.filterStatus = this.filterStatus.filter(x => x!==filterStatus)
      this.filterStatus = [];
    } else {
      this.filterStatus = [filterStatus];
    }
  }

  setPeriod(start, end) {
    this.startAt = start;
    this.endAt = end;
  }

  setEndAt(end) {
    this.endAt = end;
  }

  setCalendarUnit(unit) {
    this.calendarUnit = unit;
  }

  setLocationState(state) {
    this.locationState = { ...state };
  }

  setShowCanceled(showCanceled) {
    this.showCanceled = showCanceled;
  }

  setClinic(clinic) {
    this.clinic = clinic;
  }

  toggleFilterStatusOld() {
    this.tempFilterStatusOld
      ? (this.tempFilterStatusOld = false)
      : (this.tempFilterStatusOld = true);
  }

  setTempDepartmentColor(departmentId, colorhex) {
    if (!(departmentId in this.departmentColor)) {
      this.departmentColor[departmentId] = {
        codeValueTemp: colorhex,
      };
    } else this.departmentColor[departmentId].codeValueTemp = colorhex;
  }

  setDepartmentColor(departmentId, colorhex) {
    this.departmentColor[departmentId] = {
      codeValue: colorhex,
      codeValueTemp: null,
    };
    modal
      .confirm({
        type: 'SUCCESS',
        msg: '업데이트되었습니다.',
      })
      .then(() => {
        window.localStorage.setItem(
          'departmentColor',
          JSON.stringify(this.departmentColor)
        );
      });
  }

  loadDailyAppointments = flow(
    /** @this AppointmentBoardState */
    function* (date) {
      try {
        yield this.updateMonthlyAppointmentCount();

        //data
        let dataQuery = $qb()
          .limit(6000)
          .customParam('startAt', date)
          .customParam('endAt', date)
          .customParam('departmentVisible', true)
          .customParam('includeDeleted', true);

        const resp = yield services.crm.crud.appointment.monthly(
          dataQuery.build()
        );
        if (!resp) return;

        this._monthDailyAppointments[date] = resp.data
          .map((a) => new Appointment(a))
          .sort((a, b) => {
            return new Date(b.scheduledAt) - new Date(a.scheduledAt) > 0
              ? -1
              : 1;
          });
      } catch (e) {
        console.log(e);
      }
    }
  );

  loadSingleAppointment = flow(
    /** @this AppointmentBoardState */
    function* (appointmentId) {
      const resp = yield services.crm.crud.appointment.detail(appointmentId);

      if (!resp) return;

      let appointment = resp.data;
      // 시,수술 예약인 경우 paymentTreatmentItems 배열에 카테고리 객체가 없어서, 생성해줘야함.
      // items와 paymentTreatmentItems의 구조가 다름
      if (resp.data.type === 'surgery') {
        let chagnePaymentTreatmentItems = appointment.paymentTreatmentItems.map(
          (v) => {
            return {
              ...v,
              category: {
                name: v.categoryName,
              },
            };
          }
        );

        appointment.paymentTreatmentItems = chagnePaymentTreatmentItems;
      }
      this.mergeAppointments([appointment]);
    }
  );

  loadAppointments = flow(
    /** @this AppointmentBoardState */
    function* () {
      if (this.calendarUnit === 'month') {
        yield this.monthlyCallApi();
      } else {
        yield this.notMonthlyCallApi((appointments) =>
          this.mergeAppointments(appointments)
        );
      }
    }
  );

  mergeAppointments(appointments) {
    if (this.appointments.length === 0) {
      for (const a of appointments) {
        const app = new Appointment(a);
        this.appointments.push(app);
      }
      return;
    }

    for (const a of appointments) {
      const old = this.appointments.find((app) => app.id === a.id);
      if (!old) {
        this.appointments.push(new Appointment(a));
      } else {
        Object.assign(old, a);
      }
    }
  }

  findAppointmentsIn(result, appointments, department, date, prevTime, time) {
    if (!result[department.id]) {
      result[department.id] = {};
    }

    const dateStr = toDateString(date);

    if (!result[department.id][dateStr]) {
      result[department.id][dateStr] = {
        maxCount: 0,
        timedAppointments: {},
      };
    }

    if (!prevTime) {
      result[department.id][dateStr].timedAppointments[time] = appointments;
      result[department.id][dateStr].maxCount = appointments.length;
      return;
    }

    //prevAppointment : 이전 시간의 예약건
    const prevAppointments = result[department.id][dateStr].timedAppointments[
      prevTime
    ].map((appointment) => {
      if (!appointment) return null;

      if (appointment.endHour <= time) {
        return null;
      } else if (appointment.endHour > time) {
        //현재 시간라인보다 예약의 종료시간이 넘어 갔을 때,
        //cellSize가 몇칸으로 그려져서 넘어가서 그려졌는지, 넘어가서 그려지지 않았는지를 판단하여
        //넘어가서 그려졌으면 return v, 넘어가지 않았으면 빈공간이므로 return null
        const today = moment().format('YYYY-MM-DD');
        let start = moment(`${today} ${(appointment || {}).startHour}`);
        let currentStart = moment(`${today} ${time}`);
        let end = moment(`${today} ${appointment.endHour}`);
        let diff = end.diff(start, 'minutes');
        let diff2 = start.diff(currentStart, 'minutes');
        //디폴트 셀사이즈
        let originalCellSize = Math.ceil(
          diff / this.auth.me.clinic.appointmentTimeUnit
        );
        //currentCellSize = 지금 시간까지 셀사이즈 몇칸이 그려졌는가?
        let currentCellSize = Math.ceil(
          Math.abs(diff2) / this.auth.me.clinic.appointmentTimeUnit
        );

        if (diff2 >= this.auth.me.clinic.appointmentTimeUnit) {
          return appointment;
        } else {
          if (originalCellSize !== currentCellSize) {
            return appointment;
          } else {
            return null;
          }
        }
      } else {
        return appointment;
      }
    });

    //example
    //prevAppointment = [null, null, {}, null]
    //curAppointemnt = [{},{}]
    //result = [{c1},{c2},{},null]
    prevAppointments.forEach((prev, i) => {
      if (prev == null && appointments.length > 0) {
        prevAppointments[i] = appointments[0];
        appointments.splice(0, 1);
      }
    });

    // Remove trailing nulls
    for (let i = prevAppointments.length - 1; i >= 0; i--) {
      if (prevAppointments[i]) {
        break;
      } else {
        prevAppointments.splice(i, 1);
      }
    }

    //남은 예약건은 concat으로 이어붙임
    let appointmentsSince = prevAppointments.concat(appointments);

    result[department.id][dateStr].timedAppointments[time] = appointmentsSince;
    result[department.id][dateStr].maxCount = Math.max(
      result[department.id][dateStr].maxCount,
      appointmentsSince.length
    );
  }

  monthlyCallApi = flow(
    /** @this AppointmentBoardState */
    function* () {
      try {
        const startDate = this.startDate;
        const endDate = this.endDate;

        yield this.updateMonthlyAppointmentCount();

        //data
        let dataQuery = $qb()
          .limit(5)
          .customParam('startAt', this.startDate)
          .customParam('endAt', this.endDate)
          .customParam('updatedStartAt', this.updateTimestamp)
          .customParam(
            'departmentCategoryId',
            (this.filters.departmentCategory || {}).id
          )
          .customParam('counselorId', (this.filters.counselor || {}).id)
          .customParam('doctorId', (this.filters.doctor || {}).id)
          .customParam('departmentVisible', true)
          .customParam('status', this.filterStatus.join(','));

        const resp = yield services.crm.crud.appointment.monthly(
          dataQuery.build()
        );
        if (!resp) return;
        if (startDate !== this.startDate || endDate !== this.endDate) return;

        const datesMap = {};
        for (const date of this.datesBetween) {
          datesMap[date] = [];
        }

        resp.data.forEach((a) => {
          const appointment = new Appointment(a);
          datesMap[appointment.date].push(appointment);
        });

        for (const date of this.datesBetween) {
          datesMap[date].sort((a, b) => {
            return new Date(b.scheduledAt) - new Date(a.scheduledAt) > 0
              ? -1
              : 1;
          });
        }

        this._monthDailyAppointments = datesMap;
      } catch (e) {
        console.log(e);
      }
    }
  );

  updateMonthlyAppointmentCount = flow(function* () {
    const query = $qb()
      .limit(1000)
      .customParam('startAt', this.startDate)
      .customParam('endAt', this.endDate)
      .customParam(
        'departmentCategoryId',
        (this.filters.departmentCategory || {}).id
      )
      .customParam('counselorId', (this.filters.counselor || {}).id)
      .customParam('doctorId', (this.filters.doctor || {}).id)
      .customParam('departmentVisible', true)
      .customParam('status', this.filterStatus.join(','));

    const monthlyCountResp = yield services.crm.crud.appointment.monthly_count(
      query.build()
    );

    const counts = this.datesBetween.reduce((p, c) => {
      p[c] = {
        total: 0,
        first: 0,
        second: 0,
        canceled: 0,
      };
      return p;
    }, {});

    monthlyCountResp.data.forEach((count) => {
      const item = counts[count.scheduledAt];

      if (count.isNewCustomer && count.status !== 'canceled') {
        item.first += count.cnt;
      } else if (!count.isNewCustomer && count.status !== 'canceled') {
        item.second += count.cnt;
      }
      if (count.status === 'canceled') {
        item.canceled += count.cnt;
      }
      item.total += count.cnt;
    });

    this._monthDailyCount = counts;
  });

  notMonthlyCallApi = flow(
    /** @this AppointmentBoardState */
    function* (updateFunc) {
      try {
        const startAt = this.startAt;
        const endAt = this.endAt;
        const query = $qb()
          .limit(6000)
          .customParam('departmentVisible', true)
          .customParam('updatedStartAt', this.updateTimestamp)
          .customParam('includeDeleted', true);

        // if (this.updateTimestamp == null) {
        query
          .customParam('startAt', moment(this.startAt).format('YYYY-MM-DD'))
          .customParam('endAt', moment(this.endAt).format('YYYY-MM-DD'));
        // }

        const resp = yield services.crm.crud.appointment.list(query.build());

        if (!resp) return;
        if (startAt !== this.startAt || endAt !== this.endAt) return;

        this.updateTimestamp = resp.timeAt;
        this.departmentAbsenceApi();
        updateFunc(resp.data);
      } catch (e) {
        console.log(e);
      }
    }
  );

  departmentAbsenceApi = flow(
    /** @this AppointmentBoardState */
    function* () {
      const absenceResp = yield services.crm.crud.absenceSchedule.all({
        startAt: moment(this.startAt).format('YYYY-MM-DD'),
        endAt: moment(this.endAt).add(1, 'days').format('YYYY-MM-DD'),
      });
      if (absenceResp) {
        this.departmentAbsence = absenceResp.data;
      }
    }
  );

  getDepartmentSize(index) {
    return this.departmentSize[index] ?? (this.mode === 'vertical' ? 62 : 49);
  }

  setDepartmentSize(index, size) {
    if (size === this.departmentSize[index]) return;
    this.departmentSize[index] = size;
    // eslint-disable-next-line no-unused-expressions
    this.departmentListRef?.current?.resetAfterIndex(index);
  }

  getCategoryViewSize(category, date) {
    const result = category.departments
      .filter((d) => d.visible)
      .reduce((p, c) => {
        return p + this.getDepartmentViewSize(c, date);
      }, 0);
    return result;
  }

  getDateViewSize(date) {
    return this.filteredDepartmentCategories.reduce((p, c) => {
      return p + this.getCategoryViewSize(c, date);
    }, 0);
  }

  setCalendarRect(rect) {
    this.calendarRect = rect;
  }

  setDepartmentDrawingFlag(value) {
    this.departmentDrawingFlag = value;
  }

  recalcDepartmentSizeAll() {
    this.dateCategoryDepartments.forEach(({ department, date }, i) => {
      const size = this.getDepartmentViewSize(department, date);
      this.setDepartmentSize(i, size);
    });
  }
}
