import { translate } from 'filters';

export default {
  options: {
    hasMergeCell: true,
    groupTitles: ['ACTION', '카테고리'],
    explodeColumn: 'consultingRequestStatus',
  },
  keys: [
    {
      title: 'ACTION',
      noorder: true,
      filter: (o) => `
        ${
          o.name === '응대 성공' || o.name === '미입력'
            ? ''
            : `
            ${
              o.name === '리콜 필요'
                ? `<button class="btn btn-sm btn-primary btn-edit m-r-8">수정</button>`
                : o['visible']
                ? `<button class="btn btn-sm btn-primary btn-edit m-r-8">수정</button>
            <button class="btn btn-sm m-r-8 btn-black" data-type="visible" >${translate(
              'TOGGLE_DEACTIVATE'
            )}</button>`
                : ''
            }
            ${
              !o['visible']
                ? `<button class="btn btn-sm btn-gray" data-type="visible" >${translate(
                    'TOGGLE_ACTIVATE'
                  )}</button>`
                : ''
            }`
        }
        `,
    },
    {
      title: '카테고리',
      column: 'name',
      noorder: true,
    },
    {
      //응대성공 카테고리는 추가,수정,삭제,미사용 모두 불가능
      title: '응대 처리결과',
      column: '@@consultingRequestStatus__name',
      noorder: true,
      filter: (o) => `<div>
            <div class="flex-row items-center flex-between">
                <div>${o['@@consultingRequestStatus'].name}</div>
                ${
                  o.name === '응대 성공' ||
                  o.name === '미입력' ||
                  (o.name === '리콜 필요' &&
                    o['@@consultingRequestStatus'].defaultCode)
                    ? ''
                    : `
                    ${
                      o['@@consultingRequestStatus']['visible']
                        ? `<button class="btn btn-sm ${
                            o['visible'] ? 'btn-black' : 'btn-gray'
                          } m-l-8 m-r-8" data-type="visible" >${translate(
                            'TOGGLE_DEACTIVATE'
                          )}</button>`
                        : ''
                    }
                    ${
                      !o['@@consultingRequestStatus']['visible']
                        ? `<button class="btn btn-sm btn-gray m-l-8 m-r-8" data-type="visible" >${translate(
                            'TOGGLE_ACTIVATE'
                          )}</button>`
                        : ''
                    }`
                }
                ${
                  o.name === '응대 성공' ||
                  o.name === '미입력' ||
                  (o.name === '리콜 필요' &&
                    o['@@consultingRequestStatus'].defaultCode)
                    ? ''
                    : `<button class="btn btn-sm m-r-8 ${
                        o['visible'] ? 'btn-danger' : 'btn-gray'
                      } ${
                        o['@@consultingRequestStatus']['visible']
                          ? 'btn-dagner'
                          : 'btn-gray'
                      } " data-type="delete" >삭제</button>`
                }
            </div>
        </div>`,
    },
  ],
};
