import React, { useCallback, useContext } from 'react';
import { observer } from 'mobx-react';
//import { AppointmentBoardState } from 'store/appointmentBoardState';
import { AppointmentBoardStateContext } from './AppointmentBoards';
import MonthlyAppointmentCell from './MonthlyAppointmentCell';

// 달력 안에 들어갈 날짜별 예약
const AppointmentItems = observer(({ date }) => {
  /**
   * @type {AppointmentBoardState }
   */
  const state = useContext(AppointmentBoardStateContext);

  const onClickExpanded = useCallback(() => {
    state.setDayExpanded(date, !state.dayExpanded[date]);
    if (state.dayExpanded[date]) {
      state.loadDailyAppointments(date);
    }
  }, [date]);

  const cnt = state.monthDailyCount[date];

  return (
    <div className="appointment-items">
      {state.datesBetween.includes(date) && (
        <>
          <div className="stats">
            <span className="ti">예약</span>
            {cnt.total}
            <span className="ti">신환</span>
            {cnt.first}
            <span className="ti">구환</span>
            {cnt.second}
            <span className="ti">취소</span>
            {cnt.canceled}
          </div>
          {state.monthDailyAppointments[date]
            .filter(
              (appointment, index) => state.dayExpanded[date] || index <= 4
            )
            .map((appointment) => {
              return (
                <MonthlyAppointmentCell
                  key={appointment.id}
                  appointment={appointment}
                />
              );
            })}
          {(!state.showCanceled ? cnt.total : cnt.total - cnt.canceled) > 5 && (
            <div
              onClick={() => {
                onClickExpanded();
              }}
              className="more"
            >
              {!state.dayExpanded[date]
                ? !state.showCanceled
                  ? `${cnt.total - 5}개 더보기`
                  : `${cnt.total - cnt.canceled - 5}개 더보기`
                : '접기'}
            </div>
          )}
        </>
      )}
    </div>
  );
});

export default AppointmentItems;
