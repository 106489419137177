import React, { useState, useEffect, useCallback } from 'react';
import { observer } from 'mobx-react';
import DataTableAlpha from 'components/data-table/DataTableAlpha';
import PropTypes from 'prop-types';
import models from 'models';
import { translate } from 'filters';
import { $qb } from 'scripts/querybuilder';
import { useServices } from 'hooks/useServices';
import styled from 'styled-components';
import searchIcon from 'assets/images/icon/ic-search.png';

const Input = styled.input`
  width: 100%;
  height: 34px;
  font-size: 12px;
  display: block;
  min-height: 10px;
  border-radius: 4px;
  color: #000;
  border: 1px solid #dee2ec;
  padding: 8px 15px 8px 50px;
  background-color: #fff;
  background-image: url(${searchIcon});
  background-size: 19px;
  background-repeat: no-repeat;
  background-position: left 15px center;

  &::placeholder {
    font-size: 12px;
  }
`;

const ModalAddPaymentProducts = ({ close }) => {
  const services = useServices();
  const defaultProductParams = $qb().limit(5);
  const [productParams, setProductParams] = useState(defaultProductParams);
  const [productSearchValue, setProductSearchValue] = useState('');
  const [productData, setProductData] = useState([]);
  const [productTotal, setProductTotal] = useState(0);
  const [checkedProducts, setCheckedProducts] = useState([]);
  const [inputQuantity, setInputQuantity] = useState([]);
  const [inputVatPrices, setInputVatPrices] = useState([]);
  const [inputPrices, setInputPrices] = useState([]);

  const productCallApi = async () => {
    productParams.customParam('visible', true);
    let resp = await services.crm.payment.product.all(productParams.build());
    //페이지네이션시에도 입력한 갯수가 유지되도록
    resp.data.map((data) => {
      //개수 인풋박스 유지되도록
      if ((inputQuantity || []).find((v) => v.id === data.id) !== undefined) {
        data[`QUANTITY_$$input`] = (inputQuantity || []).find(
          (v) => v.id === data.id
        )[`QUANTITY_$$input`];
      } else {
        data[`QUANTITY_$$input`] = data.quantity || 0;
      }

      //가격(VAT 포함) 인풋박스 유지되도록
      if ((inputVatPrices || []).find((v) => v.id === data.id) !== undefined) {
        data[`PRICE_VAT_INCLUDE_$$input`] = (inputVatPrices || []).find(
          (v) => v.id === data.id
        )[`PRICE_VAT_INCLUDE_$$input`];
      } else {
        data[`PRICE_VAT_INCLUDE_$$input`] = data.priceVatInclude;
      }

      if ((inputPrices || []).find((v) => v.id === data.id) !== undefined) {
        data[`NOT_VAT_INCLUDE_$$input`] = (inputPrices || []).find(
          (v) => v.id === data.id
        )[`NOT_VAT_INCLUDE_$$input`];
      } else {
        data[`NOT_VAT_INCLUDE_$$input`] = data.price;
      }

      return data;
    });
    setProductData(resp.data);
    setProductTotal(resp.total);
  };

  useEffect(() => {
    productCallApi();
  }, []);

  const onProductParams = (p) => {
    setProductParams(p);
    productCallApi();
  };

  const onChangePriceVat = (surgeries) => {
    if (
      (inputVatPrices || []).find((v) => v.id === surgeries.id) === undefined
    ) {
      inputVatPrices.push(surgeries);
      let list = [...inputVatPrices];
      list.push(surgeries);
      setInputVatPrices([...list]);
    } else {
      let list = inputVatPrices.map((v) => {
        if (v.id === surgeries.id) {
          v[`PRICE_VAT_INCLUDE_$$input`] =
            surgeries[`PRICE_VAT_INCLUDE_$$input`];
          v[`NOT_VAT_INCLUDE_$$input`] = v['vatIncluded']
            ? Math.round(v[`PRICE_VAT_INCLUDE_$$input`] / 1.1)
            : v[`PRICE_VAT_INCLUDE_$$input`];
        }
        return v;
      });
      setInputVatPrices([...list]);
    }
  };

  const onChangePrice = (surgeries) => {
    if ((inputPrices || []).find((v) => v.id === surgeries.id) === undefined) {
      inputPrices.push(surgeries);
      let list = [...inputPrices];
      list.push(surgeries);
      setInputPrices([...list]);
    } else {
      let list = inputPrices.map((v) => {
        if (v.id === surgeries.id) {
          v[`NOT_VAT_INCLUDE_$$input`] = surgeries[`NOT_VAT_INCLUDE_$$input`];
          v[`PRICE_VAT_INCLUDE_$$input`] = v['vatIncluded']
            ? Math.round(v[`NOT_VAT_INCLUDE_$$input`] * 1.1)
            : v[`NOT_VAT_INCLUDE_$$input`];
        }
        return v;
      });
      setInputPrices([...list]);
    }
  };

  const onProductAction = (obj) => {
    const model = obj.key;
    //const event = obj.event;
    const product = obj.row;

    if (model.title === 'PRICE_VAT_INCLUDE') {
      //가격 (VAT포함) 횟수 인풋박스 변경
      //inputVatPrices 리스트에 현재 변경된 값의 아이디가 있으면,
      //$$input 변경값이 있으므로, 덮어씌우기
      //리스트에 없는 값이면 리스트에 추가
      onChangePriceVat(product);
    }

    if (model.title === 'NOT_VAT_INCLUDE') {
      //가격 (VAT포함) 횟수 인풋박스 변경
      //inputVatPrices 리스트에 현재 변경된 값의 아이디가 있으면,
      //$$input 변경값이 있으므로, 덮어씌우기
      //리스트에 없는 값이면 리스트에 추가
      onChangePrice(product);
    }

    if (model.title === 'QUANTITY') {
      //시/수술 횟수 인풋박스 변경
      //inputTreatmentCount 리스트에 현재 변경된 값의 아이디가 있으면,
      //$$input 변경값이 있으므로, 덮어씌우기
      //리스트에 없는 값이면 리스트에 추가
      if (
        (inputQuantity || []).find((v) => v.id === product.id) === undefined
      ) {
        inputQuantity.push(product);
        let list = [...inputQuantity];
        list.push(product);
        setInputQuantity([...list]);
      } else {
        let list = inputQuantity.map((v) => {
          if (v.id === product.id) {
            v[`QUANTITY_$$input`] = product[`QUANTITY_$$input`];
          }
          return v;
        });
        setInputQuantity([...list]);
      }

      let checked = [...checkedProducts];
      if ((checked || []).find((v) => v.id === product.id) === undefined) {
        checked.push(product);
        setCheckedProducts([...checked]);
      } else {
        let list = checked.filter((v) => {
          return v.id !== product.id;
        });
        setCheckedProducts([...list]);
      }
    }

    // if (['quantity'].indexOf(model.column) !== -1) {
    //   if(event.target.value > 0) {
    //     //입력값이 0 이상인 경우에만 구매에 포함
    //     //기존 배열에 id가 있는 경우 구매수량 변경,
    //     //기존 배열에 id가 없는 경우 배열에 추가

    //     const checkList = checkedProducts.filter( v => {
    //       return v.id === product.id
    //     })
    //     if(checkList.length) {
    //       const list = checkedProducts.map( v => {
    //         v.quantity = Number(event.target.value)
    //         return v
    //       })
    //       setCheckedProducts([...list])
    //     } else {
    //       const obj = {...product, quantity: Number(event.target.value)}
    //       setCheckedProducts([...checkedProducts, obj])
    //     }

    //   } else {
    //     //0보다 작거나 같은 경우 배열에서 제외
    //     let list = checkedProducts.filter( v => {
    //       return (v.id !== product.id)
    //     })
    //     setCheckedProducts([...list])
    //   }
    // }
  };

  const onClickProductSearch = () => {
    productParams.queryParams = {
      limit: productParams.queryParams.limit,
      visible: true,
      ...productSearchValue,
    };
    onProductParams(productParams);
  };

  const onClickSave = useCallback(async () => {
    const productItems = checkedProducts.map((v) => {
      if (v.quantity > 1) {
        // let price = v.price*v.quantity
        // v.price = price
        let priceVatInclude =
          parseInt(v['PRICE_VAT_INCLUDE_$$input']) *
          parseInt(v['QUANTITY_$$input']);
        v.priceVatInclude = priceVatInclude;
        v.requestAmount = priceVatInclude;
        v.totalAmount = priceVatInclude;
      } else {
        v.requestAmount = parseInt(v['PRICE_VAT_INCLUDE_$$input']);
        v.totalAmount = parseInt(v['PRICE_VAT_INCLUDE_$$input']);
      }
      return v;
    });

    close(productItems);
  }, [close, checkedProducts]);

  return (
    <div className="modal-payment-items items-group">
      <div className="head flex-row flex-between items-start">
        <div className="title">제품 추가</div>
        <i onClick={() => close()} className="zmdi zmdi-close" />
      </div>
      <div className="body">
        <div className="search-bar">
          <div className="search-item m-r-8">
            <Input
              placeholder="제품명을 검색하세요"
              name="name"
              onKeyPress={(e) =>
                e.key === 'Enter' ? onClickProductSearch() : null
              }
              onChange={(e) =>
                setProductSearchValue({
                  ...productSearchValue,
                  [e.target.name]: e.target.value,
                })
              }
            />
          </div>
          <div className="search-btns">
            <button
              className="btn flex-wrap _small m-r-8 btn-primary"
              onClick={onClickProductSearch}
            >
              검색
            </button>
          </div>
        </div>
        <DataTableAlpha
          model={models.crm.paymentProductItems}
          data={productData}
          total={productTotal}
          params={productParams}
          onParams={onProductParams}
          onAction={onProductAction}
          hideAllCheck={true}
        />
      </div>

      <div className="buttons">
        <div className="flex-row">
          <button
            onClick={() => {
              close(undefined);
            }}
            className="btn btn-default"
          >
            {translate('CANCEL')}
          </button>
          <button onClick={onClickSave} className="btn btn-primary">
            등록
          </button>
        </div>
      </div>
    </div>
  );
};

ModalAddPaymentProducts.propTypes = {
  close: PropTypes.func,
};

export default observer(ModalAddPaymentProducts);
