import { currency } from 'filters';

export default {
  keys: [
    {
      title: '카테고리',
      column: 'category__name',
    },
    {
      title: '시/수술명(제품명)',
      column: 'name',
    },
    {
      title: '개수',
      column: 'quantity',
      filter: (o) => {
        return o.quantity === undefined ? '-' : o.quantity;
      },
    },
    {
      title: '가격',
      column: 'price',
      filter: (o) => {
        return `₩${currency(o.price)}`;
      },
    },
    {
      title: 'VAT포함가격',
      column: 'priceVatInclude',
      filter: (o) => {
        //현재 시수술 코드 입력 시 priceVatInclude가 들어가지 않고 있음
        return `${
          o.vatIncluded
            ? `₩${currency(Math.round(o.price * 1.1))}`
            : `₩${currency(o.price)}`
        }`;
        // return `₩${currency(o.priceVatInclude)}`
      },
    },
    {
      title: '과세여부',
      column: 'vatIncluded',
      filter: (o) => (o.vatIncluded ? '과세' : '비과세'),
    },
  ],
};
