import * as $http from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import hooks from 'hooks';
import CtiDatePickerInput from './CtiDatePickerInput';
import CtiRecallDatePicker from './CtiRecallDatePicker';
import CtiGuideTip from './CtiGuideTip';
import CtiMemoList from './CtiMemoList';
import classNames from 'classnames';
import CtiPopupCloseModal from './CtiPopupCloseModal';
import { CtiCheckbox } from './CtiCheckbox';
import { CtiFormSelect } from './CtiFormSelect';
import { CtiFormTextField } from './CtiFormTextField';
import { CounselingResult } from './counselingResult';
import { appendZero } from './dateUtil';
import useCtiStore from './hooks/useCtiStore';
import { useModal } from 'hooks/useModal';
import { useServices } from 'hooks/useServices';
import { useToast } from 'hooks/useToast';
import ModalCustomerFinder from 'components/modals/ModalCustomerFinder';
import { RadioInput } from 'components/common/RadioInput';
import './ctiCallPopup.scss';

const CtiCallPopup = observer(function CtiCallPopup() {
  const history = useHistory();
  const modal = useModal();
  const services = useServices();
  const toast = useToast();
  const ctiStore = useCtiStore();

  const {
    call,
    caller,
    recallDate,
    recallTime,
    recall,
    result,
    memo,
    sex,
    sms,
    promotion,
    name,
    inflowSource,
    referral,
    referralName,
    modified,
    callerError,
    recallError,
    resultError,
    nameError,
  } = ctiStore.currentCall;

  const [minimize, setMinimize] = useState(false);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [showMemo, setShowMemo] = useState(false);
  const [saveResult, setSaveResult] = useState(null);

  const [relPosition, setRelPosition] = useState({ x: null, y: null });
  const [position, setPosition] = useState({ x: null, y: null });
  const mountedRef = useRef(false);
  const [callers, setCallers] = useState([]);
  const [inflowSources, setInflowSources] = useState([]);

  const saveResultTimer = useRef(null);
  const popupWindow = useRef(null);

  useEffect(() => {
    if (mountedRef.current) {
      ctiStore.updateCurrentCall({
        modified: true,
      });
    }

    mountedRef.current = true;
  }, [caller, recallDate, recallTime, recall, result, sex, promotion, name, inflowSource, referral]);

  async function loadCallers() {
    const res = await $http.get('/users?limit=300');

    setCallers(
      res.data.map((d) => ({
        id: d.id,
        name: d.name,
      }))
    );
  }

  useEffect(() => {
    loadCallers();
    loadInflowSources();
  }, []);

  async function loadInflowSources() {
    const res = await services.crm.customer.acquisitionChannel.all({
      visible: true,
      limit: 300,
    });
    setInflowSources(
      res.data.map(({ id, name }) => ({
        id,
        name,
      }))
    );
  }

  const recallTimes = [];
  for (let i = 9; i < 18; i++) {
    recallTimes.push(i);
  }

  function recallTimeText(t) {
    if (t < 12) {
      return `오전 ${t}시-${t + 1}시`;
    }

    return `오후 ${t - 12}시-${t - 11}시`;
  }

  /**
   *
   * @param {Date} d
   */
  function formatCallTime(d) {
    return `${d.getFullYear()}${appendZero(d.getMonth())}${appendZero(d.getDate())}_${appendZero(d.getHours())}${appendZero(d.getMinutes())}`;
  }

  function toggleMinimize() {
    setMinimize(!minimize);
  }

  function onClickDashboard() {
    hooks.openCustomerChart({
      customer: {
        id: call.customerData.id,
      },
    });
  }

  function toggleDatePicker() {
    if (showDatePicker) {
      setShowDatePicker(!showDatePicker);
    } else {
      if (recall) {
        setShowDatePicker(!showDatePicker);
      }
    }
  }

  function onClickSearchReferral() {
    modal
      .custom({
        component: ModalCustomerFinder,
        options: { customerData: {} },
      })
      .then((customer) => {
        if (!customer) return;
        ctiStore.updateCurrentCall({
          referral: customer.id,
          referralName: customer.name,
        });
      });
  }

  function onClickHistory() {
    hooks.openCustomerChart({
      customer: {
        id: call.customerData.id,
      },
      selectTab: 'CALLHISTORYCHART',
    });
  }

  function onClickCalendar() {
    history.push(`/crm/appointment-boards`);
  }

  function onClickReservation() {
    hooks.openCustomerChart({
      customer: {
        id: call.customerData.id,
      },
      selectTab: 'APPOINTMENT',
    });
  }

  function onClickMemo() {
    setShowMemo(true);
  }

  function onClickCancel() {
    if (modified) {
      modal.custom({
        component: CtiPopupCloseModal,
        options: {
          call: call,
          title: '화면을 닫으시겠습니까?',
          message: '작성 중이시던 내용은 저장되지 않습니다.',
          onConfirm: () => ctiStore.cancelCall(),
        },
      });
    } else {
      ctiStore.cancelCall();
    }
  }

  async function onClickSave() {
    if (!ctiStore.validateCurrentCall()) {
      showSaveResult({
        success: false,
        message: '필수 값을 입력해주세요.',
      });

      return;
    }

    try {
      if (ctiStore.currentCall.call.isCalling) {
        await ctiStore.endCall();
      } else {
        await ctiStore.saveCall();
      }

      toast.success('통화 응대가 저장 및 종료되었습니다.');
    } catch (e) {
      toast.error('통화 저장 및 종료 중 에러가 발생했습니다.');
    }
  }

  async function onClickComplete() {
    if (!ctiStore.validateCurrentCall()) {
      showSaveResult({
        success: false,
        message: '필수 값을 입력해주세요.',
      });

      return;
    }

    await ctiStore.endCall();
    toast.success('통화 응대가 저장 및 종료되었습니다.');
  }

  function clearSaveResultTimeout() {
    if (saveResultTimer.current) {
      clearTimeout(saveResultTimer.current);
      saveResultTimer.current = null;
    }
  }

  function showSaveResult(result) {
    clearSaveResultTimeout();

    setSaveResult(result);
    saveResultTimer.current = setTimeout(() => {
      clearSaveResultTimeout();
      setSaveResult(null);
    }, 1000);
  }

  useEffect(() => {
    return () => {
      clearSaveResultTimeout();
    };
  }, []);

  function onDragStart(e) {
    var img = new Image();
    img.src =
      'data:image/gif;base64,R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=';
    e.dataTransfer.setDragImage(img, 0, 0);
    let rect = popupWindow.current.getBoundingClientRect();
    setRelPosition({
      x: e.nativeEvent.pageX - rect.left,
      y: e.nativeEvent.pageY - rect.top,
    });
  }

  function onDrag(e) {
    if (!e.nativeEvent.pageX && !e.nativeEvent.pageY) {
      return;
    }

    setPosition({
      x: e.nativeEvent.pageX - relPosition.x,
      y: e.nativeEvent.pageY - relPosition.y,
    });
  }

  return (
    <div
      className={classNames(
        'cti-call-popup',
        ctiStore.settings.callPopupLocation,
        ctiStore.settings.historyLocation,
        {
          statusBar: ctiStore.showStatusBar,
          history: ctiStore.showHistory,
        }
      )}
      ref={popupWindow}
      style={{
        top: position.y != null ? position.y : undefined,
        left: position.x != null ? position.x : undefined,
        bottom: position.y != null ? 'auto' : undefined,
        right: position.x != null ? 'auto' : undefined,
      }}
    >
      <div className="call-popup-card">
        <div
          className="cti-handle"
          draggable={true}
          onDragStart={onDragStart}
          onDrag={onDrag}
        >
          <button
            className="cti-minimize-btn"
            onClick={toggleMinimize}
          ></button>
          <button className="cti-close-btn" onClick={onClickCancel}></button>
        </div>
        <div className="cti-header">
          <div className="cti-description">
            {call.customerData && (
              <>
                <div className="cti-description-sub">
                  {call.customerData.level} |{' '}
                  {call.customerData.counselorName ?? '미지정'} |{' '}
                  {formatCallTime(call.createdAt)}
                </div>
                <div className="cti-description-main">
                  <span>{call.customerData.name}</span>
                  <button
                    className="cti-dashboard-btn"
                    onClick={onClickDashboard}
                  ></button>
                </div>
              </>
            )}
            {!call.customerData && (
              <>
                <div className="cti-description-sub">신규 회원입니다 :)</div>
                <div className="cti-description-main">
                  <span>{call.phoneNumber}</span>
                </div>
              </>
            )}
          </div>
        </div>
        {!minimize && (
          <div className="cti-recall-form">
            <div className="cti-form-group">
              <div className="cti-form-control">
                <div className="cti-form-label required">통화자</div>
                <CtiFormSelect
                  className="counselor-select"
                  value={caller}
                  onChange={(e) =>
                    ctiStore.updateCurrentCall({
                      caller: Number(e.target.value),
                    })
                  }
                  error={callerError}
                >
                  <option value="">-선택-</option>
                  {callers.map((c) => (
                    <option key={c.id} value={c.id}>
                      {c.name}
                    </option>
                  ))}
                </CtiFormSelect>
              </div>

              <div className="cti-form-control">
                <div className="cti-form-label">
                  리콜
                  <CtiCheckbox
                    className="recall-checkbox"
                    id="recall"
                    name="recall"
                    checked={recall}
                    onChange={(e) =>
                      ctiStore.updateCurrentCall({ recall: e.target.checked })
                    }
                  />
                </div>
                <div className="cti-inline-form">
                  <div className="recall-date-field">
                    <CtiDatePickerInput
                      error={recall && recallError}
                      disabled={!recall}
                      date={recallDate}
                      onChange={(v) =>
                        ctiStore.updateCurrentCall({ recallDate: v })
                      }
                      onClickDatePicker={toggleDatePicker}
                    />
                  </div>
                  <select
                    disabled={!recall}
                    value={recallTime}
                    onChange={(e) =>
                      ctiStore.updateCurrentCall({
                        recallTime: Number(e.target.value),
                      })
                    }
                  >
                    <option value={0}>-선택-</option>
                    {recallTimes.map((t, i) => (
                      <option key={t} value={i + 1}>
                        {recallTimeText(t)}
                      </option>
                    ))}
                    <option value={recallTimes.length + 1}>오전</option>
                    <option value={recallTimes.length + 2}>오후</option>
                    <option value={recallTimes.length + 3}>상시</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="cti-form-group">
              <div className="cti-form-control full-width">
                <div className="cti-form-label required">통화결과</div>
                <CtiFormSelect
                  value={result}
                  onChange={(e) =>
                    ctiStore.updateCurrentCall({
                      result: Number(e.target.value),
                    })
                  }
                  error={resultError}
                >
                  {[
                    {
                      value: CounselingResult.none,
                      label: '-선택-',
                    },
                    {
                      value: CounselingResult.counseling_by_kakaotalk,
                      label: CounselingResult.toName(
                        CounselingResult.counseling_by_kakaotalk
                      ),
                    },
                    {
                      value: CounselingResult.recall_need,
                      label: CounselingResult.toName(
                        CounselingResult.recall_need
                      ),
                    },
                    {
                      value: CounselingResult.recall_first_absence,
                      label: CounselingResult.toName(
                        CounselingResult.recall_first_absence
                      ),
                    },
                    {
                      value: CounselingResult.recall_second_absence,
                      label: CounselingResult.toName(
                        CounselingResult.recall_second_absence
                      ),
                    },
                    {
                      value: CounselingResult.response_complete_appointment,
                      label: CounselingResult.toName(
                        CounselingResult.response_complete_appointment
                      ),
                    },
                    {
                      value: CounselingResult.response_complete_recall,
                      label: CounselingResult.toName(
                        CounselingResult.response_complete_recall
                      ),
                    },
                    {
                      value: CounselingResult.response_complete_inquiry,
                      label: CounselingResult.toName(
                        CounselingResult.response_complete_inquiry
                      ),
                    },
                    {
                      value: CounselingResult.response_complete_reject,
                      label: CounselingResult.toName(
                        CounselingResult.response_complete_reject
                      ),
                    },
                  ].map((o) => (
                    <option key={o.value} value={o.value}>
                      {o.label}
                    </option>
                  ))}
                </CtiFormSelect>
              </div>
            </div>
            {!call.customerData && (
              <div className="cti-form-group">
                <div className="cti-form-control half">
                  <div className="cti-form-label required">고객명</div>
                  <CtiFormTextField
                    type="text"
                    placeholder="이름"
                    value={name}
                    onChange={(e) =>
                      ctiStore.updateCurrentCall({ name: e.target.value })
                    }
                    error={nameError}
                  />
                </div>
                <div className="cti-form-control half">
                  <div className="cti-form-label required">성별</div>
                  <div className="radio-group">
                    {[
                      {
                        value: 'female',
                        label: '여성',
                      },
                      {
                        value: 'male',
                        label: '남성',
                      },
                    ].map((s) => (
                      <div key={s.value} className="radio-item">
                        <RadioInput
                          id={`sex-${s.value}`}
                          name="sex"
                          value={s.value}
                          checked={sex === s.value}
                          onChange={(e) =>
                            ctiStore.updateCurrentCall({ sex: e.target.value })
                          }
                        />
                        <label htmlFor={`sex-${s.value}`}>{s.label}</label>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
            <div className="cti-form-group">
              <div className="cti-form-control full-width">
                <div className="cti-form-label">메모</div>
                <textarea
                  placeholder="상담내용을 입력해주세요"
                  value={memo}
                  onChange={(e) =>
                    ctiStore.updateCurrentCall({ memo: e.target.value })
                  }
                />
              </div>
            </div>
            {!call.customerData && (
              <>
                <div className="cti-form-group">
                  <div className="cti-form-control half">
                    <div className="cti-form-label">내원경로</div>
                    <select
                      className="full"
                      value={inflowSource}
                      onChange={(e) =>
                        ctiStore.updateCurrentCall({
                          inflowSource: Number(e.target.value),
                        })
                      }
                    >
                      <option value={0}>- 선택 -</option>
                      {inflowSources.map((v) => (
                        <option key={v.id} value={v.id}>
                          {v.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="cti-form-control half">
                    <div className="cti-form-label">소개자</div>
                    <div className="cti-referral-input">
                      <input
                        className="referral-input-field"
                        type="text"
                        value={referralName}
                        placeholder="소개자"
                        readOnly={true}
                      ></input>
                      <button
                        className="referral-input-btn"
                        onClick={onClickSearchReferral}
                      ></button>
                    </div>
                  </div>
                </div>
                <div className="cti-form-group">
                  <div className="cti-form-control half">
                    <div className="cti-form-label required">문자 수신</div>
                    <div className="radio-group">
                      {[
                        {
                          value: true,
                          label: '동의',
                        },
                        {
                          value: false,
                          label: '미동의',
                        },
                      ].map((item) => (
                        <div key={item.value} className="radio-item">
                          <RadioInput
                            id={`sms-${item.value}`}
                            name="sms"
                            value={item.value.toString()}
                            checked={sms === item.value}
                            onChange={(e) => {
                              ctiStore.updateCurrentCall({
                                sms: e.target.value === 'true',
                              });
                            }}
                          />
                          <label htmlFor={`sms-${item.value}`}>
                            {item.label}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="cti-form-control half">
                    <div className="cti-form-label required">마케팅 수신</div>
                    <div className="radio-group">
                      {[
                        {
                          value: true,
                          label: '동의',
                        },
                        {
                          value: false,
                          label: '미동의',
                        },
                      ].map((item) => (
                        <div key={item.value} className="radio-item">
                          <RadioInput
                            id={`promotion-${item.value}`}
                            name="promotion"
                            value={item.value.toString()}
                            checked={promotion === item.value}
                            onChange={(e) => {
                              ctiStore.updateCurrentCall({
                                promotion: e.target.value === 'true',
                              });
                            }}
                          />
                          <label htmlFor={`promotion-${item.value}`}>
                            {item.label}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="goto-calendar-box">
                  <CtiGuideTip
                    id="goto-calendar"
                    text="고객등록 필수 항목을 입력하시고 예약해주세요!"
                    show={true}
                  >
                    <button
                      className="goto-calendar-btn"
                      onClick={onClickCalendar}
                    >
                      예약 캘린더 바로가기
                    </button>
                  </CtiGuideTip>
                </div>
              </>
            )}
            {call.customerData && (
              <div className="menu-box">
                <button className="menu-item" onClick={onClickHistory}>
                  <div className={classNames('menu-icon', 'history')}></div>
                  <div className="menu-text">통화내역</div>
                </button>
                <button className="menu-item" onClick={onClickReservation}>
                  <div className={classNames('menu-icon', 'reservation')}></div>
                  <div className="menu-text">예약내역</div>
                </button>
                <button className="menu-item" onClick={onClickCalendar}>
                  <div className={classNames('menu-icon', 'calendar')}></div>
                  <div className="menu-text">예약캘린더</div>
                </button>
                <button className="menu-item" onClick={onClickMemo}>
                  <div className={classNames('menu-icon', 'memo')}></div>
                  <div className="menu-text">모든메모</div>
                </button>
              </div>
            )}
            <div className="action-box">
              <button className="cancel-button" onClick={onClickCancel}>
                <span>취소</span>
              </button>
              {call.isCalling && (
                <button className="complete-button" onClick={onClickComplete}>
                  저장 및 종료
                </button>
              )}
              {!call.isCalling && (
                <button className="save-button" onClick={onClickSave}>
                  수정 완료
                </button>
              )}
            </div>
          </div>
        )}
      </div>
      <CtiRecallDatePicker
        open={showDatePicker}
        onClose={() => setShowDatePicker(false)}
        date={recallDate ? new Date(recallDate) : new Date()}
        onChange={(v) => ctiStore.updateCurrentCall({ recallDate: v })}
      />
      {call.customerData && (
        <CtiMemoList
          customerId={call.customerData.id}
          open={showMemo}
          onClose={() => setShowMemo(false)}
        />
      )}
      {saveResult && (
        <div
          className={classNames('save-result-flash', {
            success: saveResult.success,
            error: !saveResult.success,
          })}
        >
          {saveResult.message}
        </div>
      )}
    </div>
  );
});

export default CtiCallPopup;
