import React, { useState, useEffect, useCallback } from 'react';
import { observer } from 'mobx-react';
import DataTableAlpha from 'components/data-table/DataTableAlpha';
import PropTypes from 'prop-types';
import models from 'models';
import { $qb } from 'scripts/querybuilder';
import { useModal } from 'hooks/useModal';
import { useServices } from 'hooks/useServices';

const ModalDepartmentCategory = ({ close }) => {
  const services = useServices();
  const modal = useModal();
  const [params] = useState($qb());
  const [data, setData] = useState(null);

  const onDeleteAuthorityFavorite = async (user) => {
    try {
      const { id, email, authorityGroup } = user.user;
      let editUser = {
        id,
        email,
        authorityGroup: { ...authorityGroup, isFavorite: false },
      };
      await services.crm.user.update(editUser);
      modal
        .confirm({
          type: 'SUCCESS',
          msg: '삭제되었습니다.',
        })
        .then(() => {
          close();
        });
    } catch (e) {
      modal.confirm({
        type: 'ERROR',
        msg: e.description,
      });
    }
  };

  const onAction = (obj) => {
    const event = obj.event;
    const user = obj.row;
    const classList = event.target.classList;

    if (classList.contains('btn-select')) {
      close(user);
    }
    if (classList.contains('btn-delete')) {
      onDeleteAuthorityFavorite(user);
    }
  };

  const dataSetting = (data) => {
    let filterData = data.filter((v) => {
      if (v.user.authorityGroup !== null) {
        return v.user.authorityGroup.isFavorite;
      }
    });
    setData(filterData);
  };

  const callApi = useCallback(async () => {
    params.queryParams = { ...params.queryParams, type: 'staff', limit: 300 };
    const resp = await services.crm.organizations.organ.all(params.build());
    if (!resp) return;
    dataSetting(resp.data);
  }, [services.crm.organizations.organ, params]);

  useEffect(() => {
    callApi();
  }, [callApi]);

  return (
    <div className="modal-frequently-authority">
      <div className="head flex-row flex-between items-start">
        <div className="title">자주 쓰는 권한</div>
      </div>
      <div className="body">
        <DataTableAlpha
          model={models.crm.frequentlyAuthority}
          data={data}
          hideBottom={true}
          onAction={onAction}
        />
      </div>
      <div className="buttons">
        <div className="flex-row">
          <button onClick={() => close(0)} className="btn btn-primary">
            확인
          </button>
        </div>
      </div>
    </div>
  );
};

ModalDepartmentCategory.propTypes = {
  close: PropTypes.func,
};

export default observer(ModalDepartmentCategory);
