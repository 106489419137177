import React, { useState, useRef } from 'react';
import { observer } from 'mobx-react';
import { translate } from 'filters';
import ModalCustomerFinder from 'components/modals/ModalCustomerFinder';
import ModalCustomerWorkIn from 'components/modals/ModalCustomerWorkIn';
import ModalAddChartInfo from 'components/modals/ModalAddChartInfo';
import hooks from 'hooks';
import moment from 'moment';
import { useModal } from 'hooks/useModal';
import { useServices } from 'hooks/useServices';
import { useToast } from 'hooks/useToast';
import styled from 'styled-components';
import searchIcon from 'assets/images/icon/ic-search.png';

const Input = styled.input`
  width: 340px;
  height: 46px;
  padding-left: 50px !important;
  padding-right: 20px !important;
  border: 0;
  background-color: #f3f8ff;
  background-image: url(${searchIcon});
  background-size: 19px;
  background-repeat: no-repeat;
  background-position: left 18px center;
  font-size: 12px !important;

  &::placeholder {
    font-size: 12px;
  }
`;

const CustomerSearchBar = () => {
  const modal = useModal();
  const services = useServices();
  const toast = useToast();

  const searchInput = useRef();
  const [searchValue, setSearchValue] = useState('');

  const onClickSearch = () => {
    searchInput.current.blur();
    if (!searchValue) return toast.error('ERROR_NO_SEARCH_VALUE');

    modal
      .custom({
        component: ModalCustomerFinder,
        options: {
          customerData: { searchQuery: searchValue },
        },
      })
      .then((customer) => {
        if (!customer) return;

        hooks.openCustomerChart({ customer, closeFlag: true });
      });
  };

  const openModalWorkIn = (customer) => {
    modal.custom({
      component: ModalCustomerWorkIn,
      options: {
        customer,
      },
    });
  };

  const checkTodayAppointments = async (customer) => {
    try {
      let param = {
        customerId: customer.id,
        startAt: moment().format('YYYY-MM-DD'),
        endAt: moment().format('YYYY-MM-DD'),
      };
      const resp = await services.crm.crud.appointment.all(param);
      if (!resp) return;

      if (resp.total > 0) {
        modal
          .basic({
            body: '이미 접수된 고객입니다. 그래도 접수하시겠습니까?',
            buttons: [
              { text: 'CANCEL', class: 'btn-default' },
              { text: 'CONFIRM', class: 'btn-primary' },
            ],
          })
          .then((idx) => {
            if (idx === 1) {
              openModalWorkIn(customer);
            }
          });
      } else {
        openModalWorkIn(customer);
      }
    } catch (e) {
      console.log(e.description);
    }
  };

  const onClickWorkIn = () => {
    modal
      .custom({
        component: ModalCustomerFinder,
        options: {
          customerData: {},
        },
      })
      .then((customer) => {
        if (!customer) return;
        checkTodayAppointments(customer);
      });
  };

  const onClickCreateCustomer = () => {
    modal.custom({
      component: ModalAddChartInfo,
      options: {
        customer: {
          type: 'domestic',
          sex: 'female',
          smsEnabled: true,
          eventSmsEnabled: true,
        },
        editInfo: false,
      },
    });
  };

  return (
    <div className="customer-search-bar flex-row items-center">
      <Input
        ref={searchInput}
        placeholder="고객명, 전화번호"
        value={searchValue}
        onChange={(e) => {
          setSearchValue(e.target.value);
        }}
        onKeyPress={(e) => (e.key === 'Enter' ? onClickSearch() : null)}
      />
      <div className="buttons flex-row flex-wrap">
        <button className="btn btn-accent" onClick={onClickSearch}>
          {translate('SEARCH')}
        </button>
        <button className="btn btn-accent" onClick={onClickCreateCustomer}>
          {translate('CREATE_CUSTOMER')}
        </button>
        <button className="btn btn-accent" onClick={onClickWorkIn}>
          당일접수
        </button>
      </div>
    </div>
  );
};

export default observer(CustomerSearchBar);
