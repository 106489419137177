import React, { useState, useEffect, useCallback } from 'react';
import { observer } from 'mobx-react';
import { Tooltip, Button } from 'antd';
import DataTableAlpha from 'components/data-table/DataTableAlpha';
import ModalMediaConnects from 'components/modals/ModalMediaConnects';
import models from 'models';
import { $qb } from 'scripts/querybuilder';
import { useModal } from 'hooks/useModal';
import { useServices } from 'hooks/useServices';
import { useToast } from 'hooks/useToast';

const ConsultingRequestConnect = () => {
  const modal = useModal();
  const services = useServices();
  const toast = useToast();
  const defaultParams = $qb().limit(5);
  const [params, setParams] = useState(defaultParams);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);

  const tooltipText = (
    <span>
      바비톡, 네이버, 굿닥 등을 통해 광고하고 있으면 연동하기 기능을 사용하세요!
      성공적으로 연동이 되면, 우노케어의 ‘상담문의’ 화면에서 한 눈에 확인할 수
      있습니다.
    </span>
  );

  const onAction = (obj) => {
    const event = obj.event;
    const media = obj.row;
    const classList = event.target.classList;

    if (classList.contains('btn-connects')) {
      onClickMediaConnectsDelete(media);
    }
  };

  const onParams = (p) => {
    setParams(p);
    callApi();
  };

  const callApi = useCallback(async () => {
    const resp = await services.crm.consultingRequest.connect.all(
      params.build()
    );
    if (!resp) return;

    setData(resp.data);
    setTotal(resp.total);
  }, [services.crm.consultingRequest.connect, params]);

  useEffect(() => {
    callApi();
  }, [callApi]);

  const onClickMediaConnectsDelete = useCallback(
    async (media) => {
      const onDelete = async () => {
        try {
          await services.crm.consultingRequest.connect.delete(media.id);
          modal
            .confirm({
              type: 'SUCCESS',
              msg: '연동해제하였습니다.',
            })
            .then(() => {
              callApi();
            });
        } catch (e) {
          console.log(e.description);
          modal.confirm({
            type: 'ERROR',
            msg: e.description,
          });
        }
      };

      modal
        .basic({
          body: '연동해제하시겠습니까?',
          buttons: [
            {
              text: 'CANCEL',
              class: 'btn-default',
            },
            {
              text: 'CONFIRM',
              class: 'btn-primary',
            },
          ],
        })
        .then((selectedIdx) => {
          if (selectedIdx === 1) {
            onDelete();
          }
        });
    },
    [modal, toast, services.crm.consultingRequest.connect, callApi]
  );

  const onClickConnects = useCallback(async () => {
    modal
      .custom({
        component: ModalMediaConnects,
        options: {
          // categories: data
        },
      })
      .then(() => {
        setTimeout(function () {
          //팝업 로딩 후 0.5초 뒤에 api 호출 (복제지연시간을 고려한 호출)
          callApi();
        }, 500);
      });
  }, [modal, callApi]);

  return (
    <div className="consulting-request-media-connects">
      <div className="consulting-connect">
        <div className="connect-header">
          <div className="title">
            <span className="m-r-8">상담문의 자동연동</span>
            <Tooltip placement="top" title={tooltipText}>
              <Button>?</Button>
            </Tooltip>
          </div>
          <div className="flex-row items-center m-t-8">
            <button
              className="flex-wrap btn btn-sm btn-add"
              onClick={onClickConnects}
            >
              연동하기
            </button>
          </div>
        </div>
        <div className="consulting-request-connect m-t-16">
          <DataTableAlpha
            model={models.crm.consultingRequestsMediaConnects}
            data={data}
            total={total}
            params={params}
            onParams={onParams}
            onAction={onAction}
            hideLimitSetter={true}
            bottomPositionInner
          />
        </div>
      </div>
    </div>
  );
};

export default observer(ConsultingRequestConnect);
