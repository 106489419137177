import React, { useState, useEffect, useCallback } from 'react';
import { observer } from 'mobx-react';
import OrganizationsAuthoritySettings from 'components/pages/crm/organizations/OrganizationsAuthoritySettings';
import ModalFrequentlyAuthority from 'components/modals/ModalFrequentlyAuthority';
import PropTypes from 'prop-types';
import { useEvent } from 'hooks/useEvent';
import { useModal } from 'hooks/useModal';
import { useServices } from 'hooks/useServices';
import { useAuth } from 'store/auth';

const ModalOrganizationsAuthoritySettings = ({ options, close }) => {
  const eventBus = useEvent();
  const modal = useModal();
  const services = useServices();
  const auth = useAuth();
  const [authorityObj, setAuthorityObj] = useState({});
  const [isFavorite, setIsFavorite] = useState({});
  const [dataAccessAuthorityList, setDataAccessAuthorityList] = useState([]);
  const [defaultUserAuthorityObj, setDefaultUserAuthorityObj] = useState(
    options.user
  );
  const [selectDepartmentId, setSelectDepartmentId] = useState(
    options.user.parentId
  );

  //0708. 계정 생성시 접근권한 병원 전체로 세팅이 필요하여 디폴트값 생성
  const [organizationId, setOrganizationId] = useState();

  const getOrganizationCallApi = useCallback(async () => {
    try {
      const resp = await services.crm.organizations.organ.all();
      //최상단 부서
      setOrganizationId(resp.data[0].id);
    } catch (e) {
      console.log(e.description);
    }
  }, [services.crm.organizations.organ]);

  useEffect(() => {
    //0709 부서아이디 찾기
    //접속한 계정의 userId를 파라미터로 organizationId를 찾는다
    getOrganizationCallApi();
  }, [getOrganizationCallApi]);

  const onChangeDataAccessAuthorityObj = (obj) => {
    setDataAccessAuthorityList(obj);
  };

  const onChangeAuthorityObj = (obj) => {
    setAuthorityObj(obj);
  };

  const onChangeFavoriteCheck = (obj) => {
    setIsFavorite(obj);
  };

  const dataSetting = () => {
    const { id, email } = options.user.user;
    let user = {
      id,
      email,
    };

    let obj = {};
    authorityObj.map((v) => {
      v.items.length > 0 &&
        v.items.map((item) => {
          let key;
          Object.keys(item.value).map((keys) => {
            if (item.value[keys]) {
              key = keys;
            }
          });
          obj[item.key] = key;
        });
    });

    const authorityGroup = {
      ...isFavorite,
      ...obj,
    };

    user = { ...user, authorityGroup: authorityGroup };
    return user;
  };

  const onSaveDataAccessAuthority = async () => {
    try {
      /*const payload = dataAccessAuthorityList.map((v) => {
        return { ...v, userId: id };
      });*/

      //0709 임시 병원전체로 세팅하여 보내기
      const dataAccessAuthorityDefault = [
        {
          tableName: 'appointments',
          authorityName: 'appointment_board',
          type: 'department',
          organizationId,
        },
        {
          tableName: 'appointments',
          authorityName: 'appointment_calendar',
          type: 'department',
          organizationId,
        },
        {
          tableName: 'customers',
          authorityName: 'customer',
          type: 'department',
          organizationId,
        },
        {
          tableName: 'appointments',
          authorityName: 'appointment',
          type: 'department',
          organizationId,
        },
        {
          tableName: 'consultings',
          authorityName: 'consulting',
          type: 'department',
          organizationId,
        },
        {
          tableName: 'treatments',
          authorityName: 'treatment',
          type: 'department',
          organizationId,
        },
        {
          tableName: 'payments',
          authorityName: 'payment',
          type: 'department',
          organizationId,
        },
        {
          tableName: 'files',
          authorityName: 'penchart',
          type: 'department',
          organizationId,
        },
      ];

      const resp = await services.crm.user.data_access_authorities.create({
        data: dataAccessAuthorityDefault,
      });
      // const resp = await services.crm.user.data_access_authorities.create({data: payload})

      if (!resp) return;

      modal
        .confirm({
          type: 'SUCCESS',
          msg: '업데이트되었습니다.',
        })
        .then(() => {
          eventBus.emit('callApi');
          auth.loadMe();
          close();
        });
    } catch (e) {
      modal.confirm({
        type: 'ERROR',
        msg: e.description,
      });
    }
  };

  const onSaveAuthority = async () => {
    try {
      const payload = dataSetting();
      await services.crm.user.update(payload);
      onSaveDataAccessAuthority(payload.id, dataAccessAuthorityList);
      // toast.success('저장하였습니다.')
    } catch (e) {
      modal.confirm({
        type: 'ERROR',
        msg: e.description,
      });
    }
  };

  const onClickSave = () => {
    onSaveAuthority();
  };

  const onClickFrequentlyUsedAuthority = () => {
    modal
      .custom({
        component: ModalFrequentlyAuthority,
        options: {},
      })
      .then((user) => {
        if (!user) return;

        let obj = {
          userId: user.userId,
          user: { authorityGroup: user.user.authorityGroup },
        };
        setDefaultUserAuthorityObj(obj);
        setSelectDepartmentId(user.parentId);
      });
  };

  return (
    <div className="list organizations">
      <div className="head flex-row flex-between items-start">
        <div className="title">권한 수정</div>
        <i className="zmdi zmdi-close" onClick={() => close()}></i>
      </div>

      <div className="user-contents">
        <div className="card-title m-b-16">
          <p>
            <button onClick={onClickFrequentlyUsedAuthority}>
              자주쓰는 권한 불러오기
            </button>
          </p>
        </div>
        <OrganizationsAuthoritySettings
          setAuthorityObj={onChangeAuthorityObj}
          setIsFavorite={onChangeFavoriteCheck}
          setDataAccessAuthorityObj={onChangeDataAccessAuthorityObj}
          user={defaultUserAuthorityObj}
          departmentId={selectDepartmentId}
          initStyle
        />
      </div>
      <div className="flex-row">
        <div className="_right">
          <button
            className="btn btn-cancel _small m-r-8"
            onClick={() => {
              close();
            }}
          >
            입력취소
          </button>
          <button className="btn btn-basic _small" onClick={onClickSave}>
            저장
          </button>
        </div>
      </div>
    </div>
  );
};

ModalOrganizationsAuthoritySettings.propTypes = {
  options: PropTypes.object,
  close: PropTypes.func,
};

export default observer(ModalOrganizationsAuthoritySettings);
