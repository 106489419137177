// @flow

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
// import 'assets/styles/index.scss'
import 'assets/styles/output/index.scss';
import * as Sentry from '@sentry/react';
import { Chart } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import * as moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  release: process.env.REACT_APP_SENTRY_RELEASE,
  environment: process.env.REACT_APP_SENTRY_ENV,
  ignoreErrors: ['ResizeObserver loop limit exceeded'],
});

Chart.register(ChartDataLabels);

momentDurationFormatSetup(moment);

document
  .getElementById('ogUrl')
  .setAttribute('content', process.env.REACT_APP_URL ?? '');

ReactDOM.render(<App />, document.getElementById('app'));
serviceWorker.unregister();
