import React, { useContext, useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { AppointmentBoardStateContext } from './AppointmentBoards';
import Leftmost from './Leftmost';
import CategoryRow from './CategoryRow';
import DepartmentRow from './DepartmentRow';
import DepartmentsList from './DepartmentsList';
import TimeIndicatorLine from './TimeIndicatorLine';
import { useElementSize } from 'hooks/useElementSize';

const DailyBoard = observer(() => {
  /**
   * @type {AppointmentBoardState }
   */
  const state = useContext(AppointmentBoardStateContext);

  const [pivotEl, setPivotEl] = useState(null);
  const [timelineEl, setTimelineEl] = useState(null);
  const wrapperRef = useRef();
  const [rect, calendarRef] = useElementSize();

  useEffect(() => {
    if (!rect) return;
    state.recalcDepartmentSizeAll();
  }, [rect, state.departmentListRef]);

  useEffect(() => {
    state.dailyBoardRef = wrapperRef;
    //상위 스크롤 이동
    if (state.scrollState) {
      //부서 인덱스
      let departmentIndex = state.dateCategoryDepartments.findIndex(
        (x) =>
          x.department.name === state.locationState.appointment.department.name
      );
      //예약시간 인덱스
      let appointmentTimeIndex = state.calendarTimes.findIndex(
        (x) => x === state.locationState.appointment.startHour
      );

      if (state.mode === 'horizontal') {
        wrapperRef.current.scrollTo(
          appointmentTimeIndex * 35,
          departmentIndex * 30
        );
      } else {
        wrapperRef.current.scrollTo(
          departmentIndex * 30,
          appointmentTimeIndex * 35
        );
      }
      state.scrollState = false;
    }
  }, [state.locationState, wrapperRef]);

  function onScroll(e) {
    if (state.departmentListRef.current) {
      state.departmentListRef.current.scrollTo(
        state.mode === 'vertical' ? e.target.scrollLeft : e.target.scrollTop
      );
    }
  }

  useEffect(() => {
    const interval = setInterval(() => {
      const date = new Date();
      state.setTime(date.getHours(), date.getMinutes());
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <>
      <div className="calendar-wrapper" ref={wrapperRef} onScroll={onScroll}>
        <div ref={calendarRef} className="calendar-padded">
          <Leftmost
            pivotEl={pivotEl}
            timelineEl={timelineEl}
            setPivotEl={setPivotEl}
            setTimelineEl={setTimelineEl}
            showIndicator={true}
          />
          <div className="calendar-board-header-panel">
            <TimeIndicatorLine pivotEl={pivotEl} timelineEl={timelineEl} />
            <CategoryRow />
            <DepartmentRow />
            <DepartmentsList />
          </div>
        </div>
      </div>
    </>
  );
});

export default DailyBoard;
