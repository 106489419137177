import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import ConsultingRequestDataArea from 'components/pages/crm/consulting-request/ConsultingRequestDataArea';
import ConsultingRequestUnResponsiveDataArea from 'components/pages/crm/consulting-request/ConsultingRequestUnResponsiveDataArea';
import ConsultingRequestResponsiveHistoryDataArea from 'components/pages/crm/consulting-request/ConsultingRequestResponsiveHistoryDataArea';
import ModalCustomerFinder from 'components/modals/ModalCustomerFinder';
import ModalCustomerChart from 'components/modals/ModalCustomerChart';
import { useAuth } from 'store/auth';
import { useEvent } from 'hooks/useEvent';
import { useModal } from 'hooks/useModal';
import { useServices } from 'hooks/useServices';
import { useToast } from 'hooks/useToast';
import styled, { css, createGlobalStyle } from 'styled-components';
import DatePicker from 'components/common/DatePicker';
import moment from 'moment';

const Input = styled.input`
  width: 100%;
  height: 34px;
  padding: 8px 20px;
  border-color: #d7e3f1;
  font-size: 12px;
  min-height: 10px;
  border-radius: 4px;
  background-color: #f3f8ff;
  color: #000;
  border: 1px solid #dee2ec;

  ${({ disabled }) =>
    disabled &&
    css`
      background-color: #efeff4 !important;
      color: #ceced7;
    `}
`;

const Select = styled.select`
  margin-bottom: 5px;
`;

const Span = styled.span`
  padding : 5px; 0px;
`;

const DIV = styled.div`
  flex: 0 0 40%;
`;

const SelectWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const WrapDatePicker = createGlobalStyle`
  .date_picker.full-width {
    width: 100%;
    padding-bottom: 5px;
  }
`;
const hours = [
  '08',
  '09',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  '19',
  '20',
  '21',
  '22',
];
const minute = ['00', '30'];

const TabConsultingRequest = ({ data, close }) => {
  const eventBus = useEvent();
  const modal = useModal();
  const services = useServices();
  const toast = useToast();
  const auth = useAuth();
  const [statusBox, setStatusBox] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [categoryId, setCategoryId] = useState();
  const [statusList, setStatusList] = useState([]);
  const [unResponsiveList, setUnResponsiveList] = useState([]);
  const [exceptUnResponsiveList, setExceptUnResponsiveList] = useState([]);
  const [consultingRequestObj, setConsultingRequestObj] = useState({});
  const [appointmentConfirm, setAppointmentConfirm] = useState(false);
  const [recallDate, setRecallDate] = useState(new Date());
  const [recallHour, setRecallHour] = useState('08');
  const [recallMinute, setRecallMinute] = useState('00');
  const [datePickerVisible, setDatePickerVisible] = useState(false);

  //상담문의 설정의 처리결과 카테고리의 예약완료 카테고리 id값을 가져오기 위함
  const [appointmentCompleteId, setAppointmentCompleteId] = useState();

  const statusCategoriesCallApi = useCallback(async () => {
    const resp = await services.crm.consultingRequest.requests_categories.all();
    if (!resp) return;

    setCategoryList(
      resp.data.filter((v) => {
        return v.visible;
      })
    );
  }, [services.crm.consultingRequest.requests_categories]);

  useEffect(() => {
    statusCategoriesCallApi();
  }, [statusCategoriesCallApi]);

  const statusCallApi = useCallback(async () => {
    const param = { visible: true };
    const resp = await services.crm.consultingRequest.requests.status(param);
    if (!resp) return;

    let appointmentId;

    setStatusList(resp.data);
    setAppointmentCompleteId(appointmentId);
  }, [services.crm.consultingRequest.requests]);

  const callApi = useCallback(async () => {
    const param = { parentId: data.id };
    const resp = await services.crm.consultingRequest.requests.all(param);
    if (!resp) return;

    let unResponsive = [],
      exceptUnResponsive = [];
    resp.data.forEach((v) => {
      const status = v.status;
      if (status === null) {
        //status = null = 미응대
        unResponsive.push(v);
      } else {
        exceptUnResponsive.push(v);
      }
    });

    setUnResponsiveList(unResponsive);
    setExceptUnResponsiveList(exceptUnResponsive);
  }, [services.crm.consultingRequest.requests, data.id]);

  useEffect(() => {
    statusCallApi();
  }, [statusCallApi]);

  const onChangeEndAt = (date) => {
    setRecallDate(date);
  };

  const appointmentComplete = useCallback(async () => {
    //예약 탭에서 예약 완료 후처리
    //처리결과 예약 완료로 변경
    const obj = statusList.find((v) => v.name === '예약완료');
    let payload = {
      memo: consultingRequestObj.memo,
      status: obj,
      statusId: obj.id,
      consultingRequestId: data.id,
    };
    await services.crm.consultingRequest.result.create(payload);
    close();
    eventBus.emit('callApi');
    eventBus.emit('countCallApi');
  }, [consultingRequestObj, statusList]);

  useEffect(() => {
    eventBus.on('appointmentComplete', () => appointmentComplete());
    return () => eventBus.off('appointmentComplete');
  }, [eventBus, appointmentComplete]);

  useEffect(() => {
    if (data.id) {
      callApi();
    }
  }, [callApi, data]);

  const validator = () => {
    if (!categoryId) {
      toast.error('처리결과를 입력해주세요.');
      return false;
    }
    return true;
  };

  const onClickSave = async () => {
    if (!validator()) return;

    //처리결과 수정/저장시 마다 응대기록 누적
    try {
      let payload = {
        ...consultingRequestObj,
        assignUserId: data.assignUserId,
        consultingRequestId: data.id,
      };

      let categoryName = categoryList.find((v) => v.id === categoryId);
      if (categoryName?.name === '리콜 필요') {
        const recall_date = moment(recallDate).format('YYYY-MM-DD');
        payload.recallAt = `${recall_date} ${recallHour}:${recallMinute}:00`;
      }

      //memo 필수값 제외, 현재는 sql 오류가 발생하여 빈 스트링으로 보냄
      if (payload.memo === undefined) {
        payload.memo = '';
      }

      const resp = await services.crm.consultingRequest.result.create(payload);
      if (!resp) return;

      modal
        .confirm({
          type: 'SUCCESS',
          msg: '저장되었습니다.',
        })
        .then(() => {
          eventBus.emit('countCallApi');
          close();
        });
    } catch (e) {
      console.log(e.description);
      modal.confirm({
        type: 'ERROR',
        msg: e.description,
      });
    }
  };

  const openCustomerChartAppointment = (customer) => {
    //closeFlag = true > 예약 완료 후 모달창 종료, appointmentComplete 호출
    modal.custom({
      component: ModalCustomerChart,
      options: {
        customer,
        selectTab: 'APPOINTMENT',
        consultingRequest: true,
      },
    });
  };

  const onClickCreateAppointment = async () => {
    //예약등록 클릭 시 고객조회 팝업
    if (data?.customerId) {
      const resp = await services.crm.customer.detail(data.customerId);
      openCustomerChartAppointment(resp.data);
    } else {
      modal
        .custom({
          component: ModalCustomerFinder,
          options: {
            customerData: data,
          },
        })
        .then((customer) => {
          //통합차트 오픈
          if (!customer) return;
          openCustomerChartAppointment(customer);
        });
    }
  };

  const onChangeCategory = (e) => {
    const { value } = e.target;
    const [id, name] = value.split('||');
    if (name !== '리콜 필요') {
      setDatePickerVisible(false);
    } else {
      setDatePickerVisible(true);
    }
    const obj = statusList.filter(
      (value) => value.category.id === Number(id)
    )[0];
    setConsultingRequestObj({
      ...consultingRequestObj,
      status: obj ?? null,
      statusId: obj?.id,
    });
    setCategoryId(Number(id));
  };

  const onChangeStatus = (e) => {
    const { value } = e.target;
    const [id] = value.split('||');
    const obj = statusList.find((v) => v.id === Number(id));
    setConsultingRequestObj({
      ...consultingRequestObj,
      status: obj,
      statusId: Number(id),
    });
    // if(value === '직접입력') {
    //   setStatusBox(true)
    // } else if(value === '미응대') {
    //   //status = 미응대 = null
    //   setConsultingRequestObj({...consultingRequestObj, status: null })
    // } else {
    //   setConsultingRequestObj({...consultingRequestObj, status: value })
    // }
  };

  const onChangeRecallTime = (e) => {
    const { name, value } = e.target;
    if (name === 'hour') {
      setRecallHour(value);
    } else {
      setRecallMinute(value);
    }
  };

  return (
    <div className="consulting-request m-t-16">
      <ConsultingRequestDataArea data={data} />
      {unResponsiveList.length ? (
        <ConsultingRequestUnResponsiveDataArea data={unResponsiveList} />
      ) : null}
      {data.consultingRequestResult.length ? (
        <ConsultingRequestResponsiveHistoryDataArea
          parentData={data}
          data={exceptUnResponsiveList}
        />
      ) : null}

      <div
        className={`handling-data m-t-32 ${
          auth.me.id !== data.assignUserId ? '_dim' : ''
        }`}
      >
        {auth.me.id !== data.assignUserId && (
          <div className="dim">
            <div>*담당자만 입력할 수 있습니다.</div>
          </div>
        )}
        <span>메모</span>
        <div className="flex-row">
          <div className="m-r-8">
            <textarea
              onChange={(e) =>
                setConsultingRequestObj({
                  ...consultingRequestObj,
                  memo: e.target.value,
                })
              }
              placeholder="메모를 입력해주세요."
            />
          </div>
          <DIV>
            <div className="m-b-8">
              <p className="title">처리하기</p>
              <div className="flex-row">
                <button
                  className="btn btn-basic _small"
                  //TODO 아래 두개중 어떤게 사용되는걸까요...?? 일단 코드상으로는 아래거가 적용되기에 위에건 주석처리 해놓을게요
                  //disabled={appointmentConfirm}
                  disabled={auth.me.id !== data.assignUserId}
                  onClick={onClickCreateAppointment}
                >
                  예약등록
                </button>
                {/* <button className="btn btn-basic _small m-r-8" disabled={appointmentConfirm} onClick={onClickCreateAppointment} disabled={auth.me.id !== data.assignUserId}>예약등록</button> */}
                {/* <button className="btn btn-sm m-r-8" disabled={appointmentConfirm} >부재문자전송</button> */}
              </div>
            </div>
            <div>
              <p className="title">처리결과*</p>
              {statusBox ? (
                <Input
                  name="status"
                  disabled={appointmentConfirm}
                  onChange={(e) =>
                    setConsultingRequestObj({
                      ...consultingRequestObj,
                      status: e.target.value,
                    })
                  }
                  defaultValue={consultingRequestObj.status || ''}
                />
              ) : (
                <>
                  <Select name="category" onChange={onChangeCategory}>
                    <option value={''}>처리 카테고리</option>
                    {categoryList.map((v, i) => (
                      <option key={i} value={`${v.id}||${v.name}`}>
                        {v.name}
                      </option>
                    ))}
                  </Select>
                  <Select
                    name="status"
                    onChange={onChangeStatus}
                    disabled={!categoryId}
                  >
                    {categoryId || <option value={''}>응대 결과</option>}
                    {statusList
                      .filter((value) => value.category.id === categoryId)
                      .map((v, i) => (
                        <option key={i} value={`${v.id}||${v.name}`}>
                          {v.name}
                        </option>
                      ))}
                  </Select>
                  {datePickerVisible ? (
                    <>
                      <DatePicker
                        className="text-center"
                        wrapperClassName="date_picker full-width"
                        value={recallDate}
                        onChange={(date) => {
                          onChangeEndAt(date);
                        }}
                        popperPlacement="auto"
                      />
                      <WrapDatePicker />
                      <SelectWrapper>
                        <select name="hour" onChange={onChangeRecallTime}>
                          {hours.map((v, i) => {
                            return (
                              <option key={i} value={v}>
                                {v}
                              </option>
                            );
                          })}
                        </select>
                        <Span>시</Span>
                        <select name="minute" onChange={onChangeRecallTime}>
                          {minute.map((v, i) => {
                            return (
                              <option key={i} value={v}>
                                {v}
                              </option>
                            );
                          })}
                        </select>
                        <Span>분</Span>
                      </SelectWrapper>
                    </>
                  ) : (
                    ''
                  )}
                </>
              )}
            </div>
          </DIV>
        </div>
      </div>

      <div className="buttons">
        <div className="flex-row">
          <button className="btn-primary" onClick={() => close(0)}>
            닫기
          </button>
          <button
            className="btn-primary"
            disabled={auth.me.id !== data.assignUserId}
            onClick={onClickSave}
          >
            저장
          </button>
        </div>
      </div>
    </div>
  );
};

TabConsultingRequest.propTypes = {
  data: PropTypes.object,
  close: PropTypes.func,
};

export default observer(TabConsultingRequest);
