import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import { translate } from 'filters';
import { useServices } from 'hooks/useServices';
import { useToast } from 'hooks/useToast';
import styled from 'styled-components';
import QuillTextField from 'components/quill/QuillTextField';
import { toHexColorHtml } from 'components/quill/quillUtil';

const Input = styled.input`
  width: 100%;
  height: 34px;
  padding: 8px 20px;
  font-size: 12px;
  display: block;
  min-height: 10px;
  border-radius: 4px;
  color: #000;
  border: 1px solid #dee2ec;
`;

const ModalMemoBoilerplateEdit = ({ options, close }) => {
  const services = useServices();
  const toast = useToast();
  const [category] = useState(toJS(options.category));
  const [data] = useState(toJS(options.data));
  const [total] = useState(toJS(options.total));
  const [title, setTitle] = useState(
    options.action == 'edit' ? toJS(options.data.title) : ''
  );
  const [content, setContent] = useState(
    options.action == 'edit' ? toJS(options.data.contents || '') : ''
  );

  const onSave = useCallback(async () => {
    if (!title) {
      toast.error('제목을 입력해주세요');
      return;
    }
    if (!content) {
      toast.error('내용을 입력해주세요');
      return;
    }
    try {
      let resp;
      if (options.action == 'add') {
        resp = await services.crm.boilerplate.memo.create({
          category: category,
          title: title,
          contents: toHexColorHtml(content),
          order: total,
          visible: true,
        });
      } else if (options.action == 'edit') {
        resp = await services.crm.boilerplate.memo.update(data.id, {
          ...data,
          category: category,
          title: title,
          contents: toHexColorHtml(content),
        });
      }
      close({ action: options.action, update: resp.data });
    } catch (error) {
      toast.error(error.description);
    }
  }, [services.crm.boilerplate.memo, toast, options, title, content]);

  return (
    <div className="modal-memo-boilerplate-edit modal-input">
      <div className="head flex-row flex-between items-start">
        <div className="title">
          {options.action == 'add' && '상용구 추가'}
          {options.action == 'edit' && '상용구 수정'}
        </div>
        <i onClick={() => close()} className="zmdi zmdi-close" />
      </div>
      <div className="body">
        <div className="form-control">
          <label className="label-required">제목</label>
          <Input
            placeholder="제목"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </div>
        <div className="form-control">
          <label className="label-required">내용</label>
          <QuillTextField
            value={content || ''}
            setValue={(v) => setContent(v)}
            placeholder="내용을 입력하세요."
          />
        </div>
      </div>
      <div className="buttons">
        <div className="flex-row">
          <button onClick={close} className="btn btn-default" value={content}>
            {translate('CLOSE')}
          </button>
          <button onClick={() => onSave()} className="btn btn-primary">
            저장
          </button>
        </div>
      </div>
    </div>
  );
};

ModalMemoBoilerplateEdit.propTypes = {
  options: PropTypes.object,
  close: PropTypes.func,
};

export default observer(ModalMemoBoilerplateEdit);
