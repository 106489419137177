import React, { useState } from 'react';
import { observer } from 'mobx-react';
import useCtiStore from './hooks/useCtiStore';
import './ctiSettingsModal.scss';
import classNames from 'classnames';
import { CtiCheckbox } from './CtiCheckbox';

const CtiSettingsModal = observer(function CtiSettingsModal({ close }) {
  const ctiStore = useCtiStore();

  const [useRing, setUseRing] = useState(ctiStore.settings.useRing);
  const [autohide, setAutohide] = useState(ctiStore.settings.autohide);
  const [autohideDuration, setAutohideDuration] = useState(
    ctiStore.settings.autohideDuration
  );
  const [showGrade, setShowGrade] = useState(ctiStore.settings.showGrade);
  const [showCounselor, setShowCounselor] = useState(
    ctiStore.settings.showCounselor
  );
  const [showDoctor, setShowDoctor] = useState(ctiStore.settings.showDoctor);
  const [showCaller, setShowCaller] = useState(ctiStore.settings.showCaller);
  const [showResult, setShowResult] = useState(ctiStore.settings.showResult);

  const [historyLocation, setHistoryLocation] = useState(
    ctiStore.settings.historyLocation
  );
  const [callPopupLocation, setCallPopupLocation] = useState(
    ctiStore.settings.callPopupLocation
  );

  function onClickLocOption(loc) {
    setCallPopupLocation(loc);
  }

  function onClickHistoryLocOption(loc) {
    setHistoryLocation(loc);
  }

  function checkInfoOptions(f, v) {
    if (!v) {
      f(v);
      return;
    }

    let count = 0;
    if (showGrade) count++;
    if (showCounselor) count++;
    if (showDoctor) count++;
    if (showCaller) count++;
    if (showResult) count++;

    if (count >= 3) {
      return;
    }

    f(v);
  }

  function onClickCancel() {
    close();
  }

  function onClickSave() {
    ctiStore.setSettings({
      useRing,
      autohide,
      autohideDuration,
      showGrade,
      showCounselor,
      showDoctor,
      showCaller,
      showResult,
      historyLocation,
      callPopupLocation,
    });

    close();
  }

  return (
    <div className="cti-settings-modal">
      <div className="modal-head">
        <span className="title">CTI 설정</span>
        <button className="close-icon-btn" onClick={onClickCancel}></button>
      </div>
      <div className="modal-body">
        <div className="section">
          <div className="section-title">
            <span>Ring 팝업</span>
          </div>
          <div className="section-content">
            <div className="section-description">
              콜 유입 시 화면 내 Ring 팝업의 사용 여부와 세부 옵션을 설정하여
              고객의 정보를 미리 알 수 있도록 도와줍니다.
            </div>
            <div className="item-row">
              <CtiCheckbox
                checked={useRing}
                onChange={(e) => setUseRing(e.target.checked)}
                id="ctiUseRing"
              />
              <span className="input-label">사용 여부</span>
            </div>
            <div className="item-row">
              <div>
                <div className="autohide-option-row1">
                  <CtiCheckbox
                    disabled={!useRing}
                    checked={autohide}
                    onChange={(e) => setAutohide(e.target.checked)}
                    id="ctiAutohide"
                  />
                  <span className="input-label">
                    Ring 팝업 자동사라짐 사용 여부
                  </span>
                </div>
                <div className="autohide-option-row2">
                  <input
                    className="small"
                    type="number"
                    disabled={!useRing || !autohide}
                    value={autohideDuration}
                    min={1}
                    onChange={(e) => setAutohideDuration(e.target.value)}
                    onBlur={() =>
                      setAutohideDuration(Math.max(1, autohideDuration))
                    }
                  />
                  <span className="input-description">초 후 사라짐</span>
                </div>
              </div>
            </div>
            <div className="item-row">
              <span className="item-title">간략 정보 표출(최대 3개* 가능)</span>
            </div>
            <div className="item-row">
              <div className="info-option-item">
                <CtiCheckbox
                  disabled={!useRing}
                  checked={showGrade}
                  onChange={(e) =>
                    checkInfoOptions(setShowGrade, e.target.checked)
                  }
                  id="ctiShowGrade"
                />
                <span className="input-label">등급</span>
              </div>
              <div className="info-option-item">
                <CtiCheckbox
                  disabled={!useRing}
                  checked={showCounselor}
                  onChange={(e) =>
                    checkInfoOptions(setShowCounselor, e.target.checked)
                  }
                  id="ctiShowCounselor"
                />
                <span className="input-label">상담사</span>
              </div>
              <div className="info-option-item">
                <CtiCheckbox
                  disabled={!useRing}
                  checked={showDoctor}
                  onChange={(e) =>
                    checkInfoOptions(setShowDoctor, e.target.checked)
                  }
                  id="ctiShowDoctor"
                />
                <span className="input-label">의사</span>
              </div>
              <div className="info-option-item">
                <CtiCheckbox
                  disabled={!useRing}
                  checked={showCaller}
                  onChange={(e) =>
                    checkInfoOptions(setShowCaller, e.target.checked)
                  }
                  id="ctiShowCaller"
                />
                <span className="input-label">최근 통화자</span>
              </div>
              <div className="info-option-item">
                <CtiCheckbox
                  disabled={!useRing}
                  checked={showResult}
                  onChange={(e) =>
                    checkInfoOptions(setShowResult, e.target.checked)
                  }
                  id="ctiShowResult"
                />
                <span className="input-label">최근 통화결과</span>
              </div>
            </div>
          </div>
        </div>
        <div className="section">
          <div className="section-title">
            <span>최근 통화내역 팝업</span>
          </div>
          <div className="section-content">
            <div className="section-description">
              CRM 전체 화면 내에 금일 최근 통화내역 팝업의 위치를 설정할 수
              있습니다.
            </div>
            <div className="item-row">
              <div className="item-box">
                <span className="item-title">팝업 위치 :</span>
                <div className="location-items">
                  {[
                    {
                      label: '우측 배치',
                      value: 'right',
                    },
                    {
                      label: '좌측 배치',
                      value: 'left',
                    },
                  ].map((loc) => (
                    <button
                      key={loc.value}
                      className="location-option"
                      onClick={() => onClickHistoryLocOption(loc.value)}
                    >
                      <div className={`option-icon history ${loc.value}`}></div>
                      <div
                        className={classNames('option-name', {
                          selected: historyLocation === loc.value,
                        })}
                      >
                        {loc.label}
                      </div>
                    </button>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section">
          <div className="section-title">
            <span>상담통화 팝업</span>
          </div>
          <div className="section-content">
            <div className="section-description">
              고객 정보 열람과 동시에 응대를 할 수 있도록 상담통화 팝업의 위치를
              설정할 수 있습니다.
            </div>
            <div className="item-row">
              <div className="item-box">
                <span className="item-title">팝업 위치 :</span>
                <div className="location-items">
                  {[
                    {
                      label: '좌하단 배치',
                      value: 'bottomleft',
                    },
                    {
                      label: '좌상단 배치',
                      value: 'topleft',
                    },
                    {
                      label: '우하단 배치',
                      value: 'bottomright',
                    },
                    {
                      label: '우상단 배치',
                      value: 'topright',
                    },
                  ].map((loc) => (
                    <button
                      key={loc.value}
                      className="location-option"
                      onClick={() => onClickLocOption(loc.value)}
                    >
                      <div className={`option-icon popup ${loc.value}`}></div>
                      <div
                        className={classNames('option-name', {
                          selected: callPopupLocation === loc.value,
                        })}
                      >
                        {loc.label}
                      </div>
                    </button>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="actions">
        <button className="cancel-btn" onClick={onClickCancel}>
          취소
        </button>
        <button className="save-btn" onClick={onClickSave}>
          저장
        </button>
      </div>
    </div>
  );
});

export default CtiSettingsModal;
