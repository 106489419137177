import React, { useState, useCallback, useEffect } from 'react';
import { observer } from 'mobx-react';
import SelectAutoNotificationCommon from './SelectAutoNotificationCommon';
import InputDatePicker from 'components/common/InputDatePicker';
import PropTypes from 'prop-types';
import { translate } from 'filters';
import { RadioInput } from 'components/common/RadioInput';

const ViewMessageBottom = ({ obj, setObj, searchHistoryInit }) => {
  const [initUiObj] = useState({
    scheduleDate: null,

    scheduleTimeHour:
      obj.smsScheduleType === 'scheduled'
        ? obj.scheduleTime.split(':')[0]
        : '08',
    scheduleTimeMinute:
      obj.smsScheduleType === 'scheduled'
        ? obj.scheduleTime.split(':')[1]
        : '00',
    disableScheduleTime: obj.smsScheduleType === 'scheduled' ? false : true,
    scheduleDaysBefore: String(obj.scheduleDaysBefore).toLowerCase(),
  });

  const [uiObj, setUiObj] = useState(initUiObj);

  useEffect(() => {
    //문자 전송후 searchHistoryInit = true > 이면 검색 조건 초기화
    setUiObj(initUiObj);
  }, [searchHistoryInit]);

  const onChangeHandler = useCallback(
    (e) => {
      const { name, value } = e.target;
      const scheduleTimeItemNames = ['scheduleTimeHour', 'scheduleTimeMinute'];

      if (scheduleTimeItemNames.indexOf(name) !== -1) {
        const timeObj = Object.assign(
          ...scheduleTimeItemNames.map((name) => ({ [name]: uiObj[name] })),
          { [name]: value }
        );
        const scheduleTime = `${timeObj.scheduleTimeHour}:${timeObj.scheduleTimeMinute}`;

        setUiObj((uiObj) => ({
          ...uiObj,
          ...timeObj,
        }));

        setObj((obj) => ({
          ...obj,
          scheduleTime: scheduleTime,
        }));
        return;
      }

      if (name === 'scheduleDaysBefore') {
        setUiObj((uiObj) => ({
          ...uiObj,
          [name]: value,
        }));
        setObj((obj) => ({
          ...obj,
          scheduleDaysBefore: value === 'true',
        }));
        return;
      }

      if (name === 'smsScheduleType') {
        setUiObj((uiObj) => ({
          ...uiObj,
          disableScheduleTime: obj.smsScheduleType === 'scheduled',
        }));
      }

      setObj((obj) => ({
        ...obj,
        [name]: value,
      }));
    },
    [obj.smsScheduleType, setObj, uiObj]
  );

  return (
    <div className={`app-view-bottom  `}>
      <div className="sms-type">
        <div className="label">
          <span className="label-required">{translate('LABEL_SMSTYPE')}</span>
        </div>
        <div className="input-select">
          <label>
            {/* obj.isBabitalkUri: 링크발송은 항상 광고이기때문에 링크를 누르면 광고로 설정되고 disabled */}
            <RadioInput
              name="smsType"
              value="normal"
              onChange={onChangeHandler}
              checked={obj.smsType === 'normal'}
              disabled={obj.isBabitalkUri}
            />
            <span>{translate('LABEL_SMSTYPE_NORMAL')}</span>
          </label>
          <label>
            <RadioInput
              name="smsType"
              value="event"
              onChange={onChangeHandler}
              checked={obj.smsType === 'event'}
              disabled={obj.isBabitalkUri}
            />
            <span>{translate('LABEL_SMSTYPE_EVENT')}</span>
          </label>
        </div>
      </div>
      <div className="schedule-type">
        <div className="label">
          <span className="label-required">
            {translate('LABEL_SCHEDULE_TYPE')}
          </span>
        </div>
        <div className="input-select">
          <label>
            <RadioInput
              name="smsScheduleType"
              value="immediate"
              onChange={onChangeHandler}
              checked={obj.smsScheduleType === 'immediate'}
            />
            <span>{translate('LABEL_SCHEDULE_TYPE_IMMEDIATE')}</span>
          </label>
          <label>
            <RadioInput
              name="smsScheduleType"
              value="scheduled"
              onChange={onChangeHandler}
              checked={obj.smsScheduleType === 'scheduled'}
            />
            <span>{translate('LABEL_SCHEDULE_TYPE_SCHEDULED')}</span>
          </label>
        </div>
      </div>
      <div className="schedule-info">
        <div className="input-select">
          <div className="schedule-info-select schedule-info-date m-b-8">
            <InputDatePicker
              className="text-center"
              disabled={uiObj.disableScheduleTime}
              name="scheduleDate"
              onChange={onChangeHandler}
              value={obj.scheduleDate}
            />
          </div>
          <div className="flex-row schedule-info-time">
            <div className="schedule-info-select schedule-info-time-hour">
              <SelectAutoNotificationCommon
                disabled={uiObj.disableScheduleTime}
                name="scheduleTimeHour"
                onChange={onChangeHandler}
                value={uiObj.scheduleTimeHour}
              />
            </div>
            <div className="m-r-8 m-l-8">
              <span>:</span>
            </div>
            <div className="schedule-info-select schedule-info-time-minute">
              <SelectAutoNotificationCommon
                disabled={uiObj.disableScheduleTime}
                name="scheduleTimeMinute"
                onChange={onChangeHandler}
                value={uiObj.scheduleTimeMinute}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ViewMessageBottom.propTypes = {
  obj: PropTypes.object,
  setObj: PropTypes.func,
  searchHistoryInit: PropTypes.bool,
};

export default React.memo(observer(ViewMessageBottom));
