import React, { useEffect, useCallback, useState, useRef } from 'react';
import { observer } from 'mobx-react';
import DataTableAlpha from 'components/data-table/DataTableAlpha';
import './ctiMemoList.scss';
import useCtiStore from './hooks/useCtiStore';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import models from 'models';
import { $qb } from 'scripts/querybuilder';
import { useServices } from 'hooks/useServices';
import { useToast } from 'hooks/useToast';

const MemoListWrapper = ({ children }) => {
  const ctiStore = useCtiStore();

  const memoViewer = useRef(null);

  const [relPosition, setRelPosition] = useState({ x: null, y: null });
  const [position, setPosition] = useState({ x: null, y: null });

  function onDragStart(e) {
    var img = new Image();
    img.src =
      'data:image/gif;base64,R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=';
    e.dataTransfer.setDragImage(img, 0, 0);
    let rect = memoViewer.current.getBoundingClientRect();
    setRelPosition({
      x: e.nativeEvent.pageX - rect.left,
      y: e.nativeEvent.pageY - rect.top,
    });
  }

  function onDrag(e) {
    if (!e.nativeEvent.pageX && !e.nativeEvent.pageY) {
      return;
    }

    setPosition({
      x: e.nativeEvent.pageX - relPosition.x,
      y: e.nativeEvent.pageY - relPosition.y,
    });
  }

  function onDragEnd(e) {
    // setPosition({
    //   x: e.nativeEvent.pageX - relPosition.x,
    //   y: e.nativeEvent.pageY - relPosition.y,
    // });
  }

  return (
    <div
      className={classNames(
        'cti-all-memo-viewer',
        ctiStore.settings.callPopupLocation,
        ctiStore.settings.historyLocation,
        {
          statusBar: ctiStore.showStatusBar,
          history: ctiStore.showHistory,
        }
      )}
      ref={memoViewer}
      style={{
        top: position.y != null ? position.y : undefined,
        left: position.x != null ? position.x : undefined,
        bottom: position.y != null ? 'auto' : undefined,
      }}
      draggable={true}
      onDragStart={onDragStart}
      onDrag={onDrag}
      onDragEnd={onDragEnd}
    >
      {children}
    </div>
  );
};

MemoListWrapper.propTypes = {
  children: PropTypes.node,
};

const MemoList = observer(function MemoList({ onClose, customerId }) {
  const services = useServices();
  const toast = useToast();
  const defaultParams = $qb().limit(50).orderBy('scheduledAt desc');
  const [type, setType] = useState(null);
  const [data, setData] = useState(null);
  const [total, setTotal] = useState(null);
  const [params, setParams] = useState(defaultParams);

  useEffect(() => {
    callApi();
  }, [customerId, type]);

  const callApi = useCallback(async () => {
    try {
      if (!customerId) return;
      const allMemoParams = { ...params.build(), customerId: customerId };
      if (type) allMemoParams.type = type;
      const resp = await services.crm.customer.all_memo(allMemoParams);
      setData(resp.data);
      setTotal(resp.total);
    } catch (e) {
      toast.error(e.description);
    }
  }, [services.crm.customer, toast, customerId, type]);

  const onParams = (p) => {
    setParams(p);
    callApi();
  };

  return (
    <MemoListWrapper>
      <div className="inner">
        <div className="head">
          <div>
            <div className="title">모든메모 모아보기</div>
            <i onClick={() => onClose()} className="zmdi zmdi-close" />
          </div>
          <div className="search-form">
            <select onChange={(e) => setType(e.target.value)}>
              <option value="">카테고리 선택</option>
              <option value="appointment">예약</option>
              <option value="consulting">상담</option>
              <option value="consult_request">상담문의</option>
              <option value="payment">수납</option>
              <option value="surgery">시수술</option>
              <option value="treatment">진료</option>
              <option value="nurse_operation">수술간호</option>
              <option value="nurse_care">간호</option>
              <option value="nurse_skin">피부관리</option>
            </select>
          </div>
        </div>

        <div className="body hidden-scroll">
          <DataTableAlpha
            model={models.crm.allMemo}
            total={total}
            data={data}
            params={{ ...params, type: type }}
            onParams={onParams}
            hideLimitSetter
          />
        </div>
      </div>
    </MemoListWrapper>
  );
});

const CtiMemoList = observer(function CtiMemoList({
  open,
  onClose,
  customerId,
}) {
  if (!open) return null;

  return <MemoList onClose={onClose} customerId={customerId} />;
});

export default CtiMemoList;
