import React from 'react';
import { observer } from 'mobx-react';
import { currency } from 'filters';
import PropTypes from 'prop-types';
import moment from 'moment';

const ConsultingRequestDataArea = ({ data }) => {
  let currentTime = new Date();
  let recallTime = new Date(
    moment(data.lastRecallAt).format('YYYY-MM-DD HH:mm:ss')
  );
  let result = recallTime - currentTime;
  let greenFont = result <= 1800000 && result >= 0; //리콜일시 30분 전
  let redFont = result < 0; //리콜일시가 지났을 때
  return (
    <div className="request-data m-t-8">
      <table>
        <colgroup>
          <col width="20%" />
          <col width="30%" />
          <col width="20%" />
          <col width="30%" />
          <col width="30%" />
          <col width="30%" />
        </colgroup>
        <tbody>
          <tr>
            <th>담당자배정*</th>
            <td>{data.assignUser !== null ? data.assignUser.name : ''}</td>
            <th>상담문의일*</th>
            <td>{moment(data.requestAt).format('YYYY-MM-DD HH:mm:ss')}</td>
          </tr>
          <tr>
            <th>고객명*</th>
            <td>{data.userName}</td>
            <th>연락처*</th>
            <td>
              {data.customerId === null ? (
                <span className="new-customer">신규고객</span>
              ) : (
                <span className="existing-customer">기존고객</span>
              )}
              <span className="m-r-16">{data.phoneNumber}</span>
            </td>
          </tr>
          <tr>
            <th>처리결과</th>
            <td
              colSpan="3"
              style={
                data.status?.category?.name === '리콜 필요'
                  ? greenFont
                    ? { color: 'green' }
                    : redFont
                    ? { color: '#f7685b' }
                    : null
                  : data.statusName === '미응대'
                  ? { color: '#f7685b' }
                  : null
              }
            >
              {data.statusName}
              {data.status?.category?.name === '리콜 필요' && data.lastRecallAt
                ? ' ( 리콜 일시 : ' + data.lastRecallAt + ' )'
                : ''}
            </td>
          </tr>
          <tr>
            <th>매체</th>
            <td>{data.media?.tag}</td>
            <th>이벤트명</th>
            <td>{data.eventName}</td>
          </tr>
          <tr>
            <th>이벤트 유형</th>
            <td>{data.eventType}</td>
            <th>이벤트 가격</th>
            <td>
              {data.price !== null
                ? `₩${currency(data.price || 0)}`
                : data.price}
            </td>
          </tr>
          <tr>
            <th>방문희망일</th>
            <td>{moment(data.visitAt).format('YYYY-MM-DD')}</td>
            <th>관심항목</th>
            <td>{data.interestItem}</td>
          </tr>
          <tr>
            <th>고객문의</th>
            <td colSpan="3">{data.memo}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

ConsultingRequestDataArea.propTypes = {
  data: PropTypes.object,
};

export default observer(ConsultingRequestDataArea);
