import React, { useState, useCallback } from 'react';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import PropTypes from 'prop-types';
import { translate } from 'filters';
import { useEvent } from 'hooks/useEvent';
import { useModal } from 'hooks/useModal';
import { useServices } from 'hooks/useServices';
import { useToast } from 'hooks/useToast';
import styled from 'styled-components';

const Input = styled.input`
  width: calc(100% - 84px);
  background-color: #fff;
  font-size: 14px;
  height: 34px;
  padding: 8px 20px;
  border-radius: 4px;
  border: 1px solid #dee2ec;
`;

const ModalDepartmentCategory = ({ options, close }) => {
  const eventBus = useEvent();
  const modal = useModal();
  const services = useServices();
  const toast = useToast();
  const [departmentCategory, setDepartmentCategory] = useState(
    toJS(options.departmentCategory) || {}
  );

  const validate = () => {
    if (departmentCategory.name === undefined) {
      toast.error('부서명을 입력하세요.');
      return false;
    }

    if (departmentCategory.departments === undefined) {
      toast.error('세부부서명을 입력하세요.');
      return false;
    }

    return true;
  };

  const onClickSave = useCallback(async () => {
    if (!validate()) {
      return;
    }

    let endpoint = !departmentCategory.id ? 'create' : 'update';
    let payload = { ...departmentCategory };
    if (endpoint === 'create') {
      payload.order = options.categoriesOrder + 1;
    }
    payload.departments = departmentCategory.departments.map((v, i) => {
      v.order = i + 1;
      return v;
    });

    try {
      await services.crm.crud.departmentCategory[endpoint](payload);
      const message = translate(`${endpoint.toUpperCase()}D`);
      modal
        .confirm({
          type: 'SUCCESS',
          msg: message,
        })
        .then(() => {
          eventBus.emit('callApi');
          close();
        });
    } catch (e) {
      toast.error(e.description);
    }
  }, [eventBus, modal, services, toast, departmentCategory, close, options]);

  const addDepartment = () => {
    departmentCategory.departments = (
      departmentCategory.departments || []
    ).concat({
      name: '',
    });
    setDepartmentCategory({ ...departmentCategory });
  };

  return (
    <div className="modal-department-category">
      <div className="head flex-row flex-between items-start">
        <div className="title">{translate('EDIT_DEPARTMENT_CATEGORY')}</div>
        <i onClick={() => close()} className="zmdi zmdi-close" />
      </div>
      <div className="body">
        <div className="form-control section required">
          <label>{translate('DEPARTMENT_CATEGORY')}</label>
          <div className="department flex-row m-b-8">
            <Input
              value={departmentCategory.name || ''}
              onChange={(e) => {
                departmentCategory.name = e.target.value;
                setDepartmentCategory({ ...departmentCategory });
              }}
            />
          </div>
        </div>
        <div className="form-control section required">
          <label>{translate('DEPARTMENTS')}</label>
          {(departmentCategory.departments || []).map((item, idx) => (
            <div className="department flex-row m-b-8" key={idx}>
              <Input
                value={item.name}
                onChange={(e) => {
                  item.name = e.target.value;
                  setDepartmentCategory({ ...departmentCategory });
                }}
              />
              {!departmentCategory.id && (
                <button
                  className={`btn btn-remove flex-wrap m-l-8 ${
                    item.name.length > 0 ? 'active' : ''
                  }`}
                  onClick={() => {
                    (departmentCategory.departments || []).splice(idx, 1);
                    setDepartmentCategory({ ...departmentCategory });
                  }}
                >
                  {translate('DELETE')}
                </button>
              )}
            </div>
          ))}
        </div>
        <button
          className="btn btn-primary btn-block"
          onClick={() => addDepartment()}
        >
          {translate('ADD_DEPARTMENT')}
        </button>
      </div>
      <div className="buttons">
        <div className="flex-row">
          <button onClick={() => close(0)} className="btn btn-default">
            {translate('CANCEL')}
          </button>
          <button onClick={() => onClickSave()} className="btn btn-primary">
            {translate('SAVE')}
          </button>
        </div>
      </div>
    </div>
  );
};

ModalDepartmentCategory.propTypes = {
  options: PropTypes.object,
  close: PropTypes.func,
};

export default observer(ModalDepartmentCategory);
