export default {
  keys: [
    {
      title: '선택',
      type: 'multiCheck',
      checkUse: () => 'false',
    },
    {
      title: '상담사명',
      column: 'name',
      filter: (o) => (o.name === undefined ? o.userName : o.name),
    },
    {
      title: '순서변경',
      column: '',
      type: 'arrow',
    },
  ],
};
