import React, { useEffect, useContext } from 'react';
import { observer } from 'mobx-react';
import moment from 'moment';
import { AppointmentBoardStateContext } from './AppointmentBoards';
import AppointmentItems from './AppointmentItems';
import { useHoliday } from 'store/holiday';

const AppointmentMonthlyBoard = () => {
  /**
   * @type {AppointmentBoardState }
   */
  const state = useContext(AppointmentBoardStateContext);
  const holiday = useHoliday();

  useEffect(() => {
    state.resetDayExpanded();
  }, [state.startAt, state.endAt]);

  useEffect(() => {
    console.log('useeffect');
    state.resetDayExpanded();
    state.loadAppointments();
  }, [
    state.filters.counselor,
    state.filters.departmentCategory,
    state.filters.doctor,
    state.filterStatus,
  ]);

  return (
    <div className="calendar-wrapper">
      <div className="monthly-board">
        <div className="first-line flex-row">
          {['일', '월', '화', '수', '목', '금', '토'].map((dayName, index) => (
            <div key={dayName} className={`day-name day-${index}`}>
              {dayName}
            </div>
          ))}
        </div>
        <div className="calendar">
          {state.calendarDays.map((date, index) => (
            <div key={date} className="monthly-cell">
              <div
                className={`day day-${index % 7}${
                  date === moment().format('YYYY-MM-DD') ? ' today' : ''
                }${
                  holiday.data.some((v) => v.locdate === date) ? ' holiday' : ''
                }`}
              >
                <span>{date.split('-')[2]}</span>
              </div>
              <AppointmentItems date={date} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default observer(AppointmentMonthlyBoard);
