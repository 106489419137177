import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { translate } from 'filters';

const ContextMenu = ({ options, onClick }) => {
  const onClickOverlay = (e) => {
    e.preventDefault();

    if (e.target.classList.contains('home-context-menu')) {
      onClick(-1);
    }
  };

  const action = (item) => {
    onClick(item);
  };

  return (
    <div
      className={`home-context-menu ${options.show ? '' : 'display-none'}`}
      onClick={onClickOverlay}
      onContextMenu={onClickOverlay}
    >
      <ul
        style={{
          left: `${options.x}px`,
          top: `${options.y}px`,
        }}
      >
        {(options.items || []).length > 0
          ? options.items.map((item, idx) => (
              <li
                key={idx}
                onClick={() => action(item)}
                className={`flex-row items-center flex-between ${item.customStyle}`}
              >
                <span className="flex-fill">{translate(item.title)}</span>
              </li>
            ))
          : null}
      </ul>
    </div>
  );
};

ContextMenu.propTypes = {
  options: PropTypes.object,
  onClick: PropTypes.func,
};

export default observer(ContextMenu);
