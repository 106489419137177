import { targetConditionConverter } from 'filters/messageFilters';
import { formatCallerNumber, translate } from 'filters';
import conditionModel from './message-target-condition';
import data from 'store/data';

const queryWrapper = ({ category, item, query }) => {
  const storeData = data ? data : {};
  const models = conditionModel.keys;
  const model = models.find((m) => {
    return m.category === category && m.item === item;
  });

  if (model && model.options) {
    // 직접지정 option의 경우이면서 filter가 없는 경우
    // 추후 filter에서 고려하지 않는 컬럼이 생성될 경우를 고려
    const options = model.options ? model.options : {};
    return query.map((serverValue) => {
      const option = options.find((uiPreset) => uiPreset.value === serverValue);
      const label = option.label ? option.label : serverValue;
      // 참고. 직접지정은 다국어 적용 케이스가 발생할 수 있음 -> true/false 등
      if (model && model.filter) {
        return model.filter(label);
      }
      return label;
    });
  }
  if (model && model.useStoreData) {
    // 재참조 해줘야하는 경우 : 상담사 ID 등
    // key는
    // dataStore["storeKey"]에서 label에 해당하는 값을 UI로 출력.
    const { key: storeKey, label: storeLabel, value: storeValue } = model.store;
    const modelStore = storeData[storeKey];
    return query.map((serverValue) => {
      if (model.item === 'itemIds') {
        //itemIds 인 경우는, 하위 시수술명을 찾아야 함
        //query의 아이디가 하위 시수술 아이디임
        const matchedInfo = modelStore.filter((storeObj) => {
          return storeObj.items.find((f) => f.id === serverValue);
        });
        return matchedInfo
          ? (((matchedInfo[0] || []).items || []).find(
              (f) => f.id === serverValue
            ) || {})[storeLabel]
          : serverValue;
      } else {
        const matchedInfo = modelStore.find(
          (storeObj) => storeObj[storeValue] === serverValue
        );
        return matchedInfo ? matchedInfo[storeLabel] : serverValue;
      }
    });
  }

  return query;
};

export default {
  keys: [
    {
      title: 'ACTION',
      noorder: true,
      class: 'action column-width-px-180',
      filter: () => {
        return `       
            <button class="btn btn-sm btn-primary m-r-4"  data-type="select" >${translate(
              'SELECT'
            )}</button>
            <button class="btn btn-sm btn-danger m-r-4"  data-type="delete" >${translate(
              'DELETE'
            )}</button>
      `;
      },
    },
    {
      title: 'SEARCH_CONDITION',
      noorder: true,
      class: 'column-search-condition  column-width-px-600',
      filter: (obj) => {
        const { smsSearchCondition: groups, directSearchCondition } =
          obj.condition || {};
        const { phoneNumber: _manualNumbers } = directSearchCondition
          ? directSearchCondition
          : {};
        const { query: manualNumbers } = _manualNumbers ? _manualNumbers : {};
        const _html_groups = (groups ? groups : []).map((group) => {
          const { conditions } = targetConditionConverter.serverToUi(group);
          const _html_dynamic_columns = conditions.map((condition) => {
            const { category, item, query } = condition;
            const _str_category = translate(
              `LABEL_CONDITION_${category}`.toUpperCase()
            );
            const _str_item = translate(
              `LABEL_CONDITION_${category}_${item}`.toUpperCase()
            );
            let _set_query = '';
            if (Array.isArray(query)) {
              _set_query = queryWrapper({ category, item, query });
              _set_query = `${_set_query.join(', ')}`;
            } else {
              _set_query = `${query.start} ~ ${query.end}`;
            }
            return `
            <div class="row-condition">
              <div class="label"><div class="category">(${_str_category})</div><div>${_str_item}: </div></div>
              <div class="query" title="${_set_query}">${_set_query}</div>
            </div>
          `;
          });
          const html_dynamic_columns = _html_dynamic_columns.join('');
          return `
          <div class="row-group">
            ${html_dynamic_columns}
          </div>
        `;
        });
        const html_groups = _html_groups.join('');
        const _parsedManualNumbers = (manualNumbers
          ? manualNumbers
          : []
        ).map((q) => formatCallerNumber(q));
        const html_manualNumbers = `
        <div class="row-group">
          <div class="row-condition">
            <div class="label"><div>${translate(
              'DIRECT_INPUT_NUMBER'
            )}</div></div>
            <div class="query" title="${_parsedManualNumbers.join(
              ', '
            )}">${_parsedManualNumbers.join(', ')}</div>
          </div>
        </div>`;
        return `
        <div class="contents">
          ${html_groups}
          ${_parsedManualNumbers.length > 0 ? html_manualNumbers : ''}
        </div>
      `;
      },
    },
    {
      title: 'MEMO',
      noorder: true,
      class: 'column-memo',
      filter: (o) => {
        let _innerHTML = '';
        if (!o.memo || o.memo === '') {
          _innerHTML = `<button class="btn btn-sm btn-success" data-type="memo">${translate(
            'ADD_MEMO'
          )}</button>`;
        } else {
          _innerHTML = `<button class="has-value" data-type="memo">${o.memo}</button>`;
        }
        return `${_innerHTML}`;
      },
    },
  ],
};
