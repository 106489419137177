import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { translate } from 'filters';

const Table = styled.table`
  background: #fff;
  font-size: 12px;
  text-align: center;
  border-collapse: collapse;
`;

const cellCommonStyle = css`
  padding: 8px 18px;
  border: 1px solid #d7e3f1;
  min-width: 64px;
  max-width: 320px;

  ${({ grow }) =>
    grow
      ? css`
          width: 10%;
        `
      : css`
          width: 1%;
          white-space: pre;
        `}
`;

const Th = styled.th`
  ${cellCommonStyle}
  font-weight: 700;
`;

const Td = styled.td`
  ${cellCommonStyle}
  font-weight: 500;
`;

const Empty = styled.div`
  height: auto;
  padding: 60px 0;
  font-weight: 700;
  background-color: #fff;
  color: #9cb2cd;
  border: 1px solid #d7e3f1;
  border-top: 0px;
  text-align: center;
`;

const DataCell = ({ item, column, index }) => {
  if (column.value) {
    if (Array.isArray(column.value(item))) {
      return <span>{column.value(item)[index]}</span>;
    }
    return <span>{column.value(item, index)}</span>;
  }

  if (column.component) {
    return <column.component item={item} {...column?.props(item)} />;
  }

  throw new Error('invalid column schema');
};

export const DataTable = ({ data, schema }) => {
  // [] -> [[]]
  const convertToRowArray = (arr) => {
    if (Array.isArray(schema.columns[0])) return arr;
    else return [arr];
  };
  // if map
  const convertMapArray = (arr, item) => {
    const convertArr = convertToRowArray(arr);
    const matchSchemaIndex = 0;
    const mapCol = convertArr[matchSchemaIndex].filter(
      (v) => v.value && Array.isArray(v.value(item))
    );
    const mapLength = Math.max.apply(
      Math,
      mapCol.map((v) => v.value(item).length)
    );

    // layout check
    if (
      !mapCol
        .map((v) => v.value(item).length)
        .every((val, i, arr) => val === arr[0])
    ) {
      throw new Error('The array length of item should be the same.');
    }

    // set rowspan
    convertArr[matchSchemaIndex].map((v) => {
      if (
        v.rowSpan !== '100%' &&
        (v.component || !Array.isArray(v.value(item)))
      )
        v.rowSpan = (isFinite(mapLength) && mapLength) || 1;
      return v;
    });

    if (!mapCol.length || !mapLength) return convertArr;
    // add columns
    const updateArr = convertArr
      .slice(matchSchemaIndex, 1)
      .concat(Array.apply(null, { length: mapLength - 1 }).map(() => mapCol));

    return convertArr
      .map((v, i) => {
        if (i === matchSchemaIndex) return updateArr;
        return [v];
      })
      .flat();
  };

  return (
    <>
      <Table>
        <thead>
          <tr>
            {convertToRowArray(schema.columns)[0].map((col) => (
              <Th key={col.id} grow={col.grow}>
                {col.name}
              </Th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((item) =>
            convertMapArray(schema.columns, item).map((trColumn, trIndex) => (
              <tr key={item.id}>
                {trColumn.map((col) => (
                  <Td
                    key={col.id}
                    style={col.style}
                    grow={col.grow}
                    rowSpan={
                      col.rowSpan === '100%'
                        ? convertMapArray(schema.columns, item).length
                        : col?.rowSpan
                    }
                    colSpan={col?.colSpan}
                  >
                    <DataCell item={item} column={col} index={trIndex} />
                  </Td>
                ))}
              </tr>
            ))
          )}
        </tbody>
      </Table>
      {data.length === 0 && <Empty>{translate('NO_SEARCH_RESULT')}</Empty>}
    </>
  );
};

DataTable.propTypes = {
  data: PropTypes.array,
  schema: PropTypes.object,
};
