import React, { useState, useEffect, useCallback } from 'react';
import { observer } from 'mobx-react';
import List from './List';
import hooks from 'hooks';
import { $case, pluralize } from 'filters';
import ModalMessageSendToPage from 'components/modals/ModalMessageSendToPage';
import ModalAppointmentCompleteAutoSms from 'components/modals/ModalAppointmentCompleteAutoSms';
import models from 'models';
import { $qb } from 'scripts/querybuilder';
import { useModal } from 'hooks/useModal';
import { useServices } from 'hooks/useServices';
import { useToast } from 'hooks/useToast';

const Appointments = () => {
  const modal = useModal();
  const services = useServices();
  const toast = useToast();
  const [totalSearchList, setTotalSearchList] = useState({});
  const [renderFlag, setRenderFlag] = useState(true);
  const [checkItems, setCheckItems] = useState([]);

  const callApi = useCallback(async () => {
    let totalResp = {};
    let facialistParams = {
      duty: '피부관리사,간호조무사,간호사',
      userStatus: 'active',
      limit: 300,
    };
    const facialistResp = await services.crm.user.duty(facialistParams);
    if (!facialistResp) return;
    totalResp.assists = facialistResp.data;

    let counselorsParams = { duty: '상담사', userStatus: 'active', limit: 300 };
    const counselorsResp = await services.crm.user.duty(counselorsParams);
    if (!counselorsResp) return;
    totalResp._counselors = counselorsResp.data;

    let doctorsParams = { duty: '의사', userStatus: 'active', limit: 300 };
    const doctorsResp = await services.crm.user.duty(doctorsParams);
    if (!doctorsResp) return;
    totalResp._doctors = doctorsResp.data;

    let acquisitionParams = { limit: 300, visible: true };
    const acquisitionResp = await services.crm.customer.acquisitionChannel.all(
      acquisitionParams
    );
    totalResp.acquisition_channels = acquisitionResp.data;

    const departmentCategoryResp = await services.crm.crud.departmentCategory.all(
      acquisitionParams
    );
    totalResp.departmentCategory = departmentCategoryResp.data;

    totalResp.departments = [];
    totalResp.departmentCategory.map((v) => {
      v.departments.map((val) => {
        totalResp.departments.push(val);
      });
    });

    let treatmentParams = { limit: 10000, visible: true };
    const treatmentResp = await services.crm.treatment.categories.items_categories_v2(
      treatmentParams
    );
    if (!treatmentResp) return;
    totalResp.surgery_category = treatmentResp.data;

    const treatmentItemResp = await services.crm.treatment.items.all_v2(
      treatmentParams
    );
    if (!treatmentItemResp) return;
    totalResp.surgery_item_name = treatmentItemResp.data;

    setTotalSearchList(totalResp);
    setRenderFlag(!renderFlag);
  }, [services.crm.user, services.crm.treatment.categories]);

  useEffect(() => {
    callApi();
  }, [callApi]);

  const onDeleteAppointment = useCallback(
    async (id) => {
      try {
        await services.crm.crud.appointment.delete(id);
        modal
          .confirm({
            type: 'SUCCESS',
            msg: '삭제되었습니다.',
          })
          .then(() => {
            setRenderFlag(!renderFlag);
          });
      } catch (e) {
        modal.confirm({
          type: 'ERROR',
          msg: e.description,
        });
      }
    },
    [services.crm.crud.appointment, toast, renderFlag]
  );

  const onClickDeleteAppointment = (appointment) => {
    modal
      .basic({
        body: '삭제하시겠습니까?',
        buttons: [
          {
            text: 'CANCEL',
            class: 'btn-default',
          },
          {
            text: 'CONFIRM',
            class: 'btn-primary',
          },
        ],
      })
      .then((idx) => {
        if (idx === 1) {
          onDeleteAppointment(appointment.id);
        }
      });
  };

  const onClickSendSms = () => {
    if (checkItems.length) {
      modal.custom({
        component: ModalMessageSendToPage,
        options: {
          page: 'appointment',
          data: checkItems,
          smsAppointmentCheck: true,
        },
      });
    } else {
      toast.error('문자전송할 예약을 선택하세요.');
    }

    return;
  };

  const onAllCheckAction = (data) => {
    const allChecked = (data || []).every((v) => v.$$checked === true);
    if (allChecked) {
      //전체 선택인 경우
      const list = (data || []).filter((v) => {
        return (
          v.$$checked === true &&
          checkItems.find((f) => f.id === v.id) === undefined
        );
      });
      setCheckItems([...checkItems, ...list]);
    } else {
      //전체 해제인 경우
      // eslint-disable-next-line array-callback-return
      const list = (checkItems || []).filter((v) => {
        if (data.find((f) => f.id === v.id) === undefined) {
          return v;
        }
      });
      setCheckItems([...list]);
    }
  };

  const onAction = (obj) => {
    let appointment = obj.row;
    let model = obj.key;
    const event = obj.event;
    const classList = event.target.classList;

    if (model.type === 'multiCheck') {
      if (appointment.$$checked) {
        checkItems.push(appointment);
      }

      const list = checkItems
        .map((v) => {
          if (v.id === appointment.id) {
            v.$$checked = appointment.$$checked;
          }
          return v;
        })
        .filter((v) => v.$$checked === true);

      setCheckItems([...list]);
      return;
    }

    if (['고객명', 'CUSTOMER'].indexOf(model.title) !== -1) {
      hooks.openCustomerChart({
        customer: appointment.customer,
        appointment,
      });
      return;
    }

    if (['STATUS'].indexOf(model.title) !== -1) {
      onClickDeleteAppointment(appointment);
    }

    if (model.title === '예약완료 자동문자 전송설정') {
      if (classList.contains('sms-sent')) {
        onOpenSendAutoSmsPopup(appointment);
        return;
      }
    }
  };

  const onOpenSendAutoSmsPopup = async (row) => {
    const defaultParam = $qb()
      .limit(100)
      .customParam('status', 'waiting,success,fail,sending')
      .customParam('smsScheduleType', 'scheduled')
      .customParam('smsType', 'normal')
      .customParam('appointmentId', row.id);
    const resp = await services.crm.notification.smsNotifications.ad.detail(
      defaultParam.build()
    );
    if (!resp) return;

    onOpenAppointmentAutoSms(row, resp.data);
  };

  const onOpenAppointmentAutoSms = (row, rowData) => {
    modal
      .custom({
        component: ModalAppointmentCompleteAutoSms,
        options: {
          appointmentId: (row || {}).id,
          rowData: rowData,
        },
      })
      .then(() => {
        setRenderFlag(!renderFlag);
      });
  };

  const settingModels = useCallback(() => {
    const dataCallList = [
      '_doctors',
      'assists',
      '_counselors',
      'surgery_category',
      'surgery_item_name',
      'acquisition_channels',
      'departmentCategory',
      'departments',
    ];
    const model = models.crm.appointment;
    let found;
    dataCallList.forEach((v) => {
      found = (model.keys || []).find(
        (o) => o.title === $case.toConst(pluralize(v))
      );
      if (!found) return;
      found.selectOptions = (totalSearchList[v] || []).map((o) => {
        o.$$label = o['name'];
        o.$$value = o.id;
        return o;
      });
    });
  }, [models.crm.appointment, totalSearchList]);

  useEffect(() => {
    settingModels();
  }, [settingModels]);

  return (
    <List
      renderFlag={renderFlag}
      api={services.crm.crud.appointment.all}
      model={models.crm.appointment}
      modelName={'appointment'}
      orderBy={'scheduledAt desc'}
      onAction={onAction}
      onAllCheckAction={onAllCheckAction}
      onClickSendSms={onClickSendSms}
      checkItems={checkItems}
    />
  );
};

export default observer(Appointments);
