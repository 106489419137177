import React, { useCallback, useContext } from 'react';
import moment from 'moment';
import { observer } from 'mobx-react';
import { translate } from 'filters';
import { AppointmentBoardStateContext } from './AppointmentBoards';

const SelectPeriodPanel = observer(() => {
  /**
   * @type {AppointmentBoardState}
   */
  const state = useContext(AppointmentBoardStateContext);

  const onClickCalendarUnit = useCallback((key) => {
    let unitKey = key.indexOf('-') === -1 ? key : 'day';
    let startAt = moment().startOf(unitKey);
    let endAt = moment().endOf(key);
    if (key === 'week') {
      startAt.add(1, 'days');
      endAt.add(1, 'days');
    }
    if (key === '3-day') {
      endAt.add(2, 'days');
    }
    startAt = startAt.toDate();
    endAt = endAt.toDate();
    state.setCalendarUnit(key);
    state.setPeriod(startAt, endAt);
    state.loadAppointments();
  }, []);

  return (
    <>
      {['day', '3-day', 'week', 'month'].map((key) => (
        <button
          key={key}
          className={key === state.calendarUnit ? 'selected' : ''}
          onClick={() => onClickCalendarUnit(key)}
        >
          {translate(key.toUpperCase())}
        </button>
      ))}
    </>
  );
});

export default SelectPeriodPanel;
