import { translate } from 'filters';

export default {
  keys: [
    {
      title: 'ACTION',
      noorder: true,
      filter: (o) => {
        return `
              ${
                o['visible']
                  ? `<button class="btn btn-sm btn-success m-r-4"  data-type="edit" >${translate(
                      'EDIT'
                    )}</button>`
                  : `<button class="btn btn-sm btn-gray m-r-4"  data-type="edit" >${translate(
                      'EDIT'
                    )}</button>`
              }
              ${
                o['visible']
                  ? `<button class="btn btn-sm btn-danger m-r-4"  data-type="delete" >${translate(
                      'DELETE'
                    )}</button>`
                  : `<button class="btn btn-sm btn-gray m-r-4"  data-type="delete" >${translate(
                      'DELETE'
                    )}</button>`
              }
           `;
      },
      class: 'action column-width-px-180',
    },
    {
      title: '제목',
      noorder: true,
      filter: (o) => {
        return o['title'];
      },
    },
    {
      title: '내용',
      noorder: true,
      type: 'text',
      class: 'title-and-content column-width-px-400',
      filter: (o) => {
        return `<div class="contents">
            <div class="text">${o.contents || ''}</div>
          </div>`;
      },
    },
    {
      title: 'IMAGE',
      noorder: true,
      type: 'image',
      filter: (o) => {
        if ((o.images || []).length === 0) return;

        return o.images[0].url;
      },
    },
  ],
};
