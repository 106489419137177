import React, { useCallback, useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import DataTableAlpha from 'components/data-table/DataTableAlpha';
import QuillTextField from 'components/quill/QuillTextField2';
import { toJS } from 'mobx';
import moment from 'moment';
import PropTypes from 'prop-types';
import ModalSurgery from 'components/modals/ModalSurgery';
import useCustomerChart from 'hooks/useCustomerChart';
import modelOperationNurseTab from 'models/operation-nurse-tab';
import { copy, translate } from 'filters';
import { $qb } from 'scripts/querybuilder';
import { useAuth } from 'store/auth';
import { useEvent } from 'hooks/useEvent';
import { useModal } from 'hooks/useModal';
import { useServices } from 'hooks/useServices';
import { useToast } from 'hooks/useToast';
import styled from 'styled-components';

const Input = styled.input`
  border-radius: 4px;
  color: #000;
  font-size: 14px;
  border: 1px solid #dee2ec;
  background-color: #fff;
  height: 34px;
  min-height: 10px;
`;

const VitalInput = styled(Input)`
  text-align: center;
  padding: 10px;
  width: 60px;
  height: 30px;
  margin-right: 10px;
`;

const MinuteInput = styled(Input)`
  width: 60px;
  margin-right: 5px;
  margin-left: 5px;
  max-width: 200px;
  padding: 8px 20px;
  border-color: #d7e3f1;
  font-size: 12px;
`;

const AmountInput = styled(Input)`
  width: 90px;
  margin-right: 10px;
  padding: 16px 20px;
`;

const CustomerOperationNurseCharts = ({
  customer,
  // operationNurseChart,
  // openFlag,
  closeFlag,
}) => {
  const auth = useAuth();
  const eventBus = useEvent();
  const modal = useModal();
  const services = useServices();
  const toast = useToast();

  //간호차트 data api
  const tabName = 'operation';
  const defaultParams = $qb()
    .limit(10)
    .customParam('customerId', customer.id)
    .orderBy('scheduledAt desc');
  const [params, setParams] = useState(defaultParams);
  const [loadingBtnDisabledFlag, setLoadingBtnDisabledFlag] = useState(false);
  const [memoBoilerplateList, setMemoBoilerplateList] = useState([]);
  const [chartList, setChartList] = useState([]);
  const [total, setTotal] = useState(0);
  const [obj, setObj] = useState({});
  const [componentFlag, setComponentFlag] = useState(null);
  const [doctors, setDoctors] = useState([]);
  const [nurses, setNurses] = useState([]);
  const [treatmentItemCategories, setTreatmentItemCategories] = useState([]);
  const [itemsList, setItemsList] = useState([{}]); //관심 시/수술 카테고리 배열
  const [itemsListTemp, setItemsListTemp] = useState([{}]); // 수정시 items id 갖고있을 배열
  const [vitals, setVitals] = useState(null);
  const [lineTemp, setLineTemp] = useState([]);
  const [injectItems, setInjectItems] = useState(null);
  const [itemList, setItemList] = useState([
    { name: '', unit: '', amount: '' },
  ]); // 투약 항목 배열

  const opNurseChartCallApi = useCallback(async () => {
    //수술간호차트 get api 호출
    try {
      let resp = await services.crm.nurse['operations'].all_v2(
        defaultParams.build()
      );
      if (!resp) return;
      let changeResp = resp.data;
      setChartList(changeResp);
      setTotal(resp.pagination.total);
    } catch (e) {
      console.log(e.description);
    }
  }, [services.crm.nurse]);

  useEffect(() => {
    opNurseChartCallApi();
  }, [opNurseChartCallApi]);

  const onParams = (p) => {
    setParams(p);
    opNurseChartCallApi();
  };

  const doctorCallApi = useCallback(async () => {
    let params = { duty: '의사', userStatus: 'active', limit: 300 };
    const resp = await services.crm.user.duty(params);
    if (!resp) return;

    setDoctors(resp.data);
  }, [services.crm.user]);

  useEffect(() => {
    doctorCallApi();
  }, [doctorCallApi]);

  const memoBoilerplateCallApi = useCallback(async () => {
    try {
      const memoItems = await useCustomerChart.useGetMemoBoilerplate(tabName);
      setMemoBoilerplateList(memoItems);
    } catch (e) {
      console.log(e.description);
    }
  }, [services.crm.boilerplate.memo]);

  useEffect(() => {
    memoBoilerplateCallApi();
  }, [memoBoilerplateCallApi]);

  const onChangeValue = (column, value) => {
    setObj((obj) => {
      return { ...obj, [column]: value };
    });
  };

  const nurseCallApi = useCallback(async () => {
    let params = {
      duty: '간호사,간호조무사',
      userStatus: 'active',
      limit: 300,
    };
    const resp = await services.crm.user.duty(params);
    if (!resp) return;

    setNurses(resp.data);
  }, [services.crm.user]);

  useEffect(() => {
    nurseCallApi();
  }, [nurseCallApi]);

  const vitalCallApi = useCallback(async () => {
    let params = $qb().limit(100).orderBy('order asc');
    params.customParam('visible', '1');
    const resp = await services.crm.vital['operations'].all_v2(params.build());
    if (!resp) return;
    resp.data.map((v) => {
      v.operationVitalItemId = v.id;
      return v;
    });
    setVitals(resp.data);
    let obj = [];
    obj['vitalItems'] = resp.data;
    setObj(obj);
    let numbers = parseInt(resp.data.length / 7);
    let temp = [];
    if (numbers > 0) {
      for (let i = 0; i < numbers + 1; i++) {
        i === numbers ? temp.push(resp.data.length - i * 7) : temp.push(7);
      }
    } else {
      temp.push(resp.data.length);
    }
    setLineTemp([...temp]);
  }, [services.crm.vital]);

  useEffect(() => {
    vitalCallApi();
  }, [vitalCallApi]);

  const injectItemCallApi = useCallback(async () => {
    let params = $qb().limit(100).orderBy('order asc');
    params.customParam('visible', '1');
    const resp = await services.crm.injectitem['operations'].all_v2(
      params.build()
    );
    if (!resp) return;
    resp.data.map((v) => {
      v.operationInjectItemId = v.id;
      return v;
    });
    setInjectItems(resp.data);
  }, [services.crm.injectitem]);

  useEffect(() => {
    injectItemCallApi();
  }, [injectItemCallApi]);

  const treatmentItemCategoriesCallApi = useCallback(async () => {
    try {
      let params = { limit: 10000, visible: true };
      const resp = await services.crm.treatment.categories.items_categories_v2(
        params
      );
      if (!resp) return;
      setTreatmentItemCategories(resp.data);
    } catch (e) {
      console.log(e.description);
    }
  }, [services.crm.treatment.categories]);

  useEffect(() => {
    treatmentItemCategoriesCallApi();
  }, [treatmentItemCategoriesCallApi]);

  const onAction = (obj) => {
    const event = obj.event;
    const row = toJS(obj.row);
    const classList = event.target.classList;
    const model = obj.key;

    if (
      (obj.eventType === 'doubleClick' && model?.title !== 'STATUS') ||
      classList.contains('btn-primary')
    ) {
      //수정

      if (obj.row.startAt) {
        let temp = obj.row.startAt.split('T');
        obj.row['startHour'] = temp[1].slice(0, 2);
        obj.row['startMinute'] = temp[1].slice(3, 5);
      }
      if (obj.row.endAt) {
        let temp = obj.row.endAt.split('T');
        obj.row['endHour'] = temp[1].slice(0, 2);
        obj.row['endMinute'] = temp[1].slice(3, 5);
      }

      // let vitalTemp = vitals.map((v,i)=>{
      //   obj.row.vitalItems.forEach(element => {
      //     if(v.operationVitalItemId === element.operationVitalItemId){
      //       v['amount'] = element.amount
      //     }
      //   });
      //   return v
      // })

      let vitalTemp = vitals.map((v) => {
        let result = {};
        result = obj.row.vitalItems.find(
          (value) => value.operationVitalItemId === v.operationVitalItemId
        );
        v.amount = result ? result.amount : '';

        return v;
      });

      //obj.row.vitalItems = vitalTemp
      setObj(copy(obj.row));
      setVitals(vitalTemp);
      setItemList(copy(obj.row.injectItems));
      setItemsList(copy(obj.row).items || [{}]);
      setItemsListTemp(copy(obj.row).items || [{}]);

      setComponentFlag('opNurseChart');
      //window.scrollTo(0, 0);
      return;
    }

    if (classList.contains('btn-danger')) {
      onOpenDelete(row);
      return;
    }
  };

  const onClickCreateNurseChart = () => {
    let doctor = customer && customer.doctor ? customer.doctor : null;
    setObj({ ...obj, doctor, scheduledAt: moment().format() });
    setComponentFlag('opNurseChart');
  };

  const actionInit = useCallback(() => {
    setComponentFlag(null);
    setObj({});
    setItemsList([{}]);
    setItemsListTemp([{}]);
    setItemList([{ name: '', unit: '', amount: '' }]);
    vitalCallApi();
  }, []);

  const onDelete = useCallback(
    async (id) => {
      try {
        await services.crm.nurse['operations'].delete_v2(id);
        modal
          .confirm({
            type: 'SUCCESS',
            msg: '삭제되었습니다.',
          })
          .then(() => {
            opNurseChartCallApi();
            eventBus.emit('chartCountCallApi');
            actionInit();
          });
      } catch (e) {
        console.log(e.description);
        modal.confirm({
          type: 'ERROR',
          msg: e.description,
        });
      }
    },
    [eventBus, modal, services.crm.nurse, actionInit, opNurseChartCallApi]
  );

  const onOpenDelete = useCallback(
    async (chart) => {
      modal
        .basic({
          body: '정말로 삭제하시겠습니까?',
          buttons: [
            {
              text: 'CANCEL',
              class: 'btn-default',
            },
            {
              text: 'CONFIRM',
              class: 'btn-primary',
            },
          ],
        })
        .then((idx) => {
          if (idx === 1) {
            onDelete(chart.id);
          }
        });
    },
    [modal, onDelete]
  );

  const onChange = (e) => {
    if (!/^[0-9]*$/.test(e.target.value)) {
      return;
    }
    obj[e.target.name] = e.target.value;
    setObj({ ...obj });
  };

  const addInjectItem = () => {
    let arr = { name: '', unit: '', amount: '' };
    setItemList([...itemList, arr]);
  };

  const addTreatmentItem = () => {
    if (treatmentItemCategories[0] === undefined) {
      toast.error('수납코드 설정에서 시/수술을 추가해주세요.');
      return;
    }
    let list = [...itemsList];
    list.push({});
    setItemsList([...list]);
  };

  const validator = () => {
    // if(obj.startHour === undefined || obj.startMinute === null) {
    //   toast.error('수술시작시간을 선택하세요.')
    //   setLoadingBtnDisabledFlag(false)
    //   // setSaveAppointment(false)
    //   return false
    // }
    return true;
  };

  const onClickSave = useCallback(async () => {
    setLoadingBtnDisabledFlag(true);

    if (validator()) {
      //수술 시작/종료 시간

      if (
        obj['startHour'] &&
        obj['startMinute'] &&
        obj['endHour'] &&
        obj['endMinute']
      ) {
        let scheduledAt = moment(obj['scheduledAt']).format('YYYY-MM-DDT');
        let start = obj['startHour'] + ':' + obj['startMinute'];
        let end = obj['endHour'] + ':' + obj['endMinute'];
        let startTime = scheduledAt + start + ':00';
        let endTime = scheduledAt + end + ':00';

        obj['startAt'] = startTime;
        obj['endAt'] = endTime;
      }

      let endpoint = !obj.id ? 'create' : 'update';

      let payload = { ...obj };
      payload.doctor = payload.doctor ? { id: payload.doctor.id } : null;
      payload.nurse = payload.nurse ? { id: payload.nurse.id } : null;
      if (payload.vitalItems) {
        payload.vitalItems = payload.vitalItems.map((v) => {
          return {
            id: v.id,
            name: v.name,
            unit: v.unit,
            operationVitalItemId: v.operationVitalItemId,
            order: v.order,
            amount: v.amount,
            visible: v.visible,
          };
        });
      }
      if (payload.injectItems) {
        payload.injectItems = payload.injectItems.map((v) => {
          return {
            id: v.id,
            name: v.name,
            unit: v.unit,
            operationInjectItemId: v.operationInjectItemId,
            order: v.order,
            amount: v.amount,
            visible: v.visible,
          };
        });
      }
      if (payload.items) {
        payload.items = payload.items.map((v) => {
          return {
            categoryId: v.categoryId,
            categoryName: v.categoryName,
            name: v.name,
            treatmentItemId: v.treatmentItemId,
          };
        });
      }
      delete payload.startHour;
      delete payload.startMinute;
      delete payload.endHour;
      delete payload.endMinute;

      if (endpoint === 'create') {
        payload['items'] &&
          payload['items'].map((v) => {
            delete v.id;
            return v;
          });
      } else {
        itemsList.map((v, i) => {
          if (itemsListTemp[i] && itemsListTemp[i].id) {
            if (payload['items'] && payload['items'][i]) {
              payload['items'][i].id = itemsListTemp[i].id;
            }
          } else {
            if (
              payload['items'] &&
              payload['items'][i] &&
              payload['items'][i].id
            )
              delete payload['items'][i].id;
          }
        });
      }

      payload['injectItems'] &&
        payload['injectItems'].map((v) => {
          v.amount = v.amount === '' ? null : v.amount;
          delete v.id;
          delete v.createdAt;
          delete v.updatedAt;
          return v;
        });
      payload['vitalItems'] &&
        payload['vitalItems'].map((v) => {
          v.amount = v.amount === '' ? null : v.amount;
          delete v.id;
          delete v.createdAt;
          delete v.updatedAt;
          return v;
        });
      payload.customerId = (customer || {}).id;
      try {
        const resp =
          endpoint === 'create'
            ? await services.crm.nurse['operations'].create_v2(payload)
            : await services.crm.nurse['operations'].update_v2(payload);

        if (!resp) return;

        let message = translate(`${endpoint.toUpperCase()}D`);
        modal
          .confirm({
            type: 'SUCCESS',
            msg: message,
          })
          .then(() => {
            treatmentItemCategoriesCallApi();
            opNurseChartCallApi();
            eventBus.emit('chartCountCallApi');

            if (closeFlag) {
              modal.pop();
              return;
            }
            setLoadingBtnDisabledFlag(false);
            actionInit();
          });
      } catch (e) {
        console.log(e.description);
        let message = e.description;

        if (
          e.description.includes('vitalItems') &&
          e.description.includes('Not a valid number')
        ) {
          message = '바이탈 값을 숫자로 입력해주세요.';
        } else if (
          e.description.includes('injectItems') &&
          e.description.includes('Not a valid number')
        ) {
          message = '투약량을 숫자로 입력해주세요.';
        } else if (e.description.includes('Not a valid datetime')) {
          message = '수술 시작/종료시간을 오기입했습니다. 수정해주세요.';
        } else {
          message = e.description;
        }

        modal
          .confirm({
            type: 'ERROR',
            msg: message,
          })
          .then(() => {
            setLoadingBtnDisabledFlag(false);
          });
      }
    }
  }, [modal, services, obj, closeFlag, actionInit, opNurseChartCallApi]);

  const onClickOpenPaymentCodePopup = () => {
    // afterUpdateReturn: true > 팝업에서 수납코드 저장 후 저장된 내용 반환 위한 플래그
    modal
      .custom({
        component: ModalSurgery,
        options: { surgery: undefined, afterUpdateReturn: true },
      })
      .then((result) => {
        if (typeof result === 'object') {
          treatmentItemCategoriesCallApi();
          let setItems = useCustomerChart.useSettingSurgeryItems(
            result,
            itemsList.map((v) => {
              return {
                ...v,
                category: { id: v.categoryId, name: v.categoryName },
              };
            })
          );

          if (!setItems) return;

          let items = setItems.map((v) => {
            return {
              ...v,
              categoryId: v.category.id,
              categoryName: v.category.name,
              treatmentItemId:
                v?.treatmentItemId === undefined ? v.id : v.treatmentItemId,
            };
          });
          setItemsList(items);
          obj['items'] = items;
          setObj({ ...obj });
        }
      });
  };

  const onChangeScheduledAt = (scheduledAt) => {
    let date =
      scheduledAt === null ? moment().format() : moment(scheduledAt).format();
    setObj((obj) => {
      return { ...obj, scheduledAt: date };
    });
  };

  return (
    <div className="opnurse-charts chart-page">
      <div className="data-input">
        <div className="title">
          수술간호입력
          <div className="right">
            {componentFlag === null ? (
              <>
                <button
                  className="btn btn-basic _small"
                  onClick={onClickCreateNurseChart}
                >
                  신규 수술간호 입력
                </button>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
        {componentFlag === 'opNurseChart' ? (
          <div className="card">
            <div className="form-double">
              <div className="form-control">
                <label>일자</label>
                {useCustomerChart.getScheduledAtDatePicker({
                  objScheduledAt: obj.scheduledAt || new Date(),
                  onChangeScheduledAt,
                })}
              </div>
            </div>

            <div className="form-double">
              <div className="form-control">
                <label>의사</label>
                <select
                  value={doctors.findIndex(
                    (existing) =>
                      existing.id === ((obj && obj['doctor']) || {}).id
                  )}
                  onChange={(e) => {
                    obj['doctor'] = doctors[e.target.value];
                    setObj({ ...obj });
                  }}
                >
                  <option value={-1}>의사를 선택하세요.</option>

                  {(doctors || []).map((o, idx) => (
                    <option key={idx} value={idx}>
                      {' '}
                      {o.name}{' '}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-control">
                <label>간호사</label>
                <select
                  value={nurses.findIndex(
                    (existing) =>
                      existing.id === ((obj && obj['nurse']) || {}).id
                  )}
                  onChange={(e) => {
                    obj['nurse'] = nurses[e.target.value];
                    setObj({ ...obj });
                  }}
                >
                  <option value={-1}>간호사를 선택하세요.</option>
                  {(nurses || []).map((o, idx) => (
                    <option key={idx} value={idx}>
                      {' '}
                      {o.name}{' '}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <>
              <div className="form-wide m-b-20">
                {itemsList.map((list, idx) => {
                  return (treatmentItemCategories || []).length > 0 ? (
                    <div className="form-double-side" key={idx}>
                      <div className="form-control">
                        {idx === 0 && <label>시/수술 카테고리</label>}
                        <select
                          value={(treatmentItemCategories || []).findIndex(
                            (category) =>
                              category.id === (itemsList[idx] || []).categoryId
                          )}
                          onChange={(e) => {
                            if (
                              e.target.value !== -1 &&
                              e.target.value !== 'null'
                            ) {
                              const items = (
                                treatmentItemCategories[e.target.value] || {}
                              ).items;
                              itemsList[idx] = (items || [])[0];
                              if (!e.target.value) {
                                itemsList[idx]['categoryName'] =
                                  itemsList[idx]['category'].name;
                                itemsList[idx]['categoryId'] =
                                  itemsList[idx]['category'].id;
                              }

                              itemsList[idx]['categoryName'] =
                                itemsList[idx]['category'].name;
                              itemsList[idx]['categoryId'] =
                                itemsList[idx]['category'].id;
                              itemsList[idx]['treatmentItemId'] =
                                itemsList[idx]['id'];
                              setItemsList([...itemsList]);
                              obj['items'] = itemsList;
                              setObj({ ...obj });
                            } else {
                              itemsList[idx] = {};
                              setItemsList([...itemsList]);
                              obj['items'] = itemsList;
                              setObj({ ...obj });
                            }
                          }}
                        >
                          <option value={-1} hidden disabled>
                            시/수술 카테고리를 선택하세요.
                          </option>
                          <option value="null">없음</option>
                          {(treatmentItemCategories || []).map(
                            (category, idx) => (
                              <option
                                key={idx}
                                value={idx}
                                className={
                                  !category.visible ? 'display-none' : ''
                                }
                              >
                                {category.name}
                              </option>
                            )
                          )}
                        </select>
                      </div>
                      <div className="form-control">
                        {idx === 0 && <label>시/수술명</label>}
                        {(treatmentItemCategories || []).length > 0 ? (
                          <select
                            value={(
                              (
                                (
                                  treatmentItemCategories || []
                                ).find((category) =>
                                  category.items.some(
                                    (item) =>
                                      item.id ===
                                      (itemsList[idx] || {}).treatmentItemId
                                  )
                                ) || {}
                              ).items || []
                            ).findIndex(
                              (item) =>
                                item.id ===
                                (itemsList[idx] || {}).treatmentItemId
                            )}
                            onChange={(e) => {
                              if (
                                e.target.value === -1 ||
                                e.target.value === 'null'
                              ) {
                                itemsList[idx] = {};
                                setItemsList([...itemsList]);
                                obj['items'] = itemsList;
                                setObj({ ...obj });
                                return false;
                              }
                              itemsList[idx] = (
                                treatmentItemCategories || []
                              ).find((category) =>
                                category.items.some(
                                  (item) =>
                                    item.id ===
                                    (itemsList[idx] || {}).treatmentItemId
                                )
                              ).items[e.target.value];
                              itemsList[idx]['categoryName'] =
                                itemsList[idx]['category'].name;
                              itemsList[idx]['categoryId'] =
                                itemsList[idx]['category'].id;
                              itemsList[idx]['treatmentItemId'] =
                                itemsList[idx]['id'];
                              setItemsList([...itemsList]);
                              obj['items'] = itemsList;
                              setObj({ ...obj });
                            }}
                          >
                            <option value={-1} hidden disabled>
                              시/수술명을 선택하세요.
                            </option>
                            <option value="null">없음</option>
                            {(
                              (
                                (treatmentItemCategories || []).find(
                                  (category) =>
                                    category.id ===
                                    (itemsList[idx] || {}).categoryId
                                ) || {}
                              ).items || []
                            ).map((item, idx) => (
                              <option
                                key={idx}
                                value={idx}
                                className={!item.visible ? 'display-none' : ''}
                              >
                                {item.name}
                              </option>
                            ))}
                          </select>
                        ) : null}
                      </div>

                      <button
                        className="btn btn-danger _small"
                        onClick={() => {
                          modal
                            .basic({
                              body:
                                '삭제하시겠습니까? 최종 저장 전에는 반영되지 않습니다.',
                              buttons: [
                                { text: 'CANCEL', class: 'btn-default' },
                                { text: 'CONFIRM', class: 'btn-danger' },
                              ],
                            })
                            .then((selectedBtnIdx) => {
                              if (selectedBtnIdx === 1) {
                                itemsList.splice(idx, 1);
                                setItemsList([...itemsList]);
                                obj['items'] = itemsList;
                                setObj({ ...obj });
                              }
                            });
                        }}
                      >
                        {translate('DELETE')}
                      </button>
                    </div>
                  ) : null;
                })}
              </div>
              <div className="btnwrap m-t-8">
                <button
                  className="btn btn-basic middle _small _full"
                  onClick={() => addTreatmentItem()}
                >
                  입력란 추가
                </button>
                {auth.me.authorityGroup.paymentCode !== 'disabled' && (
                  <button
                    className="btn btn-primary _small m-l-8"
                    onClick={() => onClickOpenPaymentCodePopup()}
                  >
                    시/수술코드 추가
                  </button>
                )}
              </div>
            </>

            <div className="form-double">
              <div className="form-control">
                <label>수술시작시간</label>
                <MinuteInput
                  name="startHour"
                  type="text"
                  maxLength="2"
                  onChange={onChange}
                  value={obj && obj['startHour'] ? obj['startHour'] : ''}
                />
                시
                <MinuteInput
                  name="startMinute"
                  type="text"
                  maxLength="2"
                  onChange={onChange}
                  value={obj && obj['startMinute'] ? obj['startMinute'] : ''}
                />
                분
              </div>
              <div className="form-control">
                <label>수술종료시간</label>
                <MinuteInput
                  name="endHour"
                  type="text"
                  maxLength="2"
                  onChange={onChange}
                  value={obj && obj['endHour'] ? obj['endHour'] : ''}
                />
                시
                <MinuteInput
                  name="endMinute"
                  type="text"
                  maxLength="2"
                  onChange={onChange}
                  value={obj && obj['endMinute'] ? obj['endMinute'] : ''}
                />
                분
              </div>
              <div className="form-control">
                <label>마취 여부</label>
                <select
                  value={
                    obj && obj['anesthesiaType']
                      ? obj['anesthesiaType']
                      : 'null'
                  }
                  onChange={(e) => {
                    obj['anesthesiaType'] = e.target.value;
                    setObj({ ...obj });
                  }}
                >
                  {/* <option value='null' hidden disabled>선택</option> */}
                  <option value="none">없음</option>
                  <option value="general">전신 마취</option>
                  <option value="regional">부분 마취</option>
                  <option value="sedation">수면 마취</option>
                </select>
              </div>
            </div>

            <div className="form-double">
              <div>
                <label>바이탈</label>
                {lineTemp.map((v, i) => {
                  return (
                    <table key={i} className={'col-' + v}>
                      <thead>
                        <tr>
                          {vitals
                            .filter((value, index) => {
                              return index > i * 7 - 1 && index < (i + 1) * 7;
                            })
                            .map((mValue, mIndex) => {
                              return <th key={mIndex}>{mValue.name}</th>;
                            })}
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          {vitals
                            .filter((value, index) => {
                              return index > i * 7 - 1 && index < (i + 1) * 7;
                            })
                            .map((mValue, mIndex) => {
                              return (
                                <td key={mIndex}>
                                  <VitalInput
                                    type="text"
                                    onChange={(e) => {
                                      vitals[7 * i + mIndex]['amount'] =
                                        e.target.value;
                                      setVitals([...vitals]);
                                      obj['vitalItems'] = vitals;
                                      setObj({ ...obj });
                                    }}
                                    // value={obj && obj["vitalItems"] && obj["vitalItems"][(7*i)+mIndex] && obj["vitalItems"][(7*i)+mIndex]['amount'] ? obj["vitalItems"][(7*i)+mIndex]['amount'] : '' }
                                    value={
                                      vitals[7 * i + mIndex]['amount'] || ''
                                    }
                                  />
                                  {mValue.unit}
                                </td>
                              );
                            })}
                        </tr>
                      </tbody>
                    </table>
                  );
                })}
              </div>
            </div>

            {itemList.map((item, i) => {
              return (
                <div key={i} className="form-double-side">
                  <div className="form-control">
                    {i === 0 ? <label>투약약품명</label> : null}
                    <select
                      value={injectItems.findIndex(
                        (index) =>
                          index.operationInjectItemId ===
                          ((itemList && itemList[i]) || {})
                            .operationInjectItemId
                      )}
                      onChange={(e) => {
                        itemList[i] = {
                          name: injectItems[e.target.value].name,
                          unit: injectItems[e.target.value].unit,
                          operationInjectItemId:
                            injectItems[e.target.value].operationInjectItemId,
                          amount: '',
                        };
                        setItemList([...itemList]);
                        obj['injectItems'] = itemList;
                        setObj({ ...obj });
                      }}
                    >
                      <option value={-1} hidden disabled>
                        선택
                      </option>
                      <option value="null" hidden disabled>
                        선택
                      </option>
                      {(injectItems || []).map((o, idx) => (
                        <option key={idx} value={idx}>
                          {' '}
                          {o.name}{' '}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="form-control2">
                    {i === 0 ? <label>투약량</label> : null}
                    <AmountInput
                      key={i}
                      type="text"
                      onChange={(e) => {
                        itemList[i]['amount'] = e.target.value;
                        setItemList([...itemList]);
                        obj['injectItems'] = itemList;
                        setObj({ ...obj });
                      }}
                      value={itemList[i]['amount'] ? itemList[i]['amount'] : ''}
                    />
                    {itemList && itemList[i] ? itemList[i].unit : null}
                  </div>
                  <div className="form-control">
                    <button
                      className="btn btn-danger _small"
                      onClick={() => {
                        (itemList || []).splice(i, 1);
                        setItemList([...itemList]);
                        obj['injectItems'] = itemList;
                        setObj({ ...obj });
                      }}
                    >
                      삭제
                    </button>
                  </div>
                </div>
              );
            })}

            <div className="form-double">
              <div className="form-control">
                <button
                  className="btn btn-basic _small"
                  onClick={() => addInjectItem()}
                >
                  입력란 추가
                </button>
              </div>
            </div>

            <div className="form-wide">
              <div className="form-control">
                <label>메모</label>
                {memoBoilerplateList.length > 0 && (
                  <div className="wrap-btn-boilerplate-memo">
                    {memoBoilerplateList.slice(0, 5).map((v, i) => (
                      <button
                        className="btn btn-sm btn-white _ssmall"
                        key={i}
                        onClick={() =>
                          onChangeValue(
                            'memo',
                            (obj.memo == '<pre><br></pre>'
                              ? ''
                              : obj.memo || '') + v.contents
                          )
                        }
                      >
                        {v.title.slice(0, 5) + (v.title.length > 5 ? '…' : '')}
                      </button>
                    ))}
                  </div>
                )}
                {obj && (
                  <QuillTextField
                    tabName={tabName}
                    key={obj.id}
                    value={obj.memo || ''}
                    setValue={(v) => onChangeValue('memo', v)}
                    setMemoBoilerplateList={setMemoBoilerplateList}
                    placeholder="메모를 입력하세요."
                  />
                )}
              </div>
            </div>

            <div className="flex-row wrap-bottom-btns">
              <button
                className="btn btn-cancel _small m-r-8"
                onClick={actionInit}
              >
                입력취소
              </button>
              <button
                className="btn btn-basic _small"
                onClick={() => onClickSave()}
                disabled={loadingBtnDisabledFlag}
              >
                {obj && obj.id ? '수정완료' : '입력완료'}
              </button>
            </div>
          </div>
        ) : null}
      </div>
      <div className="wrap-data-table data-table">
        <div className="card">
          <div className="title">수술간호 내역</div>
          <DataTableAlpha
            model={modelOperationNurseTab}
            data={chartList}
            params={params}
            total={total}
            onParams={onParams}
            onAction={onAction}
            bottomPositionInner
            hasLine
          />
        </div>
      </div>
    </div>
  );
};

CustomerOperationNurseCharts.propTypes = {
  operationNurseChart: PropTypes.object,
  customer: PropTypes.object,
  openFlag: PropTypes.object,
  closeFlag: PropTypes.bool,
};

export default observer(CustomerOperationNurseCharts);
