import React, {
  useContext,
  useCallback,
  useState,
  useRef,
  useEffect,
} from 'react';
import { observer } from 'mobx-react';
import moment from 'moment';
import { ChromePicker } from 'react-color';
import { AppointmentBoardStateContext } from './AppointmentBoards';
import { translate } from 'filters';
import { useAuth } from 'store/auth';
import { useEvent } from 'hooks/useEvent';
import { useModal } from 'hooks/useModal';
import { useServices } from 'hooks/useServices';
import { useToast } from 'hooks/useToast';
import ModalDepartmntAbsence from 'components/modals/ModalDepartmntAbsence';

const DepartmentContextMenu = () => {
  const auth = useAuth();
  const eventBus = useEvent();
  const modal = useModal();
  const services = useServices();
  const toast = useToast();
  /**
   * @type {AppointmentBoardState }
   */
  const state = useContext(AppointmentBoardStateContext);

  const [pickerParent, setPickerParent] = useState(null);
  const [pickerColor, setPickerColor] = useState(null);
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const departmentContextRef = useRef(null);

  const onSaveColor = useCallback(() => {
    state.setDepartmentColor(
      state.departmentContextMenuOptions.department.id,
      pickerColor
    );
    setDisplayColorPicker(false);
    state.departmentContextMenuOptions.show = false;
  }, [pickerColor]);

  const onResetColor = useCallback(() => {
    if (!pickerParent) return;
    state.setTempDepartmentColor(
      state.departmentContextMenuOptions.department.id,
      null
    );
    setDisplayColorPicker(false);
  }, [pickerParent]);

  const onDeleteColor = useCallback(() => {
    if (!pickerParent) return;
    state.setDepartmentColor(
      state.departmentContextMenuOptions.department.id,
      null
    );
    setDisplayColorPicker(false);
    state.departmentContextMenuOptions.show = false;
  }, [pickerParent]);

  useEffect(() => {
    // update color
    if (pickerColor == null) return;
    state.setTempDepartmentColor(
      state.departmentContextMenuOptions.department.id,
      pickerColor
    );
  }, [pickerColor]);

  const onClickContextMenuItem = (e, item) => {
    if (!item || item === -1) {
      onResetColor();
      setDisplayColorPicker(false);
      state.departmentContextMenuOptions.show = false;
      return;
    }
    if (item.title === '휴진설정') {
      state.departmentContextMenuOptions.show = false;
      showSetDepartmentAbsence();
    } else if (item.title === '휴진설정해제') {
      state.departmentContextMenuOptions.show = false;
      modal
        .basic({
          body: '휴진설정을 해제하시겠습니까?',
          buttons: [
            { text: '취소', class: 'btn-default' },
            { text: '확인', class: 'btn-primary' },
          ],
        })
        .then((idx) => {
          if (idx === 1) {
            state.departmentContextAbsence.map((absenceSchedule) => {
              deleteDepartmentAbsence(absenceSchedule.id);
            });
          }
        });
    } else if (item.title == '부서색상설정') {
      setPickerParent({
        name: state.departmentContextMenuOptions.department.name,
        position: {
          x: state.departmentContextMenuOptions.x,
          y: state.departmentContextMenuOptions.y + 58,
        },
      });
      setPickerColor(
        state.getDepartmentOriginalColor(
          state.departmentContextMenuOptions.department.id
        )
      );
      setDisplayColorPicker(true);
    }
  };

  const showSetDepartmentAbsence = useCallback(() => {
    modal
      .custom({
        component: ModalDepartmntAbsence,
        options: {
          department: state.departmentContextMenuOptions.department,
          targetDate: state.departmentContextMenuOptions.date,
        },
      })
      .then((absence) => {
        if (absence) createAbsenceDepartment();
      });
  }, [modal]);

  const deleteDepartmentAbsence = useCallback(
    async (absenceScheduleId) => {
      try {
        await services.crm.crud.absenceSchedule.delete(absenceScheduleId);
        modal
          .confirm({
            type: 'SUCCESS',
            msg: '해제하였습니다.',
          })
          .then(() => {
            state.departmentAbsenceApi();
          });
      } catch (e) {
        console.log(e.description);
      }
    },
    [services.crm.crud.absenceSchedule, toast, eventBus]
  );

  const action = (e, item, s) => {
    if (item.disabled) return;
    onClickContextMenuItem(e, item, s);
  };

  const onClickOverlay = (e) => {
    e.preventDefault();
    if (e.target.classList.contains('overlay')) {
      onClickContextMenuItem(-1);
    }
  };

  const createAbsenceDepartment = useCallback(async () => {
    try {
      //TODO API CALL 이후 처리가 필요해서 임시로 변경했어요
      /*const absenceDepartmentItem = await services.crm.crud.absenceSchedule.create(
        {
          scheduledAt: moment(state.departmentContextMenuOptions.date).format(),
          endAt: moment(state.departmentContextMenuOptions.date).format(),
          startHour: auth.me.clinic.workStart,
          endHour: auth.me.clinic.workEnd,
          department: { id: state.departmentContextMenuOptions.department.id },
          allDay: false,
        }
      );*/
      await services.crm.crud.absenceSchedule.create({
        scheduledAt: moment(state.departmentContextMenuOptions.date).format(),
        endAt: moment(state.departmentContextMenuOptions.date).format(),
        startHour: auth.me.clinic.workStart,
        endHour: auth.me.clinic.workEnd,
        department: { id: state.departmentContextMenuOptions.department.id },
        allDay: false,
      });
      modal
        .confirm({
          type: 'SUCCESS',
          msg: '휴진설정하였습니다.',
        })
        .then(() => {
          state.departmentAbsenceApi();
        });
    } catch (e) {
      console.log(e.description);
    }
  }, [services.crm.crud.absenceSchedule, toast, auth]);

  if (!state.departmentContextMenuOptions.show) return null;

  return (
    <div
      className={`context-menu overlay`}
      onClick={onClickOverlay}
      onContextMenu={onClickOverlay}
      ref={departmentContextRef}
    >
      <ul
        style={{
          left: `${state.departmentContextMenuOptions.x}px`,
          top: `${state.departmentContextMenuOptions.y}px`,
        }}
      >
        {state.departmentContextMenuOptions.items.map((item, idx) => (
          <li
            key={idx}
            onClick={(e) => action(e, item)}
            className={`flex-row items-center flex-between ${
              item.customStyle
            } ${item.disabled ? 'disabled' : ''}`}
          >
            <span className="flex-fill">{translate(item.title)}</span>
          </li>
        ))}
      </ul>
      {displayColorPicker && (
        <div
          className="wrap-color-picker"
          style={{
            top: pickerParent.position.y,
            left: pickerParent.position.x,
          }}
        >
          <ChromePicker
            disableAlpha={true}
            color={pickerColor}
            onChangeComplete={(color) => setPickerColor(color.hex)}
          />
          <div className="wrap_btn">
            <button
              className="btn btn-normal m-r-8"
              onClick={() => onResetColor()}
            >
              취소
            </button>
            <button
              className="btn btn-primary m-r-8"
              onClick={() => onSaveColor()}
            >
              저장
            </button>
            <button className="btn btn-primary" onClick={() => onDeleteColor()}>
              초기화
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default observer(DepartmentContextMenu);
