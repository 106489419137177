import React from 'react';
import { observer } from 'mobx-react';
import Products from './Products';
import CommonForm from '../CommonForm';
import Surgeries from './Surgeries';
import { useServices } from 'hooks/useServices';
import models from 'models';

const PaymentCodes = () => {
  const services = useServices();
  /*
   * 프론트 정의: priceVatInclude > vatIncluded = true일때, price*1.1의 값, false일때, price와 같은 값으로 저장
   * 즉 priceVatInclude는 계산된 값이다
   */
  return (
    <div className="payment-codes">
      <Surgeries />
      <Products />
      {/* <CommonForm
        api={services.crm.payment.refundReason}
        model={models.crm.refundReason}
        modelName="refundReason"
        titleInline
      /> */}
      <CommonForm
        api={services.crm.payment.discountReason}
        model={models.crm.discountReason}
        modelName="discountReason"
        titleInline
      />
    </div>
  );
};

export default observer(PaymentCodes);
