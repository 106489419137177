import { formatCallerNumber, translate } from 'filters';

export default {
  keys: [
    {
      title: 'ACTION',
      noorder: true,
      class: 'action column-width-px-180',
      filter: (o) => {
        return `
            ${
              o['visible']
                ? `
              <button class="btn btn-sm btn-success m-r-4" data-type="edit" >${translate(
                'EDIT'
              )}</button>
              <button class="btn btn-sm btn-black" data-type="visible" >${translate(
                'TOGGLE_DEACTIVATE'
              )}</button>
            `
                : ''
            }
            ${
              !o['visible']
                ? `<button class="btn btn-sm btn-gray" data-type="visible" >${translate(
                    'TOGGLE_ACTIVATE'
                  )}</button>`
                : ''
            }
        `;
      },
    },
    // {
    //   title: 'ALIMTALK_STATUS',
    //   column: 'alimtalkStatus',
    //   filter: o => translate(`ALIMTALK_STATUS_${(o.alimtalkStatus || '').toUpperCase()}`),
    //   class: "column-width-px-100"
    // },
    {
      title: 'CALLER_NUMBERS',
      noorder: true,
      class: 'column-width-px-120',
      filter: (o) => {
        return formatCallerNumber(o['callerNumber']['number']);
      },
    },
    {
      title: 'SMS_SITUATION',
      column: 'smsSituation',
      noorder: true,
      class: 'column-width-px-120 pre-line',
      filter: (o) =>
        translate(
          (o.smsSituation ? `SITUATION_${o.smsSituation}` : '').toUpperCase()
        ),
    },
    {
      title: '부서',
      column: 'departmentName',
      noorder: true,
      class: 'column-width-px-120 pre-line',
      filter: (o) => {
        let smsAll = o.departmentExtendAll ? '(전체 문자 포함)' : '';
        let departmentName = '전체';
        let deleteAndVisitFlagStr = '';

        if (o.departmentName !== null) {
          let deleteFlag = '';
          let visibleFlag = '';
          if (
            o.departmentCategoryDeletedAt !== null ||
            o.departmentDeletedAt !== null
          ) {
            deleteFlag = '[삭제]';
          }

          if (
            deleteFlag !== '[삭제]' &&
            (!o.departmentCategoryVisible || !o.departmentVisible)
          ) {
            visibleFlag = '[미사용]';
          }

          departmentName = `${o.departmentCategoryName || ''}_${
            o.departmentName || ''
          }`;

          deleteAndVisitFlagStr = `${deleteFlag} ${visibleFlag}`;
        }
        return `<span
          style="text-align: center; width: 112px; white-space: break-spaces;"
          data-type="edit" 
          >${departmentName}${smsAll !== '' ? `\n${smsAll}` : ''}${
          deleteAndVisitFlagStr !== '' ? `\n${deleteAndVisitFlagStr}` : ''
        }</span>`;
      },
    },
    {
      title: 'SCHEDULE_TIME',
      noorder: true,
      class: 'column-width-px-120',
      filter: (o) => {
        if (o['smsScheduleType'] === 'immediate') {
          return translate('SMS_SCHEDULE_TYPE_IMMEDIATE');
        }
        if (o['smsScheduleType'] === 'scheduled') {
          const days = o['scheduleDaysDelta'] === 1 ? 'ONE_DAY' : 'N_DAYS';
          const direction = o['scheduleDaysBefore'] ? 'AGO' : 'LATER';
          const date = translate(`${days}_${direction}`).replace(
            /%s/,
            o['scheduleDaysDelta']
          );
          const time = o['scheduleTime'];
          return `${date} ${time}`;
        }
        return '-';
      },
    },
    {
      title: 'TITLE_AND_CONTENT',
      noorder: true,
      type: 'text',
      class: 'title-and-content column-width-px-400',
      filter: (o) => {
        if (o.title === '') {
          return `<div class="contents">
                       <div class="label">${translate('CONTENTS')}</div>
                       <div class="text">${o.contents || ''}</div>
                     </div>`;
        }

        return `<div class="title">
                       <div class="label">${translate('TITLE')}</div>
                       <div class="text">${o.title || ''}</div>
                   </div>
                   <div class="contents">
                       <div class="label">${translate('CONTENTS')}</div>
                       <div class="text">${o.contents || ''}</div>
                   </div>`;
      },
    },
    {
      title: 'IMAGE',
      noorder: true,
      type: 'image',
      class: 'image-preview column-width-px-200',
      filter: (o) => {
        if ((o.images || []).length === 0) return;
        return o.images[0].url;
      },
    },
  ],
};
