import React, { useState, useEffect, useCallback } from 'react';
import { observer } from 'mobx-react';
import HeaderRowSpanDataTable from 'components/data-table/HeaderRowSpanDataTable';
import ModalMonthlySettlementsDetail from 'components/modals/ModalMonthlySettlementsDetail';
import PropTypes from 'prop-types';
import moment from 'moment';
import models from 'models';
import { $qb } from 'scripts/querybuilder';
import { useModal } from 'hooks/useModal';
import { useServices } from 'hooks/useServices';

const MonthlySettlementsList = ({ startAt, endAt }) => {
  const modal = useModal();
  const services = useServices();
  const defaultParams = $qb()
    .orderBy('paidAt desc')
    .customParam(
      'paidStartAt',
      moment(new Date(startAt.getFullYear(), startAt.getMonth(), 1)).format(
        'YYYY-MM-DD'
      )
    )
    .customParam(
      'paidEndAt',
      moment(new Date(endAt.getFullYear(), endAt.getMonth() + 1, 0)).format(
        'YYYY-MM-DD'
      )
    );

  const [params, setParams] = useState(defaultParams);
  const [searchValue, setSearchValue] = useState({
    paidStartAt: moment(startAt).format('YYYY-MM-DD'),
    paidEndAt: moment(endAt).format('YYYY-MM-DD'),
  });
  const [data, setData] = useState();

  const onChangeStartAt = () => {
    const paidStartAt = moment(startAt).format('YYYY-MM-DD');
    const paidEndAt = moment(endAt).format('YYYY-MM-DD');
    let searchParam = { ...searchValue, paidStartAt, paidEndAt };
    if (searchParam.paidStartAt === 'Invalid date') {
      // eslint-disable-next-line no-unused-vars
      const { paidStartAt: deletedKey, ...otherKeys } = searchParam;
      searchParam = otherKeys;
    }
    if (searchParam.paidEndAt === 'Invalid date') {
      // eslint-disable-next-line no-unused-vars
      const { paidEndAt: deletedKey, ...otherKeys } = searchParam;
      searchParam = otherKeys;
    }

    params.queryParams = {
      limit: params.queryParams.limit,
      orderBy: params.queryParams.orderBy,
      ...searchParam,
    };

    setSearchValue(searchParam);
    setParams(params);
    monthlyCallApi();
  };

  const monthlyCallApi = useCallback(async () => {
    const resp = await services.crm.settlements.settlements.monthly(
      params.build()
    );
    if (!resp) return;

    let monthlyTotalTotalAmountt = 0; //total row > 당일 매출액
    let monthlyTotalSumAmount = 0; //total row > 당일 수납액 total (현금+카드+계좌이체)
    let monthlyTotalCreditCardAmount = 0; // total row > 수납액 신용카드
    let monthlyTotalDebitCardAmount = 0; // total row > 수납액 체크카드
    let monthlyTotalCashAmount = 0; //total row > 당일 수납액 cash
    let monthlyTotalBankTransferAmount = 0; //total row > 당일 수납액 계좌이체
    let monthlyTotalRefundAmount = 0; // total row > 환불액
    let monthlyTotalPaidAmount = 0; // total row > 실수금액
    let monthlyTotalUnpaidAmount = 0; // total row > 미수금액
    let monthlyTotalCashReceiptAmount = 0; // total row > 현금영수증 발급액
    let monthlyTotalRefundCreditCardAmount = 0; // total row > 신용카드 환불액
    let monthlyTotalRefundDebitCardAmount = 0; // total row > 체크카드 환불액
    let monthlyTotalRefundCashAmount = 0; // total row > 현금 환불액
    let monthlyTotalRefundBankTransferAmount = 0; // total row > 계좌이체 환불액

    resp.data.forEach((v, i) => {
      v.id = i;
      v.sumAmount =
        v.creditCardAmount +
        v.debitCardAmount +
        v.cashAmount +
        v.bankTransferAmount;
      v.cardAmount = v.creditCardAmount + v.debitCardAmount;
      v.realAmount = v.paidAmount + v.unpaidAmount - v.refundAmount;

      monthlyTotalTotalAmountt += v.totalAmount;
      monthlyTotalCreditCardAmount += v.creditCardAmount;
      monthlyTotalDebitCardAmount += v.debitCardAmount;
      monthlyTotalSumAmount += v.sumAmount;
      monthlyTotalCashAmount += v.cashAmount;
      monthlyTotalBankTransferAmount += v.bankTransferAmount;
      monthlyTotalRefundAmount += v.refundAmount;
      monthlyTotalPaidAmount += v.paidAmount;
      monthlyTotalUnpaidAmount += v.unpaidAmount;
      monthlyTotalCashReceiptAmount += v.cashReceiptAmount;
      monthlyTotalRefundCreditCardAmount += v.refundCreditCardAmount;
      monthlyTotalRefundDebitCardAmount += v.refundDebitCardAmount;
      monthlyTotalRefundCashAmount += v.refundCashAmount;
      monthlyTotalRefundBankTransferAmount += v.refundBankTransferAmount;
    });

    setData([
      {
        id: -1,
        paidAt: 'Total',
        totalAmount: monthlyTotalTotalAmountt,
        creditCardAmount: monthlyTotalCreditCardAmount,
        debitCardAmount: monthlyTotalDebitCardAmount,
        sumAmount: monthlyTotalSumAmount,
        cashAmount: monthlyTotalCashAmount,
        bankTransferAmount: monthlyTotalBankTransferAmount,
        refundAmount: monthlyTotalRefundAmount,
        paidAmount: monthlyTotalPaidAmount,
        unpaidAmount: monthlyTotalUnpaidAmount,
        cashReceiptAmount: monthlyTotalCashReceiptAmount,
        refundCreditCardAmount: monthlyTotalRefundCreditCardAmount,
        refundDebitCardAmount: monthlyTotalRefundDebitCardAmount,
        refundCashAmount: monthlyTotalRefundCashAmount,
        refundBankTransferAmount: monthlyTotalRefundBankTransferAmount,
      },
      ...resp.data,
    ]);
  }, [services.crm.settlements, params]);

  useEffect(() => {
    monthlyCallApi();
  }, [monthlyCallApi]);

  useEffect(() => {
    onChangeStartAt();
  }, [startAt]);

  const onClickOpenSettlementsDetail = (paidAt) => {
    modal.custom({
      component: ModalMonthlySettlementsDetail,
      options: {
        startAt: moment(
          new Date(paidAt.getFullYear(), paidAt.getMonth(), 1)
        ).format('YYYY-MM-DD'),
        endAt: moment(
          new Date(paidAt.getFullYear(), paidAt.getMonth() + 1, 0)
        ).format('YYYY-MM-DD'),
      },
    });
  };

  const onAction = (obj) => {
    const { row, key } = obj; // , rowIdx, event
    if (key.title === '일자') {
      if (row.paidAt !== 'Total') {
        onClickOpenSettlementsDetail(new Date(row.paidAt));
        return;
      }
    }
  };

  const onClickHeaderRow = (row) => {
    let orderBy = `${row.column} ${row.order}`;
    params.orderBy(orderBy);
    monthlyCallApi();
  };

  return (
    <div className="settlements-container total-row">
      <HeaderRowSpanDataTable
        rowKey="id"
        model={models.crm.monthlySettlements}
        data={data}
        bordered={true}
        hideBottom={true}
        onAction={onAction}
        onClickHeaderRow={onClickHeaderRow}
      />
    </div>
  );
};

MonthlySettlementsList.propTypes = {
  startAt: PropTypes.object,
  endAt: PropTypes.object,
};

export default observer(MonthlySettlementsList);
