import React, { useState, useEffect, useCallback } from 'react';
import { observer } from 'mobx-react';
import { countByteByString, emojisContainsCheck, translate } from 'filters';
import ScreenAutoNotification from 'components/app/MessageScreen/ScreenAutoNotification';
import messages from 'store/messages';
import PropTypes from 'prop-types';
import { useModal } from 'hooks/useModal';
import { useServices } from 'hooks/useServices';
import { useToast } from 'hooks/useToast';

const ModalMessageAutoSurgeryPeriod = ({ options, close }) => {
  const modal = useModal();
  const services = useServices();
  const toast = useToast();
  const [loadingBtnDisabledFlag, setLoadingBtnDisabledFlag] = useState(false);
  const [initObj] = useState({
    images: [],
    imageFiles: [],
    // id: null,
    title: '',
    contents: '',
    // visible: true,
    // createdId: null,
    // organizationId: null,
    // callerNumber: { },
    smsSituation: 'treatment_item',
    smsScheduleType: 'scheduled',
    scheduleDaysDelta: 0,
    scheduleDaysBefore: false,
    scheduleTime: '08:00',
    reserveCount: 1,
  });

  useEffect(() => {
    return () => {
      messages.setSelectSurgeryCategories([]);
      messages.setSelectSurgeryItems([]);
      messages.setAllSurgeryItems([]);

      messages.setSelectScheduleDaysDelta(0);
      messages.setSelectReserveCount(1);
      messages.setSelectScheduleDaysCycle('day');
    };
  }, []);

  const [obj, setObj] = useState({
    ...initObj,
    ...(options.autoNotification && options.autoNotification),
  });

  const [callerNumberList, setCallerNumberList] = useState([]);

  const validator = useCallback(() => {
    if (!obj.callerNumber || !obj.callerNumber.id) {
      const errorString = translate('ERROR_MISSING_FIELD').replace(
        /%s/,
        translate('PLACEHOLDER_CALLER_NUMBER')
      );
      toast.error(errorString);
      return false;
    }

    if (obj['contents'] === '') {
      const errorString = translate('ERROR_EMPTY_MESSAGE_CONTENTS');
      toast.error(errorString);
      return false;
    }

    if (!obj.smsSituation || obj['smsSituation'] === '') {
      const errorString = translate('PLACEHOLDER_SMS_SITUATION');
      toast.error(errorString);
      return false;
    }

    if (
      !obj.treatmentItemId ||
      obj.treatmentItemId === '' ||
      obj.treatmentItemId.length === 0
    ) {
      const errorString = '시/수술명을 선택하세요.';
      toast.error(errorString);
      return false;
    }

    if (!obj.scheduleDaysDelta || obj['scheduleDaysDelta'] === 0) {
      const errorString = '발송 주기가 0일로 설정되어있습니다.';
      toast.error(errorString);
      return false;
    }

    if (countByteByString(obj['title']) > 40) {
      const errorString = translate('ERROR_EXCEEDED_MESSAGE_TITLE');
      toast.error(errorString);
      return false;
    }

    if (countByteByString(obj['contents']) > 2000) {
      const errorString = translate('ERROR_EXCEEDED_MESSAGE_CONTENTS');
      toast.error(errorString);
      return false;
    }

    if (
      emojisContainsCheck((obj || {}).title) === true ||
      emojisContainsCheck((obj || {}).contents) === true
    ) {
      //문자 자동전송 추가 시 제목, 내용에 특수 이모티콘(이모지) 포함되어있는지 체크
      toast.error('문자 제목 또는 내용에 특수 이모티콘을 제거해주세요');
      return false;
    }

    return true;
  }, [obj]);

  const onClickSave = useCallback(async () => {
    if (validator()) {
      setLoadingBtnDisabledFlag(true);

      let payload = { ...obj };
      // 부서별 자동문자에서 사용하는 컬럼으로, null로 세팅되어있는 값들때문에 수정이 되지 않아
      // 임의로 삭제하고 보냄 (백엔드에서 false로 변경)
      delete payload.departmentExtendAll;

      if (messages.selectScheduleDaysCycle !== null) {
        switch (messages.selectScheduleDaysCycle) {
          case 'week':
            payload.scheduleDaysDelta = payload.scheduleDaysDelta * 7;
            break;
          case 'month':
            payload.scheduleDaysDelta = payload.scheduleDaysDelta * 30;
            break;
          case 'day':
          default:
            break;
        }
      }

      if (obj.departmentId === null && obj.departmentName === null) {
        delete payload.departmentId;
        delete payload.departmentName;
      }

      if (obj.reserveCount === null) {
        payload.reserveCount = 1;
      }

      if (obj.treatmentItemId !== '') {
        if (obj.id) {
          // 수정시에는 시/수술 개별선택
          if (typeof obj.treatmentItemId === 'object') {
            payload.treatmentItemId = obj.treatmentItemId.join(',');
          }
          // 수정시에 name 삭제하고 보내도 등록됨
          delete payload.treatmentItemName;
        } else {
          // 등록시에는 시/수술 복수선택
          if (typeof obj.treatmentItemId === 'object') {
            // 복수로 추가되는 경우는 배열형태
            payload.treatmentItemIds = obj.treatmentItemId.join(',');
            delete payload.treatmentItemId;
          }
        }
      }

      try {
        if (payload.id && payload.id !== null) {
          const resp = await services.crm.notification.smsAutoNotifications.update(
            payload
          );
          if (resp) {
            modal
              .confirm({
                type: 'SUCCESS',
                msg: '업데이트되었습니다.',
              })
              .then(() => {
                close(1);
                setLoadingBtnDisabledFlag(false);
              });
          }
        } else {
          await services.crm.notification.smsAutoNotifications.create_v2(
            payload
          );
          modal
            .confirm({
              type: 'SUCCESS',
              msg: '생성되었습니다.',
            })
            .then(() => {
              close(1);
              setLoadingBtnDisabledFlag(false);
            });
        }
      } catch (e) {
        setLoadingBtnDisabledFlag(false);
        if (e.code === 400 && e.description === 'InvalidSmsSituation') {
          toast.error(
            '고객등록완료 상황시엔 예약일, 예약시간 변수를 사용할 수 없습니다.'
          );
          return;
        }
        if (e.description === 'invalidString') {
          toast.error('문자 제목 또는 내용에 특수 이모티콘을 제거해주세요');
          return;
        }
        toast.error(e.description);
        console.log('catch: e', e);
      }
    }
  }, [close, obj, validator]);

  const getCallerNumberList = useCallback(async () => {
    try {
      const resp = await services.crm.notification.callerNumbers.all();
      if (resp && resp.data) {
        setCallerNumberList(resp.data);
        return;
      }
      setCallerNumberList([]);
    } catch (e) {
      setCallerNumberList([]);
    }
  }, []);

  const onChangeHandler = useCallback(
    (e) => {
      setObj({
        ...obj,
        [e.target.name]: e.target.value,
      });
    },
    [obj]
  );

  useEffect(() => {
    getCallerNumberList();
  }, [getCallerNumberList]);

  return (
    <div className="modal-message-screen auto-notification ">
      <div className="head flex-row flex-between items-start">
        <div className="title">주기별 문자 자동전송 입력</div>
        <i onClick={() => close()} className="zmdi zmdi-close" />
      </div>
      <div className="body">
        <div className="row">
          <ScreenAutoNotification
            obj={obj}
            setObj={setObj}
            onChange={onChangeHandler}
            callerNumberList={callerNumberList}
            autoMessageType="surgery-period"
          />
        </div>
      </div>

      <div className="buttons">
        <div className="flex-row">
          <button onClick={close} className="btn btn-default">
            {translate('CANCEL')}
          </button>
          <button
            disabled={loadingBtnDisabledFlag}
            onClick={(e) => {
              onClickSave(e);
            }}
            className="btn btn-primary"
          >
            {translate('SAVE')}
          </button>
        </div>
      </div>
    </div>
  );
};

ModalMessageAutoSurgeryPeriod.propTypes = {
  options: PropTypes.object,
  close: PropTypes.func,
};

export default observer(ModalMessageAutoSurgeryPeriod);
