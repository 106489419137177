import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { currency, currencySymbol, removeCurrency, translate } from 'filters';
import { useModal } from 'hooks/useModal';
import { useServices } from 'hooks/useServices';
import styled from 'styled-components';
import { RadioInput } from 'components/common/RadioInput';

const Input = styled.input`
  width: 100%;
  height: 34px;
  padding: 8px 20px;
  font-size: 12px;
  border-radius: 4px;
  border: 1px solid #dee2ec;
`;

const RadioInputWrapper = styled(RadioInput)`
  margin-right: 16px;
`;

const ModalProduct = ({ options, close }) => {
  const modal = useModal();
  const services = useServices();
  const [productList, setProductList] = useState([
    options.product || { priceVatInclude: 0, price: 0, vatIncluded: true },
  ]);

  const onChangeRadio = (e, idx) => {
    const { value } = e.target;
    productList[idx].vatIncluded = value === 'true';
    productList[idx].price = productList[idx].vatIncluded
      ? Math.round(productList[idx].priceVatInclude / 1.1)
      : productList[idx].priceVatInclude;
    setProductList([...productList]);
  };

  const onClickSave = async () => {
    const endpoint = options.product === undefined ? 'create' : 'update';
    try {
      for (let i in productList) {
        let obj = {
          ...productList[i],
          //priceVatInclude: productList[i].vatIncluded ? Math.round(productList[i].price*1.1) : productList[i].price
          price: productList[i].vatIncluded
            ? Math.round(productList[i].priceVatInclude / 1.1)
            : productList[i].priceVatInclude,
        };
        await services.crm.payment.product[endpoint](obj);
      }
      let message =
        options.product === undefined
          ? '생성되었습니다.'
          : '업데이트되었습니다.';
      modal
        .confirm({
          type: 'SUCCESS',
          msg: message,
        })
        .then(() => {
          close(1);
        });
    } catch (e) {
      console.log(e.description);
      let errorMessage = e.description;
      modal.confirm({
        type: 'ERROR',
        msg: errorMessage,
      });
    }
  };

  const onClickAddProduct = () => {
    setProductList([...productList, { vatIncluded: true }]);
  };

  return (
    <div className="modal-product">
      <div className="head flex-row flex-between items-start">
        <div className="title">{translate('EDIT_PRODUCT')}</div>
        <i onClick={() => close()} className="zmdi zmdi-close" />
      </div>
      <div className="body">
        {productList.map((item, i) => {
          return (
            <div key={i}>
              <div className="form-control required">
                <label>제품명</label>
                {/* {translate('PRODUCT')} */}
                <Input
                  value={item.name || ''}
                  onChange={(e) => {
                    productList[i].name = e.target.value;
                    setProductList([...productList]);
                  }}
                />
              </div>
              <div className="flex-row">
                <div className="form-control m-r-8 required">
                  <label>VAT 포함가격</label>
                  <Input
                    type="text"
                    value={
                      `${currencySymbol()} ${currency(
                        Math.round(parseInt(item.priceVatInclude))
                      )}` || ''
                    }
                    onChange={(e) => {
                      productList[i].priceVatInclude = parseInt(
                        removeCurrency(e.target.value.replace('₩', ''))
                      );
                      productList[i].price = item.vatIncluded
                        ? Math.round(productList[i].priceVatInclude / 1.1)
                        : productList[i].priceVatInclude;
                      console.log(productList[i].priceVatInclude);
                      setProductList([...productList]);
                    }}
                  />
                </div>
                <div className="form-control">
                  <label>VAT 미포함가격</label>
                  <Input
                    type="text"
                    value={
                      `${currencySymbol()} ${currency(
                        Math.round(parseInt(item.price))
                      )}` || ''
                    }
                    onChange={(e) => {
                      productList[i].price = parseInt(
                        removeCurrency(e.target.value.replace('₩', ''))
                      );
                      productList[i].priceVatInclude = item.vatIncluded
                        ? Math.round(productList[i].price * 1.1)
                        : productList[i].price;
                      console.log(productList[i].price);
                      setProductList([...productList]);
                    }}
                  />
                </div>
              </div>
              <div className="flex-row">
                <div className="form-control">
                  <span className="m-r-8">과세</span>
                  <RadioInputWrapper
                    name={`vatIncluded-${i}`}
                    checked={item.vatIncluded ? 'checked' : ''}
                    value="true"
                    onChange={(e) => onChangeRadio(e, i)}
                  />
                  <span className="m-r-8">비과세</span>
                  <RadioInputWrapper
                    name={`vatIncluded-${i}`}
                    checked={!item.vatIncluded ? 'checked' : ''}
                    value="false"
                    onChange={(e) => onChangeRadio(e, i)}
                  />
                </div>
                <div className="form-control">
                  {options.product === undefined && i !== 0 ? (
                    <button
                      className="btn btn-danger btn-sm"
                      onClick={() => {
                        (productList || []).splice(i, 1);
                        setProductList([...productList]);
                      }}
                    >
                      삭제
                    </button>
                  ) : null}
                </div>
              </div>
              <div className="row row-separator">
                <div className="col-1"></div>
                <div className="col-11 row-border"></div>
              </div>
            </div>
          );
        })}
      </div>
      {options.product === undefined ? (
        <button
          className="btn btn-primary btn-sm w-100"
          onClick={onClickAddProduct}
        >
          제품추가
        </button>
      ) : null}

      <div className="buttons">
        <div className="flex-row">
          <button onClick={close} className="btn btn-default">
            {translate('CANCEL')}
          </button>
          <button onClick={onClickSave} className="btn btn-primary">
            {translate('SAVE')}
          </button>
        </div>
      </div>
    </div>
  );
};

ModalProduct.propTypes = {
  options: PropTypes.object,
  close: PropTypes.func,
};

export default observer(ModalProduct);
