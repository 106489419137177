import React, { useRef, useState } from 'react';
import { observer } from 'mobx-react';
import './ctiHistory.scss';
import useCtiStore from './hooks/useCtiStore';
import CtiHistoryFilters from './CtiHIstoryFilters';
import CtiHistoryItems from './CtiHistoryItems';
import { ToggleButton } from 'components/common/ToggleButton';
import classNames from 'classnames';
import { CtiHelpTooltip } from './CtiHelpTooltip';

export default observer(function CtiHistory() {
  const ctiStore = useCtiStore();

  const [relPosition, setRelPosition] = useState({ x: null, y: null });
  const [position, setPosition] = useState({ x: null, y: null });

  const dragWindow = useRef(null);

  function onClickClose() {
    ctiStore.setShowHistory(false);
  }

  function onDragStart(e) {
    var img = new Image();
    img.src =
      'data:image/gif;base64,R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=';
    e.dataTransfer.setDragImage(img, 0, 0);
    let rect = dragWindow.current.getBoundingClientRect();
    setRelPosition({
      x: e.nativeEvent.pageX - rect.left,
      y: e.nativeEvent.pageY - rect.top,
    });
  }

  function onDrag(e) {
    if (!e.nativeEvent.pageX && !e.nativeEvent.pageY) {
      return;
    }

    setPosition({
      x: e.nativeEvent.pageX - relPosition.x,
      y: e.nativeEvent.pageY - relPosition.y,
    });
  }

  return (
    <>
      {ctiStore.showHistory && (
        <div
          ref={dragWindow}
          className={classNames(
            'cti-history',
            ctiStore.settings.historyLocation
          )}
          style={{
            opacity: ctiStore.historyOpacity,
            top: position.y != null ? position.y : undefined,
            left: position.x != null ? position.x : undefined,
            bottom: position.y != null ? 'auto' : undefined,
            right: position.x != null ? 'auto' : undefined,
          }}
        >
          <div
            className="cti-panel-handle"
            draggable={true}
            onDragStart={onDragStart}
            onDrag={onDrag}
          >
            <button onClick={onClickClose}></button>
          </div>
          <CtiHistoryFilters />
          <CtiHistoryItems />
          <div className="cti-panel-footer">
            <CtiHelpTooltip
              title="방해금지를 켜놓으시면 전화가 왔을 때 자동으로 팝업이 올라오지 않습니다."
              placement="top"
            >
              <span className="footer-help"></span>
            </CtiHelpTooltip>
            <span className="footer-text">방해금지</span>
            <ToggleButton
              value={ctiStore.doNotDisturb}
              onChange={(v) => ctiStore.setDoNotDisturb(v)}
            />
          </div>
        </div>
      )}
    </>
  );
});
