import './quill.css';
import React from 'react';
import sanitizeHtml from 'sanitize-html';
import PropTypes from 'prop-types';

export default function QuillText({ value }) {
  const textClass = /^<p>[\s\S]*<\/p>$/.test(value)
    ? 'quill-text'
    : 'quill-plain-text';

  return (
    <div
      className={textClass}
      dangerouslySetInnerHTML={{
        __html: sanitizeHtml(value ?? '', {
          allowedAttributes: false,
        }),
      }}
    />
  );
}

QuillText.propTypes = {
  value: PropTypes.string,
};
