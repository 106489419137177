import moment from 'moment';
import ui from 'store/ui';

export default {
  keys: [
    {
      title: '',
      noorder: true,
      filter: (o) => {
        let disabled = ui.prescriptionPrintBtnDisabledFlag ? 'disabled' : '';
        return `
          ${
            o.prescription.status !== 'temporary_stored'
              ? `<button class="btn btn-sm btn-black m-r-4"
              ${disabled}
              >인쇄</button>`
              : ``
          }
          <button class="btn btn-sm btn-primary m-r-4">수정</button>
          <button class="btn btn-sm btn-del">삭제</button>
        `;
      },
      nosearch: true,
    },
    {
      title: '발급일',
      column: 'createdAt',
      // TODO: 추후 정렬 추가 작업시 noorder 삭제
      noorder: true,
      selectType: 'date',
      customParamFieldName: 'startAt',
      filter: (o) => {
        return `${moment(o.prescription.createdAt).format('YYYY-MM-DD')}`;
      },
    },
    {
      title: '작성자',
      noorder: true,
      filter: (o) => {
        return `${o.prescription.writerName}`;
      },
    },
    {
      title: '교부번호',
      noorder: true,
      filter: (o) =>
        `${
          o.prescription.createdIdCount
            ? `${moment(o.prescription.createdAt).format(
                'YYYY년 MM월 DD일'
              )} 제 ${o.prescription.createdIdCount}호`
            : `-`
        }`,
    },
    {
      title: '보험종류',
      noorder: true,
      filter: (o) => `
                ${
                  o.prescription.prescriptionType === 'health_insurance'
                    ? '건강보험'
                    : o.prescription.prescriptionType === 'medical_benefit'
                    ? '의료급여'
                    : o.prescription.prescriptionType === 'industrial_accident'
                    ? '산업재해보험'
                    : o.prescription.prescriptionType === 'car_insurance'
                    ? '자동차보험'
                    : '기타'
                }
            `,
    },
    {
      title: '고객명',
      noorder: true,
      filter: (o) => `${o.prescription.customerName || '-'}`,
    },
    {
      title: '처방의사',
      noorder: true,
      filter: (o) => `${o.prescription.doctorName || '-'}`,
    },
    {
      title: '처방내역',
      noorder: true,
      type: 'text',
      align: 'left',
      filter: (o) => {
        let arr = [...o.medicineList, ...o.injectionList];
        return `${
          arr.length === 0 ? '-' : `${arr[0].drugName} 외 ${arr.length - 1}건`
        }`;
      },
    },
    {
      title: '상태',
      noorder: true,
      filter: (o) => `
                ${
                  o.prescription.status === 'temporary_stored'
                    ? '임시저장'
                    : o.prescription.status === 'stored'
                    ? '작성완료'
                    : o.prescription.status === 'published'
                    ? '인쇄완료'
                    : ''
                }
            `,
    },
  ],
};
