export default {
  keys: [
    {
      title: 'ACTION',
      noorder: true,
      filter: (o) => `
            ${
              o.visible
                ? `<button class="btn btn-sm btn-primary m-r-8">${
                    o.$$editing ? '저장' : '수정'
                  }</button>`
                : ''
            }
            <button class="btn btn-sm m-r-8 ${
              o.visible ? 'btn-black' : 'btn-gray'
            }">${o.visible ? '미사용' : '사용'}</button>
            <button class="btn btn-sm m-r-8 ${
              o.visible ? 'btn-danger' : 'btn-gray'
            }" data-type="delete" >삭제</button>`,
    },
    {
      title: 'LEVELS',
      column: 'name',
      noorder: true,
      type: 'editable',
    },
  ],
};
