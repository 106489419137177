import { currency, currencySymbol } from 'filters';

export default {
  keys: [
    {
      title: '순위',
      key: 'no',
      column: 'no',
      columnGroup: false,
    },
    {
      title: '환자명',
      key: 'customerName',
      column: 'customerName',
      columnGroup: false,
    },
    {
      title: '고객등급',
      columnGroup: false,
      key: 'customerLevel',
      column: 'customerLevel',
    },
    {
      title: '상담건수',
      key: 'consultedCount',
      column: 'consultedCount',
      columnGroup: false,
      orderKey: 'consultedCount',
      order: 'desc',
    },
    {
      title: '진료건수',
      key: 'treatmentCount',
      column: 'treatmentCount',
      columnGroup: false,
      orderKey: 'treatmentCount',
      order: 'desc',
    },
    {
      title: '시수술건수',
      key: 'surgeryCount',
      column: 'surgeryCount',
      columnGroup: false,
      orderKey: 'surgeryCount',
      order: 'desc',
    },
    {
      title: '수납건수',
      key: 'paymentCount',
      column: 'paymentCount',
      columnGroup: false,
      orderKey: 'paymentCount',
      order: 'desc',
    },
    {
      title: '매출액',
      key: 'salesFigures',
      column: 'salesFigures',
      columnGroup: false,
      orderKey: 'salesFigures',
      order: 'desc',
      filter: (o) => {
        return `${currencySymbol()} ${currency(
          (o?.salesFigures ?? 0) - (o?.refundAmount ?? 0)
        )}`;
      },
    },
    {
      title: '수납액',
      key: 'paidAmount',
      column: 'paidAmount',
      columnGroup: false,
      orderKey: 'paidAmount',
      order: 'desc',
      filter: (o) => {
        return `${currencySymbol()} ${currency((o || {}).paidAmount || 0)}`;
      },
    },
    {
      title: '미수금액',
      key: 'unpaidAmount',
      column: 'unpaidAmount',
      orderKey: 'unpaidAmount',
      order: 'desc',
      columnGroup: false,
      filter: (o) => {
        return `${currencySymbol()} ${currency((o || {}).unpaidAmount || 0)}`;
      },
    },
  ],
};
