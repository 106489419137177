import React from 'react';
import MessageApp from './Layouts/MessageApp';
import ModalMessageBoilerplateSelector from 'components/modals/codes/message-codes/ModalMessageBoilerplateSelect';
import SelectCallerNumber from './Layouts/SelectCallerNumber';
import ViewMessageSendBottom from './Layouts/ViewMessageSendBottom';
import PropTypes from 'prop-types';

const ScreenMessageSend = ({
  onChange,
  obj,
  setObj,
  callerNumberList,
  searchHistoryInit,
  setLoadingBtnDisabledFlag,
}) => {
  return (
    <div className={`message-screen-message-send`}>
      <MessageApp
        searchHistoryInit={searchHistoryInit}
        onChange={onChange}
        obj={obj}
        setObj={setObj}
        modalBoilerplateSelector={ModalMessageBoilerplateSelector}
        titleComponent={
          <SelectCallerNumber
            obj={obj}
            setObj={setObj}
            callerNumberList={callerNumberList}
          />
        }
        bottomComponent={
          <ViewMessageSendBottom
            obj={obj}
            setObj={setObj}
            searchHistoryInit={searchHistoryInit}
          />
        }
        setLoadingBtnDisabledFlag={setLoadingBtnDisabledFlag}
        hideBabitalkLink
      />
    </div>
  );
};

ScreenMessageSend.propTypes = {
  onChange: PropTypes.func,
  obj: PropTypes.object,
  setObj: PropTypes.func,
  callerNumberList: PropTypes.array,
  searchHistoryInit: PropTypes.bool,
  setLoadingBtnDisabledFlag: PropTypes.func,
};

export default ScreenMessageSend;
