import React, { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import HideHidden from 'components/app/HideHidden';
import DataTableAlpha from 'components/data-table/DataTableAlpha';
import ModalSurgery from 'components/modals/ModalSurgery';
import helpers from 'hooks/helpers';
import modelSurgery from 'models/surgery';
import { translate } from 'filters';
import { $qb } from 'scripts/querybuilder';
import { useModal } from 'hooks/useModal';
import { useServices } from 'hooks/useServices';
import { useToast } from 'hooks/useToast';

const Surgeries = () => {
  const modal = useModal();
  const services = useServices();
  const toast = useToast();
  const defaultParams = $qb().limit(1000).orderBy('id desc');
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(null);
  const [params] = useState(defaultParams);
  const [hideHidden, setHideHidden] = useState(false);

  const callApi = useCallback(async () => {
    try {
      const resp = await services.crm.treatment.categories.items_categories_v2(
        params.build()
      );
      if (!resp) return;
      resp.data.map((v, i) => {
        v.index = i;
        return v;
      });
      setData(resp.data);
      setTotal(resp.pagination.total);
    } catch (error) {
      console.log('error', error);
      toast.error(error.description);
    }
  }, [params]);

  const onSaveSurgeryItem = useCallback(
    async (payload) => {
      try {
        const { id, items } = payload;
        let changeItems = items.map((item) => {
          const { id, order } = item;
          return {
            id,
            order,
          };
        });
        let changePayload = {
          id,
          items: changeItems,
        };

        await services.crm.treatment.categories.update_v2(changePayload);
        modal
          .confirm({
            type: 'SUCCESS',
            msg: '업데이트되었습니다.',
          })
          .then(() => {
            callApi();
          });
      } catch (e) {
        modal.confirm({
          type: 'SUCCESS',
          msg: e.description,
        });
      }
    },
    [callApi]
  );

  const onSaveSurgeryCategories = useCallback(
    async (payload) => {
      let changePayload = payload.map((v) => {
        const { id, order } = v;
        return {
          id,
          order,
        };
      });

      try {
        await services.crm.treatment.categories.multi_update_v2({
          data: changePayload,
        });
        modal
          .confirm({
            type: 'SUCCESS',
            msg: '업데이트되었습니다.',
          })
          .then(() => {
            callApi();
          });
      } catch (e) {
        modal.confirm({
          type: 'SUCCESS',
          msg: e.description,
        });
      }
    },
    [callApi]
  );

  const onAction = (obj) => {
    const { row, key, event, rowIdx } = obj;
    if (
      event.target.dataset.type === 'visible' &&
      key['title'] === '시/수술명'
    ) {
      //시/수술명 비활성화
      const toggleObj = {
        visible: !row['@@items']['visible'],
        id: row['@@items']['id'],
      };
      const endpoint = services.crm.treatment.items;
      return helpers.toggleVisibilityById({
        obj: toggleObj,
        endpoint,
        callback: callApi,
        type: 'treatment_items',
      });
    }

    if (key['title'] === 'ACTION' && event.target.dataset.type === 'visible') {
      //카테고리 비활성화
      const toggleObj = {
        visible: !row['visible'],
        id: row['id'],
      };
      const endpoint = services.crm.treatment.categories;
      return helpers.toggleVisibilityById({
        obj: toggleObj,
        endpoint,
        callback: callApi,
        type: 'treatment_categories',
      });
    }

    if (key['title'] === 'ACTION' && event.target.dataset.type === 'edit') {
      onOpenModalSurgery({ row, key, event, rowIdx });
    }

    if (event.target.classList.contains('zmdi-long-arrow-up')) {
      changeArrowUpDown('up', key.title, row);
    } else if (event.target.classList.contains('zmdi-long-arrow-down')) {
      changeArrowUpDown('down', key.title, row);
    }

    if (
      key['title'] === '시/수술명' &&
      event.target.dataset.type === 'delete'
    ) {
      //시수술명 삭제
      modal
        .basic({
          body: `정말로 삭제하시겠습니까?`,
          buttons: [
            {
              text: 'CANCEL',
              class: 'btn-default',
            },
            {
              text: 'CONFIRM',
              class: 'btn-primary',
            },
          ],
        })
        .then((idx) => {
          idx === 1 && onClickDeleteCategoryItem(row);
        });
    }

    if (key['title'] === 'ACTION' && event.target.dataset.type === 'delete') {
      //시수술 카테고리 삭제
      modal
        .basic({
          body: `정말로 삭제하시겠습니까?`,
          buttons: [
            {
              text: 'CANCEL',
              class: 'btn-default',
            },
            {
              text: 'CONFIRM',
              class: 'btn-primary',
            },
          ],
        })
        .then((idx) => {
          idx === 1 && onClickDeleteCategories(row);
        });
    }
  };

  const onDragAction = (obj) => {
    const { rowIndex, dragRowIndex, type, categoryIndex = '' } = obj;
    if (type === 'category') {
      let categories = [...data];
      let from = Number(dragRowIndex);
      let to = Number(rowIndex);
      if (from < to) to--;
      categories.splice(to, 0, categories.splice(from, 1)[0]);

      let payload = categories.map((v, i) => {
        v['order'] = i + 1;
        return v;
      });
      onSaveSurgeryCategories(payload);
    } else {
      let items = data[categoryIndex].items;
      let from = Number(dragRowIndex);
      let to = Number(rowIndex);
      if (from < to) to--;
      items.splice(to, 0, items.splice(from, 1)[0]);

      let item = items.map((v, i) => {
        v['order'] = i + 1;
        return v;
      });

      let payload = { ...data[categoryIndex], items: item };
      onSaveSurgeryItem(payload);
    }
  };

  const changeOrderSurgery = useCallback(async (change, id, surgery) => {
    let swapUpIdx = 0;
    let swapUpData = surgery.filter((item, i) => {
      if (item.id === id) {
        swapUpIdx = i;
      }
      return item.id === id;
    })[0];

    let swapDownIdx = 0;
    if (change === 'up') {
      swapDownIdx = swapUpIdx - 1;
      if (
        swapDownIdx === -1 ||
        //visibe인 리스트 중, 내 부서 id를 찾아, 해당 인덱스가 0이면 가장 상위
        surgery.filter((v) => v.visible).findIndex((v) => v.id === id) === 0
      ) {
        toast.error('가장 상위 순서입니다.');
        return;
      }
    } else if (change === 'down') {
      swapDownIdx = swapUpIdx + 1;
      if (
        surgery.length - surgery.filter((v) => !v.visible).length === 1 ||
        swapDownIdx === surgery.length
      ) {
        toast.error('가장 하위 순서입니다.');
        return;
      }
    }

    const getSwapData = (swapDownIdx) => {
      let idx = change === 'up' ? swapDownIdx - 1 : swapDownIdx + 1;
      if (swapDownIdx === 0) {
        toast.error('가장 상위 순서입니다.');
        return;
      }
      if (swapDownIdx === surgery.length - 1) {
        toast.error('가장 하위 순서입니다.');
        return;
      }

      if (surgery[idx].visible) {
        return { idx, data: surgery[idx] };
      } else {
        return getSwapData(idx);
      }
    };

    let swapDownData = surgery[swapDownIdx];
    let item;
    if (!surgery[swapDownIdx].visible) {
      item = getSwapData(swapDownIdx);
      if (item !== undefined) {
        swapDownData = item.data;
        swapDownIdx = item.idx;
      }
    } else {
      item = { idx: swapDownIdx, data: surgery[swapDownIdx] };
    }

    return { item, swapUpData, swapDownData, swapUpIdx, swapDownIdx };
  }, []);

  const changeSurgeryItem = useCallback(
    async (change, row) => {
      let items = row.items;
      const { id } = row['@@items'];
      const changeOrder = await changeOrderSurgery(change, id, items);

      if (changeOrder !== undefined) {
        items[changeOrder.swapDownIdx] = changeOrder.swapUpData;
        items[changeOrder.swapUpIdx] = changeOrder.swapDownData;
        let item = items.map((v, i) => {
          v['order'] = i + 1;
          return v;
        });

        let payload = { ...row, items: item };
        onSaveSurgeryItem(payload);
      }
    },
    [changeOrderSurgery, onSaveSurgeryItem]
  );

  const changeSurgeryCategories = useCallback(
    async (change, row) => {
      let categories = [...data];
      const { id } = row;
      //현재 인덱스를 가진 row와
      //바로 위 인덱스를 가진 row를 찾아
      //순서를 바꾸고
      //바꾼 순서대로 order를 다시 붙여준다

      //비활성화 된 항목을 제외하고 순서가 변경됨
      const changeOrder = await changeOrderSurgery(change, id, categories);
      if (changeOrder !== undefined) {
        categories[changeOrder.swapDownIdx] = changeOrder.swapUpData;
        categories[changeOrder.swapUpIdx] = changeOrder.swapDownData;
        let payload = categories.map((v, i) => {
          v['order'] = i + 1;
          return v;
        });
        onSaveSurgeryCategories(payload);
      }
    },
    [data, changeOrderSurgery, onSaveSurgeryCategories]
  );

  const changeArrowUpDown = useCallback(
    async (change, title, row) => {
      if (title === '카테고리') {
        //카테고리 이동
        changeSurgeryCategories(change, row);
      } else {
        //시/수술 이동
        changeSurgeryItem(change, row);
      }
    },
    [changeSurgeryItem, changeSurgeryCategories]
  );

  useEffect(() => {
    callApi();
  }, [callApi]);

  const onClickDeleteCategories = useCallback(
    async (row) => {
      try {
        await services.crm.treatment.categories.delete_v2(row.id);
        modal
          .confirm({
            type: 'SUCCESS',
            msg: '삭제되었습니다.',
          })
          .then(() => {
            callApi();
          });
      } catch (e) {
        console.log(e.description);
        let errorMessage = e.description;
        modal.confirm({
          type: 'ERROR',
          msg: errorMessage,
        });
      }
    },
    [callApi]
  );

  const onClickDeleteCategoryItem = useCallback(
    async (row) => {
      try {
        let payload = row['@@items'];
        await services.crm.treatment.items.delete_v2(payload.id);

        modal
          .confirm({
            type: 'SUCCESS',
            msg: '삭제되었습니다.',
          })
          .then(() => {
            callApi();
          });
      } catch (e) {
        console.log(e.description);
        let errorMessage = e.description;
        modal.confirm({
          type: 'ERROR',
          msg: errorMessage,
        });
      }
    },
    [callApi]
  );

  const onOpenModalSurgery = useCallback(
    ({ row }) => {
      modal
        .custom({
          component: ModalSurgery,
          options: { surgery: row },
        })
        .then((submitted) => {
          if (submitted) {
            callApi();
          }
        });
    },
    [callApi]
  );

  useEffect(() => {
    callApi();
  }, [callApi]);

  return (
    <>
      <div className="page-navi">
        <span className="title">환경설정</span>
        <span className="title">수납코드 설정</span>
      </div>
      <div className="products organizations">
        <div className="route-top">
          <div className="title">시/수술</div>
          <div className="flex-row items-center">
            <HideHidden
              toggleHandler={setHideHidden}
              text="미사용항목 보지않기"
            />
            <button
              onClick={() => {
                onOpenModalSurgery({});
              }}
              className="btn btn-primary btn-sm flex-wrap btn-add"
            >
              {translate('ADD')}
            </button>
          </div>
        </div>
        <DataTableAlpha
          model={modelSurgery}
          data={hideHidden ? data.filter((row) => row.visible) : data}
          total={total}
          params={params}
          onAction={onAction}
          dragTypes={'Surgeries'}
          onDragAction={onDragAction}
          mergeColumn={[
            {
              name: null,
              treatmentCount: null,
              nextDay: null,
              nextMonth: null,
              price: null,
              vatIncluded: null,
            },
          ]}
          hasLine
          hasDeactivate
          bottomPositionInner
          hideBottom={true}
          hideHidden={hideHidden}
        />
      </div>
    </>
  );
};

export default observer(Surgeries);
