import React from 'react';
import { observer } from 'mobx-react';
import DataTableAlpha from 'components/data-table/DataTableAlpha';
import PropTypes from 'prop-types';
import models from 'models';

const ConsultingRequestUnResponsiveDataArea = ({ data }) => {
  return (
    <div className="unresponsive-data m-t-16">
      <span>이 외, 미응대 중인 건</span>
      <div className="scroll-table">
        <DataTableAlpha
          model={models.crm.responseConsultings}
          data={data}
          hideBottom={true}
        />
      </div>
    </div>
  );
};

ConsultingRequestUnResponsiveDataArea.propTypes = {
  data: PropTypes.object,
};

export default observer(ConsultingRequestUnResponsiveDataArea);
