import React, { useState, useCallback, useEffect } from 'react';
import { observer } from 'mobx-react';
import { objectToFormData } from 'object-to-formdata';
import Resizer from 'react-image-file-resizer';
import axios from 'axios';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/react';
import { translate } from 'filters';
import { useEvent } from 'hooks/useEvent';
import { useModal } from 'hooks/useModal';
import { useServices } from 'hooks/useServices';
import { useToast } from 'hooks/useToast';
import styled from 'styled-components';

const Input = styled.input`
  width: 100%;
  height: 34px;
  padding: 8px 20px;
  border-color: #d7e3f1;
  font-size: 12px;
  min-height: 10px;
  border-radius: 4px;
  color: #000;
  border: 1px solid #dee2ec;
`;

const InputTypeFile = styled.input`
  background: #fff;
  padding: 5px 20px;
  min-height: 10px;
  border-radius: 4px;
  color: #000;
  font-size: 14px;
  border: 1px solid #dee2ec;
  width: 100%;
  height: 34px;
`;

const ModalPenChartsCreate = ({ options, close }) => {
  const eventBus = useEvent();
  const modal = useModal();
  const services = useServices();
  const toast = useToast();
  const [inputValue, setInputValue] = useState('');
  const [imageFile, setImageFile] = useState([]);
  const [resizeImageArr, setResizeImageArr] = useState([]);
  const [thumbnailImageArr, setThumbnailImageArr] = useState([]);

  const [uploadSuccess, setUploadSuccess] = useState(0);
  const [uploadFail, setUploadFail] = useState(0);

  const [loadingType, setLoadingType] = useState(null);
  const [loading, setLoading] = useState(false);
  const [fileSelectStatus, setFileSelectStatus] = useState(false);

  // axios cancel token
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  // default params
  let params = {
    customerId: options.customer.id,
    parentId: options.currentFolder,
  };
  const config = {
    // onUploadProgress: function(progressEvent) {
    //     var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
    // }
    cancelToken: source.token,
  };

  useEffect(() => {
    let currentProgressIndex = uploadSuccess + uploadFail;
    if (currentProgressIndex == 0) return;
    if (imageFile.length > currentProgressIndex) {
      onConfirmFile(setImgParams(currentProgressIndex));
    } else {
      // setLoading(false);
      setTimeout(() => {
        let message = uploadFail
          ? (uploadSuccess ? '일부 ' : '') + '파일 업로드에 실패하였습니다.'
          : '파일을 생성하였습니다!';
        let confirmType = uploadFail ? 'ERROR' : 'SUCCESS';
        modal
          .confirm({
            type: confirmType,
            msg: message,
          })
          .then(() => {
            eventBus.emit('chartCountCallApi');
            options.setNeedUpdate({ type: 'CREATE' });
            close();
          });
      }, 1000);
    }
  }, [uploadSuccess, uploadFail]);

  const onClose = () => {
    if (loading && loadingType == 'upload') {
      modal
        .basic({
          body:
            '정말로 취소하시겠습니까? 취소 시, 업로드 중인 파일이 소실될 수 있습니다.',
          buttons: [
            {
              text: 'CANCEL',
              class: 'btn-default',
            },
            {
              text: 'CONFIRM',
              class: 'btn-primary',
            },
          ],
        })
        .then(() => {
          source.cancel('Operation canceled by the user.');
          options.setNeedUpdate({ type: 'CREATE' });
          close();
        });
    } else {
      close();
    }
  };

  const onConfirmFolder = async (params) => {
    try {
      let payload = { ...params };
      if (payload.parentId === null) {
        //parentId int로 설정되어 있어서, null인 경우 삭제하지 않고 보내면 Not a valid integer 에러 발생
        delete payload.parentId;
      }

      if (
        payload.customerId === null ||
        typeof payload.customerId !== 'number'
      ) {
        //customerId가 int로 설정되어 있어서, 삭제하지 않고 보내면 Not a valid integer 에러 발생
        delete payload.customerId;
      }

      await services.crm.penchart.create_v2(objectToFormData(payload));
      modal
        .confirm({
          type: 'SUCCESS',
          msg: '폴더를 생성하였습니다.',
        })
        .then(() => {
          options.setNeedUpdate({ type: 'CREATE' });
          close();
        });
    } catch (error) {
      modal.confirm({
        type: 'ERROR',
        msg: error.description,
      });
    }
  };

  const onConfirmFile = async (params) => {
    try {
      let payload = { ...params };
      if (payload.imageFile === null || payload.thumbnailFile === null) {
        return;
      }

      if (payload.parentId === null) {
        //parentId int로 설정되어 있어서, null인 경우 삭제하지 않고 보내면 Not a valid integer 에러 발생
        delete payload.parentId;
      }

      if (
        payload.customerId === null ||
        typeof payload.customerId !== 'number'
      ) {
        //customerId가 int로 설정되어 있어서, 삭제하지 않고 보내면 Not a valid integer 에러 발생
        delete payload.customerId;
      }

      await services.crm.penchart.create_v2(objectToFormData(payload));
      setUploadSuccess(uploadSuccess + 1);
      // close();
    } catch (error) {
      modal
        .confirm({
          type: 'ERROR',
          msg: error.description || 'ERROR',
        })
        .then(() => {
          setUploadFail(uploadFail + 1);
        });
    }
  };

  const onClickSave = useCallback(() => {
    if (options.type == 'CREATE_FOLDER') {
      if (inputValue.length == 0) {
        toast.error('폴더명을 입력해주세요.');
        return;
      }
      params = {
        ...params,
        name: inputValue,
        type: 'folder',
      };
      onConfirmFolder(params);
    }

    if (options.type !== 'CREATE_FOLDER') {
      if (imageFile.length == 0) {
        toast.error('파일을 선택해주세요.');
        return;
      }
      setLoadingType('upload');
      setLoading(true);
      setFileSelectStatus(true);
      onConfirmFile(setImgParams(0));
    }
  }, [
    services.crm.penchart,
    toast,
    close,
    inputValue,
    imageFile,
    resizeImageArr,
    thumbnailImageArr,
    options,
  ]);

  const setImgParams = (i) => {
    return {
      ...params,
      name: imageFile[i].name,
      imageFile: dataURLtoFile(resizeImageArr[i], imageFile[i].name),
      thumbnailFile: dataURLtoFile(
        thumbnailImageArr[i],
        'thumbnail_' + imageFile[i].name
      ),
      type: 'file',
    };
  };

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        2048,
        1000000,
        'JPEG',
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        'base64'
      );
    });

  const thumbnailFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        512,
        10000,
        'JPEG',
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        'base64'
      );
    });

  const onResizeFiles = async (files) => {
    setLoadingType('resize');
    setLoading(true);
    await Promise.all(
      files.map(async (v) => {
        return resizeFile(v);
      })
    ).then((arr) => {
      setResizeImageArr(arr);
    });

    await Promise.all(
      files.map(async (v) => {
        return thumbnailFile(v);
      })
    ).then((arr) => {
      setThumbnailImageArr(arr);
      setLoading(false);
    });
  };

  const dataURLtoFile = (dataurl, fileName) => {
    try {
      if (!dataurl) return;
      let arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], fileName, { type: mime });
    } catch (e) {
      Sentry.captureException(e);
      setUploadFail(uploadFail + 1);
      return null;
    }
  };

  return (
    <div className="modal-pen-chart">
      <div className="head flex-row flex-between items-start">
        <div className="title">
          {options.type === 'CREATE_FOLDER' ? '새 폴더' : '새 파일'}
        </div>
        <i onClick={() => onClose()} className="zmdi zmdi-close" />
      </div>
      <div className="body">
        <div className="form-control">
          {options.type === 'CREATE_FOLDER' ? (
            <Input
              type="text"
              value={inputValue}
              onChange={(e) => {
                setInputValue(e.target.value);
              }}
            />
          ) : (
            <InputTypeFile
              type="file"
              accept=".png, .jpg, .jpeg"
              multiple="multiple"
              disabled={fileSelectStatus}
              onChange={(e) => {
                if (e.target.files && e.target.files.length > 20) {
                  toast.error('한번에 20개를 초과하여 업로드 할 수 없습니다.');
                  e.target.value = null;
                  return;
                }
                setImageFile([...e.target.files]);
                onResizeFiles([...e.target.files]);
              }}
            />
          )}
        </div>

        {options.type == 'CREATE_FILE' && loading && (
          <div className="wrap_progress">
            {loadingType == 'resize' && <p>업로드 준비 중...</p>}
            {loadingType == 'upload' && (
              <>
                <div className="progress">
                  <div
                    className="bar"
                    style={{
                      width:
                        ((uploadSuccess + uploadFail) / imageFile.length) *
                          100 +
                        '%',
                    }}
                  />
                </div>
                <p>
                  파일 업로드 중. {uploadSuccess}개 업로드 완료, {uploadFail}개
                  업로드 실패
                </p>
              </>
            )}
          </div>
        )}
      </div>
      <div className="buttons">
        <div className="flex-row">
          <button
            onClick={() => onClose()}
            className="btn btn-default"
            style={{ display: 'block' }}
          >
            {translate('CANCEL')}
          </button>
          <button
            onClick={() => !loading && onClickSave()}
            className={`btn ${loading ? 'btn-default disable' : 'btn-primary'}`}
            style={{ display: 'block' }}
          >
            저장
          </button>
        </div>
      </div>
    </div>
  );
};

ModalPenChartsCreate.propTypes = {
  options: PropTypes.object,
  close: PropTypes.func,
};

export default observer(ModalPenChartsCreate);
