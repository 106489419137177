import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { useServices } from 'hooks/useServices';
import { useToast } from 'hooks/useToast';
import PropTypes from 'prop-types';

const BeforeAndAfterViewer = ({
  customer,
  beforeFileArr = [],
  afterFileArr = [],
}) => {
  const services = useServices();
  const toast = useToast();
  const [loading, setLoading] = useState(false);

  const setImgInfo = async () => {
    if (beforeFileArr.length == 0 && afterFileArr.length == 0) {
      toast.error('선택된 사진이 없습니다.');
      return;
    }

    setLoading(true);
    let beforeArr = await beforeFileArr.map(async (v) => {
      if (v.type == 'file') {
        return { ...v, type: 'before' };
      } else if (v.type == 'folder') {
        let fileList = await services.crm.penchart.all_v2({
          type: 'file',
          customerId: customer.id,
          parentId: v.id,
          limit: 1000,
        });
        let result = await Promise.all(
          fileList.data.map(async (fv) => {
            return { ...fv, type: 'before' };
          })
        );
        return result;
      }
    });

    let afterArr = await afterFileArr.map(async (v) => {
      if (v.type == 'file') {
        return { ...v, type: 'after' };
      } else if (v.type == 'folder') {
        let fileList = await services.crm.penchart.all_v2({
          type: 'file',
          customerId: customer.id,
          parentId: v.id,
          limit: 1000,
        });
        let result = await Promise.all(
          fileList.data.map(async (fv) => {
            return { ...fv, type: 'after' };
          })
        );
        return result;
      }
    });

    await Promise.allSettled([...beforeArr, ...afterArr]).then((results) => {
      let valueArr = [];
      results.forEach((result) => {
        if (result.status == 'fulfilled') {
          if (!result.value || result.value.length == 0) return;
          valueArr.push(result.value);
        }
      });
      setLoading(false);

      if (valueArr.length == 0) {
        toast.error('비교할 수 있는 사진이 없습니다. 재시도 해주세요.');
      } else {
        window.localStorage.setItem(
          'before_and_after_data',
          JSON.stringify(valueArr)
        );
        window.open(window.location.origin + '/before_and_after/index.html');
      }
    });
  };

  return (
    <>
      <button
        className="btn-show-viewer _small"
        onClick={() => setImgInfo()}
        disabled={loading}
      >
        전후사진비교
      </button>
    </>
  );
};

BeforeAndAfterViewer.propTypes = {
  customer: PropTypes.object,
  beforeFileArr: PropTypes.array,
  afterFileArr: PropTypes.array,
};

export default observer(BeforeAndAfterViewer);
