import React, { useState, useEffect, useRef } from 'react';
import { observer } from 'mobx-react';
import moment from 'moment';
import penchart from 'store/penchart';

const PenChartHoverCard = () => {
  const hoverCardRef = useRef();
  const [hoverOptions, setHoverOptions] = useState({
    left: penchart.hoverCardPosition.x,
    top: penchart.hoverCardPosition.y,
  });

  useEffect(() => {
    if (
      penchart.hoverCardPosition.x !== undefined ||
      penchart.hoverCardPosition.y !== undefined
    ) {
      let left = hoverCardRef?.current
        ? Math.min(
            window.innerWidth - hoverCardRef.current.clientWidth,
            penchart.hoverCardPosition.x
          )
        : penchart.hoverCardPosition.x;

      let top = hoverCardRef?.current
        ? Math.min(
            window.innerHeight - hoverCardRef.current.clientHeight,
            penchart.hoverCardPosition.y
          )
        : penchart.hoverCardPosition.y;

      setHoverOptions({
        x: left,
        y: top,
        show: true,
      });
    }
  }, [penchart.hoverCardPosition, hoverCardRef]);

  if (!penchart.hoverCardOptions.show || !penchart.hoveringPenChart)
    return null;

  return (
    <div
      ref={hoverCardRef}
      className="penchart-hover-card"
      style={{
        left: `${hoverOptions.x}px`,
        top: `${hoverOptions.y}px`,
        pointerEvents: `none`,
      }}
    >
      <div className="flex-row m-b-8">
        <div className="label">차트명</div>
        <div className="contents">{`${
          penchart.hoveringPenChart?.name || '-'
        }`}</div>
      </div>
      <div className="flex-row m-b-8">
        <div className="label">등록일</div>
        <div className="contents">{`${moment(
          penchart.hoveringPenChart?.createdAt
        ).format('YYYY-MM-DD HH:mm:ss')} ${
          penchart.hoveringPenChart.createdUserName !== undefined
            ? `(${penchart.hoveringPenChart.createdUserName})`
            : ''
        }`}</div>
      </div>
      <div className="flex-row m-b-8">
        <div className="label">마지막 수정일</div>
        <div className="contents">{`${moment(
          penchart.hoveringPenChart?.updatedAt
        ).format('YYYY-MM-DD HH:mm:ss')} ${
          penchart.hoveringPenChart.lastUpdatedUserName !== undefined
            ? `(${penchart.hoveringPenChart.lastUpdatedUserName})`
            : ''
        }`}</div>
      </div>
    </div>
  );
};

export default observer(PenChartHoverCard);
