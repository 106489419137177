import React, { useContext, useCallback, useRef, useEffect } from 'react';
import { observer } from 'mobx-react';
//import { AppointmentBoardState } from 'store/appointmentBoardState';
import { AppointmentBoardStateContext } from './AppointmentBoards';
import CalendarCells from './CalendarCells';
import { reaction } from 'mobx';

const DepartmentColumn = observer(({ index, style }) => {
  /**
   * @type {AppointmentBoardState }
   */
  const state = useContext(AppointmentBoardStateContext);
  const root = useRef(null);

  const { department, date } = state.dateCategoryDepartments[index];

  const updateSize = useCallback((size) => {
    state.setDepartmentSize(index, size);
  }, []);

  useEffect(() => {
    updateSize(state.getDepartmentViewSize(department, date));

    return reaction(
      () => {
        return state.getDepartmentViewSize(department, date);
      },
      (size) => {
        updateSize(size);
      }
    );
  }, []);

  return (
    <div style={style}>
      <div ref={root} className="department-column">
        <CalendarCells department={department} date={date} index={index} />
      </div>
    </div>
  );
});

export default DepartmentColumn;
