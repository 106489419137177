import React, { useEffect, useState, useCallback } from 'react';
import { observer } from 'mobx-react';
import { DataTable } from 'components/DataTable/DataTable';
import { $case, pluralize, translate } from 'filters';
import ListFilter from 'components/pages/crm/ListFilter';
import moment from 'moment';
import PropTypes from 'prop-types';
import services from 'services';
import Pagination from 'components/DataTable/Pagination';
import { LimitSetter } from 'components/DataTable/LimitSetter';
import styled from 'styled-components';
import { $qb } from 'scripts/querybuilder';
import { useEvent } from 'hooks/useEvent';
import { useModal } from 'hooks/useModal';
import { useToast } from 'hooks/useToast';
import { AxiosErrorCodes } from 'axiosErrorCodes';

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 20px;
`;

const DataGridView = ({
  api,
  renderFlag,
  model,
  modelName,
  orderBy,
  schema,
}) => {
  const eventBus = useEvent();
  const modal = useModal();
  const toast = useToast();
  const defaultParams = $qb()
    .limit(20)
    .orderBy(orderBy || 'id desc');
  const [data, setData] = useState([]);
  const [totalPage, setTotalPage] = useState(1);
  const [params, setParams] = useState(
    modelName === 'customer'
      ? defaultParams
      : modelName === 'operations'
      ? defaultParams
          .customParam(
            'scheduledStartAt',
            moment(new Date().setDate(new Date().getDate() - 30)).format(
              'YYYY-MM-DD'
            )
          )
          .customParam(
            'scheduledEndAt',
            moment(new Date()).format('YYYY-MM-DD')
          )
      : defaultParams
          .customParam(
            'startAt',
            moment(new Date().setDate(new Date().getDate() - 30)).format(
              'YYYY-MM-DD'
            )
          )
          .customParam('endAt', moment(new Date()).format('YYYY-MM-DD'))
  );
  const [downloadBtnDisabledFlag, setDownloadBtnDisabledFlag] = useState(false);
  const [page, setPage] = useState(
    params && params.queryParams && params.queryParams.page
      ? params.queryParams.page
      : 1
  );
  const [limit, setLimit] = useState(20);
  const [total, setTotal] = useState(0);

  const callApi = async () => {
    try {
      const resp = await api(params.build());
      if (!resp) return;
      setData(resp.data);
      setTotal(resp.pagination.total);
      setTotalPage(resp.pagination.total_page);
    } catch (e) {
      console.log(e.description);
    }
  };

  useEffect(() => {
    const hasPageParam =
      params && params.queryParams && params.queryParams.page;
    setPage(hasPageParam ? params.queryParams.page : 1);
    return () => {
      setPage(0);
    };
  }, [params]);

  const excelDownload = (resp) => {
    if (resp !== undefined) {
      //리스폰스 받고 로딩팝업 닫은 후 내려받기 버튼 활성화
      modal.close({
        type: 'LOADING',
      });
      setDownloadBtnDisabledFlag(false);

      const url = window.URL.createObjectURL(new Blob([resp]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `${translate($case.toConst(pluralize(modelName)))}.xlsx`
      );
      document.body.appendChild(link);
      link.click();
    }
  };

  const onParams = (p) => {
    setParams(p);
    callApi();
  };

  useEffect(() => {
    eventBus.on('callApi', callApi);
    callApi();

    return () => eventBus.off('callApi');
  }, [eventBus, renderFlag]);

  const onClickExcelDownload = useCallback(async () => {
    try {
      setDownloadBtnDisabledFlag(true);
      modal.loading({
        type: 'LOADING',
        model: modelName,
        msg:
          '다운로드중입니다. 데이터 양에 따라 \n 수 초 ~ 수 분이 걸릴 수 있습니다.',
      });

      let resp;
      if (modelName === 'appointment') {
        resp = await services.crm.crud.appointment.excel_download(
          params.build()
        );
        excelDownload(resp);
      } else if (
        modelName === 'customer' ||
        modelName === 'consulting' ||
        modelName === 'surgery' ||
        modelName === 'treatment'
      ) {
        resp = await services.crm[modelName].excel_download_v2(params.build());

        // 5000 건 이하인 경우
        if (resp.type.indexOf('sheet') !== -1) {
          excelDownload(resp);
        } else {
          const reader = new FileReader();
          reader.addEventListener('loadend', async (e) => {
            let res;
            res = await services.crm[modelName].excel_check_download_v2({
              fileKey: JSON.parse(e.target.result).fileKey,
            });

            let startInterval = setInterval(async () => {
              res = await services.crm[modelName].excel_check_download_v2({
                fileKey: JSON.parse(e.target.result).fileKey,
              });
              if (res.type.indexOf('sheet') !== -1) {
                excelDownload(res);
                clearInterval(startInterval);
              }
            }, 20000);
          });
          reader.readAsText(resp);
        }
      } else {
        resp = await services.crm[modelName].excel_download(params.build());
        excelDownload(resp);
      }
    } catch (e) {
      modal.close({
        type: 'LOADING',
      });
      setDownloadBtnDisabledFlag(false);

      let critical = true;
      if (e.code === AxiosErrorCodes.aborted) {
        toast.error('다운로드가 취소되었습니다.');
        critical = false;
      } else {
        toast.error(
          '다운로드에 실패하였습니다.\n다시 시도하거나, cs@unobeauty.kr로 문의해주세요.'
        );
      }

      if (critical) {
        console.log('throw');
        throw e;
      }
    }
  }, [modelName, params]);

  function onChangePage(p) {
    if (p >= 0) {
      setPage(p);
    }
    params.customParam('page', p);
    onParams && onParams(params);
  }

  function onChangeLimit(limit) {
    setLimit(limit);
    setPage(1);
    params.limit(limit);
    params.customParam('page', 1);
    onParams && onParams(params);
  }

  return (
    <div className={`${pluralize(modelName)} list inquiry`}>
      <div className="page-navi">
        <span className="title">{translate('SEARCH')}</span>
        <span className="title">
          {translate($case.toConst(pluralize(modelName)))} ({total || 0})
        </span>
      </div>
      <div className="route-top">
        <ListFilter
          model={model}
          modelName={modelName}
          params={params}
          setParams={setParams}
          onParams={onParams}
          defaultParams={defaultParams}
        />
        <div className="display-table">
          <div className="flex-row">
            {modelName !== 'operations' &&
            modelName !== 'nurses' &&
            modelName !== 'skins' ? (
              <button
                className="btn btn-basic _small m-r-8 btn-excel"
                disabled={downloadBtnDisabledFlag}
                onClick={onClickExcelDownload}
              >
                Excel 내려받기
              </button>
            ) : null}
          </div>
        </div>
      </div>

      <DataTable data={data} schema={schema} />
      <Footer>
        <Pagination total={totalPage} onChange={onChangePage} page={page} />
        <LimitSetter limit={limit} onLimit={onChangeLimit} />
      </Footer>
    </div>
  );
};

DataGridView.propTypes = {
  api: PropTypes.func,
  renderFlag: PropTypes.bool,
  model: PropTypes.object,
  modelName: PropTypes.string,
  onAction: PropTypes.func,
  orderBy: PropTypes.string,
  schema: PropTypes.object,
};

export default observer(DataGridView);
