import React, { createContext, useState } from 'react';

/**
 * @type {React.Context<CtiStore>}
 */
export const CtiStoreContext = createContext(null);
export const SetCtiStoreContext = createContext(null);

export default function withCtiStore(Comp) {
  return function WrappedComp(props) {
    const [ctiStore, setCtiStore] = useState(null);

    return (
      <CtiStoreContext.Provider value={ctiStore}>
        <SetCtiStoreContext.Provider
          value={{
            set: setCtiStore,
          }}
        >
          <Comp {...props} />
        </SetCtiStoreContext.Provider>
      </CtiStoreContext.Provider>
    );
  };
}
