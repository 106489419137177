import { currency } from 'filters';
import moment from 'moment';

/*
DataTableAlpha rowspan 스타일 (21.12.7 제거)
1. 
options: { rowspan: 2, }
2.
rowspan이 아닌 key에 rowspan: 2 속성 추가
{
  title: 'NO',
  column: 'no',
  rowspan: 2,
  class: 'flex-center no sticky',
}
3.
rowspan인 경우 colspan: 5 속성 추가
{
  title: '수납',
  column: '',
  colspan: 5,
  class: 'th-colspan',
}
4.
colspan인 경우 rowSpan: true 속성 추가
{
  title: '청구액',
  column: 'requestAmount',
  rowSpan: true,
}

테이블 좌측 sticky 스타일 
1. class: 'sticky', 속성 추가
*/

const contextItems = [
  { title: '수정' },
  { title: '삭제' },
  { title: '미수수납' },
  { title: '환불' },
];

export default {
  options: {
    hasMergeCell: true,
    groupTitles: [
      'NO',
      'STATUS',
      'FIRST_PAID_AT',
      '담당자',
      '청구액',
      '수납액',
      '미수액',
      '환불액',
      '현금영수증',
      '메모',
      'UPDATE_AT',
      '상담사',
    ],
    explodeColumn: 'paymentTotalItems',
  },
  keys: [
    {
      title: 'NO',
      column: 'no',
      class: 'flex-center no',
      noorder: true,
      useContext: true,
      contextTrigger: 'doubleClick',
      contextItems,
    },
    {
      title: 'STATUS',
      noorder: true,
      tdDivStyle: () => {
        return { width: '40px' };
      },
      customStyle: () => {
        return { width: '40px' };
      },
      filter: (o) => {
        // status: paid(완납) / unpaid(미수)
        // refundStats : null(기존 데이터) / none(환불 없음) / part_refund(부분 환불) / full_refund(전체 환불)
        let class_status;
        let status = '완납';
        if (o.status === 'unpaid') {
          class_status = 'unpaid';
          status = '미수';
        } else {
          //paid인 경우 refundStatus로 구분
          if (o.refundStatus === null || o.refundStatus === 'none') {
            class_status = 'paid';
            status = o.status === 'paid' ? '완납' : '미수';
          }
          if (o.refundStatus === 'part_refund') {
            status = '부분환불';
            class_status = 'part_refund';
          }
          if (o.refundStatus === 'full_refund') {
            status = '전체환불';
            class_status = 'full_refund';
          }
        }
        return `<div class="text-center">
                <div class="payment-contents-status ${class_status}">${status}</div>
            </div>`;
      },
      useContext: true,
      contextTrigger: 'doubleClick',
      contextItems,
    },
    {
      title: 'FIRST_PAID_AT',
      column: 'firstPaidAt',
      tdDivStyle: () => {
        return { width: '78px' };
      },
      customStyle: () => {
        return { width: '78px' };
      },
      filter: (o) =>
        `<div class="flex-row">
        <div class="text-underline">
          ${moment(o.firstPaidAt).format('YYYY.MM.DD')}
        </div>
        <div>
          <span 
            class="not-context flex-wrap ${
              o.subTableToggle ? 'ico-arrow-down' : 'ico-arrow-up'
            }">
          </span>
        </div>
      </div>`,
      nosearch: true,
      useContext: true,
      contextTrigger: 'click',
      contextItems,
    },
    {
      title: '카테고리',
      column: '@@paymentTotalItems__categoryName',
      noorder: true,
      type: 'list_mini',
      tdDivStyle: () => {
        return { width: '90px' };
      },
      customStyle: () => {
        return { width: '90px' };
      },
      filter: (o) => {
        return `<div class="flex-wrap"
      style="width: 100%;">${
        o['@@paymentTotalItems']['categoryName'] || ''
      }</div>`;
      },
      useContext: true,
      contextTrigger: 'doubleClick',
      contextItems,
    },
    {
      title: '시/수술명(제품명)',
      column: '@@paymentTotalItems__name',
      noorder: true,
      tdDivStyle: () => {
        return { width: '100px', justifyContent: 'left' };
      },
      customStyle: () => {
        return { width: '100px' };
      },
      filter: (o) => {
        return `<div
      style="width: 100%; 
      white-space: break-spaces;">${o['@@paymentTotalItems']['name'] || ''}${
          o['@@paymentTotalItems']['isFree']
            ? '<span class="flex-wrap ico-service"></span>'
            : ''
        }</div>`;
      },
      useContext: true,
      contextTrigger: 'doubleClick',
      contextItems,
    },
    {
      title: '회차 (개수)',
      noorder: true,
      column: '@@paymentTotalItmes',
      class: 'flex-center count',
      titleStyle: () => {
        return {
          whiteSpace: 'break-spaces',
          display: 'inline-block',
          textAlign: 'center',
        };
      },
      tdDivStyle: () => {
        return { width: '32px' };
      },
      customStyle: () => {
        return { width: '32px' };
      },
      filter: (o) => {
        if (o['@@paymentTotalItems']['quantity'] === undefined) {
          //시/수술 회차
          return o['@@paymentTotalItems']['treatmentCount'];
        } else {
          //개수
          return o['@@paymentTotalItems']['quantity'];
        }
      },
      useContext: true,
      contextTrigger: 'doubleClick',
      contextItems,
    },
    {
      title: 'VAT 미포함금액',
      column: '@@paymentTotalItems__price',
      noorder: true,
      class: 'white-space',
      titleStyle: () => {
        return {
          whiteSpace: 'break-spaces',
          display: 'inline-block',
        };
      },
      tdDivStyle: () => {
        return { width: '68px' };
      },
      customStyle: () => {
        return { width: '68px' };
      },
      filter: (o) => {
        return `${currency(o['@@paymentTotalItems']['price'] || 0)}`;
      },
      useContext: true,
      contextTrigger: 'doubleClick',
      contextItems,
    },
    {
      title: 'VAT 포함금액',
      column: '@@paymentTotalItems__priceVatInclude',
      noorder: true,
      titleStyle: () => {
        return {
          whiteSpace: 'break-spaces',
          display: 'inline-block',
        };
      },
      class: 'white-space',
      tdDivStyle: () => {
        return { width: '68px' };
      },
      customStyle: () => {
        return { width: '68px' };
      },
      filter: (o) => {
        return `${currency(o['@@paymentTotalItems']['priceVatInclude'] || 0)}`;
      },
      useContext: true,
      contextTrigger: 'doubleClick',
      contextItems,
    },
    {
      title: '할인',
      column: '@@paymentTotalItems__discountAmount',
      noorder: true,
      class: 'discount not-padding',
      tdDivStyle: () => {
        return { width: '83px' };
      },
      customStyle: () => {
        return { width: '83px' };
      },
      filter: (o) => {
        return `<div style="width: 100%;">
            <div style="padding: 4px 6px;
              white-space: break-spaces;">${currency(
                o['@@paymentTotalItems']['discountAmount'] || 0
              )}</div>
            ${
              o['@@paymentTotalItems']['discountAmount'] > 0
                ? `<div class="flex-row red-box btn-discount-reason">
            <div class="btn-discount-reason"
              style="color: #f7685b;">할인사유</div>
            <span 
              class="flex-wrap ico-arrow-red btn-discount-reason">
            </span>
            </div>`
                : ''
            }
            </div>`;
      },
      useContext: true,
      contextTrigger: 'doubleClick',
      contextItems,
    },
    {
      title: '청구액',
      noorder: true,
      class: 'white-space',
      tdDivStyle: () => {
        return { width: '71px' };
      },
      customStyle: () => {
        return { width: '71px' };
      },
      filter: (o) => `${currency(o.requestAmount || 0)}`,
      useContext: true,
      contextTrigger: 'doubleClick',
      contextItems,
    },
    {
      title: '수납액',
      noorder: true,
      class: 'white-space',
      tdDivStyle: () => {
        return { width: '71px' };
      },
      customStyle: () => {
        return { color: '#0060ff', width: '71px' };
      },
      filter: (o) => `${currency(o.paidAmount || 0)}`,
      useContext: true,
      contextTrigger: 'doubleClick',
      contextItems,
    },
    {
      title: '환불액',
      noorder: true,
      class: 'white-space',
      tdDivStyle: () => {
        return { width: '71px' };
      },
      customStyle: (o) => {
        if (o.refundAmount !== 0) {
          return { color: '#f7685b', width: '71px' };
        }
      },
      filter: (o) => `${currency(o.refundAmount || 0)}`,
      useContext: true,
      contextTrigger: 'doubleClick',
      contextItems,
    },
    {
      title: '미수액',
      noorder: true,
      class: 'white-space',
      tdDivStyle: () => {
        return { width: '71px' };
      },
      customStyle: () => {
        return { width: '71px' };
      },
      filter: (o) => `${currency(o.unpaidAmount || 0)}`,
      useContext: true,
      contextTrigger: 'doubleClick',
      contextItems,
    },
    {
      title: '메모',
      column: 'memo',
      noorder: true,
      type: 'memo',
      thStyle: () => {
        return { width: '185px' };
      },
      useContext: true,
      contextTrigger: 'doubleClick',
      contextItems,
    },
    {
      title: '상담사',
      column: 'counselor__name',
      noorder: true,
      useContext: true,
      contextTrigger: 'doubleClick',
      contextItems,
    },
    {
      title: '담당자',
      column: 'createdName',
      noorder: true,
      useContext: true,
      contextTrigger: 'doubleClick',
      contextItems,
    },
  ],
};
