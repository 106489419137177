import { makeAutoObservable } from 'mobx';
import React, { createContext, useContext, useMemo } from 'react';
import { useAuth } from 'store/auth';

export class ClinicCheck {
  constructor(auth) {
    this.auth = auth;

    makeAutoObservable(this);
  }

  get isClinicStantopCounselor() {
    // (스탠탑 병원에서만) 로그인한 계정의 직무가 상담사를 포함하는지 체크
    return (
      this.auth.me.clinicId == process.env.REACT_APP_STANTOP_CLINIC_ID &&
      this.auth.me.duty.includes('상담사')
    );
  }
}

const ClinicCheckContext = createContext();

// hooks
export const useClinicCheck = () => {
  return useContext(ClinicCheckContext);
};

// provider
export const ClinicCheckProvider = (props) => {
  const auth = useAuth();
  const store = useMemo(() => new ClinicCheck(auth), []);
  return <ClinicCheckContext.Provider value={store} {...props} />;
};
