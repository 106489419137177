export default {
  keys: [
    {
      title: '카테고리',
      column: 'categoryName',
      filter: (o) => {
        let categoryName =
          o.category === undefined
            ? o.categoryName || ''
            : o.category.name || '';
        return `<div style="position: relative; width: 125px; text-align: center;">
                <div class="flex-wrap"
                    style="max-width: 125px; white-space: break-spaces; display: inline-block;">${
                      categoryName || ''
                    }</div></div>`;
      },
    },
    {
      title: '시/수술명',
      column: 'name',
      filter: (o) => {
        return `<div style="position: relative; width: 240px; text-align: center;">
            <div class="flex-wrap"
                style="max-width: 240px; white-space: break-spaces; display: inline-block;">${
                  o['name'] || ''
                }${
          o['isFree'] ? '<div class="flex-wrap ico-service"></div>' : ''
        }</div>
            </div>`;
      },
    },
    {
      title: '시/수술횟수',
      column: 'treatmentCount',
      filter: (o) => {
        return `${o.treatmentCount}회`;
      },
    },
    {
      title: '진행한 회차',
      column: 'useCount',
      filter: (o) => {
        if (o.category === undefined) {
          return `${o.useCount}회`;
        } else {
          //새로 추가한 시수술이기때문에 useCount가 없음
          return '0회';
        }
      },
    },
    {
      title: '남은 회차',
      column: 'remainingCount',
      filter: (o) => {
        if (o.category === undefined) {
          return `${o.remainingCount}회`;
        } else {
          //새로 추가한 시수술이기때문에 진행회차=남은회차
          return `${o.treatmentCount}회`;
        }
      },
    },
  ],
};
