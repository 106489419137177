import React, { useEffect, useState, useCallback } from 'react';
import DataTableAlpha from 'components/data-table/DataTableAlpha';
import PropTypes from 'prop-types';
import modelMessageTarget from 'models/message-target';
import { observer } from 'mobx-react';
import { $qb } from 'scripts/querybuilder';
import { useServices } from 'hooks/useServices';
import { useToast } from 'hooks/useToast';
import { translate } from 'filters';

const TargetTable = ({
  searchHistoryInit,
  smsSearchHistory,
  status,
  setStatus,
  dataObj: targetDataObj,
  setDataObj,
  checkedCount,
  setCheckedCount,
  smsType,
}) => {
  const services = useServices();
  const toast = useToast();
  const STATUS_TYPE = {
    SET: 'set',
    IDLE: 'idle',
    APPLY: 'apply',
  };

  const defaultParams = $qb().limit(20).page(1);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [total, setTotal] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [checkedCountByServer, setCheckedCountByServer] = useState(0);
  const [params, setParams] = useState(defaultParams);
  const excludePhoneNumbers = targetDataObj.excludePhoneNumbers;

  useEffect(() => {
    //문자 전송 후 검색 조건 초기화
    if (searchHistoryInit === true) {
      setData(null);
      setTotal(null);
      setCheckedCountByServer(0);
      setParams(defaultParams);
    }
  }, [searchHistoryInit, defaultParams]);

  const callApi = useCallback(async () => {
    setLoading(true);
    try {
      const searchHistoryId = smsSearchHistory.id;
      const requestParams = {
        ...params.build(),
        id: searchHistoryId,
      };
      const resp = await services.crm.notification.smsSearchResult.detail(
        requestParams
      );
      const pageData = resp.data.map((row) => {
        const { smsEnabled, phoneNumber, eventSmsEnabled } = row;
        /*
          [!유의] smsEnabled 응답값은 [ true, false, undefined ] 3가지 타입          
        */
        const _smsEnabled =
          smsType === 'normal'
            ? smsEnabled === undefined || smsEnabled === true
            : eventSmsEnabled === undefined || eventSmsEnabled === true;
        const checked =
          _smsEnabled && excludePhoneNumbers.indexOf(phoneNumber) === -1;
        return {
          ...row,
          checked: checked,
          smsType,
        };
      });

      setData(pageData);
      setTotal(resp.totalCount);
      // smsType에 따라 문자수신/마케팅 문자수신 고객 카운트 변경
      let checkCount =
        smsType === 'normal' ? resp.checkedCount : resp.checkedEventCount;

      //문자 타입에 따라 excludeNumbers 체크
      let excludeItems;
      if (smsType === 'normal') {
        //일반문자 수신 거부 고객들을 excludeNumbers에
        excludeItems = resp.data.filter((v) => v.smsEnabled === false);
      } else if (smsType === 'event') {
        //마케팅문자 수신 거부 고객들을 excludeNumbers에
        excludeItems = resp.data.filter((v) => v.eventSmsEnabled === false);
      }

      const prevExcludeNumbers = targetDataObj.excludePhoneNumbers;
      const _excludeNumbers = [
        ...prevExcludeNumbers,
        ...excludeItems.map((v) => v.phoneNumber),
      ];

      setDataObj({
        ...targetDataObj,
        excludePhoneNumbers: [..._excludeNumbers],
        checkedCount: checkCount,
      });

      setCheckedCountByServer(checkCount);
      setLoading(false);
    } catch (error) {
      toast.error(error.description);
      setLoading(false);
    }
  }, [
    services.crm.notification.smsSearchResult,
    toast,
    excludePhoneNumbers,
    params,
    smsSearchHistory.id,
    smsType,
    targetDataObj,
    setDataObj,
  ]);

  const onParams = (p) => {
    setParams(p);
    callApi();
  };

  useEffect(() => {
    setCheckedCount(total - excludePhoneNumbers.length);
  }, [excludePhoneNumbers, setCheckedCount, total]);

  const onAction = (obj) => {
    const { row, event } = obj; // , rowIdx
    if (event.target.dataset.type === 'check') {
      const { checked, phoneNumber } = row;
      if (checked) {
        setDataObj((dataObj) => {
          const prevExcludeNumbers = dataObj.excludePhoneNumbers;
          const _excludeNumbers = [...prevExcludeNumbers, phoneNumber];
          return {
            ...dataObj,
            excludePhoneNumbers: [...new Set(_excludeNumbers)],
          };
        });
      } else {
        setDataObj((dataObj) => {
          const prevExcludeNumbers = dataObj.excludePhoneNumbers;
          return {
            ...dataObj,
            excludePhoneNumbers: prevExcludeNumbers.filter(
              (number) => number !== phoneNumber
            ),
          };
        });
      }
      setData((prevData) => {
        return prevData.map((prevRow) => {
          // uniq 속성은 phoneNumber. id의 경우 직접입력할 경우 없음
          if (prevRow.phoneNumber === phoneNumber) {
            return {
              ...prevRow,
              checked: !prevRow.checked,
            };
          }
          return prevRow;
        });
      });
    }
  };

  useEffect(() => {
    const searchHistoryId = smsSearchHistory.id;
    if (status === STATUS_TYPE.APPLY && searchHistoryId) {
      setParams(defaultParams);
      setStatus(STATUS_TYPE.SET);
    } else if (status === STATUS_TYPE.SET && searchHistoryId) {
      callApi();
      setStatus(STATUS_TYPE.IDLE);
    }
  }, [
    STATUS_TYPE.APPLY,
    STATUS_TYPE.IDLE,
    STATUS_TYPE.SET,
    callApi,
    defaultParams,
    setStatus,
    smsSearchHistory.id,
    status,
  ]);

  return (
    <div>
      <div>
        <div className="set-cnt">
          <span>
            {`${translate('SEARCH_TARGET_RESULTS')} `}
            <b>{`${
              total === 1
                ? translate('ONE_PERSON')
                : translate('N_PERSONS').replace('%s', total ? total : 0)
            }`}</b>
          </span>
          <span className="m-l-8">
            {`${translate('SELECTED_TARGETS')} `}
            <b>{`${
              checkedCount === 1
                ? translate('ONE_PERSON')
                : translate('N_PERSONS').replace(
                    '%s',
                    checkedCount ? checkedCount : 0
                  )
            }`}</b>
          </span>
        </div>
        <DataTableAlpha
          className="header-style-classic"
          model={modelMessageTarget}
          data={data}
          total={total}
          params={params}
          onParams={onParams}
          onAction={onAction}
          loading={loading}
          hideBottom={false}
        />
      </div>
    </div>
  );
};

TargetTable.propTypes = {
  searchHistoryInit: PropTypes.bool,
  smsSearchHistory: PropTypes.object,
  status: PropTypes.string,
  setStatus: PropTypes.func,
  dataObj: PropTypes.shape({
    targetCount: PropTypes.number,
    smsSearchHistory: PropTypes.shape({
      id: PropTypes.number,
    }),
    excludePhoneNumbers: PropTypes.array,
  }),
  setDataObj: PropTypes.func,
  checkedCount: PropTypes.number,
  setCheckedCount: PropTypes.func,
  smsType: PropTypes.string,
};

export default observer(TargetTable);
