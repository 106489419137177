import React, { useCallback, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import { copy, numArray, fillTimeframe, translate } from 'filters';
import hooks from 'hooks';
import Select from 'react-select';
import DataTableAlpha from 'components/data-table/DataTableAlpha';
import QuillTextField from 'components/quill/QuillTextField2';
import { toHexColorHtml } from 'components/quill/quillUtil';
import ModalAppointmentCompleteAutoSms from 'components/modals/ModalAppointmentCompleteAutoSms';
import ModalAppointmentUpdateCompleteAutoSms from 'components/modals/ModalAppointmentUpdateCompleteAutoSms';
import ModalAppointmentUpdateCompleteAutoSmsAlert from 'components/modals/ModalAppointmentUpdateCompleteAutoSmsAlert';
import moment from 'moment';
import PropTypes from 'prop-types';
import ModalSurgery from 'components/modals/ModalSurgery';
import useCustomerChart from 'hooks/useCustomerChart';
import modelAppointmentTab from 'models/appointment-tab';
import models from 'models';
import { $qb } from 'scripts/querybuilder';
import { useAuth } from 'store/auth';
import { useData } from 'hooks/useData';
import { useEvent } from 'hooks/useEvent';
import { useModal } from 'hooks/useModal';
import { useServices } from 'hooks/useServices';
import { useToast } from 'hooks/useToast';
import { useMessages } from 'hooks/useMessages';
import styled from 'styled-components';
import { RadioInput } from 'components/common/RadioInput';

const SendSmsRadioInput = styled(RadioInput)`
  margin-right: 8px;
`;

const CustomerAppointments = ({
  customer,
  openFlag,
  closeFlag,
  closeModal,
  consultingRequest,
  saveAppointment,
  recallMemo,
  calledPage,
}) => {
  const history = useHistory();
  const auth = useAuth();
  const data = useData();
  const eventBus = useEvent();
  const modal = useModal();
  const services = useServices();
  const toast = useToast();
  const messages = useMessages();
  const tabName = 'appointment';
  const [loadingBtnDisabledFlag, setLoadingBtnDisabledFlag] = useState(false);
  const defaultParams = $qb()
    .limit(10)
    .customParam('customerId', customer.id)
    .orderBy('scheduledAt desc');
  const [params, setParams] = useState(defaultParams);
  const [appointments, setAppointments] = useState([]);
  const [obj, setObj] = useState({});
  const [total, setTotal] = useState(0);

  const [paymentData, setPaymentData] = useState([]);
  const [surgeryTreatmentItems, setSurgeryTreatmentItems] = useState([]);
  const [processAppointmentId] = useState(
    (openFlag || {}).appointmentId || null
  );
  //componentFlag appointments('신규예약등록')
  const [componentFlag, setComponentFlag] = useState(null);

  const [facialist, setFaicalist] = useState([]);
  const [counselors, setCounselors] = useState([]);
  const [doctors, setDoctors] = useState([]);
  const [createdList, setCreatedList] = useState([]); //접수자
  const [acquisitionChannel, setAcquisitionChannel] = useState([]);
  const [treatmentItemCategories, setTreatmentItemCategories] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [itemsList, setItemsList] = useState([{}]); //관심 시/수술 카테고리 배열
  const [estimatedMinutesList, setEstimatedMinutesList] = useState([]);
  const [memoBoilerplateList, setMemoBoilerplateList] = useState([]);

  //자동문자 발송 라디오버튼
  const [sendAutoSmsCheck, setSendAutoSmsCheck] = useState(true);
  //자동문자 선택
  const [smsAutoNotifications, setSmsAutoNotifications] = useState([]);
  const [radioValue, setRadioValue] = useState(false);
  const [counselorFlag, setCounselorFlag] = useState(false);
  const [doctorFlag, setDoctorFlag] = useState(false);
  const [acquisitionChannelFlag, setAcquisitionChannelFlag] = useState(false);

  //초기 예약건 정보. openFlag의 예약건이나 수정 눌렀을 때의 예약건
  const [selectAppointment, setSelectAppointment] = useState(
    openFlag?.appointment
  );

  const [radioItemsType] = useState([
    { value: true, label: '신환예약' },
    { value: false, label: '구환예약' },
  ]);

  // 방문시간 옵션 리스트
  const [startHour, setStartHour] = useState('');
  const [startHourOptions, setStartHourOptions] = useState([]);
  // 부서, 예약일 변경 object
  const [timeSetObj, setTimeSetObj] = useState({
    departmentId: null,
    scheduledAt: null,
  });

  const selectCustomStyles = {
    container: () => ({
      marginRight: '10px',
      width: '200px',
      outlineWidth: '0px',
    }),
    control: (base) => ({
      ...base,
      padding: '8px 0px 8px 20px',
      boxShadow: '0px',
      minHeight: '34px',
      height: '34px',
    }),
    menu: (base) => ({
      ...base,
      zIndex: 2,
    }),
    valueContainer: (base) => ({
      ...base,
      height: '18px',
      padding: '0px',
    }),
    input: (base) => ({
      ...base,
      margin: '0px',
      border: '0px',
      padding: '0px !important',
      height: '18px',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    indicatorsContainer: (base) => ({
      ...base,
      height: '18px',
      width: '18px',
    }),
    singleValue: (base) => ({
      ...base,
      margin: '0px',
    }),
    placeholder: (base) => ({
      ...base,
      color: '-internal-light-dark(black, white)',
      margin: '0px 0px 0px 0px',
      whiteSpace: 'nowrap',
    }),
  };

  const departmentCategoriesCallApi = useCallback(async () => {
    try {
      let params = { limit: 300 };
      const resp = await services.crm.crud.departmentCategory.all(params);
      if (!resp) return;
      // eslint-disable-next-line array-callback-return
      let changeResp = resp.data.filter((category) => {
        if (category.departments.length > 0 && category.visible) {
          category.departments.forEach((item) => {
            item.category = { id: category.id, name: category.name };
            return item;
          });
          return category;
        }
      });

      let department = [];
      changeResp.forEach((category) => {
        department = department.concat(category.departments || []);
      });

      setDepartments(department);
    } catch (e) {
      console.log(e.description);
    }
  }, [services.crm.crud.departmentCategory]);

  useEffect(() => {
    departmentCategoriesCallApi();
  }, [departmentCategoriesCallApi]);

  const treatmentItemCategoriesCallApi = useCallback(async () => {
    try {
      let params = { limit: 10000, visible: true };
      const resp = await services.crm.treatment.categories.items_categories_v2(
        params
      );
      if (!resp) return;
      setTreatmentItemCategories(resp.data);
    } catch (e) {
      console.log(e.description);
    }
  }, [services.crm.treatment.categories]);

  useEffect(() => {
    treatmentItemCategoriesCallApi();
  }, [treatmentItemCategoriesCallApi]);

  const acquisitionChannelCallApi = useCallback(async () => {
    let params = { limit: 300, visible: true };
    const resp = await services.crm.customer.acquisitionChannel.all(params);
    if (!resp) return;

    setAcquisitionChannel(resp.data);
  }, [services.crm.customer.acquisitionChannel]);

  useEffect(() => {
    acquisitionChannelCallApi();
  }, [acquisitionChannelCallApi]);

  const createdListCallApi = useCallback(async () => {
    let params = { userStatus: 'active', limit: 300 };
    const resp = await services.crm.user.duty(params);
    if (!resp) return;

    setCreatedList(resp.data);
  }, [services.crm.user]);

  useEffect(() => {
    createdListCallApi();
  }, [createdListCallApi]);

  const facialistCallApi = useCallback(async () => {
    let params = {
      duty: '피부관리사,간호사,간호조무사',
      userStatus: 'active',
      limit: 300,
    };
    const resp = await services.crm.user.duty(params);
    if (!resp) return;

    setFaicalist(resp.data);
  }, [services.crm.user]);

  useEffect(() => {
    facialistCallApi();
  }, [facialistCallApi]);

  const counselorCallApi = useCallback(async () => {
    let params = { duty: '상담사', userStatus: 'active', limit: 300 };
    const resp = await services.crm.user.duty(params);
    if (!resp) return;

    setCounselors(resp.data);
  }, [services.crm.user]);

  useEffect(() => {
    counselorCallApi();
  }, [counselorCallApi]);

  const doctorCallApi = useCallback(async () => {
    let params = { duty: '의사', userStatus: 'active', limit: 300 };
    const resp = await services.crm.user.duty(params);
    if (!resp) return;

    setDoctors(resp.data);
  }, [services.crm.user]);

  useEffect(() => {
    doctorCallApi();
  }, [doctorCallApi]);

  const onChangeSmsAutoNotification = useCallback(async () => {
    let notifications = await messages.loadDepartmentSmsAutoNotifications(
      obj?.department?.id
    );

    setSmsAutoNotifications(notifications);

    if (obj.id !== undefined) {
      //id 존재.. 수정시에는 설정된 값 여부로 결정
      setSendAutoSmsCheck(obj.smsSent);
    }
  }, [obj.department]);

  useEffect(() => {
    if (obj.department != undefined) {
      onChangeSmsAutoNotification();
    }
  }, [obj.department]);

  const loadAppointments = useCallback(async () => {
    try {
      if (customer.id) {
        const resp = await services.crm.crud.appointment.all(params.build());
        if (!resp) return;
        setAppointments(resp.data);
        setTotal(resp.total);
      }
    } catch (e) {
      console.log(e.description);
    }
  }, [services.crm.crud.appointment, customer.id, params]);

  const init = useCallback(async () => {
    loadAppointments();
    paymentCallApi();

    if (openFlag !== undefined) {
      if (openFlag.appointment !== undefined && openFlag.appointment !== null) {
        onClickCreateAppointments();
      }
    }

    if (!data.departmentCategories) data.actions.loadDepartmentCategories();
  }, [data.actions, loadAppointments, openFlag]);

  const stateInit = useCallback(async () => {
    setComponentFlag(null);
    setObj({});
    setItemsList([{}]);
    setLoadingBtnDisabledFlag(false);
    setRadioValue(false);
    setDoctorFlag(false);
    setCounselorFlag(false);
    setAcquisitionChannelFlag(false);
    setSurgeryTreatmentItems([]);
    setPaymentData((data) =>
      data.map((v) => {
        return {
          ...v,
          $$checked: false,
        };
      })
    );
  }, []);

  const actionInit = useCallback(async () => {
    init();
    stateInit();
  }, [init, stateInit]);

  const onDeleteAppointment = useCallback(
    async (id) => {
      try {
        await services.crm.crud.appointment.delete(id);
        modal
          .confirm({
            type: 'SUCCESS',
            msg: '삭제되었습니다.',
          })
          .then(() => {
            eventBus.emit('chartCountCallApi');
            actionInit();
          });
      } catch (e) {
        console.log(e.description);
        modal.confirm({
          type: 'ERROR',
          msg: e.description,
        });
      }
    },
    [eventBus, modal, services.crm.crud.appointment, actionInit]
  );

  const onOpenAppointmentAutoSms = (row, rowData) => {
    modal
      .custom({
        component: ModalAppointmentCompleteAutoSms,
        options: {
          appointmentId: (row || {}).id,
          rowData: rowData,
        },
      })
      .then(() => {
        loadAppointments();
      });
  };

  const onOpenDeleteAppointment = useCallback(
    async (appointment) => {
      modal
        .basic({
          body: '정말로 삭제하시겠습니까?',
          buttons: [
            {
              text: 'CANCEL',
              class: 'btn-default',
            },
            {
              text: 'CONFIRM',
              class: 'btn-primary',
            },
          ],
        })
        .then((idx) => {
          if (idx === 1) {
            onDeleteAppointment(appointment.id);
          }
        });
    },
    [modal, onDeleteAppointment]
  );

  const infoFlagSetting = (name, row) => {
    //고객의 담당의사가 있는 경우
    if (customer && customer[name]) {
      // 예약건의 의사가 있는 경우
      if (row && row[name] && row[name].id) {
        // 고객의 담당의사와 예약건의 의사가 다른경우
        if (row[name].id !== customer[name].id) {
          if (name === 'doctor') setDoctorFlag(true);
          if (name === 'counselor') setCounselorFlag(true);
          if (name === 'acquisitionChannel') setAcquisitionChannelFlag(true);
        } else {
          if (name === 'doctor') setDoctorFlag(false);
          if (name === 'counselor') setCounselorFlag(false);
          if (name === 'acquisitionChannel') setAcquisitionChannelFlag(false);
        }
      } else {
        if (name === 'doctor') setDoctorFlag(true);
        if (name === 'counselor') setCounselorFlag(true);
        if (name === 'acquisitionChannel') setAcquisitionChannelFlag(true);
      }
    }
    //고객의 담당의사가 없는 경우
    else {
      // 예약건의 의사가 있는 경우
      if (row && row[name] && row[name].id) {
        if (name === 'doctor') setDoctorFlag(true);
        if (name === 'counselor') setCounselorFlag(true);
        if (name === 'acquisitionChannel') setAcquisitionChannelFlag(true);
      } else {
        if (name === 'doctor') setDoctorFlag(false);
        if (name === 'counselor') setCounselorFlag(false);
        if (name === 'acquisitionChannel') setAcquisitionChannelFlag(false);
      }
    }
  };

  const onAction = (obj) => {
    const event = obj.event;
    const row = obj.row;
    const classList = event.target.classList;
    const model = obj.key;

    if (
      (obj.eventType === 'doubleClick' && model?.title !== 'STATUS') ||
      classList.contains('btn-primary')
    ) {
      //수정
      //메모 상용구 api 호출
      memoBoilerplateCallApi();

      setTimeSetObj({
        departmentId: row.department.id,
        scheduledAt: row.scheduledAt,
      });
      setSendAutoSmsCheck(row.smsSent);
      setSelectAppointment(copy(row));
      setObj(copy(row));
      setStartHour(row.startHour);
      //setObj({ ...obj.row, isNewCustomer: customer.isNewCustomer });
      setItemsList(copy(row).items.filter((v) => v.category !== null) || [{}]);
      setComponentFlag('appointments');

      infoFlagSetting('doctor', row);
      infoFlagSetting('counselor', row);
      infoFlagSetting('acquisitionChannel', row);

      // 진행가능한 시/수술 호출
      paymentCallApi();

      return;
    }

    if (classList.contains('btn-danger')) {
      onOpenDeleteAppointment(row);
      return;
    }

    if (model.title === '예약완료 자동문자 전송설정') {
      if (classList.contains('sms-sent')) {
        onOpenSendAutoSmsPopup(row);
        return;
      }
    }

    if (classList.contains('ico-arrow-right')) {
      if (history.location.pathname === '/crm/appointment-boards') {
        history.location.state = {
          date: obj.row.scheduledAt,
          id: obj.row.id,
          appointment: obj.row,
        };
      } else {
        history.push({
          pathname: '/crm/appointment-boards',
          state: {
            date: obj.row.scheduledAt,
            id: obj.row.id,
            appointment: obj.row,
          },
        });
      }
      modal.pop();
    }
  };

  const onOpenSendAutoSmsPopup = async (row) => {
    const defaultParam = $qb()
      .limit(100)
      .customParam('status', 'waiting,success,fail,sending')
      .customParam('smsScheduleType', 'scheduled')
      .customParam('smsType', 'normal')
      .customParam('appointmentId', row.id);
    const resp = await services.crm.notification.smsNotifications.ad.detail(
      defaultParam.build()
    );
    if (!resp) return;

    onOpenAppointmentAutoSms(row, resp.data);
  };

  const onSelectSurgeryAction = (object) => {
    const event = object.event;
    const surgeries = object.row;
    const classList = event.target.classList;

    if (classList.contains('checkbox') || classList.contains('zmdi-check')) {
      if (surgeries.$$checked) {
        setSurgeryTreatmentItems([...surgeryTreatmentItems, surgeries]);
      } else {
        let list = surgeryTreatmentItems.filter((v) => {
          return v.id !== surgeries.id;
        });
        setSurgeryTreatmentItems([...list]);
      }
    }
  };

  const onParams = (p) => {
    setParams(p);
    loadAppointments();
  };

  const paymentCallApi = useCallback(async () => {
    try {
      //수납된(미수포함) / 남은 회차가 1회 이상 남은  시/수술 내역 노출
      //남은 회차가 0회인(환불포함) 시/수술은 노출 x

      if (!customer.id) return;

      const resp = await useCustomerChart.useGetRemainingPaidTreatmentItems(
        customer.id
      );
      if (!resp) return;

      setPaymentData(resp);
    } catch (e) {
      console.log(e.description);
    }
  }, [services.crm.payment, customer.id]);

  const validator = () => {
    if (obj.department === undefined || obj.department === null) {
      toast.error('부서를 선택하세요.');
      setLoadingBtnDisabledFlag(false);
      return false;
    }

    if (
      obj?.id === undefined &&
      sendAutoSmsCheck &&
      smsAutoNotifications.length > 0 &&
      smsAutoNotifications.every((e) => e['$$checked'] === false)
    ) {
      toast.error('최소 1개 이상의 전송할 예약 문자를 선택해 주세요.');
      return false;
    }

    return true;
  };

  const smsNotificationsCallApi = async (payload, endpoint) => {
    const apiParams = $qb()
      .customParam('status', 'waiting')
      .customParam('smsScheduleType', 'scheduled')
      .customParam('smsType', 'normal')
      .customParam('appointmentId', obj.id);

    const resp = await services.crm.notification.smsNotifications.ad.all(
      apiParams.build()
    );
    if (!resp) return;

    //기존 예약걸린 문자가 없는 경우
    let departmentSmsAutoNotifications = await messages.loadDepartmentSmsAutoNotifications(
      obj.department?.id
    );

    if (resp.data.length > 0) {
      //기존 예약걸린 문자가 있는 경우
      modal
        .custom({
          component: ModalAppointmentUpdateCompleteAutoSms,
          options: {
            model: 'autoSms',
            canClose: false,
            type: 'SEND_SMS_CHECK',
            data: resp.data,
            notifications: departmentSmsAutoNotifications,
            scheduledAt: payload.scheduledAt,
          },
        })
        .then(({ sendAutoSmsCheck, sendAutoSmsIds }) => {
          payload.sendAutoSms = sendAutoSmsCheck;
          if (sendAutoSmsCheck) {
            payload.sendAutoSmsIds = sendAutoSmsIds;
          }
          onSaveAppointment(endpoint, payload);
        });
    } else {
      if (departmentSmsAutoNotifications.length > 0) {
        modal
          .custom({
            component: ModalAppointmentUpdateCompleteAutoSmsAlert,
            options: {
              model: 'autoSms',
              canClose: false,
              type: 'SEND_SMS_CHECK',
              data: resp.data,
              msgType: 'default',
              notifications: departmentSmsAutoNotifications,
              scheduledAt: payload.scheduledAt,
            },
          })
          .then(({ sendAutoSmsCheck, sendAutoSmsIds }) => {
            payload.sendAutoSms = sendAutoSmsCheck;
            if (sendAutoSmsCheck) {
              payload.sendAutoSmsIds = sendAutoSmsIds;
            }
            onSaveAppointment(endpoint, payload);
          });
      } else {
        payload.sendAutoSms = false;
        onSaveAppointment(endpoint, payload);
      }
    }
  };

  const setUpdatePayload = (p) => {
    let appointment = selectAppointment;
    const endpoint = 'update';
    let payload = { ...p };
    //예약 수정일때 appointmentId 지정
    //appointmentId는 현황판/예약캘린더에서 openFlag로 넘겨준 id이거나,
    //예약 수정시, 저장되어있던 obj.id 이다.
    if (processAppointmentId !== null) {
      payload.appointmentId = processAppointmentId;
    } else {
      payload.appointmentId = obj.id;
    }

    let originDid = appointment?.department?.id;
    let copiedDid = payload?.department?.id;

    let [a_hours, a_minutes] = appointment.startHour.split(':');
    let [hours, minutes] = payload?.startHour.split(':');
    const currentScheduled = moment();
    const originScheduled = moment(appointment.scheduledAt)
      .hours(a_hours)
      .minutes(a_minutes);
    const copiedScheduled = moment(payload.scheduledAt)
      .hours(hours)
      .minutes(minutes);

    //부서,예약일,예약시간이 변경 될 시 다이얼로그 팝업 노출
    if (
      smsAutoNotifications.length > 0 &&
      (originDid !== copiedDid || originScheduled.diff(copiedScheduled) !== 0)
    ) {
      //현재시간보다 미래 날짜로 예약 수정시 다이얼로그 팝업 노출
      if (currentScheduled.diff(copiedScheduled) < 0) {
        //설정된 자동문자 타입이 없으면 자동문자전송 dialog 팝업 미노출.

        //기존에 예약걸린 문자가 있는가?
        smsNotificationsCallApi(payload, endpoint);
      } else {
        payload.sendAutoSms = false;
        onSaveAppointment(endpoint, payload);
      }
    } else {
      payload.sendAutoSms = false;
      onSaveAppointment(endpoint, payload);
    }
  };

  const onClickSave = useCallback(
    async (surgeryTreatmentItems) => {
      setLoadingBtnDisabledFlag(true);

      if (validator()) {
        let endpoint = !obj.id ? 'create' : 'update';
        //예약시에는 visited = false 여야 하고, 방문했을 때 true가 되어야 한다
        //백단에서 기본 값이 false로 저장이 되어야하는데 불가능하면 프론트에서 visited를 false로 보낸다
        let payload = !obj.id
          ? { ...obj, status: 'scheduled', visited: false }
          : { ...obj };
        payload = surgeryTreatmentItems.length
          ? { ...payload, paymentTreatmentItems: surgeryTreatmentItems }
          : payload;
        let items = itemsList.filter(
          (v) => v !== undefined && Object.keys(v).length !== 0
        );
        payload =
          items.length > 0
            ? { ...payload, items: items }
            : endpoint === 'update'
            ? { ...payload, items: [] }
            : payload;

        payload.appointmentStatus = 'scheduled';
        payload.customerId = (customer || {}).id;
        payload.sendAutoSms = !obj.id
          ? sendAutoSmsCheck && smsAutoNotifications.length > 0
          : false;
        payload.saveCustomer = radioValue;
        if (obj.memo) payload.memo = toHexColorHtml(obj.memo);
        if (recallMemo) payload.memo = recallMemo;

        // payload.startHour = obj.startHour.split('(')[0].trim(0);
        payload.startHour = startHour.split('(')[0].trim(0);
        if (endpoint === 'update') {
          delete payload.customerProcess;
          setUpdatePayload(payload);
        } else {
          if (sendAutoSmsCheck && smsAutoNotifications.length > 0) {
            payload.sendAutoSmsIds = smsAutoNotifications
              .filter((f) => f['$$checked'])
              .map((v) => {
                return v.id;
              });
          }
          onSaveAppointment(endpoint, payload);
          if (consultingRequest) {
            closeModal();
            eventBus.emit('appointmentComplete');
          }
        }
      }
    },
    [
      loadAppointments,
      obj,
      closeFlag,
      processAppointmentId,
      actionInit,
      itemsList,
      recallMemo,
      radioValue,
      startHour,
      smsAutoNotifications,
      sendAutoSmsCheck,
    ]
  );

  const onSaveAppointment = useCallback(
    async (endpoint, payload) => {
      try {
        const resp = await services.crm.crud.appointment[endpoint](payload);
        if (!resp) return;
        let message = translate(`${endpoint.toUpperCase()}D`);
        modal
          .confirm({
            type: 'SUCCESS',
            msg: message,
          })
          .then(() => {
            eventBus.emit('chartCountCallApi');
            //탭이 닫히지 않을 때 내역 조회 필요
            loadAppointments();

            if (history.location.pathname === '/crm/appointment-boards') {
              eventBus.emit('boardsLoadAppointments');
            } else if (history.location.pathname === '/crm/home') {
              eventBus.emit('homeLoadAppointments');
            }

            if (closeFlag) {
              eventBus.emit('appointmentComplete');
              modal.pop();
              return;
            }
            // onClickSaveRecall(resp.data.id)
            // setSaveAppointment(false);
            stateInit();
            eventBus.emit('customerCallApi');
          });
      } catch (e) {
        console.log(e.description);
        let message = e.description;
        if (e.description === 'AppointmentExists') {
          message = '해당 시간에 이미 예약이 존재합니다.';
        }
        if (e.code === 412 && e.description === 'OutOfWorkHour') {
          message = '영업시간이내에만 예약을 생성할 수 있습니다.';
        }

        modal
          .confirm({
            type: 'ERROR',
            msg: message,
          })
          .then(() => {
            // setSaveAppointment(false);
            setLoadingBtnDisabledFlag(false);
          });
      }
    },
    [eventBus, modal, services, loadAppointments, closeFlag, actionInit]
  );

  const checkAbsence = useCallback(async (surgeryTreatmentItems) => {
    let { department, scheduledAt } = obj;
    if (validator()) {
      if (calledPage !== 'boards') {
        try {
          const absenceResp = await services.crm.crud.absenceSchedule.all({
            startAt: moment(scheduledAt).format('YYYY-MM-DD'),
            endAt: moment(scheduledAt).add(1, 'days').format('YYYY-MM-DD'),
            departmentId: department.id,
          });
          if (absenceResp.data.length > 0) {
            modal
              .basic({
                body: '휴진설정한 부서입니다. 그래도 예약을 생성하시겠습니까?',
                buttons: [
                  { text: '취소', class: 'btn-default' },
                  { text: '확인', class: 'btn-primary' },
                ],
              })
              .then((idx) => {
                if (idx === 0) {
                  return;
                } else {
                  checkAppointment(surgeryTreatmentItems);
                }
              });
          } else {
            checkAppointment(surgeryTreatmentItems);
          }
        } catch (e) {
          console.log(e.description);
        }
      } else {
        checkAppointment(surgeryTreatmentItems);
      }
    }
  });

  const checkAppointment = useCallback(
    async (surgeryTreatmentItems) => {
      let { id, customer, scheduledAt } = obj;
      if (!id) {
        try {
          let param = {
            customerId: (customer || {}).id,
            startAt: moment(scheduledAt).format('YYYY-MM-DD'),
            endAt: moment(scheduledAt).format('YYYY-MM-DD'),
          };
          const resp = await services.crm.crud.appointment.all(param);

          if (!resp) return;

          if (resp.total > 0) {
            modal
              .basic({
                body:
                  '이미 해당일에 예약이 존재합니다. 그래도 예약하시겠습니까?',
                buttons: [
                  { text: 'CANCEL', class: 'btn-default' },
                  { text: 'CONFIRM', class: 'btn-primary' },
                ],
              })
              .then((idx) => {
                if (idx === 1) {
                  onClickSave(surgeryTreatmentItems);
                } else {
                  setLoadingBtnDisabledFlag(false);
                }
              });
          } else {
            onClickSave(surgeryTreatmentItems);
          }
        } catch (e) {
          console.log(e.description);
        }
      } else {
        onClickSave(surgeryTreatmentItems);
      }
    },
    [modal, services, obj, onClickSave]
  );

  const onChangeValue = (column, value) => {
    setObj((obj) => {
      return { ...obj, [column]: value };
    });
  };

  const initAppointment = (appointmentId) => {
    let items = [{}];
    let doctor = customer && customer.doctor ? customer.doctor : null;
    let counselor = customer && customer.counselor ? customer.counselor : null;
    let acquisitionChannel =
      customer && customer.acquisitionChannel
        ? customer.acquisitionChannel
        : null;
    if (appointmentId) {
      let obj = copy(
        appointments.find((appointment) => appointment.id === appointmentId)
      );
      setObj({ ...obj });
      setItemsList(...obj.items);
    } else {
      // let favor = [{}]
      if (openFlag !== undefined) {
        if (openFlag.appointment !== undefined) {
          let changeObj = { ...obj, ...openFlag.appointment };
          if (openFlag.appointment !== null) {
            changeObj.type === undefined && (changeObj.type = 'consulting');
            // changeObj.items = (openFlag.appointment.items === undefined) ? favor : openFlag.appointment.items
            items =
              openFlag.appointment.items === undefined
                ? [{}]
                : openFlag.appointment.items.filter((v) => v.category !== null);
            changeObj.estimatedServiceMinutes =
              openFlag.appointment.estimatedServiceMinutes === undefined
                ? 30
                : openFlag.appointment.estimatedServiceMinutes;
            setStartHour(changeObj.startHour);
          } else {
            changeObj.type = 'consulting';
            // changeObj.items = favor
            changeObj.estimatedServiceMinutes = 30;
            // changeObj.startHour = (auth.me.clinic || {}).workStart;
            setStartHour((auth.me.clinic || {}).workStart);
          }

          // 신/구환 여부 셋팅
          if (!(changeObj && changeObj.isNewCustomer)) {
            changeObj.isNewCustomer = changeObj.customer.isNewCustomer;
          }
          setObj({
            ...changeObj,
            doctor,
            counselor,
            acquisitionChannel,
            isNewCustomer: changeObj && changeObj.isNewCustomer,
          });
          setTimeSetObj({
            departmentId: changeObj && changeObj.department.id,
            scheduledAt: changeObj && changeObj.scheduledAt,
          });
          setStartHour(changeObj.startHour);
        } else {
          setObj({
            customer,
            type: 'consulting',
            estimatedServiceMinutes: 30,
            createdId: auth.me.id,
            doctor,
            counselor,
            acquisitionChannel,
            isNewCustomer: (customer || {}).isNewCustomer,
            scheduledAt: moment().format(),
          });
          setStartHour((auth.me.clinic || {}).workStart);
        }
      } else {
        setObj({
          customer,
          type: 'consulting',
          estimatedServiceMinutes: 30,
          createdId: auth.me.id,
          doctor,
          counselor,
          acquisitionChannel,
          isNewCustomer: (customer || {}).isNewCustomer,
          scheduledAt: moment().format(),
        });
        setStartHour((auth.me.clinic || {}).workStart);
      }
      setItemsList(items);
    }

    setComponentFlag('appointments');
  };

  const addTreatmentItem = () => {
    if (treatmentItemCategories[0] === undefined) {
      toast.error('수납코드 설정에서 시/수술을 추가해주세요.');
      return;
    }
    let list = [...itemsList];
    list.push({});
    setItemsList([...list]);
  };

  useEffect(() => {
    //탭 진입 시, openFlag 값이 있으면 setObj setState
    // 1.현황판 > 예약수정 > openFlag 주어짐 >> 예약등록 화면으로 진입
    // 2.예약캩린더에서 예약 > openFlag 주어짐 >> 예약등록 화면으로 진입
    // 3.상담문의에서 예약 > openFlag X >> 예약 초기화면으로 진입
    // 4.예약내역 > 예약수정 > openFlag x >> 예약등록 화면으로 진입

    if (auth.me.clinic.appointmentTimeUnit === 10) {
      setEstimatedMinutesList([...hooks.estimatedServiceMinutesList]);
    } else {
      setEstimatedMinutesList([
        ...hooks.estimatedServiceMinutesList.filter((value) => value !== '10'),
      ]);
    }
    init();

    return () => {
      setObj({});
      setItemsList([{}]);
      setComponentFlag(null);
    };
  }, [init]);

  const timeCountCallApi = useCallback(async () => {
    let time = { items: null };
    let splitStartHour = '';
    let selectStartHour = '';
    splitStartHour = startHour.split('(')[0].trim();
    selectStartHour = startHour;
    // if (obj && obj.startHour !== null && typeof obj.startHour === 'string') {
    //   splitStartHour = obj.startHour.split('(')[0].trim();
    //   selectStartHour = obj.startHour;
    // }

    if (
      obj.department &&
      obj.department.id !== undefined &&
      obj.scheduledAt !== undefined
    ) {
      let scheduledAt = moment(obj.scheduledAt).format('YYYY-MM-DD');
      let departmentId = obj.department.id;
      const resp = await services.crm.crud.appointment.time_count({
        scheduledAt,
        departmentId,
      });
      if (!resp) return;
      time = resp.data;
    }

    const hours = numArray(18)
      .map((val) => val + 6)
      .map((val) => {
        if (val >= 24) val -= 24;
        return `${(val.toString().length === 1 ? '0' : '') + val}`;
      });
    let result = [];
    hours.forEach((hour) => {
      result = result.concat(
        fillTimeframe(auth.me.clinic.appointmentTimeUnit).map((val) => {
          let timeSet = `${hour}:${val}`;

          if (time.items !== null && time.items.length > 0) {
            let item = time.items.find((f) => {
              return f.time === timeSet;
            });

            if (item?.count > 0) {
              timeSet += `\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0(${
                item.count || 0
              })`;
            }

            if (item?.time === splitStartHour) {
              selectStartHour = timeSet;
            }
          } else {
            selectStartHour = splitStartHour;
          }
          return timeSet;
        })
      );
    });

    if (selectStartHour !== '') {
      setStartHour(selectStartHour);
      // setObj({
      //   ...obj,
      //   startHour: selectStartHour,
      // });
    }

    setStartHourOptions(result);
  }, [timeSetObj]);

  useEffect(() => {
    timeCountCallApi();
  }, [timeCountCallApi]);

  const onClickCreateAppointments = async () => {
    //메모 상용구 api 호출
    memoBoilerplateCallApi();

    initAppointment();
  };

  const objectWithoutKey = (object, key) => {
    // eslint-disable-next-line unused-imports/no-unused-vars
    const { [key]: deletedKey, ...otherKeys } = object;
    return otherKeys;
  };

  const onChangeType = (e) => {
    const { value } = e.target;
    if (value === 'surgery' && !paymentData.length) {
      toast.success('진행중인 시/수술이 없습니다.');
      return;
    }

    let changeObj = {
      ...obj,
      type: value,
    };

    //선택한 type에 따라 obj도 변경되어야 한다
    switch (value) {
      case 'consulting':
      case 'treatment':
        changeObj = objectWithoutKey(changeObj, 'facialist');
        setSurgeryTreatmentItems([]);
        setPaymentData((data) =>
          data.map((v) => {
            return {
              ...v,
              $$checked: false,
            };
          })
        );
        break;
      case 'surgery':
        changeObj = objectWithoutKey(changeObj, 'counselor');
        changeObj = objectWithoutKey(changeObj, 'acquisitionChannel');
        changeObj = objectWithoutKey(changeObj, 'items');
        setItemsList([{}]);
        break;
      default:
        break;
    }

    setObj(changeObj);
  };

  const memoBoilerplateCallApi = useCallback(async () => {
    try {
      const memoItems = await useCustomerChart.useGetMemoBoilerplate(tabName);
      setMemoBoilerplateList(memoItems);
    } catch (e) {
      console.log(e.description);
    }
  }, [services.crm.boilerplate.memo]);

  const onClickOpenPaymentCodePopup = () => {
    // afterUpdateReturn: true > 팝업에서 수납코드 저장 후 저장된 내용 반환 위한 플래그
    modal
      .custom({
        component: ModalSurgery,
        options: { surgery: undefined, afterUpdateReturn: true },
      })
      .then((result) => {
        if (typeof result === 'object') {
          treatmentItemCategoriesCallApi();
          const setItems = useCustomerChart.useSettingSurgeryItems(
            result,
            itemsList
          );
          setItemsList(setItems);
        }
      });
  };

  useEffect(() => {
    if (saveAppointment === true) {
      checkAbsence(surgeryTreatmentItems);
    }
  }, [saveAppointment]);

  const onChangeScheduledAt = (scheduledAt) => {
    let date =
      scheduledAt === null ? moment().format() : moment(scheduledAt).format();
    setTimeSetObj({
      ...timeSetObj,
      scheduledAt,
    });
    setObj((obj) => {
      return { ...obj, scheduledAt: date };
    });
  };

  return (
    <div className="customer-appointments chart-page">
      <div className="data-input">
        <div className="title">
          {translate('FORM_APPOINTMENT')}{' '}
          {(obj || {}).id ? <span>&#40;ID: {obj.id}&#41;</span> : null}
          <div className="right">
            {componentFlag === null ? (
              <>
                <button
                  className="btn btn-basic _small"
                  onClick={onClickCreateAppointments}
                >
                  신규예약등록
                </button>
              </>
            ) : (
              <>
                {/* <button className="btn btn-cancel _small m-r-8" onClick={actionInit}>{'입력취소'}</button>
              <button className="btn btn-basic _small" disabled={`${loadingBtnDisabledFlag ? 'disabled' : ''}`} onClick={() => onClickSave(surgeryTreatmentItems)}>예약완료</button> */}
              </>
            )}
          </div>
        </div>

        {obj && componentFlag === 'appointments' && (
          <div className="card">
            <div className="form-control flex-wrap">
              <label className="label-required">예약종류</label>
              <div className="flex-row">
                <button
                  className={`btn flex-wrap _small m-r-8 ${
                    obj.type === 'consulting' ? 'btn-primary' : 'btn-normal'
                  }`}
                  name="type"
                  value="consulting"
                  onClick={onChangeType}
                >
                  상담예약
                </button>
                <button
                  className={`btn flex-wrap _small m-r-8 ${
                    obj.type === 'treatment' ? 'btn-primary' : 'btn-normal'
                  }`}
                  name="type"
                  value="treatment"
                  onClick={onChangeType}
                >
                  진료예약
                </button>
                <button
                  className={`btn flex-wrap _small ${
                    obj.type === 'surgery' ? 'btn-primary' : 'btn-normal'
                  }`}
                  name="type"
                  value="surgery"
                  onClick={onChangeType}
                >
                  시/수술예약
                </button>
              </div>
            </div>
            <div className="form-double">
              <div className="form-control">
                <label className="label-required">예약부서</label>
                <select
                  required
                  value={(departments || []).findIndex(
                    (department) => department.id === (obj.department || {}).id
                  )}
                  onChange={(e) => {
                    obj.department = departments[e.target.value];
                    setObj({ ...obj });
                    setTimeSetObj({
                      ...timeSetObj,
                      departmentId: obj.department.id,
                    });
                  }}
                >
                  <option value={-1} hidden disabled>
                    부서를 선택하세요.
                  </option>
                  {(departments || []).map((department, idx) => (
                    <option
                      key={idx}
                      value={idx}
                      className={!department.visible ? 'display-none' : ''}
                    >
                      {department.category.name} - {department.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-control">
                <label>작성자</label>
                <select
                  value={obj?.createdId || -1}
                  onChange={(e) => {
                    const idx = createdList.findIndex(
                      (f) => f.id === Number(e.target.value)
                    );

                    obj['createdId'] = createdList[idx]?.id;
                    setObj({ ...obj });
                  }}
                >
                  <option value={-1} hidden disabled>
                    작성자를 선택하세요.
                  </option>
                  <option value="null">-</option>
                  {(createdList || []).map((o, idx) => (
                    <option key={idx} value={o.id}>
                      {' '}
                      {o.name}{' '}
                    </option>
                  ))}
                </select>
              </div>
              {auth.isNewCustomerOption === 'manual' ? (
                <div className="form-control">
                  <label className="label-required">신/구환예약</label>
                  <div className="wrap-radio">
                    {hooks.radios({
                      radios: radioItemsType,
                      obj,
                      setObj,
                      name: 'isNewCustomer',
                    })}
                  </div>
                </div>
              ) : null}
            </div>
            <div className="form-double">
              <div className="form-control">
                <label className="label-required">
                  {translate('SCHEDULED_AT')}
                </label>
                {useCustomerChart.getScheduledAtDatePicker({
                  objScheduledAt: obj.scheduledAt || new Date(),
                  onChangeScheduledAt,
                })}
              </div>
              <div className="form-control">
                <label className="label-required">방문시간</label>
                {hooks.selector({
                  options: startHourOptions,
                  value: startHour,
                  onChange: (e) => {
                    setStartHour(e.target.value);
                    // obj.startHour = e.target.value;
                    // setObj({ ...obj });
                  },
                })}
              </div>
              <div className="form-control">
                <label className="label-required">예상소요시간</label>
                <select
                  required
                  value={obj.estimatedServiceMinutes || 30}
                  onChange={(e) => {
                    obj.estimatedServiceMinutes = parseInt(
                      e.target.value.split('분')[0]
                    );
                    setObj({ ...obj });
                  }}
                >
                  {estimatedMinutesList.map((value, idx) => (
                    <option value={value} key={idx}>
                      {`${
                        Math.floor(value / 60) !== 0
                          ? `${Math.floor(value / 60)}시간`
                          : ''
                      }`}
                      {`${value % 60 !== 0 ? `${value % 60}분` : ''}`}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="form-double">
              {obj.type === 'consulting' || obj.type === 'treatment' ? (
                <div className="form-control">
                  <label>상담사</label>
                  <select
                    value={counselors.findIndex(
                      (existing) => existing.id === (obj['counselor'] || {}).id
                    )}
                    onChange={(e) => {
                      // 고객의 담당상담사가 있는 경우
                      if (customer && customer.counselor) {
                        if (
                          counselors[e.target.value] &&
                          customer.counselor.id ===
                            counselors[e.target.value].id
                        ) {
                          setCounselorFlag(false);
                        } else {
                          setCounselorFlag(true);
                        }
                      }
                      // 고객의 담당상담사가 없는 경우
                      else {
                        if (e.target.value === 'null') {
                          setCounselorFlag(false);
                        } else {
                          setCounselorFlag(true);
                        }
                      }
                      obj['counselor'] = counselors[e.target.value];
                      setObj({ ...obj });
                    }}
                  >
                    <option value={-1} hidden disabled>
                      상담사를 선택하세요.
                    </option>
                    <option value="null">-</option>
                    {(counselors || []).map((o, idx) => (
                      <option key={idx} value={idx}>
                        {' '}
                        {o.name}{' '}
                      </option>
                    ))}
                  </select>
                </div>
              ) : null}
              {obj.type === 'consulting' ||
              obj.type === 'treatment' ||
              obj.type === 'surgery' ? (
                <div className="form-control">
                  <label>의사</label>
                  <select
                    value={doctors.findIndex(
                      (existing) => existing.id === (obj['doctor'] || {}).id
                    )}
                    onChange={(e) => {
                      // 고객의 담당의사가 있는 경우
                      if (customer && customer.doctor) {
                        if (
                          doctors[e.target.value] &&
                          customer.doctor.id === doctors[e.target.value].id
                        ) {
                          setDoctorFlag(false);
                        } else {
                          setDoctorFlag(true);
                        }
                      }
                      // 고객의 담당의사가 없는 경우
                      else {
                        if (e.target.value === 'null') {
                          setDoctorFlag(false);
                        } else {
                          setDoctorFlag(true);
                        }
                      }
                      obj['doctor'] = doctors[e.target.value];
                      setObj({ ...obj });
                    }}
                  >
                    <option value={-1} hidden disabled>
                      의사를 선택하세요.
                    </option>
                    <option value="null">-</option>
                    {(doctors || []).map((o, idx) => (
                      <option key={idx} value={idx}>
                        {' '}
                        {o.name}{' '}
                      </option>
                    ))}
                  </select>
                </div>
              ) : null}
              {obj.type === 'surgery' ? (
                <div className="form-control">
                  <label>어시스트</label>
                  <select
                    value={facialist.findIndex(
                      (existing) => existing.id === (obj['facialist'] || {}).id
                    )}
                    onChange={(e) => {
                      obj['facialist'] = facialist[e.target.value];
                      setObj({ ...obj });
                    }}
                  >
                    <option value={-1} hidden disabled>
                      어시스트를 선택하세요.
                    </option>
                    <option value="null">-</option>
                    {(facialist || []).map((o, idx) => (
                      <option key={idx} value={idx}>
                        {' '}
                        {o.name}{' '}
                      </option>
                    ))}
                  </select>
                </div>
              ) : // hooks.DOMOptionPlaceHolder({ optionKey: 'facialist', obj, onChangeValue })
              null}
              {obj.type !== 'surgery' ? (
                <div className="form-control">
                  <label>내원경로</label>
                  <select
                    value={acquisitionChannel.findIndex(
                      (existing) =>
                        existing.id === (obj['acquisitionChannel'] || {}).id
                    )}
                    onChange={(e) => {
                      // 고객의 내원경로가 있는 경우
                      if (customer && customer.acquisitionChannel) {
                        if (
                          acquisitionChannel[e.target.value] &&
                          customer.acquisitionChannel.id ===
                            acquisitionChannel[e.target.value].id
                        ) {
                          setAcquisitionChannelFlag(false);
                        } else {
                          setAcquisitionChannelFlag(true);
                        }
                      }
                      // 고객의 내원경로가 없는 경우
                      else {
                        if (e.target.value === 'null') {
                          setAcquisitionChannelFlag(false);
                        } else {
                          setAcquisitionChannelFlag(true);
                        }
                      }
                      obj['acquisitionChannel'] =
                        acquisitionChannel[e.target.value];
                      setObj({ ...obj });
                    }}
                  >
                    <option value={-1} hidden disabled>
                      내원경로를 선택하세요.
                    </option>
                    <option value="null">-</option>
                    {acquisitionChannel.map((o, idx) => (
                      <option key={idx} value={idx}>
                        {o.name}
                      </option>
                    ))}
                  </select>
                </div>
              ) : // hooks.DOMOptionPlaceHolder({ optionKey: 'acquisitionChannel', obj, onChangeValue })
              null}
            </div>

            {doctorFlag || counselorFlag || acquisitionChannelFlag ? (
              <div className="form-wide m-b-20">
                <span className="m-r-20">
                  {counselorFlag ? ' [상담사] ' : null}
                  {doctorFlag ? ' [의사] ' : null}
                  {acquisitionChannelFlag ? ' [내원경로] ' : null}
                  {'를 해당 고객의 담당으로 지정하시겠습니까?'}
                </span>
                <span className="m-r-20">
                  <RadioInput
                    name="saveCustomer"
                    checked={radioValue}
                    onChange={() => setRadioValue(true)}
                  />{' '}
                  예
                </span>
                <span className="m-r-20">
                  <RadioInput
                    name="saveCustomer"
                    checked={!radioValue}
                    onChange={() => setRadioValue(false)}
                  />{' '}
                  아니오
                </span>
              </div>
            ) : null}

            {obj.type === 'surgery' && paymentData.length ? (
              <div className="form-control form-full">
                <label>진행가능한 시/수술</label>
                <DataTableAlpha
                  model={models.crm.appointmentSurgery}
                  data={paymentData}
                  hideBottom={true}
                  hideAllCheck={true}
                  onAction={onSelectSurgeryAction}
                />
              </div>
            ) : null}

            {obj.type !== 'surgery' ? (
              <>
                <div className="form-wide m-b-20">
                  {itemsList.map((list, idx) => {
                    return (treatmentItemCategories || []).length > 0 ? (
                      <div className="form-double-side" key={idx}>
                        <div className="form-control">
                          {idx === 0 && <label>시/수술 카테고리</label>}
                          <Select
                            className="form-react-select"
                            styles={selectCustomStyles}
                            placeholder="시/수술 카테고리를 선택하세요."
                            value={
                              (itemsList[idx] || {}).category
                                ? {
                                    value: (
                                      treatmentItemCategories || []
                                    ).findIndex(
                                      (category) =>
                                        category.id ===
                                        ((itemsList[idx] || {}).category || {})
                                          .id
                                    ),
                                    label: (
                                      (itemsList[idx] || {}).category || {}
                                    ).name,
                                  }
                                : null
                            }
                            onChange={(e) => {
                              if (e.value !== -1) {
                                const items = (
                                  treatmentItemCategories[e.value] || {}
                                ).items;
                                itemsList[idx] = (items || [])[0];
                                setItemsList([...itemsList]);
                              } else {
                                itemsList[idx] = [];
                                setItemsList([...itemsList]);
                              }
                            }}
                            options={[{ value: null, label: '없음' }].concat(
                              (treatmentItemCategories || [])
                                .map((category, idx) => ({
                                  value: idx,
                                  label: category.name,
                                  isDisabled: !category.visible,
                                }))
                                .filter((v) => !v.isDisabled)
                            )}
                          />
                        </div>
                        <div className="form-control">
                          {idx === 0 && <label>시/수술명</label>}
                          {(treatmentItemCategories || []).length > 0 ? (
                            <Select
                              className="form-react-select"
                              styles={selectCustomStyles}
                              placeholder="시/수술명을 선택하세요."
                              value={
                                (itemsList[idx] || {}).category
                                  ? {
                                      value: (
                                        (
                                          (
                                            treatmentItemCategories || []
                                          ).find((category) =>
                                            category.items.some(
                                              (item) =>
                                                item.id ===
                                                (itemsList[idx] || {}).id
                                            )
                                          ) || {}
                                        ).items || []
                                      ).findIndex(
                                        (item) =>
                                          item.id === (itemsList[idx] || {}).id
                                      ),
                                      label: (itemsList[idx] || {}).name,
                                    }
                                  : null
                              }
                              onChange={(e) => {
                                if (e.value === null) {
                                  itemsList[idx] = {};
                                  setItemsList([...itemsList]);
                                  return false;
                                }
                                itemsList[idx] = (
                                  treatmentItemCategories || []
                                ).find((category) =>
                                  category.items.some(
                                    (item) =>
                                      item.id === (itemsList[idx] || {}).id
                                  )
                                ).items[e.value];
                                setItemsList([...itemsList]);
                              }}
                              options={[{ value: null, label: '없음' }].concat(
                                (
                                  (
                                    (treatmentItemCategories || []).find(
                                      (category) =>
                                        category.id ===
                                        ((itemsList[idx] || {}).category || {})
                                          .id
                                    ) || {}
                                  ).items || []
                                )
                                  .map((item, idx) => ({
                                    value: idx,
                                    label: item.name,
                                    isDisabled: !item.visible,
                                  }))
                                  .filter((v) => !v.isDisabled)
                              )}
                            />
                          ) : null}
                        </div>

                        <button
                          className="btn btn-danger _small"
                          onClick={() => {
                            modal
                              .basic({
                                body:
                                  '삭제하시겠습니까? 최종 저장 전에는 반영되지 않습니다.',
                                buttons: [
                                  { text: 'CANCEL', class: 'btn-default' },
                                  { text: 'CONFIRM', class: 'btn-danger' },
                                ],
                              })
                              .then((selectedBtnIdx) => {
                                if (selectedBtnIdx === 1) {
                                  itemsList.splice(idx, 1);
                                  setItemsList([...itemsList]);
                                }
                              });
                          }}
                        >
                          {translate('DELETE')}
                        </button>
                      </div>
                    ) : null;
                  })}
                </div>
                <div className="btnwrap m-t-8">
                  <button
                    className="btn btn-basic middle _small _full"
                    onClick={() => addTreatmentItem()}
                  >
                    입력란 추가
                  </button>
                  {auth.me.authorityGroup.paymentCode !== 'disabled' && (
                    <button
                      className="btn btn-primary _small m-l-8"
                      onClick={() => onClickOpenPaymentCodePopup()}
                    >
                      시/수술코드 추가
                    </button>
                  )}
                </div>
              </>
            ) : null}
            <div className="form-wide">
              <div className="form-control">
                <label>예약메모</label>
                {memoBoilerplateList.length > 0 && (
                  <div className="wrap-btn-boilerplate-memo">
                    {memoBoilerplateList.slice(0, 5).map((v, i) => (
                      <button
                        className="btn btn-sm btn-white _ssmall"
                        key={i}
                        onClick={() =>
                          onChangeValue(
                            'memo',
                            (obj.memo == '<p><br></p>' ? '' : obj.memo || '') +
                              v.contents
                          )
                        }
                      >
                        {v.title.slice(0, 5) + (v.title.length > 5 ? '…' : '')}
                      </button>
                    ))}
                  </div>
                )}
                {obj && (
                  <QuillTextField
                    tabName={tabName}
                    key={obj.id}
                    value={obj.memo || ''}
                    setValue={(v) => onChangeValue('memo', v)}
                    setMemoBoilerplateList={setMemoBoilerplateList}
                    placeholder="메모를 입력하세요."
                  />
                )}
              </div>
            </div>
            {smsAutoNotifications.length > 0 && (
              <div
                className="sms-sent m-t-16 m-l-4"
                style={obj.id && { color: 'gray' }}
              >
                <p className="label m-b-8">
                  고객에게 예약완료 자동문자를 전송하시겠습니까?
                </p>
                <label>
                  <SendSmsRadioInput
                    name="sendAutoSms"
                    disabled={obj.id}
                    checked={sendAutoSmsCheck === true}
                    onChange={() => {
                      setSendAutoSmsCheck(true);
                      setObj({ ...obj, sendAutoSms: true });
                    }}
                  />
                  <span
                    className="flex-wrap m-r-16"
                    style={{ fontWeight: '500' }}
                  >
                    전송
                  </span>
                </label>
                <label>
                  <SendSmsRadioInput
                    name="sendAutoSms"
                    disabled={obj.id}
                    checked={sendAutoSmsCheck === false}
                    onChange={() => {
                      setSendAutoSmsCheck(false);
                      setObj({ ...obj, sendAutoSms: false });
                    }}
                  />
                  <span
                    className="flex-wrap m-r-16"
                    style={{ fontWeight: '500' }}
                  >
                    미전송
                  </span>
                </label>
              </div>
            )}
            {/* 전송 체크시에만 아래 문구 노출 */}
            {smsAutoNotifications.length > 0 && sendAutoSmsCheck === true && (
              <div
                className="m-t-8 flex-row"
                style={obj.id && { color: 'gray' }}
              >
                <span style={{ maxWidth: '125px' }}>
                  자동문자 전송설정내용 :{' '}
                </span>
                <span style={{ float: 'right' }}>
                  {useCustomerChart.SmsAutoNotificationsList(
                    smsAutoNotifications,
                    setSmsAutoNotifications,
                    obj.id,
                    obj.scheduledAt || new Date()
                  )}
                </span>
              </div>
            )}

            <div className="flex-row wrap-bottom-btns">
              <button
                className="btn btn-cancel _small m-r-8"
                onClick={actionInit}
              >
                {'입력취소'}
              </button>
              <button
                className="btn btn-basic _small"
                disabled={`${loadingBtnDisabledFlag ? 'disabled' : ''}`}
                onClick={() => checkAbsence(surgeryTreatmentItems)}
              >
                예약완료
              </button>
            </div>
          </div>
        )}
      </div>
      <div className="wrap-data-table data-table">
        <div className="card">
          <div className="title">{translate('LIST_APPOINTMENT')}</div>
          <DataTableAlpha
            model={modelAppointmentTab}
            total={total}
            data={appointments}
            params={params}
            onParams={onParams}
            onAction={onAction}
            bottomPositionInner
            hasLine
          />
        </div>
      </div>
    </div>
  );
};

CustomerAppointments.propTypes = {
  appointment: PropTypes.object,
  setSaveAppointment: PropTypes.bool,
  saveAppointment: PropTypes.func,
  onClickSaveRecall: PropTypes.func,
  closeModal: PropTypes.func,
  consultingRequest: PropTypes.bool,
  recallMemo: PropTypes.string,
  calledPage: PropTypes.string,
  nurseChart: PropTypes.object,
  customer: PropTypes.object,
  openFlag: PropTypes.object,
  closeFlag: PropTypes.bool,
};

export default React.memo(observer(CustomerAppointments));
