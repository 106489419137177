import { currency, translate } from 'filters';

const translateNumbering = (str, num) => translate(str).replace(/%s/, num);

export default {
  options: {
    hasMergeCell: true,
    groupTitles: ['ACTION', '카테고리'],
    explodeColumn: 'items',
  },
  keys: [
    {
      title: 'ACTION',
      noorder: true,
      filter: (o) => {
        return `<div class="inline-flex has-action depth-1">
              ${
                o['visible']
                  ? `<button class="btn btn-sm btn-primary m-r-4" data-type="edit" >${translate(
                      'EDIT'
                    )}</button>`
                  : ''
              }
              ${
                o['visible']
                  ? `<button class="btn btn-sm btn-black m-r-4"  data-type="visible" >${translate(
                      'TOGGLE_DEACTIVATE'
                    )}</button>`
                  : ''
              }
              ${
                !o['visible']
                  ? `<button class="btn btn-sm btn-gray m-r-4" data-type="visible" >${translate(
                      'TOGGLE_ACTIVATE'
                    )}</button>`
                  : ''
              }
              ${`<button class="btn btn-sm ${
                o['visible'] ? 'btn-danger' : 'btn-gray'
              }" data-type="delete" >${translate('DELETE')}</button>`}
           </div>`;
      },
    },
    {
      title: '카테고리',
      column: 'name',
      noorder: true,
      type: 'arrow',
      filter: (o) => `<div class="pre-line">${o.name}</div>`,
    },
    {
      title: '시/수술명',
      column: '@@items__name',
      noorder: true,
      type: 'arrow',
      filter: (o) => {
        if (o['@@items']['name'] !== null) {
          return `<div class="inline-flex has-action depth-2" >
              <span>${o['@@items']['name']}</span>
              ${
                o['@@items']['visible']
                  ? `<button class="btn ${
                      o['visible'] ? 'btn-black' : 'btn-gray'
                    } btn-sm m-r-4" data-type="visible">${translate(
                      'TOGGLE_DEACTIVATE'
                    )}</button>`
                  : ''
              }
              ${
                !o['@@items']['visible']
                  ? `<button class="btn btn-gray btn-sm m-r-4" data-type="visible">${translate(
                      'TOGGLE_ACTIVATE'
                    )}</button>`
                  : ''
              }
              ${`<button class="btn btn-sm ${
                o['visible'] ? 'btn-danger' : 'btn-gray'
              }" data-type="delete" >${translate('DELETE')}</button>`}
            </div>`;
        }

        return '-';
      },
    },
    {
      title: 'SURGERY_NUMOF',
      column: '@@items__treatmentCount',
      noorder: true,
      filter: (o) => {
        if (o['@@items']['treatmentCount'] === null) {
          return '-';
        }

        if (o['@@items']['treatmentCount'] === 1) {
          return translateNumbering('NUM_ONCE');
        } else {
          return translateNumbering(
            'NUM_TIMES',
            o['@@items']['treatmentCount']
          );
        }
      },
    },
    {
      title: '적정시술주기',
      // column: "@@items__roughItemC",
      noorder: true,
      filter: (o) => {
        if (o['@@items']['nextDay'] !== 0 && o['@@items']['nextMonth'] === 0) {
          if (o['@@items']['nextDay'] === 1) {
            return translateNumbering('SURGERY_AFTER_DAY', 1);
          }
          return translateNumbering(
            'SURGERY_AFTER_DAYS',
            o['@@items']['nextDay']
          );
        }

        if (o['@@items']['nextMonth'] !== 0 && o['@@items']['nextDay'] === 0) {
          if (o['@@items']['nextMonth'] === 1) {
            return translateNumbering('SURGERY_AFTER_MONTH', 1);
          }
          return translateNumbering(
            'SURGERY_AFTER_MONTHS',
            o['@@items']['nextMonth']
          );
        }

        return '-';
      },
    },
    {
      title: 'VAT 미포함가격',
      column: '@@items__price',
      noorder: true,
      filter: (o) => {
        if (o['@@items']['price'] !== null) {
          return `₩${currency(o['@@items']['price'])}`;
        }

        return '-';
      },
    },
    {
      title: 'VAT포함가격',
      column: '@@items__price',
      noorder: true,
      filter: (o) => {
        if (o['@@items']['priceVatInclude'] !== null) {
          return `₩${currency(o['@@items']['priceVatInclude'])}`;
        }

        return '-';
      },
    },
    {
      title: '과세여부',
      column: '@@items__vatIncluded',
      noorder: true,
      filter: (o) => {
        if (o['@@items']['vatIncluded'] !== null) {
          return o['@@items']['vatIncluded']
            ? translate('VAT_TRUE')
            : translate('VAT_FALSE');
        }

        return '-';
      },
    },
    // {
    //   title: "REIMBURSEMENT",
    //   column: "@@items__reimbursement",
    //   filter: (o) => (o["@@items"]["reimbursement"] ? translate("REIMB_TRUE") : translate("REIMB_FALSE")),
    // },
  ],
};
