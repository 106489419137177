import React, { useState } from 'react';
import { observer } from 'mobx-react';
import CustomerAddChartInfo from 'components/pages/crm/customer-chart/CustomerAddChartInfo';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ToggleButton } from 'components/common/ToggleButton';

const Title = styled.div`
  display: flex;
`;

const Wrapper = styled.div`
  margin-left: 20px;
  display: flex;
  align-items: center;
`;

const Text = styled.span`
  margin-right: 5px;
  font-size: 12px;
  font-weight: 500;
`;

const NewCustomerTitle = () => {
  return (
    <Title>
      <span>신규고객등록</span>
      <OpenCustomerChartToggleButton />
    </Title>
  );
};

const OpenCustomerChartToggleButton = () => {
  const [open, setOpen] = useState(
    JSON.parse(window.localStorage.getItem('isCustomerChartOpend')) ?? false
  );

  const onChangeToggle = (value) => {
    window.localStorage.setItem('isCustomerChartOpend', value);
    setOpen(value);
  };

  return (
    <Wrapper>
      <Text>등록완료 후 고객통합차트 열기</Text>
      <ToggleButton value={open} onChange={onChangeToggle} />
    </Wrapper>
  );
};

const ModalAddChartInfo = ({ options, close }) => {
  return (
    <div className="modal-add-customer chart">
      <div className="head flex-row">
        <div className="title">
          {options.editInfo ? '정보수정' : <NewCustomerTitle />}
        </div>
        <i onClick={close} className="zmdi zmdi-close" />
      </div>
      <CustomerAddChartInfo customer={options.customer} />
    </div>
  );
};

ModalAddChartInfo.propTypes = {
  options: PropTypes.object,
  close: PropTypes.func,
};

export default observer(ModalAddChartInfo);
