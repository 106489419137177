import { currency, currencySymbol } from 'filters';

export default {
  keys: [
    {
      title: '상담사명',
      key: 'counselorName',
      column: 'counselorName',
      columnGroup: false,
    },
    {
      title: '상담건수',
      key: 'consultedCount',
      column: 'consultedCount',
      columnGroup: false,
      // TODO: 추후 정렬 추가 작업시 orderKey, order 주석 해제
      // orderKey: 'consultedCount',
      // order: 'desc',
    },
    {
      title: '상담인원수',
      key: 'consultedPeopleCount',
      column: 'consultedPeopleCount',
      columnGroup: false,
      // TODO: 추후 정렬 추가 작업시 orderKey, order 주석 해제
      // orderKey: 'consultedPeopleCount',
      // order: 'desc',
    },
    {
      title: '수납건수',
      key: 'paymentsCount',
      column: 'paymentsCount',
      columnGroup: false,
      // TODO: 추후 정렬 추가 작업시 orderKey, order 주석 해제
      // orderKey: 'paymentsCount',
      // order: 'desc',
    },
    {
      title: '매출액',
      key: 'salesFigures',
      column: 'salesFigures',
      columnGroup: false,
      // TODO: 추후 정렬 추가 작업시 orderKey, order 주석 해제
      // orderKey: 'salesFigures',
      // order: 'desc',
      filter: (o) => {
        return `${currencySymbol()} ${currency(
          (o?.salesFigures ?? 0) - (o?.refundAmount ?? 0)
        )}`;
      },
    },
    {
      title: '수납액',
      key: 'paidAmount',
      column: 'paidAmount',
      columnGroup: false,
      // TODO: 추후 정렬 추가 작업시 orderKey, order 주석 해제
      // orderKey: 'paidAmount',
      // order: 'desc',
      filter: (o) => {
        return `${currencySymbol()} ${currency((o || {}).paidAmount || 0)}`;
      },
    },
    {
      title: '미수금액',
      key: 'unpaidAmount',
      column: 'unpaidAmount',
      columnGroup: false,
      // TODO: 추후 정렬 추가 작업시 orderKey, order 주석 해제
      // orderKey: 'unpaidAmount',
      // order: 'desc',
      filter: (o) => {
        return `${currencySymbol()} ${currency((o || {}).unpaidAmount || 0)}`;
      },
    },
    {
      title: '환불액',
      key: 'refundAmount',
      column: 'refundAmount',
      columnGroup: false,
      // TODO: 추후 정렬 추가 작업시 orderKey, order 주석 해제
      // orderKey: 'refundAmount',
      // order: 'desc',
      filter: (o) => {
        return `${currencySymbol()} ${currency((o || {}).refundAmount || 0)}`;
      },
    },
    {
      title: '실수납액',
      key: 'actualPaymentAmount',
      column: 'actualPaymentAmount',
      columnGroup: false,
      // TODO: 추후 정렬 추가 작업시 orderKey, order 주석 해제
      // orderKey: 'actualPaymentAmount',
      // order: 'desc',
      filter: (o) => {
        return `${currencySymbol()} ${currency(
          (o || {}).actualPaymentAmount || 0
        )}`;
      },
    },
    {
      title: '할인금액',
      key: 'discountAmount',
      column: 'discountAmount',
      columnGroup: false,
      // TODO: 추후 정렬 추가 작업시 orderKey, order 주석 해제
      // orderKey: 'discountAmount',
      // order: 'desc',
      filter: (o) => {
        return `${currencySymbol()} ${currency((o || {}).discountAmount || 0)}`;
      },
    },
  ],
};
