import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Button = styled.button`
  display: flex;
  margin: auto;
  text-decoration: underline;
  color: #0060ff;
  font-size: 12px;
  font-weight: 500;
`;

export function CustomerChartLink({ item, onClick }) {
  return <Button onClick={onClick}>{item.customer?.name ?? ''}</Button>;
}

CustomerChartLink.propTypes = {
  item: PropTypes.object,
  onClick: PropTypes.func,
};
