import React, { useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import { translate } from 'filters';
import ModalChangePassword from 'components/modals/ModalChangePassword';
import { useAuth } from 'store/auth';
import { useModal } from 'hooks/useModal';
import { useToast } from 'hooks/useToast';
import {
  Input,
  Footer,
  P,
  Title,
  Contents,
  Copyright,
  FooterWrapper,
  Wrapper,
  LoginWrapper,
} from './Login.styled';

const Login = () => {
  const history = useHistory();
  const auth = useAuth();
  const modal = useModal();
  const toast = useToast();

  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const loginButton = useRef();
  const searchInput = useRef();

  const login = async () => {
    if (disabled()) {
      let err;
      if (!email) {
        err = 'ERROR_LOGIN_MISSING_EMAIL';
      }

      if (!password) {
        err = 'ERROR_LOGIN_MISSING_PASSWORD';
      }

      toast.error(err);
      return;
    }

    let version;
    try {
      version = await auth.signIn({ email, password });
    } catch (e) {
      toast.error(
        '로그인 정보가 일치하지 않습니다. 아이디나 비밀번호를 잊은 경우 별도로 문의해주세요.'
      );
      return;
    }

    if (version === 'v1') {
      if (auth.me.passwordChanged) {
        history.push('/crm/home');
      } else openChangePasswordModal();
    }
  };

  const openChangePasswordModal = () => {
    modal
      .basic({
        body: translate('REQUIRE_CHANGE_PASSWORD'),
        buttons: [{ text: 'CONFIRM', class: 'btn-primary' }],
      })
      .then((idx) => {
        if (idx === 0) {
          modal.custom({
            component: ModalChangePassword,
          });
        }
      });
  };

  const loginOnEnterKey = (e) => {
    if (e.key === 'Enter') {
      searchInput.current.blur();
      login();
    }
  };

  const disabled = () => (!email || !password ? 'disabled' : '');

  return (
    <Wrapper>
      <LoginWrapper>
        <div
          style={{
            gridColumn: 2,
          }}
          className="login-body"
        >
          <div className="login flex">
            <div className="entrance-wrapper flex-row items-center">
              <div className="entrance flex-fill">
                <div className="logo">
                  <span className="a11y">UNO CRM</span>
                </div>
                <form>
                  <div className="form-control">
                    <div className="login-input-wrapper">
                      <div className="input-label">ID</div>
                      <Input
                        ref={searchInput}
                        autoComplete="name"
                        name="email"
                        placeholder={translate('PLACEHOLDER_EMAIL')}
                        onKeyPress={loginOnEnterKey}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="form-control">
                    <div className="login-input-wrapper">
                      <div className="input-label">PW</div>
                      <Input
                        ref={searchInput}
                        autoComplete="name"
                        name="password"
                        type="password"
                        placeholder={translate('PLACEHOLDER_PASSWORD')}
                        onKeyPress={loginOnEnterKey}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </div>
                  </div>
                  <button
                    ref={loginButton}
                    type="button"
                    className="btn btn-login btn-basic _full"
                    onClick={login}
                  >
                    {translate('LOGIN')}
                  </button>
                </form>
              </div>
            </div>
          </div>
          <FooterWrapper>
            <Footer>
              <div style={{ display: 'flex' }}>
                <P>
                  <Title>주식회사 케어랩스 (Carelabs Co.,Ltd)</Title>
                </P>
                <P>
                  <Title>대표자</Title>
                  <Contents>신종현</Contents>
                </P>
                <P>
                  <Title>주소</Title>
                  <Contents>
                    서울특별시 강남구 역삼로 2길 16(에스타워), 5층
                  </Contents>
                </P>
              </div>
              <div style={{ display: 'flex' }}>
                <P>
                  <Title>사업자등록번호</Title>
                  <Contents>220-88-36643</Contents>
                  <Title underline="active">
                    <a
                      rel="noreferrer"
                      target="_blank"
                      href="http://www.ftc.go.kr/bizCommPop.do?wrkr_no=2208836643"
                    >
                      사업자정보확인
                    </a>
                  </Title>
                </P>
                <P>
                  <Title>통신판매업신고번호</Title>
                  <Contents>제 2019-서울강남-01787호</Contents>
                  <Title underline="active">
                    <a
                      target="_blank"
                      href="https://www.carecrm.co.kr/privacy"
                      rel="noreferrer"
                    >
                      개인정보처리방침
                    </a>
                  </Title>
                </P>
              </div>
              <div>
                <P>
                  <Title>{translate('CONTACT_TO')}</Title>
                  <Contents>
                    <a href="mailto:cs@carecrm.co.kr">cs@carecrm.co.kr</a>
                  </Contents>
                </P>
              </div>
            </Footer>
            <Copyright>COPYRIGHT © carelabs All rights reserved</Copyright>
          </FooterWrapper>
        </div>
      </LoginWrapper>
    </Wrapper>
  );
};

Login.propTypes = {};

export default observer(Login);
