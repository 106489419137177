import { currency } from 'filters';

export default {
  keys: [
    {
      title: '카테고리',
      column: 'category__name',
      filter: (o) => {
        if (o.category === undefined) {
          return `<div style="position: relative; width: 125px; text-align: center;">
                <div class="flex-wrap"
                    style="max-width: 125px; white-space: break-spaces; display: inline-block;">${
                      o.categoryName || ''
                    }</div></div>`;
        } else {
          return `<div style="position: relative; width: 125px; text-align: center;">
                <div class="flex-wrap"
                    style="max-width: 125px; white-space: break-spaces; display: inline-block;">${
                      o.category.name || ''
                    }</div></div>`;
        }
      },
    },
    {
      title: '시/수술명',
      column: 'name',
      filter: (o) => {
        return `<div style="position: relative; width: 180px; text-align: center;">
            <div class="flex-wrap"
                style="max-width: 180px; white-space: break-spaces; display: inline-block;">${
                  o['name'] || ''
                }${
          o['FREE_SERVICE_$$checked'] || o['isFree']
            ? '<div class="flex-wrap ico-service"></div>'
            : ''
        }</div>
            </div>`;
      },
    },
    {
      title: 'SURGERY_NUMOF',
      column: 'treatmentCount',
    },
    {
      title: 'NOT_VAT_INCLUDE',
      column: 'price',
      filter: (o) =>
        `<span class="text-underline">₩${currency(o.price)}</span>`,
    },
    {
      title: 'PRICE_VAT_INCLUDE',
      column: 'priceVatInclude',
      filter: (o) =>
        `<span class="text-underline">₩${currency(o.priceVatInclude)}</span>`,
    },
    {
      title: '할인',
      column: 'discountAmount',
      filter: (o) =>
        `<span class="text-underline">₩${currency(
          o.discountAmount || 0
        )}</span>`,
    },
    {
      title: '청구액',
      column: 'requestAmount',
      filter: (o) => {
        if (o.requestAmount === undefined) {
          return `₩${currency(o.priceVatInclude - (o.discountAmount ?? 0))}`;
        } else {
          return `₩${currency(o.requestAmount)}`;
        }
      },
    },
    {
      title: '',
      filter: () => {
        return `<div class="action-delete"><i class="zmdi zmdi-minus" style="font-size: 12px;" ></i></div>`;
      },
    },
  ],
};
