import * as $http from 'axios';

export default {
  // 처방전
  prescription: {
    all: (params) => $http.get(`/prescription`, { params }),
    all_v2: (params) => $http.get(`/prescription/v2`, { params }),
    create: (payload) => $http.post(`/prescription`, payload),
    create_v2: (payload) => $http.post(`/prescription/v2`, payload),
    update: (payload) => $http.put(`/prescription`, payload),
    update_v2: (payload) => $http.put(`/prescription/v2`, payload),
    delete: (params) => $http.delete(`/prescription`, { params }),
    delete_v2: (params) => $http.delete(`/prescription/v2`, { params }),
  },

  prescription_list: {
    all: (params) => $http.get(`/prescription/list`, { params }),
    all_v2: (params) => $http.get(`/prescription/v2/list`, { params }),
  },

  prescription_code: {
    all: (params) => $http.get(`/prescription/v2/codes`, { params }),
  },

  // 묶음 처방전
  prescription_collection: {
    all: (params) => $http.get(`/prescription/medicine/collection`, { params }),
    all_v2: (params) =>
      $http.get(`/prescription/v2/medicine/collection`, { params }),
    create: (payload) =>
      $http.post(`/prescription/medicine/collection`, payload),
    delete: (params) =>
      $http.delete(`/prescription/medicine/collection`, { params }),
  },

  prescription_collection_list: {
    all: (params) =>
      $http.get(`/prescription/medicine/collection/list`, { params }),
    all_v2: (params) =>
      $http.get(`/prescription/v2/medicine/collection/list`, { params }),
  },

  // 약품/주사제
  drug_list: {
    all: (params) => $http.get(`/prescription/drug/list`, { params }),
    all_v2: (params) => $http.get(`/prescription/v2/drug/list`, { params }),
  },
};
