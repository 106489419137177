import PropTypes from 'prop-types';
import React from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';

const Input = styled.input`
  font-size: 14px;
  background-color: #efeff4;
  color: #ceced7;
  min-height: 10px;
  padding: 16px 20px;
  border-radius: 4px;
  border: 1px solid #dee2ec;
  width: 100%;
  height: 34px;
`;

const ModalDepartmentAbsence = ({ options, close }) => {
  const onClickSave = (e, v) => {
    close(v);
    return;
  };

  return (
    <div className="modal-department-absense">
      <div className="head flex-row flex-between items-start">
        <div className="title">휴진설정</div>
        <i onClick={() => close()} className="zmdi zmdi-close" />
      </div>
      <div className="body">
        <div className="flex-row">
          <div className="title">부서명</div>
          <Input
            placeholder={(options.department || {}).name}
            name="name"
            value=""
            disabled
          />
        </div>
        <div className="flex-row">
          <div className="title">
            <span>휴진일</span>
            <div className="title-special">*</div>
          </div>
          <Input
            placeholder={options.targetDate}
            name="name"
            value=""
            disabled
          />
        </div>
      </div>
      <div className="buttons">
        <div className="flex-row">
          <button
            onClick={(e) => onClickSave(e, false)}
            className="btn btn-default"
          >
            취소
          </button>
          <button
            onClick={(e) => onClickSave(e, true)}
            className="btn btn-basic"
          >
            저장
          </button>
        </div>
      </div>
    </div>
  );
};

ModalDepartmentAbsence.propTypes = {
  options: PropTypes.object,
  close: PropTypes.func,
};

export default observer(ModalDepartmentAbsence);
