import React, { useState, useEffect, useCallback } from 'react';
import { observer } from 'mobx-react';
import InputCurrency from 'components/common/InputCurrency';
import PropTypes from 'prop-types';

const ChangePrice = ({ payments, setPayments }) => {
  // eslint-disable-next-line no-unused-vars
  const [priceVatInclude, setPriceVatInclude] = useState();

  const priceSetting = useCallback(() => {
    setPriceVatInclude(payments.priceVatInclude);
  }, [payments]);

  useEffect(() => {
    priceSetting();
  }, [priceSetting]);

  const onChangePrice = (e) => {
    setPayments({ ...payments, priceVatInclude: Number(e.target.value) });
    setPriceVatInclude(Number(e.target.value));
  };

  return (
    <React.Fragment>
      <div className="form-control m-t-16">
        <InputCurrency
          className={'item-currency m-l-8'}
          name={'price'}
          initValue={payments.priceVatInclude || 0}
          allowNegative={false}
          onValueChange={onChangePrice}
          countryCode={'kr'}
          disabled={false}
        />
      </div>
    </React.Fragment>
  );
};

ChangePrice.propTypes = {
  payments: PropTypes.object,
  setPayments: PropTypes.func,
};

export default observer(ChangePrice);
