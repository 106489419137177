import React from 'react';
import { observer } from 'mobx-react';
import './ctiRingingPopup.scss';
import useCtiStore from './hooks/useCtiStore';
import hooks from 'hooks';
import CtiGuideTip from './CtiGuideTip';
import classNames from 'classnames';
import { CounselingResult } from './counselingResult';
import { useToast } from 'hooks/useToast';

const CtiRingingPopup = observer(function CtiRingingPopup({ call, index }) {
  const toast = useToast();
  const ctiStore = useCtiStore();

  function onClickDashboard() {
    hooks.openCustomerChart({
      customer: {
        id: call.customerData.id,
      },
    });
  }

  function onClose() {
    ctiStore.hideCall(call);
  }

  function onStart() {
    if (ctiStore.currentCall) {
      toast.error('ERROR_CTI_CLOSE_FIRST');
      return;
    }

    ctiStore.hideCall(call);
    ctiStore.startCall(call);
  }

  const infos = [];

  if (ctiStore.settings.showGrade && call.customerData) {
    infos.push(call.customerData.level || '등급 없음');
  }

  if (ctiStore.settings.showCounselor && call.customerData) {
    infos.push(call.customerData.counselorName || '상담사 없음');
  }

  if (ctiStore.settings.showDoctor && call.customerData) {
    infos.push(call.customerData.doctorName || '의사 없음');
  }

  if (ctiStore.settings.showCaller && call.customerData) {
    infos.push(call.customerData.lastCallUserName || '최근 통화자 없음');
  }

  if (ctiStore.settings.showResult && call.customerData) {
    infos.push(
      call.customerData.lastCallCounselingResult
        ? CounselingResult.toName(call.customerData.lastCallCounselingResult)
        : '최근 통화결과 없음'
    );
  }

  return (
    <div className={classNames(`cti-ringing-popup`)}>
      <div className="profile-box">
        <div className="profile-head">
          {call.customerData && (
            <>
              <span className="profile-name">{call.customerData.name}</span>
              <CtiGuideTip
                id="ringing-dashboard"
                text="통합 차트 이동"
                show={true}
              >
                <button
                  className="dashboard-button"
                  onClick={onClickDashboard}
                ></button>
              </CtiGuideTip>
            </>
          )}
          {!call.customerData && (
            <>
              <span className="profile-name">{call.phoneNumber}</span>
            </>
          )}
        </div>
        <div className="profile-detail">
          {call.customerData && (
            <>
              {infos.map((info, i) => (
                <React.Fragment key={info}>
                  <span className="profile-info-item">{info}</span>
                  {i !== infos.length - 1 && (
                    <span className="profile-separator">|</span>
                  )}
                </React.Fragment>
              ))}
            </>
          )}
          {!call.customerData && (
            <span className="profile-description">신규 회원입니다 :)</span>
          )}
        </div>
      </div>
      <div className="action-box">
        <button className="start-button" onClick={onStart}>
          시작
        </button>
        <button className="close-button" onClick={onClose}>
          닫기
        </button>
      </div>
    </div>
  );
});

export default CtiRingingPopup;
