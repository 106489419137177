import React from 'react';
import { observer } from 'mobx-react';
import PenChartsDrive from '../PenChartsDrive';
import PropTypes from 'prop-types';

const CustomerPenCharts = ({ customer, scrollY, height }) => {
  return (
    <div className="customer-pen-charts chart-page">
      <PenChartsDrive customer={customer} scrollY={scrollY} height={height} />
    </div>
  );
};

CustomerPenCharts.propTypes = {
  customer: PropTypes.object,
  scrollY: PropTypes.number,
  height: PropTypes.number,
};

export default observer(CustomerPenCharts);
