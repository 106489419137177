import React, { useEffect } from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useAuth } from 'store/auth';
import { useAutoRefresh } from 'hooks/useAutoRefresh';
import Login from 'components/pages/Login';
import DocsCertifications from 'components/pages/docs/Certifications';
import Privacy from 'components/pages/docs/Privacy';
import { CrmRouter } from './CrmRouter';
import EditImage from 'components/pages/crm/image-editor/EditImage';
import { PrivateRoute } from './PrivateRoute';

export const RootRouter = observer(() => {
  const auth = useAuth();
  const history = useHistory();
  const currentPath = (history || {}).location.pathname;
  useAutoRefresh([currentPath]);

  useEffect(() => {
    if (!auth.me) return;
  }, [auth.me]);
  if (!auth.loaded) return null;

  return (
    <Switch>
      <Route path="/login" component={Login} />
      <Route exact path="/docs/certifications" component={DocsCertifications} />
      <Route exact path="/privacy" component={Privacy} />

      {/* WAS NOT REACT COMPONENT */}
      <Route
        exact
        path="/policy/privacy_collect"
        onEnter={() => window.location.reload()}
      />
      <Route
        exact
        path="/policy/privacy_commission"
        onEnter={() => window.location.reload()}
      />
      <Route
        path="/crm/files/alim-talk/template-guide/v201911"
        component={() => {
          window.location.href =
            'https://unobeautycrm-assets.s3.ap-northeast-2.amazonaws.com/assets/alimtalk_template_guide_1911.pdf';
        }}
      />
      <Route path="/event_apply" onEnter={() => window.location.reload()} />

      {/* 펜차트 이미지 에디터 */}
      <PrivateRoute path="/image/:id" component={EditImage} />
      {/* 전후사진비교 */}
      <PrivateRoute
        path="/before_and_after"
        onEnter={() => window.location.reload()}
      />
      <PrivateRoute path="/crm" component={CrmRouter} />
      <Route path="/">
        <Redirect to="/crm/home" />
      </Route>
    </Switch>
  );
});
