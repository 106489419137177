import * as moment from 'moment';
import { translate } from 'filters';

export default {
  keys: [
    {
      //     type: 'multiCheck',
      //     column: '',
      //     checkUse: o => 'false',
      //   },{
      title: 'STATUS',
      noorder: true,
      filter: () => {
        return `<div class="text-center">
                <span class="text-underline c-red">삭제</span>
            </div>`;
      },
    },
    {
      title: '등록일',
      column: 'createdAt',
      type: 'date',
      nosearch: true,
    },
    {
      title: 'LABEL_CONDITION_CUSTOMER_LASTVISITAT',
      column: 'lastVisitAt',
      // TODO: 추후 정렬 추가 작업시 noorder 삭제
      noorder: true,
      type: 'date',
      nosearch: true,
    },
    {
      title: 'lastVisitStartAt',
      column: 'lastVisitStartAt',
      selectType: 'date',
      hideColumn: true,
      customParamFieldName: 'lastVisitStartAt',
      filter: (o) => {
        return `<div>
            <div>${moment(o.lastVisitStartAt).format('YYYY-MM-DD')} ${
          o.lastVisitStartAt
        }</div>
            </div>`;
      },
    },
    {
      title: 'lastVisitEndAt',
      column: 'lastVisitEndAt',
      selectType: 'date',
      hideColumn: true,
      customParamFieldName: 'lastVisitEndAt',
      filter: (o) => {
        return `<div>
            <div>${moment(o.lastVisitEndAt).format('YYYY-MM-DD')} ${
          o.lastVisitEndAt
        }</div>
            </div>`;
      },
    },
    {
      title: '고객명',
      column: 'name',
      noorder: true,
      customParamFieldName: 'name',
      filter: (o) =>
        `<span class="text-underline c-primary">${o.name ?? ''}</span>`,
    },
    {
      title: 'CHART_NO',
      column: 'chartNo',
      noorder: true,
      customParamFieldName: 'chartNo',
    },
    {
      title: 'TYPE',
      noorder: true,
      customParamFieldName: 'type',
      selectType: 'checkbox',
      selectOptions: [
        {
          $$label: '내국인',
          $$value: 'domestic',
        },
        {
          $$label: '외국인',
          $$value: 'foreigner',
        },
      ],
      filter: (o) => translate((o.type || '').toUpperCase()),
    },
    {
      title: 'BIRTHDAY',
      column: 'birthday',
      type: 'date',
      nosearch: true,
    },
    {
      title: 'SEX',
      noorder: true,
      customParamFieldName: 'sex',
      selectType: 'checkbox',
      selectOptions: [
        {
          $$label: 'MALE',
          $$value: 'male',
        },
        {
          $$label: 'FEMALE',
          $$value: 'female',
        },
      ],
      filter: (o) => translate((o.sex || '').toUpperCase()),
    },
    {
      title: 'PHONE_NUMBER',
      column: 'phoneNumber',
      noorder: true,
      customParamFieldName: 'phoneNumber',
      filter: (o) => {
        if (o.phoneNumber) {
          let phoneNumber = (o.phoneNumber || '')
            .replace(/[^0-9]/g, '')
            .replace(
              /(^02|^0505|^1[0-9]{3}|^0[0-9]{2})([0-9]+)?([0-9]{4})/,
              '$1-$2-$3'
            )
            .replace('--', '-');
          return phoneNumber;
        }
      },
    },
    {
      title: 'SMS_ENABLED',
      noorder: true,
      customParamFieldName: 'smsEnabled',
      selectType: 'checkbox',
      selectOptions: [
        {
          $$label: '동의',
          $$value: true,
        },
        {
          $$label: '거부',
          $$value: false,
        },
      ],
      filter: (o) => (o.smsEnabled ? '동의' : '거부'),
    },
    {
      title: 'EVENT_SMS_ENABLED',
      noorder: true,
      customParamFieldName: 'eventSmsEnabled',
      selectType: 'checkbox',
      selectOptions: [
        {
          $$label: '동의',
          $$value: true,
        },
        {
          $$label: '거부',
          $$value: false,
        },
      ],
      filter: (o) => (o.eventSmsEnabled ? '동의' : '거부'),
    },
    {
      title: 'FAVORITES',
      noorder: true,
      type: 'list_mini',
      customParamFieldName: 'favoriteId',
      filter: (o) => {
        if ((o.favorites || []).length === 0) return;
        return `${o.favorites
          .map(
            (treatmentItemCategory) => `<p>${treatmentItemCategory.name}</p>`
          )
          .join('\n')}`;
      },
    },
    {
      title: 'COUNSELORS',
      column: 'counselor__name',
      noorder: true,
      customParamFieldName: 'counselorId',
    },
    {
      title: 'DOCTORS',
      column: 'doctor__name',
      noorder: true,
      customParamFieldName: 'doctorId',
    },
    {
      title: 'ACQUISITION_CHANNELS',
      column: 'acquisitionChannel__name',
      noorder: true,
      nosearch: true,
    },
    {
      title: 'RECOMMENDER',
      column: 'recommenderName',
      noorder: true,
      customParamFieldName: 'recommenderName',
    },
    {
      title: 'REFERENCE_COUNT',
      column: 'recommendCount',
      nosearch: true,
    },
    {
      title: 'JOBS',
      column: 'job__name',
      noorder: true,
      customParamFieldName: 'jobId',
    },
    {
      title: 'LEVELS',
      column: 'level__name',
      noorder: true,
      customParamFieldName: 'levelId',
    },
    {
      title: 'COMPLAINTS',
      column: 'complaint__content',
      noorder: true,
      customParamFieldName: 'complaintId',
    },
    {
      title: 'REGIONS',
      column: 'region__name',
      noorder: true,
      customParamFieldName: 'regionId',
    },
    {
      title: 'ADDRESS',
      column: 'address',
      noorder: true,
      nosearch: true,
    },
    {
      title: 'MEMO',
      column: 'memo',
      noorder: true,
      type: 'text',
      nosearch: true,
    },
    // {
    //     title: 'UPDATE_AT',
    //     column: 'updatedAt',
    //     filter: o => {
    //         return `<div>
    //             <div>${moment(o.updatedAt).format('YYYY-MM-DD')}</div>
    //             <div>${moment(o.updatedAt).format('HH:mm:ss')}</div>
    //         </div>`
    //     },
    //     nosearch: true,
    // }
  ],
};
