import PropTypes from 'prop-types';
import React from 'react';
import './toggleButton.scss';

export function ToggleButton({ value, onChange }) {
  function onClick() {
    if (onChange) {
      onChange(!value);
    }
  }

  return (
    <button
      className={`custom-toggle-button ${value ? 'on' : 'off'}`}
      onClick={onClick}
    >
      {value && (
        <div className="custom-toggle-button-on-text">
          <span>ON</span>
        </div>
      )}
      {!value && (
        <div className="custom-toggle-button-off-text">
          <span>OFF</span>
        </div>
      )}
      <div
        className={`custom-toggle-button-switch ${value ? 'on' : 'off'}`}
      ></div>
    </button>
  );
}

ToggleButton.propTypes = {
  value: PropTypes.bool,
  onChange: PropTypes.func,
};
