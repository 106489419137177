import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'filters';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import { useModal } from 'hooks/useModal';
import { useServices } from 'hooks/useServices';
import { useToast } from 'hooks/useToast';
import styled, { css } from 'styled-components';

const Input = styled.input`
  padding: 8px 10px 8px 20px;
  border-radius: 4px;
  font-size: 14px;
  border: 1px solid #dee2ec;
  height: 34px;
  background-color: #f3f8ff;
  width: 100%;

  ${({ disabled }) =>
    disabled &&
    css`
      background-color: #efeff4 !important;
      color: #ceced7;
    `}
`;

const ModalAddConsultingRequestsStatusCategories = ({ options, close }) => {
  const modal = useModal();
  const services = useServices();
  const toast = useToast();
  const [loadingBtnDisabledFlag, setLoadingBtnDisabledFlag] = useState(false);
  const [selectCategory, setSelectCategory] = useState(
    options.props === undefined ? options.categories[0] : options.props
  );
  const [
    defaultConsultingRequestStatus,
    setDefaultConsultingRequestStatus,
  ] = useState((options.props || {}).consultingRequestStatus || []);
  const [consultingRequestStatus, setConsultingRequestStatus] = useState(
    options.props === undefined ? [{ name: '', visible: true }] : []
  );

  const onChangeCategory = useCallback(
    async (e) => {
      let changeCategory = options.categories.filter((v) => {
        return v.id === Number(e.target.value);
      });

      setSelectCategory(...changeCategory);
    },
    [options.categories]
  );

  const addItem = useCallback(async () => {
    setConsultingRequestStatus([
      ...consultingRequestStatus,
      { name: '', visible: true },
    ]);
  }, [consultingRequestStatus]);

  const deleteDefaultItem = useCallback(
    async (idx) => {
      let list = defaultConsultingRequestStatus;
      //리스트 삭제
      list.splice(idx, 1);
      setDefaultConsultingRequestStatus([...toJS(list)]);
    },
    [defaultConsultingRequestStatus]
  );

  const deleteItem = useCallback(
    async (idx) => {
      let list = consultingRequestStatus;
      //리스트 삭제
      list.splice(idx, 1);
      setConsultingRequestStatus([...toJS(list)]);
    },
    [consultingRequestStatus]
  );

  const onChangeDefaultItem = useCallback(
    async (e, i) => {
      //기존에 저장되어있던 defaultConsultingRequestStatus의 변경
      let list = defaultConsultingRequestStatus.map((v, idx) => {
        if (i === idx) {
          v.name = e.target.value;
        }
        return v;
      });
      setDefaultConsultingRequestStatus([...list]);
    },
    [defaultConsultingRequestStatus]
  );

  const onChangeItem = useCallback(
    async (e, i) => {
      let list = consultingRequestStatus.map((v, idx) => {
        if (i === idx) {
          v.name = e.target.value;
        }
        return v;
      });
      setConsultingRequestStatus([...list]);
    },
    [consultingRequestStatus]
  );

  const validator = () => {
    if (options.props === undefined) {
      let filter = consultingRequestStatus.filter((v) => {
        return v.name.trim() !== '';
      });

      if (filter.length === 0) {
        toast.error('응대 처리결과를 입력하세요');
        setLoadingBtnDisabledFlag(false);
        return false;
      }

      if (selectCategory === undefined) {
        toast.error('카테고리를 선택하세요');
        setLoadingBtnDisabledFlag(false);
        return false;
      }
    }

    return true;
  };

  const updateCallApi = useCallback(
    async (id, payload) => {
      try {
        await services.crm.consultingRequest.requests_categories.update(
          id,
          payload
        );
        modal
          .confirm({
            type: 'SUCCESS',
            msg: '업데이트되었습니다.',
          })
          .then(() => {
            setLoadingBtnDisabledFlag(false);
            close();
          });
      } catch (e) {
        console.log(e.description);
        let message =
          e.description === 'ConsultingRequestStatusExists'
            ? '중복된 명칭이 있습니다.'
            : e.description;
        modal
          .confirm({
            type: 'ERROR',
            msg: message,
          })
          .then(() => {
            setLoadingBtnDisabledFlag(false);
          });
      }
    },
    [services.crm.consultingRequest.requests_categories, toast, close]
  );

  const onClickSave = useCallback(async () => {
    setLoadingBtnDisabledFlag(true);
    let endpoint = options.props === undefined ? 'create' : 'update';

    if (validator()) {
      let mergeConsultingRequestStatus;
      if (endpoint === 'create') {
        let lastOrder = 0;
        //body json에 id를 거른 값: originConsultingRequestStatus
        let originConsultingRequestStatus = selectCategory.consultingRequestStatus.filter(
          (v) => {
            // eslint-disable-next-line no-unused-vars
            let { id: deletedKey, ...otherKeys } = v;
            lastOrder = v.order;
            return otherKeys;
          }
        );

        let filter = consultingRequestStatus.filter((v) => {
          if (v.name.trim() !== '') {
            lastOrder++;
            v.order = lastOrder;
          }
          return v.name.trim() !== '';
        });
        mergeConsultingRequestStatus = [
          ...originConsultingRequestStatus,
          ...filter,
        ];
      } else {
        let lastOrder = 0;
        let originConsultingRequestStatus = defaultConsultingRequestStatus.filter(
          (v) => {
            let {
              // eslint-disable-next-line no-unused-vars
              'key@@child': deletedKey1,
              // eslint-disable-next-line no-unused-vars
              'exploded@@order': deletedKey2,
              ...otherKeys
            } = v;
            lastOrder = v.order;
            return toJS({ otherKeys });
          }
        );

        let filter = consultingRequestStatus.filter((v) => {
          if (v.name.trim() !== '') {
            lastOrder++;
            v.order = lastOrder;
          }
          return v.name.trim() !== '';
        });

        mergeConsultingRequestStatus = [
          ...originConsultingRequestStatus,
          ...filter,
        ];
      }

      const payload = {
        id: selectCategory.id,
        consultingRequestStatus: mergeConsultingRequestStatus,
      };

      updateCallApi(selectCategory.id, payload);
    }
  }, [consultingRequestStatus, selectCategory, defaultConsultingRequestStatus]);

  return (
    <div className="modal-status-categories items-group">
      <div className="head flex-row flex-between items-start">
        <div className="title">
          {options.props === undefined
            ? '상담문의 응대 처리결과 추가'
            : '상담문의 응대 처리결과 수정'}
        </div>
        <i onClick={() => close()} className="zmdi zmdi-close" />
      </div>
      <div className="body">
        <div>
          <div>
            <span>카테고리 선택</span>
          </div>
          <div>
            {options.props === undefined ? (
              <select onChange={onChangeCategory}>
                {options.categories.map((v, i) => {
                  //default 선택값 종류
                  //- 응대 진행중, 상담문의 응대실패 (응대 성공은 수정,추가 불가능)
                  if (v.name !== '응대 성공') {
                    return (
                      <option key={i} value={v.id}>
                        {v.name}
                      </option>
                    );
                  } else {
                    return null;
                  }
                })}
              </select>
            ) : (
              <Input type="text" disabled value={options.props.name} />
            )}
          </div>
        </div>
        <div className="row row-separator">
          <div className="col-1"></div>
          <div className="col-12 row-border"></div>
        </div>
        <div className="request-result m-t-8">
          <div className="row-title">응대 처리결과</div>
          <div className="row-body">
            {options.props !== undefined
              ? defaultConsultingRequestStatus.map((v, i) => {
                  return (
                    <div className="flex-row" key={i}>
                      <Input
                        type="text"
                        value={v.name}
                        onChange={(e) => onChangeDefaultItem(e, i)}
                      />
                      {v.defaultCode === null ? (
                        <button
                          className="btn btn-sm btn-danger flex-wrap"
                          onClick={() => deleteDefaultItem(i)}
                          disabled={v.name === ''}
                        >
                          삭제
                        </button>
                      ) : null}
                    </div>
                  );
                })
              : null}
            {consultingRequestStatus.map((v, i) => {
              return (
                <div className="flex-row m-b-8" key={i}>
                  <Input
                    type="text"
                    placeholder="응대 처리결과를 입력하세요"
                    value={v.name}
                    onChange={(e) => onChangeItem(e, i)}
                  />
                  {(i !== 0 || options.props !== undefined) && (
                    <button
                      className="btn btn-sm btn-danger flex-wrap m-l-4"
                      onClick={() => deleteItem(i)}
                      disabled={v.name === ''}
                    >
                      삭제
                    </button>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <button
        className="btn btn-primary _small m-t-8 w-100"
        onClick={() => addItem()}
      >
        입력란 추가
      </button>
      <div className="buttons">
        <div className="flex-row">
          <button onClick={close} className="btn btn-default">
            {translate('CANCEL')}
          </button>
          <button
            onClick={onClickSave}
            className="btn btn-primary"
            disabled={loadingBtnDisabledFlag}
          >
            {translate('SAVE')}
          </button>
        </div>
      </div>
    </div>
  );
};

ModalAddConsultingRequestsStatusCategories.propTypes = {
  options: PropTypes.object,
  close: PropTypes.func,
};

export default observer(ModalAddConsultingRequestsStatusCategories);
