import React, { useContext, useEffect } from 'react';
import { observer } from 'mobx-react';
import ContextMenu from './ContextMenu';
import DepartmentContextMenu from './DepartmentContextMenu';
import AppointmentHoverCard from './AppointmentHoverCard';
import { AppointmentBoardStateContext } from './AppointmentBoards';
import WeeklyBoard from './WeeklyBoard';
import DailyBoard from './DailyBoard';
import AppointmentMonthlyBoard from './AppointmentMonthlyBoard';
import { useElementSize } from 'hooks/useElementSize';

// calendarUnit: 일, 주, 월 단위로 다른 모양의 시간표를 보여주는 컴포넌트
const ConditionalBoard = () => {
  /**
   * @type {AppointmentBoardState }
   */
  const state = useContext(AppointmentBoardStateContext);

  const [rect, rectRef] = useElementSize();

  useEffect(() => {
    state.setCalendarRect(rect);
  }, [rect]);

  //세로뷰
  return (
    <div
      ref={rectRef}
      className={`unit-60 conditional-board ${
        state.calendarUnit.indexOf('-') > -1 ? 'week' : state.calendarUnit
      } ${!state.filters.department ? 'squeeze' : ''} ${state.mode} ${
        state.tempFilterStatusOld ? 'old' : 'new'
      } ${state.cellSize}`}
    >
      <ContextMenu />
      <DepartmentContextMenu />
      <AppointmentHoverCard />
      {state.calendarUnit === 'day' && state.workTimes ? <DailyBoard /> : null}
      {state.calendarUnit === '3-day' && state.workTimes ? (
        <WeeklyBoard />
      ) : null}
      {state.calendarUnit === 'week' && state.workTimes ? (
        <WeeklyBoard />
      ) : null}
      {state.calendarUnit === 'month' && state.workTimes ? (
        <AppointmentMonthlyBoard />
      ) : null}
    </div>
  );
};

export default observer(ConditionalBoard);
