import React, { useState, useEffect, useCallback } from 'react';
import { observer } from 'mobx-react';
import moment from 'moment';
import SalesStatistics from 'components/pages/crm/statistics/SalesStatistics';
import { $qb } from 'scripts/querybuilder';
import { useServices } from 'hooks/useServices';

const Statistics = () => {
  const services = useServices();
  const [params] = useState($qb());
  const [apiData, setApiData] = useState(null);

  const callApi = useCallback(async () => {
    let startAt = new Date();
    let startMonth = startAt.getMonth();
    startAt.setMonth(startMonth - 6 - 1);

    let search_start_at = moment(startAt).startOf('month').format('YYYY-MM-DD');
    let search_end_at = moment(new Date()).endOf('month').format('YYYY-MM-DD');

    params
      .customParam('searchStartAt', search_start_at)
      .customParam('searchEndAt', search_end_at);
    const resp = await services.crm.statistics.statistics.summarize_v2(
      params.build()
    );
    if (!resp) return;

    setApiData(resp);
  }, [services, params]);

  useEffect(() => {
    callApi();
  }, [callApi]);

  return (
    <div className={`statistics list inquiry`}>
      <div className="page-navi">
        <span className="title">통계</span>
        <span className="title">주요 통계 요약</span>
      </div>
      <div className="route-top statistics">
        <div className="statistics-contents">
          <div className="card">
            <SalesStatistics apiData={apiData} />
          </div>
          {/* <div className="card">
          <PaymentsStatistics apiData={apiData} />
        </div> */}
          {/* <div className="card">
            <ConversionStatistics />
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default observer(Statistics);
