import React from 'react';
import PropTypes from 'prop-types';

const LimitSetter = ({ limit, onLimit }) => {
  return (
    <div className="limit-setter flex-row">
      {[10, 20, 50, 100].map((value) => (
        <div
          onClick={() => onLimit(value)}
          key={value}
          className={`page-icon flex-wrap ${value === limit ? 'selected' : ''}`}
        >
          {value}
        </div>
      ))}
    </div>
  );
};

LimitSetter.propTypes = {
  limit: PropTypes.number,
  onLimit: PropTypes.func,
};

export default LimitSetter;
