import { currency, translate } from 'filters';

export default {
  keys: [
    {
      title: 'ACTION',
      noorder: true,
      filter: (o) => `
            ${
              o.visible
                ? `<button class="btn btn-sm btn-primary m-r-8">수정</button>`
                : ''
            }
            <button class="btn btn-sm m-r-8 ${
              o.visible ? 'btn-black' : 'btn-gray'
            }">${o.visible ? '미사용' : '사용'}</button>
            <button class="btn btn-sm m-r-8 btn-danger" data-type="delete" >삭제</button>`,
    },
    {
      title: '제품명',
      column: 'name',
      noorder: true,
      type: 'editable',
    },
    {
      title: 'VAT 미포함가격',
      noorder: true,
      filter: (o) => `₩${currency(o.price)}`,
    },
    {
      title: 'VAT포함가격',
      noorder: true,
      filter: (o) => `₩${currency(o.priceVatInclude)}`,
    },
    {
      title: '과세여부',
      noorder: true,
      filter: (o) =>
        o.vatIncluded ? translate('VAT_TRUE') : translate('VAT_FALSE'),
    },
    // {
    //     title: 'REIMBURSEMENT',
    //     column: 'reimbursement',
    //     filter: o => o.reimbursement ? '급여' : '비급여',
    // }
  ],
};
