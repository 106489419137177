export default {
  keys: [
    {
      title: 'CUSTOMER',
      column: 'name',
      class: 'text-underline c-primary',
    },
    {
      title: 'CHART_NO',
      column: 'chartNo',
    },
    {
      title: 'BIRTHDAY',
      column: 'birthday',
      type: 'date',
    },
    {
      title: 'SEX',
      column: 'sex',
    },
    {
      title: 'PHONE_NUMBER',
      column: 'phoneNumber',
    },
    {
      title: 'SMS_ENABLED',
      column: 'smsEnabled',
    },
    {
      title: 'EVENT_SMS_ENABLED',
      column: 'eventSmsEnabled',
    },
    {
      title: 'ACTION',
      filter: () => `<button class="btn btn-primary btn-sm">선택</button>`,
    },
  ],
};
