import React, { useState, useCallback } from 'react';
import { observer } from 'mobx-react';
import DataTableAlpha from 'components/data-table/DataTableAlpha';
import modelAppointmentUpdateAutoSms from 'models/appointment-update-auto-sms';
import PropTypes from 'prop-types';
import { $qb } from 'scripts/querybuilder';
import { useServices } from 'hooks/useServices';
import { useToast } from 'hooks/useToast';
import useCustomerChart from 'hooks/useCustomerChart';
import styled from 'styled-components';
import { RadioInput } from 'components/common/RadioInput';

const RadioInputWrapper = styled(RadioInput)`
  margin-right: 8px;
`;

const ModalAppointmentUpdateCompleteAutoSms = ({ options, close }) => {
  const services = useServices();
  const toast = useToast();
  const [smsCheck, setSmsCheck] = useState(true);
  const defaultParams = $qb()
    .limit(100)
    .customParam('status', 'waiting')
    .customParam('smsScheduleType', 'scheduled')
    .customParam('smsType', 'normal')
    .customParam('appointmentId', options.appointmentId);
  const [params, setParams] = useState(defaultParams);
  const [data, setData] = useState(options.data);
  const [smsAutoNotifications, setSmsAutoNotifications] = useState(
    options.notifications
  );

  const callApi = useCallback(async () => {
    const resp = await services.crm.notification.smsNotifications.ad.detail(
      params.build()
    );
    if (!resp) return;

    setData(resp.data);
  }, []);

  const onParams = (p) => {
    setParams(p);
    callApi();
  };

  return (
    <div className={`modal-complete-update-auto-sms`}>
      <div className="sub-title m-b-16">
        해당 예약건에, 전송대기중인 예약완료 자동문자가 있습니다.
      </div>
      <div className="auto-sms-container m-b-16">
        <DataTableAlpha
          model={modelAppointmentUpdateAutoSms}
          data={data}
          params={params}
          onParams={onParams}
          hideBottom={true}
        />
      </div>
      <p className="sub-title m-b-8">
        수정된 예약으로 전송대기중인 문자내용을 업데이트하시겠습니까?
      </p>
      <div className="m-b-8">
        <label>
          <RadioInputWrapper
            name="smsCheck"
            checked={smsCheck === true}
            onChange={() => {
              setSmsCheck(true);
            }}
          />
          <span className="flex-wrap m-r-16">
            수정된 예약으로 문자내용 변경
            {/* <span className="m-l-16 red-content">
              * 설정된 전송일은 그대로 유지됩니다.
            </span> */}
          </span>
        </label>
      </div>
      <div>
        <label>
          <RadioInputWrapper
            name="smsCheck"
            checked={smsCheck === false}
            onChange={() => {
              setSmsCheck(false);
            }}
          />
          <span className="flex-wrap m-r-16">기존 문자내용 유지</span>
        </label>
      </div>
      <div
        className="m-t-8 flex-row"
        style={smsCheck === false ? { color: 'gray' } : {}}
      >
        <span style={{ maxWidth: '125px' }}>자동문자 전송설정내용 : </span>
        <span style={{ float: 'right' }}>
          {useCustomerChart.SmsAutoNotificationsList(
            options.notifications,
            setSmsAutoNotifications,
            !smsCheck,
            options.scheduledAt
          )}
        </span>
      </div>
      <div className="buttons">
        <button
          className="btn btn-primary"
          onClick={() => {
            if (
              smsCheck &&
              smsAutoNotifications.every((e) => e['$$checked'] === false)
            ) {
              toast.error('최소 1개 이상의 전송할 예약 문자를 선택해 주세요.');
            } else {
              close({
                sendAutoSmsCheck: smsCheck,
                sendAutoSmsIds: smsAutoNotifications
                  .filter((f) => f['$$checked'])
                  .map((v) => {
                    return v.id;
                  }),
              });
            }
          }}
        >
          확인
        </button>
      </div>
    </div>
  );
};

ModalAppointmentUpdateCompleteAutoSms.propTypes = {
  options: PropTypes.shape({
    model: PropTypes.string,
    type: PropTypes.string,
    data: PropTypes.array,
    notifications: PropTypes.array,
    msgType: PropTypes.string,
    appointmentId: PropTypes.number,
    scheduledAt: PropTypes.string,
  }),
  close: PropTypes.func,
};

export default observer(ModalAppointmentUpdateCompleteAutoSms);
