import { makeAutoObservable } from 'mobx';
import { CallStatus } from './callStatus';
import { CentrexCallStatus } from './centrexCallStatus';
import { CounselingResult } from './counselingResult';
import { CtiStatusIconType } from './ctiStatusIconType';

export class Call {
  constructor(data) {
    Object.assign(this, data);
    makeAutoObservable(this);
  }

  id;
  createdAt;
  phoneNumber;
  callerId;
  callerName;
  centrexCallStatus;
  callStatus;
  counselingResult;
  customerData;
  ringing = true;

  get isRinging() {
    return (
      this.callStatus === CallStatus.none &&
      (this.centrexCallStatus === CentrexCallStatus.ringing ||
        this.centrexCallStatus === CentrexCallStatus.calling)
    );
  }

  get isCalling() {
    return this.callStatus === CallStatus.calling;
  }

  get isMissed() {
    return (
      this.callStatus === CallStatus.none &&
      this.centrexCallStatus === CentrexCallStatus.missed
    );
  }

  get isEnded() {
    return (
      this.callStatus === CallStatus.none &&
      this.centrexCallStatus === CentrexCallStatus.ended
    );
  }

  get isCompleted() {
    return this.callStatus === CallStatus.ended;
  }

  get isRecall() {
    return [
      CounselingResult.recall_need,
      CounselingResult.recall_first_absence,
      CounselingResult.recall_second_absence,
    ].includes(this.counselingResult);
  }

  get isHandled() {
    return [
      CounselingResult.counseling_by_kakaotalk,
      CounselingResult.response_complete_appointment,
      CounselingResult.response_complete_inquiry,
      CounselingResult.response_complete_recall,
      CounselingResult.response_complete_reject,
    ].includes(this.counselingResult);
  }

  get iconType() {
    if (this.isRinging) {
      return CtiStatusIconType.ringing;
    }

    if (this.isCalling) {
      return CtiStatusIconType.calling;
    }

    if (this.isMissed) {
      return CtiStatusIconType.missed;
    }

    if (this.isEnded) {
      return CtiStatusIconType.ended;
    }

    if (this.isRecall) {
      return CtiStatusIconType.recall;
    }

    if (this.isHandled) {
      return CtiStatusIconType.handled;
    }

    throw new Error(
      `Invalid icon type. centrexStatus: ${this.centrexCallStatus}, callStatus: ${this.callStatus}, counselingResult: ${this.counselingResult}`
    );
  }
}
