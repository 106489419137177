import moment from 'moment';
import { toThousandCommas } from 'filters';
import { mustParse } from 'utils/json';

export default {
  keys: [
    {
      title: '배정된 담당자',
      noorder: true,
      type: 'multiCheck',
      checkUse: (o) => (o.assignUserId !== null ? 'true' : 'false'),
      filter: (o) =>
        `<div class="text-center">${
          o.assignUserId !== null
            ? `<span>${o.assignUser.name}</span><button class="btn btn-change">변경하기</button>`
            : ''
        }</div>`,
    },
    {
      title: '처리결과',
      column: 'statusName',
      noorder: true,
      filter: (o) =>
        `<div class="text-center"
        style="
          ${
            o.status?.category?.name === '리콜 필요'
              ? o.greenFont
                ? `color: green`
                : o.redFont
                ? `color: #f7685b`
                : null
              : o.statusName === '미응대'
              ? `color: #f7685b`
              : null
          }"
        >${o.statusName}
        <div ${o.greenFont}>${
          o.status?.category?.name === '리콜 필요' && o.lastRecallAt
            ? ' ( 리콜 일시 : ' + o.lastRecallAt + ' )'
            : ''
        }</div>`,
    },
    {
      title: '상담문의일',
      column: 'requestAt',
      filter: (o) => moment(o.requestAt).format('YYYY-MM-DD HH:mm:ss'),
      thStyle: () => {
        return { width: '116px' };
      },
      tdDivStyle: () => {
        return {
          textAlign: 'center',
          width: '116px',
          textOverflow: 'ellipsis',
          whiteSpace: 'normal',
        };
      },
    },
    {
      title: '고객명',
      column: 'userName',
      noorder: true,
      thStyle: () => {
        return { width: '110px' };
      },
      tdDivStyle: () => {
        return {
          textAlign: 'center',
          width: '110px',
          textOverflow: 'ellipsis',
          whiteSpace: 'normal',
        };
      },
    },
    {
      title: '연락처',
      column: 'phoneNumber',
      noorder: true,
      filter: (o) =>
        `<div class="text-center"><span class="${
          o.customerId === null ? 'new-customer' : 'existing-customer'
        }">${
          o.customerId === null ? '신규고객' : '기존고객'
        }</span><div class="text-underline">${o.phoneNumber}</div></div>`,
    },
    {
      title: '매체',
      column: 'mediaId',
      noorder: true,
      filter: (o) => o.media?.tag,
      thStyle: () => {
        return { width: '110px' };
      },
      tdDivStyle: () => {
        return {
          textAlign: 'center',
          width: '110px',
          textOverflow: 'ellipsis',
          whiteSpace: 'normal',
        };
      },
    },
    {
      title: '이벤트명',
      column: 'eventName',
      noorder: true,
      tdDivStyle: () => {
        return {
          textAlign: 'center',
          width: '240px',
          textOverflow: 'ellipsis',
          whiteSpace: 'normal',
        };
      },
    },
    {
      title: '이벤트 유형',
      column: 'eventType',
      noorder: true,
    },
    {
      title: '이벤트 가격',
      column: 'price',
      noorder: true,
      filter: (o) => (o.price !== null ? toThousandCommas(o.price) : ''),
    },
    {
      title: '방문희망일',
      column: 'visitAt',
      filter: (o) =>
        o.visitAt !== null ? moment(o.visitAt).format('YYYY-MM-DD HH:mm') : '',
      thStyle: () => {
        return { width: '116px' };
      },
      tdDivStyle: () => {
        return {
          textAlign: 'center',
          width: '116px',
          textOverflow: 'ellipsis',
          whiteSpace: 'normal',
        };
      },
    },
    {
      title: '관심항목',
      column: 'interestItem',
      noorder: true,
      thStyle: () => {
        return { width: '180px' };
      },
      tdDivStyle: () => {
        return {
          textAlign: 'center',
          width: '180px',
          textOverflow: 'ellipsis',
          whiteSpace: 'normal',
        };
      },
    },
    {
      title: '고객문의',
      column: 'memo',
      noorder: true,
      thStyle: () => {
        return { width: '180px' };
      },
      tdDivStyle: () => {
        return {
          width: '180px',
          textOverflow: 'ellipsis',
          whiteSpace: 'normal',
        };
      },
    },
    {
      title: 'MEMO',
      noorder: true,
      filter: (o) => {
        let arr = mustParse(
          o.consultingRequestResult.length ? o.consultingRequestResult : ''
        );
        return `<div class="flex-row">
                <div class="memo-list">
                ${
                  (arr || []).length > 0
                    ? arr
                        .map(
                          (memoItem, idx) =>
                            `<div class="memo-item index-${idx}">${memoItem.memo}</div>`
                        )
                        .join('')
                    : ''
                }
                </div>
            </div>`;
      },
      thStyle: () => {
        return { width: '240px' };
      },
      tdDivStyle: () => {
        return {
          width: '240px',
          textOverflow: 'ellipsis',
          whiteSpace: 'normal',
        };
      },
      // style: {
      //     minWidth: '300px',
      // },
    },
  ],
};
