import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import { AppointmentBoardStateContext } from './AppointmentBoards';
import hooks from 'hooks';
import PropTypes from 'prop-types';
import { useServices } from 'hooks/useServices';
import { useToast } from 'hooks/useToast';

function rgb2hex(c) {
  if (!c) return;
  if (c.includes('#')) return c;
  return (
    '#' + c.match(/\d+/g).map((x) => (+x).toString(16).padStart(2, 0)).join``
  );
}

const MonthlyAppointmentCell = ({ appointment }) => {
  const services = useServices();
  const toast = useToast();
  /**
   * @type {AppointmentBoardState }
   */
  const state = useContext(AppointmentBoardStateContext);

  const colorConfig = state.stateColors[appointment.colorStatus];
  const colorCode = colorConfig
    ? rgb2hex(colorConfig.codeValueTemp || colorConfig.codeValue)
    : undefined;

  const customerDetailCallApi = async (customerId) => {
    try {
      const resp = await services.crm.customer.detail(customerId);
      if (!resp) return;
      return resp.data;
    } catch (e) {
      if (e.dscription === 'CustomerNotFound') {
        toast.error('삭제된 고객입니다.');
        return;
      }
    }
  };

  const showChart = async (e) => {
    e.preventDefault();

    const { customerId } = appointment;
    const customer = await customerDetailCallApi(customerId);

    hooks.openCustomerChart({
      customer,
      // openFlag,
      calledPage: 'boards',
    });
    state.setShowHoverCard(false);
  };

  const setHoverCardPos = async (e) => {
    state.setHoveringAppointment(appointment);
    state.setShowHoverCard(true);
    state.setHoverCardPosition({
      x: e.clientX,
      y: e.clientY + 10,
    });
  };

  const updateHoverCard = async () => {
    state.setHoveringAppointment(appointment);
  };

  const removeHoverCard = async () => {
    state.setShowHoverCard(false);
  };

  return (
    <div
      className={`monthly-appointment-cell`}
      onDoubleClick={(e) => {
        showChart(e);
      }}
      onMouseMove={(e) => setHoverCardPos(e)}
      onMouseEnter={(e) => updateHoverCard(e)}
      onMouseLeave={(e) => removeHoverCard(e)}
    >
      <div className={`item flex-row status ${appointment.status}`}>
        <span
          className="color"
          style={colorCode ? { backgroundColor: colorCode } : {}}
        ></span>
        <span className="schedule">{appointment.startHour} </span>
        <span className="customer">
          [{appointment.isNewCustomer ? '신환' : '구환'}]{' '}
          {appointment.customerName}
        </span>
        <span className="department">
          {' ' + appointment.departmentCategoryName}-
          {appointment.departmentName}
        </span>
        <span className="counselor">{appointment.startHour} </span>
        <span className="doctor">{appointment.startHour} </span>
      </div>
    </div>
  );
};

MonthlyAppointmentCell.propTypes = {
  appointment: PropTypes.object,
};

export default observer(MonthlyAppointmentCell);
