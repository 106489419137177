import React from 'react';
import { observer } from 'mobx-react';
import DepartmentCategories from './DepartmentCategories';
import AppointmentTime from './AppointmentTime';
import UnvisitedConfig from './UnvisitedConfig';

const AppointmentCodes = () => {
  return (
    <div className="appointment-codes">
      <DepartmentCategories />
      <AppointmentTime />
      <UnvisitedConfig />
    </div>
  );
};

export default observer(AppointmentCodes);
