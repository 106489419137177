import React, { useCallback, useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import {
  currency,
  removeCurrency,
  payoutCards,
  payoutBankTransfers,
  translate,
} from 'filters';
import Checkbox from 'components/app/Checkbox';
import QuillTextField from 'components/quill/QuillTextField';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useModal } from 'hooks/useModal';
import { useServices } from 'hooks/useServices';
import { useToast } from 'hooks/useToast';
import styled from 'styled-components';
import DatePicker from 'components/common/DatePicker';

const Input = styled.input`
  width: 150px;
  height: 34px;
  padding: 8px 20px;
  border-color: #d7e3f1;
  font-size: 12px;
  border-radius: 4px;
  background-color: #f3f8ff;
  border: 1px solid #dee2ec;
`;

const ModalPaymentsUnpaid = ({ options, close }) => {
  const modal = useModal();
  const services = useServices();
  const toast = useToast();
  const [loadingBtnDisabledFlag, setLoadingBtnDisabledFlag] = useState(false);
  const [parentObj, setParentObj] = useState({});
  const [memo, setMemo] = useState('');
  const [paidAt, setPaidAt] = useState(new Date());

  const [cashReceipt, setCashReceipt] = useState(false);
  const [payouts, setPayouts] = useState([]);
  const [payoutAmount, setPayoutAmount] = useState(0); //납부금액
  const [unpaidAmount, setUnpaidAmount] = useState(0); //미납금액
  const [remainingUnpaidAmount, setRemainingUnpaidAmount] = useState(0);

  const [payoutCreditItems, setPayoutCreditItems] = useState([
    {
      type: 'select',
      paymentMethod: 'credit_card',
      companyName: '',
      amount: 0,
    },
  ]);
  const [payoutDebitItems, setPayoutDebitItems] = useState([
    { type: 'select', paymentMethod: 'debit_card', companyName: '', amount: 0 },
  ]);
  const [payoutBankTransferItems, setPayoutBankTransferItems] = useState([
    {
      type: 'select',
      paymentMethod: 'bank_transfer',
      companyName: '',
      amount: 0,
    },
  ]);
  const [payoutCash, setPayoutCash] = useState(0);

  //각 결제수단별 수납 총 금액액
  const [creditCardsTotalAmount, setCreditCardsTotalAmount] = useState(0);
  const [debitCardsTotalAmount, setDebitCardsTotalAmount] = useState(0);
  const [bankTransferTotalAmount, setBankTransferTotalAmount] = useState(0);
  const [cashTotalAmount, setCashTotalAmount] = useState(0);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState({});

  const userCallApi = useCallback(async () => {
    let params = { userStatus: 'active' };
    const resp = await services.crm.user.duty(params);
    if (!resp) return;

    setUsers(resp.data);
  }, [services.crm.user]);

  useEffect(() => {
    userCallApi();
  }, [userCallApi]);

  const dataSettings = () => {
    const row = options.row || {};
    const { paymentMethod, amount, companyName, cashReceipt, paidAt } = row;

    if (paymentMethod === 'credit_card') {
      setCreditCardsTotalAmount(amount);
      setPayoutCreditItems([
        {
          type: 'select',
          paymentMethod: 'credit_card',
          companyName,
          amount,
        },
      ]);
    } else if (paymentMethod === 'debit_card') {
      setDebitCardsTotalAmount(amount);
      setPayoutDebitItems([
        {
          type: 'select',
          paymentMethod: 'debit_card',
          companyName,
          amount,
        },
      ]);
    } else if (paymentMethod === 'bank_transfer') {
      setBankTransferTotalAmount(amount);
      setPayoutBankTransferItems([
        {
          type: 'select',
          paymentMethod: 'bank_transfer',
          companyName,
          amount,
        },
      ]);
      setCashReceipt(cashReceipt);
    } else if (paymentMethod === 'cash') {
      setCashTotalAmount(amount);
      setPayoutCash(amount);
      setCashReceipt(cashReceipt);
    }

    setParentObj({
      ...options.parentRow,
      payouts: options.parentRow.payouts.filter((v) => v.id !== row.id),
    });
    setPayouts([...options.parentRow.payouts.filter((v) => v.id === row.id)]);
    setPayoutAmount(row.amount);
    setRemainingUnpaidAmount(options.parentRow.unpaidAmount);
    setUnpaidAmount(options.parentRow.unpaidAmount);
    setMemo(options.parentRow.memo);
    setPaidAt(paidAt || new Date());
    setSelectedUser({ name: row.createdName, id: row.createdId });
  };

  useEffect(() => {
    dataSettings();
  }, []);

  const deletePayoutCreditItem = (idx) => {
    let list = payoutCreditItems;
    const deleteItem = payoutCreditItems[idx];
    //리스트 삭제
    list.splice(idx, 1);
    setPayoutCreditItems([...list]);

    //삭제된 아이템의 금액만큼 수납액에서 마이너스
    setPayoutAmount(payoutAmount - deleteItem.amount);
    setRemainingUnpaidAmount(remainingUnpaidAmount + deleteItem.amount);
    setCreditCardsTotalAmount(creditCardsTotalAmount - deleteItem.amount);
  };

  const deletePayoutDebitItem = (idx) => {
    let list = payoutDebitItems;
    const deleteItem = payoutDebitItems[idx];
    //리스트 삭제
    list.splice(idx, 1);
    setPayoutDebitItems([...list]);
    //payouts
    let payoutsItems = payouts.filter((payout) => {
      return !(
        payout.paymentMethod === 'debit_card' &&
        payout.companyName === deleteItem.companyName
      );
    });
    setPayouts([...payoutsItems]);

    //삭제된 아이템의 금액만큼 수납액에서 마이너스
    setPayoutAmount(payoutAmount - deleteItem.amount);
    setRemainingUnpaidAmount(remainingUnpaidAmount + deleteItem.amount);
    setDebitCardsTotalAmount(debitCardsTotalAmount - deleteItem.amount);
  };

  const deletePayoutBankTransferItem = (idx) => {
    let list = payoutBankTransferItems;
    const deleteItem = payoutBankTransferItems[idx];
    //리스트 삭제
    list.splice(idx, 1);
    setPayoutBankTransferItems([...list]);

    //삭제된 아이템의 금액만큼 수납액에서 마이너스
    setPayoutAmount(payoutAmount - deleteItem.amount);
    setRemainingUnpaidAmount(remainingUnpaidAmount + deleteItem.amount);
    setBankTransferTotalAmount(bankTransferTotalAmount - deleteItem.amount);
  };

  const setPaymentPayload = () => {
    const status = unpaidAmount === 0 ? 'paid' : 'unpaid';

    const creditItems = payoutCreditItems
      .filter((v) => v.amount !== 0)
      .map((v) => {
        v.paidAt = moment(paidAt).format('YYYY-MM-DDTHH:mm:ss');
        v.createdName = selectedUser.name;
        v.createdId = selectedUser.id;
        // eslint-disable-next-line no-unused-vars
        const { type: deletedKey, ...otherKeys } = v;
        return otherKeys;
      });
    const debitItems = payoutDebitItems
      .filter((v) => v.amount !== 0)
      .map((v) => {
        v.paidAt = moment(paidAt).format('YYYY-MM-DDTHH:mm:ss');
        v.createdName = selectedUser.name;
        v.createdId = selectedUser.id;
        // eslint-disable-next-line no-unused-vars
        const { type: deletedKey, ...otherKeys } = v;
        return otherKeys;
      });
    const bankTransferItems = payoutBankTransferItems
      .filter((v) => v.amount !== 0)
      .map((v) => {
        v.paidAt = moment(paidAt).format('YYYY-MM-DDTHH:mm:ss');
        v.createdName = selectedUser.name;
        v.createdId = selectedUser.id;
        v.cashReceipt = cashReceipt;
        // eslint-disable-next-line no-unused-vars
        const { type: deletedKey, ...otherKeys } = v;
        return otherKeys;
      });

    let setPayoutItems = [
      ...payouts
        .map((v) => {
          //cashItems
          if (v.paymentMethod === 'cash') {
            v.paidAt = moment(paidAt).format('YYYY-MM-DDTHH:mm:ss');
            v.cashReceipt = cashReceipt;
          }
          v.createdName = selectedUser.name;
          v.createdId = selectedUser.id;
          return v;
        })
        .filter((v) => {
          return v.paymentMethod === 'cash' && v.amount !== 0;
        }),
      ...creditItems,
      ...debitItems,
      ...bankTransferItems,
    ];

    let payload = {
      id: parentObj.id,
      customerId: (parentObj.customer || {}).id,
      customer: parentObj.customer,
      totalAmount: parentObj.totalAmount,
      requestAmount: parentObj.requestAmount,
      payoutAmount: parentObj.payoutAmount - options.row.amount + payoutAmount,
      unpaidAmount: parentObj.unpaidAmount + options.row.amount - payoutAmount,
      paidAmount: parentObj.paidAmount - options.row.amount + payoutAmount,
      refundAmount: parentObj.refundAmount,
      createdId: parentObj.createdId,
      createdName: parentObj.createdName,
      memo: memo,
      payouts: [...parentObj.payouts, ...setPayoutItems],
      status,
      refunds: [...parentObj.refunds],
      discountReason: null,
      firstPaidAt: parentObj.firstPaidAt,
      reimbursementAmount: parentObj.reimbursementAmount,
      nonReimbursementAmount: parentObj.nonReimbursementAmount,
    };
    return payload;
  };

  const onClickSave = () => {
    const payload = setPaymentPayload();
    setLoadingBtnDisabledFlag(true);
    onClickSavePaymentUpdate(payload);
  };

  const onClickSavePaymentUpdate = useCallback(
    async (payload) => {
      try {
        const resp = await services.crm.payment.update(payload);
        if (!resp) return;

        modal
          .confirm({
            type: 'SUCCESS',
            msg: '업데이트되었습니다.',
          })
          .then(() => {
            setLoadingBtnDisabledFlag(false);
            close(1);
          });
      } catch (e) {
        console.log(e.description);
        modal
          .confirm({
            type: 'ERROR',
            msg: e.description,
          })
          .then(() => {
            setLoadingBtnDisabledFlag(false);
          });
      }
    },
    [services.crm.payment, toast, paidAt, close]
  );

  return (
    <div className="modal-payments-unpaid">
      <div className="head flex-row flex-between items-start">
        <div className="title">수정</div>
        <i onClick={() => close()} className="zmdi zmdi-close" />
      </div>
      <div className="body">
        <div className="flex-row">
          <div className="form-control flex-wrap" style={{}}>
            <label className="label-required">수납일</label>
            <div style={{ width: '180px' }}>
              <DatePicker
                value={paidAt ? new Date(paidAt) : setPaidAt(moment().format())}
                onChange={(e) =>
                  e === null
                    ? setPaidAt(moment().format())
                    : setPaidAt(moment(e).format())
                }
                dateFormat="yyyy/MM/dd"
              />
            </div>
          </div>
          <div className="form-control flex-wrap w-180">
            <label className="required">담당자</label>
            <select
              value={users.findIndex(
                (existing) => existing.id === (selectedUser || {}).id
              )}
              onChange={(e) => {
                setSelectedUser(users[e.target.value]);
              }}
            >
              <option disabled key={-1} value={-1}>
                -
              </option>
              {(users || []).map((o, idx) => (
                <option key={idx} value={idx}>
                  {o.name}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>

      <div className="flex-row">
        <div className="flex-wrap m-b-20 card-payouts">
          {payoutCreditItems.map((v, i) => {
            return (
              <div className="form-double-side" key={i}>
                <div className="form-control">
                  {i === 0 && <label>신용카드</label>}
                  {v.type === 'select' ? (
                    <select
                      value={v.companyName}
                      onChange={(e) => {
                        if (e.target.value === '직접입력') {
                          let list = payoutCreditItems.filter((v, idx) => {
                            if (i === idx) {
                              v.type = 'input';
                            }
                            return v;
                          });
                          setPayoutCreditItems([...list]);
                        } else {
                          let list = payoutCreditItems.filter((v, idx) => {
                            if (i === idx) {
                              v.companyName = e.target.value;
                            }
                            return v;
                          });
                          setPayoutCreditItems([...list]);
                        }
                      }}
                    >
                      <option value={''} hidden disabled>
                        카드사 선택
                      </option>
                      {payoutCards.map((bank, i) => (
                        <option key={i} value={bank}>
                          {bank}
                        </option>
                      ))}
                    </select>
                  ) : (
                    <Input
                      type="text"
                      placeholder="직접입력"
                      defaultValue={v.companyName}
                      onBlur={(e) => {
                        let list = payoutCreditItems.filter((v, idx) => {
                          if (i === idx) {
                            v.companyName = e.target.value;
                          }
                          return v;
                        });
                        setPayoutCreditItems([...list]);
                      }}
                    />
                  )}
                </div>
                <div className="form-control">
                  {i === 0 && <label>수납액</label>}
                  <Input
                    value={currency(v.amount)}
                    onBlur={(e) => {
                      let value = removeCurrency(e.target.value);
                      if (!isNaN(Number(value))) {
                        let cardTotal = 0;
                        payoutCreditItems.forEach((v, idx) => {
                          if (i === idx) {
                            cardTotal += Number(value);
                          } else {
                            cardTotal += v.amount;
                          }
                        });
                        const totalAmount =
                          cardTotal +
                          debitCardsTotalAmount +
                          bankTransferTotalAmount +
                          cashTotalAmount;
                        setCreditCardsTotalAmount(cardTotal);
                        setPayoutAmount(totalAmount);
                      }
                    }}
                    onChange={(e) => {
                      let value = removeCurrency(e.target.value);
                      if (!isNaN(Number(value))) {
                        let list = payoutCreditItems.filter((v, idx) => {
                          if (i === idx) {
                            v.amount = Number(value);
                          }
                          return v;
                        });
                        setPayoutCreditItems([...list]);
                      }
                    }}
                  />
                </div>
                {i === 0 && (
                  <button
                    className="btn btn-normal-gray _small"
                    onClick={() => {
                      setPayoutCreditItems([
                        ...payoutCreditItems,
                        {
                          type: 'select',
                          paymentMethod: 'credit_card',
                          companyName: '',
                          amount: 0,
                        },
                      ]);
                    }}
                  >
                    추가
                  </button>
                )}
                {i > 0 && (
                  <button
                    className="btn btn-danger _small"
                    onClick={() => {
                      deletePayoutCreditItem(i);
                    }}
                  >
                    삭제
                  </button>
                )}
              </div>
            );
          })}
        </div>

        <div className="flex-wrap m-b-20 card-payouts">
          {payoutDebitItems.map((v, i) => {
            return (
              <div key={i} className="form-double-side">
                <div className="form-control">
                  {i === 0 && <label>체크카드</label>}
                  {v.type === 'select' ? (
                    <select
                      value={v.companyName}
                      onChange={(e) => {
                        if (e.target.value === '직접입력') {
                          let list = payoutDebitItems.filter((v, idx) => {
                            if (i === idx) {
                              v.type = 'input';
                            }
                            return v;
                          });
                          setPayoutDebitItems([...list]);
                        } else {
                          let list = payoutDebitItems.filter((v, idx) => {
                            if (i === idx) {
                              v.companyName = e.target.value;
                            }
                            return v;
                          });
                          setPayoutDebitItems([...list]);
                        }
                      }}
                    >
                      <option value={''} hidden disabled>
                        카드사 선택
                      </option>
                      {payoutCards.map((bank, i) => (
                        <option key={i} value={bank}>
                          {bank}
                        </option>
                      ))}
                    </select>
                  ) : (
                    <Input
                      type="text"
                      placeholder="직접입력"
                      defaultValue={v.companyName}
                      onBlur={(e) => {
                        let list = payoutDebitItems.filter((v, idx) => {
                          if (i === idx) {
                            v.companyName = e.target.value;
                          }
                          return v;
                        });
                        setPayoutDebitItems([...list]);
                      }}
                    />
                  )}
                </div>
                <div className="form-control">
                  {i === 0 && <label>수납액</label>}
                  <Input
                    value={currency(v.amount)}
                    onBlur={(e) => {
                      let value = removeCurrency(e.target.value);
                      if (!isNaN(Number(value))) {
                        let cardTotal = 0;
                        payoutDebitItems.forEach((v, idx) => {
                          if (i === idx) {
                            cardTotal += Number(value);
                          } else {
                            cardTotal += v.amount;
                          }
                        });
                        const totalAmount =
                          cardTotal +
                          creditCardsTotalAmount +
                          bankTransferTotalAmount +
                          cashTotalAmount;
                        setDebitCardsTotalAmount(cardTotal);
                        setPayoutAmount(totalAmount);
                      }
                    }}
                    onChange={(e) => {
                      let value = removeCurrency(e.target.value);
                      if (!isNaN(Number(value))) {
                        let list = payoutDebitItems.filter((v, idx) => {
                          if (i === idx) {
                            v.amount = Number(value);
                          }
                          return v;
                        });
                        setPayoutDebitItems([...list]);
                      }
                    }}
                  />
                </div>
                {i === 0 && (
                  <button
                    className="btn btn-normal-gray _small"
                    onClick={() => {
                      setPayoutDebitItems([
                        ...payoutDebitItems,
                        {
                          type: 'select',
                          paymentMethod: 'debit_card',
                          companyName: '',
                          amount: 0,
                        },
                      ]);
                    }}
                  >
                    추가
                  </button>
                )}
                {i > 0 && (
                  <button
                    className="btn btn-danger _small"
                    onClick={() => {
                      deletePayoutDebitItem(i);
                    }}
                  >
                    삭제
                  </button>
                )}
              </div>
            );
          })}
        </div>
      </div>

      <div className="card-payouts m-b-20">
        {payoutBankTransferItems.map((v, i) => {
          return (
            <div key={i} className="form-double-side">
              <div className="form-control">
                {i === 0 && <label>계좌이체</label>}
                {v.type === 'select' ? (
                  <select
                    value={v.companyName}
                    onChange={(e) => {
                      if (e.target.value === '직접입력') {
                        let list = payoutBankTransferItems.filter((v, idx) => {
                          if (i === idx) {
                            v.type = 'input';
                          }
                          return v;
                        });
                        setPayoutBankTransferItems([...list]);
                      } else {
                        let list = payoutBankTransferItems.filter((v, idx) => {
                          if (i === idx) {
                            v.companyName = e.target.value;
                          }
                          return v;
                        });
                        setPayoutBankTransferItems([...list]);
                      }
                    }}
                  >
                    <option value={''} hidden disabled>
                      은행 선택
                    </option>
                    {payoutBankTransfers.map((bank, i) => (
                      <option key={i} value={bank}>
                        {bank}
                      </option>
                    ))}
                  </select>
                ) : (
                  <Input
                    type="text"
                    placeholder="직접입력"
                    defaultValue={v.companyName}
                    onBlur={(e) => {
                      let list = payoutBankTransferItems.filter((v, idx) => {
                        if (i === idx) {
                          v.companyName = e.target.value;
                        }
                        return v;
                      });
                      setPayoutBankTransferItems([...list]);
                    }}
                  />
                )}
              </div>
              <div className="form-control">
                {i === 0 && <label>수납액</label>}
                <Input
                  value={currency(v.amount)}
                  onBlur={(e) => {
                    let value = removeCurrency(e.target.value);
                    if (!isNaN(Number(value))) {
                      let bankTotal = 0;
                      payoutBankTransferItems.forEach((v, idx) => {
                        if (i === idx) {
                          bankTotal += Number(value);
                        } else {
                          bankTotal += v.amount;
                        }
                      });
                      const totalAmount =
                        bankTotal +
                        creditCardsTotalAmount +
                        debitCardsTotalAmount +
                        cashTotalAmount;
                      setBankTransferTotalAmount(bankTotal);
                      setPayoutAmount(totalAmount);
                    }
                  }}
                  onChange={(e) => {
                    let value = removeCurrency(e.target.value);
                    if (!isNaN(Number(value))) {
                      let list = payoutBankTransferItems.filter((v, idx) => {
                        if (i === idx) {
                          v.amount = Number(value);
                        }
                        return v;
                      });
                      setPayoutBankTransferItems([...list]);
                    }
                  }}
                />
              </div>
              {i === 0 && (
                <button
                  className="btn btn-normal-gray _small"
                  onClick={() => {
                    setPayoutBankTransferItems([
                      ...payoutBankTransferItems,
                      {
                        type: 'select',
                        paymentMethod: 'bank_transfer',
                        companyName: '',
                        amount: 0,
                      },
                    ]);
                  }}
                >
                  추가
                </button>
              )}
              {i > 0 && (
                <button
                  className="btn btn-danger _small"
                  onClick={() => {
                    deletePayoutBankTransferItem(i);
                  }}
                >
                  삭제
                </button>
              )}
            </div>
          );
        })}
      </div>

      <div className="form-control">
        <div className="flex-row items-center">
          <div className="flex-wrap payment-input">
            <label>현금</label>
          </div>
        </div>
        <div className="flex-row items-center">
          <div className="flex-wrap payment-input">
            <Input
              value={currency(payoutCash)}
              onChange={(e) => {
                let value = removeCurrency(e.target.value);
                if (!isNaN(Number(value))) {
                  setPayoutCash(Number(value));
                }
              }}
              onBlur={(e) => {
                let value = removeCurrency(e.target.value);
                if (!isNaN(Number(value))) {
                  let cashTotal = Number(value);
                  const totalAmount =
                    cashTotal +
                    creditCardsTotalAmount +
                    debitCardsTotalAmount +
                    bankTransferTotalAmount;
                  //const excessAmount = Math.abs(unpaidAmount - totalAmount);
                  //const maxAmount = Number(value) - excessAmount;
                  setCashTotalAmount(cashTotal);
                  setPayoutAmount(totalAmount);

                  //payouts
                  let cashItem = payouts.filter((v) => {
                    return v.paymentMethod === 'cash';
                  });
                  if (cashItem.length) {
                    let payoutsItems = payouts.map((v) => {
                      if (v.paymentMethod === 'cash') {
                        v.amount = Number(value);
                      }
                      return v;
                    });
                    setPayouts([...payoutsItems]);
                  } else {
                    let payout = {
                      paymentMethod: 'cash',
                      amount: Number(value),
                      paidAt: moment(new Date()).format('YYYY-MM-DDTHH:mm:ss'),
                    };
                    setPayouts([...payouts, payout]);
                  }
                }
              }}
            />
          </div>
          <div className="flex-wrap payment-input m-l-8">
            {' '}
            <label>현금영수증</label>
          </div>
          <div className="flex-wrap payment-input">
            <Checkbox
              className="flex-wrap m-l-8"
              checked={cashReceipt}
              toggleHandler={() => {
                setCashReceipt(!cashReceipt);
              }}
            />
          </div>
        </div>
      </div>

      <div className="form-wide">
        <div className="form-control">
          <label>{translate('MEMO')}</label>
          <QuillTextField
            value={memo || options.parentRow.memo}
            setValue={(v) => setMemo(v)}
            placeholder="메모를 입력해주세요."
          />
        </div>
      </div>

      <div className="buttons">
        <div className="flex-row">
          <button onClick={close} className="btn btn-default">
            {translate('CANCEL')}
          </button>
          <button
            onClick={onClickSave}
            disabled={`${loadingBtnDisabledFlag ? 'disabled' : ''}`}
            className="btn btn-primary"
          >
            수납완료
          </button>
        </div>
      </div>
    </div>
  );
};

ModalPaymentsUnpaid.propTypes = {
  options: PropTypes.object,
  close: PropTypes.func,
};

export default observer(ModalPaymentsUnpaid);
