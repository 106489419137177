import moment from 'moment';

export default {
  keys: [
    {
      title: '등록일',
      column: 'createdAt',
      filter: (o) => moment(o.createdAt).format('YYYY-MM-DD HH:mm'),
    },
    {
      title: '고객명',
      column: 'customerName',
      filter: (o) => o.customer.name,
    },
    {
      title: '담당자',
      column: 'userName',
      filter: (o) => o.user.name,
    },
    {
      title: '연락희망일',
      column: 'recallAt',
      filter: (o) => moment(o.recallAt).format('YYYY-MM-DD HH:mm'),
    },
    {
      title: '메모',
      column: 'memo',
    },
  ],
};
