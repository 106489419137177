import React, { useState, useCallback, useEffect } from 'react';
import { observer } from 'mobx-react';
import {
  currencySymbol,
  currency,
  emojisContainsCheck,
  translate,
} from 'filters';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import ScreenMessageSend from 'components/app/MessageScreen/ScreenMessageSend';
import TargetContainer from './message-send/TargetContainer';
import ModalMessageSendConfirm from 'components/modals/ModalMessageSendConfirm';
import ModalMessagePriceTable from 'components/modals/ModalMessagePriceTable';
import { useAuth } from 'store/auth';
import { useModal } from 'hooks/useModal';
import { useServices } from 'hooks/useServices';
import { useToast } from 'hooks/useToast';

const MessageSend = () => {
  const auth = useAuth();
  const modal = useModal();
  const services = useServices();
  const toast = useToast();
  const [loadingBtnDisabledFlag, setLoadingBtnDisabledFlag] = useState(false);
  //searchHistoryInit: 문자 전송 후 검색조건 초기화를 위해 두는 init flag
  const [searchHistoryInit, setSearchHistoryInit] = useState(false);

  const initMessageObj = {
    images: [],
    imageFiles: [],
    title: '',
    contents: '',
    smsScheduleType: 'immediate',
    scheduleTime: '08:00',
    smsType: 'normal',
  };

  const [messageObj, setMessageObj] = useState(initMessageObj);
  const [targetDataObj, setTargetDataObj] = useState({
    smsSearchHistory: {
      id: null,
    },
    excludePhoneNumbers: [],
  });
  const [checkedCount, setCheckedCount] = useState(0);
  const [balance, setBalance] = useState({
    amount: null,
    freeAmount: null,
    totalAmount: null,
    limit: null,
  });
  const [callerNumberList, setCallerNumberList] = useState();
  const callApiCallerNumberList = useCallback(async () => {
    try {
      const resp = await services.crm.notification.callerNumbers.all();
      if (resp && resp.data) {
        setCallerNumberList(resp.data);
        return;
      } else {
        setCallerNumberList([]);
      }
    } catch (e) {
      setCallerNumberList([]);
    }
  }, [services.crm.notification.callerNumbers]);

  const callBalanceInfo = useCallback(async () => {
    try {
      const resp = await services.crm.notification.balance.all();
      const respData = resp.data;
      const { amount, freeAmount } = respData;
      const totalAmount = amount + freeAmount;

      setBalance((balance) => ({
        ...balance,
        ...respData,
        totalAmount,
      }));
    } catch (e) {
      toast.error('문자충전금 조회에 실패하였습니다.');
    }
  }, [services.crm.notification.balance, toast]);

  useEffect(() => {
    callBalanceInfo();
    return () => {
      setBalance({
        amount: null,
        freeAmount: null,
        totalAmount: null,
        limit: null,
      });
    };
  }, [callBalanceInfo]);

  useEffect(() => {
    callApiCallerNumberList();
    return () => {
      setCallerNumberList([]);
    };
  }, [callApiCallerNumberList]);

  const openModalMessagePriceTable = useCallback(() => {
    modal.custom({
      component: ModalMessagePriceTable,
    });
  }, [modal]);

  const validate = useCallback(
    (dataObj) => {
      // 전송대상 0명
      const { checkedCount } = dataObj;
      if (!checkedCount || checkedCount <= 0) {
        toast.error('ERROR_NO_TARGET_SELECTED');
        return false;
      }

      const {
        callerNumber,
        smsScheduleType,
        // scheduledAt,
        scheduleDate,
        scheduleTime,
        contents,
        smsType,
        title,
      } = dataObj.notification;

      // 발신번호 미선택
      if (!callerNumber || !callerNumber.id) {
        toast.error('ERROR_SELECT_CALLING_NUMBER');
        return false;
      }

      // 예약선택인경우. 날짜 미선택
      if (smsScheduleType === 'scheduled' && !scheduleDate) {
        toast.error('ERROR_SELECT_SCHEDULE_DATE');
        return false;
      }

      // 선택한 시간/날짜가 이전인 경우
      const scheduleAt = moment(
        `${scheduleDate} ${scheduleTime}`,
        'YYYY-MM-DD HH:mm'
      );
      if (smsScheduleType === 'scheduled' && scheduleAt.isBefore()) {
        toast.error('ERROR_INVALID_SCHEDULED_TIME');
        return false;
      }

      // 문자내용 없음
      if (!contents || contents.length === 0) {
        toast.error('ERROR_EMPTY_MESSAGE_CONTENTS');
        return false;
      }

      const meInfo = auth.me ? auth.me : {};
      const clinicInfo = meInfo.clinic ? meInfo.clinic : {};
      const { rejectionPhoneNumber } = clinicInfo ? clinicInfo : {};
      if (smsType === 'event' && !rejectionPhoneNumber) {
        toast.error('080 수신거부번호가 없어 광고문자를 전송할 수 없습니다');
        return false;
      }

      if (
        emojisContainsCheck(title) === true ||
        emojisContainsCheck(contents) === true
      ) {
        //문자 전송시 제목, 내용에 특수 이모티콘(이모지) 포함되어있는지 체크
        toast.error('문자 제목 또는 내용에 특수 이모티콘을 제거해주세요');
        return false;
      }

      // dataObj
      return true;
    },
    [toast, auth]
  );

  const onOpenModalConfirm = useCallback(() => {
    const { scheduleDate, scheduleTime } = messageObj;
    const notification = {
      ...messageObj,
      ...(messageObj.smsScheduleType === 'scheduled' && {
        scheduledAt: moment(
          `${scheduleDate} ${scheduleTime}`,
          'YYYY-MM-DDTHH:mm:ss'
        ).format('YYYY-MM-DDTHH:mm:ss'),
      }),
    };

    const { smsSearchHistory, excludePhoneNumbers } = targetDataObj;
    const smsSearchHistoryId = smsSearchHistory.id;

    const dataObj = {
      smsSearchHistoryId,
      excludePhoneNumbers,
      notification,
      checkedCount,
    };

    if (validate(dataObj)) {
      setLoadingBtnDisabledFlag(true);
      modal
        .custom({
          component: ModalMessageSendConfirm,
          options: { dataObj, balance },
        })
        .then((submitted) => {
          if (submitted && typeof submitted === 'boolean') {
            callBalanceInfo();
            //문자전송 성공 후 검색조건 초기화
            setMessageObj({
              images: [],
              imageFiles: [],
              title: '',
              contents: '',
              smsScheduleType: 'immediate',
              scheduleTime: '08:00',
              smsType: 'normal',
              messageType: 'SMS',
            });
            setTargetDataObj({
              smsSearchHistory: {
                id: null,
              },
              excludePhoneNumbers: [],
            });

            setCheckedCount(0);
            setSearchHistoryInit(true);
          }
          setLoadingBtnDisabledFlag(false);
        });
    }
  }, [
    modal,
    balance,
    checkedCount,
    callBalanceInfo,
    messageObj,
    targetDataObj,
    validate,
  ]);

  return (
    <div className="message-send">
      <div className="message-wrap">
        <div className={'point-status'}>
          <div className="point-head">
            <div className="head-left">
              <span style={{ fontSize: '1.1em', marginRight: '.5em' }}>
                {translate('MESSAGE_POINT_AMOUNT')}
              </span>
              <span>
                {balance.totalAmount !== null &&
                  `${currencySymbol()}${currency(balance.totalAmount)}`}
                {balance.totalAmount === null && (
                  <FontAwesomeIcon icon={faCircleNotch} spin />
                )}
              </span>
            </div>
            <div className="head-right">
              {/* <button className="btn btn-basic _small m-r-8">{translate("MESSAGE_POINT_RECHARGE")}</button> */}
              <button
                className="btn btn-basic _small"
                onClick={() => openModalMessagePriceTable()}
              >
                {translate('MESSAGE_POINT_PRICE')}
              </button>
            </div>
          </div>
          <ScreenMessageSend
            style={{ display: 'flex' }}
            obj={messageObj}
            setObj={setMessageObj}
            callerNumberList={callerNumberList}
            searchHistoryInit={searchHistoryInit}
            setLoadingBtnDisabledFlag={setLoadingBtnDisabledFlag}
          />
          <div className="btnwrap" style={{ padding: '0 16px' }}>
            <button
              className="btn btn-basic _small _full"
              style={{ width: '100%' }}
              disabled={loadingBtnDisabledFlag}
              onClick={() => onOpenModalConfirm()}
            >
              {translate('MESSAGE_SEND')}
            </button>
          </div>
        </div>
        <div className="detail-cont">
          <div className="page-navi">
            <span className="title">문자관리</span>
            <span className="title">문자전송</span>
          </div>
          <TargetContainer
            searchHistoryInit={searchHistoryInit}
            setSearchHistoryInit={setSearchHistoryInit}
            targetDataObj={targetDataObj}
            setTargetDataObj={setTargetDataObj}
            checkedCount={checkedCount}
            setCheckedCount={setCheckedCount}
            smsType={messageObj.smsType}
          />
        </div>
      </div>
    </div>
  );
};

export default observer(MessageSend);
