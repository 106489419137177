import { translate } from 'filters';

export default {
  keys: [
    {
      title: 'QUANTITY',
      column: 'quantity',
      type: 'input',
    },
    {
      title: '제품명',
      column: 'name',
    },
    {
      title: '과세여부',
      type: 'toggle',
      column: 'vatIncluded',
      filter: (o) => {
        if (o['vatIncluded'] !== null) {
          return o['vatIncluded']
            ? translate('VAT_TRUE')
            : translate('VAT_FALSE');
        }

        return '-';
      },
    },
    {
      title: 'NOT_VAT_INCLUDE',
      column: 'price',
      type: 'input_currency',
    },
    {
      title: 'PRICE_VAT_INCLUDE',
      column: 'priceVatInclude',
      type: 'input_currency',
    },
  ],
};
