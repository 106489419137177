import * as $http from 'axios';
import { $case } from 'filters';

const payment = {
  create: (payment) => $http.post('payments', payment),
  all: (params) => $http.get('payments', { params }),
  detail: (paymentId) => $http.get(`payments/${paymentId}`),
  update: (payment) => $http.put(`payments/${payment.id}`, payment),
  delete: (paymentId) => $http.delete(`payments/${paymentId}`),
  excel_download_v2: (params) =>
    $http.get('/payments/v2/excel', { params, responseType: 'blob' }),
  excel_check_download_v2: (params) =>
    $http.get('/payments/v2/excel/check/download', {
      params,
      responseType: 'blob',
    }),

  paid_treatment_items: (params) =>
    $http.get(`payments/paid/treatment_items`, { params }),
  tobe_treatment_items: (params) =>
    $http.get(`payments/tobe/treatment_items`, { params }),
  summary: (params) => $http.get(`payments/summary`, { params }),
};

const models = ['discountReason', 'refundReason', 'product'];

models.forEach((modelName) => {
  payment[modelName] = {
    create: (model) =>
      $http.post(`payments/${$case.toSnake(modelName)}s`, model),
    all: (params) =>
      $http.get(`payments/${$case.toSnake(modelName)}s`, { params }),
    detail: (modelId) =>
      $http.get(`payments/${$case.toSnake(modelName)}s/${modelId}`),
    update: (model) =>
      $http.put(`payments/${$case.toSnake(modelName)}s/${model.id}`, model),
    delete: (modelId) =>
      $http.delete(`payments/${$case.toSnake(modelName)}s/${modelId}`),
  };
});

export default payment;
