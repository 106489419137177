import React, { useState, useEffect, useCallback } from 'react';
import { observer } from 'mobx-react';
import HeaderRowSpanDataTable from 'components/data-table/HeaderRowSpanDataTable';
import MultiSelect from 'react-multi-select-component';
import moment from 'moment';
import models from 'models';
import { $qb } from 'scripts/querybuilder';
import { useServices } from 'hooks/useServices';
import DatePicker from 'components/common/DatePicker';

const DoctorsStatistics = () => {
  const services = useServices();
  const defaultParams = $qb()
    .limit(20)
    .customParam(
      'searchStartAt',
      moment(new Date().setDate(new Date().getDate() - 31)).format('YYYY-MM-DD')
    )
    .customParam(
      'searchEndAt',
      moment(new Date().setDate(new Date().getDate() - 1)).format('YYYY-MM-DD')
    );
  const [params, setParams] = useState(defaultParams);
  const [searchValue, setSearchValue] = useState({
    searchStartAt: moment(new Date().setDate(new Date().getDate() - 31)).format(
      'YYYY-MM-DD'
    ),
    searchEndAt: moment(new Date().setDate(new Date().getDate() - 1)).format(
      'YYYY-MM-DD'
    ),
  });
  const [startAt, setStartAt] = useState(
    new Date().setDate(new Date().getDate() - 31)
  );
  const [endAt, setEndAt] = useState(
    new Date().setDate(new Date().getDate() - 1)
  );
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [model, setModel] = useState(models.crm.doctorStatistics);
  const [doctors, setDoctors] = useState([]);
  const [selected, setSelected] = useState([]);

  const doctorStatisticsCallApi = useCallback(async () => {
    let paramId = '';
    if (params.queryParams && params.queryParams.paramTemp) {
      paramId = params.queryParams.paramTemp;
      delete params.queryParams.paramTemp;
    }
    const resp = await services.crm.statistics.statistics.doctor(
      params.build(),
      paramId
    );
    if (!resp) return;
    resp.data.doctorData.map((v, i) => {
      let page = params.queryParams.page || 1;
      let limit = params.queryParams.limit;
      let no = parseInt((page - 1) * limit + (i + 1));
      v.no = no;
      return v;
    });
    setData(resp.data.doctorData);
    setTotal(resp.meta.pagination.total);
    setModel(models.crm.doctorStatistics);
  }, [services.crm.statistics, params, models.crm.doctorStatistics]);

  useEffect(() => {
    doctorStatisticsCallApi();
  }, [doctorStatisticsCallApi]);

  const doctorCallApi = useCallback(async () => {
    let params = { duty: '의사', userStatus: 'active', limit: 300 };
    const resp = await services.crm.user.duty(params);
    if (!resp) return;
    const result = resp.data.map((v) => {
      return { label: v.name, value: v.id };
    });

    setDoctors([...result]);
  }, [services.crm.user]);

  useEffect(() => {
    doctorCallApi();
  }, [doctorCallApi]);

  const onParams = (p) => {
    setParams(p);
    doctorStatisticsCallApi();
  };

  /*const onClickSelectDate = (v) => {
    const now = new Date();
    if (v === 'month') {
      onChangeDate(
        new Date(now.getFullYear(), now.getMonth(), 1),
        new Date(now.getFullYear(), now.getMonth() + 1, 0)
      );
    } else if (v === 'week') {
      const nowDayOfWeek = now.getDay();
      const nowDay = now.getDate();
      const nowMonth = now.getMonth();
      let nowYear = now.getYear();
      nowYear += nowYear < 2000 ? 1900 : 0;
      const weekStartDate = new Date(nowYear, nowMonth, nowDay - nowDayOfWeek);
      const weekEndDate = new Date(
        nowYear,
        nowMonth,
        nowDay + (6 - nowDayOfWeek)
      );
      onChangeDate(weekStartDate, weekEndDate);
    } else if (v === 'quarter') {
      const currentMonth = now.getMonth() + 1;
      const quarter = Math.ceil(currentMonth / 3);
      const startDate = new Date(now.getFullYear(), (quarter - 1) * 3, 1);
      const endDate = new Date(now.getFullYear(), quarter * 3, 0);
      onChangeDate(startDate, endDate);
    }
  };
  const onChangeDate = (startDate, endDate) => {
    setStartAt(startDate);
    setEndAt(endDate);
    setSearchValue({
      ...searchValue,
      searchStartAt: moment(startDate).format('YYYY-MM-DD'),
      searchEndAt: moment(endDate).format('YYYY-MM-DD'),
    });
  };*/

  const onChangeStartAt = (date) => {
    const searchStartAt = moment(date).format('YYYY-MM-DD');
    setStartAt(date);
    setSearchValue({ ...searchValue, searchStartAt });
  };

  const onChangeEndAt = (date) => {
    const searchEndAt = moment(date).format('YYYY-MM-DD');
    setEndAt(date);
    setSearchValue({ ...searchValue, searchEndAt });
  };

  const onClickHeaderRow = (row) => {
    let searchParam = searchValue;
    let orderBy = `${row.column} ${row.order}`;
    params.orderBy(orderBy);
    if (selected.length > 0) {
      let search = new URLSearchParams(
        selected.map((s) => ['doctorIds', s.value])
      );
      searchParam.paramTemp = search.toString();
    }
    params.queryParams = {
      limit: params.queryParams.limit,
      orderBy: params.queryParams.orderBy,
      ...searchParam,
    };
    setParams(params);

    doctorStatisticsCallApi();
  };

  const onClickSearch = () => {
    let searchParam = searchValue;
    if (searchParam.searchStartAt === 'Invalid date') {
      // eslint-disable-next-line no-unused-vars
      const { searchStartAt: deletedKey, ...otherKeys } = searchParam;
      searchParam = otherKeys;
    }

    if (searchParam.searchEndAt === 'Invalid date') {
      // eslint-disable-next-line no-unused-vars
      const { searchEndAt: deletedKey, ...otherKeys } = searchParam;
      searchParam = otherKeys;
    }
    delete searchParam.paramTemp;
    if (selected.length > 0) {
      let search = new URLSearchParams(
        selected.map((s) => ['doctorIds', s.value])
      );
      searchParam.paramTemp = search.toString();
    }
    setParams(params);

    params.queryParams = {
      limit: params.queryParams.limit,
      orderBy: params.queryParams.orderBy,
      ...searchParam,
    };

    setSearchValue(searchParam);
    setParams(params);
    doctorStatisticsCallApi();
  };

  return (
    <div className={`statistics list inquiry`}>
      <div className="page-navi">
        <span className="title">통계</span>
        <span className="title">의사별 통계</span>
      </div>

      <div className="route-top">
        <div className="search-bar">
          {/* <div className="search-btns">
            <button
              className={`btn btn-normal _small`}
              onClick={() => onClickSelectDate('week')}
            >
              이번 주
            </button>
          </div>
          <div className="search-btns">
            <button
              className={`btn btn-normal _small`}
              onClick={() => onClickSelectDate('month')}
            >
              이번 달
            </button>
          </div>
          <div className="search-btns">
            <button
              className={`btn btn-normal _small`}
              onClick={() => onClickSelectDate('quarter')}
            >
              이번 분기
            </button>
          </div> */}
          <div className="search-item">
            <DatePicker
              className="text-center"
              placeholderText="날짜선택"
              todayButton="오늘"
              maxDate={new Date()}
              value={startAt}
              onChange={onChangeStartAt}
            />
          </div>
          <div className="search-item">-</div>
          <div className="search-item">
            <DatePicker
              className="text-center"
              placeholderText="날짜선택"
              todayButton="오늘"
              maxDate={new Date()}
              value={endAt}
              onChange={onChangeEndAt}
            />
          </div>
          <div className="search-item">
            <MultiSelect
              className="multi-select"
              options={doctors}
              value={selected}
              onChange={setSelected}
              labelledBy={'Select'}
              disableSearch
              selectAllLabel={'전체선택'}
              overrideStrings={{
                selectSomeItems: '의사명',
                allItemsAreSelected: '전체선택',
              }}
            />
          </div>
          <div className="search-btns">
            <button
              className="btn btn-basic _small search"
              onClick={onClickSearch}
            >
              조회
            </button>
          </div>
        </div>
      </div>
      <div className="sales-statistics-container">
        <HeaderRowSpanDataTable
          rowKey="no"
          model={model}
          data={data}
          total={total}
          onClickHeaderRow={onClickHeaderRow}
          params={params}
          onParams={onParams}
          bordered={true}
        />
      </div>
    </div>
  );
};

export default observer(DoctorsStatistics);
