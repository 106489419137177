import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { observer } from 'mobx-react';
import DataGridView from './DataGridView';
import hooks from 'hooks';
import { $case, pluralize } from 'filters';
import models from 'models';
import { useModal } from 'hooks/useModal';
import { useServices } from 'hooks/useServices';
import modelTreatments from 'models/treatment';
import { DeleteButton } from 'components/DataTable/DeleteButton';
import { CustomerChartLink } from 'components/DataTable/CustomerChartLink';
import QuillText from 'components/quill/QuillText';
import moment from 'moment';

const Treatments = () => {
  const modal = useModal();
  const services = useServices();
  const [totalSearchList, setTotalSearchList] = useState({});
  const [renderFlag, setRenderFlag] = useState(true);

  const callApi = useCallback(async () => {
    let totalResp = {};
    let doctorsParams = { duty: '의사', userStatus: 'active', limit: 300 };
    const doctorsResp = await services.crm.user.duty(doctorsParams);
    if (!doctorsResp) return;
    totalResp._doctors = doctorsResp.data;

    let treatmentParams = { limit: 10000, visible: true };
    const treatmentResp = await services.crm.treatment.categories.items_categories_v2(
      treatmentParams
    );
    if (!treatmentResp) return;
    totalResp.surgery_category = treatmentResp.data;

    const treatmentItemResp = await services.crm.treatment.items.all_v2(
      treatmentParams
    );
    if (!treatmentItemResp) return;
    totalResp.surgery_item_name = treatmentItemResp.data;

    setTotalSearchList(totalResp);
    setRenderFlag((renderFlag) => !renderFlag);
  }, []);

  useEffect(() => {
    callApi();
  }, [callApi]);

  const onDeleteTreatment = useCallback(async (id) => {
    try {
      await services.crm.treatment.delete_v2(id);
      modal
        .confirm({
          type: 'SUCCESS',
          msg: '삭제되었습니다.',
        })
        .then(() => {
          setRenderFlag((renderFlag) => !renderFlag);
        });
    } catch (e) {
      modal.confirm({
        type: 'ERROR',
        msg: e.description,
      });
    }
  }, []);

  const settingModels = useCallback(() => {
    const dataCallList = ['_doctors', 'surgery_category', 'surgery_item_name'];
    const model = models.crm.treatment;
    let found;
    dataCallList.forEach((v) => {
      found = (model.keys || []).find(
        (o) => o.title === $case.toConst(pluralize(v))
      );
      if (!found) return;
      found.selectOptions = (totalSearchList[v] || []).map((o) => {
        o.$$label = o['name'];
        o.$$value = o.id;
        return o;
      });
    });
  }, [totalSearchList]);

  useEffect(() => {
    settingModels();
  }, [settingModels]);

  const openCustomerChart = (item) => {
    hooks.openCustomerChart({ customer: item.customer, item });
  };

  const onClickDelete = useCallback((item) => {
    modal
      .basic({
        body: '삭제하시겠습니까?',
        buttons: [
          {
            text: 'CANCEL',
            class: 'btn-default',
          },
          {
            text: 'CONFIRM',
            class: 'btn-primary',
          },
        ],
      })
      .then((idx) => {
        if (idx === 1) {
          onDeleteTreatment(item);
        }
      });
  }, []);

  const treatmentSchema = useMemo(
    () => ({
      columns: [
        {
          id: 'status',
          name: '상태',
          component: DeleteButton,
          props: (item) => ({
            onClick: () => onClickDelete(item.id),
          }),
        },
        {
          id: 'scheduledAt',
          name: '진료일',
          value: (item) => moment(item.scheduledAt).format('YYYY-MM-DD'),
        },
        {
          id: 'name',
          name: '고객명',
          component: CustomerChartLink,
          props: (item) => ({
            onClick: () => openCustomerChart(item),
          }),
        },
        {
          id: 'chartNumber',
          name: '차트번호',
          value: (item) => item.customer.chartNo,
        },
        {
          id: 'doctor',
          name: '의사',
          value: (item) => item.doctor?.name || '',
        },
        {
          id: 'categoryName',
          name: '시/수술 카테고리',
          value: (item) =>
            item.items
              .map((v) => v.category?.name || '')
              .filter((v) => v)
              .join('\n'),
        },
        {
          id: 'itemsName',
          name: '시/수술명',
          value: (item) => `${item.items.map((v) => `${v.name}`).join('\n')}`,
        },
        {
          id: 'memo',
          name: '메모',
          component: QuillText,
          props: (item) => ({
            value: item.memo,
          }),
          grow: true,
          style: {
            textAlign: 'left',
          },
        },
      ],
    }),
    []
  );

  return (
    <DataGridView
      schema={treatmentSchema}
      renderFlag={renderFlag}
      api={services.crm.treatment.all_v2}
      model={modelTreatments}
      modelName={'treatment'}
      orderBy={'scheduledAt desc'}
    />
  );
};

export default observer(Treatments);
