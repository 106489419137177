import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinusCircle } from '@fortawesome/free-solid-svg-icons';
import TextareaAutosize from 'react-textarea-autosize';
import PropTypes from 'prop-types';
import { translate } from 'filters';
import { useAuth } from 'store/auth';
import styled from 'styled-components';

const Input = styled.input`
  overflow-y: hidden;
  width: 100%;
  background-color: transparent;
  border-width: 0;
  font-size: 0.9em;
  resize: none;
  height: 34px;
  line-height: 1.4em;
  min-height: 10px;
  padding: 16px 20px;
`;

const MessageDetailViewMain = ({
  uiObj,
  obj,
  contentsRef,
  resetImages,
  setLoadingBtnDisabledFlag,
  hidePlaceholder,
}) => {
  const auth = useAuth();
  const unusableVariable = [
    '#{예약일}',
    '#{예약시간}',
    '#{대부서명}',
    '#{세부부서명}',
  ];
  const [LINK_CONSTANT] = useState('#바비톡병원홈페이지링크');
  // eslint-disable-next-line no-unused-vars
  const [rejectionPhoneNumber, setRejectionPhoneNumber] = useState('');
  const [rejectionString, setRejectionString] = useState('');
  const [unusableVariableList, setUnusableVariableList] = useState([]);

  useEffect(() => {
    // 실제 받게되는 문자내용은 다국어 UI요소가 아님.
    const nullCaseString = '번호가 없습니다';
    const meInfo = auth.me ? auth.me : {};
    const clinicInfo = meInfo.clinic ? auth.me.clinic : {};
    const rejection_phone_number = clinicInfo.rejectionPhoneNumber
      ? clinicInfo.rejectionPhoneNumber
      : '';
    if (!rejection_phone_number) {
      setRejectionPhoneNumber(rejection_phone_number);
      setRejectionString(nullCaseString);
    } else {
      setRejectionPhoneNumber(rejection_phone_number);
      setRejectionString(rejection_phone_number);
    }
  }, [auth.me]);

  useEffect(() => {
    setLoadingBtnDisabledFlag && checkUnusableVariable(obj.contents);
  }, [obj.contents]);

  const checkUnusableVariable = (text) => {
    let arr = [];
    unusableVariable.map((v) => {
      if (text.includes(v)) arr.push(v);
    });
    setUnusableVariableList(arr);
    setLoadingBtnDisabledFlag(arr.length > 0);
  };

  return (
    <div className={`app-view-main`}>
      <div className="message-bubble">
        <div className="message-bubble-clinic-profile">
          {/* <FontAwesomeIcon icon={faUser} mask={faCircle} transform="shrink-7" size="2x"/> */}
          {/* <div className="message-bubble-datetime">HH:mm</div>. 예약문자의 경우가 있어 뺌 */}
        </div>
        <div className="message-bubble-content">
          {unusableVariableList.length > 0 && (
            <p className="error-msg c-red">
              사용할 수 없는 변수가 있습니다 : <br />
              <b>{String(unusableVariableList)}</b>
              <br />
              해당 변수를 사용하려면 예약캘린더 > 마우스 우클릭하여
              문자전송해주세요.
            </p>
          )}
          {uiObj.previewImages &&
            uiObj.previewImages.length === 1 &&
            uiObj.previewImages.map((imgObj, idx) => (
              <div className="message-bubble-image" key={idx}>
                <div
                  className={'remove-image'}
                  onClick={(e) => {
                    resetImages(e);
                  }}
                >
                  <FontAwesomeIcon icon={faMinusCircle} />
                </div>
                <img src={imgObj.url} alt="" />
              </div>
            ))}
          <div className="message-bubble-text">
            {obj.title && (
              <div className="message-title ">
                <div className="title-input">
                  <Input name="title" value={obj.title} readOnly />
                </div>
                <div className="title-status">
                  <span className="title-bytes">{uiObj.titleBytes}</span>
                  <span className="title-max-bytes">/40&nbsp;byte</span>
                </div>
              </div>
            )}

            <div className="message-contents">
              <div className="content-input">
                {obj.smsType === 'event' && !hidePlaceholder && (
                  <span className="event-prefix">(광고)</span>
                )}
                <TextareaAutosize
                  inputRef={contentsRef}
                  className="message-contents  message-textarea "
                  name={'contents'}
                  value={obj.contents}
                  placeholder={translate('PLACEHOLDER_SMS_CONTENTS')}
                  readOnly
                />
                {/* #{바비톡 병원 홈페이지 링크} 변수처리 결과가 들어갈 영역 */}
                {obj.smsType === 'event' &&
                  obj.isBabitalkUri &&
                  !hidePlaceholder && (
                    <span className="event-suffix">{LINK_CONSTANT}</span>
                  )}
                {obj.smsType === 'event' && !hidePlaceholder && (
                  <span className="event-suffix">
                    무료거부 {rejectionString}
                  </span>
                )}
              </div>
              <div className="content-status">
                <span className="message-type">{uiObj.type}</span>
                <span className="content-bytes">{uiObj.contentsBytes}</span>
                <span className="content-max-bytes">
                  &nbsp;/&nbsp;{`${uiObj.type === 'SMS' ? 90 : 2000}`}&nbsp;byte
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

MessageDetailViewMain.propTypes = {
  uiObj: PropTypes.object,
  obj: PropTypes.object,
  contentsRef: PropTypes.object,
  resetImages: PropTypes.func,
  setLoadingBtnDisabledFlag: PropTypes.bool,
  hidePlaceholder: PropTypes.bool,
};

export default React.memo(observer(MessageDetailViewMain));
