import { currencySymbol, currency } from 'filters';

export default {
  keys: [
    {
      title: 'MESSAGE_TYPE',
      column: 'serviceName',
    },
    {
      title: 'PRICE_OF_UNIT',
      column: 'unitPrice',
      filter: (o) =>
        o.unitPrice ? `${currencySymbol()} ${currency(o.unitPrice)}` : '-',
    },
  ],
};
