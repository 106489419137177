import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Button = styled.button`
  display: flex;
  margin: auto;
  text-decoration: underline;
  color: #f7685b;
  font-size: 12px;
  font-weight: 500;
`;

export function DeleteButton({ onClick }) {
  return <Button onClick={onClick}>삭제</Button>;
}

DeleteButton.propTypes = {
  onClick: PropTypes.func,
};
