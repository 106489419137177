import React, { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import { translate } from 'filters';
import { useAuth } from 'store/auth';
import { banks } from 'hooks/constants';
import { useModal } from 'hooks/useModal';
import { useServices } from 'hooks/useServices';
import { useToast } from 'hooks/useToast';
import styled, { css } from 'styled-components';

const Input = styled.input`
  font-size: 14px;
  width: 100%;
  vertical-align: top;
  line-height: 1;
  padding: 8px 10px 8px 20px;
  border: 1px solid #dee2ec;
  border-radius: 4px;
  height: 34px;
  background-color: #f3f8ff;

  ${({ disabled }) =>
    disabled &&
    css`
      background-color: #efeff4;
      color: #ceced7;
    `}
`;

const ClinicInfo = () => {
  const auth = useAuth();
  const modal = useModal();
  const services = useServices();
  const toast = useToast();
  const [clinicInfoModel] = useState([
    {
      title: 'CLINIC_INFO',
      items: [
        {
          title: 'CLINIC_NO',
          column: 'clinicNo',
          disabled: false,
          type: 'number',
          length: '8',
        },
        { title: 'CLINIC_NAME', column: 'name', disabled: true, type: 'text' },
        {
          title: 'CLINIC_EMPLOYER_NO',
          column: 'employerNo',
          disabled: true,
          type: 'number',
        },
        {
          title: 'CLINIC_OWNER_NAME',
          column: 'ownerName',
          disabled: true,
          type: 'text',
        },
        {
          title: 'CLINIC_ADDRESS',
          column: 'address',
          disabled: false,
          type: 'text',
        },
        {
          title: 'CLINIC_TEL',
          column: 'clinicTel',
          disabled: false,
          type: 'number',
          length: '11',
        },
        {
          title: 'CLINIC_FAX',
          column: 'clinicFax',
          disabled: false,
          type: 'number',
          length: '11',
        },
        {
          title: 'CLINIC_CONTACT_MANAGER',
          column: 'contactManager',
          disabled: false,
          type: 'text',
        },
        {
          title: 'CLINIC_CONTACT_TEL',
          column: 'contactTel',
          disabled: false,
          type: 'number',
          length: '11',
        },
      ],
    },
    {
      title: 'CMS_DIRECT_DEBIT',
      items: [
        { title: 'CLINIC_MONTHLY_COST', column: 'monthlyCost' },
        { title: 'CLINIC_AUTO_TRANSFER_DAY', column: 'autoTransferDay' },
        { title: 'CLINIC_BANK', value: '-' },
        { title: 'CLINIC_BANK_ACCOUNT', column: 'bankAccount' },
        { title: 'CLINIC_TAXBILL_EMAIL', column: 'taxbillEmail' },
      ],
    },
  ]);

  // eslint-disable-next-line no-unused-vars
  const [data, setData] = useState({});
  const [clinic, setClinic] = useState({
    cms: {},
    additional: {},
    contracts: {},
  });
  const [buttonType, setButtonType] = useState('update');

  const callApi = useCallback(async () => {
    const me = toJS(auth.me);

    console.log(me);
    if (!me) return;
    const resp = await services.crm.clinic.detail(me.clinic.id);
    if (resp.data.cms && resp.data.cms.bank)
      clinicInfoModel[1].items[2].value = banks.find(
        (bank) => bank.value === resp.data.cms.bank
      ).key;

    setData(resp.data);
    setClinic(resp.data);
  }, [services.crm.clinic, clinicInfoModel]);

  const formControl = ({ fieldName, disabled, type, length }) => (
    <Input
      disabled={disabled}
      value={clinic[fieldName] || ''}
      maxLength={length || ''}
      onChange={(e) => {
        if (type === 'number') {
          if (!/^[0-9]*$/.test(e.target.value)) {
            toast.error('ERROR_NUMBERS_ONLY');
            return;
          }
        }
        setClinic({
          ...clinic,
          [fieldName]: e.target.value,
        });
      }}
    />
  );

  const onClickSave = useCallback(async () => {
    try {
      const {
        id,
        name,
        clinicNo,
        clinicTel,
        clinicFax,
        contactManager,
        address,
        contactTel,
      } = clinic;
      const resp = await services.crm.clinic.update(id, {
        name,
        clinicNo,
        clinicTel,
        clinicFax,
        contactManager,
        address,
        contactTel,
      });

      modal
        .confirm({
          type: 'SUCCESS',
          msg: '업데이트되었습니다.',
        })
        .then(() => {
          setData(resp.data);
          setClinic(resp.data);
          setButtonType('update');
          auth.updateMe({ clinic: resp.data });
        });
    } catch (e) {
      toast.error(e.description);
    }
  }, [services.crm.clinic, clinic]);

  useEffect(() => {
    callApi();
  }, [callApi]);

  return (
    <div className="clinic-info detail">
      <div className="page-navi">
        <span className="title">병원관리</span>
        <span className="title">병원정보</span>
      </div>
      <div className="card">
        <div className="header">
          <div className="title">{translate('CLINIC_INFO')}</div>
          <div className="desc">{translate('CLINIC_INFO_DESC')}</div>
        </div>
        <div className="table-round">
          <div className="grid-col">
            {clinicInfoModel[0].items.map((item) => (
              <div
                className="form-control"
                style={{ marginRight: '5px', marginBottom: '5px' }}
                key={item.title}
              >
                <label>{translate(item.title)}</label>
                {buttonType === 'update'
                  ? formControl({
                      fieldName: item.column,
                      disabled: true,
                      type: item.type,
                      length: item.length,
                    })
                  : formControl({
                      fieldName: item.column,
                      disabled: item.disabled,
                      type: item.type,
                      length: item.length,
                    })}
              </div>
            ))}
          </div>
          {buttonType === 'update' ? (
            <button
              className="btn btn-basic _small"
              onClick={() => setButtonType('cancel')}
            >
              수정
            </button>
          ) : (
            <>
              <button
                className="btn btn-cancel _small m-r-8"
                onClick={() => setButtonType('update')}
              >
                취소
              </button>
              <button className="btn btn-basic _small" onClick={onClickSave}>
                저장
              </button>
            </>
          )}
        </div>
      </div>
      {/* {(data || {}).cms ?
      <div className="card">
        <div className="header">
          <div className="title">{translate('CMS_DIRECT_DEBIT')}</div>
        </div>
        {clinicInfoModel[1].items.map(item => (
          <div className="form-control" key={item.title}>
            <label>{translate(item.title)}</label>
            <div>{item.value || translate(data.cms[item.column])}</div>
          </div>
        ))}
      </div> : null} */}
    </div>
  );
};

ClinicInfo.propTypes = {};

export default observer(ClinicInfo);
