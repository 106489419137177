import * as moment from 'moment';
import { translate } from 'filters';

export default {
  keys: [
    {
      title: 'STATUS',
      filter: (o) => translate(`APPOINTMENT_STATUS_${o.status}`.toUpperCase()),
    },
    {
      title: 'SCHEDULED_AT',
      column: 'scheduledAt',
      filter: (o) => {
        let start = o.startHour;
        let end = o.endHour;
        return `<div>
                <div>${moment(o.scheduledAt).format('YYYY-MM-DD')}</div>
                <div>${start}-${end}</div>
            </div>`;
      },
    },
    {
      title: 'FIRST_VISIT',
      column: 'firstVisit',
      filter: (o) => (o.firstVisit ? '초진' : '재진'),
    },
    {
      title: 'VISITED',
      column: 'visited',
      filter: (o) => (o.visited ? '방문' : '미방문'),
    },
    {
      title: 'ACQUISITION_CHANNELS',
      column: 'customer__acquisitionChannel__name',
    },
    {
      title: 'CUSTOMER',
      column: 'customer__name',
      class: 'text-underline c-primary',
    },
    {
      title: 'CHART_NO',
      column: 'customer__chartNo',
    },
    {
      title: 'BIRTHDAY',
      column: 'customer__birthday',
    },
    {
      title: 'SEX',
      column: 'customer__sex',
    },
    {
      title: 'PHONE_NUMBER',
      column: 'customer__phoneNumber',
    },
    {
      title: 'DEPARTMENT_CATEGORIES',
      column: 'department__category__name',
    },
    {
      title: 'DEPARTMENTS',
      column: 'department__name',
    },
    {
      title: 'COUNSELORS',
      column: 'counselor__name',
    },
    {
      title: 'DOCTORS',
      column: 'doctor__name',
    },
    {
      title: 'TREATMENT_ITEM_CATEGORIES',
      filter: (o) => `<div>
            ${o.items
              .map(
                (item) => `
                <div>${(item.category || {}).name || ''}</div>
            `
              )
              .join('\n')}
            </div>`,
    },
    {
      title: 'TREATMENT_ITEMS',
      filter: (o) => `<div>
            ${o.items
              .map(
                (item) => `
                <div>${item.name}</div>
            `
              )
              .join('\n')}
            </div>`,
    },
    {
      title: 'SMS_SENT',
      column: 'smsSent',
      filter: (o) => (o.smsSent ? '전송완료' : '미전송'),
    },
    {
      title: 'MEMO',
      column: 'memo',
      type: 'text',
    },
  ],
};
