export default {
  keys: [
    {
      title: 'STATUS',
      noorder: true,
      filter: () => `<div class="text-center">
            <button class="btn btn-sm btn-primary">수정</button>
            <button class="btn btn-sm btn-danger">삭제</button>
        </div>`,
    },
    {
      title: '상담일',
      column: 'scheduledAt',
      type: 'date',
    },
    {
      title: '상담내용',
      column: 'memo',
      type: 'memo',
      noorder: true,
      thStyle: () => {
        return { width: '240px' };
      },
    },
    {
      title: '상담사',
      column: 'counselor__name',
      noorder: true,
    },
    {
      title: '카테고리',
      type: 'list_mini',
      noorder: true,
      filter: (o) =>
        `${(o.items || [])
          .map((item) => `<p>${(item.category || {}).name || ''}</p>`)
          .join('\n')}`,
    },
    {
      title: '시/수술명',
      type: 'list',
      noorder: true,
      filter: (o) =>
        `${(o.items || []).map((item) => `<p>${item.name}</p>`).join('\n')}`,
    },
    {
      title: 'RESULTS',
      column: 'result__name',
      noorder: true,
    },
    // {
    //     title: 'UPDATE_AT',
    //     column: 'updatedAt',
    //     filter: o => {
    //         return `<div>
    //             <div>${moment(o.updatedAt).format('YYYY-MM-DD')}</div>
    //             <div>${moment(o.updatedAt).format('HH:mm:ss')}</div>
    //         </div>`
    //     },
    // }
  ],
};
