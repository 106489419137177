import React, { useCallback, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import { numArray, fillTimeframe, translate } from 'filters';
import hooks from 'hooks';
import DataTableAlpha from 'components/data-table/DataTableAlpha';
import moment from 'moment';
import PropTypes from 'prop-types';
import models from 'models';
import useCustomerChart from 'hooks/useCustomerChart';
import { useAuth } from 'store/auth';
import { useEvent } from 'hooks/useEvent';
import { useModal } from 'hooks/useModal';
import { useServices } from 'hooks/useServices';
import { useToast } from 'hooks/useToast';
import styled from 'styled-components';
import { RadioInput } from 'components/common/RadioInput';
import DatePicker from 'components/common/DatePicker';

const IsNewCustomerRadioInput = styled(RadioInput)`
  margin-left: 8px;
`;

const CustomerRadioInput = styled(RadioInput)`
  margin-left: 16px;
`;

const ModalCustomerWorkIn = ({ options, close }) => {
  const history = useHistory();
  const auth = useAuth();
  const eventBus = useEvent();
  const modal = useModal();
  const services = useServices();
  const toast = useToast();

  const [loadingBtnDisabledFlag, setLoadingBtnDisabledFlag] = useState(false);
  const [obj, setObj] = useState({});
  const [paymentData, setPaymentData] = useState([]);
  const [surgeryTreatmentItems, setSurgeryTreatmentItems] = useState([]);

  const [facialist, setFaicalist] = useState([]);
  const [counselors, setCounselors] = useState([]);
  const [doctors, setDoctors] = useState([]);
  const [createdList, setCreatedList] = useState([]); //접수자
  const [acquisitionChannel, setAcquisitionChannel] = useState([]);
  const [treatmentItemCategories, setTreatmentItemCategories] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [itemsList, setItemsList] = useState([{}]); //관심 시/수술 카테고리 배열

  // 방문시간 옵션 리스트
  const [startHourOptions, setStartHourOptions] = useState([]);
  // 부서, 예약일 변경 object
  const [timeSetObj, setTimeSetObj] = useState({
    departmentId: null,
    scheduledAt: null,
  });

  const timeCountCallApi = useCallback(async () => {
    let time = { items: null };
    let splitStartHour = '';
    let selectStartHour = '';
    if (obj && obj.startHour !== null && typeof obj.startHour === 'string') {
      splitStartHour = obj.startHour.split('(')[0].trim();
      selectStartHour = obj.startHour;
    }

    if (
      obj.department &&
      obj.department.id !== undefined &&
      obj.scheduledAt !== undefined
    ) {
      let scheduledAt = moment(obj.scheduledAt).format('YYYY-MM-DD');
      let departmentId = obj.department.id;
      const resp = await services.crm.crud.appointment.time_count({
        scheduledAt,
        departmentId,
      });
      if (!resp) return;
      time = resp.data;
    }

    const hours = numArray(18)
      .map((val) => val + 6)
      .map((val) => {
        if (val >= 24) val -= 24;
        return `${(val.toString().length === 1 ? '0' : '') + val}`;
      });
    let result = [];
    hours.forEach((hour) => {
      result = result.concat(
        fillTimeframe(auth.me.clinic.appointmentTimeUnit).map((val) => {
          let timeSet = `${hour}:${val}`;

          if (time.items !== null && time.items.length > 0) {
            let item = time.items.find((f) => {
              return f.time === timeSet;
            });

            if (item.count > 0) {
              timeSet += `\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0(${
                item.count || 0
              })`;
            }

            if (item.time === splitStartHour) {
              selectStartHour = timeSet;
            }
          } else {
            selectStartHour = splitStartHour;
          }
          return timeSet;
        })
      );
    });

    if (selectStartHour !== '') {
      setObj({
        ...obj,
        startHour: selectStartHour,
      });
    }

    setStartHourOptions(result);
  }, [timeSetObj]);

  useEffect(() => {
    timeCountCallApi();
  }, [timeCountCallApi]);

  const departmentCategoriesCallApi = useCallback(async () => {
    try {
      let params = { limit: 300 };
      const resp = await services.crm.crud.departmentCategory.all(params);
      if (!resp) return;
      // eslint-disable-next-line array-callback-return
      let changeResp = resp.data.filter((category) => {
        if (category.departments.length > 0 && category.visible) {
          category.departments.forEach((item) => {
            item.category = { id: category.id, name: category.name };
            return item;
          });
          return category;
        }
      });

      let department = [];
      changeResp.forEach((category) => {
        department = department.concat(category.departments || []);
      });

      setDepartments(department);
    } catch (e) {
      console.log(e.description);
    }
  }, [services.crm.crud.departmentCategory]);

  useEffect(() => {
    departmentCategoriesCallApi();
  }, [departmentCategoriesCallApi]);

  const treatmentItemCategoriesCallApi = useCallback(async () => {
    try {
      let params = { limit: 10000, visible: true };
      const resp = await services.crm.treatment.categories.items_categories_v2(
        params
      );
      if (!resp) return;
      setTreatmentItemCategories(resp.data);
    } catch (e) {
      console.log(e.description);
    }
  }, [services.crm.treatment.categories]);

  useEffect(() => {
    treatmentItemCategoriesCallApi();
  }, [treatmentItemCategoriesCallApi]);

  const acquisitionChannelCallApi = useCallback(async () => {
    let params = { limit: 300, visible: true };
    const resp = await services.crm.customer.acquisitionChannel.all(params);
    if (!resp) return;

    setAcquisitionChannel(resp.data);
  }, [services.crm.customer.acquisitionChannel]);

  useEffect(() => {
    acquisitionChannelCallApi();
  }, [acquisitionChannelCallApi]);

  const createdListCallApi = useCallback(async () => {
    let params = { userStatus: 'active', limit: 300 };
    const resp = await services.crm.user.duty(params);
    if (!resp) return;

    setCreatedList(resp.data);
  }, [services.crm.user]);

  useEffect(() => {
    createdListCallApi();
  }, [createdListCallApi]);

  const facialistCallApi = useCallback(async () => {
    let params = { duty: '피부관리사', userStatus: 'active', limit: 300 };
    const resp = await services.crm.user.duty(params);
    if (!resp) return;

    setFaicalist(resp.data);
  }, [services.crm.user]);

  useEffect(() => {
    facialistCallApi();
  }, [facialistCallApi]);

  const counselorCallApi = useCallback(async () => {
    let params = { duty: '상담사', userStatus: 'active', limit: 300 };
    const resp = await services.crm.user.duty(params);
    if (!resp) return;

    setCounselors(resp.data);
  }, [services.crm.user]);

  useEffect(() => {
    counselorCallApi();
  }, [counselorCallApi]);

  const doctorCallApi = useCallback(async () => {
    let params = { duty: '의사', userStatus: 'active', limit: 300 };
    const resp = await services.crm.user.duty(params);
    if (!resp) return;

    setDoctors(resp.data);
  }, [services.crm.user]);

  useEffect(() => {
    doctorCallApi();
  }, [doctorCallApi]);

  const onSelectSurgeryAction = (object) => {
    const event = object.event;
    const surgeries = object.row;
    const classList = event.target.classList;

    if (classList.contains('checkbox') || classList.contains('zmdi-check')) {
      if (surgeries.$$checked) {
        setSurgeryTreatmentItems([...surgeryTreatmentItems, surgeries]);
      } else {
        let list = surgeryTreatmentItems.filter((v) => {
          return v.id !== surgeries.id;
        });
        setSurgeryTreatmentItems([...list]);
      }
    }
  };

  const paymentCallApi = useCallback(async () => {
    try {
      //수납된(미수포함) / 남은 회차가 1회 이상 남은  시/수술 내역 노출
      //남은 회차가 0회인(환불포함) 시/수술은 노출 x

      if (!options.customer.id) return;

      const resp = await useCustomerChart.useGetRemainingPaidTreatmentItems(
        options.customer.id
      );
      if (!resp) return;

      setPaymentData(resp);
    } catch (e) {
      console.log(e.desrcription);
    }
  }, [services.crm.payment, options.customer.id]);

  const validator = useCallback(() => {
    if (
      (obj || {}).department === undefined ||
      (obj || {}).department === null
    ) {
      toast.error('부서를 선택하세요.');
      setLoadingBtnDisabledFlag(false);
      return false;
    }
    return true;
  }, [toast, obj]);

  const onClickSave = useCallback(
    async (surgeryTreatmentItems) => {
      setLoadingBtnDisabledFlag(true);

      if (validator()) {
        //워크인접수는 내방한것으로 간주하여 vistied: true
        let payload = { ...obj, status: `${obj.type}_waiting`, visited: true };
        payload = surgeryTreatmentItems.length
          ? { ...payload, paymentTreatmentItems: surgeryTreatmentItems }
          : payload;
        let items = itemsList.filter(
          (v) => v !== undefined && Object.keys(v).length !== 0
        );
        payload = items.length > 0 ? { ...payload, items: items } : payload;

        //당일접수시 자동문자 보내지지 않도록
        payload.sendAutoSms = false;
        payload.appointmentStatus = `${obj.type}_waiting`;
        payload.customerId = ((options || {}).customer || {}).id;
        payload.startHour = obj.startHour.split('(')[0].trim(0);

        try {
          const resp = await services.crm.crud.appointment.create(payload);
          if (!resp) return;
          modal
            .confirm({
              type: 'SUCCESS',
              msg: '생성되었습니다.',
            })
            .then(() => {
              //당일접수 "완료" 시, 예약캘린더 혹은 현황판에 곧바로 생성되도록 bus 호출
              if (history.location.pathname === '/crm/appointment-boards') {
                eventBus.emit('boardsLoadAppointments');
              } else if (history.location.pathname === '/crm/home') {
                eventBus.emit('homeLoadAppointments');
              }

              close();
            });
        } catch (e) {
          console.log(e.description);
          let message = e.description;
          if (e.description === 'AppointmentExists') {
            message = '해당 시간에 이미 예약이 존재합니다.';
          }
          if (e.code === 412 && e.description === 'OutOfWorkHour') {
            message = '영업시간이내에만 예약을 생성할 수 있습니다.';
          }

          modal
            .confirm({
              type: 'ERROR',
              msg: message,
            })
            .then(() => {
              setLoadingBtnDisabledFlag(false);
            });
        }
      }
    },
    [history, eventBus, obj, validator, close, itemsList]
  );

  const onChangeValue = (column, value) => {
    obj[column] = value;
    setObj({ ...obj });
  };

  const initAppointment = useCallback(() => {
    let startTime = moment().format('HH:mm');
    let startTimeStr = startTime.substr(0, 4) + '0';

    setObj({
      customer: options.customer,
      type: 'consulting',
      estimatedServiceMinutes: 30,
      startHour: startTimeStr,
      createdId: auth.me.id,
      isNewCustomer: options.customer.isNewCustomer,
    });
  }, [options.customer]);

  const addTreatmentItem = () => {
    if (treatmentItemCategories[0] === undefined) {
      toast.error('수납코드 설정에서 시/수술을 추가해주세요.');
      return;
    }
    let list = [...itemsList];
    list.push({});
    setItemsList([...list]);
  };

  const onClickCreateAppointments = useCallback(async () => {
    initAppointment();
    paymentCallApi();
  }, [initAppointment, paymentCallApi]);

  const init = useCallback(async () => {
    onClickCreateAppointments();
  }, [onClickCreateAppointments]);

  useEffect(() => {
    init();

    return () => {
      setObj({});
    };
  }, [init]);

  const objectWithoutKey = (object, key) => {
    // eslint-disable-next-line no-unused-vars
    const { [key]: deletedKey, ...otherKeys } = object;
    return otherKeys;
  };

  const onChangeType = (e) => {
    const { value } = e.target;
    if (value === 'surgery' && !paymentData.length) {
      toast.success('진행중인 시/수술이 없습니다.');
      return;
    }

    let changeObj = {
      ...obj,
      type: value,
    };

    //선택한 type에 따라 obj도 변경되어야 한다
    switch (value) {
      case 'consulting':
      case 'treatment':
        changeObj = objectWithoutKey(changeObj, 'facialist');
        setSurgeryTreatmentItems([]);
        setPaymentData((data) =>
          data.map((v) => {
            return {
              ...v,
              $$checked: false,
            };
          })
        );
        break;
      case 'surgery':
        changeObj = objectWithoutKey(changeObj, 'counselor');
        changeObj = objectWithoutKey(changeObj, 'acquisitionChannel');
        changeObj = objectWithoutKey(changeObj, 'items');
        setItemsList([{}]);
        break;
      default:
        break;
    }

    setObj(changeObj);
  };

  return (
    <div className="modal-customer-chart modal-work-in">
      <div className="customer-chart chart-conts">
        <div className="customer-appointments chart-page">
          <div className="head flex-row flex-between items-start m-b-16">
            <div className="title">당일접수</div>
            <i onClick={() => close()} className="zmdi zmdi-close" />
          </div>
          <div className="card">
            <div className="form-control flex-wrap required">
              <label>접수종류</label>
              <div className="flex-row">
                <button
                  className={`btn flex-wrap _small m-r-8 ${
                    (obj || {}).type === 'consulting'
                      ? 'btn-primary'
                      : 'btn-normal'
                  }`}
                  name="type"
                  value="consulting"
                  onClick={onChangeType}
                >
                  상담접수
                </button>
                <button
                  className={`btn flex-wrap _small m-r-8 ${
                    (obj || {}).type === 'treatment'
                      ? 'btn-primary'
                      : 'btn-normal'
                  }`}
                  name="type"
                  value="treatment"
                  onClick={onChangeType}
                >
                  진료접수
                </button>
                <button
                  className={`btn flex-wrap _small ${
                    (obj || {}).type === 'surgery'
                      ? 'btn-primary'
                      : 'btn-normal'
                  }`}
                  name="type"
                  value="surgery"
                  onClick={onChangeType}
                >
                  시/수술접수
                </button>
              </div>
            </div>
            <div className="form-double">
              <div className="form-control">
                <label className="label-required">부서</label>
                <select
                  value={(departments || []).findIndex(
                    (department) =>
                      department.id === ((obj || {}).department || {}).id
                  )}
                  onChange={(e) => {
                    (obj || {}).department = departments[e.target.value];
                    setObj({ ...obj });
                    setTimeSetObj({
                      ...timeSetObj,
                      departmentId: obj.department.id,
                    });
                  }}
                >
                  <option value={-1} hidden disabled>
                    부서를 선택하세요.
                  </option>
                  {(departments || []).map((department, idx) => (
                    <option
                      key={idx}
                      value={idx}
                      className={!department.visible ? 'display-none' : ''}
                    >
                      {department.category.name} - {department.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-control">
                <label className="label-required">접수일</label>
                <DatePicker
                  value={
                    (obj || {}).scheduledAt
                      ? new Date((obj || {}).scheduledAt)
                      : setObj({ ...obj, scheduledAt: moment().format() })
                  }
                  onChange={(scheduledAt) => {
                    scheduledAt === null
                      ? onChangeValue('scheduledAt', moment().format())
                      : onChangeValue(
                          'scheduledAt',
                          moment(scheduledAt).format()
                        );
                    setTimeSetObj({
                      ...timeSetObj,
                      scheduledAt,
                    });
                  }}
                  dateFormat="yyyy/MM/dd"
                />
              </div>
              <div className="form-control">
                <label>작성자</label>
                <select
                  value={obj?.createdId || -1}
                  onChange={(e) => {
                    const idx = createdList.findIndex(
                      (f) => f.id === Number(e.target.value)
                    );

                    obj['createdId'] = createdList[idx]?.id;
                    setObj({ ...obj });
                  }}
                >
                  <option value={-1} hidden disabled>
                    작성자를 선택하세요.
                  </option>
                  <option value="null">-</option>
                  {(createdList || []).map((o, idx) => (
                    <option key={idx} value={o.id}>
                      {' '}
                      {o.name}{' '}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="form-double">
              <div className="form-control">
                <label className="label-required">방문시간</label>
                {hooks.selector({
                  options: startHourOptions,
                  value: (obj || {}).startHour,
                  onChange: (e) => {
                    (obj || {}).startHour = e.target.value;
                    setObj({ ...obj });
                  },
                })}
              </div>
              <div className="form-control">
                <label className="label-required">예상소요시간</label>
                <select
                  value={(obj || {}).estimatedServiceMinutes || 30}
                  onChange={(e) => {
                    (obj || {}).estimatedServiceMinutes = parseInt(
                      e.target.value.split('분')[0]
                    );
                    setObj({ ...obj });
                  }}
                >
                  {hooks.estimatedServiceMinutesList.map((value, idx) => (
                    <option value={value} key={idx}>
                      {`${
                        Math.floor(value / 60) !== 0
                          ? `${Math.floor(value / 60)}시간`
                          : ''
                      }`}
                      {`${value % 60 !== 0 ? `${value % 60}분` : ''}`}
                    </option>
                  ))}
                </select>
              </div>

              {auth.isNewCustomerOption === 'manual' ? (
                <div className="radios flex-row">
                  <div className="radio-item flex-row items-center flex-wrap m-r-16">
                    <div className="form-control">
                      <label className="label-required">신/구환접수</label>
                      <div className="wrap-radio">
                        <IsNewCustomerRadioInput
                          name="isNewCustomer"
                          value="true"
                          checked={obj && obj.isNewCustomer === true}
                          onChange={() => {
                            obj.isNewCustomer = true;
                            setObj({ ...obj });
                          }}
                        />
                        <span>신환접수</span>
                        <CustomerRadioInput
                          name="isNewCustomer"
                          value="false"
                          checked={obj && obj.isNewCustomer !== true}
                          onChange={() => {
                            obj.isNewCustomer = false;
                            setObj({ ...obj });
                          }}
                        />
                        <span>구환접수</span>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>

            <div className="form-double">
              {(obj || {}).type === 'consulting' ||
              (obj || {}).type === 'treatment' ? (
                <div className="form-control">
                  <label>상담사</label>
                  <select
                    value={counselors.findIndex(
                      (existing) => existing.id === (obj['counselor'] || {}).id
                    )}
                    onChange={(e) => {
                      obj['counselor'] = counselors[e.target.value];
                      setObj({ ...obj });
                    }}
                  >
                    <option value={-1} hidden disabled>
                      상담사를 선택하세요.
                    </option>
                    <option value="null">-</option>
                    {(counselors || []).map((o, idx) => (
                      <option key={idx} value={idx}>
                        {' '}
                        {o.name}{' '}
                      </option>
                    ))}
                  </select>
                </div>
              ) : null}
              {(obj || {}).type === 'consulting' ||
              (obj || {}).type === 'treatment' ||
              (obj || {}).type === 'surgery' ? (
                <div className="form-control">
                  <label>의사</label>
                  <select
                    value={doctors.findIndex(
                      (existing) => existing.id === (obj['doctor'] || {}).id
                    )}
                    onChange={(e) => {
                      obj['doctor'] = doctors[e.target.value];
                      setObj({ ...obj });
                    }}
                  >
                    <option value={-1} hidden disabled>
                      의사를 선택하세요.
                    </option>
                    <option value="null">-</option>
                    {(doctors || []).map((o, idx) => (
                      <option key={idx} value={idx}>
                        {' '}
                        {o.name}{' '}
                      </option>
                    ))}
                  </select>
                </div>
              ) : null}
              {(obj || {}).type === 'surgery' ? (
                <div className="form-control">
                  <label>피부관리사</label>
                  <select
                    value={facialist.findIndex(
                      (existing) => existing.id === (obj['facialist'] || {}).id
                    )}
                    onChange={(e) => {
                      obj['facialist'] = facialist[e.target.value];
                      setObj({ ...obj });
                    }}
                  >
                    <option value={-1} hidden disabled>
                      피부관리사를 선택하세요.
                    </option>
                    <option value="null">-</option>
                    {(facialist || []).map((o, idx) => (
                      <option key={idx} value={idx}>
                        {' '}
                        {o.name}{' '}
                      </option>
                    ))}
                  </select>
                </div>
              ) : null}
              {(obj || {}).type !== 'surgery' ? (
                <div className="form-control">
                  <label>내원경로</label>
                  <select
                    value={acquisitionChannel.findIndex(
                      (existing) =>
                        existing.id ===
                        ((obj || {})['acquisitionChannel'] || {}).id
                    )}
                    onChange={(e) => {
                      (obj || {})['acquisitionChannel'] =
                        acquisitionChannel[e.target.value];
                      setObj({ ...obj });
                    }}
                  >
                    <option value={-1} hidden disabled>
                      내원경로를 선택하세요.
                    </option>
                    <option value="null">-</option>
                    {acquisitionChannel.map((o, idx) => (
                      <option key={idx} value={idx}>
                        {o.name}
                      </option>
                    ))}
                  </select>
                </div>
              ) : null}
            </div>

            {(obj || {}).type === 'surgery' && paymentData.length ? (
              <div className="form-control form-full">
                <label>진행가능한 시/수술</label>
                <DataTableAlpha
                  model={models.crm.appointmentSurgery}
                  data={paymentData}
                  hideBottom={true}
                  hideAllCheck={true}
                  onAction={onSelectSurgeryAction}
                />
              </div>
            ) : null}

            {(obj || {}).type !== 'surgery' ? (
              <>
                <div className="form-wide m-b-20">
                  {itemsList.map((list, idx) => {
                    return (treatmentItemCategories || []).length > 0 ? (
                      <div className="form-double-side" key={idx}>
                        <div className="form-control">
                          {idx === 0 && <label>시/수술 카테고리</label>}
                          <select
                            value={(treatmentItemCategories || []).findIndex(
                              (category) =>
                                category.id ===
                                ((itemsList[idx] || []).category || {}).id
                            )}
                            onChange={(e) => {
                              if (e.target.value !== -1) {
                                const items = (
                                  treatmentItemCategories[e.target.value] || {}
                                ).items;
                                itemsList[idx] = (items || [])[0];
                                setItemsList([...itemsList]);
                              } else {
                                itemsList[idx] = [];
                                setItemsList([...itemsList]);
                              }
                            }}
                          >
                            <option value={-1} hidden disabled>
                              시/수술 카테고리를 선택하세요.
                            </option>
                            <option value="null">없음</option>
                            {(treatmentItemCategories || []).map(
                              (category, idx) => (
                                <option
                                  key={idx}
                                  value={idx}
                                  className={
                                    !category.visible ? 'display-none' : ''
                                  }
                                >
                                  {category.name}
                                </option>
                              )
                            )}
                          </select>
                        </div>
                        <div className="form-control">
                          {idx === 0 && <label>시/수술명</label>}
                          {(treatmentItemCategories || []).length > 0 ? (
                            <select
                              value={(
                                (
                                  (
                                    treatmentItemCategories || []
                                  ).find((category) =>
                                    category.items.some(
                                      (item) =>
                                        item.id === (itemsList[idx] || {}).id
                                    )
                                  ) || {}
                                ).items || []
                              ).findIndex(
                                (item) => item.id === (itemsList[idx] || {}).id
                              )}
                              onChange={(e) => {
                                itemsList[idx] = (
                                  treatmentItemCategories || []
                                ).find((category) =>
                                  category.items.some(
                                    (item) =>
                                      item.id === (itemsList[idx] || {}).id
                                  )
                                ).items[e.target.value];
                                setItemsList([...itemsList]);
                              }}
                            >
                              <option value={-1} hidden disabled>
                                시/수술명을 선택하세요.
                              </option>
                              <option value="null">없음</option>
                              {(
                                (
                                  (treatmentItemCategories || []).find(
                                    (category) =>
                                      category.id ===
                                      ((itemsList[idx] || {}).category || {}).id
                                  ) || {}
                                ).items || []
                              ).map((item, idx) => (
                                <option
                                  key={idx}
                                  value={idx}
                                  className={
                                    !item.visible ? 'display-none' : ''
                                  }
                                >
                                  {item.name}
                                </option>
                              ))}
                            </select>
                          ) : null}
                        </div>

                        <button
                          className="btn btn-danger _small"
                          onClick={() => {
                            modal
                              .basic({
                                body:
                                  '삭제하시겠습니까? 최종 저장 전에는 반영되지 않습니다.',
                                buttons: [
                                  { text: 'CANCEL', class: 'btn-default' },
                                  { text: 'CONFIRM', class: 'btn-danger' },
                                ],
                              })
                              .then((selectedBtnIdx) => {
                                if (selectedBtnIdx === 1) {
                                  itemsList.splice(idx, 1);
                                  setItemsList([...itemsList]);
                                }
                              });
                          }}
                        >
                          {translate('DELETE')}
                        </button>
                      </div>
                    ) : null;
                  })}
                </div>
                <div className="btnwrap m-t-8">
                  <button
                    className="btn btn-basic middle _small _full"
                    onClick={addTreatmentItem}
                  >
                    입력란 추가
                  </button>
                </div>
              </>
            ) : null}
            <div className="form-wide">
              <div className="form-control">
                <label>접수메모</label>
                <textarea
                  value={(obj || {}).memo || ''}
                  onChange={(e) => onChangeValue('memo', e.target.value)}
                  placeholder="메모를 입력하세요."
                />
              </div>
            </div>

            <div className="flex-row wrap-bottom-btns">
              <button className="btn btn-normal _small m-r-8" onClick={close}>
                {'입력취소'}
              </button>
              <button
                className="btn btn-basic _small"
                disabled={`${loadingBtnDisabledFlag ? 'disabled' : ''}`}
                onClick={() => onClickSave(surgeryTreatmentItems)}
              >
                접수완료
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ModalCustomerWorkIn.propTypes = {
  options: PropTypes.object,
  close: PropTypes.func,
};

export default observer(ModalCustomerWorkIn);
