export default {
  keys: [
    {
      title: '설정',
      noorder: true,
      filter: (o) => {
        if (o.user.type === 'owner') {
          return `<button class="btn btn-primary btn-sm m-r-8 btn-edit">수정</button>`;
        } else {
          if (o.user.status === 'active') {
            return `<button class="btn btn-primary btn-sm m-r-8 btn-edit">수정</button>
                    <button class="btn btn-warning btn-sm m-r-8 btn-status">미사용</button>
                    <button class="btn btn-danger btn-sm m-r-8 btn-delete">삭제</button>`;
          } else if (o.user.status !== 'deleted') {
            return `<button class="btn btn-sm m-r-8 btn-gray btn-status">사용</button>
                    <button class="btn btn-sm m-r-8 btn-gray btn-delete">삭제</button>`;
          }
        }
      },
    },
    {
      title: '소속조직',
      noorder: true,
      filter: (o) => o.parent.name,
    },
    {
      title: '이름',
      noorder: true,
      filter: (o) => o.user.name,
    },
    {
      title: '직무',
      noorder: true,
      filter: (o) => o.user.duty,
    },
    {
      title: '직급',
      noorder: true,
      filter: (o) => o.user.position,
    },
    {
      title: '권한설정',
      noorder: true,
      filter: (o) => {
        if (o.user.type === 'owner') {
          return `<span>관리자</span>`;
        } else {
          let disabled =
            o.user.status === 'active' ? 'btn-primary' : 'btn-gray';
          return `<button class="btn btn-sm ${disabled} btn-settings">설정</button>`;
        }
      },
    },
    {
      title: '비밀번호 초기화',
      noorder: true,
      filter: () => {
        return `<button class="btn btn-sm btn-primary btn-change-pw">초기화</button>`;
      },
    },
    {
      title: '상담문의 응대담당자',
      noorder: true,
      type: 'multiCheck',
      checkUse: (o) => (o.user.status !== 'active' ? 'true' : 'false'),
    },
  ],
};
