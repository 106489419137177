//import React, { forwardRef, useRef } from 'react';
import React from 'react';
import { registerLocale } from 'react-datepicker';
import moment from 'moment';
import 'moment/locale/ko';
import { observer } from 'mobx-react';
import {
  convertCodeTranslationToLanguage,
  convertCodeTranslationToLanguageAndLocale,
} from 'filters';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { faCalendarAlt as farCalendarAlt } from '@fortawesome/free-regular-svg-icons';
import { ko } from 'date-fns/esm/locale';
import PropTypes from 'prop-types';
import { useTranslation } from 'hooks/useTranslation';
import DatePicker from 'components/common/DatePicker';

const InputDatePicker = ({
  name,
  onChange,
  value,
  dateFormat,
  serverDateFormat = 'YYYY-MM-DD',
  placeholder,
  ...props
}) => {
  const translation = useTranslation();
  const translationCode = translation.locale ? translation.locale : false;
  const langCode = convertCodeTranslationToLanguage[translationCode];
  moment.locale(convertCodeTranslationToLanguageAndLocale[translationCode]);

  value = moment(value, serverDateFormat, true).isValid()
    ? moment(value, serverDateFormat).toDate()
    : null;

  const onChangeHandler = (e) => {
    const dtName = e.target.name;
    const _dtValue = e.target.value;
    const dtValue = moment(_dtValue).format(serverDateFormat);

    const payload = {
      target: {
        name: dtName,
        value: dtValue,
      },
    };

    onChange(payload);
  };

  return (
    <div className="uno-datepicker">
      <CommonPicker
        name={name}
        value={value}
        langCode={langCode}
        dateFormat={dateFormat}
        translationCode={translationCode}
        onChange={onChangeHandler}
        placeholder={placeholder}
        {...props}
      />
    </div>
  );
};

InputDatePicker.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.object,
  translation: PropTypes.object,
  dateFormat: PropTypes.object,
  serverDateFormat: PropTypes.object,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
};

const datepickerCustomOptions = (lang) => {
  const _lang = lang;
  const options = {
    dateFormat: {
      ko: 'yyyy/MM/dd',
      en: 'MM/dd/yyyy',
    },
    yearUnit: {
      ko: '년',
      en: '',
    },
    yearFormat: {
      ko: (year) => `${year}년`,
      en: (year) => year,
    },
    flexOrder: {
      ko: {
        years: 1,
        months: 2,
      },
      en: {
        years: 2,
        months: 1,
      },
    },
    placeholder: {
      ko: '날짜선택',
      en: 'Select a date',
    },
  };

  return {
    dateFormat: options.dateFormat[_lang],
    yearUnit: options.yearUnit[_lang],
    yearFormat: options.yearFormat[_lang],
    flexOrder: options.flexOrder[_lang],
    placeholder: options.placeholder[_lang],
  };
};

export const CommonPicker = ({
  langCode,
  name,
  value,
  onChange,
  placeholder,
  ...props
}) => {
  registerLocale('ko', ko); // datepicker에 date-fns의 ko locale 정보를 추가
  //const customDateInpurRef = useRef();

  const datepickerOptions = datepickerCustomOptions(langCode);

  // 현재 연도를 기준으로 기준으로 (올해-90)년 ~ (올해+5년)
  // TODO 한쓰이는 기능같아서 일단 주석처리 해놨어요
  /*const yearRange = 5;
  // const _minYear = moment().add(-yearRange,'y').year()
  const _minYear = moment().add(-90, 'y').year();
  const _maxYear = moment()
    .add(+yearRange, 'y')
    .year();
  const minYear =
    moment(value).year() < _minYear ? moment(value).year() : _minYear;
  const maxYear =
    moment(value).year() > _maxYear ? moment(value).year() : _maxYear;

  const years = [...Array(maxYear - minYear + 1).keys()].map((y) => {
    return {
      label: datepickerOptions.yearFormat(y + minYear),
      value: y + minYear,
    };
  });

  // [ 1월=0, 2월=1 ,,, 12월=11 ]
  const months = moment
    .months()
    .map((val, idx) => ({ value: idx, label: val }));*/

  return (
    <div className="search-item">
      <DatePicker
        className="text-center"
        onChange={(eventValue) => {
          const payload = {
            target: {
              name: name,
              value: eventValue,
            },
          };
          return onChange(payload);
        }}
        value={value ? value : null}
        locale={langCode}
        {...props}
        // dateFormat={dateFormat?dateFormat:datepickerOptions.dateFormat}
        // customInput={<CustomDateInput ref={customDateInpurRef} />}
        // customInput={<button/>}
        // renderCustomHeader={({
        //   date,
        //   changeYear, changeMonth,
        //   decreaseMonth, increaseMonth,
        //   prevMonthButtonDisabled, nextMonthButtonDisabled
        // }) => (
        //   <div className="datepicker-container-header">
        //     <button className="month-previous" onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
        //       <FontAwesomeIcon icon={faCaretLeft} size="2x"/>
        //     </button>
        //     <select className={`year-select flex-order-${datepickerOptions.flexOrder.years}`} value={moment(date).year()}
        //       onChange={({ target: { value } }) => changeYear(value)}
        //     >
        //       {years.map(({label, value}) => (<option key={value} value={value}>{label}</option>))}
        //     </select>
        //     <select className={`month-select flex-order-${datepickerOptions.flexOrder.months}`} value={moment(date).month()}
        //       onChange={({ target: { value } }) =>changeMonth( value )}
        //     >
        //       { months.map(month => (
        //         <option key={month.value} value={month.value}>
        //           {month.label}
        //         </option>
        //       ))}
        //     </select>
        //     <button className="month-next" onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
        //       <FontAwesomeIcon icon={faCaretRight} size="2x"/>
        //     </button>
        //   </div>
        // )}
        placeholderText={
          placeholder ? placeholder : datepickerOptions.placeholder
        }
        {...props}
      />
    </div>
  );
};

CommonPicker.propTypes = {
  langCode: PropTypes.string,
  name: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  value: PropTypes.object,
  onChange: PropTypes.func,

  placeholder: PropTypes.string,
};

/*const CustomDateInput = forwardRef((props, ref) => {
  const { onClick, value, placeholder, disabled } = props;
  return (
    <button
      className={`input-datepicker ${value ? ' ' : ' empty '}`}
      onClick={(e) => onClick(e)}
      disabled={disabled}
    >
      <label>{value ? value : disabled ? null : placeholder}</label>
      <FontAwesomeIcon className="icon-calendar" icon={farCalendarAlt} />
    </button>
  );
});*/

export default observer(InputDatePicker);
