import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { translate } from 'filters';
import { useModal } from 'hooks/useModal';
import { useServices } from 'hooks/useServices';
import styled from 'styled-components';

const Input = styled.input`
  width: 100%;
  height: 34px;
  padding: 8px 20px;
  border-color: #d7e3f1;
  font-size: 12px;
  min-height: 10px;
  border-radius: 4px;
  background-color: #f3f8ff;
  color: #000;
  border: 1px solid #dee2ec;
`;

const ModalTemporaryChangePassword = ({ options, close }) => {
  const modal = useModal();
  const services = useServices();
  const [newPassword, setNewPassword] = useState(null);
  const [errNewPassword, setErrNewPassword] = useState(null);

  const onClickConfirm = async () => {
    try {
      let payload = { userId: options.userId, password: newPassword };
      await services.crm.organizations.owner.change_password(payload);

      modal
        .confirm({
          type: 'SUCCESS',
          msg: '초기화하였습니다.',
        })
        .then(() => {
          close();
        });
    } catch (e) {
      // if(e.code === 403 && e.description === 'no permission') {
      //   toast.error('owner 계정만 초기화기능을 사용할 수 있습니다.')
      //   return
      // }
      modal.confirm({
        type: 'ERROR',
        msg: e.description,
      });
    }
  };

  useEffect(() => {
    if (!newPassword) {
      setErrNewPassword(null);
      return;
    }

    setErrNewPassword(newPassword.length < 8 ? 'ERROR_NEW_PASSWORD' : null);
  }, [newPassword, setErrNewPassword]);

  return (
    <div className="modal-change-password">
      <div className="head flex-row flex-between items-start m-b-16">
        <div className="title">비밀번호를 초기화 하시겠습니까?</div>
      </div>
      <div className="inputs">
        <div className="form-control required">
          <label>임시비밀번호</label>
          <Input
            type="password"
            onChange={(e) => setNewPassword(e.target.value)}
          />
          {errNewPassword ? (
            <div className="error-form">{translate(errNewPassword)}</div>
          ) : null}
        </div>
      </div>

      <div className="buttons">
        <div className="flex-row">
          <button onClick={() => close()} className="btn btn-default">
            {translate('CANCEL')}
          </button>
          <button
            disabled={!newPassword || errNewPassword}
            onClick={() => onClickConfirm()}
            className="btn btn-primary"
          >
            {translate('CONFIRM')}
          </button>
        </div>
      </div>
    </div>
  );
};

ModalTemporaryChangePassword.propTypes = {
  options: PropTypes.object,
  close: PropTypes.func,
};

export default observer(ModalTemporaryChangePassword);
