import { observable, action, makeObservable } from 'mobx';

class PenChart {
  constructor() {
    makeObservable(this, {
      showHoverCard: observable,
      hoverCardOptions: observable,
      hoverCardPosition: observable,
      hoveringPenChart: observable,
      penChart: observable,

      setShowHoverCard: action,
      setHoverCardOptions: action,
      setHoverCardPosition: action,
      setPenChart: action,
    });
  }

  showHoverCard = false;
  hoverCardOptions = {};
  hoverCardPosition = {};
  hoveringPenChart = {};
  penChart = {};

  setShowHoverCard = (bool) => {
    this.showHoverCard = bool;
  };

  setHoverCardOptions = (obj) => {
    this.hoverCardOptions = obj;
  };

  setHoverCardPosition = (obj) => {
    this.hoverCardPosition = obj;
  };

  setHoveringPenChart = (obj) => {
    this.hoveringPenChart = obj;
  };

  setPenChart = (obj) => {
    this.penChart = obj;
  };
}

export default new PenChart();
