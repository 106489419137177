import React, { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import HideHidden from 'components/app/HideHidden';
import DataTableAlpha from 'components/data-table/DataTableAlpha';
import helpers from 'hooks/helpers';
import ModalMessageAutoNotification from 'components/modals/codes/message-codes/ModalMessageAutoNotification';
import modelMessageAutoNotification from 'models/message-auto-notification';
import { translate } from 'filters';
import { $qb } from 'scripts/querybuilder';
import { useModal } from 'hooks/useModal';
import { useServices } from 'hooks/useServices';
import { useToast } from 'hooks/useToast';
import { useMessages } from 'hooks/useMessages';

const MessageAutoNotifications = () => {
  const modal = useModal();
  const services = useServices();
  const toast = useToast();
  const messages = useMessages();
  const defaultParams = $qb().limit().orderBy();
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [params, setParams] = useState(defaultParams);
  const [hideHidden, setHideHidden] = useState(false);
  const [loading, setLoading] = useState(true);
  const onParams = (p) => {
    setParams(p);
  };

  const callApi = useCallback(async () => {
    try {
      setLoading(true);
      const resp = await services.crm.notification.smsAutoNotifications.all({
        smsSituation: 'reserved,cancel,register,unvisited,leave',
      });
      setData(resp.data);
      setTotal(resp.total);
      setLoading(false);
    } catch (error) {
      toast.error(error.description);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    callApi();
  }, [callApi]);

  const onAction = (obj) => {
    const { row, key, event } = obj; // , rowIdx
    if (event.target.dataset.type === 'visible') {
      const toggleObj = {
        visible: !row['visible'],
        id: row['id'],
      };
      const endpoint = services.crm.notification.smsAutoNotifications;
      return helpers.toggleVisibilityById({
        obj: toggleObj,
        endpoint,
        callback: callApi,
      });
    }

    if (event.target.dataset.type === 'edit') {
      if (
        key.title === 'ACTION' ||
        row.departmentDeletedAt !== null ||
        row.departmentCategoryDeletedAt !== null
      ) {
        openModalEdit({ row, key, event });
      }
    }

    if (event.target.dataset.type === 'delete') {
      openModalDelete({
        endpoint: services.crm.notification.smsAutoNotifications,
        id: row['id'],
      });
    }

    if (key['type'] === 'image' && (row.images || []).length > 0) {
      modal.images({
        images: (row.images || []).map((image) => image.url),
      });
      return;
    }
  };

  const openModalDelete = ({ endpoint, id }) => {
    const onConfirm = async () => {
      try {
        await endpoint.delete(id);
        toast.success('DELETED');
        callApi();
        // eventBus.emit('callApi')
      } catch (e) {
        console.log('e', e);
        toast.error(e.description);
      }
    };

    modal
      .basic({
        body: translate('DELETE_CONFIRM'),
        buttons: [
          {
            text: 'CANCEL',
            class: 'btn-default',
          },
          {
            text: 'CONFIRM',
            class: 'btn-danger',
          },
        ],
      })
      .then((idx) => {
        if (idx === 1) {
          onConfirm();
        }
      });
  };

  const openModalEdit = useCallback(
    async ({ row }) => {
      //, key, event, rowIdx
      const resp = await services.crm.notification.smsAutoNotifications.departments_v2();
      if (!resp) return;

      let department = [...resp.data];
      //부서 해제를 위해 해제옵션 기본값 null 추가
      department.unshift({ id: -1, name: '전체' });
      messages.setDepartments(department);

      if (row !== undefined) {
        //row === undefined >> 추가 팝업
        if (
          row.departmentDeletedAt !== null ||
          row.departmentCategoryDeletedAt !== null
        ) {
          //삭제된 부서라면(삭제된 부서인경우 deletedAt이 노출)
          //departmentId = null로 세팅
          //null 로 세팅하는 이유는 기본 플레이스 홀더를 노출하기 위함.
          //실제 리퀘스트 보낼때는 departmentId = -1로 전달
          row.departmentId = null;
        }
      }

      modal
        .custom({
          component: ModalMessageAutoNotification,
          options: { autoNotification: row },
        })
        .then((submitted) => {
          if (submitted) {
            setData([]);
            setTotal(0);
            callApi();
          }
        });
    },
    [callApi]
  );

  return (
    <div className="message-auto-notification">
      <div className="route-top">
        <div className="title">{translate('SMS_AUTO_NOTIFICATIONS')}</div>
        <div className="flex-row items-center">
          <HideHidden
            toggleHandler={setHideHidden}
            text="미사용항목 보지않기"
          />
          <button
            onClick={() => {
              openModalEdit({});
            }}
            className="flex-wrap btn btn-sm m-r-8 btn-add"
          >
            {translate('ADD')}
          </button>
        </div>
      </div>
      <DataTableAlpha
        className="header-style-classic"
        model={modelMessageAutoNotification}
        data={hideHidden ? data.filter((row) => row.visible) : data}
        total={total}
        params={params}
        onParams={onParams}
        onAction={onAction}
        loading={loading}
        hideBottom={true}
        hideHidden={hideHidden}
        hasDeactivate
      />
    </div>
  );
};

export default observer(MessageAutoNotifications);
