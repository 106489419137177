import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'filters';
import { observer } from 'mobx-react';
import { useModal } from 'hooks/useModal';
import { useServices } from 'hooks/useServices';
import { useToast } from 'hooks/useToast';
import styled from 'styled-components';

const Input = styled.input`
  width: 100%;
  height: 34px;
  padding: 8px 20px;
  border-color: #d7e3f1;
  font-size: 12px;
  min-height: 10px;
  border-radius: 4px;
  background-color: #f3f8ff;
  color: #000;
  border: 1px solid #dee2ec;
`;

const ModalChangePassword = ({ close }) => {
  const modal = useModal();
  const services = useServices();
  const toast = useToast();
  const [newPassword, setNewPassword] = useState(null);
  const [passwordConfirm, setPasswordConfirm] = useState(null);
  const [errNewPassword, setErrNewPassword] = useState(null);
  const [errPasswordConfirm, setErrPasswordConfirm] = useState(null);

  const onClickConfirm = async () => {
    try {
      await services.crm.user.changePassword(newPassword);
      close();
      modal.basic({
        body: translate('PASSWORD_CHANGED'),
        buttons: [{ text: 'CONFIRM', class: 'btn-primary' }],
      });
    } catch (e) {
      toast.error(e.description);
    }
  };

  useEffect(() => {
    if (!newPassword) {
      setErrNewPassword(null);
      setErrPasswordConfirm(null);
      return;
    }

    setErrNewPassword(newPassword.length < 8 ? 'ERROR_NEW_PASSWORD' : null);
    setErrPasswordConfirm(
      newPassword && passwordConfirm && newPassword !== passwordConfirm
        ? 'ERROR_PASSWORD_CONFIRM'
        : null
    );
  }, [newPassword, passwordConfirm, setErrNewPassword, setErrPasswordConfirm]);

  return (
    <div className="modal-change-password">
      <div className="head flex-row flex-between items-start m-b-16">
        <div className="title">{translate('CHANGE_PASSWORD')}</div>
        <i onClick={() => close()} className="zmdi zmdi-close" />
      </div>
      <div className="inputs">
        <div className="form-control">
          <label>{translate('NEW_PASSWORD')}</label>
          <Input
            type="password"
            onChange={(e) => setNewPassword(e.target.value)}
          />
          {errNewPassword ? (
            <div className="error-form">{translate(errNewPassword)}</div>
          ) : null}
        </div>
        <div className="form-control">
          <label>{translate('PASSWORD_CONFIRM')}</label>
          <Input
            type="password"
            onChange={(e) => setPasswordConfirm(e.target.value)}
          />
          {errPasswordConfirm ? (
            <div className="error-form">{translate(errPasswordConfirm)}</div>
          ) : null}
        </div>
      </div>

      <div className="buttons">
        <div className="flex-row">
          <button onClick={() => close()} className="btn btn-default">
            {translate('CANCEL')}
          </button>
          <button
            disabled={
              !newPassword ||
              !passwordConfirm ||
              errNewPassword ||
              errPasswordConfirm
            }
            onClick={() => onClickConfirm()}
            className="btn btn-primary"
          >
            {translate('CONFIRM')}
          </button>
        </div>
      </div>
    </div>
  );
};

ModalChangePassword.propTypes = {
  close: PropTypes.func,
};

export default observer(ModalChangePassword);
