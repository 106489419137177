import React, { useState, useEffect, useCallback, useRef } from 'react';
import 'assets/styles/components/mobilescreen.scss';
import MessageAppTabBar from './ViewTabBar';
import MessageAppMainView from './ViewMain';
import PropTypes from 'prop-types';
import { useToast } from 'hooks/useToast';

const MessageApp = ({
  className = '',
  obj,
  setObj,
  titleComponent,
  modalBoilerplateSelector,
  bottomComponent,
  searchHistoryInit,
  setLoadingBtnDisabledFlag,
  hideBabitalkLink,
}) => {
  const toast = useToast();
  const contentsRef = useRef();
  const appendContentsBytes = 28; // 광고문자일 경우
  const appendBabitalkLinkedContentsByte = 73; // 링크추가> 바비톡 병원 홈페이지 링크

  const [initUiObj] = useState({
    // type : SMS, MMS, LMS,
    type: null,
    useImage: obj.images.length > 0 ? true : false,
    useTitle: null,
    titleBytes: 0,
    contentsBytes: 0,
    previewImages: obj.images.map((imageObj) => ({
      isUploaded: true,
      url: imageObj.url,
      id: imageObj.id,
    })),
    // previewImages : [{
    // isUploaded : false,
    // url : ""
    // }]
  });

  const [uiObj, setUiObj] = useState(initUiObj);

  const convertWhiteSpace = useCallback((v) => {
    return v
      .replace(/[\uFEFF|\u200B]/gu, '')
      .replace(
        /[\u3000|\u200A|\u2009|\u2008|\u2007|\u2006|\u2005|\u2004|\u2003|\u2002|\u00A0]/gu,
        ' '
      );
  }, []);

  const onChangeHandler = useCallback(
    (e) => {
      const { name, value } = e.target;
      let contentsBytes = countByteByString(convertWhiteSpace(value));
      contentsBytes += obj.smsType === 'event' ? appendContentsBytes : 0;

      if (contentsBytes > 2000) {
        toast.error('최대 입력수를 초과하였습니다');
        return;
      }

      setObj((obj) => ({
        ...obj,
        [name]: convertWhiteSpace(value),
      }));
    },
    [setObj]
  );

  const resetImages = useCallback(() => {
    setObj((obj) => ({
      ...obj,
      images: [],
      imageFiles: [],
    }));

    setUiObj((uiObj) => ({
      ...uiObj,
      useImage: false,
      previewImages: [],
    }));
  }, [setObj]);

  const countByteByString = useCallback((str) => {
    let count = 0;
    const strLength = str ? str.length : 0;
    for (let i = strLength; i--; ) {
      if (str.charCodeAt(i) > 127) {
        count += 2;
      } else if (str.charAt(i) !== '\r') {
        count += 1;
      }
    }
    return count;
  }, []);

  useEffect(() => {
    const titleBytes = countByteByString(obj.title);
    let contentsBytes = countByteByString(obj.contents);

    contentsBytes += obj.smsType === 'event' ? appendContentsBytes : 0;
    contentsBytes += obj.isBabitalkUri ? appendBabitalkLinkedContentsByte : 0;

    const hasImage = obj.images.length > 0 || obj.imageFiles.length > 0;

    let type = 'SMS';
    if (contentsBytes > 90) type = 'LMS';
    if (hasImage) type = 'MMS';

    if (contentsBytes > 2000) {
      toast.error('최대 입력수를 초과하였습니다');
      return;
    }

    setUiObj((uiObj) => ({
      ...uiObj,
      type: type,
      contentsBytes: contentsBytes,
      useTitle: type === 'SMS' ? false : true,
      titleBytes: type === 'SMS' ? 0 : titleBytes,
      useImage: type === 'MMS' ? true : false,
    }));
  }, [
    obj.title,
    obj.contents,
    obj.images,
    obj.imageFiles,
    countByteByString,
    obj.smsType,
    obj.isBabitalkUri,
  ]);

  useEffect(() => {
    if (uiObj.type === 'SMS') {
      setObj((obj) => ({
        ...obj,
        title: '',
      }));
    }

    setObj((obj) => ({
      ...obj,
      messageType: uiObj.type,
    }));
  }, [setObj, uiObj.type]);

  useEffect(() => {
    if (searchHistoryInit === true) {
      //이미지 첨부 초기화
      resetImages();
    }
  }, [searchHistoryInit, resetImages]);

  const [testUiObj] = useState({
    fontSize: '1.0em',
    // theme : "kakaotalk"
    theme: 'default',
  });

  // const testingComponent = ({obj, setObj})=>{
  //   const onChangeHandler = (e)=>{
  //     setObj({
  //       ...obj,
  //       [e.target.name] : e.target.value
  //     })

  //   }

  //   return (
  //     <div style={{display:"flex"}}>
  //       <span style={{fontWeight:600}}>{`{선택/발신번호/타입}`}</span>
  //       <span>&nbsp;</span>
  //       <select name="fontSize" onChange={onChangeHandler} >
  //         <option value="1.1em">글자크기</option>
  //         <option value="1.0em">1.0</option>
  //         <option value="1.1em">1.1(v)</option>
  //         <option value="1.2em">1.2</option>
  //         <option value="1.3em">1.3</option>
  //       </select>
  //       <select name="theme" onChange={onChangeHandler} >
  //         {/* <option value="kakaotalk">색상</option> */}
  //         <option value="kakaotalk">kakao</option>
  //         <option value="iphone">iphone_hover</option>
  //         <option value=" ">일반</option>
  //       </select>
  //     </div>
  //   )
  // }

  return (
    <div className={`mobile-screen ${className}`} data-theme={testUiObj.theme}>
      {titleComponent && (
        <div className="nav-bar">
          {/* <div className="nav-bar-title"> */}
          {titleComponent}
          {/* </div> */}
          {/* <div className="nav-bar-submit">*/}
          {/* </div> */}
        </div>
      )}

      <div className="app-view-header">
        {/* 카카오톡의 공지사항 같은. floating 메뉴. */}
        {/* 토글식 dropdown 버튼을 넣으려다가 폐기함 */}
      </div>
      <MessageAppMainView
        uiObj={uiObj}
        obj={obj}
        setObj={setObj}
        onChangeHandler={onChangeHandler}
        contentsRef={contentsRef}
        resetImages={resetImages}
        setLoadingBtnDisabledFlag={setLoadingBtnDisabledFlag}
      />
      <MessageAppTabBar
        obj={obj}
        uiObj={uiObj}
        setObj={setObj}
        setUiObj={setUiObj}
        modalBoilerplateSelector={modalBoilerplateSelector}
        contentsRef={contentsRef}
        hideBabitalkLink={hideBabitalkLink}
      />
      {bottomComponent && bottomComponent}
    </div>
  );
};

MessageApp.propTypes = {
  className: PropTypes.string,
  obj: PropTypes.object,
  setObj: PropTypes.func,
  titleComponent: PropTypes.object,
  modalBoilerplateSelector: PropTypes.object,
  bottomComponent: PropTypes.object,
  searchHistoryInit: PropTypes.bool,
  setLoadingBtnDisabledFlag: PropTypes.func,
  hideBabitalkLink: PropTypes.bool,
};

export default MessageApp;
