import React, { useEffect, useContext, useRef, memo } from 'react';
import { observer } from 'mobx-react';
import DepartmentColumn from './DepartmentColumn';
import { areEqual, VariableSizeList as List } from 'react-window';
// eslint-disable-next-line unused-imports/no-unused-imports
import { AppointmentBoardState } from 'store/appointmentBoardState';
import { AppointmentBoardStateContext } from './AppointmentBoards';
import PropTypes from 'prop-types';

const DepartmentsList = () => {
  /**
   * @type {AppointmentBoardState }
   */
  const state = useContext(AppointmentBoardStateContext);
  const listRef = useRef();

  useEffect(() => {
    state.departmentListRef = listRef;
    if (listRef.current) {
      listRef.current.resetAfterIndex(0);
    }
  }, [listRef, state.departmentListRef]);

  const Column = memo(
    ({ index, style }) => <DepartmentColumn index={index} style={style} />,
    areEqual
  );

  Column.propTypes = {
    index: PropTypes.number,
    style: PropTypes.object,
  };

  if (!state.calendarRect) return null;

  return (
    <>
      <List
        overscanCount={0}
        ref={listRef}
        itemCount={state.dateCategoryDepartments.length}
        itemSize={state.getDepartmentSize.bind(state)}
        layout={state.mode === 'vertical' ? 'horizontal' : 'vertical'}
        height={state.mode === 'vertical' ? '100%' : state.calendarRect.height}
        width={state.mode === 'vertical' ? state.calendarRect.width : '100%'}
        className="calendar-scroller-override"
      >
        {Column}
      </List>
    </>
  );
};

export default observer(DepartmentsList);
