import React, { useEffect } from 'react';
import { translate } from 'filters';
import { observer } from 'mobx-react';
import { useToast } from 'hooks/useToast';

const Toast = () => {
  const toast = useToast();
  useEffect(() => {
    setTimeout(
      () => toast.set({ show: false, message: null }),
      toast.duration || 2000
    );
  }, [toast, toast.show]);

  return (
    <div className="toast-wrapper">
      <div className={`toast ${toast.type || ''} ${toast.show ? 'show' : ''}`}>
        <pre>{translate(toast.message)}</pre>
      </div>
    </div>
  );
};

export default observer(Toast);
