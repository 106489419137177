import React, { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import DataTableAlpha from 'components/data-table/DataTableAlpha';
import models from 'models';
import { translate } from 'filters';
import { $qb } from 'scripts/querybuilder';
import { useServices } from 'hooks/useServices';
import { useToast } from 'hooks/useToast';
import PropTypes from 'prop-types';

const ModalMessageTargetSearchHistory = ({ close }) => {
  const services = useServices();
  const toast = useToast();
  const defaultParams = $qb().limit().orderBy();
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(null);
  const [loading, setLoading] = useState(true);
  const [params, setParams] = useState(defaultParams);
  const [hideHidden] = useState(false);
  const onParams = (p) => {
    setParams(p);
  };

  const callApi = useCallback(async () => {
    try {
      setLoading(true);
      const resp = await services.crm.notification.priceTables.all();
      const _respData = resp.data;

      const messageTypes = ['sms', 'lms', 'mms']; //, "alimtalk"
      const priceData = messageTypes.map((type) => {
        const initObj = {
          serviceName: type.toUpperCase(),
          serviceCode: type,
          unitPrice: null,
        };
        const nextObj = _respData.find((row) => row.serviceCode === type);
        return {
          ...initObj,
          ...nextObj,
        };
      });
      setData(priceData);
      setLoading(false);
    } catch (error) {
      toast.error(error.description);
      setLoading(false);
    }
  }, [services.crm.notification.priceTables, toast]);

  useEffect(() => {
    callApi();
  }, [callApi]);

  return (
    <div className="modal-message-target-search-history-selector modal-transit-unit">
      <div className="head flex-row flex-between items-start">
        <div className="title">{translate('MESSAGE_POINT_PRICE')}</div>
        <i onClick={() => close()} className="zmdi zmdi-close" />
      </div>
      <div className="body">
        <DataTableAlpha
          className="header-style-classic"
          model={models.crm.messagePrice}
          data={hideHidden ? data.filter((row) => row.visible) : data}
          total={total}
          params={params}
          onParams={onParams}
          loading={loading}
          hideBottom={true}
          hideHidden={hideHidden}
        />
        {/* <span>*바비톡링크 추가 시, 건당 20원 추가 과금</span> */}
      </div>
      {/* <div>
        <p style={{marginTop: "8px", color: "#0060ff" ,fontWeight: "normal"}}>*바비톡링크 추가 시, 건당 20원 추가 과금</p>
      </div> */}
      <div className="buttons">
        <div className="flex-row">
          <button onClick={close} className="btn btn-primary">
            확인
          </button>
        </div>
      </div>
    </div>
  );
};

ModalMessageTargetSearchHistory.propTypes = {
  close: PropTypes.func,
};

export default observer(ModalMessageTargetSearchHistory);
