import React from 'react';
import PropTypes from 'prop-types';

const Checkbox = ({
  checked,
  toggleHandler,
  clickHandler,
  className,
  disabled,
}) => (
  <div
    onClick={() => {
      if ((!clickHandler && !toggleHandler) || disabled) return;
      clickHandler ? clickHandler() : toggleHandler();
    }}
    className={`checkbox ${checked ? 'checked' : ''} ${className} ${
      disabled ? 'disabled' : ''
    }`}
  >
    {checked ? <i className="zmdi zmdi-check" /> : null}
  </div>
);

Checkbox.propTypes = {
  checked: PropTypes.bool,
  toggleHandler: PropTypes.func,
  clickHandler: PropTypes.func,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

export default Checkbox;
