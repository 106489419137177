import React, { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import DataTableAlpha from 'components/data-table/DataTableAlpha';
import ModalMessageMap from 'components/modals/codes/message-codes/ModalMessageMap';
import models from 'models';
import { translate } from 'filters';
import { $qb } from 'scripts/querybuilder';
import { useModal } from 'hooks/useModal';
import { useServices } from 'hooks/useServices';
import { useToast } from 'hooks/useToast';

const MessageMap = () => {
  const modal = useModal();
  const services = useServices();
  const toast = useToast();
  const defaultParams = $qb().limit().orderBy();
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(null);
  const [params, setParams] = useState(defaultParams);
  const [isMap, setIsMap] = useState(false);

  const onParams = (p) => {
    setParams(p);
  };

  const callApi = useCallback(async () => {
    try {
      const resp = await services.crm.notification.boilerplateMap.all();
      setData(resp.data);
      setTotal(resp.total);
    } catch (error) {
      toast.error(error.description);
    }
  }, [services.crm.notification.boilerplateMap, toast]);

  useEffect(() => {
    callApi();
  }, [callApi]);

  const callConfigApi = useCallback(async () => {
    try {
      const resp = await services.crm.notification.smsConfig.all();
      if (!resp) return;

      resp.data.map !== undefined && setIsMap(resp.data.map);
    } catch (error) {
      toast.error(error.description);
    }
  }, [services.crm.notification.smsConfig, toast]);

  useEffect(() => {
    callConfigApi();
  }, [callConfigApi]);

  const onOpenModalMessageMap = useCallback(
    ({ row }) => {
      modal
        .custom({
          component: ModalMessageMap,
          options: { boilerplateMap: row },
        })
        .then((submitted) => {
          if (submitted) {
            setData([]);
            setTotal(null);
            callApi();
            callConfigApi();
          }
        });
    },
    [modal, callApi, callConfigApi]
  );

  /*const onChangeVisible = async (row) => {
    const onConfirm = async () => {
      try {
        let payload = {
          ...row,
          visible: !row.visible,
        };

        await services.crm.notification.boilerplateMap.update(payload);
        toast.success(
          translate(
            row.visible ? 'TOGGLE_ACTIVATED' : 'TOGGLE_DEACTIVATED'
          )
        );
      } catch (e) {
        toast.error(e.description);
        console.log(e.description);
      } finally {
        callApi();
        callConfigApi();
      }
    };

    modal
      .basic({
        body: translate(
          !row.visible ? 'CONFIRM_ACTIVATE' : 'CONFIRM_DEACTIVATE'
        ),
        buttons: [
          {
            text: 'CANCEL',
            class: 'btn-default',
          },
          {
            text: 'CONFIRM',
            class: 'btn-primary',
          },
        ],
      })
      .then((idx) => {
        if (idx === 1) {
          onConfirm();
        }
      });
  };*/

  const onAction = (obj) => {
    const { row, key, event } = obj;
    // if ( event.target.dataset.type==="visible" ){
    //   onChangeVisible(row)
    // }
    if (event.target.dataset.type === 'edit') {
      onOpenModalMessageMap({ row, key, event });
    }
    if (event.target.dataset.type === 'delete') {
      openModalDelete({
        id: row['id'],
        endpoint: services.crm.notification.boilerplateMap,
      });
    }
    if (key['type'] === 'image' && (row.images || []).length > 0) {
      modal.images({
        images: (row.images || []).map((image) => image.url),
      });
      return;
    }
  };

  const openModalDelete = ({ endpoint, id }) => {
    const onConfirm = async () => {
      try {
        await endpoint.delete(id);
        modal
          .confirm({
            type: 'SUCCESS',
            msg: '삭제되었습니다.',
          })
          .then(() => {
            callApi();
            callConfigApi();
          });
      } catch (e) {
        toast.error(e.description);
      }
    };

    modal
      .basic({
        body: translate('DELETE_CONFIRM'),
        buttons: [
          {
            text: 'CANCEL',
            class: 'btn-default',
          },
          {
            text: 'CONFIRM',
            class: 'btn-danger',
          },
        ],
      })
      .then((idx) => {
        if (idx === 1) {
          onConfirm();
        }
      });
  };

  return (
    <div className="message-map">
      <div className="route-top">
        <div className="title">약도 전송</div>
        <div className="flex-row items-center">
          {!isMap && (
            <button
              onClick={() => {
                onOpenModalMessageMap({});
              }}
              className="flex-wrap btn btn-sm m-r-8 btn-add"
            >
              {translate('ADD')}
            </button>
          )}
        </div>
      </div>
      <DataTableAlpha
        className="header-style-classic"
        model={models.crm.messageMap}
        data={data}
        total={total}
        params={params}
        onParams={onParams}
        onAction={onAction}
        hideBottom={true}
      />
    </div>
  );
};

export default observer(MessageMap);
