import React, { useContext, useCallback } from 'react';
import moment from 'moment';
import hooks from 'hooks';
import { observer } from 'mobx-react';
import { translate } from 'filters';
import { useEvent } from 'hooks/useEvent';
import { useModal } from 'hooks/useModal';
import { useServices } from 'hooks/useServices';
import { useToast } from 'hooks/useToast';
import { useMessages } from 'hooks/useMessages';
import { AppointmentBoardStateContext } from './AppointmentBoards';
import ModalMessageSendToBoards from 'components/modals/ModalMessageSendToBoards';
import ModalAppointmentUpdateCompleteAutoSmsAlert from 'components/modals/ModalAppointmentUpdateCompleteAutoSmsAlert';

const ContextMenu = () => {
  const eventBus = useEvent();
  const modal = useModal();
  const services = useServices();
  const toast = useToast();
  const messages = useMessages();
  /**
   * @type {AppointmentBoardState }
   */
  const state = useContext(AppointmentBoardStateContext);

  const appointmentDetailCallApi = async (appointmentId) => {
    const resp = await services.crm.crud.appointment.detail(appointmentId);
    if (!resp) return;

    const appointment = resp.data;
    return appointment;
  };

  const copiedAction = async (copied, schedule) => {
    copied.startHour = schedule.startHour;
    copied.department = schedule.department;
    const appointment = await appointmentDetailCallApi(copied.id);

    let payload = {
      customerId: (appointment.customer || {}).id,
      customer: appointment.customer,
      doctor: appointment.doctor,
      counselor: appointment.counselor,
      facialist: appointment.facialist,
      status: 'scheduled',
      type: appointment.type,
      scheduledAt: schedule.scheduledAt,
      estimatedServiceMinutes: copied.estimatedServiceMinutes,
      startHour: schedule.startHour,
      department: schedule.department,
      paymentTreatmentItems: appointment.paymentTreatmentItems, //시수술 예약시
      items: appointment.items, //상담,진료 예약시
      memo: appointment.memo,
      isNewCustomer: appointment && appointment.isNewCustomer,
    };

    const originScheduled = moment();
    const copiedScheduled = moment(schedule.scheduledAt);
    //현재시간보다 미래 날짜에 붙여넣기 시 다이얼로그 팝업 노출
    if (
      state.smsAutoNotifications.length > 0 &&
      originScheduled.diff(copiedScheduled) < 0
    ) {
      //설정된 자동문자 타입이 없으면 자동문자전송 dialog 팝업 미노출.

      let departmentSmsAutoNotifications = await messages.loadDepartmentSmsAutoNotifications(
        payload.department?.id
      );

      if (departmentSmsAutoNotifications.length > 0) {
        modal
          .custom({
            component: ModalAppointmentUpdateCompleteAutoSmsAlert,
            options: {
              model: 'autoSms',
              canClose: false,
              type: 'SEND_SMS_CHECK',
              msgType: 'paste_copy',
              notifications: departmentSmsAutoNotifications,
              scheduledAt: payload.scheduledAt,
            },
          })
          .then(({ sendAutoSmsCheck, sendAutoSmsIds }) => {
            payload.sendAutoSms = sendAutoSmsCheck;
            if (sendAutoSmsCheck) {
              payload.sendAutoSmsIds = sendAutoSmsIds;
            }
            onSaveAppointment(payload);
          });
      } else {
        payload.sendAutoSms = false;
        onSaveAppointment(payload);
      }
    } else {
      payload.sendAutoSms = false;
      onSaveAppointment(payload);
    }
  };

  const onSaveAppointment = async (payload) => {
    services.crm.crud.appointment
      .create({ ...payload })
      .then(() => {
        modal
          .confirm({
            type: 'SUCCESS',
            msg: '생성되었습니다.',
          })
          .then(() => {
            state.loadAppointments();
          });
      })
      .catch((e) => toast.error(e.description));
  };

  const cutAndCopiedAction = async (copied, schedule) => {
    try {
      copied.startHour = schedule.startHour;
      copied.department = schedule.department;
      const appointment = await appointmentDetailCallApi(copied.id);

      let payload = {
        id: appointment.id,
        customerId: (appointment.customer || {}).id,
        customer: appointment.customer,
        department: { id: schedule.department.id },
        doctor: appointment.doctor,
        counselor: appointment.counselor,
        facialist: appointment.facialist,
        status: appointment.status,
        type: appointment.type,
        scheduledAt: schedule.scheduledAt,
        estimatedServiceMinutes: copied.estimatedServiceMinutes,
        startHour: schedule.startHour,
        paymentTreatmentItems: appointment.paymentTreatmentItems, //시수술 예약시
        items: appointment.items, //상담,진료 예약시
        memo: appointment.memo,
      };

      const originScheduled = moment();
      const copiedScheduled = moment(schedule.scheduledAt);
      //현재시간보다 미래 날짜에 붙여넣기 시 다이얼로그 팝업 노출
      if (
        state.smsAutoNotifications.length > 0 &&
        originScheduled.diff(copiedScheduled) < 0
      ) {
        //설정된 자동문자 타입이 없으면 자동문자전송 dialog 팝업 미노출.
        let departmentSmsAutoNotifications = await messages.loadDepartmentSmsAutoNotifications(
          payload.department?.id
        );

        if (departmentSmsAutoNotifications.length > 0) {
          modal
            .custom({
              component: ModalAppointmentUpdateCompleteAutoSmsAlert,
              options: {
                model: 'autoSms',
                canClose: false,
                type: 'SEND_SMS_CHECK',
                msgType: 'paste_cut',
                notifications: departmentSmsAutoNotifications,
                scheduledAt: payload.scheduledAt,
              },
            })
            .then(({ sendAutoSmsCheck, sendAutoSmsIds }) => {
              payload.sendAutoSms = sendAutoSmsCheck;
              if (sendAutoSmsCheck) {
                payload.sendAutoSmsIds = sendAutoSmsIds;
              }
              onUpdateAppointment(payload);
            });
        } else {
          payload.sendAutoSms = false;
          onUpdateAppointment(payload);
        }
      } else {
        payload.sendAutoSms = false;
        onUpdateAppointment(payload);
      }
    } catch (e) {
      console.log(e.description);
    }
  };

  const onUpdateAppointment = async (payload) => {
    try {
      await services.crm.crud.appointment.update(payload);
      modal
        .confirm({
          type: 'SUCCESS',
          msg: '생성되었습니다.',
        })
        .then(() => {
          state.loadAppointments();
          state.setCopied(null);
          state.setCutClipBoard(null);
        });
    } catch (e) {
      console.log(e.description);
    }
  };

  const onChangeAppointmentStatusToDuring = async (status) => {
    // 상담대기>상담중 / 진료대기>진료중 / 시수술대기>시수술중으로 변경시 호출됨
    const detail = await appointmentDetailCallApi(state.appointment.id);
    if (!detail) return;

    const payload = {
      ...detail,
      id: (detail || {}).id,
      scheduledAt: (detail || {}).scheduledAt,
      status,
      appointmentStatus: status,
      appointmentId: (detail || {}).id,
      customerId: (detail.customer || {}).id,
      department: { id: detail.department?.id },
    };

    //상태변경 시, items, paymentTreatmentItems는 제외하고 api 호출 (변경이 필요 없으므로)
    let {
      // eslint-disable-next-line no-unused-vars
      items: deletedKey,
      // eslint-disable-next-line no-unused-vars
      paymentTreatmentItems: deletedKey2,
      ...otherKeys
    } = payload;

    try {
      const result = await services.crm.crud.appointment.update(otherKeys);
      if (!result) return;
      return Object.keys(result.data).length;
    } catch (e) {
      console.log(e.description);
    }
  };

  const customerDetailCallApi = async (customerId) => {
    try {
      const resp = await services.crm.customer.detail(customerId);
      if (!resp) return;

      return resp.data;
    } catch (e) {
      console.log(e.description);
      if (e.dscription === 'CustomerNotFound') {
        toast.error('삭제된 고객입니다.');
        return;
      }
    }
  };

  const changeAppointmentStatus = useCallback(
    async (appointmentId, changeStatus) => {
      //appointmentId: 상태 변경할 예약의 id
      //chagneStatus: 변경할 상태값
      try {
        // /list/limit에는 예약id 정보밖에 없으므로, 예약건을 조회하여 복사 업데이트
        const appointmentItem = await services.crm.crud.appointment.detail(
          appointmentId
        );
        if (Object.keys(appointmentItem.data).length) {
          const updatePayload = {
            ...appointmentItem.data,
            status: changeStatus,
            customerId: (appointmentItem.data.customer || {}).id,
            department: { id: appointmentItem.data.department?.id },
          };

          //상태변경 시, items, paymentTreatmentItems는 제외하고 api 호출 (변경이 필요 없으므로)
          let {
            // eslint-disable-next-line no-unused-vars
            items: deletedKey,
            // eslint-disable-next-line no-unused-vars
            paymentTreatmentItems: deletedKey2,
            ...otherKeys
          } = updatePayload;

          await services.crm.crud.appointment.update(otherKeys);
        }
      } catch (e) {
        console.log(e.description);
      }
    },
    [services.crm.crud.appointment]
  );

  const deleteAppointment = useCallback(
    async (appointment) => {
      try {
        await services.crm.crud.appointment.delete(appointment.id);
        modal
          .confirm({
            type: 'SUCCESS',
            msg: '삭제되었습니다.',
          })
          .then(() => {
            state.loadAppointments();
          });
      } catch (e) {
        console.log(e.description);
      }
    },
    [services.crm.crud.appointment, toast, eventBus]
  );

  const openCustomerChart = async (listAppointment, statusName, openFlag) => {
    const customer = await customerDetailCallApi(listAppointment.customerId);
    let appointment = { appointmentId: listAppointment.id };
    hooks.openCustomerChart({
      customer,
      appointment,
      selectTab: statusName,
      openFlag,
      calledPage: 'boards',
    });
    return;
  };

  const onChangeStatus = useCallback(
    async (appointment, statusKey) => {
      try {
        let status = statusKey.split('APPOINTMENT_STATUS_')[1].toLowerCase();
        let changeStatus = status;
        if (status === 'complete') {
          //완료상태로 변경 > 예약의 타입에 맞게 done 처리
          const { type } = appointment;
          changeStatus = `${type}_done`;
        }
        await changeAppointmentStatus(appointment.id, changeStatus);
        modal
          .confirm({
            type: 'SUCCESS',
            msg: '업데이트되었습니다.',
          })
          .then(() => {
            state.loadAppointments();
          });
      } catch (e) {
        console.log(e.description);
      }
    },
    [changeAppointmentStatus, eventBus]
  );

  const copyAppointment = () => {
    toast.success('COPIED_APPOINTMENT');
    state.setCopied(state.appointment);
    state.setCutClipBoard(null);
  };
  const cutAppointment = () => {
    toast.success('잘라냈습니다. 원하는 날짜에 우클릭 후 붙여넣기 해주세요.');
    state.setCopied(state.appointment);
    state.setCutClipBoard(state.appointment);
  };

  // 지금 생각해보니 여기서 핸들링할게 아니라, Cell 컴포넌트 안의
  // onContextMenu 안의 items 안 각각 항목들에 handler: () => {}를 만드는게 낫겠네요
  const onClickContextMenuItem = async (item, statusKey) => {
    state.contextMenuOptions.show = item.title === 'CHANGE_STATUS';
    state.setShowHoverCard(false);

    if (item === -1) return;
    if (state.appointment != null) {
      //예약복사>붙여넣기의 경우 appointment = undefined
      if (state.appointment.customerStatus === 'deactivated') {
        toast.error('삭제된 고객입니다.');
        return;
      }
    }

    if (item.title === 'CHANGE_STATUS' && state.appointment) {
      // 2depth 상태변경
      if (!item.expanded) {
        item.expanded = true;
        return;
      }
      if (!statusKey) return;

      if (statusKey !== undefined) {
        //2depth data 선택
        onChangeStatus(state.appointment, statusKey);
      }
      return;
    }

    if (item.title === '접수하기') {
      const changeStatus = `${state.appointment.type}_waiting`;
      //내원한 것이므로 예약의 visited = true로 변경,
      const appointmentItem = await services.crm.crud.appointment.detail(
        state.appointment.id
      );

      if (Object.keys(appointmentItem.data).length) {
        const updatePayload = {
          ...appointmentItem.data,
          visited: true,
          status: changeStatus,
          appointmentStatus: changeStatus,
          appointmentId: state.appointment.id,
          customerId: (appointmentItem.data.customer || {}).id,
          department: { id: appointmentItem.data.department?.id },
        };
        //접수하기 시, items, paymentTreatmentItems는 제외하고 api 호출 (변경이 필요 없으므로)
        let {
          // eslint-disable-next-line no-unused-vars
          items: deletedKey,
          // eslint-disable-next-line no-unused-vars
          paymentTreatmentItems: deletedKey2,
          ...otherKeys
        } = updatePayload;

        const resp = await services.crm.crud.appointment.update(otherKeys);
        modal
          .confirm({
            type: 'SUCCESS',
            msg: '접수되었습니다.',
          })
          .then(() => {
            if (!resp) return;
            if (resp.data) {
              state.loadAppointments();
            }
          });
      }
      return;
    }

    if (item.title === '미방문') {
      const changeStatus = `unvisited`;
      //내원한 것이므로 예약의 visited = true로 변경,
      const appointmentItem = await services.crm.crud.appointment.detail(
        state.appointment.id
      );

      if (Object.keys(appointmentItem.data).length) {
        const updatePayload = {
          ...appointmentItem.data,
          visited: false,
          status: changeStatus,
          appointmentStatus: changeStatus,
          appointmentId: state.appointment.id,
          customerId: (appointmentItem.data.customer || {}).id,
          department: { id: appointmentItem.data.department?.id },
        };
        //미방문으로 변경 시, items, paymentTreatmentItems는 제외하고 api 호출 (변경이 필요 없으므로)
        let {
          // eslint-disable-next-line no-unused-vars
          items: deletedKey,
          // eslint-disable-next-line no-unused-vars
          paymentTreatmentItems: deletedKey2,
          ...otherKeys
        } = updatePayload;

        const resp = await services.crm.crud.appointment.update(otherKeys);
        modal
          .confirm({
            type: 'SUCCESS',
            msg: '업데이트되었습니다.',
          })
          .then(() => {
            if (!resp) return;
            if (resp.data) {
              state.loadAppointments();
            }
          });
      }
      return;
    }

    if (item.title === '차트보기') {
      const customer = await customerDetailCallApi(
        state.appointment.customerId
      );
      const openFlag = {
        appointmentId: state.appointment.id,
      };
      hooks.openCustomerChart({
        customer,
        openFlag,
        calledPage: 'boards',
      });
      return;
    }

    if (item.title === '예약수정') {
      const detail = await appointmentDetailCallApi(state.appointment.id);
      const customer = detail.customer;
      const openFlag = {
        appointmentId: state.appointment.id,
        appointment: detail,
      };
      hooks.openCustomerChart({
        appointment: state.appointment,
        customer,
        selectTab: 'APPOINTMENT',
        openFlag,
        calledPage: 'boards',
      });
    }

    if (item.title === '상담하기') {
      const result = await onChangeAppointmentStatusToDuring(
        'consulting_during'
      );
      const openFlag = {
        appointmentId: state.appointment.id,
        consulting: null,
      };
      if (result) {
        openCustomerChart(state.appointment, 'CONSULTING', openFlag);
      }
      return;
    }

    if (item.title === '진료하기') {
      const result = await onChangeAppointmentStatusToDuring(
        'treatment_during'
      );
      const openFlag = {
        appointmentId: state.appointment.id,
        treatment: null,
      };
      if (result) {
        openCustomerChart(state.appointment, 'TREATMENT', openFlag);
      }
      return;
    }

    if (item.title === '시/수술하기') {
      const result = await onChangeAppointmentStatusToDuring('surgery_during');
      const openFlag = {
        appointmentId: state.appointment.id,
        surgery: null,
      };
      if (result) {
        openCustomerChart(state.appointment, 'SURGERY', openFlag);
      }
      return;
    }

    if (item.title === '수납하기') {
      const openFlag = {
        appointmentId: state.appointment.id,
        payment: { componentFlag: null },
      };
      openCustomerChart(state.appointment, 'PAYMENT', openFlag);
      return;
    }

    if (item.title === '예약복사') {
      if (state.copied !== null || state.cutClipBoard !== null) {
        //이미 예약복사 혹은 잘라내기를 한 경우
        modal
          .basic({
            body:
              '이미 복사 혹은 잘라낸 예약이 있습니다. 그래도 복사 하시겠습니까?',
            buttons: [
              {
                text: 'CANCEL',
                class: 'btn-default',
              },
              {
                text: 'CONFIRM',
                class: 'btn-primary',
              },
            ],
          })
          .then((selectedIdx) => {
            if (selectedIdx === 1) {
              copyAppointment();
            }
          });
      } else {
        copyAppointment();
      }
      return;
    }

    if (item.title === '잘라내기') {
      if (state.copied !== null || state.cutClipBoard !== null) {
        //이미 예약복사 혹은 잘라내기를 한 경우
        modal
          .basic({
            body:
              '이미 복사 혹은 잘라낸 예약이 있습니다. 그래도 잘라내기 하시겠습니까?',
            buttons: [
              {
                text: 'CANCEL',
                class: 'btn-default',
              },
              {
                text: 'CONFIRM',
                class: 'btn-primary',
              },
            ],
          })
          .then((selectedIdx) => {
            if (selectedIdx === 1) {
              cutAppointment();
            }
          });
      } else {
        cutAppointment();
      }
      return;
    }

    if (
      item.title === '붙여넣기' &&
      state.cutClipBoard !== null &&
      state.copySchedule
    ) {
      //잘라내기 > 붙여넣기 (cutClipBoard로 구분)
      state.setCutClipBoard(null);
      cutAndCopiedAction(state.copied, state.copySchedule);
      return;
    }

    if (item.title === '붙여넣기' && state.copied && state.copySchedule) {
      //예약복사 > 붙여넣기
      copiedAction(state.copied, state.copySchedule);
      return;
    }

    if (item.title === '삭제') {
      modal
        .basic({
          body: '삭제하시겠습니까?',
          buttons: [
            { text: 'CANCEL', class: 'btn-default' },
            { text: 'CONFIRM', class: 'btn-danger' },
          ],
        })
        .then((selectedBtnIdx) => {
          if (selectedBtnIdx === 1) {
            deleteAppointment(state.appointment);
          }
        });
      return;
    }

    if (item.title === '문자전송') {
      //문자 전송
      openModalSendMessage(state.appointment);
      return;
    }

    if (item.title === '약도전송') {
      onClickSendMessageMap(state.appointment);
    }
  };

  const callBoilerplateMap = async () => {
    try {
      const resp = await services.crm.notification.boilerplateMap.all();
      if (!resp) return;

      let map = resp.data[0];
      //약도전송에 저장된 id 제거하고 메시지 전송하기
      // eslint-disable-next-line no-unused-vars
      let { id: deletedKey, ...message } = map;
      modal.custom({
        component: ModalMessageSendToBoards,
        options: {
          appointment: state.appointment,
          mapMessage: {
            ...message,
          },
        },
      });
    } catch (e) {
      console.log(e.description);
    }
  };

  const onClickSendMessageMap = useCallback(async () => {
    //1. 문자코드 설정에 설정한 약도가 있는지?
    //설정되어 있으면 전송 확인 팝업
    //설정되어 있지 않으면 토스트 알림

    try {
      const resp = await services.crm.notification.smsConfig.all();
      if (!resp) return;

      if (resp.data.map === undefined || !resp.data.map) {
        modal.basic({
          body: '문자코드 설정에서 약도이미지를 설정하세요.',
          buttons: [{ text: 'CONFIRM', class: 'btn-primary' }],
        });
        return;
      } else {
        callBoilerplateMap();
      }
    } catch (error) {
      toast.error(error.description);
    }
  }, [modal, services.crm.notification.smsConfig]);

  const openModalSendMessage = useCallback(() => {
    modal.custom({
      component: ModalMessageSendToBoards,
      options: {
        appointment: state.appointment,
      },
    });
  }, [modal]);

  const onClickOverlay = (e) => {
    e.preventDefault();
    if (e.target.classList.contains('overlay')) {
      onClickContextMenuItem(-1);
    }
  };

  const action = (item, s) => {
    if (item.disabled) return;

    onClickContextMenuItem(item, s);
  };

  if (!state.contextMenuOptions.show) return null;

  return (
    <div
      className={`context-menu overlay`}
      onClick={onClickOverlay}
      onContextMenu={onClickOverlay}
    >
      <ul
        style={{
          left: `${state.contextMenuOptions.x}px`,
          //마우스 커서 위치 + 300 (컨텍스트 height)의 길이가 window.innerHeight를 초과하면,
          top: `${
            state.contextMenuOptions.y + 300 > window.innerHeight
              ? //짤림 영역만큼 top영역을 끌어올림
                state.contextMenuOptions.y -
                (state.contextMenuOptions.y + 300 - window.innerHeight)
              : state.contextMenuOptions.y
          }px`,
        }}
      >
        {state.contextMenuOptions.items.map((item, idx) => (
          <li
            key={idx}
            onClick={() => action(item)}
            className={`flex-row items-center flex-between ${
              item.customStyle
            } ${item.disabled ? 'disabled' : ''}`}
          >
            <span className="flex-fill">{translate(item.title)}</span>
            {item.title === 'CHANGE_STATUS' ? (
              <i className="zmdi zmdi-chevron-right" />
            ) : null}
          </li>
        ))}
      </ul>
      {state.contextMenuOptions.items.length > 0 &&
      (
        state.contextMenuOptions.items[
          state.contextMenuOptions.items.findIndex(
            (f) => f.title === 'CHANGE_STATUS'
          )
        ] || {}
      ).expanded ? (
        <ul
          className="menu-status"
          style={{
            left: `${state.contextMenuOptions.x + 152}px`,
            //마우스 커서 위치 + 400 (상태변경 height)의 길이가 window.innerHeight를 초과하면,
            top: `${
              state.contextMenuOptions.y +
                state.contextMenuOptions.items.findIndex(
                  (f) => f.title === 'CHANGE_STATUS'
                ) *
                  33 +
                400 >
              window.innerHeight
                ? //짤림 영역만큼 top영역을 끌어올림
                  state.contextMenuOptions.y -
                  (state.contextMenuOptions.y + 400 - window.innerHeight)
                : //33px > li의 height
                  state.contextMenuOptions.y +
                  state.contextMenuOptions.items.findIndex(
                    (f) => f.title === 'CHANGE_STATUS'
                  ) *
                    33
            }px`,
          }}
        >
          {(
            state.contextMenuOptions.items[
              state.contextMenuOptions.items.findIndex(
                (f) => f.title === 'CHANGE_STATUS'
              )
            ].status || []
          ).map((s, idx) => {
            let appointmentStatus = state.appointment.status;
            if (
              appointmentStatus === 'consulting_request_done' ||
              appointmentStatus === 'consulting_done' ||
              appointmentStatus === 'treatment_done' ||
              appointmentStatus === 'surgery_done' ||
              appointmentStatus === 'payment_done'
            ) {
              //_done 상태일때 complete에 체크되도록
              appointmentStatus = 'complete';
            }
            if (appointmentStatus === 'done') {
              //done 상태일때 leave에 체크되도록
              appointmentStatus = 'leave';
            }
            return appointmentStatus !==
              s.split('APPOINTMENT_STATUS_')[1].toLowerCase() ? (
              <li
                className="flex-row"
                onClick={() =>
                  action(
                    state.contextMenuOptions.items[
                      state.contextMenuOptions.items.findIndex(
                        (f) => f.title === 'CHANGE_STATUS'
                      )
                    ],
                    s
                  )
                }
                key={idx}
              >
                <div
                  className={`radius-box ${s
                    .split('APPOINTMENT_STATUS_')[1]
                    .toLowerCase()}`}
                  style={{
                    backgroundColor:
                      state.stateColors[
                        s.split('APPOINTMENT_STATUS_')[1].toLowerCase()
                      ].codeValue,
                  }}
                />
                <div>{translate(s)}</div>
              </li>
            ) : (
              <li key={idx} className="flex-row">
                <div
                  className={`radius-box ${s
                    .split('APPOINTMENT_STATUS_')[1]
                    .toLowerCase()}`}
                  style={{
                    backgroundColor:
                      state.stateColors[
                        s.split('APPOINTMENT_STATUS_')[1].toLowerCase()
                      ].codeValue,
                  }}
                />
                <div>{translate(s)}</div>
                <div className="right">v</div>
              </li>
            );
          })}
        </ul>
      ) : null}
    </div>
  );
};

export default observer(ContextMenu);
