import React from 'react';
import { observer } from 'mobx-react';
import { components as SelectComponent } from 'react-select';
import SelectBox from 'components/common/SelectBox';
import { formatCallerNumber, translate } from 'filters';
import PropTypes from 'prop-types';

const SelectCallerNumber = ({
  obj,
  setObj,
  callerNumberList = [],
  ...props
}) => {
  const INDEX_KEY = 'number';

  const uiObj =
    obj.callerNumber && obj.callerNumber[INDEX_KEY]
      ? {
          value: obj.callerNumber[INDEX_KEY], // 협의 후 ID로 변경.
          id: obj.callerNumber.id,
          number: obj.callerNumber.number,
          comment: obj.callerNumber.comment,
        }
      : { callerNumber: { value: null } };

  const options = callerNumberList
    .filter((option) => option.visible)
    .map((option) => ({
      ...option,
      value: option[INDEX_KEY],
    }));

  const onChangeHandler = (e) => {
    const value = e.target.value;
    const payload = options.find((option) => option.value === value);
    setObj((obj) => ({
      ...obj,
      callerNumber: payload,
    }));
  };

  const SingleValue = ({ data, ...props }) => {
    return (
      <SelectComponent.SingleValue className="selected-value" {...props}>
        <span className="number">{formatCallerNumber(data.number)}</span>
        <span className="comment">{data.comment}</span>
      </SelectComponent.SingleValue>
    );
  };

  SingleValue.propTypes = {
    data: PropTypes.object,
  };

  const OptionComponent = ({ data, ...props }) => {
    return (
      <SelectComponent.Option className="option" {...props}>
        <span className="number">{formatCallerNumber(data.number)}</span>
        <span className="comment">{data.comment}</span>
      </SelectComponent.Option>
    );
  };

  OptionComponent.propTypes = {
    data: PropTypes.object,
  };

  return (
    <>
      <div className="nav-bar-title">
        {/* <div className="title-label " /> */}
        <div className="title-input">
          <SelectBox
            name={'callerNumber'}
            value={uiObj[INDEX_KEY]}
            className={'caller-numbers'}
            onChange={onChangeHandler}
            options={options}
            components={{ Option: OptionComponent, SingleValue: SingleValue }}
            placeholder={translate('PLACEHOLDER_CALLER_NUMBER')}
            {...props}
          />
        </div>
      </div>
    </>
  );
};

SelectCallerNumber.propTypes = {
  obj: PropTypes.object,
  setObj: PropTypes.func,
  callerNumberList: PropTypes.array,
};

export default React.memo(observer(SelectCallerNumber));
