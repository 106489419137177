import React, { useState, useEffect, useCallback } from 'react';
import { observer } from 'mobx-react';
import List from './List';
import hooks from 'hooks';
import * as $http from 'axios';
import { $case, pluralize } from 'filters';
import ModalMessageSendToPage from 'components/modals/ModalMessageSendToPage';
import modelConsultings from 'models/consulting';
import services from 'services';
import { useModal } from 'hooks/useModal';
import { useToast } from 'hooks/useToast';

const Consultings = () => {
  const modal = useModal();
  const toast = useToast();
  const [totalSearchList, setTotalSearchList] = useState({});
  const [renderFlag, setRenderFlag] = useState(true);
  const [checkItems, setCheckItems] = useState([]);

  const codeCallApi = useCallback(async () => {
    let totalResp = {};

    const resp = await $http.get('/consultings/v2/codes');
    if (!resp) return;

    totalResp.results = resp.data.consultingResult;
    totalResp._counselors = resp.data.counselor;
    totalResp.surgery_category = resp.data.surgeryCategories;
    totalResp.surgery_item_name = resp.data.surgeryItems;

    setTotalSearchList(totalResp);
    setRenderFlag((flag) => {
      return !flag;
    });
  }, []);

  useEffect(() => {
    codeCallApi();
  }, [codeCallApi]);

  const onDeleteConsulting = useCallback(
    async (id) => {
      try {
        await services.crm.consulting.delete_v2(id);
        modal
          .confirm({
            type: 'SUCCESS',
            msg: '삭제되었습니다.',
          })
          .then(() => {
            setRenderFlag(!renderFlag);
          });
      } catch (e) {
        modal.confirm({
          type: 'ERROR',
          msg: e.description,
        });
      }
    },
    [renderFlag]
  );

  const onClickDeleteConsulting = (consulting) => {
    modal
      .basic({
        body: '정말로 삭제하시겠습니까?',
        buttons: [
          {
            text: 'CANCEL',
            class: 'btn-default',
          },
          {
            text: 'CONFIRM',
            class: 'btn-primary',
          },
        ],
      })
      .then((idx) => {
        if (idx === 1) {
          onDeleteConsulting(consulting.id);
        }
      });
  };

  const onClickSendSms = () => {
    if (checkItems.length) {
      modal.custom({
        component: ModalMessageSendToPage,
        options: {
          page: 'consulting',
          data: checkItems,
        },
      });
    } else {
      toast.error('문자전송할 상담을 선택하세요.');
    }

    return;
  };

  const onAllCheckAction = (data) => {
    const allChecked = (data || []).every((v) => v.$$checked === true);
    if (allChecked) {
      //전체 선택인 경우
      const list = (data || []).filter((v) => {
        return (
          v.$$checked === true &&
          checkItems.find((f) => f.id === v.id) === undefined
        );
      });
      setCheckItems([...checkItems, ...list]);
    } else {
      //전체 해제인 경우
      // eslint-disable-next-line array-callback-return
      const list = (checkItems || []).filter((v) => {
        if (data.find((f) => f.id === v.id) === undefined) {
          return v;
        }
      });
      setCheckItems([...list]);
    }
  };

  const onAction = (obj) => {
    let consulting = obj.row;
    let model = obj.key;

    if (model.type === 'multiCheck') {
      if (consulting.$$checked) {
        checkItems.push(consulting);
      }

      const list = checkItems
        .map((v) => {
          if (v.id === consulting.id) {
            v.$$checked = consulting.$$checked;
          }
          return v;
        })
        .filter((v) => v.$$checked === true);

      setCheckItems([...list]);
      return;
    }

    if (['고객명', 'CUSTOMER'].indexOf(model.title) !== -1) {
      hooks.openCustomerChart({
        customer: consulting.customer,
        consulting,
      });
    }

    if (['STATUS'].indexOf(model.title) !== -1) {
      onClickDeleteConsulting(consulting);
    }
  };

  const settingModels = useCallback(() => {
    const dataCallList = [
      'results',
      '_counselors',
      'surgery_category',
      'surgery_item_name',
    ];
    const model = modelConsultings;
    let found;
    dataCallList.forEach((v) => {
      found = (model.keys || []).find(
        (o) => o.title === $case.toConst(pluralize(v))
      );
      if (!found) return;
      found.selectOptions = (totalSearchList[v] || []).map((o) => {
        o.$$label = o['name'];
        o.$$value = o.id;
        return o;
      });
    });
  }, [totalSearchList]);

  useEffect(() => {
    settingModels();
  }, [settingModels]);

  return (
    <List
      renderFlag={renderFlag}
      api={services.crm.consulting.all_v2}
      model={modelConsultings}
      modelName={'consulting'}
      orderBy={'scheduled_at desc'}
      onAction={onAction}
      onAllCheckAction={onAllCheckAction}
      onClickSendSms={onClickSendSms}
      checkItems={checkItems}
    />
  );
};

export default observer(Consultings);
