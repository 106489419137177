import React, { useState, useEffect, useCallback } from 'react';
import { observer } from 'mobx-react';
import { banks } from 'hooks/constants';
import { toThousandCommas } from 'filters';
import moment from 'moment';
import ModalSmsPointRefundRequest from 'components/modals/ModalSmsPointRefundRequest';
import { $qb } from 'scripts/querybuilder';
import { useAuth } from 'store/auth';
import { useEvent } from 'hooks/useEvent';
import { useModal } from 'hooks/useModal';
import { useServices } from 'hooks/useServices';
import { useToast } from 'hooks/useToast';
import styled, { css } from 'styled-components';
import { RadioInput } from 'components/common/RadioInput';
import { BalanceRequestView } from 'components/pages/crm/sms-point/BalanceRequestView';
import { BalanceRefundView } from 'components/pages/crm/sms-point/BalanceRefundView';

const RadioInputWrapper = styled(RadioInput)`
  margin-right: 5px;
`;

const Input = styled.input`
  padding: 9px 20px 8px;
  font-size: 14px;
  background-color: transparent;
  width: 100%;
  height: 34px;
  border-radius: 4px;
  border: 1px solid #dee2ec;

  ${({ disabled }) =>
    disabled &&
    css`
      background-color: #efeff4 !important;
      color: #ceced7;
    `}
`;

const FormControl = styled.div`
  margin-bottom: 20px;
  font-size: 13px;
`;

const MailText = styled.span`
  color: #008ddc;
  text-decoration: underline;
`;

const SmsPoint = () => {
  const auth = useAuth();
  const eventBus = useEvent();
  const modal = useModal();
  const services = useServices();
  const toast = useToast();
  const [loadingBtnDisabledFlag, setLoadingBtnDisabledFlag] = useState(false);
  const [pointPrice, setPointPrice] = useState(0);
  const [data, setData] = useState({});
  const [balanceData, setBalanaceData] = useState({});
  const [accountData, setAccountData] = useState({});
  const [requestData, setRequestData] = useState([]);
  const [depositRequest, setDepositRequest] = useState(false);
  const [refundRequest, setRefundRequest] = useState(false);
  const [cancelSchedule, setCancelSchedule] = useState(null);
  const [clinic, setClinic] = useState();

  const callApi = useCallback(async () => {
    let data = {
      no: 1, //결제정보 1개만 입력. update개념
    };

    const clinicResp = await services.crm.clinic.detail(auth.me.clinic.id);
    setClinic(clinicResp.data);
    const balanceResp = await services.crm.balance.balance.all();
    setBalanaceData(balanceResp.data);

    let accountResp = balanceResp.data.accountIssue;
    if (accountResp) {
      accountResp = {
        ...accountResp,
        bankName: banks.find((bank) => bank.value === accountResp.bankCd).key,
      };
      data = {
        ...data,
        bankCd: accountResp.bankCd,
        acctNo: accountResp.acctNo,
        acctNm: accountResp.acctNm,
      };
      setAccountData(accountResp);
    } else {
      const accountIssue = await services.crm.balance.account_issue.create();
      data = {
        ...data,
        bankCd: accountIssue.bankCd,
        acctNo: accountIssue.acctNo,
        acctNm: accountIssue.acctNm,
      };
      setAccountData(accountIssue);
    }

    //충전금 신청 내역 /balance/requests
    const params = $qb().limit(1).orderBy('id desc');
    const requestResp = await services.crm.balance.balance.request(
      params.build()
    );
    setRequestData(requestResp.data);

    const { name, employerNo, ownerName, cms, contactManager, contactTel } =
      clinic || clinicResp.data;
    setData({
      ...data,
      clinicName: name,
      employerNo,
      ownerName,
      contactEmail: cms.taxbillEmail,
      contactManager,
      contactTel,
    });

    const requestData = requestResp.data[0];

    if (requestData.status === 'request') {
      requestData.requestType === 'deposit'
        ? setDepositRequest(true)
        : requestData.requestType === 'refund' && setRefundRequest(true);
    } else {
      requestData.requestType === 'deposit'
        ? setDepositRequest(false)
        : requestData.requestType === 'refund' && setRefundRequest(false);
    }

    //TODO : 다국어 반영
    let date = new Date(requestData.requestAt);
    date.setDate(date.getDate() + 7);
    setCancelSchedule(moment(date).format('YYYY년 MM월 DD일 HH시'));
  }, [services, auth]);

  useEffect(() => {
    eventBus.on('callApi', callApi);
    callApi();
  }, [eventBus, callApi]);

  const onClickRefundRequest = () => {
    modal
      .basic({
        title: '환불 신청',
        body:
          '환불 신청이 완료되면 모든 문자 발송이 중단됩니다. 환불 신청을 하시겠습니까?',
        buttons: [
          {
            text: '예',
            class: 'btn-primary',
          },
          {
            text: '아니오',
            class: 'btn-default',
          },
        ],
      })
      .then((selectedIdx) => {
        if (selectedIdx === 0) {
          //예 선택
          //기존 잔액 합치기, id제외 시키기
          const { amount } = balanceData;
          const {
            no,
            clinicName,
            employerNo,
            ownerName,
          } = balanceData.taxBillInfo[0];
          const data = {
            requestType: 'refund',
            no,
            amount,
            clinicName,
            employerNo,
            ownerName,
          };

          let returnData;
          const onChangeRefundData = (rd) => {
            returnData = rd;
          };

          modal
            .custom({
              component: ModalSmsPointRefundRequest,
              options: {
                data,
                onChange: onChangeRefundData,
              },
            })
            .then(async (closed) => {
              if (closed === true) {
                //환불 신청하기 선택
                try {
                  await services.crm.balance.balance.request_create(returnData);
                  modal
                    .confirm({
                      type: 'SUCCESS',
                      msg: '환불신청 완료',
                    })
                    .then(() => {
                      eventBus.emit('callApi');
                    });
                } catch (e) {
                  toast.error(e.description);
                }
              }
            });
        }
      });
  };

  const onChangeInput = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  const onClickPrice = (e) => {
    const { value } = e.target;
    let price = value !== '0' ? pointPrice + Number(value) : 0;
    setPointPrice(price);
  };

  const onClickBalanceRequests = async () => {
    setLoadingBtnDisabledFlag(true);

    let reqData = {
      ...data,
      requestType: 'deposit',
      amount: pointPrice,
    };
    try {
      await services.crm.balance.balance.request_create(reqData);
      modal
        .confirm({
          type: 'SUCCESS',
          msg: '충전신청 완료',
        })
        .then(() => {
          setLoadingBtnDisabledFlag(false);
          callApi();
        });
    } catch (e) {
      setLoadingBtnDisabledFlag(false);
      toast.error(e.description);
    }
  };

  const onClickRefundCancel = async () => {
    const { id, no, bankCd } = requestData[0];
    const data = {
      id,
      no,
      bankCd,
      status: 'canceled',
    };

    try {
      await services.crm.balance.balance.request_update(data);
      modal
        .confirm({
          type: 'SUCCESS',
          msg: '환불신청 취소',
        })
        .then(() => {
          callApi();
        });
    } catch (e) {
      toast.error(e.description);
    }
  };
  const onClickDepoistCancel = async () => {
    const { id, no, bankCd } = requestData[0];
    const data = {
      id,
      no,
      bankCd,
      status: 'canceled',
    };

    try {
      await services.crm.balance.balance.request_update(data);
      modal
        .confirm({
          type: 'SUCCESS',
          msg: '충전신청 취소',
        })
        .then(() => {
          callApi();
        });
    } catch (e) {
      toast.error(e.description);
    }
  };

  return (
    <div className="point-histories">
      <div className="page-navi">
        <span className="title">문자관리</span>
        <span className="title">문자 충전</span>
      </div>
      <div className="point-balance">
        <h2 className="title">충전금</h2>
        <div className="card">
          <div className="route-top flex-row items-center flex-between">
            <div className="title">충전금 잔액</div>
          </div>

          <div className="point-balance-desc">
            <span>
              ₩
              {toThousandCommas(
                balanceData.amount + balanceData.freeAmount || 0
              )}
            </span>
            {!depositRequest && !refundRequest && (
              <div className="btnwrap">
                <button
                  className="btn btn-basic _small"
                  disabled={!balanceData.amount}
                  onClick={onClickRefundRequest}
                >
                  환불 신청
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      {refundRequest && (
        <div className="refund-finish">
          <div className="card">
            <BalanceRefundView
              data={requestData[0]}
              clinic={clinic}
              onClickRefundCancel={onClickRefundCancel}
            />
          </div>
        </div>
      )}

      {depositRequest && (
        <div className="point-apply point-apply-finish">
          <div className="card">
            <BalanceRequestView
              data={requestData[0]}
              clinic={clinic}
              cancelSchedule={cancelSchedule}
              onClickDepoistCancel={onClickDepoistCancel}
            />
          </div>
        </div>
      )}

      {!depositRequest && !refundRequest && (
        <>
          <div className="point-account-info">
            <div className="card">
              <div className="route-top flex-row items-center flex-between">
                <div className="title">충전금 입금 계좌 정보</div>
              </div>
              <div className="point-deposit-info">
                <span>
                  {' '}
                  {accountData.bankName} {accountData.acctNo}{' '}
                  {accountData.acctNm}
                </span>
              </div>
            </div>
          </div>

          <div className="point-apply">
            <div className="card">
              <div className="title m-b-16">병원 기본 정보</div>
              <div className="payment-info-confirm">
                <div className="regions">
                  <FormControl>
                    <label>상호(병원명)</label>
                    <Input
                      name="clinicName"
                      defaultValue={data.clinicName}
                      disabled={true}
                      onChange={onChangeInput}
                    />
                  </FormControl>
                  <FormControl>
                    <label>사업자등록번호</label>
                    <Input
                      name="employerNo"
                      defaultValue={data.employerNo}
                      disabled={true}
                      onChange={onChangeInput}
                    />
                  </FormControl>
                  <FormControl>
                    <label>대표자</label>
                    <Input
                      name="ownerName"
                      defaultValue={data.ownerName}
                      disabled={true}
                      onChange={onChangeInput}
                    />
                  </FormControl>
                  <FormControl>
                    <label>세금계산서 발행 이메일</label>
                    <Input
                      name="contactEmail"
                      defaultValue={data.contactEmail}
                      disabled={true}
                      onChange={onChangeInput}
                    />
                  </FormControl>
                  <FormControl>
                    <label>담당자 이름</label>
                    <Input
                      name="contactManager"
                      defaultValue={data.contactManager}
                      disabled={true}
                      onChange={onChangeInput}
                    />
                  </FormControl>
                  <FormControl>
                    <label>담당자 번호</label>
                    <Input
                      name="contactTel"
                      defaultValue={data.contactTel}
                      disabled={true}
                      onChange={onChangeInput}
                    />
                  </FormControl>
                  <FormControl>
                    문자 세금계산서 발행정보 수정 신청
                    <br />
                    <MailText>
                      <a href="mailto:cs@carecrm.co.kr">cs@carecrm.co.kr</a>
                    </MailText>
                  </FormControl>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="title m-b-16">충전 신청 금액 입력</div>
              <div className="point-apply-input">
                <div className="route-top flex-row items-center flex-between">
                  <label className="label-required">충전 신청 금액</label>
                </div>
                <div className="form-control">
                  <span>
                    <RadioInputWrapper value="true" checked />
                    계좌이체
                  </span>
                  <div className="price-btn">
                    <button
                      className="btn-sm"
                      onClick={onClickPrice}
                      value="10000000"
                    >
                      1,000만원
                    </button>
                    <button
                      className="btn-sm"
                      onClick={onClickPrice}
                      value="5000000"
                    >
                      500만원
                    </button>
                    <button
                      className="btn-sm"
                      onClick={onClickPrice}
                      value="3000000"
                    >
                      300만원
                    </button>
                    <button
                      className="btn-sm"
                      onClick={onClickPrice}
                      value="2000000"
                    >
                      200만원
                    </button>
                    <button
                      className="btn-sm"
                      onClick={onClickPrice}
                      value="1000000"
                    >
                      100만원
                    </button>
                    <button
                      className="btn-sm"
                      onClick={onClickPrice}
                      value="500000"
                    >
                      50만원
                    </button>
                    <button
                      className="btn-sm"
                      onClick={onClickPrice}
                      value="300000"
                    >
                      30만원
                    </button>
                    <button className="btn-sm" onClick={onClickPrice} value="0">
                      초기화
                    </button>
                  </div>
                </div>
                <div className="form-control">
                  <Input
                    value={
                      pointPrice ? `${toThousandCommas(pointPrice)}원` : ''
                    }
                    onChange={() => ''}
                    placeholder="30만 원 이상부터 충전 신청 가능합니다."
                  />
                </div>
                <div className="form-control">
                  <label>입금할 금액(VAT 포함)</label>
                  <Input
                    disabled
                    value={
                      pointPrice
                        ? `${toThousandCommas(Math.round(pointPrice * 1.1))}원`
                        : ''
                    }
                    onChange={() => ''}
                  />
                </div>

                <button
                  className="btn btn-basic _small"
                  disabled={pointPrice < 300000 || loadingBtnDisabledFlag}
                  onClick={onClickBalanceRequests}
                >
                  충전 신청
                </button>
                <div className="help">
                  <p>유의 사항 및 안내</p>
                  <p>
                    * 입금 후 약 5~10분 이내에 자동으로 금액이 충전됩니다.
                    <br />
                    &nbsp;&nbsp;&nbsp;시간이 지나도 충전되지 않는다면 ‘
                    <MailText>
                      <a href="mailto:cs@carecrm.co.kr">cs@carecrm.co.kr</a>
                    </MailText>
                    ’로 요청해 주세요.
                    <br />
                    * 입금 기한은 신청일로부터 7일까지이며, 7일 경과 시
                    자동취소됩니다.
                    <br />
                    * 입금 오류 시 입금 기한, 입금 금액, 계좌번호 일치 순으로
                    확인해 주세요.
                    <br />
                    * 세금계산서 작성일자는 신청일이며 월 중순 입금 시 20일경에
                    발행,
                    <br />
                    &nbsp;&nbsp;&nbsp;이후 입금 시 다음 달 초에 발행됩니다.
                    <br />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default observer(SmsPoint);
