export default {
  keys: [
    {
      title: '일자',
      column: 'scheduledAt',
    },
    {
      title: '종류',
      column: 'smsType',
    },
    {
      title: '사용금액',
      column: '',
    },
    {
      title: '발송성공',
      column: '',
    },
    {
      title: '링크조회수',
      column: '',
    },
    {
      title: '수술건수',
      column: '',
    },
    {
      title: '금액',
      column: '',
    },
    {
      title: '링크조회율',
      column: '',
    },
    {
      title: '이벤트신청율',
      column: '',
    },
    {
      title: '시수술전환률',
      column: '',
    },
    {
      title: '광고효율',
      column: '',
    },
    {
      title: '상세 결과 보기',
      column: '',
    },
    {
      title: '해당조건 다시 보내기',
      column: '',
    },
  ],
};
