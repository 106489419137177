import React from 'react';
import MessageApp from './Layouts/MessageApp';
import ModalMessageBoilerplateSelector from 'components/modals/codes/message-codes/ModalMessageBoilerplateSelect';
import PropTypes from 'prop-types';

const ScreenMap = ({ onChange, obj, setObj }) => {
  return (
    <div className={`message-screen-map`}>
      <MessageApp
        onChange={onChange}
        obj={obj}
        setObj={setObj}
        modalBoilerplateSelector={ModalMessageBoilerplateSelector}
        hideBabitalkLink
      />
    </div>
  );
};

ScreenMap.propTypes = {
  onChange: PropTypes.func,
  obj: PropTypes.object,
  setObj: PropTypes.func,
};

export default ScreenMap;
