import React from 'react';
import { observer } from 'mobx-react';
import NurseVital from './NurseVital';
import NurseInjectItem from './NurseInjectItem';

const NurseCodes = () => {
  return (
    <div className="nurse-codes">
      <div className="page-navi">
        <span className="title">환경설정</span>
        <span className="title">간호코드 설정</span>
      </div>
      <NurseVital type={'operations'} />
      <NurseInjectItem type={'operations'} />
      <NurseVital type={'cares'} />
      <NurseInjectItem type={'cares'} />
    </div>
  );
};

export default observer(NurseCodes);
