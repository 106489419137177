import React from 'react';
import './ctiStatusBar.scss';
import CtiStats from './CtiStats';
import CtiHistoryMenu from './CtiHistoryMenu';
import { observer } from 'mobx-react';
import useCtiStore from './hooks/useCtiStore';
import CtiSettingsModal from './CtiSettingsModal';
import classNames from 'classnames';
import { useModal } from 'hooks/useModal';

const CtiStatusBar = observer(function CtiStatusBar() {
  const modal = useModal();
  const ctiStore = useCtiStore();

  function showStatusBar() {
    ctiStore.setShowStatusBar(true);
    ctiStore.setShowHistory(true);
  }

  function hideStatusBar() {
    ctiStore.setShowStatusBar(false);
    ctiStore.setShowHistory(false);
  }

  function openSettings() {
    modal.custom({
      component: CtiSettingsModal,
    });
  }

  return (
    <>
      {!ctiStore.showStatusBar && (
        <div
          className={classNames(
            'cti-status-bar-hidden',
            ctiStore.settings.historyLocation
          )}
          onClick={showStatusBar}
        ></div>
      )}
      {ctiStore.showStatusBar && (
        <div
          className={classNames(
            'cti-status-bar',
            ctiStore.settings.historyLocation
          )}
        >
          <div className="cti-status-bar-contents">
            <CtiHistoryMenu />
            <CtiStats />
            <button
              className="cti-settings-button"
              onClick={openSettings}
            ></button>
            <div
              className={classNames(
                'cti-hide-button',
                ctiStore.settings.historyLocation
              )}
              onClick={hideStatusBar}
            ></div>
          </div>
        </div>
      )}
    </>
  );
});

export default CtiStatusBar;
