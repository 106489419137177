import * as $http from 'axios';

export default {
  settlements: {
    all: (params) => $http.get('/payments/settlements', { params }),
    all_v2: (params) => $http.get('/payments/v2/settlements', { params }),
    detail: (params) => $http.get('/payments/settlements/detail', { params }),
    detail_v2: (params) =>
      $http.get('/payments/v2/settlements/detail', { params }),
    monthly: (params) => $http.get('/payments/settlements/monthly', { params }),
  },
};
