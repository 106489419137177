import { $case, pluralize, translate } from 'filters';
import moment from 'moment';

export default {
  keys: [
    {
      type: 'checkbox',
      column: '',
    },
    {
      title: '수납일',
      column: 'createdAt',
      filter: (o) => {
        return moment(o.createdAt).format('YYYY-MM-DD');
      },
    },
    {
      title: '카테고리',
      column: 'categoryName',
    },
    {
      title: '시/수술명',
      column: 'name',
    },
    {
      title: '진행한 회차',
      column: 'useCount',
      filter: (o) => `${o.useCount}회`,
    },
    {
      title: '남은 회차',
      column: 'remainingCount',
      filter: (o) => `${o.remainingCount}회`,
    },
    {
      title: '적정 시/수술주기',
      column: 'period',
      filter: (o) =>
        `${
          o.period === null
            ? ''
            : `${o.period}${translate($case.toConst(pluralize(o.periodUnit)))}`
        }`,
    },
  ],
};
