import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import { AppointmentBoardStateContext } from './AppointmentBoards';

const ModeTogglePanel = observer(() => {
  /**
   * @type {AppointmentBoardState}
   */
  const state = useContext(AppointmentBoardStateContext);

  return (
    <button
      className={`header-toggle-btn ${
        state.mode === 'vertical' ? 'selected' : ''
      }`}
      onClick={() => state.toggleMode()}
    >
      <label className="header-toggle-btn-label">
        {state.mode === 'vertical' ? '세로' : '가로'}
      </label>
    </button>
  );
});

export default ModeTogglePanel;
