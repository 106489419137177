import React, { useState } from 'react';
import { pluralize, translate, $case } from 'filters';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';

const SelectNextSurgery = ({
  doctors,
  facialist,
  appointmentDoctor,
  appointmentFacialist,
  setTreatmentSelectDoctors,
  setTreatmentSelectFacialists,
}) => {
  const [selectDoctor, setSelectDoctor] = useState(appointmentDoctor);
  const [selectFacialist, setSelectFacialist] = useState(appointmentFacialist);

  const onChangeSelectDoctor = (e) => {
    setSelectDoctor(doctors[e.target.value]);
    setTreatmentSelectDoctors(doctors[e.target.value]);
  };

  const onChangeSelectFacialist = (e) => {
    setSelectFacialist(facialist[e.target.value]);
    setTreatmentSelectFacialists(facialist[e.target.value]);
  };

  return (
    <div className="form-control m-t-16">
      <div>
        <label className="m-b-8">의사</label>
        <select
          className="m-l-8 m-b-8"
          value={(doctors || []).findIndex(
            (option) => option.id === selectDoctor?.id
          )}
          onChange={onChangeSelectDoctor}
        >
          <option value={undefined}>
            {translate($case.toConst(pluralize('doctors')))}를 선택하세요.
          </option>
          {(doctors || []).map((item, idx) => (
            <option key={idx} value={idx}>
              {item.name || item.content}
            </option>
          ))}
        </select>
      </div>
      <div>
        <label>피부관리사</label>
        <select
          className="m-l-8"
          value={(facialist || []).findIndex(
            (option) => option.id === selectFacialist?.id
          )}
          onChange={onChangeSelectFacialist}
        >
          <option value={undefined}>
            {translate($case.toConst(pluralize('facialist')))}를 선택하세요.
          </option>
          {(facialist || []).map((item, idx) => (
            <option key={idx} value={idx}>
              {item.name || item.content}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

SelectNextSurgery.propTypes = {
  doctors: PropTypes.array,
  facialist: PropTypes.array,
  appointmentDoctor: PropTypes.object,
  appointmentFacialist: PropTypes.object,
  setTreatmentSelectDoctors: PropTypes.func,
  setTreatmentSelectFacialists: PropTypes.func,
};

export default observer(SelectNextSurgery);
