import React from 'react';
import { observer } from 'mobx-react';
import './ctiPopupCloseModal.scss';

const CtiPopupCloseModal = observer(function CtiPopupCloseModal({
  close,
  options: { title, message, onConfirm },
}) {
  function onClickCancel() {
    close();
  }

  function onClickClose() {
    onConfirm();
    close();
  }

  return (
    <div className="cti-popup-close-modal">
      <div className="modal-head">
        <button className="close-icon-btn" onClick={onClickCancel}></button>
      </div>
      <div className="message-box">
        <span className="title">{title}</span>
        <span className="message">{message}</span>
      </div>
      <div className="actions">
        <button className="btn-outlined" onClick={onClickCancel}>
          취소
        </button>
        <button className="btn-close" onClick={onClickClose}>
          닫기
        </button>
      </div>
    </div>
  );
});

export default CtiPopupCloseModal;
