import React, { useState, useCallback } from 'react';
import { observer } from 'mobx-react';
import DataTableAlpha from 'components/data-table/DataTableAlpha';
import PropTypes from 'prop-types';
import models from 'models';
import { translate } from 'filters';
import { $qb } from 'scripts/querybuilder';
import { useModal } from 'hooks/useModal';
import { useServices } from 'hooks/useServices';
import { useToast } from 'hooks/useToast';

const ModalAppointmentCompleteAutoSms = ({ options, close }) => {
  const modal = useModal();
  const services = useServices();
  const toast = useToast();
  const defaultParams = $qb()
    .limit(100)
    .customParam('status', 'waiting,success,fail,sending')
    .customParam('smsScheduleType', 'scheduled')
    .customParam('smsType', 'normal')
    .customParam('appointmentId', options.appointmentId);
  const [params, setParams] = useState(defaultParams);
  const [data, setData] = useState(options.rowData);

  const callApi = useCallback(async () => {
    const resp = await services.crm.notification.smsNotifications.ad.detail(
      params.build()
    );
    if (!resp) return;

    setData(resp.data);
  }, []);

  const onParams = (p) => {
    setParams(p);
    callApi();
  };

  const onAction = (obj) => {
    const event = obj.event;
    const row = obj.row;
    const classList = event.target.classList;

    if (
      classList.contains('btn-danger') &&
      event.target.dataset.type === 'canceled'
    ) {
      //전송 취소
      openModalDelete({ row });
      return;
    }
  };

  const openModalDelete = ({ row }) => {
    const onConfirm = async () => {
      try {
        const params = { id: row['smsHistoryId'] };
        const resp = await services.crm.notification.smsNotifications.delete(
          params.id
        );

        if (resp.data && resp.data.success) {
          modal
            .confirm({
              type: 'SUCCESS',
              msg: '취소되었습니다.',
            })
            .then(() => {
              callApi();
            });
        } else {
          modal
            .confirm({
              type: 'SUCCESS',
              msg: '실패되었습니다.',
            })
            .then(() => {
              callApi();
            });
        }
      } catch (e) {
        console.log(e.description);
        toast.error(e.description);
      }
    };

    modal
      .basic({
        body: translate('CONFIRM_CANCEL'),
        buttons: [
          {
            text: 'CANCEL',
            class: 'btn-default',
          },
          {
            text: 'CONFIRM',
            class: 'btn-danger',
          },
        ],
      })
      .then((idx) => {
        if (idx === 1) {
          onConfirm();
        }
      });
  };

  return (
    <div className={`modal-complete-auto-sms`} style={{ width: '900px' }}>
      <div className="head flex-row flex-between items-start">
        <div className="title">설정된 예약완료 자동문자</div>
        <i onClick={() => close()} className="zmdi zmdi-close" />
      </div>
      <div className="body auto-sms-container">
        <DataTableAlpha
          model={models.crm.appointmentAutoSms}
          data={data}
          params={params}
          onParams={onParams}
          onAction={onAction}
          hideBottom={true}
        />
      </div>
    </div>
  );
};

ModalAppointmentCompleteAutoSms.propTypes = {
  options: PropTypes.object,
  close: PropTypes.func,
};

export default observer(ModalAppointmentCompleteAutoSms);
