import { currency, translate } from 'filters';

const c = (val) => `₩${currency(val)}`;

export default {
  keys: [
    {
      title: 'ACTION',
      filter: () => `
            <button class="btn btn-primary btn-sm">수정</button>
            <button class="btn btn-danger btn-sm m-l-8">삭제</button>
        `,
    },
    {
      title: 'PAID_AT',
      column: 'paidAt',
      type: 'timestamp',
    },
    {
      title: 'REIMBURSEMENT',
      column: 'reimbursement',
      filter: (o) => (o.reimbursement ? '급여' : '비급여'),
    },
    {
      title: 'PAYMENT_METHOD',
      column: 'paymentMethod',
      filter: (o) => translate((o.paymentMethod || '').toUpperCase()),
    },
    {
      title: 'AMOUNT',
      column: 'amount',
      filter: (o) => c(o.amount),
    },
    {
      title: 'CASH_RECEIPT',
      column: 'cashReceipt',
    },
  ],
};
