export const CounselingResult = {
  0: 'none',
  1: 'counseling_by_kakaotalk',
  2: 'recall_need',
  3: 'recall_first_absence',
  4: 'recall_second_absence',
  5: 'response_complete_appointment',
  6: 'response_complete_recall',
  7: 'response_complete_inquiry',
  8: 'response_complete_reject',
  none: 0,
  counseling_by_kakaotalk: 1,
  recall_need: 2,
  recall_first_absence: 3,
  recall_second_absence: 4,
  response_complete_appointment: 5,
  response_complete_recall: 6,
  response_complete_inquiry: 7,
  response_complete_reject: 8,
  toName: (v) => {
    switch (v) {
      case CounselingResult.none:
        return '결과 없음';
      case CounselingResult.counseling_by_kakaotalk:
        return '응대 중_카톡 응대 중';
      case CounselingResult.recall_need:
        return '리콜필요_리콜';
      case CounselingResult.recall_first_absence:
        return '리콜필요_1차부재';
      case CounselingResult.recall_second_absence:
        return '리콜필요_2차부재';
      case CounselingResult.response_complete_appointment:
        return '응대완료_예약완료';
      case CounselingResult.response_complete_recall:
        return '응대완료_리콜응대완료';
      case CounselingResult.response_complete_inquiry:
        return '응대완료_단순문의';
      case CounselingResult.response_complete_reject:
        return '응대완료_거절';
    }
  },
};
