import moment from 'moment';

export default {
  keys: [
    {
      //     type: 'multiCheck',
      //     column: '',
      //     checkUse: o => 'false',
      //   },{
      title: 'STATUS',
      noorder: true,
      filter: () => {
        return `<div class="text-center">
                <span class="text-underline c-red">삭제</span>
            </div>`;
      },
    },
    {
      title: '진료일',
      column: 'scheduledAt',
      nosearch: true,
      filter: (o) => moment(o.scheduledAt).format('YYYY-MM-DD'),
    },
    {
      title: 'START_AT',
      column: 'startAt',
      hideColumn: true,
      selectType: 'date',
      customParamFieldName: 'startAt',
      filter: (o) => {
        return `<div>
                <div>${moment(o.scheduledAt).format('YYYY-MM-DD')} ${
          o.startHour
        }</div>
            </div>`;
      },
    },
    {
      title: 'END_AT',
      column: 'endAt',
      hideColumn: true,
      selectType: 'date',
      customParamFieldName: 'endAt',
      filter: (o) => {
        return `<div>
                <div>${moment(o.scheduledAt).format('YYYY-MM-DD')} ${
          o.endHour
        }</div>
            </div>`;
      },
    },
    {
      title: '고객명',
      column: 'customer__name',
      noorder: true,
      customParamFieldName: 'name',
      filter: (o) =>
        `<span class="text-underline c-primary">${o.customer.name}</span>`,
    },
    {
      title: 'CHART_NO',
      column: 'customer__chartNo',
      noorder: true,
      customParamFieldName: 'chartNo',
    },
    {
      title: '_DOCTORS',
      column: 'doctor__name',
      noorder: true,
      selectType: 'multiSelect',
      customParamFieldName: 'doctorId',
    },
    {
      title: 'SURGERY_CATEGORIES',
      column: 'surgery_item_category__name',
      noorder: true,
      type: 'list_mini',
      selectType: 'multiSelect',
      customParamFieldName: 'treatmentCategoryId',
      filter: (o) =>
        `${o.items
          .map((item) => `<p>${(item.category || {}).name || ''} </p>`)
          .join('\n')}`,
    },
    {
      title: 'SURGERY_ITEM_NAMES',
      column: 'surgery_item_name__name',
      noorder: true,
      type: 'list',
      selectType: 'multiSelect',
      customParamFieldName: 'treatmentItemId',
      filter: (o) =>
        `${o.items.map((item) => `<p>${item.name}</p>`).join('\n')}`,
    },
    {
      title: 'MEMO',
      column: 'memo',
      noorder: true,
      type: 'text',
      nosearch: true,
    },
    // {
    //     title: 'UPDATE_AT',
    //     column: 'updatedAt',
    //     filter: o => {
    //         return `<div>
    //             <div>${moment(o.updatedAt).format('YYYY-MM-DD')}</div>
    //             <div>${moment(o.updatedAt).format('HH:mm:ss')}</div>
    //         </div>`
    //     },
    //     nosearch: true,
    // }
  ],
};
