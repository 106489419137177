import React from 'react';
import classNames from 'classnames';
import './ctiFormTextField.scss';
import PropTypes from 'prop-types';

export const CtiFormTextField = ({ error, className, ...props }) => {
  return (
    <div
      className={classNames('cti-textfield-wrapper', className, {
        error: Boolean(error),
      })}
    >
      <input {...props} />
      {/* {error && <div className="cti-form-error">{error}</div>} */}
    </div>
  );
};

CtiFormTextField.propTypes = {
  error: PropTypes.string,
  className: PropTypes.string,
};
