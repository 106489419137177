import React, { useState, useEffect, useCallback } from 'react';
import { observer } from 'mobx-react';
import models from 'models';
import { translate } from 'filters';
import { useModal } from 'hooks/useModal';
import { useServices } from 'hooks/useServices';
import styled from 'styled-components';
import CommonForm from '../CommonForm';
import { RadioInput } from 'components/common/RadioInput';

const RadioInputWrapper = styled(RadioInput)`
  margin-right: 8px;
`;

const CustomerCodes = () => {
  const modal = useModal();
  const services = useServices();
  const [convert, setConvert] = useState('');
  const [sex, setSex] = useState('female');
  const [customerSex, setCustomerSex] = useState('');

  const callApi = useCallback(async () => {
    const resp = await services.crm.customer.convertTime();
    if (!resp) return;

    setConvert(resp.data.convertAt);
  }, []);

  useEffect(() => {
    callApi();
  }, [callApi]);

  const configCallApi = useCallback(async () => {
    try {
      const resp = await services.crm.clinic.configs({
        groupName: 'customer',
      });

      if (resp && resp.data.length < 1) return;
      else {
        setSex(resp.data[0].codeValue);
        setCustomerSex(resp.data[0]);
      }
    } catch (e) {
      console.log(e.description);
    }
  }, []);

  useEffect(() => {
    configCallApi();
  }, [configCallApi]);

  const CallPostApi = async (payload) => {
    try {
      await services.crm.clinic.configs_multi(payload);
      modal.confirm({
        type: 'SUCCESS',
        msg: '업데이트되었습니다.',
      });
    } catch (e) {
      console.log(e.description);
      let errorMessage = e.description;
      modal.confirm({
        type: 'ERROR',
        msg: errorMessage,
      });
    }
  };

  const onClickSave = async () => {
    let data = { ...customerSex };
    let payload = {
      groupName: 'customer',
      codeName: 'sex',
      codeValue: sex,
    };

    if (data.id) payload.id = data.id;
    payload.codeValue =
      payload.codeValue === null ? 'female' : payload.codeValue;
    await CallPostApi({ data: payload });
    // configCallApi();
  };

  return (
    <div className="customer-codes">
      <div className="page-navi">
        <span className="title">환경설정</span>
        <span className="title">고객코드 설정</span>
      </div>

      <div className="is-new-customer customer-change cust-box">
        <div className="title">신환/구환 전환시점 설정</div>
        <div className="data-table">
          <div className="form-control">
            <div className="radios flex-row">
              <div className="radio-item">
                <RadioInputWrapper
                  name="isNewCustomer"
                  checked={convert === 'visit'}
                  disabled
                />
                <label>내원유무시점에 전환</label>
                <div className="desc">
                  <p>내원이력 없음 - 신환</p>
                  <p>내원이력 있음 - 구환</p>
                </div>
              </div>
              <div className="radio-item">
                <RadioInputWrapper
                  name="isNewCustomer"
                  checked={convert === 'payment'}
                  disabled
                />
                <label>수납유무시점에 전환</label>
                <div className="desc">
                  <p>수납이력 없음 - 신환</p>
                  <p>수납이력 있음 - 구환</p>
                </div>
              </div>
              <div className="radio-item">
                <RadioInputWrapper
                  name="isNewCustomer"
                  checked={convert === 'manual'}
                  disabled
                />
                <label>수동 설정</label>
                <div className="desc">
                  <p>예약생성 시 직접설정</p>
                </div>
              </div>
            </div>
            <p className="help">
              ※ 해당 설정을 변경하시려면 cs@unobeauty.kr으로 문의해주세요.
            </p>
          </div>
        </div>
      </div>

      <div className="is-new-customer customer-sex cust-box">
        <div className="title">
          고객 성별 기본값 설정
          <button
            className="btn btn-warning"
            onClick={() => {
              configCallApi();
            }}
          >
            {translate('INIT')}
          </button>
          <button className="btn btn-primary" onClick={onClickSave}>
            {translate('SAVE')}
          </button>
        </div>
        <div className="data-table">
          <div className="form-control">
            <div className="radios flex-row">
              <label>성별</label>
              <div className="radio-item">
                <RadioInputWrapper
                  name="customerSex"
                  checked={sex === 'male'}
                  onChange={() => setSex('male')}
                />
                <label>남성</label>
              </div>
              <div className="radio-item">
                <RadioInputWrapper
                  name="customerSex"
                  checked={sex === 'female'}
                  onChange={() => setSex('female')}
                />
                <label>여성</label>
              </div>
            </div>
          </div>
        </div>
      </div>

      {['acquisitionChannel', 'job', 'level', 'complaint', 'region'].map(
        (key, idx) => (
          <CommonForm
            key={idx}
            api={services.crm.customer[key]}
            model={models.crm[key]}
            modelName={key}
          />
        )
      )}
    </div>
  );
};

export default observer(CustomerCodes);
