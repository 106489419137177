import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { translate } from 'filters';
import { observer } from 'mobx-react';
import CustomerSearchBar from './CustomerSearchBar';
import { useModal } from 'hooks/useModal';
import { useToast } from 'hooks/useToast';
import { useAuth } from 'store/auth';
import { useTranslation } from 'hooks/useTranslation';
import { useUI } from 'hooks/useUI';

const HeaderBar = () => {
  const history = useHistory();
  const auth = useAuth();
  const modal = useModal();
  const toast = useToast();
  const translation = useTranslation();
  const ui = useUI();
  const [routeName, setRouteName] = useState();

  const toggleLocale = () => {
    translation.toggleLocale();
    setRouteName(preparedRouteName());
  };

  const preparedRouteName = () => {
    const parts = history.location.pathname.split('/');
    let lastPart = parts[parts.length - 1];
    if (!isNaN(parseInt(lastPart))) {
      lastPart = parts[parts.length - 2];
    }
    return translate(lastPart.replace(/-/g, '_').toUpperCase());
  };

  const logout = () => {
    modal
      .basic({
        title: 'LOGOUT',
        body: translate('MODAL_LOGOUT_BODY'),
        buttons: [
          {
            text: 'CANCEL',
            class: 'btn-default',
          },
          {
            text: 'CONFIRM',
            class: 'btn-primary',
          },
        ],
      })
      .then((selectedIdx) => {
        if (selectedIdx === 1) {
          toast.success('Bye Bye!');
          auth.signOut();
          history.push('/login');
        }
      });
  };

  useEffect(() => {
    setRouteName(preparedRouteName());
  }, [history.location.pathname, preparedRouteName]);

  return (
    <div
      className={`header-bar flex-row flex-between ui-header ${
        ui.showNavigation ? '' : 'side'
      }`}
    >
      <div className="header-items left flex-wrap">
        <h1 className="title">{auth.me && auth.me.clinic.name}</h1>
        <button
          className="btn btn-close"
          onClick={() => {
            ui.setShowNavigation(!ui.showNavigation);
          }}
        />
      </div>
      <div className="flex-row items-center flex-between flex-fill">
        <CustomerSearchBar />
        <div className="header-items right">
          <div className="me _language">
            <button className="btn btn-lang" onClick={toggleLocale}>
              언어변환
            </button>
            <div className="lang">
              <ul>
                <li>
                  <a href="#">한글</a>
                </li>
                <li>
                  <a href="#">영문</a>
                </li>
              </ul>
            </div>
          </div>

          {auth.me && (
            <div className="me _info">
              <p className="fig"></p>
              <p>{auth.me.name || auth.me.clinic.ownerName}</p>
              <p className="email">
                <a href={`mailto:${auth.me.email}`}>{auth.me.email}</a>
              </p>
            </div>
          )}

          <div className="me _power">
            <button className="btn btn-logout" onClick={logout}>
              {translate('LOGOUT')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

HeaderBar.propTypes = {
  translation: PropTypes.object,
  preparedRouteName: PropTypes.func,
};

export default observer(HeaderBar);
