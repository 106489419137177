import React, { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { currencySymbol, currency, translate } from 'filters';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import { useAuth } from 'store/auth';
import { useModal } from 'hooks/useModal';
import { useServices } from 'hooks/useServices';
import { useToast } from 'hooks/useToast';

const ModalMessageSendConfirm = ({ options, close }) => {
  const modal = useModal();
  const services = useServices();
  const toast = useToast();
  const auth = useAuth();
  const { dataObj, balance } = options;

  const { checkedCount } = dataObj;
  const [estimatedAmount, setEstimatedAmount] = useState(null);
  const { priceTable } = auth.me.clinic;
  const [loadingBtnDisabledFlag, setLoadingBtnDisabledFlag] = useState(false);

  const validate = useCallback(() => {
    if (balance.totalAmount - estimatedAmount < balance.limit) {
      // 남은금액 - 예상소진금액 > 제한금액(-100,000) 이면 alert 노출
      toast.error(
        '잔여충전금을 초과하여, 요청에 실패했습니다. 요청하신 문자는 모두 발송되지 않습니다.',
        5000
      );
      return false;
    }

    const acceptableAmount = balance.totalAmount - balance.limit;
    if (estimatedAmount > acceptableAmount) {
      toast.error('충전금이 부족합니다.\n 충전 후 다시 시도해주세요');
      return false;
    }

    return true;
  }, [toast, balance.limit, balance.totalAmount, estimatedAmount]);

  useEffect(() => {
    if (
      checkedCount > 0 &&
      Array.isArray(priceTable) &&
      priceTable.length > 0
    ) {
      const { messageType, isBabitalkUri } = dataObj.notification;

      if (!messageType || messageType.length === 0) {
        toast.error('[Error] Invalid Message type');
        return false;
      }
      const priceInfo = priceTable.find(
        (o) => messageType.toLowerCase() === o.serviceCode.toLowerCase()
      );
      const { unitPrice } = priceInfo;

      if (!unitPrice || unitPrice <= 0) {
        toast.error('[Error] Invalid Price Info');
        return false;
      }

      let babitalkUnitPrice = 0;
      if (isBabitalkUri) {
        const babitalkPriceInfo = priceTable.find(
          (o) => 'babi_link' === o.serviceCode.toLowerCase()
        );
        babitalkUnitPrice = babitalkPriceInfo.unitPrice;
      }
      setEstimatedAmount(checkedCount * (unitPrice + babitalkUnitPrice));
    }
  }, [toast, checkedCount, dataObj.notification, priceTable]);

  const onClickConfirm = useCallback(async () => {
    setLoadingBtnDisabledFlag(true);

    if (!validate()) {
      return;
    }

    try {
      if (options.endpoint === 'sendNotification/task') {
        //현황판, 예약캘린더 우클릭 액션으로 문자전송 추가시 POST /send_notifications/task api 호출
        const resp = await services.crm.notification.sendNotifications.create(
          dataObj
        );

        const { success } = resp.data;
        if (success === true) {
          modal
            .confirm({
              type: 'SUCCESS',
              msg: '전송요청 되었습니다.',
            })
            .then(() => {
              close(true);
            });
        } else {
          close(false);
          toast.error('정상응답이나 성공여부 리턴안됨');
          setLoadingBtnDisabledFlag(false);
        }
      } else {
        const resp = await services.crm.notification.smsNotifications.create(
          dataObj
        );

        const { success } = resp.data;
        if (success === true) {
          modal
            .confirm({
              type: 'SUCCESS',
              msg: '전송요청 되었습니다.',
            })
            .then(() => {
              close(true);
            });
        } else {
          close(false);
          toast.error('정상응답이나 성공여부 리턴안됨');
          setLoadingBtnDisabledFlag(false);
        }
      }
    } catch (e) {
      close(false);
      setLoadingBtnDisabledFlag(false);
      if (e.description === 'invalidString') {
        toast.error('문자 제목 또는 내용에 특수 이모티콘을 제거해주세요');
      } else if (e.description === 'DeficiencyOfBalance') {
        toast.error(
          '잔여충전금을 초과하여, 요청에 실패했습니다. 요청하신 문자는 모두 발송되지 않습니다.',
          5000
        );
      } else {
        toast.error(e.description);
      }
    }
  }, [
    options.endpoint,
    services.crm.notification.sendNotifications,
    services.crm.notification.smsNotifications,
    toast,
    close,
    dataObj,
    validate,
  ]);

  return (
    <div className="modal-message-send-confirm items-group">
      <div className="head">
        <div className="row">
          <span className="desc">전송하시겠습니까?</span>
        </div>
      </div>
      <div className="body">
        <div className="row guide">
          {`#{변수}를 사용했을 경우, 글자수가 달라져 \n실제소진되는 금액과 다를 수 있습니다.`}
        </div>
        <div className="row detail">
          <span className="label">문자전송인원: </span>
          <span className="value">
            {dataObj.checkedCount === 1
              ? translate('ONE_PERSON')
              : translate('N_PERSONS').replace(/%s/, dataObj.checkedCount)}
          </span>
        </div>
        <div className="row detail">
          <span className="label">예상소진 금액: </span>
          <span className="value">
            {estimatedAmount !== null &&
              `${currencySymbol()} ${currency(estimatedAmount)}`}
            {estimatedAmount === null && (
              <FontAwesomeIcon icon={faCircleNotch} spin />
            )}
          </span>
        </div>
      </div>

      <div className="buttons">
        <div className="flex-row">
          <button onClick={close} className="btn btn-default">
            {translate('CANCEL')}
          </button>
          <button
            onClick={onClickConfirm}
            disabled={estimatedAmount === null || loadingBtnDisabledFlag}
            className="btn btn-primary"
          >
            {translate('CONFIRM')}
          </button>
        </div>
      </div>
    </div>
  );
};

ModalMessageSendConfirm.propTypes = {
  options: PropTypes.object,
  close: PropTypes.func,
};

export default observer(ModalMessageSendConfirm);
