import * as $http from 'axios';

const nurse = {};

const models = ['operations', 'cares', 'skins'];

models.forEach((modelName) => {
  nurse[modelName] = {
    all: (params) => $http.get(`nurse/${modelName}`, { params }),
    delete: (model) => $http.delete(`nurse/${modelName}/${model}`),
    update: (model) => $http.put(`nurse/${modelName}/${model.id}`, model),
    create: (model) => $http.post(`nurse/${modelName}`, model),
    list: (params) => $http.get(`nurse/${modelName}/list`, { params }),
    //visible: (model) => $http.put(`nurse/${modelName}/injectitems/${model.id}`, model),
    all_v2: (params) => $http.get(`nurse/v2/${modelName}`, { params }),
    update_v2: (model) => $http.put(`nurse/v2/${modelName}/${model.id}`, model),
    create_v2: (model) => $http.post(`nurse/v2/${modelName}`, model),
    delete_v2: (model) => $http.delete(`nurse/v2/${modelName}/${model}`),
    list_v2: (params) => $http.get(`nurse/v2/${modelName}/list`, { params }),
  };
});

export default nurse;
