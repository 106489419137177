import React, { useState, useEffect, useCallback } from 'react';
import { observer } from 'mobx-react';
import HeaderRowSpanDataTable from 'components/data-table/HeaderRowSpanDataTable';
import PropTypes from 'prop-types';
import moment from 'moment';
import modelDailySettlements from 'models/daily-settlements';
import { $qb } from 'scripts/querybuilder';
import { useServices } from 'hooks/useServices';

const DailySettlementsList = ({ startAt, endAt }) => {
  const services = useServices();
  const defaultParams = $qb()
    .orderBy('paidAt desc')
    .customParam('paidStartAt', moment(startAt).format('YYYY-MM-DD'))
    .customParam('paidEndAt', moment(endAt).format('YYYY-MM-DD'));

  const [params, setParams] = useState(defaultParams);
  const [searchValue, setSearchValue] = useState({
    paidStartAt: moment(startAt).format('YYYY-MM-DD'),
    paidEndAt: moment(endAt).format('YYYY-MM-DD'),
  });
  const [data, setData] = useState([]);

  const onChangeDateAt = () => {
    const paidStartAt = moment(startAt).format('YYYY-MM-DD');
    const paidEndAt = moment(endAt).format('YYYY-MM-DD');
    let searchParam = { ...searchValue, paidStartAt, paidEndAt };
    if (searchParam.paidStartAt === 'Invalid date') {
      // eslint-disable-next-line no-unused-vars
      const { paidStartAt: deletedKey, ...otherKeys } = searchParam;
      searchParam = otherKeys;
    }

    if (searchParam.paidEndAt === 'Invalid date') {
      // eslint-disable-next-line no-unused-vars
      const { paidEndAt: deletedKey, ...otherKeys } = searchParam;
      searchParam = otherKeys;
    }

    params.queryParams = {
      limit: params.queryParams.limit,
      orderBy: params.queryParams.orderBy,
      ...searchParam,
    };

    setSearchValue(searchParam);
    setParams(params);
    dailyCallApi();
  };

  const dailyCallApi = useCallback(async () => {
    const resp = await services.crm.settlements.settlements.all_v2(
      params.build()
    );
    if (!resp) return;

    let dailyTotalTotalAmountt = 0; //total row > 당일 매출액
    let dailyTotalSumAmount = 0; //total row > 당일 수납액 total (현금+카드+계좌이체)
    let dailyTotalCreditCardAmount = 0; // total row > 수납액 신용카드
    let dailyTotalDebitCardAmount = 0; // total row > 수납액 체크카드
    let dailyTotalBankTransferAmount = 0; //total row > 당일 수납액 계좌이체
    let dailyTotalCashAmount = 0; //total row > 당일 수납액 cash
    let dailyTotalRefundAmount = 0; // total row > 환불액
    let dailyTotalRefundCreditCardAmount = 0; // total row > 신용카드 환불액
    let dailyTotalRefundDebitCardAmount = 0; // total row > 체크카드 환불액
    let dailyTotalRefundBankTransferAmount = 0; // total row > 계좌이체 환불액
    let dailyTotalRefundCashAmount = 0; // total row > 현금 환불액
    let dailyTotalActualAmount = 0; // total row > 실매출액
    let dailyTotalCashReceiptAmount = 0; // total row > 현금영수증 발급액

    resp.data.forEach((v, i) => {
      v.id = i;
      v.sumAmount =
        v.creditCardAmount +
        v.debitCardAmount +
        v.cashAmount +
        v.bankTransferAmount;

      dailyTotalTotalAmountt += v.totalAmount;
      dailyTotalCreditCardAmount += v.creditCardAmount;
      dailyTotalDebitCardAmount += v.debitCardAmount;
      dailyTotalSumAmount += v.sumAmount;
      dailyTotalCashAmount += v.cashAmount;
      dailyTotalBankTransferAmount += v.bankTransferAmount;
      dailyTotalRefundAmount += v.refundAmount;
      dailyTotalCashReceiptAmount += v.cashReceiptAmount;
      dailyTotalRefundCreditCardAmount += v.refundCreditCardAmount;
      dailyTotalRefundDebitCardAmount += v.refundDebitCardAmount;
      dailyTotalRefundCashAmount += v.refundCashAmount;
      dailyTotalRefundBankTransferAmount += v.refundBankTransferAmount;
      dailyTotalActualAmount += v.actualAmount;
    });

    setData([
      {
        id: -1,
        paidAt: 'Total',
        totalAmount: dailyTotalTotalAmountt,
        sumAmount: dailyTotalSumAmount,
        creditCardAmount: dailyTotalCreditCardAmount,
        debitCardAmount: dailyTotalDebitCardAmount,
        bankTransferAmount: dailyTotalBankTransferAmount,
        cashAmount: dailyTotalCashAmount,
        refundAmount: dailyTotalRefundAmount,
        refundCreditCardAmount: dailyTotalRefundCreditCardAmount,
        refundDebitCardAmount: dailyTotalRefundDebitCardAmount,
        refundBankTransferAmount: dailyTotalRefundBankTransferAmount,
        refundCashAmount: dailyTotalRefundCashAmount,
        actualAmount: dailyTotalActualAmount,
        cashReceiptAmount: dailyTotalCashReceiptAmount,
      },
      ...resp.data,
    ]);
  }, [services.crm.settlements, params]);

  useEffect(() => {
    dailyCallApi();
  }, [dailyCallApi]);

  useEffect(() => {
    onChangeDateAt();
  }, [startAt, endAt]);

  const onClickHeaderRow = (row) => {
    let orderBy = `${row.column} ${row.order}`;
    params.orderBy(orderBy);
    dailyCallApi();
  };

  return (
    <div className="settlements-container total-row">
      <HeaderRowSpanDataTable
        rowKey="id"
        model={modelDailySettlements}
        data={data}
        bordered={true}
        hideBottom={true}
        onClickHeaderRow={onClickHeaderRow}
      />
    </div>
  );
};

DailySettlementsList.propTypes = {
  startAt: PropTypes.object,
  endAt: PropTypes.object,
};

export default observer(DailySettlementsList);
