import React, { useState, useEffect, useCallback } from 'react';
import { observer } from 'mobx-react';
import moment from 'moment';
import DataTableAlpha from 'components/data-table/DataTableAlpha';
import Checkbox from 'components/app/Checkbox';
import ModalBulkRegistration from 'components/modals/ModalBulkRegistration';
import ModalCreateConsultingRequests from 'components/modals/ModalCreateConsultingRequests';
import ModalChangeCounselors from 'components/modals/ModalChangeCounselors';
import ModalAutoAssign from 'components/modals/ModalAutoAssign';
import hooks from 'hooks';
import models from 'models';
import { $qb } from 'scripts/querybuilder';
import { useEvent } from 'hooks/useEvent';
import { useModal } from 'hooks/useModal';
import { useServices } from 'hooks/useServices';
import { useToast } from 'hooks/useToast';
import styled from 'styled-components';
import DatePicker from 'components/common/DatePicker';

const Input = styled.input`
  min-height: 10px;
  border-radius: 4px;
  background-color: #f3f8ff;
  font-size: 14px;
  padding: 8px 10px 9px 20px;
  display: inline-block;
  width: 140px;
  height: 36px;
  line-height: 1;
  border: 1px solid #dee2ec;
`;

const ConsultingRequestsCounselors = () => {
  const eventBus = useEvent();
  const modal = useModal();
  const services = useServices();
  const toast = useToast();
  const defaultParams = $qb().limit(20).orderBy('requestAt desc');
  const [params, setParams] = useState(defaultParams);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [consultingManager, setConsultingManager] = useState([]);
  const [countCounselors, setCountCounselors] = useState([]);
  const [countNotCounselors, setCountNotCounselors] = useState();
  const [selectRow, setSelectRow] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [startAt, setStartAt] = useState();
  const [endAt, setEndAt] = useState();
  const [hideAssignUser, setHideAssignUser] = useState(false);
  const [statusBox, setStatusBox] = useState(false);
  const [statusList, setStatusList] = useState([{ name: '미응대' }]);
  const [loadingBtnDisabledFlag, setLoadingBtnDisabledFlag] = useState(false);
  const [showAutoAssign, setShowAutoAssign] = useState(false);
  const [showAutoAssignAlways, setShowAutoAssignAlways] = useState(true);

  const counselorCallApi = useCallback(async () => {
    //24시간 설정되어 있으면 자동배정하기 버튼 비노출
    let autoAssign = await services.crm.consultingRequest.auto_assign.all();
    if (!autoAssign) return;

    if (autoAssign.data.length) {
      setShowAutoAssignAlways(autoAssign.data[0].isAssignAlways);
    }
  }, [services.crm.consultingRequest.auto_assign]);

  useEffect(() => {
    counselorCallApi();
  }, [counselorCallApi]);

  const configCallApi = useCallback(async () => {
    //자동배정 미사용시 자동배정하기 버튼 비노출
    const resp = await services.crm.clinic.configs();
    if (!resp) return;

    if (resp.data.length) {
      let list = resp.data.filter((v) => {
        return (
          v.groupName === 'consulting_request_auto_assign' &&
          v.codeValue === 'True'
        );
      });
      setShowAutoAssign(list.length);
    }
  }, [services.crm.clinic]);

  useEffect(() => {
    //자동배정 사용/미사용
    configCallApi();
  }, [configCallApi]);

  const todayAssignCallApi = useCallback(async () => {
    try {
      let yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      yesterday = moment(yesterday).format('YYYY-MM-DD');
      let today = moment(new Date()).format('YYYY-MM-DD');
      let param = { assignedStartAt: yesterday, assignedEndAt: today };
      const resp = await services.crm.consultingRequest.requests.count(param);
      if (!resp) return;

      let obj = {};
      resp.data.forEach((v) => {
        if (obj[v.assignUserId] === undefined) {
          obj[v.assignUserId] = v.cnt;
        } else {
          obj[v.assignUserId] += v.cnt;
        }
      });
      setCountCounselors(obj);
    } catch (e) {
      console.log(e.description);
    }
  }, [services]);

  const callApi = useCallback(async () => {
    const resp = await services.crm.consultingRequest.requests.all(
      params.build()
    );
    if (!resp) return;

    resp.data.forEach((v) => {
      let currentTime = new Date();
      let recallTime = new Date(
        moment(v.lastRecallAt).format('YYYY-MM-DD HH:mm:ss')
      );
      let result = recallTime - currentTime;
      v.greenFont = result <= 1800000 && result >= 0; //리콜일시 30분 전
      v.redFont = result < 0; //리콜일시가 지났을 때
      const { consultingRequestResult } = v;
      v.consultingRequestResult = consultingRequestResult.sort((a, b) => {
        return a.createdAt > b.createAt ? 1 : -1;
      });
      v.statusName = '미응대';
      if (v.status) {
        v.statusName = (v.status.category?.name ?? '') + ' > ' + v.status.name;
      } else {
        if (v.consultingRequestResult.length > 0) {
          v.statusName = '미입력';
        }
      }
      if (v.lastRecallAt) {
        v.lastRecallAt = v.lastRecallAt.replace('T', ' ').slice(0, -3);
      }

      return v;
    });

    setData(resp.data);
    setTotal(resp.total);
  }, [services, params]);

  const consultingManagerCallApi = useCallback(async () => {
    const params = { type: 'staff', userStatus: 'active', limit: 1000 };
    const resp = await services.crm.organizations.organ.all(params);
    if (!resp) return;

    const managerList = resp.data.filter((v) => {
      return v.user.isConsultingRequestUser;
    });

    setConsultingManager(managerList);
  }, [services.crm.organizations.organ]);

  const statusCallApi = useCallback(async () => {
    const param = { visible: true };
    const resp = await services.crm.consultingRequest.requests.status(param);
    if (!resp) return;

    setStatusList((statusList) => [...statusList, ...resp.data]);
  }, [services.crm.consultingRequest.requests]);

  const customerInfoCallApi = async (customerId) => {
    try {
      const resp = await services.crm.customer.detail(customerId);
      if (!resp) return;
      const customer = resp.data;
      hooks.openCustomerChart({ customer });
    } catch (e) {
      console.log(e.description);
      if (e.description === 'CustomerNotFound') {
        toast.error('삭제된 고객입니다.');
      }
    }
  };

  const requestUpdateCallApi = async (id, consulting) => {
    try {
      const resp = await services.crm.consultingRequest.requests.update(
        id,
        consulting
      );
      if (!resp) return;

      modal
        .confirm({
          type: 'SUCCESS',
          msg: '업데이트되었습니다.',
        })
        .then(() => {
          setSelectRow([]);
          consultingManagerCallApi();
          todayAssignCallApi();
          notCounselorsCallApi();
          callApi();
        });
    } catch (e) {
      console.log(e.description);
      modal.confirm({
        type: 'ERROR',
        msg: e.description,
      });
    }
  };

  const requestUpdateMultiCallApi = async (consulting) => {
    try {
      const resp = await services.crm.consultingRequest.requests.multi_update(
        consulting
      );
      if (!resp) return;

      modal
        .confirm({
          type: 'SUCCESS',
          msg: '담당자 배정 완료',
        })
        .then(() => {
          setSelectRow([]);
          consultingManagerCallApi();
          todayAssignCallApi();
          notCounselorsCallApi();
          callApi();
          setLoadingBtnDisabledFlag(false);
        });
    } catch (e) {
      console.log(e.description);
      modal
        .confirm({
          type: 'ERROR',
          msg: e.description,
        })
        .then(() => {
          setLoadingBtnDisabledFlag(false);
        });
    }
  };

  const notCounselorsCallApi = useCallback(async () => {
    const param = {
      // ...params.queryParams,
      assignUserId: 0,
    };
    // const resp = await services.crm.consultingRequest.requests.all(param)
    const resp = await services.crm.consultingRequest.requests.count(param);
    let count = 0;
    resp.data.forEach((v) => (count += v.cnt));
    setCountNotCounselors(count);
  }, [services.crm.consultingRequest.requests]);

  useEffect(() => {
    //not param call api > 담당자 배정 필요 건 지정
    notCounselorsCallApi();
  }, [notCounselorsCallApi]);

  useEffect(() => {
    eventBus.on('callApi', callApi);

    //담당자 리스트
    consultingManagerCallApi();
    //처리결과 리스트
    statusCallApi();
    todayAssignCallApi();
    callApi();

    return () => eventBus.off('callApi');
  }, [
    consultingManagerCallApi,
    statusCallApi,
    todayAssignCallApi,
    callApi,
    eventBus,
  ]);

  const onParams = (p) => {
    setParams(p);
    callApi();
    notCounselorsCallApi();
  };

  const onAllCheckAction = (data) => {
    const list = data.filter((v) => {
      return v.assignUser === null && v.$$checked;
    });
    setSelectRow(list);
  };

  const onAction = (obj) => {
    const { key, event, row } = obj;
    const consulting = row;
    const model = key;
    const classList = event.target.classList;

    if (classList.contains('zmdi-check')) {
      let list = selectRow.filter((v) => {
        return consulting.id !== v.id;
      });
      setSelectRow(list);
    }
    if (classList.contains('checkbox')) {
      let list = selectRow;
      list.push(consulting);
      setSelectRow(list);
    }

    if (classList.contains('btn-change')) {
      openModalCounselorsList(consulting);
    }

    if (['연락처'].indexOf(model.title) !== -1) {
      if (consulting.customerId !== null) {
        customerInfoCallApi(consulting.customerId);
      }
      return;
    }
  };

  const openModalCounselorsList = (consulting) => {
    //담당자 리스트 팝업을 불러와서 선택
    modal
      .custom({
        component: ModalChangeCounselors,
        options: {
          consultingManager,
        },
      })
      .then((counselors) => {
        if (counselors !== undefined) {
          onClickUpdateAssignUser(consulting, counselors.user.id);
        }
      });
  };

  const onClickUpdateAssignUser = (consulting, userId) => {
    const payload = {
      // id: consulting.id,
      userName: consulting.userName,
      phoneNumber: consulting.phoneNumber,
      assignUserId: userId,
    };
    requestUpdateCallApi(consulting.id, payload);
  };

  const onClickSelectDate = (v) => {
    const now = new Date();
    if (v === 'month') {
      onChangeDate(
        new Date(now.getFullYear(), now.getMonth(), 1),
        new Date(now.getFullYear(), now.getMonth() + 1, 0)
      );
    } else if (v === 'week') {
      const nowDayOfWeek = now.getDay();
      const nowDay = now.getDate();
      const nowMonth = now.getMonth();
      let nowYear = now.getYear();
      nowYear += nowYear < 2000 ? 1900 : 0;
      const weekStartDate = new Date(nowYear, nowMonth, nowDay - nowDayOfWeek);
      const weekEndDate = new Date(
        nowYear,
        nowMonth,
        nowDay + (6 - nowDayOfWeek)
      );
      onChangeDate(weekStartDate, weekEndDate);
    }
  };

  const onChangeDate = (startDate, endDate) => {
    setStartAt(startDate);
    setEndAt(endDate);
    setSearchValue({
      ...searchValue,
      requestStartAt: moment(startDate).format('YYYY-MM-DD'),
      requestEndAt: moment(endDate).format('YYYY-MM-DD'),
    });
  };

  const onChangeStartAt = (date) => {
    const requestStartAt = moment(date).format('YYYY-MM-DD');
    setStartAt(date);
    setSearchValue({ ...searchValue, requestStartAt });
  };

  const onChangeEndAt = (date) => {
    const requestEndAt = moment(date).format('YYYY-MM-DD');
    setEndAt(date);
    setSearchValue({ ...searchValue, requestEndAt });
  };

  const onClickSearch = () => {
    let searchParam = searchValue;
    if (searchParam.requestStartAt === 'Invalid date') {
      // eslint-disable-next-line no-unused-vars
      const { requestStartAt: deletedKey, ...otherKeys } = searchParam;
      searchParam = otherKeys;
    }

    if (searchParam.requestEndAt === 'Invalid date') {
      // eslint-disable-next-line no-unused-vars
      const { requestEndAt: deletedKey, ...otherKeys } = searchParam;
      searchParam = otherKeys;
    }

    params.queryParams = {
      limit: params.queryParams.limit,
      orderBy: params.queryParams.orderBy,
      ...searchParam,
    };
    onParams(params);
    setSearchValue(searchParam);
  };

  const onClickCreateRequest = () => {
    modal
      .custom({
        component: ModalCreateConsultingRequests,
        options: {
          consultingManager,
        },
      })
      .then(() => {
        consultingManagerCallApi();
        notCounselorsCallApi();
        todayAssignCallApi();
        callApi();
      });
  };

  const onClickBulkRegistration = () => {
    modal
      .custom({
        component: ModalBulkRegistration,
        options: {
          title: 'requests',
        },
      })
      .then(() => {
        setTimeout(function () {
          //팝업 로딩 후 0.5초 뒤에 api 호출 (복제지연시간을 고려한 호출)
          consultingManagerCallApi();
          callApi();
        }, 500);
      });
  };

  const onClickAutoAssign = () => {
    modal
      .custom({
        component: ModalAutoAssign,
        // options: {
        //   consultingManager
        // },
      })
      .then(() => {
        // consultingManagerCallApi()
        // notCounselorsCallApi()
        // todayAssignCallApi()
        // callApi()
      });
  };

  const updateCounselors = (user) => {
    setLoadingBtnDisabledFlag(true);
    if (!loadingBtnDisabledFlag) {
      toast.success('담당자 배정중입니다.');
      let multiPayload = [];
      selectRow.forEach((v, i) => {
        const payload = {
          // userName: selectRow[i].userName,
          // phoneNumber: selectRow[i].phoneNumber,
          ...selectRow[i],
          assignUserId: user.id,
          assignedAt: moment().format('YYYY-MM-DDTHH:mm:ss'),
        };

        // eslint-disable-next-line no-unused-vars
        const { $$checked: deleteKey, ...otherKeys } = payload;
        multiPayload.push(otherKeys);
      });

      requestUpdateMultiCallApi({ data: multiPayload });
    }
  };

  const onClickChangeCounselors = (v) => {
    const { user } = v;
    if (!loadingBtnDisabledFlag) {
      if (selectRow.length) {
        modal
          .basic({
            body: `상담문의 ${selectRow.length}건에 ${user.name}를 배정하시겠습니까?`,
            buttons: [
              {
                text: 'CANCEL',
                class: 'btn-default',
              },
              {
                text: 'CONFIRM',
                class: 'btn-primary',
              },
            ],
          })
          .then((selectedIdx) => {
            if (selectedIdx === 1) {
              updateCounselors(user);
            }
          });
      } else {
        toast.error('담당자를 배정할 상담문의를 선택하세요.');
      }
    }
  };

  const onChangeStatus = (e) => {
    const { name, value } = e.target;
    const objectWithoutKey = (object, key) => {
      // eslint-disable-next-line no-unused-vars
      const { [key]: deletedKey, ...otherKeys } = object;
      return otherKeys;
    };

    //status = 미응대 = null
    //검색 조회 필드에서는 status=''
    if (value === '직접입력') {
      setStatusBox(true);
    } else if (value === '') {
      setSearchValue(objectWithoutKey(searchValue, 'status'));
    } else if (value === '미응대') {
      setSearchValue({ ...searchValue, [name]: '' });
    } else {
      setSearchValue({ ...searchValue, [name]: value });
    }
  };

  const onClickCheckBoxAssignUser = () => {
    const assignUserParam = hideAssignUser ? null : 0;
    params.queryParams = {
      limit: params.queryParams.limit,
      orderBy: params.queryParams.orderBy,
      ...searchValue,
      assignUserId: assignUserParam,
    };

    onParams(params);
    setSearchValue({ ...searchValue, assignUserId: assignUserParam });
    setHideAssignUser(!hideAssignUser);
  };

  return (
    <div className="list counselors">
      <div className="page-navi">
        <span className="title">상담문의 관리</span>
        <span className="title">담당자 배정</span>
      </div>
      <div className="route-top">
        <div className="search-bar">
          <div className="search-btns">
            <button
              className="btn btn-normal _small"
              onClick={() => onClickSelectDate('week')}
            >
              이번 주
            </button>
          </div>
          <div className="search-btns">
            <button
              className="btn btn-normal _small"
              onClick={() => onClickSelectDate('month')}
            >
              이번 달
            </button>
          </div>
          <div className="search-item">
            <DatePicker
              placeholderText="날짜선택"
              todayButton="오늘"
              value={startAt}
              onChange={onChangeStartAt}
              popperPlacement="bottom"
              popperModifiers={{
                flip: {
                  behavior: ['bottom'],
                },
                preventOverflow: {
                  enabled: false,
                },
                hide: {
                  enabled: false,
                },
              }}
            />
          </div>
          <div className="search-item">-</div>
          <div className="search-item">
            <DatePicker
              placeholderText="날짜선택"
              todayButton="오늘"
              value={endAt}
              onChange={onChangeEndAt}
              popperPlacement="bottom"
              popperModifiers={{
                flip: {
                  behavior: ['bottom'],
                },
                preventOverflow: {
                  enabled: false,
                },
                hide: {
                  enabled: false,
                },
              }}
            />
          </div>
          <div className="search-item">
            <select
              className="select-form"
              name="assignUserId"
              defaultValue=""
              onChange={(e) =>
                setSearchValue({
                  ...searchValue,
                  [e.target.name]: Number(e.target.value),
                })
              }
            >
              <option value="null">배정된 담당자</option>
              {consultingManager.map((v, i) => (
                <option key={i} value={v.user.id}>
                  {v.user.name}
                </option>
              ))}
            </select>
          </div>
          <div className="search-item">
            {statusBox ? (
              <Input
                name="status"
                placeholder="처리결과"
                onChange={(e) =>
                  setSearchValue({
                    ...searchValue,
                    [e.target.name]: e.target.value,
                  })
                }
              />
            ) : (
              <select
                name="status"
                className="select-form"
                defaultValue=""
                onChange={onChangeStatus}
              >
                <option value={''}>처리결과</option>
                {statusList.map((v, i) => (
                  <option key={i} value={v.name}>
                    {v.name}
                  </option>
                ))}
              </select>
            )}
          </div>
          <div className="row">
            <div className="search-item-normal">
              <Input
                placeholder="고객명"
                name="userName"
                onChange={(e) =>
                  setSearchValue({
                    ...searchValue,
                    [e.target.name]: e.target.value,
                  })
                }
                onKeyPress={(e) => (e.key === 'Enter' ? onClickSearch() : null)}
              />
            </div>
            <div className="search-item-normal">
              <Input
                placeholder="연락처"
                name="phoneNumber"
                onChange={(e) =>
                  setSearchValue({
                    ...searchValue,
                    [e.target.name]: e.target.value,
                  })
                }
                onKeyPress={(e) => (e.key === 'Enter' ? onClickSearch() : null)}
              />
            </div>
            <div className="search-btns">
              <button className="btn btn-basic _small" onClick={onClickSearch}>
                조회
              </button>
            </div>
          </div>
        </div>
        <div className="wrap_consulting_manage">
          <div className="title">
            <span className="subtitle">
              담당자 배정 필요 건{' '}
              <span className="cnt">{countNotCounselors || 0}</span>
            </span>
            <span className="subtitle">
              <span>금일 배정 건</span>
              {consultingManager.map((v, i) => {
                return (
                  <span className="m-r-0" key={i}>
                    {v.user.name}{' '}
                    <span className="cnt" key={i}>
                      {countCounselors[v.userId] || 0}
                    </span>
                  </span>
                );
              })}
            </span>
          </div>
          <div className="consulting_manage">
            {consultingManager.map((v, i) => {
              return (
                <button
                  key={i}
                  className={`btn _small m-r-8 m-b-8`}
                  disabled={`${loadingBtnDisabledFlag ? 'disabled' : ''}`}
                  onClick={() => onClickChangeCounselors(v)}
                >
                  {v.user.name} 배정
                </button>
              );
            })}
          </div>
        </div>
      </div>
      <div className="flex-row items-center flex-between flex-fill m-t-32 m-b-12">
        <div className="flex-row items-center">
          <Checkbox
            className="flex-wrap m-r-8"
            checked={hideAssignUser}
            toggleHandler={onClickCheckBoxAssignUser}
          />
          <span>미배정 건 보기</span>
        </div>
        <div className="flex-row right">
          <button
            className="btn btn-basic _small m-r-8"
            onClick={onClickCreateRequest}
          >
            상담문의 개별등록
          </button>
          <button
            className="btn btn-basic _small m-r-8"
            onClick={onClickBulkRegistration}
          >
            상담문의 대량등록
          </button>
          {showAutoAssign === 0 || showAutoAssignAlways === true ? null : (
            <button
              className="btn btn-basic _small"
              onClick={onClickAutoAssign}
            >
              자동배정하기
            </button>
          )}
        </div>
      </div>

      <DataTableAlpha
        model={models.crm.consultingCounselors}
        data={data}
        total={total}
        params={params}
        onParams={onParams}
        onAction={onAction}
        onAllCheckAction={onAllCheckAction}
      />
    </div>
  );
};

export default observer(ConsultingRequestsCounselors);
