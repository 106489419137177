import moment from 'moment';

export default {
  options: {
    hasMergeCell: true,
    groupTitles: [
      '',
      'STATUS',
      '수술간호일',
      '고객명',
      'CHART_NO',
      '_DOCTORS',
      '_NURSES',
      'SURGERY_CATEGORIES',
      'SURGERY_ITEM_NAMES',
      '메모',
    ],
    explodeColumn: 'items',
  },
  keys: [
    {
      title: 'STATUS',
      noorder: true,
      filter: () => {
        return `<div class="text-center">
                <span class="text-underline c-red">삭제</span>
            </div>`;
      },
    },
    {
      title: '수술간호일',
      column: 'scheduledAt',
      type: 'date',
      nosearch: true,
    },
    {
      title: 'START_AT',
      column: 'startAt',
      hideColumn: true,
      selectType: 'date',
      customParamFieldName: 'startAt',
      filter: (o) => {
        return `<div>
                <div>${moment(o.scheduledAt).format('YYYY-MM-DD')} ${
          o.startHour
        }</div>
            </div>`;
      },
    },
    {
      title: 'END_AT',
      column: 'endAt',
      hideColumn: true,
      selectType: 'date',
      customParamFieldName: 'endAt',
      filter: (o) => {
        return `<div>
                <div>${moment(o.scheduledAt).format('YYYY-MM-DD')} ${
          o.endHour
        }</div>
            </div>`;
      },
    },
    {
      title: '고객명',
      column: 'customer__name',
      noorder: true,
      customParamFieldName: 'name',
      filter: (o) =>
        `<span class="text-underline c-primary">${
          o.customer && o.customer.name
        }</span>`,
    },
    {
      title: 'CHART_NO',
      column: 'customer__chartNo',
      noorder: true,
      customParamFieldName: 'chartNo',
    },
    {
      title: '_DOCTORS',
      column: 'doctor__name',
      noorder: true,
      customParamFieldName: 'doctorId',
    },
    {
      title: '_NURSES',
      column: 'nurse__name',
      noorder: true,
      customParamFieldName: 'nurseId',
    },
    {
      title: 'SURGERY_CATEGORIES',
      column: 'surgery_item_category__name',
      noorder: true,
      type: 'list_mini',
      customParamFieldName: 'treatmentCategoryId',
      filter: (o) =>
        `${o.items.map((item) => `<p>${item.categoryName} </p>`).join('\n')}`,
    },
    {
      title: 'SURGERY_ITEM_NAMES',
      column: 'surgery_item_name__name',
      noorder: true,
      type: 'list',
      customParamFieldName: 'treatmentItemId',
      filter: (o) =>
        `${o.items.map((item) => `<p>${item.name}</p>`).join('\n')}`,
    },
    {
      title: '메모',
      column: 'memo',
      noorder: true,
      type: 'text',
      nosearch: true,
    },
    // {
    //     title: 'UPDATE_AT',
    //     column: 'updatedAt',
    //     filter: o => {
    //         return `<div>
    //             <div>${moment(o.updatedAt).format('YYYY-MM-DD')}</div>
    //             <div>${moment(o.updatedAt).format('HH:mm:ss')}</div>
    //         </div>`
    //     },
    //     nosearch: true,
    // }
  ],
};
