import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import PenChartsDrive from './PenChartsDrive';

const useScroll = () => {
  const [state, setState] = useState({
    scrollY: 0,
    height: 0,
  });
  const onScroll = () => {
    setState({
      scrollY: document.getElementById('scrollSampleListener').parentElement
        .scrollTop,
      height:
        document.getElementById('scrollSampleListener').parentElement
          .scrollHeight -
        document.getElementById('scrollSampleListener').parentElement
          .clientHeight,
    });
  };
  useEffect(() => {
    document
      .getElementById('scrollSampleListener')
      .parentElement.addEventListener('scroll', onScroll);
    return () =>
      document
        .getElementById('scrollSampleListener')
        .parentElement.removeEventListener('scroll', onScroll);
  }, []);
  return state;
};

const PenChartSample = () => {
  const { scrollY, height } = useScroll();
  return (
    <div className="list chart-sample" id="scrollSampleListener">
      <div className="page-navi">
        <span className="title">병원관리</span>
        <span className="title">펜차트 샘플함</span>
      </div>
      <div className="route-top">
        <p>
          해당 페이지에서 파일 또는 폴더를 추가/수정/삭제 시, 우노케어 CRM 앱의
          ‘차트 샘플함’에 동시 반영됩니다.
        </p>
      </div>
      <div className="card clear">
        <PenChartsDrive
          customer={{ id: 'default' }}
          scrollY={scrollY}
          height={height}
          isSample
        />
      </div>
    </div>
  );
};

export default observer(PenChartSample);
