import React from 'react';
import { Switch, useRouteMatch } from 'react-router-dom';

import { DefaultLayout } from 'components/layouts/DefaultLayout';

import AppointmentBoards from 'components/pages/crm/appointment-boards/AppointmentBoards';
import Appointments from 'components/pages/crm/Appointments';
import ClinicInfo from 'components/pages/crm/ClinicInfo';
import Consultings from 'components/pages/crm/Consultings';
import ConsultingRequests from 'components/pages/crm/ConsultingRequests';
import ConsultingRequestsCounselors from 'components/pages/crm/ConsultingRequestsCounselors';
import ConsultingRequestsConnects from 'components/pages/crm/ConsultingRequestsConnects';
import Customers from 'components/pages/crm/Customers';
import Home from 'components/pages/crm/home/Home';
import Payments from 'components/pages/crm/Payments';
import Treatments from 'components/pages/crm/Treatments';
import Surgeries from 'components/pages/crm/Surgeries';
import Operations from 'components/pages/crm/Operations';
import Nurses from 'components/pages/crm/Nurses';
import Skins from 'components/pages/crm/Skins';
import MessageSend from 'components/pages/crm/MessageSend';
import MessageHistories from 'components/pages/crm/MessageHistories';
import SmsSendLists from 'components/pages/crm/SmsSendLists';
import SmsUseLists from 'components/pages/crm/SmsUseLists';
import SmsPoint from 'components/pages/crm/SmsPoint';
import Organizations from 'components/pages/crm/Organizations';
import OrganizationsStaff from 'components/pages/crm/OrganizationsStaff';
import OrganizationsCreateUser from 'components/pages/crm/organizations/OrganizationsCreateUser';
import PenChartSample from 'components/pages/crm/PenChartSample';
import Statistics from 'components/pages/crm/Statistics';
import SalesStatistics from 'components/pages/crm/SalesStatistics';
import CounselorsStatistics from 'components/pages/crm/CounselorsStatistics';
import DoctorsStatistics from 'components/pages/crm/DoctorsStatistics';
import CustomersStatistics from 'components/pages/crm/CustomersStatistics';
import SurgeryStatistics from 'components/pages/crm/SurgeryStatistics';
// import RegionsStatistics from 'components/pages/crm/RegionsStatistics';
import DailySettlements from 'components/pages/crm/DailySettlements';
import MonthlySettlements from 'components/pages/crm/MonthlySettlements';
import PrescriptionIssue from 'components/pages/crm/prescription/PrescriptionIssue';
import PrescriptionList from 'components/pages/crm/prescription/PrescriptionList';
import PrescriptionStatistics from 'components/pages/crm/prescription/PrescriptionStatistics';
// CRM - Settings
import AppointmentCodes from 'components/pages/crm/codes/appointment-codes/AppointmentCodes';
import ConsultingCodes from 'components/pages/crm/codes/consulting-codes/ConsultingCodes';
import CustomerCodes from 'components/pages/crm/codes/customer-codes/CustomerCodes';
import PaymentCodes from 'components/pages/crm/codes/payment-codes/PaymentCodes';
import NurseCodes from 'components/pages/crm/codes/nurse-codes/NurseCodes';
import SmsNotificationCodes from 'components/pages/crm/codes/sms-notification-codes/SmsNotificationCodes';
import { PrivateRoute } from './PrivateRoute';

export const CrmRouter = () => {
  const { path } = useRouteMatch();

  return (
    <DefaultLayout>
      <Switch>
        {/* [ 현황판(홈) ]  */}
        <PrivateRoute path={`${path}/home`} component={Home} />
        {/* [ 예약 캘린더 ] */}
        <PrivateRoute
          path={`${path}/appointment-boards`}
          component={AppointmentBoards}
        />
        {/* [ 상담문의 관리 ] - [ 상담문의, 담당자 배정, 상담문의설정 ]  */}
        <PrivateRoute
          path={`${path}/consulting-requests`}
          component={ConsultingRequests}
        />
        <PrivateRoute
          path={`${path}/consulting-requests-counselors`}
          component={ConsultingRequestsCounselors}
        />
        <PrivateRoute
          path={`${path}/consulting-requests-connects`}
          component={ConsultingRequestsConnects}
        />
        {/* [ 문자관리 ] - [ 문자전송, 문자충전, 전송내역조회, 충전내역조회, 문자코드설정 ]  */}
        <PrivateRoute path={`${path}/message-send`} component={MessageSend} />
        <PrivateRoute
          path={`${path}/message-histories`}
          component={MessageHistories}
        />
        <PrivateRoute path={`${path}/sms-point`} component={SmsPoint} />
        <PrivateRoute
          path={`${path}/sms-send-lists`}
          component={SmsSendLists}
        />
        <PrivateRoute path={`${path}/sms-use-lists`} component={SmsUseLists} />
        {/* [ 조회 ] - [ 고객조회, 예약조회, 상담조회, 진료조회, 수납조회, 시/수술조회 ] */}
        <PrivateRoute path={`${path}/customers`} exact component={Customers} />
        <PrivateRoute path={`${path}/appointments`} component={Appointments} />
        <PrivateRoute path={`${path}/consultings`} component={Consultings} />
        <PrivateRoute path={`${path}/treatments`} component={Treatments} />
        <PrivateRoute path={`${path}/payments`} component={Payments} />
        <PrivateRoute path={`${path}/surgeries`} component={Surgeries} />
        <PrivateRoute path={`${path}/operations`} component={Operations} />
        <PrivateRoute path={`${path}/nurses`} component={Nurses} />
        <PrivateRoute path={`${path}/skins`} component={Skins} />
        {/* [ 결산 ]  */}
        <PrivateRoute
          path={`${path}/daily-settlements`}
          component={DailySettlements}
        />
        <PrivateRoute
          path={`${path}/monthly-settlements`}
          component={MonthlySettlements}
        />
        {/* [ 통계 ]  */}
        <PrivateRoute path={`${path}/statistics`} component={Statistics} />
        <PrivateRoute
          path={`${path}/sales-statistics`}
          component={SalesStatistics}
        />
        <PrivateRoute
          path={`${path}/counselors-statistics`}
          component={CounselorsStatistics}
        />
        <PrivateRoute
          path={`${path}/doctors-statistics`}
          component={DoctorsStatistics}
        />
        <PrivateRoute
          path={`${path}/customers-statistics`}
          component={CustomersStatistics}
        />
        <PrivateRoute
          path={`${path}/surgery-statistics`}
          component={SurgeryStatistics}
        />
        {/* <PrivateRoute
          path={`${path}/regions-statistics`}
          component={RegionsStatistics}
        /> */}
        {/* [ 처방전 ] */}
        <PrivateRoute
          exact
          path={`${path}/prescription-issue`}
          component={PrescriptionIssue}
        />
        <PrivateRoute
          exact
          path={`${path}/prescription-issue/:id`}
          component={PrescriptionIssue}
        />
        <PrivateRoute
          path={`${path}/prescription-list`}
          component={PrescriptionList}
        />
        <PrivateRoute
          path={`${path}/prescription-statistics`}
          component={PrescriptionStatistics}
        />
        {/* [ 환경설정 ] - [ 고객코드, 예약코드, 상담/진료코드, 수납코드, 문자코드, 계정코드 ] */}
        <PrivateRoute
          path={`${path}/customer-codes`}
          component={CustomerCodes}
        />
        <PrivateRoute
          path={`${path}/appointment-codes`}
          component={AppointmentCodes}
        />
        <PrivateRoute
          path={`${path}/consulting-codes`}
          component={ConsultingCodes}
        />
        <PrivateRoute path={`${path}/payment-codes`} component={PaymentCodes} />
        <PrivateRoute path={`${path}/nurse-codes`} component={NurseCodes} />
        <PrivateRoute
          path={`${path}/sms-notification-codes`}
          component={SmsNotificationCodes}
        />
        {/* [ 병원관리 ] - [ 조직관리, 직원관리, 병원정보 ] */}
        <PrivateRoute
          path={`${path}/organizations`}
          component={Organizations}
        />
        <PrivateRoute
          path={`${path}/organizations-staff`}
          component={OrganizationsStaff}
        />
        <PrivateRoute
          exact
          path={`${path}/organizations-users`}
          component={OrganizationsCreateUser}
        />
        <PrivateRoute
          exact
          path={`${path}/organizations-users/:id`}
          component={OrganizationsCreateUser}
        />
        <PrivateRoute
          path={`${path}/chart-sample`}
          component={PenChartSample}
        />
        <PrivateRoute path={`${path}/clinic-info`} component={ClinicInfo} />
        {/* <PrivateRoute component={NotFound} /> */}
      </Switch>
    </DefaultLayout>
  );
};
