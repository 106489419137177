import React from 'react';
import { observer } from 'mobx-react';
import './ctiRingings.scss';
import useCtiStore from './hooks/useCtiStore';
import CtiRingingPopup from './CtiRingingPopup';
import classNames from 'classnames';
import { CallStatus } from './callStatus';

export default observer(function CtiRingings() {
  const ctiStore = useCtiStore();

  function clear() {
    ctiStore.clearRingings();
  }

  function collapse() {
    ctiStore.setRingingsCollapsed(true);
  }

  function expand() {
    ctiStore.setRingingsCollapsed(false);
  }

  return (
    <div
      className={classNames('cti-ringings', ctiStore.settings.historyLocation, {
        statusBar: ctiStore.showStatusBar,
        history: ctiStore.showHistory,
      })}
    >
      <div className="ringings-list">
        <div className="inner">
          {ctiStore.ringingsCollapsed &&
            ctiStore.ringings
              .slice(1, 3)
              .map((r, i) => ({ ringing: r, index: i }))
              .reverse()
              .map(({ ringing, index }) => (
                <div
                  key={ringing.id}
                  className={classNames(
                    `collapsed`,
                    `collapsed-${index}`,
                    CallStatus[ringing.callStatus]
                  )}
                />
              ))}
          {ctiStore.ringingsCollapsed && ctiStore.ringings.length > 0 && (
            <CtiRingingPopup
              key={ctiStore.ringings[0].id}
              call={ctiStore.ringings[0]}
              index={0}
            />
          )}
          {!ctiStore.ringingsCollapsed &&
            ctiStore.ringings.map((c, i) => (
              <CtiRingingPopup key={c.id} call={c} index={i} />
            ))}
        </div>
      </div>
      {ctiStore.ringings.length > 1 && (
        <div className="actions">
          {!ctiStore.ringingsCollapsed && (
            <button className="collapse-btn" onClick={collapse}>
              접기
            </button>
          )}
          {ctiStore.ringingsCollapsed && (
            <button className="expand-btn" onClick={expand}>
              {ctiStore.ringings.length}건 보기
            </button>
          )}
          <button className="close-btn" onClick={clear}>
            모두 닫기
          </button>
        </div>
      )}
    </div>
  );
});
