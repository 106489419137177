import moment from 'moment';

export default {
  keys: [
    {
      title: '일자',
      columnGroup: false,
      key: 'scheduledAt',
      column: 'scheduledAt',
      // TODO: 추후 정렬 추가 작업시 orderKey, order 주석 해제
      // orderKey: 'scheduledAt',
      // order: 'desc',
      filter: (o) => {
        if (o !== undefined) {
          if (o.scheduledAt === 'total') {
            return 'total';
          } else {
            return `<div><span>${moment(o.scheduledAt).format(
              'YYYY-MM-DD'
            )}</span></div>`;
          }
        }
      },
    },
    {
      title: 'SMS',
      columnGroup: true,
      columnItems: [
        { title: '성공', key: 'smsSuccessCount' },
        { title: '실패', key: 'smsFailCount' },
        { title: '소진액', key: 'smsAmount', filter: { type: 'amount' } },
      ],
    },
    {
      title: 'LMS',
      columnGroup: true,
      columnItems: [
        { title: '성공', key: 'lmsSuccessCount' },
        { title: '실패', key: 'lmsFailCount' },
        { title: '소진액', key: 'lmsAmount', filter: { type: 'amount' } },
      ],
    },
    {
      title: 'MMS',
      columnGroup: true,
      columnItems: [
        { title: '성공', key: 'mmsSuccessCount' },
        { title: '실패', key: 'mmsFailCount' },
        { title: '소진액', key: 'mmsAmount', filter: { type: 'amount' } },
      ],
    },
  ],
};
