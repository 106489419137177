import React from 'react';
import styled, { css } from 'styled-components';
import { banks } from 'hooks/constants';
import { toThousandCommas } from 'filters';
import PropTypes from 'prop-types';

const FormWrapper = styled.div`
  width: 350px;
`;

const TitleText = styled.div`
  font-size: 16px;
  margin-bottom: 20px;
  font-weight: 400;
  line-hieght: 26px;
`;

const Title = styled.div`
  font-size: 18px;
  font-weight: 700;
  display: flex;
  gap: 5px;
  margin-bottom: 5px;
`;

const SubTitle = styled.div`
  font-size: 14px;
  font-weight: 700;
  margin: 20px 0 10px 0;
`;

const Input = styled.input`
  padding: 9px 20px 8px;
  font-size: 14px;
  background-color: transparent;
  width: 100%;
  height: 34px;
  border-radius: 4px;
  border: 1px solid #dee2ec;

  ${({ disabled }) =>
    disabled &&
    css`
      background-color: #efeff4 !important;
      color: #ceced7;
    `}
`;

export const BalanceRequestView = ({
  data,
  clinic,
  cancelSchedule,
  onClickDepoistCancel,
}) => {
  const { name: clinicName, employerNo, ownerName } = clinic;

  return (
    <FormWrapper>
      <TitleText>
        <p>충전금 신청이 완료 되었습니다.</p>
        <p>입금이 완료되면 충전금이 충전 됩니다.</p>
        <p>{cancelSchedule} 까지 충전하지 않으면 </p>
        <p>충전 신청이 취소됩니다.</p>
      </TitleText>
      <div>
        <SubTitle>
          <p>충전금 신청 내역</p>
        </SubTitle>
        <div className="point-refund">
          <Title>
            <span>충전 금액</span>
            <span>{toThousandCommas(data.amount)}원</span>
          </Title>
          <Title>
            <span>입금할 금액(VAT 포함)</span>
            <span>{toThousandCommas(Math.round(data.amount * 1.1))}원</span>
          </Title>
          <Title>
            <p>
              {banks.find((bank) => bank.value === data.bankCd).key}{' '}
              {data.acctNo} {data.acctNm}
            </p>
          </Title>
          <div>
            <SubTitle>결제정보 확인</SubTitle>
            <div className="form-control">
              <label>상호(병원명)</label>
              <Input disabled={true} defaultValue={clinicName} />
            </div>
            <div className="form-control">
              <label>사업자등록번호</label>
              <Input disabled={true} defaultValue={employerNo} />
            </div>
            <div className="form-control">
              <label>대표자</label>
              <Input disabled={true} defaultValue={ownerName} />
            </div>
          </div>
        </div>
      </div>
      <button className="btn btn-basic _small" onClick={onClickDepoistCancel}>
        충전 신청 취소
      </button>
    </FormWrapper>
  );
};

BalanceRequestView.propTypes = {
  data: PropTypes.any,
  clinic: PropTypes.any,
  cancelSchedule: PropTypes.any,
  onClickDepoistCancel: PropTypes.any,
};
