import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import useCtiStore from './hooks/useCtiStore';
import './ctiGuideTip.scss';

export default observer(function CtiGuideTip({
  id,
  text,
  children,
  offset,
  show,
  direction,
}) {
  const ctiStore = useCtiStore();
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (ctiStore.canDisplayGuide(id)) {
      ctiStore.setGuideDisplayed(id);
      setVisible(true);
    }
  }, []);

  function onClose() {
    ctiStore.incrementGuide(id);
    setVisible(false);
  }

  const yTransform = direction === 'bottom' ? `100%` : '-100%';
  const arrowYTransform = direction === 'bottom' ? `-50%` : '50%';

  return (
    <div className="cti-guide-holder">
      {children}
      {show && visible && (
        <div
          className={`cti-guide-tip ${direction}`}
          style={{
            transform: `translate(calc(-50% + ${
              offset ?? 0
            }px), ${yTransform})`,
          }}
        >
          <div className="cti-guide-content">
            {text}
            <button
              className="cti-guide-close-button"
              onClick={onClose}
            ></button>
          </div>
          <div
            className={`cti-guide-arrow ${direction}`}
            style={{
              transform: `translate(calc(-50% + ${-(
                offset ?? 0
              )}px), ${arrowYTransform}) rotate(45deg)`,
            }}
          ></div>
        </div>
      )}
    </div>
  );
});
