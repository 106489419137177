import React, { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import Checkbox from 'components/app/Checkbox';
import DataTableAlpha from 'components/data-table/DataTableAlpha';
import ModalDepartmentCategory from 'components/modals/ModalDepartmentCategory';
import models from 'models';
import { translate } from 'filters';
import { $qb } from 'scripts/querybuilder';
import { useEvent } from 'hooks/useEvent';
import { useModal } from 'hooks/useModal';
import { useServices } from 'hooks/useServices';
import { useToast } from 'hooks/useToast';
import { useData } from 'hooks/useData';

const DepartmentCategories = () => {
  const eventBus = useEvent();
  const modal = useModal();
  const services = useServices();
  const toast = useToast();
  const storeData = useData();
  const defaultParams = $qb().limit(20).orderBy('id desc');
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [params, setParams] = useState(defaultParams);
  const [hideHidden, setHideHidden] = useState(false);

  const callApi = useCallback(async () => {
    const resp = await storeData.actions.loadDepartmentCategories();
    if (!resp) return;
    resp.data.map((v, i) => {
      v.index = i;
      return v;
    });

    setData(resp.data);
    setTotal(resp.total);
  }, [storeData.actions]);

  const onParams = (p) => {
    setParams(p);
    callApi();
  };

  const openModalConfirmDelete = (payload, key) => {
    modal
      .basic({
        body: `정말로 삭제하시겠습니까?`,
        buttons: [
          {
            text: 'CANCEL',
            class: 'btn-default',
          },
          {
            text: 'CONFIRM',
            class: 'btn-primary',
          },
        ],
      })
      .then((idx) => {
        if (idx === 1) {
          if (key === 'ACTION') {
            //부서 삭제
            onDeleteDepartments(payload, 'departmentCategory');
          } else if (key === 'DEPARTMENT') {
            //세부 부서 삭제
            onDeleteDepartments(payload, 'department');
          }
        }
      });
  };
  const onAction = (obj) => {
    const { row, key, event } = obj;

    if (key['title'] === 'DEPARTMENT') {
      let classList = event.target.className.split(' ');
      let indexClass = classList.find((className) =>
        className.startsWith('index')
      );
      if (indexClass) {
        let idx = indexClass.split('-')[1];
        return toggleVisibility(row, idx);
      }
    }

    if (key['title'] === 'ACTION' && event.target.dataset.type === 'visible') {
      toggleVisibility(row);
      return;
    }

    if (key['title'] === 'ACTION' && event.target.dataset.type === 'edit') {
      if (event.target.innerText === '수정') {
        openModalDepartmentCategory(row);
        return;
      }
    }

    if (event.target.classList.contains('zmdi-long-arrow-up')) {
      changeArrowUpDown('up', key.title, row);
    } else if (event.target.classList.contains('zmdi-long-arrow-down')) {
      changeArrowUpDown('down', key.title, row);
    }

    //부서 삭제
    if (key['title'] === 'ACTION' && event.target.dataset.type === 'delete') {
      openModalConfirmDelete(row, key['title']);
      return;
    }

    // 세부 부서 삭제
    if (
      key['title'] === 'DEPARTMENT' &&
      event.target.dataset.type === 'delete'
    ) {
      openModalConfirmDelete(row['@@departments'], key['title']);
      return;
    }
  };

  const onDragAction = (obj) => {
    const { rowIndex, dragRowIndex, type, categoryIndex = '' } = obj;
    if (type === 'category') {
      let categories = [...data];
      let from = Number(dragRowIndex);
      let to = Number(rowIndex);
      if (from < to) to--;
      categories.splice(to, 0, categories.splice(from, 1)[0]);

      let payload = categories.map((v, i) => {
        v['order'] = i + 1;
        return v;
      });
      onSaveDepartmentCategories(payload);
    } else {
      let departments = data[categoryIndex].departments;
      let from = Number(dragRowIndex);
      let to = Number(rowIndex);
      if (from < to) to--;
      departments.splice(to, 0, departments.splice(from, 1)[0]);

      let department = departments.map((v, i) => {
        v['order'] = i + 1;
        return v;
      });

      let payload = { ...data[categoryIndex], departments: department };
      onSaveDepartments(payload);
    }
  };

  const onDeleteDepartments = useCallback(
    async (payload, endpoint) => {
      try {
        await services.crm.crud[endpoint].delete(payload.id);
        modal
          .confirm({
            type: 'SUCCESS',
            msg: '삭제되었습니다.',
          })
          .then(() => {
            callApi();
          });
      } catch (e) {
        modal.confirm({
          type: 'ERROR',
          msg: e.description,
        });
      }
    },
    [modal, services]
  );

  const onSaveDepartments = useCallback(
    async (payload) => {
      try {
        await services.crm.crud.departmentCategory.update(payload);
        modal
          .confirm({
            type: 'SUCCESS',
            msg: '업데이트되었습니다.',
          })
          .then(() => {
            callApi();
          });
      } catch (e) {
        modal.confirm({
          type: 'ERROR',
          msg: e.description,
        });
      }
    },
    [modal, services]
  );

  const onSaveDepartmentCategories = useCallback(
    async (payload) => {
      try {
        await services.crm.crud.departmentCategory.multi_update({
          data: payload,
        });
        modal
          .confirm({
            type: 'SUCCESS',
            msg: '업데이트되었습니다.',
          })
          .then(() => {
            callApi();
          });
      } catch (e) {
        modal.confirm({
          type: 'ERROR',
          msg: e.description,
        });
      }
    },
    [modal, services]
  );

  const changeOrderDepartments = useCallback(
    async (change, id, department) => {
      let swapUpIdx = 0;
      let swapUpData = department.filter((item, i) => {
        if (item.id === id) {
          swapUpIdx = i;
        }
        return item.id === id;
      })[0];

      let swapDownIdx = 0;
      if (change === 'up') {
        swapDownIdx = swapUpIdx - 1;
        if (
          swapDownIdx === -1 ||
          //visibe인 리스트 중, 내 부서 id를 찾아, 해당 인덱스가 0이면 가장 상위
          department.filter((v) => v.visible).findIndex((v) => v.id === id) ===
            0
          //|| department.length-department.filter(v=> !v.visible).length === 1
        ) {
          toast.error('가장 상위 순서입니다.');
          return;
        }
      } else if (change === 'down') {
        swapDownIdx = swapUpIdx + 1;
        if (
          department.length - department.filter((v) => !v.visible).length ===
            1 ||
          swapDownIdx === department.length
        ) {
          toast.error('가장 하위 순서입니다.');
          return;
        }
      }

      const getSwapData = (swapDownIdx) => {
        let idx = change === 'up' ? swapDownIdx - 1 : swapDownIdx + 1;
        if (swapDownIdx === 0) {
          toast.error('가장 상위 순서입니다.');
          return;
        }
        if (swapDownIdx === department.length - 1) {
          toast.error('가장 하위 순서입니다.');
          return;
        }

        if (department[idx].visible) {
          return { idx, data: department[idx] };
        } else {
          return getSwapData(idx);
        }
      };

      let swapDownData = department[swapDownIdx];
      let item;
      if (!department[swapDownIdx].visible) {
        item = getSwapData(swapDownIdx);
        if (item !== undefined) {
          swapDownData = item.data;
          swapDownIdx = item.idx;
        }
      } else {
        item = { idx: swapDownIdx, data: department[swapDownIdx] };
      }

      return { item, swapUpData, swapDownData, swapUpIdx, swapDownIdx };
    },
    [toast]
  );

  const changeDepartments = useCallback(
    async (change, row) => {
      let departments = row.departments;
      const { id } = row['@@departments'];

      const changeOrder = await changeOrderDepartments(change, id, departments);
      if (changeOrder !== undefined) {
        departments[changeOrder.swapDownIdx] = changeOrder.swapUpData;
        departments[changeOrder.swapUpIdx] = changeOrder.swapDownData;
        let department = departments.map((v, i) => {
          v['order'] = i + 1;
          return v;
        });

        let payload = { ...row, departments: department };
        onSaveDepartments(payload);
      }
    },
    [changeOrderDepartments, onSaveDepartments]
  );

  const changeDepartmentCategories = useCallback(
    async (change, row) => {
      let categories = [...data];
      const { id } = row;
      //현재 인덱스를 가진 row와
      //바로 위 인덱스를 가진 row를 찾아
      //순서를 바꾸고
      //바꾼 순서대로 order를 다시 붙여준다

      //비활성화 된 항목을 제외하고 순서가 변경됨
      const changeOrder = await changeOrderDepartments(change, id, categories);
      if (changeOrder !== undefined) {
        categories[changeOrder.swapDownIdx] = changeOrder.swapUpData;
        categories[changeOrder.swapUpIdx] = changeOrder.swapDownData;
        let payload = categories.map((v, i) => {
          v['order'] = i + 1;
          return v;
        });
        onSaveDepartmentCategories(payload);
      }
    },
    [data, changeOrderDepartments, onSaveDepartmentCategories]
  );

  const changeArrowUpDown = useCallback(
    async (change, title, row) => {
      if (title === 'DEPARTMENT_CATEGORY') {
        //부서 순서 이동
        changeDepartmentCategories(change, row);
      } else {
        //세부부서 순서 이동
        changeDepartments(change, row);
      }
    },
    [changeDepartmentCategories, changeDepartments]
  );

  useEffect(() => {
    eventBus.on('callApi', callApi);
    callApi();

    return () => eventBus.off('callApi');
  }, [callApi, eventBus]);

  const openModalDepartmentCategory = (departmentCategory) => {
    modal.custom({
      component: ModalDepartmentCategory,
      options: {
        departmentCategory,
        categoriesOrder: data.length,
      },
    });
  };

  // 여기만 좀 특이해서 helpers.toggleVisiblity 안쓰고 따로 정의
  const toggleVisibility = (departmentCategory, idx) => {
    let target =
      idx >= 0 ? departmentCategory.departments[idx] : departmentCategory;
    const onConfirm = async () => {
      target.visible = !target.visible;

      try {
        await services.crm.crud.departmentCategory.update(departmentCategory);
        let message = translate('VISIBILITY_TOGGLED').replace(
          /%s/,
          translate(target.visible ? 'UNHIDE' : 'HIDE')
        );
        modal
          .confirm({
            type: 'SUCCESS',
            msg: message,
          })
          .then(() => {
            callApi();
          });
      } catch (e) {
        modal.confirm({
          type: 'ERROR',
          msg: e.description,
        });
      }
    };

    modal
      .basic({
        body: translate(target.visible ? 'CONFIRM_HIDE' : 'CONFIRM_SHOW'),
        buttons: [
          {
            text: 'CANCEL',
            class: 'btn-default',
          },
          {
            text: 'CONFIRM',
            class: 'btn-primary',
          },
        ],
      })
      .then((idx) => {
        if (idx === 1) {
          onConfirm();
        }
      });
  };

  return (
    <>
      <div className="page-navi">
        <span className="title">환경설정</span>
        <span className="title">예약캘린더 설정</span>
      </div>
      <div className="department-categories">
        <div className="route-top">
          <div className="title">{translate('DEPARTMENT_CATEGORIES')}</div>
          <div className="flex-row items-center flex-between">
            <div className="flex-row items-center m-r-12">
              <Checkbox
                className="flex-wrap m-r-8"
                checked={hideHidden}
                toggleHandler={() => setHideHidden(!hideHidden)}
              />
              <span>미사용항목 보지않기</span>
            </div>
            <button
              onClick={() => openModalDepartmentCategory()}
              className="btn btn-primary btn-sm flex-wrap btn-add"
            >
              {translate('ADD')}
            </button>
          </div>
        </div>
        <DataTableAlpha
          model={models.crm.departmentCategory}
          data={
            hideHidden
              ? data.filter((departmentCategory) => departmentCategory.visible)
              : data
          }
          total={total}
          params={params}
          onParams={onParams}
          onAction={onAction}
          dragTypes={'Department'}
          onDragAction={onDragAction}
          hideBottom={true}
          hasLine
          hasDeactivate
          mergeColumn={[{ name: null }]}
        />
      </div>
    </>
  );
};

export default observer(DepartmentCategories);
