import React, { useState, useEffect, useCallback } from 'react';
import { observer } from 'mobx-react';
import DataTableAlpha from 'components/data-table/DataTableAlpha';
import PropTypes from 'prop-types';
import models from 'models';
import { useServices } from 'hooks/useServices';

const TabRequestList = ({ data, close }) => {
  const services = useServices();
  const [list, setList] = useState(null);

  const callApi = useCallback(async () => {
    //동일한 연락처로 미응대중인 건을 제외한 문의내역 >> 동일 연락처에 대해 하나의 children으로 묶인다
    ///parentId + ?parentId 리스트를 합쳐서 보여주기
    //>미응대건을 제외한 모든 문의내역이 보여져야 하므로, 부모 데이터까지 보여지도록
    const param = { parentId: data.id };
    const resp = await services.crm.consultingRequest.requests.all(param);
    if (!resp) return;
    const parentResp = await services.crm.consultingRequest.requests.detail(
      data.id
    );
    if (!parentResp) return;

    let filterList = [];
    parentResp.data.status !== null && filterList.push(parentResp.data);
    const notUnResponsiveStatus = resp.data.filter((v) => {
      return v.status !== null;
    });
    if (notUnResponsiveStatus.length) {
      filterList = [...filterList, ...notUnResponsiveStatus];
    }

    setList(filterList);
  }, [services.crm.consultingRequest.requests, data.id]);

  useEffect(() => {
    if (data.id) {
      callApi();
    }
  }, [callApi, data.id]);

  return (
    <div className="consulting-request m-t-16">
      <DataTableAlpha
        model={models.crm.responseRequests}
        data={list}
        hideBottom={true}
      />
      <div className="buttons">
        <div className="flex-row">
          <button className="btn-primary" onClick={() => close(0)}>
            닫기
          </button>
          <button className="btn-primary">저장</button>
        </div>
      </div>
    </div>
  );
};

TabRequestList.propTypes = {
  data: PropTypes.object,
  close: PropTypes.func,
};

export default observer(TabRequestList);
