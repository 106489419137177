import React, { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import DataTableAlpha from 'components/data-table/DataTableAlpha';
import helpers from 'hooks/helpers';
import models from 'models';
import { translate } from 'filters';
import { $qb } from 'scripts/querybuilder';
import { useModal } from 'hooks/useModal';
import { useServices } from 'hooks/useServices';
import { useToast } from 'hooks/useToast';
import PropTypes from 'prop-types';

const ModalMessageBoilerplateSelector = ({ close }) => {
  const modal = useModal();
  const services = useServices();
  const toast = useToast();
  const defaultParams = $qb().limit().orderBy();
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(null);
  const [params, setParams] = useState(defaultParams);
  const [hideHidden] = useState(false);
  const [loading, setLoading] = useState(true);
  const onParams = (p) => {
    setParams(p);
  };

  const callApi = useCallback(async () => {
    try {
      setLoading(true);
      const resp = await services.crm.notification.boilerplateMessages.all();
      setData(resp.data);
      setTotal(resp.total);
      setLoading(false);
    } catch (error) {
      toast.error(error.description);
      setLoading(false);
    }
  }, [services.crm.notification.boilerplateMessages, toast]);

  useEffect(() => {
    callApi();
  }, [callApi]);

  const onAction = (obj) => {
    const { row, key, event } = obj; // , rowIdx
    if (event.target.dataset.type === 'visible') {
      const toggleObj = {
        visible: !row['visible'],
        id: row['id'],
      };
      const endpoint = services.crm.notification.boilerplateMessage;
      return helpers.toggleVisibilityById({
        obj: toggleObj,
        endpoint,
        callback: callApi,
      });
    }

    if (event.target.dataset.type === 'select') {
      const payload = {
        title: row.title ? row.title : '',
        contents: row.contents ? row.contents : '',
        images: row.images.length > 0 ? row.images : [],
        imageFiles: [],
      };
      close({ payload });
    }
    if (key['type'] === 'image' && (row.images || []).length > 0) {
      modal.images({
        images: (row.images || []).map((image) => image.url),
      });
      return;
    }
  };

  return (
    <div className="modal-message-boilerplate-selector message-boilerplate-selector ">
      <div className="head flex-row flex-between items-start">
        <div className="title">{translate('SELECT_BOILERPLATE_MESSAGE')}</div>
        <i onClick={() => close()} className="zmdi zmdi-close" />
      </div>
      <div className="body">
        <DataTableAlpha
          className="header-style-classic"
          model={models.crm.messageBoilerplateSelector}
          data={hideHidden ? data.filter((row) => row.visible) : data}
          total={total}
          params={params}
          onParams={onParams}
          onAction={onAction}
          loading={loading}
          hideBottom={true}
          hideHidden={hideHidden}
        />
      </div>
      <div className="buttons">
        <div className="flex-row">
          <button onClick={close} className="btn btn-default">
            {translate('CLOSE')}
          </button>
        </div>
      </div>
    </div>
  );
};

ModalMessageBoilerplateSelector.propTypes = {
  close: PropTypes.func,
};

export default observer(ModalMessageBoilerplateSelector);
