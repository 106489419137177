import React, { useEffect, useState, useCallback, useRef } from 'react';
import { observer } from 'mobx-react';
import ConditionRowList from './ConditionRowList';
import PropTypes from 'prop-types';

const ConditionGroup = ({
  onCreateGroup,
  onRemoveGroup,
  groupId,
  groupIdx,
  onChangeGroup,
  ...props
}) => {
  const conditionRef = useRef(0);
  const initCondition = {
    column: null,
    category: null,
    query: null,
    include: true,
    uiKeyName: null,
    conditionId: conditionRef.current++,
  };
  const initConditions = props.conditions
    ? props.conditions.map((condition) => {
        const nextCondition = {
          ...initCondition,
          ...condition,
          conditionId: condition.conditionId
            ? condition.conditionId
            : conditionRef.current++,
        };
        return nextCondition;
      })
    : [initCondition];

  const [conditionGroup, setConditionGroup] = useState({
    groupId: groupId,
    conditions: initConditions,
  });

  useEffect(() => {
    onChangeGroup(conditionGroup);
  }, [onChangeGroup, conditionGroup]);

  const [selectedConditions, setSelectedConditions] = useState([]);
  useEffect(() => {
    const conditions = conditionGroup.conditions;
    const selected = conditions
      .filter((c) => c.uiKeyName !== null)
      .map((c) => c.uiKeyName);
    setSelectedConditions(selected);
  }, [conditionGroup.conditions]);

  const onCreateCondition = useCallback(() => {
    setConditionGroup((conditionGroup) => ({
      ...conditionGroup,
      conditions: conditionGroup.conditions.concat({
        ...initCondition,
        uiKeyName: null,
        value: null,
        conditionId: conditionRef.current++,
      }),
    }));
  }, [initCondition]);

  const onChangeCondition = useCallback((payload) => {
    const { conditionId } = payload;
    setConditionGroup((conditionGroup) => ({
      ...conditionGroup,
      conditions: conditionGroup.conditions.map((condition) => {
        if (condition.conditionId === conditionId) {
          return payload;
        }
        return condition;
      }),
    }));
  }, []);

  const onRemoveCondition = useCallback((payload) => {
    const { conditionId } = payload;
    setConditionGroup((conditionGroup) => ({
      ...conditionGroup,
      conditions: conditionGroup.conditions.filter((condition) => {
        return condition.conditionId !== conditionId;
      }),
    }));
  }, []);

  return (
    <div className="condition-group">
      {/* <div className="condition-row condition-group-controller" >
        <button className="btn btn-basic _small" onClick={onCreateCondition}>{translate("ADD_CONDITION")}</button>
        <button className="btn btn-del _small"
            // disabled={groupIdx===0} 
            onClick={(e)=>onRemoveGroup({groupId})}>{translate("REMOVE_CONDITION_GROUP")}</button>
      </div> */}
      <ConditionRowList
        conditions={conditionGroup.conditions}
        onCreateGroup={onCreateGroup}
        onRemoveGroup={onRemoveGroup}
        onCreateCondition={onCreateCondition}
        onChangeCondition={onChangeCondition}
        onRemoveCondition={onRemoveCondition}
        selectedConditions={selectedConditions}
        groupIdx={groupIdx}
        groupId={groupId}
      />
    </div>
  );
};

ConditionGroup.propTypes = {
  onCreateGroup: PropTypes.func,
  onRemoveGroup: PropTypes.func,
  groupId: PropTypes.number,
  groupIdx: PropTypes.number,
  onChangeGroup: PropTypes.func,
  conditions: PropTypes.array,
};

export default observer(ConditionGroup);
