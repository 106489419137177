import { formatCallerNumber, translate } from 'filters';

export default {
  keys: [
    {
      title: 'ACTION',
      noorder: true,
      class: 'action column-width-px-180',
      filter: (o) => {
        return `
            ${
              o['visible']
                ? `
              <button class="btn btn-sm btn-success m-r-4"  data-type="edit" >${translate(
                'EDIT'
              )}</button>
              <button class="btn btn-sm btn-black"  data-type="visible" >${translate(
                'TOGGLE_DEACTIVATE'
              )}</button>
            `
                : ''
            }
            ${
              !o['visible']
                ? `<button class="btn btn-sm btn-gray" data-type="visible" >${translate(
                    'TOGGLE_ACTIVATE'
                  )}</button>`
                : ''
            }
        `;
      },
    },
    {
      title: '시/수술명',
      column: 'treatmentItemName',
      noorder: true,
    },
    {
      title: 'CALLER_NUMBERS',
      noorder: true,
      class: 'column-width-px-120',
      filter: (o) => {
        return formatCallerNumber(o['callerNumber']['number']);
      },
    },
    {
      title: '문자전송주기\n(마지막 시수술일 기준)',
      noorder: true,
      titleStyle: () => {
        return { whiteSpace: 'break-spaces' };
      },
      thStyle: () => {
        return { width: '140px' };
      },
      filter: (o) => {
        let cycle = '';
        if (o.reserveCount === 1) {
          cycle = ' 후';
        } else if (o.reserveCount > 1) {
          cycle = ' 마다';
        }

        return `<div style="text-align: center;"><div>${
          o.scheduleDaysDelta
        }일 ${cycle}</div>
          ${o.reserveCount > 1 ? `<div>${o.reserveCount}회까지</div>` : ''} `;
      },
    },
    {
      title: 'TITLE_AND_CONTENT',
      noorder: true,
      type: 'text',
      class: 'title-and-content column-width-px-400',
      filter: (o) => {
        if (o.title === '') {
          return `<div class="contents">
                   <div class="label">${translate('CONTENTS')}</div>
                   <div class="text">${o.contents || ''}</div>
                 </div>`;
        }

        return `<div class="title">
                   <div class="label">${translate('TITLE')}</div>
                   <div class="text">${o.title || ''}</div>
               </div>
               <div class="contents">
                   <div class="label">${translate('CONTENTS')}</div>
                   <div class="text">${o.contents || ''}</div>
               </div>`;
      },
    },
    {
      title: 'IMAGE',
      noorder: true,
      type: 'image',
      class: 'image-preview column-width-px-200',
      filter: (o) => {
        if ((o.images || []).length === 0) return;
        return o.images[0].url;
      },
    },
  ],
};
