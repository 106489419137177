import { translate } from 'filters';

export default {
  keys: [
    {
      title: 'ACTION',
      filter: () => {
        return `<button class="btn btn-sm btn-success m-l-4"  data-type="select" >${translate(
          'SELECT'
        )}</button>`;
      },
      class: 'action column-width-px-100',
    },
    {
      title: 'CATEGORY',
      column: 'category',
      class: 'category  column-width-px-200',
    },
    {
      title: 'TITLE_AND_CONTENT',
      filter: (o) => {
        if (o.title === '') {
          return `<div class="contents">
                   <div class="label">${translate('CONTENTS')}</div>
                   <div class="text">${o.contents || ''}</div>
                 </div>`;
        }

        return `<div class="title">
                   <div class="label">${translate('TITLE')}</div>
                   <div class="text">${o.title || ''}</div>
               </div>
               <div class="contents">
                   <div class="label">${translate('CONTENTS')}</div>
                   <div class="text">${o.contents || ''}</div>
               </div>`;
      },
      type: 'text',
      class: 'title-and-content column-width-px-400',
    },
    {
      title: 'IMAGE',
      filter: (o) => {
        if ((o.images || []).length === 0) return;
        return o.images[0].url;
      },
      type: 'image',
      class: 'image-preview column-width-px-200',
    },
  ],
};
