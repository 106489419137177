import './quill.css';
import React, { useMemo, useRef, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import modalMemoBoilerplate from 'hooks/modalMemoBoilerplate';
import PropTypes from 'prop-types';

// 고객차트 내 메모 상용구 기능이 추가되면서, defaultValue -> value로 변경
// - 기존 onChange 내 toHexColorHtml으로 인해서도 포커스 버그가 생겨서
//   최종 저장시에 toHexColorHtml 사용 (시간상 추후 체크)

export default function QuillTextField({
  tabName,
  value,
  setValue,
  setMemoBoilerplateList,
  ...props
}) {
  let quillRef = useRef(null);
  function onChange(v) {
    setValue(v);
  }

  useEffect(() => {
    if (tabName === 'appointment') {
      //예약탭의 메모에서는 기본 볼드 속성 지정
      var customButton = document.querySelector('.ql-bold');
      customButton.click();
    }
  }, [tabName]);

  const modules = useMemo(() => {
    return {
      toolbar: {
        container: [
          'bold',
          'italic',
          'underline',
          'strike',
          { color: [] },
          { background: [] },
          'boilerplate',
        ],
        handlers: {
          boilerplate: function () {
            modalMemoBoilerplate.openModalBoilerplate(
              tabName, // 카테고리
              quillRef.current.state.value, // 원본 텍스트
              onChange, // 변경 fun
              setMemoBoilerplateList // 보일러 플레이트 리스트 fun
            );
          },
        },
      },
    };
  }, [tabName]);

  return (
    <ReactQuill
      ref={quillRef}
      {...props}
      theme="snow"
      modules={modules}
      formats={['background', 'bold', 'color', 'italic', 'strike', 'underline']}
      value={value}
      onChange={onChange}
      preserveWhitespace
    />
  );
}

QuillTextField.propTypes = {
  tabName: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  value: PropTypes.string,
  setValue: PropTypes.func,
  setMemoBoilerplateList: PropTypes.func,
  placeholder: PropTypes.string,
};
