import React, { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import HideHidden from 'components/app/HideHidden';
import DataTableAlpha from 'components/data-table/DataTableAlpha';
import helpers from 'hooks/helpers';
import ModalMessageBoilerplate from 'components/modals/codes/message-codes/ModalMessageBoilerplate';
import models from 'models';
import { translate } from 'filters';
import { $qb } from 'scripts/querybuilder';
import { useModal } from 'hooks/useModal';
import { useServices } from 'hooks/useServices';
import { useToast } from 'hooks/useToast';

const MessageBoilerplates = () => {
  const modal = useModal();
  const services = useServices();
  const toast = useToast();
  const defaultParams = $qb().limit().orderBy();
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(null);
  const [params, setParams] = useState(defaultParams);
  const [hideHidden, setHideHidden] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(true);
  const onParams = (p) => {
    setParams(p);
  };

  const callApi = useCallback(async () => {
    try {
      setLoading(true);
      const resp = await services.crm.notification.boilerplateMessages.all();
      setData(resp.data);
      setTotal(resp.total);
      setLoading(false);
    } catch (error) {
      toast.error(error.description);
      setLoading(false);
    }
  }, [services.crm.notification.boilerplateMessages, toast]);

  useEffect(() => {
    callApi();
  }, [callApi]);

  const onAction = (obj) => {
    const { row, key, event } = obj;
    if (event.target.dataset.type === 'visible') {
      const toggleObj = {
        visible: !row['visible'],
        id: row['id'],
      };
      const endpoint = services.crm.notification.boilerplateMessages;
      return helpers.toggleVisibilityById({
        obj: toggleObj,
        endpoint,
        callback: callApi,
      });
    }
    if (event.target.dataset.type === 'edit') {
      openModalBoilerplate({ row, key, event });
    }
    if (event.target.dataset.type === 'delete') {
      openModalDelete({
        id: row['id'],
        endpoint: services.crm.crud.boilerplateMessage,
      });
    }
    if (key['type'] === 'image' && (row.images || []).length > 0) {
      modal.images({
        images: (row.images || []).map((image) => image.url),
      });
      return;
    }
  };

  const openModalDelete = ({ endpoint, id }) => {
    const onConfirm = async () => {
      try {
        await endpoint.delete(id);
        modal
          .confirm({
            type: 'SUCCESS',
            msg: '삭제되었습니다.',
          })
          .then(() => {
            callApi();
          });
        // eventBus.emit('callApi')
      } catch (e) {
        toast.error(e.description);
      }
    };

    modal
      .basic({
        body: translate('DELETE_CONFIRM'),
        buttons: [
          {
            text: 'CANCEL',
            class: 'btn-default',
          },
          {
            text: 'CONFIRM',
            class: 'btn-danger',
          },
        ],
      })
      .then((idx) => {
        if (idx === 1) {
          onConfirm();
        }
      });
  };

  const openModalBoilerplate = useCallback(
    ({ row }) => {
      modal
        .custom({
          component: ModalMessageBoilerplate,
          options: { boilerplateMessage: row },
        })
        .then((submitted) => {
          if (submitted) {
            setData([]);
            setTotal(null);
            callApi();
          }
        });
    },
    [modal, callApi]
  );

  return (
    <div className="message-boilerplate">
      <div className="route-top">
        <div className="title">{translate('BOILERPLATE_MESSAGES')}</div>
        <div className="flex-row items-center">
          <HideHidden
            toggleHandler={setHideHidden}
            text="미사용항목 보지않기"
          />
          <button
            onClick={() => {
              openModalBoilerplate({});
            }}
            className="flex-wrap btn btn-sm m-r-8 btn-add"
          >
            {translate('ADD')}
          </button>
        </div>
      </div>
      <DataTableAlpha
        className="header-style-classic"
        model={models.crm.messageBoilerplate}
        data={hideHidden ? data.filter((row) => row.visible) : data}
        total={total}
        params={params}
        onParams={onParams}
        onAction={onAction}
        hideBottom={true}
        hideHidden={hideHidden}
        hasDeactivate
      />
    </div>
  );
};

export default observer(MessageBoilerplates);
