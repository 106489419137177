import React, { useMemo } from 'react';
import menuItems from './menu-items';
import Menu from 'components/app/Menu';
import { observer } from 'mobx-react';
import { useAuth } from 'store/auth';
import { useUI } from 'hooks/useUI';

const LeftNavigation = () => {
  const auth = useAuth();
  const ui = useUI();
  const menu = useMemo(
    () => ({
      items: auth.me.type === 'admin' ? menuItems.admin : menuItems.crm,
    }),
    [auth.me]
  );

  return (
    <div
      className={`left-navigation flex flex-wrap ${
        ui.showNavigation ? '' : 'side'
      }`}
    >
      <div className="ver-scroll flex-fill">
        <Menu depth={0} menu={menu} />
      </div>
    </div>
  );
};

export default observer(LeftNavigation);
