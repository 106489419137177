import { translate } from 'filters';
import modal from 'store/modal';
//v2 적용된 공통코드 props modelName과 동일하게 배열에 추가
// 상담코드설정: 상담결과 consultings/result
const v2_api_no_id_list = ['result'];

// 간호코드설정: 수술간호 투약, 바이탈 operation(/injectItem, /vitalItems)
// 수납코드설정: 시수술 > 시수술명 treatment/items
// 수납코드설정: 시수술 > 카테고리 treatment/items/categories
// 간호코드설정 : 간호 투약, 바이탈 (cares/injectItem, /vitalItems)
const v2_api_id_list = [
  'operations',
  'treatment_items',
  'treatment_categories',
  'cares',
];

export default {
  toggleVisibility: ({ obj, endpoint, callback, modelName }) => {
    const onConfirm = async () => {
      try {
        obj.visible = !obj.visible;

        let payload = {
          id: obj.id,
          visible: obj.visible,
        };

        if (modelName === 'discountReason') {
          //해당 경우에 content를 보내지 않으면 오류발생함
          payload.content = obj.content;
        }

        if (modelName === 'product') {
          //해당 경우에 name을 보내지 않으면 오류발생함
          payload.name = obj.name;
        }

        v2_api_no_id_list.indexOf(modelName) > -1
          ? await endpoint.update_v2(payload)
          : await endpoint.update(payload);

        modal
          .confirm({
            type: 'SUCCESS',
            msg: translate('VISIBILITY_TOGGLED').replace(
              /%s/,
              translate(obj.visible ? 'UNHIDE' : 'HIDE')
            ),
          })
          .then(() => {
            callback();
          });
      } catch (e) {
        console.log(e.description);
        let errorMessage = e.description;
        modal
          .confirm({
            type: 'ERROR',
            msg: errorMessage,
          })
          .then(() => {
            callback();
          });
      }
    };

    modal
      .basic({
        body: translate(obj.visible ? 'CONFIRM_HIDE' : 'CONFIRM_SHOW'),
        buttons: [
          {
            text: 'CANCEL',
            class: 'btn-default',
          },
          {
            text: 'CONFIRM',
            class: 'btn-primary',
          },
        ],
      })
      .then((idx) => {
        if (idx === 1) {
          onConfirm();
        }
      });
  },
  toggleVisibilityById: ({ obj, endpoint, callback, type }) => {
    const onConfirm = async () => {
      try {
        v2_api_id_list.indexOf(type) > -1
          ? await endpoint.visible_v2(obj)
          : await endpoint.visible(obj);

        modal
          .confirm({
            type: 'SUCCESS',
            msg: translate(
              obj.visible ? 'TOGGLE_ACTIVATED' : 'TOGGLE_DEACTIVATED'
            ),
          })
          .then(() => {
            callback();
          });
      } catch (e) {
        console.log(e.description);
        let errorMessage = e.description;
        modal
          .confirm({
            type: 'ERROR',
            msg: errorMessage,
          })
          .then(() => {
            callback();
          });
      }
    };

    modal
      .basic({
        body: translate(
          obj.visible ? 'CONFIRM_ACTIVATE' : 'CONFIRM_DEACTIVATE'
        ),
        buttons: [
          {
            text: 'CANCEL',
            class: 'btn-default',
          },
          {
            text: 'CONFIRM',
            class: 'btn-primary',
          },
        ],
      })
      .then((idx) => {
        if (idx === 1) {
          onConfirm();
        }
      });
  },
};
