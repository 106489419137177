import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

export const useHelpStyle = makeStyles((theme) => ({
  arrow: {
    '&::before': {
      color: theme.palette.common.black,
      backgroundColor: '#ffffff',
      border: '1px solid #0060ff',
    },
  },
  tooltip: {
    backgroundColor: '#ffffff',
    padding: '20px 12px',
    border: '1px solid #0060ff',
    fontSize: 12,
    maxWidth: 205,
    color: '#484760',
  },
}));

export function CtiHelpTooltip(props) {
  const classes = useHelpStyle();

  return <Tooltip arrow classes={classes} {...props} />;
}
