import modal from 'store/modal';
import React, { useState } from 'react';
import { $case, translate } from 'filters';
import { mustParse } from 'utils/json';
import ModalCustomerChart from 'components/modals/ModalCustomerChart';
import ko from 'date-fns/locale/ko';
import { RadioInput } from 'components/common/RadioInput';
import styled from 'styled-components';

const RadioInputWrapper = styled(RadioInput)`
  margin-right: 4px;
`;

const datePickerKoLocale = {
  ...ko,
  options: {
    weekStartsOn: 0,
    /* Monday */
    firstWeekContainsDate: 1,
  },
};

const useForceUpdate = () => {
  const [, setValue] = useState(0);
  return () => setValue((value) => ++value);
};

const openCustomerChart = ({
  customer,
  appointment,
  consulting,
  treatment,
  payment,
  surgery,
  operationNurseChart,
  nurseChart,
  skinManageChart,
  smsNotification,
  selectTab,
  openFlag,
  closeFlag,
  calledPage,
  editInfo,
  consultingRequest,
}) => {
  modal.closeModalByComponent(ModalCustomerChart);
  modal.custom({
    component: ModalCustomerChart,
    options: {
      customer,
      appointment,
      consulting,
      treatment,
      payment,
      surgery,
      operationNurseChart,
      nurseChart,
      skinManageChart,
      smsNotification,
      selectTab,
      openFlag,
      closeFlag,
      calledPage,
      editInfo,
      consultingRequest,
    },
  });
};

const radios = ({ radios, obj, setObj, name, tabIndex }) => (
  <div className="radios flex-row">
    {radios.map((radio, idx) => (
      <div
        key={idx}
        className="radio-item flex-row items-center flex-wrap m-r-16"
      >
        <RadioInputWrapper
          tabIndex={tabIndex}
          name={name}
          value={radio.value}
          checked={radio.value === obj[name]}
          onChange={(e) => {
            obj[name] = mustParse(e.target.value);
            setObj({ ...obj });
          }}
        />
        {translate(radio.label || (radio.value || '').toUpperCase())}
      </div>
    ))}
  </div>
);
const estimatedServiceMinutesList = [
  '10',
  '30',
  '60',
  '90',
  '120',
  '150',
  '180',
  '210',
  '240',
  '270',
  '300',
  '330',
  '360',
  '390',
  '420',
  '450',
  '480',
];

const selector = ({ options, unit, value, onChange, name, disabled }) => {
  return (
    <select
      disabled={String(name).includes('lunch') && !disabled ? true : false}
      name={name}
      value={value || ''}
      onChange={onChange}
    >
      <option disabled value={''} key={-1}>
        -
      </option>
      {options.map((value, idx) => (
        <option value={value} key={idx}>
          {value}
          {unit}
        </option>
      ))}
    </select>
  );
};

const disabledField = ({ obj, requiredFields }) => {
  const missingField = requiredFields.find(
    (key) =>
      (typeof obj[key] !== 'boolean' && !obj[key]) ||
      (typeof obj[key] === 'object' && !(obj[key] || {}).id)
  );

  return missingField
    ? translate('ERROR_MISSING_FIELD').replace(
        /%s/,
        translate($case.toConst(missingField))
      )
    : null;
};

export default {
  datePickerKoLocale,
  useForceUpdate,
  openCustomerChart,
  estimatedServiceMinutesList,
  selector,
  radios,
  disabledField,
};
