import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { banks } from 'hooks/constants';
import { toThousandCommas } from 'filters';

const FormWrapper = styled.div`
  width: 350px;
`;

const TitleText = styled.div`
  font-size: 16px;
  margin-bottom: 20px;
  font-weight: 400;
  line-hieght: 26px;
`;

const Title = styled.div`
  font-size: 18px;
  font-weight: 700;
  display: flex;
  gap: 5px;
  margin-bottom: 5px;
`;

const SubTitle = styled.div`
  font-size: 14px;
  font-weight: 700;
  margin: 20px 0 10px 0;
`;

const Input = styled.input`
  padding: 9px 20px 8px;
  font-size: 14px;
  background-color: transparent;
  width: 100%;
  height: 34px;
  border-radius: 4px;
  border: 1px solid #dee2ec;

  ${({ disabled }) =>
    disabled &&
    css`
      background-color: #efeff4 !important;
      color: #ceced7;
    `}
`;

const Select = styled.select`
  width: 100%;
`;

export const BalanceRefundView = ({ data, onClickRefundCancel, clinic }) => {
  const { name: clinicName, employerNo, ownerName } = clinic;
  return (
    <FormWrapper>
      <TitleText>
        <p>환불 처리 중입니다.</p>
        <p>신청일 기준 2영업일 이내 처리될 예정입니다.</p>
      </TitleText>
      <div>
        <SubTitle>
          <p>환불 신청 내역</p>
        </SubTitle>
        <div className="point-refund">
          <Title>
            <span>환불 금액</span>
            <span>{toThousandCommas(data?.amount)}원</span>
          </Title>
          <div>
            <SubTitle>결제정보 확인</SubTitle>
            <div className="form-control">
              <label>상호(병원명)</label>
              <Input disabled value={clinicName} />
            </div>
            <div className="form-control">
              <label>사업자등록번호</label>
              <Input disabled value={employerNo} />
            </div>
            <div className="form-control">
              <label>대표자</label>
              <Input disabled value={ownerName} />
            </div>

            <div className="title m-t-16 m-b-8">환불 받을 계좌 정보</div>
            <div className="form-control">
              <label>은행명</label>
              <div>
                <Select
                  className="block"
                  name="refundBankCd"
                  defaultValue={data?.refundBankCd}
                  disabled
                >
                  <option key={-1} value={-1}>
                    -
                  </option>
                  {banks.map((bank, idx) => (
                    <option key={idx} value={bank.value}>
                      {bank.key}
                    </option>
                  ))}
                </Select>
              </div>
            </div>
            <div className="form-control">
              <label>계좌번호</label>
              <Input
                name="refundAcctNo"
                disabled
                defaultValue={data?.refundAcctNo}
              />
            </div>
            <div className="form-control">
              <label>예금주</label>
              <Input
                name="refundAcctNm"
                disabled
                defaultValue={data?.refundAcctNm}
              />
            </div>
          </div>
        </div>
      </div>
      <button className="btn btn-danger _small" onClick={onClickRefundCancel}>
        환불 신청 취소
      </button>
    </FormWrapper>
  );
};

BalanceRefundView.propTypes = {
  data: PropTypes.any,
  clinic: PropTypes.any,
  onClickRefundCancel: PropTypes.any,
};
