import moment from 'moment';

export default {
  keys: [
    {
      title: '날짜',
      column: 'scheduledAt',
      order: 'desc',
      filter: (o) => {
        return `
                ${moment(o.scheduledAt).format('YYYY-MM-DD')}<br />
                ${moment(o.scheduledAt).format('HH:mm:ss')}
            `;
      },
    },
    {
      title: '카테고리',
      // column: 'type',
      filter: (o) => {
        if (o.type === 'appointment') return '예약';
        if (o.type === 'consulting') return '상담';
        if (o.type === 'consult_request') return '상담문의';
        if (o.type === 'payment') return '수납';
        if (o.type === 'surgery') return '시수술';
        if (o.type === 'treatment') return '진료';
        if (o.type === 'nurse_skin') return '피부관리';
        if (o.type === 'nurse_operation') return '수술간호';
        if (o.type === 'nurse_care') return '간호';
        else return o.type;
      },
    },
    {
      title: '메모',
      column: 'memo',
      noorder: true,
      type: 'memo',
    },
    {
      title: '작성자',
      // column: 'createdName',
      filter: (o) => o.createdName,
    },
  ],
};
