import * as $http from 'axios';

export default {
  all: (params) => $http.get('/files', { params }),
  all_v2: (params) => $http.get('/files/v2', { params }),
  create: (query, config) => $http.post(`/files`, query, config),
  create_v2: (query) => $http.post(`/files/v2`, query),
  copy: (query) => $http.post(`/files/copy`, query),
  copy_v2: (query) => $http.post(`/files/v2/copy`, query),
  get: (id) => $http.get(`/files/${id}`),
  get_v2: (id) => $http.get(`/files/v2/${id}`),
  updateFile_v2: (query) => $http.post(`/files/v2/update`, query),
  update: (id, query) => $http.put(`/files/${id}`, query),
  update_v2: (id, query) => $http.put(`/files/v2/${id}`, query),
  delete: (id) => $http.delete(`/files/${id}`),
  delete_v2: (id) => $http.delete(`/files/v2/${id}`),
  getImg: (id, size) =>
    $http.get(`/files/image/${id}/${size}`, {
      responseType: 'blob',
      headers: {
        'Cache-Control': 'no-cache',
      },
    }),
  getImg_v2: (id, size) =>
    $http.get(`/files/v2/image/${id}/${size}`, {
      responseType: 'blob',
      headers: {
        'Cache-Control': 'no-cache',
      },
    }),
  getImgThumb: (id) =>
    $http.get(`/files/image/thumbnail/${id}`, {
      responseType: 'blob',
      headers: {
        'Cache-Control': 'no-cache',
      },
    }),
  getImgThumb_v2: (id) =>
    $http.get(`/files/v2/image/thumbnail/${id}`, {
      responseType: 'blob',
      headers: {
        'Cache-Control': 'no-cache',
      },
    }),
};
