import { currency, currencySymbol } from 'filters';

export default {
  keys: [
    {
      title: '고객등급',
      key: 'customerLevel',
      column: 'customerLevel',
      columnGroup: false,
    },
    {
      title: '고객수',
      key: 'customerCount',
      column: 'customerCount',
      columnGroup: false,
    },
    {
      title: '상담인원수',
      columnGroup: false,
      key: 'consultedPeopleCount',
      column: 'consultedPeopleCount',
      orderKey: 'consultedPeopleCount',
      order: 'desc',
    },
    {
      title: '상담건수',
      columnGroup: false,
      key: 'consultedCount',
      column: 'consultedCount',
      orderKey: 'consultedCount',
      order: 'desc',
    },
    {
      title: '진료인원수',
      key: 'treatmentPeopleCount',
      column: 'treatmentPeopleCount',
      columnGroup: false,
      orderKey: 'treatmentPeopleCount',
      order: 'desc',
    },
    {
      title: '진료건수',
      key: 'treatmentCount',
      column: 'treatmentCount',
      columnGroup: false,
      orderKey: 'treatmentCount',
      order: 'desc',
    },
    {
      title: '시수술인원수',
      key: 'surgeryPeopleCount',
      column: 'surgeryPeopleCount',
      columnGroup: false,
      orderKey: 'surgeryPeopleCount',
      order: 'desc',
    },
    {
      title: '시수술건수',
      key: 'surgeryCount',
      column: 'surgeryCount',
      columnGroup: false,
      orderKey: 'surgeryCount',
      order: 'desc',
    },
    {
      title: '수납건수',
      key: 'paymentCount',
      column: 'paymentCount',
      columnGroup: false,
      orderKey: 'paymentCount',
      order: 'desc',
    },
    {
      title: '매출액',
      key: 'salesFigures',
      column: 'salesFigures',
      columnGroup: false,
      orderKey: 'salesFigures',
      order: 'desc',
      filter: (o) => {
        return `${currencySymbol()} ${currency(
          (o?.salesFigures ?? 0) - (o?.refundAmount ?? 0)
        )}`;
      },
    },
    {
      title: '수납액',
      key: 'paidAmount',
      column: 'paidAmount',
      columnGroup: false,
      orderKey: 'paidAmount',
      order: 'desc',
      filter: (o) => {
        return `${currencySymbol()} ${currency((o || {}).paidAmount || 0)}`;
      },
    },
    {
      title: '미수금액',
      key: 'unpaidAmount',
      column: 'unpaidAmount',
      columnGroup: false,
      orderKey: 'unpaidAmount',
      order: 'desc',
      filter: (o) => {
        return `${currencySymbol()} ${currency((o || {}).unpaidAmount || 0)}`;
      },
    },
  ],
};
