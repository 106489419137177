import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useRouteMatch } from 'react-router-dom';
import { toJS } from 'mobx';
import moment from 'moment';
import PropTypes from 'prop-types';
import ModalCustomerFinder from 'components/modals/ModalCustomerFinder';
import ModalSearchMedicine from 'components/modals/ModalSearchMedicine';
import ModalPrescriptionSealCreate from 'components/modals/ModalPrescriptionSealCreate';
import { useAuth } from 'store/auth';
import { useModal } from 'hooks/useModal';
import { useServices } from 'hooks/useServices';
import { useToast } from 'hooks/useToast';
import { useUI } from 'hooks/useUI';
import styled, { css } from 'styled-components';

const Input = styled.input`
  width: 100%;
  padding: 0 !important;
  border: 0 !important;
  background: none !important;
  text-align: center;
  height: 13px !important;
  font-size: 13px !important;

  &[type='number'] {
    padding-left: 12px !important;
  }

  ${({ widthSize }) => {
    if (widthSize) {
      return css`
        width: ${widthSize}px !important;
      `;
    }
  }}
`;

const PrescriptionTable = ({
  bundleId,
  setInfo,
  buttonRef,
  printId,
  setPrintId,
  printMode,
}) => {
  const auth = useAuth();
  const modal = useModal();
  const services = useServices();
  const toast = useToast();
  const ui = useUI();
  const match = useRouteMatch();
  const postId = printMode ? printId : match.params.id;

  const [printAction, setPrintAction] = useState(false); // (Boolean)
  const [clinic, setClinic] = useState({}); // 병원정보(Object)
  const [doctorList, setDoctorList] = useState([]); // 의사 리스트(Array)

  const [printType, setPrintType] = useState({
    personal: false,
    pharmacy: true,
  }); // 처방전 프린트 타입 (Object)
  const [docStatus, setDocStatus] = useState('temporary_stored'); // 처방전 상태(String)
  const [createdId, setCreatedId] = useState({}); // 발급 연월일 및 번호(Object)
  const [insuranceType, setInsuranceType] = useState('etc'); // 보험 타입(Number)
  const [injectionType, setInjectionType] = useState('outer_clinic'); // 주사제 처방명세 타입(Number)
  const [customer, setCustomer] = useState({}); // 성명(String)
  const [
    userResidentRegistrationNumber,
    setUserResidentRegistrationNumber,
  ] = useState(['', '']); // 주민등록번호(Array[String, String])
  const [doctor, setDoctor] = useState({}); // 처방 의료인(Object)
  const [medicineList, setMedicineList] = useState([]); // 처방약 리스트(Array)
  const [injectionList, setInjectionList] = useState([]); // 주사제 리스트(Array)
  const [medicineDose, setMedicineDose] = useState({
    dosageOnce: null,
    dosageDaily: null,
    dosageTotalDay: null,
    memo: null,
  }); // 입력전 처방약 투약량/횟수/일수(Object)
  const [injectionDose, setInjectionDose] = useState({
    dosageOnce: null,
    dosageDaily: null,
    dosageTotalDay: null,
    memo: null,
  }); // 입력전 주사제 투약량/횟수/일수(Object)
  const [dueUntil, setDueUntil] = useState(7); // 입력전 주사제 투약량/횟수/일수(Object)

  const [sealImage, setSealImage] = useState(null);

  const maxMedicineLine = 13;
  const maxInjectionLine = 3;

  // 인쇄용 class를 추가하는 이벤트 리스너
  // 본래 react-print 라이브러리를 사용하려 했으나,
  // 현재 functional component는 지원하지 않아 이렇게 작성하게 됨
  // 프린트 하고싶다면 다음과 같이 클래스를 추가한다. -> body.printMode, component.wrap_print
  useEffect(() => {
    window.addEventListener('afterprint', resetPrintClass);
    return () => {
      window.removeEventListener('afterprint', resetPrintClass);
    };
  }, []);

  useEffect(() => {
    if (!services) return;
    getDoctorList();
  }, [postId]);

  useEffect(() => {
    if (!auth.me) return;
    setClinic(toJS(auth.me.clinic));
  }, []);

  useEffect(() => {
    getPrescription();
  }, [doctorList]);

  useEffect(() => {
    getBundelData(bundleId);
  }, [bundleId]);

  useEffect(() => {
    if (printAction === true) onPostPage();
  }, [printAction]);

  useEffect(() => {
    //초기 진입시 로그인한 의사 세팅
    //postId undefined인 경우 = 수정
    //수정시에는 초기 셋팅 없이 저장된 의사를 불러오도록 함
    if (!postId) {
      getInitDoctor();
    }
  }, [postId]);

  const getInitDoctor = async () => {
    try {
      const resp = await services.crm.user.me();
      if (!resp) return;

      let isDutyDoctor = resp.data.duty?.indexOf('의사');

      if (isDutyDoctor > -1) {
        setDoctor(resp.data);
        setSealImage(resp.data?.sealImage);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (buttonRef) {
      buttonRef.bundle.current.addEventListener('click', onSaveBundle);
      buttonRef.reset.current.addEventListener('click', resetValue);
      buttonRef.temporary.current.addEventListener('click', onPostPage);
      buttonRef.post.current.addEventListener('click', onPostPage);
      buttonRef.print.current.addEventListener('click', onPostPage);

      return () => {
        buttonRef.bundle.current &&
          buttonRef.bundle.current.removeEventListener('click', onSaveBundle);
        buttonRef.reset.current &&
          buttonRef.reset.current.removeEventListener('click', resetValue);
        buttonRef.temporary.current &&
          buttonRef.temporary.current.removeEventListener('click', onPostPage);
        buttonRef.post.current &&
          buttonRef.post.current.removeEventListener('click', onPostPage);
        buttonRef.print.current &&
          buttonRef.print.current.removeEventListener('click', onPostPage);
      };
    }
  });

  const onSaveBundle = () => {
    setInfo({ medicineList: medicineList, injectionList: injectionList });
  };

  const resetValue = () => {
    if (docStatus === 'temporary_stored') {
      setInsuranceType('etc');
      setCustomer({});
      setDoctor({});
      setSealImage(null);
    }
    setInjectionType('outer_clinic');
    setUserResidentRegistrationNumber(['', '']);
    setMedicineList([]);
    setInjectionList([]);
    setMedicineDose({
      dosageOnce: null,
      dosageDaily: null,
      dosageTotalDay: null,
      memo: null,
    });
    setInjectionDose({
      dosageOnce: null,
      dosageDaily: null,
      dosageTotalDay: null,
      memo: null,
    });
    setDueUntil(7);
  };

  const onPostPage = async (e) => {
    if (!customer.id) {
      toast.error('고객명을 입력하세요.');
      return;
    }

    let status;
    if (e) {
      status = e.target.className.includes('temporary')
        ? 'temporary_stored'
        : e.target.className.includes('post')
        ? 'stored'
        : e.target.className.includes('print')
        ? 'published'
        : '';
    }
    if (printMode) {
      status = 'published';
    }

    if (status === 'published') {
      ui.setPrescriptionPrintBtnDisabledFlag(true);
    }

    let params = {
      prescriptionId: postId,
      prescriptionType: insuranceType,
      customerId: customer && customer.id,
      // userId: (doctor && doctor.id) || undefined,
      doctorId: (doctor && doctor.id) || undefined,
      status: status,
      dueUntil: dueUntil,
      injectionStatus: injectionType,
      medicineList: medicineList,
      injectionList: injectionList,
    };

    try {
      let resp;
      if (postId) {
        // resp = await services.crm.prescription.prescription.update(params);
        resp = await services.crm.prescription.prescription.update_v2(params);
      } else {
        // resp = await services.crm.prescription.prescription.create(params);
        resp = await services.crm.prescription.prescription.create_v2(params);
      }

      if (status === 'temporary_stored') {
        modal.confirm({
          type: 'SUCCESS',
          msg: '임시저장하였습니다.',
        });
      } else if (status === 'stored') {
        modal.confirm({
          type: 'SUCCESS',
          msg: '작성완료하였습니다.',
        });
      } else if (status === 'published') {
        // setCreatedId({
        //   date: resp.createdIdDate,
        //   count: resp.createdIdCount,
        // });
        setCreatedId({
          date: resp.data.prescription.createdIdDate,
          count: resp.data.prescription.createdIdCount,
        });

        onPrintPage();
        ui.setPrescriptionPrintBtnDisabledFlag(false);
      }

      setDocStatus(status);
      setPrintAction(false);
    } catch (e) {
      toast.error(e.description);
    }
  };

  const resetPrintClass = () => {
    document.body.classList.remove('printMode');
    // document.body.classList.add("printMode");
  };

  const onPrintPage = () => {
    if (printId) setPrintId(0);
    document.body.classList.add('printMode');
    window.print();
  };

  const addMedicine = (type, obj) => {
    let copyDose;
    if (type === 'medicine') {
      copyDose = {
        ...medicineDose,
        dosageOnce: medicineDose.dosageOnce || 1,
        dosageDaily: medicineDose.dosageDaily || 1,
        dosageTotalDay: medicineDose.dosageTotalDay || 1,
      };
      setMedicineList([...medicineList, { ...obj, ...copyDose }]);
      setMedicineDose({
        dosageOnce: null,
        dosageDaily: null,
        dosageTotalDay: null,
        memo: null,
      });
    }
    if (type === 'injection') {
      copyDose = {
        ...injectionDose,
        dosageOnce: injectionDose.dosageOnce || 1,
        dosageDaily: injectionDose.dosageDaily || 1,
        dosageTotalDay: injectionDose.dosageTotalDay || 1,
      };
      setInjectionList([...injectionList, { ...obj, ...copyDose }]);
      setInjectionDose({
        dosageOnce: null,
        dosageDaily: null,
        dosageTotalDay: null,
        memo: null,
      });
    }
  };

  const removeMedicine = (type, index) => {
    if (type === 'medicine')
      setMedicineList(medicineList.filter((v, i) => i !== index));
    else if (type === 'injection')
      setInjectionList(injectionList.filter((v, i) => i !== index));
  };

  const editDose = (type, i, editObj) => {
    let copyList;
    if (type === 'medicine') {
      copyList = [...medicineList];
      copyList[i] = { ...copyList[i], ...editObj };
      setMedicineList(copyList);
    }
    if (type === 'injection') {
      copyList = [...injectionList];
      copyList[i] = { ...copyList[i], ...editObj };
      setInjectionList(copyList);
    }
  };

  const searchUser = (value) => {
    modal
      .custom({
        component: ModalCustomerFinder,
        options: {
          customerData: { searchQuery: value },
        },
      })
      .then((customer) => {
        if (customer) {
          setCustomer(customer);

          if (customer.ssnHead) {
            //7자리인 경우 slice
            if (customer.ssnHead.length === 7) {
              setUserResidentRegistrationNumber([
                customer.ssnHead.slice(0, 6),
                customer.ssnHead.slice(-1),
              ]);
            }
            //6자리인 경우
            else if (customer.ssnHead.length === 6) {
              setUserResidentRegistrationNumber([
                customer.ssnHead.slice(0, 6),
                '',
              ]);
            } else {
              setUserResidentRegistrationNumber(['', '']);
            }
          } else {
            setUserResidentRegistrationNumber(['', '']);
          }
        }
      });
  };

  const searchMedicine = (type, value) => {
    modal
      .custom({
        component: ModalSearchMedicine,
        options: {
          type: type,
          customerData: { searchQuery: value },
          onSelect: addMedicine,
        },
      })
      .then(() => {});
  };

  const getPrescription = async () => {
    if (!postId) return;
    let params = { prescriptionId: postId };
    try {
      const resp = await services.crm.prescription.prescription.all_v2(params);
      if (resp.data) {
        setDocStatus(resp.data.prescription.status);
        setCreatedId({
          date: resp.data.prescription.createdIdDate,
          count: resp.data.prescription.createdIdCount,
        });
        setInsuranceType(resp.data.prescription.prescriptionType);
        setInjectionType(resp.data.prescription.injectionStatus);
        setCustomer({
          id: resp.data.prescription.customerId,
          name: resp.data.prescription.customerName,
        });
        setDoctor({
          // lock: resp.data.prescription.userId ? true : false,
          // lock: resp.data.prescription.doctorId ? true : false,
          // id: resp.data.prescription.userId,
          id: resp.data.prescription.doctorId,
          name: resp.data.prescription.doctorName,
          licenseType: doctorList.filter(
            // (v) => v.id == resp.data.prescription.userId
            (v) => v.id == resp.data.prescription.doctorId
          ).length
            ? doctorList.filter(
                // (v) => v.id == resp.data.prescription.userId
                (v) => v.id == resp.data.prescription.doctorId
              )[0].licenseType
            : null,
        });
        setMedicineList(resp.data.medicineList);
        setInjectionList(resp.data.injectionList);
        setDueUntil(resp.data.prescription.dueUntil);
        setSealImage(
          // doctorList.filter((v) => v.id == resp.data.prescription.userId)
          doctorList.filter((v) => v.id == resp.data.prescription.doctorId)
            .length
            ? doctorList.filter(
                // (v) => v.id == resp.data.prescription.userId
                (v) => v.id == resp.data.prescription.doctorId
              )[0].sealImage
            : null
        );
      }

      if (printMode) {
        setPrintAction(true);
      }
    } catch (e) {
      toast.error(e.description);
    }
  };

  const getBundelData = async (id) => {
    if (!id) return;

    let params = { collectionId: id };
    try {
      const resp = await services.crm.prescription.prescription_collection.all_v2(
        params
      );
      if (!resp.data) return;
      setMedicineList(resp.data.medicineList);
      setInjectionList(resp.data.injectionList);
    } catch (e) {
      console.log(e);
    }
  };

  const getDoctorList = async () => {
    try {
      const resp = await services.crm.prescription.prescription_code.all();
      resp && setDoctorList(resp.data.doctor);
    } catch (e) {
      console.log(e);
    }
  };

  const onSaveSealImage = async (doctor) => {
    modal
      .custom({
        component: ModalPrescriptionSealCreate,
        options: {
          doctor,
        },
      })
      .then(async () => {
        //직인 등록 후
        try {
          const resp = await services.crm.prescription.prescription_code.all();
          resp && setDoctorList(resp.data.doctor);
          setSealImage(
            resp.data.doctor.filter((v) => v.id === doctor.id)[0].sealImage
          );
          setDoctor(...resp.data.doctor.filter((v) => v.id === doctor.id));
        } catch (e) {
          console.log(e.description);
        }
      });
  };

  const onOpenSealImageAlert = (doctor) => {
    modal
      .basic({
        body: `등록된 직인이 없습니다.
        직인을 등록하시거나, 출력 후 직접 도장을 찍어주세요.`,
        buttons: [
          {
            text: '직인 등록하기',
            class: 'btn-primary',
          },
          {
            text: 'CONFIRM',
            class: 'btn-primary',
          },
        ],
      })
      .then((idx) => {
        if (idx === 0) {
          onSaveSealImage(doctor);
        } else {
          setSealImage(null);
          setDoctor(doctor);
        }
      });
  };

  if (!clinic) return false;
  return (
    <>
      {/* prettier-ignore */}
      <div id="prescription-table" className={`wrap_print ${printMode ? "hide" : ""} print_page_${Object.values(printType).filter((v) => v === true).length}`}>
      {Object.values(printType).filter((v) => v === true).map((v, i) => { return ( 
      <div key={i} className={`${i === 0 ? "" : "hide"} ${i === 0 && printType.personal ? "type_personal" : "type_pharmacy"}`}>
        <p className="wrap-type-btn right m-b-8 print-type" style={{ width: "100%" }}>
          <button className={`pharmacy ${printType.personal ? "select" : ""}`} onClick={() => printType.pharmacy && setPrintType({...printType, personal: !printType.personal})}>본인보관용</button>
          <button className={`personal ${printType.pharmacy ? "select" : ""}`} onClick={() => printType.personal && setPrintType({...printType, pharmacy: !printType.pharmacy})}>약국제출용</button>
        </p>
        <table>
          <colgroup>
            <col width="70px" />
            <col width="100px" />
            <col width="200px" />
            <col width="100px" />
            <col width="100px" />
            <col width="100px" />
            <col width="100px" />
            <col width="100px" />
            <col width="100px" />
          </colgroup>

          <tbody>
            <tr>
              <td className="title" colSpan="9">
                <h2>처&nbsp;&nbsp;&nbsp;&nbsp;방&nbsp;&nbsp;&nbsp;&nbsp;전</h2>
                <p className="wrap-type-btn">
                  <button className={insuranceType === "health_insurance" ? "select" : ""} onClick={() => (docStatus === "temporary_stored") && setInsuranceType("health_insurance")}>건강보험</button>
                  <button className={insuranceType === "medical_benefit" ? "select" : ""} onClick={() => (docStatus === "temporary_stored") && setInsuranceType("medical_benefit")}>의료급여</button>
                  <button className={insuranceType === "industrial_accident" ? "select" : ""} onClick={() => (docStatus === "temporary_stored") && setInsuranceType("industrial_accident")}>산업재해보험</button>
                  <button className={insuranceType === "car_insurance" ? "select" : ""} onClick={() => (docStatus === "temporary_stored") && setInsuranceType("car_insurance")}>자동차보험</button>
                  <button className={insuranceType === "etc" ? "select" : ""} onClick={() => (docStatus === "temporary_stored") && setInsuranceType("etc")}>기타 ( )</button>
                </p>
                <p align="left">* [  ]에는 해당되는 곳에 &quot;V&quot;표시를 합니다.</p>
              </td>
            </tr>
            <tr>
              <td align="left" colSpan="9">요양기관기호 : {clinic.clinicNo}</td>
            </tr>
            <tr>
              <td colSpan="2">발급 연월일 및 번호</td>
              <td colSpan="3">{(createdId.date && createdId.count) && `${moment(createdId.date).format('YYYY년 MM월 DD일')} 제 ${createdId.count}호`}</td>
              <td rowSpan="3">의료기관</td>
              <td>명칭</td>
              <td colSpan="2">{clinic.name}</td>
            </tr>
            <tr>
              <td rowSpan="4">환자<br />질병<br />분류<br />기호</td>
              <td>성명</td>
              <td colSpan="3">
                {docStatus !== "temporary_stored"
                  ? <p>{customer?.name}</p>
                  : <Input value={customer?.name || ''} onChange={(e) => setCustomer({ name: e.target.value })} onKeyPress={(e) => (docStatus === "temporary_stored" && e.key === "Enter") && searchUser(e.target.value)} placeholder="고객명 입력 후 엔터를 누르세요" />
                }
              </td>
              <td>전화번호</td>
              <td colSpan="2">{clinic.clinicTel}</td>
            </tr>
            <tr>
              <td>주민등록번호</td>
              <td colSpan="3">
                <div className="flex-row">
                  <Input value={userResidentRegistrationNumber[0]} onChange={(e) => setUserResidentRegistrationNumber([e.target.value, userResidentRegistrationNumber[1]])} maxLength="6" />-
                  <Input value={userResidentRegistrationNumber[1]} onChange={(e) => setUserResidentRegistrationNumber([userResidentRegistrationNumber[0], e.target.value])} maxLength="7" />
                </div>
              </td>
              <td>팩스번호</td>
              <td colSpan="2">{clinic.clinicFax}</td>
            </tr>
            <tr>
              <td colSpan="3" rowSpan="2"><br /></td>
              <td rowSpan="2">처방 의료인의 성명</td>
              <td colSpan="2" rowSpan="2">
                {(docStatus === "temporary_stored" || (!doctor?.id)) && doctorList &&
                  <select className="m-b-8" value={(doctor || {}).id || -1} onChange={(e) => { 
                    let { value } = e.target
                    let seal = ((doctorList.filter((v) => {
                      return v.id == value
                    }) || [])[0] || {}).sealImage
                    if(seal === null) {
                      onOpenSealImageAlert((doctorList.filter((v) => v.id == value) || [])[0])
                    } else {
                      setSealImage(seal)
                      setDoctor(...doctorList.filter((v) => v.id == value))
                    }
                    
                  }}>
                    <option value={-1}>선택하세요.</option>
                    {doctorList.map((v) => <option key={v.id} value={v.id}>{v.name}</option>)}
                  </select>
                }
                <p className={`left doctor_name ${(docStatus !== "temporary_stored" && (doctor?.id)) ? "" : "hide"}`}>
                  {doctor && doctor.name}
                </p>
                <p align="right">(서명 또는 날인)</p>
                { 
                  (sealImage !== null && sealImage !== undefined) &&
                  <img
                    alt=""
                    style={{
                      width: '40px',
                      height: '40px',
                      position: 'absolute',
                      marginTop: '-26px',
                      marginLeft: '30px',
                    }}
                    src={sealImage}
                  ></img>
                }
              </td>
              <td>면허종류</td>
              <td>의사</td>
            </tr>
            <tr>
              <td>면허번호</td>
              <td>
                {doctor && doctor.licenseType}
              </td>
            </tr>
            <tr>
              <td align="left" colSpan="9">환자가 요구하면 질병분류기호를 적지 않습니다</td>
            </tr>
            <tr>
              <td colSpan="4">처방 의약품의 명칭</td>
              <td>1회<br />투약량</td>
              <td>1일<br />투여횟수</td>
              <td>총 <br />투약일수</td>
              <td colSpan="2" >용법</td>
            </tr>
            {medicineList && medicineList.map((v, i) => (
              //'처방 의약품의 명칭'뒤 텍스트 추가: ('규격', '단위')
              //ex)약품명(규격, 단위) = 팜피린 0.05mg(1정, 500ml)
              <tr key={i}>
                <td colSpan="4" align="left">
                  <button className="btn btn-del btn-ssm m-r-8" onClick={() => removeMedicine("medicine", i)}>-</button>
                  [{v.drugId}]{v.drugName}({v.dosage || ''}{v.unit || ''})
                </td>{/* 처방 의약품의 명칭 */}
                <td><Input type="number" step="0.25" min="0" value={v.dosageOnce || 1} onChange={(e) => editDose("medicine", i, { dosageOnce: e.target.value || 1 })} placeholder="1" /></td>{/* 1회 투약량 */}
                <td><Input type="number" step="1" min="0" value={v.dosageDaily || 1} onChange={(e) => editDose("medicine", i, { dosageDaily: e.target.value || 1 })} placeholder="1" /></td>{/* 1일 투여횟수 */}
                <td><Input type="number" step="1" min="0" value={v.dosageTotalDay || 1} onChange={(e) => editDose("medicine", i, { dosageTotalDay: e.target.value || 1 })} placeholder="1" /></td>{/* 총 투약일수 */}
                <td colSpan="2"><Input value={v.memo || ""} onChange={(e) => editDose("medicine", i, { memo: e.target.value })} placeholder="입력하세요" maxLength="20" /></td>{/* 용법 */}
              </tr>
            ))}
            {[...Array(maxMedicineLine - medicineList.length)].map((v, i) => (<React.Fragment key={i}>
              {(i === 0) &&
                <tr key={i}>
                  <td colSpan="4"><Input onClick={() => searchMedicine("medicine")} placeholder="입력하세요" readOnly /></td>{/* 처방 의약품의 명칭 */}
                  <td><Input type="number" step="0.25" value={medicineDose.dosageOnce || ""} onChange={(e) => setMedicineDose({ ...medicineDose, dosageOnce: e.target.value || 1 })} placeholder="1" /></td>{/* 1회 투약량 */}
                  <td><Input type="number" step="1" value={medicineDose.dosageDaily || ""} onChange={(e) => setMedicineDose({ ...medicineDose, dosageDaily: e.target.value || 1 })} placeholder="1" /></td>{/* 1일 투여횟수 */}
                  <td><Input type="number" step="1" value={medicineDose.dosageTotalDay || ""} onChange={(e) => setMedicineDose({ ...medicineDose, dosageTotalDay: e.target.value || 1 })} placeholder="1" /></td>{/* 총 투약일수 */}
                  <td colSpan="2"><Input value={medicineDose.memo || ""} onChange={(e) => setMedicineDose({ ...medicineDose, memo: e.target.value })} placeholder="입력하세요" maxLength="20" /></td>{/* 용법 */}
                </tr>
              }
              {(i > 0) &&
                <tr key={i}>
                  <td colSpan="4"><br /></td>
                  <td><br /></td>
                  <td><br /></td>
                  <td><br /></td>
                  <td colSpan="2"><br /></td>
                </tr>
              }
            </React.Fragment>))}

            <tr>
              <td align="left" colSpan="7">
                주사제 처방명세
                <div className="wrap-type-btn">
                  (<button className={injectionType === "inner_clinic" ? "select" : ""} onClick={() => setInjectionType("inner_clinic")}>원 내 조제,</button>
                  <button className={injectionType === "outer_clinic" ? "select" : ""} onClick={() => setInjectionType("outer_clinic")}>원 외 처방</button>)
                </div>
              </td>
              <td colSpan="2">조제시 참고사항</td>
            </tr>
            {injectionList && injectionList.map((v, i) => (
              //'처방 의약품의 명칭'뒤 텍스트 추가: ('규격', '단위')
              //ex)약품명(규격, 단위) = 팜피린 0.05mg(1정, 500ml)
              <tr key={i}>
                <td colSpan="4" align="left">
                  <button className="btn btn-del btn-ssm m-r-8" onClick={() => removeMedicine("injection", i)}>-</button>
                  [{v.drugId}]{v.drugName}({v.dosage || ''}{v.unit || ''})
                </td>{/* 처방 의약품의 명칭 */}
                <td><Input type="number" step="0.25" value={v.dosageOnce || 1} onChange={(e) => editDose("injection", i, { dosageOnce: e.target.value || 1 })} placeholder="1" /></td>{/* 1회 투약량 */}
                <td><Input type="number" step="1" value={v.dosageDaily || 1} onChange={(e) => editDose("injection", i, { dosageDaily: e.target.value || 1 })} placeholder="1" /></td>{/* 1일 투여횟수 */}
                <td><Input type="number" step="1" value={v.dosageTotalDay || 1} onChange={(e) => editDose("injection", i, { dosageTotalDay: e.target.value || 1 })} placeholder="1" /></td>{/* 총 투약일수 */}
                {(i === 0) && <td colSpan="2" rowSpan="3"><br /></td>}
              </tr>
            ))}
            {[...Array(maxInjectionLine - injectionList.length)].map((v, i) => (<React.Fragment key={i}>
              {(i === 0) &&
                <tr key={i}>
                  <td colSpan="4"><Input onClick={() => searchMedicine("injection")} placeholder="입력하세요" readOnly /></td>{/* 주사제 처방명세 */}
                  <td><Input type="number" step="0.25" value={injectionDose.dosageOnce || ""} onChange={(e) => setInjectionDose({ ...injectionDose, dosageOnce: e.target.value || 1 })} placeholder="1" /></td>
                  <td><Input type="number" step="1" value={injectionDose.dosageDaily || ""} onChange={(e) => setInjectionDose({ ...injectionDose, dosageDaily: e.target.value || 1 })} placeholder="1" /></td>
                  <td><Input ype="number" step="1" value={injectionDose.dosageTotalDay || ""} onChange={(e) => setInjectionDose({ ...injectionDose, dosageTotalDay: e.target.value || 1 })} placeholder="1" /></td>
                  {(i === 0 && injectionList.length === 0) && <td colSpan="2" rowSpan="3"><br /></td>}
                </tr>
              }
              {(i > 0) &&
                <tr key={i}>
                  <td colSpan="4"><br /></td>
                  <td><br /></td>
                  <td><br /></td>
                  <td><br /></td>
                  {/* <td colSpan="2" rowSpan="3"><br /></td> */}
                </tr>
              }
            </React.Fragment>))}

            <tr>
              <td>사용기간</td>
              <td colSpan="3">발급일로부터 (<Input widthSize="40" placeholder="7" value={dueUntil} onChange={(e) => setDueUntil(e.target.value)} />)일간</td>
              <td colSpan="5">사용기간 내에 약국에 제출하여야 합니다.</td>
            </tr>
            <tr>
              <td colSpan="9" bgcolor="#D9D9D9">의약품 조제명세</td>
            </tr>
            <tr>
              <td rowSpan="4">조제명세</td>
              <td>조제기관의<br />명칭</td>
              <td colSpan="4"><br /></td>
              <td colSpan="3" rowSpan="4" className="top left">처방의 변경, 수정, 확인, 대체시 그 내용 등</td>
            </tr>
            <tr>
              <td>조제약사</td>
              <td colSpan="4">
                <p align="left">성명</p>
                <p align="right">(서명 또는 인)</p>
              </td>
            </tr >
            <tr>
              <td>조제량<br />(조제일수)</td>
              <td colSpan="4"><br /></td>
            </tr>
            <tr>
              <td>조제연월일</td>
              <td colSpan="4"><br /></td>
            </tr>
            <tr>
              <td colSpan="9"><br /></td>
            </tr>
          </tbody >
        </table >
      </div>
      )})}
      </div>
    </>
  );
};

PrescriptionTable.propTypes = {
  bundleId: PropTypes.number,
  setInfo: PropTypes.func,
  buttonRef: PropTypes.object,
  printId: PropTypes.number,
  setPrintId: PropTypes.func,
  printMode: PropTypes.bool,
};

export default observer(PrescriptionTable);
