import React from 'react';
import './ctiCheckbox.scss';
import PropTypes from 'prop-types';

export const CtiCheckbox = ({ id, className, ...props }) => {
  return (
    <div className={`cti-checkbox-wrapper ${className}`}>
      <input type="checkbox" className="cti-checkbox" id={id} {...props} />
      <label htmlFor={id}></label>
    </div>
  );
};

CtiCheckbox.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
};
