import React from 'react';
import classNames from 'classnames';
import './ctiFormSelect.scss';
import PropTypes from 'prop-types';

export const CtiFormSelect = ({ error, children, className, ...props }) => {
  return (
    <div
      className={classNames('cti-select-wrapper', className, {
        error: Boolean(error),
      })}
    >
      <select {...props}>{children}</select>
      {/* {error && <div className="cti-form-error">{error}</div>} */}
    </div>
  );
};

CtiFormSelect.propTypes = {
  error: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
};
