export const banks = [
  { key: '산업은행', value: '002' },
  { key: '기업은행', value: '003' },
  { key: '국민은행', value: '004' },
  { key: '수협중앙회', value: '007' },
  { key: '농협중앙회', value: '011' },
  { key: '우리은행', value: '020' },
  { key: 'SC제일은행', value: '023' },
  { key: '시티은행', value: '027' },
  { key: '대구은행', value: '031' },
  { key: '부산은행', value: '032' },
  { key: '광주은행', value: '034' },
  { key: '제주은행', value: '035' },
  { key: '전북은행', value: '037' },
  { key: '경남은행', value: '039' },
  { key: '새마을금고', value: '045' },
  { key: '신협중앙회', value: '048' },
  { key: '우체국', value: '071' },
  { key: 'KEB하나', value: '081' },
  { key: '신한은행', value: '088' },
  { key: '케이뱅크', value: '089' },
  { key: '카카오뱅크', value: '090' },
  { key: '유안타증권', value: '209' },
  { key: '삼성증권', value: '240' },
];

export const agencies = [
  { key: '없음', value: '000' },
  { key: '클래버', value: '001' },
  { key: '바이브알', value: '002' },
];
