import styled, { css } from 'styled-components';

export const Input = styled.input`
  font-size: 14px;
  border: none;
  display: block;
  width: 460px;
  padding: 18px 30px 17px;
  background-color: #f3f8ff;
  height: 52px;

  &::placeholder {
    color: hsla(0, 0%, 100%, 0.5);
  }
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;

  p:before {
    content: '';
    left: 0;
    display: block;
    width: 1px;
    height: 12px;
    background-color: #dee2ec;
    margin: 0 10px;
  }

  p:first-child:before {
    display: none;
  }
`;

export const P = styled.p`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Title = styled.span`
  color: #4a4e70;
  font-weight: bold;

  ${({ underline }) =>
    underline === 'active' &&
    css`
      text-decoration: underline;
    `}
`;

export const Contents = styled.span`
  margin: 0 5px;
  color: #4a4e70;
  font-weight: 400;
`;

export const Copyright = styled.div`
  color: #a1b1ca;
`;

export const FooterWrapper = styled.div`
  width: 620px;
  font-size: 12px;
  gap: 15px;
  display: flex;
  flex-direction: column;
  margin-bottom: 100px;
`;

export const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const LoginWrapper = styled.div`
  height: 100%;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  gap: 4;
`;
