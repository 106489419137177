import React, { useRef, useCallback, useState } from 'react';
import { observer } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSmile as fasSmile } from '@fortawesome/free-regular-svg-icons';
import {
  faHashtag,
  faLink,
  faFolderOpen,
  faTimesCircle,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import { translate } from 'filters';
import { useAuth } from 'store/auth';
import { useModal } from 'hooks/useModal';
import { useServices } from 'hooks/useServices';
import { FileInput } from 'components/common/FileInput';

const MessageAppTabBar = ({
  obj,
  setObj,
  setUiObj,
  contentsRef,
  modalBoilerplateSelector,
  hideBabitalkLink,
}) => {
  const auth = useAuth();
  const modal = useModal();
  const services = useServices();
  const imageFileInputRef = useRef();
  const [dropupIsOpen, setDropupIsOpen] = useState(false);
  const [babitalkLinkIsOpen, setBabitalkLinkIsOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState(null);
  const onImageChangeHandler = useCallback(
    (e) => {
      e.preventDefault();
      const files = e.target.files;
      const allowFileType = ['image/jpeg', 'image/png'];
      if (!files || files.length === 0) {
        return;
      }
      if (files.length > 1) {
        alert(translate('ERROR_EXCEEDED_IMAGES'));
        console.error('비정상 시도.'); // 이 시도의 로깅고려
        return;
      }
      let reader = new FileReader();
      let file = files[0]; // 파일은 단일 업로드만 지원.
      if (file.size > 1024 * 1024) {
        alert(translate('ERROR_EXCEEDED_IMAGE_SIZE_1MB'));
        return;
      }

      if (!allowFileType.includes(file.type)) {
        alert(translate('ERROR_INVALID_IMAGE_EXT'));
        return;
      }

      reader.onloadend = () => {
        setObj((obj) => ({
          ...obj,
          images: [],
          imageFiles: [file],
        }));

        setUiObj((uiObj) => ({
          ...uiObj,
          previewImages: [
            {
              isUploaded: false,
              url: reader.result,
            },
          ],
        }));
      };

      e.target.value = null;
      reader.readAsDataURL(file);
    },
    [setObj, setUiObj]
  );

  const babitalkMideaConnectCheckApi = useCallback(async () => {
    //const clinicId = auth.me.clinic.id;
    //const resp = await services.crm.clinic.detail(clinicId);
    const resp = await services.crm.consultingRequest.connect.all();
    if (!resp) return;
    const babitalkUri =
      resp.data && resp.data.some((v) => v.media && v.media.tag === '바비톡');
    return babitalkUri;
  }, [services.crm.consultingRequest.connect]);

  const onClickBabitalkLink = useCallback(
    async (e) => {
      e.preventDefault();
      const tabRole = e.currentTarget.getAttribute('tabrole');
      setSelectedTab(tabRole);

      if (!babitalkLinkIsOpen) {
        const response = await babitalkMideaConnectCheckApi();
        if (!response) {
          modal.basic({
            body: `바비톡과 상담문의 자동연동이 되어있지 않습니다.
          상담문의 자동연동 설정 후 이용이 가능합니다.`,
            buttons: [
              {
                text: 'CONFIRM',
                class: 'btn-primary',
              },
            ],
          });
          return;
        }

        setObj({
          ...obj,
          smsType: 'event',
          isBabitalkUri: true,
        });
      } else {
        setObj({
          ...obj,
          smsType: 'normal',
          isBabitalkUri: false,
        });
      }

      setBabitalkLinkIsOpen(!babitalkLinkIsOpen);
    },
    [modal, babitalkLinkIsOpen, obj, setObj, babitalkMideaConnectCheckApi]
  );

  const toggleDropup = useCallback(
    async (e) => {
      e.preventDefault();
      const tabRole = e.currentTarget.getAttribute('tabrole');
      setSelectedTab(tabRole);

      if (dropupIsOpen && selectedTab && selectedTab !== tabRole) {
        return;
      }

      setDropupIsOpen(!dropupIsOpen);
    },
    [selectedTab, dropupIsOpen]
  );

  const addContents = useCallback(
    (addValue) => {
      const { selectionStart, selectionEnd } = contentsRef.current;
      const valueLength = contentsRef.current.value.length
        ? contentsRef.current.value.length
        : 0;
      const beforeCursorValue = contentsRef.current.value.substring(
        0,
        selectionStart
      );
      const afterCursorValue = contentsRef.current.value.substring(
        selectionEnd,
        valueLength
      );

      const joinedValue = `${beforeCursorValue}${addValue}${afterCursorValue}`;

      contentsRef.current.value = joinedValue;

      setObj((obj) => ({
        ...obj,
        contents: joinedValue,
      }));

      contentsRef.current.selectionStart = selectionStart + addValue.length;
      contentsRef.current.selectionEnd = selectionStart + addValue.length;
      contentsRef.current.focus();
    },
    [contentsRef, setObj]
  );

  const onInsertHandler = useCallback(
    (e) => {
      e.preventDefault();
      const currentValue = e.currentTarget.getAttribute('value');
      addContents(currentValue);
    },
    [addContents]
  );

  const dropupBody = useCallback(
    ({ type }) => {
      const specialCharacterList = [
        '※',
        '☆',
        '★',
        '○',
        '●',
        '◎',
        '◇',
        '◆',
        '□',
        '■',
        '△',
        '▲',
        '▽',
        '▼',
        '→',
        '←',
        '↑',
        '↓',
        '↔',
        '◁',
        '◀',
        '▷',
        '▶',
        '♤',
        '♠',
        '♡',
        '♥',
        '♧',
        '♣',
        '⊙',
        '◈',
        '▣',
        '◐',
        '◑',
        '￥',
        '＄',
        '￦',
      ];

      // locale 항목. 다국어대상 아님.
      let variableList = [];
      // *변수 사용 정의*
      // 상용구 > 고객명, 병원명, 예약일, 예약시간
      // 자동문자전송 > 고객명, 병원명, 예약일, 예약시간 / 상황이 예약완료,예약취소일때는 대부서명, 세부부서명도 추가로 사용
      // 주기별 문자 자동전송 > 고객명, 병원명
      // 현황판/예약캘린더 > 고객명, 병원명, 예약일, 예약시간, 대부서명, 세부부서명
      // 약도전송 > 고객명, 병원명, 예약일, 예약시간, 대부서명, 세부부서명
      // 예약조회 > 고객명, 병원명, 예약일, 예약시간, 대부서명, 세부부서명
      // 다른 조회페이지 > 고객명, 병원명

      //smsSituation> 문자 자동 전송 || category > 상용구 추가
      if ((obj || {}).smsAppointmentCheck === true) {
        //smsAppointmentCheck: 예약캘린더/현황판 등에서 예약일, 예약시간을 사용할 수 있도록 플래그 전달
        variableList = [
          `#{고객명}`,
          `#{병원명}`,
          `#{예약일}`,
          `#{예약시간}`,
          `#{대부서명}`,
          `#{세부부서명}`,
        ];
      } else if (
        //아래가 원본 코드
        /*
        obj.hasOwnProperty('smsSituation') ||
        obj.hasOwnProperty('category')
        */
        Object.prototype.hasOwnProperty.call(obj, 'smsSituation') ||
        Object.prototype.hasOwnProperty.call(obj, 'category')
      ) {
        if (
          obj.smsSituation === 'reserved' ||
          obj.smsSituation === 'cancel' ||
          obj.smsSituation === 'unvisited' ||
          obj.smsSituation === 'leave'
        ) {
          //전송 상황이 예약완료, 예약취소, 미방문, 퇴원 일때만 대부서명, 세부부서명 사용 가능
          variableList = [
            `#{고객명}`,
            `#{병원명}`,
            `#{예약일}`,
            `#{예약시간}`,
            `#{대부서명}`,
            `#{세부부서명}`,
          ];
        } else if (obj.smsSituation === 'treatment_item') {
          variableList = [`#{고객명}`, `#{병원명}`];
        } else {
          variableList = [`#{고객명}`, `#{병원명}`, `#{예약일}`, `#{예약시간}`];
        }
      } else {
        variableList = [`#{고객명}`, `#{병원명}`];
      }

      const homepageUri = String(auth.me.clinic.homepageUri);
      const babitalkUri = String(auth.me.clinic.babitalkUri);
      const bodyCompObj = {
        CHARACTERS: () => (
          <ul className="dropup-body-characters">
            {specialCharacterList.map((c, idx) => (
              <li key={idx} value={c} onClick={onInsertHandler}>
                {c}
              </li>
            ))}
          </ul>
        ),
        VARIABLES: () => (
          <ul className="dropup-body-variables">
            {variableList.map((c, idx) => (
              <li key={idx} value={c} onClick={onInsertHandler}>
                {c}
              </li>
            ))}
          </ul>
        ),
        LINKS: () => (
          <ul className="dropup-body-links">
            <li value={homepageUri} onClick={onInsertHandler}>
              <span>{translate('CLINIC_HOMEPAGE_LINK')}</span>
            </li>
            <li value={babitalkUri} onClick={onInsertHandler}>
              <span>{translate('CLINIC_BABITALK_LINK')}</span>
            </li>
          </ul>
        ),
      };
      return bodyCompObj[type]();
    },
    [auth, obj, onInsertHandler]
  );

  const openModalBoilerplate = useCallback(() => {
    modal
      .custom({
        component: modalBoilerplateSelector,
      })
      .then(({ payload }) => {
        if (payload) {
          setObj((obj) => ({
            ...obj,
            ...payload,
          }));

          if (payload.images) {
            setUiObj((uiObj) => ({
              ...uiObj,
              previewImages: payload.images.map((imageObj) => ({
                isUploaded: true,
                url: imageObj.url,
                id: imageObj.id,
              })),
            }));
          }
        }
      })
      .catch((error) => {
        // 2중모달 비정상 종료 케이스.
        console.info('Unexpected case. - modal close\n', error);
      });
  }, [modal, modalBoilerplateSelector, setObj, setUiObj]);

  return (
    <div className={`app-view-tab-bar ${dropupIsOpen ? ' toggled ' : ' '} `}>
      {/* selectedTab === LINKS 를 제외하고 tab-dropup 노출 */}
      {selectedTab !== 'LINKS' ? (
        <div className="tab-dropup">
          <div className="dropup-header">
            <div className="dropup-header-title">
              <div className="dropup-header-icon">
                <div className="close-icon display-normal">
                  <FontAwesomeIcon icon={faChevronRight} />
                </div>
              </div>
              <span>{translate(`LABEL_SCREEN_TAB_ITEM_${selectedTab}`)}</span>
            </div>
            <div
              className="dropup-header-close"
              onClick={() => setDropupIsOpen(false)}
            >
              <FontAwesomeIcon icon={faTimesCircle} />
            </div>
          </div>
          <div className="dropup-body">
            {selectedTab && dropupBody({ type: selectedTab })}
          </div>
        </div>
      ) : (
        ''
      )}
      <div className="tab-control">
        {/* <div className="tab-header">
          <div className="tab-header-icon">
            <FontAwesomeIcon  icon={fasPlusSquare} />
          </div>
        </div> */}
        <div className="tab-items-group">
          <TabItem
            className={`${
              dropupIsOpen && selectedTab === 'CHARACTERS' ? ' active ' : ' '
            }`}
            iconClassName="ico-character"
            tabrole={'CHARACTERS'}
            icon={fasSmile}
            mask={['far']}
            text={translate('LABEL_SCREEN_TAB_ITEM_CHARACTERS')}
            onClick={(e) => {
              toggleDropup(e);
            }}
          />
          <TabItem
            icon={faFolderOpen}
            iconClassName="ico-text"
            text={translate('LABEL_SCREEN_TAB_ITEM_BOILERPLATE')}
            onClick={(e) => {
              if (!babitalkLinkIsOpen) openModalBoilerplate(e);
            }}
          />
          <TabItem
            className={`${
              dropupIsOpen && selectedTab === 'VARIABLES' ? ' active ' : ' '
            }`}
            iconClassName="ico-variables"
            tabrole={'VARIABLES'}
            icon={faHashtag}
            text={translate('LABEL_SCREEN_TAB_ITEM_VARIABLES')}
            onClick={(e) => {
              toggleDropup(e);
            }}
          />
          {hideBabitalkLink || (
            <TabItem
              className={`${
                babitalkLinkIsOpen && selectedTab === 'LINKS' ? ' active ' : ' '
              }`}
              iconClassName="ico-links"
              tabrole={'LINKS'}
              icon={faLink}
              text={translate('LABEL_SCREEN_TAB_ITEM_LINKS')}
              onClick={(e) => {
                onClickBabitalkLink(e);
              }}
            />
          )}
          <div
            className="tab-item tooltip"
            onClick={() => {
              imageFileInputRef.current.click();
            }}
            tooltip-class={
              hideBabitalkLink
                ? 'message-upload-image'
                : 'babitalk-message-upload-image'
            }
            tooltip-message={translate('ERROR_IMAGE_VALIDATE')}
          >
            {/* <div className="tab-item" onClick={e=>{openImageUploadGuide()}} > */}
            <div className="tab-item-icon ico-image">
              {/* <span className="fa-layers fa-fw"> */}
              {/* <FontAwesomeIcon icon={faImage}/> */}
              {/* 최대 1개. 숫자는 넣지 않음. */}
              {/* {uiObj.previewImages.length > 0 && (
                  <span className="fa-layers-counter fa-1x" />
                )} */}
              {/* </span> */}
            </div>
            <span className="tab-item-text">
              {translate('LABEL_SCREEN_TAB_ITEM_IMAGE')}
            </span>
            <FileInput
              ref={imageFileInputRef}
              accept="image/jpeg"
              onChange={(e) => onImageChangeHandler(e)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

MessageAppTabBar.propTypes = {
  obj: PropTypes.object,
  setObj: PropTypes.func,
  setUiObj: PropTypes.func,
  contentsRef: PropTypes.object,
  modalBoilerplateSelector: PropTypes.object,
  hideBabitalkLink: PropTypes.bool,
};

const TabItem = ({
  className = '',
  iconClassName = '',
  text,
  onClick,
  value,
  tabrole,
}) => {
  return (
    <div
      className={`tab-item ${className}`}
      value={value}
      tabrole={tabrole}
      onClick={(e) => {
        if (onClick) onClick(e);
      }}
    >
      <div className={`tab-item-icon ${iconClassName}`}>
        {/* <FontAwesomeIcon  icon={icon} mask={mask}/> */}
      </div>
      <span className="tab-item-text">{text}</span>
    </div>
  );
};

TabItem.propTypes = {
  className: PropTypes.string,
  iconClassName: PropTypes.string,
  text: PropTypes.string,
  onClick: PropTypes.func,
  value: PropTypes.object,
  tabrole: PropTypes.string,
};

export default observer(MessageAppTabBar);
