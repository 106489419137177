import React from 'react';
import { observer } from 'mobx-react';
import ConditionRow from './ConditionRow';

const ConditionRowList = ({
  conditions,
  onCreateGroup,
  onRemoveGroup,
  onCreateCondition,
  onChangeCondition,
  onRemoveCondition,
  selectedConditions,
  groupIdx,
  groupId,
}) => {
  return (conditions ? conditions : []).map(
    ({ uiKeyName, query, conditionId, include }, idx) => (
      <ConditionRow
        //  key={idx}
        key={conditionId}
        rowIdx={idx}
        onCreateGroup={onCreateGroup}
        onRemoveGroup={onRemoveGroup}
        onCreate={onCreateCondition}
        onChange={onChangeCondition}
        onRemove={onRemoveCondition}
        selectedConditions={selectedConditions}
        include={include}
        uiKeyName={uiKeyName}
        query={query}
        conditionId={conditionId}
        groupIdx={groupIdx}
        groupId={groupId}
      />
    )
  );
};

export default observer(ConditionRowList);
