import * as $http from 'axios';

const surgery = {
  create: (payload) => $http.post('surgeries', payload),
  all: (params) => $http.get('surgeries', { params }),
  detail: (surgeryId) => $http.get(`surgeries/${surgeryId}`),
  update: (payload) => $http.put(`surgeries/${payload.id}`, payload),
  delete: (surgeryId) => $http.delete(`surgeries/${surgeryId}`),
  excel_download_v2: (params) =>
    $http.get('/surgeries/v2/excel', { params, responseType: 'blob' }),
  excel_check_download_v2: (params) =>
    $http.get('/surgeries/v2/excel/check/download', {
      params,
      responseType: 'blob',
    }),
};

export default surgery;
