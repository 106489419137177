import moment from 'moment';
import { currency, currencySymbol } from 'filters';

export default {
  keys: [
    {
      title: '일자',
      key: 'paidAt',
      column: 'paidAt',
      orderKey: 'paidAt',
      order: 'desc',
      columnGroup: false,
      filter: (o) => {
        //total row는 paidAt이 string 'Total'로 되어 있음
        if (o !== undefined) {
          if (o.paidAt === 'Total') {
            return 'Total';
          } else {
            return `<div><span class="text-underline c-primary cursor-pointer">${moment(
              o.paidAt
            ).format('YYYY-MM')}</span></div>`;
          }
        }
      },
    },
    {
      title: '월 매출액',
      key: 'totalAmount',
      column: 'totalAmount',
      columnGroup: false,
      filter: (o) => {
        return `${currencySymbol()} ${currency((o || {}).totalAmount || 0)}`;
      },
    },
    {
      title: '월 수납액',
      columnGroup: true,
      columnItems: [
        { title: 'Total', key: 'sumAmount', filter: { type: 'amount' } },
        {
          title: '신용카드',
          key: 'creditCardAmount',
          filter: { type: 'amount' },
        },
        {
          title: '체크카드',
          key: 'debitCardAmount',
          filter: { type: 'amount' },
        },
        {
          title: '계좌이체',
          key: 'bankTransferAmount',
          filter: { type: 'amount' },
        },
        { title: '현금', key: 'cashAmount', filter: { type: 'amount' } },
      ],
    },
    {
      title: '월 환불액',
      column: 'refundAmount',
      columnGroup: true,
      columnItems: [
        { title: 'Total', key: 'refundAmount', filter: { type: 'amount' } },
        {
          title: '신용카드',
          key: 'refundCreditCardAmount',
          filter: { type: 'amount' },
        },
        {
          title: '체크카드',
          key: 'refundDebitCardAmount',
          filter: { type: 'amount' },
        },
        {
          title: '계좌이체',
          key: 'refundBankTransferAmount',
          filter: { type: 'amount' },
        },
        { title: '현금', key: 'refundCashAmount', filter: { type: 'amount' } },
      ],
    },
    {
      title: '월 실매출액',
      key: 'paidAmount',
      column: 'paidAmount',
      columnGroup: false,
      filter: (o) => {
        // 실매출액 : '수납 total 금액' - '환불액'
        let paidAmount = 0;
        if (o !== undefined) {
          paidAmount = (o || {}).paidAmount - (o || {}).refundAmount;
        }
        return `${currencySymbol()} ${currency(paidAmount)}`;
      },
    },
    {
      title: '현금영수증 발급액',
      key: 'cashReceiptAmount',
      column: 'cashReceiptAmount',
      columnGroup: false,
      filter: (o) => {
        return `${currencySymbol()} ${currency(
          (o || {}).cashReceiptAmount || 0
        )}`;
      },
    },
  ],
};
