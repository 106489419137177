import React, { useState, useCallback, useEffect } from 'react';
import { observer } from 'mobx-react';
import DataTableAlpha from 'components/data-table/DataTableAlpha';
import HeaderRowSpanDataTable from 'components/data-table/HeaderRowSpanDataTable';
import InputDatePickerRange from 'components/common/InputDatePickerRange';
import SmsMessagesDetail from 'components/pages/crm/message-histories/SmsMessagesDetail';
import EventMessagesDetail from 'components/pages/crm/message-histories/EventMessagesDetail';
import ModalMessageDetail from 'components/modals/codes/message-codes/ModalMessageDetail';
import ModalMessageSendAppointmentsDetail from 'components/modals/codes/message-codes/ModalMessageSendAppointmentsDetail';
import moment from 'moment';
import modelMessageHistorySms from 'models/message-history-sms';
import modelMessageHistoryEventResult from 'models/message-history-event-result';
import modelMessageHistoryEventWaiting from 'models/message-history-event-waiting';
import { translate } from 'filters';
import { $qb } from 'scripts/querybuilder';
import { useModal } from 'hooks/useModal';
import { useServices } from 'hooks/useServices';
import { useToast } from 'hooks/useToast';
import styled from 'styled-components';

const InputWrapper = styled.div`
  margin-right: 4px;
`;

const Input = styled.input`
  padding: 8px 10px 8px 20px;
  width: 140px;
  border-radius: 4px;
  font-size: 14px;
  border: 1px solid #dee2ec;
  height: 34px;
`;

const SmsMessages = () => {
  const modal = useModal();
  const services = useServices();
  const toast = useToast();
  const [data, setData] = useState([]);
  const [normalData, setNormalData] = useState([]); //전송예약중인 일반문자
  const [total, setTotal] = useState(0);
  const [normalTotal, setNormalTotal] = useState(0);
  const defaultParams = $qb()
    .limit(10)
    .orderBy('scheduledAt desc')
    .customParam('smsType', 'normal');
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false);
  const [params, setParams] = useState(defaultParams);
  const TAB_TYPES = {
    RESULTS: 'results',
    WAITING: 'waiting',
    EVENT_RESULTS: 'event_results',
    EVENT_WATING: 'event_waiting',
  };
  const [selectedTab, setSelectedTab] = useState(TAB_TYPES.RESULTS);

  const [selectedDate, setSelectedDate] = useState({
    scheduledStartAt: moment().format('YYYY-MM-DD'),
    scheduledEndAt: moment().format('YYYY-MM-DD'),
  });

  const [searchParams, setSearchParams] = useState({
    name: '',
    phoneNumber: '',
  });

  const onChangeTab = (value) => {
    setSearchParams({
      name: '',
      phoneNumber: '',
    });
    setParams(defaultParams);
    setSelectedTab(value);
  };

  const onChangeDate = (e) => {
    const { value } = e.target;

    setParams(defaultParams);
    if (selectedTab !== TAB_TYPES.EVENT_RESULTS) {
      setSelectedDate(value);
    } else {
      setSelectedDate(value);
    }
  };

  const onClickSelectDate = (type) => {
    let startDate, endDate;

    if (type === 'today') {
      startDate = new Date();
      endDate = new Date();
    } else if (type === 'week') {
      const now = new Date();
      const nowDayOfWeek = now.getDay();
      const nowDay = now.getDate();
      const nowMonth = now.getMonth();
      let nowYear = now.getYear();
      nowYear += nowYear < 2000 ? 1900 : 0;
      startDate = new Date(nowYear, nowMonth, nowDay - nowDayOfWeek);
      endDate = new Date(nowYear, nowMonth, nowDay + (6 - nowDayOfWeek));
    } else if (type === 'yesterday') {
      startDate = new Date(new Date().setDate(new Date().getDate() - 1));
      endDate = new Date(new Date().setDate(new Date().getDate() - 1));
    } else if (type === 'lastweek') {
      const now = new Date(new Date().setDate(new Date().getDate() - 7));
      const nowDayOfWeek = now.getDay();
      const nowDay = now.getDate();
      const nowMonth = now.getMonth();
      let nowYear = now.getYear();
      nowYear += nowYear < 2000 ? 1900 : 0;
      startDate = new Date(nowYear, nowMonth, nowDay - nowDayOfWeek);
      endDate = new Date(nowYear, nowMonth, nowDay + (6 - nowDayOfWeek));
    }

    const _event = {
      target: {
        name: 'date',
        value: {
          scheduledStartAt: moment(startDate).format('YYYY-MM-DD'),
          scheduledEndAt: moment(endDate).format('YYYY-MM-DD'),
        },
      },
    };
    onChangeDate(_event);
  };

  const callApi = useCallback(async () => {
    setLoading(true);
    const eventResultParmas = $qb()
      .limit(10)
      .page(1)
      .orderBy(params.queryParams.orderBy)
      .customParam('smsType', 'event')
      .build();

    const requestParams = {
      ...(selectedTab === TAB_TYPES.RESULTS && params.build()),
      ...(selectedTab === TAB_TYPES.RESULTS && {
        ...selectedDate,
        smsType: 'normal',
      }),
      ...(selectedTab === TAB_TYPES.WAITING && {
        status: 'waiting',
        smsScheduleType: 'scheduled',
        smsType: 'normal',
        orderBy: params.queryParams.orderBy,
      }),
      ...(selectedTab === TAB_TYPES.EVENT_RESULTS && {
        ...eventResultParmas,
        ...selectedDate,
      }),
      ...(selectedTab === TAB_TYPES.EVENT_WATING && {
        status: 'waiting',
        smsScheduleType: 'scheduled',
        smsType: 'event',
        orderBy: params.queryParams.orderBy,
      }),
    };

    if (params.queryParams.name) requestParams.name = params.queryParams.name;
    if (params.queryParams.phoneNumber)
      requestParams.phoneNumber = params.queryParams.phoneNumber;

    if (
      selectedTab === TAB_TYPES.RESULTS ||
      selectedTab === TAB_TYPES.EVENT_RESULTS
    ) {
      //일반문자 전송결과 , 광고문자 전송결과
      const resp = await services.crm.statistics.statistics.sms_daily(
        requestParams
      );
      if (!resp) return;
      const result = resp.data.map((v, i) => {
        v.id = i;
        return v;
      });

      setData([...result]);
      setTotal(resp.total);
    } else {
      //전송예약중인 문자
      const resp = await services.crm.notification.smsNotifications.ad.all(
        requestParams
      );
      if (!resp) return;

      const changeData = resp.data.map((v) => {
        v.mini_contents = v.contents;
        v.name =
          v.totalCount > 1 ? v.name + ` 외 ${v.totalCount - 1}명` : v.name;
        return v;
      });
      setLoading(false);
      setNormalData(changeData);
      setNormalTotal(resp?.total);
    }
  }, [
    services.crm.statistics.statistics,
    params,
    selectedDate,
    selectedTab,
    services.crm.notification.smsNotifications,
  ]);

  useEffect(() => {
    callApi();
  }, [callApi]);

  const onParams = (p) => {
    setParams(p);
    callApi();
  };

  const openModalBoilerplate = useCallback(
    ({ row }) => {
      modal.custom({
        component: ModalMessageDetail,
        options: { boilerplateMessage: row },
      });
    },
    [modal, callApi]
  );

  const openModalDelete = ({ row }) => {
    const onConfirm = async () => {
      try {
        const params = { id: row['smsHistoryId'] };
        const resp = await services.crm.notification.smsNotifications.delete(
          params.id
        );

        if (resp.data && resp.data.success) {
          modal
            .confirm({
              type: 'SUCCESS',
              msg: '취소되었습니다.',
            })
            .then(() => {
              callApi();
            });
        } else {
          modal
            .confirm({
              type: 'SUCCESS',
              msg: '실패되었습니다.',
            })
            .then(() => {
              callApi();
            });
        }
      } catch (e) {
        console.log(e.description);
        toast.error(e.description);
      }
    };

    modal
      .basic({
        body: translate('CONFIRM_CANCEL'),
        buttons: [
          {
            text: 'CANCEL',
            class: 'btn-default',
          },
          {
            text: 'CONFIRM',
            class: 'btn-danger',
          },
        ],
      })
      .then((idx) => {
        if (idx === 1) {
          onConfirm();
        }
      });
  };

  const onClickHeaderRow = (row) => {
    let orderBy = `${row.column} ${row.order}`;
    params.orderBy(orderBy);

    setParams(params);
    callApi();
  };

  const onAction = (obj) => {
    const { row, key, event } = obj; // , rowIdx

    if (key.title === 'TITLE_AND_CONTENT') {
      openModalBoilerplate(obj);
    }

    if (event.target.dataset.type === 'delete') {
      openModalDelete({ row, key, event });
    }

    if (key.title === '고객명') {
      openModalMessageDetail(obj);
    }
  };

  const openModalMessageDetail = useCallback(
    ({ row }) => {
      modal.custom({
        component: ModalMessageSendAppointmentsDetail,
        options: { smsHistoryId: row.smsHistoryId },
      });
    },
    [modal]
  );

  const onClickSearch = () => {
    params.queryParams = {
      limit: params.queryParams.limit,
      orderBy: params.queryParams.orderBy,
      smsType: 'event',
      ...searchParams,
    };

    setParams(params);
    callApi();
  };

  return (
    <div className="message-history-container">
      <div className="page-navi">
        <span className="title">문자 관리</span>
        <span className="title">전송내역 조회</span>
      </div>
      <div className="tabs">
        <div
          className={`tab ${
            selectedTab === TAB_TYPES.RESULTS ? 'selected' : ''
          }`}
          onClick={() => {
            onChangeTab(TAB_TYPES.RESULTS);
          }}
        >
          일반문자 전송결과
        </div>
        <div
          className={`tab ${
            selectedTab === TAB_TYPES.WAITING ? 'selected' : ''
          }`}
          onClick={() => {
            onChangeTab(TAB_TYPES.WAITING);
          }}
        >
          전송예약중인 일반문자
        </div>
        <div
          className={`tab ${
            selectedTab === TAB_TYPES.EVENT_RESULTS ? 'selected' : ''
          }`}
          onClick={() => {
            onChangeTab(TAB_TYPES.EVENT_RESULTS);
          }}
        >
          광고문자 전송결과
        </div>
        <div
          className={`tab ${
            selectedTab === TAB_TYPES.EVENT_WATING ? 'selected' : ''
          }`}
          onClick={() => {
            onChangeTab(TAB_TYPES.EVENT_WATING);
          }}
        >
          전송예약중인 광고문자
        </div>
      </div>
      <>
        {selectedTab === TAB_TYPES.RESULTS && (
          <div>
            <div className="search-inputs">
              <button
                className="btn btn-normal _small"
                onClick={() => {
                  onClickSelectDate('today');
                }}
              >
                오늘
              </button>
              <button
                className="btn btn-normal _small"
                onClick={() => {
                  onClickSelectDate('week');
                }}
              >
                이번 주
              </button>
              <button
                className="btn btn-normal _small"
                onClick={() => {
                  onClickSelectDate('yesterday');
                }}
              >
                어제
              </button>
              <button
                className="btn btn-normal _small"
                onClick={() => {
                  onClickSelectDate('lastweek');
                }}
              >
                지난 주
              </button>
              <InputDatePickerRange
                value={selectedDate}
                name={{ date: ['scheduledStartAt', 'scheduledEndAt'] }}
                onChange={onChangeDate}
              />
              <button className="btn btn-primary _small" onClick={callApi}>
                {translate('SEARCH')}
              </button>
            </div>
            <div className="page-navi">
              <span className="title">요약</span>
            </div>
            <div className="message-history-container total-row">
              {selectedDate !== null && (
                <HeaderRowSpanDataTable
                  rowKey="id"
                  model={modelMessageHistorySms}
                  data={data}
                  total={total}
                  bordered={true}
                  /*hideBottom={true}*/
                  params={params}
                  onParams={onParams}
                  onAction={onAction}
                  onClickHeaderRow={onClickHeaderRow}
                />
              )}
            </div>
            <div className="page-navi">
              <span className="title">상세내역</span>
            </div>
            <SmsMessagesDetail options={selectedDate} />
          </div>
        )}
        {/* {광고문자 전송결과} */}
        {selectedTab === TAB_TYPES.EVENT_RESULTS && (
          <div>
            <div className="search-inputs">
              <button
                className="btn btn-normal _small"
                onClick={() => {
                  onClickSelectDate('today');
                }}
              >
                오늘
              </button>
              <button
                className="btn btn-normal _small"
                onClick={() => {
                  onClickSelectDate('week');
                }}
              >
                이번 주
              </button>
              <button
                className="btn btn-normal _small"
                onClick={() => {
                  onClickSelectDate('yesterday');
                }}
              >
                어제
              </button>
              <button
                className="btn btn-normal _small"
                onClick={() => {
                  onClickSelectDate('lastweek');
                }}
              >
                지난 주
              </button>
              <InputDatePickerRange
                value={selectedDate}
                name={{ date: ['scheduledStartAt', 'scheduledEndAt'] }}
                onChange={onChangeDate}
              />
              <button className="btn btn-primary _small" onClick={callApi}>
                {translate('SEARCH')}
              </button>
            </div>
            <div className="page-navi">
              <span className="title">요약</span>
            </div>
            <div className="message-history-container total-row-event">
              {selectedDate !== null && (
                <HeaderRowSpanDataTable
                  rowKey="id"
                  model={modelMessageHistoryEventResult}
                  data={data}
                  total={total}
                  bordered={true}
                  /*hideBottom={true}*/
                  params={params}
                  onParams={onParams}
                  onAction={onAction}
                  onClickHeaderRow={onClickHeaderRow}
                />
              )}
            </div>
            <div className="page-navi">
              <span className="title">상세내역</span>
            </div>
            <EventMessagesDetail options={selectedDate} />
          </div>
        )}
        {/* 전송예약중인 일반문자 */}
        {(selectedTab === TAB_TYPES.WAITING ||
          selectedTab === TAB_TYPES.EVENT_WATING) && (
          <div className="reservation">
            <div className="search-inputs">
              <InputWrapper>
                <Input
                  name="name"
                  value={searchParams && searchParams.name}
                  placeholder={translate('LABEL_CONDITION_CUSTOMER_NAME')}
                  onKeyDown={(e) => e.keyCode == 13 && onClickSearch()}
                  onChange={(e) => {
                    setSearchParams({
                      ...searchParams,
                      name: e.target.value,
                    });
                  }}
                />
              </InputWrapper>
              <InputWrapper>
                <Input
                  name="phoneNumber"
                  value={searchParams && searchParams.phoneNumber}
                  placeholder={translate(
                    'LABEL_CONDITION_CUSTOMER_PHONENUMBER'
                  )}
                  onKeyDown={(e) => e.keyCode == 13 && onClickSearch()}
                  onChange={(e) => {
                    setSearchParams({
                      ...searchParams,
                      phoneNumber: e.target.value,
                    });
                  }}
                />
              </InputWrapper>
              <button className="btn btn-primary" onClick={onClickSearch}>
                {translate('SEARCH')}
              </button>
            </div>
            <div className="m-t-10">
              <DataTableAlpha
                className="header-style-classic"
                model={modelMessageHistoryEventWaiting}
                data={normalData}
                total={normalTotal}
                params={params}
                onParams={onParams}
                onAction={onAction}
                hideBottom={false}
              />
            </div>
          </div>
        )}
      </>
    </div>
  );
};

export default observer(SmsMessages);
