import React, { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import moment from 'moment';
import AppointmentsByStatus from './AppointmentsByStatus';
import Notices from './Notices';
import { translate } from 'filters';
import { $qb } from 'scripts/querybuilder';
import { useEvent } from 'hooks/useEvent';
import { useServices } from 'hooks/useServices';
import { useUI } from 'hooks/useUI';
import DatePicker from 'components/common/DatePicker';

const Home = () => {
  const eventBus = useEvent();
  const services = useServices();
  const ui = useUI();

  const [startAt, setStartAt] = useState(new Date());
  const [endAt, setEndAt] = useState(new Date());
  const [filters, setFilters] = useState({
    departmentCategory: null,
    counselor: null,
    doctor: null,
  });
  const [appointments, setAppointments] = useState([]);
  const [updatedStartAt, setUpdatedStartAt] = useState(null);
  const [departmentCategories, setDepartmentCategories] = useState([]);
  const [counselors, setCounselors] = useState([]);
  const [doctors, setDoctors] = useState([]);
  const [isMenuFold, setIsMenuFold] = useState(
    ui.isMenuFold && typeof ui.isMenuFold.home === 'boolean'
      ? ui.isMenuFold.home
      : false
  );

  const filtered = (list, filters) => {
    return list.filter(
      (v) =>
        v.deletedAt == null &&
        (filters.doctor ? v.doctorId == filters.doctor?.id : true) &&
        (filters.counselor ? v.counselorId == filters.counselor?.id : true) &&
        (filters.departmentCategory
          ? filters.departmentCategory.id === v.departmentCategoryId
          : true)
    );
  };

  const loadAppointments = useCallback(async () => {
    try {
      const query = $qb()
        .limit(1000)
        .customParam('startAt', moment(startAt).format('YYYY-MM-DD'))
        .customParam('endAt', moment(endAt).format('YYYY-MM-DD'))
        .customParam('departmentVisible', true)
        .customParam('updatedStartAt', updatedStartAt)
        .customParam('includeDeleted', true);

      const resp = await services.crm.crud.appointment.list(query.build());
      if (!resp) return;

      const { data, timeAt } = resp;
      const newAppointments = [...appointments];

      if (newAppointments.length === 0) {
        for (const a of data) {
          newAppointments.push(a);
        }
        setAppointments(newAppointments);
        setUpdatedStartAt(timeAt);
        return;
      }

      for (const a of data) {
        const old = newAppointments.find((app) => app.id === a.id);
        if (!old) {
          newAppointments.push(a);
        } else {
          Object.assign(old, a);
        }
      }

      setAppointments(newAppointments);
      setUpdatedStartAt(timeAt);
    } catch (e) {
      console.log(e.description);
    }
  }, [startAt, endAt, appointments, updatedStartAt]);

  useEffect(() => {
    loadAppointments();
  }, [startAt, endAt]);

  const callApi = useCallback(() => {
    eventBus.on('homeLoadAppointments', loadAppointments);
    loadAppointments();

    return () => eventBus.off('homeLoadAppointments');
  }, [eventBus]);

  const onClickArrow = (num) => {
    let s = moment(startAt).add(num, 'days').toDate();
    let e = moment(endAt).add(num, 'days').toDate();
    setStartAt(s);
    setEndAt(e);
    setAppointments([]);
    setUpdatedStartAt(null);
  };

  const onClickToday = () => {
    setStartAt(moment().toDate());
    setEndAt(moment().toDate());
    setAppointments([]);
    setUpdatedStartAt(null);
  };

  const departmentCallApi = useCallback(async () => {
    let params = { limit: 300 };
    const resp = await services.crm.crud.departmentCategory.all(params);
    if (!resp) return;

    setDepartmentCategories(resp.data);
  }, [services.crm.crud.departmentCategory]);

  useEffect(() => {
    departmentCallApi();
  }, [departmentCallApi]);

  const counselorCallApi = useCallback(async () => {
    let params = { duty: '상담사', userStatus: 'active', limit: 300 };
    const resp = await services.crm.user.duty(params);
    if (!resp) return;

    setCounselors(resp.data);
  }, [services.crm.user]);

  useEffect(() => {
    counselorCallApi();
  }, [counselorCallApi]);

  const doctorCallApi = useCallback(async () => {
    let params = { duty: '의사', userStatus: 'active', limit: 300 };
    const resp = await services.crm.user.duty(params);
    if (!resp) return;

    setDoctors(resp.data);
  }, [services.crm.user]);

  useEffect(() => {
    doctorCallApi();
  }, [doctorCallApi]);

  useEffect(() => {
    callApi();
  }, [eventBus]);

  useEffect(() => {
    const interv = setInterval(() => loadAppointments(), 5000);
    return () => {
      clearInterval(interv);
    };
  }, [eventBus, startAt, endAt, filters, appointments, updatedStartAt]);

  const onChangeDatePicker = (date) => {
    setStartAt(date);
    setEndAt(date);
  };

  const onClickFold = (isMenuFold) => {
    ui.setUiState('isMenuFold', { home: isMenuFold });
    setIsMenuFold(isMenuFold);
  };

  return (
    <div className="home">
      <div className="flex-row">
        <div className={`left-panel flex-wrap ${isMenuFold ? 'short' : ''}`}>
          <p>
            <button
              className={`btn btn-fold ${isMenuFold ? 'full' : ''}`}
              onClick={() => {
                onClickFold(!isMenuFold);
              }}
            >
              {isMenuFold ? '열기' : '접기'}
            </button>
          </p>
          <div className="filters form-control">
            <label>{translate('FILTERS')}</label>
            <div className="filter-wrap">
              <div className="filter">
                <select
                  value={departmentCategories.findIndex(
                    (category) =>
                      category.id === (filters.departmentCategory || {}).id
                  )}
                  onChange={(e) => {
                    filters.departmentCategory =
                      departmentCategories[e.target.value];
                    setFilters({ ...filters });
                  }}
                >
                  <option key={-1} value={-1} disabled={!true}>
                    {'전체 부서' || '-'}
                  </option>
                  {departmentCategories.map((category, idx) => (
                    <option
                      key={idx}
                      value={idx}
                      className={!category.visible ? 'display-none' : ''}
                    >
                      {category.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="filter">
                <select
                  value={counselors.findIndex(
                    (existing) =>
                      existing.id === (filters['counselor'] || {}).id
                  )}
                  onChange={(e) => {
                    filters['counselor'] = counselors[e.target.value];
                    setFilters({ ...filters });
                  }}
                >
                  <option value={-1}>
                    전체 {translate('counselor'.toUpperCase())}
                  </option>
                  {counselors.map((o, idx) => (
                    <option key={idx} value={idx}>
                      {o.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="filter">
                <select
                  value={doctors.findIndex(
                    (existing) => existing.id === (filters['doctor'] || {}).id
                  )}
                  onChange={(e) => {
                    filters['doctor'] = doctors[e.target.value];
                    setFilters({ ...filters });
                  }}
                >
                  <option value={-1}>
                    전체 {translate('doctor'.toUpperCase())}
                  </option>
                  {doctors.map((o, idx) => (
                    <option key={idx} value={idx}>
                      {o.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <Notices startAt={startAt} endAt={endAt} />
        </div>
        <div className="right-panel">
          <div className="header">
            <div className="date-functions flex-row items-center">
              <button className="btn btn-today" onClick={onClickToday}>
                {translate('TODAY')}
              </button>
              <button className="btn btn-left" onClick={() => onClickArrow(-1)}>
                <span className="a11y">이전</span>
              </button>
              <DatePicker
                className="text-center"
                placeholderText="날짜선택"
                value={startAt}
                todayButton="오늘"
                onChange={onChangeDatePicker}
              />
              <button className="btn btn-right" onClick={() => onClickArrow(1)}>
                <span className="a11y">이전</span>
              </button>
            </div>
          </div>
          {appointments ? (
            <AppointmentsByStatus
              appointments={filtered(appointments, filters)}
              loadAppointments={loadAppointments}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default observer(Home);
