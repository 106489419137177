import { observer } from 'mobx-react';
import React, { useContext, useEffect } from 'react';
import moment from 'moment';
//import { AppointmentBoardState } from 'store/appointmentBoardState';
import { AppointmentBoardStateContext } from './AppointmentBoards';

const DepartmentRow = observer(() => {
  /**
   * @type {AppointmentBoardState }
   */
  const state = useContext(AppointmentBoardStateContext);

  useEffect(() => {
    state.setDepartmentDrawingFlag(
      (state.departmentListRef && state.dailyBoardRef) ||
        (state.departmentListRef && state.weeklyBoardRef)
    );
  }, [state.departmentListRef, state.dailyBoardRef, state.weeklyBoardRef]);

  const departmentContextItems = {
    setAbsence: [{ title: '휴진설정' }, { title: '부서색상설정' }],
    existAbsence: [
      // { title: "휴진설정" },
      { title: '휴진설정해제' },
      { title: '부서색상설정' },
    ],
  };

  const onContextMenu = (e, department, date) => {
    e.preventDefault();
    state.setDepartmentContextMenuOptions({
      show: true,
      x: e.clientX,
      y: e.clientY,
      items: state.departmentAbsence.some(
        (v) =>
          (v.department || {}).id === department.id &&
          moment(v.scheduledAt).format('YYYY-MM-DD') === date
      )
        ? departmentContextItems['existAbsence']
        : departmentContextItems['setAbsence'],
      date: date,
      department: department,
    });
  };

  return (
    <div className="calendar-departments-row">
      {state.dateCategoryDepartments.map((dcd, i) => (
        <div
          key={i}
          style={{
            width:
              state.mode === 'vertical'
                ? state.getDepartmentViewSize(dcd.department, dcd.date)
                : undefined,
            height:
              state.mode === 'horizontal'
                ? state.getDepartmentViewSize(dcd.department, dcd.date)
                : undefined,
            backgroundColor: state.getDepartmentColor(
              dcd.department.id,
              dcd.date
            ),
            borderRight:
              state.mode === 'vertical'
                ? state.calendarUnit === '3-day'
                  ? state.threeDaysArr.some((v) => v === i + 1) &&
                    '3px solid #d7e3f1'
                  : state.calendarUnit === 'week'
                  ? state.weekArr.some((v) => v === i + 1) &&
                    '3px solid #d7e3f1'
                  : state.calendarUnit === 'day'
                  ? state.dateCategoryDepartments.length === i + 1 &&
                    '3px solid #d7e3f1'
                  : ''
                : undefined,
            borderBottom:
              state.mode === 'horizontal'
                ? state.calendarUnit === '3-day'
                  ? state.threeDaysArr.some((v) => v === i + 1) &&
                    '3px solid #d7e3f1'
                  : state.calendarUnit === 'week'
                  ? state.weekArr.some((v) => v === i + 1) &&
                    '3px solid #d7e3f1'
                  : state.calendarUnit === 'day'
                  ? state.dateCategoryDepartments.length === i + 1 &&
                    '3px solid #d7e3f1'
                  : ''
                : undefined,
          }}
          className="department-name-cell"
          onContextMenu={(e) => {
            onContextMenu(e, dcd.department, dcd.date);
          }}
        >
          {dcd.department.name}
        </div>
      ))}
    </div>
  );
});

export default DepartmentRow;
