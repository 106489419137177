import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import { AppointmentBoardStateContext } from './AppointmentBoards';
import AppointmentCell from './AppointmentCell';

const AppointmentCellsList = observer(({ department, left, date }) => {
  /**
   * @type {AppointmentBoardState }
   */
  const state = useContext(AppointmentBoardStateContext);

  const appointments =
    state.timedAppointments[department.id]?.[date]?.timedAppointments[left] ??
    [];

  const indexedAppointments = appointments
    .map((a, i) => ({
      appointment: a,
      index: i,
    }))
    .filter((va) => va.appointment != null && va.appointment.startHour >= left);

  // const absenceDepartment =
  // state.departmentAbsence.some(v => (
  //   (v.department || {}).id === department.id &&
  //   moment(v.scheduledAt).format("YYYY-MM-DD") === date))?true:false

  return (
    <>
      {indexedAppointments.map(({ appointment, index }) => (
        <AppointmentCell
          key={appointment.id}
          index={index}
          appointment={appointment}
        />
      ))}
    </>
  );
});

export default AppointmentCellsList;
