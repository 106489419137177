import React, { useRef, useState, useCallback, useEffect } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { translate } from 'filters';
import {
  targetConditionConverter,
  convertManualNumbers,
} from 'filters/messageFilters';
import { useModal } from 'hooks/useModal';
import { useServices } from 'hooks/useServices';
import { useToast } from 'hooks/useToast';
import { RadioInput } from 'components/common/RadioInput';
import ConditionGroupList from './ConditionGroupList';
import ModalMessageTargetSearchHistory from 'components/modals/ModalMessageTargetSearchHistory';
import styled from 'styled-components';

const RadioInputWrapper = styled(RadioInput)`
  margin-right: 4px;
`;

const ConditionContainer = ({
  searchHistoryInit,
  setSearchHistoryInit,
  setDataObj,
  setStatus,
}) => {
  const modal = useModal();
  const services = useServices();
  const toast = useToast();
  const groupRef = useRef(0);
  const STATUS_TYPE = Object.freeze({
    LOAD: 'load',
    SEARCH: 'search',
  });
  const initCondition = {
    column: null,
    category: null,
    query: null,
    include: true,
    uiKeyName: null,
    conditionId: 0,
  };
  const initGroup = {
    // visit : { query: {} },
    conditions: [initCondition],
    groupId: groupRef.current++,
  };
  const [uiObj, setUiObj] = useState({
    status: '',
    groups: [initGroup],
    manual: '',
  });
  // eslint-disable-next-line no-unused-vars
  const [searchDataObj, setSearchDataObj] = useState({});
  const [searchResult, setSearchResult] = useState(0);
  const [toggleManual, setToggleManual] = useState(false);

  useEffect(() => {
    //문자 전송 후 검색 조건 초기화
    if (searchHistoryInit === true) {
      setToggleManual(false);
      setSearchDataObj({});
      setSearchResult(0);
      setUiObj({ status: '', groups: [initGroup], manual: '' });
      setSearchHistoryInit(false);
    }
  }, [searchHistoryInit, setSearchHistoryInit, initGroup]);

  const onCreateGroup = useCallback(() => {
    setUiObj((conditionGroups) => ({
      ...conditionGroups,
      groups: conditionGroups.groups.concat({
        ...initGroup,
        groupId: groupRef.current++,
      }),
    }));
  }, [initGroup]);

  const onChangeGroup = useCallback((payload) => {
    const { groupId } = payload;
    setUiObj((conditionGroups) => ({
      ...conditionGroups,
      groups: conditionGroups.groups.map((group) => {
        if (group.groupId === groupId) {
          return payload;
        }
        return group;
      }),
    }));
  }, []);

  const onToggle = useCallback(() => {
    //토글시 검색조건 초기화
    setUiObj({
      status: '',
      groups: [initGroup],
      manual: '',
    });

    setSearchDataObj({});
    setSearchResult(0);

    setToggleManual((toggled) => {
      return !toggled;
    });
  }, [initGroup]);

  const validateDriectInputPhoneNumber = (uiObj) => {
    // 번호 직접입력시 휴대폰번호를 입력하지 않았거나, 검색조건이 지정이 되어있지 않으면 true 반환
    const { manual, groups } = uiObj;

    const conditionsCheck = (groups || []).every((group) => {
      return (group.conditions || []).every((condition) => {
        return (condition || {}).query === null;
      });
    });

    let check = [manual === '', conditionsCheck];
    return check.every((c) => c === true);
  };

  const validateConditions = (groupsObj, toggleManual, manual) => {
    const dynamicConditionsUiKeyName = 'conditions';
    const uiKeyName = 'uiKeyName';
    const queryKeyName = 'query';
    const res = {
      hasError: false,
      desc: '',
    };
    groupsObj.forEach((groupObj, groupIdx) => {
      groupObj[dynamicConditionsUiKeyName].forEach((row, conditionIdx) => {
        if (manual !== '') {
          //핸드폰번호가 있으면 groups 조건이 있는지 없는지 판단
          // groups조건이 있으면 아래 벨리데이션 체크 로직을 탄다
          if (row[uiKeyName] === null && groupIdx === 0 && conditionIdx === 0) {
            return;
          }
        }

        if (row[uiKeyName] === null) {
          // 조건항목이 선택되지 않은 경우
          res.hasError = true;
          res.desc = 'ERROR_SELECT_CONDITION';
          return res;
        }

        if (row[uiKeyName] !== null) {
          if (row[queryKeyName] === null) {
            // 조건항목은 선택되었으나, 검색항목이 선택하지않아 객체가 생성되지 않은 경우
            res.hasError = true;
            res.desc = 'ERROR_SELECT_CONDITION';
            return res;
          }
          if (
            Array.isArray(row[queryKeyName]) &&
            row[queryKeyName].length === 0
          ) {
            // 검색항목 객체는 생성되었지만, 제거하여 갯수가 0개 인 경우
            res.hasError = true;
            res.desc = 'ERROR_SELECT_CONDITION';
            // res.desc = `groupIdx:${groupIdx}, conditionIdx:${conditionIdx}, obj:${row[uiKeyName]}`
            return res;
          }
          if ((row[queryKeyName] || {}).constructor === Object) {
            // Object일 경우. 범위날짜 case
            const [startDateKey, endDateKey] = ['start', 'end'];
            if (
              !Object.prototype.hasOwnProperty.call(
                row[queryKeyName] || {},
                startDateKey
              ) ||
              !((row[queryKeyName] || {})[startDateKey] || {})
            ) {
              // 시작날짜 미지정
              res.hasError = true;
              res.desc = 'ERROR_SELECT_CONDITION';
              return res;
            }
            if (
              !Object.prototype.hasOwnProperty.call(
                row[queryKeyName] || {},
                endDateKey
              ) ||
              !((row[queryKeyName] || {})[endDateKey] || {})
            ) {
              // 종료날짜 미지정
              res.hasError = true;
              res.desc = 'ERROR_SELECT_CONDITION';
              return res;
            }
          }
        }
      });
    });
    return res;
  };

  const validateManualNumbers = useCallback((numObj) => {
    // 동적조건은 toast로, 직접번호입력은 modal로 안내.
    const { total, data } = numObj;
    const res = {
      hasError: false,
      desc: '',
    };
    if (total.duplicate === 0 && total.error === 0) {
      res.hasError = false;
      return res;
    }
    if (total.duplicate > 0 || total.error > 0) {
      res.hasError = true;
      // const str_duplicate = translate("중복된번호: %s건").replace(/%s/, total.duplicate)
      // const str_error = translate("번호형식오류: %s건").replace(/%s/, total.error)
      // 번호형식오류 건수/목록이 하단에, 중복된번호 건수/목록이 상단에
      const html_error = `<div class="row">
            <div class="label">
              <div>${translate('INVALID_PHONENUMBER_FORMAT')}</div>
              <div class="count">${translate('N_COUNTS').replace(
                /%s/,
                total.error
              )}</div>
            </div>
            <div class="detail">${data.error
              .map((n) => `<span>${n}</span>`)
              .join('')}</div>
           </div>`;

      const html_duplicate = `<div class="row">
            <div class="label">
              <div>${translate('DUPLICATED_PHONENUMBERS')}</div>
              <div class="count">${translate('N_COUNTS').replace(
                /%s/,
                total.duplicate
              )}</div>
            </div>
            <div class="detail">${data.duplicate
              .map((n) => `<span>${n}</span>`)
              .join('')}</div>
          </div>`;

      const html_desc = `<div class="error-manual-input-number">
            ${total.duplicate > 0 ? html_duplicate : ''}
            ${total.error > 0 ? html_error : ''}
            <div class="row"><div class="tail">${translate(
              'CONFIRM_REMOVED'
            )}</div></div>
           </div>`;

      res.desc = html_desc;
    }
    return res;
  }, []);

  const onClickSearch = useCallback(() => {
    const onConfirm = async () => {
      const converter = targetConditionConverter;
      const manualNumbers = convertManualNumbers(uiObj.manual);
      const manualData = manualNumbers.data.success;
      let smsSearchCondition = uiObj.groups.map((group) =>
        converter.uiToServer(group)
      );
      //smsSearchCondition에 [{}] 형태로 검색이 되면, 올바르지 않은 값이 나와서 [] 로 변환해줌
      if (
        smsSearchCondition.length === 1 &&
        Object.keys(smsSearchCondition[0]).length === 0
      ) {
        smsSearchCondition = [];
      }
      const obj = {
        smsSearchCondition: smsSearchCondition,
        directSearchCondition: {
          phoneNumber: {
            query: manualData,
          },
        },
      };
      setStatus(STATUS_TYPE.SEARCH);
      setSearchDataObj(obj);
      try {
        const resp = await services.crm.notification.smsSearch.create(obj);
        const { targetCount, smsSearchHistory } = resp.data;
        setSearchResult(targetCount);
        setDataObj({
          smsSearchHistory,
        });
      } catch (error) {
        console.log(error.description);
        let errorMessage = error.description;
        if (error.code === 412 && error.description === 'ExceededMaxRowCount') {
          errorMessage =
            '데이터가 많아 실패하였습니다. 우노케어 CRM CS팀에 문의해주세요.';
        }
        toast.error(errorMessage);
      }
    };

    //번호 직접입력 시 검색조건 혹은 휴대폰번호 둘중 하나라도 입력하지 않으면 validation check
    const validPhoneNumber = toggleManual
      ? validateDriectInputPhoneNumber(uiObj)
      : false;

    if (validPhoneNumber) {
      toast.error('검색조건 혹은 휴대폰번호를 입력하세요.');
      return;
    }

    const manualNumbers = convertManualNumbers(uiObj.manual);
    const validConditions = validateConditions(
      uiObj.groups,
      toggleManual,
      uiObj.manual
    );
    const validManualNumbers = validateManualNumbers(manualNumbers);
    if (validConditions.hasError) {
      return toast.error(validConditions.desc);
    }
    if (validManualNumbers.hasError) {
      modal
        .basic({
          title: translate('VALID_DIRECT_NUMBERS'),
          body: validManualNumbers.desc,
          buttons: [
            {
              text: 'CANCEL',
              class: 'btn-default',
            },
            {
              text: 'CONFIRM',
              class: 'btn-primary',
            },
          ],
        })
        .then((idx) => {
          if (idx === 1) {
            onConfirm();
          }
        });
    } else {
      onConfirm();
    }
  }, [
    STATUS_TYPE.SEARCH,
    services,
    modal,
    toast,
    setDataObj,
    setStatus,
    uiObj.groups,
    uiObj.manual,
    validateManualNumbers,
    toggleManual,
  ]);

  const onChangeManualHandler = (e) => {
    const { name, value } = e.target;
    setUiObj((uiObj) => ({
      ...uiObj,
      [name]: value,
    }));
  };

  const onRemoveGroup = useCallback((payload) => {
    const { groupId } = payload;

    setUiObj((conditionGroups) => {
      return {
        ...conditionGroups,
        groups: conditionGroups.groups.filter((group) => {
          return group.groupId !== groupId;
        }),
      };
    });
  }, []);

  const openTargetSearchHistoryModal = useCallback(
    (customer, obj) => {
      let component = ModalMessageTargetSearchHistory;
      modal
        .custom({
          component: component,
          options: { payload: obj, customer: customer },
        })
        .then((payload) => {
          try {
            if (!payload) return;
            const { groups, manualNumbers } = payload;
            let manual = '';
            let hasManualNumbers =
              Array.isArray(manualNumbers) && manualNumbers.length > 0;
            if (hasManualNumbers) {
              manual = manualNumbers.join(', ');
              setToggleManual(true);
            } else {
              setToggleManual(false);
            }

            let group =
              groups.length === 0
                ? [{ ...initCondition }]
                : groups.map((group) => {
                    const groupId = groupRef.current++;
                    return {
                      ...group,
                      groupId: groupId,
                      conditions:
                        group.conditions.length === 0
                          ? [{ ...initCondition }]
                          : group.conditions,
                    };
                  });

            let obj = {
              status: STATUS_TYPE.LOAD,
              groups: group,
              manual: manual,
            };
            setUiObj({ ...obj });
          } catch (error) {
            toast.error(error.description);
          }
        });
    },
    [STATUS_TYPE.LOAD, modal, toast, initCondition]
  );

  return (
    <>
      <div className="condition-container">
        <div className="condition-container-controller">
          <div className="label">
            <span>전송대상</span>
            <div className="radios flex-row">
              <div className="radio-item flex-row items-center flex-wrap m-r-24">
                <label>
                  <RadioInputWrapper
                    name="searchType"
                    onChange={() => {
                      onToggle();
                    }}
                    checked={Boolean(!toggleManual)}
                  />
                  검색
                </label>
              </div>
              <div className="radio-item flex-row items-center flex-wrap m-r-24">
                <label>
                  <RadioInputWrapper
                    name="searchType"
                    onChange={() => {
                      onToggle();
                    }}
                    checked={Boolean(toggleManual)}
                  />
                  번호 직접입력
                </label>
              </div>
            </div>
            <div
              className="btnwrap"
              style={{
                display: 'flex',
                flexGrow: 1,
                justifyContent: 'flex-end',
              }}
            >
              <button
                className="btn btn-basic _small"
                onClick={() => openTargetSearchHistoryModal()}
              >
                {translate('RECENT_SEARCH_CONDITIONS')}
              </button>
            </div>
          </div>
          <div className="label" style={{ marginTop: '20px' }}>
            검색
          </div>
        </div>

        <div className="condition-groups">
          <ConditionGroupList
            groups={uiObj.groups}
            onCreateGroup={onCreateGroup}
            onRemoveGroup={onRemoveGroup}
            onChangeGroup={onChangeGroup}
          />
        </div>
        {toggleManual && (
          <div className="condition-direct">
            <div className="label">휴대폰번호</div>
            <div className="form">
              <TextareaAutosize
                name="manual"
                value={uiObj.manual}
                className="textarea defalut"
                onChange={onChangeManualHandler}
                placeholder={translate('PLACEHOLDER_DIRECT_PHONE_NUMBER')}
              />
            </div>
          </div>
        )}
        <div className="condition-search">
          <div className="condition-search-button">
            <button className="btn btn-basic _small" onClick={onClickSearch}>
              {translate('LABEL_SEARCH')}
            </button>
          </div>
          <div className="condition-search-result">
            <span>{translate('SEARCH_RESULTS')} : </span>
            <span>
              {searchResult === 1
                ? translate('ONE_PERSON')
                : translate('N_PERSONS').replace(/%s/, searchResult)}
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

ConditionContainer.propTypes = {
  searchHistoryInit: PropTypes.bool,
  setSearchHistoryInit: PropTypes.func,
  setDataObj: PropTypes.func,
  setStatus: PropTypes.func,
};

export default observer(ConditionContainer);
