import React, { useState, useEffect, useCallback } from 'react';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import DataTableAlpha from 'components/data-table/DataTableAlpha';
import { formatDate } from 'filters';
import modelPrescriptionList from 'models/prescription-list';
import ModalCustomerFinder from 'components/modals/ModalCustomerFinder';
import PrescriptionTable from './PrescriptionTable';
import { $qb } from 'scripts/querybuilder';
import { useModal } from 'hooks/useModal';
import { useServices } from 'hooks/useServices';
import { useToast } from 'hooks/useToast';
import { useUI } from 'hooks/useUI';
import styled from 'styled-components';
import DatePicker from 'components/common/DatePicker';

const Input = styled.input`
  font-size: 12px;
  width: 140px;
  vertical-align: top;
  line-height: 1;
  padding: 8px 10px 8px 20px;
  border: 1px solid #dee2ec;
  border-radius: 4px;
  height: 34px;
  background-color: #f3f8ff;
`;

const PrescriptionList = () => {
  const modal = useModal();
  const services = useServices();
  const toast = useToast();
  const ui = useUI();
  const history = useHistory();

  const defaultParams = $qb().limit(20);
  const [params, setParams] = useState(defaultParams);
  const [printId, setPrintId] = useState(0); // 인쇄할 처방전 id(Number)
  const [doctorList, setDoctorList] = useState(null); // 의사 리스트(Array)
  const [prescriptionList, setPrescriptionList] = useState(null); // 처방전 리스트(Array)
  const [total, setTotal] = useState(0); // 처방전 리스트 total(Number)
  const [doctor, setDoctor] = useState(); // 처방 의료인 id(Object)
  const [customer, setCustomer] = useState(''); // 고객명(String)
  const [searchDate, setSearchDate] = useState({
    startAt: new Date().setDate(new Date().getDate() - 30),
    endAt: new Date(),
  });

  const getDoctorList = useCallback(async () => {
    try {
      const resp = await services.crm.prescription.prescription_code.all();
      resp && setDoctorList(resp.data.doctor);
    } catch (e) {
      console.log(e.description);
    }
  }, []);

  useEffect(() => {
    if (!services) return;
    getDoctorList();
  }, [getDoctorList]);

  const getPrescriptionList = useCallback(async () => {
    let requestParams = {
      fromDate: formatDate(searchDate.startAt),
      toDate: formatDate(searchDate.endAt),
      customerId: customer.id,
      doctorId: (doctor && doctor.id) || null,
      ...params.build(),
    };

    try {
      const resp = await services.crm.prescription.prescription_list.all_v2(
        requestParams
      );
      if (!resp.data) return;
      setPrescriptionList(resp.data.prescriptionList);
      setTotal(resp.meta.pagination.total);
    } catch (e) {
      toast.error(e.description);
    }
  }, [customer.id, doctor, params, searchDate.endAt, searchDate.startAt]);

  useEffect(() => {
    if (!services) return;
    getPrescriptionList();
  }, [getPrescriptionList]);

  const onParams = (p) => {
    setParams(p);
    getPrescriptionList();
  };

  const onAction = (obj) => {
    let model = obj.key;
    let object = obj.row;
    let event = obj.event;
    let id = object.prescription.id;

    if (['name'].indexOf(model.column) !== -1) {
      object[model.column] = event.target.value;
    }

    const btnLabel = event.target.innerText;
    if (btnLabel === '인쇄') {
      ui.setPrescriptionPrintBtnDisabledFlag(true);
      setPrintId(id);
    }

    if (btnLabel === '수정') {
      history.push(`/crm/prescription-issue/${id}`);
    }

    if (btnLabel === '삭제') {
      onActionDelete(id);
    }
  };

  const deletePrescription = async (id) => {
    let params = { prescriptionId: id };

    try {
      await services.crm.prescription.prescription.delete_v2(params);
      modal
        .confirm({
          type: 'SUCCESS',
          msg: '삭제되었습니다.',
        })
        .then(() => {
          getPrescriptionList();
        });
    } catch (e) {
      toast.error(e.description);
    }
  };

  const searchUser = (value) => {
    modal
      .custom({
        component: ModalCustomerFinder,
        options: {
          customerData: { searchQuery: value },
        },
      })
      .then((customer) => {
        if (customer) setCustomer(customer);
      });
  };

  const onActionDelete = (id) => {
    modal
      .basic({
        body: `정말로 삭제하시겠습니까?`,
        buttons: [
          {
            text: 'CANCEL',
            class: 'btn-default',
          },
          {
            text: 'CONFIRM',
            class: 'btn-primary',
          },
        ],
      })
      .then((selectedIdx) => {
        if (selectedIdx === 1) {
          deletePrescription(id);
        }
      });
  };

  return (
    <div className="prescription inquiry">
      <div className="page-navi">
        <span className="title">처방전 발급/관리</span>
        <span className="title">처방전 조회</span>
      </div>

      <div className="route-top">
        <div className="search-filter search-inputs flex-fill m-b-20">
          <div className="search-item wrap-datepicker">
            <DatePicker
              className="text-center"
              placeholderText={new Date(searchDate.startAt)}
              todayButton="오늘"
              value={searchDate.startAt}
              maxDate={searchDate.endAt}
              shouldCloseOnSelect={true}
              onChange={(date) => {
                setSearchDate({ ...searchDate, startAt: date });
              }}
            />
          </div>
          <div className="search-item wrap-datepicker">
            <DatePicker
              className="text-center"
              placeholderText={new Date(searchDate.endAt)}
              todayButton="오늘"
              value={searchDate.endAt}
              minDate={searchDate.startAt}
              shouldCloseOnSelect={true}
              onChange={(date) => {
                setSearchDate({ ...searchDate, endAt: date });
              }}
            />
          </div>
          <div className="search-item">
            <Input
              value={customer.name || ''}
              onClick={() => searchUser()}
              placeholder="고객명"
              readOnly
            />
          </div>
          {doctorList && (
            <select
              className="m-b-8"
              defaultValue={'default'}
              onChange={(e) => setDoctor(doctorList[e.target.value])}
            >
              <option value={'default'}>선택하세요.</option>
              {doctorList.map((v, i) => (
                <option key={i} value={i}>
                  {v.name}
                </option>
              ))}
            </select>
          )}
          <div className="search-btns">
            <button
              className="btn btn-basic _small"
              onClick={() => getPrescriptionList()}
            >
              조회
            </button>
          </div>
        </div>
      </div>

      <DataTableAlpha
        model={modelPrescriptionList}
        data={prescriptionList}
        total={total}
        params={params}
        onParams={onParams}
        onAction={onAction}
      />

      <PrescriptionTable printId={printId} setPrintId={setPrintId} printMode />
    </div>
  );
};

export default observer(PrescriptionList);
