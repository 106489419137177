import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { translate } from 'filters';
import { useModal } from 'hooks/useModal';
import { useServices } from 'hooks/useServices';
import styled from 'styled-components';

const Input = styled.input`
  width: 100%;
  height: 34px;
  padding: 8px 20px;
  border-color: #d7e3f1;
  font-size: 12px;
  min-height: 10px;
  border-radius: 4px;
  color: #000;
  border: 1px solid #dee2ec;
`;

const ModalPenChartsUpdate = ({ options, close }) => {
  const modal = useModal();
  const services = useServices();
  const { target, customer, currentFolder, setNeedUpdate } = options;
  const [inputValue, setInputValue] = useState(target.name);

  const displayErrorMsg = (msg) => {
    modal
      .confirm({
        type: 'ERROR',
        msg: msg,
      })
      .then(() => {
        close();
      });
  };

  const displaySuccessMsg = async () => {
    await modal.confirm({
      type: 'SUCCESS',
      msg: '이름을 변경하였습니다.',
    });
  };

  const onClickSave = useCallback(async () => {
    try {
      const params = {
        id: target.id,
        name: inputValue,
        parentId: currentFolder,
      };

      if (customer.id && typeof customer.id === 'number') {
        params.customerId = customer.id;
      }

      await services.crm.penchart.update_v2(target.id, params);

      setNeedUpdate({ type: 'EDIT', data: inputValue });
      await displaySuccessMsg();

      close();
    } catch (error) {
      displayErrorMsg(error.description);
    }
  }, [services, close, inputValue]);

  return (
    <div className="modal-pen-chart">
      <div className="head flex-row flex-between items-start">
        <div className="title">이름 변경</div>
        <i onClick={() => close()} className="zmdi zmdi-close" />
      </div>
      <div className="body">
        <div className="form-control">
          <Input
            type="text"
            value={inputValue}
            onChange={(e) => {
              setInputValue(e.target.value);
            }}
          />
        </div>
      </div>
      <div className="buttons">
        <div className="flex-row">
          <button
            onClick={close}
            className="btn btn-default"
            style={{ display: 'block' }}
          >
            {translate('CANCEL')}
          </button>
          <button
            onClick={onClickSave}
            className="btn btn-primary"
            style={{ display: 'block' }}
          >
            저장
          </button>
        </div>
      </div>
    </div>
  );
};

ModalPenChartsUpdate.propTypes = {
  options: PropTypes.object,
  close: PropTypes.func,
};

export default observer(ModalPenChartsUpdate);
