import React, { useState, useEffect, useRef } from 'react';
import { observer } from 'mobx-react';
import Checkbox from 'components/app/Checkbox';
import PrescriptionTable from './PrescriptionTable';
import PresciptionBundleList from './PresciptionBundleList';
import { useModal } from 'hooks/useModal';
import { useServices } from 'hooks/useServices';
import { useToast } from 'hooks/useToast';
import { useUI } from 'hooks/useUI';

const PrescriptionIssue = () => {
  const modal = useModal();
  const services = useServices();
  const toast = useToast();
  const ui = useUI();
  const buttonBundleRef = useRef(null);
  const buttonResetRef = useRef(null);
  const buttonPostRef = useRef(null);
  const buttonTemporaryRef = useRef(null);
  const buttonPrintRef = useRef(null);

  const [updateBundle, setUpdateBundle] = useState(false);
  const [bundleId, setBundleId] = useState(null);
  const [info, setInfo] = useState(null);
  const [isSaveBundle, setIsSaveBundle] = useState(false);

  useEffect(() => {
    setIsSaveBundle(false);
  }, [bundleId]);

  const onSaveBundle = () => {
    if (!info.medicineList.length && !info.injectionList.length) {
      toast.error('의약품/주사제를 입력해주세요.');
      return;
    }

    modal
      .input({
        title: '묶음처방으로 저장',
        inputs: [
          {
            placeholder: '묶음처방명',
          },
        ],
      })
      .then(async (inputs) => {
        if (!inputs || inputs.length === 0) return;
        if (!inputs[0].text) {
          toast.error('ERROR_EMPTY_VALUE');
          return;
        }

        try {
          let params = {
            collectionName: inputs[0].text,
            medicineList: info.medicineList,
            injectionList: info.injectionList,
          };

          await services.crm.prescription.prescription_collection.create(
            params
          );
          modal
            .confirm({
              type: 'SUCCESS',
              msg: '저장하였습니다.',
            })
            .then(() => {
              setIsSaveBundle(true);
              setUpdateBundle(true);
            });
        } catch (e) {
          modal.confirm({
            type: 'ERROR',
            msg: e.description,
          });
        }
      });
  };

  return (
    <div className="prescription">
      <div className="page-navi">
        <span className="title">처방전 발급/관리</span>
        <span className="title">처방전 발급</span>
      </div>

      <div className="flex-row">
        <div className="wrap-prescription-table">
          <div className="route-top">
            <span className="title">처방전 작성</span>
            <div className="wrap-action-btn">
              <div
                className="flex-row items-center bundle"
                onClick={() => onSaveBundle()}
                ref={buttonBundleRef}
              >
                <Checkbox className="flex-wrap m-r-8" checked={isSaveBundle} />
                <span className="m-r-12">묶음처방으로 저장</span>
              </div>
              <button
                className="btn btn-basic _small reset"
                ref={buttonResetRef}
              >
                초기화
              </button>
              <button
                className="btn btn-basic _small temporary"
                ref={buttonTemporaryRef}
              >
                임시저장
              </button>
              <button className="btn btn-basic _small post" ref={buttonPostRef}>
                작성완료
              </button>
              <button
                className="btn btn-basic _small print"
                ref={buttonPrintRef}
                disabled={`${
                  ui.prescriptionPrintBtnDisabledFlag ? 'disabled' : ''
                }`}
              >
                인쇄
              </button>
            </div>
          </div>
          <PrescriptionTable
            bundleId={bundleId}
            setInfo={setInfo}
            buttonRef={{
              bundle: buttonBundleRef,
              reset: buttonResetRef,
              temporary: buttonTemporaryRef,
              post: buttonPostRef,
              print: buttonPrintRef,
            }}
          />
        </div>

        <PresciptionBundleList
          setBundleId={setBundleId}
          setUpdateBundle={setUpdateBundle}
          updateBundle={updateBundle}
        />
      </div>
    </div>
  );
};

export default observer(PrescriptionIssue);
