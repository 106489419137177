import React, { useState, useCallback } from 'react';
import { observer } from 'mobx-react';
import { countByteByString, emojisContainsCheck, translate } from 'filters';
import ScreenBoilerplate from 'components/app/MessageScreen/ScreenBoilerplate';
import PropTypes from 'prop-types';
import { useModal } from 'hooks/useModal';
import { useServices } from 'hooks/useServices';
import { useToast } from 'hooks/useToast';

const ModalMessageBoilerplate = ({ options, close }) => {
  const modal = useModal();
  const services = useServices();
  const toast = useToast();
  const [loadingBtnDisabledFlag, setLoadingBtnDisabledFlag] = useState(false);
  const initObj = Object.freeze({
    images: [],
    imageFiles: [],
    // id: null,
    category: '',
    title: '',
    contents: '',
    // visible: true,
    // createdId: null,
    // organizationId: null,
  });

  const [obj, setObj] = useState({
    ...initObj,
    ...(options.boilerplateMessage && options.boilerplateMessage),
  });

  const validator = useCallback(() => {
    if (obj['category'] === '') {
      const errorString = translate('ERROR_MISSING_FIELD').replace(
        /%s/,
        translate('LABEL_BOILERPLATE_CATEGORY')
      );
      toast.error(errorString);
      return false;
    }
    if (obj['contents'] === '') {
      const errorString = translate('ERROR_EMPTY_MESSAGE_CONTENTS');
      toast.error(errorString);
      return false;
    }
    if (countByteByString(obj['title']) > 40) {
      const errorString = translate('ERROR_EXCEEDED_MESSAGE_TITLE');
      toast.error(errorString);
      return false;
    }
    if (countByteByString(obj['contents']) > 2000) {
      const errorString = translate('ERROR_EXCEEDED_MESSAGE_CONTENTS');
      toast.error(errorString);
      return false;
    }

    if (
      emojisContainsCheck((obj || {}).title) === true ||
      emojisContainsCheck((obj || {}).contents) === true
    ) {
      //상용구 추가 시 제목, 내용에 특수 이모티콘(이모지) 포함되어있는지 체크
      toast.error('문자 제목 또는 내용에 특수 이모티콘을 제거해주세요');
      return false;
    }

    return true;
  }, [toast, obj]);

  const onClickSave = useCallback(async () => {
    if (validator()) {
      setLoadingBtnDisabledFlag(true);
      try {
        if (obj.id && obj.id !== null) {
          //백엔드에서 내려주는 deletedAt 임시로 제외하고 업데이트 (백엔드실서버 배포 잘못됨)
          // eslint-disable-next-line no-unused-vars
          let { deletedAt: deletedKey, ...otherKeys } = obj;
          const resp = await services.crm.notification.boilerplateMessages.update(
            otherKeys
          );
          if (resp) {
            modal
              .confirm({
                type: 'SUCCESS',
                msg: '업데이트되었습니다.',
              })
              .then(() => {
                close(1);
                setLoadingBtnDisabledFlag(false);
              });
          }
        } else {
          const resp = await services.crm.notification.boilerplateMessages.create(
            obj
          );
          if (resp) {
            modal
              .confirm({
                type: 'SUCCESS',
                msg: '생성되었습니다.',
              })
              .then(() => {
                close(1);
                setLoadingBtnDisabledFlag(false);
              });
          }
        }
      } catch (e) {
        setLoadingBtnDisabledFlag(false);
        if (e.description === 'invalidString') {
          toast.error('문자 제목 또는 내용에 특수 이모티콘을 제거해주세요');
          return;
        } else {
          toast.error(e.description);
        }
      }
    }
  }, [
    services.crm.notification.boilerplateMessages,
    toast,
    close,
    obj,
    validator,
  ]);

  const onChangeHandler = useCallback(
    (e) => {
      setObj({
        ...obj,
        [e.target.name]: e.target.value,
      });
    },
    [obj]
  );

  return (
    <div className="modal-message-screen boilerplate ">
      <div className="head flex-row flex-between items-start">
        <div className="title">
          {obj.id
            ? translate('EDIT_BOILERPLATE_MESSAGE')
            : translate('ADD_BOILERPLATE_MESSAGE')}
        </div>
        <i onClick={() => close()} className="zmdi zmdi-close" />
      </div>
      <div className="body">
        <div className="row">
          <ScreenBoilerplate
            obj={obj}
            setObj={setObj}
            onChange={onChangeHandler}
          />
        </div>
      </div>
      <div className="buttons">
        <div className="flex-row">
          <button onClick={close} className="btn btn-default">
            {translate('CANCEL')}
          </button>
          <button
            disabled={loadingBtnDisabledFlag}
            onClick={(e) => {
              onClickSave(e);
            }}
            className="btn btn-primary"
          >
            {translate('SAVE')}
          </button>
        </div>
      </div>
    </div>
  );
};

ModalMessageBoilerplate.propTypes = {
  options: PropTypes.object,
  close: PropTypes.func,
};

export default observer(ModalMessageBoilerplate);
