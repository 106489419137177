import React, { useEffect, useState } from 'react';
import moment from 'moment';
import './ctiDatePickerInput.scss';
import classNames from 'classnames';
import PropTypes from 'prop-types';

export default function CtiDatePickerInput({
  date,
  disabled,
  onChange,
  onClickDatePicker,
  error,
}) {
  const [text, setText] = useState(date);

  useEffect(() => {
    setText(date);
  }, [date]);

  function onChangeText(e) {
    setText(e.target.value);
  }

  function setDateText() {
    if (!text) {
      onChange(null);
      return;
    }

    const dateText = moment(text).format('YYYY-MM-DD');
    if (date !== dateText) {
      setText(dateText);
      onChange(dateText);
    }
  }

  function onBlur() {
    setDateText();
  }

  function onKeyDown(e) {
    if (e.keyCode === 13) setDateText();
  }

  return (
    <div className="cti-datepicker-input">
      <div className="inner">
        <input
          className={classNames('datepicker-input', { error: Boolean(error) })}
          type="text"
          disabled={disabled}
          value={text}
          onChange={onChangeText}
          onBlur={onBlur}
          placeholder="리콜 날짜"
          onKeyDown={onKeyDown}
        ></input>
        <button onClick={onClickDatePicker}></button>
      </div>
      {/* {error && <div className="cti-form-error">{error}</div>} */}
    </div>
  );
}

CtiDatePickerInput.propTypes = {
  date: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onClickDatePicker: PropTypes.func,
  error: PropTypes.string,
};
