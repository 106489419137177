import React, { useState } from 'react';
import { observer } from 'mobx-react';
import AppointmentCards from './AppointmentCards';
import PropTypes from 'prop-types';
import { useModal } from 'hooks/useModal';
import { useServices } from 'hooks/useServices';

const AppointmentsByStatus = ({ appointments, loadAppointments }) => {
  const services = useServices();
  const modal = useModal();
  // 상태 (consulting_request : 상담문의, consulting_request_done : 상담문의응대 완료, scheduled : 예약, canceled : 예약 취소, need_recall : 리콜 필요, unvisited : 미방문, consulting_waiting : 상담 대기, consulting_during : 상담 중, consulting_done : 상담 완료, treatment_waiting : 진료 대기, treatment_during : 진료 중, treatment_done : 진료 완료, surgery_waiting : 시/수술 대기, surgery_during : 시/수술 중, surgery_done : 시/수술 완료, payment_waiting : 수납 대기, payment_during : 수납 중, payment_done : 수납 완료, done : 완료, leave : 퇴원)
  const [cardGroup] = useState(
    [
      [
        'scheduled', // 예약
        'unvisited', // 미방문
        'canceled', // 예약취소
      ],
      [
        'consulting_waiting', //상담대기
        'consulting_during', //상담중
      ],
      [
        'treatment_waiting', //진료대기
        'treatment_during', //진료중
        'surgery_waiting', //시/수술대기
        'surgery_during', //시/수술중
      ],
      [
        'payment_waiting', //수납대기
        'complete', //모든 완료상태(consulting_done, treatment_done, surgery_done, payment_done, ...)
        'leave', //퇴원
      ],
    ].map((group) => {
      return group.map((status) => ({
        status,
        expanded: true,
      }));
    })
  );
  const [activeAppointment, setActiveAppointment] = useState(null);

  const appointmentCallApi = async (appointmentId) => {
    try {
      const resp = await services.crm.crud.appointment.detail(appointmentId);
      if (!resp) return;

      return resp.data;
    } catch (e) {
      console.log(e.description);
    }
  };

  const onMouseUpOnStatus = async (status) => {
    if (!activeAppointment || activeAppointment.status === status) return;
    const { id, scheduledAt } = activeAppointment;
    const appointment = await appointmentCallApi(id);
    let changeStatus = status;
    if (status === 'complete') {
      //완료 상태로 이동했을때, 예약건의 type에 맞춰 done상태로 변경된다
      const { type } = appointment;
      changeStatus = `${type}_done`;
    }

    let payload = {
      id,
      scheduledAt,
      customerId: ((appointment || {}).customer || {}).id,
      status: changeStatus,
      appointmentStatus: changeStatus,
      appointmentId: id,
    };

    if (appointment.doctor !== null) {
      payload.doctor = appointment.doctor;
    }

    if (appointment.counselor !== null) {
      payload.counselor = appointment.counselor;
    }

    if (appointment.facialist !== null) {
      payload.facialist = appointment.facialist;
    }

    try {
      await services.crm.crud.appointment.update(payload);
      modal
        .confirm({
          type: 'SUCCESS',
          msg: '업데이트되었습니다.',
        })
        .then(() => {
          setActiveAppointment(null);
          loadAppointments();
        });
    } catch (e) {
      console.log(e.description);
    }
  };

  return (
    <div className="appointments-by-status flex-row">
      {cardGroup.map((group, idx) => (
        <div key={idx} className="status-group flex-wrap">
          {group.map((card, idx) => (
            <div
              key={idx}
              onDrop={() => onMouseUpOnStatus(card.status)}
              onDragOver={(e) => {
                e.stopPropagation();
                e.preventDefault();
              }}
            >
              <AppointmentCards
                card={card}
                appointments={appointments}
                setActiveAppointment={setActiveAppointment}
                onMouseUpOnStatus={onMouseUpOnStatus}
              />
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

AppointmentsByStatus.propTypes = {
  appointments: PropTypes.array,
  loadAppointments: PropTypes.func,
};

export default observer(AppointmentsByStatus);
