import { numArray, fillTimeframe } from 'filters';
import hooks from 'hooks';
import React, { useEffect } from 'react';

import PropTypes from 'prop-types';

const TimeSetter = ({ fieldName, obj, setObj, unit, lunchState }) => {
  let hours = '';
  let minutes = '';
  let options = '';
  if (obj[fieldName]) {
    [hours, minutes] = obj[fieldName].split(':');
  }

  useEffect(() => {
    if (!obj[fieldName]) return;

    const floored = Math.floor(Number(minutes) / unit) * unit;

    if (floored !== Number(minutes)) {
      const zeroPadded = floored < 10 ? `0${floored}` : floored.toString();
      obj[fieldName] = `${hours}:${zeroPadded}`;
      setObj({ ...obj });
    }
  }, [obj, unit]);

  switch (fieldName) {
    case 'workStart':
      options = numArray(12)
        .map((val) => val + 6)
        .map((val) => {
          if (val >= 24) val -= 24;
          return `${(val.toString().length === 1 ? '0' : '') + val}`;
        });

      break;

    case 'workEnd':
      options = numArray(7)
        .map((val) => val + 17)
        .map((val) => {
          if (val >= 24) val -= 24;
          return `${(val.toString().length === 1 ? '0' : '') + val}`;
        });

      break;

    default:
      options = numArray(24)
        .map((val) => val + 8)
        .map((val) => {
          if (val >= 24) val -= 24;
          return `${(val.toString().length === 1 ? '0' : '') + val}`;
        });
  }

  return (
    <div className="time-setter">
      {hooks.selector({
        options: options,
        value: hours,
        name: fieldName,
        disabled: lunchState,
        onChange: (e) => {
          obj[fieldName] = `${e.target.value}:${minutes}`;
          setObj({ ...obj });
        },
      })}
      :
      {hooks.selector({
        options: fillTimeframe(unit || 5),
        value: minutes,
        name: fieldName,
        disabled: lunchState,
        onChange: (e) => {
          obj[fieldName] = `${hours}:${e.target.value}`;
          setObj({ ...obj });
        },
      })}
    </div>
  );
};

TimeSetter.propTypes = {
  fieldName: PropTypes.string,
  obj: PropTypes.object,
  setObj: PropTypes.func,
  unit: PropTypes.number,
  lunchState: PropTypes.bool,
};

export default TimeSetter;
