import React, { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import Checkbox from 'components/app/Checkbox';
import DataTableAlpha from 'components/data-table/DataTableAlpha';
import ModalProduct from 'components/modals/ModalProduct';
import helpers from 'hooks/helpers';
import models from 'models';
import { translate } from 'filters';
import { $qb } from 'scripts/querybuilder';
import { useEvent } from 'hooks/useEvent';
import { useModal } from 'hooks/useModal';
import { useServices } from 'hooks/useServices';
import { useToast } from 'hooks/useToast';
import styled from 'styled-components';
import searchIcon from 'assets/images/icon/ic-search.png';

const Input = styled.input`
  width: 270px;
  height: 46px;
  padding: 8px 15px 8px 50px;
  border-color: #dee2ec;
  background-color: #fff;
  background-image: url(${searchIcon});
  background-size: 19px;
  background-repeat: no-repeat;
  background-position: left 15px center;
  border-radius: 4px;
  border: 1px solid #dee2ec;
`;

const Products = () => {
  const eventBus = useEvent();
  const modal = useModal();
  const services = useServices();
  const toast = useToast();
  const defaultParams = $qb().limit(10).orderBy('id desc');
  const [data, setData] = useState(null);
  const [total, setTotal] = useState(null);
  const [params, setParams] = useState(defaultParams);
  const [searchValue, setSearchValue] = useState('');
  const [hideHidden, setHideHidden] = useState(false);

  const callApi = useCallback(async () => {
    // const resp = await storeData.actions.loadProducts()
    //let params = { limit: 300 }
    //const resp = await services.crm.payment.product.all(params)
    const resp = await services.crm.payment.product.all(params.build());
    if (!resp) return;

    setData(resp.data);
    setTotal(resp.total);
  }, [services.crm.payment.product]);

  const onParams = (p) => {
    setParams(p);
    callApi();
  };

  const openModalProduct = (product) => {
    modal
      .custom({
        component: ModalProduct,
        options: { product },
      })
      .then((idx) => (idx === 1 ? callApi() : null));
  };

  const onAction = (obj) => {
    let product = obj.row;
    let event = obj.event;
    let model = obj.key;

    const btnLabel = event.target.innerText;
    if (btnLabel === '수정') {
      return openModalProduct(product);
    }

    if (['미사용', '사용'].indexOf(btnLabel) !== -1) {
      return helpers.toggleVisibility({
        obj: product,
        endpoint: services.crm.payment.product,
        callback: callApi,
        modelName: 'product',
      });
    }

    if (model['title'] === 'ACTION' && event.target.dataset.type === 'delete') {
      modal
        .basic({
          body: `정말로 삭제하시겠습니까?`,
          buttons: [
            {
              text: 'CANCEL',
              class: 'btn-default',
            },
            {
              text: 'CONFIRM',
              class: 'btn-primary',
            },
          ],
        })
        .then((idx) => {
          idx === 1 && onClickDelete(product);
        });
    }
  };

  const onClickDelete = useCallback(
    async (payload) => {
      try {
        await services.crm.payment.product.delete(payload.id);
        modal
          .confirm({
            type: 'SUCCESS',
            msg: '삭제하였습니다.',
          })
          .then(() => {
            callApi();
          });
      } catch (e) {
        console.log(e.description);
        let errorMessage = e.description;
        modal
          .confirm({
            type: 'ERROR',
            msg: errorMessage,
          })
          .then(() => {
            callApi();
          });
      }
    },
    [toast, services.crm.payment.product, callApi]
  );

  const onClickSearch = () => {
    params.queryParams = {
      limit: params.queryParams.limit,
      orderBy: params.queryParams.orderBy,
      name: searchValue.name,
    };
    onParams(params);
  };

  useEffect(() => {
    eventBus.on('callApi', callApi);
    callApi();

    return () => eventBus.off('callApi');
  }, [eventBus, callApi]);

  return (
    <div className="products">
      <div className="route-top">
        <div className="title">{translate('PRODUCTS')}</div>
        <div className="flex-row items-center">
          <div className="flex-row items-center m-r-12">
            <Checkbox
              className="flex-wrap m-r-8"
              checked={hideHidden}
              toggleHandler={() => setHideHidden(!hideHidden)}
            />
            <span>미사용항목 보지않기</span>
          </div>
          <button
            onClick={() => openModalProduct()}
            className="btn btn-primary btn-sm flex-wrap btn-add"
          >
            {translate('ADD')}
          </button>
        </div>
      </div>
      <div className="search-bar">
        <div className="search-item">
          <Input
            name="name"
            placeholder="제품명 입력"
            onChange={(e) =>
              setSearchValue({
                ...searchValue,
                [e.target.name]: e.target.value,
              })
            }
          />
        </div>
        <div className="search-item">
          <button className="btn-sm" onClick={onClickSearch}>
            {'검색'}
          </button>
        </div>
      </div>

      <DataTableAlpha
        model={models.crm.product}
        data={hideHidden ? data.filter((product) => product.visible) : data}
        total={total}
        params={params}
        onParams={onParams}
        onAction={onAction}
        bottomPositionInner
        hideBottom={false}
        hideLimitSetter={true}
        hasDeactivate
      />
    </div>
  );
};

export default observer(Products);
