export const firebaseConfig = {
  // firebase 설정과 관련된 개인 정보
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: 'unocare-crm.firebaseapp.com',
  databaseURL: 'https://unocare-crm.firebaseio.com',
  projectId: 'unocare-crm',
  storageBucket: 'unocare-crm.appspot.com',
  messagingSenderId: '756897563356',
  appId: '1:756897563356:web:e29f1a561b9621694bea19',
  measurementId: 'G-PTT76JNRJP',
};
