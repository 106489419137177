import React, { useState, useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { translate } from 'filters';
import { $qb } from 'scripts/querybuilder';
import { useModal } from 'hooks/useModal';
import { useServices } from 'hooks/useServices';
import { useToast } from 'hooks/useToast';
import styled, { css } from 'styled-components';
import ModalAddCategory from 'components/modals/ModalAddCategory';
import ItemListComponent from './codes/payment-codes/SurgeryItemList';

const Input = styled.input`
  width: 100%;
  height: 34px;
  padding: 8px 20px;
  font-size: 12px;
  border-radius: 4px;
  border: 1px solid #dee2ec;

  ${({ disabled }) =>
    disabled &&
    css`
      background-color: #efeff4 !important;
      color: #ceced7;
    `}
`;

const ModalSurgery = ({ options, close }) => {
  const modal = useModal();
  const services = useServices();
  const toast = useToast();
  const payload = options.surgery || {};

  const nextRef = useRef(0);

  const defaultParams = $qb().limit(1000).orderBy('createdAt desc');
  const [params] = useState(defaultParams);
  const [categoryList, setCategoryList] = useState([]);
  const [initItem] = useState({
    name: '',
    treatmentCount: 1,
    roughItemC: 'd__0',
    price: 0,
    priceVatInclude: 0,
    vatIncluded: true,
    reimbursement: false,
    itemRef: null, // ref
    nextDay: 0,
    nextMonth: 0,
  });

  const [initItems] = useState(
    payload.items
      ? payload.items.map((item) => {
          return {
            ...item,
            itemRef: nextRef.current++,
          };
        })
      : [{ ...initItem, itemRef: nextRef.current++ }]
  );

  const [initObj] = useState({
    name: payload.name ? payload.name : '',
    visible: payload.visible ? payload.visible : true,
    ...(payload.id && { id: payload.id }),
    items: initItems,
  });

  const [items, setItems] = useState(initItems);
  const [obj, setObj] = useState(initObj);

  //카테고리 추가 후 팝업 닫힐 때 리턴될 객체
  const [addCategoryReturn, setAddCategoryReturn] = useState([]);

  const validator = useCallback(() => {
    if (obj['name'] === '') {
      const errorString = translate('ERROR_MISSING_FIELD').replace(
        /%s/,
        translate('SURGERY_CATEGORY')
      );
      toast.error(errorString);
      return false;
    }

    const hasNull = obj['items'].some((item) => {
      if (item['name'] === '') {
        const errorString = translate('ERROR_MISSING_FIELD').replace(
          /%s/,
          translate('SURGERY_NAME')
        );
        toast.error(errorString);
        return true;
      }

      if (item['price'] === undefined) {
        //기존 조건 item["price"] === 0 && item["price"] !== undefined
        const errorString = translate('ERROR_MISSING_FIELD').replace(
          /%s/,
          `(${item['name']} - ${translate('PRICE')})`
        );
        toast.error(errorString);

        return true;
      }

      return false;
    });

    return !hasNull;
  }, [obj]);

  const categoryCallApi = useCallback(async () => {
    const resp = await services.crm.treatment.categories.items_categories_v2(
      params.build()
    );
    if (!resp) return;

    setCategoryList(resp.data);
  }, [params]);

  const onClickSave = useCallback(
    async (categoryList) => {
      setObj((obj) => ({
        ...obj,
        items: items,
      }));

      if (validator()) {
        try {
          const item = categoryList.filter((v) => {
            return v.id === parseInt(obj.id);
          });

          let objItems = obj.items.map((v, i) => {
            //v['priceVatInclude'] = v.vatIncluded ? Math.round(v.price*1.1) : v.price
            v.price = v.vatIncluded
              ? Math.round(v.priceVatInclude / 1.1)
              : v.priceVatInclude;
            v.order = i + 1;

            return v;
          });
          let payload =
            options.surgery === undefined
              ? { ...obj, items: [...item[0].items, ...objItems] }
              : {
                  ...obj,
                  items: objItems,
                  order: item[0].order || categoryList.length,
                };

          const { id, name, order, visible, items } = payload;
          let changeItems = items.map((v, i) => {
            v.order = i + 1;
            const {
              id,
              name,
              nextDay,
              nextMonth,
              order,
              price,
              priceVatInclude,
              treatmentCount,
              vatIncluded,
              visible,
            } = v;
            return {
              id,
              name,
              nextDay,
              nextMonth,
              order,
              price,
              priceVatInclude,
              treatmentCount,
              vatIncluded,
              visible,
            };
          });

          let changePayload = {
            id,
            name,
            order,
            visible,
            items: changeItems,
          };

          const resp = await services.crm.treatment.categories.update_v2(
            changePayload
          );
          modal
            .confirm({
              type: 'SUCCESS',
              msg: '업데이트되었습니다.',
            })
            .then(() => {
              if (options.afterUpdateReturn) {
                //업데이트 후 리턴을 할 필요가 있을때
                let closeObj = {
                  category: resp.data || [],
                  items: obj.items || [],
                };
                close([...addCategoryReturn, closeObj]);
              } else {
                close(1);
              }
            });
        } catch (e) {
          const { code, description } = e;
          console.log(description);
          let errorMessage = description;
          errorMessage = code === 409 && '중복되는 이름이 있습니다.';
          modal.confirm({
            type: 'ERROR',
            msg: errorMessage,
          });
        } finally {
          // close()
        }
      }
    },
    [close, items, obj, validator, options.surgery, addCategoryReturn]
  );

  const addItem = useCallback(() => {
    const item = { ...initItem, itemRef: nextRef.current++ };
    setItems((items) => items.concat(item));
  }, [initItem]);

  const onRemoveItem = useCallback((ref) => {
    setItems((items) =>
      items.filter((item) => {
        if (item['itemRef'] !== ref) {
          //TODO 분기처리 필요
        }
        return item['itemRef'] !== ref;
      })
    );
  }, []);

  const onChangeItem = useCallback((payload) => {
    setItems((items) =>
      items.map((item) => {
        if (item['itemRef'] === payload['itemRef']) {
          return payload;
        } else {
          return item;
        }
      })
    );
  }, []);

  useEffect(() => {
    categoryCallApi();
  }, [categoryCallApi]);

  useEffect(() => {
    setObj((obj) => ({
      ...obj,
      items: items,
    }));
  }, [items]);

  const onChangeCategory = (e) => {
    const { value } = e.target;
    const item = categoryList.filter((v) => {
      return v.id === parseInt(value);
    });

    setObj({
      ...obj,
      id: parseInt(value),
      name: item[0].name,
    });
  };

  const onChangeHandler = useCallback(
    (e) => {
      setObj({
        ...obj,
        [e.target.name]: e.target.value,
      });
    },
    [obj]
  );

  const addCategory = () => {
    modal
      .custom({
        component: ModalAddCategory,
        options: { afterUpdateReturn: options.afterUpdateReturn },
      })
      .then((result) => {
        categoryCallApi();
        if (options.afterUpdateReturn && result?.id) {
          //업데이트 후 리턴을 할 필요가 있을때
          let returnObj = {
            category: result || [],
            items: result.items || [],
          };
          setAddCategoryReturn((value) => {
            return [...value, returnObj];
          });
        }
      });
  };

  return (
    <div className="modal-surgery">
      <div className="head flex-row flex-between items-start">
        <div className="title">
          {options.surgery !== undefined
            ? translate('EDIT_SURGERY')
            : translate('ADD_SURGERY')}
        </div>
        <i
          onClick={() => {
            if (options.afterUpdateReturn) {
              close(addCategoryReturn);
            } else {
              close(1);
            }
          }}
          className="zmdi zmdi-close"
        />
      </div>
      <div className="body">
        <div className="form-control wrap-title">
          <div className="title">
            <label className="label-required m-b-20">카테고리 선택</label>
            <div className="right">
              <button
                className="btn btn-basic _small m-l-8"
                onClick={() => addCategory()}
              >
                카테고리 추가
              </button>
            </div>
          </div>
          {options.surgery === undefined ? (
            <>
              <select
                name="id"
                onChange={onChangeCategory}
                value={obj.id || ''}
              >
                <option value="" hidden disabled>
                  원하는 카테고리가 없으면 카테고리 추가 버튼을 클릭하세요
                </option>
                {categoryList.map((v, i) => {
                  return (
                    <option key={i} value={v.id}>
                      {v.name}
                    </option>
                  );
                })}
              </select>
            </>
          ) : (
            <Input
              disabled
              name="name"
              value={obj.name}
              type="text"
              onChange={onChangeHandler}
            />
          )}
        </div>
        <div className="row">
          <ItemListComponent
            payload={items}
            onChangeItem={onChangeItem}
            onRemoveItem={onRemoveItem}
          />
        </div>
      </div>
      <button
        className="btn btn-basic _small _full m-t-20"
        onClick={() => addItem()}
      >
        입력란 추가
      </button>
      <div className="buttons">
        <div className="flex-row">
          <button
            onClick={() => {
              if (options.afterUpdateReturn) {
                close(addCategoryReturn);
              } else {
                close(1);
              }
            }}
            className="btn btn-cancel _small m-r-8"
          >
            {translate('CANCEL')}
          </button>
          <button
            onClick={() => onClickSave(categoryList)}
            className="btn btn-basic _small"
          >
            {translate('SAVE')}
          </button>
        </div>
      </div>
    </div>
  );
};

ModalSurgery.propTypes = {
  options: PropTypes.object,
  close: PropTypes.func,
};

export default observer(ModalSurgery);
