export default {
  keys: [
    {
      title: 'ACTION',
      filter: (o) => `
            ${
              o.visible
                ? `<button class="btn btn-sm btn-primary m-r-8">${
                    o.$$editing ? '저장' : '수정'
                  }</button>`
                : ''
            }
            <button class="btn btn-sm ${
              o.visible ? 'btn-danger' : 'btn-primary'
            }">${o.visible ? '숨김' : '숨김해제'}</button>`,
    },
    {
      title: 'REFUND_REASONS',
      column: 'content',
      type: 'editable',
    },
  ],
};
