import React, { useEffect, useState, useRef } from 'react';
import { observer } from 'mobx-react';
import LimitSetter from './LimitSetter';
import Pagination from './Pagination';
import Checkbox from 'components/app/Checkbox';
import hooks from 'hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage } from '@fortawesome/free-solid-svg-icons';
import { Tooltip, Button } from 'antd';
import {
  currency,
  currencySymbol,
  removeCurrency,
  formatDate,
  translate,
} from 'filters';
import TableContextMenu from 'components/data-table/TableContextMenu';
import SubDataTable from './SubDataTable';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import './datatable.css';

const EditableInput = styled.input`
  min-height: 10px;
  padding: 16px 20px;
  border-radius: 4px;
  background-color: #f3f8ff;
  font-size: 14px;
  border: 1px solid #dee2ec;
  width: 100%;
  height: 34px;
`;

const Input = styled.input`
  width: 70px;
  margin-right: 8px;
  font-size: 12px;
  min-height: 10px;
  padding: 16px 20px;
  border-radius: 4px;
  background-color: #f3f8ff;
  color: #000;
  border: 1px solid #dee2ec;
  height: 34px;
`;

const CurrencyInput = styled(Input)`
  width: 120px;
  margin-right: 8px;
`;

/*  
  DataTable Alpha v1.0 
  기획서상 시/수술, 패키지 세부항목으로 분리되는 UI 적용을 위해, 
  기존 DataTable를 가능한 재사용할 수 있게 행병합/행분리 기능 추가
  사이드이펙트 발생여지가 있어, 충분한 테스트 후 적용하기위해 분리함.   
  별다른 이슈없을 시 DataTable에 병합 '당할'예정 
  ( [DataTableAlpha + DataTable] -> DataTable ) : v1.0에선 예정과 반대로 수행함 - 이하 로그
  
  v0.1
  데이터 로딩 시, 로딩중이 출력되는 플로우 추가
  v0.2
  HQL의 explode 구현.
  > 우선은, 원본객체를 같이 들고 다니게함
  v0.3
  부모셀(병합되는열)의 rowspan element 구성
  v0.4
  기존 render return 부분의 경우의수가 너무많아 가독성을 위해 함수형으로 뽑아냄
  v0.5
  rowspan에 대한 ui 분기처리
  v0.6
  로딩중의 html/css 처리
  v0.7
  explode된 컬럼명은 기존컬럼명 앞에 @@를 붙임  
  그 외 explode과정에서 추가된 property는 @@를 앞에 명시함 (데이터와 혼동하지 않게하기 위함)
  v0.8
  병합된 컬럼의 hover background css 처리  
  병합된 컬럼은 hover background를 무시하고, 병합된 셀간 행 사이에 border로 경계를 구분함
  세부항목내 버튼관련 css추가
  v0.9
  이미 데이터가 로드되어 있는 상태에서 로딩 요청 시, 기존 로딩 전 로드되어 있던 데이터를 보여주지 않게 함 
  v1.0 기존 DataTable과 병합
  [ DataTable + DataTableAlpha ] -> DataTableAlpha
  참조된 DataTable의 commit 버전 : 1cecf562d67aa5867ffc78c4440435c4f56bcd27

  thStyle : th 스타일 지정
  tdDivStyle : td내의 div에 스타일 지정

  checkbox : 모든 로우에 체크박스를 할 수 있음. hideAllCheck > th 모든 로우 선택하는 체크박스 숨김여부
  multiCheck : checkUse = false 지정한 로우에만 체크박스를 노출
  subcheckbox : 기본 체크박스 외에 추가적인 컬럼에서도 체크박스를 사용해야 할 때 사용
    checkbox > $$checked로 컬럼 구분을 할 수 없음.
    subcheckbox > 체크시 "[key.title]_$$checked"로 각 컬럼별로 체크박스 구분 가능


  key.type = input 추가
  [key.title]_$$input으로 로우별 value 지정 가능
  inputUnit > input에 따라오는 단위문구 지정 (ex: inputUnit:회 > N회)

  key.type = input_currency 추가
  화폐단위 사용시

  props : expandable > 확장형 테이블 사용
  subModel > 확장형 테이블에서 사용할 subModel
  SubDataTable 단순화한 datatable을 서브테이블로 사용

  테이블 내에서 컨텍스트메뉴 사용
  model. filter로 마크업 정의시, 클래스명에 not-context 사용시, 해당 클래스에는 컨텍스트 메뉴 적용되지 않도록함.
  model. useContext: 사용 시 true로 정의
  model. contextTrigger: context 사용시 click / doubleClick 등 으로 노출 구분.
  model. contextItems : 컨텍스트 ul 그릴 list
    ex) [{title: '수정'}, {title: '삭제'}]
  onContextAction : 컨텍스트 li 선택 시 발생하는 action / onSubContextAction : SubTable 내에서의 컨텍스트 액션
    선택한 li 타이틀과 해당 row를 반환

  테이블 헤더 rowspan/colspan 적용
  model.options.rowspan : (type:number)rowspan 적용을 위한 숫자
  keys.rowspan : (type:number)rowspan 적용을 위한 숫자
  ex)<th rowspan="${number}"></th>
  keys.colspan : (type:number)colspan 적용을 위한 숫자
  ex)<th colspan="${number}"></th>
*/

const debounce = function (fn, delay) {
  let timeoutID = null;
  return function () {
    clearTimeout(timeoutID);
    let args = arguments;
    let that = this;
    timeoutID = setTimeout(function () {
      fn.apply(that, args);
    }, delay);
  };
};

const DataTableAlpha = ({
  data,
  total,
  model,
  params,
  hideBottom,
  bottomPositionInner,
  onAction,
  dragTypes,
  onDragAction,
  onParams,
  loading,
  hideHidden,
  className,
  hideLimitSetter, // by DataTable
  onAllCheckAction, // by DataTable
  hideAllCheck,
  mergeColumn,
  hasLine,
  hasDeactivate,
  expandable,
  subModel,
  onContextAction,
  onSubContextAction,
  onSubAction,
}) => {
  const forceUpdate = hooks.useForceUpdate();
  const searchInputs = useRef();
  // const [currentIndex, setCurrentIndex] = useState(0)
  const [currentIndex, setCurrentIndex] = useState(
    params && params.queryParams && params.queryParams.page
      ? params.queryParams.page - 1
      : 0
  );
  const [limit, setLimit] = useState(params ? params.queryParams.limit : 20);
  const [rows, setRows] = useState([]);
  const [contextMenuOptions, setContextMenuOptions] = useState({});
  let dragItem;
  let rowValue;
  let dragOverRowId = '';
  let detailType, detailType2;
  detailType = dragTypes === 'Department' ? '@@departments' : '@@items';
  detailType2 = dragTypes === 'Department' ? 'departments' : 'items';
  let placeholder = document.createElement('tr');
  let tr = document.createElement('tr');
  placeholder.className = 'placeholder';

  //dragTypes 에 따른 placeholder td 갯수
  let td = [];
  let t = dragTypes === 'Department' ? 3 : 8;
  for (let i = 0; i < t; i++) {
    td[i] = document.createElement('td');
    td[i].className = 'td-placeholder';
    placeholder.appendChild(td[i]);
  }

  placeholder.addEventListener(
    'dragover',
    function (e) {
      e.preventDefault();
    },
    false
  );

  placeholder.addEventListener(
    'drop',
    function (e) {
      let type = dragItem;
      e.preventDefault();
      let dragRow = JSON.parse(e.dataTransfer.getData('text'));
      if (dragItem === 'category') {
        let rowIndex = e.target.parentNode.getAttribute('name');
        let dragRowIndex = dragRow.index;
        let ele = document.getElementsByClassName('placeholder')[0];
        if (ele) ele.parentNode.removeChild(placeholder);
        if (onDragAction) onDragAction({ e, rowIndex, dragRowIndex, type });
      }
    },
    false
  );

  td[0].addEventListener(
    'dragover',
    function (e) {
      e.preventDefault();
    },
    false
  );

  td[0].addEventListener(
    'drop',
    function (e) {
      let dragRow = JSON.parse(e.dataTransfer.getData('text'));
      let type = dragItem;
      if (dragItem === 'item') {
        // 부서가 다르면 X
        if (dragRow.index !== rowValue) {
          return false;
        }

        //item name null 체크
        if (!(dragRow[detailType] || {}).name) {
          return false;
        }

        let categoryIndex;
        let dragRowIndex;
        let rowIndex;
        categoryIndex = rowValue;
        dragRowIndex = dragRow[detailType2].findIndex(
          (v) => v.name === dragRow[detailType].name
        );
        rowIndex = dragRow[detailType2].findIndex(
          (v) => v.id === Number(e.target.getAttribute('name'))
        );

        //placeholder 지우기
        let ele = document.getElementsByClassName('td-placeholder');
        if (
          ele &&
          ele[0] &&
          ele[0].parentNode.className.includes('not-merged') &&
          ele[0].parentElement.children[1]
        ) {
          let firstTr = document.getElementsByName(rowValue)[1];
          firstTr.className = firstTr.className.replace(
            ' merged',
            ' not-merged'
          );
          firstTr.insertBefore(
            ele[0].parentElement.children[1],
            firstTr.firstChild
          );
          firstTr.insertBefore(
            ele[0].parentElement.children[0],
            firstTr.firstChild
          );
          firstTr.children[0].setAttribute(
            'rowspan',
            dragRow[detailType2].length
          );
          firstTr.children[1].setAttribute(
            'rowspan',
            dragRow[detailType2].length
          );
          if (ele && ele[0]) ele[0].parentNode.remove();
        } else {
          let firstTr = document.getElementsByName(rowValue)[0];
          firstTr.children[0].setAttribute(
            'rowspan',
            dragRow[detailType2].length
          );
          firstTr.children[1].setAttribute(
            'rowspan',
            dragRow[detailType2].length
          );
          if (ele && ele[0]) ele[0].parentNode.remove();
        }
        if (onDragAction)
          onDragAction({ e, rowIndex, dragRowIndex, type, categoryIndex });
      }
    },
    false
  );

  const onContextMenu = (e, row, contextItems, column) => {
    e.preventDefault();
    setContextMenuOptions({
      show: true,
      x: e.clientX,
      y: e.clientY,
      row: row,
      items: contextItems,
      clickColumn: column,
    });
  };

  const onClickContextMenuItem = async ({ item, row, clickColumn }) => {
    setContextMenuOptions({});
    if (item === -1) {
      return;
    }

    onContextAction({ item, row, clickColumn });
  };

  const [options] = useState({
    hasMergeCell:
      (model && model.options && model.options.hasMergeCell) || false,
    groupTitles: (model && model.options && model.options.groupTitles) || [],
    explodeColumn:
      (model && model.options && model.options.explodeColumn) || null,
  });

  useEffect(() => {
    if (options.hasMergeCell) {
      const parsed = dataExploder({
        ...options,
        payload: data,
        hideHidden: hideHidden,
      });
      setRows(parsed);
    } else {
      setRows(data);
    }
  }, [data, hideHidden, options]);

  useEffect(() => {
    if (params !== undefined) {
      if (params.queryParams.offset === 0 && params.queryParams.page === 1) {
        resetOffset();
      }
    }
  }, []);

  useEffect(() => {
    const hasPageParam =
      params && params.queryParams && params.queryParams.page;
    setCurrentIndex(hasPageParam ? params.queryParams.page - 1 : 0);
    return () => {
      setCurrentIndex(0);
    };
  }, [params]);

  const dataExploder = ({ explodeColumn, payload, hideHidden }) => {
    if (!Array.isArray(payload)) return [];

    // 데이터량이 많아 질 경우. 다음의 함수를 고려 [flatMap, for, reduce]
    const isVisible = (data, hidden) => {
      return hidden
        ? Object.prototype.hasOwnProperty.call(data, 'visible')
          ? data.visible
          : true
        : true;
    };

    return payload.reduce((result, obj, index) => {
      if (!Object.prototype.hasOwnProperty.call(obj, explodeColumn))
        return result;

      const child__length = obj[explodeColumn].filter((obj) => {
        return isVisible(obj, hideHidden);
      }).length;

      obj['key@@parentKey'] = index;
      obj['next@@idx'] = child__length;
      const childObjects =
        obj[explodeColumn].length === 0
          ? //mergeColumn이 빈배열인경우 예외처리
            (mergeColumn || [])
              .filter((obj) => {
                return isVisible(obj, hideHidden);
              })
              .map((child__obj, child__idx) => {
                if (child__idx === 0) {
                  obj['@@rowspan'] = 1;
                  obj['@@merged'] = false;
                } else {
                  obj['@@rowspan'] = 0;
                  obj['@@merged'] = true;
                }
                child__obj['exploded@@order'] = child__idx;
                child__obj['key@@child'] = child__idx;
                return { ...obj, [`@@${explodeColumn}`]: child__obj };
              })
          : obj[explodeColumn]
              .filter((obj) => {
                return isVisible(obj, hideHidden);
              })
              .map((child__obj, child__idx) => {
                if (child__idx === 0) {
                  obj['@@rowspan'] = child__length;
                  obj['@@merged'] = false;
                } else {
                  obj['@@rowspan'] = 0;
                  obj['@@merged'] = true;
                }
                child__obj['exploded@@order'] = child__idx;
                child__obj['key@@child'] = child__idx;
                return { ...obj, [`@@${explodeColumn}`]: child__obj };
              });
      return result.concat(childObjects);
    }, []);
  };

  const order = (key) => {
    if (!params) return;

    if (!key.column || key.nojoin) return;

    model.keys.forEach((k) => {
      if (k.column !== key.column) {
        k.order = undefined;
        return;
      }
      k.order = k.order === 'desc' ? 'asc' : 'desc';
      params.orderBy(`${k.column} ${k.order}`);
    });

    onPage();
  };

  const onPage = (idx) => {
    if (!params) return;

    if (idx >= 0) {
      setCurrentIndex(idx);
    }
    params.customParam('page', idx + 1);
    model.currentIndex = idx;
    onParams && onParams(params);
  };

  const onLimit = (l) => {
    if (!params) return;

    resetOffset();
    setLimit(l);
    params.limit(l);
    model.limit = l;
    onPage();
  };

  const allCheckAction = (data, allChecked) => {
    if (onAllCheckAction) onAllCheckAction(data, allChecked);
  };
  const doAction = (event, row, key, rowIdx, eventType) => {
    const classList = event.target.classList;

    if (key.type === 'textarea' || key.type === 'input') return;

    // context를 사용하고, 클릭한 이벤트 타입에 맞게 걸려있으며,
    // not-context 를 클래스명에 포함하지 않는 경우에만 컨텍스트 동작
    if (
      key.useContext &&
      key.contextTrigger === eventType &&
      !classList.contains('not-context')
    ) {
      onContextMenu(event, row, key.contextItems, key.column);
      return;
    }

    if (onAction) onAction({ row, key, event, rowIdx, eventType });
  };

  const onBlur = (event, row, key, rowIdx) => {
    if (onAction) onAction({ row, key, event, rowIdx });
  };

  const onVatToggle = (e, row, key, rowIdx) => {
    row.vatIncluded = !row.vatIncluded;
    if (row.vatIncluded) {
      row.price = Math.round(row.priceVatInclude / 1.1);
      row['NOT_VAT_INCLUDE_$$input'] = row.price;
    } else {
      row.price = row.priceVatInclude;
      row['NOT_VAT_INCLUDE_$$input'] = row.price;
    }
    doAction(e, row, key, rowIdx);
    forceUpdate();
  };

  const onDragStart = (e, row) => {
    e.dataTransfer.effectAllowed = 'move';
    dragItem = 'category';
    if (e.target.nodeName !== 'TR') dragItem = 'item';
    rowValue = row.index;
    e.dataTransfer.setData('text', JSON.stringify(row));
  };

  const onDragEnd = (e, row) => {
    //세부항목 DragEnd
    if (dragItem === 'item') {
      //placeholder가 첫 번째 tr에 있는 경우
      let ele = document.getElementsByClassName('td-placeholder');
      if (
        ele &&
        ele[0] &&
        ele[0].parentNode.className.includes('not-merged') &&
        ele[0].parentElement.children[1]
      ) {
        let firstTr = document.getElementsByName(row.index)[1];
        firstTr.className = firstTr.className.replace(' merged', ' not-merged');
        firstTr.insertBefore(
          ele[0].parentElement.children[1],
          firstTr.firstChild
        );
        firstTr.insertBefore(
          ele[0].parentElement.children[0],
          firstTr.firstChild
        );
        firstTr.children[0].setAttribute('rowspan', row[detailType2].length);
        firstTr.children[1].setAttribute('rowspan', row[detailType2].length);
        if (ele && ele[0]) ele[0].parentNode.remove();
      } else {
        let firstTr = document.getElementsByName(row.index)[0];
        if (firstTr && firstTr.children && firstTr.children.length > 1) {
          firstTr.children[0].setAttribute('rowspan', row[detailType2].length);
          firstTr.children[1].setAttribute('rowspan', row[detailType2].length);
        }
        if (ele && ele[0]) ele[0].parentNode.remove();
      }
      return false;
    }
    //카테고리 DragEnd
    else {
      if (document.getElementsByClassName('placeholder').length > 0) {
        let ele = document.getElementsByClassName('placeholder')[0];
        if (ele) ele.parentNode.removeChild(placeholder);
      }
    }
  };

  const onDrop = (e, row) => {
    e.stopPropagation();
    e.preventDefault();

    if (onDragAction) return;
    if (e.dataTransfer.effectAllowed !== 'move') return;

    let dragRow = JSON.parse(e.dataTransfer.getData('text'));
    let type;
    // 카테고리
    if (dragItem === 'category') {
      type = 'category';
      let rowIndex = row.index;
      let dragRowIndex = JSON.parse(e.dataTransfer.getData('text')).index;
      let ele = document.getElementsByClassName('placeholder')[0];
      //placeholder 삭제
      if (document.getElementsByClassName('placeholder').length > 0) {
        if (ele) ele.parentNode.removeChild(placeholder);
        if (onDragAction) onDragAction({ e, rowIndex, dragRowIndex, type });
      }
    }
    // 세부항목
    if (dragItem === 'item') {
      // 카테고리가가 다르면 X
      if (row.index !== rowValue) {
        return false;
      }
      type = 'item';
      let categoryIndex;
      let dragRowIndex;
      let rowIndex;
      // event target TagName 이 td 인 경우
      if (e.target.nodeName === 'TD') {
        categoryIndex = e.target.parentElement.getAttribute('name');
        if (
          row &&
          row[detailType2] &&
          dragRow[detailType] &&
          dragRow[detailType].name
        ) {
          dragRowIndex = row[detailType2].findIndex(
            (v) => v.name === dragRow[detailType].name
          );
          rowIndex = row[detailType2].findIndex(
            (v) => v.name === e.target.innerText.split('\n')[0]
          );
        }
      }
      // event target TagName 이 div 인 경우들
      else {
        if (
          row &&
          row[detailType2] &&
          dragRow[detailType] &&
          dragRow[detailType].name
        ) {
          categoryIndex = rowValue;
          dragRowIndex = row[detailType2].findIndex(
            (v) => v.name === dragRow[detailType].name
          );
          rowIndex = row[detailType2].findIndex(
            (v) => v.name === row[detailType].name
          );
        } else {
          return false;
        }
      }
      //첫 번째 tr class 변경 , td rowspan 적용 후 placeholder 삭제
      let ele = document.getElementsByClassName('td-placeholder');
      // 첫 번째 tr이 placeholder td 를 포함하는 경우
      if (
        ele &&
        ele[0] &&
        ele[0].parentNode.className.includes('not-merged') &&
        ele[0].parentElement.children[1]
      ) {
        let firstTr = document.getElementsByName(row.index)[1];
        firstTr.className = firstTr.className.replace(' merged', ' not-merged');
        firstTr.insertBefore(
          ele[0].parentElement.children[1],
          firstTr.firstChild
        );
        firstTr.insertBefore(
          ele[0].parentElement.children[0],
          firstTr.firstChild
        );
        firstTr.children[0].setAttribute('rowspan', row[detailType2].length);
        firstTr.children[1].setAttribute('rowspan', row[detailType2].length);
        if (ele && ele[0]) ele[0].parentNode.remove();
      }
      //
      else {
        let firstTr = document.getElementsByName(row.index)[0];
        if (firstTr && firstTr.children && firstTr.children.length > 1) {
          firstTr.children[0].setAttribute('rowspan', row[detailType2].length);
          firstTr.children[1].setAttribute('rowspan', row[detailType2].length);
        }
        if (ele && ele[0]) ele[0].parentNode.remove();
      }
      if (onDragAction)
        onDragAction({ e, rowIndex, dragRowIndex, type, categoryIndex });
    }
  };

  const onDragOver = (e, row) => {
    e.preventDefault();
    //카테고리 DragOver
    if (
      dragItem === 'category' &&
      e.target.tagName == 'TD' &&
      (e.target.className.includes('column-1') ||
        e.target.className.includes('column-2'))
    ) {
      let ele = document.querySelector('.row-first');
      placeholder.setAttribute(
        'name',
        e.target.parentNode.getAttribute('name')
      );
      if (dragOverRowId !== row.id) {
        ele.parentNode.insertBefore(placeholder, e.target.parentNode);
      }
      dragOverRowId = row.id;
    }

    //세부함옥 DragOver
    else if (
      dragItem === 'item' &&
      e.target.tagName == 'TD' &&
      e.target.className.includes('column-3')
    ) {
      let ele = document.querySelector('.column-3');

      // 다른 부서로 drag 할 때는 X
      if (e.target.parentNode.getAttribute('name') !== String(rowValue)) {
        return false;
      }

      // 요소가 하나읠 때 X
      if (
        document.getElementsByName(e.target.parentNode.getAttribute('name'))
          .length < 2
      ) {
        return false;
      }

      //이전 Dragover한 Tr 이 아닌 경우
      if (dragOverRowId !== row[detailType].id) {
        //dragover가 tr class not-merged 에서 일어난 경우 (첫 번째 tr)
        if (e.target.parentNode.className.includes('not-merge')) {
          let firstTr = document.getElementsByName(
            e.target.parentNode.getAttribute('name')
          )[0];
          tr.className = e.target.parentNode.className;
          tr.setAttribute('name', e.target.parentNode.getAttribute('name'));
          firstTr.className = firstTr.className.replace('not-merged', 'merged');
          let rowSpan = row[detailType2].length + 1;

          firstTr.childNodes[0].setAttribute('rowspan', Number(rowSpan));
          firstTr.childNodes[1].setAttribute('rowspan', Number(rowSpan));

          tr.appendChild(firstTr.childNodes[0]);
          tr.appendChild(firstTr.childNodes[0]);
          td[0].setAttribute('name', row[detailType].id);
          if (dragTypes === 'Surgeries') {
            tr.appendChild(td[0]);
            tr.appendChild(td[1]);
            tr.appendChild(td[2]);
            tr.appendChild(td[3]);
            tr.appendChild(td[4]);
            tr.appendChild(td[5]);
          } else {
            tr.appendChild(td[0]);
          }
          ele.parentNode.parentNode.insertBefore(tr, e.target.parentNode);
          dragOverRowId = row[detailType].id;
        } else {
          let firstTr = document.getElementsByName(
            e.target.parentNode.getAttribute('name')
          )[0];
          // 첫 번째 tr 이 placeholder인 경우
          if (
            firstTr.children[2] &&
            firstTr.children[2].className === 'td-placeholder'
          ) {
            //  placeholder tr 제외한 첫번째 요소에서 dragover가 발생한 경우
            if (
              document.getElementsByName(
                e.target.parentNode.getAttribute('name')
              )[1].lastChild === e.target
            ) {
              return false;
            } else {
              firstTr = document.getElementsByName(
                e.target.parentNode.getAttribute('name')
              )[1];
              let trTemp = document.getElementsByName(
                e.target.parentNode.getAttribute('name')
              )[0];
              firstTr.insertBefore(trTemp.children[1], firstTr.firstChild);
              firstTr.insertBefore(trTemp.children[0], firstTr.firstChild);
              trTemp.remove();
              firstTr.className = firstTr.className.replace(
                ' merged',
                ' not-merged'
              );
            }
          } else {
            let rowSpan = row[detailType2].length + 1;
            firstTr.childNodes[0].setAttribute('rowspan', Number(rowSpan));
            firstTr.childNodes[1].setAttribute('rowspan', Number(rowSpan));
            tr.className = tr.className.replace('not-merged', 'merged');
          }
          td[0].setAttribute('name', row[detailType].id);
          if (dragTypes === 'Surgeries') {
            tr.appendChild(td[0]);
            tr.appendChild(td[1]);
            tr.appendChild(td[2]);
            tr.appendChild(td[3]);
            tr.appendChild(td[4]);
            tr.appendChild(td[5]);
          } else {
            tr.appendChild(td[0]);
          }
          ele.parentNode.parentNode.insertBefore(tr, e.target.parentNode);
          dragOverRowId = row[detailType].id;
        }
      } else {
        return false;
      }
    } else {
      return e.preventDefault();
    }
  };

  const getValue = (row, key) => {
    if (!row || !key.column) return;

    let cols = key.column.split('__');
    if (cols.length === 1) {
      if ((key.type || '').includes('timestamp')) {
        return formatDate(
          row[cols[0]],
          `YYYY-MM-DD ${key.type.includes('day') ? '(ddd)' : 'HH:mm:ss'}`
        );
      }
      if ((key.type || '').includes('date')) {
        return formatDate(row[cols[0]], 'YYYY-MM-DD');
      }
      return row[cols[0]];
    }

    let newKey = JSON.parse(JSON.stringify(key));
    newKey.column = cols.slice(1).join('__');
    return getValue(row[cols[0]], newKey);
  };

  const image = (row, key) => {
    let imageUrl = key.filter ? key.filter(row) : getValue(row, key);
    // return imageUrl || blank

    const nullImage = (
      <div className="null-image">
        <span className="image-icon fa-layers fa-fw fa-lg">
          <FontAwesomeIcon className="" icon={faImage} size="lg" />
          {/* <FontAwesomeIcon icon={faSlash} size='sm' /> */}
        </span>
      </div>
    );

    const hasImage = <img className="img-cover m-a" alt="" src={imageUrl} />;
    // return imageUrl || blank
    return (
      <div className="image-thumbnail">{imageUrl ? hasImage : nullImage}</div>
    );
  };

  const getArrow = (row, key) => {
    if (row.visible !== undefined) {
      if (!row.visible) {
        return '';
      }
    }

    if (key.column.indexOf('@@') > -1) {
      //@@를 포함하고 있으면 merge-row
      let { explodeColumn } = model.options;
      if (!row[`@@${explodeColumn}`]['visible']) {
        return '';
      }
    }

    return (
      <div className="table-arrow flex-wrap">
        <i className="zmdi zmdi-long-arrow-up" />
        <i className="zmdi zmdi-long-arrow-down" />
      </div>
    );
  };

  const onFilter = debounce(function (key, preventResetParam) {
    if (!params) return;
    if (key && !/[0-9a-zA-Zㄱ-ㅎ가-힣]/.test(key)) return;
    if (!preventResetParam) {
      resetOffset();
    }
    Object.keys(params.queryParams).forEach((key) => {
      if (['limit', 'orderBy', 'offset'].indexOf(key) === -1) {
        delete params.queryParams[key];
      }
    });
    model.keys
      .filter(Object.prototype.hasOwnProperty.call(key, 'searchValue'))
      .forEach((key) => {
        let col = key.customParamFieldName || key.column;
        let value = key.searchValue;
        if (value) params.customParam(col, value);
        else delete params.queryParams[col];
      });
    onParams && onParams(params);
  }, 250);

  const setSearchValue = (key, value) => (key.searchValue = value);

  const resetOffset = () => {
    if (!params) return;

    setCurrentIndex(0);
    params.offset(0);
  };

  const resetQuery = () => {
    model.keys.forEach((key) => {
      key.order = null;
      key.searchValue = null;
    });

    const newParams = {
      limit: params.queryParams.limit,
      orderBy: params.queryParams.orderBy,
      offset: params.queryParams.offset,
    };
    params.queryParams = newParams;
    let dom = searchInputs.current.getElementsByTagName('input');
    for (let i = 0; i < dom.length; i++) {
      dom[i].value = '';
    }
    resetOffset();
    onFilter();
  };

  const isLegacyType = (model, row) => {
    if (
      !model ||
      !row ||
      !Object.prototype.hasOwnProperty.call(model, 'options') ||
      !Object.prototype.hasOwnProperty.call(model.options, 'groupTitles') ||
      !Object.prototype.hasOwnProperty.call(row, '@@merged')
    ) {
      return true;
    } else {
      return false;
    }
  };

  const isDisplayTd = (model, row, key) => {
    if (isLegacyType(model, row, key)) {
      return true;
    }
    return !(
      model.options.groupTitles.indexOf(key.title) !== -1 && row['@@merged']
    );
  };

  /*const isMergedTd = (model, row, key) => {
    if (isLegacyType(model, row, key)) {
      return true;
    }
    return (
      model.options.groupTitles.indexOf(key.title) !== -1 && row['@@merged']
    );
  };*/

  const isGroupedTd = (model, row, key) => {
    if (isLegacyType(model, row, key)) {
      return false;
    }
    return model.options.groupTitles.indexOf(key.title) !== -1;
  };

  const getRowSpan = (model, row, key) => {
    if (isLegacyType(model, row, key)) {
      return 1;
    }
    return model.options.groupTitles.indexOf(key.title) !== -1
      ? row['@@rowspan']
      : 1;
  };

  return (
    <>
      <TableContextMenu
        options={contextMenuOptions}
        onClick={onClickContextMenuItem}
      />
      <div
        className={`data-table ${className ? className : ''} ${
          hasDeactivate ? 'white' : ''
        }`}
      >
        <table
          className={`${
            bottomPositionInner && (rows || []).length > 0
              ? 'border-bottom'
              : ''
          } ${
            mergeColumn ||
            (rows && rows.filter((v) => v['@@merged']).length > 0)
              ? 'merge-column'
              : ''
          }`}
        >
          <thead>
            <tr>
              {model.keys
                .filter((o) => !o.hideColumn)
                .map((key, idx) => {
                  let thDivStyle = {
                    display:
                      key.type && key.type == 'multiCheck'
                        ? 'inline-block'
                        : '',
                  };
                  let titleStyle = {};
                  if (key.thStyle !== undefined) {
                    thDivStyle = { ...thDivStyle, ...key.thStyle() };
                  }
                  if (key.titleStyle !== undefined) {
                    titleStyle = { ...titleStyle, ...key.titleStyle() };
                  }

                  return (
                    key.rowSpan !== true && (
                      <th
                        rowSpan={key.rowspan}
                        colSpan={key.colspan}
                        onClick={() => !key.noorder && order(key)}
                        key={idx}
                        className={`${key.class ? key.class : ''}`}
                      >
                        <div
                          className={`flex-row items-center`}
                          style={thDivStyle}
                        >
                          <div>
                            <span
                              style={titleStyle}
                              dangerouslySetInnerHTML={{
                                __html: translate(key.title),
                              }}
                            ></span>
                            {key.type === 'tooltip' ? (
                              <Tooltip placement="top" title={key.tooltipText}>
                                <Button>?</Button>
                              </Tooltip>
                            ) : null}
                          </div>
                          {!key.noorder && key.order ? (
                            <i
                              className={`zmdi flex-wrap m-l-8 zmdi-chevron-${
                                key.order === 'asc' ? 'up' : 'down'
                              }`}
                            />
                          ) : null}
                        </div>
                        {key.type === 'checkbox' && !hideAllCheck ? ( //모든 로우에 체크박스
                          <Checkbox
                            className="m-a"
                            checked={(rows || []).every((row) => row.$$checked)}
                            toggleHandler={() => {
                              if ((rows || []).length === 0) return;

                              const allChecked = rows.every(
                                (row) => row.$$checked
                              );
                              rows.forEach(
                                (row) =>
                                  (row.$$checked = allChecked ? false : true)
                              );
                              forceUpdate();
                            }}
                          />
                        ) : null}
                        {key.type === 'multiCheck' ? ( //checkUse=false인 로우에만 체크박스 (부분적으로 체크박스를 세팅할 수 있음)
                          <Checkbox
                            className="m-a"
                            checked={(rows || []).every((row) => row.$$checked)}
                            toggleHandler={() => {
                              if ((rows || []).length === 0) return;

                              const allChecked = rows.every(
                                (row) => row.$$checked
                              );
                              rows.forEach(
                                (row) =>
                                  (row.$$checked = allChecked ? false : true)
                              );
                              forceUpdate();
                              allCheckAction(rows, allChecked);
                            }}
                          />
                        ) : null}
                        {/* {key.type === "subcheckbox" ? null //기본 checkbox 외에 또 다른 컬럼에 checkbox 사용 시, 현재는 allcheck 없이 사용하므로 null 처리
                  : null} */}
                      </th>
                    )
                  );
                })}
            </tr>
            {/* rowspan 일때 tr row */}
            {((model || {}).options || {}).rowspan > 0 && (
              <tr>
                {model.keys
                  .filter((o) => !o.hideColumn)
                  .map((key, idx) => {
                    let thDivStyle = {
                      display:
                        key.type && key.type == 'multiCheck'
                          ? 'inline-block'
                          : '',
                    };
                    let titleStyle = {};
                    if (key.thStyle !== undefined) {
                      thDivStyle = { ...thDivStyle, ...key.thStyle() };
                    }
                    if (key.titleStyle !== undefined) {
                      titleStyle = { ...titleStyle, ...key.titleStyle() };
                    }

                    return (
                      key.rowSpan === true && (
                        <th
                          onClick={() => !key.noorder && order(key)}
                          key={idx}
                          className={`${key.class ? key.class : ''}`}
                        >
                          <div
                            className={`flex-row items-center`}
                            style={thDivStyle}
                          >
                            <div>
                              <span
                                style={titleStyle}
                                dangerouslySetInnerHTML={{
                                  __html: translate(key.title),
                                }}
                              ></span>
                              {key.type === 'tooltip' ? (
                                <Tooltip
                                  placement="top"
                                  title={key.tooltipText}
                                >
                                  <Button>?</Button>
                                </Tooltip>
                              ) : null}
                            </div>
                            {!key.noorder && key.order ? (
                              <i
                                className={`zmdi flex-wrap m-l-8 zmdi-chevron-${
                                  key.order === 'asc' ? 'up' : 'down'
                                }`}
                              />
                            ) : null}
                          </div>
                          {key.type === 'checkbox' && !hideAllCheck ? ( //모든 로우에 체크박스
                            <Checkbox
                              className="m-a"
                              checked={(rows || []).every(
                                (row) => row.$$checked
                              )}
                              toggleHandler={() => {
                                if ((rows || []).length === 0) return;

                                const allChecked = rows.every(
                                  (row) => row.$$checked
                                );
                                rows.forEach(
                                  (row) =>
                                    (row.$$checked = allChecked ? false : true)
                                );
                                forceUpdate();
                              }}
                            />
                          ) : null}
                          {key.type === 'multiCheck' ? ( //checkUse=false인 로우에만 체크박스 (부분적으로 체크박스를 세팅할 수 있음)
                            <Checkbox
                              className="m-a"
                              checked={(rows || []).every(
                                (row) => row.$$checked
                              )}
                              toggleHandler={() => {
                                if ((rows || []).length === 0) return;

                                const allChecked = rows.every(
                                  (row) => row.$$checked
                                );
                                rows.forEach(
                                  (row) =>
                                    (row.$$checked = allChecked ? false : true)
                                );
                                forceUpdate();
                                allCheckAction(rows, allChecked);
                              }}
                            />
                          ) : null}
                          {/* {key.type === "subcheckbox" ? null //기본 checkbox 외에 또 다른 컬럼에 checkbox 사용 시, 현재는 allcheck 없이 사용하므로 null 처리
                  : null} */}
                        </th>
                      )
                    );
                  })}
              </tr>
            )}
          </thead>

          {!loading && (
            <tbody>
              {(rows || []).length > 0
                ? rows.map((row, rowIdx) => (
                    <React.Fragment key={rowIdx}>
                      <tr
                        className={`
                      ${!row['@@merged'] ? 'not-merged' : 'merged'}
                      ${row.selected ? 'selected' : ''} row-${rowIdx} 
                      ${hasLine && !row['@@merged'] ? 'row-first' : ''} 
                      ${
                        hasDeactivate &&
                        row['@@items'] &&
                        ((row['@@items'] &&
                          !row['@@items']['visible'] &&
                          row.items.length) ||
                          (!row.visible && !row.items.length))
                          ? 'deactivate'
                          : ''
                      } 
                      ${
                        hasDeactivate &&
                        !row['@@items'] &&
                        (row.visible === false ||
                          row.isDisabled === true ||
                          (row.user && row.user.status !== 'active'))
                          ? 'deactivate'
                          : ''
                      }
                      ${row.visible === false ? 'deactivate' : null}
                      `}
                        onClick={() => (row.selected = !row.selected)}
                        key={rowIdx}
                        name={row.index}
                        draggable={onDragAction ? true : false}
                        onDragStart={(e) => onDragStart(e, row, rowIdx)}
                        onDragEnd={(e) => onDragEnd(e, row)}
                        onDrop={(e) => onDrop(e, row, rowIdx)}
                        onDragOver={(e) => onDragOver(e, row)}
                      >
                        {model.keys
                          .filter((o) => !o.hideColumn)
                          .map((key, idx) =>
                            key.colspan === undefined &&
                            isDisplayTd(model, row, key) ? (
                              <td
                                onClick={(e) =>
                                  doAction(e, row, key, rowIdx, 'click')
                                }
                                onDoubleClick={(e) =>
                                  doAction(e, row, key, rowIdx, 'doubleClick')
                                }
                                key={idx}
                                rowSpan={getRowSpan(model, row, key)}
                                className={`column-${idx + 1} ${
                                  key.class ? key.class : ''
                                } ${
                                  isGroupedTd(model, row, key)
                                    ? ' td-grouped'
                                    : ''
                                } ${
                                  hasDeactivate &&
                                  isGroupedTd(model, row, key) &&
                                  !row.visible
                                    ? 'deactivate'
                                    : ''
                                }`}
                                // style={key.style}
                                style={
                                  key.customStyle !== undefined
                                    ? key.customStyle(row)
                                    : key.style
                                }
                                draggable={
                                  idx === 2 &&
                                  onDragAction &&
                                  row[detailType2].length > 1
                                    ? true
                                    : false
                                }
                                onDragStart={(e) => onDragStart(e, row, rowIdx)}
                                onDragEnd={(e) => {
                                  onDragEnd(e, row);
                                }}
                                onDrop={(e) => {
                                  onDrop(e, row, rowIdx);
                                }}
                              >
                                {[
                                  'image',
                                  'editable',
                                  'checkbox',
                                  'multiCheck',
                                  'subcheckbox',
                                  'input',
                                  'input_currency',
                                  'toggle',
                                ].indexOf(key.type) === -1 ||
                                (key.type === 'editable' && !row.$$editing) ? (
                                  <div
                                    className="flex-row"
                                    style={
                                      key.tdDivStyle && { ...key.tdDivStyle() }
                                    }
                                  >
                                    <div
                                      className={`item-content
                                  ${
                                    key.type !== 'arrow_text' &&
                                    key.type !== 'memo' &&
                                    key.type !== 'text' &&
                                    !(key.type || '').includes('list')
                                      ? 'center'
                                      : ''
                                  } 
                                  ${
                                    (key.type || '').includes('list')
                                      ? 'space-normal'
                                      : ''
                                  } 
                                  ${
                                    (key.type || '').includes('mini')
                                      ? 'min-w-125'
                                      : ''
                                  }
                                  ${
                                    key.type === 'text' &&
                                    (getValue(row, key) ||
                                      (key.filter && key.filter(row)))
                                      ? 'pre-line'
                                      : ''
                                  }
                                  ${
                                    key.type === 'memo' &&
                                    (getValue(row, key) ||
                                      (key.filter && key.filter(row)))
                                      ? 'pre-wrap'
                                      : ''
                                  }
                                  ${
                                    (
                                      key.filter
                                        ? key.filter(row) === true
                                        : getValue(row, key) === true
                                    )
                                      ? 'c-blue'
                                      : ''
                                  }
                                  ${
                                    (
                                      key.filter
                                        ? key.filter(row) === false
                                        : getValue(row, key) === false
                                    )
                                      ? 'c-danger'
                                      : ''
                                  }
                                  ${
                                    key.column
                                      ? `item-content-${key.column}`
                                      : ''
                                  }
                                `}
                                      dangerouslySetInnerHTML={{
                                        __html: key.filter
                                          ? key.filter(row)
                                          : getValue(row, key),
                                      }}
                                      style={
                                        key.align && {
                                          justifyContent: key.align,
                                        }
                                      }
                                    />
                                    {(key.type || '').includes('arrow')
                                      ? getArrow(row, key)
                                      : ''}
                                  </div>
                                ) : null}
                                {key.type === 'editable' && row.$$editing ? (
                                  <EditableInput
                                    onBlur={(e) => onBlur(e, row, key, rowIdx)}
                                    defaultValue={getValue(row, key)}
                                  />
                                ) : null}
                                {key.type === 'input' ? (
                                  <div className="center">
                                    <Input
                                      type="text"
                                      // style={
                                      //   key.inputStyle !== undefined
                                      //     ? { ...key.inputStyle }
                                      //     : null
                                      // }
                                      onBlur={(e) => {
                                        onBlur(e, row, key, rowIdx);
                                        forceUpdate();
                                      }}
                                      onChange={(e) => {
                                        row[`${key.title}_$$input`] =
                                          e.target.value;
                                        forceUpdate();
                                      }}
                                      value={row[`${key.title}_$$input`]}
                                    />
                                    {key.inputUnit}
                                  </div>
                                ) : null}
                                {key.type === 'input_currency' ? (
                                  <div className="center">
                                    <CurrencyInput
                                      type="text"
                                      onBlur={(e) => {
                                        onBlur(e, row, key, rowIdx);
                                        forceUpdate();
                                      }}
                                      onChange={(e) => {
                                        row[`${key.title}_$$input`] = parseInt(
                                          removeCurrency(
                                            e.target.value.replace('₩', '')
                                          )
                                        );
                                        doAction(e, row, key, rowIdx);
                                        forceUpdate();
                                      }}
                                      value={`${currencySymbol()} ${currency(
                                        row[`${key.title}_$$input`]
                                      )}`}
                                    />
                                  </div>
                                ) : null}
                                {key.type === 'toggle' ? (
                                  <div className="center">
                                    <div
                                      className={'select-none'}
                                      onClick={(e) => {
                                        onVatToggle(e, row, key, rowIdx);
                                      }}
                                    >
                                      {key.filter(row)}
                                    </div>
                                  </div>
                                ) : null}
                                {key.type === 'image'
                                  ? // <img className="img-cover m-a" alt="" src={image(row, key)} />
                                    image(row, key)
                                  : null}
                                {key.type === 'checkbox' ? (
                                  <Checkbox
                                    className="m-a"
                                    disabled={row.checkDisabled}
                                    checked={row.$$checked}
                                    toggleHandler={() => {
                                      row.$$checked = !row.$$checked;
                                      forceUpdate();
                                    }}
                                  />
                                ) : null}
                                {key.type === 'multiCheck' && (
                                  <React.Fragment>
                                    {key.checkUse(row) === 'false' && (
                                      <Checkbox
                                        className="m-a"
                                        checked={row.$$checked}
                                        toggleHandler={() => {
                                          row.$$checked = !row.$$checked;
                                          forceUpdate();
                                        }}
                                      />
                                    )}
                                    <div
                                      className={`item-content
                                ${
                                  key.type !== 'text' &&
                                  !(key.type || '').includes('list')
                                    ? 'center'
                                    : ''
                                } 
                                ${
                                  (key.type || '').includes('list')
                                    ? 'space-normal'
                                    : ''
                                } 
                                ${
                                  (key.type || '').includes('mini')
                                    ? 'min-w-125'
                                    : ''
                                }
                                ${
                                  key.type === 'text' &&
                                  (getValue(row, key) ||
                                    (key.filter && key.filter(row)))
                                    ? 'pre-line'
                                    : ''
                                }
                                ${
                                  (
                                    key.filter
                                      ? key.filter(row) === true
                                      : getValue(row, key) === true
                                  )
                                    ? 'c-blue'
                                    : ''
                                }
                                ${
                                  (
                                    key.filter
                                      ? key.filter(row) === false
                                      : getValue(row, key) === false
                                  )
                                    ? 'c-danger'
                                    : ''
                                }
                              `}
                                      dangerouslySetInnerHTML={{
                                        __html: key.filter
                                          ? key.filter(row)
                                          : getValue(row, key),
                                      }}
                                    />
                                  </React.Fragment>
                                )}
                                {key.type === 'subcheckbox' ? (
                                  <Checkbox
                                    className="m-a"
                                    checked={row[`${key.title}_$$checked`]} //disabled={row.checkDisabled}
                                    toggleHandler={() => {
                                      row[`${key.title}_$$checked`] = !row[
                                        `${key.title}_$$checked`
                                      ];
                                      forceUpdate();
                                    }}
                                  />
                                ) : null}
                              </td>
                            ) : null
                          )}
                      </tr>
                      {expandable
                        ? row[`@@${model.options.explodeColumn}`][
                            'key@@child'
                          ] ===
                            row[model.options.explodeColumn].length - 1 ||
                          row[`${model.options.explodeColumn}`].length === 0 //explodeColumn이 없어도, subRow를 가지고 있으면 펼쳐보기 가능하도록 조건 추가
                          ? row.subTableToggle &&
                            subModel !== undefined &&
                            row.subRow.length > 0 && (
                              <tr className="sub-table-tr">
                                <td
                                  className="sub-table-padding-left"
                                  colSpan={subModel.options.subColspan[0]}
                                ></td>
                                <td
                                  className="sub-table-main-td"
                                  colSpan={subModel.options.subColspan[1]}
                                >
                                  <SubDataTable
                                    parentRow={row}
                                    subRow={row.subRow}
                                    subModel={subModel}
                                    onSubContextAction={onSubContextAction}
                                    onSubAction={onSubAction}
                                  />
                                </td>
                                <td
                                  className="sub-table-padding-right"
                                  colSpan={subModel.options.subColspan[2]}
                                ></td>
                              </tr>
                            )
                          : null
                        : null}
                    </React.Fragment>
                  ))
                : null}
            </tbody>
          )}
        </table>
        {loading ? (
          <div className="loading">
            <i className="zmdi zmdi-rotate-right zmdi-hc-spin" />
            <span>{translate('TABLE_DATA_LOADING')}</span>
          </div>
        ) : (rows || []).length === 0 ? (
          <div className="empty">{translate('NO_SEARCH_RESULT')}</div>
        ) : null}
        {!hideBottom && bottomPositionInner ? (
          <div className="flex-row flex-between wrap-pagination">
            <Pagination
              total={total}
              limit={limit}
              currentIndex={currentIndex}
              onPage={onPage}
            />
            {!hideLimitSetter ? (
              <LimitSetter limit={limit} onLimit={onLimit} />
            ) : null}
          </div>
        ) : null}
      </div>
      {!hideBottom && !bottomPositionInner ? (
        <div className="flex-row flex-between wrap-pagination">
          <Pagination
            total={total}
            limit={limit}
            currentIndex={currentIndex}
            onPage={onPage}
          />
          {!hideLimitSetter ? (
            <LimitSetter limit={limit} onLimit={onLimit} />
          ) : null}
        </div>
      ) : null}
    </>
  );
};

DataTableAlpha.propTypes = {
  data: PropTypes.array,
  total: PropTypes.number,
  model: PropTypes.object,
  params: PropTypes.object,
  hideBottom: PropTypes.bool,
  bottomPositionInner: PropTypes.bool,
  onAction: PropTypes.func,
  dragTypes: PropTypes.string,
  onDragAction: PropTypes.func,
  onParams: PropTypes.func,
  loading: PropTypes.bool,
  hideHidden: PropTypes.bool,
  className: PropTypes.string,
  hideLimitSetter: PropTypes.bool,
  onAllCheckAction: PropTypes.func,
  hideAllCheck: PropTypes.bool,
  mergeColumn: PropTypes.array,
  hasLine: PropTypes.bool,
  hasDeactivate: PropTypes.bool,
  expandable: PropTypes.bool,
  subModel: PropTypes.object,
  onContextAction: PropTypes.func,
  onSubContextAction: PropTypes.func,
  onSubAction: PropTypes.func,
};

export default observer(DataTableAlpha);
