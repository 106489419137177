import React, { useState, useEffect, useCallback } from 'react';
import { observer } from 'mobx-react';
import DataTableAlpha from 'components/data-table/DataTableAlpha';
import PropTypes from 'prop-types';
import models from 'models';

const ModalPaymentDetail = ({ options, close }) => {
  const [detail, setDetail] = useState(null);

  const setDetailData = useCallback(() => {
    const { row } = options;
    const payouts = row.payouts.map((v) => {
      v.status = 'payouts';
      return v;
    });
    const refunds = row.refunds.map((v) => {
      v.status = 'refunds';
      return v;
    });
    const data = [...payouts, ...refunds];
    setDetail(data);
  }, [options]);

  useEffect(() => {
    setDetailData();
  }, [setDetailData]);

  return (
    // openChart: true = 통합차트에서 수납 상세보기 팝업 호출 시, 폰트 사이즈 변경을 위해 open-chart 클래스명 추가
    <div
      className={`modal-payment-products ${
        options.openChart ? 'open-chart' : ''
      }`}
    >
      <div className="head flex-row flex-between items-start">
        <div className="title">수납 상세보기</div>
        <i onClick={() => close()} className="zmdi zmdi-close" />
      </div>
      <div className="body">
        <DataTableAlpha
          model={models.crm.paymentDetail}
          data={detail}
          hideBottom={true}
        />

        <div className="buttons">
          <div className="flex-row">
            <button onClick={() => close()} className="btn btn-primary">
              확인
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

ModalPaymentDetail.propTypes = {
  options: PropTypes.object,
  close: PropTypes.func,
};

export default observer(ModalPaymentDetail);
