import { currency } from 'filters';
import moment from 'moment';

const paymentMethod = {
  credit_card: '신용카드',
  debit_card: '체크카드',
  cash: '현금',
  bank_transfer: '계좌이체',
  account: '',
  account_point: '',
};

export default {
  keys: [
    {
      title: '수납일',
      filter: (o) => moment(o.paidAt).format('YYYY-MM-DD'),
      customStyle: (o) => {
        return o.status === 'refunds' ? { color: 'red' } : {};
      },
    },
    {
      title: '담당자',
      column: 'createdName',
      customStyle: (o) => {
        return o.status === 'refunds' ? { color: 'red' } : {};
      },
    },
    {
      title: '결제수단',
      column: 'paymentMethod',
      filter: (o) => {
        return paymentMethod[o.paymentMethod];
      },
      customStyle: (o) => {
        return o.status === 'refunds' ? { color: 'red' } : {};
      },
    },
    {
      title: '카드사(은행명)',
      column: 'companyName',
      customStyle: (o) => {
        return o.status === 'refunds' ? { color: 'red' } : {};
      },
    },
    {
      title: '수납/환불액',
      column: 'amount',
      filter: (o) => {
        return o.status === 'refunds'
          ? `-₩${currency(Number(o.amount))}`
          : `₩${currency(Number(o.amount))}`;
      },
      customStyle: (o) => {
        return o.status === 'refunds' ? { color: 'red' } : {};
      },
    },
    {
      title: '현금영수증',
      column: 'cashReceipt',
      filter: (o) => {
        return o.cashReceipt ? '발행' : '미발행';
      },
      customStyle: (o) => {
        return o.status === 'refunds' ? { color: 'red' } : {};
      },
    },
  ],
};
