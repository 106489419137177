import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { translate } from 'filters';
import { useEvent } from 'hooks/useEvent';
import { useModal } from 'hooks/useModal';
import { useServices } from 'hooks/useServices';

const ModalPenChartsDelete = ({ options, close }) => {
  const eventBus = useEvent();
  const services = useServices();
  const modal = useModal();
  const onClickSave = useCallback(() => {
    const onConfirm = async (id) => {
      try {
        await services.crm.penchart.delete_v2(id);
        modal
          .confirm({
            type: 'SUCCESS',
            msg: '삭제되었습니다.',
          })
          .then(() => {
            eventBus.emit('chartCountCallApi');
            options.setNeedUpdate({ type: 'DELETE' });
          });
      } catch (error) {
        modal
          .confirm({
            type: 'ERROR',
            msg: error.description,
          })
          .then(() => {
            close();
            return;
          });
      }
    };

    options.selectArr.map((v) => {
      onConfirm(options.fileArr[v].id);
    });
    close();
  }, [eventBus, modal, services, options, close]);

  useEffect(() => {
    //
  });

  return (
    <div className="modal-pen-chart">
      <div className="body">
        <div className="form-control">
          <p>
            선택한 {options && options.selectArr.length}개의 항목을 정말로
            삭제하시겠습니까?
          </p>
          <p>삭제 시 복원이 불가능하며, 펜차트 앱에서도 삭제됩니다.</p>
        </div>
      </div>
      <div className="buttons">
        <div className="flex-row">
          <button
            onClick={() => close()}
            className="btn btn-default"
            style={{ display: 'block' }}
          >
            {translate('CANCEL')}
          </button>
          <button
            onClick={onClickSave}
            className="btn btn-primary"
            style={{ display: 'block' }}
          >
            확인
          </button>
        </div>
      </div>
    </div>
  );
};

ModalPenChartsDelete.propTypes = {
  options: PropTypes.object,
  close: PropTypes.func,
};

export default observer(ModalPenChartsDelete);
