export default {
  keys: [
    {
      title: '일자',
      column: '',
    },
    {
      title: '종류',
      column: '',
    },
    {
      title: '사용금액',
      column: '',
    },
    {
      title: '발송건수',
      column: '',
    },
    {
      title: '발송성공',
      column: '',
    },
    {
      title: '발송실패',
      column: '',
    },
    {
      title: '상세 결과 보기',
      column: '',
    },
    {
      title: '해당조건 다시 보내기',
      column: '',
    },
  ],
};
