import moment from 'moment';
import { toThousandCommas } from 'filters';

export default {
  keys: [
    {
      title: '일자',
      column: 'createdAt',
      filter: (o) => moment(o.createdAt).format('YYYY-MM-DD'),
    },
    {
      title: '충전/환불',
      noorder: true,
      filter: (o) => {
        if (o.status === 'deposit') {
          if (o.type === 'paid') {
            return '유상충전';
          }
          if (o.type === 'free') {
            return '무상충전';
          }
          return '충전';
        } else if (o.status === 'refund') {
          return '환불';
        }
      },
    },
    {
      title: '처리 금액',
      noorder: true,
      filter: (o) => {
        let amount = o.amount;
        if (o.type === 'free') {
          amount = o.freeAmount;
        }
        return `${o.status === 'deposit' ? '+' : '-'} ₩${toThousandCommas(
          amount
        )}`;
      },
    },
    {
      title: '세금계산서',
      noorder: true,
      type: 'tooltip',
      tooltipText: '세금계산서는 익월 초에 발행됩니다.',
      filter: (o) => `
            ${
              o.isTaxBill
                ? `<button class="btn btn-sm btn-taxbill">보기</button>`
                : '발행 전'
            }`,
    },
  ],
};
