import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';
import { useAuth } from 'store/auth';

export function PrivateRoute({ children, component: Component, ...rest }) {
  const auth = useAuth();

  return (
    <Route
      {...rest}
      render={({ location, ...props }) => {
        return auth.token ? (
          Component ? (
            <Component location={location} {...props} />
          ) : (
            children
          )
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
}

PrivateRoute.propTypes = {
  children: PropTypes.node,
  component: PropTypes.any,
};
