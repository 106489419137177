import React, { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import DataTableAlpha from 'components/data-table/DataTableAlpha';
import ModalMessageDetail from 'components/modals/codes/message-codes/ModalMessageDetail';
import PropTypes from 'prop-types';
import models from 'models';
import { translate } from 'filters';
import { $qb } from 'scripts/querybuilder';
import { useModal } from 'hooks/useModal';
import { useServices } from 'hooks/useServices';
import { useToast } from 'hooks/useToast';
import styled from 'styled-components';

const InputWrapper = styled.div`
  margin-right: 4px;
`;

const Input = styled.input`
  padding: 8px 10px 8px 20px;
  width: 150px;
  border-radius: 4px;
  font-size: 14px;
  border: 1px solid #dee2ec;
  height: 34px;
`;

const SmsMessagesDetail = ({ options }) => {
  const modal = useModal();
  const services = useServices();
  const toast = useToast();
  const defaultParams = $qb().limit(10).customParam('smsType', 'normal');
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [params, setParams] = useState(defaultParams);
  const [loading, setLoading] = useState(true);
  const [dataObj, setDataObj] = useState({
    customerName: null,
    phoneNumber: null,
    status: null,
  });

  const callApi = useCallback(async () => {
    try {
      setLoading(true);
      let requestParams = {
        ...params.build(),
        ...options,
      };

      const resp = await services.crm.notification.smsNotifications.ad.detail(
        requestParams
      );
      if (!resp) return;
      const changeData = resp.data.map((v, i) => {
        let page = params.queryParams.page || 1;
        let limit = params.queryParams.limit;
        v.no = resp.total - ((page - 1) * limit + i);
        v.mini_contents = v.contents;
        return v;
      });
      setData(changeData);
      setTotal(resp.total);
      setLoading(false);
    } catch (error) {
      toast.error(error.description);
      setData([]);
      setTotal(0);
      setLoading(false);
    }
  }, [services.crm.notification.smsNotifications.ad, toast, params, options]);

  useEffect(() => {
    callApi();
  }, [callApi, options]);

  const onParams = (p) => {
    setParams(p);
    callApi();
  };

  const openModalBoilerplate = useCallback(
    ({ row }) => {
      modal.custom({
        component: ModalMessageDetail,
        options: { boilerplateMessage: row },
      });
    },
    [modal, callApi]
  );

  const onAction = (obj) => {
    const { key } = obj; // , rowIdx

    if (key.title === 'TITLE_AND_CONTENT') {
      openModalBoilerplate(obj);
    }
  };

  const onChangeHandler = (e) => {
    const { name, value } = e.target;

    if (!value || value === null || value === '') {
      setDataObj((dataObj) => ({
        ...dataObj,
        [name]: null,
      }));
    } else {
      setDataObj((dataObj) => ({
        ...dataObj,
        [name]: value,
      }));
    }
  };

  const onClickSearch = () => {
    params.queryParams = {
      limit: params.queryParams.limit,
      orderBy: params.queryParams.orderBy,
      smsType: 'normal',
      ...dataObj,
    };

    setParams(params);
    callApi();
  };

  return (
    <div className="modal-message-details message-type-event">
      <div className="body">
        <div className="search-inputs">
          <InputWrapper>
            <Input
              onChange={onChangeHandler}
              onKeyDown={(e) => e.keyCode == 13 && onClickSearch()}
              name="name"
              placeholder={translate('LABEL_CONDITION_CUSTOMER_NAME')}
            />
          </InputWrapper>
          <InputWrapper>
            <Input
              onChange={onChangeHandler}
              onKeyDown={(e) => e.keyCode == 13 && onClickSearch()}
              name="phoneNumber"
              placeholder={translate('LABEL_CONDITION_CUSTOMER_PHONENUMBER')}
            />
          </InputWrapper>
          <select className="m-r-12" name="status" onChange={onChangeHandler}>
            <option value={''}>{translate('MESSAGE_RESULT_STATUS')}</option>
            <option value={'success'}>
              {translate('MESSAGE_RESULT_STATUS_SUCCESS')}
            </option>
            <option value={'waiting'}>
              {translate('MESSAGE_RESULT_STATUS_WAITING')}
            </option>
            <option value={'fail'}>
              {translate('MESSAGE_RESULT_STATUS_FAIL')}
            </option>
            <option value={'canceled'}>
              {translate('MESSAGE_RESULT_STATUS_CANCELED')}
            </option>
          </select>
          <button className="btn btn-primary" onClick={onClickSearch}>
            {translate('SEARCH')}
          </button>
        </div>
        <DataTableAlpha
          className="header-style-classic"
          model={models.crm.messageHistorySmsDetail}
          data={data}
          total={total}
          params={params}
          onParams={onParams}
          loading={loading}
          onAction={onAction}
        />
      </div>
    </div>
  );
};

SmsMessagesDetail.propTypes = {
  options: PropTypes.object,
};

export default observer(SmsMessagesDetail);
