export default {
  keys: [
    {
      title: '',
      filter: () => `    
            <button class="btn btn-sm btn-primary m-r-8">선택</button>
            <button class="btn btn-sm btn-danger">삭제</button>
        `,
    },
    {
      title: '묶음처방명',
      column: '',
      filter: (o) => `${o.collectionName}`,
    },
    {
      title: '의약용품명',
      column: '',
      type: 'text',
      align: 'left',
      filter: (o) => `${o.collectionDrugName}`,
    },
  ],
};
