import React from 'react';
import ConditionGroup from './ConditionGroup';

const ConditionGroupList = ({
  groups,
  onCreateGroup,
  onRemoveGroup,
  onChangeGroup,
}) => {
  return groups.map((group, idx) => (
    <React.Fragment key={idx}>
      {idx !== 0 && <p className="text-or">위 조건을 만족하거나, 또는</p>}
      <ConditionGroup
        groupId={group.groupId}
        groupIdx={idx}
        key={group.groupId}
        onCreateGroup={onCreateGroup}
        onRemoveGroup={onRemoveGroup}
        onChangeGroup={onChangeGroup}
        {...group}
      />
    </React.Fragment>
  ));
};

export default ConditionGroupList;
