import React, { useMemo, useState, useEffect, useCallback } from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import moment from 'moment';
import { $qb } from 'scripts/querybuilder';
import { useServices } from 'hooks/useServices';
import { useDownloadExcel } from 'hooks/useDownloadExcel';

import DatePicker from 'components/common/DatePicker';
import { DataTable } from 'components/DataTable/DataTable';
import { LimitSetter } from 'components/DataTable/LimitSetter';
import Pagination from 'components/DataTable/Pagination';

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 20px;
`;

const Input = styled.input`
  font-size: 12px;
  width: 200px;
  padding: 8px 38px 9px 20px;
  border: 1px solid #dee2ec;
  border-radius: 4px;
  height: 35px;

  &::placeholder {
    font-size: 12px;
  }
`;

const getPaidType = (type) => {
  switch (type) {
    case 'paid':
      return '급여';
    case 'paid_welfare':
      return '보훈급여';
    case 'paid_stop':
      return '급여정지';
    case 'impossible':
      return '산정불가';
    case 'deleted':
    case 'unpaid':
      return '비급여';
    default:
      return type;
  }
};

const PrescriptionStatistics = () => {
  const services = useServices();
  const [startAt, setStartAt] = useState(
    new Date().setDate(new Date().getDate() - 30)
  );
  const [endAt, setEndAt] = useState(new Date().setDate(new Date().getDate()));
  const [companyName, setCompanyName] = useState('');
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [total, setTotal] = useState(0);
  const defaultParams = $qb()
    .limit(20)
    .page(1)
    .customParam('startAt', moment(startAt).format('YYYY-MM-DD'))
    .customParam('endAt', moment(endAt).format('YYYY-MM-DD'));
  const [params] = useState(defaultParams);
  const { downloading, download } = useDownloadExcel({
    api: 'prescription',
    fileName: `${moment(startAt).format('YYYYMMDD')}-${moment(endAt).format(
      'YYYYMMDD'
    )} 처방전통계`,
    params: params.build(),
  });

  const callApi = useCallback(async () => {
    const resp = await services.crm.statistics.statistics.prescription(
      params.build()
    );
    setData(resp.data ?? []);
    setTotal(resp.pagination.total_page);
  }, [services.crm.prescription]);

  useEffect(() => {
    callApi();
  }, [callApi]);

  const onClickSearch = () => {
    params.queryParams = {
      ...params.queryParams,
      page: 1,
      startAt: moment(startAt).format('YYYY-MM-DD'),
      endAt: moment(endAt).format('YYYY-MM-DD'),
      company: companyName,
    };
    setPage(1);
    callApi();
  };

  const onChangePage = (p = 1) => {
    setPage(p);
    params.page(p);
    callApi();
  };

  const onChangeLimit = (limit) => {
    setLimit(limit);
    setPage(1);
    params.limit(limit).page(1);
    callApi();
  };

  const schema = useMemo(
    () => ({
      columns: [
        [
          {
            id: 'company',
            name: '제약사명',
            rowSpan: '100%',
            value: (item) => item.company,
            style: {
              whiteSpace: 'pre-line',
              wordBreak: 'break-all',
              minWidth: 300,
            },
          },
          {
            id: 'id',
            name: '약품코드',
            value: ({ drugs }) =>
              drugs.filter((v) => v.paidType !== 'total').map((v) => v.id),
          },
          {
            id: 'name',
            name: '약품명',
            value: ({ drugs }) =>
              drugs.filter((v) => v.paidType !== 'total').map((v) => v.name),
            style: {
              whiteSpace: 'pre-line',
              wordBreak: 'break-all',
              minWidth: 250,
            },
          },
          {
            id: 'paidType',
            name: '급여구분',
            value: ({ drugs }) =>
              drugs
                .filter((v) => v.paidType !== 'total')
                .map((v) => getPaidType(v.paidType)),
          },
          {
            id: 'totalDosage',
            name: '총 투여량',
            value: ({ drugs }) =>
              drugs
                .filter((v) => v.paidType !== 'total')
                .map((v) => v.totalDosage.toFixed(2)),
          },
          {
            id: 'totalDosageDaily',
            name: '총 투약일수',
            value: ({ drugs }) =>
              drugs
                .filter((v) => v.paidType !== 'total')
                .map((v) => v.totalDosageDaily.toFixed(2)),
          },
          {
            id: 'doctor',
            name: '총 사용량',
            value: ({ drugs }) =>
              drugs
                .filter((v) => v.paidType !== 'total')
                .map((v) => v.totalUsage.toFixed(2)),
          },
          {
            id: 'totalExpense',
            name: '총 약품비',
            value: ({ drugs }) =>
              drugs
                .filter((v) => v.paidType !== 'total')
                .map((v) => v.totalExpense.toLocaleString()),
          },
        ],
        [
          {
            colSpan: 2,
            value: () => '',
            style: { background: '#f0f0f0', borderRight: '0' },
          },
          {
            value: () => 'total',
            style: { background: '#f0f0f0', borderLeft: '0' },
          },
          {
            value: ({ drugs }) =>
              drugs.find((v) => v.paidType === 'total').totalDosage.toFixed(2),
            style: { background: '#f0f0f0' },
          },
          {
            value: ({ drugs }) =>
              drugs
                .find((v) => v.paidType === 'total')
                .totalDosageDaily.toFixed(2),
            style: { background: '#f0f0f0' },
          },
          {
            value: ({ drugs }) =>
              drugs.find((v) => v.paidType === 'total').totalUsage.toFixed(2),
            style: { background: '#f0f0f0' },
          },
          {
            value: ({ drugs }) =>
              drugs
                .find((v) => v.paidType === 'total')
                .totalExpense.toLocaleString(),
            style: { background: '#f0f0f0' },
          },
        ],
      ],
    }),
    []
  );

  return (
    <div className={`statistics list inquiry`}>
      <div className="page-navi">
        <span className="title">처방전 발급/관리</span>
        <span className="title">처방전 통계</span>
      </div>

      <div className="route-top customer-tab-line" style={{ width: 718 }}>
        <div className="tabwrap">
          <ul>
            <li className="tab selected">
              <a>제약사별 처방통계</a>
            </li>
          </ul>
        </div>
      </div>

      <div className="route-top">
        <div className="search-bar">
          <div className="search-item">
            <DatePicker
              className="text-center"
              placeholderText="날짜선택"
              todayButton="오늘"
              value={startAt}
              onChange={(v) => v && setStartAt(v)}
            />
          </div>
          <div className="search-item">-</div>
          <div className="search-item">
            <DatePicker
              className="text-center"
              placeholderText="날짜선택"
              todayButton="오늘"
              value={endAt}
              onChange={(v) => v && setEndAt(v)}
            />
          </div>
          <div className="search-item">
            <Input
              value={companyName}
              placeholder="제약사명을 검색하세요"
              onChange={(e) => {
                setCompanyName(e.target.value);
              }}
            />
          </div>
          <div className="search-btns">
            <button
              className="btn btn-basic _small search"
              onClick={() => onClickSearch()}
            >
              조회
            </button>
          </div>
        </div>
      </div>
      <div className="sales-statistics-container">
        <button
          className="btn btn-basic _small m-r-8 m-b-20 btn-excel"
          onClick={download}
          disabled={downloading}
        >
          Excel 내려받기
        </button>
        <DataTable data={data} schema={schema} />
        <Footer>
          <Pagination total={total} onChange={onChangePage} page={page} />
          <LimitSetter limit={limit} onLimit={onChangeLimit} />
        </Footer>
      </div>
    </div>
  );
};

export default observer(PrescriptionStatistics);
