import { translate } from 'filters';

export default {
  keys: [
    {
      title: 'ACTION',
      noorder: true,
      class: 'action column-width-px-180',
      filter: (o) => {
        return `       
            ${
              o['visible']
                ? `<button class="btn btn-sm btn-success m-r-4"  data-type="edit" >${translate(
                    'EDIT'
                  )}</button>`
                : `<button class="btn btn-sm btn-gray m-r-4"  data-type="edit" >${translate(
                    'EDIT'
                  )}</button>`
            }
            ${
              o['visible']
                ? `<button class="btn btn-sm m-r-4 btn-black"  data-type="visible" >${translate(
                    'TOGGLE_DEACTIVATE'
                  )}</button>`
                : ''
            }
            ${
              !o['visible']
                ? `<button class="btn btn-sm m-r-4 btn-gray" data-type="visible" >${translate(
                    'TOGGLE_ACTIVATE'
                  )}</button>`
                : ''
            }
            ${
              o['visible']
                ? `<button class="btn btn-sm btn-danger m-r-4"  data-type="delete" >${translate(
                    'DELETE'
                  )}</button>`
                : `<button class="btn btn-sm btn-gray m-r-4"  data-type="delete" >${translate(
                    'DELETE'
                  )}</button>`
            }
            
         `;
      },
    },
    {
      title: 'CATEGORY',
      column: 'category',
      noorder: true,
      class: 'category  column-width-px-200',
    },
    {
      title: 'TITLE_AND_CONTENT',
      noorder: true,
      type: 'text',
      class: 'title-and-content column-width-px-400',
      filter: (o) => {
        if (o.title === '') {
          return `<div class="contents">
                       <div class="label">${translate('CONTENTS')}</div>
                       <div class="text">${o.contents || ''}</div>
                     </div>`;
        }
        return `<div class="title">
                       <div class="label">${translate('TITLE')}</div>
                       <div class="text">${o.title || ''}</div>
                   </div>
                   <div class="contents">
                       <div class="label">${translate('CONTENTS')}</div>
                       <div class="text">${o.contents || ''}</div>
                   </div>`;
      },
    },
    {
      title: 'IMAGE',
      noorder: true,
      type: 'image',
      class: 'image-preview column-width-px-200',
      filter: (o) => {
        if ((o.images || []).length === 0) return;
        return o.images[0].url;
      },
    },
  ],
};
