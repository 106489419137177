import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { useToast } from 'hooks/useToast';
import styled from 'styled-components';

const Input = styled.input`
  width: 100%;
  height: 34px;
  padding: 8px 20px;
  font-size: 12px;
  display: block;
  min-height: 10px;
  border-radius: 4px;
  background-color: #f3f8ff;
  color: #000;
  border: 1px solid #dee2ec;
`;

const Phone = ({ phone, onChange, className, placeholder, tabIndex }) => {
  const toast = useToast();

  const onChangePhone = (event) => {
    event.preventDefault();

    if (!/^[0-9-]*$/.test(event.target.value)) {
      toast.error('ERROR_NUMBERS_ONLY');
      return;
    }

    if (event.target.value.length > 13) {
      toast.error('ERROR_PHONE_TOO_LONG');
      return;
    }

    let numberTemp = (event.target.value || '')
      .replace(/[^0-9]/g, '')
      .replace(
        /(^02|^0505|^1[0-9]{3}|^0[0-9]{2})([0-9]+)?([0-9]{4})/,
        '$1-$2-$3'
      )
      .replace('--', '-');
    onChange(numberTemp);
  };

  return (
    <div className={`phone ${className}`}>
      <Input
        tabIndex={tabIndex}
        value={(phone || '')
          .replace(/[^0-9]/g, '')
          .replace(
            /(^02|^0505|^1[0-9]{3}|^0[0-9]{2})([0-9]+)?([0-9]{4})/,
            '$1-$2-$3'
          )
          .replace('--', '-')}
        onChange={onChangePhone}
        placeholder={placeholder}
      />
    </div>
  );
};

Phone.propTypes = {
  phone: PropTypes.string,
  onChange: PropTypes.func,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  tabIndex: PropTypes.number,
};

export default observer(Phone);
