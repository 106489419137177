import React from 'react';
import { observer } from 'mobx-react';
import SmsTableList from 'hooks/SmsTableList';
import modelBalanceHistories from 'models/balance-histories';
import modelBalanceRequest from 'models/balance-request';
import { useServices } from 'hooks/useServices';

const SmsUseLists = () => {
  const services = useServices();
  return (
    <div className="list sms-use-list">
      <div className="page-navi">
        <span className="title">문자관리</span>
        <span className="title">충전내역 조회</span>
      </div>
      <SmsTableList
        modelName="충전금 사용 내역"
        api={services.crm.balance.balance.histories}
        model={modelBalanceHistories}
        showSmsSelect={false}
      />
      <SmsTableList
        modelName="충전/환불 내역"
        api={services.crm.balance.balance.histories}
        model={modelBalanceRequest}
        param={{ status: 'refund,deposit' }}
        showSmsSelect={false}
      />
    </div>
  );
};

export default observer(SmsUseLists);
