import React from 'react';

const Certifications = () => {
  return (
    <div className="docs-cert">
      <div className="cert-body">
        <p>
          전자서명을 등록한 계정은 차트 수정 시 마다 공인전자서명을 자동
          진행합니다. 전자서명된 진료차트는 우노케어가 운영하는 서버에 저장되어
          보관됩니다.
        </p>
        <p>
          <strong>※ 참고</strong>
        </p>
        <p>
          <strong>의료법 제 22조 1항</strong>
          <br />
          의료인은 각각 진료기록부, 조산기록부, 간호기록부, 그 밖의 진료에 관한
          기록(이하 "진료기록부등" 이라 한다)을 갖추어 두고 환자의 주된 증상,
          진단 및 치료 내용 등 보건복지부령으로 정하는 의료행위에 관한 사항과
          의견을 상세히 기록하고 서명하여야 한다.
        </p>
        <p>
          <strong>제23조 제1항</strong>
          <br />
          의료인이나 의료기관 개설자는 제 22조의 규정에도 불구하고
          진료기록부등을 '전자서명법'에 따른 전자서명이 기재된 전자문서(이하
          "전자의무기록"이라 한다)로 작성, 보관한다.
        </p>
      </div>
    </div>
  );
};

export default Certifications;
