import React, { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
//import { AppointmentBoardState } from 'store/appointmentBoardState';
import { AppointmentBoardStateContext } from './AppointmentBoards';

const TimeIndicatorText = observer(({ pivotEl, timelineEl }) => {
  /**
   * @type {AppointmentBoardState }
   */
  const state = useContext(AppointmentBoardStateContext);
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    if (!pivotEl || !timelineEl) return;

    const ratio = state.minutesOffset / state.workMinutesSpan;
    const pivotSize =
      state.mode === 'vertical' ? pivotEl.offsetHeight : pivotEl.offsetWidth;
    const timelineSize =
      state.mode === 'vertical'
        ? timelineEl.offsetHeight
        : timelineEl.offsetWidth;
    const offsetTemp = pivotSize + ratio * (timelineSize - pivotSize);
    setOffset(offsetTemp ? offsetTemp : 0);
  }, [
    pivotEl,
    timelineEl,
    state.minutesOffset,
    state.workMinutesSpan,
    state.mode,
    state.cellSize,
  ]);

  const getTime = () => {
    const d = new Date();
    const h = d.getHours() < 10 ? '0' + d.getHours() : d.getHours();
    const m = d.getMinutes() < 10 ? '0' + d.getMinutes() : d.getMinutes();

    return h >= 12 ? `${h}:${m}` : `${h}:${m}`;
  };

  function onClick() {
    state.toggleTimeline();
  }

  return (
    <>
      <span
        className="time-indicator-text"
        style={{
          top: state.mode === 'vertical' ? offset : undefined,
          left: state.mode === 'horizontal' ? offset : undefined,
        }}
        onClick={onClick}
      >
        {getTime()}
      </span>
      {state.showTimeline && (
        <div
          className="time-indicator-text-line"
          style={{
            top: state.mode === 'vertical' ? offset : undefined,
            left: state.mode === 'horizontal' ? offset : undefined,
          }}
        />
      )}
    </>
  );
});

export default TimeIndicatorText;
