import React, { useCallback, useContext } from 'react';
import { observer } from 'mobx-react';
import { AppointmentBoardStateContext } from './AppointmentBoards';
import hexToRgba from 'hex-to-rgba';
import hooks from 'hooks';
import ModalAppointmentUpdateCompleteAutoSms from 'components/modals/ModalAppointmentUpdateCompleteAutoSms';
import ModalAppointmentUpdateCompleteAutoSmsAlert from 'components/modals/ModalAppointmentUpdateCompleteAutoSmsAlert';
import messages from 'store/messages';
import moment from 'moment';
import { $qb } from 'scripts/querybuilder';
import { useModal } from 'hooks/useModal';
import { useServices } from 'hooks/useServices';
import { useToast } from 'hooks/useToast';

const contextItemsStatus = [
  'APPOINTMENT_STATUS_SCHEDULED', //예약
  'APPOINTMENT_STATUS_UNVISITED', //미방문
  'APPOINTMENT_STATUS_CANCELED', //예약취소
  'APPOINTMENT_STATUS_CONSULTING_WAITING', //상담대기
  'APPOINTMENT_STATUS_CONSULTING_DURING', //상담중
  'APPOINTMENT_STATUS_TREATMENT_WAITING', //진료대기
  'APPOINTMENT_STATUS_TREATMENT_DURING', //진료중
  'APPOINTMENT_STATUS_SURGERY_WAITING', //시/수술대기
  'APPOINTMENT_STATUS_SURGERY_DURING', //시/수술중
  'APPOINTMENT_STATUS_PAYMENT_WAITING', //수납대기
  'APPOINTMENT_STATUS_COMPLETE', //완료
  'APPOINTMENT_STATUS_LEAVE', //퇴원
];

const contextItems = {
  scheduled_items: [
    { title: '접수하기' },
    {
      title: 'CHANGE_STATUS',
      expanded: false,
      status: contextItemsStatus,
    },
    { title: '차트보기' },
    // { title: "가망고객 등록" },
    { title: '예약수정' },
    { title: '예약복사' },
    { title: '잘라내기' },
    { title: '삭제' },
    { title: '문자전송' },
    { title: '약도전송' },
  ],
  unvisited_items: [
    { title: '접수하기' },
    {
      title: 'CHANGE_STATUS',
      expanded: false,
      status: contextItemsStatus,
    },
    { title: '차트보기' },
    // { title: "가망고객 등록" },
    { title: '예약수정' },
    { title: '예약복사' },
    { title: '잘라내기' },
    { title: '삭제' },
    { title: '문자전송' },
    { title: '약도전송' },
  ],
  canceled_items: [
    { title: '접수하기' },
    {
      title: 'CHANGE_STATUS',
      expanded: false,
      status: contextItemsStatus,
    },
    { title: '차트보기' },
    // { title: "가망고객 등록" },
    { title: '예약수정' },
    { title: '예약복사' },
    { title: '잘라내기' },
    { title: '삭제' },
    { title: '문자전송' },
    { title: '약도전송' },
  ],
  consulting_waiting_items: [
    { title: '상담하기' },
    {
      title: 'CHANGE_STATUS',
      expanded: false,
      status: contextItemsStatus,
    },
    { title: '차트보기' },
    // { title: "가망고객 등록" },
    { title: '예약수정' },
    { title: '예약복사' },
    { title: '잘라내기' },
    { title: '삭제' },
    { title: '문자전송' },
    { title: '약도전송' },
  ],
  consulting_during_items: [
    { title: '상담하기' },
    {
      title: 'CHANGE_STATUS',
      expanded: false,
      status: contextItemsStatus,
    },
    { title: '차트보기' },
    // { title: "가망고객 등록" },
    { title: '예약수정' },
    { title: '예약복사' },
    { title: '잘라내기' },
    { title: '삭제' },
    { title: '문자전송' },
    { title: '약도전송' },
  ],
  consulting_done_items: [
    {
      title: 'CHANGE_STATUS',
      expanded: false,
      status: contextItemsStatus,
    },
    { title: '차트보기' },
    // { title: "가망고객 등록" },
    { title: '예약수정' },
    { title: '예약복사' },
    { title: '잘라내기' },
    { title: '삭제' },
    { title: '문자전송' },
    { title: '약도전송' },
  ],
  treatment_waiting_items: [
    { title: '진료하기' },
    {
      title: 'CHANGE_STATUS',
      expanded: false,
      status: contextItemsStatus,
    },
    { title: '차트보기' },
    // { title: "가망고객 등록" },
    { title: '예약수정' },
    { title: '예약복사' },
    { title: '잘라내기' },
    { title: '삭제' },
    { title: '문자전송' },
    { title: '약도전송' },
  ],
  treatment_during_items: [
    { title: '진료하기' },
    {
      title: 'CHANGE_STATUS',
      expanded: false,
      status: contextItemsStatus,
    },
    { title: '차트보기' },
    // { title: "가망고객 등록" },
    { title: '예약수정' },
    { title: '예약복사' },
    { title: '잘라내기' },
    { title: '삭제' },
    { title: '문자전송' },
    { title: '약도전송' },
  ],
  treatment_done_items: [
    {
      title: 'CHANGE_STATUS',
      expanded: false,
      status: contextItemsStatus,
    },
    { title: '차트보기' },
    // { title: "가망고객 등록" },
    { title: '예약수정' },
    { title: '예약복사' },
    { title: '잘라내기' },
    { title: '삭제' },
    { title: '문자전송' },
    { title: '약도전송' },
  ],
  surgery_waiting_items: [
    { title: '시/수술하기' },
    {
      title: 'CHANGE_STATUS',
      expanded: false,
      status: contextItemsStatus,
    },
    { title: '차트보기' },
    // { title: "가망고객 등록" },
    { title: '예약복사' },
    { title: '잘라내기' },
    { title: '삭제' },
    { title: '문자전송' },
    { title: '약도전송' },
  ],
  surgery_during_items: [
    { title: '시/수술하기' },
    {
      title: 'CHANGE_STATUS',
      expanded: false,
      status: contextItemsStatus,
    },
    { title: '차트보기' },
    // { title: "가망고객 등록" },
    { title: '예약수정' },
    { title: '예약복사' },
    { title: '잘라내기' },
    { title: '삭제' },
    { title: '문자전송' },
    { title: '약도전송' },
  ],
  surgery_done_items: [
    {
      title: 'CHANGE_STATUS',
      expanded: false,
      status: contextItemsStatus,
    },
    { title: '차트보기' },
    // { title: "가망고객 등록" },
    { title: '예약수정' },
    { title: '예약복사' },
    { title: '잘라내기' },
    { title: '삭제' },
    { title: '문자전송' },
    { title: '약도전송' },
  ],
  payment_waiting_items: [
    { title: '수납하기' },
    {
      title: 'CHANGE_STATUS',
      expanded: false,
      status: contextItemsStatus,
    },
    { title: '차트보기' },
    // { title: "가망고객 등록" },
    { title: '예약수정' },
    { title: '예약복사' },
    { title: '잘라내기' },
    { title: '삭제' },
    { title: '문자전송' },
    { title: '약도전송' },
  ],
  leave_items: [
    {
      title: 'CHANGE_STATUS',
      expanded: false,
      status: contextItemsStatus,
    },
    { title: '차트보기' },
    // { title: "가망고객 등록" },
    { title: '예약수정' },
    { title: '예약복사' },
    { title: '잘라내기' },
    { title: '삭제' },
    { title: '문자전송' },
    { title: '약도전송' },
  ],
  done_items: [
    {
      title: 'CHANGE_STATUS',
      expanded: false,
      status: contextItemsStatus,
    },
    { title: '차트보기' },
    // { title: "가망고객 등록" },
    { title: '예약수정' },
    { title: '예약복사' },
    { title: '잘라내기' },
    { title: '삭제' },
    { title: '문자전송' },
    { title: '약도전송' },
  ],
};

function getCellSize(appointment, unit) {
  return Math.ceil(appointment.minSpan / unit);
}

function rgb2hex(c) {
  if (!c) return;
  if (c.includes('#')) return c;
  return (
    '#' + c.match(/\d+/g).map((x) => (+x).toString(16).padStart(2, 0)).join``
  );
}

const getRGBA = (color, alpha = 1) => {
  if (!color) return null;
  if (color.includes('rgb')) {
    let rgb = color.replace('rgb(', '').replace(')', '');
    return `rbga(${rgb}, ${alpha})`;
  } else {
    return hexToRgba(color, alpha);
  }
};

const AppointmentCellWrapper = observer(({ appointment, index, children }) => {
  const modal = useModal();
  const services = useServices();
  const toast = useToast();
  /**
   * @type {AppointmentBoardState }
   */
  const state = useContext(AppointmentBoardStateContext);
  const colorConfig = state.stateColors[appointment.colorStatus];
  const colorCode = colorConfig
    ? rgb2hex(colorConfig.codeValueTemp || colorConfig.codeValue)
    : undefined;

  const onContextMenu = useCallback(
    (e) => {
      e.stopPropagation();
      //예약셀
      const itemName = `${appointment.status}_items`;
      e.preventDefault();
      state.setContextMenuOptions({
        show: true,
        x: e.clientX,
        y: e.clientY,
        items: contextItems[itemName] || contextItems['leave_items'],
        // 정의되지 않은 모든 상태값은 퇴원 상태와 같은 액션으로 보여준다.
        // consulting_request_done, consulting_done, treatment_done, surgery_done, payment_done, leave, done, fail_consulting_request, fail_appointment, fail_consulting, fail_treatment, fail_surgery, fail_payment
        schedule: {
          appointment,
        },
      });
      state.setShowHoverCard(false);
      state.setAppointment(appointment);
    },
    [appointment]
  );

  const customerDetailCallApi = async (customerId) => {
    try {
      const resp = await services.crm.customer.detail(customerId);
      if (!resp) return;
      return resp.data;
    } catch (e) {
      if (e.dscription === 'CustomerNotFound') {
        toast.error('삭제된 고객입니다.');
        return;
      }
    }
  };

  const onDoubleClick = useCallback(
    async (e) => {
      e.stopPropagation();
      e.preventDefault();

      const { customerId } = appointment;
      const customer = await customerDetailCallApi(customerId);

      hooks.openCustomerChart({
        customer,
        calledPage: 'boards',
      });
      state.setShowHoverCard(false);
    },
    [appointment]
  );

  const setHoverCardPos = useCallback(
    (e) => {
      state.setHoveringAppointment(appointment);
      state.setShowHoverCard(true);
      state.setHoverCardPosition({
        x: e.clientX,
        y: e.clientY + 10,
      });
    },
    [appointment]
  );

  const updateHoverCard = useCallback(() => {
    state.setHoveringAppointment(appointment);
  }, [appointment]);

  const removeHoverCard = useCallback(() => {
    state.setShowHoverCard(false);
  }, []);

  const smsNotificationsCallApi = async (payload, endpoint) => {
    const apiParams = $qb()
      .customParam('status', 'waiting')
      .customParam('smsScheduleType', 'scheduled')
      .customParam('smsType', 'normal')
      .customParam('appointmentId', payload.id);

    const resp = await services.crm.notification.smsNotifications.ad.all(
      apiParams.build()
    );
    if (!resp) return;

    //기존 예약걸린 문자가 없는 경우
    let departmentSmsAutoNotifications = await messages.loadDepartmentSmsAutoNotifications(
      payload.department?.id
    );

    if (resp.data.length > 0) {
      //기존 예약걸린 문자가 있는 경우
      modal
        .custom({
          component: ModalAppointmentUpdateCompleteAutoSms,
          options: {
            model: 'autoSms',
            canClose: false,
            type: 'SEND_SMS_CHECK',
            data: resp.data,
            appointmentId: payload.id,
            notifications: departmentSmsAutoNotifications,
            scheduledAt: payload.scheduledAt,
          },
        })
        .then(({ sendAutoSmsCheck, sendAutoSmsIds }) => {
          payload.sendAutoSms = sendAutoSmsCheck;
          if (sendAutoSmsCheck) {
            payload.sendAutoSmsIds = sendAutoSmsIds;
          }
          if (endpoint === 'save') {
            onSaveAppointment(payload);
          }
        });
    } else {
      if (departmentSmsAutoNotifications.length > 0) {
        modal
          .custom({
            component: ModalAppointmentUpdateCompleteAutoSmsAlert,
            options: {
              model: 'autoSms',
              canClose: false,
              type: 'SEND_SMS_CHECK',
              data: resp.data,
              msgType: 'default',
              notifications: departmentSmsAutoNotifications,
              scheduledAt: payload.scheduledAt,
            },
          })
          .then(({ sendAutoSmsCheck, sendAutoSmsIds }) => {
            payload.sendAutoSms = sendAutoSmsCheck;
            if (sendAutoSmsCheck) {
              payload.sendAutoSmsIds = sendAutoSmsIds;
            }
            if (endpoint === 'save') {
              onSaveAppointment(payload);
            }
          });
      } else {
        payload.sendAutoSms = false;
        if (endpoint === 'save') {
          onSaveAppointment(payload);
        }
      }
    }
  };

  const onDragStart = useCallback((e) => {
    state.setDragging(true);
    e.dataTransfer.effectAllowed = 'move';
  }, []);

  const onDragEnd = useCallback((e) => {
    e.preventDefault();
    state.setDragging(false);
    if (e.nativeEvent.dataTransfer.dropEffect === 'none') return;
    if (!state.targetSchedule) return;

    let payload = {
      customerId: appointment.customerId,
      department: { id: state.targetSchedule.department?.id },
      startHour: state.targetSchedule.startHour,
      id: appointment.id,
      scheduledAt: appointment.scheduledAt,
      counselor: appointment.counselorId
        ? { id: appointment.counselorId }
        : undefined,
      doctor: appointment.doctorId ? { id: appointment.doctorId } : undefined,
      facialist: appointment.facialistId
        ? { id: appointment.facialistId }
        : undefined,
    };

    const originScheduled = moment();
    let [hours, minutes] = state.targetSchedule.startHour.split(':');
    const copiedScheduled = moment(appointment.scheduledAt)
      .hours(hours)
      .minutes(minutes);

    //부서가 변경 될 시 다이얼로그 팝업 노출
    //현재시간보다 미래 날짜에 붙여넣기 시 다이얼로그 팝업 노출
    if (
      state.smsAutoNotifications.length > 0 &&
      (appointment.departmentId !== state.targetSchedule.department.id ||
        originScheduled.diff(copiedScheduled) < 0) &&
      originScheduled.diff(copiedScheduled) < 0
    ) {
      //설정된 자동문자 타입이 없으면 자동문자전송 dialog 팝업 미노출.

      //기존에 예약걸린 문자가 있는가?
      smsNotificationsCallApi(payload, 'save');
    } else {
      payload.sendAutoSms = false;
      onSaveAppointment(payload);
    }
  }, []);

  const onSaveAppointment = useCallback(async (payload) => {
    try {
      await services.crm.crud.appointment.update(payload);
      modal
        .confirm({
          type: 'SUCCESS',
          msg: '업데이트되었습니다.',
        })
        .then(() => {
          state.loadAppointments();
        });
    } catch (e) {
      if (e.code === 412 && e.description === 'OutOfWorkHour') {
        toast.error('영업시간이내에만 예약을 생성할 수 있습니다.');
      }
      state.loadAppointments();
    }
  }, []);

  const style = {};

  if (colorCode) {
    Object.assign(style, {
      borderColor: colorCode,
      background: getRGBA(
        colorCode,
        state.cutClipBoard?.id === appointment.id ? 0.2 : 0.6
      ),
    });
  }

  if (state.mode === 'vertical') {
    Object.assign(style, {
      height:
        state.cellCrossAxisSize *
          getCellSize(appointment, state.clinic.appointmentTimeUnit) -
        1,
    });
  }

  if (state.mode === 'horizontal') {
    Object.assign(style, {
      width:
        state.cellCrossAxisSize *
          getCellSize(appointment, state.clinic.appointmentTimeUnit) -
        1,
    });
  }

  if (!appointment.isNewCustomer) {
    Object.assign(style, {
      paddingLeft: '2px',
    });
  }

  return (
    <div
      className={`appointment-cell ${appointment.status} ${
        state.cutClipBoard?.id === appointment.id ? 'cut' : ''
      } appointment-${index}`}
      onContextMenu={onContextMenu}
      onDoubleClick={onDoubleClick}
      onMouseMove={setHoverCardPos}
      onMouseEnter={updateHoverCard}
      onMouseLeave={removeHoverCard}
      draggable={true}
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
      style={style}
    >
      {children}
    </div>
  );
});

export default AppointmentCellWrapper;
