import React, { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import HideHidden from 'components/app/HideHidden';
import DataTableAlpha from 'components/data-table/DataTableAlpha';
import helpers from 'hooks/helpers';
import ModalMessageAutoSurgeryPeriod from 'components/modals/codes/message-codes/ModalMessageAutoSurgeryPeriod';
import modelAutoSurgeryPeriod from 'models/message-auto-surgery-period';
import { toJS } from 'mobx';
import { translate } from 'filters';
import { $qb } from 'scripts/querybuilder';
import { useModal } from 'hooks/useModal';
import { useServices } from 'hooks/useServices';
import { useToast } from 'hooks/useToast';
import { useMessages } from 'hooks/useMessages';

const MessageAutoSurgeryPeriod = () => {
  const modal = useModal();
  const services = useServices();
  const toast = useToast();
  const messages = useMessages();
  const defaultParams = $qb().limit().orderBy();
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [params, setParams] = useState(defaultParams);
  const [hideHidden, setHideHidden] = useState(false);
  const [loading, setLoading] = useState(true);
  const onParams = (p) => {
    setParams(p);
  };

  const surgeryCategoryCallApi = useCallback(async () => {
    let params = { limit: 10000, visible: true };
    const resp = await services.crm.treatment.categories.items_categories_v2(
      params
    );
    if (!resp) return;

    messages.setAllSurgeryCategories(
      // MultiSelect value에 맞춰 데이터 객체 가공
      resp.data.map((v) => {
        return { label: v.name, value: v.id, data: { ...v } };
      })
    );
  }, []);

  useEffect(() => {
    surgeryCategoryCallApi();
  }, [surgeryCategoryCallApi]);

  const callApi = useCallback(async () => {
    try {
      setLoading(true);
      const resp = await services.crm.notification.smsAutoNotifications.all({
        smsSituation: 'treatment_item',
      });
      setData(resp.data);
      setTotal(resp.total);
      setLoading(false);
    } catch (error) {
      toast.error(error.description);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    callApi();
  }, [callApi]);

  const onAction = (obj) => {
    const { row, key, event } = obj; // , rowIdx
    if (event.target.dataset.type === 'visible') {
      const toggleObj = {
        visible: !row['visible'],
        id: row['id'],
      };
      const endpoint = services.crm.notification.smsAutoNotifications;
      return helpers.toggleVisibilityById({
        obj: toggleObj,
        endpoint,
        callback: callApi,
      });
    }

    if (event.target.dataset.type === 'edit') {
      openModalEdit({ row, key, event });
    }

    if (event.target.dataset.type === 'delete') {
      openModalDelete({
        endpoint: services.crm.notification.smsAutoNotifications,
        id: row['id'],
      });
    }

    if (key['type'] === 'image' && (row.images || []).length > 0) {
      modal.images({
        images: (row.images || []).map((image) => image.url),
      });
      return;
    }
  };

  const openModalDelete = ({ endpoint, id }) => {
    const onConfirm = async () => {
      try {
        await endpoint.delete(id);
        toast.success('DELETED');
        callApi();
        // eventBus.emit('callApi')
      } catch (e) {
        console.log('e', e);
        toast.error(e.description);
      }
    };

    modal
      .basic({
        body: translate('DELETE_CONFIRM'),
        buttons: [
          {
            text: 'CANCEL',
            class: 'btn-default',
          },
          {
            text: 'CONFIRM',
            class: 'btn-danger',
          },
        ],
      })
      .then((idx) => {
        if (idx === 1) {
          onConfirm();
        }
      });
  };

  const openModalEdit = useCallback(
    ({ row }) => {
      // row, key, event, rowIdx
      if (row && row.treatmentItemId) {
        // treatmentItemId를 가지고 allSurgeryCategories 배열을 돌며 카테고리와 시/수술명을 찾는다.
        // 1. 체크된 시/수술명 리스트 (시/수술명 에 멀티체크)> selectSurgeryItems
        // 2. 체크된 시/수술명을 포함하는 시/수술 카테고리 리스트 (시/수술 카테고리에 멀티체크) > selectSurgeryCategories
        // 3. 2번의 시/수술 카테고리의 모든 시/수술명 리스트 (시/수술명 옵션 구성) > allSurgeryItems

        let allItems = []; // allItems = allSurgeryItems
        let selectItems = []; // selectItems = selectSurgeryItems
        let categories = messages.allSurgeryCategories
          .filter((f) => {
            // treatmentItemId를 가지고있는 모든 시/수술 카테고리를 찾는다.
            return (
              f.data.items.findIndex((fi) => {
                return fi.id === row.treatmentItemId;
              }) > -1
            );
          })
          .map((v) => {
            let itemLabel = v.data.items
              .filter((item) => item.visible)
              .map((item) => {
                let itemObj = {
                  label: item.name,
                  value: item.id,
                };

                if (item.id === row.treatmentItemId) {
                  // 저장된 값 = 선택된 시/수술명
                  selectItems.push(itemObj);
                }
                return itemObj;
              });
            // 저장된 값을 포함한 카테고리의 모든 시/수술명
            allItems.push(...toJS(itemLabel));
            return toJS(v);
          });

        messages.setSelectSurgeryCategories(toJS(categories));
        messages.setAllSurgeryItems([...toJS(allItems)]);
        messages.setSelectSurgeryItems([...toJS(selectItems)]);

        //일, 주, 개월 구분
        let delta = row.scheduleDaysDelta;
        let deltaCycle = 'day';
        if (row.scheduleDaysDelta % 30 === 0) {
          delta = row.scheduleDaysDelta / 30;
          deltaCycle = 'month';
        } else if (row.scheduleDaysDelta % 7 === 0) {
          delta = row.scheduleDaysDelta / 7;
          deltaCycle = 'week';
        }
        row.scheduleDaysDelta = delta;

        messages.setSelectScheduleDaysDelta(delta);
        messages.setSelectScheduleDaysCycle(deltaCycle);
        messages.setSelectReserveCount(row.reserveCount || 1);
      }

      modal
        .custom({
          component: ModalMessageAutoSurgeryPeriod,
          options: { autoNotification: row },
          autoMessageType: 'surgery-period',
        })
        .then((submitted) => {
          if (submitted) {
            setData([]);
            setTotal(null);
            callApi();
          }
        });
    },
    [callApi]
  );

  return (
    <div className="message-auto-notification">
      <div className="route-top">
        <div className="title">주기별 문자 자동전송</div>
        <div className="flex-row items-center">
          <HideHidden
            toggleHandler={setHideHidden}
            text="미사용항목 보지않기"
          />
          <button
            onClick={() => {
              openModalEdit({});
            }}
            className="flex-wrap btn btn-sm m-r-8 btn-add"
          >
            {translate('ADD')}
          </button>
        </div>
      </div>
      <DataTableAlpha
        className="header-style-classic"
        model={modelAutoSurgeryPeriod}
        data={hideHidden ? data.filter((row) => row.visible) : data}
        total={total}
        params={params}
        onParams={onParams}
        onAction={onAction}
        loading={loading}
        hideBottom={true}
        hideHidden={hideHidden}
        hasDeactivate
      />
    </div>
  );
};

export default observer(MessageAutoSurgeryPeriod);
