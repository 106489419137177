import moment from 'moment';

export default {
  options: {
    hasMergeCell: true,
    groupTitles: [
      '',
      'STATUS',
      '시/수술일',
      '고객명',
      'CHART_NO',
      '_DOCTORS',
      'ASSISTS',
      '시/수술내용',
    ],
    explodeColumn: 'surgeryTreatmentItems',
  },
  keys: [
    {
      //     type: 'multiCheck',
      //     title: '',
      //     column: '',
      //     checkUse: o => 'false',
      // },{
      title: 'STATUS',
      noorder: true,
      filter: () => {
        return `<div class="text-center">
                <span class="text-underline c-red">삭제</span>
            </div>`;
      },
    },
    {
      title: '시/수술일',
      column: 'scheduledAt',
      type: 'date',
      nosearch: true,
    },
    {
      title: 'START_AT',
      column: 'startAt',
      hideColumn: true,
      selectType: 'date',
      customParamFieldName: 'startAt',
      filter: (o) => {
        return `<div>
                <div>${moment(o.scheduledAt).format('YYYY-MM-DD')} ${
          o.startHour
        }</div>
            </div>`;
      },
    },
    {
      title: 'END_AT',
      column: 'endAt',
      hideColumn: true,
      selectType: 'date',
      customParamFieldName: 'endAt',
      filter: (o) => {
        return `<div>
                <div>${moment(o.scheduledAt).format('YYYY-MM-DD')} ${
          o.endHour
        }</div>
            </div>`;
      },
    },
    {
      title: '고객명',
      column: 'customer__name',
      noorder: true,
      customParamFieldName: 'name',
      filter: (o) =>
        `<span class="text-underline c-primary">${o.customer.name}</span>`,
    },
    {
      title: 'CHART_NO',
      column: 'customer__chartNo',
      noorder: true,
      customParamFieldName: 'chartNo',
    },
    {
      title: '_DOCTORS',
      column: 'doctor__name',
      noorder: true,
      customParamFieldName: 'doctorId',
    },
    {
      title: 'ASSISTS',
      column: 'facialist__name',
      noorder: true,
      customParamFieldName: 'facialistId',
    },
    {
      title: 'SURGERY_CATEGORIES',
      column: '@@surgeryTreatmentItems__categoryName',
      noorder: true,
      customParamFieldName: 'treatmentCategoryId',
      type: 'list_mini',
      filter: (o) => {
        return `<div style="position: relative; width: 125px; text-align: center;">
            <div class="flex-wrap"
                style="max-width: 125px; white-space: break-spaces; display: inline-block;">${
                  o['@@surgeryTreatmentItems']['categoryName'] || ''
                }</div></div>`;
      },
    },
    {
      title: 'SURGERY_ITEM_NAMES',
      column: '@@surgeryTreatmentItems__name',
      noorder: true,
      customParamFieldName: 'treatmentItemId',
      type: 'list',
      filter: (o) => {
        return `<div style="position: relative; width: 240px; text-align: center;">
            <div class="flex-wrap"
                style="max-width: 240px; white-space: break-spaces; display: inline-block;">${
                  o['@@surgeryTreatmentItems']['name'] || ''
                }${
          o['@@surgeryTreatmentItems']['isFree']
            ? '<div class="flex-wrap ico-service"></div>'
            : ''
        }</div>
            </div>`;
      },
    },
    {
      title: '시/수술횟수',
      column: '@@surgeryTreatmentItems__treatmentCount',
      noorder: true,
      nosearch: true,
      filter: (o) => `<div>
            ${
              o['@@surgeryTreatmentItems']['treatmentCount'] === null
                ? ''
                : `${o['@@surgeryTreatmentItems']['treatmentCount']}회`
            }
        </div>`,
    },
    {
      title: '진행한 회차',
      column: '@@surgeryTreatmentItems__useCount',
      noorder: true,
      nosearch: true,
      filter: (o) => `<div>
            ${
              o['@@surgeryTreatmentItems']['useCount'] === null
                ? ''
                : `${o['@@surgeryTreatmentItems']['useCount']}회`
            }
        </div>`,
    },
    {
      title: '남은 회차',
      column: '@@surgeryTreatmentItems__remainingCount',
      noorder: true,
      nosearch: true,
      filter: (o) => `<div>
            ${
              o['@@surgeryTreatmentItems']['remainingCount'] === null
                ? ''
                : `${o['@@surgeryTreatmentItems']['remainingCount']}회`
            }
        </div>`,
    },
    {
      title: '시/수술내용',
      column: 'memo',
      type: 'text',
      noorder: true,
      nosearch: true,
    },
    // {
    //     title: 'UPDATE_AT',
    //     column: 'updatedAt',
    //     filter: o => {
    //         return `<div>
    //             <div>${moment(o.updatedAt).format('YYYY-MM-DD')}</div>
    //             <div>${moment(o.updatedAt).format('HH:mm:ss')}</div>
    //         </div>`
    //     },
    //     nosearch: true,
    // }
  ],
};
