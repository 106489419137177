import React from 'react';
import SurgeryItem from './SurgeryItem';
import PropTypes from 'prop-types';

const SurgeryItemList = ({ payload, onChangeItem, onRemoveItem }) => {
  return (
    <>
      {payload.map((item, idx) => {
        return (
          <SurgeryItem
            key={item['itemRef']}
            uId={idx}
            itemKey={item['itemRef']}
            item={item}
            onChangeItem={onChangeItem}
            onRemoveItem={onRemoveItem}
          />
        );
      })}
    </>
  );
};

SurgeryItemList.propTypes = {
  payload: PropTypes.array,
  onChangeItem: PropTypes.func,
  onRemoveItem: PropTypes.func,
};

export default SurgeryItemList;
