import React, { useCallback, useState, useEffect } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { translate, currency, removeCurrency } from 'filters';
import useCustomerChart from 'hooks/useCustomerChart';
import models from 'models';
import DataTableAlpha from 'components/data-table/DataTableAlpha';
import InputCurrency from 'components/common/InputCurrency';
import QuillTextField from 'components/quill/QuillTextField';
import Checkbox from 'components/app/Checkbox';
import styled, { css } from 'styled-components';
import { useAuth } from 'store/auth';
import { useModal } from 'hooks/useModal';
import { useServices } from 'hooks/useServices';

const Input = styled.input`
  width: 170px;
  height: 34px;
  padding: 8px 20px;
  border-color: #d7e3f1;
  font-size: 12px;
  border-radius: 4px;
  background-color: #f3f8ff;
  border: 1px solid #dee2ec;

  ${({ disabled }) =>
    disabled &&
    css`
      font-weight: 700;
      background-color: #efeff4 !important;
      color: #ceced7 !important;
    `}
`;

const ModalPaymentsRefund = ({ options, close }) => {
  const auth = useAuth();
  const modal = useModal();
  const services = useServices();
  const [loadingBtnDisabledFlag, setLoadingBtnDisabledFlag] = useState(false);
  const [memo, setMemo] = useState();
  const [refundAt, setRefundAt] = useState(
    moment().format('YYYY-MM-DDTHH:mm:ss')
  );
  const [refundAmount, setRefundAmount] = useState(0);
  const [surgeriesData, setSurgeriesData] = useState([]);
  const [productsData, setProductsData] = useState([]);
  const [refunds, setRefunds] = useState([]);
  const [obj, setObj] = useState({});
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState({});
  const [payoutCreditItems, setPayoutCreditItems] = useState(
    options &&
      options.row &&
      options.row.payouts &&
      options.row.payouts.filter((v) => v.paymentMethod === 'credit_card')
  );
  const [payoutDebitItems, setPayoutDebitItems] = useState(
    options &&
      options.row &&
      options.row.payouts &&
      options.row.payouts.filter((v) => v.paymentMethod === 'debit_card')
  );
  const [payoutBankTransferItems, setPayoutBankTransferItems] = useState(
    options &&
      options.row &&
      options.row.payouts &&
      options.row.payouts.filter((v) => v.paymentMethod === 'bank_transfer')
  );
  const [payoutCash, setPayoutCash] = useState(
    options &&
      options.row &&
      options.row.payouts &&
      options.row.payouts.filter(
        (v) => v.paymentMethod === 'cash' || v.paymentMethod === null
      )
  );
  //TODO set이 안쓰이는데 useState 안쓰고 const refundCreditItems = 이런식으로 해도 괜찮지 않을까요?
  // eslint-disable-next-line no-unused-vars
  const [refundCreditItems, setRefundCreditItems] = useState(
    options &&
      options.row &&
      options.row.refunds &&
      options.row.refunds.filter((v) => v.paymentMethod === 'credit_card')
  );
  //TODO set이 안쓰이는데 useState 안쓰고 const refundDebitItems = 이런식으로 해도 괜찮지 않을까요?
  // eslint-disable-next-line no-unused-vars
  const [refundDebitItems, setRefundDebitItems] = useState(
    options &&
      options.row &&
      options.row.refunds &&
      options.row.refunds.filter((v) => v.paymentMethod === 'debit_card')
  );

  //TODO set이 사용되지 않는거같은데 useState말고 const refundBankTransferItems 로 해도 되지 않을까요?
  // eslint-disable-next-line no-unused-vars
  const [refundBankTransferItems, setRefundBankTransferItems] = useState(
    options &&
      options.row &&
      options.row.refunds &&
      options.row.refunds.filter((v) => v.paymentMethod === 'bank_transfer')
  );
  //TODO set이 사용되지 않는거같은데 useState말고 const refundBankTransferItems 로 해도 되지 않을까요?
  // eslint-disable-next-line no-unused-vars
  const [refundCash, setRefundCash] = useState(
    options &&
      options.row &&
      options.row.refunds &&
      options.row.refunds.filter(
        (v) => v.paymentMethod === 'cash' || v.paymentMethod === null
      )
  );

  const userCallApi = useCallback(async () => {
    let params = { userStatus: 'active' };
    const resp = await services.crm.user.duty(params);
    if (!resp) return;

    setUsers(resp.data);
  }, [services.crm.user]);

  useEffect(() => {
    userCallApi();
  }, [userCallApi]);

  const dataSettings = () => {
    const row = options.row || {};

    const surgeries = (row || {}).paymentTreatmentItems.map((v) => {
      v['REFUND_COUNT_$$input'] = 0;
      return v;
    });
    setSurgeriesData([...surgeries]);

    const products = (row || {}).paymentProducts.map((v) => {
      v['REFUND_QUANTITY_$$input'] = 0;
      return v;
    });
    setProductsData([...products]);
    setMemo(row.memo);
    setObj(row);
    setRefunds([...(options.row || {}).refunds]);
    setSelectedUser({ name: auth.me.name, id: auth.me.id });

    if (options.subRow !== undefined) {
      //서브 컨텍스트에서 수정(환불) 누른 경우
      //환불한 내역을 수정하는 것
      setObj({
        ...row,
        refundAmount: row.refundAmount - options.subRow.amount,
        paidAmount: row.paidAmount + options.subRow.amount,
      });
      setRefunds([
        ...(options.row || {}).refunds.filter(
          (v) => v.id !== options.subRow.id
        ),
      ]);
      setRefundAt(options.subRow.paidAt);
      setRefundAmount(options.subRow.amount);
      setSelectedUser({
        name: options.subRow.createdName,
        id: options.subRow.createdId,
      });
    }
  };

  useEffect(() => {
    dataSettings();

    return () => {
      setRefundAmount(0);
      setSurgeriesData([]);
      setProductsData([]);
      setRefunds([]);
      setObj({});
    };
  }, []);

  const onActionSurgeries = (object) => {
    const model = object.key;
    const payments = object.row;

    if (model.title === 'REFUND_COUNT') {
      let list = [...surgeriesData];
      if (payments.remainingCount < payments['REFUND_COUNT_$$input']) {
        //남은회차가 음수로 나오지 않도록 '최대입력값' == '남은회차 값'
        list = surgeriesData.map((v) => {
          if (v.id === payments.id) {
            v['REFUND_COUNT_$$input'] = v.remainingCount;
            v.refundCount = v.remainingCount;
          }
          return v;
        });
      }
      setSurgeriesData([...list]);
      return;
    }
  };

  const onActionProducts = (object) => {
    const model = object.key;
    const payments = object.row;

    if (model.title === 'REFUND_QUANTITY') {
      let list = [...productsData];
      if (payments.quantity < payments['REFUND_QUANTITY_$$input']) {
        //남은회차가 음수로 나오지 않도록 '최대입력값' == '남은회차 값'
        list = productsData.map((v) => {
          if (v.id === payments.id) {
            v['REFUND_QUANTITY_$$input'] = v.quantity;
            v.refundQuantity = v.quantity;
          }
          return v;
        });
      }
      setProductsData([...list]);
      return;
    }
  };

  const onChangeRefundAmount = (e) => {
    setRefundAmount(Number(e.target.value));
  };

  const onClickSave = useCallback(async () => {
    const payment = obj;

    //실제 돌려줘야 할 금액 = 환불액-미수액
    //만약 음수인 경우에는 돌려줘야할 금액은 0이고, 미수액을 차감하는 개념이다.
    // let refund = refundAmount-payment.unpaidAmount > 0 ? refundAmount-payment.unpaidAmount : 0

    //돌려줘야할 금액이 0이상이면 수납액에서 돌려준것으로 간주하여, 마이너스 수납액
    // let payout = changeRefundAmount-payment.unpaidAmount > 0 ? payment.payoutAmount-(changeRefundAmount-payment.unpaidAmount) : payment.payoutAmount
    // let paid = refundAmount-payment.unpaidAmount > 0 ? payment.paidAmount-(refundAmount-payment.unpaidAmount) : payment.paidAmount

    //미수액
    let unpaid =
      refundAmount - payment.unpaidAmount > 0
        ? 0
        : Math.abs(refundAmount - payment.unpaidAmount);

    //refundAmount: 실제 돌려받아야 할 금액 XX
    //>> refundAmount: 실제 입력한 금액 / refunds_amount: 돌려받아야 할 금액
    //payoutAmount : 납부 금액
    //paidAmount : 최종 수납액
    //unpaidAmount : 미납 금액

    let refundItems = [];
    // 신용카드 환불 추가
    if (payoutCreditItems.filter((value) => value.refundAmount).length > 0) {
      refundItems = [
        ...refundItems,
        ...payoutCreditItems
          .filter((value) => value.refundAmount)
          .map((v) => {
            return {
              paymentMethod: v.paymentMethod,
              createdId: selectedUser.id,
              createdName: selectedUser.name,
              amount: v.refundAmount,
              companyName: v.companyName,
              cashReceipt: false,
              paidAt:
                moment(refundAt).format('YYYY-MM-DDTHH:mm:ss') ||
                moment().format('YYYY-MM-DDTHH:mm:ss'),
            };
          }),
      ];
    }

    // 직불카드 환불 추가
    if (payoutDebitItems.filter((value) => value.refundAmount).length > 0) {
      refundItems = [
        ...refundItems,
        ...payoutDebitItems
          .filter((value) => value.refundAmount)
          .map((v) => {
            return {
              paymentMethod: v.paymentMethod,
              createdId: selectedUser.id,
              createdName: selectedUser.name,
              amount: v.refundAmount,
              companyName: v.companyName,
              cashReceipt: false,
              paidAt:
                moment(refundAt).format('YYYY-MM-DDTHH:mm:ss') ||
                moment().format('YYYY-MM-DDTHH:mm:ss'),
            };
          }),
      ];
    }

    // 계좌이체 환불 추가
    if (
      payoutBankTransferItems.filter((value) => value.refundAmount).length > 0
    ) {
      refundItems = [
        ...refundItems,
        ...payoutBankTransferItems
          .filter((value) => value.refundAmount)
          .map((v) => {
            return {
              paymentMethod: v.paymentMethod,
              createdId: selectedUser.id,
              createdName: selectedUser.name,
              amount: v.refundAmount,
              companyName: v.companyName,
              cashReceipt: false,
              paidAt:
                moment(refundAt).format('YYYY-MM-DDTHH:mm:ss') ||
                moment().format('YYYY-MM-DDTHH:mm:ss'),
            };
          }),
      ];
    }

    // 현금 환불 추가
    if (payoutCash.filter((value) => value.refundAmount).length > 0) {
      refundItems = [
        ...refundItems,
        ...payoutCash
          .filter((value) => value.refundAmount)
          .map((v) => {
            return {
              paymentMethod: 'cash',
              createdId: selectedUser.id,
              createdName: selectedUser.name,
              amount: v.refundAmount,
              companyName: v.companyName,
              cashReceipt: v.cashReceipt,
              paidAt:
                moment(refundAt).format('YYYY-MM-DDTHH:mm:ss') ||
                moment().format('YYYY-MM-DDTHH:mm:ss'),
            };
          }),
      ];
    }

    if (refundAmount !== 0) {
      refundItems = [
        {
          ...options.subRow,
          createdId: selectedUser.id,
          createdName: selectedUser.name,
          amount: refundAmount,
          paidAt:
            moment(refundAt).format('YYYY-MM-DDTHH:mm:ss') ||
            moment().format('YYYY-MM-DDTHH:mm:ss'),
        },
      ];
    }

    let refundTotalAmount = 0;
    refundItems.map((v) => {
      refundTotalAmount += v.amount;
    });

    let payload = {
      id: payment.id,
      customerId: (payment.customer || {}).id,
      customer: payment.customer,
      paymentTreatmentItems: surgeriesData.map((v) => {
        v.refundCount = Number(v['REFUND_COUNT_$$input']);
        v.remainingCount = v.remainingCount - v.refundCount;
        return v;
      }),
      paymentProducts: productsData.map((v) => {
        v.refundQuantity = Number(v['REFUND_QUANTITY_$$input']);
        v.quantity = v.quantity - v.refundQuantity;
        return v;
      }),
      payouts: payment.payouts,
      refunds: [...refunds, ...refundItems],
      refundAmount: payment.refundAmount + refundTotalAmount,
      discountAmount: payment.discountAmount,
      reuqestAmount: payment.requestAmount,
      totalAmount: payment.totalAmount,
      payoutAmount: payment.payoutAmount,
      paidAmount: payment.paidAmount - refundTotalAmount,
      unpaidAmount: unpaid,
      discountReason: null,
      memo: memo,
      reimbursementAmount: payment.reimbursementAmount,
      nonReimbursementAmount: payment.nonReimbursementAmount,
    };
    setLoadingBtnDisabledFlag(true);
    onUpdateRefunds(payload);
  }, [
    surgeriesData,
    productsData,
    memo,
    refundAmount,
    refundAt,
    refunds,
    obj,
    selectedUser,
  ]);

  const onUpdateRefunds = async (payload) => {
    try {
      const resp = await services.crm.payment.update(payload);
      if (!resp) return;

      modal
        .confirm({
          type: 'SUCCESS',
          msg: '업데이트되었습니다.',
        })
        .then(() => {
          setLoadingBtnDisabledFlag(false);
          close(1);
        });
    } catch (e) {
      console.log(e.description);
      modal
        .confirm({
          type: 'ERROR',
          msg: e.description,
        })
        .then(() => {
          setLoadingBtnDisabledFlag(false);
        });
    }
  };

  const onChangeScheduledAt = (scheduledAt) => {
    scheduledAt === null
      ? setRefundAt(moment().format())
      : setRefundAt(moment(scheduledAt).format());
  };

  return (
    <div className="modal-payments-refund">
      <div className="head flex-row flex-between items-start">
        <div className="title">환불</div>
        <i onClick={() => close()} className="zmdi zmdi-close" />
      </div>
      <div className="body">
        <div className="flex-row">
          <div className="form-control flex-wrap">
            <label className="label-required">환불일</label>
            <div style={{ width: '180px' }}>
              {useCustomerChart.getScheduledAtDatePicker({
                objScheduledAt: refundAt || new Date(),
                onChangeScheduledAt,
              })}
            </div>
          </div>
          <div className="form-control flex-wrap w-180">
            <label className="required">담당자</label>
            <select
              /* disabled={options && options.subRow ? false : true} */
              value={users.findIndex(
                (existing) => existing.id === (selectedUser || {}).id
              )}
              onChange={(e) => {
                setSelectedUser(users[e.target.value]);
              }}
            >
              <option disabled key={-1} value={-1}>
                -
              </option>
              {(users || []).map((o, idx) => (
                <option key={idx} value={idx}>
                  {o.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        {options && options.subRow && (
          <div className="form-control flex-wrap" style={{ width: '180px' }}>
            <label className="required">환불액</label>
            <div>
              <InputCurrency
                className={'item-currency'}
                name={'refundAmount'}
                initValue={((options || {}).subRow || {}).amount || 0}
                allowNegative={false}
                onValueChange={onChangeRefundAmount}
                countryCode={'kr'}
                disabled={false}
              />
            </div>
          </div>
        )}
        {options && !options.subRow && (
          <div className="flex-row">
            <div className="form-control flex-wrap m-r-10">
              <label className="label-required">환불액 설정</label>
            </div>
          </div>
        )}
        {options &&
          !options.subRow &&
          payoutCreditItems.map((v, i) => {
            return (
              <div className="flex-row" key={i}>
                <div className="form-control flex-wrap m-r-10">
                  {i === 0 && <label className="required">신용카드</label>}
                  <div>
                    <Input value={v.companyName || ''} disabled />
                  </div>
                </div>
                <div className="form-control flex-wrap m-r-10">
                  {i === 0 && <label className="required">수납일</label>}
                  <div>
                    <Input
                      value={moment(v.paidAt).format('YYYY/MM/DD')}
                      disabled
                    />
                  </div>
                </div>
                <div className="form-control flex-wrap m-r-10">
                  {i === 0 && <label className="required blue">수납액</label>}
                  <div>
                    <InputCurrency
                      className={'item-currency'}
                      name={'creditItemsRefund'}
                      initValue={(v || {}).amount || 0}
                      allowNegative={false}
                      countryCode={'kr'}
                      disabled
                    />
                  </div>
                </div>
                <div className="form-control flex-wrap">
                  {i === 0 && <label className="required">환불액</label>}
                  <div>
                    <Input
                      value={currency((v || {}).refundAmount || 0)}
                      onChange={(e) => {
                        let list = payoutCreditItems.filter((v, idx) => {
                          if (i === idx) {
                            v.refundAmount = removeCurrency(e.target.value);
                          }
                          return v;
                        });
                        setPayoutCreditItems([...list]);
                      }}
                    />
                  </div>
                </div>
              </div>
            );
          })}

        {options &&
          !options.subRow &&
          refundCreditItems.map((v, i) => {
            return (
              <div className="flex-row" key={i}>
                <div className="form-control flex-wrap m-r-10">
                  {i === 0 && (
                    <label className="required">신용카드 환불내역</label>
                  )}
                  <div>
                    <Input value={v.companyName || ''} disabled />
                  </div>
                </div>
                <div className="form-control flex-wrap m-r-10">
                  {i === 0 && <label className="required">환불일</label>}
                  <div>
                    <Input
                      value={moment(v.paidAt).format('YYYY/MM/DD')}
                      disabled
                    />
                  </div>
                </div>
                <div className="form-control flex-wrap m-r-10">
                  {i === 0 && <label className="required red">환불액</label>}
                  <div>
                    <InputCurrency
                      className={'item-currency'}
                      name={'refundAmount'}
                      initValue={(v || {}).amount || 0}
                      allowNegative={false}
                      countryCode={'kr'}
                      disabled
                    />
                  </div>
                </div>
              </div>
            );
          })}

        {options &&
          !options.subRow &&
          payoutDebitItems.map((v, i) => {
            return (
              <div className="flex-row" key={i}>
                <div className="form-control flex-wrap m-r-10">
                  {i === 0 && <label className="required">체크카드</label>}
                  <div>
                    <Input value={v.companyName || ''} disabled />
                  </div>
                </div>
                <div className="form-control flex-wrap m-r-10">
                  {i === 0 && <label className="required">수납일</label>}
                  <div>
                    <Input
                      value={moment(v.paidAt).format('YYYY/MM/DD')}
                      disabled
                    />
                  </div>
                </div>
                <div className="form-control flex-wrap m-r-10">
                  {i === 0 && <label className="required blue">수납액</label>}
                  <div>
                    <InputCurrency
                      className={'item-currency'}
                      name={'refundAmount'}
                      initValue={(v || {}).amount || 0}
                      allowNegative={false}
                      countryCode={'kr'}
                      disabled
                    />
                  </div>
                </div>
                <div className="form-control flex-wrap">
                  {i === 0 && <label className="required">환불액</label>}
                  <div>
                    <Input
                      value={currency((v || {}).refundAmount || 0)}
                      onChange={(e) => {
                        let list = payoutDebitItems.filter((v, idx) => {
                          if (i === idx) {
                            v.refundAmount = removeCurrency(e.target.value);
                          }
                          return v;
                        });
                        setPayoutDebitItems([...list]);
                      }}
                    />
                  </div>
                </div>
              </div>
            );
          })}

        {options &&
          !options.subRow &&
          refundDebitItems.map((v, i) => {
            return (
              <div className="flex-row" key={i}>
                <div className="form-control flex-wrap m-r-10">
                  {i === 0 && (
                    <label className="required">체크카드 환불내역</label>
                  )}
                  <div>
                    <Input value={v.companyName || ''} disabled />
                  </div>
                </div>
                <div className="form-control flex-wrap m-r-10">
                  {i === 0 && <label className="required">환불일</label>}
                  <div>
                    <Input
                      value={moment(v.paidAt).format('YYYY/MM/DD')}
                      disabled
                    />
                  </div>
                </div>
                <div className="form-control flex-wrap m-r-10">
                  {i === 0 && <label className="required red">환불액</label>}
                  <div>
                    <InputCurrency
                      className={'item-currency'}
                      name={'refundAmount'}
                      initValue={(v || {}).amount || 0}
                      allowNegative={false}
                      countryCode={'kr'}
                      disabled
                    />
                  </div>
                </div>
              </div>
            );
          })}

        {options &&
          !options.subRow &&
          payoutBankTransferItems.map((v, i) => {
            return (
              <div className="flex-row" key={i}>
                <div className="form-control flex-wrap m-r-10">
                  {i === 0 && <label className="required">계좌이체</label>}
                  <div>
                    <Input value={v.companyName || ''} disabled />
                  </div>
                </div>
                <div className="form-control flex-wrap m-r-10">
                  {i === 0 && <label className="required">수납일</label>}
                  <div>
                    <Input
                      value={moment(v.paidAt).format('YYYY/MM/DD')}
                      disabled
                    />
                  </div>
                </div>
                <div className="form-control flex-wrap m-r-10">
                  {i === 0 && <label className="required blue">수납액</label>}
                  <div>
                    <InputCurrency
                      className={'item-currency'}
                      name={'refundAmount'}
                      initValue={(v || {}).amount || 0}
                      allowNegative={false}
                      countryCode={'kr'}
                      disabled
                    />
                  </div>
                </div>
                <div className="form-control flex-wrap">
                  {i === 0 && <label className="required">환불액</label>}
                  <div>
                    <Input
                      value={currency((v || {}).refundAmount || 0)}
                      onChange={(e) => {
                        let list = payoutBankTransferItems.filter((v, idx) => {
                          if (i === idx) {
                            v.refundAmount = removeCurrency(e.target.value);
                          }
                          return v;
                        });
                        setPayoutBankTransferItems([...list]);
                      }}
                    />
                  </div>
                </div>
              </div>
            );
          })}

        {options &&
          !options.subRow &&
          refundBankTransferItems.map((v, i) => {
            return (
              <div className="flex-row" key={i}>
                <div className="form-control flex-wrap m-r-10">
                  {i === 0 && (
                    <label className="required">계좌이체 환불내역</label>
                  )}
                  <div>
                    <Input value={v.companyName || ''} disabled />
                  </div>
                </div>
                <div className="form-control flex-wrap m-r-10">
                  {i === 0 && <label className="required">환불일</label>}
                  <div>
                    <Input
                      value={moment(v.paidAt).format('YYYY/MM/DD')}
                      disabled
                    />
                  </div>
                </div>
                <div className="form-control flex-wrap m-r-10">
                  {i === 0 && <label className="required red">환불액</label>}
                  <div>
                    <InputCurrency
                      className={'item-currency'}
                      name={'refundAmount'}
                      initValue={(v || {}).amount || 0}
                      allowNegative={false}
                      countryCode={'kr'}
                      disabled
                    />
                  </div>
                </div>
              </div>
            );
          })}

        {options &&
          !options.subRow &&
          payoutCash.map((v, i) => {
            return (
              <div className="flex-row" key={i}>
                <div className="form-control flex-wrap m-r-10">
                  {i === 0 && <label className="required">현금</label>}
                  <div>
                    <InputCurrency
                      className={'item-currency'}
                      name={'refundAmount'}
                      initValue={(v || {}).amount || 0}
                      allowNegative={false}
                      countryCode={'kr'}
                      disabled
                    />
                  </div>
                </div>
                <div className="form-control flex-wrap m-r-10">
                  {i === 0 && <label className="required">수납일</label>}
                  <div>
                    <Input
                      value={moment(v.paidAt).format('YYYY/MM/DD')}
                      disabled
                    />
                  </div>
                </div>
                <div
                  className={`flex-row receipt-items${
                    i === 0 ? `-center` : ``
                  }`}
                >
                  {' '}
                  <label>현금영수증</label>
                </div>
                <div
                  className={`flex-row receipt-check-items${
                    i === 0 ? `-center` : ``
                  }`}
                >
                  <div className="flex-wrap payment-input">
                    <Checkbox
                      className="flex-wrap m-l-8"
                      checked={v.cashReceipt}
                      disabled
                    />
                  </div>
                </div>
                <div className="form-control flex-wrap">
                  {i === 0 && <label className="required">환불액</label>}
                  <div>
                    <Input
                      value={currency((v || {}).refundAmount || 0)}
                      onChange={(e) => {
                        let list = payoutCash.filter((v, idx) => {
                          if (i === idx) {
                            v.refundAmount = removeCurrency(e.target.value);
                          }
                          return v;
                        });
                        setPayoutCash([...list]);
                      }}
                    />
                  </div>
                </div>
              </div>
            );
          })}

        {options &&
          !options.subRow &&
          refundCash.map((v, i) => {
            return (
              <div className="flex-row" key={i}>
                <div className="form-control flex-wrap m-r-10">
                  {i === 0 && <label className="required">현금 환불내역</label>}
                  <div>
                    <Input value={`₩${(v || {}).amount || 0}`} disabled />
                  </div>
                </div>
                <div className="form-control flex-wrap m-r-10">
                  {i === 0 && <label className="required">환불일</label>}
                  <div>
                    <Input
                      value={moment(v.paidAt).format('YYYY/MM/DD')}
                      disabled
                    />
                  </div>
                </div>
              </div>
            );
          })}

        <div className="m-t-16">
          <DataTableAlpha
            model={models.crm.paymentRefundsSurgeries}
            data={surgeriesData}
            onAction={onActionSurgeries}
            hideAllCheck={true}
            hideBottom={true}
          />
        </div>
        {(productsData || []).length > 0 && (
          <div className="m-t-16">
            <DataTableAlpha
              model={models.crm.paymentRefundsProducts}
              data={productsData}
              onAction={onActionProducts}
              hideAllCheck={true}
              hideBottom={true}
            />
          </div>
        )}

        <div className="form-wide m-t-16">
          <div className="form-control">
            <label>{translate('MEMO')}</label>
            <QuillTextField
              value={memo || options.row.memo}
              setValue={(v) => setMemo(v)}
              placeholder="메모를 입력해주세요."
            />
          </div>
        </div>
      </div>
      <div className="buttons">
        <div className="flex-row">
          <button onClick={close} className="btn btn-default">
            {translate('CANCEL')}
          </button>
          <button
            onClick={onClickSave}
            disabled={`${loadingBtnDisabledFlag ? 'disabled' : ''}`}
            className="btn btn-primary"
          >
            환불완료
          </button>
        </div>
      </div>
    </div>
  );
};

ModalPaymentsRefund.propTypes = {
  options: PropTypes.object,
  close: PropTypes.func,
};

export default observer(ModalPaymentsRefund);
