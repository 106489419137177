import React, { useState, useCallback } from 'react';
import { observer } from 'mobx-react';
import { asAge, translate } from 'filters';
import hooks from 'hooks';
import ContextMenu from './ContextMenu';
import FilterContextMenu from './FilterContextMenu';
import HomeHoverCard from './HomeHoverCard';
import ModalMessageSendToBoards from 'components/modals/ModalMessageSendToBoards';
import QuillText from 'components/quill/QuillText';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useEvent } from 'hooks/useEvent';
import { useModal } from 'hooks/useModal';
import { useServices } from 'hooks/useServices';
import { useToast } from 'hooks/useToast';
import { useUI } from 'hooks/useUI';

const AppointmentCards = ({
  appointments,
  card,
  setActiveAppointment,
  onMouseUpOnStatus,
}) => {
  const eventBus = useEvent();
  const modal = useModal();
  const services = useServices();
  const toast = useToast();
  const ui = useUI();
  const forceUpdate = hooks.useForceUpdate();
  // const items = (appointments || []).filter(o => o.status === card.status).sort((a, b) => a.startHour > b.startHour ? 1 : -1)
  const items = (appointments || [])
    .filter((o) => {
      if (o.status === 'done') {
        //done 상태의 예약이 퇴원 카드에 보여지도록
        return card.status === 'leave';
      }

      //consulting_request_done, consulting_done, treatment_done, surgery_done, payment_done, leave, done, fail_consulting_request, fail_appointment, fail_consulting, fail_treatment, fail_surgery, fail_payment
      //모든 done 상태들은 완료 카드에 보여지도록
      if (
        o.status === 'consulting_request_done' ||
        o.status === 'consulting_done' ||
        o.status === 'treatment_done' ||
        o.status === 'surgery_done' ||
        o.status === 'payment_done'
      ) {
        return card.status === 'complete';
      }

      if (
        o.status === 'fail_consulting_request' ||
        o.status === 'fail_appointment' ||
        o.status === 'fail_consulting' ||
        o.status === 'fail_treatment' ||
        o.status === 'fail_surgery' ||
        o.status === 'fail_payment'
      ) {
        return card.status === 'leave';
      }

      return o.status === card.status;
    })
    .sort((a, b) => {
      let cardStatus = a.status;

      if (
        cardStatus === 'consulting_request_done' ||
        cardStatus === 'consulting_done' ||
        cardStatus === 'treatment_done' ||
        cardStatus === 'surgery_done' ||
        cardStatus === 'payment_done'
      ) {
        cardStatus = 'complete';
      }
      if (
        cardStatus === 'fail_consulting_request' ||
        cardStatus === 'fail_appointment' ||
        cardStatus === 'fail_consulting' ||
        cardStatus === 'fail_treatment' ||
        cardStatus === 'fail_surgery' ||
        cardStatus === 'fail_payment'
      ) {
        return cardStatus === 'leave';
      }

      let jsonLocal = JSON.parse(window.localStorage.getItem('homeOrderBy'));
      //카드별로 오름차순(기본) 로컬스토리지에 저장된 값에 따라 정렬 기준 변경됨
      let positive = 1;
      let negative = -1;

      if (jsonLocal !== null) {
        if (jsonLocal[cardStatus] === 'asc') {
          positive = -1;
          negative = 1;
        }
      }

      const statusName_A = a.status.split('_')[0];
      const statusName_B = b.status.split('_')[0];

      //카드상태 비교 후 카드의 상태가 같으면, 시간을 비교해야 한다
      if (a.status === null || b.status === null) {
        return a.startHour > b.startHour ? positive : negative;
      }

      if (
        a.status === 'done' ||
        b.status === 'leave' ||
        a.status === 'leave' ||
        b.status === 'done'
      ) {
        let time_A = moment(a[`${a.status}At`]).format('HH:mm');
        let time_B = moment(b[`${b.status}At`]).format('HH:mm');
        if (time_A === 'Invalid date') {
          time_A = moment(a[`${b.status}At`]).format('HH:mm');
        }
        if (time_B === 'Invalid date') {
          time_B = moment(b[`${a.status}At`]).format('HH:mm');
        }
        //예전버전에 의해 doneAt, leaveAt이 찍히지 않았던 경우를 위한 예외처리
        if (time_A === 'Invalid date' && time_B === 'Invalid date') {
          return a.startHour > b.startHour ? positive : negative;
        }

        return time_A > time_B ? positive : negative;
      }

      if (a.status.indexOf('done') > -1 && a.status !== 'done') {
        //모든 done 상태들(consulting_done, treatment_done, ..)태에 대한 정렬
        return moment(a[`${statusName_A}DoneAt`]).format('HH:mm') >
          moment(b[`${statusName_B}DoneAt`]).format('HH:mm')
          ? positive
          : negative;
      }

      if (a.status === b.status) {
        if (a.status.indexOf('during') > -1) {
          return moment(a[`${statusName_A}DuringAt`]).format('HH:mm') >
            moment(b[`${statusName_B}DuringAt`]).format('HH:mm')
            ? positive
            : negative;
        }

        if (a.status.indexOf('waiting') > -1) {
          return moment(a[`${statusName_A}WaitAt`]).format('HH:mm') >
            moment(b[`${statusName_B}WaitAt`]).format('HH:mm')
            ? positive
            : negative;
        }

        if (a.status.indexOf('done') > -1) {
          if (a.status === 'done') {
            //두 카드의 상태가 status === 'done'이고,
            return moment(a[`${statusName_A}DoneAt`]).format('HH:mm') >
              moment(b[`DoneAt`]).format('HH:mm')
              ? positive
              : negative;
          } else {
            //기존에는 모든 done 상태들(consulting_done, treatment_done, ..)은 done 카드에 표시되었음
            //그래서 정렬을 done 카드에서 했었으나 10/15 작업물로 모든 done 상태의 카드들은 '완료'카드에 보여지게 변경되어
            //이 정렬 로직을 타지 않을것으로 예상
            return moment(a[`${statusName_A}DoneAt`]).format('HH:mm') >
              moment(b[`${statusName_B}DoneAt`]).format('HH:mm')
              ? positive
              : negative;
          }
        }

        if (a.status.indexOf('leave') > -1) {
          return moment(a[`${statusName_A}At`]).format('HH:mm') >
            moment(b[`${statusName_B}At`]).format('HH:mm')
            ? positive
            : negative;
        }
      }

      return a.startHour > b.startHour ? positive : negative;
    });

  //왼클릭
  const [hoverCardOptions, setHoverCardOptions] = useState({});
  const [detailAppointment, setDetailAppointment] = useState({});

  //컨텍스트로 선택한 카드
  const [appointment, setAppointment] = useState(null);
  const [contextMenuOptions, setContextMenuOptions] = useState({});
  const [orderContextMenuOptions, setOrderContextMenuOptions] = useState({});
  const appointmentType = {
    consulting: '상담예약',
    treatment: '진료예약',
    surgery: '시/수술예약',
  };
  const contextItems = {
    scheduled_items: [
      { title: '접수하기' },
      { title: '차트보기' },
      { title: '예약수정' },
      { title: '예약취소', customStyle: 'color-red' },
      { title: '삭제' },
      { title: '문자전송' },
      { title: '약도전송' },
    ],
    unvisited_items: [
      { title: '접수하기' },
      { title: '차트보기' },
      { title: '예약수정' },
      { title: '예약취소', customStyle: 'color-red' },
      { title: '삭제' },
      { title: '문자전송' },
      { title: '약도전송' },
    ],
    canceled_items: [
      { title: '차트보기' },
      { title: '삭제' },
      { title: '문자전송' },
      { title: '약도전송' },
    ],
    consulting_waiting_items: [
      { title: '상담하기' },
      { title: '상담취소', customStyle: 'color-red' },
      { title: '차트보기' },
      { title: '삭제' },
      { title: '문자전송' },
      { title: '약도전송' },
    ],
    consulting_during_items: [
      { title: '상담하기' },
      { title: '상담취소', customStyle: 'color-red' },
      { title: '차트보기' },
      { title: '삭제' },
      { title: '문자전송' },
      { title: '약도전송' },
    ],
    consulting_done_items: [
      { title: '차트보기' },
      { title: '삭제' },
      { title: '문자전송' },
      { title: '약도전송' },
    ],
    treatment_waiting_items: [
      { title: '진료하기' },
      { title: '진료취소', customStyle: 'color-red' },
      { title: '차트보기' },
      { title: '삭제' },
      { title: '문자전송' },
      { title: '약도전송' },
    ],
    treatment_during_items: [
      { title: '진료하기' },
      { title: '진료취소', customStyle: 'color-red' },
      { title: '차트보기' },
      { title: '삭제' },
      { title: '문자전송' },
      { title: '약도전송' },
    ],
    treatment_done_items: [
      { title: '차트보기' },
      { title: '삭제' },
      { title: '문자전송' },
      { title: '약도전송' },
    ],
    surgery_waiting_items: [
      { title: '시/수술하기' },
      { title: '시/수술취소', customStyle: 'color-red' },
      { title: '차트보기' },
      { title: '삭제' },
      { title: '문자전송' },
      { title: '약도전송' },
    ],
    surgery_during_items: [
      { title: '시/수술하기' },
      { title: '시/수술취소', customStyle: 'color-red' },
      { title: '차트보기' },
      { title: '삭제' },
      { title: '문자전송' },
      { title: '약도전송' },
    ],
    surgery_done_items: [
      { title: '차트보기' },
      { title: '삭제' },
      { title: '문자전송' },
      { title: '약도전송' },
    ],
    payment_waiting_items: [
      { title: '수납하기' },
      { title: '수납취소', customStyle: 'color-red' },
      { title: '차트보기' },
      { title: '삭제' },
      { title: '문자전송' },
      { title: '약도전송' },
    ],
    leave_items: [
      { title: '차트보기' },
      { title: '삭제' },
      { title: '문자전송' },
      { title: '약도전송' },
    ],
    done_items: [
      { title: '차트보기' },
      { title: '삭제' },
      { title: '문자전송' },
      { title: '약도전송' },
    ],
    complete_items: [
      { title: '차트보기' },
      { title: '삭제' },
      { title: '문자전송' },
      { title: '약도전송' },
    ], //완료열
  };

  const onReloadHoverCard = (hoverCardReloadFlg) => {
    if (hoverCardReloadFlg) {
      setHoverCardOptions({
        ...hoverCardOptions,
      });
    }
  };

  const onContextMenu = (e, appointment) => {
    if (appointment !== undefined) {
      const itemName = `${card.status}_items`;

      e.preventDefault();
      setContextMenuOptions({
        show: true,
        x: e.clientX,
        y: e.clientY,
        items: contextItems[itemName],
      });
      setAppointment(appointment);
    }
  };

  const onOrderContextMenu = (e, status, prevOrder) => {
    e.preventDefault();
    setOrderContextMenuOptions({
      show: true,
      x: e.clientX,
      y: e.clientY,
      items: [
        { title: '시간 내림차순', status, prevOrderCheck: prevOrder === 'asc' },
        {
          title: '시간 오름차순',
          status,
          prevOrderCheck: prevOrder === 'desc',
        },
      ],
    });
  };

  const appointmentDetailCallApi = async (appointmentId) => {
    const resp = await services.crm.crud.appointment.detail(appointmentId);
    if (!resp) return;

    return resp.data;
  };

  const customerDetailCallApi = async (customerId) => {
    try {
      const resp = await services.crm.customer.detail(customerId);
      if (!resp) return;

      return resp.data;
    } catch (e) {
      console.log(e.description);
      if (e.dscription === 'CustomerNotFound') {
        toast.error('삭제된 고객입니다.');
      }
    }
  };

  const openCustomerChart = async (listAppointment, statusName, openFlag) => {
    const customer = await customerDetailCallApi(listAppointment.customerId);
    let appointment = { appointmentId: listAppointment.id };
    hooks.openCustomerChart({
      customer,
      appointment,
      selectTab: statusName,
      openFlag,
      calledPage: 'home',
    });
    return;
  };

  const onShowAlertAppointmentStatusToCancel = (status, appointment) => {
    //상태 취소 알러트
    modal
      .basic({
        body: `${status}취소하시겠습니까?`,
        buttons: [
          {
            text: '취소',
            class: 'btn-default',
          },
          {
            text: '확인',
            class: 'btn-primary',
          },
        ],
      })
      .then((idx) => {
        if (idx === 1) {
          onChangeAppointmentStatusToCancel(status, appointment);
        }
      });
  };

  const onChangeAppointmentStatusToCancel = async (status, appointment) => {
    //예약데이터도 취소상태로 변경해야함
    try {
      const appointmentItem = await services.crm.crud.appointment.detail(
        appointment.id
      );
      if (Object.keys(appointmentItem.data).length) {
        const updatePayload = {
          ...appointmentItem.data,
          status: 'canceled',
          customerId: ((appointmentItem.data || {}).customer || {}).id,
          department: { id: appointmentItem.data?.department?.id },
        };

        //취소 시, items, paymentTreatmentItems는 제외하고 api 호출 (변경이 필요 없으므로)
        let {
          // eslint-disable-next-line no-unused-vars
          items: deletedKey,
          // eslint-disable-next-line no-unused-vars
          paymentTreatmentItems: deletedKey2,
          ...otherKeys
        } = updatePayload;

        await services.crm.crud.appointment.update(otherKeys);
        const message = `${status}취소하였습니다.`;
        modal
          .confirm({
            type: 'SUCCESS',
            msg: message,
          })
          .then(() => {
            eventBus.emit('homeLoadAppointments');
          });
      }
    } catch (e) {
      console.log(e.description);
    }
  };

  const onChangeAppointmentStatusToDuring = async (status) => {
    // 상담대기>상담중 / 진료대기>진료중 / 시수술대기>시수술중으로 변경시 호출됨
    const detail = await appointmentDetailCallApi(appointment.id);
    if (!detail) return;

    const payload = {
      ...detail,
      id: (detail || {}).id,
      customerId: ((detail || {}).customer || {}).id,
      scheduledAt: (detail || {}).scheduledAt,
      status,
      appointmentStatus: status,
      appointmentId: (detail || {}).id,
      department: { id: detail?.department?.id },
    };

    //상태변경 시, items, paymentTreatmentItems는 제외하고 api 호출 (변경이 필요 없으므로)
    let {
      // eslint-disable-next-line no-unused-vars
      items: deletedKey,
      // eslint-disable-next-line no-unused-vars
      paymentTreatmentItems: deletedKey2,
      ...otherKeys
    } = payload;

    try {
      const result = await services.crm.crud.appointment.update(otherKeys);
      if (!result) return;
      return Object.keys(result.data).length;
    } catch (e) {
      console.log(e.description);
    }
  };

  const deleteAppointment = useCallback(
    async (appointment) => {
      try {
        await services.crm.crud.appointment.delete(appointment.id);
        modal
          .confirm({
            type: 'SUCCESS',
            msg: '삭제되었습니다.',
          })
          .then(() => {
            eventBus.emit('homeLoadAppointments');
          });
      } catch (e) {
        console.log(e.description);
      }
    },
    [services.crm.crud.appointment, toast, eventBus]
  );

  const onClickOrderContextMenuItem = async (item) => {
    setOrderContextMenuOptions({});
    if (item === -1) {
      return;
    }

    if (item.title === '시간 내림차순') {
      onClickOrderBy(item.status, 'asc');
      return;
    }

    if (item.title === '시간 오름차순') {
      onClickOrderBy(item.status, 'desc');
      return;
    }
  };

  const onClickContextMenuItem = async (item) => {
    setContextMenuOptions({});
    if (item === -1) {
      return;
    }

    if (appointment.customerStatus === 'deactivated') {
      toast.error('삭제된 고객입니다.');
      return;
    }

    if (item.title === '접수하기') {
      const changeStatus = `${appointment.type}_waiting`;
      //내원한 것이므로 예약의 visited = true로 변경,
      const appointmentItem = await services.crm.crud.appointment.detail(
        appointment.id
      );

      if (Object.keys(appointmentItem.data).length) {
        const updatePayload = {
          ...appointmentItem.data,
          visited: true,
          status: changeStatus,
          appointmentStatus: changeStatus,
          appointmentId: appointment.id,
          customerId: ((appointmentItem.data || {}).customer || {}).id,
          department: { id: appointmentItem.data?.department?.id },
        };

        //접수하기 시, items, paymentTreatmentItems는 제외하고 api 호출 (변경이 필요 없으므로)
        let {
          // eslint-disable-next-line no-unused-vars
          items: deletedKey,
          // eslint-disable-next-line no-unused-vars
          paymentTreatmentItems: deletedKey2,
          ...otherKeys
        } = updatePayload;

        const resp = await services.crm.crud.appointment.update(otherKeys);
        if (!resp) return;

        setTimeout(function () {
          //업데이트 후 0.5초 뒤에 api 호출 (복제지연시간을 고려한 호출)
          if (resp.data) {
            eventBus.emit('homeLoadAppointments');
          }
        }, 500);
      }
      return;
    }

    if (item.title === '차트보기') {
      const customer = await customerDetailCallApi(appointment.customerId);
      const openFlag = {
        appointmentId: appointment.id,
      };
      hooks.openCustomerChart({ customer, openFlag, calledPage: 'home' });
      return;
    }

    if (item.title === '예약수정') {
      const detail = await appointmentDetailCallApi(appointment.id);
      const customer = detail.customer;
      const openFlag = {
        appointmentId: appointment.id,
        appointment: detail,
      };
      hooks.openCustomerChart({
        appointment,
        customer,
        selectTab: 'APPOINTMENT',
        openFlag,
        calledPage: 'home',
      });
    }

    if (
      item.title === '예약취소' ||
      item.title === '상담취소' ||
      item.title === '진료취소' ||
      item.title === '수납취소' ||
      item.title === '시/수술취소'
    ) {
      onShowAlertAppointmentStatusToCancel(
        item.title.replace('취소', ''),
        appointment
      );
    }

    if (item.title === '상담하기') {
      const result = await onChangeAppointmentStatusToDuring(
        'consulting_during'
      );
      const openFlag = {
        appointmentId: appointment.id,
        consulting: null,
      };
      if (result) {
        openCustomerChart(appointment, 'CONSULTING', openFlag);
      }
      return;
    }

    if (item.title === '진료하기') {
      const result = await onChangeAppointmentStatusToDuring(
        'treatment_during'
      );
      const openFlag = {
        appointmentId: appointment.id,
        treatment: null,
      };
      if (result) {
        openCustomerChart(appointment, 'TREATMENT', openFlag);
      }
      return;
    }

    if (item.title === '시/수술하기') {
      const result = await onChangeAppointmentStatusToDuring('surgery_during');
      const openFlag = {
        appointmentId: appointment.id,
        surgery: null,
      };
      if (result) {
        openCustomerChart(appointment, 'SURGERY', openFlag);
      }
      return;
    }

    if (item.title === '수납하기') {
      const openFlag = {
        appointmentId: appointment.id,
        payment: { componentFlag: null },
      };
      openCustomerChart(appointment, 'PAYMENT', openFlag);
      return;
    }

    if (item.title === '삭제') {
      modal
        .basic({
          body: '삭제하시겠습니까?',
          buttons: [
            { text: 'CANCEL', class: 'btn-default' },
            { text: 'CONFIRM', class: 'btn-danger' },
          ],
        })
        .then((selectedBtnIdx) => {
          if (selectedBtnIdx === 1) {
            deleteAppointment(appointment);
          }
        });
      return;
    }

    if (item.title === '문자전송') {
      //문자 전송
      openModalSendMessage(appointment);
      return;
    }

    if (item.title === '약도전송') {
      onClickSendMessageMap(appointment);
      return;
    }
  };

  const callBoilerplateMap = async (appointment) => {
    try {
      const resp = await services.crm.notification.boilerplateMap.all();
      if (!resp) return;

      let map = resp.data[0];
      //약도전송에 저장된 id 제거하고 메시지 전송하기
      // eslint-disable-next-line no-unused-vars
      let { id: deletedKey, ...message } = map;
      modal.custom({
        component: ModalMessageSendToBoards,
        options: {
          appointment: appointment,
          mapMessage: {
            ...message,
          },
        },
      });
    } catch (e) {
      console.log(e.description);
    }
  };

  const onClickSendMessageMap = useCallback(
    async (appointment) => {
      //1. 문자코드 설정에 설정한 약도가 있는지?
      //설정되어 있으면 전송 확인 팝업
      //설정되어 있지 않으면 토스트 알림

      try {
        const resp = await services.crm.notification.smsConfig.all();
        if (!resp) return;

        if (resp.data.map === undefined || !resp.data.map) {
          modal.basic({
            body: '문자코드 설정에서 약도이미지를 설정하세요.',
            buttons: [{ text: 'CONFIRM', class: 'btn-primary' }],
          });
          return;
        } else {
          callBoilerplateMap(appointment);
        }
      } catch (error) {
        toast.error(error.description);
      }
    },
    [modal, services.crm.notification.smsConfig]
  );

  const openModalSendMessage = useCallback(() => {
    modal.custom({
      component: ModalMessageSendToBoards,
      options: {
        appointment,
      },
    });
  }, [modal, appointment]);

  const CardOrderByIcon = (statusObj) => {
    let status = statusObj.status;
    let prevOrder = 'desc';
    let jsonLocal = JSON.parse(window.localStorage.getItem('homeOrderBy'));
    if (jsonLocal !== null) {
      prevOrder = jsonLocal[status] === 'desc' ? 'desc' : 'asc';
    }

    return (
      <i
        className={`zmdi zmdi-filter`}
        onClick={(e) => onOrderContextMenu(e, status, prevOrder)}
      />
    );
  };

  const TimeAreaFromStatus = (itemObj) => {
    let item = itemObj.item;
    const { status } = item;

    if (status === null) {
      return (
        <>
          <div>{item.startHour}</div>
          <div>~{item.endHour}</div>
        </>
      );
    }

    const statusName = status.split('_')[0];
    if (status.indexOf('during') > -1) {
      return <div>{moment(item[`${statusName}DuringAt`]).format('HH:mm')}</div>;
    }

    if (status.indexOf('waiting') > -1) {
      return <div>{moment(item[`${statusName}WaitAt`]).format('HH:mm')}</div>;
    }

    if (status.indexOf('done') > -1) {
      if (status === 'done') {
        if (item[`doneAt`] === null) {
          //doneAt이 null인경우 이전 버전과 호환을 위해서 leaveAt을 표시
          if (item[`leaveAt`] !== null) {
            return <div>{moment(item[`leaveAt`]).format('HH:mm')}</div>;
          }
        } else {
          return <div>{moment(item[`doneAt`]).format('HH:mm')}</div>;
        }
      } else {
        return <div>{moment(item[`${statusName}DoneAt`]).format('HH:mm')}</div>;
      }
    }

    if (status.indexOf('leave') > -1) {
      if (item[`leaveAt`] === null) {
        //leaveAt null인경우 이전 버전과 호환을 위해서 doneAt을 표시
        if (item[`doneAt`] !== null) {
          return <div>{moment(item[`doneAt`]).format('HH:mm')}</div>;
        }
      } else {
        return <div>{moment(item[`leaveAt`]).format('HH:mm')}</div>;
      }
    }

    return (
      <>
        <div>{item.startHour}</div>
        <div>~{item.endHour}</div>
      </>
    );
  };

  const onClickCard = async (e, appointment) => {
    // if (!setAppointment) return
    e.preventDefault();
    if (setHoverCardOptions) {
      setHoverCardOptions({
        show: true,
        x: e.clientX,
        y: e.clientY,
      });
    }

    if (appointment) {
      setDetailAppointment(appointment);
    }
  };

  // const onClickOpenChart = (e, appointment, type) => {
  //   e.preventDefault()
  //   setHoverCardOptions({ show: false })
  //   setDetailAppointment([])
  //   openCustomerChart(appointment, type)
  // }

  const onClickOrderBy = (status, order) => {
    // ex: { scheduled: 'asc', unvisited: 'desc' }
    let jsonLocal = JSON.parse(window.localStorage.getItem('homeOrderBy'));
    if (jsonLocal === null) {
      jsonLocal = { [status]: order };
    } else {
      jsonLocal[status] = order;
    }
    window.localStorage.setItem('homeOrderBy', JSON.stringify(jsonLocal));
  };

  return (
    <div
      onMouseUp={() => onMouseUpOnStatus(card.status)}
      className={`appointments-card ${card.status}`}
    >
      <div className="header flex-row items-center flex-between">
        <div className="title">
          <span>
            {translate(`APPOINTMENT_STATUS_${card.status.toUpperCase()}`)}
          </span>
          (<span>{items.length || 0}</span>)
        </div>
        <div className="flex-row" style={{ alignItems: 'center' }}>
          <FilterContextMenu
            options={orderContextMenuOptions}
            onClick={onClickOrderContextMenuItem}
          />
          <CardOrderByIcon status={card.status} />
          <i
            className={`zmdi zmdi-chevron-${
              (
                ui.isCardFold && typeof ui.isCardFold[card.status] === 'boolean'
                  ? ui.isCardFold[card.status]
                  : card.expanded
              )
                ? 'up'
                : 'down'
            }`}
            onClick={() => {
              card.expanded =
                ui.isCardFold && typeof ui.isCardFold[card.status] === 'boolean'
                  ? !ui.isCardFold[card.status]
                  : !card.expanded;
              let update = { ...ui.isCardFold };
              update[card.status] = card.expanded;
              ui.setUiState('isCardFold', update);
              forceUpdate();
            }}
          />
        </div>
      </div>
      {(ui.isCardFold && typeof ui.isCardFold[card.status] === 'boolean'
        ? ui.isCardFold[card.status]
        : card.expanded) && (
        <div className="items">
          {items.length ? (
            <>
              <ContextMenu
                options={contextMenuOptions}
                onClick={onClickContextMenuItem}
              />
              <HomeHoverCard
                options={hoverCardOptions}
                appointment={detailAppointment}
                setHoverCardOptions={setHoverCardOptions}
                setDetailAppointment={setDetailAppointment}
                setReloadHoverCard={onReloadHoverCard}
              />
              {items.map((item, idx) => (
                <div
                  onContextMenu={(e) => onContextMenu(e, item)}
                  draggable={true}
                  onDragStart={() => setActiveAppointment(item)}
                  onDragLeave={() => {}}
                  key={idx}
                  className={`appointment flex-row items-center`}
                >
                  <div
                    className={`schedule flex-wrap ${
                      card.status === 'unvisited' ? 'unvisited-time' : ''
                    }`}
                  >
                    <TimeAreaFromStatus item={item}></TimeAreaFromStatus>
                  </div>
                  <div className="info">
                    <div onClick={(e) => onClickCard(e, item)}>
                      <div className="wrap-badge type flex-row">
                        <span className="badge badge-blue">
                          {appointmentType[item.type]}
                        </span>
                      </div>
                      <div className="basic flex-row">
                        <div className="customer_info">
                          <span>{item.customerName || '-'}</span>
                          <span>
                            ({asAge(item.customerBirthday) || '-'}/
                            {item.customerSex !== null
                              ? translate(item.customerSex.toUpperCase())
                              : ''}
                            )
                          </span>
                          {item.isNewCustomer ? (
                            <span className="badge new-patient">신환</span>
                          ) : (
                            <span className="badge origin-patient">구환</span>
                          )}
                        </div>
                      </div>
                      {(item.counselorName ||
                        item.doctorName ||
                        item.facialistName) && (
                        <div className="counselor-doctor flex-row">
                          <div className="doctor">
                            {item.counselorName && (
                              <span>{item.counselorName}</span>
                            )}
                            {item.doctorName && <span>{item.doctorName}</span>}
                            {item.facialistName && (
                              <span>{item.facialistName}</span>
                            )}
                          </div>
                        </div>
                      )}
                      <div className="sub-objects wrap-info-department">
                        <span className="department">
                          {item.departmentCategoryName || ''}-
                          {item.departmentName || ''}
                        </span>
                        {item.type === 'surgery' ? (
                          (item.paymentTreatmentItems || []).length > 0 ? (
                            <span className="treatment-items">
                              {item.paymentTreatmentItems.map(
                                (treatmentItem, idx) => (
                                  <span key={idx}>
                                    {treatmentItem.category.name}-
                                    {treatmentItem.name}
                                  </span>
                                )
                              )}
                            </span>
                          ) : null
                        ) : (item.items || []).length > 0 ? (
                          <div className="treatment-items">
                            {item.items.map((treatmentItem, idx) => (
                              <div key={idx}>
                                {treatmentItem?.category.name}-
                                {treatmentItem.name}
                              </div>
                            ))}
                          </div>
                        ) : null}
                      </div>
                      <div className="memo">
                        <QuillText value={item.memo ?? ''} />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </>
          ) : null}
        </div>
      )}
    </div>
  );
};

AppointmentCards.propTypes = {
  appointments: PropTypes.array,
  card: PropTypes.object,
  setActiveAppointment: PropTypes.func,
  onMouseUpOnStatus: PropTypes.func,
};

export default observer(AppointmentCards);
