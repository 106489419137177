import React, { useState, useEffect, useCallback } from 'react';
import { observer } from 'mobx-react';
import DataTableAlpha from 'components/data-table/DataTableAlpha';
import ModalSurgery from 'components/modals/ModalSurgery';
import PropTypes from 'prop-types';
import models from 'models';
import { translate } from 'filters';
import { $qb } from 'scripts/querybuilder';
import { useAuth } from 'store/auth';
import { useModal } from 'hooks/useModal';
import { useServices } from 'hooks/useServices';
import styled from 'styled-components';
import searchIcon from 'assets/images/icon/ic-search.png';

const Input = styled.input`
  width: 100%;
  height: 34px;
  font-size: 12px;
  display: block;
  min-height: 10px;
  border-radius: 4px;
  color: #000;
  border: 1px solid #dee2ec;
  padding: 8px 15px 8px 50px;
  background-color: #fff;
  background-image: url(${searchIcon});
  background-size: 19px;
  background-repeat: no-repeat;
  background-position: left 15px center;

  &::placeholder {
    font-size: 12px;
  }
`;

/*
  시/수술탭, 피부관리탭, 수납탭 > 시/수술추가 버튼시 노출되는 팝업
  팝업에서 선택 후 저장시에 시/수술탭, 피부관리탭 / 수납탭 에서 사용하는 items 값이 달라서,
  공통으로 사용하는 값들은 해당 파일에서 정의되고,
  구분되는 내용은 팝업 오픈 이후의 .then의 로직에서 정의됨.
*/

const ModalAddPaymentsSurgeries = ({ close }) => {
  const modal = useModal();
  const services = useServices();
  const auth = useAuth();
  const defaultParams = $qb().limit(5).customParam('visible', true);
  const [params, setParams] = useState(defaultParams);
  const [searchValue, setSearchValue] = useState('');
  const [treatmentData, setTreatmentData] = useState([]);
  const [total, setTotal] = useState(0);
  const [checkedSurgeries, setCheckedSurgeries] = useState([]);
  const [checkedServices, setCheckedServices] = useState([]);
  const [inputTreatmentCount, setInputTreatmentCount] = useState([]);
  const [inputVatPrices, setInputVatPrices] = useState([]);
  const [inputPrices, setInputPrices] = useState([]);

  const treatmentCallApi = async () => {
    params.customParam('visible', true);
    let resp = await services.crm.treatment.items.all_v2(params.build());
    //페이지네이션시에도 체크박스가 유지되도록
    resp.data.forEach((data) => {
      if (checkedSurgeries.find((v) => v.id === data.id) !== undefined) {
        data.$$checked = true;
      }

      //무료 서비스 컬럼 체크박스 유지되도록
      if ((checkedServices || []).find((v) => v.id === data.id) !== undefined) {
        data['FREE_SERVICE_$$checked'] = true;
      } else {
        data['FREE_SERVICE_$$checked'] = false;
      }

      //가격(VAT 포함) 인풋박스 유지되도록
      if ((inputVatPrices || []).find((v) => v.id === data.id) !== undefined) {
        data[`PRICE_VAT_INCLUDE_$$input`] = (inputVatPrices || []).find(
          (v) => v.id === data.id
        )[`PRICE_VAT_INCLUDE_$$input`];
      } else {
        data[`PRICE_VAT_INCLUDE_$$input`] = data.priceVatInclude;
      }

      if ((inputPrices || []).find((v) => v.id === data.id) !== undefined) {
        data[`NOT_VAT_INCLUDE_$$input`] = (inputPrices || []).find(
          (v) => v.id === data.id
        )[`NOT_VAT_INCLUDE_$$input`];
      } else {
        data[`NOT_VAT_INCLUDE_$$input`] = data.price;
      }

      //시/수술횟수 인풋박스 유지되도록
      if (
        (inputTreatmentCount || []).find((v) => v.id === data.id) !== undefined
      ) {
        data[`SURGERY_NUMOF_$$input`] = (inputTreatmentCount || []).find(
          (v) => v.id === data.id
        )[`SURGERY_NUMOF_$$input`];
      } else {
        data[`SURGERY_NUMOF_$$input`] = data.treatmentCount;
      }

      return data;
    });

    setTreatmentData(resp.data);
    setTotal(resp.pagination.total);
  };

  useEffect(() => {
    treatmentCallApi();
  }, []);

  const onParams = (p) => {
    setParams(p);
    treatmentCallApi();
  };

  const onChangePriceVat = (surgeries) => {
    if (
      (inputVatPrices || []).find((v) => v.id === surgeries.id) === undefined
    ) {
      inputVatPrices.push(surgeries);
      let list = [...inputVatPrices];
      list.push(surgeries);
      setInputVatPrices([...list]);
    } else {
      let list = inputVatPrices.map((v) => {
        if (v.id === surgeries.id) {
          v[`PRICE_VAT_INCLUDE_$$input`] =
            surgeries[`PRICE_VAT_INCLUDE_$$input`];
          v[`NOT_VAT_INCLUDE_$$input`] = v['vatIncluded']
            ? Math.round(v[`PRICE_VAT_INCLUDE_$$input`] / 1.1)
            : v[`PRICE_VAT_INCLUDE_$$input`];
        }
        return v;
      });
      setInputVatPrices([...list]);
    }
  };

  const onChangePrice = (surgeries) => {
    if ((inputPrices || []).find((v) => v.id === surgeries.id) === undefined) {
      inputPrices.push(surgeries);
      let list = [...inputPrices];
      list.push(surgeries);
      setInputPrices([...list]);
    } else {
      let list = inputPrices.map((v) => {
        if (v.id === surgeries.id) {
          v[`NOT_VAT_INCLUDE_$$input`] = surgeries[`NOT_VAT_INCLUDE_$$input`];
          v[`PRICE_VAT_INCLUDE_$$input`] = v['vatIncluded']
            ? Math.round(v[`NOT_VAT_INCLUDE_$$input`] * 1.1)
            : v[`NOT_VAT_INCLUDE_$$input`];
        }
        return v;
      });
      setInputPrices([...list]);
    }
  };

  const onAction = (obj) => {
    const event = obj.event;
    const surgeries = obj.row;
    const classList = event.target.classList;
    const model = obj.key;

    if (model.title === 'SURGERY_NUMOF') {
      //시/수술 횟수 인풋박스 변경
      //inputTreatmentCount 리스트에 현재 변경된 값의 아이디가 있으면,
      //$$input 변경값이 있으므로, 덮어씌우기
      //리스트에 없는 값이면 리스트에 추가
      if (
        (inputTreatmentCount || []).find((v) => v.id === surgeries.id) ===
        undefined
      ) {
        inputTreatmentCount.push(surgeries);
        let list = [...inputTreatmentCount];
        list.push(surgeries);
        setInputTreatmentCount([...list]);
      } else {
        let list = inputTreatmentCount.map((v) => {
          if (v.id === surgeries.id) {
            v[`SURGERY_NUMOF_$$input`] = surgeries[`SURGERY_NUMOF_$$input`];
          }
          return v;
        });
        setInputTreatmentCount([...list]);
      }
    }

    if (model.title === 'PRICE_VAT_INCLUDE') {
      //가격 (VAT포함) 횟수 인풋박스 변경
      //inputVatPrices 리스트에 현재 변경된 값의 아이디가 있으면,
      //$$input 변경값이 있으므로, 덮어씌우기
      //리스트에 없는 값이면 리스트에 추가
      onChangePriceVat(surgeries);
    }

    if (model.title === 'NOT_VAT_INCLUDE') {
      //가격 (VAT포함) 횟수 인풋박스 변경
      //inputVatPrices 리스트에 현재 변경된 값의 아이디가 있으면,
      //$$input 변경값이 있으므로, 덮어씌우기
      //리스트에 없는 값이면 리스트에 추가
      onChangePrice(surgeries);
    }

    if (classList.contains('checkbox') || classList.contains('zmdi-check')) {
      let checked = [...checkedSurgeries];
      let cData = [...treatmentData];
      let serviceChecked = [...checkedServices];

      if (model.title === 'FREE_SERVICE') {
        //페이지네이션 시 기억될 수 있도록 checkedService list 생성
        //무료 서비스 선택 시 가격 0원처리
        //무료 서비스 체크박스 체크 > "등록" 버튼 클릭 시, 아이템 등록되도록 수정
        if (surgeries['FREE_SERVICE_$$checked']) {
          let surgery = { ...surgeries, PRICE_VAT_INCLUDE_$$input: 0 };
          serviceChecked = [...checkedServices, surgery];

          let changeData = treatmentData.map((v) => {
            //가격(VAT 포함) input = 0으로 업데이트
            if (v.id === surgeries.id) {
              v[`PRICE_VAT_INCLUDE_$$input`] = 0;
              v[`NOT_VAT_INCLUDE_$$input`] = 0;
              v['$$checked'] = true;
            }
            return v;
          });
          cData = [...changeData];
          checked = checked.map((v) => {
            if (v.id === surgeries.id) {
              v[`FREE_SERVICE_$$checked`] = true;
            }
            return v;
          });
          onChangePriceVat(surgery);
        } else {
          let list = checkedServices.filter((v) => {
            return v.id !== surgeries.id;
          });
          serviceChecked = [...list];

          let changeData = treatmentData.map((v) => {
            //가격(VAT 포함) input = 원래 금액으로 업데이트
            if (v.id === surgeries.id) {
              v[`PRICE_VAT_INCLUDE_$$input`] = v.priceVatInclude;
              v[`NOT_VAT_INCLUDE_$$input`] = v.price;
            }
            return v;
          });
          cData = [...changeData];
          checked = checked.map((v) => {
            if (v.id === surgeries.id) {
              v[`FREE_SERVICE_$$checked`] = false;
            }
            return v;
          });
        }
      }

      //선택 체크 열
      if (surgeries.$$checked) {
        if ((checked || []).find((v) => v.id === surgeries.id) === undefined) {
          checked.push(surgeries);
        }
      } else {
        let list = checked.filter((v) => {
          return v.id !== surgeries.id;
        });
        checked = [...list];

        //선택 해제 시 무료 서비스가 체크되어 있는 데이터면 같이 해제 시킴
        serviceChecked = serviceChecked.filter((v) => {
          return v['FREE_SERVICE_$$checked'] === true;
        });
        cData = cData.map((v) => {
          if (v.id === surgeries.id) {
            v['$$checked'] = false;
            v['FREE_SERVICE_$$checked'] = false;
            v[`PRICE_VAT_INCLUDE_$$input`] = v.priceVatInclude;
            v[`NOT_VAT_INCLUDE_$$input`] = v.price;
          }
          return v;
        });
      }

      setCheckedSurgeries([...checked]);
      setCheckedServices([...serviceChecked]);
      setTreatmentData([...cData]);
    }
  };

  const onClickSearch = () => {
    params.queryParams = {
      limit: params.queryParams.limit,
      visible: true,
      ...searchValue,
    };
    onParams(params);
  };

  const onClickSave = useCallback(async () => {
    const treatmentItems = checkedSurgeries.map((v) => {
      v.categoryName = (v.category || {}).name;
      v.priceVatInclude = parseInt(v['PRICE_VAT_INCLUDE_$$input']);
      v.price = parseInt(Math.round(v[`PRICE_VAT_INCLUDE_$$input`] / 1.1));
      v.treatmentCount = parseInt(v['SURGERY_NUMOF_$$input']) || 0;
      v.treatmentItemId = v.id;

      if (v['FREE_SERVICE_$$checked']) {
        v.isFree = true;
      }

      return v;
    });

    close(treatmentItems);
  }, [close, checkedSurgeries]);

  const onClickOpenPaymentCodePopup = () => {
    modal
      .custom({
        component: ModalSurgery,
        options: { surgery: undefined },
      })
      .then((submitted) => {
        if (submitted) {
          treatmentCallApi();
        }
      });
  };

  return (
    <div className="modal-payment-items items-group">
      <div className="head flex-row flex-between items-start">
        <div className="title">시/수술 추가</div>
        <i onClick={() => close()} className="zmdi zmdi-close" />
      </div>
      <div className="body">
        <div className="search-bar">
          <div className="search-item m-r-8">
            <Input
              placeholder="시/수술 카테고리를 검색하세요"
              name="categoryName"
              onKeyPress={(e) => (e.key === 'Enter' ? onClickSearch() : null)}
              onChange={(e) =>
                setSearchValue({
                  ...searchValue,
                  [e.target.name]: e.target.value,
                })
              }
            />
          </div>
          <div className="search-item m-r-8">
            <Input
              placeholder="시/수술명을 검색하세요"
              name="name"
              onKeyPress={(e) => (e.key === 'Enter' ? onClickSearch() : null)}
              onChange={(e) =>
                setSearchValue({
                  ...searchValue,
                  [e.target.name]: e.target.value,
                })
              }
            />
          </div>
          <div className="search-btns">
            <button
              className="btn flex-wrap _small m-r-8 btn-primary"
              onClick={onClickSearch}
            >
              검색
            </button>
          </div>
          {auth.me.authorityGroup.paymentCode !== 'disabled' && (
            <div
              className="search-btns"
              style={{
                float: 'right',
                marginLeft: 'auto',
              }}
            >
              <button
                className="btn _small btn-primary"
                onClick={onClickOpenPaymentCodePopup}
              >
                시/수술코드 추가
              </button>
            </div>
          )}
        </div>
        <DataTableAlpha
          model={models.crm.paymentTreatmentItems}
          data={treatmentData}
          total={total}
          params={params}
          onParams={onParams}
          onAction={onAction}
          hideAllCheck={true}
        />
      </div>

      <div className="buttons">
        <div className="flex-row">
          <button
            onClick={() => {
              close(undefined);
            }}
            className="btn btn-default"
          >
            {translate('CANCEL')}
          </button>
          <button onClick={onClickSave} className="btn btn-primary">
            등록
          </button>
        </div>
      </div>
    </div>
  );
};

ModalAddPaymentsSurgeries.propTypes = {
  close: PropTypes.func,
};

export default observer(ModalAddPaymentsSurgeries);
